<div class="document-preview-container">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" (clickBreadcrumb)="onClickBreadcrumb()"></ptg-breadcrumb>
    <ng-container *ngIf="entityType === EntityType.Participant">
      <ptg-overview-header-entity
        [isShowChangeMemberDetailBtn]="false"
        [targetIdFromDialog]="targetId"
        [isSubHeader]="true"
      >
      </ptg-overview-header-entity>
    </ng-container>
    <div class="info-edit">
      <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()" class="form">
        <div class="form-container">
          <div class="wrap-btn">
            <button mat-raised-button type="submit" class="submit-button">Save Document</button>
            <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
          </div>
          <div class="form-content">
            <div class="column-section">
              <div class="half-row">
                <div class="value-container">
                  <span class="label truncate">Template</span>
                  <div class="value-item">{{ templateName }}</div>
                </div>
              </div>
              <div class="half-row">
                <div class="value-container">
                  <span class="label truncate">File Extension</span>
                  <div class="value-item">{{ fileTypeName }}</div>
                </div>
              </div>
            </div>
            <div class="autocomplete-chip-section">
              <div class="auto-complete-section">
                <div class="select-container">
                  <div class="select-tag">
                    <mat-form-field class="auto-complete" appearance="fill">
                      <mat-label>Tags</mat-label>
                      <input
                        class="custom-input"
                        type="text"
                        matInput
                        [formControl]="tagsAutoCompleteControl"
                        [matAutocomplete]="auto"
                        (blur)="validateTags()"
                        (focus)="tagsFocus($event)"
                      />
                      <span class="custom-arrow mat-select-arrow-wrapper"><span class="mat-select-arrow"></span></span>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagFn">
                        <mat-option *ngFor="let option of filteredByTypingTagOptions | async" [value]="option">
                          {{ option.displayValue }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error
                        *ngIf="
                          !tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync
                        "
                      >
                        Value must be selected from result list.
                      </mat-error>
                    </mat-form-field>
                    <p
                      class="add-new-hyperlink"
                      [ngClass]="{
                        'pt-3':
                          !tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync
                      }"
                    >
                      <span (click)="addNewTags()">Add New Tag</span>
                    </p>
                  </div>
                  <div class="chip-container">
                    <span class="add-chip-btn-section">
                      <button
                        mat-icon-button
                        (click)="onAddNewChip()"
                        aria-label="add chip"
                        [disabled]="!tagsAutoCompleteControl.value || tagsAutoCompleteControl?.errors?.inValidAsync"
                      >
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </span>
                    <div class="tags-chip-list-section">
                      <mat-chip-list *ngIf="tagsChipListControl?.length">
                        <mat-chip
                          *ngFor="let item of tagsChipListControl.controls; index as i"
                          (removed)="onRemoveChip(item.value, i)"
                        >
                          <span class="chip-wrapper">
                            <span class="chip__txt--primary">
                              <span class="mr-1">{{ item.value.displayValue }}</span>
                            </span>
                          </span>
                          <button matChipRemove>
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-chip>
                      </mat-chip-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="document-preview-content">
        <ngx-extended-pdf-viewer
          *ngIf="documentPreviewUrl"
          backgroundColor="#fff"
          [src]="documentPreviewUrl"
          [pageViewMode]="'infinite-scroll'"
          [showToolbar]="false"
          [showSidebarButton]="false"
          [showFindButton]="true"
          [showPagingButtons]="false"
          [showDrawEditor]="false"
          [showStampEditor]="false"
          [showTextEditor]="false"
          [showZoomButtons]="false"
          [showPresentationModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="false"
          [showDownloadButton]="false"
          [showSecondaryToolbarButton]="false"
          [showRotateButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingTemplate>
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </ng-template>
</div>
