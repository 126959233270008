import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FIRST_PAGE, PageEvent, PaginationComponent } from '@ptg-shared/controls/pagination';
import { PaymentInfoNoteType } from '@ptg-shared/constance/value.const';
import * as fromReducer from '@ptg-reducers';

import * as fromPayeeDetail from '../../store/reducers/';
const PAGE_SIZE_CONST = '-ptg-note-memo-detail-pageSize';
import * as fromMember from '../../../../store/reducers';
import {
  checkEditButtonBPAction,
  checkEditButtonPDAction,
  clearGetNotesAction,
  clearNoteMemoState,
  getNotesAction,
} from '../../store';
import { checkEditBPState, checkEditPDState, getNotesState } from '../../store/selectors/note-memo-detail.selector';
import { HeaderBenefit, LocalDate, NoteResponse } from '../../types/models';
import { InstructionHistory, PaymentTab } from '../../services/models';
import { AddNoteComponent } from '../add-note/add-note.component';
import { MatDialog } from '@angular/material/dialog';
import {
  AdjustmentType,
  PayStatus,
  PaymentInfoAdjustmentType,
  PaymentInstructionCorrectionType,
  PaymentInstructionLumpsumType,
  PaymentInstructionType,
  PaymentInstructionTypeLabel,
  PositionPaymentInfoTabDetailedQueryType,
  TabPaymentInfo,
} from '../../types/enums';
import { EditPayableDateComponent } from '../edit-payable-date/edit-payable-date.component';
import { ActivatedRoute } from '@angular/router';
import { ONE_TIME_PAYMENT_INSTRUCTION_TYPE } from '../../types/constants/payment-info-tab.constant';

import { PaymentInstructionDetailComponentService } from '../../pages/payment-instruction-detail/payment-instruction-detail.component.service';
import { getDateString } from '@ptg-shared/utils/string.util';
@Component({
  selector: 'ptg-note-memo-detail',
  templateUrl: './note-memo-detail.component.html',
  styleUrls: ['./note-memo-detail.component.scss'],
})
export class NoteMemoDetailComponent implements OnInit, OnChanges {
  readonly PAY_STATUS = PayStatus;
  readonly TabPaymentInfo = TabPaymentInfo;
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly PaymentInstructionLumpsumType = PaymentInstructionLumpsumType;

  @ViewChild(PaginationComponent) paginator!: PaginationComponent;
  dataNotes: NoteResponse[] = [];
  PaymentInfoNoteType = PaymentInfoNoteType;
  unsubscribe$ = new Subject<void>();
  lengthPg: number | any;
  pageSize: number = 2;
  pageNumber: number = FIRST_PAGE;
  currentFund: any = {};
  displayTitle: string = '';
  noteType!: number;
  isLoading = false;
  asyncConditionShowEditButtonPD = false;
  asyncConditionShowEditButtonBP = false;
  originalPayment: string = '';
  reversalAdjustment: string = '';

  PaymentInfoAdjustmentType = PaymentInfoAdjustmentType;
  PositionPaymentInfoTabDetailedQueryType = PositionPaymentInfoTabDetailedQueryType;
  AdjustmentType = AdjustmentType;
  objDate: LocalDate = {};

  isDisplayAddEditRemoveMemo: boolean = false;
  isPaymentLumpsum: boolean = false;

  @Input() payeeRecordId?: string;
  @Input() entityReferenceLinkedId?: string;
  @Input() paymentInfo?: PaymentTab & InstructionHistory;
  @Input() selectedHeaderBenefit?: HeaderBenefit;
  @Input() selectedTabPayment: TabPaymentInfo = TabPaymentInfo.Payment;
  @Input() payrollRunId: string = '';
  @Input() isLumpsumBenefit?: boolean;

  @Output() onRemoveMemo = new EventEmitter();
  @Output() onAddNoteMemo = new EventEmitter();

  constructor(
    private store: Store<fromMember.MemberState>,
    private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {}

  get isShowEditButtonPD() {
    const isRecurringRecord =
      this.paymentInfo?.paymentType === PaymentInstructionType.Recurring ||
      this.paymentInfo?.paymentType === PaymentInstructionType.AllRecurring;
    const isAcceptedStatus =
      this.paymentInfo?.payStatus !== PayStatus.Terminated && this.paymentInfo?.payStatus !== PayStatus.Cancelled;
    const isFinalizedStatus = this.paymentInfo?.payStatus === PayStatus.Finalized;
    return isAcceptedStatus && !isRecurringRecord && this.asyncConditionShowEditButtonPD && !isFinalizedStatus;
  }

  get isShowEditButtonBP() {
    const isRecurringRecord =
      this.paymentInfo?.paymentType === PaymentInstructionType.Recurring ||
      this.paymentInfo?.paymentType === PaymentInstructionType.AllRecurring;
    const isAcceptedStatus =
      this.paymentInfo?.payStatus !== PayStatus.Terminated && this.paymentInfo?.payStatus !== PayStatus.Cancelled;
    const isAcceptedPaymentType = [
      PaymentInstructionType.InitialPayment,
      PaymentInstructionType.PeriodicLumpsumPayment,
      PaymentInstructionType.Reissue,
      PaymentInstructionType.FinalPayment,
    ].every((type) => this.paymentInfo?.paymentType !== type);
    const isFinalizedStatus = this.paymentInfo?.payStatus === PayStatus.Finalized;
    return (
      isAcceptedStatus &&
      !isRecurringRecord &&
      isAcceptedPaymentType &&
      this.asyncConditionShowEditButtonBP &&
      !isFinalizedStatus
    );
  }

  get isAdjustmentType() {
    return this.paymentInfo?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment;
  }

  get isOneTimePayment() {
    return (
      this.paymentInfo?.originalPaymentInstructionType &&
      ONE_TIME_PAYMENT_INSTRUCTION_TYPE.includes(this.paymentInfo?.originalPaymentInstructionType)
    );
  }

  get originalPaymentText() {
    if (this.isOneTimePayment && this.paymentInfo?.originalPaymentInstructionType) {
      if (PaymentInstructionCorrectionType.includes(this.paymentInfo?.originalPaymentInstructionType)) {
        return 'One-time Correction';
      }
      return this.paymentInfo?.originalPaymentInstructionType
        ? PaymentInstructionTypeLabel[this.paymentInfo?.originalPaymentInstructionType]
        : '';
    }
    return 'Recurring Payroll';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paymentInfo?.currentValue) {
      this.handleDataPaymentInfo();
      this.getData();
      this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = changes.paymentInfo.currentValue.id;
      this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId =
        changes.paymentInfo.currentValue.paymentInstructionHistoryId;
    }
    if ((changes.paymentInfo || changes.selectedHeaderBenefit) && this.paymentInfo && this.selectedHeaderBenefit) {
      const request = {
        paymentInstructionId: this.paymentInfo.id,
        benefitTypeOptionId: this.selectedHeaderBenefit.benefitTypeOptionId,
      };
      this.store.dispatch(checkEditButtonPDAction(request));
      this.store.dispatch(checkEditButtonBPAction(request));
    }
  }

  ngOnInit(): void {
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.currentFund = el;
    });
    this.payeeDetailStore.pipe(select(getNotesState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.payeeDetailStore.dispatch(clearGetNotesAction());
      if (el) {
        this.isLoading = el.isLoading;
        if (el?.payload) {
          this.isDisplayAddEditRemoveMemo = el?.payload.isDisplayAddEditRemoveMemo;
          this.displayTitle = el.payload?.displayTitle;
          this.dataNotes = el.payload?.notes?.map((ele: any) => {
            return {
              ...ele,
              postedDate:
                !ele.postedAt || ele.postedAt.includes('+') || ele.postedAt.includes('Z')
                  ? ele.postedAt
                  : ele.postedAt + 'Z',
              createdBy: `${ele.postedByFirstName ? `${ele.postedByFirstName[0]}.` : ''}  ${ele.postedByLastName}`,
              isTruncate: true,
            };
          });
          this.lengthPg = el.payload.total;
        }
      }
    });

    this.store.pipe(select(checkEditPDState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.asyncConditionShowEditButtonPD = !!el?.payload;
    });

    this.store.pipe(select(checkEditBPState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.asyncConditionShowEditButtonBP = !!el?.payload;
    });
  }

  private handleDataPaymentInfo(): void {
    this.objDate.payableDate = this.paymentInfo?.payableDate ?? '';
    this.objDate.originalCheckDate = getDateString(this.paymentInfo?.originalCheckDate ?? '');
    this.objDate.originalDepositDate = getDateString(this.paymentInfo?.originalDepositDate ?? '');
    this.objDate.revertDate = getDateString(this.paymentInfo?.revertDate ?? '');
    this.isPaymentLumpsum = PaymentInstructionLumpsumType.includes(
      this.paymentInfo?.paymentType as PaymentInstructionType,
    );
  }

  getData() {
    this.payeeDetailStore.dispatch(
      getNotesAction({
        query: {
          pageIndex: this.pageNumber,
          totalPerPage: this.pageSize,
          paymentInstructionHistoryId: this.paymentInfo?.paymentInstructionHistoryId,
          isAdjustment: this.isAdjustmentType,
          payrollRunId: this.payrollRunId,
        },
        paymentInstructionId: this.paymentInfo?.id ?? '',
      }),
    );
  }

  getDataFirstPage() {
    if (this.dataNotes?.length > 0) {
      this.paginator?.jumpToFirst();
    } else {
      this.payeeDetailStore.dispatch(
        getNotesAction({
          query: {
            pageIndex: 1,
            totalPerPage: this.pageSize,
          },
          paymentInstructionId: this.paymentInfo?.id ?? '',
        }),
      );
    }
  }

  changePaging(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }

  onClickRemoveMemo(item: NoteResponse) {
    this.onRemoveMemo.emit(item);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.payeeDetailStore.dispatch(clearNoteMemoState());
  }

  onClickAdd(noteType: number, item?: NoteResponse) {
    this.noteType = noteType;
    const dialogRef = this.dialog.open(AddNoteComponent, {
      panelClass: noteType === PaymentInfoNoteType.Note ? 'add-note-popup' : 'add-memo-popup',
      disableClose: true,
      autoFocus: false,
      data: {
        paymentInstructionId: this.paymentInfo?.id,
        noteType: noteType,
        payStatus: this.paymentInfo?.payStatus,
        memoDetails: item,
        paymentInstructionHistoryId: this.paymentInfo?.paymentInstructionHistoryId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.onAddNoteMemo.emit(result);
      }
    });
  }

  onEditBenefitPeriodPayableDate(isEditPayableDate: boolean) {
    this.dialog.open(EditPayableDateComponent, {
      panelClass: ['edit-popup'],
      autoFocus: false,
      disableClose: true,
      height: 'auto',
      width: '738px',
      data: {
        payableDate: this.paymentInfo?.payableDate,
        paymentInstructionId: this.paymentInfo?.id,
        isEditPayableDate,
        isPaymentSuspend: this.paymentInfo?.isPaymentSuspend,
        benefitPeriod: {
          startDate: this.paymentInfo?.startPeriodDate,
          endDate: this.paymentInfo?.endPeriodDate,
          benefitEntityDataId: this.selectedHeaderBenefit?.benefitEntityDataId,
          benefitTypeOptionId: this.selectedHeaderBenefit?.benefitTypeOptionId,
          payeeEntityRecordId: this.payeeRecordId,
          benefitTypeName: this.selectedHeaderBenefit?.benefitTypeName,
          paymentType: this.paymentInfo?.paymentType,
          benefitCode: this.selectedHeaderBenefit?.benefitId,
          benefitName: this.selectedHeaderBenefit?.benefitName
        },
      },
    });
  }

  navigateToOriginal(
    event: any,
    paramId: {
      paymentInstructionHistoryId?: string;
      adjustmentId?: string;
    },
    queryType: PositionPaymentInfoTabDetailedQueryType = PositionPaymentInfoTabDetailedQueryType.Adjustment,
  ) {
    event.preventDefault();
    if (queryType === PositionPaymentInfoTabDetailedQueryType.PaymentInstructionHistory) {
      this.paymentInstructionDetailComponentService.selectedPaymentInstructionId = '';
      this.paymentInstructionDetailComponentService.selectedPaymentInstructionHistoryId =
        paramId.paymentInstructionHistoryId ?? '';
    }
    this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition(
      {
        ...paramId,
      },
      queryType,
    );
  }
}
