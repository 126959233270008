import { createAction, props } from '@ngrx/store';

import {
  GetEarningFundingSourcesRequest,
  GetEarningFundingSourcesResponse,
  CreateEditEarningItemDetailsRequest,
  GetEarningFundingSourcesQueryParams,
  GetDeductionFundingSourcesResponse,
  SetDeductionsRequest,
} from '../../services/models';

export const getEarningFundingSourcesAction = createAction(
  '[GetEarningFundingSources/API] Send Request',
  props<{ request: GetEarningFundingSourcesRequest; queryParams?: GetEarningFundingSourcesQueryParams }>(),
);

export const getEarningFundingSourcesSuccessAction = createAction(
  '[GetEarningFundingSources/API] Success',
  props<{ response: GetEarningFundingSourcesResponse }>(),
);

export const getEarningFundingSourcesFailureAction = createAction(
  '[GetEarningFundingSources/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetEarningFundingSourcesStateAction = createAction('[GetEarningFundingSources] Clear');

// save edit earning items
export const createEditEarningItemDetailsAction = createAction(
  '[CreateEditEarningItemDetails/API] Send Request',
  props<{ request: CreateEditEarningItemDetailsRequest, isChangeAmountOfTaxable: boolean  }>(),
);

export const createEditEarningItemDetailsSuccessAction = createAction(
  '[CreateEditEarningItemDetails/API] Success',
  props<{ isChangeAmountOfTaxable: boolean  }>(),
);

export const createEditEarningItemDetailsFailureAction = createAction(
  '[CreateEditEarningItemDetails/API] Failure',
  props<{ error?: any }>(),
);

export const clearCreateEditEarningItemDetailsStateAction = createAction('[CreateEditEarningItemDetails] Clear');

// save edit deduction as Funding Source
export const editDeductionAsFundingSourceAction = createAction(
  '[EditDeductionAsFundingSource/API] Send Request',
  props<{ paymentInstructionId: string, request: SetDeductionsRequest }>(),
);
export const editDeductionAsFundingSourceSuccessAction = createAction(
  '[EditDeductionAsFundingSource/API] Success'
);
export const editDeductionAsFundingSourceFailureAction = createAction(
  '[EditDeductionAsFundingSource/API] Failure',
  props<{ error?: any }>(),
);

export const clearEditDeductionAsFundingSourceStateAction = createAction('[EditDeductionAsFundingSource] Clear');