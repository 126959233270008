import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ParametersQuery } from '@ptg-employer/models';
import { ParticipantReportAction } from '@ptg-member/store/actions';
import { GeneratedReport, ReportCategoryName } from '@ptg-member/types/models/participant-report.model';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { uploadFile } from '@ptg-shared/constance/listIcons.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Column, ColumnType, GridComponent } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { takeUntil } from 'rxjs/operators';
import * as fromMember from '../../../store/reducers';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
const PAGE_SIZE_CONST = '-ptg-notification-pageSize';


@Component({
  selector: 'ptg-generated-report',
  templateUrl: './generated-report.component.html',
  styleUrls: ['./generated-report.component.scss']
})
export class GeneratedReportComponent extends BaseComponent {
  @ViewChild('gridGeneratedReport') gridGeneratedReport?: GridComponent<GeneratedReport>;

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Generated List',
      url: ''
    }
  ];
  settings: Breadcrumb[] = [
    {
      name: 'Manage Reports',
      url:'/member/report'
    },
    {
      name: 'Manage Categories',
      url:'/member/report/category'
    } 
  ];

  readonly ACTION_COLUMN = ACTION_COLUMN;

  columns: Column[] = [
    {
      name: 'categoryName',
      header: {
        title: 'Category',
        style: {
          'padding-left': '72px',
        },
      },
      sortable: true,
      truncate: true,
      style: {
        'padding-left': '72px',
      },
    },
    {
      name: 'reportName',
      header: {
        title: 'Report Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'generatedAt',
      header: {
        title: 'Generated at',
      },
      sortable: true,
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy hh:mm a',
      },
    },
    {
      name: 'generatedBy',
      header: {
        title: 'Generated by',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header:{
        title: 'Action'
      },
    }
  ];

  errorMsg?: string = '';
  isLoading: boolean = true;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  totalRecords: number | any;
  sortInfo: {} | any = {};
  currentFund: any = {};
  generatedReport: GeneratedReport[] = [];
  notFoundMessage: string = 'No Report to Display.';
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  reportCategoryName: ReportCategoryName[] = [];
  editedReportName = '';
  
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
  ) {
    super();
    iconRegistry.addSvgIconLiteral('upload-icon', sanitizer.bypassSecurityTrustHtml(uploadFile));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.memberStore.dispatch(ParticipantReportAction.deleteGeneratedReportClear());
    this.getData();

    this.memberStore.pipe(
      select(fromMember.selectGetGeneratedReport),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.generatedReport = state.response.generatedReports;
      if(state.isLoading !== true && state.isSuccess === true) {
        this.generatedReport = state.response.generatedReports.map(obj => {
          return {
            ...obj, 
            generatedAt: this.formatDate(obj.generatedAt)
          };
        })
      }

      this.totalRecords = state.response.total;
      this.isLoading = state.isLoading;
    })

    this.memberStore.pipe(
      select(fromMember.selectDeleteGeneratedReport),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.isLoading = true;

      if (state.action && !state.isLoading) { 
        if (this.generatedReport.length === 1) 
          this.gridGeneratedReport?.paginator?.jumpToFirst();

        this.getData();      
        const options = { 
          customMessage: 
            state.isSuccess === true 
            ? `Report File successfully removed.` 
            : `Error occurred removing Report File. Please try again.` 
        };
        const updateState = state.isSuccess === true ? 'Success': 'Fail';
        showBanner.call(this, updateState, '', state.action, options);
      }
    })
  }

  onChangeSort(event: any) {
    this.sortInfo = event;
    this.isLoading = true;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    let sortType = 1;
    let sortNames = [
      'GeneratedAt'
    ];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: ParametersQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType
    };
    this.memberStore.dispatch(ParticipantReportAction.getGeneratedReportRequest({ query:query }));
  }

  onRemoveClick(row:any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Warning,
        title: 'Confirmation',
        cancelButtonTitle: 'No',
        text: `Are you sure you want to remove this report ?`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeReport(row?.id);
      }
    });
  }

  removeReport(reportId: string) {
    this.memberStore.dispatch(ParticipantReportAction.deleteGeneratedReport({ reportId: reportId }));
  }

  formatDate(input: Date) : Date  {
    return new Date(new Date(input.toString().replace('T', ' ') + ' UTC').toISOString())
  }

  onClickHyperlink(document: any): void {
    this.router.navigateByUrl(
      `/member/report/generated/${document?.id}/${document.fileName}`,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
