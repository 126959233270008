<div class="flex gap-3">
  <div>
    <ng-container [ngSwitch]="inputType">
      <ng-container *ngSwitchCase="ChipInputType.Selection">
        <ptg-select
          [class]="'w-[' + width + ']'"
          [controlField]="selectionCtrl"
          [placeholder]="placeholder"
          [listData]="options"
          [isOptionObj]="true"
        ></ptg-select>
      </ng-container>

      <ng-container *ngSwitchCase="ChipInputType.Autocomplete">
        <mat-form-field *ngIf="autocompleteContext" appearance="fill">
          <mat-label>{{ placeholder }}</mat-label>
          <mat-icon aria-hidden="false" matPrefix>{{ autocompleteContext.icon }}</mat-icon>
          <input
            matInput
            type="text"
            [matAutocomplete]="auto"
            [class]="'search-input' + ' !w-[' + width + ']'"
            [formControl]="selectionCtrl"
            (input)="onSearch()"
          />
          <button
            *ngIf="selectionCtrl.value"
            (click)="clearSearchContent()"
            mat-icon-button
            aria-label="close icon"
            class="x-button"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete #auto="matAutocomplete" class="search-panel" panelWidth="auto" [disableRipple]="true">
            <mat-option
              [disabled]="true"
              *ngIf="
                !autocompleteContext.isSearching &&
                autocompleteContext?.searchResult?.length &&
                selectionCtrl?.value?.length &&
                selectionCtrl.value.length >= autocompleteContext.lengthToSearch! ||
                autocompleteContext.displayAllResult
              "
            >
              <table mat-table [dataSource]="autocompleteContext.searchResult!" class="w-full">
                <ng-container *ngFor="let column of autocompleteContext.columns" [matColumnDef]="column">
                  <th mat-header-cell *matHeaderCellDef [hidden]="true"></th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container
                      [ngTemplateOutlet]="defaultCellContent"
                      [ngTemplateOutletContext]="{
                        row: element[column],
                        searchContent: selectionCtrl.value
                      }"
                    ></ng-container>

                    <ng-template #defaultCellContent>
                      <span [innerHTML]="element[column] | customBold: selectionCtrl.value && autocompleteContext.lengthToSearch && (selectionCtrl.value.length >= autocompleteContext.lengthToSearch) ? selectionCtrl.value : ''"></span>
                    </ng-template>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="autocompleteContext.columns!"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: autocompleteContext.columns!"
                  (click)="onClickSelectItem(row)"
                ></tr>
              </table>
            </mat-option>

            <mat-option
              class="message-option"
              *ngIf="
                autocompleteContext?.searchResult?.length === 0 &&
                selectionCtrl?.value?.length &&
                selectionCtrl.value.length >= autocompleteContext.lengthToSearch!
              "
            >
              {{
                autocompleteContext.isSearching
                  ? 'Searching...'
                  : autocompleteContext.isSearchError
                    ? 'Error occurred while searching.'
                    : 'No Record Found.'
              }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </ng-container>

    <mat-error *ngIf="controlField?.touched && controlField?.errors?.required" class="pl-3 mb-2">
      {{ placeholder + ' is required.' }}
    </mat-error>
    <mat-error
      *ngIf="controlField?.errors?.[customError] && !controlField?.errors?.required"
      [class]="'w-[' + width + '] pl-3 mb-2'"
    >
      {{ controlField.getError([customError]) }}
    </mat-error>
  </div>

  <span class="btn-add-item">
    <button
      aria-label="add chip button"
      mat-icon-button
      [disabled]="
        selectionCtrl.value === null ||
        selectionCtrl.value === undefined ||
        selectionCtrl.value === '' ||
        (inputType === ChipInputType.Autocomplete && !selectedItemValue)
      "
      (click)="onClickAddItem()"
    >
      <mat-icon>add_circle</mat-icon>
    </button>
  </span>

  <div class="mt-2">
    <mat-chip-list *ngIf="selectedOptions.length">
      <mat-chip *ngFor="let item of selectedOptions" (removed)="onClickRemoveItem(item)">
        <span class="chip-wrapper">
          <span class="chip__txt--primary">
            <ng-container
              *ngIf="chipItemTemplate; else normalChipItem"
              [ngTemplateOutlet]="chipItemTemplate.templateRef"
              [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
            <ng-template #normalChipItem>
              {{ item.displayValue }}
            </ng-template>
          </span>
        </span>

        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
