import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { Sort } from '@angular/material/sort';
import { SORT_TYPE } from '@ptg-shared/constance';
import { ActivatedRoute, Router } from '@angular/router';


import { takeUntil } from 'rxjs/operators';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { AddPaymentHeaderConfigurationComponent } from '../../components/add-payment-header-configuration/add-payment-header-configuration.component';
import { PayeeDetailState } from '../../store/reducers';
import { clearPaymentHeaderConfigurationState, createPaymentHeader, getHeaderList, selectPaymentHeaderConfigurationList } from '../../store';
import { GetPaymentHeaderConfigurationRequest, PaymentHeaderConfiguration } from '../../types/models';
import { PaymentInstructionDetailComponentService } from '../payment-instruction-detail/payment-instruction-detail.component.service';

const PAGE_SIZE_CONST = '-ptg-payment-header-configuration-pagesize'


@Component({
  selector: 'ptg-payment-header-configuration',
  templateUrl: './payment-header-configuration.component.html',
  styleUrls: ['./payment-header-configuration.component.scss'],
  providers:[PaymentInstructionDetailComponentService]
})
export class PaymentHeaderConfigurationComponent extends BaseComponent {

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Payment Info',
      url: '/member',
    },
    {
      name: 'Payment Header Configuration',
    },
  ];

  columns: Column[] = [
    {
      name: 'default',
      header: {
        title: '',
      },
      width: '72px',
    },
    {
      name: 'benefitType',
      header: {
        title: 'Benefit Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'entity',
      header: {
        title: 'Entity',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'totalProperties',
      header: {
        title: 'Total Properties',
      },
      type: ColumnType.Decimal,
      templateArgs: { 
        decimal: 0 
      },
      truncate: true,
      sortable: true,
      width: '300px',
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '300px',
    },
  ];

  sortInfo?: Sort;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  crrHeaderDeleteID?: string | null;

  headersData: (PaymentHeaderConfiguration & Row)[] = [];
  isLoading: boolean = true;

  currentFund: any = {};
  total: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  menuId: string = '';
  viewId: string = '';
  id: string = '';
  extendsUrl?: string;
  constructor(
    public dialog: MatDialog,
    public router: Router,
    private store: Store<fromReducer.State>,
    private payeeDetailStore: Store<PayeeDetailState>,
    public route: ActivatedRoute,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
        this.getData();
      });

    this.payeeDetailStore.pipe(select(selectPaymentHeaderConfigurationList), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoading = data?.isLoading;
        if (data.success) {
          this.headersData = data?.payload?.headerConfigurations ?? [];
          this.total = data?.payload?.total;
        }
      }  
    });

    this.payeeDetailStore.pipe(select(createPaymentHeader), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success && data?.payload) {
        this.getData();
      }
    });

    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.getRouteData();
  }

  getRouteData() {
    const params = this.route.snapshot.params;
    const queryParams = this.route.snapshot.queryParams;
    this.menuId = params.menuId;
    this.id = params.id;
    this.viewId = params.viewId;
    this.extendsUrl = this.paymentInstructionDetailComponentService.getQueryParams(queryParams);
    this.listBreadcrumbs[0].url = `/member/system-view/payment-information/${this.menuId}/${this.viewId}/${this.id}${this.extendsUrl}`;
  }  

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.payeeDetailStore.dispatch(clearPaymentHeaderConfigurationState());
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  openForm(): void {
    this.dialog.open(AddPaymentHeaderConfigurationComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '847px',
      height: 'auto',
      data: {
        menuId: this.menuId,
        viewId: this.viewId,
        id: this.id
      },
    });
  }

  handleEdit(row?: Row): void {
    this.router.navigate(
      [`/member/system-view/payment-header-configuration/${this.menuId}/${this.viewId}/${this.id}/${row?.id}`],
      { queryParamsHandling: 'merge' },
    );
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }

  getData(): void {
    let query: GetPaymentHeaderConfigurationRequest = {
      sortField: '',
      sortType: 0,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      query = {
        ...query,
        sortField: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SORT_TYPE.ASC : SORT_TYPE.DESC,
      };
    }
    this.payeeDetailStore.dispatch(getHeaderList({ query: query }));
  }

}
