<div class="dialog-container">
    <div class="header-title">Add New Header</div>
    <form class="edit-form" [formGroup]="editForm">
      <div class="input-container">
        <ptg-select
            [controlField]="editForm.get('benefitTypeId')"
            [listData]="listBenefitType"
            [isOptionObj]="true"
            placeholder="Benefit Type"
            [errorAsync]="'Header for this Benefit Type and Entity already exists.'"
            (changeOptionValue)="onSourceChangedValue()"
            [isSetMaxWidthSelect]="true"
        ></ptg-select>
        <ptg-select
            [controlField]="editForm.get('entityId')"
            [listData]="listEntity"
            [isOptionObj]="true"
            placeholder="Entity"
            [errorAsync]="'Header for this Benefit Type and Entity already exists.'"
            (changeOptionValue)="onSourceChangedValue()"
            [isSetMaxWidthSelect]="true"
        ></ptg-select>
      </div>
  
      <div class="row-button">
        <button
          mat-raised-button
          type="button"
          class="primary"
          (click)="formSubmit$.next(true)"
        >
          Create & Continue
        </button>
        <button
          mat-raised-button
          type="button"
          class="secondary"
          (click)="formSubmit$.next(false)"
        >
          Create & Add Another
        </button>
        <button
          mat-raised-button
          type="button"
          class="cancel-button"
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
  