import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as TierConfigurationActions from '../../store/actions/tier-configuration.actions';
import {
  BinaryValuesResponse,
  ConditionLookupResponse,
  ConditionOperatorsResponse,
  ConditionPropertiesResponse,
  GetTierConfigurationsResponse,
  PlanResponse,
} from '../../types/models';
import { TierConfigurationService } from '../../services/tier-configuration.service';

@Injectable()
export class TierConfigurationEffects {
  constructor(
    private actions$: Actions,
    private tierConfigurationService: TierConfigurationService
  ) {}

  getTierConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.getTierConfiguration),
      switchMap(({ query }) => {
        return this.tierConfigurationService.getTierConfiguration(query).pipe(
          map((response: GetTierConfigurationsResponse) => {
            return TierConfigurationActions.getTierConfigurationSuccess({response});
          }),
          catchError((err) => {
            return of(
              TierConfigurationActions.getTierConfigurationFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );

  editTierConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.editTierConfiguration),
      switchMap(({ body, query }) => {
        return this.tierConfigurationService
          .editTierConfiguration(body, query)
          .pipe(
            map(() => {
              return TierConfigurationActions.editTierConfigurationsSuccess();
            }),
            catchError((err) => {
              return of(
                TierConfigurationActions.editTierConfigurationsFailure({
                  error: err.message,
                })
              );
            })
          );
      })
    )
  );
  setTierConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.setTierConfiguration),
      switchMap(({ body }) => {
        return this.tierConfigurationService.setTierConfiguration(body).pipe(
          map(() => {
            return TierConfigurationActions.setTierConfigurationsSuccess();
          }),
          catchError((err) => {
            return of(
              TierConfigurationActions.setTierConfigurationsFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );

  getTierConfigurationPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.getTierConfigurationPlan),
      switchMap(() => {
        return this.tierConfigurationService.getTierConfigurationPlan().pipe(
          map((plans: PlanResponse) => {
            return TierConfigurationActions.getTierConfigurationPlanSuccess({
              plans,
            });
          }),
          catchError((err) => {
            return of(
              TierConfigurationActions.getTierConfigurationPlanFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );

  getTierConfigurationMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.getTierConfigurationMetadata),
      switchMap(() => {
        return this.tierConfigurationService
          .getTierConfigurationMetadata()
          .pipe(
            map((properties: ConditionPropertiesResponse) => {
              return TierConfigurationActions.getTierConfigurationMetadataSuccess(
                { properties }
              );
            }),
            catchError((err) => {
              return of(
                TierConfigurationActions.getTierConfigurationMetadataFailure({
                  error: err.message,
                })
              );
            })
          );
      })
    )
  );

  getTierConfigurationOperator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.getTierConfigurationOperator),
      mergeMap(({ query }) => {
        return this.tierConfigurationService
          .getTierConfigurationOperator(query)
          .pipe(
            map((operator: ConditionOperatorsResponse) => {
              return TierConfigurationActions.getTierConfigurationOperatorSuccess(
                { operator }
              );
            }),
            catchError((err) => {
              return of(
                TierConfigurationActions.getTierConfigurationOperatorFailure({
                  error: err.message,
                })
              );
            })
          );
      })
    )
  );

  getTierConfigurationConditionLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.getTierConfigurationConditionLookup),
      mergeMap(({ query }) => {
        return this.tierConfigurationService
          .getTierConfigurationConditionLookup(query)
          .pipe(
            map((result: ConditionLookupResponse) => {
              return TierConfigurationActions.getTierConfigurationConditionLookupSuccess(
                { result }
              );
            }),
            catchError((err) => {
              return of(
                TierConfigurationActions.getTierConfigurationConditionLookupFailure(
                  { error: err.message }
                )
              );
            })
          );
      })
    )
  );

  getTierConfigurationBinaryValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TierConfigurationActions.getTierConfigurationBinaryValues),
      mergeMap(({ query }) => {
        return this.tierConfigurationService
          .getTierConfigurationBinaryValues(query)
          .pipe(
            map((resultBinary: BinaryValuesResponse) => {
              return TierConfigurationActions.getTierConfigurationBinaryValuesSuccess(
                { resultBinary }
              );
            }),
            catchError((err) => {
              return of(
                TierConfigurationActions.getTierConfigurationBinaryValuesFailure(
                  { error: err.message }
                )
              );
            })
          );
      })
    )
  );
}
