import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateTime } from 'luxon';

import {
  DIRECT_DEPOSIT_PROPERTY_KEYS,
  FIXED_SECTION_KEY,
  LookupTableType,
} from '@ptg-employer/constance/employer-filter.const';
import { EmployerFilter } from '@ptg-employer/models/employer-filter.model';
import { MemberFilter } from '@ptg-member/types/models';
import {
  FIXED_PROPERTIES,
  FilterPropertyType,
  OPERATOR_LABEL,
  Operator,
} from '@ptg-member/constance/member-list.const';

import { deepClone, formatUtcDateString } from '../../utils/common.util';
import { MunicipalityFixedProperty } from '@ptg-shared/constance/fixed-property.const';

export interface ChipData {
  contents: ContentConfig[];
}

export interface ContentConfig {
  value: string;
  className:
    | 'normal'
    | 'italic'
    | 'colored'
    | 'normal not-space-after'
    | 'colored not-space-after';
}

@Component({
  selector: 'ptg-view-current-filter',
  templateUrl: './view-current-filter.component.html',
  styleUrls: ['./view-current-filter.component.scss'],
})
export class ViewCurrentFilterComponent implements OnInit, OnChanges {
  @Input() propertyItemLabel: Record<string, string> = {
    Prefix: 'Salutation',
    First: 'First Name',
    Middle: 'Middle Name',
    Last: 'Last Name',
    Suffix: 'Suffix',
    Street1: 'Street 1',
    Street2: 'Street 2 (Apartment No./Suite No.)',
    City: 'City',
    State: 'State/Province/Territory',
    ZipCode: 'Zip Code/Postal Code',
    Country: 'Country',
  };

  @Input() currentFilters: MemberFilter[] | EmployerFilter[] = [];
  @Input() canRemove: boolean = false;

  @Output() removed: EventEmitter<MemberFilter | EmployerFilter> =
    new EventEmitter<MemberFilter | EmployerFilter>();

  listChipData: ChipData[] = [];

  constructor() {}

  ngOnInit(): void {}

  removeFilter(index: number) {
    this.listChipData.splice(index, 1);
    this.removed.emit(this.currentFilters[index]);
  }

  getChipData(value: MemberFilter | EmployerFilter): ChipData {
    const filter = deepClone(value) as MemberFilter | EmployerFilter;
    switch (filter.type) {
      case FilterPropertyType.Status: {
        let result: ContentConfig[] = [];
        const statusName = value.values?.[0];
        const statusEvent = (value as any).event;
        const statusDate = (value as any).date;
        if (statusName || (value as any).operator == Operator.IsAny) {
          if ((value as any).operator == Operator.Equals) {
            result.push({ value: 'Status', className: 'normal' });
            result.push({ value: 'is', className: 'italic' });
            result.push({ value: statusName.toString(), className: 'colored' });
          }
          if ((value as any).operator == Operator.NotEquals) {
            result.push({ value: 'Status', className: 'normal' });
            result.push({ value: 'is not', className: 'italic' });
            result.push({ value: statusName.toString(), className: 'colored' });
          }
          if ((value as any).operator == Operator.IsAny) {
            result.push({ value: 'Any Status', className: 'normal' });
          }

          if (statusEvent) {
            if (statusEvent.operator == Operator.NotEquals) {
              result.push({ value: '(', className: 'normal not-space-after' });
              result.push({ value: 'with', className: 'italic' });
              result.push({ value: 'Status Event', className: 'normal' });
              result.push({ value: 'is not', className: 'italic' });
              result.push({
                value: statusEvent.values[0].toString(),
                className: 'colored not-space-after',
              });
              result.push({ value: ')', className: 'normal' });
            }
            if (statusEvent.operator == Operator.Equals) {
              result.push({ value: '(', className: 'normal' });
              result.push({
                value: statusEvent.values[0].toString(),
                className: 'colored',
              });
              result.push({ value: ')', className: 'normal' });
            }
          }
          if (statusDate) {
            switch (statusDate.operator) {
              case Operator.Between:
                result = [
                  ...result,
                  { value: 'from', className: 'italic' },
                  {
                    value: formatUtcDateString(statusDate.values[0] as string),
                    className: 'colored',
                  },
                  { value: 'to', className: 'italic' },
                  {
                    value: formatUtcDateString(statusDate.values[1] as string),
                    className: 'colored',
                  },
                ];
                break;
              case Operator.IsCurrentStatus:
                result = [
                  ...result,
                  { value: 'on', className: 'italic' },
                  {
                    value: formatUtcDateString(statusDate.values[0] as string),
                    className: 'colored',
                  },
                ];
                break;
              case Operator.WithinLastDay:
                result = [
                  ...result,
                  { value: 'within last', className: 'italic' },
                  {
                    value: statusDate.values[0].toString(),
                    className: 'colored',
                  },
                  { value: 'days', className: 'italic' },
                ];
                break;
              case Operator.AtAnyPoint:
                result = [
                  ...result,
                  { value: 'at any point', className: 'italic' },
                ];
                break;
            }
          }
        }
        return { contents: result };
      }
      case FilterPropertyType.Date: {
        filter.values = filter.values.map((item) =>
          formatUtcDateString(item as string)
        );
        break;
      }
      case FilterPropertyType.DateTime: {
        filter.values = filter.values.map((item) =>
          DateTime.fromISO(item as string).toFormat('MM/dd/yyyy hh:mm a')
        );
        break;
      }
    }
    let prefix = '';
    if (filter.sectionKey === FIXED_SECTION_KEY.CONTACT) {
      prefix = 'Primary ';
    } else if (
      filter.sectionKey === FIXED_SECTION_KEY.PAYMENT_PREFERENCES &&
      DIRECT_DEPOSIT_PROPERTY_KEYS.includes(filter.propertyKey)
    ) {
      prefix = 'Direct Deposit - ';
    }
    filter.selectedPropertyItem = `${prefix}${
      filter.propertyName || filter.propertyKey
    }${
      filter.options && filter.type !== FilterPropertyType.Boolean
        ? ` - ${this.propertyItemLabel[filter.options || '']}`
        : ''
    }`;
    let operatorName = OPERATOR_LABEL[Operator[filter.operator]];
    if (
      [FilterPropertyType.Date, FilterPropertyType.DateTime].includes(
        filter.type
      ) &&
      ['GreaterThan', 'LessThan'].includes(Operator[filter.operator])
    ) {
      operatorName = OPERATOR_LABEL['Date' + Operator[filter.operator]];
    }
    if (
      ['registered', 'IsLocked', 'paperlessOptedIn', 'smsOptedIn'].includes(
        filter.propertyKey
      )
    ) {
      return {
        contents: [
          { value: filter.selectedPropertyItem, className: 'normal' },
          { value: operatorName, className: 'italic' },
          {
            value:
              filter.operator !== Operator.Equals
                ? ''
                : filter.values[0]
                ? 'Yes'
                : 'No',
            className: 'colored',
          },
        ],
      };
    }

    // filter municipality id
    if (filter.propertyKey === FIXED_PROPERTIES.MunicipalityId.PropertyKey) {
      operatorName = OPERATOR_LABEL.Equals;
    }

    switch (filter.operator) {
      case Operator.Between: {
        return {
          contents: [
            { value: filter.selectedPropertyItem, className: 'normal' },
            { value: operatorName, className: 'italic' },
            { value: filter.values[0].toString(), className: 'colored' },
            { value: 'and', className: 'italic' },
            { value: filter.values[1].toString(), className: 'colored' },
          ],
        };
      }
      case Operator.IsEmpty:
      case Operator.IsNotEmpty: {
        return {
          contents: [
            { value: filter.selectedPropertyItem, className: 'normal' },
            { value: operatorName, className: 'italic' },
          ],
        };
      }
      default: {
        return {
          contents: [
            { value: filter.selectedPropertyItem, className: 'normal' },
            { value: operatorName, className: 'italic' },
            { value: this.getFilterValue(filter), className: 'colored' },
          ],
        };
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentFilters) {
      this.listChipData = deepClone(this.currentFilters).map(
        (filter: MemberFilter | EmployerFilter) => this.getChipData(filter)
      );
      this.addCustomStatusChipWhenFilterByMunicipality(this.currentFilters);
    }
  }

  getFilterValue(filter: MemberFilter | EmployerFilter) {
    if (!filter.optionValues?.length ||
        filter.options === 'ZipCode' ||
        filter.options === 'Street1' ||
        filter.options === 'Street2' ||
        filter.options === 'City' ||
        filter.propertyName === MunicipalityFixedProperty.CertificationStatus) {
      return filter.values[0]?.toString();
    }
    return (
      filter.optionValues.find(o => {
        if(filter.type !== FilterPropertyType.Boolean) {
          return o.id === filter.values[0]?.toString() || o.code === filter.values[0];
        }
        return (filter.values[0] && o.type === LookupTableType.YesValue) || (!filter.values[0] && o.type === LookupTableType.NoValue)
      })?.text
    ) || '';
  }

  addCustomStatusChipWhenFilterByMunicipality(currentFilters: MemberFilter[] | any) {
    const muniIdFilter = currentFilters.find((filter: MemberFilter) => filter.propertyKey === FIXED_PROPERTIES.MunicipalityId.PropertyKey);
    const statusValue = muniIdFilter?.values?.[0]?.toString()?.split('|')?.[1];
    if (muniIdFilter && statusValue) {
      this.listChipData.push({
        contents: [
          { value: FIXED_PROPERTIES.Status.PropertyName, className: 'normal' },
          { value: OPERATOR_LABEL.Equals, className: 'italic' },
          { value: statusValue, className: 'colored' },
        ],
      });
    }
  }
}
