<div class="entity-header" id="entity-header-page">
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" (onAdd)="openForm()"
    buttonAddLabel="New Header"></ptg-breadcrumb>
    <div class="banner" *ngIf="bannerType">
      <ptg-banner
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>
    </div>
  
    <ptg-grid
      [paginable]="true"
      [isLoading]="isLoading"
      [data]="headersData"
      [columns]="columns"
      [fitToParent]="true"
      [totalRecords]="total"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      (pageChange)="onChangePage($event)"
      (sortChange)="sortChange($event)"
      notFoundMessage="No Header to Display"
    >
      <ng-template cellContent columnName="action" let-row>
        <ptg-button
          buttonName="Edit"
          classInput="edit-button"
          (clickButton)="handleEdit(row)"
        >
          <mat-icon>edit</mat-icon>
        </ptg-button>
        <ptg-button
          buttonName="Remove"
          classInput="remove-button"
        >
          <mat-icon>delete_forever</mat-icon>
        </ptg-button>
      </ng-template>
    </ptg-grid>
  </div>
  