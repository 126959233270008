<div class="dialog-container">
  <h1 mat-dialog-title>{{ data.label }}</h1>
  <div mat-dialog-content>
    <ng-container *ngIf="data.label === 'Memo'">
        <ng-container *ngFor="let checkMemo of data.transaction.checkMemos">
          <ng-container [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ title: 'Check Memo', content: checkMemo.memo, createdTime: checkMemo.date}"></ng-container>
        </ng-container>
        <ng-container *ngFor="let memo of data.transaction.memo">
          <ng-container [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ title: 'Memo', content: memo.note, createdTime: memo.postedAt}"></ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="data.label === 'Justification'">
      <ng-container *ngIf="data.transaction.justification" [ngTemplateOutlet]="rowTemplate"
        [ngTemplateOutletContext]="{ title: 'Justification', content: data.transaction.justification, createdTime: data.transaction.createdTime }"></ng-container>
    </ng-container>
    <ng-container *ngIf="data.label === 'Addenda'">
      <ng-container *ngIf="data.transaction.addendaValue" [ngTemplateOutlet]="rowTemplate"
        [ngTemplateOutletContext]="{ content: data.transaction.addendaValue }"></ng-container>
    </ng-container>
  </div>
  <ng-template #rowTemplate let-title="title" let-content="content" let-createdTime="createdTime">
    <div class="row-content">
      <div class="subtitle" *ngIf="title">
        {{ title }}
        <ng-container *ngIf="createdTime">
          {{ createdTime | dateFormat }}
        </ng-container>
      </div>
      <div class="content">{{ content }}</div>
    </div>
  </ng-template>
  <div mat-dialog-actions align="end">
    <button mat-button mat-raised-button mat-dialog-close class="close-button">
      Close
    </button>
  </div>
</div>
