<div class="lookup-table-list" id="lookup-table-list-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [functionButtons]="functionButtons"
    (emitFunction)="emitFunction($event)"
  ></ptg-breadcrumb>

  <div *ngIf="bannerType" class="p-6">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <ptg-grid
    [data]="lookupTablesData"
    [columns]="columns"
    keyColumn="id"
    [isLoading]="isLoading"
    [errorMessage]="errorMsg"
    [fitToParent]="true"
    [paginable]="false"
    (sortChange)="sortChange($event)"
    notFoundMessage="No Lookup Table to Display"
  >
    <ng-template cellContent columnName="active" let-row>
      <mat-icon *ngIf="!row.active" class="icon-inactive">domain_disabled</mat-icon>
    </ng-template>
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="viewDetail(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
