export * from './person-property-mapping.constant';
export * from './entity-mapping.constant';
// TODO Move to other constant files
import { FieldData } from '@ptg-shared/types/models';

export const MODULE_KEY = 'entity-management-module';

export const IDENTIFIER_PATTERN_DEFAULT_VALUE = '#######';

export const FixedPropertyKey = {
  DateOfDeath: 'dateOfDeath',
  LineOfDutyDeath: 'lineOfDutyDeath',
  NewHireExam: 'newHireExam',
  NewHireExamDate: 'newHireExamDate',
  RetireRehire: 'retireRehire',
  RelationShip: 'relationship',
  Payee: 'payee',
  CourtOrderType: 'courtOrderType',
  StatusCourtOrder: 'statusCourtOrder',
  RejectReason: 'rejectReason',
  BlankSpace: 'blankSpace',
};

export const LIMIT_PHONE_NUMBER = new RegExp(/^(\d{3})(\d{3})(\d{4})$/);

export const LIST_INPUT_ADDRESS: FieldData[] = [
  new FieldData('street1', 'Street1', 'Street 1', 'text', true, '46', 'w380'),
  new FieldData('street2', 'Street2', 'Street 2 (Apartment No./Suite No.)', 'text', false, '46', 'w380'),
  new FieldData('city', 'City', 'City', 'text', true, '46', 'w182'),
  new FieldData('state', 'State', 'State/Province/Territory', 'select', true, 'none', 'w182'),
  new FieldData('zipCode', 'ZipCode', 'Zip Code/Postal Code', 'text', true, '10', 'w182'),
  new FieldData('country', 'Country', 'Country', 'select', true, 'none', 'w182'),
  new FieldData('effectFrom', 'EffectFrom', 'Effective From', 'date', true, 'none', 'w380'),
  new FieldData('effectTo', 'EffectTo', 'Effective To', 'date', false, 'none', 'w380'),
];
export const LIST_INPUT_PERSON_NAME: FieldData[] = [
  new FieldData('prefix', 'Prefix', 'Prefix', 'select', false, 'none', 'w167'),
  new FieldData('first', 'First', 'First Name', 'text', true, 'none', 'w152 first-name'),
  new FieldData('middle', 'Middle', 'Middle Name', 'text', false, 'none', 'w152'),
  new FieldData('last', 'Last', 'Last Name', 'text', true, 'none', 'w152 last-name'),
  new FieldData('suffix', 'Suffix', 'Suffix', 'text', false, 'none', 'w89'),
];
export const LIST_INPUT_STATUS: FieldData[] = [
  new FieldData('status', 'Status', 'Status', 'select', true, 'none', 'w167'),
  new FieldData('event', 'Event', 'Event', 'select', true, 'none', 'w167'),
];

export const StatusCourtOrder = {
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Terminated: 'Terminated',
};

export const COURT_ORDER_TYPE_CODE = {
  Garnishment: 'GNM',
  QDRO: 'QDR',
};

export const UnitedStates = 'USA';

export const PERSON_BASE_PROPERTIES = {
  Name: '0AC1B906-C396-4BC5-A83B-EEC542482445',
  DateOfBirth: '220E5FCB-9A56-4C89-88B2-C717F6DF2B5E',
  DateOfDeath: '92EB0499-7C10-485F-B9F1-757F8F9C1632',
  MaritalStatus: '1FA73B73-7C1C-4BA8-93A5-782429C3EFDD',
  Gender: '5F4D07BE-F13B-4C9C-99AA-CE9B228F07C7',
  Phone: '94566AA1-5318-4FC3-B89C-3D683AD8CB5F',
  Email: '00FABCB5-D468-460B-BEBB-23F06178DC19',
  PrimaryAddress: 'A499344F-F95C-43B2-B716-516C8AC3754B',
  SSN: '4BE56F09-EA2F-4DCC-8C53-5E44707A124B',
  SSNHash: '4BE56F09-EA2F-4DCC-8C53-5E44707A124B',
  Age: 'C171F9AA-BBDD-4DAF-81DD-C5C54CA62889',
  ReportedDeceased: 'B1959435-6FE3-4EC8-A067-D6C263298868',
};

export enum FixedPropertyId {
  CaseNumber = 'C069DB2F-F7F0-4032-86B5-58A5FA4D5FBC',
}

export const AUDIT_TRAIL_PROPERTIES = {
  Reason: { Key: 'reason', Label: 'Reason' },
}
