import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers/index';
import {
  AddSectionBody,
  BodyChangeKey,
  CheckExist,
  MetadataOverview,
  MetadataPropertyTypeView,
  MetadataSectionsNonListResponse,
  MetadataView,
  PropertyNavType,
} from '../types/models';
import { EntityViewItems } from '@ptg-entity-management/services/models';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  activeFund: any;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  getMetadata(query?: any): Observable<MetadataView> {
    // let params = new HttpParams();
    // if (query && query.sortField) {
    //   params = params.append(
    //     'SortNames',
    //     query.sortField[0].toUpperCase() + query.sortField.substring(1)
    //   );
    //   params = params.append('SortType', query.sortType ? query.sortType : 0);
    // }
    // if (query?.screenId) {
    //   params = params.append('screenId', query?.screenId);
    // }
    // return this.httpClient.get<MetadataView>(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata`,
    //   { params }
    // );
    return of();
  }

  getMetadataNonList(
    memberNavigationItemId?: string
  ): Observable<MetadataSectionsNonListResponse> {
    // let params = new HttpParams();
    // if (memberNavigationItemId) {
    //   params = params.append('MemberNavigationItemId', memberNavigationItemId);
    // }
    // return this.httpClient.get<MetadataSectionsNonListResponse>(
    //   `${environment.apiUrl}/Metadata/${this.activeFund?.key}/MetadataNonList`,
    //   { params }
    // );

    // TODO remove when complete module entity
   return of();
  }

  orderTable(body: BodyChangeKey) {
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${body.reorderItemKey}/Order`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  checkNewSectionExist = (bodyCheck: CheckExist) => {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Clients/Metadata/${this.activeFund?.key}/Exists/AddNewMetadata`,
    //   bodyCheck
    // );

    // TODO remove when complete module entity
   return of();
  };

  addSection(body: AddSectionBody) {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Metadata/${this.activeFund?.key}/AddNew`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  removeSection(metadataKey: string) {
    // return this.httpClient.delete(
    //   `${environment.apiUrl}/Metadata/${this.activeFund?.key}/${metadataKey}/Delete`
    // );

    // TODO remove when complete module entity
   return of();
  }

  getMetadataPropertyTypes(
    type: PropertyNavType
  ): Observable<MetadataPropertyTypeView> {
    // return this.httpClient.get<MetadataPropertyTypeView>(
    //   `${environment.apiUrl}/Metadata/PropertyTypes/${type.navType}`
    // );

    // TODO remove when complete module entity
   return of();
  }

  getMetadataItemByProfileOverviewConfig(
    memberId: string, screenId?: string
  ): Observable<MetadataOverview> {
    // let params = new HttpParams();
    // if(screenId) {
    //   params = params.append('screenId', screenId);
    // }
    // return this.httpClient.get<MetadataOverview>(
    //   `${environment.apiUrl}/Metadata/GetMetadataItemByProfileOverviews/${memberId}`, { params }
    // );

    // TODO remove when complete module entity
   return of();
  }

  getViewConfig(viewId: string): Observable<EntityViewItems> {
    return this.httpClient.get<EntityViewItems>(
      `${environment.apiUrl}/entity/views/${viewId}/cards`
    );
  }

  
}
