<div *ngIf="!isLoading; else notLoading" class="deductions-container">
  <div class="control-bar">
    <span class="name"> Deductions </span>
    <div
      *ngIf="
        selectedRow?.payStatus !== PayStatus.Finalized &&
        deductionsInfo?.isEditable &&
        selectedTabPayment === TabPaymentInfo.Payment
      "
      class="edit-btn"
      (click)="onClickEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
    <!-- Out scope in release 1 -->
    <!-- <div
      *ngIf="
        selectedTabPayment === TabPaymentInfo.InstructionHistory &&
        selectedRow?.paymentInstructionType !== PaymentInstructionType.Adjustment
      "
      class="edit-btn"
    >
      <mat-icon>history</mat-icon>
      <span class="title-edit">Audit</span>
    </div> -->
  </div>

  <div class="list-item-grid">
    <div class="list-item-content">
      <div
        *ngIf="selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        class="list-item-content__content--field"
      >
        <div class="label-header">Name</div>
      </div>
      <div
        *ngIf="selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment; else adjustment"
        class="list-item-content__content--field"
      >
        <div class="label-header align-right">Current</div>
      </div>
      <ng-template #adjustment>
        <div class="list-item-content__content--field amount-adjustment">
          <div class="label-header align-right">Amount</div>
        </div>
      </ng-template>
      <div
        *ngIf="selectedRow.payStatus === PayStatus.Finalized && isHavingAdjustments"
        class="list-item-content__content--field"
      >
        <div class="label-header align-right">Net Amount</div>
      </div>
      <div
        *ngIf="selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        class="list-item-content__content--field"
      >
        <div class="label-header align-right">YTD</div>
      </div>
    </div>

    <ng-container *ngFor="let arrData of [deductions, deductionsQDRO]">
      <div *ngFor="let ele of arrData; last as isLast">
        <div class="list-item-content item-info">
          <ng-template #customTooltip>
            <div
              *ngIf="
                ele.deductionType === DeductionTypeEnum.Garnishment || ele.deductionType === DeductionTypeEnum.QDRO
              "
            >
              Court Order: <b>{{ ele.courtOrder }}</b>
            </div>
            <div
              *ngIf="
                ele.deductionType === DeductionTypeEnum.Garnishment || ele.deductionType === DeductionTypeEnum.QDRO
              "
            >
              Payee: <b>{{ ele.payeeName }}</b>
            </div>
            <div *ngIf="ele.deductionType === DeductionTypeEnum.Adjustment">
              Total Limit: <b>{{ ele.totalLimit || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</b>
            </div>
            <div *ngIf="ele.deductionType === DeductionTypeEnum.Adjustment">
              Remaining Balance:
              <b>{{ ele.remainingBalance || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</b>
            </div>
            <div *ngIf="!isHiddenEffectiveDate">
              Effective Date:
              <b
                >{{ ele.startDate | date: 'MM/dd/yyyy' }} {{ ele.endDate ? '-' : '' }}
                {{ ele.endDate | date: 'MM/dd/yyyy' }}</b
              >
            </div>
          </ng-template>
          <div class="list-item-content__content--field">
            <div class="label truncate first-child" [tooltip]="shouldShowTooltip(ele) ? customTooltip : null">
              {{ ele.labelDisplay }}
            </div>
          </div>
          <div class="list-item-content__content--field">
            <div class="content align-right">
              {{
                (ele.isActive ? ele.amount?.amountCurrent || 0 : 0)
                  | numberLocalDecimal: '$' : { accountingFormat: true }
              }}
            </div>
          </div>
          <div
            *ngIf="selectedRow.payStatus === PayStatus.Finalized && isHavingAdjustments"
            class="list-item-content__content--field"
          >
            <div class="content align-right">
              {{ ele.amount?.amountNet || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
            </div>
          </div>
          <div
            *ngIf="selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
            class="list-item-content__content--field"
          >
            <div class="content align-right">
              {{ ele.amount?.amountYTD || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
            </div>
          </div>
        </div>
        <hr *ngIf="isLast" />
      </div>
    </ng-container>

    <div class="list-item-content item-info" *ngIf="!isHideTotalActive">
      <div class="list-item-content__content--field">
        <div class="label truncate">Total Deductions (Active)</div>
      </div>
      <div class="list-item-content__content--field">
        <div class="content align-right">
          {{
            deductionsInfo?.totalDeductionActive?.totalDeductionCurrent || 0
              | numberLocalDecimal: '$' : { accountingFormat: true }
          }}
        </div>
      </div>
      <div
        *ngIf="selectedRow.payStatus === PayStatus.Finalized && isHavingAdjustments"
        class="list-item-content__content--field"
      >
        <div class="content align-right">
          {{
            deductionsInfo?.totalDeductionActive?.totalDeductionNet || 0
              | numberLocalDecimal: '$' : { accountingFormat: true }
          }}
        </div>
      </div>
      <div
        *ngIf="selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        class="list-item-content__content--field"
      >
        <div class="content align-right">
          {{
            deductionsInfo?.totalDeductionActive?.totalDeductionYTD || 0
              | numberLocalDecimal: '$' : { accountingFormat: true }
          }}
        </div>
      </div>
    </div>
    <div class="list-item-content item-info">
      <div class="list-item-content__content--field">
        <div class="label truncate">{{ isHistory ? 'Total Deductions' : 'Total Deductions (All)' }}</div>
      </div>
      <div class="list-item-content__content--field">
        <div class="content align-right">
          {{
            deductionsInfo?.totalDeductionAll?.totalDeductionCurrent || 0
              | numberLocalDecimal: '$' : { accountingFormat: true }
          }}
        </div>
      </div>
      <div
        *ngIf="selectedRow.payStatus === PayStatus.Finalized && isHavingAdjustments"
        class="list-item-content__content--field"
      >
        <div class="content align-right">
          {{
            deductionsInfo?.totalDeductionAll?.totalDeductionNet || 0
              | numberLocalDecimal: '$' : { accountingFormat: true }
          }}
        </div>
      </div>
      <div
        *ngIf="selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        class="list-item-content__content--field"
      >
        <div class="content align-right">
          {{
            deductionsInfo?.totalDeductionAll?.totalDeductionYTD || 0
              | numberLocalDecimal: '$' : { accountingFormat: true }
          }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notLoading>
  <ptg-spinner classInput="p-6" [isLoading]="isLoading"></ptg-spinner>
</ng-template>
