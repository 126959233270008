<div
  class="add-employer-portal-user-container"
  id="add-employer-portal-user-dialog"
>
  <ptg-title-bar
    [name]="data?.portalUser ? 'Edit' : 'Add New'"
    [viewName]="data?.configSection?.displayName ?? ''"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [ngClass]="{'opacity-30' : disableBtn}"
        [disabled]="disableBtn"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        [ngClass]="{'opacity-30' : disableBtn}"
        [disabled]="disableBtn"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-control-container">
      <ptg-checkbox
        *ngIf="data?.portalUser"
        [controlField]="editForm.get('isInactive')"
        label="Inactive"
      ></ptg-checkbox>
      <div class="group-control group-2">
        <ptg-textbox
          [controlField]="editForm.get('firstName')"
          placeholder="First Name"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="80"
        >
        </ptg-textbox>
        <ptg-textbox
          [controlField]="editForm.get('lastName')"
          placeholder="Last Name"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="80"
        ></ptg-textbox>
      </div>
      <ptg-textbox
        class="mg-top"
        *ngIf="!isEdit"
        [controlField]="editForm.get('email')"
        placeholder="Email"
        [hasLabel]="true"
        [isRequired]="true"
        inputType="Email"
        customError="mesExistEmail"
        ></ptg-textbox>
      <div
        ngClass="mg-top {{
          data.portalUser ? 'group-control group-role-edit' : ''
        }}"
      >
        <ptg-select
          placeholder="System Role"
          [controlField]="editForm.get('systemRoleId')"
          [listData]="listSystemRole"
          [isOptionObj]="true"
          errorRequire="System Role is required."
        ></ptg-select>
      </div>
      <div *ngIf="isEdit" class="content">
        <div class="portal-item">
          <div class="item">
            <div class="email-item">
              <label class="email-edit">Email</label>
              <p>{{ portalUserGlobal?.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
