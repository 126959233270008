import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-input-number-mask',
  templateUrl: './input-number-mask.component.html',
  styleUrls: ['./input-number-mask.component.scss']
})
export class InputNumberMaskComponent implements OnInit {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;
  
  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() errorRequire?: string;
  @Input() errorPattern?: string;
  @Input() errorAsync?: string;
  @Input() errorMaxLength?: string;
  @Input() errorMin?: string;
  @Input() errorMax?: string;
  @Input() errorBetween?: string;
  @Input() maxLength?: number;
  @Input() hasLabel?: boolean;
  @Input() isDisabled?: boolean;
  @Input() class?: string;
  @Input() min?: string;
  @Input() max?: string;
  @Input() mask: string = 'separator';
  @Input() thousandSeparator: string = '';
  @Input() allowNegativeNumbers: boolean = true;
  @Input() isAutoLeadingZeros: boolean = false;
  @Input() isValidation: boolean = true;
  @Input() isPercentage?: boolean;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  constructor() { }

  ngOnInit(): void { 
  }

  onFocusOut(event: any) {
    const val = event.target.value;
    // Auto leading zeros by maxlength
    if (this.isAutoLeadingZeros && this.maxLength && this.maxLength > 0 && val && val?.trim() !== '') {
      this.controlField.setValue(('0'.repeat(this.maxLength) + val).slice(-Math.abs(this.maxLength)));
    }

    if (this.isPercentage && this.controlField.value && this.controlField.value.endsWith('.')) {
      this.controlField.setValue(this.controlField.value.substring(0, this.controlField.value.length - 1));
    }
  }
}
