import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance/value.const';

import { AddressHistoryList, GetListAddressHistoryQuery, PayloadAddress, AddressEntityProperty } from '../types/models';
import { map } from 'rxjs/operators';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

@Injectable({
  providedIn: 'root',
})
export class AddressHistoryService {
  constructor(private httpClient: HttpClient) {}

  getAddressHistoryList(query: GetListAddressHistoryQuery, entityId: string): Observable<AddressHistoryList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient
      .get<AddressHistoryList>(`${environment.apiUrl}/address/${query.memberId}/${entityId}`, { params })
      .pipe(map((result: any) => ({ listAddressHistory: result.addresses, total: result?.total ?? 0 })));
  }

  getAddressEntityProperty(entityId: string): Observable<AddressEntityProperty> {
    return this.httpClient
      .get<AddressEntityProperty>(`${environment.apiUrl}/entity/entities/${entityId}/address-properties?screenId=overview`);
  }

  createAddress(body: PayloadAddress) {
    return this.httpClient.post(`${environment.apiUrl}/address`, body);
  }

  updateAddress(body: PayloadAddress) {
    return this.httpClient.put(`${environment.apiUrl}/address`, body);
  }

  checkExit(body: any): Observable<any[]> {
    return this.httpClient.post<any>(`${environment.apiUrl}/address/existed`, body);
  }
}
