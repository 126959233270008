<div
  class="member-audit-trails-container"
  id="member-audit-trails-container"
  [class.show-banner]="bannerType"
>
  <div class="header-container">
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
    ></ptg-breadcrumb>
  </div>

  <ptg-overview-header-entity 
    #overViewHeader
    *ngIf="memberId && (isFromOverview || isFromDetailView)"
    [injectedMemberId]="memberId"
    [shouldDisableNavigationButtonsInAllCases]="true"
    [shouldDisableCloseButtonInAllCases]="true"
  ></ptg-overview-header-entity>

  <div
    [ngClass]="{ 'for-member': memberId }"
    class="main-page-content flex flex-col">
    <div class="flex flex-col">
      <div 
        *ngIf="!isShowTableAfterFirstFilter && !memberId"
        class="member-list-banner pt-6 pb-3 px-6">
        <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
      </div>
    </div>

    <div class="filter-section pt-4 pl-6 pr-6 pb-3">
      <div
        [ngClass]="{ 'not-use-search-name': memberId }"
        class="flex flex-wrap filter-input-section">
        <!-- Section Dropdown -->
        <div class="section-dropdown-area">
          <ptg-select
            [listData]="sectionOptionList"
            [controlField]="sectionDropdownControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            placeholder="Section"
            panelClass="member-audit-trails-section-dropdown"
          >
          </ptg-select>
        </div>
        <!-- Name Search Textbox -->
        <div class="search-name-area" *ngIf="!memberId">
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <mat-icon aria-hidden="false" matPrefix>person_search</mat-icon>
            <input
              #searchNameInput
              #searchNameTrigger="matAutocompleteTrigger"
              matInput
              [(ngModel)]="valueNameSearch"
              [matAutocomplete]="nameSearch"
              (input)="searchName(valueNameSearch)"
              type="text"
              class="search-name-input"
            />
            <button
              *ngIf="hideClearSearchNameContent"
              (click)="clearSearchNameContent()"
              mat-icon-button
              aria-label="close icon"
              class="x-button"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete
              #nameSearch="matAutocomplete"
              [disableRipple]="true"
              class="member-audit-trails-name-search-panel"
              panelWidth="auto"
            >
              <mat-option
                [disabled]="true"
                *ngIf="
                  !isSearchNameLoading &&
                  searchNameResultList?.length &&
                  (valueNameSearch && valueNameSearch.length > 1)
                "
              >
                <table mat-table [dataSource]="searchNameResultList" class="w-full">
                  <ng-container
                    [matColumnDef]="column"
                    *ngFor="let column of searchNameResultColumns"
                  >
                    <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      ptgSearchEmployerBold
                    >
                      <span class="value">
                        {{ element[column] }}
                      </span>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="searchNameResultColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: searchNameResultColumns"
                    (click)="selectName(row)"
                  ></tr>
                </table>
              </mat-option>
              <mat-option
                class="message-option"
                *ngIf="searchNameResultList?.length === 0 && valueNameSearch && valueNameSearch.length >= 2"
              >
                {{
                  isSearchNameLoading
                    ? "Searching..."
                    : isSearchNameError
                      ? "Error occurred while searching."
                      : "No Name found."
                }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <!-- User Search Textbox -->
        <div class="search-user-area">
          <mat-form-field appearance="fill">
            <mat-label>User</mat-label>
            <mat-icon aria-hidden="false" matPrefix>person_search</mat-icon>
            <input
              matInput
              [(ngModel)]="valueUserSearch"
              [matAutocomplete]="userSearch"
              (input)="searchUser(valueUserSearch)"
              type="text"
            />
            <button
              *ngIf="hideClearSearchUserContent"
              (click)="clearSearchUserContent()"
              mat-icon-button
              aria-label="close icon"
              class="x-button"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete
              #userSearch="matAutocomplete"
              [disableRipple]="true"
              class="member-audit-trails-user-search-panel"
              panelWidth="auto"
            >
              <ng-container
                *ngIf="
                  !isSearchUserLoading &&
                  searchUserResultList?.length &&
                  (valueUserSearch && valueUserSearch.length > 1)
                "
                >
                <mat-option
                  *ngFor="let user of searchUserResultList"
                  (click)="selectUser(user)"
                >
                  {{ user?.name ?? '' }}
                </mat-option>
              </ng-container>
              <mat-option
                class="message-option"
                *ngIf="searchUserResultList?.length === 0 && valueUserSearch && valueUserSearch.length >= 2"
              >
                {{
                  isSearchUserLoading
                    ? "Searching..."
                    : isSearchUserError
                      ? "Error occurred while searching."
                      : "No User found."
                }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <!-- Date From Datepicker -->
        <div class="date-from-area">
          <ptg-datepicker
            [controlField]="dateFromDatePickerControl"
            [minDate]="minDateFrom"
            [maxDate]="maxDateFrom"
            [notUseErrorMaxDate]="true"
            [notUseErrorMinDate]="true"
            (changeValue)="onChangeDateFrom()"
            placeholder="Date From"
            customError="errorMessageDateFrom"
          ></ptg-datepicker>
        </div>
        <!-- Date To Datepicker -->
        <div class="date-from-area">
          <ptg-datepicker
            [controlField]="dateToDatePickerControl"
            [minDate]="minDateTo"
            [maxDate]="maxDateTo"
            [notUseErrorMaxDate]="true"
            [notUseErrorMinDate]="true"
            (changeValue)="onChangeDateTo()"
            placeholder="Date To"
            customError="errorMessageDateTo"
          ></ptg-datepicker>
        </div>
      </div>
      <!-- Apply & Filter area -->
      <div class="flex pb-3">
        <div class="filter-non-button-section">
        </div>
        <div class="filter-buttons-section flex justify-end gap-3">
          <ptg-button
            [isDisabled]="isTableLoading"
            (click)="onApplyFilter()"
            classInput="mat-flat-button primary"
            buttonName="Apply">
          </ptg-button>
          <ptg-button
            [isDisabled]="isTableLoading"
            (click)="onClearFilter()"
            classInput="mat-flat-button tertiary clear-filter"
            buttonName="Clear Filter">
          </ptg-button>
        </div>
      </div>
    </div>

    <div 
      *ngIf="isShowTableAfterFirstFilter"
      class="flex-grow overflow-hidden grid-section">
      <ptg-grid
        [data]="tableData"
        [columns]="tableColumns"
        [isLoading]="isTableLoading"
        [fitToParent]="true"
        [hideScrollbar]="false"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="pageChange($event)"
        (sortChange)="sortChange($event)"
        notFoundMessage="No Data to Display"
        [useTooltipForDefaultCellData]="true"
      >
      <ng-template cellContent columnName="targetName" let-row>
        <div class="flex configured-profile-container">
          <span
            [ngClass]="{ 'is-link': row?.isProfileConfigured === true }" 
            (click)="onClickHyperlink(row)" 
            class="configured-profile-name">
            <span 
              class="truncate"
              matTooltip="{{ row?.targetName }}"
              matTooltipClass="cs-tooltip transactions-note-tooltip"
              matTooltipPosition="below">
              {{ row?.targetName }}
            </span>
          </span>
        </div>
      </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
