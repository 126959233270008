import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import {
  PayeeDetailState,
  clearGetFinalPaymentConfigurationsStateAction,
  clearSetFinalPaymentConfigurationsStateAction,
  getFinalPaymentConfigurationsAction,
  getFinalPaymentConfigurationsSelector,
  setFinalPaymentConfigurationsAction,
  setFinalPaymentConfigurationsSelector,
} from '../../store';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberNavigationResponse } from '@ptg-member/types/models';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { BenefitSubTypes } from '../../services/models';
import { FinalPaymentConfigurationEnum } from '../../types/enums';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { PaymentInstructionDetailComponentService } from '../payment-instruction-detail/payment-instruction-detail.component.service';

@Component({
  selector: 'ptg-final-payment-configuration',
  templateUrl: './final-payment-configuration.component.html',
  styleUrls: ['./final-payment-configuration.component.scss'],
  providers:[PaymentInstructionDetailComponentService]
})
export class FinalPaymentConfiguration extends BaseComponent {
  readonly FinalPaymentConfigurationEnum = FinalPaymentConfigurationEnum;

  // Notification Banner
  bannerType = BannerType.Hidden;
  message: string = '';

  listBreadcrumbs: Breadcrumb[] = [];
  configurationForm: FormGroup | any = this.fb.group({});
  benefitSubTypesList: BenefitSubTypes[] = [];
  isEnableSaveBtn: boolean = false;
  currentConfigValue: any = {};

  menuId: string = '';
  viewId: string = '';
  id: string = '';
  extendsUrl?: string;

  isLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private payeeDetailStore: Store<PayeeDetailState>,
    public dialog: MatDialog,
    private router: Router,
    public route: ActivatedRoute,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onCreateGroupFormValueChange();
    this.getRouteData();

    this.getFinalPaymentConfig();
    this.selectSetFinalPaymentConfig();
    this.selectFinalPaymentConfig();
  }

  getRouteData() {
    const params = this.route.snapshot.params;
    const queryParams = this.route.snapshot.queryParams;
    this.menuId = params.menuId;
    this.id = params.id;
    this.viewId = params.viewId;
    this.extendsUrl = this.paymentInstructionDetailComponentService.getQueryParams(queryParams);
    this.getViewName();
  }

  getViewName() {
    this.payeeDetailStore
      .select(fromLayoutReducer.selectMemberNavigationListState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menu) => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] = menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });
          const menuName = menuItems?.find((item: any) => item?.id === this.menuId)?.name;

          this.listBreadcrumbs[0] = {
            name: menuName ?? '',
          };

          this.listBreadcrumbs = [
            {
              name: menuName ?? '',
              url: `/member/system-view/payment-information/${this.menuId}/${this.viewId}/${this.id}${this.extendsUrl}`,
            },
            {
              name: 'Final Payment Configuration',
            },
          ];
        }
      });
  }

  private onInitForm() {
    this.benefitSubTypesList.forEach((item) => {
      this.configurationForm.setControl(item.id, new FormControl(item.payOption));
    });
    this.currentConfigValue = this.configurationForm.value;
  }

  private onCreateGroupFormValueChange(){
    this.configurationForm.valueChanges.subscribe(() => {
      this.isEnableSaveBtn = Object.keys(this.currentConfigValue).some(key => this.configurationForm.value[key] != this.currentConfigValue[key])
    });
  }

  private getFinalPaymentConfig() {
    this.payeeDetailStore.dispatch(getFinalPaymentConfigurationsAction());
  }

  private selectFinalPaymentConfig() {
    this.payeeDetailStore
      .select(getFinalPaymentConfigurationsSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        if (res) {
          this.isLoading = res?.isLoading;

          if (res?.success) {
            this.benefitSubTypesList = res?.payload?.benefitSubTypes ?? [];
            this.onInitForm();
          }
        }
        this.payeeDetailStore.dispatch(clearGetFinalPaymentConfigurationsStateAction());
      });
  }

  private setFinalPaymentConfig() {
    let benefitSubTypes: BenefitSubTypes[] = [];
    Object.entries(this.configurationForm.value).forEach(([key, value]) => {
      benefitSubTypes.push({
        id: key,
        payOption: Number(value)
      })
    })
    this.payeeDetailStore.dispatch(setFinalPaymentConfigurationsAction({ body: { benefitSubTypes } }));
  }

  private selectSetFinalPaymentConfig(): void {
    this.payeeDetailStore
      .select(setFinalPaymentConfigurationsSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        if (res?.success) {
          this.currentConfigValue = this.configurationForm.value;
          showBanner.call(this, BannerType.Success, '', '', {
            customMessage: 'Final Payment Configuration successfully updated.',
          });
        } else {
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: 'Error occurred updating Final Payment Configuration. Please try again.',
          });
        }
        this.getFinalPaymentConfig();
        this.payeeDetailStore.dispatch(clearSetFinalPaymentConfigurationsStateAction());
      });
  }

  private navigateToPaymentInfo() {
    this.router.navigateByUrl(`/member/system-view/payment-information/${this.menuId}/${this.viewId}/${this.id}${this.extendsUrl}`);
  }

  onSubmit() {
    this.isEnableSaveBtn = false;
    this.isLoading = true;
    this.setFinalPaymentConfig();
  }

  onCancel() {
    const ALERT_MESSAGE = 'Are you sure you want to cancel any/all changes?';
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.ConfirmWithDontShowAgain,
        title: 'Cancel Action',
        cancelButtonTitle: 'No',
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.navigateToPaymentInfo();
      }
    });
  }
}
