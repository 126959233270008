import { Action, combineReducers } from '@ngrx/store';
import {
  InitialPaymentConfigurationState,
  initialPaymentConfigurationReducer,
} from './initial-payment-configuration.reducer';
import {
  PaymentHeaderConfigurationReducer,
  PaymentHeaderConfigurationState,
} from './payment-header-configuration.reducer';
import * as fromPaymentEarning from './payee-detail.reducer';
import * as fromAddOneTimeReducer from './add-one-time.reducer';
import * as fromCreateOffsetDeductionReducer from './create-offset-deduction.reducer';
import { PaymentInfoTabState, paymentInfoTabReducer } from './payment-info-tab.reducer';
import { PayrollSettingsState, payrollSettingsReducer } from './payroll-settings.reducer';
import { PayeePaymentState, payeePaymentReducer } from './payee-payment.reducer';
import { EditFundingSourceState, editFundingSourceReducer } from './edit-funding-source.reducer';
import { EditPayrollSettingsState, editPayrollSettingsReducer } from './edit-payroll-settings.reducer';
import {
  editDeductionsReducer,
  EditDeductionsState,
} from '@ptg-member/features/payee-detail/store/reducers/edit-deductions.reducer';
import { NoteMemoState, PaymentNoteMemoReducer } from './note-memo-detail.reducer';
import { EditPaymentStatusState, editPaymentStatusReducer } from './edit-payment-status.reducer';
import { CreateAdjustmentDetailedState, createAdjustmentDetailedReducer } from './new-detailed-adjustment.reducer';
import { CreateGeneralAdjustmentState, createGeneralAdjustmentReducer } from './create-general-adjustment.reducer';
import { ViewAdjustmentDetailsState, viewAdjustmentDetailsReducer } from './view-adjustment-details.reducer';
import { CreateReversalAdjustmentState, createReversalAdjustmentReducer } from './create-reversal-adjustment.reducer';
import { FinalPaymentConfigurationState, finalPaymentConfigurationReducer } from './final-payment-configuration.reducer';

export interface PayeeDetailState {
  [fromPaymentEarning.PaymentEarningsDetailFeatureKey]: fromPaymentEarning.State;
  [fromAddOneTimeReducer.AddOneTimeKey]: fromAddOneTimeReducer.State;
  [fromCreateOffsetDeductionReducer.CreateOffsetDeductionKey]: fromCreateOffsetDeductionReducer.State;
  initialPaymentConfiguration: InitialPaymentConfigurationState;
  finalPaymentConfiguration: FinalPaymentConfigurationState;
  paymentHeaderConfiguration: PaymentHeaderConfigurationState;
  paymentInfoTab: PaymentInfoTabState;
  payrollSettings: PayrollSettingsState;
  payeePayment: PayeePaymentState;
  editFundingSource: EditFundingSourceState;
  editPayrollSettings: EditPayrollSettingsState;
  editDeductions: EditDeductionsState;
  noteMemoState: NoteMemoState;
  editPaymentStatus: EditPaymentStatusState;
  createAdjustmentDetailed: CreateAdjustmentDetailedState;
  createGeneralAdjustment: CreateGeneralAdjustmentState;
  viewAdjustmentDetails: ViewAdjustmentDetailsState;
  createReversalAdjustment: CreateReversalAdjustmentState;
}

export const payeeDetailReducers = (state: PayeeDetailState | undefined, action: Action) => {
  return combineReducers({
    initialPaymentConfiguration: initialPaymentConfigurationReducer,
    finalPaymentConfiguration: finalPaymentConfigurationReducer,
    paymentHeaderConfiguration: PaymentHeaderConfigurationReducer,
    [fromPaymentEarning.PaymentEarningsDetailFeatureKey]: fromPaymentEarning.reducer,
    [fromAddOneTimeReducer.AddOneTimeKey]: fromAddOneTimeReducer.reducer,
    [fromCreateOffsetDeductionReducer.CreateOffsetDeductionKey]: fromCreateOffsetDeductionReducer.reducer,
    paymentInfoTab: paymentInfoTabReducer,
    payrollSettings: payrollSettingsReducer,
    payeePayment: payeePaymentReducer,
    editFundingSource: editFundingSourceReducer,
    editPayrollSettings: editPayrollSettingsReducer,
    noteMemoState: PaymentNoteMemoReducer,
    editDeductions: editDeductionsReducer,
    editPaymentStatus: editPaymentStatusReducer,
    createAdjustmentDetailed: createAdjustmentDetailedReducer,
    createGeneralAdjustment: createGeneralAdjustmentReducer,
    viewAdjustmentDetails: viewAdjustmentDetailsReducer,
    createReversalAdjustment: createReversalAdjustmentReducer,
  })(state, action);
};
