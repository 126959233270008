import { FilterInputType } from '../types/models';

export enum GetPropertyType {
  MemberListConfiguration = 0,
  ProfileHeader = 1,
  MemberListFilter = 2,
  ParticipantSearchConfiguration = 3,
  StepConfiguration = 4,
}

export enum Operator {
  'IsEmpty',
  'IsNotEmpty',
  'Equals',
  'NotEquals',
  'Contains',
  'NotContains',
  'GreaterThan',
  'LessThan',
  'Between',
  IsAny = 9,
  IsCurrentStatus = 10,
  WithinLastDay = 11,
  AtAnyPoint = 12,
}

export const OPERATOR_LABEL: Record<string, string> = {
  IsEmpty: 'has no value',
  IsNotEmpty: 'has a value',
  Equals: 'is',
  NotEquals: 'is not',
  Contains: 'does contain',
  NotContains: 'does not contain',
  GreaterThan: 'is or greater than',
  LessThan: 'is or less than',
  Between: 'is between',
  DateGreaterThan: 'is on or after',
  DateLessThan: 'is on or before',
  IsAny: 'is any',
  IsCurrentStatus: 'is',
  WithinLastDay: 'within last days',
  AtAnyPoint: 'at any point',
};

export const INPUT_TYPE: Record<number, FilterInputType> = {
  1: 'Text',
  2: 'Text',
  3: 'Number',
  4: 'Number',
  5: 'Number',
  6: 'Number',
  7: 'Date',
  9: 'Boolean',
  10: 'Number',
  11: 'Text',
  // 50: 'Text',
  100: 'Text',
  101: 'Text',
  102: 'Text',
  8: 'DateTime',
  51: 'Text',
  52: 'Text',
  205: 'Text',
};

export const OPERATOR_BY_TYPE = {
  Text: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  Currency: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Decimal: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  'Whole Number': [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Date: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Email: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  Phone: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  Percentage: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Boolean: [Operator.Equals],
  List: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  'Address - Street 1': [
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Address - Street 2 (Apartment No./Suite No.)': [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Address - City': [
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Address - State/Province/Territory': [Operator.Equals, Operator.NotEquals],
  'Address - Zip Code/Postal Code': [
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Address - Country': [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  'Person Name - Salutation': [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  'Person Name - First Name': [
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Person Name - Middle Name': [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Person Name - Last Name': [
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  'Person Name - Suffix': [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  SSN: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
  DateTime: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Registered: [Operator.Equals],
  FixedMember: [Operator.Equals],
  FixedParticipant: [Operator.Equals],
  FixedPerson: [Operator.Equals],
  Locked: [Operator.Equals],
  StatusName: [Operator.Equals, Operator.NotEquals, Operator.IsAny],
  StatusEvent: [Operator.Equals, Operator.NotEquals, Operator.IsAny],
  StatusDate: [
    Operator.IsCurrentStatus,
    Operator.WithinLastDay,
    Operator.AtAnyPoint,
    Operator.Between,
  ],
  Count: [
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Department: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  Employer: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  Benefit: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  'SMS Opted-In': [Operator.Equals],
  'Paperless Opted-In': [Operator.Equals],
  Tier: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
  ],
  Age: [
    Operator.Equals,
    Operator.NotEquals,
    Operator.GreaterThan,
    Operator.LessThan,
    Operator.Between,
  ],
  Identifier: [
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.Equals,
    Operator.NotEquals,
    Operator.Contains,
    Operator.NotContains,
  ],
};

export enum FilterPropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  'Whole Number' = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  DateTime = 8,
  Boolean = 9,
  Percentage = 10,
  SSN = 11,
  Register = 200,
  List = 50,
  Address = 100,
  'Person Name' = 101,
  Status = 102,
  Employer = 51,
  Department = 52,
  RichText = 12,
  Benefit = 53,
  'Entity Reference' = 54,
  'SMS Opted-In' = 13,
  'Paperless Opted-In' = 14,
  Tier = 55,
  System = 203,
  Identifier = 205,
  Aggregation = 103,
  Calculation = 104,
}

export const SystemProperties: string[] = ['Registered', 'Locked', 'PaperlessOptedIn', 'SMSOptedIn', 'FixedMember', 'FixedParticipant', 'FixedPerson'];
export const AgePropertyName: string = 'Age';

export enum HeaderType {
  Property,
  Aggregation,
  Calculation
}

export const IsEstablished: string = 'isEstablished';

export const TAX_CODE = {
  TAX01: 'TAX01',
  TAX02: 'TAX02',
};

export enum TaxType {
  FederalTax = 0,
  StateTax = 1,
}

export enum PayStatus {
  IssuePayment = 0,
  Suspend = 1,
  Terminate = 2,
}

export const ICON_REGISTER_FIELDS = {
  registerIconName: 'registerIconName',
  registerIconColor: 'registerIconColor',
};

export const ICON_STATUS_FIELDS = {
  statusIconName: 'statusIconName',
  statusIconColor: 'statusIconColor',
  statusIconLabel: 'statusIconLabel',
  eventIconLabel: 'eventIconLabel',
};

export const FIXED_PROPERTIES = {
  StatusEvent: 'Fixed_status_Event',
  MunicipalityId: {
    SectionKey: '',
    PropertyKey: '_municipalityIds',
    PropertyName: 'Municipality Name',
    Type: FilterPropertyType.Text
  },
  Status: {
    SectionKey: 'Fixed',
    PropertyKey: 'status',
    PropertyName: 'Municipality Status',
    Option: 'Name',
    Type: FilterPropertyType.Status
  }
};

export const FIXED_KEY = {
  Locked: 'Fixed_IsLocked'
}

export const FIXED_KEY_ENTITY = {
  Locked: 'Locked'
}


export const PROPERTY_TYPE = 'Property List';


export const PARTICIPANT_ENTITY_CONNECTION_NAME = 'participants';
