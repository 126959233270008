import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { addNoteAction, addNoteFailure, addNoteSuccess, checkEditButtonBPAction, checkEditButtonBPFailure, checkEditButtonBPSuccess, checkEditButtonPDAction, checkEditButtonPDFailure, checkEditButtonPDSuccess, clearGetNotesAction, clearNoteMemoState, editBenefitPeriodAction, editBenefitPeriodFailure, editBenefitPeriodSuccess, editPayableDateAction, editPayableDateFailure, editPayableDateSuccess, getNotesAction, getNotesFailure, getNotesSuccess, updateNoteAction, updateNoteFailure, updateNoteSuccess } from '../actions';
import { GetPaymentNoteMemoResponse, updatePaymentNoteMemoResponsive } from '../../types/models';
import { STATE } from '@ptg-shared/constance';

export interface NoteMemoState {
  payeeNotesDetail?: BaseActionState<GetPaymentNoteMemoResponse>;
  createPayeeNotes?: updatePaymentNoteMemoResponsive;
  updatePayeeNotes?: updatePaymentNoteMemoResponsive;
  editPayableDate?: BaseActionState;
  editBenefitPeriod?: BaseActionState;
  checkEditButtonPD?: BaseActionState<boolean>;
  checkEditButtonBP?: BaseActionState<boolean>;
}

export const PaymentNoteMemoKey = 'paymentNoteMemo';

const initialState: NoteMemoState = {
  payeeNotesDetail: {
    isLoading: true,
    success: true,
    payload: undefined,
  },
};

export const PaymentNoteMemoReducer = createReducer(
  initialState,
  on(clearNoteMemoState, () => ({
    ...initialState
  })),
  on(getNotesAction, (state, {}) => ({
    ...state,
    payeeNotesDetail: {
      isLoading: true,
    },
  })),
  on(getNotesSuccess, (state, {paymentInfoNotes}) => ({
    ...state,
    payeeNotesDetail: {
      isLoading: false,
      success: true,
      error: false,
      payload: paymentInfoNotes,
    },
  })),
  on(getNotesFailure, (state) => ({
    ...state,
    payeeNotesDetail: {
      isLoading: false,
      success: true,
      error: false,
      payload: undefined,
    },
  })),

  on(clearGetNotesAction, (state) => ({
    ...state,
    payeeNotesDetail: undefined
  })),
  on(addNoteAction, (state) => ({
    ...state,
    createPayeeNotes: {
      isLoading: true,
    }
  })),
  on(addNoteSuccess, (state) => ({
    ...state,
    createPayeeNotes: {
      isLoading: false,
      createState: STATE.SUCCESS,
      success: true,
    }
  })),
  on(addNoteFailure, (state, { error }) => ({
    ...state,
    createPayeeNotes: {
      isLoading: false,
      success: true,
      createState: STATE.FAIL,
      error: error,
    }
  })),
  on(updateNoteAction, (state) => ({
    ...state,
    updatePayeeNotes: {
      isLoading: true
    }
  })),
  on(updateNoteSuccess, (state) => ({
    ...state,
    updatePayeeNotes: {
      isLoading: false,
      updateState: STATE.SUCCESS,
      success: true,
    }
  })),
  on(updateNoteFailure, (state, { error }) => ({
    ...state,
    updatePayeeNotes: {
      isLoading: false,
      success: true,
      updateState: STATE.FAIL,
      error: error,
    }
  })),
  on(checkEditButtonPDAction, (state) => ({
    ...state,
    checkEditButtonPD: undefined
  })),
  on(checkEditButtonPDSuccess, (state, {isValid}) => ({
    ...state,
    checkEditButtonPD: {
      isLoading: false,
      success: true,
      payload: isValid
    }
  })),
  on(checkEditButtonPDFailure, (state, { error }) => ({
    ...state,
    checkEditButtonPD: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),
  on(checkEditButtonBPAction, (state) => ({
    ...state,
    checkEditButtonBP: undefined
  })),
  on(checkEditButtonBPSuccess, (state, {isValid}) => ({
    ...state,
    checkEditButtonBP: {
      isLoading: false,
      success: true,
      payload: isValid
    }
  })),
  on(checkEditButtonBPFailure, (state, { error }) => ({
    ...state,
    checkEditButtonBP: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),
  on(editPayableDateAction, (state) => ({
    ...state,
    editPayableDate: undefined
  })),
  on(editPayableDateSuccess, (state) => ({
    ...state,
    editPayableDate: {
      isLoading: false,
      success: true,
    }
  })),
  on(editPayableDateFailure, (state, { error }) => ({
    ...state,
    editPayableDate: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),
  on(editBenefitPeriodAction, (state) => ({
    ...state,
    editBenefitPeriod: undefined
  })),
  on(editBenefitPeriodSuccess, (state) => ({
    ...state,
    editBenefitPeriod: {
      isLoading: false,
      success: true,
    }
  })),
  on(editBenefitPeriodFailure, (state, { error }) => ({
    ...state,
    editBenefitPeriod: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),
);
