<div id="status-detail-page" class="status-detail-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div *ngIf="statusDetail" class="box">
      <div class="title">
        <span class="title-name">Status Details</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditStatus()"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail">
          <span class="label" id="label-Status Name">Status Name</span>
          <span class="value" id="value-Status Name">
            <ng-container>{{ statusDetail?.name }}</ng-container>
          </span>
        </div>
        <div class="detail">
          <span class="label" id="label-Status Icon">Status Icon</span>
          <span class="value" id="value-Status Icon">
            <mat-icon
              *ngIf="statusDetail.iconName"
              class="icon-status"
              [ngStyle]="{ color: statusDetail.color }"
              >{{ statusDetail.iconName }}</mat-icon
            >
          </span>
        </div>
        <div class="detail">
          <span class="label" id="label-Disabled">Active</span>
          <span class="value" id="value-Disabled">
            {{ statusDetail?.active ? "Yes" : "No" }}
          </span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="title">
        <span class="title-name">Status Event List</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="New Event"
            classInput="edit-button"
            (clickButton)="editEvent(undefined)"
          >
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>
      </div>
      <ptg-grid
        [data]="memberEventsData"
        [columns]="columns"
        keyColumn="key"
        [isLoading]="isLoading"
        [paginable]="false"
        (sortChange)="sortChange($event)"
        [errorMessage]="errorMsg"
        notFoundMessage="No Event to Display"
      >
        <ng-template cellContent columnName="active" let-row>
          {{ row.active  ? 'Yes' : 'No' }} 
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="editEvent(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
