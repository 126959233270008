<div class="flex-col justify-center items-end gap-12 confirmation-dialog-container">
  <div class="flex-col justify-start items-start gap-4 flex">
    <div class="title">Confirmation</div>
    <div class="content-container gap-4">
      <mat-icon class="info-icon">info</mat-icon>
      <div class="w-full flex flex-col information-container">
        <span *ngIf="noOfParticipantsNotMeetRequirement !== undefined && noOfParticipantsNotMeetRequirement > 0">
          {{ data.annualCertificationConfirmationData?.noOfParticipantsNotMeetRequirement }} members did not meet requirements
        </span>
        <span *ngIf="noOfParticipantsNoActivity !== undefined && noOfParticipantsNoActivity > 0">
          {{ data.annualCertificationConfirmationData?.noOfParticipantsNoActivity }} members had no activity
        </span>
        <div *ngIf="data.participantName" >
          <span class="font-bold">{{ data.participantName }}</span> had no activity for some gap years.
        </div>
        <div class="flex" *ngIf="amountCredit !== undefined && amountCredit > 0 || data.creditAmount > 0">
          <span class="font-bold">${{ data.annualCertificationConfirmationData?.amountCredit || data.creditAmount }}</span>
          <span>&nbsp;will be credited back to {{ data.municipalityName }} municipality.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full my-12">
    <ptg-textbox
      [hasLabel]="true"
      [controlField]="cashJournalControl"
      [inputType]="InputType.TYPE_NUMBER"
      [maxLength]="4"
      placeholder="CJ #"
      customError="invalidCashJournal"
      [isPositive]="true"
      [isDecimal]="false"
      [isNumberOnly]="true"
      ></ptg-textbox>
  </div>
  <div class="dialog-footer flex justify-end items-center gap-2">
    <ptg-button classInput="save-button" (clickButton)="formSubmit$.next()">Save</ptg-button>
    <ptg-button classInput="cancel-button" (clickButton)="cancel()">Cancel</ptg-button>
  </div>
</div>
