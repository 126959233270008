import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Column, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { ACTION, DEFAULT_PAGE_SIZE, STATE, SortType } from '@ptg-shared/constance';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

import * as fromMember from '../../store/reducers';
import * as fromReducer from '@ptg-reducers';
import {
  BankAccountType,
  DepositAccount,
  GetDepositAccountsRequest,
} from '@ptg-member/types/models/deposit-account.model';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import {
  clearCreateDepositAccountsStateAction,
  clearSetDepositAccountStateAction,
  getDepositAccountsAction,
} from '@ptg-member/store/actions/deposit-account.action';
import { MemberNavigationResponse } from '@ptg-member/types/models';
import {
  createDepositAccountsSelector,
  getDepositAccountsState,
  setDepositAccountSelector,
} from '@ptg-member/store/selectors';
import { UpsertDepositAccountComponent } from '@ptg-member/components/upsert-deposit-account/upsert-deposit-account.component';

const PAGE_SIZE_CONST = '-ptg-entity-deposit-account-detail-pageSize';

@Component({
  selector: 'ptg-entity-deposit-account-detail',
  templateUrl: './entity-deposit-account-detail.component.html',
  styleUrls: ['./entity-deposit-account-detail.component.scss'],
})
export class EntityDepositAccountDetailComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;
  readonly BankAccountType = BankAccountType;
  listBreadcrumbs: Breadcrumb[] = [{ name: '' }];
  message: string = '';
  isLoading = false;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'bankName',
      header: {
        title: 'Bank Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'accountNumberMasked',
      header: {
        title: 'Account Number',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'accountType',
      header: {
        title: 'Account Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'isUse',
      header: {
        title: 'In Use',
      },
      sortable: true,
    },
    {
      name: 'isActive',
      header: {
        title: 'Active',
      },
      sortable: true,
    },
  ];

  depositAccountTable: (DepositAccount & Row)[] = [];
  memberId: string = '';
  viewId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  viewName!: string;
  viewUrl!: string;

  @ViewChild(OverviewHeaderEntityComponent) overViewHeaderEntity!: OverviewHeaderEntityComponent;

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params?.currentRowIndex) {
        this.currentRowIndex = +params.currentRowIndex;
      } else {
        this.getCurrentFundState();
      }

      if (params?.pageSize) {
        this.pageSize = Number(params?.pageSize);
      }

      if (params?.pageNumber) {
        this.pageNumber = Number(params?.pageNumber);
      }

      if (params?.prevName) {
        this.viewName = params.prevName;
        this.viewUrl = params.prevUrl;
      }
    });

    this.route.params.subscribe((params) => {
      this.memberId = params.id;
      this.viewId = params.viewId;
      this.getData();
      this.getViewName();
    });

    this.getDepositAccountSelector();
    this.getCreateDepositAccountSelector();
    this.getSetDepositAccountSelector();
  }

  getCurrentFundState() {
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? DEFAULT_PAGE_SIZE;
      this.currentFund = el;
      this.pageSize =
        Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0
          ? this.pageSize
          : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    });
  }

  getData(jumpToFirst?: boolean) {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }
    let sortType = 0;
    let sortNames = ''
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType =
        this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    let request: GetDepositAccountsRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      targetId: this.memberId,
      sortNames, 
      sortType
    };

    this.store.dispatch(getDepositAccountsAction({ request }));
  }

  getDepositAccountSelector() {
    this.store.pipe(select(getDepositAccountsState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success && !state?.isLoading) {
        this.depositAccountTable = (state?.payload?.depositAccounts ?? [])?.map((el) => {
          return {
            ...el,
            accountType: Number(el?.accountType) === BankAccountType.Checking ? 'Checking' : 'Savings',
            accountNumber: el?.accountNumber,
            accountNumberMasked: el?.accountNumber && el?.accountNumber.length > 3 ?
              (this.getMaskedFormat((el?.accountNumber?.length ?? 0) - 4) ?? '') +
              el?.accountNumber?.slice(el.accountNumber?.length - 4, el.accountNumber?.length) : el?.accountNumber,
            visibilityOff: true,
          };
        });
        this.isLoading = state?.isLoading;
        this.lengthPg = state?.payload?.total;
      }
    });
  }

  getCreateDepositAccountSelector() {
    this.memberStore.pipe(select(createDepositAccountsSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        this.bannerType = BannerType.Success;
        showBanner.call(this, STATE.SUCCESS, 'Deposit Account', ACTION.ADD);
        this.sortInfo = {};
        this.getData();
        this.currentRowIndex = 0;
      }

      if (state?.error) {
        this.bannerType = BannerType.Fail;
        showBanner.call(this, STATE.FAIL, 'Deposit Account', ACTION.ADD);
      }
      this.getData();
      this.memberStore.dispatch(clearCreateDepositAccountsStateAction());
    });
  }

  getSetDepositAccountSelector() {
    this.memberStore.pipe(select(setDepositAccountSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success) {
        this.bannerType = BannerType.Success;
        showBanner.call(this, STATE.SUCCESS, 'Deposit Account', ACTION.EDIT);
      }

      if (state?.error) {
        this.bannerType = BannerType.Fail;
        showBanner.call(this, STATE.FAIL, 'Deposit Account', ACTION.EDIT);
      }

      this.getData();
      this.memberStore.dispatch(clearSetDepositAccountStateAction());
    });
  }

  getMaskedFormat(length?: number) {
    let format = '';
    if (length! < 0) {
      return;
    }
    for (let index = 0; index < length!; index++) {
      format = format + 'X';
    }
    return format;
  }

  getViewName() {
    this.store
      .select(fromLayoutReducer.selectMemberNavigationListState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menu) => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] = menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });

          const menuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
          if (this.viewName) {
            this.listBreadcrumbs = [
              {
                url: this.viewUrl,
                name: this.viewName,
              },
              {
                name: menuName ?? 'Deposit Account List',
              },
            ];
          } else
            this.listBreadcrumbs[0] = {
              name: menuName ?? 'Deposit Account List',
            };
        }
      });
  }

  reloadOverviewHeader() {
    this.overViewHeaderEntity.memberId = this.memberId;
    this.overViewHeaderEntity.getMemberProfileData();
    this.overViewHeaderEntity.checkMemberIndex();
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;

    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.depositAccountTable.findIndex((item) => item.id === event.id);
  }

  onClickIcon(row: any) {
    row.visibilityOff = !row.visibilityOff;
  }

  onEditDepositAccount(event: any) {
    let data = this.depositAccountTable.length > 0 ? this.depositAccountTable[this.currentRowIndex] : null;

    this.dialog.open(UpsertDepositAccountComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        isEdit: true,
        depositAccountDetail: data,
      },
    });
  }

  onAddDepositAccount(event: any) {
    this.dialog.open(UpsertDepositAccountComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
      },
    });
  }
}
