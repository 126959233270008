import { FlatType, FillingStatusType, FederalTableFormType } from '@ptg-processing/features/taxes/types/enums';

export const FEDERAL_TABLE_FORM_TYPE_LABEL = {
  [FederalTableFormType.None]: 'None',
  [FederalTableFormType.W4]: 'W-4 2019 or Earlier',
  [FederalTableFormType.W4P]: 'W-4P; W-4 2020 or Later',
};

export const FILLING_STATUS_TYPE_LABEL = {
  [FillingStatusType.None]: 'None',
  [FillingStatusType.Single]: 'Single',
  [FillingStatusType.Married]: 'Married',
  [FillingStatusType.SingleOrMarried]: 'Single or Married filling separately',
  [FillingStatusType.MarriedOrQualifySpouse]: 'Married filling jointly or Qualifying surviving spouse',
  [FillingStatusType.HeadOfHousehold]: 'Head of household',
};

export const FLAT_TYPE_LABEL = {
  [FlatType.None]: 'None',
  [FlatType.Flat]: 'Flat Amount',
  [FlatType.Percent]: 'Percent',
};
