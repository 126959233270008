import { createAction, props } from '@ngrx/store';
import {
  GetDepositAccountsRequest,
  GetDepositAccountsResponse,
  UpsertDepositAccountRequest,
} from '@ptg-member/types/models/deposit-account.model';

export const getDepositAccountsAction = createAction(
  '[GetDepositAccounts/API] Send Request',
  props<{ request: GetDepositAccountsRequest }>(),
);

export const getDepositAccountsSuccessAction = createAction(
  '[GetDepositAccounts/API] Success',
  props<{ response: GetDepositAccountsResponse }>(),
);

export const getDepositAccountsFailureAction = createAction(
  '[GetDepositAccounts/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetDepositAccountsStateAction = createAction('[GetDepositAccounts] Clear');

export const createDepositAccountsAction = createAction(
  '[CreateDepositAccounts/API] Send Request',
  props<{ request: UpsertDepositAccountRequest }>(),
);

export const createDepositAccountsSuccessAction = createAction('[CreateDepositAccounts/API] Success');

export const createDepositAccountsFailureAction = createAction(
  '[CreateDepositAccounts/API] Failure',
  props<{ error?: any }>(),
);

export const clearCreateDepositAccountsStateAction = createAction('[CreateDepositAccounts] Clear');

export const setDepositAccountAction = createAction(
  '[SetDepositAccount/API] Send Request',
  props<{ request: UpsertDepositAccountRequest }>()
);

export const setDepositAccountSuccessAction = createAction(
  '[SetDepositAccount/API] Success'
);

export const setDepositAccountFailureAction = createAction(
  '[SetDepositAccount/API] Failure',
  props<{ error?: any }>()
);

export const clearSetDepositAccountStateAction = createAction(
  '[SetDepositAccount] Clear'
);

