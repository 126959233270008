import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromCurrentFund from '../fund-list/reducers/currentFund.reducer';
import { InjectionToken } from '@angular/core';
import { localStorageSync } from 'ngrx-store-localstorage';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  [fromCurrentFund.currentFundFeatureKey]: fromCurrentFund.State;
  router: fromRouter.RouterReducerState<any>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers current fund', {
  factory: () => ({
    [fromCurrentFund.currentFundFeatureKey]: fromCurrentFund.reducer,
    router: fromRouter.routerReducer,
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

/**
 * Here we declare localstorage reducer, in order if you wish to store object state into the localstorage.
 */

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['fundList'], rehydrate: true, restoreDates: false})(reducer);
}

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['layout'],
    rehydrate: true,
    storage: sessionStorage,
    removeOnUndefined: true,
    restoreDates: false,
  })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, sessionStorageSyncReducer];

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
// export const metaReducers: MetaReducer<State>[] = !environment.production
//   ? [logger]
//   : [];

  /**
 * Layout Selectors
 */
export const selectFundListState = createFeatureSelector<fromCurrentFund.State>(
  fromCurrentFund.currentFundFeatureKey
);

export const selectCurrentFundState = createSelector(
  selectFundListState,
  (state) => state.currentFund
);

export const selectListStateValue = createSelector(
  selectFundListState,
  (state) => state.getFundList
);

export const selectAddFundState = createSelector(
  selectFundListState,
  (state) => state.addFund
);

export const selectBrandRefreshed = createSelector(
  selectFundListState,
  (state) => state.fundLayout?.brandRefreshed
);

export const selectNavigationUpdated = createSelector(
  selectFundListState,
  (state) => state.fundLayout?.navigationUpdated
);

export const selectMetadataUpdated = createSelector(
  selectFundListState,
  (state) => state.fundLayout?.metadataUpdated
);

export const selectProfileNavigationUpdated = createSelector(
  selectFundListState,
  (state) => state.fundLayout?.profileNavigationUpdated
);

export const selectRemoveFundState = createSelector(
  selectFundListState,
  (state) => state.removeFund
);

export const selectTimeZonesValue = createSelector(
  selectFundListState,
  (state) => state.timeZones
);

export const selectNotificationAlert = createSelector(
  selectFundListState,
  (state) => state.notificationAlertModel
);

export const selectCreateNotificationAlertState = createSelector(
  selectFundListState,
  (state) => state.createNotificationAlert
);

export const selectFundInformationsState = createSelector(
  selectFundListState,
  (state) => state.fundInformations
);

/**
 * Router Selectors
 */
export const { selectRouteData } = fromRouter.getSelectors();
