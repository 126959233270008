import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { clearCreateOffsetDeductionStateAction, createOffsetDeductionFailureAction, createOffsetDeductionSuccessAction } from '../actions/create-offset-deduction.action';

export interface State {
  createOffsetDeduction?: BaseActionState;
}

export const CreateOffsetDeductionKey = 'createOffsetDeduction';

const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(clearCreateOffsetDeductionStateAction, (state: State) => ({
    ...state,
    createOffsetDeduction: undefined
  })),
  on(createOffsetDeductionSuccessAction, (state: State) => ({
    ...state,
    createOffsetDeduction: {
      isLoading: false,
      success: true,
    }
  })),
  on(createOffsetDeductionFailureAction, (state: State) => ({
    ...state,
    createOffsetDeduction: {
      isLoading: false,
      success: false,
    }
  })),
);
