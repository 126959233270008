<div class="member-document-list">
  <div class="header-container">
    <div class="filter-select-container">
      <!-- <mat-select [(ngModel)]="selectedFilter" class="mt-2.5">
        <mat-select-trigger class="block">
          <mat-icon class="mt-1.5 ml-1.5">filter_alt</mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let option of filterOptions" [value]="option.value">
          <div (click)="onFilterSelect(option.value)">
            <mat-icon>{{ option.iconName }}</mat-icon>
            <span>{{ option.label }}</span>
          </div>
        </mat-option>
      </mat-select> -->
      <div class="filter-icon">
        <mat-icon (click)="onFilterSelect()" class="mt-1.5 ml-1.5">filter_alt</mat-icon>
      </div>
    </div>
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
      [noTruncateButton]="true"
      [functionButtons]="functionButtons"
      [settings]="breadcrumbSettings"
      [gearIconClasses]="DOCUMENT_LIST_GEAR_ICON_CLASSES"
      [menuPanelSettingClasses]="DOCUMENT_LIST_GEAR_MENU_PANEL_SETTINGS_CLASSES"
      (emitFunction)="emitFunction($event)"
    ></ptg-breadcrumb>

  </div>
  <div class="document-list-content flex flex-col">
    <div class="filter-container" *ngIf="currentFilters">
      <div class="current-filter-container">
        <ptg-document-view-current-filter
          [currentFilters]="currentFilters"
          (notExistsFilter)="clearFilter()"
        >
        </ptg-document-view-current-filter>
      </div>
      <div class="filter-action-button-container">
        <button
          *ngIf="!isApplyMunicipalityFilter"
          mat-raised-button
          type="button"
          class="submit-button"
          (click)="editFilterDialog()"
        >
          Edit
        </button>
        <button
          *ngIf="!isApplyMunicipalityFilter"
          mat-raised-button
          type="button"
          class="secondary"
          (click)="clearFilter()"
        >
          Clear Filter
        </button>
      </div>
    </div>
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>
    <div class="flex-grow overflow-hidden grid-section">
      <ptg-grid
        #gridDocumentList
        [data]="dataTable"
        [columns]="columns"
        [isLoading]="isLoading"
        [allowSelection]="true"
        [fitToParent]="true"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [hideScrollbar]="false"
        (pageChange)="onChangePage($event)"
        (selectionChange)="onChangeSelection()"
        (sortChange)="onChangeSort($event)"
        notFoundMessage="No Documents to Display"
      >
        <ng-template cellContent columnName="documentName" let-row>
          <div class="flex file-document-container" matTooltip="{{ row?.documentName }}"
          matTooltipClass="cs-tooltip">
              <!-- <span class="truncate"
                    matTooltip="{{ row?.documentName }}"
                    matTooltipClass="cs-tooltip"
                    matTooltipPosition="below"> -->
                    {{ row?.documentName }}
              <!-- </span> -->
          </div>
        </ng-template>
        <ng-template cellContent columnName="documentLocation" let-row>
          <div class="flex file-document-container">
            <a [href]="row.documentLocationRouter" class="file-document-name" target="_blank">
              <!-- <span class="truncate"> -->
                {{ row?.documentLocation }}
              <!-- </span> -->
            </a>
          </div>
        </ng-template>
        <ng-template cellContent columnName="documentTags" let-row>
          <div class="flex file-document-container">
              <!-- <span class="truncate"
                    matTooltip="{{ row?.documentTags }}"
                    matTooltipClass="cs-tooltip"
                    matTooltipPosition="below"> -->
                    {{ row?.documentTags }}
              <!-- </span> -->
          </div>
        </ng-template>
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <div
              (click)="downloadDocument(row)"
              class="file-document-name" target="_blank"
              matTooltip="{{ row?.fileName }}"
              matTooltipClass="cs-tooltip"
              matTooltipPosition="below">
              <!-- <span class="truncate"> -->
                {{ row?.fileName }}
              <!-- </span> -->
            </div>
          </div>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditDocument(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveDocument(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
