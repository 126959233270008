<ptg-dialog class="edit-payroll-settings flex flex-col" [title]="'Edit Payroll Settings'" [isFullScreen]="true">
  <!-- DIALOG CONTENT HEADER -->
  <ng-template dialogContentHeader>
    <ptg-payment-header [targetIdFromDialog]="data.memberId" [isShowInteractionComponents]="false"></ptg-payment-header>
  </ng-template>

  <!-- DIALOG CONTENT BODY -->
  <ng-template dialogBody>
    <div class="mb-4" *ngIf="bannerType">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>

    <div class="edit-payroll-settings__body" *ngIf="!isLoading; else loadingBlock">
      <div class="flex flex-col gap-4">
        <div class="action-buttons flex gap-2">
          <ptg-button
            classInput="primary"
            buttonName="Save"
            (clickButton)="onSave()"
            [isDisabled]="!!(isErrorOccurred | async)"
          ></ptg-button>
          <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
        </div>

        <ng-container *ngIf="!(isErrorOccurred | async)">
          <div class="representative-payee-section flex flex-col gap-2">
            <!-- ISSUE TO ESTATE CHECKBOX -->
            <ng-container *ngIf="showIssueToEstate">
              <ptg-checkbox
                [controlField]="issueToEstateControl"
                [isDisabled]="hasRepresentativePayeeControl.value"
                label="Issue to Estate"
              ></ptg-checkbox>

              <!-- PAYEE DROPDOWN LIST -->
              <ptg-select
                *ngIf="issueToEstateControl.value"
                placeholder="Payee"
                [controlField]="payeeControl"
                [listData]="estatePayeeList"
                [isOptionObj]="true"
                [isMultipleLineOption]="true"
                [isSetMaxWidthSelect]="true"
                class="w-full"
              ></ptg-select>
            </ng-container>

            <!-- REPRESENTATIVE PAYEE CHECKBOX -->
            <ptg-checkbox
              label="Representative Payee"
              [isDisabled]="issueToEstateControl.value"
              [controlField]="hasRepresentativePayeeControl"
            ></ptg-checkbox>

            <!-- REPRESENTATIVE PAYEE DROPDOWN LIST -->
            <ptg-select
              placeholder="Representative Payee"
              class="w-full"
              [controlField]="representativesPayeeControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [listData]="representativesPayeeNameList"
              [isMultipleLineOption]="true"
              *ngIf="showRepresentativePayeeName"
            ></ptg-select>

            <!-- NAME PRINTED ON CHECK TEXTBOX -->
            <ptg-textbox
              placeholder="Name Printed on Check"
              class="w-full"
              [controlField]="namePrintedOnCheckControl"
              [hasLabel]="true"
              [maxLength]="maxCharacterLengthForTextBox"
              *ngIf="showNamePrintOnCheck"
            ></ptg-textbox>

            <!-- PAYMENT ADDRESS DROPDOWN LIST -->
            <ptg-select
              placeholder="Payment Address"
              class="w-full mb-[-0.5rem]"
              [controlField]="paymentAddressControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [listData]="paymentAddressList"
              [isMultipleLineOption]="true"
              [isOneTimeType]="true"
              [hasNoneValue]="true"
            ></ptg-select>
          </div>

          <hr *ngIf="showFederalWithholdingColumn" />

          <div class="flex gap-12" *ngIf="showFederalWithholdingColumn">
            <div
              class="federal-section flex flex-col gap-2"
              [ngClass]="showStateWithholdingColumn ? 'w-1/2' : 'w-full'"
            >
              <div class="text-xl font-bold mb-2">Federal</div>
              <ptg-select
                placeholder="Form"
                class="w-full"
                [controlField]="federalControls.form"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="formList"
                [isClearError]="false"
                errorRequire="Form is required"
              ></ptg-select>
              <ptg-select
                placeholder="Withholding"
                class="w-full"
                [controlField]="federalControls.withholding"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="taxTableList"
                [isClearError]="false"
                errorRequire="Withholding is required"
              ></ptg-select>
              <ptg-textbox
                placeholder="Exemption"
                class="w-full"
                [controlField]="federalControls.exemption"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_NUMBER"
                [isDecimal]="false"
                [isPositive]="true"
                *ngIf="federalComponentVisibility.showExemption"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Income from Job/Pension/Annuities"
                class="w-full"
                [controlField]="federalControls.incomeFromJobPensionAnnuities"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="federalComponentVisibility.showIncomeFromJobPensionAnnuities"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Claim Dependents"
                class="w-full"
                [controlField]="federalControls.claimDependents"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="federalComponentVisibility.showClaimDependents"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Additional Income"
                class="w-full"
                [controlField]="federalControls.additionalIncome"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="federalComponentVisibility.showAdditionalIncome"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Additional Deductions"
                class="w-full"
                [controlField]="federalControls.additionalDeductions"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="federalComponentVisibility.showAdditionalDeductions"
              ></ptg-textbox>
              <ptg-select
                placeholder="Additional Withholding"
                class="w-full"
                [controlField]="federalControls.additionalWithholding"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="additionalWithholdingList"
              ></ptg-select>
              <ptg-textbox
                placeholder="Flat Amount"
                class="w-full"
                [controlField]="federalControls.flatAmount"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="federalComponentVisibility.showFlatAmount"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Percent"
                class="w-full"
                [controlField]="federalControls.percent"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_PERCENTAGE"
                [min]="minValueForPercentage"
                [max]="maxValueForPercentage"
                prefix=""
                suffix="%"
                *ngIf="federalComponentVisibility.showPercent"
              ></ptg-textbox>
            </div>

            <div class="state-section w-1/2 flex flex-col gap-2" *ngIf="showStateWithholdingColumn">
              <div class="text-xl font-bold mb-2">State</div>
              <ptg-select
                placeholder="Form"
                class="w-full"
                [controlField]="stateControls.form"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="formList"
                [isClearError]="false"
                errorRequire="Form is required"
              ></ptg-select>
              <ptg-select
                placeholder="Withholding"
                class="w-full"
                [controlField]="stateControls.withholding"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="taxTableList"
                [isClearError]="false"
                errorRequire="Withholding is required"
              ></ptg-select>
              <ptg-textbox
                placeholder="Exemption"
                class="w-full"
                [controlField]="stateControls.exemption"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_NUMBER"
                [isDecimal]="false"
                [isPositive]="true"
                *ngIf="stateComponentVisibility.showExemption"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Income from Job/Pension/Annuities"
                class="w-full"
                [controlField]="stateControls.incomeFromJobPensionAnnuities"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="stateComponentVisibility.showIncomeFromJobPensionAnnuities"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Claim Dependents"
                class="w-full"
                [controlField]="stateControls.claimDependents"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="stateComponentVisibility.showClaimDependents"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Additional Income"
                class="w-full"
                [controlField]="stateControls.additionalIncome"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="stateComponentVisibility.showAdditionalIncome"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Additional Deductions"
                class="w-full"
                [controlField]="stateControls.additionalDeductions"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="stateComponentVisibility.showAdditionalDeductions"
              ></ptg-textbox>
              <ptg-select
                placeholder="Additional Withholding"
                class="w-full"
                [controlField]="stateControls.additionalWithholding"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="additionalWithholdingList"
              ></ptg-select>
              <ptg-textbox
                placeholder="Flat Amount"
                class="w-full"
                [controlField]="stateControls.flatAmount"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [min]="minValueForCurrency"
                [max]="maxValueForCurrency"
                [maxLength]="maxCharacterLengthForTextBox"
                *ngIf="stateComponentVisibility.showFlatAmount"
              ></ptg-textbox>
              <ptg-textbox
                placeholder="Percent"
                class="w-full"
                [controlField]="stateControls.percent"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_PERCENTAGE"
                [min]="minValueForPercentage"
                [max]="maxValueForPercentage"
                prefix=""
                suffix="%"
                *ngIf="stateComponentVisibility.showPercent"
              ></ptg-textbox>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ptg-dialog>

<ng-template #loadingBlock>
  <div class="spinner-wrapper" [class.active]="isLoading">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>
