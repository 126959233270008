import { createReducer, on } from '@ngrx/store';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  GetDocumentListConfigurationPropertiesResponse,
  GetDocumentListConfigurationDetailResponse,
} from '../../services/models/document-list-configuration.model';
import {
  getDocumentListConfigurationPropertiesAction,
  getDocumentListConfigurationPropertiesSuccessAction,
  getDocumentListConfigurationPropertiesFailureAction,
  clearGetDocumentListConfigurationPropertiesStateAction,
  clearGetDocumentListConfigurationDetailStateAction,
  getDocumentListConfigurationDetailAction,
  getDocumentListConfigurationDetailFailureAction,
  getDocumentListConfigurationDetailSuccessAction,
  clearUpdateDocumentListConfigurationStateAction,
  updateDocumentListConfigurationAction,
  updateDocumentListConfigurationFailureAction,
  updateDocumentListConfigurationSuccessAction,
} from '../actions/document-list-configuration.action';

export interface DocumentListConfigurationState {
  getDocumentListConfigurationPropertiesState?: BaseActionState<GetDocumentListConfigurationPropertiesResponse>;
  getDocumentListConfigurationDetailState?: BaseActionState<GetDocumentListConfigurationDetailResponse>;
  updateDocumentListConfigurationState?: {
    state?: ActionState,
    errorMsg?: string,
    isLoading?: boolean,
  };
}

const initialState: DocumentListConfigurationState = {};

export const documentListConfigurationReducer = createReducer(
  initialState,

  // Get Document List Configuration Properties
  on(getDocumentListConfigurationPropertiesAction, (state) => ({
    ...state,
    getDocumentListConfigurationPropertiesState: {
      isLoading: true,
    },
  })),
  on(getDocumentListConfigurationPropertiesSuccessAction, (state, { response }) => ({
    ...state,
    getDocumentListConfigurationPropertiesState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getDocumentListConfigurationPropertiesFailureAction, (state, { error }) => ({
    ...state,
    getDocumentListConfigurationPropertiesState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDocumentListConfigurationPropertiesStateAction, (state) => ({
    ...state,
    getDocumentListConfigurationPropertiesState: undefined,
  })),

  // Get Document List Configuration Detail
  on(getDocumentListConfigurationDetailAction, (state) => ({
    ...state,
    getDocumentListConfigurationDetailState: {
      isLoading: true,
    },
  })),
  on(getDocumentListConfigurationDetailSuccessAction, (state, { response }) => ({
    ...state,
    getDocumentListConfigurationDetailState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getDocumentListConfigurationDetailFailureAction, (state, { error }) => ({
    ...state,
    getDocumentListConfigurationDetailState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDocumentListConfigurationDetailStateAction, (state) => ({
    ...state,
    getDocumentListConfigurationDetailState: undefined,
  })),

  // Update Document List Configuration
  on(updateDocumentListConfigurationAction, (state) => ({
    ...state,
    updateDocumentListConfigurationState: {
      isLoading: true,
    },
  })),
  on(updateDocumentListConfigurationSuccessAction, (state, { }) => ({
    ...state,
    updateDocumentListConfigurationState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      },
      isLoading: false,
    },
  })),
  on(updateDocumentListConfigurationFailureAction, (state, { errorMsg }) => ({
    ...state,
    updateDocumentListConfigurationState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
      isLoading: false,
    },
  })),
  on(clearUpdateDocumentListConfigurationStateAction, (state) => ({
    ...state,
    updateDocumentListConfigurationState: undefined,
  })),
);