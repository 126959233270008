import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';
import * as fromCreateOffsetDeductionReducer from '../reducers/create-offset-deduction.reducer';

export const createOffsetDeductionSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state[fromCreateOffsetDeductionReducer.CreateOffsetDeductionKey]
);

export const createOffsetDeductionSateSelector = createSelector(
  createOffsetDeductionSelector,
  (state) => state.createOffsetDeduction
);