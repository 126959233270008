import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PageEvent } from '@ptg-shared/controls/pagination';
import { Column, Row } from '@ptg-shared/controls/grid';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberManageAttachmentListComponent } from '../member-manage-attachment-list/member-manage-attachment-list.component';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components/base.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Store } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers';
import { GUID_EMPTY } from '@ptg-shared/constance';
import { EntityViewItem } from '@ptg-entity-management/services/models';
import { CardComponentType } from '@ptg-entity-management/types/enums';
import { isEqual } from '@ptg-shared/utils/string.util';
import { ENTITY_MAPPING } from '@ptg-entity-management/constants';
@Component({
  selector: 'ptg-member-navigation-list-view',
  templateUrl: './member-navigation-list-view.component.html',
  styleUrls: ['./member-navigation-list-view.component.scss'],
})
export class MemberNavigationListViewComponent extends BaseComponent {
  @Input() dataTable: any = [];
  @Input() columns!: Column[];
  @Input() sortInfo: any = {};
  @Input() lengthPg!: number | any;
  @Input() pageSize: number = 7;
  @Input() pageNumber: number = 1;
  @Input() isLoading!: boolean;
  @Input() currentRowIndex: number = 0;
  @Input() menuItemName!: string;
  @Input() menuName?: string;
  @Input() memberId?: string;
  @Input() errorMsg!: string;
  @Input() isListRecord: boolean | undefined;
  @Input() isDragDrop: boolean = false;
  @Input() isBenefitCard: boolean = false;
  @Input() isCourtOrder: boolean = false;
  @Input() cardId: string = '';
  @Input() cardName: string = '';
  @Input() cardEntity?: EntityViewItem;
  @Input() isServiceHistoryCard: boolean = false;
  @Input() entityComponentId: string = '';
  @Input() menuItemId: string = '';
  @Input() isOverview: boolean = false;
  @Input() currentEntityReferenceLinkedId: string = '';
  
  @Output() selectRowEvent = new EventEmitter();
  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter<PageEvent>();
  @Output() addNewMetaDataEvent = new EventEmitter();
  @Output() manageSectionEvent = new EventEmitter();
  @Output() currentRowIndexChange = new EventEmitter<number>();

  currentUrlQueryParams: any = null;
  viewId: string = '';
  isInactiveActionAddRelationships: boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((queryParams) => {
        this.currentUrlQueryParams = queryParams;
      });
    
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.viewId = params?.viewId ?? '';
    });

    this.isInactiveActionAddRelationships = this.cardEntity?.cardComponentType === CardComponentType.Relationships
      && (isEqual(this.cardEntity?.entityId, ENTITY_MAPPING.Person) || isEqual(this.cardEntity?.entityId, ENTITY_MAPPING.Participant));
  }

  selectRow(event: any) {
    this.selectRowEvent.emit(event);
    this.currentRowIndexChange.emit(this.currentRowIndex);
  }

  sortChange(event: any) {
    this.sortChangeEvent.emit(event);
  }

  changePagging(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }
  addNew(event: any) {
    if (this.isLoading) {
      return;
    }
    this.addNewMetaDataEvent.emit(event);
  }
  manageSection(event: any) {
    if (this.isLoading) {
      return;
    }
    this.manageSectionEvent.emit(event);
  }

  viewAttachment(row: Row) {
    const listBreadcrumbs = [{
      name: this.menuName || '',
      url: this.router.url
    },
    {
      name:'Attachments'
    }];
    this.dialog.open(MemberManageAttachmentListComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        breadcrumbs: listBreadcrumbs,
        recordId: row?.id,
        menuName: `${this.menuName}`,
        menuId: this.menuItemId,
        isOverview: this.isOverview,
        entityComponentId: row?.['values']?.[0]?.entityComponentId ?? this.entityComponentId,
        currentEntityReferenceLinkedId: this.currentEntityReferenceLinkedId,
        cardId: this.cardId ?? '',
        cardName: this.cardName,
        viewId: this.viewId,
        currentRowIndex: this.currentRowIndex,
        isServiceHistoryCard: this.isServiceHistoryCard,
      },
    });
  }

  openAuditTrailsScreen() {
    let url = deepClone(this.router.url);
    url = url.replace('member/', 'member/audit-trails/');

    const existQueryParams = Object.keys(this.currentUrlQueryParams)?.length >= 1;
    const currentRowIdFromUrl = this.currentUrlQueryParams?.currentRowId ?? '';

    if (currentRowIdFromUrl) {
      if (this.viewId === GUID_EMPTY) {
        // Edge case, current View is not configured yet
        url = url + `${existQueryParams ? '&' : '?'}isFromUnconfiguredViewScreen=true`;
      } else {
        // Normal case, current View is configured, trigger by clicked a list record of Summary View
        url = url + `${existQueryParams ? '&' : '?'}isFromDetailViewScreen=true`;
      }
    } else {
      // Normal case, current View is configured, trigger by clicked left-menu
      url = url + `${existQueryParams ? '&' : '?'}isFromDetailViewScreen=true${this.cardId ? '&cardId=' + this.cardId : ''}`;
    }

    this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
    this.store.dispatch(
      LayoutActions.selectTab({
        tab: DisplayedTabName.IndividualParticipant,
        url,
      }),
    );
  }
}
