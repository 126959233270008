import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaymentInfoTabService } from '../../services';
import {
  cancelPaymentInstructionAction,
  cancelPaymentInstructionFailureAction,
  cancelPaymentInstructionSuccessAction,
  checkDisplayOffsetButtonAction,
  checkDisplayOffsetButtonFailureAction,
  checkDisplayOffsetButtonSuccessAction,
  getInstructionHistoryAction,
  getInstructionHistoryFailureAction,
  getInstructionHistorySuccessAction,
  getPaymentInfoTabsAction,
  getPaymentInfoTabsFailureAction,
  getPaymentInfoTabsSuccessAction,
  getRecalculateTaxesAction,
  getRecalculateTaxesFailureAction,
  getRecalculateTaxesSuccessAction,
} from '../actions/payment-info-tab.action';
import {
  CheckDisplayOffsetButtonResponse,
  GetInstructionHistoryResponse,
  GetPaymentInfoTabsResponse,
  GetRecalculateTaxesResponse,
} from '../../services/models';

@Injectable()
export class PaymentInfoTabEffect {
  constructor(
    private actions$: Actions,
    private paymentInfoTabService: PaymentInfoTabService,
  ) {}

  getPaymentInfoTabs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentInfoTabsAction),
      switchMap(({ request }) =>
        this.paymentInfoTabService.getPaymentInfoTabs(request).pipe(
          map((response: GetPaymentInfoTabsResponse) => getPaymentInfoTabsSuccessAction({ response })),
          catchError((error) => of(getPaymentInfoTabsFailureAction({ error }))),
        ),
      ),
    ),
  );
  getInstructionHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstructionHistoryAction),
      switchMap(({ request }) =>
        this.paymentInfoTabService.getInstructionHistories(request).pipe(
          map((response: GetInstructionHistoryResponse) => getInstructionHistorySuccessAction({ response })),
          catchError((error) => of(getInstructionHistoryFailureAction({ error }))),
        ),
      ),
    ),
  );
  getRecalculateTaxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecalculateTaxesAction),
      switchMap(({ request }) =>
        this.paymentInfoTabService.getRecalculateTaxes(request).pipe(
          map((response: GetRecalculateTaxesResponse) => getRecalculateTaxesSuccessAction({ response })),
          catchError((error) => of(getRecalculateTaxesFailureAction({ error }))),
        ),
      ),
    ),
  );
  cancelPaymentInstruction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelPaymentInstructionAction),
      switchMap(({ request }) =>
        this.paymentInfoTabService.cancelPaymentInstruction(request).pipe(
          map(() => cancelPaymentInstructionSuccessAction()),
          catchError((error) => of(cancelPaymentInstructionFailureAction({ error }))),
        ),
      ),
    ),
  );

  checkDisplayOffsetButton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkDisplayOffsetButtonAction),
      switchMap(({ request }) =>
        this.paymentInfoTabService.checkDisplayOffsetButton(request).pipe(
          map((response: CheckDisplayOffsetButtonResponse) => checkDisplayOffsetButtonSuccessAction({ response })),
          catchError((error) => of(checkDisplayOffsetButtonFailureAction({ error }))),
        ),
      ),
    ),
  );
}
