<div class="entity-detail-view-container">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [settings]="settings"
    [isDisabledGear]="isDisabledGear"
  ></ptg-breadcrumb>

  <ptg-entity-overview-header></ptg-entity-overview-header>

  <div class="entity-detail-view-content flex flex-1 flex-col p-6 gap-6">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <!-- Detail Section -->
    <ptg-panel
      *ngIf="viewType === ViewType.ListAndProperty || viewType === ViewType.PropertyOnly"
      [title]="detailSectionLabel"
      [noPaddingBody]="true"
      [ngClass]="{ 'background-color': displayRule === StandalonePropertiesDisplayRule.HideEditButtonAndHighlight }"
    >
      <ng-template panelExtraHeader>
        <div
          *ngIf="displayRule === StandalonePropertiesDisplayRule.None"
          class="edit-btn"
          (click)="onClickEditDetail()"
        >
          <mat-icon>edit</mat-icon>
          <span class="title-edit"> Edit</span>
        </div>
      </ng-template>

      <ng-template panelBody>
        <ptg-entity-standalone-property
          *ngIf="viewType === ViewType.ListAndProperty || viewType === ViewType.PropertyOnly"
          [isEditable]="isEditable"
          [currentRow]="standalonePropertyValues"
          [metadataSection]="standaloneProperties"
          [currentSectionLayout]="standaloneViewType"
          [isLoading]="isLoadingStandalone"
        ></ptg-entity-standalone-property>
      </ng-template>
    </ptg-panel>

    <ng-container *ngIf="viewType === ViewType.ListAndProperty || viewType === ViewType.ListOnly">
      <div class="flex gap-6">
        <div
          *ngIf="
            listDetailViewProperties?.length &&
            (viewType === ViewType.ListAndProperty || viewType === ViewType.ListOnly) &&
            selectedRecordData
          "
          class="flex-1"
        >
          <!-- Selected Record -->
          <ptg-entity-detail-card-selected
            *ngIf="
              listDetailViewProperties?.length &&
              selectedRecordData &&
              (viewType === ViewType.ListAndProperty || viewType === ViewType.ListOnly)
            "
            [menuItemName]="listDetailViewLabel"
            [isList]="isList"
            [currentRow]="selectedRecordData"
            [metadataSection]="listDetailViewProperties"
            [isLoading]="isLoadingListDetailView"
            [attachmentType]="attachmentType"
            [isBenefitCard]="!!card?.isBenefitCard"
            (editButtonEvent)="onClickEditRecordSelected($event)"
            (removeButtonEvent)="onClickRemoveRecordSelected($event)"
          ></ptg-entity-detail-card-selected>
        </div>

        <div class="flex-1" *ngIf="attachmentType === IncludeAttachmentType.ListRecord">
          <!-- Manage Attachment -->
          <ptg-entity-attachment-list
            [isEntity]="true"
            [viewId]="profileConfigOverview?.entityViewId ?? ''"
            [cardId]="card?.cardId ?? ''"
            [title]="pageTitle"
            [rowId]="targetId"
            [dataTable]="attachments"
            [isLoading]="isLoadingListDetailView"
            [lengthPg]="attLengthPg"
            [pageSize]="attPageSize"
            [pageNumber]="attPageNumber"
            (pageChange)="onChangePageManageAttachments($event)"
          ></ptg-entity-attachment-list>
        </div>
      </div>

      <!--List Card-->
      <ptg-entity-detail-card-list
        [isLoading]="isLoadingListDetailView"
        [dataTable]="listCardData"
        [columns]="columns"
        [sortInfo]="sortInfo"
        [lengthPg]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [currentRowIndex]="currentRowIndex"
        [menuItemName]="listDetailViewLabel"
        [errorMsg]="errorMsg"
        [sectionHasAttachment]="attachmentType !== IncludeAttachmentType.NoAttachment"
        [isDragDrop]="isDragDrop"
        [isBenefitCard]="!!card?.isBenefitCard"
        (changePaggingEvent)="changePaggingListView($event)"
        (addNewMetaDataEvent)="openMetaDataForm($event)"
        (manageSectionEvent)="manageSection()"
        (sortChangeEvent)="sortChangeListData($event)"
        (selectRowEvent)="selectRow($event)"
      ></ptg-entity-detail-card-list>
    </ng-container>

    <div *ngIf="isNoProfileConfigured" class="center-msg">
      <div class="not-configured">
        <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
        <span class="message">Profile has not been configured yet</span>
      </div>
    </div>
  </div>
</div>
