import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  AccidentLetterResponse,
  GetAccidentClaimsResponse,
  GetAccidentHistoriesResponse,
  GetAccidentNotesResponse,
  GetListMunicipalityResponse,
  ViewAccidentDetailsResponse
} from '../../services/models';
import {
  createAccidentClaimsAction,
  createAccidentClaimsFailureAction,
  createAccidentClaimsSuccessAction,
  addAccidentPostCardAction,
  addAccidentPostCardFailureAction,
  addAccidentPostCardSuccessAction,
  addAccidentReportAction,
  addAccidentReportFailureAction,
  addAccidentReportSuccessAction,
  editAccidentPostCardAction,
  editAccidentPostCardFailureAction,
  editAccidentPostCardSuccessAction,
  editAccidentReportAction,
  editAccidentReportFailureAction,
  editAccidentReportSuccessAction,
  getAccidentClaimDetailAction,
  getAccidentClaimDetailFailureAction,
  getAccidentClaimDetailSuccessAction,
  getAccidentClaimsAction,
  getAccidentClaimsFailureAction,
  getAccidentClaimsSuccessAction,
  getAccidentHistoriesAction,
  getAccidentHistoriesFailureAction,
  getAccidentHistoriesSuccessAction,
  getListMunicipalityAction,
  getListMunicipalityFailureAction,
  getListMunicipalitySuccessAction,
  removeAccidentClaimAction,
  removeAccidentClaimFailureAction,
  removeAccidentClaimSuccessAction,
  removeAccidentHistoryAction,
  removeAccidentHistoryFailureAction,
  removeAccidentHistorySuccessAction,
  getVendorsMasterDataAction,
  getVendorsMasterDataSuccessAction,
  getVendorsMasterDataFailureAction,
  getDisbursementReportsAction,
  getDisbursementReportsSuccessAction,
  getDisbursementReportsFailureAction,
  editAccidentClaimsAction,
  editAccidentClaimsSuccessAction,
  editAccidentClaimsFailureAction,
  viewAccidentDetailsAction,
  viewAccidentDetailsFailureAction,
  viewAccidentDetailsSuccessAction,
  getAccidentDocumentsAction,
  getAccidentDocumentsSuccessAction,
  getAccidentDocumentsFailureAction,
  uploadAccidentDocumentsAction,
  uploadAccidentDocumentsSuccessAction,
  uploadAccidentDocumentsFailureAction,
  createAccidentNoteAction,
  createAccidentNoteSuccessAction,
  createAccidentNoteFailureAction,
  getAccidentNotesAction,
  getAccidentNotesSuccessAction,
  getAccidentNotesFailureAction,
  getAccidentDocumentDownloadAction,
  getAccidentDocumentDownloadFailureAction,
  getAccidentDocumentDownloadSuccessAction,
  editParticipantAccidentStatusAction,
  editParticipantAccidentStatusFailureAction,
  editParticipantAccidentStatusSuccessAction,
  removeAccidentDocumentAction,
  removeAccidentDocumentSuccessAction,
  removeAccidentDocumentFailureAction,
  getAccidentPostcardLetterAction,
  getAccidentPostcardLetterSuccessAction,
  getAccidentPostcardLetterFailureAction,
  getAccidentReportLetterAction,
  getAccidentReportLetterSuccessAction,
  getAccidentReportLetterFailureAction,
  editAccidentDocumentAction,
  editAccidentDocumentFailureAction,
  editAccidentDocumentSuccessAction,
  getAccidentClaimsReportAction,
  getAccidentClaimsReportFailureAction,
  getAccidentClaimsReportSuccessAction,
} from '../actions';
import { AccidentParticipantsService } from '../../services/accident-participants.service';
import { GetDisbursementReportsResponse, GetVendorsResponse } from 'src/app/admin/features/external-payment/services/models';
import { downloadFile } from '@ptg-shared/utils/common.util';

@Injectable()
export class AccidentClaimsEffects {
  constructor(
    private actions$: Actions,
    private accidentParticipantsService: AccidentParticipantsService
  ) { }

  getAccidentClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentClaimsAction),
      switchMap(({ request, memberId }) =>
        this.accidentParticipantsService.getAccidentClaims(request, memberId).pipe(
          map((response: GetAccidentClaimsResponse) => getAccidentClaimsSuccessAction({ response })),
          catchError((error) => of(getAccidentClaimsFailureAction({ error })))
        )
      )
    )
  );

  getAccidentHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentHistoriesAction),
      switchMap(({ request, memberId }) =>
        this.accidentParticipantsService.getAccidentHistories(request, memberId).pipe(
          map((response: GetAccidentHistoriesResponse) => getAccidentHistoriesSuccessAction({ response })),
          catchError((error) => of(getAccidentHistoriesFailureAction({ error })))
        )
      )
    )
  );

  getListMinicipality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListMunicipalityAction),
      switchMap(({ memberId, accidentDate }) =>
        this.accidentParticipantsService.getListMunicipality(memberId, accidentDate).pipe(
          map((response: GetListMunicipalityResponse) => getListMunicipalitySuccessAction({ response })),
          catchError((error) => of(getListMunicipalityFailureAction({ error })))
        )
      )
    )
  );

  addAccidentPostCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAccidentPostCardAction),
      switchMap(({ memberId, accidentPostCardRequest }) =>
        this.accidentParticipantsService.addAccidentPostCard(memberId, accidentPostCardRequest).pipe(
          map((accidentId) => addAccidentPostCardSuccessAction({ accidentId: accidentId.toString() })),
          catchError((error) => of(addAccidentPostCardFailureAction({ error })))
        )
      )
    )
  );

  editAccidentPostCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAccidentPostCardAction),
      switchMap(({ memberId, accidentPostCardRequest }) =>
        this.accidentParticipantsService.editAccidentPostCard(memberId, accidentPostCardRequest).pipe(
          map(() => editAccidentPostCardSuccessAction()),
          catchError((error) => of(editAccidentPostCardFailureAction({ error })))
        )
      )
    )
  );

  removeAccidentHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAccidentHistoryAction),
      switchMap(({ memberId, accidentId }) => {
        return this.accidentParticipantsService.removeAccidentHistory(memberId, accidentId).pipe(
          map(() => removeAccidentHistorySuccessAction()),
          catchError((error) => {
            let errorMessage = "Accident status has been changed to 'Denied'. Cannot remove claim."
            if (error?.error?.errorType === 'CommandException') {
              errorMessage ?? '';
            }
            return of(removeAccidentHistoryFailureAction({ error: errorMessage }))
          })
        )
      })
    )
  );

  createAccidentClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAccidentClaimsAction),
      switchMap(({ request, memberId, accidentId }) =>
        this.accidentParticipantsService.createAccidentClaims(request, memberId, accidentId).pipe(
          map(() => createAccidentClaimsSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(createAccidentClaimsFailureAction({ error: errorMessage }));
          })
        )
      )
    )
  );
  removeAccidentClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAccidentClaimAction),
      switchMap(({ memberId, accidentId, accidentClaimId }) => {
        return this.accidentParticipantsService.removeAccidentClaim(memberId, accidentId, accidentClaimId).pipe(
          map(() => removeAccidentClaimSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage[1] ?? '';
            }
            return of(removeAccidentClaimFailureAction({ error: errorMessage }))
          })
        )
      })
    )
  );

  getAccidentClaimDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentClaimDetailAction),
      switchMap(({ request, memberId, accidentId }) =>
        this.accidentParticipantsService.getAccidentClaimDetail(request, memberId, accidentId).pipe(
          map((response: GetAccidentClaimsResponse) => getAccidentClaimDetailSuccessAction({ response })),
          catchError((error) => of(getAccidentClaimDetailFailureAction({ error })))
        )
      )
    )
  );

  viewAccidentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewAccidentDetailsAction),
      switchMap(({ memberId, accidentId }) =>
        this.accidentParticipantsService.viewAccidentDetails(memberId, accidentId).pipe(
          map((response: ViewAccidentDetailsResponse) => viewAccidentDetailsSuccessAction({ response })),
          catchError((error) => of(viewAccidentDetailsFailureAction({ error })))
        )
      )
    )
  );

  editAccidentClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAccidentClaimsAction),
      switchMap(({ request, memberId, accidentId, accidentClaimId }) =>
        this.accidentParticipantsService.editAccidentClaims(request, memberId, accidentId, accidentClaimId).pipe(
          map(() => editAccidentClaimsSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(editAccidentClaimsFailureAction({ error: errorMessage }));
          })
        )
      )
    )
  );

  addAccidentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAccidentReportAction),
      switchMap(({ memberId, accidentId, accidentReportRequest }) =>
        this.accidentParticipantsService.addAccidentReport(memberId, accidentId, accidentReportRequest).pipe(
          map(() => addAccidentReportSuccessAction()),
          catchError((error) => of(addAccidentReportFailureAction({ errorMsg: error.message, })))
        )
      )
    )
  );

  editAccidentReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAccidentReportAction),
      switchMap(({ memberId, accidentId, accidentReportRequest }) =>
        this.accidentParticipantsService.editAccidentReport(memberId, accidentId, accidentReportRequest).pipe(
          map(() => editAccidentReportSuccessAction()),
          catchError((error) => of(editAccidentReportFailureAction({ errorMsg: error.message, })))
        )
      )
    )
  );

  editParticipantAccidentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editParticipantAccidentStatusAction),
      switchMap(({ memberId, accidentId, editParticipantAccidentStatusRequest }) =>
        this.accidentParticipantsService.editParticipantAccidentStatus(memberId, accidentId, editParticipantAccidentStatusRequest).pipe(
          map(() => editParticipantAccidentStatusSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(editParticipantAccidentStatusFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  // For Vendor List
  getVendorsMasterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVendorsMasterDataAction),
      switchMap(({ request, clientId }) =>
        this.accidentParticipantsService.getVendorsMasterData(request, clientId).pipe(
          map((response: GetVendorsResponse) => getVendorsMasterDataSuccessAction({ response })),
          catchError((error) => of(getVendorsMasterDataFailureAction({ error })))
        )
      )
    )
  );

  // For Disbursement List
  getDisbursementReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDisbursementReportsAction),
      switchMap(({ request, clientId }) =>
        this.accidentParticipantsService.getDisbursementReports(request, clientId).pipe(
          map((response: GetDisbursementReportsResponse) => getDisbursementReportsSuccessAction({ response })),
          catchError((error) => of(getDisbursementReportsFailureAction({ error })))
        )
      )
    )
  );

  getAccidentDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentDocumentsAction),
      switchMap(({ request, memberId, accidentId }) =>
        this.accidentParticipantsService.getAccidentDocuments(request, memberId, accidentId).pipe(
          map((response: any) => getAccidentDocumentsSuccessAction({ response })),
          catchError((error) => of(getAccidentDocumentsFailureAction({ error })))
        )
      )
    )
  );

  uploadAccidentDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadAccidentDocumentsAction),
      switchMap(({request, memberId, accidentId}) =>
        this.accidentParticipantsService.uploadAccidentDocuments(request, memberId, accidentId).pipe(
          map(() => uploadAccidentDocumentsSuccessAction()),
          catchError((error) => of(uploadAccidentDocumentsFailureAction({ error })))
        )
      )
    )
  );

  getAccidentDocumentDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentDocumentDownloadAction),
      switchMap(({ request }) =>
        this.accidentParticipantsService.dowloadAccidentDocument(request).pipe(
          map((response: Blob) => {
            return getAccidentDocumentDownloadSuccessAction({ response: [...[response]] });
          }),
          catchError((error) => of(getAccidentDocumentDownloadFailureAction({ error })))
        )
      )
    )
  );

  getAccidentPostcardLetter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentPostcardLetterAction),
      switchMap(({ request }) =>
        this.accidentParticipantsService.getAccidentPostcardLetter(request).pipe(
          map((response) => getAccidentPostcardLetterSuccessAction({ response } )),
          catchError((error) => of(getAccidentPostcardLetterFailureAction({ error })))
        )
      )
    )
  );

  getAccidentReportLetter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentReportLetterAction),
      switchMap(({ request }) =>
        this.accidentParticipantsService.getAccidentReportLetter(request).pipe(
          map((response: AccidentLetterResponse) => getAccidentReportLetterSuccessAction({ response })),
          catchError((error) => of(getAccidentReportLetterFailureAction({ error })))
        )
      )
    )
  );

  getAccidentClaimsReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentClaimsReportAction),
      switchMap(({ request, fileName }) =>
        this.accidentParticipantsService.getAccidentClaimsReport(request).pipe(
          map((response) => {
            if (response) {
              downloadFile.call(this, response, fileName ?? '');
              return getAccidentClaimsReportSuccessAction();
            }
            return getAccidentClaimsReportSuccessAction();
          }),
          catchError((err) => {
            return of(
              getAccidentClaimsReportFailureAction({
                error: "File not found",
              })
            );
          })
        )
      )
    )
  );

  createAccidentNote$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createAccidentNoteAction),
        switchMap(({ note, memberId, accidentId }) =>
          this.accidentParticipantsService.createAccidentNote(note, memberId, accidentId).pipe(
            map(() => createAccidentNoteSuccessAction()),
            catchError((error) => of(createAccidentNoteFailureAction({ error: error.message, })))
          )
        )
      )
    );

  getAccidentNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccidentNotesAction),
      switchMap(({ query, memberId, accidentId }) =>
        this.accidentParticipantsService.getAccidentNotes( query, memberId, accidentId).pipe(
          map((response: GetAccidentNotesResponse) => getAccidentNotesSuccessAction({ response })),
          catchError((error) => of(getAccidentNotesFailureAction({ error })))
        )
      )
    )
  );

  removeAccidentDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAccidentDocumentAction),
      switchMap(({ memberId, accidentId, accidentDocumentId }) => {
        return this.accidentParticipantsService.removeAccidentDocument(memberId, accidentId, accidentDocumentId).pipe(
          map(() => removeAccidentDocumentSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommonException') {
              errorMessage = error?.error?.errorMessage[0] ?? '';
            }
            return of(removeAccidentDocumentFailureAction({ error: errorMessage }))
          })
        )
      })
    )
  );

  editAccidentDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAccidentDocumentAction),
      switchMap(({ memberId, accidentId, fileId, request }) =>
        this.accidentParticipantsService.editAccidentDocument(memberId, accidentId, fileId, request).pipe(
          map(() => editAccidentDocumentSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommonException') {
              errorMessage = error?.error?.errorMessage?.[0] ?? '';
            }
            return of(editAccidentDocumentFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

}
