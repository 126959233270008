<div class="calendarcontainer container">
    <div class="grid grid-cols-3 gap-4">
        <div *ngFor="let Month of calendar;let m = index">
            <div class="monthcontainer ">
                <p class="monthname">{{Month.date | date:'MMMM'}}</p>
                <div class="flexdays">
                    <div class="dayheader" *ngFor="let label of days; let first = first; let last = last">
                        <div [ngClass]="(first || last) ? 'weekendclass' : ''">                            
                            {{label}}
                        </div>
                    </div>
                </div>
                <div *ngFor="let week of Month.days" class="flexdays">
                    <div *ngFor="let day of week;let i = index;"
                        [ngClass]="day ? (day.istoday ?  (day.color && day.nb > 1 ? 'todayclass-haveevents' : 'todayclass') : day.isweekendday ? 'weekendclass' : (day.nb > 0 ? 'haveevents' : '')) : 'void_day'"
                        [style.background-image]="day && day.color && (day.nb > 1 || !day.hasdotevent) ? ('linear-gradient(120deg, '+day.color.primary+', '+day.color.secondary+')') : ''"
                        [style.color]="day && day.color && (day.nb > 1 || !day.hasdotevent) ? day.color.tertiary : ''"                        
                        matTooltip="{{ day?.tooltip }}"
                        matTooltipPosition="below"
                        matTooltipClass="custom-tooltip"
                        class="day">
                        <div class="grid grid-cols-1 content-center">
                            <p [ngClass]="day?.hasdotevent && day?.nb > 1 ? 'dayhaveevents' : 'daynoevents'">{{day?.day}}</p>
                            <p *ngIf="day?.hasdotevent" [ngClass]="day?.hasdotevent && day?.nb > 1 ? 'dotevents' : 'doteventonly'">.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>