import { Component, Inject } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentInfoAdjustmentType } from '@ptg-member/features/payee-detail/types/enums';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone, showCancelDialog } from '@ptg-shared/utils/common.util';
import { EditFundingSourcesComponentService } from './edit-funding-sources.component.service';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import {
  AdjustmentFunding,
  CreateEditEarningItemDetailsRequest,
  EarningInfos,
  FundingSource,
  FundingSources,
  GetEarningFundingSourcesRequest,
  PayrollEarningItemListDetail,
} from '../../services/models';
import { Store } from '@ngrx/store';
import * as fromPayeeDetail from '../../store/reducers/';
import {
  clearCreateEditEarningItemDetailsStateAction,
  clearGetEarningFundingSourcesStateAction,
  createEditEarningItemDetailsAction,
  getEarningFundingSourcesAction,
  getPayeeDeductionsDetailAction,
} from '../../store';
import {
  createEditEarningItemDetailsSelector,
  getEarningFundingSourcesSelector,
} from '../../store/selectors/edit-funding-source.selector';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

@Component({
  selector: 'ptg-edit-funding-sources',
  templateUrl: './edit-funding-sources.component.html',
  styleUrls: ['./edit-funding-sources.component.scss'],
  providers: [EditFundingSourcesComponentService],
})
export class EditFundingSourcesComponent extends BaseComponent {
  // Default constants
  readonly InputType = InputType;
  readonly minValueForCurrency = 0;
  readonly maxValueForCurrency = 999999999999.99;

  // Banner
  bannerType: BannerType = BannerType.Hidden;
  message = '';

  // Loading
  isLoading = false;

  // Constants
  fundingSourcesOptionList: Option[] = [];
  adjustmentFundingOptionList: Option[] = [];

  fundingSourceOptionList: Option[] = [];

  dialogTitle = 'Edit Funding Sources';
  fundingSourceTitle = '';
  adjustmentFundingTitle = '';

  // FormGroup
  editForm: FormGroup = new FormGroup({});
  earningInfo!: EarningInfos;
  isShowErrorFundingSource: boolean = false;
  earningItems: any = {};
  isShowFundingSourceButton = false;
  isDisabledSaveBtn?: boolean = true;

  private readonly _fundingSourceItem: FundingSource = {
    id: '',
    name: '',
    amount: null,
    order: 1,
    adjustmentFundingSourceId: '',
    piFundingAmountId: '',
    taxable: false
  };

  private readonly _adjustmentFunding: AdjustmentFunding = {
    id: '',
    startDate: '',
    endDate: '',
    amount: null,
    order: 1,
    piFundingAmountId: '',
    name: '',
    isEditable: true,
  };

  private deletedFundingSourceItem: FundingSource[] = [];
  private deletedAdjustmentFundingSourceItem: FundingSource[] = [];

  constructor(
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<EditFundingSourcesComponent>,
    private readonly editFundingSourcesComponentService: EditFundingSourcesComponentService,
    private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
    @Inject(MAT_DIALOG_DATA) public data: { earningInfo: EarningInfos },
  ) {
    super();
  }

  ngOnInit(): void {
    // Initialize FormGroup with default values
    this.editForm = this.editFundingSourcesComponentService.getInitForm;
    this.assignEarningValue();

    this.selectorEarningFundingSource();
    this.selectorSaveEditEarning();
  }

  assignEarningValue() {
    this.earningInfo = deepClone(this.data.earningInfo);
    this.editFundingSourcesComponentService.setBenefitTypeOptionId = this.earningInfo.benefitTypeOptionId;
    this.editFundingSourcesComponentService.setPaymentId = this.earningInfo.paymentInstructionId;
    this.getEarningFundingSource();
  }

  initialEarningValue() {
    this.earningItems = (this.earningInfo?.earningItems ?? []).reduce((acc: any, prev: any) => {
      return Object.assign(acc, {
        fundingSources: [...(acc?.fundingSources ?? []), ...(prev.fundingSources ?? [])],
        adjustmentFundings: [...(acc?.adjustmentFundings ?? []), ...(prev.adjustmentFundings ?? [])],
      });
    }, {});
    this.dialogTitle = this.fundingSourceOptionList.length === 1 ? 'Edit Earnings' : 'Edit Funding Sources';
    this.fundingSourceTitle = this.fundingSourceOptionList.length === 1 ? 'Earnings' : 'Funding Sources';
    this.adjustmentFundingTitle =
      this.fundingSourceOptionList.length === 1 ? 'Adjustment Earnings' : 'Adjustment Funding';
    (this.earningItems.fundingSources ?? [])
      .filter((item: FundingSources) => item.showInViewEdit)
      .forEach((item: FundingSource) => {
        this.addFundingSourceItem(item, true);
      });

    const addAdjustmentFundingOptions: Option[] = [];
    (this.earningItems.adjustmentFundings ?? [])
      .filter((item: FundingSources) => item.showInViewEdit)
      .forEach((item: AdjustmentFunding) => {
        this.addAdjustmentFundingItem(item);
        addAdjustmentFundingOptions.push({ displayValue: item.name ?? '', value: item.id });
      });

    this.adjustmentFundingOptionList = this.returnUniqueObjectItems([
      ...this.adjustmentFundingOptionList,
      ...addAdjustmentFundingOptions,
    ]);
    this.handleFormValueChanged();
  }

  private handleFormValueChanged() {
    const valueForm = this.fundingSourcesFormArray.value;
    this.fundingSourcesFormArray.valueChanges.subscribe((value) => {
      if (JSON.stringify(valueForm) !== JSON.stringify(value)) {
        this.isDisabledSaveBtn = false;
      }
    });
    const valueFormAdj = this.adjustmentFundingFormArray.value;
    this.adjustmentFundingFormArray.valueChanges.subscribe((value) => {
      if (JSON.stringify(valueFormAdj) !== JSON.stringify(value)) {
        this.isDisabledSaveBtn = false;
      }
    });
  }

  checkShowFundingSourceButton() {
    this.isShowFundingSourceButton =
      this.fundingSourceOptionList.length === 0 ||
      this.fundingSourceOptionList.length > this.fundingSourcesFormArray.controls.length;
  }

  private returnUniqueObjectItems(arr: any[]) {
    return arr.filter((v, i, a) => a.findIndex((v2) => v.value === v2.value) === i);
  }

  private getAvailableFundingSourceOptions(fundingSourceId: string, isInit = false): Option[] {
    const addedFundingSourcesList = isInit
      ? this.earningItems.fundingSources
      : this.editForm.get('fundingSourcesList')?.value;
    const returnList = this.fundingSourceOptionList.filter(
      (sourceOption: Option) =>
        sourceOption.value === fundingSourceId ||
        !addedFundingSourcesList.some((y: any) => y.id === sourceOption.value),
    );
    return this.returnUniqueObjectItems(returnList);
  }

  getEarningFundingSource() {
    const request: GetEarningFundingSourcesRequest = {
      benefitTypeOptionId: this.earningInfo?.benefitTypeOptionId ?? '',
    };

    this.payeeDetailStore.dispatch(getEarningFundingSourcesAction({ request }));
  }

  selectorEarningFundingSource() {
    this.payeeDetailStore
      .select(getEarningFundingSourcesSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        tap((response) => (this.isLoading = !!response?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.payload) {
          const fundingSources = deepClone(state.payload);
          this.fundingSourceOptionList = fundingSources
            .filter((source) => source.adjustmentFundingSourceId)
            .map((source) => ({ displayValue: source.label ?? '', value: source.fundingSourceId, taxable: source.taxable }));

          this.adjustmentFundingOptionList = fundingSources
            .filter((source) => !source.adjustmentFundingSourceId)
            .map((source) => ({ displayValue: source.label ?? '', value: source.fundingSourceId }));

          this.initialEarningValue();
        }
        this.checkShowFundingSourceButton();
        this.payeeDetailStore.dispatch(clearGetEarningFundingSourcesStateAction());
      });
  }

  onChangeFundingSource() {
    this.refreshFundingSourceOptions();
  }

  refreshFundingSourceOptions(): void {
    let options = this.fundingSourcesFormArray;
    options?.controls.forEach((item) => {
      item.get('fundingSources')?.patchValue(this.getAvailableFundingSourceOptions(item?.get('id')?.value));
    });
  }

  onSave(): void {
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    const payrollEarningItemListDetail = this.setSaveDate();
    const checkOverlap = this.validateOverlapDateValue(payrollEarningItemListDetail.adjustmentFundings);
    const overlapName = (
      this.adjustmentFundingOptionList.find((funding: any) => funding.value === checkOverlap?.id) ?? {}
    )?.displayValue;
    if (checkOverlap) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Error',
          text: `Cannot add multiple ${overlapName} with overlapping effective periods`,
          type: ConfirmType.Warning,
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });

      return;
    }

    const request: CreateEditEarningItemDetailsRequest = {
      paymentId: this.earningInfo?.paymentInstructionId ?? '',
      benefitTypeOptionId: this.earningInfo?.benefitTypeOptionId ?? '',
      payrollEarningItemListDetail,
    };

    let isChangeAmountOfTaxable = false;
    payrollEarningItemListDetail.fundingSources.forEach(item => {
      if (item.taxable && (item.piFundingAmountId && item.order === -1 || !item.piFundingAmountId && item.order !== -1 || this.earningItems.fundingSources.find((el: any) => el.id === item.id && el.amount !== item.amount))) {
        isChangeAmountOfTaxable = true;
        return;
      }
    });

    this.payeeDetailStore.dispatch(createEditEarningItemDetailsAction({ request, isChangeAmountOfTaxable }));
  }

  private setSaveDate(): PayrollEarningItemListDetail {
    let returnValue = this.editForm.getRawValue();
    this.deletedFundingSourceItem.forEach((x) => {
      const sourceIndex = returnValue.fundingSourcesList.findIndex(
        (y: any) => y.piFundingAmountId === x.piFundingAmountId,
      );
      if (sourceIndex === -1 && x.piFundingAmountId) {
        returnValue.fundingSourcesList.push(x);
      }
    });

    this.deletedAdjustmentFundingSourceItem.forEach((x) => {
      const sourceIndex = returnValue.adjustmentFundingList.findIndex(
        (y: any) => y.piFundingAmountId === x.piFundingAmountId,
      );
      if (sourceIndex === -1) {
        returnValue.adjustmentFundingList.push(x);
      }
    });
    returnValue = {
      ...returnValue,
      fundingSourcesList: returnValue.fundingSourcesList.map((item: any) => ({
        id: item.id,
        piFundingAmountId: item.piFundingAmountId,
        amount: item.amount,
        order: item.order,
        taxable: item.taxable
      })).filter((item: any) => !(!item.id && item.order === -1)),
      adjustmentFundingList: returnValue.adjustmentFundingList.map((item: any) => ({
        id: item.id,
        piFundingAmountId: item.piFundingAmountId,
        startDate: DateTime.fromISO(item.startDate).toFormat('yyyy-MM-dd'),
        endDate: DateTime.fromISO(item.endDate).toFormat('yyyy-MM-dd'),
        amount: item.amount,
        order: item.order,
      })),
    };
    return {
      fundingSources: [...returnValue.fundingSourcesList],
      adjustmentFundings: [...returnValue.adjustmentFundingList],
    };
  }

  selectorSaveEditEarning() {
    this.payeeDetailStore
      .select(createEditEarningItemDetailsSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((addedItems) => {
        this.dialogRef.close({ resultStatus: addedItems?.success, isChangeAmountOfTaxable: addedItems?.payload?.isChangeAmountOfTaxable });
        this.payeeDetailStore.dispatch(clearCreateEditEarningItemDetailsStateAction());
        const requestDetail = {
          paymentInstructionId: this.earningInfo?.paymentInstructionId ?? '',
          memberId: this.earningInfo?.memberId ?? '',
          paymentInstructionHistoryId: this.earningInfo?.paymentInstructionHistoryId ?? '',
          paymentInfoAdjustmentType:
            this.earningInfo.paymentInfoAdjustmentType ?? PaymentInfoAdjustmentType.PaymentInstruction,
          instructionStatusHistoryId: this.earningInfo?.instructionStatusHistoryId ?? '',
        };
        this.payeeDetailStore.dispatch(getPayeeDeductionsDetailAction(requestDetail));
      });
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  addFundingSourceItem(item?: FundingSource, isInit = false): void {
    item = item ?? deepClone(this._fundingSourceItem);
    this.editFundingSourcesComponentService.setFundingSourceItem = {
      ...item,
      fundingSources: this.getAvailableFundingSourceOptions(item.id, isInit),
    };
    this.fundingSourcesFormArray.push(this.editFundingSourcesComponentService.newFundingSourceItem);

    this.isShowErrorFundingSource = false;
    this.checkShowFundingSourceButton();
  }

  removeFundingSourceItem(index: number): void {
    if (this.fundingSourcesFormArray.controls.length === 1) {
      this.isShowErrorFundingSource = true;
      return;
    }

    this.deletedFundingSourceItem.push({
      ...this.fundingSourcesFormArray.value[index],
      order: -1,
    });

    this.fundingSourcesFormArray.removeAt(index);

    this.refreshFundingSourceOptions();
    this.onChangeFundingAmount();
    this.checkShowFundingSourceButton();
  }

  addAdjustmentFundingItem(item?: AdjustmentFunding): void {
    item = item ?? this._adjustmentFunding;
    this.editFundingSourcesComponentService.setAdjustmentFunding = {
      ...item,
      adjustmentFundingOptions: this.adjustmentFundingOptionList,
    };
    this.adjustmentFundingFormArray.push(this.editFundingSourcesComponentService.newAdjustmentItem);
  }

  removeAdjustmentFundingItem(index: number): void {
    const deletedItems = this.adjustmentFundingFormArray.value[index];
    if (deletedItems.piFundingAmountId) {
      this.deletedAdjustmentFundingSourceItem.push({
        ...this.adjustmentFundingFormArray.value[index],
        order: -1,
      });
    }

    this.adjustmentFundingFormArray.removeAt(index);

    this.onChangeFundingAmount();
  }

  onChangeFundingAmount() {
    const rawValue = this.editForm.getRawValue();
    const { fundingSourcesList, adjustmentFundingList } = rawValue;
    const benefitPeriodStartDate = DateTime.fromISO(this.earningInfo?.benefitPeriodStartDate ?? '')?.toMillis();
    const benefitPeriodEndDate = DateTime.fromISO(this.earningInfo?.benefitPeriodEndDate ?? '')?.toMillis();
    const totalAdjustmentFundingAmount = adjustmentFundingList.reduce((acc: any, obj: any) => {
      const startDate = DateTime.fromISO(obj.startDate)?.toMillis();
      const endDate = DateTime.fromISO(obj.endDate)?.toMillis();

      if (obj.startDate && obj.endDate && startDate <= benefitPeriodStartDate && benefitPeriodEndDate <= endDate) {
        return acc + (obj.amount ?? 0);
      }
      return acc;
    }, 0);

    const grossPayment =
      fundingSourcesList.reduce((acc: any, obj: any) => acc + obj.amount, 0) + totalAdjustmentFundingAmount;
    this.earningInfo = {
      ...this.earningInfo,
      grossPayment: grossPayment,
      netPayment: grossPayment - (this.earningInfo?.totalDeductions ?? 0),
    };
  }

  get fundingSourcesFormArray(): FormArray {
    return this.editForm.get('fundingSourcesList') as FormArray;
  }

  get adjustmentFundingFormArray(): FormArray {
    return this.editForm.get('adjustmentFundingList') as FormArray;
  }

  onChangeStartDate(event: any, index: number) {
    this.onChangeFundingAmount();
    this.adjustmentFundingFormArray.controls[index]?.get('endDate')?.updateValueAndValidity();
  }

  onChangeEndDate(event: any, index: number) {
    this.onChangeFundingAmount();
    this.adjustmentFundingFormArray.controls[index]?.get('startDate')?.updateValueAndValidity();
  }

  validateOverlapDateValue(adjustmentFundingList: any) {
    const checkAdjustmentList = adjustmentFundingList.filter((item: any) => item.order !== -1);
    let checkOverlap = checkAdjustmentList.find((v: any, index: any, a: any) =>
      a.some((v2: any, index2: any) => {
        const v2StartDate = DateTime.fromISO(v2.startDate)?.toMillis();
        const v2EndDate = DateTime.fromISO(v2.endDate)?.toMillis();
        const v2Id = v2.id;

        const vStartDate = DateTime.fromISO(v.startDate)?.toMillis();
        const vEndDate = DateTime.fromISO(v.endDate)?.toMillis();
        const vId = v.id;

        return (
          index !== index2 &&
          vId === v2Id &&
          ((vStartDate <= v2StartDate && vEndDate >= v2StartDate) ||
            (vStartDate <= v2EndDate && vEndDate >= v2EndDate) ||
            (vStartDate >= v2StartDate && v2EndDate >= vEndDate))
        );
      }),
    );
    return checkOverlap;
  }

  identify(index: number): number {
    return index;
  }
}
