export const ClientSettingKey = {
  BvffFixedProperties: 'FixedProperties'
};

export const DEFAULT_LOOKUPTABLE = {
  STATE: 'DEFAULT_LOOKUPTABLE_STATE',
  COUNTRY: 'DEFAULT_LOOKUPTABLE_COUNTRY',
  PREFIX: 'DEFAULT_LOOKUPTABLE_PREFIX',
  GENDER: 'DEFAULT_LOOKUPTABLE_GENDER',
  NAME_CHANGE_REASON: 'DEFAULT_LOOKUPTABLE_NAME_CHANGE_REASON',
};