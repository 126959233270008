import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { Router } from '@angular/router';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { PaymentHeaderConfigurationService } from '../../services';
import { PayeeDetailState } from '../../store/reducers';
import { createHeader, createPaymentHeader, getPaymentEntitiesAction, selectPaymentEntities } from '../../store';
import { OptionValue } from '../../types/models';


@Component({
  selector: 'ptg-add-payment-header-configuration',
  templateUrl: './add-payment-header-configuration.component.html',
  styleUrls: ['./add-payment-header-configuration.component.scss']
})
export class AddPaymentHeaderConfigurationComponent extends BaseComponent {

  formSubmit$ = new Subject<boolean>();
  listBenefitType: OptionValue[] = [];
  listEntity: OptionValue[] = [];
  editForm!: FormGroup;
  isContinue = false;
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddPaymentHeaderConfigurationComponent>,
    private payeeDetailStore: Store<PayeeDetailState>,
    public router: Router,
    public paymentHeaderConfigurationService: PaymentHeaderConfigurationService,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    @Inject(MAT_DIALOG_DATA) public data: {
      menuId: string;
      viewId: string;
      id: string;
    },
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEntityData();
    this.emitFormSubmit();
    this.editForm = this.fb.group({
      benefitTypeId: this.fb.control(null, Validators.required),
      entityId: this.fb.control(null, Validators.required),
    });

    this.payeeDetailStore.pipe(select(selectPaymentEntities), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.listBenefitType = (data?.payload?.benefitTypies ?? []).map((ele: OptionValue) => {
          return {
            value: ele.id,
            displayValue: ele.displayName,
          };
        });
        this.listEntity = (data?.payload?.entities ?? []).map((ele: OptionValue) => {
          return {
            value: ele.id,
            displayValue: ele.displayName,
          };
        });
      }
    });

    this.payeeDetailStore.pipe(select(createPaymentHeader), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (this.isContinue && data?.payload) {
        this.dialogRef.close();
        this.router.navigateByUrl(`/member/system-view/payment-header-configuration/${this.data.menuId}/${this.data.viewId}/${this.data.id}/${data?.payload}`);
      } else {
        this.editForm.reset();
      }
    });
  }

  ngOnDestroy(): void {
    this.isContinue = false;
  }


  emitFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    const body = this.editForm.getRawValue();
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    this.paymentHeaderConfigurationService.checkHeaderExist(body).subscribe(res => {
      if (res?.exists) {
        this.editForm.get('benefitTypeId')?.setErrors({inValidAsync:true});
        this.editForm.get('entityId')?.setErrors({inValidAsync:true});
      } else {
        this.payeeDetailStore.dispatch(createHeader({body}));
      }
    });
  }

  getEntityData(): void {
    this.payeeDetailStore.dispatch(getPaymentEntitiesAction());
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  onSourceChangedValue() {
    const { benefitTypeId, entityId } = this.editForm.value;
    if(benefitTypeId) {
      this.editForm.get('benefitTypeId')?.setErrors(null);
    }
    if(entityId) {
      this.editForm.get('entityId')?.setErrors(null);
    }
  }
}
