import { createAction, props } from '@ngrx/store';

import { generateActionName } from '@ptg-shared/utils/common.util';

import {
  GetEditPayrollSettingsRequest,
  GetEditPayrollSettingsResponse,
  SaveEditPayrollSettingsRequest,
} from '../../services/models/edit-payroll-settings.model';

// Actions for GET saved Edit Payroll Settings data
export const getEditPayrollSettingsAction = createAction(
  generateActionName('GetEditPayrollSettings/API').Send,
  props<{ request: GetEditPayrollSettingsRequest }>(),
);
export const getEditPayrollSettingsSuccess = createAction(
  generateActionName('GetEditPayrollSettings/API').Success,
  props<{ response: GetEditPayrollSettingsResponse }>(),
);
export const getEditPayrollSettingsFailure = createAction(
  generateActionName('GetEditPayrollSettings/API').Failure,
  props<{ error: any }>(),
);
export const clearGetEditPayrollSettingsState = createAction(generateActionName('GetEditPayrollSettings').Clear);

// Actions for POST Edit Payroll Settings data
export const saveEditPayrollSettingsAction = createAction(
  generateActionName('SaveEditPayrollSettings/API').Send,
  props<{ body: SaveEditPayrollSettingsRequest; paymentInstructionId: string }>(),
);
export const saveEditPayrollSettingsSuccess = createAction(generateActionName('SaveEditPayrollSettings/API').Success);
export const saveEditPayrollSettingsFailure = createAction(
  generateActionName('SaveEditPayrollSettings/API').Failure,
  props<{ error: any }>(),
);
export const clearSaveEditPayrollSettingsState = createAction(generateActionName('SaveEditPayrollSettings').Clear);
