import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CheckExistLookupTableQuery,
  DisplayFormatRes,
  ExitsDisplayFormatRequest,
  ExitsDisplayFormatRes,
  GetLookupTableQuery,
  ImportSessionDetail,
  LookupTableDetail,
  LookupTableList,
  LookupTableRequest,
  LookupTableValueRequest,
  MetaDataLookupTableDisplayFormat,
  ReplaceValueBody,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class LookupTableService {
  constructor(private httpClient: HttpClient) {}

  getLookupTableList(query: GetLookupTableQuery): Observable<LookupTableList> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<LookupTableList>(
      `${environment.apiUrl}/Metadata/GetMetadataLookupTables`,
      { params }
    );
  }

  getLookupTableDetail(id: string): Observable<LookupTableDetail> {
    return this.httpClient.get<LookupTableDetail>(
      `${environment.apiUrl}/Metadata/MetadataLookupTable/${id}`
    );
  }

  createMetadataLookupTable(
    lookupTableDetail: LookupTableRequest
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Metadata/lookuptable/createMetadataLookupTableWithEntity`,
      lookupTableDetail
    );
  }

  updateMetadataLookupTable(
    lookupTableDetail: LookupTableRequest
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Metadata/lookuptable/setLookupTable`,
      lookupTableDetail
    );
  }

  createLookupTableValue(
    id: string,
    body: LookupTableValueRequest
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/lookup-tables/${id}/lookup-table-values`,
      body
    );
  }

  updateLookupTableValue(
    id: string,
    body: LookupTableValueRequest
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/lookup-tables/${id}/lookup-table-values/${body.lookUpId}`,
      body
    );
  }

  removeLookupTableValue(body: ReplaceValueBody): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Metadata/LookUpTableValue/DeleteValue `,
      { body }
    );
  }

  checkExistLookupTable = (body: CheckExistLookupTableQuery) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/LookUpTable/checkExistsLookUpTable`,
      body
    );
  };

  checkExistCode = (body: CheckExistLookupTableQuery) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Metadata/LookUpTableValue/CheckExist`,
      body
    );
  };

  getImportSessionDetail(
    source: string,
    sourceId: string,
    batchId: string
  ): Observable<ImportSessionDetail> {
    return this.httpClient.get<ImportSessionDetail>(
      `${environment.apiUrl}/Imports/${source}/${sourceId}/${batchId}`
    );
  }

  proceedImport(sessionId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Imports/${sessionId}/Proceed`,
      null
    );
  }

  cancelImport(sessionId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Imports/${sessionId}/Cancel`,
      null
    );
  }

  checkLookUpTableValueBeingUsed(
    lookupTableId: string,
    optionId: string
  ): Observable<{ exists: boolean; message: string }> {
    return this.httpClient.get<{ exists: boolean; message: string }>(
      `${environment.apiUrl}/lookup-tables/${lookupTableId}/lookup-table-values/${optionId}/existed`
    );
  }

  createDisplayFormats(
   body: ExitsDisplayFormatRequest
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/MetadataPortal/AddLookupTableDisplayFormats`,
      body
    );
  }
  
  editDisplayFormats(
    body: MetaDataLookupTableDisplayFormat
   ): Observable<void> {
     return this.httpClient.put<void>(
       `${environment.apiUrl}/MetadataPortal/LookupTableDisplayFormat`,
       body
     );
   }
 
  getDisplayFormats(id: string): Observable<DisplayFormatRes> {
    return this.httpClient.get<DisplayFormatRes>(
      `${environment.apiUrl}/Metadata/MetadataLookupTableDisplayFormat/${id}`
    );
  }

  checkExitsDisplayFormats(body: ExitsDisplayFormatRequest): Observable<ExitsDisplayFormatRes>{
    return this.httpClient.put<ExitsDisplayFormatRes>(`${environment.apiUrl}/MetadataPortal/ExistDisplayFormat`, body);
  }
}
