import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import {
  AddListRequest,
  AddPropertyRequest,
  CheckEntityPropertyInUsedResponse,
  CheckExistRequest,
  CheckExistResponse,
  DeletePropertyRequest,
  GetEntitiesQuery,
  GetEntitiesResponse,
  GetEntityComponentListsRequest,
  GetEntityComponentListsResponse,
  GetEntityDetailRequest,
  GetEntityDetailResponse,
  GetEntityListsRequest,
  GetEntityListsResponse,
  GetEntityPropertiesRequest,
  GetEntityPropertiesResponse,
  GetEntityPropertyDetailRequest,
  GetEntityPropertyDetailResponse,
  GetListDetailRequest,
  GetListDetailResponse,
  GetEntityPropertiesForCalculationRequest,
  GetEntityPropertiesForCalculationResponse,
  GetPropertyTypesRequest,
  GetPropertyTypesResponse,
  SetEntityPropertyRequest,
  UpdateEntityRequest,
  UpdateListConfigRequest,
  UpdatePropertyItemRequest,
  GetEntityReferencesRequest,
  GetEntityReferencesResponse,
  GetEntityInitiationConfigurationRequest,
  GetEntityInitiationConfigurationResponse,
  SetEntityInitiationConfigurationRequest,
  CheckExistEntityResponse,
  CheckEntityCanRemoveResponse,
  GetEntityListDataByIdQuery,
  GetEntityListDataByIdResponse,
  DeleteEntityListDataQuery,
  GetEntityCardsTypeResponse,
  ReOrderEntityListDataRequest,
  GetListBenefitResponse,
  BenefitMappingPayload,
} from './models';
import { delay } from 'rxjs/operators';
import { ParametersQuery } from '@ptg-employer/models';

@Injectable()
export class EntityService {
  constructor(private httpClient: HttpClient) { }

  createEntity(request: any): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/entity-new/entities`, request);
  }

  checkExists = (bodyCheck: any): Observable<CheckExistEntityResponse> => {
    let body = {
      ...bodyCheck,
    };
    return this.httpClient.post<CheckExistEntityResponse>(`${environment.apiUrl}/entity-new/entities/existed`, body);
  };

  getEntities(query?: GetEntitiesQuery): Observable<GetEntitiesResponse> {
    let params = new HttpParams();
    if (query?.pageSize) {
      params = params.append('PageSize', query?.pageSize);
    }
    if (query?.pageIndex) {
      params = params.append('PageIndex', query.pageIndex.toString());
    }

    if (query && query.sortField) {
      params = params.append('SortNames', query.sortField[0].toUpperCase() + query.sortField.substring(1));
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }

    return this.httpClient.get<GetEntitiesResponse>(`${environment.apiUrl}/entity-new/entities`, { params });
  }

  getEntityDetail(request: GetEntityDetailRequest): Observable<GetEntityDetailResponse> {
    return this.httpClient.get<GetEntityDetailResponse>(`${environment.apiUrl}/entity-new/entities/${request.id}`);
    // TODO: revert to old router entity after refactor done.
    // return this.httpClient.get<GetEntityDetailResponse>(`${environment.apiUrl}/entity/entities/${request.id}`);
  }

  getEntityCards(type: number) {
    return this.httpClient.get<GetEntityCardsTypeResponse>(
      `${environment.apiUrl}/entity/cards/include-attachment/${type}`,
    );
  }

  updateEntity(request: UpdateEntityRequest): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/entity-new/entities/${request.id}`, request);
  }

  addProperty(
    request: AddPropertyRequest,
    entityComponentId: string,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/entity-new/components/${entityComponentId}/properties`,
      request,
    );
  }

  addList(request: AddListRequest, entityId: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/entity-new/entities/${entityId}/components`, request);
  }

  checkListExists = (bodyCheck: any) => {
    let body = {
      ...bodyCheck,
    };
    return this.httpClient.post(`${environment.apiUrl}/entity-new/entities/${bodyCheck.entityId}/components/existed`, body);
  };

  checkPropertyUsing(request: DeletePropertyRequest): Observable<CheckEntityPropertyInUsedResponse> {
    let params = new HttpParams();

    if (request) {
      params = params.append('isRemoveProperty', request.isRemoveProperty);
    }
    if (request.entityPropertyId) {
      params = params.append('entityPropertyId', request.entityPropertyId);
    }

    return this.httpClient.get<CheckEntityPropertyInUsedResponse>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/components/${request.entityComponentId}/properties/using?${params}`,
    );
  }

  deleteProperty(request: DeletePropertyRequest): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/entity-new/properties/${request.entityPropertyId}`,
    );
  }

  deleteEntityComponent(entityComponentId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/entity-new/components/${entityComponentId}`,
    );
  }

  getListDetail(request: GetListDetailRequest): Observable<GetListDetailResponse> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/components/${request.entityComponentId}`,
    );
  }
  updateListConfig(request: UpdateListConfigRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity-new/components/${request.entityComponentId}`,
      {
        name: request.name,
        importKey: request.importKey,
        enableDragAndDropSorting: request.enableDragAndDropSorting,
        editByCreatorOnly: request.editByCreatorOnly,
        removeByCreatorOnly: request.removeByCreatorOnly
      },
    );
  }

  getPropertyTypes(request: GetPropertyTypesRequest): Observable<GetPropertyTypesResponse> {
    let params = new HttpParams();

    return this.httpClient.get<GetPropertyTypesResponse>(
      `${environment.apiUrl}/Metadata/PropertyTypes/${request.type}`,
      { params },
    );
  }

  getEntityPropertiesForCalculation(
    request: GetEntityPropertiesForCalculationRequest,
  ): Observable<GetEntityPropertiesForCalculationResponse> {
    return this.httpClient.get<GetEntityPropertiesForCalculationResponse>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/components/property-for-calculation`,
    );
  }

  deleteEntity(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/entity-new/entities/${id}`);
  }

  updatePropertyItem(request: UpdatePropertyItemRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/items/${request.objectId}/${request.id}/property`,
      {
        name: request.name,
        importKey: request.importKey,
        configs: request.configs,
      },
    );
  }

  getEntityProperties(request: GetEntityPropertiesRequest): Observable<GetEntityPropertiesResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetEntityPropertiesResponse>(
      `${environment.apiUrl}/entity-new/entities/${request.entityId}/properties/items`,
      { params },
    );
  }

  getEntityLists(request: GetEntityListsRequest): Observable<GetEntityListsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetEntityListsResponse>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/list/items`,
      { params },
    );
  }

  getEntityComponentLists(request: GetEntityComponentListsRequest): Observable<GetEntityComponentListsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetEntityComponentListsResponse>(
      `${environment.apiUrl}/entity-new/entities/${request.entityId}/components/list-for-new-aggregation`,
      { params },
    );
  }

  checkPropertyNameExist = (request: CheckExistRequest): Observable<CheckExistResponse> => {
    return this.httpClient.post<CheckExistResponse>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/components/${request.entityComponentId}/properties/exists`,
      request,
    );
  };

  getEntityPropertyDetail(request: GetEntityPropertyDetailRequest): Observable<GetEntityPropertyDetailResponse> {
    return this.httpClient.get<GetEntityPropertyDetailResponse>(
      `${environment.apiUrl}/entity-new/properties/${request.entityPropertyId}`,
    );
  }

  setEntityProperty(request: SetEntityPropertyRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity-new/components/${request.entityComponentId}/properties/${request.entityPropertyId}`,
      request,
    );
  }

  getEntityReferences(request: GetEntityReferencesRequest): Observable<GetEntityReferencesResponse> {
    return this.httpClient.get<GetEntityReferencesResponse>(
      `${environment.apiUrl}/entity-new/entities/entities-for-new-entity-reference`,
    );
  }

  getEntityInitiationConfiguration(
    request: GetEntityInitiationConfigurationRequest,
  ): Observable<GetEntityInitiationConfigurationResponse> {
    return this.httpClient.get<GetEntityInitiationConfigurationResponse>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/entity-initiation-configuration`,
    );
  }

  setEntityInitiationConfiguration(request: SetEntityInitiationConfigurationRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity/entities/${request.entityId}/entity-initiation-configuration`,
      request,
    );
  }

  checkEntityCanRemove(id: string): Observable<CheckEntityCanRemoveResponse> {
    return this.httpClient.get<CheckEntityCanRemoveResponse>(`${environment.apiUrl}/entity/entities/can-remove/${id}`);
  }

  getEntityListDataById(query: GetEntityListDataByIdQuery): Observable<GetEntityListDataByIdResponse> {
    let params = new HttpParams();
    params = params.append('isSummary', query?.isSummary);
    if (query?.pageSize) {
      params = params.append('PageSize', query?.pageSize);
    }
    if (query?.pageIndex) {
      params = params.append('PageIndex', query.pageIndex.toString());
    }

    if (query && query.sortField) {
      params = params.append('SortNames', query.sortField);
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    if (query.screenId) {
      params = params.append('screenId', encodeURIComponent(query.screenId));
    }
    const { cardId, memberId, entityId } = query;

    return this.httpClient.get<GetEntityListDataByIdResponse>(
      `${environment.apiUrl}/entity-new/${entityId}/cards/${cardId}/list-data/${memberId}`,
      { params },
    );
  }

  deleteEntityListData({ entityComponentId, id }: DeleteEntityListDataQuery): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/entity-new/components/${entityComponentId}/component-data/${id}`,
    );
  }

  reOrderEntityListData(entityComponentId: string, request: ReOrderEntityListDataRequest): Observable<any> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity-new/components/${entityComponentId}/reorder`,
      request,
    );
  }

  getListBenefit(query?: ParametersQuery): Observable<GetListBenefitResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<GetListBenefitResponse>(
      `${environment.apiUrl}/entities/members/benefit-mapping/list-data`,
      { params }
    );
  }

  updateBenefitMapping(body: BenefitMappingPayload) {
    return this.httpClient.post(`${environment.apiUrl}/entities/members/benefit-mapping/upsert-data`, body);
  }
}
