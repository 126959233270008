export const MODULE_KEY = 'payeeDetail';

export enum DecductionType {
  Tax,
  Insurance,
  Adjustment,
  Garnishment,
  QDRO,
  Other,
}
