import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AddressData, BenefitTimeInfo, CheckBenefitPeriod, FundingSourceElement, GetDeductionFundingSourcesResponse, PayeeResponse, PayloadAmountValidate, PayloadOneTime, PaymentType, QueryParamsBenefit, QueryParamsCheckEndSave, QueryParamsWithHoldingTax } from './models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { OffsetDeductionPayload } from './models/create-offset-deduction.model';

@Injectable({
  providedIn: 'root',
})
export class CreateOffsetDeductionService {
  constructor(private httpClient: HttpClient) {}

  createOffsetDeduction(body: OffsetDeductionPayload) {
    return this.httpClient.post(`${environment.apiUrl}/new-payroll/payments/offset-deduction`, body);
  }
}
