import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { EarningFundingSource, GetDeductionFundingSourcesResponse } from '../../services/models';
import {
  clearGetEarningFundingSourcesStateAction,
  getEarningFundingSourcesAction,
  getEarningFundingSourcesFailureAction,
  getEarningFundingSourcesSuccessAction,
  clearCreateEditEarningItemDetailsStateAction,
  createEditEarningItemDetailsAction,
  createEditEarningItemDetailsFailureAction,
  createEditEarningItemDetailsSuccessAction,
  clearEditDeductionAsFundingSourceStateAction,
  editDeductionAsFundingSourceAction,
  editDeductionAsFundingSourceFailureAction,
  editDeductionAsFundingSourceSuccessAction,
} from '../actions';

export interface EditFundingSourceState {
  getEarningFundingSources?: BaseActionState<EarningFundingSource[]>;
  createEditEarningItemDetails?: BaseActionState<{ isChangeAmountOfTaxable: boolean }>;
  editDeductionFundingSources?: BaseActionState;
}

const initialState: EditFundingSourceState = {};

export const editFundingSourceReducer = createReducer(
  initialState,
  on(getEarningFundingSourcesAction, (state) => ({
    ...state,
    getEarningFundingSources: {
      isLoading: true,
    },
  })),
  on(getEarningFundingSourcesSuccessAction, (state, { response }) => ({
    ...state,
    getEarningFundingSources: {
      isLoading: false,
      success: true,
      payload: response.fundingSources,
    },
  })),
  on(getEarningFundingSourcesFailureAction, (state, { error }) => ({
    ...state,
    getEarningFundingSources: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEarningFundingSourcesStateAction, (state) => ({
    ...state,
    getEarningFundingSources: undefined,
  })),
  // save edit earning items
  on(createEditEarningItemDetailsAction, (state) => ({
    ...state,
    createEditEarningItemDetails: {
      isLoading: true,
    },
  })),
  on(createEditEarningItemDetailsSuccessAction, (state, { isChangeAmountOfTaxable }) => ({
    ...state,
    createEditEarningItemDetails: {
      isLoading: false,
      success: true,
      payload: {
        isChangeAmountOfTaxable
      }
    },
  })),
  on(createEditEarningItemDetailsFailureAction, (state, { error }) => ({
    ...state,
    createEditEarningItemDetails: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateEditEarningItemDetailsStateAction, (state) => ({
    ...state,
    createEditEarningItemDetails: undefined,
  })),
  // save edit deduction funding source
  on(editDeductionAsFundingSourceAction, (state) => ({
    ...state,
    editDeductionFundingSources: {
      isLoading: true,
    },
  })),
  on(editDeductionAsFundingSourceSuccessAction, (state) => ({
    ...state,
    editDeductionFundingSources: {
      isLoading: false,
      success: true,
    },
  })),
  on(editDeductionAsFundingSourceFailureAction, (state, { error }) => ({
    ...state,
    editDeductionFundingSources: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearEditDeductionAsFundingSourceStateAction, (state) => ({
    ...state,
    editDeductionFundingSources: undefined,
  })),
);
