import { createAction, props } from '@ngrx/store';

import {
  InitiateDisabilityBenefitRequest,
  GetDisabilityBenefitDetailRequest,
  ComputationDisabilityDataResponse,
  InitiateDisabilityBenefitResponse,
  CheckComputeDisabilityDataResponse,
  GetDisabilityBenefitDetailResponse,
  GetTotalDaysUsedResponse,
  GetDisabilityCalculationDetailResponse,
  ValidateDisabilityBenefitRequest,
  ApproveCalculationBenefitRequest,
} from '../../services/models';
import { CalculationBenefitHistoryStatus, CalculationType } from '../../types/enums';

export const clearAddDisabilityStateAction = createAction('[AddDisability] Clear');

export const clearDisabilityCalculationDetailAllStateAction = createAction('[DisabilityCalculationDetail] Clear');

export const getDisabilityBenefitDetailAction = createAction(
  '[GetDisabilityBenefitDetail/API] Send Request',
  props<{ request: GetDisabilityBenefitDetailRequest }>(),
);

export const getDisabilityBenefitDetailSuccessAction = createAction(
  '[GetDisabilityBenefitDetail/API] Success',
  props<{ response: GetDisabilityBenefitDetailResponse }>(),
);

export const getDisabilityBenefitDetailFailureAction = createAction(
  '[GetDisabilityBenefitDetail/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetDisabilityBenefitDetailStateAction = createAction('[GetDisabilityBenefitDetail] Clear');

//Complete Disability Benefit
export const completeDisabilityBenefitAction = createAction(
  '[completeDisabilityBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
  }>(),
);
export const completeDisabilityBenefitSuccessAction = createAction('[completeDisabilityBenefit/API] Success');
export const completeDisabilityBenefitFailureAction = createAction(
  '[completeDisabilityBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearCompleteDisabilityBenefitStateAction = createAction('[completeDisabilityBenefit] Clear');

//Approve Disability Benefit
export const approveDisabilityBenefitAction = createAction(
  '[ApproveDisabilityBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
    body?: ApproveCalculationBenefitRequest;
  }>(),
);
export const approveDisabilityBenefitSuccessAction = createAction('[ApproveDisabilityBenefit/API] Success');
export const approveDisabilityBenefitFailureAction = createAction(
  '[ApproveDisabilityBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearApproveDisabilityBenefitStateAction = createAction('[ApproveDisabilityBenefit] Clear');

//validate Disability Benefit
export const validateDisabilityBenefitAction = createAction(
  '[ValidateDisabilityBenefit/API] Send Request',
  props<{ request: ValidateDisabilityBenefitRequest }>(),
);
export const validateDisabilityBenefitSuccessAction = createAction(
  '[ValidateDisabilityBenefit/API] Success',
  props<{ response: CheckComputeDisabilityDataResponse }>(),
);
export const validateDisabilityBenefitFailureAction = createAction(
  '[ValidateDisabilityBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearValidateDisabilityBenefitStateAction = createAction('[ValidateDisabilityBenefit] Clear');

//validate before computing
export const validateBeforeComputingAction = createAction(
  '[ValidationBeforeComputing/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
  }>(),
);
export const validateBeforeComputingSuccessAction = createAction(
  '[ValidationBeforeComputing/API] Success',
  props<{ response: CheckComputeDisabilityDataResponse }>(),
);
export const validateBeforeComputingFailureAction = createAction(
  '[ValidationBeforeComputing/API] Failure',
  props<{ error?: any }>(),
);
export const clearValidateBeforeComputingAction = createAction('[ValidationBeforeComputing/API] Clear');

//compute Disability Benefit
export const computeDisabilityBenefitAction = createAction(
  '[ComputeDisabilityBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
  }>(),
);
export const computeDisabilityBenefitSuccessAction = createAction(
  '[ComputeDisabilityBenefit/API] Success',
  props<{ response: ComputationDisabilityDataResponse }>(),
);
export const computeDisabilityBenefitFailureAction = createAction(
  '[ComputeDisabilityBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearComputeDisabilityBenefitStateAction = createAction('[ComputeDisabilityBenefit] Clear');

//initiate
export const initiateDisabilityBenefitAction = createAction(
  '[InitiateDisabilityBenefitAction/API] Send Request',
  props<{ request: InitiateDisabilityBenefitRequest; memberId: string }>(),
);

export const initiateDisabilityBenefitSuccessAction = createAction(
  '[InitiateDisabilityBenefitAction/API] Success',
  props<{ response: InitiateDisabilityBenefitResponse }>(),
);

export const initiateDisabilityBenefitFailureAction = createAction(
  '[InitiateDisabilityBenefitAction/API] Failure',
  props<{ error?: any }>(),
);

export const clearInitiateDisabilityBenefitStateAction = createAction('[InitiateDisabilityBenefitAction] Clear');

//edit
export const editDisabilityBenefitAction = createAction(
  '[EditDisabilityBenefitAction/API] Send Request',
  props<{
    request: InitiateDisabilityBenefitRequest;
    calculationType: CalculationType;
    calculationBenefitId: string;
  }>(),
);

export const editDisabilityBenefitSuccessAction = createAction(
  '[EditDisabilityBenefitAction/API] Success',
  props<{ response: InitiateDisabilityBenefitResponse }>(),
);

export const editDisabilityBenefitFailureAction = createAction(
  '[EditDisabilityBenefitAction/API] Failure',
  props<{ error?: any }>(),
);

export const clearEditDisabilityBenefitStateAction = createAction('[EditDisabilityBenefitAction] Clear');

//get totals days used
export const getTotalDaysUsedAction = createAction(
  '[GetTotalDaysUsedAction/API] Send Request',
  props<{
    memberId: string;
    calculationType: CalculationType;
    calculationBenefitId?: string;
  }>(),
);

export const getTotalDaysUsedSuccessAction = createAction(
  '[GetTotalDaysUsedAction/API] Success',
  props<{ response: GetTotalDaysUsedResponse }>(),
);

export const getTotalDaysUsedFailureAction = createAction(
  '[GetTotalDaysUsedAction/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetTotalDaysUsedStateAction = createAction('[GetTotalDaysUsedAction] Clear');

//validate before remove Calculation
export const validateRemoveCalculationAction = createAction(
  '[ValidationRemoveCalculation/API] Send Request',
  props<{
    memberId: string;
    calculationRecordId: string;
  }>(),
);
export const validateRemoveCalculationSuccessAction = createAction(
  '[ValidationRemoveCalculation/API] Success',
  props<{ response: CheckComputeDisabilityDataResponse }>(),
);
export const validateRemoveCalculationFailureAction = createAction(
  '[ValidationRemoveCalculation/API] Failure',
  props<{ error?: any }>(),
);
export const clearValidateRemoveCalculationAction = createAction('[ValidationRemoveCalculation/API] Clear');

//remove Calculation
export const removeDisabilityCalculationAction = createAction(
  '[RemoveDisabilityBenefitCalculation/API] Send Request',
  props<{ memberId: string; calculationRecordId: string }>(),
);

export const removeDisabilityCalculationSuccessAction = createAction(
  '[RemoveDisabilityBenefitCalculation/API] Success',
  props<{ response: any }>(),
);

export const removeDisabilityCalculationFailureAction = createAction(
  '[RemoveDisabilityBenefitCalculation/API] Failure',
  props<{ error?: any }>(),
);

export const clearRemoveDisabilityCalculationStateAction = createAction('[RemoveDisabilityBenefitCalculation] Clear');

//calculation detail
export const getDisabilityCalculationDetailAction = createAction(
  '[GetDisabilityCalculationDetail/API] Send Request',
  props<{
    calculationBenefitId: string;
    memberId: string;
    calculationType: CalculationType;
    calculationRecordId: string;
  }>(),
);

export const getDisabilityCalculationDetailSuccessAction = createAction(
  '[GetDisabilityCalculationDetail/API] Success',
  props<{ response: GetDisabilityCalculationDetailResponse }>(),
);

export const getDisabilityCalculationDetailFailureAction = createAction(
  '[GetDisabilityCalculationDetail/API] Failure',
  props<{ error?: any }>(),
);

export const clearDisabilityCalculationDetailStateAction = createAction('[GetDisabilityCalculationDetail] Clear');

//edit calculation detail
export const editCalculationDetailAction = createAction(
  '[EditCalculationDetail/API] Send Request',
  props<{
    request: InitiateDisabilityBenefitRequest;
    calculationType: CalculationType;
    calculationBenefitId: string;
    calculationRecordId: string;
  }>(),
);

export const editCalculationDetailSuccessAction = createAction(
  '[EditCalculationDetail/API] Success',
  props<{ response: InitiateDisabilityBenefitResponse }>(),
);

export const editCalculationDetailFailureAction = createAction(
  '[EditCalculationDetail/API] Failure',
  props<{ error?: any }>(),
);

export const clearEditCalculationDetailStateAction = createAction('[EditCalculationDetail] Clear');
