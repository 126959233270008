<div class="organization-chart-container">
  <div #chartContainer id="chartContainer" class="chart-container">
    <div *ngIf="chartData.parents?.length">
      <ng-container [ngTemplateOutlet]="relationshipItem"
                    [ngTemplateOutletContext]="{ items: chartData.parents }">
      </ng-container>
      <ng-container [ngTemplateOutlet]="connectionArrowContainer"
                    [ngTemplateOutletContext]="{ items: chartData.parents, isUp: false }">
      </ng-container>
    </div>
    <div>
      <ng-container [ngTemplateOutlet]="relationshipItem"
                    [ngTemplateOutletContext]="{ items: chartData.siblings }">
      </ng-container>
    </div>
    <div *ngIf="chartData.children?.length">
      <ng-container [ngTemplateOutlet]="connectionArrowContainer"
                    [ngTemplateOutletContext]="{ items: chartData.children, isUp: false }">
      </ng-container>
      <ng-container [ngTemplateOutlet]="relationshipItem"
                    [ngTemplateOutletContext]="{ items: chartData.children }">
      </ng-container>
    </div>
  </div>
  <ptg-participant-detail-dialog *ngIf="selectedItem" [targetId]="memberId" [screenId]="screenId" [participantRelationshipDetail]="selectedItem" (close)="closeDetailPanel()"></ptg-participant-detail-dialog>
</div>

<ng-template #relationshipItem let-items="items">
  <div class="relationship-container">
    <div class="relationship-item"
      [ngClass]="{'current': item.graphRelationshipType === RelationshipType.Self, 'selected': item.selected}"
      *ngFor="let item of items" (click)="selectRelationshipItem(item)"
    >
      <div class="card-label">
        <ptg-view-value [isTruncate]="true" [data]="{ value: item.cardLabel, type: FilterPropertyType['Person Name'] }"></ptg-view-value>
      </div>
      <div class="flex flex-wrap mb-3.5" *ngIf="item.benefitLabels?.length || item?.relationshipLabel">
        <div class="relationship-label benefit" *ngFor="let benefitLabel of item.benefitLabels" [ngClass]="{ 'has-value' : benefitLabel }">{{ benefitLabel }}</div>
        <div class="relationship-label relationship" *ngIf="item?.relationshipLabel" [ngClass]="{ 'has-value' : item?.relationshipLabel }">{{ item.relationshipLabel }}</div>
      </div>
      <div *ngFor="let property of $any(item.memberGraphConfigDataItems | slice:0:2)" class="config-item">
        <span class="truncate label">{{property.propertyLabel }}</span>
        <div class="value-container">
          <ptg-view-value [isTruncate]="true" [data]="{ value: property.value, type: property.type }"></ptg-view-value>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #connectionArrowContainer let-items="items" let-isUp="isUp">
  <div class="connection-arrow-container" [ngClass]="{'up': isUp, 'multi-parent': items[0].graphRelationshipType === RelationshipType.Parent && items.length > 1}">
    <div class="connection-arrow-item"
         [ngClass]="{'up': (item.graphRelationshipType === RelationshipType.Parent && !isFirst) || isUp, 'first': isFirst}"
         *ngFor="let item of items; index as i; first as isFirst">
      <mat-icon class="arrow" *ngIf="!(item.graphRelationshipType === RelationshipType.Parent && !isFirst)" >play_arrow</mat-icon>
    </div>
  </div>
</ng-template>
