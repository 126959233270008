import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { GetInitialPaymentConfigurationsResponse } from '../../services/models';
import {
  clearGetInitialPaymentConfigurationsStateAction,
  getInitialPaymentConfigurationsAction,
  getInitialPaymentConfigurationsFailureAction,
  getInitialPaymentConfigurationsSuccessAction,

  clearSetInitialPaymentConfigurationsStateAction,
  setInitialPaymentConfigurationsAction,
  setInitialPaymentConfigurationsFailureAction,
  setInitialPaymentConfigurationsSuccessAction,

} from '../actions';

export interface InitialPaymentConfigurationState {
  getInitialPaymentConfigurations?: BaseActionState<GetInitialPaymentConfigurationsResponse>;
  setInitialPaymentConfigurations?: BaseActionState;
}

const initialState: InitialPaymentConfigurationState = {};

export const initialPaymentConfigurationReducer = createReducer(
  initialState,
  on(getInitialPaymentConfigurationsAction, (state) => ({
    ...state,
    getInitialPaymentConfigurations: {
      isLoading: true,
    },
  })),
  on(getInitialPaymentConfigurationsSuccessAction, (state, { response }) => ({
    ...state,
    getInitialPaymentConfigurations: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getInitialPaymentConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    getInitialPaymentConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetInitialPaymentConfigurationsStateAction, (state) => ({
    ...state,
    getInitialPaymentConfigurations: undefined,
  })),

  on(setInitialPaymentConfigurationsAction, (state) => ({
    ...state,
    setInitialPaymentConfigurations: {
      isLoading: true,
    },
  })),
  on(setInitialPaymentConfigurationsSuccessAction, (state) => ({
    ...state,
    setInitialPaymentConfigurations: {
      isLoading: false,
      success: true,
    },
  })),
  on(setInitialPaymentConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    setInitialPaymentConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetInitialPaymentConfigurationsStateAction, (state) => ({
    ...state,
    setInitialPaymentConfigurations: undefined,
  }))

);
