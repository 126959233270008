export enum InputType {
  Text = 'Text',
  Date = 'Date',
  DateTime = 'DateTime',
  Email = 'Email',
  Phone = 'Phone',
  Number = 'Number',
  Decimal = 'Decimal',
  Percentage = 'Percentage',
  Currency = 'Currency',
  Ssn = 'SSN',
  RichText = 'RichText',
  Password = 'Password',
  Whole_Number = 'Whole_Number',
  WholeNumber = 'WholeNumber',
  'Whole Number' = 'Whole Number',
  Binary = 'Binary',
  List = 'List',
  Address = 'Address',
  Person_Name = 'Person_Name',
  PersonName = 'PersonName',
  'Person Name' = 'Person Name',
  Lookup = 'Lookup',
  Employer = 'Employer',
  Department = 'Department',
  Benefit = 'Benefit',
  Tier = 'Tier',
  MultipleSelect = 'MultipleSelect',
  Status = 'Status',
}

export enum PropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  Whole_Number = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  Lookup = 50,
  Address = 100,
  Person_Name = 101,
  Employer = 51,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  Aggregation = 103,
  Elapsed_Time = 14,
  DateTime = 8,
  Department = 52,
  Benefit = 53,
  Tier = 55,
  Status = 102,
}

export enum StatusOption {
  Date = 'Date',
  Name = 'Name',
  Event = 'Event',
}
