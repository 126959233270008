import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { deepEqual, getCurrentFundData } from '@ptg-shared/utils/common.util';
import { distinctUntilChanged, filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { ResourceCenterService } from 'src/app/fund-management/services/resource-center.service';
import { Auth0Service } from 'src/app/shared/auth/services/auth0.service';
import { ADMIN_SYSTEM } from 'src/app/shared/constance/value.const';
import { CheckPermissionService } from 'src/app/shared/services/check-permission.service';
import * as FundListActions from '../../../../fund-list/actions/fund-list.actions';
import * as fromReducer from '../../../../reducers';
import { FundSettings } from '@ptg-fund-list/models/fund-list.model';
import { FundListService } from '@ptg-fund-list/services/fund-list.service';

@Component({
  selector: 'ptg-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent extends BaseComponent {

  constructor(
    private router: Router,
    private authService: Auth0Service,
    public checkPermissionService: CheckPermissionService,
    private resourceCenterService: ResourceCenterService,
    private store: Store<fromReducer.State>,
    private fundListService: FundListService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.authService.Role !== ADMIN_SYSTEM) {
      this.resourceCenterService.getFundByKey(this.authService.fundKey).subscribe((fundModel: any) => {
        this.store.dispatch(FundListActions.selectFund(getCurrentFundData(fundModel)));
        
        this.fundListService.getClientSettings(this.authService.fundKey).subscribe((fundSettings: FundSettings) => {
          this.store.dispatch(FundListActions.setFundInformations({ 
            fundInfo: fundModel, 
            fundSettings: fundSettings 
          }));
        });
      }, (error: any) => {
        this.checkPermissionService.isNoPermission = true;
      });

      this.store.select(fromReducer.selectCurrentFundState)
        .pipe(
          distinctUntilChanged((previous, current) => deepEqual(previous, current)),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(currentFund => {
          if (currentFund?.id) {
            this.checkPermissionService.redirectByPermission();
          }
        });
    } else {
      this.router.navigateByUrl('/fund-list');
    }
  }
}
