import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearGetHeaderConfigStateAction,
  clearGetPayeePaymentStateAction,
  clearSetPayeePaymentStateAction,
  getHeaderConfigAction,
  getHeaderConfigFailureAction,
  getHeaderConfigSuccessAction,
  getPayeePaymentAction,
  getPayeePaymentFailureAction,
  getPayeePaymentSuccessAction,
  setPayeePaymentAction,
  setPayeePaymentFailureAction,
  setPayeePaymentSuccessAction,
} from '../actions';
import { GetHeaderConfigResponse, GetPayeePaymentResponse } from '../../types/models';

export interface PayeePaymentState {
  getPayeePayment?: BaseActionState<GetPayeePaymentResponse>;
  setPayeePayment?: BaseActionState;
  getHeaderConfig?: BaseActionState<GetHeaderConfigResponse>;
}

const initialState: PayeePaymentState = {};

export const payeePaymentReducer = createReducer(
  initialState,
  on(getPayeePaymentAction, (state) => ({
    ...state,
    getPayeePayment: {
      isLoading: true,
    },
  })),
  on(getPayeePaymentSuccessAction, (state, { response }) => ({
    ...state,
    getPayeePayment: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPayeePaymentFailureAction, (state, { error }) => ({
    ...state,
    getPayeePayment: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPayeePaymentStateAction, (state) => ({
    ...state,
    getPayeePayment: undefined,
  })),
  on(setPayeePaymentAction, (state) => ({
    ...state,
    setPayeePayment: {
      isLoading: true,
    },
  })),
  on(setPayeePaymentSuccessAction, (state) => ({
    ...state,
    setPayeePayment: {
      isLoading: false,
      success: true,
    },
  })),
  on(setPayeePaymentFailureAction, (state, { error }) => ({
    ...state,
    setPayeePayment: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetPayeePaymentStateAction, (state) => ({
    ...state,
    setPayeePayment: undefined,
  })),
  on(getHeaderConfigAction, (state) => ({
    ...state,
    getHeaderConfig: {
      isLoading: true,
    },
  })),
  on(getHeaderConfigSuccessAction, (state, { response }) => ({
    ...state,
    getHeaderConfig: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getHeaderConfigFailureAction, (state, { error }) => ({
    ...state,
    getHeaderConfig: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetHeaderConfigStateAction, (state) => ({
    ...state,
    getHeaderConfig: undefined,
  })),
);
