import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-input-area',
  templateUrl: './input-area.component.html',
  styleUrls: ['./input-area.component.scss']
})
export class InputAreaComponent implements OnInit {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;
  
  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() maxLength?: number;
  @Input() errorRequire?: string;
  @Input() errorMaxLength?: string;
  @Input() errorPattern?: string;
  @Input() minRows?: number = 5;
  @Input() maxRows?: number;
  @Input() hasLabel?: boolean = true;
  @Input() errorAsync: string = '';
  @Input() class?: string;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(event?: Event) {
    this.valueChange.emit(this.controlField.value);
  }

  trimValue() {
    if (this.controlField.value) {
      this.controlField.setValue(this.controlField.value.trim());
    }
  }

}
