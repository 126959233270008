<div
  *ngIf="(value || value === 0); else emptyValue"
  class="number-decimal-column-container inline-flex gap-1"
  [class.flex-row-reverse]="unitPosition === 'right'"
>
  <span *ngIf="unit && !accountingFormat" class="unit">{{ unit }}</span>
  <span class="value">{{ value | numberLocalDecimal : (accountingFormat ? unit : '') : { decimal, accountingFormat } }}</span>
</div>
<ng-template #emptyValue>
  <span *ngIf="emptyValueDisplay">{{ emptyValueDisplay }}</span>
</ng-template>
