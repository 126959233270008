import { Option } from '@ptg-shared/controls/select/select.component';
import { CourtOrderType, EntityPropertyType } from '../../types/enums';

export interface CheckExistPropertyValueRequest {
  entityId: string;
  componentId: string;
  propertyId: string;
  propertyType: EntityPropertyType;
  id?: string;
  index?: number;
  value: any;
}

export interface CheckExistPropertyValueResponse {
  isExist: boolean;
}

export interface CheckSumOfPercentageValueRequest {
  recordId?: string;
  targetId: string;
  value: number;
  isCheckSumLessThan100?: boolean; 
}

export interface CheckSumOfPercentageValueResponse {
  isValid: boolean;
}

export interface CheckIsPrimarySavedRequest {
  value?: string,
  recordId: string;
  targetId: string;
}

export interface CheckIsPrimarySavedResponse {
  isValid: boolean;
  message?: string;
}

export interface CheckRelationshipPayeeInUseRequest {
  recordId?: string;
  targetId: string;
}

export interface InitiationProperty {
  staticPropertyType: number;
  id: string;
  entityId: string;
  entityPropertyId: string;
  entityComponentId: string;
  entityPropertyName?: string;
  name: string;
  importKey: string;
  type: EntityPropertyType;
  order: number;
  label: string;
  configs: any;
  options: any[];
  recordId: string;
  referenceDatas?: EntityInitiationPropertyValue[];
  fixedPropertyKey?: string,
  initiationValue?: boolean;
  isInitiationProperty?: boolean;
  messageError?: string;
  isHidden?: boolean;
  properties: InitiationProperty[];
  isComputedProperty: boolean;

  // Use to serve Court Order logics
  isCourtOrderTypeProperty?: boolean,
  isPayeeProperty?: boolean,
  isPayeeInformationProperty?: boolean,
  isFirstPayeeInformationProperty?: boolean,
  isLastPayeeInformationProperty?: boolean,

  // Use to serve Relationship List logics
  isLegalRelationshipType?: boolean,
  isFirstBeneficiaryProperty?: boolean,
  isFirstDependentProperty?: boolean,
  isFirstPowerOfAttorneyProperty?: boolean,
  relationshipListSectionTitle?: string,
  belongLegalRelatioshipTypeId?: string,
  text?: string,

  // Use to serve Entity Reference belong Properties Order logics
  belongPropertiesOrder?: any[],
}

export interface GetInitiationPropertiesResponse {
  entityId: string;
  entityLabel: string;
  entityComponentId: string;
  properties: InitiationProperty[];
  includeEntityReference?: boolean;
  title?: string;
  entityEntityComponents?: EntityEntityComponent[];
}

export interface EntityEntityComponent{
  entityId: string;
  entityComponentId: string;
}

export interface EntityInitiationPropertyValue {
  recordId: string;
  entityId: string;
  entityComponentId: string;
  entityPropertyId: string;
  entityReferenceId?: string;
  entityReferenceComponentId?: string;
  entityReferencePropertyId?: string;
  entityReferenceLinkedId?: string;
  entitySelectedId?: string;
  selectedRecordId?: string;
  value: any;
  configs: any;
  options: any;
  type: any;
  label: string;
  valueObj: any;
  valueMask: {
    maskedValue: string;
    originalValue: string;
  };
  recordLinkedId?: string;
  isInitiationProperty?: boolean;
  isComputedProperty?: boolean;
  entityPropertyReferenceValue?: any;
  id?: string;
  rawValue?: any;
}

export interface ModuleConnections {
	items: ModuleConnectionItem[];
}

export interface ModuleConnectionItem {
	entityId: string;
	entityLabel: string;
	moduleKey: string;
}

export interface DetailOptionStatus {
  id: string;
  name: string;
  events: DetailOptionEvent[];
}

export interface DetailOptionEvent {
  id: string;
  name: string;
}

export interface PayeeRequest {
  memberId?: string;
  courtOrderType?: number;
  screenId?: string;
  courtOrderComponentId?: string;
  payeeId?: string;
}

export interface GetPayeePropertiesResponse {
	payeeOptions?: PayeeOption[];
}

export interface PayeeOption {
  id: string;
  name: string;
  relationship: string;
  relationshipLookupId: string;
  type: number;
}

export interface CheckExistSpouseRequest {
  targetId: string;
  value: string;
  lookupTableId: string;
}

export interface CheckExistSpouseResponse {
  personId?: string;
}

export interface GetCourtOrderPayeeInfoRequest {
  entityId: string,
  id: string,
}

export interface CourtOrderQdroSelectedPayee {
  id: string,
  name: string,
  relationship: string,
  relationshipLookupId: string,
  type: CourtOrderType,
}

export interface CourtOrderQdroSelectedPayeeOriginalPersonName {
  prefix?: string,
  first?: string,
  middle?: string,
  last?: string,
  suffix?: string,
}

export interface LegalRelationshipTypeOption extends Option {
  id: string,
  text: string,
  type: number,
  active: boolean,
  description: string,
  code: string,
  relationshipType: any,
  label?: string,
}
