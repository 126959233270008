<div class="header">
  <span class="title"
    >{{ data?.memoDetails ? 'Edit' : 'Add' }}
    {{ data.noteType === PaymentInfoNoteTypeEnum.Note ? 'Note' : 'Memo' }}</span
  >
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="group-control">
    <ptg-input-area
      [controlField]="formGroup.get('note')"
      [placeholder]="data.noteType === PaymentInfoNoteTypeEnum.Note ? 'Note' : 'Memo'"
      [errorRequire]="data.noteType === PaymentInfoNoteTypeEnum.Note ? 'Note is required.' : 'Memo is required.'"
      [maxRows]="data.noteType === PaymentInfoNoteTypeEnum.Note ? 5 : 4"
    ></ptg-input-area>
  </div>
  <div *ngIf="data.noteType === PaymentInfoNoteTypeEnum.Memo" class="text-count">
    <span
      >Total characters:
      {{
        formGroup.get('note')?.value?.length
          ? (formGroup.get('note')?.value?.length | numberDecimal: { decimal: 0 })
          : 0
      }}</span
    >
  </div>
  <div class="wrap-btn">
    <button mat-raised-button type="submit" style="background-color: #2d6c71; color: #fff; margin-right: 8px">
      Save
    </button>
    <button mat-raised-button type="button" style="background-color: #232323; color: #fff" (click)="onCancel()">
      Cancel
    </button>
  </div>
</form>
