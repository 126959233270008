import { createAction, props } from '@ngrx/store';
import { GetPayrollSettingsRequest, GetPayrollSettingsResponse } from '../../services/models';

export const getPayrollSettingsAction = createAction(
  '[GetPayrollSettings/API] Send Request',
  props<{ request: GetPayrollSettingsRequest }>(),
);

export const getPayrollSettingsSuccessAction = createAction(
  '[GetPayrollSettings/API] Success',
  props<{ response: GetPayrollSettingsResponse }>(),
);

export const getPayrollSettingsFailureAction = createAction(
  '[GetPayrollSettings/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetPayrollSettingsStateAction = createAction('[GetPayrollSettings] Clear');
