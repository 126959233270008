import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { GetChildSelectionResponse, GetChildSurvivorLabelNameResponse, GetChildSurvivorValidationBeforeInitializationResponse } from '../../services/models/calculation-child-survivor.model';
import { getChildSelectionAction, getChildSelectionSuccessAction, getChildSelectionFailureAction, clearGetChildSelectionStateAction, clearGetChildSurvivorValidationBeforeInitializationStateAction, getChildSurvivorValidationBeforeInitializationAction, getChildSurvivorValidationBeforeInitializationFailureAction, getChildSurvivorValidationBeforeInitializationSuccessAction, clearGetChildSurvivorLabelNameStateAction, getChildSurvivorLabelNameAction, getChildSurvivorLabelNameFailureAction, getChildSurvivorLabelNameSuccessAction } from '../actions/calculation-child-survivor.action';


export interface CalculationChildSurvivorState {
  getChildSelectionState?: BaseActionState<GetChildSelectionResponse>;
  getChildSurvivorValidationBeforeInitialization?: BaseActionState<GetChildSurvivorValidationBeforeInitializationResponse>;
  getChildSurvivorLabelName?: BaseActionState<GetChildSurvivorLabelNameResponse>;
}

const initialState: CalculationChildSurvivorState = {};

export const calculationChildSurvivorReducer = createReducer(
  initialState,

  // Get Child Survivor Label Name
  on(getChildSurvivorLabelNameAction, (state) => ({
    ...state,
    getChildSurvivorLabelName: {
      isLoading: true,
    },
  })),
  on(getChildSurvivorLabelNameSuccessAction, (state, { response }) => ({
    ...state,
    getChildSurvivorLabelName: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getChildSurvivorLabelNameFailureAction, (state, { error }) => ({
    ...state,
    getChildSurvivorLabelName: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetChildSurvivorLabelNameStateAction, (state) => ({
    ...state,
    getChildSurvivorLabelName: undefined,
  })),

  // Get Child Selection
  on(getChildSelectionAction, (state) => ({
    ...state,
    getChildSelectionState: {
      isLoading: true,
    },
  })),
  on(getChildSelectionSuccessAction, (state, { response }) => ({
    ...state,
    getChildSelectionState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getChildSelectionFailureAction, (state, { error }) => ({
    ...state,
    getChildSelectionState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetChildSelectionStateAction, (state) => ({
    ...state,
    getChildSelectionState: undefined,
  })),

  // Get Child Survivor Validation Before Initiate
  on(getChildSurvivorValidationBeforeInitializationAction, (state) => ({
    ...state,
    getChildSurvivorValidationBeforeInitialization: {
      isLoading: true,
    },
  })),
  on(getChildSurvivorValidationBeforeInitializationSuccessAction, (state, { response }) => ({
    ...state,
    getChildSurvivorValidationBeforeInitialization: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getChildSurvivorValidationBeforeInitializationFailureAction, (state, { error }) => ({
    ...state,
    getChildSurvivorValidationBeforeInitialization: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetChildSurvivorValidationBeforeInitializationStateAction, (state) => ({
    ...state,
    getChildSurvivorValidationBeforeInitialization: undefined,
  })),
);
