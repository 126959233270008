<div class="edit-menu-container" id="edit-menu-dialog">
  <ptg-title-bar name="Edit" viewName="Menu" [onlyTitle]="true"></ptg-title-bar>

  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <ptg-checkbox [controlField]="editForm.get('disabled')" label="Disabled"></ptg-checkbox>
      <ptg-textbox
        placeholder="Menu Name"
        [ngClass]="{ 'has-error': editForm.get('name')?.errors && editForm.get('name')?.touched }"
        [controlField]="editForm.get('name')"
        [hasLabel]="true"
        [maxLength]="100"
      ></ptg-textbox>
      <div class="status-container">
        <div class="status-select">
          <mat-form-field
            appearance="fill"
            class="select-custom"
            [ngClass]="{ 'mat-form-field-invalid': isInvalidStatus }"
          >
            <mat-label>Status</mat-label>
            <mat-select #select multiple panelClass="multiple-select-panel">
              <mat-select-trigger>
                <span class="option-class">
                  <span class="status-container" *ngFor="let optionSelected of select.value; last as isLast">
                    <mat-icon
                      *ngIf="optionSelected?.iconName"
                      class="icon-status"
                      [style.color]="optionSelected?.color"
                      >{{ optionSelected?.iconName }}</mat-icon
                    >
                    <span class="status-name">{{ optionSelected?.key }} {{ isLast ? '' : ', ' }}</span>
                  </span>
                </span>
              </mat-select-trigger>
              <div class="select-all" *ngIf="listStatusOption.length > 0">
                <mat-checkbox
                  [(ngModel)]="isAllSelected"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="toggleAllOption()"
                  [indeterminate]="select?.value?.length > 0 && !isAllSelected"
                >
                  (Select All)
                </mat-checkbox>
              </div>
              <mat-option *ngIf="listStatusOption.length === 0" disabled>(Select All)</mat-option>
              <mat-option
                (click)="toggleOneOption()"
                class="select-status-option"
                *ngFor="let option of listStatusOption"
                [value]="option"
              >
                <mat-icon [style.color]="option?.color">{{ option?.iconName }}</mat-icon>
                <span>{{ option.key }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="add-btn" [ngClass]="{ 'add-btn-disable': !select.value }">
            <mat-icon (click)="select.value ? addStatus() : {}">add_circle</mat-icon>
          </div>
          <div class="chips-style">
            <mat-chip-list>
              <mat-chip *ngFor="let option of listStatusOptionSelected">
                <mat-icon *ngIf="option?.iconName" [style.color]="option?.color">{{ option?.iconName }}</mat-icon>
                <span>{{ option?.key }}</span>
                <div class="remove-btn">
                  <mat-icon (click)="removeStatus(option)">clear</mat-icon>
                </div>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div class="error-message">
          <mat-error id="mat-error-status" *ngIf="isInvalidStatus" class="icon-name-status">
            Status is required.
          </mat-error>
        </div>
      </div>
      <div class="icon-color-container">
        <ptg-input-color
          [controlField]="editForm.get('color')"
          placeholder="Menu Color"
          errorInvalid="Invalid input."
        ></ptg-input-color>
      </div>
    </div>
  </form>
</div>
