import { Component, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  clearEditDocumentsStateAction,
  clearRemoveDocumentsStateAction,
  clearUploadDocumentsStateAction,
  getDocumentListsAction,
  removeDocumentsAction,
} from '@ptg-member/store/actions/member-document.action';
import {
  selectEditMemberDocumentState,
  selectMemberDocumentListState,
  selectRemoveMemberDocumentState,
  selectUploadMemberDocumentState
} from '@ptg-member/store/reducers';
import { DocumentListState } from '@ptg-member/store/reducers/member-document.reducer';
import { DocumentBodyRequest, DocumentList, RemoveDocumentItem } from '@ptg-member/types/models/member-document.model';
import { BaseComponent } from '@ptg-shared/components';
import { ACTION, STATE, SortType } from '@ptg-shared/constance';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { catchError, delay, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import * as fromReducer from '@ptg-reducers';
import { Sort } from '@angular/material/sort';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DatePipe } from '@angular/common';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { selectDowloadMemberDocumentState, selectDownloadMultipleDocumentState, selectPrepareDownloadMultipleDocumentState, selectcheckHaveZipFileState } from 'src/app/admin/features/file/store/selectors';
import { checkHaveZipFileAction, clearCheckHaveZipFileStateAction, clearDownloadMultipleDocumentsStateAction, clearGetDocumentDownloadStateAction, clearPrepareDownloadMultipleDocumentsStateAction, downloadMultipleDocumentsAction, getDocumentDownloadAction, prepareDownloadMultipleDocumentsAction } from 'src/app/admin/features/file/store/actions';
import { MemberDocumentService } from '@ptg-member/services/member-document.service';
import { EMPTY, Observable, of, timer } from 'rxjs';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { editDocumentsAction } from '@ptg-member/store/actions/member-document.action';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { CheckExistsDocumentNameResponse } from '@ptg-member/types/models/member-document.model';
import { MemberDetailActions } from '@ptg-member/store/actions';
import * as fromMember from '../../store/reducers';
import { DocumentListViewComponent } from 'src/app/admin/features/file/pages/document-list-view/document-list-view.component';
import { AccidentDocGenerate } from '@ptg-member/features/accident-claims/constants';
import { SERVICE_HISTORY_LIST } from '@ptg-entity-management/constants';
import { REMOVE_DOCUMENT_POPUP_CONFIG } from 'src/app/admin/features/file/constants';

const PAGE_SIZE_CONST = '-ptg-member-document-list-pageSize';

@Component({
  selector: 'ptg-member-document-list',
  templateUrl: './member-document-list.component.html',
  styleUrls: ['./member-document-list.component.scss']
})
export class MemberDocumentListComponent extends BaseComponent {
  readonly EntityType = EntityType;

  @ViewChild('gridDocumentData') gridDocumentData!: DocumentListViewComponent;

  dataTable!: DocumentList[];
  pageSize!: number;
  pageNumber: number = FIRST_PAGE;
  lengthPg: number | any;
  sortInfo: {} | any = {};
  isLoading: boolean = true;
  currentFund: any = {};
  message: string = '';
  customMessage = 'Error occurred while downloading Document(s). Please try again.';
  bannerType: BannerType = BannerType.Hidden;
  downloadFileName: string = 'sample.pdf';
  zipId?: string;
  downloaded: boolean = false;
  documentFilter!: any;
  memberId: string = '';
  constructor(
    private store: Store<DocumentListState>,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private documentStore: Store<DocumentsState>,
    private memberDocumentService: MemberDocumentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentFundState();
    this.getData();
    this.store
      .pipe(select(selectMemberDocumentListState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoading = data?.isLoading;
        }
        this.lengthPg = data?.total;
        this.dataTable = data?.payload?.map((item: any) => {
          return {
            ...item,
            showOnOverview: item.showOnOverview === true ? 'Yes' : item.showOnOverview === false ? 'No' : '',
            uploadedDate: this.datePipe.transform(getDateString(item.uploadedDate), 'MM/dd/yyyy')
          }
        }) ?? []
      });

    // Get Response after clicking download
    this.documentStore.select(selectPrepareDownloadMultipleDocumentState)
      .pipe(
        delay(2000),
        filter(x => !!x),
        takeUntil(this.unsubscribe$),
        switchMap((state: any) => {
          this.zipId = state?.payload?.zipId;
          if (this.zipId) {
            this.documentStore.dispatch(checkHaveZipFileAction({ zipId: this.zipId }));
            return this.documentStore.select(selectcheckHaveZipFileState).pipe(takeUntil(this.unsubscribe$));
          } else if (this.zipId === null) {
            showBanner.call(this, STATE.FAIL, '', ACTION.DOWNLOAD, {
              customMessage: this.customMessage
            });
            this.documentStore.dispatch(clearCheckHaveZipFileStateAction());
            this.documentStore.dispatch(clearDownloadMultipleDocumentsStateAction());
            this.documentStore.dispatch(clearPrepareDownloadMultipleDocumentsStateAction());
          }
          return EMPTY;
        }),
        switchMap((state: any) => {
          if (state?.payload?.status === "Available") {
            return of(state?.payload?.status);
          } else {
            return of(state?.payload?.status).pipe(delay(3000));
          }
        })
      )
      .subscribe(state => {
        if (state === "Zipping") {
          this.documentStore.dispatch(checkHaveZipFileAction({ zipId: this.zipId }));
        } else if (state === "Available") {
          if (this.zipId) {
            this.documentStore.dispatch(downloadMultipleDocumentsAction({
              zipId: this.zipId
            }));
          }
        } else if (state === "Unavailable") {
          showBanner.call(this, STATE.FAIL, '', ACTION.DOWNLOAD, {
            customMessage: this.customMessage
          });
          this.documentStore.dispatch(clearCheckHaveZipFileStateAction());
          this.documentStore.dispatch(clearDownloadMultipleDocumentsStateAction());
          this.documentStore.dispatch(clearPrepareDownloadMultipleDocumentsStateAction());
        }
      });

    this.store
      .pipe(select(selectRemoveMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          showBanner.call(this, state?.state, 'Document', ACTION.REMOVE);
          this.store.dispatch(clearRemoveDocumentsStateAction());
          if (state.state === STATE.SUCCESS) {
            if (this.pageNumber !== 1 && this.dataTable.length <= 1) {
              this.pageNumber = this.pageNumber - 1;
            }
            this.getData();
            this.gridDocumentData.gridDocumentList.selection.clear();
            if (this.gridDocumentData.functionButtons?.length > 0) {
              this.gridDocumentData.functionButtons.forEach(btn => {
                if (btn.buttonName === 'Remove' || btn.buttonName === 'Download') {
                  btn.isDisabled = true;
                }
              })
            }
          }
        }
      });

    this.store
      .pipe(select(selectUploadMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          showBanner.call(this, state?.state, 'Document', ACTION.UPLOAD);
          this.store.dispatch(clearUploadDocumentsStateAction());
          this.getData();
        }
      });

    this.store
      .pipe(select(selectEditMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          showBanner.call(this, state?.state, 'Document', ACTION.EDIT);
          this.store.dispatch(clearEditDocumentsStateAction());
          this.getData();
        }
      });

    this.documentStore
      .pipe(select(selectDownloadMultipleDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success) {
          this.downloaded = true;
          this.documentStore.dispatch(clearCheckHaveZipFileStateAction());
          this.documentStore.dispatch(clearDownloadMultipleDocumentsStateAction());
          this.documentStore.dispatch(clearPrepareDownloadMultipleDocumentsStateAction());
        }
      });

      this.documentStore
        .pipe(select(selectDowloadMemberDocumentState),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state) => {
          if (state?.success === true) {
            this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
          } else if (state?.success === false) {
            showBanner.call(this, STATE.FAIL, 'Document', ACTION.DOWNLOAD);
            this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
          }
        });
  }

  getCurrentFundState() {
    this.store.pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    ).subscribe(el => {
      this.currentFund = el;
      this.pageSize = el.defaultPageSize ?? 50;
      const defaultPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      this.pageSize = defaultPageSize === 0 ? this.pageSize : defaultPageSize;
    });
  }

  getData() {
    let sortType = SortType.DESC;
    let sortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType =
        this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(getDocumentListsAction({
      request: {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortNames,
        sortType,
        filters: this.documentFilter
      }
    }))
  }

  changePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  sortChange(sort: Sort) {
    this.sortInfo = sort;
    this.getData();
  }

  downloadDocument(row: any) {
    this.bannerType = BannerType.Hidden;
    if (!row) {
      return;
    }
    this.downloadFileName = row?.fileName;
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }));
  }

  // Remove single document
  onClickRemoveDocument(row: any) {
    this.bannerType = BannerType.Hidden;
    const mesError = 'Cannot remove Postcard Acknowledgement Letter.';
    const listRemoveDocumentItem: RemoveDocumentItem[] = [
      {
        memberId: row?.memberId,
        documentId: row?.fileId,
        documentLocation: row?.documentLocation,
        calculationBenefitId: row?.calculationBenefitId,
        calculationRecordId: row?.calculationRecordId,
        calculationType: row?.calculationType,
        calculationBenefitDocumentId: row?.calculationBenefitDocumentId,
        calculationBenefitDocumentDetailId: row?.calculationBenefitDocumentDetailId,
        accidentId: row?.accidentId,
        accidentDocumentId: row?.accidentDocumentId,
      }
    ];
    if(row?.accidentDocumentClassification === AccidentDocGenerate.AccidentPostcard){
      showBanner.call(this, STATE.FAIL, '', ACTION.REMOVE, {
        customMessage: mesError,
      });
    } else {
      if (row?.calculationBenefitDocumentId) {
        this.memberDocumentService.validateBeforeRemoveDocument({ values: listRemoveDocumentItem }).subscribe(res => {
          if (!res?.isValid && res?.message) {
            this.showErrorPopup(res?.message);
          } else {
            this.removeDocuments({ values: listRemoveDocumentItem });
          }
        });
      } else {
        this.store.dispatch(
          MemberDetailActions.checkRemoveExitAttachmentAction({
            body: [row?.fileId],
          })
        );
        const subscription = this.store
        .pipe(
          select(fromMember.checkRemoveExitAttachmentSelector),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((state: any) => {
          if (state?.success) {
            const response = state?.response;
            if (!response.isValid && response.message) {
              this.showErrorPopup(response.message);
            } else {
              this.removeDocuments({ values: listRemoveDocumentItem });
            }
            this.store.dispatch(
              MemberDetailActions.clearRemoveExitAttachmentFailure()
            );
            subscription.unsubscribe();
          }
        });
      }
    }
    
  }

  // Remove multiple document
  onClickRemoveMultipleDocument(listSelectedRow: any) {
    this.bannerType = BannerType.Hidden;
    listSelectedRow = listSelectedRow.filter((row: any) => this.dataTable.some(doc => row.id === doc.id));
    const listRemoveDocumentItem: RemoveDocumentItem[] = listSelectedRow.map((doc: any) => {
      return {
        memberId: doc?.memberId,
        documentId: doc?.fileId,
        documentLocation: doc?.documentLocation,
        calculationBenefitId: doc?.calculationBenefitId,
        calculationRecordId: doc?.calculationRecordId,
        calculationType: doc?.calculationType,
        calculationBenefitDocumentId: doc?.calculationBenefitDocumentId,
        calculationBenefitDocumentDetailId: doc?.calculationBenefitDocumentDetailId,
        accidentId: doc?.accidentId,
        accidentDocumentId: doc?.accidentDocumentId,
      };
    });
    const listCalculationDocument = listRemoveDocumentItem.filter((doc: any) => !!doc?.calculationBenefitDocumentId);
    if (listCalculationDocument?.length) {
      this.memberDocumentService.validateBeforeRemoveDocument({ values: listCalculationDocument }).subscribe(res => {
        if (!res?.isValid && res?.message) {
          this.showErrorPopup(res?.message);
        } else {
          this.removeDocuments({ values: listRemoveDocumentItem });
        }
      });
    } else {
      const listCordOrderRemove: string[] = listSelectedRow.map((doc: any) => doc?.fileId);
      this.store.dispatch(
        MemberDetailActions.checkRemoveExitAttachmentAction({
          body: listCordOrderRemove,
        })
      );
      const subscription = this.store
      .pipe(
        select(fromMember.checkRemoveExitAttachmentSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state: any) => {
        if (state?.success) {
          const response = state?.response;
          if (!response.isValid && response.message) {
            this.showErrorPopup(response.message);
          } else {
            this.removeDocuments({ values: listRemoveDocumentItem });
          }
          this.store.dispatch(
            MemberDetailActions.clearRemoveExitAttachmentFailure()
          );
          subscription.unsubscribe();
        }
      });
    }
  }

  removeDocuments(body: DocumentBodyRequest) {
    this.bannerType = BannerType.Hidden;
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: REMOVE_DOCUMENT_POPUP_CONFIG,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(removeDocumentsAction({ body }));
      }
    });
  }

  private showErrorPopup(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Error',
        type: ConfirmType.Warning,
        text: message,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  // Dowload multiple document
  downloadMultipleDocument(listSelectedRow: any) {
    this.downloaded = false;
    this.bannerType = BannerType.Hidden;
    const listSelectedId = listSelectedRow.map((item: any) => item.fileId);
    if (listSelectedRow?.length === 1) {
      this.downloadDocument(listSelectedRow[0]);
    } else if (listSelectedRow?.length > 1) {
      this.store.dispatch(prepareDownloadMultipleDocumentsAction({
        fileIds: listSelectedId
      }))
    }
  }

  validateExistDocumentNameExist(documentId: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.memberDocumentService.checkExistDocumentName(
            {
              memberId: this.memberId || '',
              documentName: control.value.toString(),
              documentId: documentId ?? null,
            }
          ).
            pipe(
              map((response: CheckExistsDocumentNameResponse) => {
                if (response?.exists) {
                  return { errMsgDocumentName: 'Document Name already exists.' };
                }
                return null;
              }),
              catchError(({ error }) => {
                return of({ errMsgDocumentName: error?.errorMessage });
              }),
            )
        )
      );
    };
  }

  editDocument(row: any) {
    this.memberId = row.memberId
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    }
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(row.fileId),
    }
    
    // Use for Document uploaded from Service History List Card, and must belong to a List Record
    const isDocumentBelongServiceHistoryListCard = row?.entityComponentId?.toUpperCase() === SERVICE_HISTORY_LIST.Id.toUpperCase();
    const isDocumentBelongOneListRecord = !!row?.targetId;
    const isUploadEditDocumentForServiceHistoryListRecord = isDocumentBelongServiceHistoryListCard && isDocumentBelongOneListRecord;
    // End of Use for Document uploaded from Service History List Card, and must belong to a List Record
    
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: row,
        infoForm,
        isUploadEditDocumentForServiceHistoryListRecord,
      }
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const body: any = {
          documentDescription: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: objectUpload.documentName,
          participantDocumentType: objectUpload?.participantDocumentType,
        }

        // call api update document
        this.store.dispatch(editDocumentsAction({
          memberId: this.memberId ?? '',
          fileId: row.fileId,
          body
        }));
      }
    });
  }

  applyFilterEvent(documentFilter: any) {
    this.documentFilter = documentFilter;
    this.getData();
  }
}
