import { createSelector } from '@ngrx/store';

import { editPayrollSettingsState } from './selector';

export const getEditPayrollSettingsSelector = createSelector(
  editPayrollSettingsState,
  (state) => state?.getEditPayrollSettings,
);

export const saveEditPayrollSettingsSelector = createSelector(
  editPayrollSettingsState,
  (state) => state?.saveEditPayrollSettings,
);
