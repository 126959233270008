import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  ParticipantSearchConfigurations,
} from '../types/models';
import { ConfigurationsResponse, PropertyResponse, UpdateSearchConfigurationsRequest } from '@ptg-member/types/models/participant-search-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ParticipantSearchConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getParticipantSearchPropertyList(entityId: string): Observable<PropertyResponse> {
    return this.httpClient.get<PropertyResponse>(
      `${environment.apiUrl}/entities/members/${entityId}/property-participant-search`
    );
  }

  getParticipantSearchConfiguration(): Observable<ParticipantSearchConfigurations> {
    // return this.httpClient.get<ParticipantSearchConfigurations>(
    //   `${environment.apiUrl}/MetadataPortal/GetSearchMemberConfigurations`
    // );

    // TODO remove when complete module entity
   return of();
  }

  manageParticipantSearchConfiguration(body: UpdateSearchConfigurationsRequest,  entityId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/entities/members/${entityId}/config-participant-search`,
      body
    );
  }

  getSearchConfiguration(entityId: string): Observable<ConfigurationsResponse> {
    return this.httpClient.get<ConfigurationsResponse>(
      `${environment.apiUrl}/entities/members/${entityId}/participant-search-config`
    );
  }
}
