<ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
<div class="tier-configuration" id="tier-configuration-page">
  <ptg-banner
    *ngIf="bannerType"
    [(bannerType)]="bannerType"
    [message]="message"
  ></ptg-banner>
  <div *ngIf="selectedTier && dataTable?.length" class="box">
    <div class="title">
      <span class="title-name">Selected Tier</span>
      <div class="button-container">
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onUpdateTier(selectedTier)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </div>
      </div>
    </div>
    <div class="detail-container">
      <div class="detail">
        <span class="label">Tier Code</span>
        <span class="value">{{ selectedTier.tierCode }}</span>
      </div>
      <div class="detail">
        <span class="label">Tier Name</span>
        <span class="value">{{ selectedTier.tierName }}</span>
      </div>
      <div class="detail">
        <span class="label">Annual Salaray Cap</span>
        <span class="value">
          <div class="align-right">
            <span style="color: #828282">$</span><span>{{selectedTier.salaryCapping | numberLocalDecimal: ' '}}</span>
          </div>
        </span>
      </div>
      <div class="detail">
        <span class="label">Modified By</span>
        <span class="value">{{ selectedTier.modifiedByName }}</span>
      </div>
    </div>
    <div class="chip-container">
      <div class="chip-group-container" *ngIf="selectedTier.plans?.length">
        <span class="label">Plans</span>
        <div class="chip-group">
          <div class="chip-children-container">
            <div class="chip-children" *ngFor="let plan of selectedTier.plans">
              {{ plan?.name }} ({{plan.employerName}})
            </div>
          </div>
        </div>
      </div>
      <div class="chip-group-container" *ngIf="selectedTier.conditions?.length">
        <span class="label">Conditions</span>
        <div class="chip-group">
          <div class="chip-children-container">
            <div class="chip-children" *ngFor="let condition of selectedTier.conditions">
              {{ condition?.propertyName }} <span [innerHTML]="displayOperator(condition)"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="list-display">
    <div class="list-header">
      <div class="title">
        <span class="title-name">Tier List</span>
        <div class="button-container">
          <div class="separator"></div>
          <div class="button-group">
            <ptg-button buttonName="New Tier" classInput="edit-button" (clickButton)="onUpdateTier()">
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-wrapper">
      <ptg-grid [data]="dataTable" [columns]="columns" [isLoading]="isLoading" [fitToParent]="true"
        [totalRecords]="totalRecord" (pageChange)="onChangePage($event)"
        (sortChange)="onChangeSort($event)"
        [pageSize]="pageSize" [pageNumber]="pageNumber"
        [(currentRowIndex)]="currentRowIndex" (rowClick)="selectRow({ row: $event, index: currentRowIndex })"
        notFoundMessage="No {{currentPageName}} to Display">
        <ng-template cellContent columnName="ACTION_COLUMN" let-row>
          <ptg-button buttonName="Edit" classInput="delete-button" (clickButton)="onUpdateTier(row)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button buttonName="Remove" classInput="delete-button non-action" (clickButton)="onClickRemoveItem(row)">
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>