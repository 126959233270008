import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { transformEntityToObject } from '@ptg-entity-management/helpers';
import { UpsertEntityDataRequest } from '@ptg-entity-management/services/models';

import { environment } from 'src/environments/environment';
import { CheckMemberExistsBySsnRequest, CheckMemberExistsBySsnResponse } from '@ptg-member/types/models';

@Injectable()
export class MemberService {
  constructor(private httpClient: HttpClient) {}

  createMember(request: UpsertEntityDataRequest): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entities/members`,
      transformEntityToObject(request)
    );
  }

  updateMember(memberId: string, cardName: string, request: UpsertEntityDataRequest, cardId?: string, auditTrailReason?: string): Observable<any> {
    const body = {
      ...transformEntityToObject(request),
      description: auditTrailReason,
      cardId,
    };

    return this.httpClient.put<any>(
      `${environment.apiUrl}/entities/members/${memberId}`,
      body
    );
  }

  checkMemberExists(request: CheckMemberExistsBySsnRequest): Observable<CheckMemberExistsBySsnResponse> {
    let params = new HttpParams();
    if (request.ssn) {
      params = params.append('Ssn', request.ssn);
    }
    if (request.firstName) {
      params = params.append('FirstName', request.firstName);
    }
    if (request.lastName) {
      params = params.append('LastName', request.lastName);
    }
    if (request.dateOfBirth) {
      params = params.append('DateOfBirth', request.dateOfBirth);
    }
    params = params.append('TimeZoneOffset', new Date().getTimezoneOffset());
    return this.httpClient.get<CheckMemberExistsBySsnResponse>(`${environment.apiUrl}/entities/members/exists`, { params });
  }

  setNonMemberToMember(recordId: string, request: UpsertEntityDataRequest): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.apiUrl}/entities/members/non-members/${ recordId }`,
      transformEntityToObject(request)
    );
  }
}
