import { CalculationType } from "@ptg-member/features/calculation/types/enums";
import { HeaderPropertyConfig } from "@ptg-member/types/models/header-configuration.model";
import { HeaderItemConfig } from "@ptg-member/types/models/header-item-config.model";

export interface GetPaymentHeaderConfigurationRequest {
  sortField: string;
  sortType: number;
  pageIndex?: number;
  pageSize?: number
}

export interface PaymentHeaderConfigurationsResponsive {
  headerConfigurations: PaymentHeaderConfiguration[];
  total: number;
} 

export interface PaymentHeaderConfiguration {
  id?: string;
  benefitType?: string;
  entity?: string;
  totalProperties?: string;
} 


export interface HeaderItemItemExistResponse {
  exists: boolean;
  message: string;
}

export interface CreatePaymentHeaderConfigurationRequest {
  benefitTypeId: string;
  entityId: string;
}

export interface PaymentEntitiesResponse {
  entities: OptionValue[];
  benefitTypies:  OptionValue[];
}

export interface OptionValue {
  id?: string;
  displayName?: string;
  value?: string;
  displayValue?: string;
}

export interface GetPaymentHeaderDetailResponse {
  paymentHeader: PaymentHeader;
  allProperties: AllProperties[];
}

export interface PaymentHeader {
  paymentHeaderId: string;
  benefitId: string;
  entityId: string;
  benefitName: string;
  entityName: string;
  properties: Properties[];
  ppName?: string;
  option?: string;
}

export interface AllProperties {
  id: string;
  type: number;
  name: string;
  entityId: string;
  entityName: string;
  entityReferencePropertyNames: EntityReferencePropertyNames[] | null;
  options?: Record<string, string>;
  isFixed: boolean;
}

export interface Properties {
  entityReferencePropertyNames: string,
  type: number,
  id: string,
  entityPropertyId: string,
  entityPropertyLinkedId: string,
  propertyName: string,
  order: number,
  label: string,
  option: null,
  isFixed: boolean;
  propertyId?: string;
  ppName?: string;
  propertyType?: string;
}

export interface EntityReferencePropertyNames {
  id: string;
  name: string;
  entityLinkedId: string;
}


export interface UpdateHeaderDetailRequest {
  paymentHeaderId?: string;
  paymentHeaderProperties: PaymentHeaderProperties[];
}

export interface PaymentHeaderProperties {
  id?: string;
  name?: string;
  order: number;
  isRemoved?: boolean;
  entityPropertyId?: string;
  entityPropertyLinkedId?: string;
  type?: string;
  option?: string;
}

export interface PaymentHeaderConfigResponsive {
  headerId: string;
  headerItems: HeaderItemConfig[];
  headerBenefits: HeaderBenefit[];
}

export interface HeaderBenefit {
  headerId: string;
  benefitEntityId: string;
  benefitTypeOptionId: string;
  benefitEntityDataId: string;
  benefitName: string;
  benefitTypeName: string;
  benefitId: string;
  value?: string;
  displayValue?: string;
  calculationType?: CalculationType;
}

export interface QueryPaymentHeaderDataRequest {
  recordId: string;
  benefitDataId: string;
  benefitId: string;
  entityId: string;
  headerId: string;
  screenId?: string;
}
export interface PaymentHeaderDataResponsive {
  resultProperties: HeaderPropertyConfig[];
}