import { createAction, props } from '@ngrx/store';
import {
  CreatePaymentHeaderConfigurationRequest,
  GetPaymentHeaderConfigurationRequest,
  GetPaymentHeaderDetailResponse,
  PaymentEntitiesResponse,
  PaymentHeaderConfigResponsive,
  PaymentHeaderConfigurationsResponsive,
  PaymentHeaderDataResponsive,
  QueryPaymentHeaderDataRequest,
} from '../../types/models';

export const clearPaymentHeaderConfigurationState = createAction('[ClearPaymentHeaderConfigurationState]');

export const getHeaderList = createAction(
  '[GetPaymentHeaderConfiguration/API] Send Request',
  props<{ query: GetPaymentHeaderConfigurationRequest }>(),
);
export const getHeaderListSuccess = createAction(
  '[GetPaymentHeaderConfiguration/API] Success',
  props<{ headerList: PaymentHeaderConfigurationsResponsive }>(),
);
export const getHeaderListFailure = createAction(
  '[GetPaymentHeaderConfiguration/API] Failure',
  props<{ error?: any }>(),
);

export const createHeader = createAction(
  '[CreatePaymentHeaderConfiguration/API] Send Request',
  props<{ body: CreatePaymentHeaderConfigurationRequest }>(),
);
export const createHeaderSuccess = createAction(
  '[CreatePaymentHeaderConfiguration/API] Success',
  props<{ body: string }>(),
);
export const createHeaderFailure = createAction(
  '[CreatePaymentHeaderConfiguration/API] Failure',
  props<{ error: any }>(),
);

export const getPaymentEntitiesAction = createAction('[GetPaymentViewEntities/API] Send Request');
export const getPaymentEntitiesSuccessAction = createAction(
  '[GetPaymentViewEntities/API] Success',
  props<{ response: PaymentEntitiesResponse }>(),
);
export const getPaymentEntitiesFailureAction = createAction(
  '[GetPaymentViewEntities/API] Failure',
  props<{ error?: any }>(),
);

export const getHeaderDetailAction = createAction('[GetPaymentHeaderDetail/API] Send Request', props<{ id: string }>());

export const getHeaderDetailSuccess = createAction(
  '[GetPaymentHeaderDetail/API] Success',
  props<{ headerDetail: GetPaymentHeaderDetailResponse }>(),
);
export const getHeaderDetailFailure = createAction('[GetPaymentHeaderDetail/API] Failure', props<{ error?: any }>());

export const updatePaymentHeaderAction = createAction('[UpdatePaymentHeader/API] Send Request', props<{ body: any }>());
export const updatePaymentHeaderSuccess = createAction('[UpdatePaymentHeader/API] Success');
export const updatePaymentHeaderFailure = createAction('[UpdatePaymentHeader/API] Failure', props<{ error: string }>());

export const getHeaderConfigApplyAction = createAction(
  '[GetHeaderConfigApply/API] Send Request',
  props<{ recordId: string; entityId: string; headerId?: string }>(),
);

export const getHeaderConfigApplySuccess = createAction(
  '[GetHeaderConfigApply/API] Success',
  props<{ headerConfigApply: PaymentHeaderConfigResponsive }>(),
);
export const getHeaderConfigApplyFailure = createAction('[GetHeaderConfigApply/API] Failure', props<{ error?: any }>());

export const getHeaderDataApplyAction = createAction(
  '[GetHeaderDataApply/API] Send Request',
  props<{ query: QueryPaymentHeaderDataRequest }>(),
);

export const getHeaderDataApplySuccess = createAction(
  '[GetHeaderDataApply/API] Success',
  props<{ headerDataApply: PaymentHeaderDataResponsive }>(),
);
export const getHeaderDataApplyFailure = createAction('[GetHeaderDataApply/API] Failure', props<{ error?: any }>());

// Actions to manage the state of Header Configuration in Dialog
export const setHeaderConfigurationInDialogAction = createAction(
  '[PaymentHeaderConfigurationInDialog Set',
  props<{ payload: any }>(),
);
export const clearHeaderConfigurationInDialogState = createAction('[PaymentHeaderConfigurationInDialog Clear');
