import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DocumentListConfigurationService } from '../../services/document-list-configuration.service';
import {
  getDocumentListConfigurationPropertiesAction,
  getDocumentListConfigurationPropertiesSuccessAction,
  getDocumentListConfigurationPropertiesFailureAction,
  getDocumentListConfigurationDetailAction,
  getDocumentListConfigurationDetailSuccessAction,
  getDocumentListConfigurationDetailFailureAction,
  updateDocumentListConfigurationAction,
  updateDocumentListConfigurationSuccessAction,
  updateDocumentListConfigurationFailureAction,
} from '../actions/document-list-configuration.action';

@Injectable()
export class DocumentListConfigurationEffects {
  constructor(
    private actions$: Actions,
    private documentListConfigurationService: DocumentListConfigurationService
  ) { }

  getDocumentListConfigurationProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentListConfigurationPropertiesAction),
      switchMap(({ request }) =>
        this.documentListConfigurationService.getDocumentListConfigurationProperties(request).pipe(
          map((response: any) => getDocumentListConfigurationPropertiesSuccessAction({ response })),
          catchError((error) => of(getDocumentListConfigurationPropertiesFailureAction({ error })))
        )
      )
    )
  );

  getDocumentListConfigurationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentListConfigurationDetailAction),
      switchMap(({ request }) =>
        this.documentListConfigurationService.getDocumentListConfigurationDetail(request).pipe(
          map((response: any) => getDocumentListConfigurationDetailSuccessAction({ response })),
          catchError((error) => of(getDocumentListConfigurationDetailFailureAction({ error })))
        )
      )
    )
  );

  updateDocumentListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDocumentListConfigurationAction),
      switchMap(({ request }) =>
        this.documentListConfigurationService.updateDocumentListConfiguration(request).pipe(
          map(() => updateDocumentListConfigurationSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(updateDocumentListConfigurationFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

}