<h3>{{  dataDetail?.columSelected ? 'Edit Column Set' : 'New Column Set'}}</h3>
<div class="add-column-set">
    <ptg-column-set-property-display-configuration
    [propertyDisplayConfigurations]="propertyDisplayConfigurations"
    [propertyConfigs]="propertyConfigs"
    [addPropertySection]="addPropertySection"
    [sortPropertySection]="sortPropertySection"
    [sortRowSection]="sortRowSection"
    [isMemberListConfiguration]="true"
    [dataDetail]="dataDetail"
    [listColumnConfig]="listColumnConfig"
    (onSubmitEventColumnSet)="onSubmit($event)"
    ></ptg-column-set-property-display-configuration>

    <div id="loading-table" *ngIf="isLoading">
        <div class="wrap-item">
            <mat-progress-spinner diameter="50" mode="indeterminate">
            </mat-progress-spinner>
            <p>Loading...</p>
        </div>
    </div>
</div>
