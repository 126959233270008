import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { Observable, Subject, of, timer } from 'rxjs';
import { catchError, map, startWith, takeUntil, filter, tap, switchMap, finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { getConcatString, getDateFormatISO } from '@ptg-shared/utils/string.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import * as fromMember from '../../store/reducers';
import { MunicipalityServiceHistoryService } from '../../services/municipality-service-history.service';
import {
  CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  EditMunicipalityServiceHistoryRequest,
  History,
  MetadataSection,
  Municipality,
  ValidateServiceHistoryRecordTerminatedMemberResponse,
  ValidateServiceHistoryRecordTerminatedMemberRequest,
  EmployerCheckServiceBeginDate,
} from '../../types/models';
import * as MunicipalityServiceHistoryAction from '../../store/actions/municipality-service-history.action';
import { NumberLocalDecimalPipe } from '@ptg-shared/pipes/number-local-decimal.pipe';
import { ViewCreditConfirmationDialogComponent } from '../view-credit-confirmation-dialog/view-credit-confirmation-dialog.component';
import * as fromReducer from '@ptg-reducers';
import { DatePipe } from '@angular/common';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';

@Component({
  selector: 'ptg-edit-municipality-service-history',
  templateUrl: './edit-municipality-service-history.component.html',
  styleUrls: ['./edit-municipality-service-history.component.scss'],
})
export class EditMemberServiceHistoryComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  listOptionMunicipality: Option[] = [];
  dateOfDeath: DateTime | null = null;
  isLoading: boolean = true;
  isEditForm: boolean = false;
  municipality = new FormControl('');
  filteredOptions!: Observable<Option[]>;
  oldBeginDate: string = this.data.serviceHistory?.beginDate ? getDateFormatISO(this.data.serviceHistory?.beginDate as string) : '';
  oldEndDate: string = this.data.serviceHistory?.endDate ? getDateFormatISO(this.data.serviceHistory?.endDate as string) : '';
  memberName: string = '';
  muniName: string = '';
  validateServiceHistoryRecordTerminatedMemberResponse: ValidateServiceHistoryRecordTerminatedMemberResponse | null = null;
  isFormLoading: boolean = false;
  memberId = this.data.memberId;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string;
      viewName: string;
      serviceHistory: History;
      dateOfDeath?: string;
      isRedirectFromOverview?: boolean;
      sectionData?: MetadataSection;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditMemberServiceHistoryComponent>,
    private serviceHistoryService: MunicipalityServiceHistoryService,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public layoutService: LayoutService,
  ) {
    super();
  }

  get beginDate(): FormControl {
    return this.editForm.get('beginDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.editForm.get('endDate') as FormControl;
  }

  ngOnInit(): void {
    this.dateOfDeath = this.data.dateOfDeath ? DateTime.fromISO(this.data.dateOfDeath) : null;

    this.getMemberInfoState();
    this.registerValidateServiceHistoryRecordTerminatedMemberSelector();
    this.registerCreateValidateServiceHistoryRecordBeginDateTransactionSelector();
    this.registerGetMemberParticipantNameSelector();

    this.memberStore.dispatch(
      MunicipalityServiceHistoryAction.getMunicipalityList()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityNameListState),
        filter(res => !!res),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((listMunicipality) => {
        this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearMunicipalityListState());
        if (!listMunicipality) {
          return;
        }

        this.setListOption(listMunicipality);
        this.initFormGroup(this.data.serviceHistory);
        this.filteredOptions = this.municipality.valueChanges.pipe(
          startWith(''),
          map((value) => {
            const muniName =
              typeof value === 'string' ? value : value?.displayValue;
            return muniName
              ? this._filter(muniName as string)
              : this.listOptionMunicipality.slice();
          })
        );
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMunicipalityLoadingState),
        tap((isLoading) => (this.isLoading = isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((isLoading) => {
        if (!isLoading) {
          this.editForm.markAllAsTouched();
          this.onChangeBeginValue();
          this.onChangeEndValue();
        }
      });
  }

  ngOnDestroy(): void {
      super.ngOnDestroy();
      this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearMunicipalityListState());
  }

  initFormGroup(formData: History) {
    const serviceHistoryBeginDate = this.data.serviceHistory?.beginDate;
    const serviceHistoryEndDate = this.data.serviceHistory?.endDate;

    const initBeginDate = serviceHistoryBeginDate ? DateTime.fromISO(serviceHistoryBeginDate) : DateTime.now();
    const initEndDate = serviceHistoryEndDate ? DateTime.fromISO(serviceHistoryEndDate) : null;

    this.municipality.setValue({
      value: formData?.municipalityId,
      displayValue: formData?.municipalityName + ' ' + formData?.municipalityId,
      valueDescription: formData?.municipalityName,
    });

    this.editForm = this.fb.group({
      id: this.fb.control(formData?.id),
      beginDate: this.fb.control(initBeginDate, [Validators.required, this.beginDateCustomValidator()]),
      endDate: this.fb.control(initEndDate, [this.endDateCustomValidator()]),
      municipality: this.fb.control(this.municipality.value),
    });
    const isBVFFFund = this.layoutService.fundType === FundType.BVFF;
    if(isBVFFFund){
      this.beginDate.addAsyncValidators(this._validateServiceBeginDate());
    } else {
      this.beginDate.clearAsyncValidators();
    }
  }

  onSubmit() {
    this.municipality.setErrors(null);
    if (
      (this.municipality.value.value === undefined &&
        typeof this.municipality.value !== 'string') ||
      this.municipality.value === ''
    ) {
      this.municipality.setErrors({ required: true });
      return;
    } else if (
        this.municipality.enabled &&
        typeof this.municipality.value === 'string'
      ) {
        this.municipality.setErrors({ inValidAsync: true });
        return;
      }
    let formData = this.editForm.value;
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    let bodyCheck = {
      beginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      endDate: formData.endDate
        ? getDateFormatISO(new Date(formData.endDate).toString())
        : null,
      municipalityId: this.municipality.value.value,
      memberId: this.data.memberId,
      serviceId: formData.id,
    };
    this.serviceHistoryService
      .checkExits(bodyCheck)
      .pipe(
        catchError((err) => {
          return of(
            MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure(err)
          );
        })
      )
      .subscribe((el: any) => {
        if (el?.exists) {
          this.endDate.setErrors({ endDateErrorMessage: 'End Date had an overlap service record.' });
          return;
        }
        const currentDate = getDateFormatISO(DateTime.now().startOf('day').toString());
        if (this.editForm.pending) {
          let sub = this.editForm.statusChanges.subscribe(() => {
            if (this.editForm.valid) {
              if ((this.endDate.value === null || getDateFormatISO(new Date(this.endDate.value).toString()) === currentDate) &&
                this.oldBeginDate === getDateFormatISO(new Date(this.beginDate.value).toString())
              ) {
                this.saveData(this.editForm.value);
              } else if (this.oldBeginDate || this.oldEndDate) {
                this.muniName = this.municipality.value?.valueDescription ?? '';
                setTimeout(() => this.isFormLoading = true, 0);
                this.validateServiceHistoryRecordTerminatedMember(formData);
              } else {
                this.saveData(this.editForm.value);
              }
            }
            sub.unsubscribe();
          });
        } else if (this.editForm.valid) {
          if ((this.endDate.value === null || getDateFormatISO(new Date(this.endDate.value).toString()) === currentDate) &&
            this.oldBeginDate === getDateFormatISO(new Date(this.beginDate.value).toString())
          ) {
            this.saveData(this.editForm.value);
          } else if (this.oldBeginDate || this.oldEndDate) {
            this.muniName = this.municipality.value?.valueDescription ?? '';
            setTimeout(() => this.isFormLoading = true, 0);
            this.validateServiceHistoryRecordTerminatedMember(formData);
          } else {
            this.saveData(this.editForm.value);
          }
        }
      });
  }

  saveData(formData: any) {
    const body: EditMunicipalityServiceHistoryRequest = {
      id: formData.id,
      memberId: this.data.memberId,
      beginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      endDate: formData.endDate
        ? getDateFormatISO(new Date(formData.endDate).toString())
        : null,
      municipalityId: this.municipality.value.value,
    };
    formData.id
      ? this.memberStore.dispatch(
          MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryRequest(
            { body }
          )
        )
      : this.memberStore.dispatch(
          MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryRequest(
            { body }
          )
        );

    this.dialogRef.close(body);
  }

  setListOption(listData: Municipality[]) {
    let listDataDisplay = this.isEditForm
      ? listData
      : listData.filter((x) => x.disabled === false);
    this.listOptionMunicipality = listDataDisplay.map((item) => {
      return {
        value: item.value,
        displayValue: `${item.description} (${item.value})`,
        valueDescription: item.description
      } as Option;
    });
  }

  onChangeBeginValue(): void {
    this.beginDate.valueChanges.subscribe(_ => {
      this.endDate.updateValueAndValidity({ emitEvent: false });
    });
  }

  onChangeEndValue(): void {
    this.endDate.valueChanges.subscribe(_ => {
      this.beginDate.updateValueAndValidity({ emitEvent: false });
    });
  }

  displayFn(value: Option): string {
    return value?.valueDescription ?? '';
  }

  private _filter(name: string): Option[] {
    const filterValue = name.toLowerCase();

    return this.listOptionMunicipality.filter((option) =>
      option.displayValue.toLowerCase().includes(filterValue)
    );
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  validateMuni(): void {
    if (this.endDate.errors?.inValidAsync) {
      this.endDate.setErrors(null);
    }
    if (this.municipality.value) {
      if (
        this.municipality.enabled &&
        typeof this.municipality.value === 'string'
      ) {
        this.municipality.setErrors({ inValidAsync: true });
        return;
      }
    if (this.municipality.value.value === undefined) {
        this.municipality.setErrors({ required: true });
      }
    }
    if (this.municipality.value === '') {
      this.municipality.setErrors({ required: true });
    }
  }

  private beginDateCustomValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('beginDate')) {
        return null;
      }

      const { value: beginDateTime } = control.parent.get('beginDate') as FormControl;
      const endDateTime = this.endDate?.value;
      const currentDate = DateTime.now().startOf('day');

      const beginDate = beginDateTime?.startOf('day');
      const endDate = endDateTime?.startOf('day');
      const dateOfDeath = this.dateOfDeath?.startOf('day');

      if (beginDate > currentDate) {
        return { beginDateErrorMessage: 'Begin Date must not be a future date.' };
      }

      if (endDate && beginDate > endDate) {
        return { beginDateErrorMessage: 'Begin Date must be earlier or equal to End Date.' };
      }

      if (dateOfDeath && beginDate && beginDate > dateOfDeath) {
        return { beginDateErrorMessage: 'Begin Date cannot be later than Date of Death.' };
      }

      return null;
    };
  }

  private endDateCustomValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent?.get('endDate')) {
        return null;
      }

      const { value: endDateTime } = control.parent.get('endDate') as FormControl;
      const beginDateTime = this.beginDate?.value;
      const currentDate = DateTime.now().startOf('day');

      const beginDate = beginDateTime?.startOf('day');
      const endDate = endDateTime?.startOf('day');
      const dateOfDeath = this.dateOfDeath?.startOf('day');

      if (endDate > currentDate) {
        return { endDateErrorMessage: 'End Date must not be a future date' };
      }

      if (beginDate && endDate && beginDate > endDate) {
        return { endDateErrorMessage: 'Begin Date must be earlier or equal to End Date.' };
      }

      if (dateOfDeath && endDate && endDate > dateOfDeath) {
        return { endDateErrorMessage: 'End Date cannot be later than Date of Death.' };
      }

      if (dateOfDeath && !endDate) {
        return { endDateErrorMessage: 'End Date cannot be blank and must be prior or equal to Date of Death' };
      }

      return null;
    };
  }

  validateServiceHistoryRecordTerminatedMember(formData: any) {
    const request: ValidateServiceHistoryRecordTerminatedMemberRequest = {
      memberId: this.data.memberId,
      oldBeginDate: this.oldBeginDate,
      newBeginDate: getDateFormatISO(new Date(formData.beginDate).toString()),
      oldEndDate: this.oldEndDate !== '' ? this.oldEndDate : null,
      serviceHistoryId: this.data.serviceHistory.id,
      newEndDate: formData.endDate ? getDateFormatISO(new Date(formData.endDate).toString()) : null,
      municipalityId: this.data.serviceHistory?.municipalityId as string,
    };
    this.memberStore.dispatch(
      MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberAction(
        { request }
      )
    );
  }

  registerValidateServiceHistoryRecordTerminatedMemberSelector() {
    this.memberStore
      .pipe(select(fromMember.validateServiceHistoryRecordTerminatedMemberSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.isFormLoading = false;
          if (data?.success) {
            this.validateServiceHistoryRecordTerminatedMemberResponse = data?.payload as ValidateServiceHistoryRecordTerminatedMemberResponse;

            const creditAmount = this.validateServiceHistoryRecordTerminatedMemberResponse?.totalCreditAmount ?? 0;
            const nextCashJournalEntry = this.validateServiceHistoryRecordTerminatedMemberResponse?.nextCashJournalEntry ?? 0;
            const decimalPipe = new NumberLocalDecimalPipe();

            if (creditAmount > 0) {
              const dialogRef = this.dialog.open(ViewCreditConfirmationDialogComponent, {
                panelClass: 'edit-popup',
                disableClose: true,
                autoFocus: false,
                height: 'auto',
                width: '800px',
                data: {
                  messages: [
                    `${ this.memberName } had no activity.`,
                    `${ decimalPipe.transform(creditAmount, '$') } will be credited back to ${ this.muniName } municipality.`
                  ],
                  cashJournalEntry: nextCashJournalEntry,
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (!result?.cashJournalEntry) {
                  this.clearValidateServiceHistoryRecordTerminatedMemberData();
                  return;
                }
                this.createValidateServiceHistoryRecordBeginDateTransaction(result.cashJournalEntry);
              });
            } else {
              this.saveData(this.editForm.value);
            }
          }
          if (!data?.success) {
            this.triggerErrorPopup();
          }
          this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearValidateServiceHistoryRecordTerminatedMemberStateAction());
        }
      });
  }

  createValidateServiceHistoryRecordBeginDateTransaction(cashJournalEntry: string) {
    setTimeout(() => this.isFormLoading = true, 0);
    const request: CreateValidateServiceHistoryRecordBeginDateTransactionRequest = {
      createDate: DateTime.utc().toFormat('yyyy-MM-dd_HH:mm:ss').replace('_', 'T'),
      gapYears: this.validateServiceHistoryRecordTerminatedMemberResponse?.gapYears as string,
      totalCreditAmount: this.validateServiceHistoryRecordTerminatedMemberResponse?.totalCreditAmount as number,
      municipalityId: this.municipality.value.value,
      memberId: this.data.memberId,
      cashJournalEntry: cashJournalEntry,
    };
    this.memberStore.dispatch(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionAction({ request }));
  }

  registerCreateValidateServiceHistoryRecordBeginDateTransactionSelector() {
    this.memberStore
      .pipe(select(fromMember.createValidateServiceHistoryRecordBeginDateTransactionSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.isFormLoading = false;
          if (data?.success) {
            this.saveData(this.editForm.value);
          }
          if (!data?.success) {
            this.triggerErrorPopup();
          }
          this.clearValidateServiceHistoryRecordTerminatedMemberData();
          this.memberStore.dispatch(MunicipalityServiceHistoryAction.clearCreateValidateServiceHistoryRecordBeginDateTransactionStateAction());
        }
      });
  }

  getMemberInfoState() {
    if (this.data.memberId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberDetailAction({
          memberId: this.data.memberId
        })
      );
    }
  }

  registerGetMemberParticipantNameSelector() {
    this.store.pipe(
      select(fromMember.selectMemberDetail),
      takeUntil(this.unsubscribe$)
    ).subscribe(memberInfo => {
      this.memberName = memberInfo
        ? getConcatString([memberInfo?.payload?.firstName, memberInfo?.payload?.middleName, memberInfo?.payload?.lastName], ' ')
        : '';
    });
  }

  triggerErrorPopup() {
    const msg = 'An unexpected error occurred. Please try again later.';
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: msg,
          type: ConfirmType.Warning,
          title: 'Error',
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        }
      });
  }

  clearValidateServiceHistoryRecordTerminatedMemberData() {
    this.muniName = '';
    this.validateServiceHistoryRecordTerminatedMemberResponse = null;
  }

  private _validateServiceBeginDate(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      const beginDateValue = new DatePipe('en-US').transform(control.value, 'yyyy-MM-dd');

      if (beginDateValue === null) {
        return of(null);
      }

      const body: EmployerCheckServiceBeginDate = {
        memberId: this.data.memberId ?? '',
        serviceBeginDate: beginDateValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.serviceHistoryService.getMuniCheckServiceBeginDate(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { beginDateErrorMessage: res?.message };
                }
                return null;
              }),
              finalize(() => {
                this.editForm.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }
}
