import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ParametersQuery } from '@ptg-employer/models';

import {
  BinaryValuesResponse,
  ConditionLookupResponse,
  ConditionOperatorsResponse,
  ConditionPropertiesResponse,
  EditTierRequest,
  GetTierConfigurationsResponse,
  ParametersQueryConditionLookup,
  ParametersQueryEditRequest,
  ParametersQueryOperators,
  PlanResponse,
  CheckExitsResponsive,
  ParametersQueryCheckExits
} from '../types/models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable({
  providedIn: 'root',
})
export class TierConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getTierConfiguration(
    query: ParametersQuery
  ): Observable<GetTierConfigurationsResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<GetTierConfigurationsResponse>(
      `${environment.apiUrl}/Metadata/tiers/get-tiers`,
      { params }
    );
  }

  manageTierConfiguration(body: any) {
    return this.httpClient.put<any>(`${environment.apiUrl}/Metadata/`, body);
  }

  getTierConfigurationOperator(
    query: ParametersQueryOperators
  ): Observable<ConditionOperatorsResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<ConditionOperatorsResponse>(
      `${environment.apiUrl}/entities/properties/tier/condition-operators`,
      { params }
    );
  }

  getTierConfigurationPlan(): Observable<PlanResponse> {
    return this.httpClient.get<PlanResponse>(`${environment.apiUrl}/plans`);
  }

  getTierConfigurationMetadata(): Observable<ConditionPropertiesResponse> {
    return this.httpClient.get<ConditionPropertiesResponse>(
      `${environment.apiUrl}/entities/properties/tier/condition-properties`
    );
  }

  setTierConfiguration(body: EditTierRequest): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<void>(`${environment.apiUrl}/tiers`, body, { context });
  }

  editTierConfiguration(
    body: EditTierRequest,
    query: ParametersQueryEditRequest
  ): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put<void>(
      `${environment.apiUrl}/tiers/${query.id}`,
      body,
      { context }
    );
  }

  getTierConfigurationConditionLookup(
    query: ParametersQueryConditionLookup
  ): Observable<ConditionLookupResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<ConditionLookupResponse>(
      `${environment.apiUrl}/tier/condition-lookup-values`,
      { params }
    );
  }

  getTierConfigurationBinaryValues(
    query: ParametersQueryConditionLookup
  ): Observable<BinaryValuesResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<BinaryValuesResponse>(
      `${environment.apiUrl}/tier/binary-values`,
      { params }
    );
  }

  checkExits = (query: ParametersQueryCheckExits): Observable<CheckExitsResponsive> => {
    const params = new HttpParams({ fromObject: query as any });
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<CheckExitsResponsive>(`${ environment.apiUrl }/tier/check-exist`, { params });
  }
}
