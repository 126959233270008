import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { uploadPlaceholder } from '../../constance/listIcons.const';

@Component({
  selector: 'ptg-upload-multiple',
  templateUrl: './upload-multiple.component.html',
  styleUrls: ['./upload-multiple.component.scss'],
})
export class UploadMultipleComponent implements OnInit, OnChanges {
  @Input() controlField = new FormControl('');
  hasError = false;
  @Input() fileSelected: any;
  firstTouch = true;
  isDuplicated = false;

  @Input() checkPattern!: RegExp;
  @Input() typeFile?: string;
  @Input() noUpload?: boolean;
  @Input() isDisabled?: boolean;
  @Input() accept?: string;
  @Input() class?: string;
  @Input() fileLabel?: string;
  @Input() pageHeader?: string;
  @Input() imageFile?: boolean;
  @Input() buttonLabel?: string;
  @Input() uploadIcon?: string;
  @Input() isMultiple: boolean = true;

  @Input() placeholder?: string;

  @Input() isRequired?: boolean;

  @Input() showDirection?: boolean;

  @Output() onBrowseFile = new EventEmitter();
  @Output() onUploadAction = new EventEmitter();
  @Output() deleteFile = new EventEmitter();

  @Input() errMsg?= 'Unsupported file format.';
  @Input() errRequired?: string;
  @Input() errLength?: string;
  @Input() errDuplicated?: string;
  @Input() uploadButtonName?: string;

  @ViewChild('inputFile') inputFile!: ElementRef;
  @Input() errorAsync: string = '';
  @Input() ignoreCancel: boolean = false;
  @Input() validateOnUpload: boolean = false;

  filesSelected: any[] = [];

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('upload-placeholder', sanitizer.bypassSecurityTrustHtml(uploadPlaceholder));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileSelected) {
      this.controlField.setValue(this.fileSelected?.name ?? '');
    }
  }

  ngOnInit(): void {
    // this.controlField.disable();
    if (this.isRequired) {
      this.controlField.addValidators(Validators.required);
    }

  }

  selectFile() {
    this.inputFile.nativeElement.click();
    this.onBrowseFile.emit();
  }

  getFile(event: any) {
    this.firstTouch = false;
    if (this.ignoreCancel && !event.target.files.length) {
      return;
    }
    if (!this.isMultiple && event.target.files && event.target.files.item(0)) {
      this.fileSelected = [event.target.files.item(0)];
      
      if (this.checkPattern.test(this.fileSelected?.[0]?.name)) {
        this.hasError = false;
      } else {
        this.hasError = true;
      }
    }
    if (event.target.files) {
      this.filesSelected = Array.from(event.target.files);
    }
    if (this.noUpload) {
      this.onUploadAction.emit(this.filesSelected);
    }
    this.inputFile.nativeElement.value = '';
  }

  uploadFile() {
    if (this.fileSelected && !this.hasError) {
      this.onUploadAction.emit(this.fileSelected);
      return;
    }
    if (this.validateOnUpload) {
      this.firstTouch = false;
    }
  }

  removeFile(file: any) {
    if (!this.isMultiple) {
      this.fileSelected = [];
      this.controlField.setValue('');
      this.inputFile.nativeElement.value = '';
      this.hasError = false;
      this.firstTouch = false;
      this.isDuplicated = false;
    }
    this.filesSelected = this.filesSelected.filter(item => item.name !== file.name);
    this.onUploadAction.emit(this.filesSelected);
    this.deleteFile.emit();
  }

  fnButtonRemove() {
    this.fileSelected = null;
    this.controlField.setValue('');
    this.inputFile.nativeElement.value = '';
    this.hasError = false;
    this.firstTouch = false;
    this.isDuplicated = false;
    this.deleteFile.emit();
  }
}
