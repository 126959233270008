<div class="payroll-error-list-container">
  <div class="header">
    <ng-container>
      <span class="title">Error List</span>
    </ng-container>
  </div>

  <div class="received mt-2" *ngIf="received">
    <p>Received: {{(received | date: 'MM/dd/yyyy hh:mm a') || '-'}}</p>
  </div>

  <div class="content" *ngIf="!data.isPaymentLevel">
    <p class="content-top">
      The payment order had encountered a number of issues. Please review the order or contact <span class="highlight-text">{{data.bankName}}</span> for more information using the following reference:<br>
    </p>
    <p>- Approximate Time of Submission: <span class="highlight-text">{{(cloneData?.dateTimeSubmitted | date: 'MM/dd/yyyy hh:mm a') || '-'}}</span></p>
    <p>- Transmission: <span class="highlight-text">{{cloneData?.transmissionJobNumber || '-'}}</span></p>
    <p>- File sequence number: <span class="highlight-text">{{cloneData?.interchangeControlNumber || '-'}}</span></p>
  </div>

  <div class="grid-wrapper">
    <ptg-grid
      [data]="errorListData"
      [columns]="columns"
      [isLoading]="isLoading"
      [fitToParent]="true"
      [paginable]="false"
      keyColumn="id"
    >
    </ptg-grid>
  </div>

  <button mat-icon-button aria-label="close icon" (click)="onClickClose()" class="x-button">
    <mat-icon>close</mat-icon>
  </button>
  
</div>
