import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DepositAccountService } from '@ptg-member/services/deposit-account.service';
import {
  getDepositAccountsAction,
  getDepositAccountsFailureAction,
  getDepositAccountsSuccessAction,
  createDepositAccountsAction,
  createDepositAccountsFailureAction,
  createDepositAccountsSuccessAction,
  setDepositAccountAction,
  setDepositAccountSuccessAction,
  setDepositAccountFailureAction
} from '../actions/deposit-account.action';
import { GetDepositAccountsResponse } from '@ptg-member/types/models/deposit-account.model';

@Injectable()
export class DepositAccountEffects {
  constructor(
    private actions$: Actions,
    private depositAccountService: DepositAccountService,
  ) {}

  getBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDepositAccountsAction),
      switchMap(({ request }) => {
        return this.depositAccountService.getDepositAccounts(request).pipe(
          map((response: GetDepositAccountsResponse) => {
            return getDepositAccountsSuccessAction({ response });
          }),
          catchError((error) => {
            return of(getDepositAccountsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  upsertDepositAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDepositAccountsAction),
      switchMap(({ request }) =>
        this.depositAccountService.createDepositAccounts(request).pipe(
          map(() => createDepositAccountsSuccessAction()),
          catchError((error) => of(createDepositAccountsFailureAction({ error }))),
        ),
      ),
    ),
  );

  setDepositAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDepositAccountAction),
      switchMap(({ request }) =>
        this.depositAccountService.setDepositAccount(request).pipe(
          map(() => setDepositAccountSuccessAction()),
          catchError((error) => of(setDepositAccountFailureAction({ error }))),
        ),
      ),
    ),
  );
}
