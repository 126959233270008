import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_NOT_FOUND_MSG, Row } from '@ptg-shared/controls/grid';
import { AdjustmentType, ChipType, PaymentTransactionStatus, TransactionRegister, TransactionType } from '../../services/models/register.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';
import { MatDialog } from '@angular/material/dialog';
import { RegisterDataPopupComponent } from '../register-data-popup/register-data-popup.component';
import { PaymentInstructionType } from '@ptg-member/features/payee-detail/types/enums';
import { ViewMarkReturnedRejectedComponent } from '../view-mark-returned-rejected/view-mark-returned-rejected.component';
import { ReissueTransactionComponent } from '../reissue-transaction/reissue-transaction.component';
import { AccountType } from '@ptg-shared/types/models/common.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { DATE_FORMAT } from '@ptg-shared/constance';

@Component({
  selector: 'ptg-register-expanded',
  templateUrl: './register-expanded.component.html',
  styleUrls: ['./register-expanded.component.scss'],
})
export class RegisterExpandedComponent extends BaseComponent {
  readonly AccountType = AccountType;
  readonly AdjustmentType = AdjustmentType;
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly PaymentTransactionStatus = PaymentTransactionStatus;
  readonly PaymentType = PaymentType;
  readonly TransactionType = TransactionType;
  readonly ChipType = ChipType;
  readonly DATE_FORMAT = DATE_FORMAT;
  @Input() registerData: (TransactionRegister & Row)[] = [];
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 50;
  @Input() pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 200];
  @Input() hiddenPageSizeOptions: boolean = false;
  @Input() maxPages: number = 5;
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Input() notFoundMessage: string = DEFAULT_NOT_FOUND_MSG;
  @Input() errorMessage?: string;
  @Input() isLoading?: boolean;
  @Input() sidebarOpened?: boolean;
  @Input() showErrorPopup?: (event: TransactionRegister) => void;
  @Input() payeeRecordId?: string;
  @Input() maxLength: number = 1;
  @Input() isConfigOST?: boolean = false;
  @Output() showBanner = new EventEmitter<{ bannerType: BannerType; message: string }>();

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {}

  //#region Handle for pagination
  onChangePage(event: PageEvent): void {
    this.pageChange.emit(event);
  }
  //#endregion

  openMemoPopup(transaction: TransactionRegister, label: string) {
    this.dialog.open(RegisterDataPopupComponent, {
      panelClass: 'expanded-data-popup',
      width: '1000px',
      autoFocus: false,
      data: { transaction, label },
    });
  }

  onClickReturnReject(row: TransactionRegister) {
    const dialog = this.dialog.open(ViewMarkReturnedRejectedComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        registerData: row,
        payeeRecordId: this.payeeRecordId,
        maxLengthCheckNumber: this.maxLength,
        isConfigOST: this.isConfigOST,
      },
    });
    dialog
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result?: { success: boolean; error: boolean }) => {
        if (result?.success) {
          this.showBanner.emit({ bannerType: BannerType.Success, message: 'Transaction successfully voided.' });
        }
        if (result?.error) {
          this.showBanner.emit({
            bannerType: BannerType.Fail,
            message: 'Error occurred voiding transaction. Please try again.',
          });
        }
      });
  }

  onClickReissue(row: TransactionRegister) {
    const dialog = this.dialog.open(ReissueTransactionComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        registerData: row,
        payeeRecordId: this.payeeRecordId,
        maxLengthCheckNumber: this.maxLength,
        isConfigOST: this.isConfigOST,
      },
    });
    dialog.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(el => {
      if (el?.success && row.transactionType === TransactionType['Deduction Payee']) {
        this.showBanner.emit({ bannerType: BannerType.Success, message: 'Transaction successfully reissued.' });
      }
    })
  }

  formatStringForCheckNumber(value: number, endingChar?: any){
    if (!endingChar) {
      return value; 
    }
    let numericPart = value?.toString()?.trim().replace(endingChar, '');
    let paddedNumericPart = numericPart.padStart(this.maxLength, '0');
    return paddedNumericPart + endingChar;
  }
}
