import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { MetadataPropertyType } from '@ptg-member/types/models';
import { BaseComponent } from '@ptg-shared/components';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';

import {
  getEntityComponentListsSelector,
  getEntityPropertyDetailSelector,
  getPropertyTypesSelector,
} from '../../store/selectors';
import {
  AggregationType,
  EntityComponentList,
  EntityPropertyDetail,
  GetEntityComponentListsRequest,
  GetEntityPropertyDetailRequest,
  GetPropertyTypesRequest,
  PropertyConfigOption,
  SetEntityPropertyRequest,
} from '../../services/models';
import { EntityPropertyType } from '../../types/enums';
import { EntityService } from '../../services';
import { EntityState } from '../../store/reducers';
import {
  getEntityComponentListsAction,
  getEntityPropertyDetailAction,
  getPropertyTypes,
  setEntityPropertyAction,
} from '../../store/actions';

@Component({
  selector: 'ptg-edit-aggregation-property-item',
  templateUrl: './edit-aggregation-property-item.component.html',
  styleUrls: ['./edit-aggregation-property-item.component.scss'],
})
export class EditAggregationPropertyItemComponent extends BaseComponent {
  readonly EntityPropertyType = EntityPropertyType;

  editForm!: FormGroup;

  get propertyNameCtrl() {
    return this.editForm?.get('propertyName') as FormControl;
  }
  get descriptionCtrl() {
    return this.editForm?.get('description') as FormControl;
  }
  get propertyTypeCtrl() {
    return this.editForm?.get('propertyType') as FormControl;
  }
  get listIdCtrl() {
    return this.editForm?.get('listId') as FormControl;
  }
  get propertyIdCtrl() {
    return this.editForm?.get('propertyId') as FormControl;
  }
  get aggregateIdCtrl() {
    return this.editForm?.get('aggregateId') as FormControl;
  }

  entityComponentData: EntityComponentList[] = [];
  propertyDetail?: EntityPropertyDetail;
  properties!: MetadataPropertyType[];
  dataProperties!: MetadataPropertyType[];
  formSubmit$ = new Subject<boolean>();
  listOptions: Option[] = [];
  propertyOptions: Option[] = [];
  aggregateOptions: Option[] = [
    {
      displayValue: 'SUM',
      value: AggregationType.Sum,
    },
    {
      displayValue: 'AVERAGE',
      value: AggregationType.Average,
    },
    {
      displayValue: 'MIN',
      value: AggregationType.Min,
    },
    {
      displayValue: 'MAX',
      value: AggregationType.Max,
    },
    {
      displayValue: 'COUNT',
      value: AggregationType.Count,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityId: string;
      entityComponentId: string;
      entityPropertyId: string;
    },
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<EditAggregationPropertyItemComponent>,
    public dialog: MatDialog,
    public entityStore: Store<EntityState>,
    private entityService: EntityService,
    private switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initFormGroup();
    this.getPropertyTypesAction();
    this.getEntityComponentAction();
    this.getEntityPropertyDetailAction();
    this.combineGetData();

    this.onSubmitForm();
  }

  getEntityPropertyDetailAction(): void {
    let request: GetEntityPropertyDetailRequest = {
      entityPropertyId: this.data?.entityPropertyId,
    };

    this.entityStore.dispatch(getEntityPropertyDetailAction({ request }));
  }

  getEntityPropertyDetailSelector(data: any): void {
    this.propertyDetail = data;
  }

  combineGetData() {
    combineLatest([
      this.entityStore.select(getEntityPropertyDetailSelector),
      this.entityStore.select(getEntityComponentListsSelector),
      this.entityStore.select(getPropertyTypesSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data[0]?.isLoading && !data[1]?.isLoading && !data[2]?.isLoading) {
          this.getEntityPropertyDetailSelector(data[0]?.payload);
          this.getEntityComponentSelector(data[1]?.payload);
          this.getPropertyTypesSelector(data[2]?.payload);
          this.initFormGroup();
        }
      });
  }

  initFormGroup() {
    this.editForm = this.fb.group({
      propertyName: this.fb.control(this.propertyDetail?.propertyName, {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.entityService.checkPropertyNameExist,
          'name',
          undefined,
          {
            params: {
              entityId: this.data?.entityId,
              entityComponentId: this.data?.entityComponentId,
              entityPropertyId: this.data?.entityPropertyId,
            },
          }
        ),
      }),
      propertyType: this.fb.control({
        value: this.propertyDetail?.type,
        disabled: true,
      }),
      listId: this.fb.control({
        value:
          this.propertyDetail?.configs[PropertyConfigOption.Aggregation]
            ?.entityComponentId,
        disabled: true,
      }),
      propertyId: this.fb.control({
        value:
          this.propertyDetail?.configs[PropertyConfigOption.Aggregation]
            ?.entityPropertyId,
        disabled: true,
      }),
      aggregateId: this.fb.control({
        value:
          this.propertyDetail?.configs[PropertyConfigOption.Aggregation]
            ?.aggregationType,
        disabled: true,
      }),
      description: this.fb.control({
        value: this.propertyDetail?.configs[PropertyConfigOption.Aggregation]?.description ?? '',
        disabled: false
      })
    });

    this.onChangeListOption(
      this.propertyDetail?.configs[PropertyConfigOption.Aggregation]
        ?.entityComponentId ?? ''
    );
  }

  getPropertyTypesAction(): void {
    const request: GetPropertyTypesRequest = {
      type: 2,
    };

    this.entityStore.dispatch(getPropertyTypes({ request }));
  }

  getPropertyTypesSelector(data: any): void {
    this.properties = (data.entityPropertyTypes ?? []).map((element: any) => {
      return {
        value: element.value,
        displayValue: element.displayValue,
      };
    });
    this.dataProperties = (data.propertyTypes ?? []).map((element: any) => {
      return {
        value: element.value,
        displayValue: element.displayValue,
      };
    });
  }

  getEntityComponentAction(): void {
    const request: GetEntityComponentListsRequest = {
      entityId: this.data?.entityId,
    };

    this.entityStore.dispatch(getEntityComponentListsAction({ request }));
  }

  getEntityComponentSelector(data: any): void {
    this.entityComponentData = data || [];
    this.listOptions = this.entityComponentData.map((element: any) => {
      return {
        value: element.id,
        displayValue: element.name,
      };
    });
  }

  onChangeListOption(entityComponentId: string): void {
    this.propertyOptions = [];

    let property = this.entityComponentData?.find(
      (item: any) => item.id === entityComponentId
    )?.entityProperties;

    this.propertyOptions = property?.map((item: any) => {
      return {
        value: item.id,
        displayValue: item.name,
      };
    });
  }

  onSubmitForm(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.editForm.get('propertyName')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    const request: SetEntityPropertyRequest = {
      configs : {
        [PropertyConfigOption.Aggregation]: {
          ...this.propertyDetail?.configs[PropertyConfigOption.Aggregation],
          description: this.descriptionCtrl?.value.toString()
        },
      },
      entityComponentId: this.data?.entityComponentId,
      entityPropertyId: this.data?.entityPropertyId,
      name: this.propertyNameCtrl.value.trim(),
    };

    this.entityStore.dispatch(setEntityPropertyAction({ request }));
    this.dialogRef.close({ propertyName: this.propertyNameCtrl?.value });
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
