import { DepositAccount } from '@ptg-member/types/models/deposit-account.model';

export interface PayeePayment {
  id?: string;
  payrollBenefitId?: string;
  paymentType?: PayeePaymentType;
  paymentMethod?: string;
  splitPaymentType?: SplitPaymentType;
  depositId?: string;
  amount?: number;
  depositAccount?: DepositAccount;
  accountType?: PayeePaymentType;
  accountTypeName?: string;
  transactionDate: string;
  transactionId: string;
  postedTime: string;
}

export interface GetPayeePaymentRequest {
  paymentInstructionId?: string;
  benefitSubtypeId?: string;
  paymentInstructionHistoryId: string;
  instructionStatusHistoryId?: string;
}

export interface GetPayeePaymentResponse {
  total: number;
  payments: PayeePayment[];
  isEditable?: boolean;
}

export enum SplitPaymentType {
  Amount,
  Percent,
}

export enum PayeePaymentType {
  'Check',
  'Direct Deposit',
  'Wire Transfer',
  'Internal Payment',
}

export interface SetPayeePaymentRequest {
  splitPaymentType?: SplitPaymentType;
  payments?: PaymentsDetail[];
  paymentInstructionId?: string;
}

export interface PaymentsDetail {
  bankId?: string;
  depositAccountId?: string;
  amount?: number;
  percentage?: number;
  payrollBenefitId?: string;
  paymentType?: PayeePaymentType;
}

export interface HeaderItem {
  propertyId?: string;
  propertyReferenceId?: string;
  propertyName?: string;
  option?: string;
  orderColumn?: string;
  value?: string;
}

export interface HeaderBenefits {
  headerId?: string;
  benefitEntityId?: string;
  benefitEntityDataId?: string;
  benefitTypeOptionId?: string;
  benefitName?: string;
  benefitId?: string;
}
export interface GetHeaderConfigRequest {
  entityId?: string;
  recordId?: string;
}

export interface GetHeaderConfigResponse {
  headerId?: string;
  headerItems?: HeaderItem[];
  headerBenefits?: HeaderBenefits[];
}
