import { createSelector } from "@ngrx/store";
import { selectMemberDetailState, selectMemberModuleState } from "../reducers";

export const memberDataSelector = createSelector(
    selectMemberModuleState,
    (state) => state?.memberData
);

export const createMemberSelector = createSelector(
    memberDataSelector,
    (state) => state?.createMember
);

export const updateMemberCardSelector = createSelector(
    memberDataSelector,
    (state) => state?.updateMemberCard
);

export const setMemberEventSelector = createSelector(
    memberDataSelector,
    (state) => state?.setMemberEvent
);

export const deactivatedMemberSelector = createSelector(
    selectMemberDetailState,
    (state) => state?.deactivateMemberState
)

export const checkRemoveExitAttachmentSelector = createSelector(
    selectMemberDetailState,
    (state) => state?.removeExitAttachment
)

export const setNonMemberToMemberSelector = createSelector(
    memberDataSelector,
    (state) => state?.setNonMemberToMember
);
  