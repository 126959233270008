import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';

export enum RemittanceStatus {
  EnterAmount,
  PendingSubmit,
  PendingPayment,
  ReadyToPost,
  Posted,
}

export const RemittanceStatusLabel: Record<number, string> = {
  [RemittanceStatus.EnterAmount]: 'Pending Submit',
  [RemittanceStatus.PendingSubmit]: 'Pending Submit',
  [RemittanceStatus.PendingPayment]: 'Pending Payment',
  [RemittanceStatus.ReadyToPost]: 'Ready to Post',
  [RemittanceStatus.Posted]: 'Posted',
};

export enum RemittanceType {
  Annual,
  Supplemental,
  Makeup,
  Buyback,
}

export enum FeeType {
  FireDepartment,
  ReserveOfficer,
  EMSD,
}

export const NOT_POSTED_ERROR_MESSAGE = 'Remittance of the previous year should be posted first.';
export const NO_PARTICIPANT_ERROR_MESSAGE =
  'This remittance does not have member. Please edit year to create remittance for another year or remove this remittance.';
export const UNIDENTIFIED_MUNI_TYPE_ERROR_MESSAGE = 'Cannot retrieve Fee Amount due to unidentified Municipality Type.';

export const ErrorMessage = {
  0: `Member End Date has been provided in prior calendar year.<br/>Please restart the remittance process to remove member from the remittance.`,
  1: "Member's Service Begin Date has been changed and no longer eligible for this remittance year.<br/>Please restart the remittance process to remove member from the remittance.",
  2: 'Total Pension Amount is Incorrect. Fee amounts changed after member service begin date.<br/>Please edit Municipality Paid Pension and Member Paid Pension.',
  3: 'Member is inactive.<br/>Please restart the remittance process to remove member from the remittance.',
  4: 'Member has been deactivated.<br/>Please restart the remittance process to remove member from the remittance.',
  5: 'This member does not work for this Municipality during this remittance time.<br/>Please restart the remittance process to remove this member.',
  10: 'Total Pension Amount is incorrect.<br/> Total Pension Amount is based on the Member Service Begin Date.'
};

export const WarningMessage = {
  0: "Pension Fee has not been updated according to Header's Fee Amounts.",
  1: 'Member has reached the maximum number of payments.<br/> Paid Pension amounts have been changed to exempt.',
  2: 'Pension Paid amount was paid by another municipality.<br/>Paid Pension amounts have been changed to exempt.',
  3: 'Member is a Retire/Rehire.<br/>Paid Pension amounts have been changed to exempt and surcharge fee has been applied.',
};

export const RemittancePrintInvoiceWarningMessage = {
  isExistPrimaryContact: 'No Primary contact found. Are you sure you want to print?',
  isExistFundAddress: 'Fund address not found. Are you sure you want to print?',
};

export const BuybackMakeupErrorMessage = {
  0: `Please remove the remittance as member did not have service history record for this remittance year.`,
  1: 'Member’s Service Begin Date has been changed and no longer eligible for this remittance year.<br/>Please restart the remittance process to remove member from the remittance.',
  2: 'Total Pension Amount is Incorrect. Fee amounts changed after member service begin date.<br/>Please edit Municipality Paid Pension and Member Paid Pension.',
  3: 'Member is inactive.<br/>Please remove the remittance.',
  4: 'Member is invalid.<br/>Please remove the remittance.',
  5: 'This member does not work for this Municipality during this remittance time.<br/>Please remove the remittance.',
  6: 'Member has reached the maximum number of payments.<br/>Please remove the remittance.',
  7: 'Pension Paid amount was paid by another municipality.<br/>Please remove the remittance.',
  8: 'Member is a Retire/Rehire and is getting retirement benefit.<br/>Please remove the remittance.',

  // Error Index 9 is align with ERR 2' (only for Buyback Make-up cases), defined in SRS
  9: 'There is an update in "Begin date" of first service year of the member which leads to a change in "Due Date" for corresponding buyback/makeup year.<br/>Please remove the remittance.',
};

export enum ParticipantWarningType {
  PensionFeeNotSameWithHeader,
  NoOfPaymentMaximum,
  PensionPaidWasPaid,
  RetireRehire,
}

export const REMITTANCE_SUBMISSION_HISTORY_GRID_COLUMN: Column[] = [
  {
    name: 'submissionDate',
    header: {
      title: 'Submission Date',
      style: {
        padding: '15px 1rem',
        minWidth: '150px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'remittanceYear',
    header: {
      title: 'Remittance Year',
      style: {
        padding: '15px 1rem',
        minWidth: '150px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
  },
  {
    name: 'remittanceNumber',
    header: {
      title: 'Remittance Number',
      style: {
        padding: '15px 1rem',
        minWidth: '150px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
  },
  {
    name: 'feeAmount',
    header: {
      title: 'Fee Amount',
      style: {
        padding: '15px 1rem',
        minWidth: '170px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
    },
  },
  {
    name: 'totalInterest',
    header: {
      title: 'Total Interest',
      style: {
        padding: '15px 1rem',
        minWidth: '170px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
    },
  },
  {
    name: 'balance',
    header: {
      title: 'Balance',
      style: {
        padding: '15px 1rem',
        minWidth: '170px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
    },
  },
  {
    name: 'statusLabel',
    header: {
      title: 'Status',
      style: {
        padding: '15px 1rem',
        minWidth: '170px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'paymentReceivedDate',
    header: {
      title: 'Payment Received Date',
      style: {
        padding: '15px 1rem',
        minWidth: '170px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'receipt',
    sortable: true,
    header: {
      title: 'Receipt #',
      style: {
        padding: '15px 1rem',
        minWidth: '300px',
      },
    },
    style: {
      padding: '15px 1rem',
    },
  },
];

export const REMITTANCE_PRINT_INVOICE_GRID_COLUMN: Column[] = [
  {
    name: 'dueDate',
    header: {
      title: 'Due Date',
      style: {
        padding: '6px 4px',
        width: '10.68%',
      },
    },
    style: {
      padding: '6px 4px',
      verticalAlign: "top"
    },
    truncate: true,
  },
  {
    name: 'description',
    header: {
      title: 'Description',
      style: {
        padding: '6px 4px',
        width: 'auto',
      },
    },

    style: {
      padding: '6px 4px',
      verticalAlign: "top",
      whiteSpace: "initial"
    },
    truncate: false,
  },
  {
    name: 'interestBeginDate',
    header: {
      title: 'Interest Begin Date',
      style: {
        padding: '6px 4px',
        width: '12.82%',
      },
    },

    style: {
      padding: '6px 4px',
      verticalAlign: "top"
    },
    truncate: true,
  },
  {
    name: 'disabilityFee',
    header: {
      title: 'Disability Fee',
      style: {
        padding: '6px 4px',
        width: '12.82%',
      },
    },

    style: {
      padding: '6px 4px',
      verticalAlign: "top"
    },
    truncate: true,
    align: Align.Right,
  },
  {
    name: 'municipalityPensionPaid',
    header: {
      title: 'Munic.Paid Pension',
      style: {
        padding: '6px 4px',
        width: '12.82%',
      },
    },

    style: {
      padding: '6px 4px',
      verticalAlign: "top"
    },
    truncate: true,
    align: Align.Right,
  },
  {
    name: 'memberPensionPaid',
    header: {
      title: 'Member Paid Pension',
      style: {
        padding: '6px 4px',
        width: '12.82%',
      },
    },

    style: {
      padding: '6px 4px',
      verticalAlign: "top"
    },
    truncate: true,
    align: Align.Right,
  },
  {
    name: 'totalAmountDue',
    header: {
      title: 'Total Amount Due',
      style: {
        padding: '6px 4px',
        width: '12.82%',
      },
    },

    style: {
      padding: '6px 4px',
      verticalAlign: "top"
    },
    truncate: true,
    align: Align.Right,
  },
];
