<div class="autocomplete-container">
  <mat-form-field appearance="fill">
    <mat-label
      [ngClass]="[
        shouldShowRequiredIndicator
          ? REQUIRED_INDICATOR
          : '',
      ]">
      {{ label }}
    </mat-label>

    <mat-icon
      *ngIf="icon; else svgIconTemp"
      aria-hidden="false"
      matPrefix
      class="mr-2"
    >
    corporate_fare
      {{ icon }}
    </mat-icon>
    <ng-template #svgIconTemp>
      <mat-icon
        aria-label="autocomplete icon"
        *ngIf="svgIcon"
        aria-hidden="false"
        matPrefix
        class="mr-2"
        [svgIcon]="svgIcon"
      ></mat-icon>
    </ng-template>

    <input
      class="search-input"
      #searchTrigger="matAutocompleteTrigger"
      matInput
      type="text"
      [matAutocomplete]="auto"
      [disabled]="isSelected || isDisabled"
      [ngModel]="searchContent"
      (ngModelChange)="onChange($event)"
      (input)="onSearch()"
      (focus)="onFocusValue($event)"
    />

    <button
      *ngIf="isShowClearButton"
      (click)="onClickClearSearch()"
      mat-icon-button
      aria-label="close icon"
      class="x-button"
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete
      #auto="matAutocomplete"
      [class]="searchClass ? searchClass : 'search-panel'"
      panelWidth="auto"
      [disableRipple]="true"
    >
      <mat-option
        [disabled]="true"
        *ngIf="
          !isLoading &&
          data.length > 0 &&
          (searchContent.length >= lengthToSearch ||
          (searchContent.length === 0 && defaultSearchResult && !shouldNotShowDefaultSearchResult))
        "
      > 
        <table mat-table [dataSource]="data" class="w-full">
          <ng-container *ngFor="let column of columns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                [ngTemplateOutlet]="
                  cellContentSearch?.templateRef ?? defaultCellContent
                "
                [ngTemplateOutletContext]="{
                  row: element[column],
                  searchContent: searchContent
                }"
              ></ng-container>

              <ng-template #defaultCellContent>
                <span
                  [innerHTML]="
                    element[column].value | customBold : searchContent
                  "
                ></span>
              </ng-template>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: columns"
            (click)="onClickSelect(row)"
          ></tr>
        </table>
      </mat-option>

      <mat-option
        class="message-option"
        *ngIf="data.length === 0 && searchContent.length >= lengthToSearch"
        [value]="searchContent"
      >
        {{
          !isLoading
            ? isError
              ? "Error occurs while searching."
              : noDataMsg
            : "Searching..."
        }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
