<div class="upsert-deposit-account-modal">
  <ptg-title-bar [name]="data.isEdit ? 'Edit' : 'Add New'" viewName="Deposit Account" [onlyTitle]="true"></ptg-title-bar>
  <ptg-overview-header-entity [targetIdFromDialog]="data.memberId"></ptg-overview-header-entity>
  <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div *ngIf="!data.isEdit || !isLoading; else loadingTemplate">
      <div class="wrap-btn">
        <button mat-raised-button type="submit" class="submit-button">Save</button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
      <div class="flex">
        <ptg-checkbox [controlField]="editForm.get('active')" label="Active"></ptg-checkbox>
        <div class="group-control-aba">
          <div class="control-check-memo">
            <ptg-textbox
              [controlField]="editForm.get('routingNumber')"
              placeholder="ABA Routing #"
              [maxLength]="9"
              [isDecimal]="false"
              [inputType]="'Number'"
              [isPositive]="true"
              [hasLabel]="true"
              [isRequired]="true"
              errorAsync="Unable to verify bank information."
            ></ptg-textbox>
          </div>
          <div class="bank-info">
            <label class="title">Bank Name</label>
            <p>
              {{
                depositAccountService?.bankInfo && this.editForm.get('routingNumber')?.value
                  ? depositAccountService?.bankInfo?.name
                  : 'N/A'
              }}
            </p>
          </div>
        </div>
        <div class="group-control">
          <div class="control-check-memo">
            <ptg-textbox
              [controlField]="editForm?.get('accountNumber')"
              placeholder="Account #"
              [maxLength]="30"
              [hasLabel]="true"
              [isRequired]="true"
              errorAsync="Duplicated bank account number."
              customError="invalidValue"
            ></ptg-textbox>
            <ptg-radio-button
              [controlField]="editForm?.get('accountType')"
              [listOption]="listAccountType"
            ></ptg-radio-button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loadingTemplate>
      <div class="mt-10">
        <ptg-spinner [isLoading]="true"></ptg-spinner>
      </div>
    </ng-template>
    
  </form>
</div>
