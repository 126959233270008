import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import * as CreateReversalAdjustment from '../actions';

import { CreateReversalAdjustmentResponse } from '../../services/models';

export interface CreateReversalAdjustmentState {
  createReversalAdjustment?: BaseActionState<CreateReversalAdjustmentResponse>;
}

const initialState: CreateReversalAdjustmentState = {};

export const createReversalAdjustmentReducer = createReducer(
  initialState,

  on(CreateReversalAdjustment.createReversalAdjustmentAction, (state) => ({
    ...state,
    createReversalAdjustment: {
      isLoading: true,
    },
  })),
  on(CreateReversalAdjustment.createReversalAdjustmentSuccess, (state, { response }) => ({
    ...state,
    createReversalAdjustment: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(CreateReversalAdjustment.createReversalAdjustmentFailure, (state, { error }) => ({
    ...state,
    createReversalAdjustment: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(CreateReversalAdjustment.clearCreateReversalAdjustmentState, (state) => ({
    ...state,
    createReversalAdjustment: undefined,
  })),
);
