<div class="view-adjustment-details">
  <div class="view-adjustment-details__header px-4 py-[18px]">
    <span class="text-xl font-bold leading-7">Adjustment Details</span>
  </div>
  <ptg-grid
    [data]="adjustmentDetailList"
    [columns]="adjustmentDetailColumnList"
    [totalRecords]="totalRecords"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [pageNumber]="pageIndex"
    notFoundMessage="No Data to Display"
    [hideScrollbar]="false"
    (sortChange)="onSortChange($event)"
    (pageChange)="onPageChange($event)"
    (rowClick)="onClickRow($event)"
  >
    <ng-template cellContent columnName="amount" let-row>
      <span class="truncate">{{ row?.amount ?? 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</span>
    </ng-template>
  </ptg-grid>
</div>
