export enum CorrectionType {
  PayingSuspendedPeriod = 21,
  CorrectionUnderpaymentBenefit = 22,
  CorrectionUnderpaymentDeduction = 23,
  CorrectionOthers = 24
}

export enum OneTimePaymentType {
  'Periodic Lumpsum Payment' = 6,
  'Correction' = 3,
  'Final Payment' = 5,
  'Initial Payment' = 1,
  'Reissue' = 4
}
