<div
  class="p-4"
  [ngClass]="{
    'payment-status-container': !isLoading,
    'payment-status-container-closed move-up': !isShowPaymentDetail && !isLoading,
    'move-down': isShowPaymentDetail && !isLoading
  }"
>
  <ng-container *ngIf="!isLoading; else loadingBlock">
    <div class="payment-status-preview">
      <div class="flex gap-8 items-center">
        <div class="title">{{ componentTitle }}</div>
        <div class="vertical-line"></div>
        <div *ngIf="isShowStatusChip" [class]="'mat-chip-status ' + statusClass">
          {{ statusLabel }}
        </div>
      </div>
      <div class="flex gap-8 items-center">
        <div *ngIf="isShowEditBtn" class="action-btn" (click)="onEdit()">
          <mat-icon>edit</mat-icon>
          <span class="title-edit">Edit</span>
        </div>
        <!-- Out scope in release 1 -->
        <!-- <div
          *ngIf="
            selectedTabPayment === TabPaymentInfo.InstructionHistory &&
            selectedRow?.paymentInstructionType !== PaymentInstructionType.Adjustment
          "
          class="action-btn"
        >
          <mat-icon>history</mat-icon>
          <span class="title-edit">Audit</span>
        </div> -->
        <div *ngIf="isShowCollapse" class="toggle-payment-status">
          <button (click)="controlToggle()">
            <ng-container *ngIf="isShowPaymentDetail; else arrowDown">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </ng-container>
            <ng-template #arrowDown>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isShowPaymentDetail" class="payment-status-detail">
      <div *ngIf="selectedRow?.statusReason?.status === PayStatus.Suspended" class="payment-status-section">
        <div class="label">Suspension Reason</div>
        <div class="value">{{ selectedRow?.statusReason?.reasonDescription }}</div>
      </div>
      <div
        *ngIf="
          selectedRow?.statusReason?.status === PayStatus.Suspended &&
          (selectedRow?.paymentType === PaymentInstructionType.Recurring ||
            selectedRow?.paymentType === PaymentInstructionType.AllRecurring)
        "
        class="payment-status-section"
      >
        <div class="label">Suspended Since</div>
        <div class="value">{{ selectedRow?.benefitPeriod }}</div>
      </div>
      <div
        *ngIf="!isOneTimePayment && selectedRow?.statusReason?.status === PayStatus.Terminated"
        class="payment-status-section"
      >
        <div class="label">Benefit End Date</div>
        <div class="value">{{ objDate.benefitEndDate | date }}</div>
      </div>
      <div *ngIf="selectedRow?.statusReason?.status === PayStatus.Terminated" class="payment-status-section">
        <div class="label">Termination Reason</div>
        <div class="value">{{ selectedRow?.statusReason?.reasonDescription }}</div>
      </div>
      <div *ngIf="selectedRow?.statusReason?.status === PayStatus.Terminated" class="payment-status-section">
        <div class="label">Last Payroll Cycle</div>
        <div class="value">
          {{ objDate.startDate | date: 'MM/dd/yyyy' }} -
          {{ objDate.endDate | date: 'MM/dd/yyyy' }}
        </div>
      </div>
      <div
        *ngIf="
          selectedRow?.paymentInstructionType === PaymentInstructionType.Adjustment ||
          selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
        "
      >
        <div *ngIf="selectedRow?.adjustmentStatus === AdjustmentStatusType.Approved">
          <div class="payment-status-section">
            <div class="label">Approved Date</div>
            <div class="value">{{ objDate.changeStatusTime | date: 'MM/dd/yyyy hh:mm a' }}</div>
          </div>
          <div class="payment-status-section">
            <div class="label">Approved By</div>
            <div class="value">{{ selectedRow?.changeStatusBy }}</div>
          </div>
        </div>
        <div *ngIf="selectedRow?.adjustmentStatus === AdjustmentStatusType.Rejected">
          <div class="payment-status-section">
            <div class="label">Reason</div>
            <div class="value">{{ selectedRow?.reason }}</div>
          </div>
          <div class="payment-status-section">
            <div class="label">Rejected Date</div>
            <div class="value">{{ objDate.changeStatusTime | date: 'MM/dd/yyyy hh:mm a' }}</div>
          </div>
          <div class="payment-status-section">
            <div class="label">Rejected By</div>
            <div class="value">{{ selectedRow?.changeStatusBy }}</div>
          </div>
        </div>
        <div class="payment-status-section">
          <div class="label">Created Date</div>
          <div class="value">{{ objDate.createdTime | date: 'MM/dd/yyyy hh:mm a' }}</div>
        </div>
        <div class="payment-status-section">
          <div class="label">Created By</div>
          <div class="value">{{ selectedRow?.createdBy }}</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loadingBlock>
  <div class="spinner-wrapper" [class.active]="isLoading">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>
