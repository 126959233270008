import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  InstructionHistory,
  LastPayrollCycle,
  PaymentInfoTabLoadingStateHandler,
  PaymentTab,
} from '../../services/models';
import { EditPaymentStatusOutputData } from '../../services/models/edit-payment-status.model';
import { getPaymentEarningsDetailState, getPayrollSettingsSelector } from '../../store';
import * as fromPayeeDetail from '../../store/reducers/index';
import { getPayeePaymentSelector } from '../../store/selectors/payee-payment.selector';
import {
  handlePaymentInfoTabLoadingState,
  ONE_TIME_PAYMENT_INSTRUCTION_TYPE,
} from '../../types/constants/payment-info-tab.constant';
import {
  AdjustmentStatusLabel,
  PaymentInfoAdjustmentType,
  PaymentInstructionType,
  PaymentInsType,
  PaymentStatusAdjustmentType,
  PayStatus,
  PayStatusLabel,
  TabPaymentInfo,
} from '../../types/enums';

import { HeaderBenefit, LocalDate } from '../../types/models';
import { EditPaymentStatusComponent } from '../edit-payment-status/edit-payment-status.component';
import { EditPaymentStatusComponentService } from '../edit-payment-status/edit-payment-status.component.service';
import { getDateString } from '@ptg-shared/utils/string.util';

@Component({
  selector: 'ptg-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit, OnDestroy, OnChanges, PaymentInfoTabLoadingStateHandler {
  readonly TabPaymentInfo = TabPaymentInfo;
  readonly PayStatus = PayStatus;
  readonly PayStatusLabel = PayStatusLabel;
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly PaymentInfoAdjustmentType = PaymentInfoAdjustmentType;
  readonly PaymentInsType = PaymentInsType;
  readonly AdjustmentStatusType = PaymentStatusAdjustmentType;

  unsubscribe$ = new Subject<void>();
  isLoading = true;
  errorOccurs = false;
  componentTitle = 'Payment Status';
  statusClass = '';
  statusLabel = '';
  isShowStatusChip = false;
  objDate: LocalDate = {};

  @Input() memberId = '';
  @Input() selectedRow?: PaymentTab & InstructionHistory;
  @Input() selectedHeaderBenefit?: HeaderBenefit;
  @Input() selectedTabPayment!: TabPaymentInfo;
  @Input() dateOfDeath?: string;

  @Output() changeBannerEmitter = new EventEmitter<{
    customMessage: string;
    resultStatus: BannerType;
    isPaymentStatusSection: boolean;
    isTerminated?: boolean;
    payeeName?: string;
    lastPayrollCycle?: LastPayrollCycle;
    isUncheckStatus?: boolean;
    isRejected?: boolean;
  }>();
  @Output() reloadDataEmitter = new EventEmitter<string>();

  isShowEditBtn?: boolean = false;
  isShowPaymentDetail: boolean = false;
  isShowCollapse: boolean = false;

  get isOneTimePayment() {
    return (this.selectedRow?.paymentType && ONE_TIME_PAYMENT_INSTRUCTION_TYPE.includes(this.selectedRow.paymentType)) ||
      !!this.selectedRow?.lumpSumPaymentTypeName;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly editPaymentStatusComponentService: EditPaymentStatusComponentService,
    private readonly payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
  ) {}

  ngOnInit(): void {
    // Loading indicator visibility
    this.loadingIndicatorFromStates();
    this.handleData();
    this.changeShowEdit();
    this.changeShowCollapse();
    handlePaymentInfoTabLoadingState(this, this.payeeDetailStore);
  }

  handleData() {
    this.objDate.changeStatusTime = getDateString(this.selectedRow?.changeStatusTime ?? '');
    this.objDate.createdTime = getDateString(this.selectedRow?.createdTime ?? '');
    this.objDate.benefitEndDate = this.selectedRow?.benefitEndDate ?? '';
    this.objDate.startDate = this.selectedRow?.lastPayrollCycle?.startDate ?? '';
    this.objDate.endDate = this.selectedRow?.lastPayrollCycle?.endDate ?? '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow) {
      this.handleData();
      this.changeShowCollapse();
      this.componentTitle =
        this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
          ? 'Status'
          : 'Payment Status';
      const fieldData =
        this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
          ? this.selectedRow?.adjustmentStatus
          : this.selectedRow?.payStatus ?? this.selectedRow?.status;

      this.statusClass = this.classStatus(fieldData);
      this.statusLabel = this.getStatusLabel();
      this.isShowStatusChip = fieldData !== undefined;
    }
    this.changeShowEdit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeShowEdit() {
    this.isShowEditBtn =
      (this.selectedTabPayment !== TabPaymentInfo.InstructionHistory &&
        this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.PaymentInstruction &&
        this.selectedRow?.payStatus !== PayStatus.Finalized &&
        this.selectedRow?.statusReason?.isEditable) ||
      (this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment &&
        this.selectedRow?.adjustmentStatus === PaymentStatusAdjustmentType.Created &&
        this.selectedRow.isEditable);
  }

  changeShowCollapse() {
    const status = this.selectedRow?.statusReason?.status;
    this.isShowCollapse =
      status === PayStatus.Suspended ||
      status === PayStatus.Terminated ||
      this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment ||
      (this.selectedTabPayment == TabPaymentInfo.InstructionHistory &&
        this.selectedRow?.paymentInstructionType === PaymentInstructionType.Recurring &&
        !!this.selectedRow?.statusReason &&
        status !== PayStatus.Pending &&
        status !== PayStatus.Closed);
  }

  controlToggle() {
    this.isShowPaymentDetail = !this.isShowPaymentDetail;
  }

  classStatus(payStatus?: PayStatus | PaymentStatusAdjustmentType) {
    switch (payStatus) {
      case PayStatus.Suspended:
      case PaymentStatusAdjustmentType.Created:
        return 'orange-status';
      case PayStatus.Pending:
      case PayStatus.Finalized:
      case PaymentStatusAdjustmentType.Approved:
        return 'green-status';
      case PayStatus.Terminated:
      case PayStatus.Cancelled:
      case PaymentStatusAdjustmentType.Rejected:
        return 'red-status';
      default:
        return '';
    }
  }

  getStatusLabel() {
    const status = this.selectedRow?.payStatus ?? this.selectedRow?.status;
    return this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
      ? AdjustmentStatusLabel[this.selectedRow?.adjustmentStatus ?? PaymentStatusAdjustmentType.Created]
      : PayStatusLabel[status ?? PayStatus.IssuePayment];
  }

  onEdit(): void {
    const editPaymentStatusDialog: MatDialogRef<EditPaymentStatusComponent, EditPaymentStatusOutputData> =
      this.dialog.open(EditPaymentStatusComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {
          memberId: this.memberId,
          paymentInstruction: this.selectedRow,
          headerBenefits: this.selectedHeaderBenefit,
          isReversing: false,
          isOneTimePayment: this.isOneTimePayment,
          dateOfDeath: this.dateOfDeath,
        },
      });

    editPaymentStatusDialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        // Cancel dialog
        if (!result) {
          return;
        }

        const customMessage: string = this.editPaymentStatusComponentService.getBannerCustomMessage(
          result.isSuccess,
          result.isReversing,
        );
        this.reloadDataEmitter.emit('payment-info-tab');
        this.changeBannerEmitter.emit({
          customMessage,
          resultStatus: result?.isSuccess ? BannerType.Success : BannerType.Fail,
          isPaymentStatusSection: true,
          isTerminated: result.isTerminated,
          lastPayrollCycle: result.lastPayrollCycle,
          isUncheckStatus: result.isUncheckStatus,
          isRejected: result.isRejected,
        });
      });
  }

  private loadingIndicatorFromStates(): void {
    combineLatest([
      this.payeeDetailStore.select(getPayrollSettingsSelector),
      this.payeeDetailStore.select(getPayeePaymentSelector),
      this.payeeDetailStore.select(getPaymentEarningsDetailState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((responseList) => {
        this.isLoading = responseList.some((res) => res?.isLoading);
        this.errorOccurs = responseList.some((res) => res?.error);
      });
  }
}
