import { createAction, props } from '@ngrx/store';
import { ValidateRemittance2024ReceiptNumberResponse } from '@ptg-employer/models/remittance-2024-confirmation.model';

// Validate Remittance 2024 Receipt Number
export const validateRemittance2024ReceiptNumberAction = createAction(
  '[ValidateRemittance2024ReceiptNumber/API] Send Request',
  props<{ receiptNumber: string, employerId: string }>()
);
export const validateRemittance2024ReceiptNumberSuccessAction = createAction(
  '[ValidateRemittance2024ReceiptNumber/API] Success',
  props<{ response: ValidateRemittance2024ReceiptNumberResponse }>()
);
export const validateRemittance2024ReceiptNumberFailureAction = createAction(
  '[ValidateRemittance2024ReceiptNumber/API] Failure',
  props<{ error?: unknown }>()
);
export const clearValidateRemittance2024ReceiptNumberStateAction = createAction(
  '[ValidateRemittance2024ReceiptNumber] Clear'
);
