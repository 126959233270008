import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as MunicipalityPaymentAction from '../../store/actions/municipality-payment.action';
import { MunicipalityPaymentService } from '../../services/municipality-payment.service';
import { MunicipalityList } from '../../types/models/status-history.model';
import { GetDisabilityPaymentsResponse } from '@ptg-member/types/models';

@Injectable()
export class MunicipalityPaymentEffects {
  getMunicipalityPaymentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityPaymentAction.getMunicipalityPaymentList),
      switchMap(({ query }) => {
        return this.municipalityPaymentService
          .getMunicipalityPaymentList(query)
          .pipe(
            map((payments: MunicipalityList) => {
              return MunicipalityPaymentAction.municipalityPaymentListSuccess({
                payments,
              });
            }),
            catchError((err) => {
              return of(
                MunicipalityPaymentAction.municipalityPaymentListFailure(),
              );
            }),
          );
      })
    )
  );

  editMunicipalityPaymentMetRequirement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityPaymentAction.editMunicipalityPaymentMetRequirement),
      switchMap(({ body }) => {
        return this.municipalityPaymentService.editMunicipalityPaymentMetRequirement(body)
          .pipe(
            map((editMetRequirementResponse) => {
              return MunicipalityPaymentAction.editMunicipalityPaymentMetRequirementSuccess({ editMetRequirementResponse });
            }),
            catchError((error) => {
              return of(
                MunicipalityPaymentAction.editMunicipalityPaymentMetRequirementFailure(error),
              );
            }),
          );
      }),
    ),
  );

  addDisabilityPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityPaymentAction.addDisabilityPaymentAction),
      switchMap(({ body }) => {
        return this.municipalityPaymentService
          .addDisabilityPayment(body)
          .pipe(
            map(() => MunicipalityPaymentAction.addDisabilityPaymentActionSuccess()),
            catchError((err) => of(MunicipalityPaymentAction.addDisabilityPaymentActionFailure(err))),
          );
      })
    )
  );

  getDisabilityPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MunicipalityPaymentAction.getDisabilityPaymentAction),
      switchMap(({ memberId }) => {
        return this.municipalityPaymentService
          .getDisabilityPayments(memberId)
          .pipe(
            map((response: GetDisabilityPaymentsResponse) => {
              return MunicipalityPaymentAction.getDisabilityPaymentActionSuccess({
                response,
              });
            }),
            catchError((err) => {
              return of(
                MunicipalityPaymentAction.getDisabilityPaymentActionFailure(err),
              );
            }),
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private municipalityPaymentService: MunicipalityPaymentService,
  ) {
  }
}
