import { NgModule } from '@angular/core';
import { AddressPipe } from './address.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterDisplayPipe } from './filter-display.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { MaskPipe } from './mask.pipe';
import { NumberDecimalPipe } from './number-decimal.pipe';
import { NumberLocalDecimalPipe } from './number-local-decimal.pipe';
import { PaymentStatusPipe } from './payment-status.pipe';
import { PersonNamePipe } from './person-name.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { PropertyListPipe } from './property-list.pipe';
import { SSNNumberPipe } from './ssn-number.pipe';
import { CustomPersonNamePipe } from './custom-person-name.pipe';
import { WholeNumberPipe } from './whole-number.pipe';
import { CustomBoldPipe } from '@ptg-shared/pipes/custom-bold.pipe';
import { BinaryPipe } from './binary.pipe';
import { GranulationOptionsPipe } from './granulation-options.pipe';
import { PrefixNamePipe } from './prefix-name.pipe';
import { StatusEntityPipe } from './status-entity.pipe';
import { CalculationNamePipe } from './calculation-name.pipe';
import { ParentBenefitEntityNamePipe } from './parent-benefit-entity-name.pipe';
import { FilterListPipe } from './filter-list.pipe';

export const PIPES = [
  FileSizePipe,
  NumberLocalDecimalPipe,
  NumberDecimalPipe,
  WholeNumberPipe,
  PhoneNumberPipe,
  LinkifyPipe,
  DateFormatPipe,
  AddressPipe,
  PersonNamePipe,
  PropertyListPipe,
  MaskPipe,
  PaymentStatusPipe,
  FilterDisplayPipe,
  SSNNumberPipe,
  CustomPersonNamePipe,
  FileSizePipe,
  CustomBoldPipe,
  BinaryPipe,
  GranulationOptionsPipe,
  PrefixNamePipe,
  StatusEntityPipe,
  CalculationNamePipe,
  ParentBenefitEntityNamePipe,
  FilterListPipe,
];

@NgModule({
  imports: [],
  declarations: PIPES,
  exports: PIPES,
  providers: PIPES,
})
export class PipeModule {}
