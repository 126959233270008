<div class="accident-detail">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>
  <ptg-overview-header-entity #overViewHeader></ptg-overview-header-entity>
  <div class="accident-detail-content" *ngIf="!isFileDownloading; else loadingTemplate">
    <div class="flex flex-col p-6 gap-6 pad-bot-0">
      <ptg-sub-header
        [memberId]="memberId"
        [menuItemTitle]="menuItemTitle"
        [menuItemSubTitle]="menuItemSubTitle"
      ></ptg-sub-header>
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>
      <div class="flex justify-between">
        <div class="latest-audit-trail">
          <span class="text"> {{textLatestAuditTrail}} </span>
          <div class="status flex">
              {{accidentStatusText}}
              <ptg-button
                class="flex"
                buttonName="edit"
                classInput="edit-button"
                (clickButton)="onEditParticipantAccidentStatus()">
                <mat-icon>edit</mat-icon>
              </ptg-button>
          </div>
        </div>
        <div class="add-note">
          <ptg-button mat-icon-button
            buttonName="Add Note"
            (clickButton)="addNote()">
            <mat-icon>note_add</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="note">
        <ptg-accident-note-detail
          [memberId]="memberId"
          [accidentId]="accidentId"
        ></ptg-accident-note-detail>
      </div>
      <div *ngIf="!isAccidentLoading" class="content-detail">
        <div class="cell-detail">
            <div class="section-info">
              <div class="section-content">
                <div class="accident-post-card-detail">
                  <div class="title">
                    <div class="name">Accident Post Card: ID {{accident?.accidentNumber}}</div>
                    <div class="action">
                      <ptg-button
                          buttonName="Edit"
                          classInput="edit-button"
                          (clickButton)="onEditAccidentPostCard()">
                          <mat-icon>edit</mat-icon>
                      </ptg-button>
                    </div>
                  </div>
                  <div class="content">
                    <div class="item">
                        <div class="full-row">
                          <div class="item-container">
                              <div class="label">Municipality</div>
                              <div class="value wrap-text">{{(accident?.municipalityCode ? accident?.municipalityCode + ' - ' : '') + (accident?.municipalityName ? accident?.municipalityName : '')}}</div>
                          </div>
                        </div>
                        <div class="one-third-row">
                          <div class="item-container">
                              <div class="label">Accident Date</div>
                              <div class="value">{{accident?.accidentDate | date: 'MM/dd/yyyy'}}</div>
                          </div>
                        </div>
                        <div class="one-third-row">
                          <div class="item-container">
                              <div class="label">Receive Date</div>
                              <div class="value">{{accident?.receiveDate | date: 'MM/dd/yyyy'}}</div>
                          </div>
                        </div>
                        <div class="one-third-row">
                          <div class="item-container">
                              <div class="label">Days Between</div>
                              <div class="value wrap-text">{{accident?.daysBetween}}</div>
                          </div>
                        </div>
                        <div class="one-third-row">
                          <div class="item-container">
                              <div class="label">Injury</div>
                              <div class="value wrap-text">{{ (accident?.injuryCode ? accident?.injuryCode + ' - ' : '') + (accident?.injuryCodeDescription ? accident?.injuryCodeDescription : '')}}</div>
                          </div>
                        </div>
                        <div class="one-third-row">
                          <div class="item-container">
                              <div class="label">Injury Description</div>
                              <div class="value wrap-text">{{accident?.injuryDescription}}</div>
                          </div>
                        </div>
                        <div class="one-third-row">
                          <div class="item-container">
                              <div class="label"> </div>
                              <div class="value"></div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="submitter-container">
                    <div class="item">
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Submitter Name</div>
                            <div class="value wrap-text">{{accident?.submitterName}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Submitter Phone</div>
                            <div class="value wrap-text">{{accident?.submitterPhone}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Submitter Email</div>
                            <div class="value wrap-text">{{accident?.submitterEmail}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="cell-detail">
          <div class="section-info">
            <div class="section-content">
              <div class="accident-report-detail">
                <div class="title">
                  <div class="name">Accident Report </div>
                  <div class="action">
                    <ptg-button
                      *ngIf="accidentReport"
                      buttonName="Edit"
                      classInput="edit-button"
                      (clickButton)="onEditAccidentReport()">
                      <mat-icon>edit</mat-icon>
                    </ptg-button>
                    <ptg-button
                      *ngIf="!accidentReport"
                      buttonName="Add Accident Report"
                      classInput="edit-button"
                      (clickButton)="onAddAccidentReport()">
                      <mat-icon>add</mat-icon>
                    </ptg-button>
                  </div>
                </div>
                <div *ngIf="accidentReport" class="content">
                  <div class="item">
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Days Off From Work</div>
                            <div class="value">{{accidentReport.dayOffFromWork}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Hospitalized</div>
                            <div class="value">{{accidentReport.isHospitalized ? 'Yes' : 'No'}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Dr Seen</div>
                            <div class="value">{{accidentReport.isDrSeen ? 'Yes' : 'No'}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Accident Time</div>
                            <div class="value">{{accidentTime}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Fatal</div>
                            <div class="value">{{accidentReport.isFatal ? 'Yes' : 'No'}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Nature of Injury</div>
                            <div class="value wrap-text">{{accidentReport.natureOfInjury}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Dr Name</div>
                            <div class="value">{{accidentReport.drName}}</div>
                        </div>
                      </div>
                      <div class="one-third-row">
                        <div class="item-container">
                            <div class="label">Chief/Sheriff Name</div>
                            <div class="value">{{accidentReport.sheriffName}}</div>
                        </div>
                      </div>
                      <div class="one-third-row last-item">
                        <div class="item-container">
                            <div class="label">Location of Accident</div>
                            <div class="value">{{locationOfAccident}}</div>
                        </div>
                      </div>
                  </div>
                </div>
                <div *ngIf="!accidentReport" class="content-no-data">
                  <div>
                    No Data to Display
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isAccidentLoading">
        <div class="flex justify-center w-full my-24">
          <ptg-spinner [isLoading]="isAccidentLoading"></ptg-spinner>
        </div>
      </div>
    </div>
    <div class="accident-document flex flex-col gap-6 p-6 pad-top-15">
      <div class="flex-grow overflow-hidden">
        <div class="header flex justify-between">
          <span class="title">Accident Documents</span>
          <ng-container *ngIf="showMenuButton">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Icon-button with a menu" class="group-button ignore-row">
              <mat-icon class="ignore-row">more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="accident-document-menu-group">
              <button mat-menu-item (click)="uploadAccidentDocument()">
                <mat-icon class="action-icon">upload</mat-icon>
                <span class="label-icon">Upload Document</span>
              </button>
              <button mat-menu-item (click)="generateAccidentPostcardLetter()">
                <mat-icon class="action-icon">add</mat-icon>
                <span class="label-icon">Accident Post Card Acknowledgment</span>
              </button>
              <button mat-menu-item (click)="generateAccidentReportLetter()">
                <mat-icon class="action-icon">add</mat-icon>
                <span class="label-icon">Accident Report Acknowledgment</span>
              </button>
            </mat-menu>
          </ng-container>
        </div>
        <div class="grid-wrapper">
          <ptg-grid
            [data]="listAccidentDocuments"
            [columns]="columnsAccidentDocuments"
            [isLoading]="isLoadingDocument"
            [totalRecords]="lengthPgDocument"
            [pageSize]="pageSizeDocument"
            [pageNumber]="pageIndexDocument"
            [fitToParent]="true"
            [hideScrollbar]="false"
            pageName="Accident Document"
            (sortChange)="onSortDocumentChange($event)"
            (pageChange)="onChangeDocumentPage($event)"
            notFoundMessage="No Accident Documents to Display"
            >

            <ng-template cellContent columnName="fileName" let-row>
              <div class="flex file-document-container">
                <span (click)="downloadDocumentFile(row)" class="file-document-name" target="_blank"><span class="truncate">{{ row?.fileName }}</span></span>
              </div>
            </ng-template>
            <ng-template cellContent columnName="action" let-row>
              <ptg-button
                buttonName="Edit"
                classInput="edit-button"
                (clickButton)="onEditDocument(row)">
                <mat-icon>edit</mat-icon>
             </ptg-button>
              <ptg-button
                  buttonName="Remove"
                  classInput="remove-button"
                  (clickButton)="onRemoveDocument(row)">
                  <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-template>
          </ptg-grid>
        </div>

      </div>
    </div>
    <div class="accident-grid flex flex-col gap-6 p-6 pad-top-15">
      <div class="flex-grow overflow-hidden">
        <div class="header flex">
          <span class="title">Accident Claims</span>
          <ptg-button
            buttonName="Add Accident Claim"
            classInput="add-button"
            class="add-accident-claim"
            (clickButton)="addAccidentClaim()"
          >
            <mat-icon class="action-icon">add</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Export Accident Claims"
            classInput="export-button"
            class="export-accident-claim"
            [ngClass]="{'opacity-50' : isLoadingExportAccidentClaims}"
            (clickButton)="exportAccidentClaim()"
            [isDisabled]="isLoadingExportAccidentClaims"
          >
            <mat-icon *ngIf="isLoadingExportAccidentClaims" class="animate-spin action-icon mr-2">refresh</mat-icon>
            <mat-icon *ngIf="!isLoadingExportAccidentClaims" class="action-icon mr-2">file_download</mat-icon>
          </ptg-button>
        </div>
        <ptg-grid
          [data]="listAccidentClaimDetail"
          [columns]="columns"
          [isLoading]="isLoading"
          [totalRecords]="lengthPg"
          [pageSize]="pageSize"
          [pageNumber]="pageIndex"
          [fitToParent]="true"
          [hideScrollbar]="false"
          pageName="Accident Claims"
          (pageChange)="onChangePage($event)"
          (sortChange)="onSortChange($event)"
          notFoundMessage="No Accident Claims to Display"
        >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditAccidentClaim(row)"
          >
              <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onRemoveAccidentClaim(row)"
          >
              <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
      </div>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="flex justify-center w-full my-24">
      <ptg-spinner [isLoading]="isFileDownloading"></ptg-spinner>
    </div>
  </ng-template>
</div>
