export const CourtOrderComponentId = '2F73BD44-060D-4B47-AEC7-83DCEA06EB36';

export enum CourtOrderPropertiesGUIDs {
  // Common Court Order Properties
  Payee = 'DFB5E764-0D1F-4F69-B934-4050EF4C8161',
  CaseNumber = 'C069DB2F-F7F0-4032-86B5-58A5FA4D5FBC',
  CourtOrderType = '67ECA942-C223-460D-AF14-271507773096',
  CourtOrderStatus = 'FCB4758D-1801-4577-80E3-7103D71EEB60',
  ReceivedDate = '9F765A3A-1C29-4F94-A1BD-D3E40D432B3B',
  FilingDate = '5AFC0BDB-DC2B-4687-AD84-19ACA6EE33C9',
  Relationship = 'A0BD4346-90DA-45D0-8439-40F01E09B1A2',
  TerminateRejectReason = 'BBCA5B04-B34B-48EE-9084-77F10EA7B1A6',
  NotifyingParty = '0FF0CCDE-53C3-4B07-B198-11DA985EC743',
  DateOfNotification = '6DB09301-F7B2-4336-B343-6B0B3CED0958',
  MarriageDate = 'CA1DEA97-DDEE-4A1F-852C-E7A8BEED6103',
  DivorceDate = '6C77C8EC-88CD-412B-A028-D284D08EFDAE',
  EligibleForCOLA = 'EEA03FB4-F19F-458D-AD81-AF55A43F85C9',
  EffectiveStartDate = '99BE35DA-D198-477F-A47C-A989099CC368',
  EffectiveEndDate = 'DF6FCB6F-AF89-46FC-9E25-BF3D9E306E03',
  AllowanceFlatAmount = '5D446E44-0C4F-4262-AEE3-AF485F37BF0E',
  TaxPayer = '3EB3736D-77B7-4D12-8593-00EAE4493FAB',
  AllowancePercentage = 'B1683499-6ADC-4019-8D52-CDAF4DFA6B91',
  COLAPercentage = '6A0EEE51-4C22-4D64-9950-C9A0BAD1860C',

  // Payee Information Properties
  DateOfBirth = '220E5FCB-9A56-4C89-88B2-C717F6DF2B5E',
  DateOfDeath = '92EB0499-7C10-485F-B9F1-757F8F9C1632',
  SSN = '4BE56F09-EA2F-4DCC-8C53-5E44707A124B',
  PersonName = '0AC1B906-C396-4BC5-A83B-EEC542482445',
  Gender = '5F4D07BE-F13B-4C9C-99AA-CE9B228F07C7',
  Address = 'A499344F-F95C-43B2-B716-516C8AC3754B',
  Email = '00FABCB5-D468-460B-BEBB-23F06178DC19',
  Phone = '94566AA1-5318-4FC3-B89C-3D683AD8CB5F',
  Note = 'E872B088-681A-462A-88AF-BDDF12199336',
}

// Only for Add/Edit Court Order QDRO case
export const NEED_VALIDATE_CAN_NOT_AFTER_TODAY_FIELDS_FIXED_ID = [
  CourtOrderPropertiesGUIDs.DateOfDeath,
  CourtOrderPropertiesGUIDs.MarriageDate,
  CourtOrderPropertiesGUIDs.DivorceDate,
];
export const NEED_WARNING_SHOULD_NOT_AFTER_TODAY_FIELDS_FIXED_ID = [
  CourtOrderPropertiesGUIDs.DateOfNotification,
];
export const NEED_WARNING_SHOULD_NOT_BLANK_FIELDS_FIXED_ID = [
  CourtOrderPropertiesGUIDs.MarriageDate,
  CourtOrderPropertiesGUIDs.DivorceDate,
  CourtOrderPropertiesGUIDs.SSN,
];

export const CONFIRM_MSG_PREFIX = 'There might be some inaccuracies as follows: ';
export const CONFIRM_MSG_SUFFIX = 'Are you sure the entered information is correct?';
export const MARITAL_INFORMATION_VALIDATE_MSG = 'Marriage Date or Divorce Date is different from the corresponding relationship information';

export enum ValidateFor {
  CourtOrder,
  Relationship
}
