import { createSelector } from "@ngrx/store";
import { selectMemberModuleState } from "../reducers";

export const memberAuditTrailsSelector = createSelector(
  selectMemberModuleState,
  (state) => state?.memberAuditTrails
);

export const getMemberAuditTrailsListSelector = createSelector(
  memberAuditTrailsSelector,
  (state) => state?.getMemberAuditTrailsListState
);

export const getMemberAuditTrailsSectionListSelector = createSelector(
  memberAuditTrailsSelector,
  (state) => state?.getMemberAuditTrailsSectionListState
);

export const getMemberAuditTrailsCardInfoSelector = createSelector(
  memberAuditTrailsSelector,
  (state) => state?.getMemberAuditTrailsCardInfoState
);

export const getMemberAuditTrailsProfileInfoSelector = createSelector(
  memberAuditTrailsSelector,
  (state) => state?.getMemberAuditTrailsProfileInfoState
);

export const getMemberAuditTrailsSearchNameSelector = createSelector(
  memberAuditTrailsSelector,
  (state) => state?.getMemberAuditTrailsSearchNameState
);

export const getMemberAuditTrailsSearchUserSelector = createSelector(
  memberAuditTrailsSelector,
  (state) => state?.getMemberAuditTrailsSearchUserState
);
