import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AddOneTimeService } from '../../services';
import { AddressData, BenefitTimeInfo, FundingSourceElement, GetDeductionFundingSourcesResponse, PayeeElementDetail, PayeeResponse, PaymentAddress, PaymentType } from '../../services/models';
import { checkDisplayWithHoldingTaxAction, checkDisplayWithHoldingTaxFailureAction, checkDisplayWithHoldingTaxSuccessAction, createOneTimePaymentAction, createOneTimePaymentFailureAction, createOneTimePaymentSuccessAction, getBenefitTimeInfoAction, getBenefitTimeInfoFailureAction, getBenefitTimeInfoSuccessAction, getDeductionDataAction, getDeductionDataFailureAction, getDeductionDataSuccessAction, getFundingSourceAction, getFundingSourceFailureAction, getFundingSourceSuccessAction, getOneTimeCorrectionTypeAction, getOneTimeCorrectionTypeFailureAction, getOneTimeCorrectionTypeSuccessAction, getOneTimePaymentTypeAction, getOneTimePaymentTypeFailureAction, getOneTimePaymentTypeSuccessAction, getPayeeListAction, getPayeeListFailureAction, getPayeeListSuccessAction, getPaymentAddressAction, getPaymentAddressFailureAction, getPaymentAddressSuccessAction } from '../actions';
import { CreateOffsetDeductionService } from '../../services/create-offset-deduction.service';
import { createOffsetDeductionSuccessAction, createOffsetDeductionFailureAction, createOffsetDeductionAction } from '../actions/create-offset-deduction.action';


@Injectable()
export class CreateOffsetDeductionEffect {
  constructor(
    private actions$: Actions,
    private createOffsetDeductionService: CreateOffsetDeductionService,
  ) {}

  createOffsetDeduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOffsetDeductionAction),
      switchMap(({ body }) => {
        return this.createOffsetDeductionService.createOffsetDeduction(body).pipe(
          map(() => {
            return createOffsetDeductionSuccessAction();
          }),
          catchError((error) => {
            return of(createOffsetDeductionFailureAction({error}));
          })
        );
      })
    )
  );
}
