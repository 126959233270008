<ptg-dialog class="mark-returned-rejected" [ngClass]="{'has-profile-header': !!data.payeeRecordId}" [title]="dialogTitle" [isFullScreen]="true">
  <ng-template dialogContentHeader>
    <div class="member-profile-container" *ngIf="data.payeeRecordId">
      <ptg-overview-header-entity
      [targetIdFromDialog]="data.payeeRecordId"
      ></ptg-overview-header-entity>
    </div>
  </ng-template>
  <ng-template dialogBody>
    <div class="action-buttons flex gap-2 mb-6">
      <ptg-button classInput="primary" buttonName="Save" (clickButton)="onSubmit()"></ptg-button>
      <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
    </div>
    <div class="form-control-container">
      <div class="flex gap-6 mb-2">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium font-bold">Recipient</div>
          <div class="text-base emphasis-high">{{ data.registerData?.recipient }}</div>
        </div>
      </div>

      <div class="flex gap-6 mb-2">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium font-bold">Transaction ID</div>
          <div class="text-base emphasis-high">{{ data.registerData?.transactionId }}</div>
        </div>

        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium font-bold">Date Posted</div>
          <div class="text-base emphasis-high">{{ data.registerData.datePosted! | dateFormat }}</div>
        </div>

        <div class="min-w-[140px]" *ngIf="data.registerData.paymentMethod === PaymentType['Direct Deposit']">
          <div class="text-sm leading-6 emphasis-medium font-bold">Deposit Date</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.depositDate | date: 'MM/dd/yyyy') || '' }}</div>
        </div>
        <div *ngIf="data.registerData.paymentMethod === PaymentType.Check" class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium font-bold">Check Date</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.checkDate | date: 'MM/dd/yyyy') || '-' }}</div>
        </div>
      </div>

      <div class="flex gap-6 mb-2">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium font-bold">Net Payment</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.netAmount || 0) | numberLocalDecimal: '$' }}</div>
        </div>

        <div class="min-w-[140px]" *ngIf="data.registerData.paymentMethod === PaymentType['Direct Deposit']">
          <div class="text-sm leading-6 emphasis-medium font-bold">Account</div>
          <div class="text-base emphasis-high">{{ accountNo }}</div>
        </div>
        <div *ngIf="data.registerData.paymentMethod === PaymentType.Check" class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium font-bold">Check Number</div>
          <div class="text-base emphasis-high">{{ data.isConfigOST ? formatCheckNumberOST(data.registerData?.checkNumber) : data.registerData?.checkNumber }}</div>
        </div>
      </div>

      <div class="flex gap-1 mb-2">
        <ptg-select
          #selectionElement
          [listData]="listItem"
          [controlField]="otherCtrl"
          [isSetMaxWidthSelect]="true"
          [isOptionObj]="true"
          [isDisabled]="isDisabledSelection"
          class="form-control"
          customError="requiredChip"
          placeholder="Reason"
        ></ptg-select>
        <button
          mat-raised-button
          type="button"
          class="add-btn"
          type="button"
          (click)="addOther()"
          [disabled]="isDisabledAddButton"
          [class.disabled]="isDisabledAddButton"
        >
          <mat-icon>add</mat-icon>
        </button>
        <div class="chip-item-container">
          <span class="chip" *ngFor="let item of listChip; let i = index">
            <span>{{ item.displayValue }}</span>
            <button mat-button type="button" class="x-btn" (click)="removeChip(i)">✕</button>
          </span>
        </div>
      </div>

      <div class="flex gap-6 mb-2" *ngIf="hasOther">
        <ptg-textbox
          placeholder="Other Reasons"
          [controlField]="otherReasonCtrl"
          [hasLabel]="true"
          [maxLength]="250"
        ></ptg-textbox>
      </div>
      <div class="flex gap-6 mb-2" *ngIf="!data.registerData.isDeductionPayee">
        <ptg-toggle-button [controlField]="suspendPayeeCtrl" label="Suspend Payee"></ptg-toggle-button>
      </div>
      <div class="flex gap-6 mb-2" *ngIf="shouldShowDisableDepositAccount()">
        <ptg-toggle-button
          [controlField]="disableDepositAccountCtrl"
          label="Disable Deposit Account"
        ></ptg-toggle-button>
      </div>
      <div class="flex gap-6 mb-2" *ngIf="!suspendPayeeCtrl?.value && disableDepositAccountCtrl?.value">
        <ptg-select
          [controlField]="replaceByCtrl"
          [listData]="listReplaceBy"
          [isOptionObj]="true"
          (changeOptionValue)="onChangeReplaceBy()"
          placeholder="Replace by"
        ></ptg-select>
      </div>
      <div class="flex gap-6 mb-2" *ngIf="replaceByCtrl.value === PaymentType['Direct Deposit'] && disableDepositAccountCtrl?.value">
        <ptg-select
          placeholder="Account #"
          [controlField]="accountCtrl"
          [listData]="listAccount"
          [isOptionObj]="true"
        ></ptg-select>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
