export enum PayeeFilterType {
  // Recurring filter
  Recurring,
  Added,
  Suspend,
  Terminated,

  // One-time filter
  InitialPayment,
  Correction,
  Reissue,
  FinalPayment,
  PeriodicLumpsumPayment,
  AutoOneTime,
}
