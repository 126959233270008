import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, Subject, forkJoin, of } from 'rxjs';

import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import * as fromReducer from '@ptg-reducers';
import { PayeePayment, PayeePaymentType, SetPayeePaymentRequest, SplitPaymentType } from '../../types/models';
import {
  BankAccountType,
  DepositAccount,
  GetDepositAccountsRequest,
} from '@ptg-member/types/models/deposit-account.model';
import { getDepositAccountsAction } from '@ptg-member/store/actions/deposit-account.action';
import { getDepositAccountsState } from '@ptg-member/store/selectors';
import { catchError, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { setPayeePaymentAction } from '../../store';
import { PayeePaymentService } from '../../services';

@Component({
  selector: 'ptg-edit-payee-payment',
  templateUrl: './edit-payee-payment.component.html',
  styleUrls: ['./edit-payee-payment.component.scss'],
})
export class EditPayeePaymentComponent extends BaseComponent {
  readonly PayeePaymentType = PayeePaymentType;
  formSubmit$: Subject<void> = new Subject();

  bannerType: BannerType = BannerType.Hidden;
  sumAmount: number = 0;
  isLoading = false;
  isLoadingAccount = true;
  editForm: FormGroup = new FormGroup({
    listParameter: new FormArray([]),
  });
  listParameter = new FormArray([]);

  listPaymentMethod = [
    {
      value: PayeePaymentType.Check,
      displayValue: 'Check',
    },
    {
      value: PayeePaymentType['Direct Deposit'],
      displayValue: 'Direct Deposit',
    },
  ];

  listAccountActive?: DepositAccount[];
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditPayeePaymentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { memberId: string; payeePaymentTable: PayeePayment[]; total: number; paymentInstructionId: string },
    private store: Store<fromReducer.State>,
    private payeePaymentService: PayeePaymentService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.bannerType = BannerType.Info;
    this.sumAmount = this.data?.total;
    this.data?.payeePaymentTable.forEach((el: any) => {
      this.listParameter.push(
        new FormGroup({
          id: new FormControl(el.id),
          depositAccountId: new FormControl(el.depositId),
          name: new FormControl(el.name),
          amount: new FormControl(el.amount),
          paymentMethod: new FormControl(el.paymentType),
          account: new FormControl(el.depositAccount?.id),
          listAccountOptions: new FormControl(),
          listPaymentMethod: new FormControl(),
          percentage: new FormControl(el.percentage),
        }),
      );
    });

    this.listParameter?.controls.forEach((el) => {
      el.get('listPaymentMethod')?.setValue(this.listPaymentMethod);
      if (el.get('paymentMethod')?.value === PayeePaymentType['Direct Deposit']) {
        el.get('account')?.setAsyncValidators(this.checkIsActiveAccount());
        el.get('account')?.updateValueAndValidity();
      }
    });

    this.getDepositAcocunt();
    this.getDepositAccountSelector();
    this.formSubmit();
  }

  formSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.listParameter?.markAllAsTouched();
        }),
        switchMap(() => {
          const arrayForm = Object.keys(this.listParameter);
          return forkJoin([
            this.editForm.statusChanges.pipe(
              startWith(this.editForm.status),
              filter((status) => status !== AbstractControlStatus.PENDING),
              take(1),
            ),
            ...arrayForm.map((i) => {
              return this.listParameter.statusChanges.pipe(
                startWith(this.listParameter.status),
                filter((status) => status !== AbstractControlStatus.PENDING),
                take(1),
              );
            }),
          ]);
        }),
        filter(
          (status) =>
            status.filter((i) => i === AbstractControlStatus.VALID || i === AbstractControlStatus.DISABLED).length ===
            status.length,
        ),
      )
      .subscribe(() => {
        this.onSave();
      });
  }

  getDepositAcocunt() {
    let request: GetDepositAccountsRequest = {
      targetId: this.data?.memberId,
      paymentInstructionId: this.data?.paymentInstructionId,
    };

    this.store.dispatch(getDepositAccountsAction({ request }));
  }

  getDepositAccountSelector() {
    this.store.pipe(select(getDepositAccountsState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success && !state?.isLoading) {
        this.listAccountActive = (state?.payload?.depositAccounts ?? [])?.map((item: any) => ({
          ...item,
          id: item?.id,
          depositAccountId: item?.depositAccountId,
          displayValue:
            ' - ' + item.bankName + ' - ' + (item?.accountType === BankAccountType.Checking ? 'Checking' : 'Savings'),
          value: item.id,
          maskedAccountNumber:
            item?.accountNumber && item?.accountNumber?.length > 4
              ? this.getMaskedFormat((item?.accountNumber?.length ?? 0) - 4)
              : '',
          accountNumberNotMasked:
            item?.accountNumber && item?.accountNumber?.length > 4
              ? item?.accountNumber?.slice(item?.accountNumber?.length - 4, item?.accountNumber?.length)
              : item?.accountNumber,
          isActive: item.isActive,
          displayValueStyle: { color: '#828282' },
        }));

        this.data?.payeePaymentTable.forEach((item: any) => {
          this.listParameter?.controls.forEach((el) => {
            const currentAccount = this.listAccountActive?.find((el: any) => el.id === item?.depositAccount?.id);
            this.listAccountActive =
              this.listAccountActive?.filter((el) => el.id === currentAccount?.id || el.isActive) ?? [];
            el.get('listAccountOptions')?.setValue(this.listAccountActive);
          });
        });

        this.isLoadingAccount = false;
      }
    });
  }

  getMaskedFormat(length?: number) {
    let format = '';
    if (length! < 0) {
      return;
    }
    for (let index = 0; index < length!; index++) {
      format = format + 'X';
    }
    return format;
  }

  onSave() {
    if (!this.editForm?.valid || !this.listParameter.valid) {
      return;
    }

    let request: SetPayeePaymentRequest = this.getPayeePaymentValue();

    this.store.dispatch(setPayeePaymentAction({ request }));
    this.dialogRef.close(true);
  }

  checkIsActiveAccount(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let request: any = {
        id: control?.value,
      };

      return this.payeePaymentService.checkIsActiveAccount(request).pipe(
        map((response: any) => {
          if (!response?.exists) {
            return { invalidValue: `The selected Account # is disabled, please select another Account #.` };
          }
          return null;
        }),
        catchError((err) => {
          return of(null);
        }),
      );
    };
  }

  getPayeePaymentValue(): SetPayeePaymentRequest {
    const rawData = this.listParameter.getRawValue();

    let request: SetPayeePaymentRequest = {
      splitPaymentType: SplitPaymentType.Percent,
      payments: rawData?.map((item: any) => {
        const currentAccountValue = item?.listAccountOptions?.find((el: any) => el?.id === item.account);
        return {
          paymentId: item.id,
          bankId: currentAccountValue?.bankId,
          depositAccountId: currentAccountValue?.id,
          // amount: item?.amount,
          paymentType: item?.paymentMethod,
          percentage: item?.percentage ?? 100,
        };
      }),
      paymentInstructionId: this.data.paymentInstructionId,
    };

    return request;
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close(false);
      }
    });
  }

  onChangePaymentMethod(index: number) {
    const currentFormControl = this.listParameter.at(index);
    if (currentFormControl?.get('paymentMethod')?.value === PayeePaymentType['Direct Deposit']) {
      currentFormControl?.get('account')?.addValidators(Validators.required);
      currentFormControl?.get('account')?.addAsyncValidators(this.checkIsActiveAccount());
      currentFormControl?.get('account')?.setErrors({ required: true });
    } else {
      currentFormControl?.get('account')?.clearValidators();
      currentFormControl?.get('account')?.clearAsyncValidators();
      currentFormControl?.get('account')?.setErrors(null);
    }
    currentFormControl?.get('account')?.setValue('');
    currentFormControl?.get('account')?.updateValueAndValidity();
  }

  onFocusOut(index: number) {
    const currentFormControl = this.listParameter.at(index);
    if (currentFormControl?.get('account')?.value) {
      return;
    }
  }

  onClickDdl(index: number) {
    const currentFormControl = this.listParameter.at(index);
    const currentAccount = this.listAccountActive?.filter(
      (el: any) => el.id === currentFormControl?.get('account')?.value,
    );
    if (currentAccount && !currentAccount[0]?.isActive) {
      this.onChangePaymentMethod(index);
    } else {
      currentFormControl?.get('account')?.addValidators(Validators.required);
      currentFormControl?.get('account')?.updateValueAndValidity({ emitEvent: false });
    }

    currentFormControl?.get('listAccountOptions')?.setValue(this.listAccountActive);
    currentFormControl?.get('listAccountOptions')?.updateValueAndValidity();
    this.isLoadingAccount = false;
  }
}
