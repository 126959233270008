import { createSelector } from '@ngrx/store';
import { selectAccidentParticipantModuleState } from './selector';
export const accidentSelector = createSelector(
  selectAccidentParticipantModuleState,
  (state) => state?.accident
);

export const getAccidentClaimsSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentClaims
);

export const getAccidentHistoriesSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentHistories
);

export const removeAccidentHistorySelector = createSelector(
  accidentSelector,
  (state) => state?.accidentHistory
);

export const createAccidentClaimsSelector = createSelector(
  accidentSelector,
  (state) => state?.createAccidentClaimState
);

export const editAccidentClaimsSelector = createSelector(
  accidentSelector,
  (state) => state?.editAccidentClaimState
);
export const removeAccidentClaimSelector = createSelector(
  accidentSelector,
  (state) => state?.accidentClaim
);

export const getMunicipalitySelector = createSelector(
  accidentSelector,
  (state) => state?.getMunicipality
);

export const addAccidentPostCardSelector = createSelector(
  accidentSelector,
  (state) => state.accidentPostCardState
);

export const editAccidentPostCardSelector = createSelector(
  accidentSelector,
  (state) => state.accidentPostCardState
);

export const getVendorsSelector = createSelector(
  accidentSelector,
  (state) => state?.getVendorsMasterData
);

export const getDisbursementReportsSelector = createSelector(
  accidentSelector,
  (state) => state?.getDisbursementReports
);
export const getAccidentClaimDetailSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentClaimDetail
);
export const viewAccidentDetailsSelector = createSelector(
  accidentSelector,
  (state) => state?.viewAccidentDetails
);

export const addAccidentReportSelector = createSelector(
  accidentSelector,
  (state) => state.accidentReportState
);

export const getAccidentDocumentsSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentDocuments
);

export const uploadAccidentDocumentsSelector = createSelector(
  accidentSelector,
  (state) => state?.uploadAccidentDocuments
);

export const getAccidentDocumentDownloadSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentDocumentDownload
);

export const getAccidentPostcardLetterSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentPostcardLetter
);

export const getAccidentReportLetterSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentReportLetter
);

export const getAccidentClaimsReportSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentClaimsReport
);

export const createAccidentNoteSelector = createSelector(
  accidentSelector,
  (state) => state.accidentNote
);

export const getAccidentNotesSelector = createSelector(
  accidentSelector,
  (state) => state?.getAccidentNotes
);

export const editAccidentReportSelector = createSelector(
  accidentSelector,
  (state) => state.editAccidentReportState
);

export const editParticipantAccidentStatusSelector = createSelector(
  accidentSelector,
  (state) => state.editParticipantAccidentStatusState
);

export const removeAccidentDocumentSelector = createSelector(
  accidentSelector,
  (state) => state.removeAccidentDocumentState
);

export const editAccidentDocumentSelector = createSelector(
  accidentSelector,
  (state) => state.editAccidentDocumentState
);
