<mat-radio-group *ngIf="!isObject; else objectOption" [formControl]="controlField" class="{{ classInput }}">
  <ng-container *ngIf="!isObject; else objectOption">
    <mat-radio-button (change)="onChangeValue($event)" *ngFor="let option of $any(listOption)"
                      [value]="option">{{option}}</mat-radio-button>
  </ng-container>
</mat-radio-group>

<ng-template #objectOption>
  <mat-radio-group [formControl]="controlField" class="{{ classInput }}">
    <ng-container *ngIf="isCustomChangeValue">
      <ng-container *ngFor="let option of $any(listOption)">
        <span class="option-value" (click)="onChangeValueOption(option)"  [ngClass]="{'disable-option': option.value === 0 ,'disable-all': isDisabled }">
          <mat-radio-button
          [value]="option.value">{{ option.label }}</mat-radio-button>
        </span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isCustomChangeValue">
      <mat-radio-button (change)="onChangeValue($event)" *ngFor="let option of $any(listOption)"
                        [value]="option.value">{{ option.label }}</mat-radio-button>
    </ng-container>
  </mat-radio-group>
</ng-template>
