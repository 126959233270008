<div class="detail__benefit-qdro">
  <div class="detail__benefit-qdro--header flex justify-between p-3">
    <span class="text-xl font-bold leading-7">{{ qdroLabelName }} Benefit Information</span>
  </div>
  <ptg-grid
    [data]="retirementBenefitQDRO"
    [columns]="columnBenefitQDRO"
    [totalRecords]="totalBenefitQDRO"
    [isLoading]="isQDROLoading"
    [pageSize]="pageQDROSize"
    [pageNumber]="pageQDROIndex"
    notFoundMessage="No {{ qdroLabelName }} Benefit Information to Display"
    (pageChange)="onChangeQDROPage($event)"
  >
    <ng-template cellContent columnName="benefitBeginDate" let-row>
      <div class="flex">
        {{ (row.benefitBeginDate | date: 'MM/dd/yyyy') || '' }}
      </div>
    </ng-template>
    <ng-template cellContent columnName="qdroBenefitAmount" let-row>
      <div class="flex">
        {{ (row?.qdroBenefitAmount | numberLocalDecimal: '$') || '-' }}
      </div>
    </ng-template>
    <ng-template cellContent columnName="status" let-row>
      <div class="flex">
        {{ CalculationBenefitHistoryStatus[row?.status] }}
      </div>
    </ng-template>
  </ptg-grid>
</div>
