import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Align, Column, Row } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { ACTION, SortType } from '@ptg-shared/constance/value.const';
import { DetailDisplay } from '@ptg-shared/types/models/detail-display.model';
import { DataType } from '@ptg-shared/constance/data-type.const';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination/constants';
import { PageEvent } from '@ptg-shared/controls/pagination/types/page-event.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import * as fromReducer from '@ptg-reducers/index';

import { AddBenefitTypeComponent } from '../../components/add-benefit-type/add-benefit-type.component';
import { EditBenefitComponent } from '../../components/edit-benefit/edit-benefit.component';
import { DefinedBenefitActions } from '../../store/actions';
import {
  Benefit,
  BenefitType,
  GetBenefitTypesRequest,
} from '../../types/models';
import * as fromMember from '../../store/reducers';
import { CalculationState, getCalculationQDROSelector } from '@ptg-member/features/calculation/store';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';

const PAGE_SIZE_CONST = '-ptg-defined-benefits-pageSize'

@Component({
  selector: 'ptg-defined-benefits',
  templateUrl: './defined-benefits.component.html',
  styleUrls: ['./defined-benefits.component.scss'],
})
export class DefinedBenefitsComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Benefit Eligible Participant',
      url: '/member/benefit',
    },
    {
      name: 'Defined Benefits',
      url: '',
    },
  ];

  message = '';
  bannerType: BannerType = BannerType.Hidden;

  propertyDisplayed: DetailDisplay[] = [
    {
      label: 'Minimum Benefit Amount',
      propertyName: 'minimumAmount',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Relationship Type Lookup Table',
      propertyName: 'lookupTable',
      type: DataType.TYPE_TEXT,
    },
    {
      label: '{qdroLabelName}',
      propertyName: 'isActiveQDRO',
      type: DataType.TYPE_BOOLEAN,
    },
    {
      label: '{qdroLabelName} Benefit',
      propertyName: 'qdroBenefitTypeName',
      type: DataType.TYPE_TEXT,
    },
    {
      label: '{qdroLabelName} Metada Section',
      propertyName: 'qdroMetadataSectionName',
      type: DataType.TYPE_TEXT,
    },
  ];
  propertyDisplayedNotShowQDRO: DetailDisplay[] = [
    {
      label: 'Minimum Benefit Amount',
      propertyName: 'minimumAmount',
      type: DataType.TYPE_CURRENCY,
    },
    {
      label: 'Relationship Type Lookup Table',
      propertyName: 'lookupTable',
      type: DataType.TYPE_TEXT,
    },
    {
      label: '{qdroLabelName}',
      propertyName: 'isActiveQDRO',
      type: DataType.TYPE_BOOLEAN,
    },
  ];
  benefit?: Benefit;

  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Benefit Type',
      },
      width: '14%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'code',
      header: {
        title: 'Code',
      },
      width: '14%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'statusName',
      header: {
        title: 'Status',
      },
      width: '14%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'eventName',
      header: {
        title: 'Status Event',
      },
      width: '14%',
      sortable: true,
    },
    {
      name: 'firstParentTypeCode',
      header: {
        title: 'Parent Type',
      },
      width: '14%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'totalParticipant',
      header: {
        title: 'Total Participants',
      },
      width: '14%',
      sortable: true,
      align: Align.Right,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
    },
  ];
  benefitTypesData: (BenefitType & Row)[] = [];
  isLoading: boolean = false;
  errorMsg?: string;
  sortInfo?: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  totalRecords: number = 0;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentFund: any = {};
  qdroLabelName: string = '';

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));

      });
    // Listen for get Benefit
    this.memberStore
      .pipe(
        select(fromMember.selectGetBenefitState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state.isLoading && state.success) {
          this.benefit = state.payload;
        }
      });

    // Listen for update benefit result
    this.memberStore
      .pipe(
        select(fromMember.selectUpdateBenefitState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.memberStore.dispatch(DefinedBenefitActions.getBenefit());
            this.getBenefitTypes(true);
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Benefits', ACTION.EDIT);
          this.store.dispatch(DefinedBenefitActions.clearGetBenefitState());
        }
      });

    // Listen for get benefit types
    this.memberStore
      .pipe(
        select(fromMember.selectGetBenefitTypesState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          this.isLoading = state.isLoading;

          if (!state.isLoading && state.success) {
            this.benefitTypesData = state.payload || [];
            this.totalRecords = state.total || 0;
          }
        }
      });

    // Listen for add benefit type result
    this.memberStore
      .pipe(
        select(fromMember.selectAddBenefitTypeState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.getBenefitTypes(true);
            this.memberStore.dispatch(DefinedBenefitActions.getBenefit());
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Benefit Type', ACTION.ADD);
          this.store.dispatch(DefinedBenefitActions.clearGetBenefitState());
        }
      });

    // Listen for update benefit type result
    this.memberStore
      .pipe(
        select(fromMember.selectUpdateBenefitTypeState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.getBenefitTypes(true);
            this.memberStore.dispatch(DefinedBenefitActions.getBenefit());
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Benefit Type', ACTION.EDIT);
          this.store.dispatch(
            DefinedBenefitActions.clearGetBenefitTypesState()
          );
        }
      });

    // Listen for remove benefit type result
    this.memberStore
      .pipe(
        select(fromMember.selectRemoveBenefitTypeState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.getBenefitTypes(true);
            this.memberStore.dispatch(DefinedBenefitActions.getBenefit());
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, 'Benefit Type', ACTION.REMOVE);
          this.store.dispatch(
            DefinedBenefitActions.clearGetBenefitTypesState()
          );
        }
      });
    this.selectCalculationQDRO();

    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.memberStore.dispatch(DefinedBenefitActions.getBenefit());
    this.getBenefitTypes();
  }

  getBenefitTypes(jumpToFirst?: boolean): void {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }

    let request: GetBenefitTypesRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };

    if (this.sortInfo) {
      const field =
        this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType:
          this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.memberStore.dispatch(
      DefinedBenefitActions.getBenefitTypes({ request })
    );
  }
  onClickNewBenefitType(): void {
    this.dialog.open(AddBenefitTypeComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
    });
  }

  onClickEditBenefitType(row: BenefitType & Row): void {
    this.dialog.open(AddBenefitTypeComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        isEdit: true,
        benefitType: row,
      },
    });
  }

  onClickRemoveBenefitType(row: BenefitType & Row): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Item',
        text:
          `Are you sure you want to remove this Benefit Type: ` +
          row.code +
          ` - ` +
          row.name +
          ` ?`,
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          DefinedBenefitActions.removeBenefitType({ benefitTypeId: row.id })
        );
      }
    });
  }

  onChangePage(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getBenefitTypes();
  }

  onChangeSort(event: Sort): void {
    this.sortInfo = event;
  }

  onClickConfigureBenefit(): void {
    this.dialog.open(EditBenefitComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: { benefit: this.benefit, qdroLabelName: this.qdroLabelName },
    });
  }

  private replaceQdroLabelProperty(properties: DetailDisplay[], _qdroLabelName: string) {
     return properties.map((property) => {
      return {
        ...property,
        label: property.label.replace(
          '{qdroLabelName}',
          _qdroLabelName,
        )
      }
    });
  }

  private selectCalculationQDRO() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
          this.propertyDisplayed = this.replaceQdroLabelProperty(this.propertyDisplayed, this.qdroLabelName);
          this.propertyDisplayedNotShowQDRO =
            this.replaceQdroLabelProperty(this.propertyDisplayedNotShowQDRO, this.qdroLabelName);
        }
      });
  }
}
