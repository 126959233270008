import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { downloadFile } from '@ptg-shared/utils/common.util';

import { MemberListActions } from '../../store/actions';
import {
  ColumnConfig,
  ColumnSetConfig,
  ColumnSetConfigRequest,
  ColumnSetPropertyDisplayConfig,
  GetCardSelectionResponse,
  ListSectionProperty,
  MemberList,
  MemberListQuery,
  PropertyDisplayResponse,
} from '../../types/models';
import { MemberListService } from '../../services/member-list.service';

@Injectable()
export class MemberListEffects {
  getMemberList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getMemberList),
      switchMap(({ query, idClient }) => {
        return this.memberListService
          .memberList(query as MemberListQuery, idClient)
          .pipe(
            map((memberList: MemberList) => {
              return MemberListActions.getMemberListSuccess({ memberList });
            }),
            catchError((error) => {
              return of(MemberListActions.getMemberListFailure({ error }));
            })
          );
      })
    )
  );

  removeMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.removeMember),
      switchMap(({ id, memberKey }) => {
        return this.memberListService.removeMember(id).pipe(
          map(() => {
            return MemberListActions.removeMemberSuccess({ memberKey });
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberListActions.removeMemberFailure({
                id,
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  lockMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.lockMember),
      switchMap(({ id }) => {
        return this.memberListService.lockMember(id).pipe(
          map(() => {
            return MemberListActions.lockMemberSuccess({ id });
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberListActions.lockMemberFailure({ id, errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  unlockMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.unlockMember),
      switchMap(({ id }) => {
        return this.memberListService.unlockMember(id).pipe(
          map(() => {
            return MemberListActions.unlockMemberSuccess({ id });
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(
              MemberListActions.unlockMemberFailure({
                id,
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  getFilterByPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getFilterByPropertyListRequest),
      switchMap(() => {
        return this.memberListService.getFilterByPropertyList().pipe(
          map((res: ListSectionProperty) => {
            return MemberListActions.getFilterByPropertyListSuccess({
              listSectionProperty: res.listSectionProperty,
            });
          }),
          catchError((err) => {
            return of(
              MemberListActions.getFilterByPropertyListFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  createMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.createMemberFilterRequest),
      switchMap(({ body }) => {
        return this.memberListService.createMemberFilter(body).pipe(
          map((id) => {
            this.memberListService.currentFilter = {
              id: id,
              name: body.name,
            };
            return MemberListActions.createMemberFilterSuccess();
          }),
          catchError((err) => {
            console.log('---------Error-----------------');
            console.log(err);
            return of(MemberListActions.createMemberFilterFailure());
          })
        );
      })
    )
  );

  getMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getMemberFilterRequest),
      switchMap(() => {
        return this.memberListService.getMemberFilter().pipe(
          map((filterInformationList) => {
            return MemberListActions.getMemberFilterSuccess({
              listFilterInfo: filterInformationList.listFilterInfo,
            });
          }),
          catchError(() => {
            return of(MemberListActions.getMemberFilterFailure());
          })
        );
      })
    )
  );

  updateFilterName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.updateFilterNameRequest),
      switchMap(({ memberFilterName }) => {
        return this.memberListService.updateFilterName(memberFilterName).pipe(
          map(() => {
            if (
              memberFilterName.id === this.memberListService.currentFilter?.id
            ) {
              this.memberListService.currentFilter = {
                id: memberFilterName.id as any,
                name: memberFilterName.name,
              };
            }
            return MemberListActions.updateFilterNameSuccess({
              id: memberFilterName.id || '',
              listFilterInfo: memberFilterName.listFilterInfo,
            });
          }),
          catchError(() => {
            return of(
              MemberListActions.updateFilterNameFailure({
                id: memberFilterName.id || '',
                listFilterInfo: memberFilterName.listFilterInfo,
              })
            );
          })
        );
      })
    )
  );

  removeMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.removeMemberFilterRequest),
      switchMap(({ id }) => {
        return this.memberListService.removeMemberFilter(id).pipe(
          map(() => {
            return MemberListActions.removeMemberFilterSuccess({ id });
          }),
          catchError(() => {
            return of(MemberListActions.removeMemberFilterFailure());
          })
        );
      })
    )
  );

  exportMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.exportMembersRequest),
      switchMap(({ request }) => {
        return this.memberListService.exportMemberList(request).pipe(
          map((data: any) => {
            if (data?.FileName) {
              return MemberListActions.downloadMembersRequest({
                fileName: data.FileName,
              });
            } else {
              return MemberListActions.exportMembersRequestFailure();
            }
          }),
          catchError(() => {
            return of(MemberListActions.exportMembersRequestFailure());
          })
        );
      })
    )
  );

  downloadMemberFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.downloadMembersRequest),
      delay(2500),
      switchMap(({ fileName }) => {
        return this.memberListService.downloadExportFile(fileName).pipe(
          map((res: Blob) => {
            if (res) {
              downloadFile.call(this, res, fileName);
              return MemberListActions.downloadMembersRequestSuccess();
            } else {
              return MemberListActions.downloadMembersRequest({ fileName });
            }
          }),
          catchError(() => {
            return of(MemberListActions.downloadMembersRequestFailure());
          })
        );
      })
    )
  );

  getEmployerDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getEmployerDetailRequest),
      switchMap(({ employerId, clientId }) => {
        return this.memberListService
          .getEmployerDetail(employerId, clientId)
          .pipe(
            map((employer) => {
              return MemberListActions.getEmployerDetailSuccess({ employer });
            }),
            catchError((error) => {
              return of(MemberListActions.getEmployerDetailFailure());
            })
          );
      })
    )
  );

  getListColumnSetConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getListColumnSetConfigurationRequest),
      switchMap(({ IsDragDrop }) => {
        return this.memberListService
          .getColumnSetConfiguration(IsDragDrop)
          .pipe(
            map((listColumnConfig: ColumnSetConfig) => {
              return MemberListActions.getListColumnSetConfigurationSuccess({ listColumnConfig });
            }),
            catchError((error) => {
              return of(MemberListActions.getListColumnSetConfigurationFailure());
            })
          );
      })
    )
  );

  createColumnSetConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.createColumnSetConfigurationRequest),
      switchMap(({ body, entityId }) => {
        return this.memberListService
          .createColumnSetConfiguration(body, entityId)
          .pipe(
            map((id: string) => {
              return MemberListActions.createColumnSetConfigurationSuccess({id});
            }),
            catchError((error) => {
              return of(MemberListActions.createColumnSetConfigurationFailure({ error: error.message }));
            })
          );
      })
    )
  );
  reorderColumnSetConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.reorderColumnSet),
      switchMap(({ body }) => {
        return this.memberListService
          .reorderColumnSetConfiguration(body)
          .pipe(
            map(() => {
              return MemberListActions.reorderColumnSetSuccess();
            }),
            catchError((error) => {
              return of(MemberListActions.reorderColumnSetFailure({ error: error.message }));
            })
          );
      })
    )
  );
  removeColumnSetConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.removeColumnSetConfigurationRequest),
      switchMap(({ id }) => {
        return this.memberListService.removeColumnSet(id).pipe(
          map(() => {
            return MemberListActions.removeColumnSetConfigurationSuccess({id});
          }),
          catchError(() => {
            return of(MemberListActions.removeColumnSetConfigurationFailure());
          })
        );
      })
    )
  );

  editColumnSetConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.editColumnSetConfigurationRequest),
      switchMap(({ id, body }) => {
        return this.memberListService
          .editColumnSetConfiguration(id,body)
          .pipe(
            map(() => {
              return MemberListActions.editColumnSetConfigurationSuccess({id});
            }),
            catchError((error) => {
              return of(MemberListActions.editColumnSetConfigurationFailure({ error: error.message }));
            })
          );
      })
    )
  );
  getMemberListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getMemberListConfiguration),
      switchMap(({ query, entityId }) => {
        return this.memberListService
          .getMemberListEntity(query , entityId )
          .pipe(
            map((memberList: MemberList) => {
              return MemberListActions.getMemberListConfigurationSuccess({ memberList });
            }),
            catchError((error) => {
              return of(MemberListActions.getMemberListConfigurationFailure({ error }));
            })
          );
      })
    )
  );

  getListColumnSetConfigurationEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getListColumnSetConfigurationEntityRequest),
      switchMap(({ IsDragDrop, entityId }) => {
        return this.memberListService
          .getColumnSetConfigurationEntity(IsDragDrop, entityId)
          .pipe(
            map((listColumnConfig: ColumnSetConfig) => {
              return MemberListActions.getListColumnSetConfigurationEntitySuccess({ listColumnConfig });
            }),
            catchError((error) => {
              return of(MemberListActions.getListColumnSetConfigurationEntityFailure({ error }));
            })
          );
      })
    )
  );
  getPropertyOfParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getPropertyOfParticipantsRequestRequest),
      switchMap(({ entityId, query }) => {
        return this.memberListService
          .getPropertyOfParticipants(entityId, query)
          .pipe(
            map((propertyOfParticipants: PropertyDisplayResponse) => {
              return MemberListActions.getPropertyOfParticipantsRequestSuccess({ propertyOfParticipants });
            }),
            catchError((error) => {
              return of(MemberListActions.getPropertyOfParticipantsRequestFailure({ error }));
            })
          );
      })
    )
  );

  getCardSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getCardSelectionRequest),
      switchMap(({query}) => {
        return this.memberListService
          .getCardSelection(query)
          .pipe(
            map((response: GetCardSelectionResponse) => {
              return MemberListActions.getCardSelectionRequestSuccess({ response });
            }),
            catchError((error) => {
              return of(MemberListActions.getCardSelectionRequestFailure({ error }));
            })
          );
      })
    )
  );

  getPropertyByCardSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MemberListActions.getPropertyByCardSelectionRequest),
      switchMap(({ cardId, screenId }) => {
        return this.memberListService
          .getPropertyByCardSelection(cardId, screenId)
          .pipe(
            map((response: any) => {
              return MemberListActions.getPropertyByCardSelectionSuccess({ response });
            }),
            catchError((error) => {
              return of(MemberListActions.getPropertyByCardSelectionFailure({ error }));
            })
          );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private memberListService: MemberListService
  ) {}
}
