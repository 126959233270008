import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';


export const editDeductionsSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.editDeductions
);

export const getTypesOfDeductionsSelector = createSelector(
  editDeductionsSelector,
  (state) => state?.getTypesOfDeductions
);

export const setDeductionsSelector = createSelector(
  editDeductionsSelector,
  (state) => state?.setDeductions
);
