import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import * as StatusActions from '../../store/actions/status.actions';
import { MemberStatusDetail } from '../../types/models';
import * as fromMember from '../../store/reducers';
import { StatusService } from '../../services/status.service';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss'],
})
export class EditStatusComponent implements OnInit {
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;
  
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();
  iconNames: string[] = ['check_circle', 'gpp_good', 'do_not_disturb_on', 'paid', 'accessible', 'badge', 'cancel'];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public statusDetail: MemberStatusDetail | null,
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public statusService: StatusService,
  ) {}

  ngOnInit(): void {
    this.initFormGroup(this.statusDetail || ({} as MemberStatusDetail));
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup(formData: MemberStatusDetail) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData.id),
      name: this.fb.control(
        formData.name,
        [Validators.required, Validators.maxLength(150)],
        checkApiValidator(this.statusService.checkExistStatus, 'name', formData.name, {
          params: {
            id: formData.id,
            memberStatusId: formData.id,
          },
        }),
      ),
      color: this.fb.control(formData.color || '#7b4200', [Validators.required, Validators.maxLength(7)]),
      iconName: this.fb.control(formData.iconName || '', [Validators.required]),
      active: this.fb.control(formData.active ?? true),
    });
  }

  onSubmit() {
    const statusDetail = JSON.parse(JSON.stringify(this.editForm.value as MemberStatusDetail));
    if (statusDetail.id) {
      this.memberStore.dispatch(StatusActions.editStatus({ statusDetail }));
    } else {
      this.memberStore.dispatch(StatusActions.createStatus({ statusDetail }));
    }
    this.dialogRef.close();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  changeIcon(iconName: string) {
    this.editForm.get('iconName')?.setValue(iconName);
  }
}
