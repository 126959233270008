import { createAction, props } from '@ngrx/store';

import {
  CreateTransactionBody,
  CreateValidateServiceHistoryRecordBeginDateTransactionRequest,
  CreateValidateServiceHistoryRecordBeginDateTransactionResponse,
  EditMunicipalityServiceHistoryRequest,
  GetListStatusHistoryQuery,
  Municipality,
  MunicipalityServiceRecordList,
  MunicipalityServiceRecordListEntity,
  ValidateRemoveServiceHistoryResponse,
  ValidateServiceHistoryRecordTerminatedMemberRequest,
  ValidateServiceHistoryRecordTerminatedMemberResponse,
  ValidateTerminatedMemberBody,
  ValidateTerminatedMemberResponse,
} from '../../types/models';

export const MunicipalityServiceHistoryListRequest =
  '[MunicipalityServiceHistoryList/API] Send Request';
export const MunicipalityServiceHistoryListSuccess =
  '[MunicipalityServiceHistoryList/API] Success';
export const MunicipalityServiceHistoryListFailure =
  '[MunicipalityServiceHistoryList/API] Failure';

export const MunicipalityServiceHistoryEntityListRequest =
  '[MunicipalityServiceHistoryEntityList/API] Send Request';
export const MunicipalityServiceHistoryEntityListSuccess =
  '[MunicipalityServiceHistoryEntityList/API] Success';
export const MunicipalityServiceHistoryEntityListFailure =
  '[MunicipalityServiceHistoryEntityList/API] Failure';

export const MunicipalityListRequest = '[MunicipalityList/API] Send Request';
export const MunicipalityListSuccess = '[MunicipalityList/API] Success';
export const MunicipalityListFailure = '[MunicipalityList/API] Failure';
export const ClearMunicipalityListState = '[MunicipalityList] Clear';

export const CreateMemberMunicipalityServiceHistoryRequest =
  '[CreateMemberMunicipalityServiceHistory/API] Send Request';
export const CreateMemberMunicipalityServiceHistorySuccess =
  '[CreateMemberMunicipalityServiceHistory/API] Success';
export const CreateMemberMunicipalityServiceHistoryFailure =
  '[CreateMemberMunicipalityServiceHistory/API] Failure';
export const EditMemberMunicipalityServiceHistoryRequest =
  '[EditMemberMunicipalityServiceHistory/API] Send Request';
export const EditMemberMunicipalityServiceHistorySuccess =
  '[EditMemberMunicipalityServiceHistory/API] Success';
export const EditMemberMunicipalityServiceHistoryFailure =
  '[EditMemberMunicipalityServiceHistory/API] Failure';
export const ClearMunicipalityServiceHistoryState =
  '[ClearMunicipalityServiceHistoryState]';

export const ValidateTerminatedMemberRequest = '[ValidateTerminatedMember/API] Send Request';
export const ValidateTerminatedMemberSuccess = '[ValidateTerminatedMember/API] Success';
export const ValidateTerminatedMemberFailure = '[ValidateTerminatedMember/API] Failure';

export const CreateTransactionRequest = '[CreateTransaction/API] Send Request';
export const CreateTransactionSuccess = '[CreateTransaction/API] Success';
export const CreateTransactionFailure = '[CreateTransaction/API] Failure';

export const ValidateRemoveServiceHistoryRequest = '[ValidateRemoveServiceHistory/API] Send Request';
export const ValidateRemoveServiceHistorySuccess = '[ValidateRemoveServiceHistory/API] Success';
export const ValidateRemoveServiceHistoryFailure = '[ValidateRemoveServiceHistory/API] Failure';
export const ClearValidateRemoveServiceHistoryFailure = '[ValidateRemoveServiceHistory/API] Clear';

export const RemoveServiceHistoryAction = '[RemoveServiceHistory/API] Send Request';
export const RemoveServiceHistorySuccess = '[RemoveServiceHistory/API] Success';
export const RemoveServiceHistoryFailure = '[RemoveServiceHistory/API] Failure';
export const ClearRemoveServiceHistoryFailure = '[RemoveServiceHistory/API] Clear';



export const getMunicipalityServiceHistoryList = createAction(
  MunicipalityServiceHistoryListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const municipalityServiceHistoryListSuccess = createAction(
  MunicipalityServiceHistoryListSuccess,
  props<{ serviceRecords: MunicipalityServiceRecordList }>()
);
export const municipalityServiceHistoryListFailure = createAction(
  MunicipalityServiceHistoryListFailure
);

export const getMunicipalityServiceHistoryEntityList = createAction(
  MunicipalityServiceHistoryEntityListRequest,
  props<{ query: any, memberId: string }>()
);
export const municipalityServiceHistoryEntityListSuccess = createAction(
  MunicipalityServiceHistoryEntityListSuccess,
  props<{ serviceRecordsEntity: MunicipalityServiceRecordListEntity }>()
);
export const municipalityServiceHistoryEntityListFailure = createAction(
  MunicipalityServiceHistoryEntityListFailure
);

export const getMunicipalityList = createAction(MunicipalityListRequest);
export const getMunicipalityListSuccess = createAction(
  MunicipalityListSuccess,
  props<{ listMunicipality: Municipality[] }>()
);
export const getMunicipalityListFailure = createAction(MunicipalityListFailure);
export const clearMunicipalityListState = createAction(ClearMunicipalityListState);

export const createMemberMunicipalityServiceHistoryRequest = createAction(
  CreateMemberMunicipalityServiceHistoryRequest,
  props<{ body: EditMunicipalityServiceHistoryRequest }>()
);
export const createMemberMunicipalityServiceHistorySuccess = createAction(
  CreateMemberMunicipalityServiceHistorySuccess
);
export const createMemberMunicipalityServiceHistoryFailure = createAction(
  CreateMemberMunicipalityServiceHistoryFailure,
  props<{ errorMessage: string }>()
);
export const editMemberMunicipalityServiceHistoryRequest = createAction(
  EditMemberMunicipalityServiceHistoryRequest,
  props<{ body: EditMunicipalityServiceHistoryRequest }>()
);
export const editMemberMunicipalityServiceHistorySuccess = createAction(
  EditMemberMunicipalityServiceHistorySuccess
);
export const editMemberMunicipalityServiceHistoryFailure = createAction(
  EditMemberMunicipalityServiceHistoryFailure,
  props<{ errorMessage: string }>()
);
export const clearMunicipalityServiceHistoryState = createAction(
  ClearMunicipalityServiceHistoryState
);

export const validateTerminatedMemberRequest = createAction(
  ValidateTerminatedMemberRequest,
  props<{ body: ValidateTerminatedMemberBody }>()
);
export const validateTerminatedMemberSuccess = createAction(
  ValidateTerminatedMemberSuccess,
  props<{ validateTerminatedMember: ValidateTerminatedMemberResponse }>()
);
export const validateTerminatedMemberFailure = createAction(
  ValidateTerminatedMemberFailure
);

export const createTransactionRequest = createAction(
  CreateTransactionRequest,
  props<{ body: CreateTransactionBody }>()
);
export const createTransactionSuccess = createAction(
  CreateTransactionSuccess
);
export const createTransactionFailure = createAction(
  CreateTransactionFailure
);

export const validateServiceHistoryRecordTerminatedMemberAction = createAction(
  '[ValidateServiceHistoryRecordTerminatedMember/API] Send Request',
  props<{ request: ValidateServiceHistoryRecordTerminatedMemberRequest }>()
);
export const validateServiceHistoryRecordTerminatedMemberSuccessAction = createAction(
  '[ValidateServiceHistoryRecordTerminatedMember/API] Success',
  props<{ response: ValidateServiceHistoryRecordTerminatedMemberResponse }>()
);
export const validateServiceHistoryRecordTerminatedMemberFailureAction = createAction(
  '[ValidateServiceHistoryRecordTerminatedMember/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearValidateServiceHistoryRecordTerminatedMemberStateAction = createAction(
  '[ValidateServiceHistoryRecordTerminatedMember] Clear'
);

export const createValidateServiceHistoryRecordBeginDateTransactionAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction/API] Send Request',
  props<{ request: CreateValidateServiceHistoryRecordBeginDateTransactionRequest }>()
);
export const createValidateServiceHistoryRecordBeginDateTransactionSuccessAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction/API] Success',
  props<{ response: CreateValidateServiceHistoryRecordBeginDateTransactionResponse }>()
);
export const createValidateServiceHistoryRecordBeginDateTransactionFailureAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearCreateValidateServiceHistoryRecordBeginDateTransactionStateAction = createAction(
  '[CreateValidateServiceHistoryRecordBeginDateTransaction] Clear'
);
//validate remove service history
export const validateRemoveServiceHistoryRequest = createAction(
  ValidateRemoveServiceHistoryRequest,
  props<{ id: string }>()
);
export const validateRemoveServiceHistorySuccess = createAction(
  ValidateRemoveServiceHistorySuccess, props<{response: ValidateRemoveServiceHistoryResponse}>()
);
export const validateRemoveServiceHistoryFailure = createAction(
  ValidateRemoveServiceHistoryFailure
);
export const clearValidateRemoveServiceHistoryFailure = createAction(
  ClearValidateRemoveServiceHistoryFailure
);

//remove service history
export const removeServiceHistoryAction = createAction(
  RemoveServiceHistoryAction,
  props<{ body: any }>()
);
export const removeServiceHistorySuccess = createAction(
  RemoveServiceHistorySuccess
);
export const removeServiceHistoryFailure = createAction(
  RemoveServiceHistoryFailure, props<{ error?: any }>()
);
export const clearRemoveServiceHistoryFailure = createAction(
  ClearRemoveServiceHistoryFailure
);