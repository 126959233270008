<div class="member-status-list" id="member-status-list-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="addStatus()"
    buttonAddLabel="New Status"
  ></ptg-breadcrumb>

  <div *ngIf="bannerType" class="p-6">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <ptg-grid
    [data]="memberStatussData"
    [columns]="columns"
    [isLoading]="isLoading"
    [paginable]="false"
    (sortChange)="sortChange($event)"
    [errorMessage]="errorMsg"
    notFoundMessage="No Status to Display"
  >
    <ng-template cellContent columnName="active" let-row>
     {{ row.active  ? 'Yes' : 'No' }} 
    </ng-template>
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="viewStatusDetail(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
