import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { DisplayDocumentTypeName } from './types/enums';

export const GRID_COLUMN_EXCEPTION_LIST: Column[] = [
  {
    name: 'exceptionType',
    header: {
      title: 'Exception Type',
      style: {
        width: '14.28%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'exceptionTime',
    header: {
      title: 'Exception Time',
      style: {
        width: '14.28%',
      },
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy hh:mm a',
    },
    truncate: true,
  },
  {
    name: 'exceptionName',
    header: {
      title: 'Exception Name',
    },
    truncate: true,
    sortable: true,
  },
];

export const GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT: Column[] = [
  {
    name: 'uploadDate',
    header: {
      title: 'Upload Date',
      style: {
        width: '14.28%',
      },
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'documentName',
    header: {
      title: 'Document Name',
      style: {
        width: '25.22%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'fileName',
    header: {
      title: 'File Name',
      style: {
        width: '27.22%',
      },
    },
    truncate: true,
    sortable: false,
  },
  {
    name: 'documentType',
    header: {
      title: 'Document Type',
      style: {
        width: '17.63%',
      },
    },
    truncate: true,
    sortable: true,
    cell: (row) => {
      return DisplayDocumentTypeName[row.documentType];
    },
  },
  {
    name: ACTION_COLUMN,
    header: {
      title: 'Action',
    },
    truncate: true,
  },
];

export const GRID_COLUMN_CALCULATION_AUDIT_TRAILS: Column[] = [
  {
    name: 'createdDate',
    header: {
      title: 'Date Time',
      style: {
        width: '29.02%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'action',
    header: {
      title: 'Action',
      style: {
        width: '29.02%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'user',
    header: {
      title: 'User',
      style: {
        width: '29.02%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'method',
    header: {
      title: 'Method',
    },
    truncate: true,
    sortable: true,
  },
];

export const GRID_COLUMN_REFUND_YEARS: Column[] = [
  {
    name: 'year',
    header: {
      title: 'Year',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'municipalityName',
    header: {
      title: 'Municipality Name',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'disabilityFees',
    header: {
      title: 'Disability Fees',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'muniPaidPension',
    header: {
      title: 'Munic. Paid Pension',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'memberPaidPension',
    header: {
      title: 'Member Paid Pension',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'sumOfPensionPayments',
    header: {
      title: 'Sum of Pension Payment',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'lastEvent',
    header: {
      title: 'Last Event',
    },
    truncate: true,
    sortable: true,
  },
];

export const GRID_COLUMN_DISABILITY_SHORT_TERM: Column[] = [
  {
    name: 'createdDate',
    header: {
      title: 'Created Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'days',
    header: {
      title: 'Days',
    },
    align: Align.Right,
    truncate: true,
    sortable: true,
  },
  {
    name: 'memberAmount',
    header: {
      title: 'Amount',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
      emptyValueDisplay: '-',
    },
  },
  {
    name: 'status',
    header: {
      title: 'Status',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'calcAsOfDate',
    header: {
      title: 'Calculation as of Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'benefitEndDate',
    header: {
      title: 'Calculation End Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'recalReason',
    header: {
      title: 'Recalc Reason',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: ACTION_COLUMN,
    header: {
      title: 'Action',
    },
    truncate: true,
  },
];

export const GRID_COLUMN_DISABILITY_LONG_TERM: Column[] = [
  {
    name: 'createdDate',
    header: {
      title: 'Created Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'memberAmount',
    header: {
      title: 'Member',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
      emptyValueDisplay: '-',
    },
  },
  {
    name: 'spouseAmount',
    header: {
      title: 'Spouse',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
      emptyValueDisplay: '-',
    },
  },
  {
    name: 'childAmount',
    header: {
      title: 'Child',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
      emptyValueDisplay: '-',
    },
  },
  {
    name: 'childNumber',
    header: {
      title: 'Child Number',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'totals',
    header: {
      title: 'Totals',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
      emptyValueDisplay: '-',
    },
  },
  {
    name: 'status',
    header: {
      title: 'Status',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'calcAsOfDate',
    header: {
      title: 'Calculation as of Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'benefitEndDate',
    header: {
      title: 'Calculation End Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'recalReason',
    header: {
      title: 'Recalc Reason',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: ACTION_COLUMN,
    header: {
      title: 'Action',
    },
    truncate: true,
  },
];

export const GRID_COLUMN_DISABILITY_RELATED_ACCIDENTS: Column[] = [
  {
    name: 'accidentNumber',
    header: {
      title: 'Accident ID',
    },
    truncate: true,
  },
  {
    name: 'accidentDate',
    header: {
      title: 'Accident Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
  },
  {
    name: 'receiveDate',
    header: {
      title: 'Receive Date',
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
  },
  {
    name: 'injury',
    header: {
      title: 'Injury',
    },
    truncate: true,
  },
  {
    name: 'municipality',
    header: {
      title: 'Municipality',
    },
    truncate: true,
  },
  {
    name: 'status',
    header: {
      title: 'Status',
    },
    truncate: true,
  },
  {
    name: 'isFatal',
    header: {
      title: 'Fatal',
    },
    truncate: true,
  },
];

export const GRID_COLUMN_DETAIL_BENEFIT_QDRO: Column[] = [
  {
    name: 'payeeName',
    header: {
      title: 'Alternate Payee Name',
    },
    truncate: true,
  },
  {
    name: 'relationship',
    header: {
      title: 'Relationship',
    },
    truncate: true,
  },
  {
    name: 'courtOrder',
    header: {
      title: 'Court Order',
    },
    truncate: true,
  },
  {
    name: 'benefitBeginDate',
    header: {
      title: 'Benefit Begin Date',
    },
    truncate: true,
  },
  {
    name: 'qdroBenefitAmount',
    header: {
      title: '{qdroLabelName} Benefit Amount',
    },
    truncate: true,
  },
  {
    name: 'status',
    header: {
      title: '{qdroLabelName} Benefit Status',
    },
    truncate: true,
  },
];
