import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Option } from '@ptg-shared/controls/select/select.component';
import { FileType, ListFileType } from '../../constants';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, toUtcDate } from '@ptg-shared/utils/common.util';
import { SortType } from '@ptg-shared/constance';
import { getTagListAction } from '../../store/actions';
import { getTagListSelector } from '../../store/selectors';
import { select, Store } from '@ngrx/store';
import { TagsState } from '../../store/reducers';
import { BaseComponent } from '@ptg-shared/components';
import { DocumentFilter } from '../../services/models/documents.model';
import { DateTime } from 'luxon';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ptg-search-filter-documents',
  templateUrl: './search-filter-documents.component.html',
  styleUrls: ['./search-filter-documents.component.scss']
})
export class SearchFilterDocumentsComponent extends BaseComponent implements OnInit {
  FileType = FileType;
  formSubmit$ = new Subject<boolean>();
  filterForm!: FormGroup;
  fileTypeCtrl: FormControl = new FormControl(null);
  documentTagDisplay: any[] = [];
  filteredByTypingTagOptions!: Observable<Option[]>;
  filteredByTypingTypeOptions!: Observable<Option[]>;
  currentTagsChipValue!: Option;
  currentTypesChipValue!: Option;
  currentTagsChipValueList: string[] = [];
  currentTypesChipValueList: string[] = [];
  currentTagsChipValueDisplayList: string[] = [];
  currentTypesChipValueDisplayList: string[] = [];
  listOptionTag: Option[] = [];
  showOnOverviewOption: Option[] = [
    {
      displayValue: 'Yes',
      value: true
    },
    {
      displayValue: 'No',
      value: false
    },
  ]
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'New Filter'
    }
  ];
  maxBeginDate: Date = new Date();
  minToDate: Date = new Date(0);
  maxDate: Date = new Date();
  minDate: Date = new Date();

  isApplyDisabled: boolean = false;
  isClearDisabled: boolean = false;

  getNotUsedSources(value: any[]) {
    return value.map((x) => ({
      displayValue: x.displayValue,
      value: x.value
    })).sort((a, b) => a?.displayValue?.localeCompare(b?.displayValue));;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { currentFilter: DocumentFilter },
    public dialogRef: MatDialogRef<SearchFilterDocumentsComponent>,
    private fb: FormBuilder,
    public tagStore: Store<TagsState>,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data.currentFilter) {
      this.listBreadcrumbs[0].name = 'Edit Filter';
    }
    this.getTagList();
    this.selectDataTagList();
    this.initFormGroup();
  }

  initFormGroup() {
    this.filterForm = this.fb.group({
      documentName: this.fb.control(this.data?.currentFilter?.documentName || ''),
      fileName: this.fb.control(this.data?.currentFilter?.fileName || ''),
      uploadedFrom: this.fb.control(this.data?.currentFilter?.uploadedFrom ?? ''),
      uploadedTo: this.fb.control(this.data?.currentFilter?.uploadedTo ?? ''),
      types: this.fb.control(''),
      typesChipList: this.fb.array(this.data?.currentFilter?.typesChipList || []),
      tags: this.fb.control(''),
      tagsChipList: this.fb.array(this.data?.currentFilter?.tagsChipList || []),
      showOnOverview: this.fb.control(this.data?.currentFilter?.showOnOverview ?? null),
    });
    if (this.data?.currentFilter?.tags) {
      this.currentTagsChipValueList.push(...this.data?.currentFilter?.tags);
      this.currentTagsChipValueDisplayList.push(...this.data?.currentFilter?.tagsDisplay);
    }
    if (this.data?.currentFilter?.types) {
      this.currentTypesChipValueList.push(...this.data?.currentFilter?.types);
      this.currentTypesChipValueDisplayList.push(...this.data?.currentFilter?.typesDisplay);
    }
  }

  get uploadedFrom(): FormControl {
    return this.filterForm.get('uploadedFrom') as FormControl;
  }

  get uploadedTo(): FormControl {
    return this.filterForm.get('uploadedTo') as FormControl;
  }

  get typesChipListControl(): FormArray {
    return this.filterForm?.get('typesChipList') as FormArray;
  }

  get typesAutoCompleteControl(): FormControl {
    return this.filterForm?.get('types') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.filterForm?.get('tagsChipList') as FormArray;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.filterForm?.get('tags') as FormControl;
  }

  get showOnOverviewCtrl(): FormControl {
    return this.filterForm?.get('showOnOverview') as FormControl;
  }

  typesFocus(event: any) {
    event.stopPropagation();
    this.currentTypesChipValue = deepClone(this.typesAutoCompleteControl?.value);
    this.filteredByTypingTypeOptions = this.typesAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        ListFileType.
          filter(item =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTypesChipValueList?.includes(item?.value)
          )));
  }

  tagsFocus(event: any) {
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        this.listOptionTag.
          filter(item =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTagsChipValueList?.includes(item?.value)
          )));
  }

  displayTypeFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  validateTypes(): void {
    if (this.typesAutoCompleteControl.value) {
      if (
        this.typesAutoCompleteControl.enabled &&
        typeof this.typesAutoCompleteControl.value === 'string' ||
        this.currentTypesChipValueList?.includes(this.typesAutoCompleteControl.value.value)
      ) {
        this.typesAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        this.tagsAutoCompleteControl.enabled &&
        typeof this.tagsAutoCompleteControl.value === 'string' ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  onAddNewTypeChip() {
    if (!this.typesAutoCompleteControl.value ||
      this.typesAutoCompleteControl.invalid) {
      this.typesAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTypesChipValue;
    this.typesAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.typesChipListControl.push(chipControl);

    if (!this.currentTypesChipValueList.includes(chip.value)) {
      this.currentTypesChipValueList.push(chip.value);
      this.currentTypesChipValueDisplayList.push(chip.displayValue);
    }
  }

  onRemoveTypeChip(value: any, index: number) {
    if (!value) return;
    this.currentTypesChipValueList.splice(index, 1);
    this.typesChipListControl.removeAt(index);
    this.currentTypesChipValueDisplayList = this.currentTypesChipValueDisplayList.filter(item => item !== value?.displayValue);
  }

  onAddNewChip() {
    if (!this.tagsAutoCompleteControl.value ||
      this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);

    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
      this.currentTagsChipValueDisplayList.push(chip.displayValue);
    }
  }

  onRemoveChip(value: any, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
    this.currentTagsChipValueDisplayList = this.currentTagsChipValueDisplayList.filter(item => item !== value?.displayValue);
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(getTagListAction({
      request: {
        sortNames,
        sortType,
      },
    }));
  }

  selectDataTagList() {
    this.tagStore
      .pipe(select(getTagListSelector),
        takeUntil(this.unsubscribe$),
      ).subscribe((data) => {
        if (data) {
          if (data?.success && data?.payload?.length) {
            this.listOptionTag = data?.payload.map((item) => ({
              value: item.id,
              displayValue: item.name,
            } as Option));
          }
        }
      });
  }

  onChangeUploadedFrom(event: any) {
    this.uploadedTo.markAllAsTouched();
    if (this.uploadedTo.errors?.inValidAsync) {
      this.uploadedTo.setErrors(null);
    }
    let beginDate = new Date(event);
    let minToDateTemp = new Date(event);
    minToDateTemp.setDate(minToDateTemp.getDate() + 1);
    this.minToDate = minToDateTemp;
    let endDate = new Date(
      this.uploadedTo.value ? this.uploadedTo.value : DateTime.now
    );
    if (beginDate >= endDate) {
      this.uploadedFrom.setErrors({ errorMessageUploadedDate: 'The Uploaded From must be prior to the Uploaded To.' });
    } else {
      this.uploadedFrom.setErrors(null);
    }
  }

  onChangeUploadedTo(event: any) {
    this.uploadedFrom.markAllAsTouched();
    if (this.uploadedTo.errors?.inValidAsync) {
      this.uploadedTo.setErrors(null);
    }
    let beginDate = new Date(this.uploadedFrom.value);
    if (!event) this.uploadedFrom.setErrors(null);
    if (event) {
      let endDate = new Date(event);
      let maxBeginDateTemp = new Date(event);
      maxBeginDateTemp.setDate(maxBeginDateTemp.getDate() - 1);
      this.maxBeginDate = maxBeginDateTemp;
      if (beginDate > endDate) {
        this.uploadedFrom.setErrors({ errorMessageUploadedDate: 'The Uploaded From must be prior to the Uploaded To.' });
      } else {
        this.uploadedFrom.setErrors(null);
      }
    }
  }

  applyFilter() {
    const documentFilter = this.filterForm.value;
    let uploadedFrom = this.filterForm?.get('uploadedFrom')?.value;
    let uploadedTo = this.filterForm?.get('uploadedTo')?.value;
    documentFilter['uploadedFrom'] = uploadedFrom ? new DatePipe('en-US').transform(uploadedFrom, 'YYYY-MM-dd')?.toString() : '';
    documentFilter['uploadedTo'] = uploadedTo ? new DatePipe('en-US').transform(uploadedTo, 'YYYY-MM-dd')?.toString() : '';
    documentFilter['tags'] = this.currentTagsChipValueList;
    documentFilter['tagsDisplay'] = this.currentTagsChipValueDisplayList;
    documentFilter['types'] = this.currentTypesChipValueList;
    documentFilter['typesDisplay'] = this.currentTypesChipValueDisplayList;
    if (this.filterForm.invalid) {
      return;
    }
    this.dialogRef.close(documentFilter);
  }

  clearFilter() {
    // reset form
    this.filterForm.reset();
    this.currentTagsChipValueList = [];
    this.currentTagsChipValueDisplayList = [];
    this.tagsChipListControl.clear();
    this.currentTypesChipValueList = [];
    this.currentTypesChipValueDisplayList = [];
    this.typesChipListControl.clear();
    this.showOnOverviewCtrl.setValue(null);
  }

  ngDoCheck() {
    const documentNameVal = this.filterForm?.get('documentName')?.value;
    const fileNameVal = this.filterForm?.get('fileName')?.value;
    const uploadedFromVal = this.uploadedFrom?.value;
    const uploadedToVal = this.uploadedTo?.value;
    const haveAtLeastOneFileChip = this.typesChipListControl?.length >= 1;
    const haveAtLeastOneTagsChip = this.tagsChipListControl?.length >= 1;
    const showOnOverviewVal = this.filterForm?.get('showOnOverview')?.value;
    const disabledBtn = !(
      documentNameVal ||
      fileNameVal ||
      uploadedFromVal ||
      uploadedToVal ||
      haveAtLeastOneFileChip ||
      haveAtLeastOneTagsChip ||
      showOnOverviewVal !== null
    );

    this.isApplyDisabled = this.isClearDisabled = disabledBtn;
  }
}
