import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  checkMemberGeneratingDocumentAction,
  checkMemberGeneratingDocumentFailureAction,
  checkMemberGeneratingDocumentSuccessAction,
  editDocumentsAction,
  editDocumentsFailureAction,
  editDocumentsSuccessAction,
  getDocumentListsAction,
  getDocumentListsFailureAction,
  getDocumentListsSuccessAction,
  getOrganizationDocumentListsAction,
  getOrganizationDocumentListsFailureAction,
  getOrganizationDocumentListsSuccessAction,
  removeDocumentsAction,
  removeDocumentsFailureAction,
  removeDocumentsSuccessAction,
  removeOrganizationDocumentsAction,
  removeOrganizationDocumentsFailureAction,
  removeOrganizationDocumentsSuccessAction,
  uploadDocumentsAction,
  uploadDocumentsFailureAction,
  uploadDocumentsSuccessAction,
  uploadOrganizationDocumentsAction,
  uploadOrganizationDocumentsFailureAction,
  uploadOrganizationDocumentsSuccessAction,
} from '../actions/member-document.action';
import { GetDocumentListsResponse, GetOrganizationDocumentListsResponse } from "@ptg-member/types/models/member-document.model";
import { catchError, map, switchMap } from "rxjs/operators";
import { MemberDocumentService } from "@ptg-member/services/member-document.service";
import { of } from "rxjs";
import { DocumentsService } from 'src/app/admin/features/file/services';
import { GetGeneratedReportsInformationResponse } from 'src/app/admin/features/file/services/models/documents.model';


@Injectable()
export class DocumentListEffects {
  constructor(
    private actions$: Actions,
    private documentListService: MemberDocumentService,
    private documentsService: DocumentsService,
  ) {}

  getDocumentLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentListsAction),
      switchMap(({ request }) =>
        this.documentListService.getDocumentLists(request).pipe(
          map((response: GetDocumentListsResponse) => getDocumentListsSuccessAction({ response })),
          catchError((error) => of(getDocumentListsFailureAction({ error })))
        )
      )
    )
  );

  getOrganizationDocumentLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganizationDocumentListsAction),
      switchMap(({ request }) =>
        this.documentListService.getOrganizationDocumentLists(request).pipe(
          map((response: GetOrganizationDocumentListsResponse) => getOrganizationDocumentListsSuccessAction({ response })),
          catchError((error) => of(getOrganizationDocumentListsFailureAction({ error })))
        )
      )
    )
  );

  removeOrganizationDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOrganizationDocumentsAction),
      switchMap(({ body }) =>
        this.documentListService.removeOrganizationDocument(body).pipe(
          map(() => removeOrganizationDocumentsSuccessAction()),
          catchError((error) => of(removeOrganizationDocumentsFailureAction({ error })))
        )
      )
    )
  );

  uploadOrganizationDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadOrganizationDocumentsAction),
      switchMap(({ body }) =>
        this.documentListService.uploadOrganizationDocuments(body).pipe(
          map(() => uploadOrganizationDocumentsSuccessAction()),
          catchError((error) => of(uploadOrganizationDocumentsFailureAction({ error })))
        )
      )
    )
  );

  removeDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDocumentsAction),
      switchMap(({ body }) =>
        this.documentListService.removeDocument(body).pipe(
          map(() => removeDocumentsSuccessAction()),
          catchError((error) => of(removeDocumentsFailureAction({ error })))
        )
      )
    )
  );

  uploadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadDocumentsAction),
      switchMap(({ body }) =>
        this.documentListService.uploadDocuments(body).pipe(
          map(() => uploadDocumentsSuccessAction()),
          catchError((error) => of(uploadDocumentsFailureAction({ error })))
        )
      )
    )
  );

  editDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editDocumentsAction),
      switchMap(({ memberId, fileId, body }) =>
        this.documentListService.editDocuments(memberId, fileId, body).pipe(
          map(() => editDocumentsSuccessAction()),
          catchError((error) => of(editDocumentsFailureAction({ error })))
        )
      )
    )
  );

  checkGeneratingDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkMemberGeneratingDocumentAction),
      switchMap(({ request }) =>
        this.documentsService.getGeneratedReportsInformation(request).pipe(
          map((response: GetGeneratedReportsInformationResponse) => checkMemberGeneratingDocumentSuccessAction({ response })),
          catchError((error) => of(checkMemberGeneratingDocumentFailureAction({ error })))
        )
      )
    )
  );
}
