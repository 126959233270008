import * as fromBulkContent from './bulk-content.reducer';
import * as fromAppContent from './app-content.reducer';
import * as fromSearch from './search.reducer';
import * as fromLayout from '@ptg-shared/layout/reducers/layout.reducer';
import * as fromRoot from '../../../reducers';
import * as fromInterceptor from './interceptor.reducer';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfileNavigation from './profile-navigation.reducer';


export const LayoutModuleFeatureKey = 'layout';

export interface LayoutState {
  [fromSearch.SearchFeatureKey]: fromSearch.State;
  [fromBulkContent.BulkContentFeatureKey]: fromBulkContent.State;
  [fromAppContent.AppContentFeatureKey]: fromAppContent.State;
  [fromLayout.LayoutFeature]: fromLayout.State;
  [fromProfileNavigation.ProfileNavigationFeatureKey]: fromProfileNavigation.State;
  [fromInterceptor.InterceptorFeatureKey]: fromInterceptor.State;
}

export interface State extends fromRoot.State {
  [LayoutModuleFeatureKey]: LayoutState;
}

export function reducers(state: LayoutState | undefined, action: Action) {
  return combineReducers({
    [fromSearch.SearchFeatureKey]: fromSearch.reducer,
    [fromBulkContent.BulkContentFeatureKey]: fromBulkContent.reducer,
    [fromAppContent.AppContentFeatureKey]: fromAppContent.reducer,
    [fromLayout.LayoutFeature]: fromLayout.reducer,
    [fromProfileNavigation.ProfileNavigationFeatureKey]: fromProfileNavigation.reducer,
    [fromInterceptor.InterceptorFeatureKey]: fromInterceptor.reducer,
  })(state, action);
}

export const selectLayoutModuleState = createFeatureSelector<LayoutState>(
  LayoutModuleFeatureKey
);

export const selectSearchState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromSearch.SearchFeatureKey]
);

export const selectSearchResultState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromSearch.SearchFeatureKey].searchResult
);

export const selectBulkContentState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromBulkContent.BulkContentFeatureKey]
);

export const selectAppContentState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromAppContent.AppContentFeatureKey]
);

export const selectLayoutState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromLayout.LayoutFeature]
);

export const selectSidebarOpenedStatusState = createSelector(
  selectLayoutState,
  (state) => state.sidebarOpenedStatus
);

export const selectTabState = createSelector(
  selectLayoutState,
  (state) => state.tab
);

export const selectTabsState = createSelector(
  selectLayoutState,
  (state) => state.tabs
);


export const selectAllMenuGroupsState = createSelector(
  selectLayoutState,
  (state) => state.allMenuGroups
);

export const closeTabStatus = createSelector(
  selectLayoutState,
  (state) => state.isCloseTab
);

export const changePageStatus = createSelector(
  selectLayoutState,
  (state) => state.isChangePage
);

export const selectUrlBeforeSearchState = createSelector(
  selectLayoutState,
  (state) => state.history?.urlBeforeSearch
);

export const selectPaymentInfoParamsState = createSelector(
  selectLayoutState,
  (state) => state.history?.paymentInfoParams
);

export const selectProfileNavigationState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromProfileNavigation.ProfileNavigationFeatureKey]
);

export const selectMemberNavigationListState = createSelector(
  selectProfileNavigationState,
  (state) => state.memberNavigationList,
);

export const selectMemberNavigationMenuListState = createSelector(selectProfileNavigationState, (state) => state.menu);

export const selectInterceptorState = createSelector(
  selectLayoutModuleState,
  (state) => state[fromInterceptor.InterceptorFeatureKey],
);

export const selectInterceptorErrorState = createSelector(selectInterceptorState, (state) => state.error);

export const selectCurrentMenuGroupsState = createSelector(selectLayoutState, (state) => state.menuGroups);
export const selectCurrentUrlState = createSelector(selectLayoutState, (state) => state.url);

export const selectLoadingState = createSelector(
  selectLayoutState,
  (state) => state.loading
);

