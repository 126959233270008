import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { Menu, MenuItem, ViewFixType } from '@ptg-shared/layout/models/layout.model';

import { GetEntityProfileConfigOverviewResponse } from '../../services/models';
import {
  clearGetEntityProfileConfigOverviewStateAction,
  getEntityProfileConfigOverviewAction,
  getEntityProfileConfigOverviewFailureAction,
  getEntityProfileConfigOverviewSuccessAction,
} from '../actions';
import { DepositAccountRouterUrl } from '@ptg-member/constants';

export interface EntityProfileConfigState {
  getEntityProfileConfigOverview?: BaseActionState<GetEntityProfileConfigOverviewResponse>;
}

const initialState: EntityProfileConfigState = {};

export const entityProfileConfigReducer = createReducer(
  initialState,
  on(getEntityProfileConfigOverviewAction, (state) => ({
    ...state,
    getEntityProfileConfigOverview: {
      isLoading: true,
    },
  })),
  on(getEntityProfileConfigOverviewSuccessAction, (state, { response }) => {
    // TODO Check logic build url later
    const menus =
      response.profileConfigOverview?.navigations?.map(
        (nav) =>
          ({
            title: nav.isSingleView ? '' : nav.name,
            style: `color: ${nav.color} !important`,
            isSingleView: nav?.isSingleView,
            menuItems: nav?.menuItems?.map((item: any) => {
              let url = Object.keys(item).some((x) => x === 'isDetailView')
                ? item.isDetailView
                  ? `member/detail-view/false/${item.id}/${item.entityViewId}`
                  : `/member/summary-view/false/${item.id}/${item.entityViewId}/`
                : `/member/navigation/${item.entityViewId}`;
              switch (item.viewFixType) {
                case ViewFixType.ParticipantRelationships:
                  url = `/member/system-view/participant-relationships/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.PaymentInformation:
                  url = `/member/system-view/payment-information/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.TransactionRegister:
                  url = `/member/system-view/transaction-register/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.StatusHistory:
                  url = `/member/system-view/status-history/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.ServiceHistory:
                  url = `/member/system-view/service-history/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.Payments:
                  url = `/member/system-view/payments/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.AddressHistory:
                  url = `/member/system-view/address-history/${item.id}/${item.entityViewId}/`;
                  break;
                case ViewFixType.DepositAccountList:
                  url = `/` + DepositAccountRouterUrl + `${item.id}/${item.entityViewId}/`;
                  break;
              }

              const subMenu: MenuItem = {
                name: item.name,
                routerLink: url,
                routerLinkExpand: [url],
                viewFixType: item.viewFixType,
                cardId: item.cardIdList,
                ...(item?.viewFixType != null ? { viewFixType: item.viewFixType } : {}),
              };

              return subMenu;
            }),
          }) as Menu,
      ) ?? [];

    return {
      ...state,
      getEntityProfileConfigOverview: {
        isLoading: false,
        success: true,
        payload: { ...response, menus },
      },
    };
  }),
  on(getEntityProfileConfigOverviewFailureAction, (state, { error }) => ({
    ...state,
    getEntityProfileConfigOverview: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityProfileConfigOverviewStateAction, (state) => ({
    ...state,
    getEntityProfileConfigOverview: undefined,
  })),
);
