import { FileSnapshot } from '@ptg-shared/types/models';

import { MemberListQuery } from './member-list';
import { MetadataSection } from './metadata.model';

export interface MemberDetail {
  demographics?: any;
  beneficiary?: any;
  benefit?: any;
  drop?: any;
  fiscal?: any;
  fiscalHistory?: any;
}

export interface AddSectionState {
  addSectionSuccess?: boolean;
  addToTop?: boolean;
  isTable?: boolean;
  metaDataPropertyValues?: any;
  sectionData: MetadataSection;
  saveSectionDataSuccess?: boolean;
  reloadSection: boolean;
  memberId: string;
  isEdit?: boolean;
  isRemove?: boolean;
  isUploadFile?: boolean;
  error?: any;
}

export interface EditMemberEventBody {
  memberId: string;
  statusId: string;
  eventId: string;
}

export interface MemberNavigationState {
  query?: MemberListQuery;
  totalPage: number;
  startPage: number;
  endPage: number;
  members: Record<string, unknown>[];
  memberIndex: number;
}

export interface MemberDetailWithPaging {
  pageIndex?: number;
  totalPerPage?: number;
  sortField?: string;
  sortType?: number;
  sortNames?: string[];
  attachmentPageSize?: number;
  attachmentPageIndex?: number;
  attachmentSortName?: string;
  attachmentSortType?: number;
}

export interface MemberDetailListWithPaging {
  total?: number;
  memberMetaData?: any;
  success?: boolean;
  error?: any;
  isLoading?: boolean;
}

export interface MemberMetadataAttachmentState {
  success?: boolean;
  isCreate?: boolean;
  isEdit?: boolean;
  isRemove?: boolean;
  documentName?: string;
  isLoading?: boolean;
}

export interface MemberPropetyDataByMaskedConfigQuery {
  page?: string;
  memberId: string;
  itemKey: string;
  propertyKey: string;
  index: string;
  optionMasked: boolean;
}

export interface MemberPropetyDataByMaskedConfigResponse {
  value: string;
}

export interface MemberListAttachments {
  total?: number;
  items: any;
  success?: boolean;
  isLoading?: boolean;
}
export interface MemberAttachmentDetail {
  memberId: string;
  itemKey: string;
  rowId: string;
  documentName?: string;
  description: string;
  fileSnapshot?: FileSnapshot;
}
export interface MemberAttachmentsListSectionQuery {
  rowId: string;
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortType?: number;
}

export interface ConfirmRetireRehireResponse {
  isShowConfirmPopup: boolean;
}

export interface LookupTableQuery {
  screenId?: string;
}

export interface LockMember {
  memberId: string;
  isLock: boolean;
}

export interface EntityMemberDetail {
  id: string;
  memberKey: string;
  namePrefix: string;
  firstName: string;
  middleName: string;
  lastName: any;
  nameSuffix: string;
  birthday: string;
  status: string;
  statusId: string;
  eventId: string;
  color: string;
  iconName: string;
  directoryId: string;
  isLocked: boolean;
  identityKey: string;
  canRemove: boolean;
  profileHeaderData: ProfileHeaderDaum[];
  isValidToChange: IsValidToChange;
  isDisabledAppModule: boolean;
  isMemberOrParticipant: boolean;
}

export interface IsValidToChange {
  Deactive: {
    isValid: boolean,
    isValidOriginalValue: boolean,
    newValueEqualToCurrentValue: boolean,
  }
}

export interface ProfileHeaderDaum {
  label: string;
  value: {
    prefix: string;
    first: string;
    middle: string;
    last: string;
    suffix: string;
  }
}

export interface ClientSetting {
  key: string;
  value: string;
}

export interface FixedProperties {
  BvffMember: PropertySetting[];
  Member: PropertySetting[];
  Employer: PropertySetting[];
  EmployerSection: PropertySetting[];
  AnnualCertification: PropertySetting[];
}

export interface PropertySetting {
  Key: string;
  EntityPropertyId: string;
}

export interface CheckAttachmentResponsive {
  isValid: boolean;
  message: string | null;
}