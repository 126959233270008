import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EditDeductionsService, EditFundingSourceService } from '../../services';
import {
  GetEarningFundingSourcesResponse,
} from '../../services/models';
import {
  getEarningFundingSourcesAction,
  getEarningFundingSourcesFailureAction,
  getEarningFundingSourcesSuccessAction,
  createEditEarningItemDetailsAction,
  createEditEarningItemDetailsFailureAction,
  createEditEarningItemDetailsSuccessAction,
  editDeductionAsFundingSourceAction,
  editDeductionAsFundingSourceFailureAction,
  editDeductionAsFundingSourceSuccessAction,
} from '../actions';

@Injectable()
export class EditFundingSourceEffects {
  constructor(
    private actions$: Actions,
    private editFundingSourceService: EditFundingSourceService,
    private editDeductionsService: EditDeductionsService
  ) {}

  getEarningFundingSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEarningFundingSourcesAction),
      switchMap(({ request, queryParams }) =>
        this.editFundingSourceService.getEarningFundingSources(request, queryParams).pipe(
          map((response: GetEarningFundingSourcesResponse) => getEarningFundingSourcesSuccessAction({ response })),
          catchError((error) => of(getEarningFundingSourcesFailureAction({ error }))),
        ),
      ),
    ),
  );

  createEditEarningItemDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEditEarningItemDetailsAction),
      switchMap(({ request, isChangeAmountOfTaxable }) =>
        this.editFundingSourceService.createEditEarningItemDetails(request).pipe(
          map(() => createEditEarningItemDetailsSuccessAction({ isChangeAmountOfTaxable })),
          catchError((error) => of(createEditEarningItemDetailsFailureAction({ error }))),
        ),
      ),
    ),
  );

  editDeductionFundingSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editDeductionAsFundingSourceAction),
      switchMap(({ paymentInstructionId, request }) =>
        this.editDeductionsService.setDeductions(paymentInstructionId, request).pipe(
          map(() => editDeductionAsFundingSourceSuccessAction()),
          catchError((error) => of(editDeductionAsFundingSourceFailureAction({ error }))),
        ),
      ),
    ),
  );
}
