import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { GUID_EMPTY, SortType } from '@ptg-shared/constance';

import {
  GetDepositAccountsRequest,
  GetDepositAccountsResponse,
  UpsertDepositAccountRequest,
} from '@ptg-member/types/models/deposit-account.model';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DepositAccountService {
  bankInfo: any;
  get Endpoint(): string {
    return `${environment.apiUrl}/new-payroll/deduction-payees`;
  }

  constructor(private httpClient: HttpClient) {}

  getDepositAccounts(request: GetDepositAccountsRequest): Observable<GetDepositAccountsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append(
        'SortNames',
        request.sortNames === 'AccountNumberMasked' ? 'AccountNumber' : request.sortNames,
      );
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.paymentInstructionId) {
      params = params.append('paymentInstructionId', request.paymentInstructionId);
    }

    return this.httpClient.get<GetDepositAccountsResponse>(
      `${environment.apiUrl}/Members/${request?.targetId}/deposit-account-list`,
      { params },
    );
  }

  createDepositAccounts(request: UpsertDepositAccountRequest): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Members/${request?.targetId}/deposit-account-list`,
      request,
    );
  }

  setDepositAccount(request: UpsertDepositAccountRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Members/${request?.targetId}/deposit-account-list/${request?.id}`,
      request,
    );
  }

  checkApiValidator(editValue?: string): AsyncValidatorFn {
    // Need obs to call api for check
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value.trim().length < 9) {
        this.bankInfo = null;
        return of({ inValidAsync: true });
      }

      return this.getBankInfo(control.value?.trim()).pipe(
        map((response: any) => {
          if (!response || response.id === GUID_EMPTY || response.isDisabled) {
            this.bankInfo = null;
            return { inValidAsync: true };
          }

          this.bankInfo = response;
          return null;
        }),
        catchError((err) => {
          this.bankInfo = null;
          return of({ inValidAsync: true });
        }),
      );
    };
  }

  getBankInfo(routingNumber: number) {
    return this.httpClient.get(`${environment.apiUrl}/new-payroll/banks/${routingNumber}`);
  }

  checkExitsDepositAccountNumber = (bodyCheck: any) => {
    let params = {
      ...bodyCheck,
    };
    return this.httpClient.post(
      `${environment.apiUrl}/Members/${bodyCheck?.targetId}/deposit-account-list/exists-deposit-account-number`,
      params,
    );
  };
}
