import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAnnualCertification from 'src/app/employer/reducers/annual-certification.reducer';
import * as fromEmployerNavigation from 'src/app/employer/reducers/employer-navigation.reducer';
import * as fromRoot from '../../reducers';
import * as fromDepartment from './department.reducer';
import * as fromEmployerSetting from './employer-setting.reducer';
import * as fromEmployerWageProcessing from './employer-wage-processing.reducer';
import * as fromEmployer from './employer.reducer';
import * as fromParameters from './parameters.reducer';
import * as fromReportingCalendar from './reporting-calendar.reducer';
import * as fromToleranceParameter from './tolerance-parameter.reducer';
import * as fromContributionInterestRate from './contribution-interest-rate.reducer';
import * as fromRemittanceSubmission from './remittance-submission.reducer';
import * as fromRemittanceSubmissionHistory from './remittance-submission-history.reducer';
import * as fromEmployerDocument from './employer-document.reducer';
import * as fromTransactions from './transactions.reducer';
import * as fromEmployerNote from './employer-note.reducer';
import * as fromAnnualFeeLetter from 'src/app/employer/reducers/annual-fee-letter.reducer';
import * as fromRemittance2024Confirmation from 'src/app/employer/reducers/remittance-2024-confirmation.reducer';
import * as fromLateFeeReminderLetter from 'src/app/employer/reducers/late-fee-reminder-letter.reducer';
import { PaymentReceiptState, paymentReceiptReducer } from './payment-receipt.reducer';
import { RemittancePrintInvoiceState, remittancePrintInvoiceReducer } from './remittance-print-invoice.reducer';

export const employerModuleFeatureKey = 'employerModule';

export interface EmployerState {
  [fromDepartment.departmentFeatureKey]: fromDepartment.State;
  [fromEmployer.employerFeatureKey]: fromEmployer.State;
  [fromParameters.parametersFeatureKey]: fromParameters.State;
  [fromReportingCalendar.reportingCalendarFeatureKey]: fromReportingCalendar.State;
  [fromEmployerWageProcessing.employerWageProcessingFeatureKey]: fromEmployerWageProcessing.State;
  [fromEmployerNavigation.employerNavigationFeatureKey]: fromEmployerNavigation.State;
  [fromToleranceParameter.toleranceParameterFeatureKey]: fromToleranceParameter.State;
  [fromContributionInterestRate.contributionInterestRateFeatureKey]: fromContributionInterestRate.State;
  [fromEmployerSetting.employerSettingFeatureKey]: fromEmployerSetting.State;
  [fromAnnualCertification.annualCertificationFeatureKey]: fromAnnualCertification.State;
  [fromRemittanceSubmission.remittanceSubmissionFeatureKey]: fromRemittanceSubmission.State;
  [fromRemittanceSubmissionHistory.remittanceSubmissionHistoryFeatureKey]: fromRemittanceSubmissionHistory.State;
  [fromTransactions.municipalityTransactionsFeatureKey]: fromTransactions.State;
  [fromEmployerDocument.employerDocumentFeatureKey]: fromEmployerDocument.DocumentState;
  [fromEmployerNote.employerNoteFeatureKey]: fromEmployerNote.NoteState;
  [fromAnnualFeeLetter.annualFeeLetterFeatureKey]: fromAnnualFeeLetter.AnnualFeeLetterState;
  [fromRemittance2024Confirmation.remittance2024ConfirmationFeatureKey]: fromRemittance2024Confirmation.Remittance2024ConfirmationState;
  [fromLateFeeReminderLetter.lateFeeReminderLetterFeatureKey]: fromLateFeeReminderLetter.LateFeeReminderLetterState;
  paymentReceipt: PaymentReceiptState;
  remittancePrintInvoice: RemittancePrintInvoiceState;
}

export interface State extends fromRoot.State {
  [employerModuleFeatureKey]: EmployerState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: EmployerState | undefined, action: Action) {
  return combineReducers({
    [fromDepartment.departmentFeatureKey]: fromDepartment.reducer,
    [fromEmployer.employerFeatureKey]: fromEmployer.reducer,
    [fromReportingCalendar.reportingCalendarFeatureKey]: fromReportingCalendar.reducer,
    [fromParameters.parametersFeatureKey]: fromParameters.reducer,
    [fromEmployerWageProcessing.employerWageProcessingFeatureKey]: fromEmployerWageProcessing.reducer,
    [fromEmployerNavigation.employerNavigationFeatureKey]: fromEmployerNavigation.reducer,
    [fromToleranceParameter.toleranceParameterFeatureKey]: fromToleranceParameter.reducer,
    [fromContributionInterestRate.contributionInterestRateFeatureKey]: fromContributionInterestRate.reducer,
    [fromEmployerSetting.employerSettingFeatureKey]: fromEmployerSetting.reducer,
    [fromAnnualCertification.annualCertificationFeatureKey]: fromAnnualCertification.reducer,
    [fromRemittanceSubmission.remittanceSubmissionFeatureKey]: fromRemittanceSubmission.reducer,
    [fromRemittanceSubmissionHistory.remittanceSubmissionHistoryFeatureKey]: fromRemittanceSubmissionHistory.reducer,
    [fromTransactions.municipalityTransactionsFeatureKey]: fromTransactions.reducer,
    [fromEmployerDocument.employerDocumentFeatureKey]: fromEmployerDocument.documentListReducer,
    [fromEmployerNote.employerNoteFeatureKey]: fromEmployerNote.noteReducer,
    [fromAnnualFeeLetter.annualFeeLetterFeatureKey]: fromAnnualFeeLetter.annualFeeLetterReducer,
    [fromRemittance2024Confirmation.remittance2024ConfirmationFeatureKey]: fromRemittance2024Confirmation.remittance2024ConfirmationReducer,
    [fromLateFeeReminderLetter.lateFeeReminderLetterFeatureKey]: fromLateFeeReminderLetter.lateFeeReminderLetterReducer,
    paymentReceipt: paymentReceiptReducer,
    remittancePrintInvoice: remittancePrintInvoiceReducer,
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectEmployerModuleState = createFeatureSelector<EmployerState>(employerModuleFeatureKey);

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */

export const selectDepartmentListState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromDepartment.departmentFeatureKey],
);
export const selectDepartmentEmployerDetailState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromDepartment.departmentFeatureKey].employerDetail,
);

export const selectEmployerListState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey],
);

export const selectEmployerOverviewState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerOverview,
);

export const selectEmployerOverviewConfigurationsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].overviewConfigurations,
);
export const selectSaveEmployerOverviewConfigState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].saveEmployerOverviewConfigState,
);
export const selectEmployerListPropertyState = createSelector(selectEmployerListState, (state) => state.properties);

export const selectEmployerPropertyListConfiguration = createSelector(
  selectEmployerListState,
  (state) => state.propertyListConfiguration,
);

export const selectEmployerPropertyListFilter = createSelector(
  selectEmployerListState,
  (state) => state.propertyListFilter,
);

export const selectEmployerInfoPropertiesState = createSelector(
  selectEmployerListState,
  (state) => state.employerInfoProperties,
);

export const selectActionState = createSelector(selectEmployerListState, (state) => state.actionState);

export const selectLoadingState = createSelector(selectEmployerListState, (state) => state.isLoading);

export const selectModifyPropertyNameState = createSelector(selectEmployerListState, (state) => state.newPropertyName);

export const selectLookupTableOptions = createSelector(
  selectEmployerListState,
  (state) => state.defaultLookupTableOptions,
);
export const selectPropertyConfigs = createSelector(
  selectEmployerListState,
  (state) => state?.employerInformationConfiguration,
);

export const selectPropertyDisplayConfigurations = createSelector(
  selectEmployerListState,
  (state) => state?.propertyDisplayConfigurations,
);

export const selectSearchEmployerState = createSelector(selectEmployerListState, (state) => state?.searchEmployer);

export const selectEmployerFileTemplatesState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFileTemplates,
);

export const selectCertificationHistoryListState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualCertification.annualCertificationFeatureKey].certificationHistoryList,
);

export const selectEmployerFileFormatState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFileFormatActionState,
);

export const selectEmployerFilePropertiesState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFileProperties,
);

export const selectMappingFieldSectionsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].mappingFieldSections,
);

export const selectEmployerFilePropertyState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFilePropertyActionState,
);

export const selectEmployerFilePostingFieldState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFilePostingField,
);

export const selectEmployerConfigurePostingFieldsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].configurePostingFieldsState,
);

export const selectSaveEmployerListConfigState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].saveEmployerListConfigState,
);

export const selectSaveEmployerListConfigurationState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].saveEmployerListConfigurationState,
);

export const selectEmployerSectionActionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerSectionActionState,
);

export const selectEmployerCheckSectionActionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerCheckSectionActionState,
);

export const selectYearsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromReportingCalendar.reportingCalendarFeatureKey].yearState,
);

export const selectPeriodsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromReportingCalendar.reportingCalendarFeatureKey].periodState,
);

export const selectReportingCalendarActionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromReportingCalendar.reportingCalendarFeatureKey].actionState,
);

export const selectListFilterInfo = createSelector(selectEmployerListState, (state) => state.listFilterInfo);
export const selectRemoveEmloyerFilterState = createSelector(
  selectEmployerListState,
  (state) => state.removeEmployerFilterState,
);
export const selectUpdateEmployerFilterNameState = createSelector(
  selectEmployerListState,
  (state) => state.updateEmployerFilterNameState,
);
export const selectEmployerFileLayoutConfigurationState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFileLayoutConfiguration,
);

export const selectMunicipalityCheckDisableState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerCheckDisableState,
);

export const selectEmployerFileLayoutActionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].employerFileLayoutActionState,
);

export const selectEmployerFiltersState = createSelector(selectEmployerListState, (state) => state.filters);
export const selectWageProcessingDataState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageProcessingData,
);
export const selectUploadWageFileState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].uploadWageFileState,
);

export const selectWageFileValidationReportState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageFileValidationReportData,
);

export const selectWageFileValidationAuditTrailsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageFileValidationAuditTrailsData,
);

export const selectWageFileParticipantContributionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].participantContributionData,
);

export const selectWageRecordCommentState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageRecordComment,
);

export const selectWageRecordSectionDataState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageRecordSectionData,
);

export const selectWageRecordDetailExceptionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageRecordDetailException,
);

export const selectWageRecordListConfigState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageRecordListConfigurations,
);

export const selectSetEmployerWageRecordListConfigurationState = createSelector(
  selectEmployerModuleState,
  (state) =>
    state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].setEmployerWageRecordListConfigurationState,
);

export const selectSetEmployerWageRecordDetailConfigurationState = createSelector(
  selectEmployerModuleState,
  (state) =>
    state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].setEmployerWageRecordDetailConfigurationState,
);

export const selectDetailWageFileExceptionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].detailWageFileException,
);

export const selectDetailExceptionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].detailException,
);

export const selectAddRemoveCommentExceptionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].addRemoveCommentExceptionState,
);

export const selectSaveCorrectedExceptionsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].saveCorrectedExceptionsState,
);

export const selectEmployerNavigationFeatureState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerNavigation.employerNavigationFeatureKey],
);

export const selectEmployerNavigationState = createSelector(
  selectEmployerNavigationFeatureState,
  (state) => state.employerNavigateState,
);

export const selectGetContributionPlanConfigurationsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromParameters.parametersFeatureKey].getContributionPlanConfigurationsState,
);

export const selectSetContributionPlanConfigurationsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromParameters.parametersFeatureKey].setContributionPlanConfigurationsState,
);

export const selectTaxStatussState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromParameters.parametersFeatureKey].taxStatusState,
);

export const selectPlansState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromParameters.parametersFeatureKey].planState,
);

export const selectPlanDetailState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromParameters.parametersFeatureKey].planDetailState,
);

export const selectToleranceState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromToleranceParameter.toleranceParameterFeatureKey].toleranceParameterState,
);

export const selectContributionInterestRateState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromContributionInterestRate.contributionInterestRateFeatureKey].contributionInterestRate,
);

export const selectInterestRateActionStateState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromContributionInterestRate.contributionInterestRateFeatureKey].actionState,
);

export const selectListFireDepartmentFeesState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerSetting.employerSettingFeatureKey].settingFeeAmounts.listFireDepartmentFees,
);

export const selectListReserveOfficerFeesState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerSetting.employerSettingFeatureKey].settingFeeAmounts.listReserveOfficerFees,
);

export const selectListEMSDFeesState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerSetting.employerSettingFeatureKey].settingFeeAmounts.listEMSDFees,
);

export const selectFeeAmountsDataState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerSetting.employerSettingFeatureKey].settingFeeAmounts.dataState,
);

export const selectAnnualCertificationInformationState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualCertification.annualCertificationFeatureKey].annualCertificationInformation,
);
export const selectAnnualCertificationInformationLoadingState = createSelector(
  selectAnnualCertificationInformationState,
  (state) => state.isLoading,
);
export const selectAnnualCertificationInformationDataState = createSelector(
  selectAnnualCertificationInformationState,
  (state) => state.data,
);
export const selectUploadFileState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualCertification.annualCertificationFeatureKey].uploadFileState,
);

export const selectAnnualCertificationParticipantsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualCertification.annualCertificationFeatureKey],
);

export const selectAnnualCertificationParticipantsDataState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.annualCertificationParticipantList.datas,
);
export const selectUpdateMetRequirementState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.updateMetRequirementState,
);
export const selectUpdateMetRequirementDataTableState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.updateMetRequirementDataTableState,
);
export const selectUpdateEndOfServiceDataTableState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.updateEndOfServiceDataTableState,
);

export const selectParticipantWageSectionsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].participantWageSectionsData,
);

export const selectParticipantWageSectionState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageRecordState,
);

export const selectAnnualCertificationConfirmationDataState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.annualCertificationConfirmationData,
);

export const selectCompleteAnnualCertificationState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.completeAnnualCertificationState,
);

export const selectSetAnnualCertificationConfirmSignaturesState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.setAnnualCertificationConfirmSignaturesState,
);

export const selectUpdateModeState = createSelector(
  selectAnnualCertificationParticipantsState,
  (state) => state.updateMode,
);

export const selectRemittanceSubmissionFeatureState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromRemittanceSubmission.remittanceSubmissionFeatureKey],
);

export const selectRemittanceSubmissionHistoryState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromRemittanceSubmissionHistory.remittanceSubmissionHistoryFeatureKey],
);

export const selectParticipantWageRecordDetailState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].participantWageSectionDetailData,
);

export const selectRemittanceSubmissionState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.remittanceSubmission,
);

export const selectEditRemittanceSubmissionState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.editRemittance,
);

export const selectRemoveRemittanceSubmissionState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.removeRemittanceState,
);

export const selectListRemittanceState = createSelector(
  selectRemittanceSubmissionHistoryState,
  (state) => state.listRemittance,
);

export const selectRemittanceReceiptState = createSelector(
  selectRemittanceSubmissionHistoryState,
  (state) => state.remittanceReceiptState,
);

export const getDownloadRemittanceReceiptSelector = createSelector(
  selectRemittanceSubmissionHistoryState,
  (state) => state?.getRemittanceReceiptDownload,
);

export const selectMunicipalityHeaderDataState = createSelector(
  selectRemittanceSubmissionHistoryState,
  (state) => state.municipalityHeaderData,
);

export const selectWageRecordSectionFieldState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].wageRecordFieldState,
);

export const selectAvailableWageRecordSectionFields = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].availableWageRecordFieldsState,
);

export const selectRemittanceHistoryDataState = createSelector(
  selectRemittanceSubmissionHistoryState,
  (state) => state.dataState,
);

export const selectEditRemittanceParticipantState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.editRemittanceParticipantState,
);

export const selectValidateRemittanceParticipantState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.validateRemittanceResponse,
);

export const selectRemittanceFeesPaymentState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.remittanceFeesPayment,
);

export const selectRecordPaymentState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.recordPaymentState,
);
export const selectRunAuditWageFileState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].runAuditWageFileState,
);
export const selectRejectWageFileState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerWageProcessing.employerWageProcessingFeatureKey].rejectWageFileState,
);
export const selectSearchRemittanceParticipantResultState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.searchRemittanceParticipantResultState,
);
export const selectMunicipalityTransactionsState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromTransactions.municipalityTransactionsFeatureKey],
);
export const selectMunicipalityTransactionHeaderState = createSelector(
  selectMunicipalityTransactionsState,
  (state) => state.municipality,
);
export const selectListMunicipalityTransactionState = createSelector(
  selectMunicipalityTransactionsState,
  (state) => state.listMunicipalityTransactions,
);
export const selectListTransactionState = createSelector(
  selectMunicipalityTransactionsState,
  (state) => state.lisTransactions,
);

export const selectRefundCreditState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.refundCreditState,
);

export const selectRecordPaymentForMunicipalityStateState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.recordPaymentForMunicipalityState,
);

export const selectEditCashJournalState = createSelector(
  selectMunicipalityTransactionsState,
  (state) => state.editCashJournalTransaction,
);
export const selectValidateRemittanceSubmissionState = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state.validateRemittanceSubmission,
);

export const createEmployersSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey]?.createEmployers,
);

export const setEmployersSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey]?.setEmployers,
);

// Begin Payment Receipt
export const paymentReceiptSelector = createSelector(selectEmployerModuleState, (state) => state?.paymentReceipt);
export const getPaymentReceiptSelector = createSelector(paymentReceiptSelector, (state) => state?.getPaymentReceipt);
export const downloadPaymentReceiptSelector = createSelector(
  paymentReceiptSelector,
  (state) => state?.downloadPaymentReceipt,
);
// End Payment Receipt

export const getBuybackMakeupRemittanceCalculationResultSelector = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state?.getBuybackMakeupRemittanceCalculationResultState,
);

export const getAnnualSupplementalRemittanceCalculationResultSelector = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state?.getAnnualSupplementalRemittanceCalculationResultState,
);

/* Start of REMITTANCE PRINT INVOICE */
export const remittancePrintInvoiceSelector = createSelector(
  selectEmployerModuleState,
  (state) => state?.remittancePrintInvoice,
);
export const getRemittanceFeeInvoiceSelector = createSelector(
  remittancePrintInvoiceSelector,
  (state) => state?.getRemittanceFeeInvoice,
);
export const validateBeforePrintFeeInvoiceSelector = createSelector(
  remittancePrintInvoiceSelector,
  (state) => state?.validateBeforePrintFeeInvoice,
);
export const printRemittanceFeeInvoiceSelector = createSelector(
  remittancePrintInvoiceSelector,
  (state) => state?.printRemittanceFeeInvoice,
);
/* End of REMITTANCE PRINT INVOICE */

// Employer Document List
export const selectEmployerDocumentState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerDocument.employerDocumentFeatureKey],
);

export const selectEmployerDocumentListState = createSelector(
  selectEmployerDocumentState,
  (state) => state.getDocuments,
);

export const getGeneratedDocumentSelector = createSelector(
  selectEmployerDocumentState,
  (state) => state?.getGeneratedDocument,
);

export const getEmployerDocumentOverviewSelector = createSelector(
  selectEmployerDocumentState,
  (state) => state?.getEmployerDocumentOverview,
);

export const uploadEmployerDocumentSelector = createSelector(
  selectEmployerDocumentState,
  (state) => state?.uploadDocuments,
);

export const editEmployerDocumentSelector = createSelector(
  selectEmployerDocumentState,
  (state) => state?.editDocuments,
);

export const removeEmployerDocumentSelector = createSelector(
  selectEmployerDocumentState,
  (state) => state?.removeDocuments,
);

export const removeDocumentEmployerSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].removeState,
);

export const selectUploadDocumentEmployerOverviewState = createSelector(
  selectEmployerDocumentState,
  (state) => state?.uploadDocumentEmployerOverview,
);

// Employer Note

export const selectEmployerNoteState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployerNote.employerNoteFeatureKey],
);

export const getNotesSelector = createSelector(selectEmployerNoteState, (state) => state?.getNotes);

export const createNoteSelector = createSelector(selectEmployerNoteState, (state) => state?.createNote);

export const getAnnualFeeLetterSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualFeeLetter.annualFeeLetterFeatureKey].getAnnualFeeLetterState,
);

export const generateAnnualFeeLetterSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualFeeLetter.annualFeeLetterFeatureKey].generateAnnualFeeLetterState,
);

export const validateBeforeGenerateAnnualFeeLetterSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualFeeLetter.annualFeeLetterFeatureKey].validateBeforeGenerateState,
);

export const getAnnualFeeLetterDownloadSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualFeeLetter.annualFeeLetterFeatureKey].getAnnualFeeLetterDownloadState,
);

export const getCurrentEmployerDetailSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromAnnualFeeLetter.annualFeeLetterFeatureKey].getCurrentEmployerDetailState
);

export const validateRemittance2024ReceiptNumberSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromRemittance2024Confirmation.remittance2024ConfirmationFeatureKey].validateRemittance2024ReceiptNumberState,
);

export const getLateFeeReminderLetterSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromLateFeeReminderLetter.lateFeeReminderLetterFeatureKey].getLateFeeReminderLetterState,
);

export const generateLateFeeReminderLetterSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromLateFeeReminderLetter.lateFeeReminderLetterFeatureKey].generateLateFeeReminderLetterState,
);

export const validateBeforeGenerateLateFeeReminderSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromLateFeeReminderLetter.lateFeeReminderLetterFeatureKey].validateBeforeGenerateState,
);

export const getLateFeeReminderLetterDownloadSelector = createSelector(
  selectEmployerModuleState,
  (state) => state[fromLateFeeReminderLetter.lateFeeReminderLetterFeatureKey].getLateFeeReminderLetterDownloadState,
);

export const selectGetExportTransactionState = createSelector(
  selectMunicipalityTransactionsState,
  (state) => state.exportTransactionState,
);

export const selectListColumnSetEmployerState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].listColumnSetEmployer,
);

export const selectDashboardStripeState = createSelector(
  selectEmployerModuleState,
  (state) => state[fromEmployer.employerFeatureKey].paymentDashboardState,
);

export const getRemittanceStatusSelector = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state?.getRemittanceStatus,
);

export const getCashJournalEntriesSelector = createSelector(
  selectRemittanceSubmissionFeatureState,
  (state) => state?.getCashJournalEntries,
);

export const checkStripeConnectEmployerSelector = createSelector(
  selectEmployerListState,
  (state) => state?.checkConnectStripe,
);

export const registerCustomerStripeSelector = createSelector(selectEmployerListState, (state) => state?.customerStripe);

export const getVoidTransactionResultSelector = createSelector(
  selectEmployerListState,
  (state) => state?.voidTransactionResult,
);
