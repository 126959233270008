<div class="split-payment grid-container flex flex-col" [ngClass]="{ 'fit-to-parent': fitToParent }">
  <!-- HEADER -->
  <div class="split-payment__header flex justify-between items-center min-h-[64px] p-3">
    <span class="text-xl font-bold ml-1">{{ title }}</span>

    <div class="button-group" *ngIf="!isLoading">
      <ptg-button
        *ngIf="
          selectedTabPayment === TabPaymentInfo.Payment &&
          selectedRow?.payStatus !== PayStatus.Finalized &&
          isShowEditButton
        "
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="onEditPaymentDetail()"
        ><mat-icon>edit</mat-icon>
      </ptg-button>
      <!-- Out scope in release 1 -->
      <!-- <ptg-button
        *ngIf="selectedTabPayment === TabPaymentInfo.InstructionHistory"
        buttonName="Audit"
        classInput="edit-button"
        ><mat-icon>history</mat-icon>
      </ptg-button> -->
    </div>
  </div>

  <!-- BODY -->
  <div class="table-container" *ngIf="!isLoading" [ngClass]="{ 'hide-scrollbar': hideScrollbar }">
    <table *ngIf="!!columns?.length" mat-table [id]="id" [dataSource]="tableDataSource">
      <!-- BEGIN Generate the columns -->
      <ng-container *ngFor="let col of columns" [matColumnDef]="col.name">
        <th mat-header-cell *matHeaderCellDef [class]="getColumnClasses(col)" [ngStyle]="getHeaderStyle(col)">
          {{ col.header?.title || '' }}
        </th>
        <td mat-cell *matCellDef="let row" [class]="getColumnClasses(col)" [ngStyle]="getCellStyle(col)">
          <ng-container [ngSwitch]="col.type">
            <!-- Binding the common template by column type -->
            <ptg-number-decimal-column
              *ngSwitchCase="ColumnType.Decimal"
              [value]="row[col.name]"
              [decimal]="col.templateArgs?.decimal || col.templateArgs?.decimal === 0 ? col.templateArgs?.decimal : 2"
              [unit]="col.templateArgs?.unit"
              [unitPosition]="col.templateArgs?.unitPosition || 'left'"
              [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay"
            ></ptg-number-decimal-column>
            <ptg-datetime-column
              *ngSwitchCase="ColumnType.DateTime"
              [value]="row[col.name]"
              [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
              [emptyString]="col.templateArgs?.emptyString || ''"
            ></ptg-datetime-column>

            <ptg-view-rich-text
              *ngSwitchCase="ColumnType.RichText"
              [title]="col.header?.title"
              [content]="row[col.name]"
            ></ptg-view-rich-text>

            <ptg-phone-column
              *ngSwitchCase="ColumnType.PhoneNumber"
              [value]="row[col.name]"
              [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay || ''"
            ></ptg-phone-column>

            <ptg-icon-column
              *ngSwitchCase="ColumnType.Icon"
              [name]="row[col.templateArgs?.nameField]"
              [color]="row[col.templateArgs?.colorField]"
              [label]="col.templateArgs?.labelField ? row[col.templateArgs?.labelField] : ''"
              [style]="col.templateArgs?.styleField"
            ></ptg-icon-column>

            <ptg-address-column
              *ngSwitchCase="ColumnType.Address"
              [value]="row[col.name]"
              [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay || ''"
            ></ptg-address-column>
            <ptg-hyperlink-column
              *ngSwitchCase="ColumnType.Link"
              [text]="row[col.name]"
              [href]="col.templateArgs?.href ? row[col.templateArgs?.href] : ''"
              (clickLink)="col.templateArgs?.onClick ? col.templateArgs?.onClick(row) : null"
            ></ptg-hyperlink-column>

            <ptg-person-name-column
              *ngSwitchCase="ColumnType.PersonName"
              [value]="row[col.name]"
              [emptyValueDisplay]="col.templateArgs?.emptyValueDisplay || ''"
            ></ptg-person-name-column>

            <ptg-binary-column
              *ngSwitchCase="ColumnType.Binary"
              [value]="row[col.name]"
              [templateArgs]="col.templateArgs"
            ></ptg-binary-column>

            <ng-container *ngSwitchDefault>
              <!-- Binding the template from outside (if any) -->
              <ng-container
                [ngTemplateOutlet]="getCellContentTemplate(col.name) || defaultTemplate"
                [ngTemplateOutletContext]="{ $implicit: row }"
              ></ng-container>

              <!-- Transform display data with "cell" function (if any) -->
              <ng-template #defaultTemplate>
                {{ col.cell ? col.cell(row) : row[col.name] ? row[col.name] : col.templateArgs?.emptyString }}
              </ng-template>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- BEGIN Generate the rows -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: fixedHeader"
        [ngClass]="{ 'hide-header': hideHeader }"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; let idx = index; columns: displayedColumns"
        [class]="getRowClasses(row)"
        [class.selected-row]="idx === currentRowIndex"
        [style.background-color]="row.backgroundColor"
        cdkDrag
        cdkDragLockAxis="y"
      ></tr>
      <!-- END -->
    </table>
  </div>

  <div class="flex-grow flex">
    <div *ngIf="isLoading; else notLoading" class="spinner m-auto">
      <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
      <p>Loading...</p>
    </div>
    <ng-template #notLoading>
      <div *ngIf="!data?.length" class="not-found m-auto flex flex-col">
        <ng-container *ngIf="errorMessage; else notError">
          <div class="warning-icon">
            <mat-icon class="material-icons-round">warning</mat-icon>
          </div>
          <div>
            <span class="error-msg">{{ errorMessage }} error</span>
            <span>occurred, please try again later.</span>
          </div>
        </ng-container>

        <ng-template #notError>
          {{ notFoundMessage }}
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
