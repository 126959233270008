import { LayoutModule } from '@angular/cdk/layout';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';
import { ControlsModule } from '../controls/controls.module';
import { SharedModule } from '../shared.module';
import { RegisterDataPopupComponent } from './components/register-data-popup/register-data-popup.component';
import { RegisterExpandedComponent } from './components/register-expanded/register-expanded.component';
import { RegisterFilterComponent } from './components/register-filter/register-filter.component';
import { ReissueTransactionComponent } from './components/reissue-transaction/reissue-transaction.component';
import { SelectAddChipComponent } from './components/select-add-chip/select-add-chip.component';
import { ViewMarkReturnedRejectedComponent } from './components/view-mark-returned-rejected/view-mark-returned-rejected.component';
import { MODULE_KEY } from './constants';
import { RegisterComponent } from './pages/register/register.component';
import { MaskCharacterPipe } from './pipes/mask-character.pipe';
import { RegisterService } from './services/register.service';
import { TransactionRegisterEffects } from './store/effects/transaction-register.effect';
import { transactionRegisterReducers } from './store/reducers/transaction-register.reducer';

@NgModule({
  declarations: [
    RegisterComponent,
    RegisterExpandedComponent,
    RegisterDataPopupComponent,
    ViewMarkReturnedRejectedComponent,
    ReissueTransactionComponent,
    MaskCharacterPipe,
    RegisterFilterComponent,
    SelectAddChipComponent,
  ],
  exports: [RegisterComponent],
  imports: [
    SharedModule,
    ControlsModule,
    LayoutModule,
    StoreModule.forFeature(MODULE_KEY, transactionRegisterReducers),
    EffectsModule.forFeature(TransactionRegisterEffects),
    RouterModule,
    OverviewHeaderEntityModule,
  ],
  providers: [RegisterService],
})
export class TransactionModule {}
