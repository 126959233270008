<div class="drop-plan-container" [class.show-banner]="bannerType">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [functionButtons]="functionButtons"
    (emitFunction)="addReportConfig($event)"
  ></ptg-breadcrumb>
  <div class="drop-banner" *ngIf="bannerType">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>
  <ptg-grid
    #gridManageReport
    [data]="participantReport"
    [columns]="columns"
    [notFoundMessage]="notFoundMessage"
    keyColumn="id"
    [errorMessage]="errorMsg"
    [isLoading]="isLoading"
    [fitToParent]="true"
    [totalRecords]="totalRecords"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    (pageChange)="onChangePage($event)"
    (sortChange)="onChangeSort($event)"
  >
    <ng-template cellContent [columnName]="FILENAME_COLUMN" let-row>
      <mat-icon
        *ngIf="row.fileName"
        style="color: #2d6c71; vertical-align: middle; margin-bottom: 5px; cursor: pointer"
        (click)="onDownload(row?.file)"
        >save_alt</mat-icon
      >
      <span class="file-name"> {{ row?.fileName }}</span>
    </ng-template>
    <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
      <ptg-button buttonName="Edit" classInput="delete-button" (clickButton)="onClickEditItem(row)">
        <mat-icon>edit</mat-icon>
      </ptg-button>
      <ptg-button buttonName="Remove" classInput="delete-button" (clickButton)="onRemoveClick(row)">
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
