import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssnNumber'
})
export class SSNNumberPipe implements PipeTransform {

  transform(value: any, mask?: boolean) {
    if (value && (/^([0-9]{9})$/).test(value.trim())) {
      let match = value.match(/^(\d{3})(\d{2})(\d{4})$/);
      if (match) {
        if (mask) {
          return match[1].replace(/[0-9]/g, 'X') + '-' + match[2].replace(/[0-9]/g, 'X') + '-' + match[3];
        }
        value = match[1] + '-' + match[2] + '-' + match[3];
      }
    }
    return value;
  }

}
