import { Injectable } from '@angular/core';
import { BenefitCalculationErrorType } from '../../types/enums';

@Injectable()
export class AddDisabilityComponentService {
  getInvalidMessageOnEdit(errorType?: BenefitCalculationErrorType, confirmMessage?: string): string {
    switch (errorType) {
      case BenefitCalculationErrorType.CalculationNotInitiated:
        return 'Can only edit disability benefit if the disability calculation status is "Initiated", and there is no calculation record has been paid.';
      case BenefitCalculationErrorType.OverDueDate:
        return 'This benefit is already end. Cannot edit.';
      case BenefitCalculationErrorType.AccidentStatusNotApproved:
        return 'An approved accident must be selected.';
      case BenefitCalculationErrorType.AccidentFatalNotYes:
        return 'Can\'t initiate disability benefit with accident\'s fatal is "Yes".';
      case BenefitCalculationErrorType.InvalidLongTermDisabilityDate:
        return confirmMessage ?? '';
      default:
        return '';
    }
  }
}
