import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  clearHeaderConfigurationInDialogState,
  clearPaymentHeaderConfigurationState,
  createHeader,
  createHeaderFailure,
  createHeaderSuccess,
  getHeaderConfigApplyAction,
  getHeaderConfigApplySuccess,
  getHeaderDataApplyAction,
  getHeaderDataApplyFailure,
  getHeaderDataApplySuccess,
  getHeaderDetailAction,
  getHeaderDetailFailure,
  getHeaderDetailSuccess,
  getHeaderList,
  getHeaderListFailure,
  getHeaderListSuccess,
  getPaymentEntitiesAction,
  getPaymentEntitiesFailureAction,
  getPaymentEntitiesSuccessAction,
  setHeaderConfigurationInDialogAction,
  updatePaymentHeaderAction,
  updatePaymentHeaderFailure,
  updatePaymentHeaderSuccess,
} from '../actions/payment-header-configuration.actions';
import {
  GetPaymentHeaderDetailResponse,
  PaymentEntitiesResponse,
  PaymentHeaderConfigResponsive,
  PaymentHeaderConfigurationsResponsive,
  PaymentHeaderDataResponsive,
} from '../../types/models';

export interface PaymentHeaderConfigurationState {
  getHeaders?: BaseActionState<PaymentHeaderConfigurationsResponsive>;
  createHeader?: BaseActionState<string>;
  getEntities?: BaseActionState<PaymentEntitiesResponse>;
  getHeadersDetail?: BaseActionState<GetPaymentHeaderDetailResponse>;
  updateHeader?: BaseActionState;
  getHeadersConfigApply?: BaseActionState<PaymentHeaderConfigResponsive>;
  getHeadersDataApply?: BaseActionState<PaymentHeaderDataResponsive>;
  paymentHeaderConfigurationInDialog?: any;
}

export const PaymentHeaderConfigurationKey = 'paymentHeaderConfiguration';

const initialState: PaymentHeaderConfigurationState = {
  getHeaders: {
    isLoading: true,
    success: true,
    payload: undefined,
  },
};

export const PaymentHeaderConfigurationReducer = createReducer(
  initialState,
  on(clearPaymentHeaderConfigurationState, () => ({
    ...initialState,
  })),
  on(getHeaderList, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    getHeaders: {
      isLoading: true,
      success: false,
      payload: undefined,
    },
  })),
  on(getHeaderListSuccess, (state: PaymentHeaderConfigurationState, { headerList }) => ({
    ...state,
    getHeaders: {
      isLoading: false,
      success: true,
      payload: headerList,
    },
  })),
  on(getHeaderListFailure, (state, { error }) => ({
    ...state,
    getHeaders: {
      isLoading: false,
      success: false,
      payload: undefined,
    },
  })),

  on(createHeader, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    createHeader: {
      isLoading: false,
      success: true,
    },
  })),
  on(createHeaderSuccess, (state: PaymentHeaderConfigurationState, { body }) => ({
    ...state,
    createHeader: {
      isLoading: false,
      success: true,
      payload: body,
    },
  })),
  on(createHeaderFailure, (state, { error }) => ({
    ...state,
    createHeader: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(getPaymentEntitiesAction, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    getEntities: {
      isLoading: true,
      success: false,
    },
  })),

  on(getPaymentEntitiesFailureAction, (state: PaymentHeaderConfigurationState, { error }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: true,
      error: error,
    },
  })),

  on(getPaymentEntitiesSuccessAction, (state: PaymentHeaderConfigurationState, { response }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getHeaderDetailAction, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    getHeadersDetail: {
      isLoading: true,
      success: false,
    },
  })),

  on(getHeaderDetailSuccess, (state: PaymentHeaderConfigurationState, { headerDetail }) => ({
    ...state,
    getHeadersDetail: {
      isLoading: false,
      success: true,
      payload: headerDetail,
    },
  })),
  on(getHeaderDetailFailure, (state, { error }) => ({
    ...state,
    getHeadersDetail: {
      isLoading: false,
      payload: undefined,
    },
  })),
  on(updatePaymentHeaderAction, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    updateHeader: {
      isLoading: true,
      success: false,
    },
  })),
  on(updatePaymentHeaderSuccess, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    updateHeader: {
      isLoading: false,
      success: true,
    },
  })),
  on(updatePaymentHeaderFailure, (state, { error }) => ({
    ...state,
    updateHeader: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getHeaderConfigApplyAction, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    getHeadersConfigApply: {
      isLoading: true,
      success: false,
    },
  })),

  on(getHeaderConfigApplySuccess, (state: PaymentHeaderConfigurationState, { headerConfigApply }) => ({
    ...state,
    getHeadersConfigApply: {
      isLoading: false,
      success: true,
      payload: headerConfigApply,
    },
  })),
  on(getHeaderDetailFailure, (state, { error }) => ({
    ...state,
    getHeadersConfigApply: {
      isLoading: false,
      payload: undefined,
    },
  })),
  on(getHeaderDataApplyAction, (state: PaymentHeaderConfigurationState) => ({
    ...state,
    getHeadersDataApply: {
      isLoading: true,
      success: false,
    },
  })),

  on(getHeaderDataApplySuccess, (state: PaymentHeaderConfigurationState, { headerDataApply }) => ({
    ...state,
    getHeadersDataApply: {
      isLoading: false,
      success: true,
      payload: headerDataApply,
    },
  })),
  on(getHeaderDataApplyFailure, (state, { error }) => ({
    ...state,
    getHeadersDataApply: {
      isLoading: false,
      payload: undefined,
    },
  })),

  // State for storing saved Payment Header Configuration data
  on(setHeaderConfigurationInDialogAction, (state, { payload }) => ({
    ...state,
    paymentHeaderConfigurationInDialog: {
      ...state.paymentHeaderConfigurationInDialog,
      ...payload,
    },
  })),
  on(clearHeaderConfigurationInDialogState, (state) => ({
    ...state,
    paymentHeaderConfigurationInDialog: undefined,
  })),
);
