import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DomainType } from '@ptg-shared/constance/common.const';
import { ADMIN_SYSTEM } from '@ptg-shared/constance/value.const';
import { BehaviorSubject, Observable, Subject, from, interval, throwError } from 'rxjs';
import { catchError, filter, finalize, retry, takeUntil } from 'rxjs/operators';
import { PermissionModel } from '../../types/models/permission.model';
import { auth0Config } from '../configs/auth0.config';

@Injectable({ providedIn: 'root' })
export class Auth0Service implements OnDestroy {
  accountActivate: any = new BehaviorSubject(null);
  idTokenClaim: any;
  fundKey: string = '';
  expireTime$: any;
  name: string = '';
  readonly auth0Config = auth0Config;
  //Auth0 props
  readonly appState$ = this.auth0.appState$;
  readonly isAuthenticated$ = this.auth0.isAuthenticated$;
  readonly isLoading$ = this.auth0.isLoading$;
  readonly error$ = this.auth0.error$;
  readonly idTokenClaim$ = this.auth0.idTokenClaims$;
  readonly user$ = this.auth0.user$;
  private readonly _destroying$ = new Subject<void>();
  private _role: string = '';
  private _domainType: string = '';
  private _email: string = '';
  private _permissions?: PermissionModel[];
  isAdminPortal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Handle for getAccessTokenSilently
  isGettingAccessToken: boolean = false;

  constructor(private auth0: AuthService) {}

  get isSysAdmin(): boolean {
    return this.Role === ADMIN_SYSTEM;
  }

  get Role(): string {
    return this._role ? this._role : (localStorage.getItem('role') as string);
  }

  set Role(value: string) {
    this._role = value;
  }

  get Email(): string {
    return this._email ?? (localStorage.getItem('email') as string);
  }

  set Email(value: string) {
    this._email = value;
  }

  get Permissions(): PermissionModel[] {
    return this._permissions ?? JSON.parse(atob(localStorage.getItem('prm') ?? btoa('[]')));
  }

  set Permissions(value: any) {
    this._permissions = value;
  }

  initializeAuth(): void {
    this.idTokenClaim$.pipe(filter((idTokenClaim) => !!idTokenClaim)).subscribe((idTokenClaim) => {
      this.idTokenClaim = idTokenClaim;
      localStorage.setItem('idTokenClaim', JSON.stringify(this.idTokenClaim));

      if (!localStorage.getItem('role')) {
        this._role = this.idTokenClaim[`${auth0Config.claimNamespace}role`] ?? '';
        localStorage.setItem('role', this._role);
      } else this._role = localStorage.getItem('role') as string;

      if (!localStorage.getItem('email')) {
        this._email = this.idTokenClaim.email || '';
        localStorage.setItem('email', this._email);
      } else this._email = localStorage.getItem('email') as string;

      if (!localStorage.getItem('name')) {
        this.name = this.idTokenClaim.name;
        localStorage.setItem('name', this.name);
      } else this.name = localStorage.getItem('name') as string;

      if (!localStorage.getItem('prm')) {
        let permissions = this.idTokenClaim[`${auth0Config.claimNamespace}permissions`]
          ? JSON.parse(this.idTokenClaim[`${auth0Config.claimNamespace}permissions`])
          : [];
        this._permissions = permissions;
        localStorage.setItem('prm', btoa(JSON.stringify(this._permissions)));
      }

      this.fundKey = this.idTokenClaim[`${auth0Config.claimNamespace}fundkey`] || '';

      if (!localStorage.getItem('domainType')) {
        this._domainType = this.idTokenClaim.domainType ?? '';
        localStorage.setItem('domainType', this._domainType);
      } else {
        this._domainType = localStorage.getItem('domainType') as string;
      }
      this.isAdminPortal$.next(this._domainType !== DomainType.EmployerPortal);
    });

    // Refresh token every 1h
    const intervalTime = 1 * 60 * 60 * 1000; // 1 hour
    interval(intervalTime)
      .pipe(takeUntil(this._destroying$))
      .subscribe(() => this.auth0.getAccessTokenSilently({ ignoreCache: true }).subscribe());
  }

  refreshToken(retryCount: number = 3): Observable<string> | undefined {
    if (this.isGettingAccessToken) return undefined; // At one time, only one call needs to be made

    this.isGettingAccessToken = true;
    return from(this.auth0.getAccessTokenSilently({ ignoreCache: true })).pipe(
      retry(retryCount), // Retry the call with retryCount times
      catchError((error) => throwError(error)),
      finalize(() => (this.isGettingAccessToken = false)),
    );
  }

  login() {
    this.auth0.loginWithRedirect();
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    history.pushState(null, "null", window.location.origin);
    this.auth0.logout({ returnTo: window.location.origin, federated: true });
  }

  isLoggedIn() {
    return this.isAuthenticated$.pipe().subscribe((el) => el);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
