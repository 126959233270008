import { createAction, props } from '@ngrx/store';

import { generateActionName } from '@ptg-shared/utils/common.util';
import { CreateReversalAdjustmentParams, CreateReversalAdjustmentResponse } from '../../services/models';

// Actions for Adding a new Reversal Adjustment
export const createReversalAdjustmentAction = createAction(
  generateActionName('CreateReversalAdjustment AddReversalAdjustment/API').Send,
  props<{ params: CreateReversalAdjustmentParams }>(),
);
export const createReversalAdjustmentSuccess = createAction(
  generateActionName('CreateReversalAdjustment AddReversalAdjustment/API').Success,
  props<{ response: CreateReversalAdjustmentResponse }>(),
);
export const createReversalAdjustmentFailure = createAction(
  generateActionName('CreateReversalAdjustment AddReversalAdjustment/API').Failure,
  props<{ error: any }>(),
);
export const clearCreateReversalAdjustmentState = createAction(
  generateActionName('CreateReversalAdjustment AddReversalAdjustment').Clear,
);
