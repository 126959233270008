export enum EditPaymentStatusActionName {
  Suspend = 'suspend',
  Terminate = 'terminate',
  UnSuspended = 'unsuspended',
  UnTerminated = 'unterminated',
}

export enum EditPaymentStatusValidationMessage {
  AddressOrWithholdingInstructionNotConfigured = 'Payment Address or Withholding instruction had not been configured, please update Payroll setting before unsuspend.',
  PaymentAddressNotConfigured = 'Payment Address had not been configured, please update Payroll setting before unsuspend.',
  DepositAccountInactive = 'A deposit account is inactive, please update Split Payment before unsuspend.',
  LastMuniTypeRequired = 'Last Muni Type is required',
  NetPaymentLessThanZero = 'The net payment is less than zero, please adjust earning and deduction before unsuspend.',
  PrimaryPayeeBenefitSuspended = 'The primary payee benefit is suspended.',
  AnotherCorrectionOneTimePaymentCreated = 'Another Correction One-Time Payment had been created for the suspended period.',
  UnsuspendTheInitialPaymentFirst = 'Please unsuspend the Initial Payment first.'
}

export enum ValidateChangeLastPayrollCycleErrorType {
  LastPayrollCycleCannotBeSetupToPayInAdvanceInCaseOfFutureBenefitEndDate,
  AFinalPaymentIsBeingProcessed,
}

export const ValidateChangeLastPayrollCycleErrorTypeMessage = {
  LastPayrollCycleCannotBeSetupToPayInAdvanceInCaseOfFutureBenefitEndDate:
    'Last Payroll Cycle cannot be setup to pay in advance in case of future Benefit End Date.',
  AFinalPaymentIsBeingProcessed: 'A final payment is being processed, please cancel it before updating.',
};
