import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import { GetFinalPaymentConfigurationsResponse, SetFinalPaymentConfigurationsRequest } from './models';

@Injectable()
export class FinalPaymentConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getFinalPaymentConfigurations(): Observable<GetFinalPaymentConfigurationsResponse> {
    return this.httpClient.get<GetFinalPaymentConfigurationsResponse>(
      `${environment.apiUrl}/payroll-settings/final-payment-configuration`,
    );
  }

  setFinalPaymentConfigurations(
    body: SetFinalPaymentConfigurationsRequest
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/payroll-settings/final-payment-configuration`, body
    );
  }

}
