export enum FederalTableFormType {
  None,
  W4,
  W4P,
}

export enum FillingStatusType {
  None,
  Single,
  Married,
  SingleOrMarried,
  MarriedOrQualifySpouse,
  HeadOfHousehold,
}

export enum ConfigurationStatus {
  Unfinished,
  Ready,
}

export enum FlatType {
  None,
  Flat,
  Percent,
}

export enum PayeeSourceType {
  Person,
  Member,
  Participant,
  Organization,
  OrganizationContact,
  PowerOfAttorneyPerson,
  PowerOfAttorneyOrganization,
}
