import { EntityPropertyType } from "@ptg-entity-management/types/enums";

export const ADMIN_SYSTEM: string = 'SysAdmin';
export const ADMIN_FUND: string = 'FundAdmin';
export const CANCEL_CONFIRM_MESSAGE: string =
  'Are you sure you want to cancel any/all changes?';
export const DISCARD_CONFIRM_MESSAGE: string =
  'Are you sure you want to discard any/all changes?';
export const DELETE_CONFIRM_MESSAGE: string =
  'Are you sure you want to delete the record?';
export const ABA_ROUTING_CONFIRM_MESSAGE: string =
  'Changing this ABA Routing Number will update Bank Account information in all processing that uses this routing number as well. Is this your intention?';
export const STATE = {
  SUCCESS: 'Success',
  FAIL: 'Fail',
};
export const ENTITY_REMOVE_ATTENTION_MESSAGE: string = 'This Entity cannot be removed since it had been linked to another Entity or it is being used in a different configuration.';
export const ENTITY_REMOVE_CONFIRM_MESSAGE: string = 'Removing this Entity will remove all of its related data. Are you sure you want to proceed?';
export const ENTITY_REMOVE_ITEM_TITLE_CONFIRM: string = 'This is a destructive action';
export const ENTITY_REMOVE_ITEM_TITLE_ATTENTION: string = 'Attention';

export const BUTTON_LABEL_OK: string = 'OK';
export const BUTTON_LABEL_CLOSE: string = 'Close';
export const BUTTON_LABEL_YES: string = 'Yes';
export const BUTTON_LABEL_NO: string = 'No';

export const ELEMENT_TYPE = {
  BUTTON: 'button',
  INPUT: 'input',
  LINK: 'link',
  MAT_ERROR: 'mat-error',
};

export const PaymentStatus = {
  Check: 'Check',
  DirectDeposit: 'DirectDeposit',
  WireTransfer: 'WireTransfer',
  Both: 'Both',
};

export enum PaymentStep {
  Review,
  SendWarrant,
  IssuePayment,
  Finalized,
  Archived,
}

export const ACTION = {
  ADD: 'add',
  EDIT: 'edit',
  REMOVE: 'remove',
  UPLOAD: 'upload',
  LOCK: 'lock',
  UNLOCK: 'unlock',
  SAVE: 'save',
  RESET: 'reset',
  CANCEL: 'cancel',
  SCHEDULED: 'scheduled',
  GET: 'get',
  REJECT: 'reject',
  COMPUTE: 'compute',
  COMPLETE: 'complete',
  APPROVE: 'approve',
  REOPEN: 'reopen',
  DOWNLOAD: 'download',
  GENERATE: 'generate',
  ATTACH: 'attach',
  APPLY:'apply',
  INACTIVE: 'inactive',
  RESUBMIT: 'resubmit',
};

export const SORT_TYPE = {
  ASC: 0,
  DESC: 1,
};

export const TransactionTypeList = ['Payee', 'Service Provider'];
export const TransactionType = {
  Payee: 0,
  'Service Provider': 1,
};

export const PaymentTypeList = [
  'Check',
  'Direct Deposit',
  'Wire Transfer',
  'Adjustment',
  'Both',
  'No Payment'
];
export enum PaymentType {
  Check,
  'Direct Deposit',
  'Wire Transfer',
  Adjustment,
  Both,
  'No Payment'
}

export const TransactionStatusDisplay = [
  'Pending',
  'Posted',
  'Voided',
  'Reissued',
  'Reversed'
];
export const TransactionStatus = {
  Pending: 0,
  Posted: 1,
  Voided: 2,
  Reissued: 3,
  Reversed: 4
};

export enum BatchResponseStatus {
  Accepted,
  Rejected,
  Hold,
}

export enum PaymentResponseStatus {
  Accepted,
  Rejected,
}

export enum TransactionPaymentResponseStatus {
  Null,
  Accepted,
  Rejected,
}

export const ERROR_NAME: Record<number, string> = {
  403: '403 Forbidden',
  412: '412 Precondition Failed',
  503: '503 Service Unavailable',
};

export const ERROR_CODE_EXCLUDE = [0, 502, 504];

export const ERROR_TYPE_PAYMENT = {
  Internal: 0,
  Batch: 1,
  Transaction: 2,
  Segment: 3,
  Field: 4,
};

export const ERROR_TYPE_PAYMENT_STRING = [
  'Internal',
  'Batch',
  'Payment',
  'Segment',
  'Field',
];

export const NO_MATCHING_TAX_BRACKET_EXCEPTION: string =
  'NoMatchingTaxBracketException';
export const MISSING_PAYMENT_INFORMATION_EXCEPTION: string =
  'MissingPaymentInformaionException';

export enum PaymentInfoNoteType {
  Note,
  Memo,
  Reason,
}

export enum SortType {
  ASC,
  DESC,
}

export const TransactionProperty = {
  'Check Memo': 'Check Memo',
  Memo: 'Memo',
  Justification: 'Justification',
};
export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
export const ENTITY_ID_GUID = '6c8d4a07-1278-462e-a968-911d5cdffff1';
export const ENTITY_MEMBER_GUID = 'd478c688-445a-44be-af5f-b090fcbc8048';

// BE hardcode entity by guid
export const ENTITY_BENEFICIARIES_LIST_GUID = 'b880c2b6-77f0-4f5c-a63b-75371d31a494';
export const ENTITY_DEPENDENTS_LIST_GUID = 'b7015fe0-dd01-466d-86b7-fa4fbe5357ed';
export const ENTITY_PERSON_GUID = 'caa221ea-bfea-4a77-a08c-232ea7839b98';
export const ENTITY_ORGANIZATION_GUID = '5ba9f232-31c4-4c76-89e0-a84c8bba293e';
export const ENTITY_PARTICIPANT_GUID = '9d19b986-af2e-4b0d-88de-e281971dc3f9';
export const COLUMN_ORGANIZATION_NAME_GUID = 'fbf8abd8-f97c-47a5-b82b-376193240101';
export const COLUMN_MEMBER_NAME_GUID = '0ac1b906-c396-4bc5-a83b-eec542482445';
// BE hardcode entity by guid

export const UPDATED_BY = 'updatedBy';
export const CREATED_AT = 'createdAt';
export const CREATED_BY = 'createdBy';
export const UPDATED_AT = 'updatedAt';
export const SYSTEM_FIXED_PROPERTY_OPTIONS = [
  UPDATED_BY, UPDATED_AT, CREATED_BY, CREATED_AT,
];

export const NOT_USED_IN_UPSERT_FORM_PROPERTIES = [
  EntityPropertyType.Aggregation,
  EntityPropertyType.Calculation,
];

export class ActionState {
  state: string = STATE.SUCCESS || STATE.FAIL || '';
  action: string =
    ACTION.ADD || ACTION.EDIT || ACTION.REMOVE || ACTION.REJECT || ACTION.UPLOAD || ACTION.GENERATE || ACTION.ATTACH ||'';
}

export const MIN_NEGATIVE_VALUE_NUMBER = -9999999999999.99;
export const MIN_VALUE_NUMBER = 0.01;
export const MAX_VALUE_NUMBER = 9999999999999.99;
export const DEFAULT_MAX_TEXT_LENGTH = 250;
export const DEFAULT_MIN_PERCENTAGE_VALUE = 0;
export const DEFAULT_MAX_PERCENTAGE_VALUE = 100;

export enum AdjustmentType {
  Credit,
  Debit,
}
export enum ParticipantPropertyType {
  ParticipantMetadata,
  Aggregation,
  Calculation,
}

export const SourceWarrentPayment = {
  Default: 204,
  Normal: 614,
}

export const DEFAULT_PAGE_SIZE = 50;

export const DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_TIME_FORMAT = 'MM/dd/yyyy HH:mm:ss';

export const NUMBER_DAYS_ONE_YEAR = 365;
