import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import { EntityPropertyType } from '../types/enums';
import {
  CheckExistPropertyValueRequest,
  CheckExistPropertyValueResponse,
  CheckIsPrimarySavedRequest,
  CheckIsPrimarySavedResponse,
  CheckSumOfPercentageValueRequest,
  CheckSumOfPercentageValueResponse,
  GetInitiationPropertiesResponse,
  GetPayeePropertiesResponse,
  ModuleConnections,
  PayeeRequest,
  CheckExistSpouseRequest,
  CheckExistSpouseResponse,
  GetCourtOrderPayeeInfoRequest,
  CheckRelationshipPayeeInUseRequest,
} from './models';
import { getValueWithoutFormat, isEmpty } from '@ptg-shared/utils/string.util';
import { map } from 'rxjs/operators';

@Injectable()
export class EntityPropertyService {
  isPrimary: boolean = false;

  constructor(private httpClient: HttpClient) { }

  checkExistPropertyValue = (request: CheckExistPropertyValueRequest) => {
    if (request.value) {
      if (request.propertyType === EntityPropertyType.Date) {
        const datepipe = new DatePipe('en-US');
        request.value = request.value
          ? datepipe.transform(request.value, 'yyyy-MM-dd')
          : '';
      } else if (request.propertyType === EntityPropertyType.SSN || request.propertyType === EntityPropertyType.Phone) {
        request.value = getValueWithoutFormat(request.value);
      }

      let params = new HttpParams();
      params = params.append('value', request.value);
      if (request.id) {
        params = params.append('id', request.id);
      }
      if (request.index || request.index === 0) {
        params = params.append('index', request.index);
      }

      return this.httpClient.get<CheckExistPropertyValueResponse>(
        `${environment.apiUrl}/entities/${request.entityId}/components/${request.componentId}/properties/${request.propertyId}/existed-value`,
        { params }
      );
    }
    return of(null);
  };

  checkSumOfPercentageValue = (request: CheckSumOfPercentageValueRequest) => {
    let params = new HttpParams();
    params = params.append('value', request.value || 0);

    if (request.recordId) {
      params = params.append('id', request.recordId);
    }

    if (request.isCheckSumLessThan100 !== undefined) {
      params = params.append('IsCheckSumLessThan100', request.isCheckSumLessThan100);
    }

    return this.httpClient.get<CheckSumOfPercentageValueResponse>(
      `${environment.apiUrl}/entity-new/components/beneficiaries/${request.targetId}/validate-percentage`,
      { params }
    );
  };

  checkIsPrimarySavedValue = (request: CheckIsPrimarySavedRequest) => {
    let params = new HttpParams();

    if (request.recordId) {
      params = params.append('id', request.recordId);
    }

    // Only validate Primary in case Checkbox is Checked
    return request?.value === 'true'
      ?  
        this.httpClient.get<CheckIsPrimarySavedResponse>(
          `${environment.apiUrl}/entity-new/components/beneficiaries/${request.targetId}/validate-isprimary`,
          { params }
        )
      : of({ isValid: true });
  };

  getInitiationProperties({
    entityId,
    includeEntityReference,
    cardId,
    isList,
    isSummaryView,
    includeInitiationProperties,
    entityReferencePropertyId,
    screenId,
    includeEntitySystem
  }: {
    entityId: string,
    includeEntityReference?: boolean
    cardId?: string,
    isList?: boolean,
    isSummaryView?: boolean,
    includeInitiationProperties?: boolean,
    entityReferencePropertyId?: string,
    screenId?: string,
    includeEntitySystem?: boolean
  }): Observable<GetInitiationPropertiesResponse> {
    let params = new HttpParams();
    ([{includeEntityReference}, {cardId}, {isList}, {isSummaryView}, {includeInitiationProperties}, {entityReferencePropertyId}] as Record<string, string | boolean>[]).forEach((i) => {
      Object.keys(i).forEach((j: string) => {
        if (i[j]) params = params.append(j, i[j]);
      })
    })
    if (includeEntitySystem != undefined)
      params = params.append('includeEntitySystem', includeEntitySystem);
    if (screenId)
      params = params.append('screenId', encodeURIComponent(screenId));
    return this.httpClient.get<GetInitiationPropertiesResponse>(
      `${environment.apiUrl}/entities/${entityId}/initiation-properties`,
      { params }
    );
  }

  getModuleConnections(): Observable<ModuleConnections> {
    return this.httpClient.get<ModuleConnections>(`${environment.apiUrl}/entity/module-connections`);
  }

  getPayee(body: PayeeRequest): Observable<GetPayeePropertiesResponse> {
    let params =  new HttpParams();
    if(body.screenId){
      params = params.append('screenId', body.screenId);
    }
    if(body.courtOrderComponentId){
      params = params.append('courtOrderComponentId', body.courtOrderComponentId);
    }
    if(body.courtOrderType){
      params = params.append('courtOrderType', body.courtOrderType);
    }
    if(body.payeeId){
      params = params.append('payeeId', body.payeeId);
    }
    return this.httpClient.get<GetPayeePropertiesResponse>(
      `${environment.apiUrl}/entities/members/${body.memberId}/person-related-with-member`, { params }
    ).pipe(map(item => {
      return {...item, courtOrderType: body.courtOrderType };
    }));
  }

  checkExistSpouse = (request: CheckExistSpouseRequest) => {
    if (request.value) {
      let params = new HttpParams();
      params = params.append('spouseLookupId', request.value);
      if (request.lookupTableId) {
        params = params.append('lookupTableId', request.lookupTableId);
      }
      return this.httpClient.get<CheckExistSpouseResponse>(
        `${environment.apiUrl}/entities/members/components/list-data/${request.targetId}/validate-spouse`,
        { params }
      );
    }
    return null;
  };

  getCourtOrderPayeeInfo(request: GetCourtOrderPayeeInfoRequest) {
    const entityId = request?.entityId ?? '';
    const id = request?.id ?? '';

    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/entities/${entityId}/entity-data/${id}`,
    );
  }

  checkRelationshipPayeeInUse = (request: CheckRelationshipPayeeInUseRequest) => {
    let params = new HttpParams();

    if (request.recordId) {
      params = params.append('recordId', request.recordId);
    }

    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/components/relationships/${request.targetId}/payee-in-use`,
      { params }
    );
  }
}
