<div class="disability-calculation-detail" #memberDetail>
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [stepperState]="stepperState"
    [completedStep]="completedStep"
  ></ptg-breadcrumb>

  <ptg-overview-header-entity></ptg-overview-header-entity>

  <div class="px-6 pt-6">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>
  </div>

  <div class="disability-benefit-detail-grid flex flex-col gap-6 px-6 pb-6">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div
      class="button-container flex flex-wrap gap-3"
      *ngIf="detailData?.benefitCalculation?.status !== CalculationBenefitHistoryStatus.Approved"
    ></div>

    <div class="flex justify-between">
      <div
        class="detail__actions--button-group flex flex-wrap gap-3"
        *ngIf="detailData?.benefitCalculation?.status !== CalculationBenefitHistoryStatus.Approved"
      >
        <ptg-button
          *ngIf="detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus.Initiated"
          buttonName="Compute"
          classInput="secondary"
          (clickButton)="onClickCompute()"
        >
        </ptg-button>

        <ptg-button
          *ngIf="detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus.Initiated"
          buttonName="Complete"
          classInput="primary"
          (clickButton)="onClickComplete()"
        >
        </ptg-button>

        <ptg-button
          *ngIf="detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus['Pending Approval']"
          buttonName="Re-open"
          classInput="secondary"
          (clickButton)="onClickReopen()"
        >
        </ptg-button>

        <ptg-button
          *ngIf="detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus['Pending Approval']"
          buttonName="Approve"
          classInput="primary"
          (clickButton)="onClickApprove()"
        >
        </ptg-button>
      </div>
      <div
        [ngClass]="{ 'flex-grow': detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus.Approved }"
        class="flex justify-end min-w-[300px]"
      >
        <ptg-municipality-type
          #municipalityType
          *ngIf="
            [CalculationBenefitHistoryStatus['Pending Approval'], CalculationBenefitHistoryStatus.Approved].includes(
              $any(detailData?.benefitCalculation?.status)
            )
          "
          [currentStatus]="detailData?.benefitCalculation?.status"
          [request]="getEmployerTypesRequest"
        ></ptg-municipality-type>
      </div>
    </div>

    <div class="disability-detail-container" *ngIf="isShowReason">
      <div class="reason">
        <div>
          <mat-icon class="icon vertical-middle">calculate</mat-icon>
          <span class="font-bold vertical-middle pl-2">Recalculation Reason</span>
        </div>
        <div class="note-content">
          {{ isReadMore ? recalReason.note : (recalReason.note | slice: 0 : limitReasonLength)
          }}<span *ngIf="isShowSeeMore && !isReadMore">...</span
          ><span *ngIf="isShowSeeMore" class="see-more" (click)="isReadMore = !isReadMore">{{
            !isReadMore ? ' See more' : ' See less'
          }}</span>
        </div>
      </div>
    </div>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header flex justify-between">
          <span class="title font-bold">Exceptions</span>
        </div>

        <ptg-grid
          [data]="exceptionList"
          [columns]="columnException"
          [totalRecords]="totalExceptionRecords"
          [isLoading]="isExceptionLoading"
          [pageSize]="pageExceptionSize"
          [pageSizeOptions]="pageExceptionSizeOptions"
          [pageNumber]="pageExceptionIndex"
          notFoundMessage="No Exceptions to Display"
          (sortChange)="onChangeSortException($event)"
          (pageChange)="onChangeExceptionPage($event)"
        >
          <ng-template cellContent columnName="exceptionType" let-row>
            <mat-icon class="icon-table" style="color: red" matTooltip="Exception" *ngIf="row.exceptionType === 0">
              error_outline
            </mat-icon>
            <mat-icon class="icon-table" style="color: yellow" matTooltip="Warning" *ngIf="row.exceptionType === 1">
              warning
            </mat-icon>
          </ng-template>
        </ptg-grid>
      </div>
    </div>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header flex justify-between">
          <span class="title font-bold">Benefit Calculation</span>
          <div>
            <ptg-button
              buttonName="Edit"
              class="edit-button"
              *ngIf="isShowEditBtn"
              (clickButton)="onClickEditCalculation()"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
          </div>
        </div>
        <div class="disability-benefit__content flex flex-wrap" *ngIf="isLongTerm">
          <div class="disability-benefit__content--field">
            <div class="label">Member Amount</div>
            <div class="content">{{ (benefitCalculationData?.memberAmount | numberLocalDecimal: '$') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Spouse Amount</div>
            <div class="content">{{ (benefitCalculationData?.spouseAmount | numberLocalDecimal: '$') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Child Amount</div>
            <div class="content">{{ (benefitCalculationData?.childAmount | numberLocalDecimal: '$') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Child Number</div>
            <div class="content">{{ benefitCalculationData?.childNumber || '0' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Total</div>
            <div class="content">
              {{
                benefitCalculationData?.totals >= 0 ? (benefitCalculationData?.totals | numberLocalDecimal: '$') : '-'
              }}
            </div>
          </div>
        </div>
        <div class="disability-benefit__content flex flex-wrap">
          <div class="disability-benefit__content--field" *ngIf="!isLongTerm">
            <div class="label">Amount</div>
            <div class="content">{{ (benefitCalculationData?.memberAmount | numberLocalDecimal: '$') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field" *ngIf="!isLongTerm">
            <div class="label">Days</div>
            <div class="content">{{ benefitCalculationData?.days || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Calculation as of Date</div>
            <div class="content">{{ (calcAsOfDate | date: 'MM/dd/yyyy') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Calculation End Date</div>
            <div class="content">{{ (calcEndDate | date: 'MM/dd/yyyy') || '-' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="disability-detail-container" *ngIf="isLongTerm">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header flex justify-between">
          <span class="title font-bold">Dependents Information</span>
        </div>
        <div class="disability-benefit__content flex flex-wrap">
          <div class="disability-benefit__content--field">
            <div class="label">Name</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Relationship</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">SSN</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Date of Birth</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">Date of Death</div>
          </div>
        </div>
        <ng-container *ngFor="let dependentItem of dependentsInfor">
          <div class="disability-benefit__content list-info flex flex-wrap">
            <div class="disability-benefit__content--field">
              <div class="content weight-500">{{ dependentItem.name || '-' }}</div>
            </div>
            <div class="disability-benefit__content--field">
              <div class="content">{{ dependentItem?.relationship || '-' }}</div>
            </div>
            <div class="disability-benefit__content--field">
              <div class="content">{{ dependentItem?.ssn || '-' }}</div>
            </div>
            <div class="disability-benefit__content--field">
              <div class="content">{{ (dependentItem?.dateOfBirth | date: 'MM/dd/yyyy' : '+0') || '-' }}</div>
            </div>
            <div class="disability-benefit__content--field">
              <div class="content">{{ (dependentItem?.dateOfDeath | date: 'MM/dd/yyyy' : '+0') || '-' }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header document-header flex justify-between">
          <span class="title font-bold">Disability Benefit Documents</span>
          <ptg-button
            buttonName="Upload Document"
            classInput="upload-button"
            class="upload-file"
            (clickButton)="onUploadDocuments()"
            *ngIf="detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus.Initiated"
          >
            <mat-icon aria-hidden="false" aria-label="upload-icon">upload_file</mat-icon>
          </ptg-button>
        </div>

        <ptg-grid
          [data]="disabilityBenefitDocuments"
          [columns]="columnsBenefitDocuments"
          [totalRecords]="totalBenefitDocuments"
          [isLoading]="isDocumentLoading"
          [pageSize]="pageDocumentSize"
          [pageNumber]="pageDocumentIndex"
          notFoundMessage="No Disability Benefit Documents to Display"
          (sortChange)="onChangeSortDocuments($event)"
          (pageChange)="onChangeDocumentsPage($event)"
        >
          <ng-template cellContent columnName="fileName" let-row>
            <div class="flex file-document-container">
              <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank"
                ><span class="truncate">{{ row?.fileName }}</span></span
              >
            </div>
          </ng-template>
          <ng-template cellContent columnName="action" let-row>
            <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onEditDocument(row)">
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              stopPropagation
              buttonName="Remove"
              classInput="remove-button"
              *ngIf="detailData?.benefitCalculation?.status === CalculationBenefitHistoryStatus.Initiated"
              (clickButton)="onRemoveDocument(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header px-4 py-3">
          <span class="title font-bold">Audit Trails</span>
        </div>
        <ptg-grid
          [data]="calculationAuditTrails"
          [columns]="columnCalculationAuditTrail"
          [totalRecords]="totalCalculationAuditTrailRecords"
          [isLoading]="isCalculationAuditTrailLoading"
          [pageSize]="pageCalculationAuditTrailSize"
          [pageNumber]="pageCalculationAuditTrailIndex"
          notFoundMessage="No Audit Trails to Display"
          (sortChange)="onChangeSortAuditTrail($event)"
          (pageChange)="onChangeAuditTrailPage($event)"
        >
          <ng-template cellContent columnName="createdDate" let-row>
            {{ row.createdDate | date: 'MM/dd/yyyy hh:mm a' }}
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </div>
</div>
