export enum PaymentInsType {
  PaymentInstruction = 0,
  Payment = 1,
  Adjustment = 2,
}

export enum PayStatus {
  IssuePayment = 0,
  Suspended = 1,
  Pending = 2,
  Terminated = 3,
  Finalized = 4,
  Cancelled = 5,
  Closed = 6,
}

export enum TabPaymentInfo {
  Payment,
  InstructionHistory,
}

export enum PaymentInstructionType {
  InitialPayment = 1, // Manual-OneTime
  Recurring = 2, // Recurring
  Correction = 3, // Manual-OneTime
  Reissue = 4, // Manual-OneTime
  FinalPayment = 5, //Manual-OneTime
  PeriodicLumpsumPayment = 6, // Manual-OneTime
  AutoOneTime = 7, //Auto-OneTime
  Adjustment = 8, //Adjustment

  CorrectionPayingSuspendedPeriod = 21, // Manual-OneSubTime
  CorrectionUnderpaymentIncorrectBenefitAmount = 22, // Manual-OneSubTime
  CorrectionUnderpaymentIncorrectDeductionAmount = 23, // Manual-OneSubTime
  CorrectionOthers = 24, //Manual-OneSubTime

  AllOneTime = 253, // Technical Reserved
  AllRecurring = 254, // Technical Reserved
  AllType = 255, // Technical Reserved
}

export enum AdjustmentType {
  GenerateAdjustment = 0,
  DetailedAdjustment = 1,
}

export const PaymentInstructionTypeOneTimeList = [
  PaymentInstructionType.InitialPayment,
  PaymentInstructionType.Correction,
  PaymentInstructionType.Reissue,
  PaymentInstructionType.FinalPayment,
  PaymentInstructionType.PeriodicLumpsumPayment,
  PaymentInstructionType.AutoOneTime,
  PaymentInstructionType.AllOneTime,
  PaymentInstructionType.AllType,
  PaymentInstructionType.CorrectionOthers,
  PaymentInstructionType.CorrectionUnderpaymentIncorrectBenefitAmount,
  PaymentInstructionType.CorrectionUnderpaymentIncorrectDeductionAmount,
];

export const PaymentInstructionTypeLabel = {
  [PaymentInstructionType.InitialPayment]: 'Initial Payment',
  [PaymentInstructionType.Recurring]: 'Recurring',
  [PaymentInstructionType.Correction]: 'Correction',
  [PaymentInstructionType.Reissue]: 'Reissue',
  [PaymentInstructionType.FinalPayment]: 'Final Payment',
  [PaymentInstructionType.PeriodicLumpsumPayment]: 'Periodic Lumpsum Payment',
  [PaymentInstructionType.AutoOneTime]: 'Auto One Time',
  [PaymentInstructionType.Adjustment]: 'Adjustment',
  [PaymentInstructionType.CorrectionPayingSuspendedPeriod]: 'Correction Paying Suspended Period',
  [PaymentInstructionType.CorrectionUnderpaymentIncorrectBenefitAmount]:
    'Correction Underpayment Incorrect Benefit Amount',
  [PaymentInstructionType.CorrectionUnderpaymentIncorrectDeductionAmount]:
    'Correction Underpayment Incorrect Deduction Amount',
  [PaymentInstructionType.CorrectionOthers]: 'Correction Others',
  [PaymentInstructionType.AllOneTime]: 'All One Time',
  [PaymentInstructionType.AllRecurring]: 'All Recurring',
  [PaymentInstructionType.AllType]: 'All Type',
};

export const PaymentInstructionCorrectionType = [
  PaymentInstructionType.Correction,
  PaymentInstructionType.CorrectionOthers,
  PaymentInstructionType.CorrectionUnderpaymentIncorrectBenefitAmount,
  PaymentInstructionType.CorrectionUnderpaymentIncorrectDeductionAmount,
];

export const PaymentInstructionLumpsumType = [
  PaymentInstructionType.PeriodicLumpsumPayment,
  PaymentInstructionType.AutoOneTime,
];

export const PayStatusLabel = {
  [PayStatus.IssuePayment]: 'Issue Payment',
  [PayStatus.Suspended]: 'Suspended',
  [PayStatus.Pending]: 'Pending',
  [PayStatus.Terminated]: 'Terminated',
  [PayStatus.Finalized]: 'Finalized',
  [PayStatus.Cancelled]: 'Cancelled',
  [PayStatus.Closed]: 'Terminated',
};

export enum PaymentInfoAdjustmentType {
  PaymentInstruction,
  Adjustment,
}

export enum PaymentStatusAdjustmentType {
  Created = 1,
  Approved = 2,
  Rejected = 3,
}
export const AdjustmentStatusLabel = {
  [PaymentStatusAdjustmentType.Created]: 'Created',
  [PaymentStatusAdjustmentType.Rejected]: 'Rejected',
  [PaymentStatusAdjustmentType.Approved]: 'Approved',
};

export enum TaxSettingType {
  PostTax = 0,
  PreTax = 1
}
