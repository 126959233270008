import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  GetDocumentListConfigurationPropertiesRequest,
  GetDocumentListConfigurationPropertiesResponse,
  GetDocumentListConfigurationDetailRequest,
  GetDocumentListConfigurationDetailResponse,
  UpdateDocumentListConfigurationRequest,
} from './models/document-list-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentListConfigurationService {
  constructor(private httpClient: HttpClient) {}

  // Get Document List Configuration Properties
  getDocumentListConfigurationProperties(
    request: GetDocumentListConfigurationPropertiesRequest,
  ): Observable<GetDocumentListConfigurationPropertiesResponse> {

    return this.httpClient.get<GetDocumentListConfigurationPropertiesResponse>(
      `${environment.apiUrl}/documents/properties`,
    );
  }

  // Get Document List Configuration Detail
  getDocumentListConfigurationDetail(
    request: GetDocumentListConfigurationDetailRequest,
  ): Observable<GetDocumentListConfigurationDetailResponse> {

    return this.httpClient.get<GetDocumentListConfigurationDetailResponse>(
      `${environment.apiUrl}/documents/list-view-configuration`,
    );
  }

  // Update Document List Configuration
  updateDocumentListConfiguration(
    request: UpdateDocumentListConfigurationRequest,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/documents/list-view-configuration`,
      request,
    );
  }
}
