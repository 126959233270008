import { createReducer, on } from '@ngrx/store';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  AccidentClaim,
  Municipality,
  AccidentHistory,
  ViewAccidentDetailsResponse,
  AccidentDocumentList,
  AccidentLetterResponse,
  GetAccidentClaimsResponse
} from '../../services/models';
import {
  addAccidentPostCardAction,
  addAccidentPostCardFailureAction,
  addAccidentPostCardSuccessAction,
  clearAccidentPostCardAction,
  clearGetAccidentClaimsStateAction,
  clearGetListMunicipalityStateAction,
  editAccidentPostCardAction,
  editAccidentPostCardFailureAction,
  editAccidentPostCardSuccessAction,
  getAccidentClaimsAction,
  getAccidentClaimsFailureAction,
  getAccidentClaimsSuccessAction,
  getListMunicipalityAction,
  getListMunicipalityFailureAction,
  getListMunicipalitySuccessAction,
  clearGetAccidentHistoriesStateAction,
  getAccidentHistoriesAction,
  getAccidentHistoriesFailureAction,
  getAccidentHistoriesSuccessAction,
  removeAccidentHistoryAction,
  removeAccidentHistorySuccessAction,
  removeAccidentHistoryFailureAction,
  clearAccidentHistoryAction,
  createAccidentClaimsAction,
  createAccidentClaimsSuccessAction,
  createAccidentClaimsFailureAction,
  clearCreateAccidentClaimsStateAction,
  getVendorsMasterDataAction,
  getVendorsMasterDataSuccessAction,
  getVendorsMasterDataFailureAction,
  clearGetVendorsMasterDataStateAction,
  getDisbursementReportsAction,
  getDisbursementReportsSuccessAction,
  getDisbursementReportsFailureAction,
  clearGetDisbursementReportsStateAction,
  editAccidentClaimsAction,
  editAccidentClaimsSuccessAction,
  editAccidentClaimsFailureAction,
  clearEditAccidentClaimsStateAction,
  removeAccidentClaimAction,
  removeAccidentClaimSuccessAction,
  removeAccidentClaimFailureAction,
  clearAccidentClaimAction,
  getAccidentClaimDetailAction,
  getAccidentClaimDetailSuccessAction,
  getAccidentClaimDetailFailureAction,
  clearGetAccidentClaimDetailStateAction,
  viewAccidentDetailsAction,
  clearViewAccidentDetailsStateAction,
  viewAccidentDetailsFailureAction,
  viewAccidentDetailsSuccessAction,
  addAccidentReportAction,
  addAccidentReportFailureAction,
  addAccidentReportSuccessAction,
  clearAccidentReportAction,
  getAccidentDocumentsAction,
  getAccidentDocumentsSuccessAction,
  getAccidentDocumentsFailureAction,
  clearGetAccidentDocumentsStateAction,
  uploadAccidentDocumentsAction,
  uploadAccidentDocumentsSuccessAction,
  uploadAccidentDocumentsFailureAction,
  clearUploadAccidentDocumentsStateAction,
  createAccidentNoteAction,
  createAccidentNoteSuccessAction,
  createAccidentNoteFailureAction,
  clearCreateAccidentNoteStateAction,
  getAccidentNotesAction,
  getAccidentNotesSuccessAction,
  getAccidentNotesFailureAction,
  clearGetAccidentNotesStateAction,
  editAccidentReportSuccessAction,
  editAccidentReportFailureAction,
  clearEditAccidentReportAction,
  editAccidentReportAction,
  getAccidentDocumentDownloadAction,
  getAccidentDocumentDownloadSuccessAction,
  getAccidentDocumentDownloadFailureAction,
  clearGetAccidentDocumentDownloadStateAction,
  clearEditParticipantAccidentStatusAction,
  editParticipantAccidentStatusAction,
  editParticipantAccidentStatusFailureAction,
  editParticipantAccidentStatusSuccessAction,
  clearRemoveAccidentDocumentAction,
  removeAccidentDocumentAction,
  removeAccidentDocumentFailureAction,
  removeAccidentDocumentSuccessAction,
  getAccidentPostcardLetterAction,
  getAccidentPostcardLetterSuccessAction,
  getAccidentPostcardLetterFailureAction,
  clearGetAccidentPostcardLetterStateAction,
  getAccidentReportLetterAction,
  getAccidentReportLetterSuccessAction,
  getAccidentReportLetterFailureAction,
  clearGetAccidentReportLetterStateAction,
  clearEditAccidentDocumentStateAction,
  editAccidentDocumentAction,
  editAccidentDocumentFailureAction,
  editAccidentDocumentSuccessAction,
  getAccidentClaimsReportAction,
  clearGetAccidentClaimsReportStateAction,
  getAccidentClaimsReportFailureAction,
  getAccidentClaimsReportSuccessAction,
} from '../actions';
import { DisbursementReportDetail, Vendor } from 'src/app/admin/features/external-payment/services/models';

export interface AccidentParticipantsState {
  getAccidentClaims?: BaseActionState<AccidentClaim[]>;
  getMunicipality?: BaseActionState<Municipality[]>;
  viewAccidentDetails?: BaseActionState<ViewAccidentDetailsResponse>;
  accidentPostCardState?: any;
  accidentReportState?: ActionState;
  editAccidentReportState?: ActionState;
  editParticipantAccidentStatusState?: {
    state: ActionState,
    errorMsg?: string
  }

  getAccidentHistories?: BaseActionState<AccidentHistory[]>;
  accidentHistory?: {
    accidentHistoryState: ActionState,
    errorMsg?: string
  };
  createAccidentClaimState?: {
    state: ActionState,
    errorMsg?: string
  }
  editAccidentClaimState?: {
    state: ActionState,
    errorMsg?: string
  }
  getVendorsMasterData?: BaseActionState<Vendor[]>;
  getDisbursementReports?: BaseActionState<DisbursementReportDetail[]>;
  accidentClaim?: {
    accidentClaimState: ActionState,
    errorMsg?: string
  }
  getAccidentClaimDetail?: BaseActionState<GetAccidentClaimsResponse>;
  getAccidentDocuments?: BaseActionState<AccidentDocumentList[]>;
  uploadAccidentDocuments?: ActionState;
  getAccidentDocumentDownload?: BaseActionState<Blob[]>;
  getAccidentPostcardLetter?: BaseActionState<AccidentLetterResponse>;
  getAccidentReportLetter?: BaseActionState<AccidentLetterResponse>;
  getAccidentClaimsReport?: BaseActionState<Blob[]>;
  accidentNote?: ActionState;
  getAccidentNotes?: BaseActionState<any[]>;
  removeAccidentDocumentState?: ActionState;
  editAccidentDocumentState?: {
    state: ActionState,
    errorMsg?: string
  }
}

const initialState: AccidentParticipantsState = {};

export const accidentReducer = createReducer(
  initialState,
  on(getAccidentClaimsAction, (state) => ({
    ...state,
    getAccidentClaims: {
      isLoading: true,
    },
  })),
  on(getAccidentClaimsSuccessAction, (state, { response }) => ({
    ...state,
    getAccidentClaims: {
      isLoading: false,
      success: true,
      payload: response.claims,
      total: response.total,
    },
  })),
  on(getAccidentClaimsFailureAction, (state, { error }) => ({
    ...state,
    getAccidentClaims: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAccidentClaimsStateAction, (state) => ({
    ...state,
    getAccidentClaims: undefined,
  })),

  on(getAccidentHistoriesAction, (state) => ({
    ...state,
    getAccidentHistories: {
      isLoading: true,
    },
  })),
  on(getAccidentHistoriesSuccessAction, (state, { response }) => ({
    ...state,
    getAccidentHistories: {
      isLoading: false,
      success: true,
      payload: response.accidents,
      total: response.total,
    },
  })),
  on(getAccidentHistoriesFailureAction, (state, { error }) => ({
    ...state,
    getAccidentHistories: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAccidentHistoriesStateAction, (state) => ({
    ...state,
    getAccidentHistories: undefined,
  })),

  on(removeAccidentHistoryAction, (state) => ({
    ...state,
    isLoading: false,
    accidentHistory: undefined
  })),
  on(removeAccidentHistorySuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentHistory: {
      accidentHistoryState: {
        action: ACTION.REMOVE,
        state: STATE.SUCCESS,
      },
    },
  })),
  on(removeAccidentHistoryFailureAction, (state, { error }) => {
    return {
      ...state,
      accidentHistory: {
        accidentHistoryState: {
          action: ACTION.REMOVE,
          state: STATE.FAIL,
        },
        errorMsg: error ?? ''
      },

    }
  }),
  on(clearAccidentHistoryAction, (state) => ({
    ...state,
    accidentHistory: undefined,
  })),

  on(createAccidentClaimsAction, (state) => ({
    ...state,
    isLoading: false,
    createAccidentClaimState: undefined

  })),
  on(createAccidentClaimsSuccessAction, (state) => ({
    ...state,
    isLoading: false,
    createAccidentClaimState: {
      state: {
        action: ACTION.ADD,
        state: STATE.SUCCESS,
      }
    }
  })),
  on(createAccidentClaimsFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    createAccidentClaimState: {
      state: {
        action: ACTION.ADD,
        state: STATE.FAIL,
      },
      errorMsg: error
    }
  })),
  on(clearCreateAccidentClaimsStateAction, (state) => ({
    ...state,
    createAccidentClaimState: undefined,
  })),

  // Edit accident claim
  on(editAccidentClaimsAction, (state) => ({
    ...state,
    isLoading: false,
    editAccidentClaimState: undefined

  })),
  on(editAccidentClaimsSuccessAction, (state) => ({
    ...state,
    isLoading: false,
    editAccidentClaimState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    }
  })),
  on(editAccidentClaimsFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    editAccidentClaimState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL
      },
      errorMsg: error
    }
  })),
  on(clearEditAccidentClaimsStateAction, (state) => ({
    ...state,
    editAccidentClaimState: undefined,
  })),

  on(removeAccidentClaimAction, (state) => ({
    ...state,
    isLoading: false,
    accidentClaim: undefined
  })),
  on(removeAccidentClaimSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentClaim: {
      accidentClaimState: {
        action: ACTION.REMOVE,
        state: STATE.SUCCESS,
      },
    },
  })),
  on(removeAccidentClaimFailureAction, (state, { error }) => {
    return {
      ...state,
      accidentClaim: {
        accidentClaimState: {
          action: ACTION.REMOVE,
          state: STATE.FAIL,
        },
        errorMsg: error ?? ''
      },

    }
  }),
  on(clearAccidentClaimAction, (state) => ({
    ...state,
    accidentClaim: undefined,
  })),
  on(getListMunicipalityAction, (state) => ({
    ...state,
    getMunicipality: {
      isLoading: true,
    },
  })),
  on(getListMunicipalitySuccessAction, (state, { response }) => ({
    ...state,
    getMunicipality: {
      isLoading: false,
      success: true,
      payload: response.municipalities
    },
  })),
  on(getListMunicipalityFailureAction, (state, { error }) => ({
    ...state,
    getMunicipality: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListMunicipalityStateAction, (state) => ({
    ...state,
    getMunicipality: undefined,
  })),
  on(addAccidentPostCardAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentPostCardState: undefined,
  })),
  on(addAccidentPostCardSuccessAction, (state, { accidentId }) => ({
    ...state,
    isLoading: false,
    accidentPostCardState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
      accidentId: accidentId,
    },
  })),
  on(addAccidentPostCardFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    accidentPostCardState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
      message: error
    },
  })),
  on(editAccidentPostCardAction, (state, { }) => ({
    ...state,
    isLoading: false,
    AccidentPostCardState: undefined,
  })),
  on(editAccidentPostCardSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentPostCardState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(editAccidentPostCardFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    accidentPostCardState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
      message: error
    }
  })),
  on(clearAccidentPostCardAction, (state) => ({
    ...state,
    isLoading: false,
    accidentPostCardState: undefined
  })),

  on(getAccidentClaimDetailAction, (state) => ({
    ...state,
    getAccidentClaimDetail: {
      isLoading: true,
    },
  })),

  on(getAccidentClaimDetailSuccessAction, (state, { response }) => ({
    ...state,
    getAccidentClaimDetail: {
      isLoading: false,
      success: true,
      payload: response,
      total: response.total,
    },
  })),
  on(getAccidentClaimDetailFailureAction, (state, { error }) => ({
    ...state,
    getAccidentClaimDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearGetAccidentClaimDetailStateAction, (state) => ({
    ...state,
    getAccidentClaimDetail: undefined,
  })),

  on(getVendorsMasterDataAction, (state) => ({
    ...state,
    getVendorsMasterData: {
      isLoading: true,
    },
  })),
  on(getVendorsMasterDataSuccessAction, (state, { response }) => ({
    ...state,
    getVendorsMasterData: {
      isLoading: false,
      success: true,
      payload: response.vendors,
      total: response.total,
    },
  })),
  on(getVendorsMasterDataFailureAction, (state, { error }) => ({
    ...state,
    getVendorsMasterData: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetVendorsMasterDataStateAction, (state) => ({
    ...state,
    getVendorsMasterData: undefined,
  })),

  on(getDisbursementReportsAction, (state) => ({
    ...state,
    getDisbursementReports: {
      isLoading: true,
    },
  })),
  on(getDisbursementReportsSuccessAction, (state, { response }) => ({
    ...state,
    getDisbursementReports: {
      isLoading: false,
      success: true,
      payload: response.disbursementReports,
      total: response.total,
    },
  })),
  on(getDisbursementReportsFailureAction, (state, { error }) => ({
    ...state,
    getDisbursementReports: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),
  on(clearGetDisbursementReportsStateAction, (state) => ({
    ...state,
    getDisbursementReports: undefined,
  })),

  on(viewAccidentDetailsAction, (state) => ({
    ...state,
    viewAccidentDetails: {
      isLoading: true,
    },
  })),
  on(viewAccidentDetailsSuccessAction, (state, { response }) => ({
    ...state,
    viewAccidentDetails: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(viewAccidentDetailsFailureAction, (state, { error }) => ({
    ...state,
    viewAccidentDetails: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearViewAccidentDetailsStateAction, (state) => ({
    ...state,
    viewAccidentDetails: undefined,
  })),

  on(addAccidentReportAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentReportState: undefined,
  })),
  on(addAccidentReportSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentReportState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
  })),
  on(addAccidentReportFailureAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentReportState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
    },
  })),
  on(clearAccidentReportAction, (state) => ({
    ...state,
    isLoading: false,
    accidentReportState: undefined
  })),

  on(getAccidentDocumentsAction, (state) => ({
    ...state,
    getAccidentDocuments: {
      isLoading: true,
    },
  })),
  on(getAccidentDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    getAccidentDocuments: {
      isLoading: false,
      success: true,
      payload: response.documents,
      total: response.total
    },
  })),
  on(getAccidentDocumentsFailureAction, (state, { error }) => ({
    ...state,
    getAccidentDocuments: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),

  on(clearGetAccidentDocumentsStateAction, (state) => ({
    ...state,
    getAccidentDocuments: undefined,
  })),

  on(uploadAccidentDocumentsAction, (state) => ({
    ...state,
    uploadAccidentDocuments: undefined

  })),
  on(uploadAccidentDocumentsSuccessAction, (state) => ({
    ...state,
    uploadAccidentDocuments: {
      action: ACTION.UPLOAD,
      state: STATE.SUCCESS,
    },
  })),
  on(uploadAccidentDocumentsFailureAction, (state, { error }) => ({
    ...state,
    uploadAccidentDocuments: {
      action: ACTION.UPLOAD,
      state: STATE.FAIL,
    },
  })),
  on(clearUploadAccidentDocumentsStateAction, (state) => ({
    ...state,
    uploadAccidentDocuments: undefined,
  })),


  on(getAccidentDocumentDownloadAction, (state) => ({
    ...state,
    getAccidentDocumentDownload: {
      isLoading: true,
    },
  })),
  on(getAccidentDocumentDownloadSuccessAction, (state, {response}) => ({
    ...state,
    getAccidentDocumentDownload: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getAccidentDocumentDownloadFailureAction, (state, { error }) => ({
    ...state,
    getAccidentDocumentDownload: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAccidentDocumentDownloadStateAction, (state) => ({
    ...state,
    getAccidentDocumentDownload: undefined,
  })),

  on(getAccidentPostcardLetterAction, (state) => ({
    ...state,
    getAccidentPostcardLetter: {
      isLoading: true,
    },
  })),
  on(getAccidentPostcardLetterSuccessAction, (state, {response}) => ({
    ...state,
    getAccidentPostcardLetter: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getAccidentPostcardLetterFailureAction, (state, { error }) => ({
    ...state,
    getAccidentPostcardLetter: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAccidentPostcardLetterStateAction, (state) => ({
    ...state,
    getAccidentPostcardLetter: undefined,
  })),

  on(getAccidentReportLetterAction, (state) => ({
    ...state,
    getAccidentReportLetter: {
      isLoading: true,
    },
  })),
  on(getAccidentReportLetterSuccessAction, (state, {response}) => ({
    ...state,
    getAccidentReportLetter: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getAccidentReportLetterFailureAction, (state, { error }) => ({
    ...state,
    getAccidentReportLetter: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAccidentReportLetterStateAction, (state) => ({
    ...state,
    getAccidentReportLetter: undefined,
  })),

  on(getAccidentClaimsReportAction, (state) => ({
    ...state,
    getAccidentClaimsReport: {
      isLoading: true,
    },
  })),
  on(getAccidentClaimsReportSuccessAction, (state) => ({
    ...state,
    getAccidentClaimsReport: {
      isLoading: false,
      success: true,
    },
  })),
  on(getAccidentClaimsReportFailureAction, (state, { error }) => ({
    ...state,
    getAccidentClaimsReport: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAccidentClaimsReportStateAction, (state) => ({
    ...state,
    getAccidentClaimsReport: undefined,
  })),

  on(createAccidentNoteAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentNote: undefined,
  })),
  on(createAccidentNoteSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentNote: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
  })),
  on(createAccidentNoteFailureAction, (state, { }) => ({
    ...state,
    isLoading: false,
    accidentNote: {
      action: ACTION.ADD,
      state: STATE.FAIL,
    },
  })),
  on(clearCreateAccidentNoteStateAction, (state) => ({
    ...state,
    isLoading: false,
    accidentNote: undefined
  })),

  on(getAccidentNotesAction, (state) => ({
    ...state,
    getAccidentNotes: {
      isLoading: true,
    },
  })),
  on(getAccidentNotesSuccessAction, (state, { response }) => ({
    ...state,
    getAccidentNotes: {
      isLoading: false,
      success: true,
      payload: response.notes,
      total: response.total,
    },
  })),
  on(getAccidentNotesFailureAction, (state, { error }) => ({
    ...state,
    getAccidentNotes: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearGetAccidentNotesStateAction, (state) => ({
    ...state,
    getAccidentNotes: undefined,
  })),

  on(editAccidentReportAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editAccidentReportState: undefined,
  })),
  on(editAccidentReportSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editAccidentReportState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(editAccidentReportFailureAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editAccidentReportState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(clearEditAccidentReportAction, (state) => ({
    ...state,
    isLoading: false,
    editAccidentReportState: undefined
  })),

  on(editParticipantAccidentStatusAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editParticipantAccidentStatusState: undefined,
  })),
  on(editParticipantAccidentStatusSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editParticipantAccidentStatusState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(editParticipantAccidentStatusFailureAction, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    editParticipantAccidentStatusState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearEditParticipantAccidentStatusAction, (state) => ({
    ...state,
    isLoading: false,
    editParticipantAccidentStatusState: undefined
  })),

  on(removeAccidentDocumentAction, (state, { }) => ({
    ...state,
    isLoading: false,
    removeAccidentDocumentState: undefined,
  })),
  on(removeAccidentDocumentSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    removeAccidentDocumentState: {
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
    },
  })),
  on(removeAccidentDocumentFailureAction, (state, { }) => ({
    ...state,
    isLoading: false,
    removeAccidentDocumentState: {
      action: ACTION.REMOVE,
      state: STATE.FAIL,
    },
  })),
  on(clearRemoveAccidentDocumentAction, (state) => ({
    ...state,
    isLoading: false,
    removeAccidentDocumentState: undefined
  })),

  // Edit Accident Document
  on(editAccidentDocumentAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editAccidentDocumentState: undefined,
  })),
  on(editAccidentDocumentSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editAccidentDocumentState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(editAccidentDocumentFailureAction, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    editAccidentDocumentState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearEditAccidentDocumentStateAction, (state) => ({
    ...state,
    isLoading: false,
    editAccidentDocumentState: undefined
  })),
);
