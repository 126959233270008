<div class="date-month-picker">
  <mat-form-field appearance="fill" class="{{class}}">
    <mat-label
      [ngClass]="[
        (controlField?.hasValidator(Validators.required) || shouldShowRequiredIndicator)
          ? REQUIRED_INDICATOR
          : '',
      ]">
      {{placeholder}}
    </mat-label>
    <input matInput
      [matDatepicker]="picker"
      [formControl]="controlField"
      [min]="minDate"
      [max]="maxDate"
      (dateChange)="onChangeValue($event)"
      >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker panelClass="date-picker-container" startView="year" (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
    <mat-error *ngIf="controlField?.errors?.required && !controlField?.errors?.matDatepickerParse">{{this.placeholder}} is required.</mat-error>
    <mat-error *ngIf="controlField?.errors?.inValidAsync">{{errorAsync}}</mat-error>
    <mat-error *ngIf="controlField?.errors?.matDatepickerParse">{{errorInvalid}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.required && !controlField?.errors?.matDatepickerParse && controlField?.errors?.matDatepickerMin">{{errorMinDate}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.required && !controlField?.errors?.matDatepickerParse && controlField?.errors?.matDatepickerMax">{{errorMaxDate}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.required && !controlField?.errors?.matDatepickerParse && !controlField?.errors?.matDatepickerMin && controlField?.errors?.inRange">{{errorRange}}</mat-error>
  </mat-form-field>
</div>
