import { createAction, props } from "@ngrx/store";
import { BenefitPeriodPayload, GetPaymentNoteMemoRequest, GetPaymentNoteMemoResponse, PayableDatePayload, UpdatePaymentNoteMemoRequest } from "../../types/models";
import { generateActionName } from "@ptg-shared/utils/common.util";

export const clearNoteMemoState = createAction(
  '[ClearNoteMemoState]',
);

export const getNotesAction = createAction(
  '[PayeeNotes/API] Send Request',
  props<{ query: GetPaymentNoteMemoRequest, paymentInstructionId: string }>()
);
export const getNotesSuccess = createAction(
  '[PayeeNotes/API] Success',
  props<{ paymentInfoNotes: GetPaymentNoteMemoResponse }>()
);
export const getNotesFailure = createAction(
  '[PayeeNotes/API] Failure',
  props<{ errorMsg: string }>()
);

export const clearGetNotesAction = createAction('[PayeeNotes/API] Clear');

export const addNoteAction = createAction(
  '[AddPayeeNote/API] Send Request',
  props<{ body: UpdatePaymentNoteMemoRequest }>()
);
export const addNoteSuccess = createAction('[AddPayeeNote/API] Success');
export const addNoteFailure = createAction(
  '[AddPayeeNote/API] Failure',
  props<{ error: any }>()
);

export const updateNoteAction = createAction(
  '[UpdatePayeeNote/API] Send Request',
  props<{ body: UpdatePaymentNoteMemoRequest }>()
);
export const updateNoteSuccess = createAction('[UpdatePayeeNote/API] Success');
export const updateNoteFailure = createAction(
  '[UpdatePayeeNote/API] Failure',
  props<{ error: any }>()
);

export const checkEditButtonPDAction = createAction(
  generateActionName('CheckEditButtonPD/API').Send,
  props<{ paymentInstructionId: string, benefitTypeOptionId: string }>()
);
export const checkEditButtonPDSuccess = createAction(
  generateActionName('CheckEditButtonPD/API').Success,
  props<{ isValid: boolean }>()
);
export const checkEditButtonPDFailure = createAction(
  generateActionName('CheckEditButtonPD/API').Failure,
  props<{ error: any }>()
);

export const checkEditButtonBPAction = createAction(
  generateActionName('CheckEditButtonBP/API').Send,
  props<{ paymentInstructionId: string, benefitTypeOptionId: string }>()
);
export const checkEditButtonBPSuccess = createAction(
  generateActionName('CheckEditButtonBP/API').Success,
  props<{ isValid: boolean }>()
);
export const checkEditButtonBPFailure = createAction(
  generateActionName('CheckEditButtonBP/API').Failure,
  props<{ error: any }>()
);

export const editPayableDateAction = createAction(
  generateActionName('EditPayableDate/API').Send,
  props<{ body: PayableDatePayload }>()
);
export const editPayableDateSuccess = createAction(
  generateActionName('EditPayableDate/API').Success
);
export const editPayableDateFailure = createAction(
  generateActionName('EditPayableDate/API').Failure,
  props<{ error: any }>()
);
export const clearPayableDateState = createAction(
  generateActionName('EditPayableDate/API').Clear
);

export const editBenefitPeriodAction = createAction(
  generateActionName('EditBenefitPeriod/API').Send,
  props<{ body: BenefitPeriodPayload }>()
);
export const editBenefitPeriodSuccess = createAction(
  generateActionName('EditBenefitPeriod/API').Success
);
export const editBenefitPeriodFailure = createAction(
  generateActionName('EditBenefitPeriod/API').Failure,
  props<{ error: any }>()
);
export const clearBenefitPeriodState = createAction(
  generateActionName('EditBenefitPeriod/API').Clear
);