import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GetPaymentRunsRequest, GetPaymentRunsResponse } from './models';

@Injectable()
export class PaymentRunService {
  constructor(private httpClient: HttpClient) {}

  getPaymentRuns(request?: GetPaymentRunsRequest): Observable<GetPaymentRunsResponse> {
    return this.httpClient.post<GetPaymentRunsResponse>(`${environment.apiUrl}/new-payroll/payment-runs`, request ?? {});
  }
}
