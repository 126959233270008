import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

import { environment } from 'src/environments/environment';

import {
  ViewAdjustmentDetailsRequest,
  ViewAdjustmentDetailsResponse,
  AdjustmentDetailItemPositionRequest,
  AdjustmentDetailItemPositionResponse,
} from './models/view-adjustment-details.model';

@Injectable()
export class ViewAdjustmentDetailsService {
  constructor(private readonly httpClient: HttpClient) {}

  getAdjustmentDetails(request: ViewAdjustmentDetailsRequest): Observable<ViewAdjustmentDetailsResponse> {
    const {
      paymentInstructionHistoryId = '',

      // Query params
      memberId = '',
      pageIndex,
      pageSize,
      sortNames = [],
      sortType = SortType.ASC,
    } = request;

    let queryParams = new HttpParams();
    queryParams = queryParams.append('MemberId', memberId);
    if (sortNames.length) {
      sortNames.forEach((name) => {
        queryParams = queryParams.append('SortNames', capitalizeFirstLetter(name));
      });
      queryParams = queryParams.append('SortType', sortType);
    }
    if (pageSize && pageIndex) {
      queryParams = queryParams.append('PageSize', pageSize);
      queryParams = queryParams.append('PageIndex', pageIndex);
    }

    return this.httpClient.get<ViewAdjustmentDetailsResponse>(
      `${environment.apiUrl}/new-payroll/adjustment-transaction/${paymentInstructionHistoryId}/detailed-adjustment`,
      { params: queryParams },
    );
  }

  // Navigate to a record in Payment Info Tab section
  getAdjustmentDetailItemPosition(
    request: AdjustmentDetailItemPositionRequest,
  ): Observable<Pick<AdjustmentDetailItemPositionResponse, 'pageNumber' | 'total'>> {
    const context = new HttpContext().set(SHOW_LOADING, true);

    let queryParams = new HttpParams();
    const {
      benefitSubtypeId = '',
      payeeRecordId = '',

      // Query params
      benefitCode,
      pageSize,
      queryType,
      ...rest
    } = request;

    queryParams = queryParams.append('BenefitCode', benefitCode);
    queryParams = queryParams.append('QueryType', queryType);
    queryParams = queryParams.append('PageSize', pageSize);

    const ids = rest as Record<string, string>;
    for (const key of Object.keys(ids)) {
      if (ids[key]) queryParams = queryParams.append(capitalizeFirstLetter(key), ids[key]);
    }

    return this.httpClient.get<Pick<AdjustmentDetailItemPositionResponse, 'pageNumber' | 'total'>>(
      `${environment.apiUrl}/new-payroll/benefit-sub-types/${benefitSubtypeId}/payment-info-tab/${payeeRecordId}/position`,
      { params: queryParams, context },
    );
  }
}
