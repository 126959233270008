import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransactionRegister } from '../../services/models/register.model';

@Component({
  selector: 'ptg-register-data-popup',
  templateUrl: './register-data-popup.component.html',
  styleUrls: ['./register-data-popup.component.scss'],
})
export class RegisterDataPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { transaction: TransactionRegister & { addendaValue: string }; label: string },
  ) {}

  ngOnInit(): void {}
}
