import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { deepClone } from "@ptg-shared/utils/common.util";
import { CalendarEvent } from "angular-calendar";

@Component({
    selector: 'mwl-calendar-year-view',
    templateUrl: './calendar-year-view.component.html',
    styleUrls: ['./calendar-year-view.component.scss']
})
export class CalendarYearViewComponent implements OnInit {
    @HostBinding('style')
    get style() {
        return this.sanitizer.bypassSecurityTrustStyle(
            `--themecolor: ${this.themecolor};`
        );
    }
    @Input()
    themecolor: any = '#ff0000'
    @Input()
    events: CalendarEvent[] = [];

    @Input()
    viewDate: Date = new Date();

    @Output()
    eventClicked = new EventEmitter<any>();

    @Output()
    actionClicked = new EventEmitter<any>();

    days: any = ["S", "M", "T", "W", "T", "F", "S"];
    dayindex: any;
    daydetails: any = {};
    year: any = new Date().getFullYear();
    calendar: any = [];
    constructor(public sanitizer: DomSanitizer,

    ) { }
    ngOnInit() {
        this.initCalendar(this.viewDate);
    }
    ngOnChanges() {
        this.initCalendar(this.viewDate);
    }
    initCalendar(date: Date) {
        this.year = date.getFullYear();
        this.calendar = [];
        for (let index = 0; index < 12; index++) {
            this.calendar.push({
                date: new Date(this.year, index + 1, 0),
                days: this.generateCalendar(index + 1, this.year)
            });
        }        
    }
    generateCalendar(month: number, year: number) {
        let monthList: any[] = [];
        let nbweeks = this.getNbWeeks(month, year);
        let dayone = new Date(year, month - 1, 1).getDay();
        let nbdaysMonth = new Date(year, month, 0).getDate();
        let lastdayindex = new Date(year, month - 1, nbdaysMonth).getDay();
        let lastday = 7;
        let day = 1;
        let today = new Date().toDateString();

        for (let indexweek = 0; indexweek < nbweeks; indexweek++) {
            monthList[indexweek] = [];
            if (nbweeks == indexweek + 1) {
                lastday = lastdayindex + 1;
            }
            if (indexweek > 0) {
                dayone = 0;
            }
            for (let indexday = dayone; indexday < lastday; indexday++) {
                let d1 = new Date(year, month - 1, day).toDateString();
                let istoday = d1 == today;
                let colorsEvents = this.getnbevents(day, month - 1)
                monthList[indexweek][indexday] = {
                    day: day,
                    istoday: istoday,
                    color: colorsEvents.color,
                    events: [],
                    nb: colorsEvents.nb,
                    tooltip: colorsEvents.tooltip,
                    hasdotevent: (colorsEvents.dotEvents ?? []).some(item => item),
                    isweekendday: indexday === 0 || indexday === 6
                };
                day++;
            }
        }

        return monthList;
    }
    getNbWeeks(month: number, year: number) {
        let dayone = new Date(year, month - 1, 1).getDay();
        let nbdaysMonth = new Date(year, month, 0).getDate();
        let lastday = new Date(year, month - 1, nbdaysMonth).getDay();
        return (nbdaysMonth + dayone + (6 - lastday)) / 7;
    }
    getTodayEvents(day: any, month: number) {
        this.daydetails = {}

        if (this.events.length > 0) {
            this.daydetails = deepClone(day);
            let d1 = new Date(this.year, month, day.day).toDateString();

            for (let index = 0; index < this.events.length; index++) {
                const element: any = this.events[index];
                let d2 = element.start.toDateString();
                if (d2 == d1) {
                    this.daydetails.events.push(element);
                }
            }
        }
    }
    getnbevents(day: number, month: number) {
        let nb = 0;
        let color;
        let eventTooltip: string[] = [];
        let dotEvents: boolean[] = [];
        if (this.events.length > 0) {
            let d1 = new Date(this.year, month, day).toDateString();
            for (let index = 0; index < this.events.length; index++) {
                const element: any = this.events[index];
                let d2 = new Date(element.start).toDateString();
                if (d2 == d1) {
                    nb++;
                    color = element.color;
                    eventTooltip.push(element.title);
                    dotEvents.push(element.meta?.type === 'dot');
                }
            }
            return ({ nb: nb, color: color, tooltip: eventTooltip.join('\r\n'), dotEvents: dotEvents });
        } else {
            return { color: null, nb: 0, tooltip: null, dotEvents: null }
        }
    }
    eventClickedFn(event: any) {
        this.eventClicked.emit(event);
    }
    refresh(date: Date) {
        this.initCalendar(date);
    }
    actionClickedFn(action: any, event?: any) {
        this.actionClicked.emit({ action: action, event: event })
    }
}