import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { GetFinalPaymentConfigurationsResponse } from '../../services/models';
import {
  clearGetFinalPaymentConfigurationsStateAction,
  getFinalPaymentConfigurationsAction,
  getFinalPaymentConfigurationsFailureAction,
  getFinalPaymentConfigurationsSuccessAction,

  clearSetFinalPaymentConfigurationsStateAction,
  setFinalPaymentConfigurationsAction,
  setFinalPaymentConfigurationsFailureAction,
  setFinalPaymentConfigurationsSuccessAction,

} from '../actions';

export interface FinalPaymentConfigurationState {
  getFinalPaymentConfigurations?: BaseActionState<GetFinalPaymentConfigurationsResponse>;
  setFinalPaymentConfigurations?: BaseActionState;
}

const finalState: FinalPaymentConfigurationState = {};

export const finalPaymentConfigurationReducer = createReducer(
  finalState,
  on(getFinalPaymentConfigurationsAction, (state) => ({
    ...state,
    getFinalPaymentConfigurations: {
      isLoading: true,
    },
  })),
  on(getFinalPaymentConfigurationsSuccessAction, (state, { response }) => ({
    ...state,
    getFinalPaymentConfigurations: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getFinalPaymentConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    getFinalPaymentConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetFinalPaymentConfigurationsStateAction, (state) => ({
    ...state,
    getFinalPaymentConfigurations: undefined,
  })),

  on(setFinalPaymentConfigurationsAction, (state) => ({
    ...state,
    setFinalPaymentConfigurations: {
      isLoading: true,
    },
  })),
  on(setFinalPaymentConfigurationsSuccessAction, (state) => ({
    ...state,
    setFinalPaymentConfigurations: {
      isLoading: false,
      success: true,
    },
  })),
  on(setFinalPaymentConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    setFinalPaymentConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetFinalPaymentConfigurationsStateAction, (state) => ({
    ...state,
    setFinalPaymentConfigurations: undefined,
  }))

);
