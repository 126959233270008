export enum ConfirmType {
  Error,
  Confirm,
  Cancel,
  CancelPopup,
  Delete,
  Destruct,
  Warning,
  Attention,
  Success,
  ConfirmSave,
  ConfirmWithDontShowAgain
}

export enum ConfirmPopupTitle {
  Warning = 'Warning',
  RemoveRecord = 'Remove Record',
  Confirmation = 'Confirmation',
  Remove = 'Remove',
  CancelAction = 'Cancel Action',
}

export enum ConfirmPopupSaveButtonTitle {
  Yes = 'Yes',
}

export enum ConfirmPopupCancelButtonTitle {
  Close = 'Close',
  Cancel = 'Cancel',
  No = 'No',
}

export interface CustomPopupConfig {
  panelClass?: string,
  title?: string,
  text?: string,
  type?: ConfirmType,
  saveButtonTitle?: ConfirmPopupSaveButtonTitle,
  cancelButtonTitle?: ConfirmPopupCancelButtonTitle,
  hideSaveAsButton?: boolean,
  hideConfirmButton?: boolean,
}

export const DefaultCustomPopupConfig: CustomPopupConfig = {
  panelClass: 'confirm-popup',
  title: 'Confirmation',
  text: '',
  type: ConfirmType.ConfirmSave,
  saveButtonTitle: ConfirmPopupSaveButtonTitle.Yes,
  cancelButtonTitle: ConfirmPopupCancelButtonTitle.No,
  hideSaveAsButton: true,
  hideConfirmButton: true,
}
