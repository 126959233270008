import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as PayeeDetailsAction from '../actions/payee-detail.actions';

import { PaymentDetailsService } from '@ptg-member/features/payee-detail/services/payment-details.service';
import { PaymentEarningsDetailResponse } from '../../services/models';
import { PaymentDeductionsDetailResponse } from '../../services/models/payment-deductions.model';
import { PaymentAnnualTotalsResponse } from '../../services/models/payment-annual-totals.model';

@Injectable()
export class PayeeDetailInfoEffects {
  constructor(
    private actions$: Actions,
    private paymentDetailsService: PaymentDetailsService,
  ) {}

  getPaymentEarningsDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayeeDetailsAction.getPaymentEarningDetailAction),
      switchMap(({ request, params }) => {
        return this.paymentDetailsService.getPaymentEarningDetailInfo(request, params).pipe(
          map((response: PaymentEarningsDetailResponse) => {
            return PayeeDetailsAction.getPaymentEarningDetailSuccess({
              response,
            });
          }),
          catchError((err) => {
            return of(PayeeDetailsAction.getPaymentEarningDetailFailure(err));
          }),
        );
      }),
    ),
  );

  getPayeeDeductionsDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayeeDetailsAction.getPayeeDeductionsDetailAction),
      switchMap(
        ({
          paymentInstructionId,
          memberId,
          paymentInstructionHistoryId,
          paymentInfoAdjustmentType,
          instructionStatusHistoryId,
        }) => {
          return this.paymentDetailsService
            .getPaymentDeductionsDetailInfo(
              paymentInstructionId,
              memberId,
              paymentInstructionHistoryId,
              instructionStatusHistoryId,
              paymentInfoAdjustmentType,
            )
            .pipe(
              map((payeeDeductionsDetail: PaymentDeductionsDetailResponse) => {
                return PayeeDetailsAction.getPayeeDeductionsDetailSuccess({
                  payeeDeductionsDetail,
                });
              }),
              catchError((err) => {
                return of(PayeeDetailsAction.getPayeeDeductionsDetailFailure(err));
              }),
            );
        },
      ),
    ),
  );

  getPaymentAnnualTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PayeeDetailsAction.getPaymentAnnualTotalsAction),
      switchMap(({ request, params }) => {
        return this.paymentDetailsService.getPaymentAnnualTotals(request, params).pipe(
          map((response: PaymentAnnualTotalsResponse) => {
            return PayeeDetailsAction.getPaymentAnnualTotalsSuccess({
              response,
            });
          }),
          catchError((err) => {
            return of(PayeeDetailsAction.getPaymentAnnualTotalsFailure(err));
          }),
        );
      }),
    ),
  );
}
