export interface GetNextPayrollRunListQueryResult {
  banners: string[];
  initializing: boolean;
  initializingFailed: boolean;
  payrollRunId?: string;
  payrollRuns: PayrollRuns[];
}

export interface PayrollRuns {
  id: string;
  payrollCalendarId: string;
  order: number;
  payrollCalendarPeriodId: string;
  calendarName: string;
  payableDate: string;
  startDate: string;
  endDate: string;
  status: number;
}