import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import {
  GetEditPayrollSettingsRequest,
  GetEditPayrollSettingsResponse,
  SaveEditPayrollSettingsRequest,
} from './models/edit-payroll-settings.model';

@Injectable()
export class EditPayrollSettingsService {
  constructor(private readonly httpClient: HttpClient) {}

  getEditPayrollSettings(request: GetEditPayrollSettingsRequest): Observable<GetEditPayrollSettingsResponse> {
    const { paymentInstructionId = '', payeeRecordId = '' } = request;

    return this.httpClient.get<GetEditPayrollSettingsResponse>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${paymentInstructionId}/payroll-settings/${payeeRecordId}/details`,
    );
  }

  savePayrollSettings(body: SaveEditPayrollSettingsRequest, paymentInstructionId = ''): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);

    return this.httpClient.post<void>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${paymentInstructionId}/payroll-settings`,
      body,
      { context },
    );
  }
}
