import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { addOneTimePaymentTypeSelector, benefitTimeInfoSelector, editBenefitPeriodAction, editPayableDateAction, getBenefitTimeInfoAction, getOneTimePaymentTypeAction } from '../../store';
import { DatePipe } from '@angular/common';
import { QueryParamsBenefit } from '../../services/models';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AddOneTimeService } from '../../services';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { tap, debounceTime, switchMap, startWith, take, filter, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { BaseComponent } from '@ptg-shared/components';
import { CorrectionType } from '@ptg-member/constance/paymentType.const';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-edit-payable-date',
  templateUrl: './edit-payable-date.component.html',
  styleUrls: ['./edit-payable-date.component.scss']
})
export class EditPayableDateComponent extends BaseComponent {
  payableDate = new FormControl();
  benefitPeriod = new FormGroup({
    startDate: new FormControl(null, {asyncValidators: [this._checkBenefitPeriodDate('benefitPeriodStart')]}),
    endDate: new FormControl(null, {asyncValidators: [this._checkBenefitPeriodDate('benefitPeriodEnd')]})
  })
  
  maxDate = new Date();
  minDate = new Date();
  formSubmit$ = new Subject<boolean>();
  benefitPeriodOverlap = false;
  errorStartAsync = '';
  errorEndAsync = '';

  constructor(
    private store: Store,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialogRef: MatDialogRef<EditPayableDateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      payableDate?: string;
      paymentInstructionId: string;
      isEditPayableDate: boolean;
      isPaymentSuspend?: boolean;
      benefitPeriod?: {
        startDate?: string;
        endDate?: string;
        benefitEntityDataId: string;
        benefitTypeOptionId: string;
        payeeEntityRecordId: string;
        benefitTypeName: string;
        benefitCode: string;
        paymentType: string;
        benefitName: string;
      };
    },
    private addOneTimeService: AddOneTimeService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.data.isEditPayableDate) {
      this.payableDate.setValue(new Date(this.data.payableDate ?? ''));
      this.benefitPeriod.get('benefitCode')?.setValue(this.data.benefitPeriod?.benefitCode);
    } else {
      this.benefitPeriod.get('startDate')?.setValue(this.data.benefitPeriod?.startDate ? new Date(this.data.benefitPeriod?.startDate) : null);
      this.benefitPeriod.get('endDate')?.setValue(this.data.benefitPeriod?.endDate ? new Date(this.data.benefitPeriod?.endDate) : null);
      this.store.dispatch(getBenefitTimeInfoAction({queryParams: this._getQueryParams(), paymentInstructionTypeId: this.data.benefitPeriod?.paymentType ?? ''}));
      this.store.select(benefitTimeInfoSelector).subscribe(el => {
        if (el.payload) {
          this.minDate = new Date(el.payload.startDate);
        }
      });
    }
    this.benefitPeriod.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(el => {
      this.benefitPeriodOverlap = false;
    });
    this.onSubmit();
  }

  private _getQueryParams(): QueryParamsBenefit {
    return {
      BenefitEntityDataId: this.data.benefitPeriod?.benefitEntityDataId ?? '',
      BenefitTypeOptionId: this.data.benefitPeriod?.benefitTypeOptionId ?? '',
      PayeeEntityRecordId: this.data.benefitPeriod?.payeeEntityRecordId ?? '',
      BenefitTypeName: this.data.benefitPeriod?.benefitTypeName ?? '',
      BenefitCode: this.data.benefitPeriod?.benefitCode ?? '',
    }
  }

  private _checkBenefitPeriodDate(filedName: string) {
    return checkApiValidator(
      this.addOneTimeService.checkValidDate,
      filedName,
      undefined,
      {
        params: {
          queryParams: {
            benefitTypeOptionId: this.data.benefitPeriod?.benefitTypeOptionId,
            payrollBenefitCode: this.data.benefitPeriod?.benefitCode
          },
          paymentInstructionTypeId: this.data.benefitPeriod?.paymentType,
        },
      },
      (result) => {
        if (filedName === 'benefitPeriodStart') {
          this.errorStartAsync =
            result?.existedPeriod === false
              ? 'Start Date must match start date of a payroll cycle.'
              : result?.isValid === false
                ? `Start date must be after the Payee's benefit begin date for ${this.data?.benefitPeriod?.benefitName}.`
                : '';
        } else if (filedName === 'benefitPeriodEnd') {
          this.errorEndAsync =
            result?.existedPeriod === false
              ? 'End Date must match end date of a payroll cycle.'
              : result?.isValid === false
                ? result?.message
                : '';
        }
        this.benefitPeriod.updateValueAndValidity();
      }
    )
  }

  onSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          if (this.data.isEditPayableDate) {
            this.payableDate.markAllAsTouched();
          } else {
            this.benefitPeriod.markAllAsTouched();
          }
        }),
        debounceTime(500),
        switchMap(() => {
          if (this.data.isEditPayableDate) {
            return this.payableDate.statusChanges.pipe(
              startWith(this.payableDate.status),
              filter(status => status !== AbstractControlStatus.PENDING),
              take(1)
            );
          }
          return this.benefitPeriod.statusChanges.pipe(
            startWith(this.benefitPeriod.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          );
        }),
        filter(status => status === AbstractControlStatus.VALID),
        switchMap(() => {
          if (this.data.isPaymentSuspend && !this.data.isEditPayableDate) {
            const benefitSubType = this.data.benefitPeriod?.benefitTypeOptionId ?? '';
            const params = {
              payeeEntityRecordId: this.data.benefitPeriod?.payeeEntityRecordId,
              paymentInstructionType: CorrectionType.PayingSuspendedPeriod,
              paymentInstructionId: this.data?.paymentInstructionId,
              startDate: this.benefitPeriod?.get('startDate') ? datePipe.transform(this.benefitPeriod?.get('startDate')?.value, 'yyyy-MM-dd') : '',
              endDate: this.benefitPeriod?.get('endDate') ? datePipe.transform(this.benefitPeriod?.get('endDate')?.value, 'yyyy-MM-dd') : '',
              benefitCode: this.data.benefitPeriod?.benefitCode
            };
            return this.addOneTimeService.checkConditionBeforeSave(benefitSubType, params).pipe(
              filter(res => {
                if (res && !res.isValid) {
                  this.benefitPeriodOverlap = true;
                }
                return res.isValid;
              }),
              take(1),
            )
          } else {
            return of(true);
          }
        })
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  saveValue() {
    if (this.data.isEditPayableDate) {
      
      const body = {
        paymentInstructionId: this.data.paymentInstructionId ?? '',
        payableDate: datePipe.transform(this.payableDate.value, 'yyyy-MM-dd') ?? '',
      };
      this.store.dispatch(editPayableDateAction({body}));
      this.dialogRef.close();
    } else {
      const body = {
        paymentInstructionId: this.data.paymentInstructionId ?? '',
        startDate: datePipe.transform(this.benefitPeriod.get('startDate')?.value, 'yyyy-MM-dd') ?? '',
        endDate: datePipe.transform(this.benefitPeriod.get('endDate')?.value, 'yyyy-MM-dd') ?? ''
      };
      this.store.dispatch(editBenefitPeriodAction({body}));
      this.dialogRef.close();
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
