<div class="create-offset-deduction" id="create-offset-deduction">
  <ptg-title-bar
    [viewName]="data.viewName"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <ptg-payment-header 
    [targetIdFromDialog]="data?.memberId ?? ''"
    [isShowInteractionComponents]="false"
  ></ptg-payment-header>

  <div>
    <ptg-banner
      *ngIf="errorOffsetMessage"
      [(bannerType)]="errorOffsetBannerType"
      [message]="errorOffsetMessage"
      (closeBanner)="closeBannerOffset()"
    ></ptg-banner>

    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
      (closeBanner)="closeBanner()"
    ></ptg-banner>

    <div class="wrap-btn">
      <ptg-button (click)="onSaveButton()" classInput="primary" buttonName="Save"></ptg-button>
      <ptg-button (click)="onCancel()" classInput="tertiary" buttonName="Cancel"></ptg-button>
    </div>
    
    <div class="form-control-container">
      <div class="benefit-period" *ngIf="benefitPeriodFormGroup">
        <h3 class="benefit-label">Benefit Period</h3>
        <div class="w-50">
          <ptg-datepicker
            [controlField]="benefitPeriodFormGroup?.get('benefitPeriodStart')"
            placeholder="Start Date "
            [isRequired]="true"
            [maxDate]="maxDate"
            errorMaxDate="Start date can not be future date."
            (changeValue)="changeStartDate()"
            [errorAsync]="errorStartAsync"
          ></ptg-datepicker>
          <ptg-datepicker
            [controlField]="benefitPeriodFormGroup?.get('benefitPeriodEnd')"
            placeholder="End Date "
            [isRequired]="true"
            [errorAsync]="errorEndAsync"
            customError="errorMessageEndDate"
          ></ptg-datepicker>
        </div>

        <div class="mat-error mb-2" *ngIf="benefitPeriodOverlap">
          The suspended period is already covered by a Recurring or One time payment. Please check again
        </div>
      </div>
      <div class="payable-date">
        <ptg-datepicker
          [controlField]="editForm.get('payableDate')"
          placeholder="Payable Date "
          [isRequired]="true"
          [minDate]="maxDate"
          errorMinDate="Payable date cannot be past date"
          customError="dependentStartDate"
        ></ptg-datepicker>
      </div>

      <div class="deduction" *ngIf="deductionFormGroup">
        <div class="flex">
          <ptg-select
            placeholder="Deduction Type"
            [controlField]="deductionFormGroup?.get('deductionType')"
            [listData]="listDeductionType"
            [isOptionObj]="true"
            customError="requiredChip"
          ></ptg-select>
          <button mat-raised-button type="button" class="add-btn" type="button" (click)="addType()">
            <mat-icon>add</mat-icon>
          </button>
          <div class="chip-item-container">
            <span class="chip" *ngFor="let item of listChip; let i = index">
              <span>{{ item.displayValue }}</span>
              <button mat-button type="button" class="x-btn" (click)="removeChip(i)">✕</button>
            </span>
          </div>
        </div>
        <div class="deduction-tax" *ngIf="taxFormGroup">
          <h3 class="deduction-label">Taxes</h3>
          <div class="row-control">
            <ptg-textbox
              [controlField]="taxFormGroup?.get('federalTax')"
              [hasLabel]="true"
              placeholder="Federal Tax"
              [max]="MAX_VALUE_NUMBER"
              [isPositive]="true"
              errorRequire="Federal Tax is required."
              [errorMinMaxValue]="'Federal Tax must be less than or equal to ' + MAX_VALUE_NUMBER"
              [inputType]="InputType.TYPE_CURRENCY"
              (valueChange)="onFederalValueChange($event)"
              customError="errorAmountMessage"
            ></ptg-textbox>
            <!-- <ptg-textbox
              [controlField]="taxFormGroup?.get('stateTax')"
              [hasLabel]="true"
              placeholder="State Tax"
              [max]="MAX_VALUE_NUMBER"
              [isPositive]="true"
              errorRequire="State Tax is required."
              [inputType]="InputType.TYPE_CURRENCY"
              customError="errorAmountMessage"
            ></ptg-textbox> -->
          </div>
        </div>
        <div class="deduction-insurance" *ngIf="insuranceFormArray">
          <h3 class="deduction-label">Insurances</h3>
          <div class="container-insurance" *ngFor="let control of insuranceFormArray?.controls; let i = index">
            <div class="row-control">
              <ptg-select
                [placeholder]="'Insurance ' + (i + 1)"
                class="input-field"
                [controlField]="control.get('insuranceDeduction')"
                [listData]="_createListData(control.get('insuranceDeduction')?.value, insuranceFormArray, listInsuranceDeduction, 'insuranceDeduction')"
                [isOptionObj]="true"
                errorRequire="Insurance Deduction is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeInsuranceDeductionValue($event, control)"
              ></ptg-select>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [errorMinMaxValue]="'Amount must be less than or equal to ' + MAX_VALUE_NUMBER"
                [inputType]="InputType.TYPE_CURRENCY"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button mat-button type="button" class="remove-button" (click)="onRemoveRowDeduction(i, insuranceFormArray, 'Insurance')">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
          <ptg-button
            buttonName="New Insurance"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton('Insurance')"
            (clickButton)="addNewRowDeduction(insuranceFormArray, 'Insurance')"
          ></ptg-button>
        </div>
        <div class="deduction-insurance" *ngIf="othersFormArray">
          <h3 class="deduction-label">Others</h3>
          <div class="container-insurance" *ngFor="let control of othersFormArray?.controls; let i = index">
            <div class="row-control">
              <ptg-select
                [placeholder]="'Other ' + (i + 1)"
                class="input-field"
                [controlField]="control.get('othersDeduction')"
                [listData]="_createListData(control.get('othersDeduction')?.value, othersFormArray, listOthersDeduction, 'othersDeduction')"
                [isOptionObj]="true"
                errorRequire="Other Deduction is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeOthersDeductionValue($event, control)"
              ></ptg-select>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [errorMinMaxValue]="'Amount must be less than or equal to ' + MAX_VALUE_NUMBER"
                [inputType]="InputType.TYPE_CURRENCY"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button mat-button type="button" class="remove-button" (click)="onRemoveRowDeduction(i, othersFormArray, 'Others')">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
          <ptg-button
            buttonName="New Others"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton('Others')"
            (clickButton)="addNewRowDeduction(othersFormArray, 'Others')"
          ></ptg-button>
        </div>
        <div class="deduction-insurance" *ngIf="garnishmentsFormArray">
          <h3 class="deduction-label">Garnishments</h3>
          <div class="container-insurance" *ngFor="let control of garnishmentsFormArray?.controls; let i = index">
            <div class="row-control garnishments">
              <ptg-select
                placeholder="Court Order"
                class="input-field"
                [controlField]="control.get('courtOrder')"
                [listData]="_createListData(control.get('courtOrder')?.value, garnishmentsFormArray, listCourtOrder, 'courtOrder')"
                [isOptionObj]="true"
                errorRequire="Court Order is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeGarnishmentValue($event, control)"
              ></ptg-select>
              <!-- <ptg-select
                placeholder="Deduction Payee"
                class="input-field"
                [controlField]="control.get('deductionPayee')"
                [listData]="listDeductionPayee"
                [isOptionObj]="true"
                errorRequire="Deduction Payee is required."
                [isSetMaxWidthSelect]="true"
              ></ptg-select> -->
              <div class="min-w-[252px]">
                <div class="text-sm leading-6 emphasis-medium">Deduction Payee</div>
                <div class="text-base emphasis-high">
                  {{control.get('deductionPayee')?.value}}
                </div>
              </div>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [errorMinMaxValue]="'Amount must be less than or equal to ' + MAX_VALUE_NUMBER"
                [inputType]="InputType.TYPE_CURRENCY"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button mat-button type="button" class="remove-button" (click)="onRemoveRowDeduction(i, garnishmentsFormArray, 'Garnishment')">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
          <ptg-button
            buttonName="New Garnishment"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton('Garnishment')"
            (clickButton)="addNewRowDeduction(garnishmentsFormArray, 'Garnishment')"
          ></ptg-button>
        </div>
      </div>
      <ptg-input-area
        [controlField]="editForm.get('reason')"
        [hasLabel]="true"
        placeholder="Reason"
        [maxLength]="255"
        errorMaxLength="Exceed the 255 character limit."
      ></ptg-input-area>
    </div>
  </div>
</div>
