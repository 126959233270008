export enum SortByCompareType {
  Number,
  String,
}

export interface NameObject {
  prefix?: string,
  first?: string,
  middle?: string,
  last?: string,
  suffix?: string,
}

export interface NameObjectSetting {
  usePrefix?: boolean,
  useFirst?: boolean,
  useMiddle?: boolean,
  useLast?: boolean,
  useSuffix?: boolean,
}
