import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import * as ViewAdjustmentDetailsActions from '../actions/view-adjustment-details.actions';
import {
  AdjustmentDetailItemPositionResponse,
  ViewAdjustmentDetailsResponse,
} from '../../services/models/view-adjustment-details.model';

export interface ViewAdjustmentDetailsState {
  viewAdjustmentDetails?: BaseActionState<ViewAdjustmentDetailsResponse>;
  adjustmentDetailItemPosition?: BaseActionState<AdjustmentDetailItemPositionResponse>;
}

const initialState: ViewAdjustmentDetailsState = {};

export const viewAdjustmentDetailsReducer = createReducer(
  initialState,

  on(ViewAdjustmentDetailsActions.getAdjustmentDetailsAction, (state) => ({
    ...state,
    viewAdjustmentDetails: {
      isLoading: true,
    },
  })),
  on(ViewAdjustmentDetailsActions.getAdjustmentDetailsSuccess, (state, { response }) => ({
    ...state,
    viewAdjustmentDetails: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(ViewAdjustmentDetailsActions.getAdjustmentDetailsFailure, (state, { error }) => ({
    ...state,
    viewAdjustmentDetails: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(ViewAdjustmentDetailsActions.clearAdjustmentDetailsState, (state) => ({
    ...state,
    viewAdjustmentDetails: undefined,
  })),

  on(ViewAdjustmentDetailsActions.getAdjustmentDetailItemPositionAction, (state) => ({
    ...state,
    adjustmentDetailItemPosition: {
      isLoading: true,
    },
  })),
  on(ViewAdjustmentDetailsActions.getAdjustmentDetailItemPositionSuccess, (state, { response }) => ({
    ...state,
    adjustmentDetailItemPosition: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(ViewAdjustmentDetailsActions.getAdjustmentDetailItemPositionFailure, (state, { error }) => ({
    ...state,
    adjustmentDetailItemPosition: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(ViewAdjustmentDetailsActions.clearAdjustmentDetailItemPositionState, (state) => ({
    ...state,
    adjustmentDetailItemPosition: undefined,
  })),
);
