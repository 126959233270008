import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/types/models';

import * as ProfileHeaderConfigurationAction from '../actions/profile-header-configuration.actions';
import {
  EntityMemberDetail,
  MetadataProfileHeaderConfiguration,
  ProfileHeader,
  ProfileHeaderList,
  PropertyDisplayConfig,
  Status,
} from '../../types/models';
import { HeaderPropertyConfig } from '@ptg-member/types/models/header-configuration.model';

export const profileHeaderConfigurationFeatureKey =
  'profileHeaderConfigurationFeatureKey';

export interface State {
  isLoading: boolean;
  propertyConfigs: PropertyDisplayConfig[];
  profileHeader: MetadataProfileHeaderConfiguration;
  updateState: string;
  profileHeaders?: ProfileHeader[];
  profileHeaderConfig?: HeaderPropertyConfig[];
  memberStatusId?: string;
  getProfileHeaderConfigList: BaseActionState<ProfileHeaderList>;
  deleteState: string;
  statusNotUsedList: Status[];
  id: string;
  headerPropertyConfig?: BaseActionState<HeaderPropertyConfig[]>;
  subHeaderPropertyConfig?: BaseActionState<HeaderPropertyConfig[]>;
  memberDetail?: BaseActionState<EntityMemberDetail>;
}

const initialState: State = {
  isLoading: true,
  propertyConfigs: [],
  profileHeader: {} as MetadataProfileHeaderConfiguration,
  updateState: '',
  getProfileHeaderConfigList: {
    isLoading: true,
  },
  deleteState: '',
  statusNotUsedList: [],
  id: '',
};

export const reducer = createReducer(
  initialState,
  on(
    ProfileHeaderConfigurationAction.clearProfileHeaderConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
      deleteState: '',
      id: '',
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderPropertyListSuccess,
    (state, { propertyConfigs }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderConfigurationSuccess,
    (state, { profileHeader }) => ({
      ...state,
      isLoading: false,
      profileHeader,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      profileHeader: {} as MetadataProfileHeaderConfiguration,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.setProfileHeaderConfigurationSuccess,
    (state, { id }) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
      id,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.setProfileHeaderConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
      updateState: STATE.FAIL,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMemberProfileHeaderDataSuccess,
    (state, { profileHeaderData }) => ({
      ...state,
      isLoading: false,
      profileHeaders: profileHeaderData.profileHeaders,
      memberStatusId: profileHeaderData.memberStatusId,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMemberProfileHeaderDataFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderDataAction,
    (state) => ({
      ...state,
      headerPropertyConfig: {
        isLoading: true,
        payload: undefined
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderDataSuccess,
    (state, { headerPropertyConfig}) => ({
      ...state,
      headerPropertyConfig: {
        isLoading: false,
        payload: headerPropertyConfig,
        success: true
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileHeaderDataFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      headerPropertyConfig: {
        isLoading: false,
        payload: undefined,
        success: false
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileSubHeaderDataAction,
    (state) => ({
      ...state,
      subHeaderPropertyConfig: {
        isLoading: true
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileSubHeaderDataSuccess,
    (state, { headerPropertyConfig}) => ({
      ...state,
      subHeaderPropertyConfig: {
        isLoading: false,
        success: true,
        payload: headerPropertyConfig
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getProfileSubHeaderDataFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      subHeaderPropertyConfig: {
        isLoading: false,
        success: false,
        payload: undefined
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMemberDetailSuccess,
    (state, { memberDetail}) => ({
      ...state,
      memberDetail: {
        isLoading: false,
        payload: memberDetail
      }
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getListProfileHeaderConfiguration,
    (state) => ({
      ...state,
      getProfileHeaderConfigList: {
        isLoading: true,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getListProfileHeaderConfigurationSuccess,
    (state, { profileHeaderList }) => ({
      ...state,
      getProfileHeaderConfigList: {
        isLoading: false,
        success: true,
        payload: profileHeaderList,
        error: undefined,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getListProfileHeaderConfigurationFailure,
    (state, { error }) => ({
      ...state,
      getProfileHeaderConfigList: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(
    ProfileHeaderConfigurationAction.deleteProfileHeaderConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      deleteState: STATE.SUCCESS,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.deleteProfileHeaderConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      deleteState: STATE.FAIL,
    })
  ),
  on(
    ProfileHeaderConfigurationAction.getMembersStatusNotUsedSuccess,
    (state, { statusNotUsedList }) => ({
      ...state,
      statusNotUsedList,
    })
  )
);
