import { Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil, tap, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { CalculationType } from '../../types/enums';
import {
  ExceptionConfigurationListItem,
  GetExceptionConfigurationRequest,
} from '../../services/models/exception-configuration.model';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { DEFAULT_PAGE_SIZE, SORT_TYPE } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { ACTION_COLUMN, Column, Row } from '@ptg-shared/controls/grid';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import {
  CalculationState,
  getExceptionConfigurationsAction,
  getExceptionConfigurationsSelector,
  clearGetExceptionConfigurationsStateAction,
  getCalculationQDROSelector,
} from '../../store';

@Component({
  selector: 'ptg-exception-configuration',
  templateUrl: './exception-configuration.component.html',
  styleUrls: ['./exception-configuration.component.scss'],
})
export class ExceptionConfigurationComponent extends BaseListComponent {
  readonly PAGE_KEY = '-ptg-exception-configuration';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  columns: Column[] = [
    {
      name: 'message',
      header: {
        title: 'Exception',
        style: {
          padding: '0.625rem 1rem 0.625rem 88px',
        },
      },
      width: '35.83%',
      style: {
        padding: '0.625rem 1rem 0.625rem 88px',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'numOfBefTypes',
      header: {
        title: 'Number of Applied Benefit Options',
        style: {
          padding: '0.625rem 1rem',
        },
      },
      width: '32.08%',
      style: {
        padding: '0.625rem 1rem',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
        style: {
          padding: '0.625rem 1rem',
        },
      },
      width: 'auto',
      style: {
        padding: '0.625rem 1rem',
      },
    },
  ];

  isLoading = true;
  exceptionConfigurations: (ExceptionConfigurationListItem & Row)[] = [];
  sortInfo?: Sort;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  memberId: string = '';
  totalRecords: number = 0;
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber = FIRST_PAGE;
  pageSize: number = DEFAULT_PAGE_SIZE;
  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  qdroLabelName: string = '';

  constructor(
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,

    private readonly router: Router,
    private readonly calculationStore: Store<CalculationState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkRoute();
    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    this.getBenefitEntityId();
    this.getData();
    this.selectExceptionConfigurationActionState();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.calculationStore.dispatch(clearGetExceptionConfigurationsStateAction());
  }

  private checkRoute(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.memberId;
      this.listBreadcrumbs = this.getBreadcrumbs();
    });
  }

  private getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Exception Configuration List',
        url: '',
      },
    ];
  }

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  private getBenefitEntityId() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
        }
      });
  }

  private getData(): void {
    let sortType = SORT_TYPE.ASC;
    let sortNames = '';

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    const request: GetExceptionConfigurationRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortNames,
      sortType,
    };

    this.calculationStore.dispatch(getExceptionConfigurationsAction({ request }));
  }

  private selectExceptionConfigurationActionState(): void {
    this.calculationStore
      .pipe(
        select(getExceptionConfigurationsSelector),
        tap((res: any) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.calculationStore.dispatch(clearGetExceptionConfigurationsStateAction());

        if (state?.success) {
          // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
          let _exceptionConfigurations = this.handleMappingData(state.payload?.details ?? [])
          _exceptionConfigurations = _exceptionConfigurations.map((exceptionConfiguration) => ({
            ...exceptionConfiguration,
            message: exceptionConfiguration.message.includes(CalculationType[CalculationType.QDRO])
              ? exceptionConfiguration.message.replace(CalculationType[CalculationType.QDRO], this.qdroLabelName)
              : exceptionConfiguration.message,
          }));
          this.exceptionConfigurations = _exceptionConfigurations;

          // this.exceptionConfigurations = this.handleMappingData(state.payload?.details ?? []);
          this.totalRecords = state.payload?.total ?? 0;
        }
      });
  }

  private handleMappingData(data: ExceptionConfigurationListItem[]): ExceptionConfigurationListItem[] {
    return data.map((item) => ({
      ...item,
      numOfBefTypes: item.numOfBefTypes === -1 ? 'All Benefit Options' : item.numOfBefTypes.toString(),
    }));
  }

  onChangeSort(event: Sort): void {
    this.sortInfo = event;
    this.getData();
  }

  onChangePage(event: PageEvent): void {
    super.onChangePage(event);
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  onClickEditExceptionConfiguration(row: ExceptionConfigurationListItem): void {
    this.router.navigateByUrl(`/member/calculation/exception-configuration/detail/${row.id}/${this.memberId}`);
  }
}
