import { createReducer, on } from '@ngrx/store';

import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { deepClone } from '@ptg-shared/utils/common.util';

import { MemberListActions } from '../actions';
import {
  ColumnSetConfig,
  Employer,
  FilterByPropertyConfig,
  FilterInformation,
  GetCardSelectionResponse,
  MemberFilter,
  MemberList,
  PropertyDisplayResponse,
} from '../../types/models';
import { BaseActionState } from '@ptg-shared/types/models';

export const memberListFeatureKey = 'memberList';
export interface State {
  isLoading: boolean;
  isExporting: boolean;
  memberList: MemberList;
  tmp?: any;
  removeState?: string;
  unLockMemberState?: string;
  lockMemberState?: string;
  memberKey: string;
  listSectionProperty: FilterByPropertyConfig[];
  filters?: MemberFilter[];
  createMemberFilterState?: string;
  listFilterInfo?: FilterInformation[];
  removeMemberFilterState?: string;
  updateFilterNameState?: string;
  updateFilterState?: string;
  isError?: boolean;
  employerDetail?: Employer;
  listColumnConfigState?: BaseActionState<ColumnSetConfig>;
  updateColumnSetState?: string;
  reorderColumnSetState?: string;
  removeColumnSetState?: string;
  removeColumnSetStatusState?: string;
  actionColumnSetState?: string;
  idColumnSet?: string;
  selectedColumnSetState?: string;
  propertyConfiguration?: BaseActionState<PropertyDisplayResponse>;
  cards?: BaseActionState<GetCardSelectionResponse>;
  propertyByCardSelection?: BaseActionState<any>;
}

const initialState: State = {
  isLoading: true,
  isExporting: false,
  memberList: {
    total: 0,
    members: [],
    columns: [],
    message: '',
  },
  memberKey: '',
  listSectionProperty: [],
};

export const reducer = createReducer(
  initialState,
  on(MemberListActions.getMemberList, (state, {}) => ({
    ...state,
    isLoading: true,
    memberList: {
      total: 0,
      members: [],
      columns: [],
      message: '',
    },
  })),
  on(MemberListActions.getMemberListSuccess, (state, { memberList }) => ({
    ...state,
    isLoading: false,
    isError: false,
    memberList,
    success: true,
    error: undefined,
  })),
  on(MemberListActions.getMemberListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isError: true,
    memberList: {
      total: 0,
      members: [],
      columns: [],
      message: '',
      success: false,
      error: error,
    },
  })),
  on(MemberListActions.removeMemberSuccess, (state, { memberKey }) => {
    return {
      ...state,
      memberKey: memberKey,
      removeState: STATE.SUCCESS,
      tmp: {
        ...state.tmp,
        memberList: null,
      },
    };
  }),
  on(MemberListActions.removeMemberClear, (state) => {
    return {
      ...state,
      removeState: '',
      unLockMemberState: '',
      lockMemberState: '',
      createMemberFilterState: '',
      removeMemberFilterState: '',
      updateFilterNameState: '',
      updateFilterState: '',
      updateColumnSetState: '',
      reorderColumnSetState: '',
      removeColumnSetState: '',
      removeColumnSetStatusState: '',
      actionColumnSetState: '',
      idColumnSet: '',
      selectedColumnSetState: ''
    };
  }),
  on(MemberListActions.removeMemberFailure, (state, { id, errorMsg }) => {
    return {
      ...state,
      removeState: STATE.FAIL,
    };
  }),
  on(MemberListActions.lockMemberFailure, (state, { id, errorMsg }) => {
    return {
      ...state,
      lockMemberState: STATE.FAIL,
    };
  }),
  on(MemberListActions.lockMemberSuccess, (state, { id }) => {
    const index = state.memberList.members.findIndex(
      (member) => member._memberId === id
    );
    const memberList = deepClone(state.memberList);
    if (index > -1) {
      memberList.members[index]._isLocked =
        !memberList.members[index]._isLocked;
    }
    return {
      ...state,
      memberList,
      lockMemberState: STATE.SUCCESS,
      reloadMetadataDetail: true,
    };
  }),
  on(MemberListActions.unlockMemberFailure, (state, { id, errorMsg }) => {
    return {
      ...state,
      unLockMemberState: STATE.FAIL,
    };
  }),
  on(MemberListActions.unlockMemberSuccess, (state, { id }) => {
    const index = state.memberList.members.findIndex(
      (member) => member._memberId === id
    );
    const memberList = deepClone(state.memberList);
    if (index > -1) {
      memberList.members[index]._isLocked =
        !memberList.members[index]._isLocked;
    }
    return {
      ...state,
      memberList,
      unLockMemberState: STATE.SUCCESS,
      reloadMetadataDetail: true,
    };
  }),
  on(
    MemberListActions.getFilterByPropertyListSuccess,
    (state, { listSectionProperty }) => ({
      ...state,
      listSectionProperty,
    })
  ),
  on(MemberListActions.setMemberFilter, (state, { filters }) => ({
    ...state,
    filters,
  })),
  on(MemberListActions.createMemberFilterSuccess, (state) => {
    return {
      ...state,
      createMemberFilterState: STATE.SUCCESS,
    };
  }),
  on(MemberListActions.createMemberFilterFailure, (state) => {
    return {
      ...state,
      createMemberFilterState: STATE.FAIL,
    };
  }),
  on(MemberListActions.getMemberFilterSuccess, (state, { listFilterInfo }) => {
    return {
      ...state,
      listFilterInfo,
    };
  }),
  on(MemberListActions.getMemberFilterFailure, (state) => {
    return {
      ...state,
    };
  }),
  on(MemberListActions.removeMemberFilterSuccess, (state, { id }) => {
    return {
      ...state,
      removeMemberFilterState: id,
    };
  }),
  on(
    MemberListActions.updateFilterNameSuccess,
    (state, { id, listFilterInfo }) => {
      return {
        ...state,
        updateFilterNameState: id,
        updateFilterState: listFilterInfo.length > 0 ? STATE.SUCCESS : '',
      };
    }
  ),
  on(
    MemberListActions.updateFilterNameFailure,
    (state, { id, listFilterInfo }) => {
      return {
        ...state,
        updateFilterState: listFilterInfo.length > 0 ? STATE.FAIL : '',
      };
    }
  ),
  on(MemberListActions.exportMembersRequest, (state, { request }) => {
    return {
      ...state,
      isExporting: true,
    };
  }),
  on(MemberListActions.downloadMembersRequestSuccess, (state, {}) => {
    return {
      ...state,
      isExporting: false,
    };
  }),

  on(MemberListActions.getEmployerDetailSuccess, (state, { employer }) => ({
    ...state,
    employerDetail: employer
  })),
  on(MemberListActions.getEmployerDetailFailure, (state) => ({
    ...state,
    employerDetail: undefined
  })),

  on(MemberListActions.getListColumnSetConfigurationRequest, (state) => ({
    ...state,
    listColumnConfigState: {
      isLoading: true,
    },
  })),
  on(MemberListActions.getListColumnSetConfigurationSuccess, (state, { listColumnConfig }) => ({
    ...state,
    listColumnConfigState: {
      isLoading: false,
      success: true,
      payload: listColumnConfig,
    },
  })),
  on(MemberListActions.getListColumnSetConfigurationFailure, (state) => ({
    ...state,
    listColumnConfigState: {
      isLoading: false,
      success: false,
    },
  })),

  on(MemberListActions.createColumnSetConfigurationSuccess, (state,  {id}) => ({
    ...state,
    isLoading: false,
    updateColumnSetState: STATE.SUCCESS,
    idColumnSet: id
  })),

  on(MemberListActions.createColumnSetConfigurationFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    updateColumnSetState: STATE.FAIL,
  })),

  on(MemberListActions.reorderColumnSetSuccess, (state) => ({
    ...state,
    isLoading: false,
    reorderColumnSetState: STATE.SUCCESS,
  })),

  on(MemberListActions.reorderColumnSetFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    reorderColumnSetState: STATE.FAIL,
  })),
  on(MemberListActions.removeColumnSetConfigurationSuccess, (state, { id }) => {
    return {
      ...state,
      removeColumnSetState: id,
      removeColumnSetStatusState: STATE.SUCCESS,
    };
  }),
  on(MemberListActions.removeColumnSetConfigurationFailure, (state, { }) => {
    return {
      ...state,
      removeColumnSetStatusState: STATE.FAIL,
    };
  }),
  on(MemberListActions.editColumnSetConfigurationSuccess, (state, { id }) => ({
    ...state,
    isLoading: false,
    updateColumnSetState: STATE.SUCCESS,
    selectedColumnSetState: id,
    actionColumnSetState: ACTION.EDIT
  })),

  on(MemberListActions.editColumnSetConfigurationFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    updateColumnSetState: STATE.FAIL,
    actionColumnSetState: ACTION.EDIT
  })),
  on(MemberListActions.getMemberListConfiguration, (state, {}) => ({
    ...state,
    isLoading: true,
    memberList: {
      total: 0,
      members: [],
      columns: [],
      message: '',
    },
  })),
  on(MemberListActions.getMemberListConfigurationSuccess, (state, { memberList }) => ({
    ...state,
    isLoading: false,
    isError: false,
    memberList,
    success: true,
    error: undefined,
  })),
  on(MemberListActions.getMemberListConfigurationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isError: true,
    memberList: {
      total: 0,
      members: [],
      columns: [],
      message: '',
      success: false,
      error: error,
    },
  })),

  on(MemberListActions.getListColumnSetConfigurationEntityRequest, (state) => ({
    ...state,
    listColumnConfigState: {
      isLoading: true,
    },
  })),
  on(MemberListActions.getListColumnSetConfigurationEntitySuccess, (state, { listColumnConfig }) => ({
    ...state,
    listColumnConfigState: {
      isLoading: false,
      success: true,
      payload: listColumnConfig,
    },
  })),
  on(MemberListActions.getListColumnSetConfigurationEntityFailure, (state) => ({
    ...state,
    listColumnConfigState: {
      isLoading: false,
      success: false,
    },
  })),

  on(MemberListActions.getPropertyOfParticipantsRequestRequest, (state) => ({
    ...state,
    propertyConfiguration: {
      isLoading: true,
    },
  })),
  on(MemberListActions.getPropertyOfParticipantsRequestSuccess, (state, { propertyOfParticipants }) => ({
    ...state,
    propertyConfiguration: {
      isLoading: false,
      success: true,
      propertyList: propertyOfParticipants
    },
  })),

  on(MemberListActions.getPropertyOfParticipantsRequestFailure, (state) => ({
    ...state,
    propertyConfiguration: {
      isLoading: false,
    },
  })),

  on(MemberListActions.getCardSelectionRequest, (state) => ({
    ...state,
    cards: {
      isLoading: true,
    },
  })),
  on(MemberListActions.getCardSelectionRequestSuccess, (state, { response }) => ({
    ...state,
    cards: {
      isLoading: false,
      success: true,
      cards: response.cards
    },
  })),
  on(MemberListActions.getCardSelectionRequestFailure, (state) => ({
    ...state,
    cards: {
      isLoading: false,
    },
  })),

  on(MemberListActions.getPropertyByCardSelectionRequest, (state) => ({
    ...state,
    propertyByCardSelection: {
      isLoading: true,
    },
  })),
  on(MemberListActions.getPropertyByCardSelectionSuccess, (state, { response }) => ({
    ...state,
    propertyByCardSelection: {
      isLoading: false,
      success: true,
      propertyByCardSelection: response
    }
  })),

  on(MemberListActions.getPropertyByCardSelectionFailure, (state) => ({
    ...state,
    propertyByCardSelection: {
      isLoading: false,
    },
  })),
);
