<div class="flex justify-center items-center h-full w-full loading-spinner {{ classInput }}" *ngIf="isLoading">
  <div class="progress-spinner-container">
    <div #ref>
      <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!ref?.childElementCount">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>{{ textLoading }}</p>
    </ng-container>
  </div>
</div>
