import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { interval, Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';

import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { uploadFile } from '@ptg-shared/constance/listIcons.const';
import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb, FunctionButtonConfig } from '@ptg-shared/types/models/breadcrumb.model';
import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { UPLOAD_STATUS } from '@ptg-member/constance/lookupTable.const';
import * as fromBulkUpdate from '@ptg-bulk-update/reducers';

import { BulkUpdateHistoryData } from '../../models/bulk-update.model';
import { BulkUpdateHistoryActions } from '../../actions';
import { UploadFileComponent } from '../../components/upload-file/upload-file.component';
import { FileResultComponent } from '../../components/file-result/file-result.component';
import * as fromReducer from '@ptg-reducers';

@Component({
  selector: 'ptg-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent extends BaseComponent implements OnChanges {
  columns: Column[] = [
    {
      name: 'fileName',
      header: {
        title: 'File Name',
        style: {
          'padding-left': '72px'
        }
      },
      sortable: true,
      truncate: true,
      style: {
        'padding-left': '72px'
      }
    },
    {
      name: 'fileSize',
      style: {
        'font-weight': '400',
        'font-size': '18px',
        'line-height': '24px',
        'color': '#828282'
      },
      truncate: true
    },
    {
      name: 'dateUploaded',
      header: {
        title: 'Uploaded At',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy h:mm a'
      },
      truncate: true
    },
    {
      name: 'importedAt',
      header: {
        title: 'Imported At',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy h:mm a'
      },
      truncate: true
    },
    {
      name: 'importTime',
      header: {
        title: 'Import Time',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'uploadedBy',
      header: {
        title: 'Imported By',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'status',
      header: {
        title: 'Status',
      },
      truncate: true
    },
    {
      name: 'removeButton',
      header: {
        title: 'Action',
      },
    },
  ];
  currentFund: any = {};
  isLoading: boolean = true;
  isFetching: boolean = false;
  bulkUpdateData: (BulkUpdateHistoryData & Row)[] = [];
  errorMsg?: string = '';
  sortInfo: {} | any = {};
  totalRecords: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;

  @Input() name: string = '';
  @Input() target: string = '';
  @Input() targetType: string = '';
  @Input() fileTypes: string[] = [];
  @Input() listNoun?: string[] = [];
  @Input() importKey?: string;
  listBreadcrumbs!: Breadcrumb[];
  functionButtons!: FunctionButtonConfig[];

  constructor(
    private bulkUpdateStore: Store<fromBulkUpdate.State>,
    private dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    private store: Store<fromReducer.State>,
    sanitizer: DomSanitizer
  ) {
    super();
    iconRegistry.addSvgIconLiteral('upload-icon', sanitizer.bypassSecurityTrustHtml(uploadFile));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name || changes.target) {
      this.setListBreadcrumbs();

      if (!this.importKey && document.location.pathname.includes('/upload-history')) {
        return;
      }
      else {
        this.functionButtons = [{
          buttonName: this.target === 'AppContent' ? 'Bulk Upload' : 'Upload ' + this.name,
          icon: 'upload-icon',
          isSvgIcon: true,
          classInput: 'bulk-upload-icon'
        }];
      }
    }
  }

  setListBreadcrumbs() {
    if (this.targetType === 'Mixed' || this.target !== 'AppContent') {
      this.listBreadcrumbs = [
        {
          name: `${this.name} Upload History`
        }
      ];
      return;
    }

    this.listBreadcrumbs = [
      {
        name: this.name || '',
        url: `/page/${this.targetType}`
      }, {
        name: 'Upload History'
      }
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
     this.store.pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      ).subscribe(el => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
      this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-ptg-bulk-update-pageSize')) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-bulk-update-pageSize'));

    if (this.target === 'AppContent') {
      this.columns.splice(3, 0, {
        name: 'size',
        header: {
          title: 'Number of Records',
        },
        sortable: true,
        align: Align.Right
      },);
    }

    this.fetchHistory(true);
    interval(4000)
      .pipe(startWith(0), filter(num => !this.isFetching), takeUntil(this.unsubscribe$))
      .subscribe(() => this.fetchHistory(false));
    this.bulkUpdateStore.pipe(
      select(fromBulkUpdate.selectBulkUpdateHistoryState),
      takeUntil(this.unsubscribe$))
      .subscribe(el => {
        this.isLoading = el.isLoading;
        if (!this.isLoading) {
          this.bulkUpdateData = el.bulkUpdateHistoryData.sessions.map((el)=>({
            ...el,
            importTime: el.status == UPLOAD_STATUS.IMPORTED ? this.convertToHourMinuteSecondFormat(el.importTime) : null
          }));
          this.totalRecords = el.bulkUpdateHistoryData.total;
          this.isFetching = false;

          if (el.removeState === 'Success') {
            this.bulkUpdateStore.dispatch(BulkUpdateHistoryActions.removeBulkUpdateHistoryClear());
          }

          if (!el.success && el.error) {
            this.errorMsg = el.error?.statusText;
          }
        }
      });
  }
  private convertToHourMinuteSecondFormat(time: any) {
    if (time) {
      let timeInMilisecond = time / 10000
      let seconds = Math.floor(timeInMilisecond / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      seconds = seconds % 60;
      minutes = minutes % 60;
      if (hours == 0 && minutes == 0) {
        return seconds == 0 ? '1s' : `${seconds}s`
      }
      const nonzeroHours = hours > 0 ? `${hours}h ` : ''
      const nonzeroMinutes = minutes > 0 ? `${minutes}m ` : ''
      const nonzeroSeconds = seconds > 0 ? `${seconds}s ` : ''
      return nonzeroHours + nonzeroMinutes + nonzeroSeconds
    }
    return null;
  }
  private fetchHistory(initialLoad: boolean): void {
    this.isFetching = true;
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo.active) {
      sortField = this.sortInfo.active;
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.bulkUpdateStore.dispatch(BulkUpdateHistoryActions.getBulkUpdateHistory({
      initialLoad: initialLoad,
      target: this.target,
      targetType: this.targetType,
      pagination: {
        pageIndex: this.pageNumber,
        totalPerPage: this.pageSize,
        sortField, sortType
      }
    }));
  }

  uploadFile() {
    const dialogRef = this.dialog.open(UploadFileComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        name: this.name,
        target: this.target,
        targetType: this.targetType,
        fileTypes: this.fileTypes,
        listNoun: this.listNoun
      }
    });
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.fetchHistory(true);
  }

  showDetail(event: any) {
    if (event?.status === 'Validating' || event?.status === 'Importing') {
      return;
    }
    const dialogRef = this.dialog.open(FileResultComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        sessionId: event?.id,
        sessionStatus: event?.status
      }
    });
  }

  onChangePage(event: PageEvent) {
    this.pageNumber = event.pageNumber;
    this.pageSize = event.pageSize;
    sessionStorage.setItem(this.currentFund.key + "-ptg-bulk-update-pageNumber", this.pageNumber.toString());
    sessionStorage.setItem(this.currentFund.key + "-ptg-bulk-update-pageSize", this.pageSize.toString());
    this.fetchHistory(true);
  }

  onRemoveClick(event: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove this uploaded item?`,
        type: ConfirmType.Delete,
        title: 'Remove Item'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bulkUpdateStore.dispatch(BulkUpdateHistoryActions.removeBulkUpdateHistory({ sessionId: event.id }))
      }
    });
  }

  emitFunction() {
    this.uploadFile();
  }
}
