<ng-container>
  <div class="section-info">
    <ng-container>
      <p class="title">
        <span>{{ configCard.cardName }}</span>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="more_horiz"
          class="group-button ignore-row"
          *ngIf="(isShowButtonEdit && !configCard?.existOnlyNotePropertyAndEnableNote) ||
          (isShowButtonAdd && !configCard.isBenefitCard) ||
          (configCard.isDragDrop && dataCard[configCard.cardId + '_list'] && dataCardList.length > 0) ||
          (configCard.attachmentType === 1)"
        >
          <mat-icon aria-hidden="true" class="ignore-row">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="isShowButtonEdit && !configCard?.existOnlyNotePropertyAndEnableNote" mat-menu-item (click)="editCard()">
            <mat-icon class="member-section-action-icon">edit</mat-icon>
            <span class="label-icon">Edit</span>
          </button>
          <button aria-label="Add Record" *ngIf="isShowButtonAdd && !configCard.isBenefitCard" mat-menu-item (click)="editCard(true)">
            <mat-icon class="member-section-action-icon">add</mat-icon>
            <span class="label-icon">Add Record</span>
          </button>
          <button aria-label="Manage"
            *ngIf="configCard.isDragDrop && dataCard[configCard.cardId + '_list'] && dataCardList.length > 0"
            mat-menu-item
            (click)="manageSection()"
          >
            <mat-icon class="member-section-action-icon"> list_alt </mat-icon>
            <span class="label-icon">Manage</span>
          </button>
          <button aria-label="Attachment" *ngIf="configCard.attachmentType === 1" mat-menu-item (click)="showAttachments()">
            <mat-icon class="member-section-action-icon">download</mat-icon>
            <span class="label-icon">Attachment</span>
          </button>
          <button mat-menu-item (click)="openAuditTrailsScreen()">
            <mat-icon class="member-section-action-icon">history</mat-icon>
            <span class="label-icon">Audit</span>
          </button>
        </mat-menu>
      </p>
      <div class="demo-content" *ngFor="let card of cardComponents">
        <!-- List -->
        <ptg-member-summary-card-list
          *ngIf="card.propertyDisplay === null && card.cardProperties.length > 0"
          #memberSummaryCardList
          [propertiesConfig]="card.cardProperties"
          [data]="dataCardList"
          [cardId]="configCard.cardId"
          [viewId]="viewId ?? ''"
          [currentEntityReferenceLinkedId]="currentEntityReferenceLinkedId ?? ''"
          [currentCardDetailViewUrl]="currentCardDetailViewUrl ?? ''"
          [cardData]="dataCard"
          [columns]="columns"
          [configDataless]="configCard.datalessConfig"
          [memberId]="memberId"
          [menuName]="menuName"
          [lengthPg]="lengthPg"
          [pageSize]="pageSize"
          [pageNumber]="pageNumber"
          [isLoading]="isLoading"
          [notFoundMessage]="notFoundMessage"
          (pageChange)="onChangePage($event)"
          (sortChange)="onSortChange($event)"
          (rowClick)="onRowClick($event)"
        ></ptg-member-summary-card-list>

        <!-- Table -->
        <ng-container *ngIf="card.propertyDisplay === PROPERTY_DISPLAY.Table && card.cardProperties.length > 0 && !configCard?.existOnlyNotePropertyAndEnableNote">
          <ptg-member-summary-card-table
            [propertiesConfig]="card.cardProperties"
            [cardData]="dataCard?.[configCard.cardId]"
            [configDataless]="configCard.datalessConfig"
          ></ptg-member-summary-card-table>
        </ng-container>

        <!-- Column -->
        <ng-container *ngIf="card.propertyDisplay === PROPERTY_DISPLAY.Column && card.cardProperties.length > 0 && !configCard?.existOnlyNotePropertyAndEnableNote">
          <ptg-member-summary-card-column
            [propertiesConfig]="card.cardProperties"
            [cardData]="dataCard?.[configCard.cardId]"
            [configDataless]="configCard.datalessConfig"
          ></ptg-member-summary-card-column>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
