import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  addDisabilityPaymentAction,
  getDisabilityPaymentAction,
} from '@ptg-member/store/actions/municipality-payment.action';
import { AddDisabilityPaymentRequestBody, DisabilityPayment } from '@ptg-member/types/models';
import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Align, Column, ColumnType, GridComponent, Row } from '@ptg-shared/controls/grid';
import { takeUntil } from 'rxjs/operators';
import * as fromMember from '../../store/reducers';
import { OverviewHeaderEntityComponent } from '../overview-header-entity/overview-header-entity.component';

@Component({
  selector: 'ptg-add-disability-payment-popup',
  templateUrl: './add-disability-payment-popup.component.html',
  styleUrls: ['./add-disability-payment-popup.component.scss'],
})
export class AddDisabilityPaymentPopupComponent extends BaseComponent {
  @ViewChild('overViewHeaderEntity') overViewHeaderEntity!: OverviewHeaderEntityComponent;
  @ViewChild('disabilityPaymentsList') disabilityPaymentsList!: GridComponent<any>;

  memberId: string = '';
  isLoading: boolean = false;
  dataTable: DisabilityPayment[] = [];
  listRecordSelected: DisabilityPayment[] = [];
  columns: Column[] = [
    {
      name: 'year',
      header: {
        title: 'Year',
      },
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality Name (ID)',
      },
      truncate: true,
    },
    {
      name: 'disabilityFee',
      header: {
        title: 'Disability Fees',
        style: {
          'padding-right': '400px',
        },
      },
      align: Align.Right,
      style: {
        'padding-right': '400px',
      }
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddDisabilityPaymentPopupComponent>,
    private memberStore: Store<fromMember.MemberState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.memberId = this.data.memberId;
    this.dataTable = this.data.dataTable;

    if (this.overViewHeaderEntity) {
      this.overViewHeaderEntity.getMemberProfileData();
    }
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onAddPayment() {
    const invalidSelectedYears = this.listRecordSelected.filter((item) => item.disabilityFee === null || item.disabilityFee === undefined).map((item) => item.year).sort();
    if (invalidSelectedYears && invalidSelectedYears.length > 0) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        data: {
          title: 'Warning',
          text: `No Fee Amount found for ${invalidSelectedYears.join(", ")}.`,
          type: ConfirmType.Warning,
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        data: {
          title: 'Confirmation',
          text: 'Are you sure you want to add Disability Payment(s) for the selected year(s)?',
          type: ConfirmType.ConfirmSave,
          saveButtonTitle: 'Yes',
          cancelButtonTitle: 'Back',
          hideSaveAsButton: true,
          hideConfirmButton: true,
        },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const listRecordSelected = this.listRecordSelected.map((record) => {
            return {
              year: record.year,
              municipalityId: record.municipalityId,
              disabilityFee: record.disabilityFee,
            } as DisabilityPayment;
          });
          const body = {
            memberId: this.memberId,
            disabilityPayments: listRecordSelected,
          } as AddDisabilityPaymentRequestBody;

          this.memberStore.dispatch(addDisabilityPaymentAction({ body }));
          this.dialogRef.close();
        }
      });
    }
  }


  onChangeSelection(row: any & Row) {
    // Check/Uncheck a row
    if (row) {
      const selectedRecordsByYear = this.disabilityPaymentsList.dataSource.filter((item: any) => item.year === row.year);
      selectedRecordsByYear.forEach((item: any) => {
        item.checked = row.checked;

        if (item.checked) {
          this.disabilityPaymentsList.selection.select(item);
          this.listRecordSelected.push(item);
        } else {
          this.disabilityPaymentsList.selection.deselect(item);
          const startIndex = this.listRecordSelected.indexOf(item);
          this.listRecordSelected.splice(startIndex, 1);
        }
      });
    } else {
      /**
       * Check/Uncheck master toggle to check/uncheck all -> do not emit data
       * Check -> get all checked item
       * Uncheck -> get []
      */
      this.listRecordSelected = this.disabilityPaymentsList.dataSource.filter((item: any) => item.checked);
    }

  }
}
