import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  removeAccountIcon,
  shieldCheckIcon,
} from '@ptg-shared/constance/listIcons.const';
import { ACTION, ENTITY_ORGANIZATION_GUID, GUID_EMPTY, STATE } from '@ptg-shared/constance/value.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { LayoutActions } from '@ptg-shared/layout/actions';
import {
  DisplayedTabName, STRING_QUERY_PARAM,
} from '@ptg-shared/layout/constance/layout.const';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { FileService } from '@ptg-shared/services/file-service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import {
  deepClone,
  deepEqual,
  showBanner,
} from '@ptg-shared/utils/common.util';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { SideType } from '@ptg-member/types/enums';

import * as MetadataActions from '../../store/actions/metadata.actions';
import * as fromReducer from '../../../reducers';
import { AddressHistoryAction, MemberDetailActions, MemberListActions } from '../../store/actions';
import { EditMemberStatusHistoryComponent } from '../../components/edit-member-status-history/edit-member-status-history.component';
import {
  ActionItem,
  MemberDetailWithPaging,
  MemberNavigationResponse,
  MemberNavigationState,
  MemberStatus,
  MetadataSection,
  OverviewData,
} from '../../types/models';
import * as fromMember from '../../store/reducers';
import { State } from '../../store/reducers/member-detail.reducer';
import { MemberDetailService } from '../../services/member-detail.service';
import { FixedSection, SectionLayout } from '../../constance/metadata.const';
import { SCREEN_ID } from '../../constance/lookupTable.const';
import { ActionCode, PROPERTY_DISPLAY } from '@ptg-member/constants';
import { MemberManageAttachmentListComponent } from '@ptg-member/components/member-manage-attachment-list/member-manage-attachment-list.component';
import { BenefitDetailViewComponent, CreateEntityDataComponent } from '@ptg-entity-management/components';
import { EntityManagementState } from '@ptg-entity-management/store/reducers';
import { deactivatedMemberSelector, updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';
import { clearUpdateMemberCardStateAction } from '@ptg-member/store/actions/member.action';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { FundType } from '@ptg-shared/types/enums';
import { CardComponentType, EntityProfileFixType, EntityPropertyType, IncludeAttachmentType, RelationshipOptions } from '@ptg-entity-management/types/enums';
import { EntityViewItem } from '@ptg-entity-management/services/models';
import { selectUploadMemberDocumentState } from '../../store/reducers';
import { clearUploadDocumentsStateAction } from '@ptg-member/store/actions/member-document.action';
import { Location } from '@angular/common';
import { createDepositAccountsSelector } from '@ptg-member/store/selectors';
import { clearCreateDepositAccountsStateAction } from '@ptg-member/store/actions/deposit-account.action';
import { PERSON_PROPERTY_MAPPING } from '@ptg-entity-management/constants';
import { ENTITY_MAPPING, NOTES_PROPERTY, RELATIONSHIP_LIST_PROPERTY_FIXED_ID } from '@ptg-entity-management/constants/entity-mapping.constant';
import { isEqual } from '@ptg-shared/utils/string.util';

const PAGE_SIZE_CONST = '-ptg-member-profile-section-pageSize';
@Component({
  selector: 'ptg-member-summary-view',
  templateUrl: './member-summary-view.component.html',
  styleUrls: ['./member-summary-view.component.scss'],
})
export class MemberSummaryViewComponent implements OnInit, OnDestroy {
  @ViewChild(OverviewHeaderEntityComponent) overViewHeader!: OverviewHeaderEntityComponent;
  readonly SideType = SideType;
  readonly EntityProfileFixType = EntityProfileFixType;
  readonly BannerType = BannerType;
  currentFund: any = {};
  pageSize: number = 10;
  memberId: string = '';
  viewId: string = '';
  menuItemId: string = '';
  screenId: string = '';
  isLocked$: Observable<any> = new Observable();
  detailInfo: State | any = {
    isLoading: true,
    isDeleting: false,
    wasDeleted: false,
    memberDetail: {},
  };
  crrListCardConfig!: string[];
  identityKey: string = '';
  memberKey: string = '';
  isLocked = false;
  listSection: MetadataSection[] = [];
  listCard: EntityViewItem[] = [];
  message = '';
  sectionName = '';
  bannerType: BannerType = BannerType.Hidden;
  bannerTypeWarning: BannerType = BannerType.Hidden;
  isLoadingInfo: boolean = false;
  overviewData!: OverviewData[];
  updateLayoutSubscription$: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  );
  @ViewChild('memberDetail') memberDetailRef!: ElementRef;
  unsubscribe$ = new Subject<void>();
  listStatus!: MemberStatus[];
  memberStatus: MemberStatus | undefined;
  memberNavigationState!: MemberNavigationState;
  fundId: string = '';
  statusId: any;
  listBreadcrumbs: Breadcrumb[] = [];
  settings: Breadcrumb[] = [];
  menuItems: any;
  menuName: string = '';
  menuId: string = ''
  viewName: string = '';
  isNoData: boolean = false;
  profileOverviewName: string = '';
  actionItems: ActionItem[] = [];
  isLoading = true;
  isLoadingViewItems = true;
  isLoadingMemberData = false;
  isLoadingLockMember = false;
  isOverview = false;
  isProfileNavigate = false;
  memberSummaryData: any;
  listRecordIdAll: string[] = [];
  entityId: string = '';
  noProfileConfigured = false;
  isDeactivate: boolean = false;
  isShowBanner: boolean = false;
  cardNameDoc: any;
  remittanceQueryParams = {
    remittanceId: '',
    employerId: ''
  }
  currentProfileIsOrganization: boolean = false;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private entityManagementStore: Store<EntityManagementState>,
    public route: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    public router: Router,
    private memberDetailService: MemberDetailService,
    private store: Store<fromReducer.State>,
    private location: Location
  ) {
    iconRegistry.addSvgIconLiteral(
      'remove-account',
      sanitizer.bypassSecurityTrustHtml(removeAccountIcon)
    );
    iconRegistry.addSvgIconLiteral(
      'thumbs-up',
      sanitizer.bypassSecurityTrustHtml(shieldCheckIcon)
    );
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit(): void {
    this.store.select(fromReducer.selectCurrentFundState).subscribe((el) => {
      this.fundId = el.id;
      this.pageSize = el.defaultPageSize ?? 10;
      this.currentFund = el;
      this.pageSize =
        Number(
          sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
        ) === 0
          ? this.pageSize
          : Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          );
    });
    this.selectorDeactivateMember();
    this.selectorUploadDocument();
    this.route.params.subscribe((params) => {
      this.memberId = params.memberId;
      this.memberDetailService.memberId = params.id;
      this.menuId = params.menuId;
      this.viewId = params.viewId;
      this.menuItemId = params.menuId;
      this.getViewConfig();
      this.isOverview = params.isOverview === 'true';
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.remittanceQueryParams.remittanceId = queryParams?.remittanceId;
      this.remittanceQueryParams.employerId = queryParams?.employerId;
      this.currentProfileIsOrganization = queryParams?.entityReferenceLinkedId?.toLowerCase() === ENTITY_ORGANIZATION_GUID.toLowerCase();
    });

    combineLatest([
      this.store.select(fromMember.selectEntityViewItems),
      this.store.select(fromLayoutReducer.selectProfileNavigationState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([state, menuItem]) => {
        if (state && !state.isLoading && menuItem?.menu?.length > 0) {
          this.noProfileConfigured = false;
          if (menuItem.menu.length > 0) {
            this.entityId = (menuItem?.memberNavigationList as any).entityId;
            if (this.isOverview) {
              this.listBreadcrumbs = [
                {
                  name: 'Participants',
                  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
                  url: '/member',
                },
                {
                  name: (menuItem.memberNavigationList as any).overviewLabel || '',
                  url: '',
                },
              ];
              this.menuName = (menuItem.memberNavigationList as any).overviewLabel;
            } else {
              const memberNavigationMenu: MemberNavigationResponse[] =
                menuItem.memberNavigationList?.memberNavigationMenu;
              this.menuItems = memberNavigationMenu
                ?.map((item) => {
                  return item.menuItems;
                })
                ?.reduce((a, b) => {
                  return a.concat(b);
                });

              this.menuName = this.menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
              this.listBreadcrumbs = [
                {
                  name: this.menuName || '',
                  url: '',
                },
              ];
            }

            if (this.router.url.includes(STRING_QUERY_PARAM.PROFILE_NAVIGATE)) {
              this.isProfileNavigate = true;
              this.listBreadcrumbs = [
                {
                  name: (menuItem.memberNavigationList as any).overviewLabel || '',
                  url: '',
                },
              ];
            }

            if (this.remittanceQueryParams.remittanceId && this.remittanceQueryParams.employerId) {
              this.listBreadcrumbs = [
                {
                  name: 'Remittance',
                  url: `/employer/remittance-submission/${this.remittanceQueryParams.employerId}/${this.remittanceQueryParams.remittanceId}`,
                },
                {
                  name: 'Profile',
                },
              ];
            }

            if (this.settings.length > 0) {
              this.settings[0].url = `/member/view/${this.viewId}?prevUrl=${encodeURIComponent(
                this.router.url
              )}&prevName=${this.menuName}`;
            }
          }

          if (state) {
            if (state.payload) {
              const listCard = (deepClone(state.payload.entityViewItems) as EntityViewItem[])?.sort(
                (a, b) => a.order - b.order
              );
              this.listCard = listCard.map(item => {
                return {
                  ...item,
                  isHideCard: item.cardComponents.every(card => card.cardProperties.length === 0)
                }
              });
              let listCardConfig: string[] = [];
              let arrCardConfig = this.listCard.filter(card => !card.isHideCard);
              for (let card of arrCardConfig) {
                for (let child of card.cardComponents) {
                  if (child.propertyDisplay === PROPERTY_DISPLAY.Column || child.propertyDisplay === PROPERTY_DISPLAY.Table) {
                    listCardConfig.push(card.cardId)
                  } else {
                    listCardConfig.push(`${card.cardId}_list`);
                    listCardConfig.push(`${card.cardId}_all`);
                  }
                }
              }

              this.crrListCardConfig = listCardConfig;

              if (listCardConfig.length > 0) {

                if (this.isOverview) {
                  this.screenId = `${menuItem.profileName.replace(/\s/g, '')}_overview_`;
                }
                else {
                  var menu = menuItem.memberNavigationList.memberNavigationMenu.find(x => x.menuItems.find(item => item.id == this.menuItemId) != null);
                  var menuName = menu?.name;
                  var menuItemName = menu?.menuItems.find(x => x.id === this.menuItemId)?.name;
                  this.screenId = `${menuItem.profileName.replace(/\s/g, '')}_${menuName?.replace(/\s/g, '')}_${menuItemName?.replace(/\s/g, '')}_`;
                }
                this.isLoadingMemberData = true;

                this.cardNameDoc = this.listCard.find(item => item.listLabel === 'Documents')?.cardName;
                this.memberStore.dispatch(
                  MemberDetailActions.getMemberDataSummaryAction({
                    memberId: this.memberId,
                    entityId: this.entityId,
                    isSummary: true,
                    listCard: listCardConfig,
                    pageSize: this.pageSize,
                    screenId: this.screenId
                  })
                );
              }

              this.viewName = state.payload.viewName;
              this.settings[0] = {
                name: this.viewName + ` Configuration`,
                url: `/member/view/${this.viewId}?prevUrl=${encodeURIComponent(
                  this.router.url
                )}&prevName=${this.menuName}`
              };
            }
            this.isLoadingViewItems = state.isLoading;
          }
        } else if (state && !state.isLoading && (!menuItem?.menu || menuItem?.menu?.length === 0) && !menuItem?.profileName) {
          this.noProfileConfigured = true;
        }
        this.isLoading = this.isLoadingMemberData && this.isLoadingViewItems;
      });

    this.memberStore
      .pipe(select(fromMember.selectMemberData), takeUntil(this.unsubscribe$))
      .subscribe((memberData) => {
        this.detailInfo.memberInfo = memberData.memberInfo;
        this.detailInfo.isLoading =
          memberData.isLoading ||
          memberData.isLoadingMemberInfo ||
          memberData.isLoadingMetadataOverview;
        this.detailInfo.isDeleting = memberData.isDeleting;
        this.detailInfo.memberDetail = memberData.memberDetail;
        this.detailInfo.profileOverviewConfig =
          memberData.profileOverviewConfig;
        if (
          this.detailInfo.memberInfo &&
          Object.keys(this.detailInfo.memberInfo).length > 0
        ) {
          this.getOverviewData();
          if (
            this.statusId &&
            this.statusId !== this.detailInfo.memberInfo?.statusId
          ) {
            this.memberStore.dispatch(
              MemberDetailActions.getMetadataBasedOnProfileOverviewConfig({
                memberId: this.memberId,
              })
            );
            this.store.dispatch(
              LayoutActions.profileNavigationItemRequest({
                memberId: this.memberId,
              })
            );
          }
          this.statusId = this.detailInfo.memberInfo?.statusId;
        }
      });

    this.memberStore
      .pipe(select(fromMember.selectMemberSummaryData), takeUntil(this.unsubscribe$))
      .subscribe((memberSummaryData) => {
        if (memberSummaryData.isLoadingView !== undefined) {
          this.isLoadingViewItems = memberSummaryData.isLoadingView;
          if (memberSummaryData.isLoadingMemberData === undefined) {
            return;
          }
          this.isLoadingMemberData = memberSummaryData.isLoadingMemberData;
          this.memberSummaryData = memberSummaryData.memberDataSummary as any;

          if (this.memberSummaryData) {
            this.listRecordIdAll = [];
            this.listCard = this.listCard.map(item => {
              const cardNoData = this.memberSummaryData[item.cardId]?.cardNoData && (!this.memberSummaryData[`${item.cardId}_list`] || this.memberSummaryData[`${item.cardId}_list`]?.cardNoData);
              const isHideCard = item.cardComponents.every(card => card.cardProperties.length === 0);
              const enableNote = this.memberSummaryData?.[item?.cardId]?.enableNote;
              const existOnlyNotePropertyAndEnableNote = 
                this.memberSummaryData?.[item?.cardId]?.results?.length === 1
                && this.memberSummaryData?.[item?.cardId]?.results?.[0]?.entityPropertyId?.toLowerCase() === NOTES_PROPERTY.Id?.toLowerCase()
                && this.memberSummaryData?.[item?.cardId]?.enableNote;
              const existNotePropertyAsStandAloneProperty =
                this.memberSummaryData?.[item?.cardId]?.enableNote
                && this.memberSummaryData?.[item?.cardId]?.results?.some((item: any) => item?.entityPropertyId?.toLowerCase() === NOTES_PROPERTY.Id?.toLowerCase());
              const currentCardIsListCard = !!item?.entityComponentId;

              // 113610 Get list recordID for exclude when search
              if (this.memberSummaryData[`${item.cardId}_all`]?.cardNoData === false && this.memberSummaryData[`${item.cardId}_all`]?.isShowButtonAdd) {
                const cardAllData = this.memberSummaryData[`${item.cardId}_all`];
                cardAllData.rowData?.forEach((row: any) => {
                  row.values.forEach((value: { recordId: string; }) => {
                    let index = this.listRecordIdAll.findIndex(item => item === value.recordId);
                    if (index === -1) {
                      this.listRecordIdAll.push(value.recordId);
                    }
                  })
                });
              }


              // Handle for Relationship Card
              const searchExceptRecords: any[] = [];
              this.memberSummaryData[`${item.cardId}_all`]?.rowData?.forEach((row: any) => {
                row.values.forEach((value: any) => {
                  let index = searchExceptRecords.findIndex(item => item === value.recordId && item.entityPropertyId === value.entityPropertyId);
                  if (value.propertyType === EntityPropertyType['Entity Reference'] && index === -1) {
                    searchExceptRecords.push({ recordId: value.recordId, entityPropertyId: value.entityPropertyId});
                  }
                })
              });

              // Check in all record list exist a record that have Relationship = Spouse or not
              const isAtLeastOneRecordIsSpouse = !!this.memberSummaryData[`${item.cardId}_all`]?.rowData?.some((row: any) =>
                row?.isRelatedPerson === false
                && row?.values?.some((value: any) => 
                  value?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship.toUpperCase()
                  && (
                    value?.options?.find((opt: any) => opt?.id === value?.value)?.relationshipType
                    === RelationshipOptions.Spouse
                  )
                )
              );
              // End of Handle for Relationship Card

              const isInactiveActionAddRelationships = item.cardComponentType === CardComponentType.Relationships
                && (isEqual(item.entityId, ENTITY_MAPPING.Person) || isEqual(item.entityId, ENTITY_MAPPING.Participant));

              const currentEntityReferenceLinkedId = new URLSearchParams(document.location.search).get("entityReferenceLinkedId") ?? '';

              return {
                ...item,
                isShowButtonAdd: this.memberSummaryData[`${item.cardId}_list`]?.isShowButtonAdd && !isInactiveActionAddRelationships,
                isHideCard: isHideCard || (item.datalessConfig.isHideEmptyCard && cardNoData),
                isConfigured: !isHideCard && item.datalessConfig.isHideEmptyCard && cardNoData,
                isCourtOrder: this.memberSummaryData[`${item.cardId}_list`]?.isCourtOrder ? true : false,
                enableNote,
                existOnlyNotePropertyAndEnableNote,
                isInactiveActionAddRelationships,
                searchExceptRecords,
                isAtLeastOneRecordIsSpouse,
                existNotePropertyAsStandAlonePropertyInListCard: existNotePropertyAsStandAloneProperty && currentCardIsListCard,
                currentEntityReferenceLinkedId,
              }
            });

            // Add the "Add [Card Label]" items into the Action dropdown
            this.actionItems = this.listCard
              .filter(
                (card) =>
                  card.isConfigured && !card.isBenefitCard && !card.isInactiveActionAddRelationships
              )
              .sort((a, b) => {
                if (a.isRight && !b.isRight)
                  return 1;
                if (!a.isRight && b.isRight)
                  return -1;
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return 0;
              })
              .map(
                (card) =>
                ({
                  id: card.cardId,
                  title: `Add ${card.cardName}`,
                  icon: 'add',
                } as ActionItem)
              );
          }
        }
        this.isLoading = this.isLoadingMemberData && this.isLoadingViewItems;
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberDetail),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberDetail) => {
        const { payload } = memberDetail ?? {};
        if (payload) {
          this.identityKey = payload.identityKey ?? "";
          if (this.identityKey === "" && !payload.isDisabledAppModule) {
            this.bannerTypeWarning = payload.isMemberOrParticipant ? BannerType.Warning : BannerType.Hidden;
          }
          this.memberKey = payload?.memberKey;
          this.isLocked = payload?.isLocked;
          this.isDeactivate = payload?.isValidToChange?.Deactive?.isValid;
          this.isLoadingLockMember = false;
        }
      }, (error) => {
        if (error) {
          this.isLoadingLockMember = false;
        }
      })

    this.memberStore
      .pipe(
        select(fromMember.selectMemberDetailState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        if (el?.removeSectionState === STATE.SUCCESS) {
          this.memberStore.dispatch(MemberDetailActions.removeSectionReset());
          showBanner.call(
            this,
            BannerType.Success,
            this.sectionName,
            ACTION.REMOVE
          );
          this.getMemberDetail();
        }

        if (el?.removeSectionState === STATE.FAIL) {
          this.memberStore.dispatch(MemberDetailActions.removeSectionReset());
          showBanner.call(
            this,
            BannerType.Fail,
            this.sectionName,
            ACTION.REMOVE
          );
        }

        if (el.removeMemberState === STATE.FAIL) {
          showBanner.call(this, BannerType.Fail, 'participant', ACTION.REMOVE);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
        }

        if (el.wasDeleted) {
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          this.memberStore.dispatch(
            MemberListActions.removeMemberSuccess({ memberKey: this.memberKey })
          );
          const memberIndex = this.memberNavigationState.members.findIndex(
            (member) => this.memberId === member._memberId
          );
          const query = JSON.parse(
            JSON.stringify(this.memberNavigationState.query)
          );
          if (memberIndex === 0 && query.pageIndex > 1) {
            query.pageIndex -= 1;
          }

          // Close
          this.store.dispatch(
            LayoutActions.setTabHiddenState({
              tabName: DisplayedTabName.IndividualParticipant,
              isHidden: true,
            })
          );

          let url = '/member';
          this.router.navigate([url], { state: { beforeState: query } });
        }
        if (el.addSectionState?.saveSectionDataSuccess) {
          this.getMemberDetail();
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          showBanner.call(
            this,
            BannerType.Success,
            el.addSectionState?.sectionData?.type === SectionLayout.List
              ? this.sectionName
              : el.addSectionState?.sectionData?.sectionTitle!,
            el.addSectionState.isRemove
              ? ACTION.REMOVE
              : el.addSectionState?.sectionData?.type === SectionLayout.List
                ? ACTION.ADD
                : ACTION.EDIT
          );
        } else if (el.addSectionState.sectionData) {
          showBanner.call(
            this,
            BannerType.Fail,
            el.addSectionState?.sectionData?.type === SectionLayout.List
              ? this.sectionName
              : el.addSectionState?.sectionData?.sectionTitle!,
            el.addSectionState.isRemove ? ACTION.REMOVE : ACTION.ADD
          );
        } else if (el.addSectionState.error) {
          showBanner.call(this, BannerType.Fail, this.sectionName, ACTION.ADD);
        }

        if (el.addSectionState?.reloadSection) {
          if (el.addSectionState?.sectionData?.key === 'demographics') {
            this.memberStore.dispatch(
              MemberDetailActions.getMetadataBasedOnProfileOverviewConfig({
                memberId: this.memberId,
                screenId: SCREEN_ID.ParticipantOverview,
              })
            );
            this.memberStore.dispatch(
              MemberDetailActions.getMemberById({
                id: this.memberId,
                screenId: SCREEN_ID.ParticipantOverview,
              })
            );
          } else {
            this.getMemberSection(el.addSectionState?.sectionData?.key || '');
          }
        }

        this.updateLayoutSubscription$.next();

        if (el.lockMemberState === STATE.SUCCESS) {
          showBanner.call(this, BannerType.Success, 'Participant', ACTION.LOCK);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          this.refeshProfileHeder();
        }

        if (el.lockMemberState === STATE.FAIL) {
          showBanner.call(this, BannerType.Fail, 'participant', ACTION.LOCK);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          this.refeshProfileHeder();
        }

        if (el.unLockMemberState === STATE.FAIL) {
          showBanner.call(this, BannerType.Fail, 'participant', ACTION.UNLOCK);
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          this.refeshProfileHeder();
        }

        if (el.unLockMemberState === STATE.SUCCESS) {
          showBanner.call(
            this,
            BannerType.Success,
            'Participant',
            ACTION.UNLOCK
          );
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          this.refeshProfileHeder();
        }

        if (el?.setMemberEventState) {
          const query: MemberDetailWithPaging = {
            pageIndex: FIRST_PAGE,
            totalPerPage: 10,
            sortField: 'statusDate',
            sortType: 1,
          };
          this.memberStore.dispatch(
            MemberDetailActions.getSectionData({
              memberId: this.memberId,
              sectionKey: FixedSection.Status,
              query,
            })
          );
          this.memberStore.dispatch(
            MemberDetailActions.getMemberById({
              id: this.memberId,
              screenId: SCREEN_ID.ParticipantOverview,
            })
          );
          this.memberStore.dispatch(
            MemberDetailActions.clearSetMemberEventState()
          );
        }
      });

    this.memberStore
      .pipe(select(fromMember.selectListStatus), takeUntil(this.unsubscribe$))
      .subscribe((listStatus) => {
        this.listStatus = listStatus;
        this.getMemberEventStatus();
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberNavigationState),
        distinctUntilChanged((previous, current) =>
          deepEqual(previous, current)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberNavigationState) => {
        this.memberNavigationState = memberNavigationState;
      });

    this.memberStore
      .pipe(
        select(fromMember.selectStatusHistoryState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (
          state.createState == STATE.SUCCESS ||
          state.updateState == STATE.SUCCESS
        ) {
          this.memberStore.dispatch(
            MemberDetailActions.resetStateRemoveMember()
          );
          showBanner.call(
            this,
            state.createState || '',
            'Status History',
            ACTION.ADD
          );
          showBanner.call(
            this,
            state.updateState || '',
            'Status History',
            ACTION.EDIT
          );
        }

        if (state.createState == STATE.FAIL) {
          showBanner.call(
            this,
            state.createState || '',
            'Status History',
            ACTION.ADD
          );
        }
      });

    this.memberStore
      .pipe(select(updateMemberCardSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el) {
          if (el.success === true) {
            this.bannerType = BannerType.Success;
            this.message = `${el.payload?.cardName} successfully updated.`;
            this.reloadOverviewHeader();
          }

          this.memberStore.dispatch(
            clearUpdateMemberCardStateAction()
          );
        }
      });
    this.store
      .pipe(select(fromMember.selectRemoveMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          showBanner.call(this, state?.state, 'Document', ACTION.REMOVE);
        }
      });

    this.getAddressStore();
    this.getCreateDepositAccountSelector();
  }

  getDataSummary() {
    if (this.crrListCardConfig.length > 0) {
      this.isLoadingMemberData = true;
      this.memberStore.dispatch(
        MemberDetailActions.getMemberDataSummaryAction({
          memberId: this.memberId,
          entityId: this.entityId,
          isSummary: true,
          listCard: this.crrListCardConfig,
          pageSize: this.pageSize,
          screenId: this.screenId
        })
      );
    }
  }

  getAddressStore() {
    this.memberStore
      .pipe(
        select(fromMember.selectAddressHistoryState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        showBanner.call(
          this,
          state.createAddress || '',
          'Address',
          ACTION.ADD
        );
        if (state.createAddress || state.updateAddress) {
          this.memberStore.dispatch(
            AddressHistoryAction.clearAddressHistoryState()
          );
        }
      });
  }

  getCreateDepositAccountSelector() {
    this.memberStore
    .pipe(
      select(createDepositAccountsSelector),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state?.success) {
        this.bannerType = BannerType.Success;
        showBanner.call(
          this,
          STATE.SUCCESS,
          'Deposit Account',
          ACTION.ADD
        );
      } 

      if (state?.error) {
        this.bannerType = BannerType.Fail;
        showBanner.call(
          this,
          STATE.FAIL,
          'Deposit Account',
          ACTION.ADD
        );
      }
      
      this.memberStore.dispatch(
        clearCreateDepositAccountsStateAction()
      );
    });
  }

  refeshProfileHeder() {
    if (this.memberId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberDetailAction({
          memberId: this.memberId
        })
      );
    }
    this.isLoadingMemberData = false;
  }
  getOverviewData() {
    this.overviewData = [];
    this.overviewData.push(
      this.detailInfo?.memberInfo?.profileHeaderData
        ? this.detailInfo.memberInfo.profileHeaderData[0]
        : { label: '', value: '' }
    );
    this.getMemberEventStatus();
  }

  getMemberDetail() {
    this.memberStore.dispatch(
      MemberDetailActions.getMemberById({
        id: this.memberId,
        screenId: SCREEN_ID.ParticipantOverview,
      })
    );
    this.memberStore.dispatch(
      MemberDetailActions.getMetadataBasedOnProfileOverviewConfig({
        memberId: this.memberId,
        screenId: SCREEN_ID.ParticipantOverview,
      })
    );
  }

  getViewConfig() {
    this.memberStore.dispatch(
      MemberDetailActions.getViewConfigsAction({
        viewId: this.viewId
      })
    );
  }

  getMemberEventStatus() {
    if (!this.overviewData?.length || !this.detailInfo.memberInfo?.statusId) {
      return;
    }
    this.listStatus = this.listStatus?.filter(
      (status) =>
        status.active || status.id === this.detailInfo.memberInfo?.statusId
    );
    this.memberStatus = this.listStatus?.find(
      (item) => item.id === this.detailInfo.memberInfo?.statusId
    );
    this.memberDetailService.isRetiree = this.memberStatus?.name === 'Retired';
    const currentEvent = this.memberStatus?.memberEvent?.find(
      (item) => item.id === this.detailInfo.memberInfo?.eventId
    );
    this.overviewData[1] = {
      label: 'Latest Event',
      value: currentEvent?.name,
      config: {
        color: this.memberStatus?.color,
        iconName: this.memberStatus?.iconName,
      },
    };
  }

  getMemberSection(sectionKey: string) {
    this.memberStore.dispatch(
      MemberDetailActions.getSectionData({
        memberId: this.memberId,
        sectionKey,
      })
    );
  }

  showAttachments(configSection: any) {
    if (configSection.attachmentType === IncludeAttachmentType.CardLevel) {
      const currentCard = this.listCard.find(c => c.cardId === configSection?.cardId);
      const isExistFiledDeathCertificateProperty = currentCard?.cardComponents?.some(c => {
        return c.cardProperties?.some(p => p.propertyId.toLowerCase() === PERSON_PROPERTY_MAPPING.FiledDeathCertificate.toLowerCase())
      });

      this.dialog.open(MemberManageAttachmentListComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        data: {
          memberId: this.memberId,
          breadcrumbs: this.listBreadcrumbs,
          recordId: configSection?.cardId,
          menuName: this.menuName,
          isOverview: this.isOverview,
          viewId: this.viewId,
          cardName: configSection?.cardName,
          isNavigationPage: true,
          menuId: this.menuId,
          isExistFiledDeathCertificateProperty,
          entityId: this.entityId,
          cardId: currentCard?.cardId ?? '',
        },
      });
    }
  }

  viewDetailBenefitEntity(config: any) {
    this.dialog.open(BenefitDetailViewComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        pageTitle: this.listBreadcrumbs[1]?.name || this.listBreadcrumbs[0]?.name ,
        entityId: config.entityId,
        viewName: config.viewName,
        viewId: config.viewId,
        memberId: this.memberId,
        isOverview: this.isOverview,
        recordId: config.recordId,
        screenId: this.screenId
      },
    });
  }

  downloadPdf(statement: any): void {
    const pdfUrl = this.signatoryService.getUrl(statement, (fileKey) => {
      this.fileService.signFile(fileKey).subscribe((sig) => {
        this.downloadPdf({
          ...statement,
          signature: sig,
        });
      });
    });

    if (pdfUrl) window.open(pdfUrl, '_blank');
  }

  lockAccount(isLock: boolean): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: isLock ? 'Are you sure you want to lock this account?' : 'Are you sure you want to unlock this account?',
        title: isLock ? 'Lock Participant' : 'Unlock Participant',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoadingLockMember = true;
        if (isLock) {
          this.memberStore.dispatch(
            MemberDetailActions.lockEntityMember({
              id: this.memberId
            })
          );
        } else {
          this.memberStore.dispatch(
            MemberDetailActions.unLockEntityMember({
              id: this.memberId
            })
          );
        }
      }
    });
  }

  removeMember(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'THIS IS A DESTRUCTIVE ACTION',
        type: ConfirmType.Destruct,
        text: `Removing this Participant will remove all traces of them, including all data and files, from the system.<br> Are you sure you want to proceed? This action is not reversible.`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          MemberDetailActions.removeMember({ id: this.memberId })
        );
      }
    });
  }

  onDeactivateMember(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'THIS IS A DESTRUCTIVE ACTION',
        type: ConfirmType.Destruct,
        text: `Are you sure you want to deactivate this member?`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          MemberDetailActions.deactivateMember({ id: this.memberId, actionCode: ActionCode.Deactive })
        );
      }
    });
  }

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  selectorDeactivateMember() {
    this.memberStore
      .pipe(select(deactivatedMemberSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.memberStore.dispatch(
          MemberDetailActions.clearDeactivateMember()
        );
        if (!el.isLoading) {
          if (el.success) {
            this.refeshProfileHeder();
            this.bannerType = BannerType.Success;
            this.message = 'Member is successfully deactivated.';
            this.reloadOverviewHeader();
            this.getDataSummary();
          } else {
            this.bannerType = BannerType.Fail;
            this.message = 'Error occurred deactivating Member. Please try again.';
          }
        }
      });
  }

  selectorUploadDocument() {
    this.store
      .pipe(select(selectUploadMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          if(state.state === 'loading') {
            this.isLoadingInfo = true;
            this.bannerType = BannerType.Info;
            this.message = 'Documents are being uploaded, this might take a few minutes for everything fully uploaded.'
          } else {
            this.isLoadingInfo = false;
            showBanner.call(this, state?.state, 'Document', ACTION.UPLOAD);
            this.store.dispatch(clearUploadDocumentsStateAction());
            this.refeshProfileHeder();
            this.reloadOverviewHeader();
          }
        }
      });
  }

  deleteSection(configSection: any): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove the ${configSection.sectionTitle}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sectionName = configSection.sectionTitle;
        this.memberStore.dispatch(
          MemberDetailActions.removeMemberSection({
            id: this.memberId,
            sectionKey: configSection.key,
          })
        );
      }
    });
  }

  private _getEntityComponentId(configCard: any, options: any) {
    return configCard.cardComponents.find(({ componentType }: { componentType: number }) => componentType === options.isAddRecord ? 1 : 0)?.componentId;
  }

  editCard(event: { configCard: EntityViewItem, isAddRecord?: boolean }) {
    if (event.isAddRecord) {
      this.addOrEditSectionRecord(event.configCard, {
        addToTop: true,
        isAddRecord: true,
        notShowFile: true,
        isList: true,
      });
    } else {
      this.addOrEditSectionRecord(event.configCard, {
        addToTop: false,
        isAddRecord: false,
        notShowFile: false,
      });
    }
  }

  manageSection(event: any) {
    const { configSection, cardDetailViewUrl } = event;
    if (configSection.type === SectionLayout.List) {
      this.openOverview(configSection.key);
    } else {
      let url = this.router.url;
      const entityReferenceLinkedId = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");

      if (url.includes(STRING_QUERY_PARAM.PROFILE_NAVIGATE)) {
        url = url.replace(STRING_QUERY_PARAM.PROFILE_NAVIGATE, 'profileNavigate=false');
        this.location.replaceState(url);
      }

      this.router.navigateByUrl(`/entity-management/entities/${configSection.entityId
        }/cards/${configSection.cardId
        }/list-data/${this.memberId
        }?attachmentType=${configSection.attachmentType
        }&isSummaryView=${true
        }&viewName=${this.viewName
        }&cardName=${configSection.cardName
        }&screenId=${encodeURIComponent(this.screenId)
        }&isBenefitCard=${configSection.isBenefitCard
        }&cardComponentType=${configSection.cardComponentType
        }&viewUrl=${encodeURIComponent(url)
        }&cardDetailViewUrl=${encodeURIComponent(cardDetailViewUrl)
        }${entityReferenceLinkedId ? `&entityReferenceLinkedId=${entityReferenceLinkedId}` : ''}`);
    }
  }

  addOrEditSectionRecord(configCard: EntityViewItem, options: any): void {
    this.openOrAddEditDialog(options, configCard);
  }

  openOrAddEditDialog(options: any, configCard: EntityViewItem) {
    const cardData = JSON.parse(JSON.stringify(configCard));
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      ariaLabel: 'Create New Entity',
      id: 'mat-dialog-container',
      autoFocus: false,
      data: {
        section: cardData,
        cardData: options.isAddRecord ? null : this.memberSummaryData[cardData.cardId]?.results,
        memberId: this.memberId,
        metadataItem: cardData.key,
        memberStatus: this.memberStatus,
        isSummaryView: true,
        entityComponentId: this._getEntityComponentId(cardData, options),
        screenId: encodeURIComponent(this.screenId),
        listRecordId: this.listRecordIdAll,
        searchExceptRecords: cardData?.searchExceptRecords,
        isEditStandAlonePropertiesFromSummaryView: !options?.isAddRecord,
        ...options,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this.getDataSummary();
        this.bannerType = BannerType.Success;
        showBanner.call(this, this.bannerType, configCard.cardName, options.isAddRecord ? ACTION.ADD : ACTION.EDIT);
      } else if (result === 'submitFail') {
        this.bannerType = BannerType.Fail;
        showBanner.call(this, this.bannerType, configCard.cardName, options.isAddRecord ? ACTION.ADD : ACTION.EDIT);
      }
    });
  }

  getMemberCard(cardId: string, isList: boolean = false) {
    this.memberStore.dispatch(
      MemberDetailActions.getMemberDataCardAction({
        memberId: this.memberId,
        cardId: isList ? `${cardId}_list` : cardId,
        entityId: this.entityId,
        isSummary: true,
        query: isList ? {
          totalPerPage: this.pageSize,
          pageIndex: 1
        } : null,
        screenId: this.screenId
      })
    );
  }

  onAddMemberStatusHistory(section: any) {
    this.sectionName = section?.sectionTitle!;
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: section?.sectionTitle,
        statusHistory: undefined,
        isRedirectFromOverview: true,
        sectionData: section,
      },
    });
  }

  openOverview(
    sectionKey: string,
    isShowBanner?: boolean,
    saveSectionDataSuccess?: boolean
  ) {
    this.router.navigateByUrl(`/member/detail/${sectionKey}/${this.memberId}`, {
      state: {
        isShowBanner,
        saveSectionDataSuccess,
      },
    });
  }

  ngOnDestroy() {
    this.memberStore.dispatch(MemberDetailActions.clearMemberDetail());
    this.memberStore.dispatch(MetadataActions.clearMetadata());
    this.updateLayoutSubscription$.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  backToMemberList() {
    if (this.remittanceQueryParams.remittanceId && this.remittanceQueryParams.employerId) {
      return;
    }

    let url = '/member';
    this.router.navigate([url], {
      state: { beforeState: this.memberNavigationState.query },
    });
  }

  onClickActionItem(cardId: string): void {
    let card = this.listCard.find(item => item.cardId === cardId);
    if (card) {
      if (card.cardComponents.find(item => item.propertyDisplay === null && item.cardProperties.length > 0)) {
        this.editCard({ configCard: card, isAddRecord: true });
      } else {
        this.editCard({ configCard: card, isAddRecord: false });
      }
    }
  }

  removeRowDoc(event: any){
    const mesError = 'Cannot remove Postcard Acknowledgement Letter.';
    showBanner.call(this, STATE.FAIL, '', ACTION.REMOVE, {
      customMessage: mesError,
    });
  }

  closeBanner() {
    this.message = '';
  }
}
