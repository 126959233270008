import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { PaymentInfoNoteType } from '@ptg-shared/constance/value.const';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';


import * as fromPayeeDetail from '../../store/reducers/';
import { addNoteAction, updateNoteAction } from '../../store';
import { NoteResponse } from '../../types/models';
import { PayStatus } from '../../types/enums';

@Component({
  selector: 'ptg-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent implements OnInit {
  readonly PaymentInfoNoteTypeEnum = PaymentInfoNoteType;
  readonly PAY_STATUS = PayStatus;
  formGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddNoteComponent>,
    private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      paymentInstructionId: string;
      noteType: number;
      payStatus: number;
      memoDetails?: NoteResponse;
      paymentInstructionHistoryId: string;
    }
  ) {
    this.formGroup = new FormGroup({
      note: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.data?.memoDetails) {
      this.formGroup.setValue({ note: this.data.memoDetails.note });
    }
  }

  onSubmit() {
    if (this.formGroup.valid) {
      let data = this.formGroup.getRawValue();
      data.type = this.data.noteType;
      data.payStatus = this.data.payStatus;
      data.paymentInstructionId = this.data.paymentInstructionId;
      if (this.data?.memoDetails) {
        const bodyFinalizedUpdate = {
          note: data.note,
          type: this.data.noteType,
          payStatus: this.data.payStatus,
          id: this.data.memoDetails.id
        };
        data.id = this.data.memoDetails.id;
        this.payeeDetailStore.dispatch(updateNoteAction({ body: this.data.payStatus === this.PAY_STATUS.Finalized ? bodyFinalizedUpdate : data }));
      } else {
        const bodyFinalizedCreate = {
          note: data.note,
          type: this.data.noteType,
          payStatus: this.data.payStatus,
          paymentInstructionHistoryId: this.data.paymentInstructionHistoryId,
          id: null
        }
        this.payeeDetailStore.dispatch(
          addNoteAction({body: this.data.payStatus === this.PAY_STATUS.Finalized ? bodyFinalizedCreate : data})
        );
      }
      this.dialogRef.close(true);
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
