import { RelationshipOptions } from "@ptg-entity-management/types/enums/entity.enum";

export enum ENTITY_MAPPING {
  Benefit = '7254DD97-906B-45A4-91EC-41792072D303',
  AnnuityBenefit = '441D37B2-BD28-442C-9426-44E388ED8317',
  LumpSumBenefit = '18230F8E-F050-4F44-BAA4-864BAE76F2EC',
  Person = 'CAA221EA-BFEA-4A77-A08C-232EA7839B98',
  Participant = '9D19B986-AF2E-4B0D-88DE-E281971DC3F9',
  Member = 'D478C688-445A-44BE-AF5F-B090FCBC8048',
  Employer = 'BEBB9F9E-2DAC-4072-A341-A32B907DDCA1',
  Organization = '5BA9F232-31C4-4C76-89E0-A84C8BBA293E',
  Trust = 'BE9D6E30-0A73-4FD0-9EAE-39365084779D',
  Estate = 'BB43A572-50C4-4AE2-89F8-FC2D6CDDB3A3',
};

export const NOTES_CARD = {
  Id: '64937b06-4859-43c3-89d6-3f001c8c01d1',
};

export const STATUS_COLUMNKEY = {
  StatusName: 'd478c688-445a-44be-af5f-b090fcbc8048_762ac020-8961-44da-a811-6a73a9d243df_eb8cfe7c-e348-4e60-93cb-f265057f5eb8_StatusName_0',
  StatusEvent: 'd478c688-445a-44be-af5f-b090fcbc8048_762ac020-8961-44da-a811-6a73a9d243df_eb8cfe7c-e348-4e60-93cb-f265057f5eb8_StatusEvent_0'
}

export const MEMBER_STATUS_EVENT = {
  StatusEventId: 'eb8cfe7c-e348-4e60-93cb-f265057f5eb8',
  DeceasedStatus: '5b123231-778d-4585-8458-3a65f3bf6a91',
}

export const JOB_HISTORY = {
  EntityComponentId: 'E0B6A5ED-105D-4491-BAF8-B896AE10CE86',
  //using samename with auto created field @BE
  Employer_Employer_Id: '274F7A8C-53E9-450F-B165-300B45209A8B',
  BeginDate: 'B1A9DA47-C2D1-4EC6-9011-4B1F18DF94E6',
  EndDate: '6FBBAF61-3CCC-4B3D-A6C2-6D6CAFD89864',
}
export const NOTES_PROPERTY = {
  Id: 'e872b088-681a-462a-88af-bddf12199336',
  CreatedIn: 'e872b088-681a-462a-88af-171220000002',
  RecordId: 'e872b088-681a-462a-88af-171220000003',
}

export const SHOULD_NOT_SHOW_NOTE_PROPERTIES_WHEN_EDIT_LIST_CARD_STAND_ALONE_PROPERTIES = [
  NOTES_PROPERTY.Id,
  NOTES_PROPERTY.CreatedIn,
  NOTES_PROPERTY.RecordId,
];

export const SHOULD_NOT_SHOW_NOTE_PROPERTIES_IN_UPSERT_ENTITY_FLOW = [
  NOTES_PROPERTY.CreatedIn,
  NOTES_PROPERTY.RecordId,
];

export enum DEPENDENT_TYPE_LOOKUP_FIXED_ID {
  TableId = 'BAAB22A1-51FF-428C-AC75-D617B6E106D0',
  FullTimeStudentOver18 = 'C77F3910-20E8-4454-A15B-97C16375AA69',
  Under18 = '692E7835-C39B-49D5-8A1F-F352677FB6CD',
  PermanentHandicap = '659A8EBB-A37C-4D16-98E3-6A305860C2D4',
  NotEligible = '633B6593-33AF-491E-A390-534CABD096E3',
  Unknown = '53C8CD7F-070E-4D35-8303-C1EE8B9D94D2',
}

export enum LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID {
  TableId = 'E9563D14-2AF4-4159-884E-B538E112C5A6',
  Beneficiary = '24123709-54DE-47BB-957D-7BE8EE8208B4',
  Dependent = 'DF11A03A-07B1-4D43-B68B-75D5BDF03653',
  PowerOfAttorney = '5653EB5B-D46A-4A06-9546-7DAF4C272EB4',
}

export enum RELATIONSHIP_LIST {
  // Mapping with `entityComponentId` of Relationship List Card
  Id = '48DD5ABF-980F-422F-AD24-79B9467FFD94',
}

export enum RELATIONSHIP_LIST_PROPERTY_FIXED_ID {
  // Main section
  Relationship = 'BED1E6F9-1CA2-4649-8B20-61FDD4AE97D1',
  RelatedEntity = 'F8AF7672-3530-4EA7-8EEB-AFEFA884D492',
  LegalRelationshipType = '762DBA78-21B1-4E48-A17E-CF739487646B',

  // Dependent
  Guardian = 'FC40165F-78AB-4291-A256-8FDC721400D6',
  StudentCertificationDate = '4B3A0ACD-11EB-4236-820F-3EC29D7684D2',
  PaymentTerminationDate = '36771A9D-7DEE-4E2D-B4D5-B89335D44DCA',
  TerminationReason = '7DEECF37-9DA0-4394-97D4-B04D5E0624DD',

  // Beneficiary
  Percentage = 'DD7842E5-DB4C-4EB4-A9F5-DC8186BC3F0B',
  IsPrimary = '742BB25E-73C4-4037-B6AB-3F5DDCB54DC3',
  
  // Power of Attourney
  EffectiveDate = '8505DBB8-B9C7-48EF-8E06-F6515D835526',
  TypeOfPower = 'D0D652C0-AFE0-4D60-B780-4CD6DCDF708A',

  // Marital Information
  MarriageDate = 'BA154C63-971B-4814-9FFF-1B10F49C618E',
  DivorceDate = '777176BD-167D-449F-9F06-10C9E8E13959',
}

export const RELATIONSHIP_DEPENDENT_PROPERTIES = [
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Guardian,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.StudentCertificationDate,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.PaymentTerminationDate,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.TerminationReason,
];

export const RELATIONSHIP_BENEFICIARY_PROPERTIES = [
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Percentage,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.IsPrimary,
];

export const RELATIONSHIP_POWER_OF_ATTOURNEY_PROPERTIES = [
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.EffectiveDate,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.TypeOfPower,
];

export const RELATIONSHIP_MARITAL_INFORMATION_PROPERTIES = [
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.MarriageDate,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID.DivorceDate,
];

export const LEGAL_RELATIONSHIP_PROPERTIES = [
  {
    LookupTableOptionId: LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary,
    Properties: RELATIONSHIP_BENEFICIARY_PROPERTIES,
  },
  {
    LookupTableOptionId: LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.PowerOfAttorney,
    Properties: RELATIONSHIP_POWER_OF_ATTOURNEY_PROPERTIES,
  },
  {
    LookupTableOptionId: LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Dependent,
    Properties: RELATIONSHIP_DEPENDENT_PROPERTIES,
  }
];

export const ALLOW_SHOW_MARRIAGE_DATE_WITH_RELATIONSHIP_VALUES = [
  RelationshipOptions.Spouse,
  RelationshipOptions.ExSpouse,
];

export const ALLOW_SHOW_DIVORCE_DATE_WITH_RELATIONSHIP_VALUES = [
  RelationshipOptions.ExSpouse,
];

export enum SERVICE_HISTORY_LIST {
  // Mapping with `entityComponentId` of Service History List Card
  Id = 'FBCBCE74-FC29-44EF-A1BA-F4CBAC04C2A5',
}

export enum SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID {
  Employer = 'A76063DB-11AD-44AB-8312-30003E3ACE4D',
  ServiceType = '560D39A1-A410-466C-8F11-7D98F3117AA4',
  StartDate = '5C6ECE6D-68FD-4D1D-9DB4-CAC19519D06D',
  EndDate = 'E0CC5B13-ACBD-4F69-8925-F779528F3535',
  Service = 'E7BADF90-18DE-4A3D-BC12-C926A95CA175',
  Plan = '83CCA27C-027F-463B-A98D-84DB90F24A32',
  Rate = '14AC6C16-1593-4EFE-BB21-8B996722D6BF',
  PurchaseAmount = '34E2D43B-005C-4ADC-8FD0-6AE8F5E5A491',
  TimePurchased = '16458C86-E6DA-4FE6-81F8-4A0125DC58EA',
  PurchaseMethod = '6B1E76BA-5E66-4394-A7FC-BE536FBD8761',
  ContinuousService = '0A261CCD-3A49-43C3-BE2D-D003594E5AC2',
  BenefitCalculation = '23B426B6-CCA7-408D-8D9E-FDE6926678E4',
}
