import { createSelector } from '@ngrx/store';

import { payrollSelector } from './selector';

export const selectPaymentInformationState = createSelector(
  payrollSelector,
  (state) => state?.paymentInfo
);

export const getPayrollRunSelector = createSelector(
  selectPaymentInformationState,
  (state) => state?.getPayrollRun
);
