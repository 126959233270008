import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import { EntityPropertyType } from '../../types/enums';
import {
  CalculationComponent,
  CalculationDataType,
  EntityCalculationConfig,
  EntityPropertyDetail,
  EntityPropertyForCalculation,
  GetEntityPropertiesForCalculationRequest,
  GetEntityPropertyDetailRequest,
  Operator,
  PropertyConfigOption,
  SetEntityPropertyRequest,
} from '../../services/models';
import {
  getEntityPropertiesForCalculationSelector,
  getEntityPropertyDetailSelector,
} from '../../store/selectors';
import { EntityService } from '../../services';
import { EntityState } from '../../store/reducers';
import {
  getEntityPropertiesForCalculation,
  getEntityPropertyDetailAction,
  setEntityPropertyAction,
} from '../../store/actions';

@Component({
  selector: 'ptg-edit-calculation-property-item',
  templateUrl: './edit-calculation-property-item.component.html',
  styleUrls: ['./edit-calculation-property-item.component.scss'],
})
export class EditCalculationPropertyItemComponent extends BaseComponent {
  formGroup?: FormGroup;
  readonly CalculationDataType = CalculationDataType;
  readonly PropertyType = EntityPropertyType;
  readonly Operator = Operator;
  get calculationNameCtrl() {
    return this.formGroup?.get('calculationName') as FormControl;
  }
  get descriptionCtrl() {
    return this.formGroup?.get('description') as FormControl;
  }
  get displayFormatCtrl() {
    return this.formGroup?.get('displayFormat') as FormControl;
  }

  get fractionalLengthCtrl() {
    return this.formGroup?.get('fractionalLength') as FormControl;
  }

  get operatorCtrl() {
    return this.formGroup?.get('operator') as FormControl;
  }

  get dataTypeCtrl() {
    return this.formGroup?.get('dataType') as FormControl;
  }

  get propertyNameCtrl() {
    return this.formGroup?.get('propertyName') as FormControl;
  }

  get numberValueCtrl() {
    return this.formGroup?.get('numberValue') as FormControl;
  }

  displayFormatOptions: Option[] = [
    {
      displayValue: 'Elapsed Time',
      value: EntityPropertyType['Elapsed Time'],
    },
    {
      displayValue: 'Decimal',
      value: EntityPropertyType.Decimal,
    },
    {
      displayValue: 'Currency',
      value: EntityPropertyType.Currency,
    },
    {
      displayValue: 'Percentage',
      value: EntityPropertyType.Percentage,
    },
    {
      displayValue: 'Whole Number',
      value: EntityPropertyType['Whole Number'],
    },
  ];

  operatorOptions: Option[] = [
    {
      displayValue: '+',
      value: Operator.Plus,
    },
    {
      displayValue: '-',
      value: Operator.Minus,
    },
    {
      displayValue: '*',
      value: Operator.Multiply,
    },
    {
      displayValue: '/',
      value: Operator.Divide,
    },
  ];

  dataTypeOptions: Option[] = [
    {
      displayValue: 'Property',
      value: CalculationDataType.Property,
    },
    {
      displayValue: 'Fixed Number',
      value: CalculationDataType.Number,
    },
  ];

  isEdit: boolean = false;
  isShowFractionLength: boolean = false;
  formulaOverview: string = '';
  propertyNameOptions?: EntityPropertyForCalculation[] = [];
  listParameter = new FormArray([]);
  formSubmit$ = new Subject<boolean>();
  availablePropertyConfigs!: Option[] | any[];
  calculationDetailData?: EntityPropertyDetail;

  constructor(
    public fb: FormBuilder,
    public entityStore: Store<EntityState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditCalculationPropertyItemComponent>,
    public router: Router,
    public entityService: EntityService,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityId: string;
      entityComponentId: string;
      entityPropertyId: string;
    }
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.emitFormSubmit();
    this.createForm();

    this.getEntityPropertyDetailAction();
    this.getEntityPropertiesForCalculationAction();
    this.getDataSelector();
  }

  getEntityPropertyDetailAction(): void {
    let request: GetEntityPropertyDetailRequest = {
      entityPropertyId: this.data?.entityPropertyId,
    };

    this.entityStore.dispatch(getEntityPropertyDetailAction({ request }));
  }

  getEntityPropertiesForCalculationAction(): void {
    let request: GetEntityPropertiesForCalculationRequest = {
      entityId: this.data?.entityId,
    };

    this.entityStore.dispatch(getEntityPropertiesForCalculation({ request }));
  }

  getDataSelector(): void {
    combineLatest([
      this.entityStore.select(getEntityPropertyDetailSelector),
      this.entityStore.select(getEntityPropertiesForCalculationSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data[0]?.isLoading && !data[1]?.isLoading) {
          this.calculationDetailData = data[0]?.payload;
          this.propertyNameOptions = data[1]?.payload || [];

          this.getProperties();
          this.initForm();
        }
      });
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      calculationName: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: checkApiValidator(
          this.entityService.checkPropertyNameExist,
          'name',
          undefined,
          {
            params: {
              entityId: this.data?.entityId,
              entityComponentId: this.data?.entityComponentId,
              entityPropertyId: this.data?.entityPropertyId,
            },
          }
        ),
      }),
      description: new FormControl(''),
      displayFormat: new FormControl('', {
        validators: [Validators.required],
      }),
      fractionalLength: new FormControl({ value: null }),
      dataType: new FormControl(CalculationDataType.Property, {
        validators: [Validators.required],
      }),
      propertyName: new FormControl('', { validators: [Validators.required] }),
      numberValue: new FormControl(''),
    });
  }

  private initForm(): void {
    if (this.calculationDetailData) {
      // first row
      const configs =
        (this.calculationDetailData.configs[
          PropertyConfigOption.Calcucation
        ] as EntityCalculationConfig) || {};
      const firstRow = configs.calculationComponents?.length
        ? configs.calculationComponents[0]
        : null;

      this.isEdit = !!firstRow;
      const propertyName =
        firstRow?.dataType === CalculationDataType.Property
          ? firstRow?.property?.entityPropertyId
          : firstRow?.value;

      this.formGroup?.setValue({
        calculationName: this.calculationDetailData?.propertyName,
        description: configs.description ?? '',
        displayFormat: configs.displayFormat || '',
        fractionalLength: configs.fractionalLength || '',
        dataType: firstRow?.dataType || CalculationDataType.Property,
        propertyName: propertyName || '',
        numberValue: propertyName || '',
      });

      const displayFormat = this.displayFormatCtrl?.value;
      if (displayFormat === EntityPropertyType['Elapsed Time']) {
        // TODO: Other story will cover Elapsed Time.
      }

      if (configs.calculationComponents?.length! > 1) {
        configs.calculationComponents?.forEach((item) => {
          if (item.order > 0) {
            const propertyName =
              item.dataType === CalculationDataType.Property
                ? item.property?.entityPropertyId
                : item.value;

            this.listParameter.push(
              new FormGroup({
                operator: new FormControl(item.operator),
                dataType: new FormControl(item.dataType),
                propertyName: new FormControl(propertyName),
                numberValue: new FormControl(propertyName),
              })
            );
          }
        });
      }

      this.onChangeFormulaOverview();
    }
  }

  onChangeDisplayFormat(): void {
    const displayFormat = this.displayFormatCtrl?.value;
    if (displayFormat === EntityPropertyType['Elapsed Time']) {
      // TODO: Other story will cover Elapsed Time.
    }
    if (![EntityPropertyType['Decimal'], EntityPropertyType['Percentage']].includes(displayFormat)) {
      this.fractionalLengthCtrl.setValidators(null);
    }
    this.fractionalLengthCtrl.setValue('');
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  onClickRemoveRow(index: number): void {
    this.listParameter.removeAt(index);
    this.onChangeFormulaOverview();
    this.listParameter.updateValueAndValidity();
  }

  onClickAddNew() {
    this.listParameter.push(
      new FormGroup({
        operator: new FormControl(null, {
          validators: [Validators.required],
        }),
        dataType: new FormControl(CalculationDataType.Property),
        propertyName: new FormControl('', {
          validators: [Validators.required],
        }),
        numberValue: new FormControl(''),
      })
    );
  }

  emitFormSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formGroup?.markAllAsTouched();
          this.listParameter?.markAllAsTouched();
        })
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    if (this.formGroup?.pending) {
      let sub = this.formGroup.statusChanges.subscribe(() => {
        if (this.formGroup?.valid) {
          this.saveCalculation();
        }
        sub.unsubscribe();
      });
    } else if (this.formGroup?.valid) {
      this.saveCalculation();
    }
  }

  saveCalculation() {
    if (!this.formGroup?.valid || !this.listParameter.valid) {
      return;
    }
    const property = this.propertyNameOptions?.find(
      (ele) => ele.entityPropertyId === this.formGroup?.value.propertyName
    );

    const firstRow: CalculationComponent = {
      dataType: this.formGroup.value.dataType,
      value:
        this.formGroup.value.dataType === CalculationDataType.Property
          ? undefined
          : this.formGroup.value.numberValue,
      order: 0,
      property:
        this.formGroup.value.dataType === CalculationDataType.Property
          ? {
              propertyType: property!.propertyType,
              entityPropertyId: property!.entityPropertyId,
            }
          : undefined,
    };
    const listParameterValue = this.getCalculationComponent();

    const request: SetEntityPropertyRequest = {
      entityComponentId: this.data?.entityComponentId,
      entityPropertyId: this.data?.entityPropertyId,
      name: this.formGroup.value.calculationName.trim(),
      configs: {
        [PropertyConfigOption.Calcucation]: {
          displayFormat: this.formGroup.value.displayFormat,
          fractionalLength: this.formGroup.value.fractionalLength,
          calculationComponents: [firstRow, ...listParameterValue],
          description: this.descriptionCtrl?.value
        },
      },
    };

    this.entityStore.dispatch(setEntityPropertyAction({ request }));
    this.dialogRef.close({ propertyName: this.calculationNameCtrl?.value });
  }

  private getCalculationComponent(): CalculationComponent[] {
    let order = 0;
    let calculationComponent: CalculationComponent[] =
      this.listParameter?.value?.map((item: any) => {
        const currentValue = this.propertyNameOptions?.find(
          (ele) => ele.entityPropertyId === item.propertyName
        );

        return {
          operator: item.operator,
          dataType: item?.dataType,
          value:
            item?.dataType === CalculationDataType.Property
              ? null
              : item.numberValue,
          order: ++order,
          property:
            item?.dataType === CalculationDataType.Property
              ? {
                  entityPropertyId: currentValue?.entityPropertyId,
                  propertyType: currentValue?.propertyType,
                }
              : null,
        } as CalculationComponent;
      }) as CalculationComponent[];

    return calculationComponent;
  }

  addNewButtonIsValid(): boolean {
    return !this.listParameter.getRawValue().some((param) => {
      if (Number(param.dataType) === CalculationDataType.Property) {
        return param?.operator === null || !param?.propertyName;
      } else {
        return (
          param?.operator === null ||
          !param?.numberValue ||
          param?.numberValue === '0'
        );
      }
    });
  }

  private getProperties() {
    this.availablePropertyConfigs = this.propertyNameOptions
      ?.filter((item) => item.entityPropertyId !== this.data.entityPropertyId)
      .reduce(
        (
          result: Option[] | any,
          propertyConfig: EntityPropertyForCalculation
        ) => {
          result.push({
            value: propertyConfig.entityPropertyId,
            displayValue: propertyConfig.entityPropertyName,
            valueDescription: propertyConfig.subTitle,
          });
          return result;
        },
        [] as Option[]
      );
  }

  onChangeFormulaOverview(index?: number) {
    this.formulaOverview = '';
    this.formulaOverview =
      this.calculationNameCtrl.value !== ''
        ? `${this.calculationNameCtrl.value} = `
        : '';
    if (this.dataTypeCtrl.value === CalculationDataType.Property) {
      const propertyValue = this.availablePropertyConfigs?.find(
        (x) => x.value === this.propertyNameCtrl.value
      );
      this.formulaOverview =
        this.formulaOverview +
        ` ` +
        (propertyValue ? propertyValue.displayValue : '');
      this.formulaOverview = this.getFormulaOverviewFromListParameter(
        this.formulaOverview
      );
    } else {
      this.formulaOverview =
        this.formulaOverview + ` ` + this.numberValueCtrl.value;
      this.formulaOverview = this.getFormulaOverviewFromListParameter(
        this.formulaOverview
      );
    }

    if (
      this.listParameter?.value[index || 0]?.operator === Operator.Divide &&
      this.listParameter?.value[index || 0]?.numberValue === '0' &&
      this.listParameter?.value[index || 0]?.dataType ===
        CalculationDataType.Number
    ) {
      this.listParameter?.controls[index || 0]
        ?.get('numberValue')
        ?.setErrors({ inValidAsync: true });
    }
  }

  private getFormulaOverviewFromListParameter(formulaValue: string): string {
    const rawValue = this.listParameter.getRawValue();
    if (rawValue.length > 0) {
      rawValue?.forEach((element: any) => {
        if (element?.dataType === CalculationDataType.Property) {
          const propertyValue = this.availablePropertyConfigs?.find(
            (x) => x.value === element?.propertyName
          );
          const operatorValue = this.operatorOptions.find(
            (x) => x.value === element?.operator
          );
          formulaValue =
            formulaValue +
            ` ` +
            `${operatorValue ? operatorValue.displayValue : ''} ${
              propertyValue ? propertyValue.displayValue : ''
            }`;
        } else if (element?.dataType === CalculationDataType.Number) {
          const operatorValue = this.operatorOptions.find(
            (x) => x.value === element.operator
          );
          formulaValue =
            formulaValue +
            ` ` +
            `${operatorValue ? operatorValue.displayValue : ''} ${
              element.numberValue
            }`;
        }
      });
    }

    return formulaValue;
  }

  onChangeDataType() {
    if (this.dataTypeCtrl.value === CalculationDataType.Property) {
      this.numberValueCtrl?.removeValidators(Validators.required);
      this.propertyNameCtrl?.addValidators(Validators.required);
      this.propertyNameCtrl?.addValidators(Validators.required);
      this.numberValueCtrl?.reset('');
    } else {
      this.propertyNameCtrl?.removeValidators(Validators.required);
      this.numberValueCtrl?.addValidators(Validators.required);
      this.propertyNameCtrl?.reset('');
    }
    this.numberValueCtrl?.updateValueAndValidity({ emitEvent: false });
    this.propertyNameCtrl?.updateValueAndValidity({ emitEvent: false });
  }

  onChangeDataTypeOfListParameter(index: number) {
    const currentFormControl = this.listParameter.at(index);
    if (
      currentFormControl?.get('dataType')?.value ===
      CalculationDataType.Property
    ) {
      currentFormControl
        ?.get('numberValue')
        ?.removeValidators(Validators.required);
      currentFormControl
        ?.get('propertyName')
        ?.addValidators(Validators.required);
      currentFormControl?.get('numberValue')?.reset('');
    } else {
      currentFormControl
        ?.get('propertyName')
        ?.removeValidators(Validators.required);
      currentFormControl
        ?.get('numberValue')
        ?.addValidators(Validators.required);
      currentFormControl?.get('propertyName')?.reset('');
    }
    currentFormControl
      ?.get('numberValue')
      ?.updateValueAndValidity({ emitEvent: false });
    currentFormControl
      ?.get('propertyName')
      ?.updateValueAndValidity({ emitEvent: false });
  }

  onKeydownFractional = ({ key, value }: { key: number, value: any }) => {
    if( (key == 8 || key == 46) && `${value}`.length === 1 ) {
      this.fractionalLengthCtrl.setValue('');
    }
  }
}
