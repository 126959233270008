import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import * as CreateGeneralAdjustment from '../actions/create-general-adjustment.actions';
import {
  GetDeductionTypeResponse,
  CreateGeneralAdjustmentResponse,
  ValidateGeneralAdjustmentResponse,
} from '../../services/models/create-general-adjustment.model';

export interface CreateGeneralAdjustmentState {
  getDeductionType?: BaseActionState<GetDeductionTypeResponse>;
  validateGeneralAdjustment?: BaseActionState<ValidateGeneralAdjustmentResponse>;
  addGeneralAdjustment?: BaseActionState<CreateGeneralAdjustmentResponse>;
}

const initialState: CreateGeneralAdjustmentState = {};

export const createGeneralAdjustmentReducer = createReducer(
  initialState,

  on(CreateGeneralAdjustment.getValidateGeneralAdjustmentAction, (state) => ({
    ...state,
    validateGeneralAdjustment: {
      isLoading: true,
    },
  })),
  on(CreateGeneralAdjustment.getValidateGeneralAdjustmentSuccess, (state, { response }) => ({
    ...state,
    validateGeneralAdjustment: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(CreateGeneralAdjustment.getValidateGeneralAdjustmentFailure, (state, { error }) => ({
    ...state,
    validateGeneralAdjustment: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(CreateGeneralAdjustment.clearValidateGeneralAdjustmentState, (state) => ({
    ...state,
    validateGeneralAdjustment: undefined,
  })),

  on(CreateGeneralAdjustment.getGetDeductionTypesAction, (state) => ({
    ...state,
    getDeductionType: {
      isLoading: true,
    },
  })),
  on(CreateGeneralAdjustment.getGetDeductionTypesSuccess, (state, { response }) => ({
    ...state,
    getDeductionType: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(CreateGeneralAdjustment.getGetDeductionTypesFailure, (state, { error }) => ({
    ...state,
    getDeductionType: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(CreateGeneralAdjustment.clearGetDeductionTypesState, (state) => ({
    ...state,
    getDeductionType: undefined,
  })),

  on(CreateGeneralAdjustment.getAddGeneralAdjustmentAction, (state) => ({
    ...state,
    addGeneralAdjustment: {
      isLoading: true,
    },
  })),
  on(CreateGeneralAdjustment.getAddGeneralAdjustmentSuccess, (state, { response }) => ({
    ...state,
    addGeneralAdjustment: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(CreateGeneralAdjustment.getAddGeneralAdjustmentFailure, (state, { error }) => ({
    ...state,
    addGeneralAdjustment: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(CreateGeneralAdjustment.clearAddGeneralAdjustmentState, (state) => ({
    ...state,
    addGeneralAdjustment: undefined,
  })),
);
