import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortType } from '@ptg-shared/constance';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetPayrollSettingsRequest, GetPayrollSettingsResponse } from './models';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

@Injectable()
export class PayrollSettingsService {
  constructor(private httpClient: HttpClient) {}

  getPayrollSettings(request: GetPayrollSettingsRequest): Observable<GetPayrollSettingsResponse> {
    let params = new HttpParams();
    if (request?.paymentInstructionHistoryId) {
      params = params.append('paymentInstructionHistoryId', request.paymentInstructionHistoryId);
    }
    if (request?.instructionStatusHistoryId) {
      params = params.append('instructionStatusHistoryId', request.instructionStatusHistoryId);
    }
    return this.httpClient.get<GetPayrollSettingsResponse>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${request.paymentInstructionId}/payroll-settings`,
      { params },
    );
  }
}
