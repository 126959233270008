import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as StatusActions from '../../store/actions/status.actions';
import { StatusService } from '../../services/status.service';
import { MemberStatusList } from '../../types/models';

@Injectable()
export class StatusEffects {
  getMemberStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.getStatus),
      switchMap(({ query }) => {
        return this.statusService.getMemberStatus(query).pipe(
          map((res: MemberStatusList) => {
            let statusList = res.memberStatuses.map((item) => {
              return item;
            });
            return StatusActions.getStatusSuccess({ statusList });
          }),
          catchError((error) => {
            if (error instanceof Error) {
              error = { message: `${error.stack} - ${error.message}` };
            }
            return of(StatusActions.getStatusFailure({ error }));
          })
        );
      })
    )
  );

  createStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.createStatus),
      switchMap(({ statusDetail }) => {
        return this.statusService.createMemberStatus(statusDetail).pipe(
          map(() => {
            return StatusActions.createStatusSuccess();
          }),
          catchError((err) => {
            return of(
              StatusActions.createStatusFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  editStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.editStatus),
      switchMap(({ statusDetail }) => {
        return this.statusService.editMemberStatus(statusDetail).pipe(
          map(() => {
            return StatusActions.editStatusSuccess();
          }),
          catchError((err) => {
            return of(
              StatusActions.editStatusFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.removeStatus),
      switchMap(({ statusId }) => {
        return this.statusService.removeMemberStatus(statusId).pipe(
          map(() => {
            return StatusActions.removeStatusSuccess();
          }),
          catchError((err) => {
            return of(
              StatusActions.removeStatusFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  getStatusDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.getStatusDetail),
      switchMap(({ query }) => {
        return this.statusService.getStatusDetail(query).pipe(
          map((statusDetail) => {
            return StatusActions.getStatusDetailSuccess({ statusDetail });
          }),
          catchError((error) => {
            return of(StatusActions.getStatusDetailFailure({ error }));
          })
        );
      })
    )
  );

  createEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.createEvent),
      switchMap(({ eventDetail }) => {
        return this.statusService.createEvent(eventDetail).pipe(
          map(() => {
            return StatusActions.createEventSuccess();
          }),
          catchError((err) => {
            return of(
              StatusActions.createEventFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  editEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.editEvent),
      switchMap(({ eventDetail }) => {
        return this.statusService.editEvent(eventDetail).pipe(
          map(() => {
            return StatusActions.editEventSuccess();
          }),
          catchError((err) => {
            return of(
              StatusActions.editEventFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );
  removeEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusActions.removeEvent),
      switchMap(({ eventId }) => {
        return this.statusService.removeMemberEvent(eventId).pipe(
          map(() => {
            return StatusActions.removeEventSuccess();
          }),
          catchError((err) => {
            return of(
              StatusActions.removeEventFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private statusService: StatusService
  ) {}
}
