import { createAction, props } from '@ngrx/store';
import {
  GetHeaderConfigRequest,
  GetHeaderConfigResponse,
  GetPayeePaymentRequest,
  GetPayeePaymentResponse,
  SetPayeePaymentRequest,
} from '../../types/models';

export const getPayeePaymentAction = createAction(
  '[GetPayeePayment/API] Send Request',
  props<{ request: GetPayeePaymentRequest }>(),
);

export const getPayeePaymentSuccessAction = createAction(
  '[GetPayeePayment/API] Success',
  props<{ response: GetPayeePaymentResponse }>(),
);

export const getPayeePaymentFailureAction = createAction('[GetPayeePayment/API] Failure', props<{ error?: any }>());

export const clearGetPayeePaymentStateAction = createAction('[GetPayeePayment] Clear');

export const setPayeePaymentAction = createAction(
  '[SetPayeePayment/API] Send Request',
  props<{ request: SetPayeePaymentRequest }>(),
);

export const setPayeePaymentSuccessAction = createAction('[SetPayeePayment/API] Success');

export const setPayeePaymentFailureAction = createAction('[SetPayeePayment/API] Failure', props<{ error?: any }>());

export const clearSetPayeePaymentStateAction = createAction('[SetPayeePayment] Clear');
export const getHeaderConfigAction = createAction(
  '[GetHeaderConfig/API] Send Request',
  props<{ request: GetHeaderConfigRequest }>(),
);

export const getHeaderConfigSuccessAction = createAction(
  '[GetHeaderConfig/API] Success',
  props<{ response: GetHeaderConfigResponse }>(),
);

export const getHeaderConfigFailureAction = createAction('[GetHeaderConfig/API] Failure', props<{ error?: any }>());

export const clearGetHeaderConfigStateAction = createAction('[GetHeaderConfig] Clear');
