<div class="add-calculation" id="all-calculation-id">
  <div class="header-title">Edit Property</div>
  <form class="edit-form" *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="form-control-container">
      <ptg-textbox class="calculation-name"
        [controlField]="calculationNameCtrl"
        [hasLabel]="true"
        [maxLength]="100"
        placeholder="Calculation Name"
        errorAsync="Calculation Name already exists."
        (valueChange)="onChangeFormulaOverview()"
        >
      </ptg-textbox>
      <ptg-textbox class="calculation-name"
        [controlField]="descriptionCtrl"
        [hasLabel]="true"
        [maxLength]="250"
        placeholder="Description"
      ></ptg-textbox>
      <div class="list-display-format">
        <ptg-select
          [controlField]="displayFormatCtrl"
          placeholder="Display Format"
          [listData]="displayFormatOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
          errorRequire="Display Format is required."
          class="max-width"
          [isDisabled]="isEdit"
          (changeOptionValue)="onChangeDisplayFormat()">
        </ptg-select>
        <ptg-textbox
          *ngIf="this.displayFormatCtrl?.value === PropertyType.Decimal || this.displayFormatCtrl?.value === PropertyType.Percentage"
          class="text-box"
          [controlField]="fractionalLengthCtrl"
          placeholder="Fractional Length"
          [hasLabel]="true"
          inputType="Number"
          [min]="1"
          [max]="10"
          [isDecimal]="false"
          [isDisabled]="isEdit"
          (onKeydown)="onKeydownFractional($event)"
          [isRequired]="true"
        >
        </ptg-textbox>
      </div>
      <span class="display-label">{{ formulaOverview }}</span>
      <div class="divider"></div>
      <div class="data-type">
        <ptg-select
          [controlField]="dataTypeCtrl"
          placeholder="Data Type"
          [listData]="dataTypeOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
          [isDisabled]="isEdit"
          (changeOptionValue)="onChangeDataType()"
          class="max-width operator-field">
        </ptg-select>
        <ptg-select
          *ngIf="this.formGroup?.get('dataType')?.value === CalculationDataType.Property"
          [controlField]="propertyNameCtrl"
          placeholder="Property Name"
          [listData]="availablePropertyConfigs"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [isRequired]="true"
          [isDisabled]="isEdit"
          (changeOptionValue)="onChangeFormulaOverview()"
          class="max-width property-name-field">
        </ptg-select>
        <ptg-textbox
          *ngIf="this.formGroup?.get('dataType')?.value === CalculationDataType.Number"
          class="text-box property-name-field"
          [controlField]="numberValueCtrl"
          placeholder="Number Value"
          [hasLabel]="true"
          inputType="Decimal"
          [mask]="'separator.10'"
          [isDisabled]="isEdit"
          (input)="onChangeFormulaOverview()">
      </ptg-textbox>
      </div>
      <div class="list-parameter">
        <div class="row-param" *ngFor="let rowControls of listParameter?.controls; index as i">
          <ptg-select
            [controlField]="rowControls.get('operator')"
            placeholder="Operator"
            [listData]="operatorOptions"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [isRequired]="true"
            [isDisabled]="isEdit"
            errorRequire="Operator is required."
            (changeOptionValue)="onChangeFormulaOverview(i)"
          ></ptg-select>
          <ptg-select
            [controlField]="rowControls.get('dataType')"
            placeholder="Data Type"
            [listData]="dataTypeOptions"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [isDisabled]="isEdit"
            class="max-width"
            (changeOptionValue)="onChangeDataTypeOfListParameter(i)">
          </ptg-select>
          <ptg-select
            *ngIf="rowControls.get('dataType')?.value === CalculationDataType.Property"
            [controlField]="rowControls.get('propertyName')"
            placeholder="Property Name"
            [listData]="availablePropertyConfigs"
            [isMultipleLineOption]="true"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [isDisabled]="isEdit"
            class="max-width"
            (changeOptionValue)="onChangeFormulaOverview(i)">
          </ptg-select>
          <ptg-textbox
            *ngIf="rowControls.get('dataType')?.value === CalculationDataType.Number"
            class="text-box"
            [controlField]="rowControls.get('numberValue')"
            placeholder="Number Value"
            [hasLabel]="true"
            inputType="Decimal"
            [isDisabled]="isEdit"
            [mask]="'separator.10'"
            (input)="onChangeFormulaOverview(i)"
            errorAsync="Cannot divide by zero.">
          </ptg-textbox>
          <span *ngIf="!isEdit" class="remove-btn" (click)="onClickRemoveRow(i)">
            <mat-icon>delete_forever</mat-icon>
          </span>
        </div>
      </div>
      <ptg-button
          *ngIf="addNewButtonIsValid() && !isEdit"
          buttonName="Add New"
          class="add-btn"
          classInput="add-button"
          (clickButton)="onClickAddNew()"
        >
        <mat-icon>add</mat-icon>
      </ptg-button>
    </div>
    <div class="row-button">
      <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
