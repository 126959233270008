<div class="document-list-configuration" id="document-list-configuration-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div
      *ngIf="!isDataLoading"
      class="property-config-container custom-class">
      <div class="row-button">
        <button
          [disabled]="!isDataChanged"
          (click)="onSubmit()"
          mat-raised-button
          type="button"
          class="submit-button">
          Save
        </button>
        <button
          (click)="onCancel()"
          mat-raised-button
          type="button"
          class="cancel-button">
          Cancel
        </button>
      </div>
    
      <div class="content-container">
        <div class="box-container add-property-section">
          <div class="box">
            <div class="title">Add Column</div>
            <div class="form-container">
              <ptg-select
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="currentAvailablePropertyList"
                [controlField]="propertyNameDropdownControl"
                (changeOptionValue)="changePropertyNameDropdown()"
                panelClass="document-list-configuration-property-name-dropdown"
                placeholder="Property Name">
              </ptg-select>
              <ptg-textbox 
                [controlField]="columnNameTextboxControl"
                [hasLabel]="true"
                [maxLength]="COLUMN_NAME_MAX_LENGTH"
                [placeholder]="'Column Name'"
                customError="duplicatedValue">
              </ptg-textbox>
              <ptg-button 
                [buttonName]="'Add Column'"
                (clickButton)="addColumn()"
                classInput="add-button"
                type="button">
                <mat-icon>add</mat-icon>
              </ptg-button>
            </div>
          </div>
        </div>
        <div class="box-container sort-property">
          <div class="box">
            <div [ngClass]="{ 'no-data': currentOrderColumnsTableData.length === 0 }" class="title">Order Columns</div>
            <ptg-grid
              #gridOrderColumns
              [data]="currentOrderColumnsTableData"
              [columns]="orderColumnsTableColumns"
              [notFoundMessage]="''"
              [fitToParent]="true"
              [paginable]="false"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteOrderColumnsTableRecord($event)"
              (rowDrop)="onDropEventOrderColumnsTable($event)"
              (change)="onChangeOrderColumnsTable()">
              <ng-template [columnName]="'columnName'" cellContent let-row>
                <ng-container>
                <div class="multiple-line-text">
                  <span class="caption">{{ row?.columnName }}</span>
                  <span class="description">{{ row?.columnNameDescription }}</span>
                </div>
                </ng-container>
              </ng-template>
              <ng-template [columnName]="ACTION_COLUMN" cellContent let-row>
                <ng-container *ngIf="!row.editing">
                  <ptg-button
                    *ngIf="!row.deleted && !row.isUsedInSortRowTable"
                    (clickButton)="onRowActions({ row, type: ACTION.ADD_SORT_ROW })"
                    classInput="edit-button">
                  <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
        <div class="box-container">
          <div class="box">
            <div class="title" [ngClass]="{ 'no-data': currentSortRowTableData.length === 0 }">Sort Row</div>
            <ptg-grid
              #gridSortRow
              [data]="currentSortRowTableData"
              [columns]="sortRowTableColumns"
              [notFoundMessage]="''"
              [fitToParent]="true"
              [paginable]="false"
              [allowDragDrop]="true"
              [hideHeader]="true"
              (rowDrop)="onDropEventSortRowTable($event)">
              <ng-template cellContent [columnName]="'columnName'" let-row>
                <ng-container>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.columnName }}</span>
                  <span class="description">{{ row.columnNameDescription }}</span>
                </div>
                </ng-container>
              </ng-template>
              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container>
                  <ptg-button
                    *ngIf="!row.deleted"
                    (clickButton)="onRowActions({ row, type: ACTION.SORT_CHANGE })"
                    classInput="edit-button">
                    <mat-icon>{{ row?.sortType ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
                  </ptg-button>
                </ng-container>
                <ng-container>
                  <ptg-button
                    *ngIf="!row.deleted"
                    classInput="delete-button"
                    (clickButton)="onRowActions({ row, type: ACTION.REMOVE })"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isDataLoading" id="loading-check" class="is-loading-section">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
  </div>
</div>
