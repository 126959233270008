import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  AddDisabilityPaymentRequestBody,
  EditMetRequirementBody,
  EditMetRequirementResponse,
  GetDisabilityPaymentsResponse,
  GetListStatusHistoryQuery,
  MunicipalityList,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityPaymentService {
  constructor(private httpClient: HttpClient) {}

  getMunicipalityPaymentList(
    query: GetListStatusHistoryQuery
  ): Observable<MunicipalityList> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MunicipalityList>(
      `${environment.apiUrl}/Members/Payments`,
      { params }
    );
  }

  generateServiceRecordReport(memberId: string, date: string) {
    return this.httpClient.get(`${environment.apiUrl}/Members/${ memberId }/report/service-record/${date}`, { responseType: 'blob' });
  }

  editMunicipalityPaymentMetRequirement(body: EditMetRequirementBody): Observable<EditMetRequirementResponse> {
    return this.httpClient.put<EditMetRequirementResponse>(`${environment.apiUrl}/municipality/payments/met-requirement`, body);
  }

  addDisabilityPayment(body: AddDisabilityPaymentRequestBody): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/municipality/create-disability-payment`, body);
  }

  getDisabilityPayments(memberId: string): Observable<GetDisabilityPaymentsResponse> {
    return this.httpClient.get<GetDisabilityPaymentsResponse>(`${environment.apiUrl}/Members/${ memberId }/disability-payment`);
  }
}
