import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { GenerateDocument } from '@ptg-member/types/models/generate-document.models';
import { deepClone } from '@ptg-shared/utils/common.util';

@Injectable({ providedIn: 'root' })
export class GenerateDocumentComponentService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private _generateDocumentData!: GenerateDocument | null;
  private _generateDocumentInfo!: any;

  get selectedGenerateDocumentDataFromService() {
    return this._generateDocumentData;
  }
  set selectedGenerateDocumentDataFromService(generateDocumentData: GenerateDocument | null) {
    this._generateDocumentData = deepClone(generateDocumentData);
  }

  get selectedGenerateDocumentInfoFromService() {
    return this._generateDocumentInfo;
  }
  set selectedGenerateDocumentInfoFromService(generateDocumentInfo: any | null) {
    this._generateDocumentInfo = deepClone(generateDocumentInfo);
  }

  constructor() {}
  ngOnDestroy(): void {
    this.selectedGenerateDocumentDataFromService = null;
    this.selectedGenerateDocumentInfoFromService = null;
  }
}
