export enum DeductionPayeeRunType {
  CheckAndDirectDeposit,
  WireTransferAndInternal,
  MuniRefund
}

export enum DeductionPayeeRunTypeName {
  'DP Check & DD',
  'DP Wire T. & Internal P.',
  MuniRefund,
}
