import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { Option } from '@ptg-shared/controls/select/select.component';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import {
  BooleanPropertyConfigType,
  BooleanPropertyConfigTypeValue,
  DefaultBooleanValue,
  PropertyConfigDefaultLookupTable,
  PropertyConfigTypeValue,
  PropertyTypeLabel,
  PropertyTypeLabelValue,
} from '../../constance/metadata.const';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import {Validation, ValidationType, ValidationOptions, ValidationTypeOptions} from '../../constance/dateValidationExpression.const';
import {
  DateValidationExpression,
  LookupTable,
  MetadataProperty,
  PropertyConfig,
  PropertyEditData,
} from '../../types/models';
import { MetadataSectionService } from '../../services/metadata-detail.service';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { OptionValue } from '@ptg-shared/types/models';
@Component({
  selector: 'ptg-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.scss'],
})
export class EditPropertyComponent implements OnInit, OnChanges {
  readonly PropertyType = PropertyType;
  readonly PropertyTypeLabel = PropertyTypeLabel;
  @Input() data!: PropertyEditData;
  @Input() itemKey?: string;
  @Input() lookupTables: LookupTable[] | undefined = [];
  @Input() metadataProperties?: MetadataProperty[] = [];
  @Output() closeDialog: EventEmitter<PropertyEditData> = new EventEmitter();
  editForm: FormGroup = this.fb.group({});
  formSubmit$: Subject<void> = new Subject();
  elementNames: BooleanPropertyConfigTypeValue[] = [];
  options: OptionValue[] = [];
  lookupTableOptions: Option[] = [];
  customValidators: ValidatorFn[] = [];
  dateValidationExpressions: DateValidationExpression[]= [];

  showMasked = false;
  propertiesMasked = [
    PropertyType.TYPE_PHONE,
    PropertyType.TYPE_SSN,
    PropertyType.TYPE_EMAIL,
  ];
  dateType = PropertyType.TYPE_DATE;
  DateValidation = Validation;
  DateValidationType = ValidationType;
  ValidationOptions = ValidationOptions;
  ValidationTypeOptions = ValidationTypeOptions;
  dataPropertylookupOptions: Option[] = [];
  currentLookupTable: string = '';

  constructor(
    public dialogRef: MatDialogRef<EditPropertyComponent>,
    public dialog: MatDialog,
    private metadataSectionService: MetadataSectionService,
    public fb: FormBuilder,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
          this.editForm.get('importLabel')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });

    this.showMasked =
      this.data?.type && this.propertiesMasked.includes(this.data?.type)
        ? true
        : false;
    this.initDataPropertyLookup();    
  }

  onNoClick() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  onSubmit() {
    const configKey = Object.values(PropertyConfigTypeValue);
    const booleanKey = Object.values(BooleanPropertyConfigType);
    const formValue = this.editForm.getRawValue();
    const config: PropertyConfig = {} as PropertyConfig;
    Object.keys(formValue).forEach((key) => {
      if (configKey.includes(key as any)) {
        (config as any)[key] = Array.isArray(formValue[key])
          ? JSON.stringify(formValue[key])
          : formValue[key].toString();
      }
      if (booleanKey.includes(key as any)) {
        (config as any)[key] = (!!formValue[key]).toString();
      }
    });
    config.required = this.editForm.get('requiredCondition')?.value.toString();
    config.readOnly = this.editForm.get('readOnlyCondition')?.value.toString();
    config.initiated = this.editForm
      .get('initiatedCondition')
      ?.value.toString();
    config.masked = this.editForm.get('maskedCondition')?.value.toString();
    if(this.data?.type == this.dateType){
      config.excludeFutureDates = this.editForm.get('excludeFutureDates')?.value.toString();
      config.excludePastDates = this.editForm.get('excludePastDates')?.value.toString();
      config.validation = this.editForm.get('validation')?.value.toString();
      config.dateValidationExpressions = this.serializeDate();
    }
    const property: PropertyEditData = {
      key: this.data.key,
      config: config as PropertyConfig,
      name: this.editForm.get('name')?.value?.trim(),
      importKey: this.editForm.get(
        BooleanPropertyConfigType.IncludeInBulkUpdate
      )?.value
        ? this.editForm.get('importLabel')?.value?.trim()
        : '',
      isEdit: true,
      options: this.options,
    };
    this.closeDialog.emit(property);
  }
  serializeDate(){
    const expressionArray = this.dateExpressions?.controls?.map((epx)=>{
      return {
        validation: Number(epx?.value['validation']),
        validationType: Number(epx?.value['validationType']),
        value: epx?.value['value']
      }
    })
    return JSON.stringify(expressionArray)
  }
  initFormGroup() {
    this.editForm = this.fb.group({
      name: this.fb.control(this.data?.name, {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: checkApiValidator(
          this.metadataSectionService.checkExitProperty,
          'propertyName',
          this.data?.name
        ),
      }),
      requiredCondition: this.fb.control({
        value: stringToBoolean(this.data?.config?.required),
        disabled:
          stringToBoolean(this.data?.config?.readOnly) ||
          stringToBoolean(this.data?.config?.initiated),
      }),
      initiatedCondition: this.fb.control(
        stringToBoolean(this.data?.config?.initiated)
      ),
      readOnlyCondition: this.fb.control({
        value: stringToBoolean(this.data?.config?.readOnly),
        disabled: false,
      }),
      maskedCondition: this.fb.control({
        value: stringToBoolean(this.data?.config?.masked),
        disabled: false,
      }),
      includeInBulkUpdate: this.fb.control({
        value: stringToBoolean(this.data?.config?.readOnly)
          ? false
          : !!this.data?.importKey,
        disabled: stringToBoolean(this.data?.config?.readOnly),
      }),
      importLabel: this.fb.control(this.data?.importKey, {
        validators: [Validators.required, Validators.maxLength(255)],
        asyncValidators: checkApiValidator(
          this.metadataSectionService.checkExitProperty,
          'propertyImportKey',
          this.data?.importKey
        ),
      }),
      excludeFutureDates: this.fb.control({
        value: stringToBoolean(this.data?.config?.excludeFutureDates),
        disabled: false,
      }),
      excludePastDates: this.fb.control({
        value: stringToBoolean(this.data?.config?.excludePastDates),
        disabled: false,
      }),
      validation: this.fb.control({
        value: stringToBoolean(this.data?.config?.validation),
        disabled: false,
      }),
      dateExpressions: this.fb.array([])      
    });
    this.elementNames = this.getProperties(
      this.data.type as keyof typeof PropertyTypeLabel
    );
    this.elementNames.forEach((propertyConfigType) =>
      this.addFormControls(propertyConfigType)
    );
    if (this.data.isStatic) {
      
      Object.keys(this.editForm.controls).forEach((key) => {
        if (
          (key === BooleanPropertyConfigType.IncludeInBulkUpdate &&
            !stringToBoolean(
              this.editForm.get(BooleanPropertyConfigType.ReadOnly)?.value
            )) ||
          key === 'name' ||
          (this.data.key === 'phone' && this.itemKey ==="demographics")||
          (this.data.key === 'email'  && this.itemKey ==="demographics")||
          key === PropertyConfigTypeValue.LookupTable ||
          (this.data.type === PropertyTypeLabel['Person Name'] &&
            (key === 'requiredCondition' || key === 'initiatedCondition'))
        ) {
          if(this.data.key === 'email' && this.itemKey ==="demographics"){
            this.editForm.get(BooleanPropertyConfigType.IncludeInBulkUpdate)?.setValue(true);
          }

           if(this.data.key === 'phone' && this.itemKey ==="demographics"){
            this.editForm.get(BooleanPropertyConfigType.SMSEnabled)?.disable({ emitEvent: false });
            this.editForm.get(BooleanPropertyConfigType.IncludeInBulkUpdate)?.setValue(true);
          }
          return;
        }
        this.editForm.get(key)?.disable({ emitEvent: false });
      });
    }
    this.checkDisabledImportLabel(
      this.editForm.get(BooleanPropertyConfigType.IncludeInBulkUpdate)?.value
    );
    this.checkDisabledRequiredToggle();    
    this.initDateValidationExpressions();
  }
  get dateExpressions() {
    return this.editForm.controls['dateExpressions'] as FormArray;
  }
  initDateValidationExpressions(){
    if(this.data?.type == this.dateType){
      if (this.data?.config?.dateValidationExpressions) {
        const expressions = JSON.parse(this.data.config.dateValidationExpressions);
        expressions.forEach((exp: any)=>{
          const dateExpression = this.fb.group({
            validation:  [exp.validation, Validators.required],
            validationType:  [exp.validationType, Validators.required],
            value:  [exp.value, Validators.required],
          })
          this.dateExpressions.push(dateExpression)       
        })       
      }      
    }
    return    
  }
  refreshError(index: number){
    (this.dateExpressions.at(index) as FormGroup).setControl('value', this.fb.control(''));
  }

  initDataPropertyLookup(){
    const dateProperties = this.metadataProperties?.filter(prop => prop.type == this.dateType && prop.key != this.data?.key) || [];
    this.dataPropertylookupOptions = dateProperties.map((prop)=>{      
        const option: Option = { 
          value : prop.key,
          displayValue : prop.name
        }
        return option;
    })
    this.dataPropertylookupOptions.sort((a, b) => {
      if (a.value < b.value) { return -1; }
      if (a.value > b.value) { return 1; }
      return 0;

    })
  }

  checkDisabledImportLabel(value: boolean) {
    if (value) {
      this.editForm
        .get('importLabel')
        ?.enable({ onlySelf: true, emitEvent: false });
    } else {
      this.editForm.get('importLabel')?.setValue('');
      this.editForm
        .get('importLabel')
        ?.disable({ onlySelf: true, emitEvent: false });
    }
  }
  onClickRemoveExpression(index: number){
    this.dateExpressions.removeAt(index);
    if(!this.dateExpressions.length){
      this.editForm.get(BooleanPropertyConfigType.Validation)?.setValue(false);
    }
  }
  removeAllDateExpressions(){
    const expressionCount = this.dateExpressions?.controls?.length;
    for(let i = 0; i<= expressionCount;i++){
      this.dateExpressions.removeAt(0);
    }    
  }
  onClickNewValidation(){
    const dateExpression = this.fb.group({
      validation:  [null, Validators.required],
      validationType:  [null, Validators.required],
      value:  [null, Validators.required],
    })
    this.dateExpressions.push(dateExpression)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.metadataSectionService.propertyKey = this.data.key || '';
      this.initFormGroup();
      this.updateValidity();
    }
    if (changes.lookupTables) {
      this.lookupTableOptions = (this.lookupTables || [])
        .reduce((result, current) => {
          result.push({
            value: current.id,
            displayValue: current.name,
          });
          return result;
        }, [] as Option[])
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));

      this.currentLookupTable =
        (this.lookupTables || []).find(
          (lookupTable) =>
            lookupTable.id?.toUpperCase() ===
            this.data?.config?.[PropertyConfigTypeValue.LookupTable]?.toUpperCase()
        )?.name || '';
    }
  }

  changeToggle(value: boolean, toggleName: string = '') {
    switch (toggleName) {
      case BooleanPropertyConfigType.InclusiveInRange: {
        if (!value) {
          this.editForm.get(PropertyConfigTypeValue.MinInput)?.setValue('');
          this.editForm.get(PropertyConfigTypeValue.MaxInput)?.setValue('');
          this.editForm
            .get(PropertyConfigTypeValue.MinInput)
            ?.disable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigTypeValue.MaxInput)
            ?.disable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.MinInput)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigTypeValue.MaxInput)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.PrefixList: {
        if (!value) {
          const defaultValue =
            this.data?.config?.[
              PropertyConfigDefaultLookupTable.DefaultTitleLookupTable
            ] || '';
          this.editForm.setControl(
            PropertyConfigTypeValue.LookupTable,
            this.fb.control({ value: defaultValue, disabled: true })
          );
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.LookupTable)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.MaximumLength: {
        if (!value) {
          this.editForm
            .get(PropertyConfigTypeValue.MaxLengthInput)
            ?.setValue('');
          this.editForm
            .get(PropertyConfigTypeValue.MaxLengthInput)
            ?.disable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.MaxLengthInput)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigTypeValue.MaxLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.FractionalLength: {
        if (!value) {
          this.editForm
            .get(PropertyConfigTypeValue.FractionalLengthInput)
            ?.setValue('');
          this.editForm
            .get(PropertyConfigTypeValue.FractionalLengthInput)
            ?.disable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.FractionalLengthInput)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigTypeValue.FractionalLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.IncludeInBulkUpdate: {
        this.checkDisabledImportLabel(value);
        break;
      }
      case BooleanPropertyConfigType.ReadOnly: {
        if (value) {
          this.changeToggle(
            false,
            BooleanPropertyConfigType.IncludeInBulkUpdate
          );
          this.editForm
            .get(BooleanPropertyConfigType.IncludeInBulkUpdate)
            ?.setValue(false);
          this.editForm
            .get(BooleanPropertyConfigType.IncludeInBulkUpdate)
            ?.disable({
              onlySelf: true,
              emitEvent: false,
            });
        } else {
          this.editForm.get('includeInBulkUpdate')?.enable();
        }
        this.checkDisabledRequiredToggle();
        break;
      }
      case BooleanPropertyConfigType.Boolean: {
        if (value) {
          this.editForm
            .get(PropertyConfigTypeValue.Affirmative)
            ?.enable({ onlySelf: true });
          this.editForm
            .get(PropertyConfigTypeValue.Negative)
            ?.enable({ onlySelf: true });
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.Affirmative)
            ?.setValue(DefaultBooleanValue.Affirmative);
          this.editForm
            .get(PropertyConfigTypeValue.Negative)
            ?.setValue(DefaultBooleanValue.Negative);
          this.editForm
            .get(PropertyConfigTypeValue.Affirmative)
            ?.disable({ onlySelf: true, emitEvent: false });
          this.editForm
            .get(PropertyConfigTypeValue.Negative)
            ?.disable({ onlySelf: true, emitEvent: false });
        }
        break;
      }
      case BooleanPropertyConfigType.Initiated: {
        this.checkDisabledRequiredToggle();
        break;
      }
      case BooleanPropertyConfigType.UsedStateLookupTable: {
        if (!value) {
          const defaultValue =
            this.data?.config?.[
              PropertyConfigDefaultLookupTable.DefaultStateLookupTable
            ] || '';
          this.editForm.setControl(
            PropertyConfigTypeValue.StateLookupTable,
            this.fb.control({ value: defaultValue, disabled: true })
          );
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.StateLookupTable)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.UsedCountryLookupTable: {
        if (!value) {
          const defaultValue =
            this.data?.config?.[
              PropertyConfigDefaultLookupTable.DefaultCountryLookupTable
            ] || '';
          this.editForm.setControl(
            PropertyConfigTypeValue.CountryLookupTable,
            this.fb.control({ value: defaultValue, disabled: true })
          );
        } else {
          this.editForm
            .get(PropertyConfigTypeValue.CountryLookupTable)
            ?.enable({ onlySelf: true });
        }
        break;
      }
      case BooleanPropertyConfigType.ExcludeFutureDates: {
        if (value) {
          this.editForm.get(BooleanPropertyConfigType.ExcludePastDates)?.setValue(false);          
        }
        break;
      }
      case BooleanPropertyConfigType.ExcludePastDates: {
        if (value) {
          this.editForm.get(BooleanPropertyConfigType.ExcludeFutureDates)?.setValue(false);          
        }
        break;
      }
      case BooleanPropertyConfigType.Validation: {
        if (!value) {
          this.removeAllDateExpressions();
          
        } else {
          this.onClickNewValidation();
        }
        break;
      }
    }
  }

  getProperties(
    type: PropertyTypeLabelValue
  ): BooleanPropertyConfigTypeValue[] {
    const properties = [];
    switch (type) {
      case PropertyTypeLabel.Currency:
        properties.push(BooleanPropertyConfigType.InclusiveInRange);
        break;
      case PropertyTypeLabel.Text:
      case PropertyTypeLabel.RichText:
        properties.push(BooleanPropertyConfigType.MaximumLength);
        break;
      case PropertyTypeLabel.Decimal:
        properties.push(BooleanPropertyConfigType.InclusiveInRange);
        properties.push(BooleanPropertyConfigType.FractionalLength);
        break;
      case PropertyTypeLabel['Whole Number']:
        properties.push(BooleanPropertyConfigType.InclusiveInRange);
        break;
      case PropertyTypeLabel.Phone:
        properties.push(BooleanPropertyConfigType.SMSEnabled);
        break;
      case PropertyTypeLabel['Person Name']:
        properties.push(BooleanPropertyConfigType.PrefixList);
        break;
      // case PropertyTypeLabel.Lookup:
      //   properties.push(BooleanPropertyConfigType.ListValues);
      //   break;
      case PropertyTypeLabel.Binary:
        properties.push(BooleanPropertyConfigType.Boolean);
        break;
      case PropertyTypeLabel.Address:
        properties.push(BooleanPropertyConfigType.UsedStateLookupTable);
        properties.push(BooleanPropertyConfigType.UsedCountryLookupTable);
        break;
      default:
        break;
    }
    return properties as BooleanPropertyConfigTypeValue[];
  }

  setCustomValidator() {
    this.customValidators = [this.validateRequired(), this.minMaxValidator()];
  }

  addFormControls(type: BooleanPropertyConfigTypeValue): void {
    this.setCustomValidator();
    switch (type) {
      case BooleanPropertyConfigType.InclusiveInRange: {
        this.editForm.addControl(
          BooleanPropertyConfigType.InclusiveInRange,
          this.fb.control(
            stringToBoolean(
              this.data?.config?.[BooleanPropertyConfigType.InclusiveInRange]
            )
          )
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.MinInput,
          this.fb.control(
            this.data?.config?.[PropertyConfigTypeValue.MinInput] || ''
          )
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.MaxInput,
          this.fb.control(
            this.data?.config?.[PropertyConfigTypeValue.MaxInput] || ''
          )
        );
        break;
      }
      case BooleanPropertyConfigType.MaximumLength: {
        const maxLengthValue = stringToBoolean(
          this.data?.config?.[BooleanPropertyConfigType.MaximumLength]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.MaximumLength,
          this.fb.control(maxLengthValue)
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.MaxLengthInput,
          this.fb.control(
            this.data?.config?.[PropertyConfigTypeValue.MaxLengthInput] || ''
          )
        );
        if (maxLengthValue) {
          this.editForm
            .get(PropertyConfigTypeValue.MaxLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.FractionalLength: {
        const fractionalLengthValue = stringToBoolean(
          this.data?.config?.[BooleanPropertyConfigType.FractionalLength]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.FractionalLength,
          this.fb.control(fractionalLengthValue)
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.FractionalLengthInput,
          this.fb.control(
            this.data?.config?.[
              PropertyConfigTypeValue.FractionalLengthInput
            ] || ''
          )
        );
        if (fractionalLengthValue) {
          this.editForm
            .get(PropertyConfigTypeValue.FractionalLengthInput)
            ?.addValidators(Validators.required);
        }
        break;
      }
      case BooleanPropertyConfigType.SMSEnabled: {
        this.editForm.addControl(
          BooleanPropertyConfigType.SMSEnabled,
          this.fb.control(
            stringToBoolean(
              this.data?.config?.[BooleanPropertyConfigType.SMSEnabled]
            )
          )
        );
        break;
      }
      case BooleanPropertyConfigType.PrefixList: {
        const prefixListValue = stringToBoolean(
          this.data?.config?.[BooleanPropertyConfigType.PrefixList]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.PrefixList,
          this.fb.control(prefixListValue)
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.LookupTable,
          this.fb.control(
            {
              value:
                this.data?.config?.[PropertyConfigTypeValue.LookupTable] || '',
              disabled: !prefixListValue,
            },
            Validators.required
          )
        );
        break;
      }
      // case BooleanPropertyConfigType.ListValues: {
      //   const listValue = stringToBoolean(this.data?.config?.[BooleanPropertyConfigType.ListValues]);
      //   this.editForm.addControl(BooleanPropertyConfigType.ListValues, this.fb.control(listValue));
      //   this.editForm.addControl(PropertyConfigTypeValue.LookupTable, this.fb.control(
      //     { value: this.data?.config?.[PropertyConfigTypeValue.LookupTable] || '', disabled: !listValue },
      //     [Validators.required, this.validateLookupTable()]
      //   ));
      //   break;
      // }
      case BooleanPropertyConfigType.Boolean: {
        this.editForm.addControl(
          BooleanPropertyConfigType.Boolean,
          this.fb.control(
            stringToBoolean(
              this.data?.config?.[BooleanPropertyConfigType.Boolean]
            )
          )
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.Affirmative,
          this.fb.control(
            this.data?.config?.[PropertyConfigTypeValue.Affirmative] || '',
            [Validators.required, this.booleanDisplayValueValidator()]
          )
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.Negative,
          this.fb.control(
            this.data?.config?.[PropertyConfigTypeValue.Negative] || '',
            [Validators.required, this.booleanDisplayValueValidator()]
          )
        );
        break;
      }
      case BooleanPropertyConfigType.UsedStateLookupTable: {
        const usedStateLookupTable = stringToBoolean(
          this.data?.config?.[BooleanPropertyConfigType.UsedStateLookupTable]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.UsedStateLookupTable,
          this.fb.control(usedStateLookupTable)
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.StateLookupTable,
          this.fb.control(
            {
              value:
                this.data?.config?.[PropertyConfigTypeValue.StateLookupTable] ||
                '',
              disabled: !usedStateLookupTable,
            },
            Validators.required
          )
        );
        break;
      }
      case BooleanPropertyConfigType.UsedCountryLookupTable: {
        const usedCountryLookupTable = stringToBoolean(
          this.data?.config?.[BooleanPropertyConfigType.UsedCountryLookupTable]
        );
        this.editForm.addControl(
          BooleanPropertyConfigType.UsedCountryLookupTable,
          this.fb.control(usedCountryLookupTable)
        );
        this.editForm.addControl(
          PropertyConfigTypeValue.CountryLookupTable,
          this.fb.control(
            {
              value:
                this.data?.config?.[
                  PropertyConfigTypeValue.CountryLookupTable
                ] || '',
              disabled: !usedCountryLookupTable,
            },
            Validators.required
          )
        );
        break;
      }
      default:
        return;
    }
  }

  removeValue(formArrayName: string, index: number, control: FormControl) {
    (this.editForm.get(formArrayName) as FormArray).removeAt(index);
    this.editForm.get(formArrayName)?.markAsTouched();
    control.markAsTouched();
    control.updateValueAndValidity();
    this.options.splice(index, 1);
  }

  addValue(formArrayName: string, control: FormControl) {
    if (formArrayName === BooleanPropertyConfigType.PrefixList) {
      (this.editForm.get(formArrayName) as FormArray)?.push(
        this.fb.control(control.value, [
          Validators.required,
          Validators.maxLength(8),
        ])
      );
    } else {
      (this.editForm.get(formArrayName) as FormArray)?.push(
        this.fb.control(control.value, [
          Validators.required,
          Validators.maxLength(150),
        ])
      );
    }
    this.options.push({ id: '', text: control.value });
    this.editForm.get(formArrayName)?.markAsTouched();
    control.reset();
    control.updateValueAndValidity();
  }

  validateRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent || !control.parent.get('inclusiveInRange')?.value) {
        return null;
      }
      const { value: minValue } = control.parent.get('minInput') as FormControl;
      const { value: maxValue } = control.parent.get('maxInput') as FormControl;
      if (!minValue?.length && !maxValue?.length) {
        return { required: true };
      }
      return null;
    };
  }

  updateValidity() {
    this.editForm
      .get(PropertyConfigTypeValue.MinInput)
      ?.valueChanges.subscribe(() =>
        this.editForm
          .get(PropertyConfigTypeValue.MaxInput)
          ?.updateValueAndValidity({ emitEvent: false })
      );
    this.editForm
      .get(PropertyConfigTypeValue.MaxInput)
      ?.valueChanges.subscribe(() =>
        this.editForm
          .get(PropertyConfigTypeValue.MinInput)
          ?.updateValueAndValidity({ emitEvent: false })
      );
    this.editForm
      .get(PropertyConfigTypeValue.Affirmative)
      ?.valueChanges.subscribe(() =>
        this.editForm
          .get(PropertyConfigTypeValue.Negative)
          ?.updateValueAndValidity({ emitEvent: false })
      );
    this.editForm
      .get(PropertyConfigTypeValue.Negative)
      ?.valueChanges.subscribe(() =>
        this.editForm
          .get(PropertyConfigTypeValue.Affirmative)
          ?.updateValueAndValidity({ emitEvent: false })
      );
  }

  minMaxValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !control.parent?.get('inclusiveInRange')?.value ||
        !control.parent?.get('minInput') ||
        !control.parent?.get('maxInput')
      ) {
        return null;
      }
      const { value: minValue } = control.parent?.get(
        'minInput'
      ) as FormControl;
      const { value: maxValue } = control.parent?.get(
        'maxInput'
      ) as FormControl;
      return maxValue.length && minValue.length && +minValue > +maxValue
        ? { minHigherThanMax: true }
        : null;
    };
  }

  booleanDisplayValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !control.parent?.get(BooleanPropertyConfigType.Boolean)?.value ||
        !control.parent?.get(PropertyConfigTypeValue.Affirmative) ||
        !control.parent?.get(PropertyConfigTypeValue.Negative)
      ) {
        return null;
      }
      let { value: affirmativeValue } = control.parent?.get(
        PropertyConfigTypeValue.Affirmative
      ) as FormControl;
      let { value: negativeValue } = control.parent?.get(
        PropertyConfigTypeValue.Negative
      ) as FormControl;
      return affirmativeValue &&
        negativeValue &&
        affirmativeValue === negativeValue
        ? { duplicateValue: true }
        : null;
    };
  }

  markMinMaxAsTouched() {
    this.editForm
      .get(PropertyConfigTypeValue.MinInput)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigTypeValue.MinInput)
      ?.updateValueAndValidity({ emitEvent: false });
    this.editForm
      .get(PropertyConfigTypeValue.MaxInput)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigTypeValue.MaxInput)
      ?.updateValueAndValidity({ emitEvent: false });
  }

  markDisplayValueAsTouched() {
    this.editForm
      .get(PropertyConfigTypeValue.Affirmative)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigTypeValue.Affirmative)
      ?.updateValueAndValidity({ emitEvent: false });
    this.editForm
      .get(PropertyConfigTypeValue.Negative)
      ?.markAsTouched({ onlySelf: true });
    this.editForm
      .get(PropertyConfigTypeValue.Negative)
      ?.updateValueAndValidity({ emitEvent: false });
  }

  checkDisabledRequiredToggle() {
    this.editForm.get('excludeFutureDates')?.enable();
    this.editForm.get('excludePastDates')?.enable();
    this.editForm.get('validation')?.enable();
    const initiatedValue = this.editForm.get('initiatedCondition')?.value;
      const listToggleValue = this.elementNames.find(
      (elementName) => elementName === BooleanPropertyConfigType.ListValues
    )
      ? this.editForm.get(BooleanPropertyConfigType.ListValues)?.value
      : true;
    const readOnlyValue = this.editForm.get('readOnlyCondition')?.value;
    if (this.data.isStatic) {
      this.editForm.get('maskedCondition')?.enable();
      if (this.data.type !== PropertyTypeLabel['Person Name'] && this.data.key !=="email" && this.data.key!=="phone") {
        return;
      }
      if (initiatedValue) {
        this.editForm.get('requiredCondition')?.setValue(true);
        this.editForm.get('requiredCondition')?.disable({ emitEvent: false });
        if((this.data.key ==="email" && this.itemKey ==="demographics") || (this.data.key==="phone"  && this.itemKey ==="demographics")){
          this.editForm.get('requiredCondition')?.setValue(true);
          this.editForm.get('requiredCondition')?.disable({ emitEvent: false });
          this.editForm.get('readOnlyCondition')?.setValue(false);
          this.editForm.get('readOnlyCondition')?.disable({ emitEvent: false });
          this.editForm.get('includeInBulkUpdate')?.enable();
        }
      } else {
        if((this.data.key ==="email" && this.itemKey ==="demographics") || (this.data.key==="phone"  && this.itemKey ==="demographics")){
          this.editForm.get('readOnlyCondition')?.enable({ emitEvent: false });
          this.editForm
            .get(BooleanPropertyConfigType.ListValues)
            ?.enable({ emitEvent: false });
          if (readOnlyValue || !listToggleValue) {
            this.editForm.get('requiredCondition')?.setValue(false);
            this.editForm.get('requiredCondition')?.disable({ emitEvent: false });
          } else {
            this.editForm.get('requiredCondition')?.enable({ emitEvent: false });
          }
        }
      }
      return;
    }
  

    if (this.data.type === PropertyType.TYPE_BENEFIT && readOnlyValue) {
      this.editForm.get('readOnlyCondition')?.disable({ emitEvent: false });
      this.editForm.get('initiatedCondition')?.disable({ emitEvent: false });
      return;
    }
    if (initiatedValue) {
      this.editForm.get('requiredCondition')?.setValue(true);
      this.editForm.get('requiredCondition')?.disable({ emitEvent: false });
      this.editForm.get('readOnlyCondition')?.setValue(false);
      this.editForm.get('readOnlyCondition')?.disable({ emitEvent: false });
      this.editForm.get('includeInBulkUpdate')?.enable();
    } else {
      this.editForm.get('readOnlyCondition')?.enable({ emitEvent: false });
      this.editForm
        .get(BooleanPropertyConfigType.ListValues)
        ?.enable({ emitEvent: false });
      if (readOnlyValue || !listToggleValue) {
        this.editForm.get('requiredCondition')?.setValue(false);
        this.editForm.get('requiredCondition')?.disable({ emitEvent: false });
      } else {
        this.editForm.get('requiredCondition')?.enable({ emitEvent: false });
      }
    }
    
  }
}
