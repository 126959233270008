export const ScreensKey: any = {
    Participants: 'participant_list'
}
// Date with Time
export const DATETIME_FORMAT_YMDHMA = 'yyyy/MM/dd HH:mm a';
export const DATETIME_FORMAT_MDYhMA = 'MM/dd/yyyy hh:mm a'

// Date
export const DATE_FORMAT_YMD = 'yyyy-MM-dd';
export const DATE_FORMAT_MDY = 'MM/dd/yyyy';

// IDs
export const ORGANIZATION_NAME_PROPERTY_ID = 'fbf8abd8-f97c-47a5-b82b-376193240101';
export const PERSON_PARTICIPANT_MEMBER_PERSON_NAME_PROPERTY_ID = '0ac1b906-c396-4bc5-a83b-eec542482445';

// Property
export const PROPERTY_PERSON_NAME_PREFIX_KEY = 'prefix';
export const PROPERTY_PERSON_NAME_FIRST_KEY = 'first';
export const PROPERTY_PERSON_NAME_MIDDLE_KEY = 'middle';
export const PROPERTY_PERSON_NAME_LAST_KEY = 'last';
export const PROPERTY_PERSON_NAME_SUFFIX_KEY = 'suffix';

// CSS Classes
export const REQUIRED_INDICATOR = '--required-indicator';
