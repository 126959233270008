<div class="flex-col justify-center items-end gap-12 confirmation-dialog-container">
  <div class="flex-col justify-start items-start gap-4 flex">
    <div class="header flex items-center">
      <div class="icon-div flex items-center" *ngIf="data?.icon">
        <mat-icon class="svg-icon">{{ data?.icon }}</mat-icon>
      </div>
      <span class="title">{{ data?.title }}</span>
    </div>
    <div class="content-container gap-4">
      <span> {{ data?.message }} </span>
    </div>
  </div>
  <div *ngIf="data?.properties?.length" class="inputs-container flex w-full">
    <div *ngFor="let p of data.properties" [ngClass]="p.class ?? 'w-full'">
      <ng-container [ngSwitch]="p.type">
        <div *ngSwitchCase="EntityPropertyType.Lookup" class="flex-1">
          <ptg-select
            [controlField]="formGroup.get(p.key)"
            [placeholder]="p.label"
            [listData]="p.options"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [hasNoneValue]="!p.isRequired"
            [isRequired]="p.isRequired ?? false"
          ></ptg-select>
        </div>
        <div *ngSwitchCase="EntityPropertyType.Text" class="flex-1">
          <ptg-textbox
            [controlField]="formGroup.get(p.key)"
            [placeholder]="p.label"
            [hasLabel]="true"
            [maxLength]="p.maxLength"
            [isRequired]="!!p.isRequired"
          ></ptg-textbox>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="dialog-footer flex justify-end items-center gap-2">
    <ptg-button classInput="save-button" (clickButton)="formSubmit$.next()">Save</ptg-button>
    <ptg-button classInput="cancel-button" (clickButton)="cancel()">Cancel</ptg-button>
  </div>
</div>
