// export reducers from reducers folder here
import { Action, combineReducers } from '@ngrx/store';
import { TagsState, tagsReducer } from './tags.reducer';
import { DocumentsState, documentsReducer } from './documents.reducer';
import { DocumentListConfigurationState, documentListConfigurationReducer } from './document-list-configuration.reducer';

//define interface State including all states in module
export interface FileState {
  tags: TagsState,
  documents: DocumentsState,
  documentListConfiguration: DocumentListConfigurationState,
}

export const fileReducers = (state: FileState | undefined, action: Action) =>
  //import reducer here
  combineReducers({tags: tagsReducer, documents: documentsReducer, documentListConfiguration: documentListConfigurationReducer})(
    state,
    action
  );

export * from './tags.reducer';
export * from './documents.reducer';
export * from './document-list-configuration.reducer';
