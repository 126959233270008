import { EntityType } from "./types/enums/entity-type.enum";

// `objectId`: Id of Member, Participant, Person, Organization or Employer, Municipality
// `isFromOverview`: Current flow come from Profile / Overview of a Member, Participant, Person, Organization or Employer, Municipality or not
export function prepareDocumentListConfigurationRoute(entityType: EntityType, objectId?: string, isFromOverview?: boolean) {
  let url = '';
  switch (entityType) {
    case EntityType.Participant:
      url += 'member/';
      break;
    case EntityType.Employer:
      url += 'employer/';
      break;
  }

  if (objectId) {
    url += `individual-document/document-list-configuration/${objectId}`;

    if (isFromOverview) {
      url += `?overview=true`;
    }
  } else {
    url += `document-list/document-list-configuration`
  }

  return url;
}
