import { Component, Inject } from '@angular/core';
import * as LookupTableActions from '@ptg-member/store/actions/lookup-table.actions';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Subject } from 'rxjs';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE, GUID_EMPTY, SortType } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { PropertyTypeLabel } from '@ptg-shared/types/enums/entity.enum';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbstractControlStatus, AsyncFunction } from '@ptg-shared/types/models/common.model';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { GetLookupTableQuery } from '@ptg-member/types/models/lookup-table.model';
import { LinkedPropertyType, ParameterType } from '@ptg-member/features/calculation/constants';
import { EntityProperty, LinkedProperty, MasterInputOutput, Parameter } from '../../services/models';
import {
  addMasterInputOutputRequest,
  CalculationState,
  editMasterInputOutputRequest,
  getEntityReferenceByLinkedIdRequest,
  getLinkedPropertiesRequest,
  getPropertiesByLinkedIdRequest,
  selectEntityReferenceByLinkedIdState,
  selectLinkedPropertiesState,
  selectPropertiesByLinkedIdState,
} from '../../store';
import { InputOutputService } from '../../services';
import { selectLookupTable } from '@ptg-member/store/reducers';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { FundType, LookupTableType } from '@ptg-shared/types/enums';
import { EntityComponentType, EntityPropertyType } from '@ptg-entity-management/types/enums';

@Component({
  selector: 'ptg-add-retirement-benefit-input-output',
  templateUrl: './add-retirement-benefit-input-output.component.html',
  styleUrls: ['./add-retirement-benefit-input-output.component.scss'],
})
export class AddRetirementBenefitInputOutputComponent extends BaseComponent {
  ParameterType = ParameterType;
  EntityPropertyType = EntityPropertyType;
  parameter?: Parameter;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: `${this.data?.id ? 'Edit' : 'Add New'} Input/Output`,
    },
  ];
  formAddInputOutput = new FormGroup({});
  parameterTypeControl = new FormControl();
  formSubmit$ = new Subject<boolean>();
  checkInputOutputNameExistsAsyncFn?: AsyncFunction;
  parameterTypeOptions: RadioOption[] = [
    {
      label: 'Input',
      value: ParameterType.Input,
    },
    {
      label: 'Output',
      value: ParameterType.Output,
    },
  ];
  listDataType: Option[] = [];
  linkedProperties: Option[] = [];
  listLookupTable: Option[] = [];
  listCondition: Option[] = [];
  listValue: Option[] = [];

  isShowConditionControl: boolean = false;
  isShowValueControl: boolean = false;
  isShowReferenceControl: boolean = false;
  linkedPropertiesData: LinkedProperty[] = [];
  listConditionData: EntityProperty[] = [];
  listReferenceData: LinkedProperty[] = [];
  listReference: Option[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddRetirementBenefitInputOutputComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Parameter,
    private fb: FormBuilder,
    private calculationStore: Store<CalculationState>,
    private inputOutputService: InputOutputService,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkInputOutputNameExistsAsyncFn = {
      function: this.inputOutputService.checkMasterInputOutputNameExists,
      fieldCheck: 'name',
      editValue: this.data?.name ?? '',
      config: {
        params: {
          id: this.data?.id,
          type: this.data?.type ?? ParameterType.Input,
        },
      },
    };
    this.parameterTypeControl = new FormControl({
      value: this.data?.type ?? ParameterType.Input,
      disabled: !!this.data?.id,
    });
    this.getListLookupTable();
    this.getLinkedProperties(this.data?.dataType, this.data?.lookupTableId);
    this.selectLinkedPropertiesState();
    this.getListDataType(this.data?.type ?? ParameterType.Input);

    this.selectPropertiesByLinkedIdState();
    this.selectEntityReferenceByLinkedIdState();
    this.initFormGroup();
    this.onValueChangesParameterType();
    this.onValueChangesDataType();
    this.onValueChangesLookupTable();
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formAddInputOutput.markAllAsTouched();
        }),
        switchMap(() =>
          this.formAddInputOutput.statusChanges.pipe(
            startWith(this.formAddInputOutput.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup() {
    this.formAddInputOutput = this.fb.group({
      name: this.fb.control(this.data?.name),
      dataType: this.fb.control(this.data?.dataType),
      description: this.fb.control(this.data?.description),
      linkedEntityPropertyId: this.fb.control(this.data?.linkedEntityPropertyId),
      lookupTableId: this.fb.control(this.data?.lookupTableId),
      fractionalLength: this.fb.control(this.data?.fractionalLength),
      conditionSelectedId: this.fb.control(this.data?.conditionSelectedId),
      valueSelectedId: this.fb.control(this.data?.valueSelectedId),
      linkedEntityRefPropertyId: this.fb.control(this.data?.linkedEntityRefPropertyId),
    });
  }

  get nameControl(): FormControl {
    return this.formAddInputOutput.get('name') as FormControl;
  }

  get dataTypeControl(): FormControl {
    return this.formAddInputOutput.get('dataType') as FormControl;
  }

  get descriptionControl(): FormControl {
    return this.formAddInputOutput.get('description') as FormControl;
  }

  get linkedEntityPropertyIdControl(): FormControl {
    return this.formAddInputOutput.get('linkedEntityPropertyId') as FormControl;
  }

  get lookupTableControl(): FormControl {
    return this.formAddInputOutput.get('lookupTableId') as FormControl;
  }

  get fractionalLengthControl(): FormControl {
    return this.formAddInputOutput.get('fractionalLength') as FormControl;
  }

  get conditionControl(): FormControl {
    return this.formAddInputOutput.get('conditionSelectedId') as FormControl;
  }

  get valueControl(): FormControl {
    return this.formAddInputOutput.get('valueSelectedId') as FormControl;
  }

  get referenceControl(): FormControl {
    return this.formAddInputOutput.get('linkedEntityRefPropertyId') as FormControl;
  }

  onValueChangesParameterType() {
    this.parameterTypeControl.valueChanges.subscribe((parameterType) => {
      this.checkInputOutputNameExistsAsyncFn!.config!.params!.type = parameterType;
      this.getListDataType(parameterType);
      this.formAddInputOutput?.reset();
    });
  }

  onValueChangesDataType() {
    this.dataTypeControl.valueChanges.subscribe((dataType) => {
      this.getLinkedProperties(dataType);
      this.isShowConditionControl = false;
      this.isShowValueControl = false;
      this.isShowReferenceControl = false;
      this.linkedEntityPropertyIdControl.reset();
      this.lookupTableControl.reset();
      if (dataType === EntityPropertyType.Lookup) {
        this.lookupTableControl.enable();
      } else {
        this.lookupTableControl.disable();
      }

      this.fractionalLengthControl.reset();
      if (dataType === EntityPropertyType.Decimal) {
        this.fractionalLengthControl.enable();
      } else {
        this.fractionalLengthControl.disable();
      }
    });
  }

  onValueChangesLinkedProperty(event: Option) {
    const selectedItem = this.linkedPropertiesData.find((item) => item.entityPropertyId === event?.value);

    this.handleShowReferenceField(
      this.dataTypeControl?.value,
      this.linkedEntityPropertyIdControl?.value,
      this.lookupTableControl?.value,
      selectedItem?.linkedPropertyType === LinkedPropertyType.EntityReference,
    );

    this.handleShowConditionAndValueField(
      this.dataTypeControl?.value,
      this.linkedEntityPropertyIdControl?.value,
      selectedItem?.entityComponentType === EntityComponentType.List,
    );

    this.conditionControl.reset();
    this.valueControl.reset();
    this.referenceControl.reset();

    if (this.parameterTypeControl.value === ParameterType.Input) {
      if (this.dataTypeControl.value === EntityPropertyType.Decimal && this.linkedEntityPropertyIdControl.value) {
        this.fractionalLengthControl.enable();
      } else {
        this.fractionalLengthControl.disable();
      }
    }
  }

  handleShowConditionAndValueField(dataType: EntityPropertyType, linkedPropertyId: string, isShow: boolean = false) {
    this.isShowConditionControl = isShow;
    this.isShowValueControl = isShow;
    if (isShow) {
      this.calculationStore.dispatch(
        getPropertiesByLinkedIdRequest({
          dataType: dataType ?? this.dataTypeControl?.value,
          linkedPropertyId: linkedPropertyId ?? this.linkedEntityPropertyIdControl?.value,
        }),
      );
    }
  }

  handleShowReferenceField(
    dataType: EntityPropertyType,
    linkedPropertyId: string,
    lookupTableId: string,
    isShow: boolean = false,
  ) {
    this.isShowReferenceControl = isShow;
    if (isShow) {
      this.calculationStore.dispatch(
        getEntityReferenceByLinkedIdRequest({
          dataType: dataType ?? this.dataTypeControl?.value,
          linkedPropertyId: linkedPropertyId ?? this.linkedEntityPropertyIdControl?.value,
          lookupTableId: lookupTableId ?? null,
        }),
      );
    }
  }

  onValueChangesLookupTable() {
    this.lookupTableControl.valueChanges.pipe(filter((lookupTableId) => !!lookupTableId)).subscribe((lookupTableId) => {
      this.linkedEntityPropertyIdControl.reset();
      this.getLinkedProperties(this.dataTypeControl.value, lookupTableId);
    });
  }

  onValueChangeCondition(event?: Option) {
    this.handleValueSelectedList(event?.value);
  }

  getListDataType(parameterType?: ParameterType) {
    let listDataType: PropertyTypeLabel[] = [
      PropertyTypeLabel.Currency,
      PropertyTypeLabel.Text,
      PropertyTypeLabel.Decimal,
      PropertyTypeLabel['Whole Number'],
      PropertyTypeLabel.Date,
      PropertyTypeLabel.Percentage,
      PropertyTypeLabel['Date Time'],
    ];

    if (parameterType === ParameterType.Input) {
      listDataType = [...listDataType, PropertyTypeLabel.Lookup, PropertyTypeLabel.Binary];
    }
    this.listDataType = listDataType
      .map((dataType) => {
        return {
          value: dataType,
          displayValue: PropertyTypeLabel[dataType],
        };
      })
      .sort((a: any, b: any) => {
        if (a?.displayValue > b?.displayValue) {
          return 1;
        }
        return -1;
      });
  }

  getLinkedProperties(dataType: EntityPropertyType, lookupTableId?: string) {
    if (
      this.parameterTypeControl.value === ParameterType.Output ||
      isEmpty(dataType) ||
      (dataType === EntityPropertyType.Lookup && !lookupTableId)
    ) {
      this.linkedProperties = [];
      return;
    }
    this.calculationStore.dispatch(getLinkedPropertiesRequest({ dataType: dataType as EntityPropertyType, lookupTableId }));
  }

  selectLinkedPropertiesState() {
    this.calculationStore
      .pipe(select(selectLinkedPropertiesState), takeUntil(this.unsubscribe$))
      .subscribe((linkedProperties) => {
        this.linkedPropertiesData = linkedProperties;
        this.linkedProperties = deepClone(linkedProperties ?? []).map((p) => {
          return {
            value: p.entityPropertyId,
            displayValue: p.name,
            valueDescription: p.subtitle,
            extraData: {
              linkedPropertyType: p.linkedPropertyType,
            },
          };
        });
        const selectedItem = this.linkedPropertiesData.find(
          (item) => item.entityPropertyId === this.data?.linkedEntityPropertyId,
        );
        if (selectedItem?.entityComponentType === EntityComponentType.List) {
          this.handleShowConditionAndValueField(this.data?.dataType, this.data?.linkedEntityPropertyId, true);
        }
        if (selectedItem?.linkedPropertyType === LinkedPropertyType.EntityReference) {
          this.handleShowReferenceField(
            this.dataTypeControl?.value,
            this.linkedEntityPropertyIdControl?.value,
            this.lookupTableControl?.value,
            true,
          );
        }
      });
  }

  selectPropertiesByLinkedIdState() {
    this.calculationStore
      .pipe(select(selectPropertiesByLinkedIdState), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.listConditionData = data;
        if (this.data?.conditionSelectedId) {
          this.handleValueSelectedList(this.data?.conditionSelectedId);
        }
        const listData = deepClone(data ?? []).filter((item) => item.id !== this.linkedEntityPropertyIdControl?.value);
        this.listCondition = deepClone(listData ?? []).map((p) => {
          return {
            value: p.id,
            displayValue: p.name,
          };
        });
      });
  }
  selectEntityReferenceByLinkedIdState() {
    this.calculationStore
      .pipe(select(selectEntityReferenceByLinkedIdState), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.listReferenceData = data ?? [];
        const listData = deepClone(data ?? []).filter(
          (item) => item.entityPropertyId !== this.linkedEntityPropertyIdControl?.value,
        );
        this.listReference = deepClone(listData ?? []).map((p) => {
          return {
            value: p.entityPropertyId,
            displayValue: p.name,
            valueDescription: p.subtitle,
          };
        });
      });
  }

  handleValueSelectedList(condition: string) {
    const selectedItem = this.listConditionData.find((item) => item.id === condition);
    this.listValue = deepClone(selectedItem?.options ?? []).map((p: any) => {
      let optionId = p.id;
      if (p.type === LookupTableType.NoValue) {
        optionId = GUID_EMPTY;
      }
      if (p.type === LookupTableType.YesValue) {
        optionId = '00000000-0000-0000-0000-000000000001';
      }
      return {
        value: optionId,
        displayValue: p.text,
      };
    });
  }

  getListLookupTable() {
    const query: GetLookupTableQuery = {
      SortNames: 'Name',
      SortType: SortType.ASC,
    };
    this.calculationStore.dispatch(LookupTableActions.getLookupTableList({ query }));
    this.calculationStore
      .select(selectLookupTable)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.listLookupTable = deepClone(state.lookupTableList ?? [])
          .filter((lt) => lt.active)
          .map((lt) => {
            return {
              value: lt.id,
              displayValue: lt.name,
            };
          });
      });
  }

  onSubmit() {
    const body = deepClone(this.formAddInputOutput.getRawValue() as MasterInputOutput);
    body.type = this.parameterTypeControl.value;
    if (this.data?.id) {
      this.calculationStore.dispatch(editMasterInputOutputRequest({ id: this.data?.id, body }));
    } else {
      this.calculationStore.dispatch(addMasterInputOutputRequest({ body }));
    }
    this.dialogRef.close();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
