import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { PayStatus } from '../types/enums';

import {
  EditPaymentStatusInitRulesResponse,
  GetLastPayrollCyclesResponse,
  GetValidateChangeLastPayrollCycleRequest,
  GetValidateChangeLastPayrollCycleResponse,
  ReasonsResponse,
  SaveAdjustmentStatusRequest,
  SaveEditPaymentStatusRequest,
  ValidationOTPPeriodOverlappedResponse,
  ValidationOTPRunResponse,
  ValidationQDROLinkedResponse,
} from './models/edit-payment-status.model';

@Injectable()
export class EditPaymentStatusService {
  constructor(private readonly httpClient: HttpClient) {}

  getReasons(paymentStatus: PayStatus): Observable<ReasonsResponse> {
    return this.httpClient.get<ReasonsResponse>(
      `${environment.apiUrl}/new-payroll/payments/payment-status/${paymentStatus}/reasons`,
    );
  }

  getLastPayrollCycles(paymentInstructionId: string, benefitEndDate?: string): Observable<GetLastPayrollCyclesResponse> {
    let params = new HttpParams();
    if (benefitEndDate) {
      params = params.append('benefitEndDate', benefitEndDate);
    }
    return this.httpClient.get<GetLastPayrollCyclesResponse>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionId}/last-payroll-cycle`,
      { params },
    );
  }

  getEditPaymentStatusInitRules(paymentInstructionId: string): Observable<EditPaymentStatusInitRulesResponse> {
    return this.httpClient.get<EditPaymentStatusInitRulesResponse>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionId}/payment-status-validation`,
    );
  }

  getValidationQDROLinked(paymentInstructionId: string): Observable<ValidationQDROLinkedResponse> {
    return this.httpClient.get<ValidationQDROLinkedResponse>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionId}/qdro-linked-confirmation`,
    );
  }

  getValidationOTPPeriodOverlapped(paymentInstructionId: string): Observable<ValidationOTPPeriodOverlappedResponse> {
    return this.httpClient.get<ValidationOTPPeriodOverlappedResponse>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionId}/otp-overlap-period-validation`,
    );
  }

  getValidationOTPRun(
    paymentInstructionId: string,
    benefitTypeOptionId: string,
    isSuspend?: boolean,
  ): Observable<ValidationOTPRunResponse> {
    return this.httpClient.get<ValidationOTPRunResponse>(
      `${
        environment.apiUrl
      }/new-payroll/one-time-payment/validate-update-status/${paymentInstructionId}?benefitTypeOptionId=${benefitTypeOptionId}&isSuspend=${!!isSuspend}`,
    );
  }

  saveEditPaymentStatus(
    paymentInstructionId: string,
    body: SaveEditPaymentStatusRequest,
    isOTP?: boolean,
  ): Observable<any> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    if (isOTP) {
      return this.httpClient.put<any>(
        `${environment.apiUrl}/new-payroll/payments/one-time-paymen/${paymentInstructionId}/payment-status`,
        body,
        { context },
      );
    }
    return this.httpClient.put<any>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionId}/payment-status`,
      body,
      { context },
    );
  }

  saveAdjustmentStatus(body: SaveAdjustmentStatusRequest): Observable<any> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<any>(`${environment.apiUrl}/new-payroll/adjustment/update-status`, body, {
      context,
    });
  }

  getValidateChangeLastPayrollCycle(
    paymentInstructionId: string,
    request: GetValidateChangeLastPayrollCycleRequest,
  ): Observable<GetValidateChangeLastPayrollCycleResponse> {
    let params = new HttpParams();
    Object.entries(request).forEach(([key, value]) => {
      if (value) {
        params = params.append(key, value);
      }
    })
    return this.httpClient.get<GetValidateChangeLastPayrollCycleResponse>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionId}/last-payroll-cycle/validate`,
      { params },
    );
  }
}
