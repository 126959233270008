<div class="edit-employer-documents">
  <ptg-title-bar *ngIf="data.document" [name]="title" [onlyTitle]="true"></ptg-title-bar>
  <ptg-breadcrumb
    *ngIf="!data.document"
    [listBreadcrumbs]="listBreadcrumbs"
    (clickBreadcrumb)="onClickBreadcrumb()"
  ></ptg-breadcrumb>
  <ng-container *ngIf="entityType === EntityType.Participant">
    <ptg-overview-header-entity
      #overViewHeaderEntity
      [isShowChangeMemberDetailBtn]="false"
      [targetIdFromDialog]="targetId"
      [isSubHeader]="true"
    >
    </ptg-overview-header-entity>
  </ng-container>
  <ng-container *ngIf="entityType === EntityType.Employer">
    <ptg-employer-overview-header
      #overviewEmployerComponent
      [employerName]="data?.currentEntity?.employerName || ''"
      [isShowChangeEmployerDetailBtn]="false"
      [targetIdFromDialog]="targetId"
      [isShowCloseButton]="false"
    >
    </ptg-employer-overview-header>
  </ng-container>
  <div class="info-edit">
    <form [formGroup]="editForm" class="form">
      <div class="content-form">
        <div class="wrap-btn">
          <button mat-flat-button type="button" style="background-color: #2d6c71;color: #fff" (click)="onSaveBtnClicked()">Save</button>
          <button mat-flat-button type="button" style="background-color: #acabab;color: #333333" (click)="onCancel()">Cancel</button>
        </div>
        <div class="flex">
          <div *ngIf="isShowExistDocumentRadio" class="mb-4">
            <div>
              <ptg-radio-button
                [controlField]="editForm.get('isNewDocument')"
                [isObject]="true"
                [listOption]="existDocumentRadioOptionList"
                (changeValue)="onChangeExistDocumentRadio($event)"
              ></ptg-radio-button>
            </div>
          </div>
          <div [ngClass]="{ 'exist-document-type-container gap-4': existDocumentType, 'flex-1': !existDocumentType }">
            <div
              [ngClass]="{ 'exist-document-type': existDocumentType }"
              *ngIf="editForm.get('isNewDocument')?.value === null || editForm.get('isNewDocument')?.value === true"
            >
              <ng-container *ngIf="filesUpload.length <= 1; else noneEditable">
                <ptg-textbox
                  [controlField]="editForm.get('documentName')"
                  [hasLabel]="true"
                  [placeholder]="'Document Name'"
                  [isRequired]="!(filesUpload.length <= 1) && !isShowExistDocumentRadio"
                  [maxLength]="100"
                  customError="errMsgDocumentName"
                  errorAsync="Document Name already exists."
                  errorDuplicated="Document Name already exists."
                  [ngClass]="{
                    'has-error': editForm.get('documentName')?.touched && editForm.get('documentName')?.errors
                  }"
                ></ptg-textbox>
              </ng-container>
              <ng-template #noneEditable>
                <div class="none-edit pb-2">
                  <p class="title">Document Name</p>
                  <p class="value">-</p>
                </div>
              </ng-template>
            </div>
            <div
              [ngClass]="{ 'exist-document-type': existDocumentType }"
              *ngIf="editForm.get('isNewDocument')?.value === false"
              class="document-name"
            >
              <ptg-select
                (changeOptionValue)="onChangeDocumentName()"
                [controlField]="editForm.get('documentId')"
                [isMultipleLineOption]="true"
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="existDocumentDropdownOptionList"
                class="full-width"
                placeholder="Document Name"
              ></ptg-select>
              <mat-error
                *ngIf="
                  editForm.get('documentId')?.errors?.required === true && editForm.get('documentId')?.touched === true
                "
              >
                Document Name is required.
              </mat-error>
            </div>
            <div
              *ngIf="
                existDocumentType &&
                (editForm.get('isNewDocument')?.value === null || editForm.get('isNewDocument')?.value === true)
              "
              class="document-type-dropdown document-type-select"
            >
              <ptg-select
                placeholder="Document Type"
                [controlField]="editForm.get('type')"
                [listData]="documentTypeOptionList"
                [isOptionObj]="true"
              ></ptg-select>
              <mat-error
                *ngIf="
                  editForm.get('type')?.errors?.required === true &&
                  editForm.get('type')?.touched === true &&
                  isShowExistDocumentRadio
                "
              >
                Document Type is required.
              </mat-error>
            </div>
            <div
              *ngIf="existDocumentType && editForm.get('isNewDocument')?.value === false"
              class="document-type-dropdown document-type-textbox"
            >
              <ptg-textbox
                [controlField]="editForm.get('existingDocumentType')"
                [hasLabel]="true"
                [isDisabled]="true"
                placeholder="Document Type"
              ></ptg-textbox>
            </div>
            <div *ngIf="editForm.get('isNewDocument')?.value === false" class="select-button-area">
              <ptg-button
                (click)="addExistingDocument()"
                buttonName="Select"
                classInput="mat-raised-button primary"
              ></ptg-button>
            </div>
          </div>

          <!-- ParticipationDocumentType: Show if upload or edit Participant documents -->
          <div
            *ngIf="entityType == EntityType.Participant
              && !existDocumentType
              && (!data.document || isParticipantDocument)
              && !isUploadEditDocumentForServiceHistoryListRecord
            ">
            <ptg-select
              placeholder="Document Type"
              [controlField]="editForm.get('participantDocumentType')"
              [listData]="participantDocumentTypeOptionList"
              [isRequired]="true"
              [isOptionObj]="true"
            ></ptg-select>
          </div>

          <div
            *ngIf="(entityType == EntityType.Participant
            && !existDocumentType
            && data.document
            && !isParticipantDocument)
            || isUploadEditDocumentForServiceHistoryListRecord
          ">
            <div class="value-container">
              <span class="label truncate">
                Document Type
              </span>
              <div class="value-item">{{ 
                isUploadEditDocumentForServiceHistoryListRecord
                  ? ParticipantDocumentType[ParticipantDocumentType.Other]
                  : data.document?.documentTypeName
              }}</div>
            </div>
          </div>

          <!-- Employer/Muni DocumentType: Show if upload or edit Employer/Muni documents -->
          <div
            *ngIf="entityType == EntityType.Employer
             && !existDocumentType
             && (!data.document || isEmployerDocument)
           ">
            <ptg-select
              [controlField]="editForm.get('employerDocumentType')"
              [listData]="EMPLOYER_DOCUMENT_TYPE_OPTIONS"
              [isRequired]="true"
              [isOptionObj]="true"
              placeholder="Document Type"
            ></ptg-select>
          </div>

          <div class="flex-1 autocomplete-chip-section">
            <div class="auto-complete-section">
              <div class="select-container">
                <div class="select-tag">
                  <mat-form-field class="auto-complete" appearance="fill">
                    <mat-label role="presentation" class="tags">Tags</mat-label>
                    <input
                      class="custom-input"
                      type="text"
                      matInput
                      [formControl]="tagsAutoCompleteControl"
                      [matAutocomplete]="auto"
                      (blur)="validateTags()"
                      (focus)="tagsFocus($event)"
                      (keydown.enter)="$event.preventDefault()"
                    />
                    <span class="custom-arrow mat-select-arrow-wrapper">
                      <span
                        class="mat-select-arrow"
                        [ngClass]="{
                          'has-error': tagsAutoCompleteControl.errors !== null && tagsAutoCompleteControl?.touched
                        }"
                      >
                      </span>
                    </span>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagFn">
                      <mat-option *ngFor="let option of filteredByTypingTagOptions | async" [value]="option">
                        {{ option.displayValue }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        !tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync
                      "
                    >
                      Value must be selected from result list.
                    </mat-error>
                  </mat-form-field>
                  <p
                    class="add-new-hyperlink"
                    [ngClass]="{
                      'pt-3':
                        !tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync
                    }"
                  >
                    <span
                      [ngClass]="{ disabled: editForm.get('isNewDocument')?.value === false }"
                      aria-disabled="true"
                      (click)="addNewTags()"
                      >Add New Tag</span
                    >
                  </p>
                </div>
                <div class="chip-container">
                  <span class="add-chip-btn-section">
                    <button
                      aria-label="add chip"
                      mat-icon-button
                      role="presentation"
                      (click)="onAddNewChip()"
                      [disabled]="!tagsAutoCompleteControl.value || tagsAutoCompleteControl?.errors?.inValidAsync"
                    >
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </span>
                  <div class="tags-chip-list-section">
                    <mat-chip-list *ngIf="tagsChipListControl?.length">
                      <mat-chip
                        *ngFor="let item of tagsChipListControl.controls; index as i"
                        (removed)="onRemoveChip(item.value, i)"
                      >
                        <span class="chip-wrapper">
                          <span class="chip__txt--primary">
                            <span class="mr-1">{{ item.value.displayValue }}</span>
                          </span>
                        </span>
                        <button matChipRemove>
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1" style="margin-top: 20px">
            <ptg-input-area
              [controlField]="editForm.get('description')"
              [hasLabel]="true"
              placeholder="Description"
              [maxLength]="255"
              errorMaxLength="Exceed the 255 character limit."
            >
            </ptg-input-area>
          </div>
          <div class="flex-1">
            <ptg-checkbox
              *ngIf="data.currentEntity.overviewEntityId !== ENTITY_ORGANIZATION_GUID"
              [controlField]="editForm.get('showOnOverview')"
              [label]="labelShowOverview"
              [isDisabled]="editForm.get('isNewDocument')?.value === false"
            >
            </ptg-checkbox>
          </div>
          <div
            class="flex-1"
            *ngIf="!isEditDocumentFile && editForm.get('isNewDocument')?.value === null"
            style="margin-top: 20px"
          >
            <ptg-upload-multiple
              #fileDocument
              (onUploadAction)="uploadFile($event)"
              (deleteFile)="deleteFile()"
              [noUpload]="true"
              [isRequired]="true"
              [uploadButtonName]="'Upload document'"
              [accept]="acceptFile"
              [isMultiple]="data?.infoForm?.isUploadMultipleFile"
              errRequired="Document is required."
              [errDuplicated]="errDuplicated"
              [errMsg]="'Unsupported file name or file format.'"
              [checkPattern]="checkPattern"
            ></ptg-upload-multiple>
            <div class="error-message" *ngIf="isShowMessageErrorFilesControl">
              {{ errorMessageRequire }}
            </div>
          </div>
          <div
            class="flex-1 single-file-upload-area"
            *ngIf="editForm.get('isNewDocument')?.value === true"
            style="margin-top: 20px"
          >
            <ptg-upload
              #fileDocumentSingleUpload
              (onUploadAction)="uploadSingleFile($event)"
              (deleteFile)="deleteSingleUploadFile($event)"
              [accept]="acceptFileForSingleUpload"
              [checkPattern]="checkPatternForSingleUpload"
              [typeFile]="supportedFileTypesForSingleUpload"
              [fileSelected]="fileSelected"
              [controlField]="$any(singleFileUploadControl)"
              [noUpload]="false"
              errRequired="Upload Signed Documentation is required."
              class="m-0"
              [validateOnUpload]="true"
              [shouldEmitWhenClickUpload]="true"
              [isDisabled]="editForm.get('documentName')?.touched && editForm.get('documentName')?.pending"
            ></ptg-upload>
            <mat-error
              *ngIf="
                $any(singleFileUploadControl)?.errors?.required === true &&
                $any(singleFileUploadControl)?.touched === true
              "
            >
              {{
                isUsedForCalculationDocumentFeatures
                  ? 'Upload Signed Documentation is required.'
                  : 'Document is required.'
              }}
            </mat-error>
            <!-- Not use `$any(singleFileUploadControl)?.errors?.required` condition below because we just expect 1 Require Error Message show up at same time -->
            <mat-error
              *ngIf="
                isShowErrorMessage &&
                $any(singleFileUploadControl)?.touched === true &&
                !$any(singleFileUploadControl)?.errors?.required
              "
            >
              Upload Signed Documentation is required.
            </mat-error>
          </div>
          <div class="flex-1" *ngIf="isEditDocumentFile">
            <div *ngFor="let file of filesEdit" class="document-selected-container">
              <div class="document-selected-item">
                <div class="label">
                  <mat-icon>upload_file</mat-icon>
                  {{ file.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 document-location" *ngIf="isEditDocumentFile">
            <div class="title">Document Location</div>
            <p class="document-location-router">
              <span>
                <a [href]="documentLocationRouter" target="_blank">
                  {{ documentLocation }}
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="audit-info" *ngIf="data.document">
        <p>Uploaded Date: {{ uploadedDate }}</p>
        <p>Uploaded By: {{ data.document?.createdBy }}</p>
        <p>Latest Updated Date: {{ !!lastUpdatedDate ? lastUpdatedDate : uploadedDate }}</p>
        <p>Latest Updated By: {{ !!data.document?.updatedBy ? data.document?.updatedBy : data.document?.createdBy }}</p>
      </div>
    </form>
    <div *ngIf="isShowDocumentTable" class="p-6 mt-4 document-table">
      <ptg-panel [title]="documentTableTitle" [noPaddingBody]="true">
        <ng-template panelBody>
          <ptg-grid
            [columns]="documentColumns"
            [data]="filteredDocuments"
            [fitToParent]="true"
            [pageSize]="pageSize"
            [pageNumber]="pageNumber"
            [totalRecords]="documentList.length"
            [notFoundMessage]="notFoundMessage"
            [paginable]="true"
            (sortChange)="onSortDocumentsTable($event)"
            (pageChange)="onChangePage($event)"
          >
            <ng-template cellContent columnName="fileName" let-row>
              <div class="flex file-document-container">
                <span class="file-document-name" target="_blank" (click)="downloadSelectedFile(row)"
                  ><span class="truncate">{{ row?.fileName }}</span></span
                >
              </div>
            </ng-template>
            <ng-template cellContent columnName="action" let-row>
              <ptg-button buttonName="Remove" classInput="remove-button" (clickButton)="removeDocument(row)">
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-template>
          </ptg-grid>
        </ng-template>
      </ptg-panel>
    </div>
  </div>
</div>
