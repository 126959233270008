export enum ColumnType {
  Address,
  Binary,
  Decimal,
  DateTime,
  Icon,
  PhoneNumber,
  PersonName,
  RichText,
  Link,
  EntityReference,
  // Select,
  Text,
  // TextPlus,
  // RichText,
  // WholeNumber,
  // Icon,
  // Button,
  // GroupButton,
  // Image,
  // TextHTML,
  Identifier = 205,
}

export enum StatusRefund {
  Pending,
  Approved,
  Rejected,
}
