import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const initialPaymentConfigurationSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.initialPaymentConfiguration
);

export const getInitialPaymentConfigurationsSelector = createSelector(
  initialPaymentConfigurationSelector,
  (state) => state?.getInitialPaymentConfigurations
);

export const setInitialPaymentConfigurationsSelector = createSelector(
  initialPaymentConfigurationSelector,
  (state) => state?.setInitialPaymentConfigurations
);

