import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import { CardRelationshipDetail, MemberGraphViewData } from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class ParticipantRelationshipService {
  constructor(private httpClient: HttpClient) {}

  getParticipantRelationships(
    memberId: string,
    screenId: string
  ): Observable<MemberGraphViewData> {
    let params = new HttpParams();
    
    if (screenId) {
      params = params.append('ScreenId', screenId);
    }

    return this.httpClient.get<MemberGraphViewData>(
      `${environment.apiUrl}/entities/members/${memberId}/MemberGraphViewDataWithEntity`,
      { params }
    );
  }

  getCardRelationshipDetail(
    targetId:string,
    participantId: string,
    cardEntityType: number,
    screenId: string
  ): Observable<CardRelationshipDetail> {
    let params = new HttpParams();
    if (participantId) {
      params = params.append('ParticipantId', participantId);
    }
    if (screenId) {
      params = params.append('ScreenId', screenId);
    }
    params = params.append('CardEntityType', cardEntityType);
    
    return this.httpClient.get<CardRelationshipDetail>(
      `${environment.apiUrl}/entities/members/${targetId}/relationship-detail`,
      {
        params
      }
    );
  }
}
