import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as fromReducer from '@ptg-reducers';
import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { ACTION, DEFAULT_PAGE_SIZE, STATE, SortType } from '@ptg-shared/constance';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { showBanner } from '@ptg-shared/utils/common.util';
import { Sort } from '@angular/material/sort';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { combineLatest } from 'rxjs';
import { AddEditBuyBackMakeUpDialogComponent } from '../../components/add-edit-buyback-makeup-dialog/add-edit-buyback-makeup-dialog.component';
import { BuyBackMakeUpState, addBuyBackMakeUpSelector, clearAddBuyBackMakeUpStateAction, clearEditBuyBackMakeUpInterestFeesStateAction, clearEditBuyBackMakeUpStateAction, clearGetBuyBackMakeUpYearRecordStateAction, clearInitiateRemittanceBuyBackMakeUpStateAction, clearRemoveBuyBackMakeUpStateAction, editBuyBackMakeUpInterestFeesSelector, editBuyBackMakeUpSelector, getBuyBackMakeUpYearRecordAction, getBuyBackMakeUpYearRecordSelector, getEligibleYearAction, getEligibleYearSelector, getListBuyBackMakeUpAction, getListBuyBackMakeUpDetailAction, getListBuyBackMakeUpDetailSelector, getListBuyBackMakeUpSelector, initiateRemittanceBuyBackMakeUpAction, initiateRemittanceBuyBackMakeUpSelector, removeBuyBackMakeUpAction, removeBuyBackMakeUpSelector } from '../../store';
import { BuyBackMakeUpStatus, BuyBackMakeUpStatusMap, BuyBackMakeUpTableType } from '../../constants';
import { BuybackMakeup, BuybackMakeupDetail, BuybackMakeupYearRecord } from '../../services/models/buyback-makeup.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { EditBuyBackMakeUpInterestFeesComponent } from '../../components/edit-buyback-makeup-interest-fee/edit-buyback-makeup-interest-fees.component';
import { MenuItemSubTitle, MenuItemTitle } from '@ptg-member/constants';
import { DatePipe } from '@angular/common';
const PAGE_SIZE_CONST = '-ptg-buy-back-make-up-overview-pageSize';

@Component({
  selector: 'ptg-buyback-makeup',
  templateUrl: './buyback-makeup.component.html',
  styleUrls: ['./buyback-makeup.component.scss']
})
export class BuyBackMakeUpComponent extends BaseListComponent {
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Buyback/Make-up' }];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  memberId: string = '';

  eligibleYear: string | number = '-';

  currentBuyBackMakeUpId: string = '';
  currentBuyBackMakeUpStatusCode: BuyBackMakeUpStatus | null = null;

  buyBackMakeUpDetailTableName: string = 'Selected Payments & Calculation';
  buyBackMakeUpDetailTableData: BuybackMakeupDetail[] = [];
  totalBuyBackMakeUpDetail: number | any = 0;
  isBuyBackMakeUpDetailLoading?: boolean = false;
  noDataBuyBackMakeUpDetail: string = 'No Data to Display';
  pageBuyBackMakeUpDetailIndex: number = FIRST_PAGE;
  pageBuyBackMakeUpDetailSize: number = DEFAULT_PAGE_SIZE;
  buyBackMakeUpDetailSortInfo: Sort = {
    active: 'year',
    direction: 'desc',
  };

  buyBackMakeUpTableName: string = 'Buyback/Make-up History';
  buyBackMakeUpTableData: BuybackMakeup[] = [];
  totalBuyBackMakeUp: number | any = 0;
  isBuyBackMakeUpLoading?: boolean = false;
  noDataBuyBackMakeUp: string = 'No Data to Display';
  pageBuyBackMakeUpIndex: number = FIRST_PAGE;
  pageBuyBackMakeUpSize: number = DEFAULT_PAGE_SIZE;
  buyBackMakeUpSortInfo: Sort = {
    active: 'createdDate',
    direction: 'desc',
  };

  currentBuyBackMakeUpYearRecord: BuybackMakeupYearRecord | null = null;

  currentFund: any = {};
  defaultPageSize: number = 50;

  isEnableInitiateRemittanceBtn: boolean = false;
  isInitiating: boolean = false;
  initiateRemittenanceBtnName: string = 'Initiate Remittance';
  redirectMunicipalityId: string | null = null;

  allBuyBackMakeUpRecordsAreRemittanceInitiated: boolean = false;

  menuItemTitle: string = MenuItemTitle.BuybackMakeup;
  menuItemSubTitle: string = MenuItemSubTitle.PurchaseServices;

  columnsBuyBackMakeUpDetail: Column[] = [
    {
      name: 'year',
      header: {
        title: 'Year',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'type',
      header: {
        title: 'Type',
      },
      truncate: true,
      sortable: true,
      cell: (row) => {
        return row.type === 0 ? 'Buyback' : 'Make-up'
      },
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'dueDate',
      header: {
        title: 'Due Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'interestBegin',
      header: {
        title: 'Interest Begin',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'interestThru',
      header: {
        title: 'Interest Thru',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'pensionAmount',
      header: {
        title: 'Pension Amount',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
    {
      name: 'interestPercent',
      header: {
        title: 'Interest %',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '%',
        unitPosition: 'right'
      },
    },
    {
      name: 'interest',
      header: {
        title: 'Interest',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
    {
      name: 'cost',
      header: {
        title: 'Cost',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
    },
  ];

  columnsBuyBackMakeUp: Column[] = [
    {
      name: 'createdDate',
      header: {
        title: 'Created Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'numberOfPayments',
      header: {
        title: 'Number of Payments',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
    },
    {
      name: 'totalCostToBuyback',
      header: {
        title: 'Total Cost to Buyback',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
    {
      name: 'statusCode',
      header: {
        title: 'Status',
      },
      truncate: true,
      sortable: true,
      cell: (row) => BuyBackMakeUpStatusMap.get(row?.statusCode) ?? '',
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
    },
  ];
  currentRowIndex: number = -1;

  constructor(
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private store: Store<BuyBackMakeUpState>,
    private dialog: MatDialog,
    private router: Router,
    public datePipe: DatePipe
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFundAndRouteData();
    const buyBackMakeUpDetailPageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + BuyBackMakeUpTableType.DetailList + PAGE_SIZE_CONST));
    const buyBackMakeUpPageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + BuyBackMakeUpTableType.List + PAGE_SIZE_CONST));
    this.pageBuyBackMakeUpDetailSize = buyBackMakeUpDetailPageSizeInSession === 0 ? this.defaultPageSize : buyBackMakeUpDetailPageSizeInSession;
    this.pageBuyBackMakeUpSize = buyBackMakeUpPageSizeInSession === 0 ? this.defaultPageSize : buyBackMakeUpPageSizeInSession;

    // Handle when receive Eligible Year data response
    this.store
      .pipe(select(getEligibleYearSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data?.success) {
          this.eligibleYear = data?.payload?.eligibleYear ?? '-';
        }
      });
    this.getEligibleYear();

    // Handle when receive Buy Back Make Up data response
    this.store
      .pipe(select(getListBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isBuyBackMakeUpLoading = data?.isLoading;
          if (data?.success) {
            this.totalBuyBackMakeUp = data?.total || 0;
            this.buyBackMakeUpTableData = data?.payload?.map((item: BuybackMakeup) => {
              return {
                ...item,
                // Add formatter for fields here if need
                createdDate: this.datePipe.transform(getDateString(item.createdDate), 'MM/dd/yyyy'),
              }
            }) ?? [];

            // Call from 2nd time
            if (this.totalBuyBackMakeUp >= 1 && this.currentBuyBackMakeUpId) {
              // After retrieve data for Buy Back Make Up History table again, should get data of current Buy Back Make Up Year Record
              const currentRecordIndex = this.buyBackMakeUpTableData.findIndex(item => item.id === this.currentBuyBackMakeUpId);
              this.currentRowIndex = currentRecordIndex;

              if (currentRecordIndex > -1) {
                const currentRecord = this.buyBackMakeUpTableData[currentRecordIndex];

                // Call API get Buyback Makeup History again, case current Year Record still exist in list data
                if (currentRecord?.statusCode === BuyBackMakeUpStatus.Initiated) {
                  this.isEnableInitiateRemittanceBtn = true;
                } else {
                  this.isEnableInitiateRemittanceBtn = false;
                }

                this.currentBuyBackMakeUpId = currentRecord?.id;
                this.currentBuyBackMakeUpStatusCode = currentRecord?.statusCode ?? null;
                this.getBuyBackMakeUpDetailData();
              } else {
                // Call API get Buyback Makeup History again, case current Year Record not exist in list data anymore
                this.currentBuyBackMakeUpId = '';
              }

              this.allBuyBackMakeUpRecordsAreRemittanceInitiated = this.buyBackMakeUpTableData?.every(item => item.statusCode === BuyBackMakeUpStatus.RemittanceInitiated);
            }

            // Case 1st time enter screen, call Buy Back Make Up Detail for 1st record,
            // or when current Buyback Makeup Year Record not exist in list anymore, should retrieve data for 1st row
            if (this.totalBuyBackMakeUp >= 1 && !this.currentBuyBackMakeUpId) {
              this.currentRowIndex = 0;
              if (this.buyBackMakeUpTableData?.[0]?.statusCode === BuyBackMakeUpStatus.Initiated) {
                this.isEnableInitiateRemittanceBtn = true;
              } else {
                this.isEnableInitiateRemittanceBtn = false;
              }
              this.currentBuyBackMakeUpId = this.buyBackMakeUpTableData?.[0]?.id ?? '';
              this.currentBuyBackMakeUpStatusCode = this.buyBackMakeUpTableData?.[0]?.statusCode ?? null;
              this.getBuyBackMakeUpDetailData();
              this.allBuyBackMakeUpRecordsAreRemittanceInitiated = this.buyBackMakeUpTableData?.every(item => item.statusCode === BuyBackMakeUpStatus.RemittanceInitiated);
            }
          }
        }
      });
    this.getBuyBackMakeUpData();

    // Handle when receive Buy Back Make Up Detail data response
    this.store
      .pipe(select(getListBuyBackMakeUpDetailSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isBuyBackMakeUpDetailLoading = data?.isLoading;
          if (data?.success) {
            this.totalBuyBackMakeUpDetail = data?.total || 0;
            this.buyBackMakeUpDetailTableData = data?.payload?.map((item: BuybackMakeupDetail) => {
              return {
                ...item,
                // Add formatter for fields here if need
                
                // -> No transform Time Zone for Interest Begin / Thru Date, Due Date yet
                // dueDate: this.datePipe.transform(getDateString(item.dueDate as string), 'MM/dd/yyyy'),
                // interestBegin: this.datePipe.transform(getDateString(item.interestBegin as string), 'MM/dd/yyyy'),
                // interestThru: this.datePipe.transform(getDateString(item.interestThru as string), 'MM/dd/yyyy'),
              }
            }) ?? [];
          }
        }
      });

    // Handle after delete Buy Back Make Up
    this.store
      .pipe(select(removeBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((removeBuyBackMakeUpState) => {
        if (removeBuyBackMakeUpState) {
          showBanner.call(this, removeBuyBackMakeUpState?.state, 'Buyback/Make-up record', removeBuyBackMakeUpState?.action);
          this.store.dispatch(clearRemoveBuyBackMakeUpStateAction());
          if (removeBuyBackMakeUpState.action === ACTION.REMOVE && removeBuyBackMakeUpState?.state === STATE.SUCCESS) {
            if (this.pageBuyBackMakeUpIndex !== 1 && this.buyBackMakeUpTableData.length <= 1) {
              this.pageBuyBackMakeUpIndex = this.pageBuyBackMakeUpIndex - 1;
            }
            this.clearBuyBackMakeUpSectionToDefault();
            this.getBuyBackMakeUpData();
            this.getEligibleYear();
          }
        }
      });

    // Handle after Add Buy Back Make Up in Overview screen
    this.store
      .pipe(select(addBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((addBuyBackMakeUpState) => {
        if (addBuyBackMakeUpState) {
          if (!addBuyBackMakeUpState?.errorMsg) {
            showBanner.call(this, addBuyBackMakeUpState?.state?.state, 'Buyback/Make-up record', addBuyBackMakeUpState?.state?.action);

            if (addBuyBackMakeUpState?.state?.state === STATE.SUCCESS) {
              this.clearBuyBackMakeUpSectionToDefault();
              this.getBuyBackMakeUpData();
              this.getEligibleYear();
            }
          }
          this.store.dispatch(clearAddBuyBackMakeUpStateAction());
        }
      });

    // Handle after Edit Buy Back Make Up in Overview screen
    this.store
      .pipe(select(editBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((editBuyBackMakeUpState) => {
        if (editBuyBackMakeUpState) {
          if (!editBuyBackMakeUpState?.errorMsg) {
            showBanner.call(this, editBuyBackMakeUpState?.state?.state, 'Buyback/Make-up record', editBuyBackMakeUpState?.state?.action);

            if (editBuyBackMakeUpState?.state?.state === STATE.SUCCESS) {
              this.clearBuyBackMakeUpSectionToDefault();
              this.getBuyBackMakeUpData();
              this.getEligibleYear();
            }
          }
          this.store.dispatch(clearEditBuyBackMakeUpStateAction());
        }
      });

    // Handle when receive Buy Back Make Up Year Record response
    this.store
      .pipe(select(getBuyBackMakeUpYearRecordSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          if (data?.success && data?.payload?.buybackMakeupYearRecord) {
            this.currentBuyBackMakeUpYearRecord = data?.payload?.buybackMakeupYearRecord;
            this.openEditBuyBackMakeUpInterestFeesDialog();
          }
          this.store.dispatch(clearGetBuyBackMakeUpYearRecordStateAction());
        }
      });

    // Handle after Edit Buy Back Make Up Interest Fees in Overview screen
    this.store
      .pipe(select(editBuyBackMakeUpInterestFeesSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((editBuyBackMakeUpInterestFeesState) => {
        if (editBuyBackMakeUpInterestFeesState) {
          console.log(editBuyBackMakeUpInterestFeesState);
          if (!editBuyBackMakeUpInterestFeesState?.errorMsg) {
            showBanner.call(this, editBuyBackMakeUpInterestFeesState?.state?.state, 'Buyback/Make-up Interest Fees', editBuyBackMakeUpInterestFeesState?.state?.action);

            if (editBuyBackMakeUpInterestFeesState?.state?.state === STATE.SUCCESS) {
              this.getBuyBackMakeUpData();
            }
          }
          if (editBuyBackMakeUpInterestFeesState?.state?.state === STATE.FAIL && editBuyBackMakeUpInterestFeesState?.errorMsg) {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: editBuyBackMakeUpInterestFeesState?.errorMsg ?? 'Message',
                type: ConfirmType.Warning,
                title: 'Error',
                cancelButtonTitle: 'Close',
                hideConfirmButton: true,
              }
            });
          }
          this.currentBuyBackMakeUpYearRecord = null;
          this.store.dispatch(clearEditBuyBackMakeUpInterestFeesStateAction());
        }
      });

    // Handle after Initiate Remittance Buy Back Make Up
    this.store
      .pipe(select(initiateRemittanceBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((initiateRemittanceBuyBackMakeUpState) => {
        if (this.redirectMunicipalityId && !initiateRemittanceBuyBackMakeUpState) {
          // Redirect to Remittance History screen if success
          const municipalityIdUsedForRedirect = this.redirectMunicipalityId;
          this.redirectMunicipalityId = null;
          this.isEnableInitiateRemittanceBtn = false;
          this.router.navigateByUrl(`/employer/remittance-submission-history/${municipalityIdUsedForRedirect}`);
        }
        if (initiateRemittanceBuyBackMakeUpState) {
          this.isInitiating = false;
          if (initiateRemittanceBuyBackMakeUpState?.state?.state === STATE.FAIL && initiateRemittanceBuyBackMakeUpState?.errorMsg) {
            // Show popup of error message if failed by common root causes with specific error
            this.handleShowInitiateErrorMessagePopup(initiateRemittanceBuyBackMakeUpState?.errorMsg, ConfirmType.Warning, 'Error', 'Close');
          }

          if (initiateRemittanceBuyBackMakeUpState?.state?.state === STATE.FAIL && !initiateRemittanceBuyBackMakeUpState?.errorMsg) {
            // Show popup of error message if failed by common root causes without specific error
            const customMessage = 'An unexpected error occurred. Please try again later.';
            this.handleShowInitiateErrorMessagePopup(customMessage, ConfirmType.Warning, 'Error', 'Close');
          }

          if (initiateRemittanceBuyBackMakeUpState?.state?.state === STATE.SUCCESS) {
            this.redirectMunicipalityId = initiateRemittanceBuyBackMakeUpState?.municipalityId ?? null;

            // Show popup of error message if failed by business root causes
            if (initiateRemittanceBuyBackMakeUpState?.messageError) {
              this.handleShowInitiateErrorMessagePopup(initiateRemittanceBuyBackMakeUpState?.messageError, ConfirmType.Warning, 'Error', 'Close');
            }
          }
          this.store.dispatch(clearInitiateRemittanceBuyBackMakeUpStateAction());
        }
      });
  }

  handleShowInitiateErrorMessagePopup(content: string, type: ConfirmType, title: string, buttonContent: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: content,
        type: type,
        title: title,
        cancelButtonTitle: buttonContent,
        hideConfirmButton: true,
      }
    });
  }

  getCurrentFundAndRouteData() {
    combineLatest([
      this.route.params,
      this.store.select(fromReducer.selectCurrentFundState),
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([params, currentFund]) => {
      this.memberId = params.memberId;
      this.currentFund = currentFund;
      this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
    });
  }

  getEligibleYear() {
    this.store.dispatch(
      getEligibleYearAction({
        request: {
          memberId: this.memberId,
        },
      })
    );
  }

  getBuyBackMakeUpData() {
    let sortType = SortType.DESC;
    let sortNames = ''
    if (this.buyBackMakeUpSortInfo?.active && this.buyBackMakeUpSortInfo.direction) {
      sortNames = capitalizeFirstLetter(this.buyBackMakeUpSortInfo.active);
      sortType = this.buyBackMakeUpSortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }

    this.store.dispatch(
      getListBuyBackMakeUpAction({
        request: {
          memberId: this.memberId,
          pageNumber: this.pageBuyBackMakeUpIndex,
          pageSize: this.pageBuyBackMakeUpSize,
          sortNames,
          sortType,
        },
      })
    );
  }

  onChangeSortBuyBackMakeUp(event: Sort) {
    this.clearBuyBackMakeUpSectionToDefault();
    this.buyBackMakeUpSortInfo = event;
    this.getBuyBackMakeUpData();
  }

  onChangePageBuyBackMakeUp(event: PageEvent) {
    this.clearBuyBackMakeUpSectionToDefault();
    this.pageBuyBackMakeUpSize = event.pageSize;
    this.pageBuyBackMakeUpIndex = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + BuyBackMakeUpTableType.List + PAGE_SIZE_CONST, event.pageSize.toString());
    this.getBuyBackMakeUpData();
  }

  onRemoveBuyBackMakeUp(row: BuybackMakeup) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Buyback/Make-up',
        text: 'Are you sure to remove this Buyback/Make-up record?',
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(removeBuyBackMakeUpAction({
          memberId: this.memberId,
          buyBackMakeUpId: row?.id,
        }))
      }
    });
  }

  clearBuyBackMakeUpSectionToDefault() {
    this.isEnableInitiateRemittanceBtn = false;
    this.currentBuyBackMakeUpId = '';
    this.currentBuyBackMakeUpStatusCode = null;
    this.buyBackMakeUpDetailTableData = [];
    this.totalBuyBackMakeUpDetail = 0;
    this.isBuyBackMakeUpDetailLoading = false;
    this.buyBackMakeUpDetailSortInfo = {
      active: 'year',
      direction: 'desc',
    };
    this.pageBuyBackMakeUpDetailSize = DEFAULT_PAGE_SIZE;
    this.pageBuyBackMakeUpDetailIndex = FIRST_PAGE;
    sessionStorage.setItem(this.currentFund.key + BuyBackMakeUpTableType.DetailList + PAGE_SIZE_CONST, DEFAULT_PAGE_SIZE.toString());
  }

  getBuyBackMakeUpDetailData() {
    let sortType = SortType.DESC;
    let sortNames = ''
    if (this.buyBackMakeUpDetailSortInfo?.active && this.buyBackMakeUpDetailSortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.buyBackMakeUpDetailSortInfo?.active);
      sortType = this.buyBackMakeUpDetailSortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(
      getListBuyBackMakeUpDetailAction({
        request: {
          memberId: this.memberId,
          buyBackMakeUpId: this.currentBuyBackMakeUpId,
          pageNumber: this.pageBuyBackMakeUpDetailIndex,
          pageSize: this.pageBuyBackMakeUpDetailSize,
          sortNames,
          sortType,
        },
      })
    );
  }

  onClickBuyBackMakeUp(row: BuybackMakeup) {
    this.isEnableInitiateRemittanceBtn = false;
    if (row?.statusCode === BuyBackMakeUpStatus.Initiated) {
      this.isEnableInitiateRemittanceBtn = true;
    }
    if (row?.id !== this.currentBuyBackMakeUpId) {
      this.clearBuyBackMakeUpSectionToDefault();
      if (row?.statusCode === BuyBackMakeUpStatus.Initiated) {
        this.isEnableInitiateRemittanceBtn = true;
      }
      this.currentBuyBackMakeUpId = row.id;
      this.isBuyBackMakeUpDetailLoading = true;
      this.currentBuyBackMakeUpStatusCode = row.statusCode;
      this.getBuyBackMakeUpDetailData();
    }
  }

  onChangeSortBuyBackMakeUpDetail(event: Sort) {
    this.buyBackMakeUpDetailSortInfo = event;
    this.getBuyBackMakeUpDetailData();
  }

  onChangePageBuyBackMakeUpDetail(event: PageEvent) {
    this.pageBuyBackMakeUpDetailSize = event.pageSize;
    this.pageBuyBackMakeUpDetailIndex = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + BuyBackMakeUpTableType.DetailList + PAGE_SIZE_CONST, event.pageSize.toString());
    this.getBuyBackMakeUpDetailData();
  }

  openAddEditBuyBackMakeUpDialog(row?: BuybackMakeup) {
    this.dialog.open(AddEditBuyBackMakeUpDialogComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        memberId: this.memberId,
        buyBackMakeUpId: row?.id || null,
      },
    });
  }

  handleEditBuybackMakeupYearRecord(row: BuybackMakeupDetail) {
    if (this.currentBuyBackMakeUpStatusCode !== BuyBackMakeUpStatus.Initiated) {
      // Show popup of error message if current Buyback Make-up record status is not Initiated
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: '“Buyback/Makeup record is not in ‘initiated’ status, so cannot edit”',
          type: ConfirmType.Warning,
          title: 'Error',
          cancelButtonTitle: 'OK',
          hideConfirmButton: true,
        }
      });
      return;
    }
    this.store.dispatch(
      getBuyBackMakeUpYearRecordAction({
        memberId: this.memberId,
        buyBackMakeUpId: row.buybackMakeupId,
        yearRecordId: row.id
      })
    );
  }

  openEditBuyBackMakeUpInterestFeesDialog() {
    this.dialog.open(EditBuyBackMakeUpInterestFeesComponent, {
      panelClass: ['edit-popup', 'edit-buyback-makeup-interest-fees-dialog'],
      disableClose: true,
      height: 'auto',
      minWidth: '910px',
      autoFocus: false,
      data: {
        memberId: this.memberId,
        currentBuybackMakeupYearRecord: this.currentBuyBackMakeUpYearRecord,
      }
    });
  }

  onClickInitiateRemittenance() {
    this.isInitiating = true;
    this.store.dispatch(
      initiateRemittanceBuyBackMakeUpAction({
        memberId: this.memberId,
        buybackMakeupId: this.currentBuyBackMakeUpId,
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
