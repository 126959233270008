<div class="list-display">
  <div class="header">
    <mat-tab-group [(selectedIndex)]="selectedTabPayment" (selectedTabChange)="onSelectedTabChange($event)">
      <ng-container *ngFor="let tab of TabPaymentList">
        <mat-tab label="{{ tab?.name }}"> </mat-tab>
      </ng-container>
    </mat-tab-group>
    <div class="flex" *ngIf="!isLoading && selectedTabPayment === TabPaymentInfo.Payment">
      <ng-container *ptgCheckPermission="PERMISSION_KEY.ONE_TIME_PAYMENTS">
        <div *ngIf="selectedHeaderBenefit" class="btn-add" (click)="openAddDialog()">
          <mat-icon>add</mat-icon>
          <span class="btn-title">New One-Time</span>
        </div>
      </ng-container>
      <button
        *ngIf="paymentInstructionDetailComponentService.getShowOptButton | async"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Icon-button with a menu"
        class="group-button ignore-row"
      >
        <mat-icon class="ignore-row">more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="payment-instruction-detail__info-tab--sub-menu">
        <div class="flex flex-col">
          <ptg-button
            *ngIf="isShowDetailedAdjustment"
            classInput="text-only"
            (clickButton)="onOpenDetailedAdjustment()"
          >
            <span class="flex items-center gap-2">
              <mat-icon>add</mat-icon>
              <span>Detailed Adjustment</span>
            </span>
          </ptg-button>

          <ptg-button classInput="text-only" (clickButton)="onCreateGeneralAdjustment()">
            <span class="flex items-center gap-2">
              <mat-icon>add</mat-icon>
              <span>General Adjustment</span>
            </span>
          </ptg-button>

          <ptg-button *ngIf="isShowReverseAdjustment" classInput="text-only" (clickButton)="onOpenReverseAdjustment()">
            <span class="flex items-center gap-2">
              <mat-icon>add</mat-icon>
              <span>Reverse Adjustment</span>
            </span>
          </ptg-button>

          <ptg-button *ngIf="isShowOffsetButton" classInput="text-only" (clickButton)="onOpenOffsetDeduction()">
            <span class="flex items-center gap-2">
              <mat-icon>add</mat-icon>
              <span>Offset Deduction</span>
            </span>
          </ptg-button>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="grid-wrapper">
    <ptg-grid
      [data]="selectedTabPayment === TabPaymentInfo.InstructionHistory ? instructionHistoryData : paymentInfoTabData"
      [columns]="columns"
      [isLoading]="isLoading"
      [totalRecords]="totalRecords"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      (pageChange)="changePaging($event)"
      (rowClick)="selectRow($event)"
      [(currentRowIndex)]="currentRowIndex"
      [fitToParent]="true"
      [errorMessage]="errorMessage"
      [notFoundMessage]="
        'No ' + (selectedTabPayment === TabPaymentInfo.InstructionHistory ? 'History' : 'Payment') + ' to Display'
      "
    >
      <ng-template cellContent columnName="gross" let-row>
        <span class="truncate">{{ row?.gross ?? 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</span>
      </ng-template>
      <ng-template cellContent columnName="totalDeduction" let-row>
        <span class="truncate">{{
          row?.totalDeduction ?? 0 | numberLocalDecimal: '$' : { accountingFormat: true }
        }}</span>
      </ng-template>
      <ng-template cellContent columnName="netPayment" let-row>
        <span class="truncate">{{ row?.netPayment ?? 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</span>
      </ng-template>
      <ng-template
        *ngIf="selectedTabPayment !== TabPaymentInfo.InstructionHistory"
        cellContent
        columnName="note"
        let-row
      >
        <span class="truncate">
          <mat-icon *ngIf="row.isNoted" class="note-icon">note-icon</mat-icon>
        </span>
      </ng-template>
      <ng-template cellContent columnName="calculation" let-row>
        <span
          (click)="onClickNavigator(row.calculationId, row?.calculationBenefitId, row?.relatedMemberId)"
          class="truncate"
        >
          <mat-icon
            *ngIf="
              row.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment &&
              row.calculationId &&
              row.calculationId !== GUID_EMPTY
            "
            class="note-icon"
            >calculate</mat-icon
          >
        </span>
      </ng-template>
    </ptg-grid>
  </div>
</div>
