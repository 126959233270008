<div class="earning-detail-container">
  <div class="earning-detail-header">
    <div class="left-title">
      <span class="name">{{ title }}</span>
    </div>
  </div>
  <p *ngIf="data.deductionItem?.length" class="pt-4 pb-6 text-describe">
     {{singleLineText}}
  </p>
  <div class="earning-detail-content">
    <div class="w-full flex">
      <div class="label w-1/2"></div>
      <div class="label top-label w-1/2 flex justify-end ">
        {{ data?.isAdjustmentType ? 'Amount' : 'Current' }}
      </div>
      <div class="label top-label  w-1/2 flex justify-end" *ngIf="data?.isLinkToAdjustment">Net Amount</div>
      <div class="label top-label  w-1/2 flex justify-end" *ngIf="!data?.isAdjustmentType && !data.deductionItem?.length">YTD</div>
    </div>
    <div class="column-value flex flex-col gap-4 pb-4">
      <ng-container *ngIf="data.deductionItem?.length">
        <ng-container *ngFor="let item of deductionAsFundingSource">
          <div class="title">{{ DeductionTypeRevert[item.deductionType] }}</div>
          <ng-container *ngFor="let sub of item.deductionSubTypes">
            <div class="w-full flex">
              <div class="label w-1/2">{{ (sub.deductionCode ? sub.deductionCode + ' - ': '') + sub.subTypeName }}</div>
              <ng-container
                [ngTemplateOutlet]="amountTemplate"
                [ngTemplateOutletContext]="{amount: sub?.amount}">
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="amountTemplate"
                [ngTemplateOutletContext]="{ amount: sub?.netAmount, isHidden: !data?.isLinkToAdjustment }"
              >
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngFor="let sub of item.courtOrderDeductions">
            <div class="w-full flex">
              <div class="label w-1/2" [tooltip]="customTooltip">{{ sub.caseNumber }}</div>
              <ng-template #customTooltip>
                <div>
                  Court Order: <b>{{ sub.caseNumber }}</b>
                </div>
                <div>
                  Payee: <b>{{ sub.payee }}</b>
                </div>
              </ng-template>
              <ng-container
                [ngTemplateOutlet]="amountTemplate"
                [ngTemplateOutletContext]="{amount: sub?.amount}">
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="amountTemplate"
                [ngTemplateOutletContext]="{ amount: sub?.netAmount, isHidden: !data?.isLinkToAdjustment }">
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!data.deductionItem?.length">
        <div *ngIf="fundingSources?.length" class="title">{{ titleFunding }}</div>
        <ng-container *ngFor="let fs of fundingSources">
          <div class="w-full flex">
            <div class="label w-1/2">{{ fs.name }}</div>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{amount: fs?.amount}">
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{amount: fs?.netAmount, isHidden: !data?.isLinkToAdjustment}">
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{amount: fs?.ytd, isHidden: data?.isAdjustmentType}">
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="adjustmentFundings?.length" class="title">{{ titleAdjustment }}</div>
        <ng-container *ngFor="let af of adjustmentFundings">
          <div class=" w-full flex">
            <div class="column-value w-1/2">
              <div class="label">{{ af.name }}</div>
              <div class="range-date-content" *ngIf="af.startDate || af.endDate">
                <span>{{ af.startDate | date: 'MM/dd/yyyy' }}</span>
                <span> - </span>
                <span>{{ af.endDate | date: 'MM/dd/yyyy' }}</span>
              </div>
            </div>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{amount: af?.amount}">
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{amount: af?.netAmount, isHidden: !data?.isLinkToAdjustment}">
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{amount: af?.ytd, isHidden: data?.isAdjustmentType}">
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="gross-value w-full flex py-4">
      <div class="label gross-label w-1/2 flex">Gross Payment</div>
      <ng-container
        [ngTemplateOutlet]="amountTemplate"
        [ngTemplateOutletContext]="{amount: grossAmount?.amountCurrent}">
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="amountTemplate"
        [ngTemplateOutletContext]="{amount: grossAmount?.amountNet, isHidden: !data.isLinkToAdjustment}">
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="amountTemplate"
        [ngTemplateOutletContext]="{amount: grossAmount?.amountYTD, isHidden: data?.isAdjustmentType || data.deductionItem?.length}">
      </ng-container>
    </div>
  </div>
  <div class="earning-detail-footer">
    <ptg-button (click)="onClose()" classInput="tertiary" buttonName="Close"></ptg-button>
  </div>
</div>

<ng-template #amountTemplate let-amount="amount" let-isHidden="isHidden">
  <div class="flex w-1/2 justify-end value" *ngIf="!isHidden">
    {{ (amount | numberLocalDecimal: '$':{accountingFormat: true}) || '-' }}
  </div>
</ng-template>
