import { createSelector } from '@ngrx/store';

import { transactionRegisterFeatureSelector } from './selector';

export const registerSelector = createSelector(
  transactionRegisterFeatureSelector,
  (state) => state?.register
);
export const createRejectedTransactionsSelector = createSelector(
  transactionRegisterFeatureSelector,
  (state) => state?.register.createRejectedTransactions
);
export const getVoidedReasonsSelector = createSelector(
  transactionRegisterFeatureSelector,
  (state) => state?.register.getVoidedReasons
);
export const getVoidedReasonDetailsSelector = createSelector(
  transactionRegisterFeatureSelector,
  (state) => state?.register.getVoidedReasonDetails
);
export const createReissueTransactionsSelector = createSelector(
  transactionRegisterFeatureSelector,
  (state) => state?.register.createReissueTransactions
);
export const getPayrollBenefitTypesSelector = createSelector(
  transactionRegisterFeatureSelector,
  (state) => state?.register.getPayrollBenefitTypes
);
