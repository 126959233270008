import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { GetPayrollSettingsResponse } from '../../services/models';
import {
  getPayrollSettingsAction,
  getPayrollSettingsSuccessAction,
  getPayrollSettingsFailureAction,
  clearGetPayrollSettingsStateAction,
} from '../actions';

export interface PayrollSettingsState {
  getPayrollSettings?: BaseActionState<GetPayrollSettingsResponse>;
}

const initialState: PayrollSettingsState = {};

export const payrollSettingsReducer = createReducer(
  initialState,
  on(getPayrollSettingsAction, (state) => ({
    ...state,
    getPayrollSettings: {
      isLoading: true,
    },
  })),
  on(getPayrollSettingsSuccessAction, (state, { response }) => ({
    ...state,
    getPayrollSettings: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPayrollSettingsFailureAction, (state, { error }) => ({
    ...state,
    getPayrollSettings: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPayrollSettingsStateAction, (state) => ({
    ...state,
    getPayrollSettings: undefined,
  })),
);
