import { createAction, props } from '@ngrx/store';
import { 
  GetMemberAuditTrailsCardInfoRequest,
  GetMemberAuditTrailsCardInfoResponse,
  GetMemberAuditTrailsListRequest, 
  GetMemberAuditTrailsListResponse, 
  GetMemberAuditTrailsProfileInfoRequest, 
  GetMemberAuditTrailsProfileInfoResponse, 
  GetMemberAuditTrailsSearchNameRequest, 
  GetMemberAuditTrailsSearchNameResponse, 
  GetMemberAuditTrailsSearchUserRequest, 
  GetMemberAuditTrailsSearchUserResponse, 
  GetMemberAuditTrailsSectionListRequest, 
  GetMemberAuditTrailsSectionListResponse,
} from '@ptg-member/types/models/member-audit-trails.model';

// Get Member Audit Trails List
export const getMemberAuditTrailsListAction = createAction(
  '[GetMemberAuditTrailsList/API] Send Request',
  props<{ request: GetMemberAuditTrailsListRequest }>()
);
export const getMemberAuditTrailsListSuccessAction = createAction(
  '[GetMemberAuditTrailsList/API] Success',
  props<{ response: GetMemberAuditTrailsListResponse }>()
);
export const getMemberAuditTrailsListUpFailureAction = createAction(
  '[GetMemberAuditTrailsList/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetMemberAuditTrailsListStateAction = createAction(
  '[GetMemberAuditTrailsList] Clear'
);

// Get Member Audit Trails Section List
export const getMemberAuditTrailsSectionListAction = createAction(
  '[GetMemberAuditTrailsSectionList/API] Send Request',
  props<{ request: GetMemberAuditTrailsSectionListRequest }>()
);
export const getMemberAuditTrailsSectionListSuccessAction = createAction(
  '[GetMemberAuditTrailsSectionList/API] Success',
  props<{ response: GetMemberAuditTrailsSectionListResponse }>()
);
export const getMemberAuditTrailsSectionListFailureAction = createAction(
  '[GetMemberAuditTrailsSectionList/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetMemberAuditTrailsSectionListStateAction = createAction(
  '[GetMemberAuditTrailsSectionList] Clear'
);

// Get Member Audit Trails Card Info
export const getMemberAuditTrailsCardInfoAction = createAction(
  '[GetMemberAuditTrailsCardInfo/API] Send Request',
  props<{ request: GetMemberAuditTrailsCardInfoRequest }>()
);
export const getMemberAuditTrailsCardInfoSuccessAction = createAction(
  '[GetMemberAuditTrailsCardInfo/API] Success',
  props<{ response: GetMemberAuditTrailsCardInfoResponse }>()
);
export const getMemberAuditTrailsCardInfoFailureAction = createAction(
  '[GetMemberAuditTrailsCardInfo/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetMemberAuditTrailsCardInfoStateAction = createAction(
  '[GetMemberAuditTrailsCardInfo] Clear'
);

// Get Member Audit Trails Profile Info
export const getMemberAuditTrailsProfileInfoAction = createAction(
  '[GetMemberAuditTrailsProfileInfo/API] Send Request',
  props<{ request: GetMemberAuditTrailsProfileInfoRequest }>()
);
export const getMemberAuditTrailsProfileInfoSuccessAction = createAction(
  '[GetMemberAuditTrailsProfileInfo/API] Success',
  props<{ response: GetMemberAuditTrailsProfileInfoResponse }>()
);
export const getMemberAuditTrailsProfileInfoFailureAction = createAction(
  '[GetMemberAuditTrailsProfileInfo/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetMemberAuditTrailsProfileInfoStateAction = createAction(
  '[GetMemberAuditTrailsProfileInfo] Clear'
);

// Get Member Audit Trails Search Name
export const getMemberAuditTrailsSearchNameAction = createAction(
  '[GetMemberAuditTrailsSearchName/API] Send Request',
  props<{ request: GetMemberAuditTrailsSearchNameRequest }>()
);
export const getMemberAuditTrailsSearchNameSuccessAction = createAction(
  '[GetMemberAuditTrailsSearchName/API] Success',
  props<{ response: GetMemberAuditTrailsSearchNameResponse }>()
);
export const getMemberAuditTrailsSearchNameFailureAction = createAction(
  '[GetMemberAuditTrailsSearchName/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetMemberAuditTrailsSearchNameStateAction = createAction(
  '[GetMemberAuditTrailsSearchName] Clear'
);

// Get Member Audit Trails Search User
export const getMemberAuditTrailsSearchUserAction = createAction(
  '[GetMemberAuditTrailsSearchUser/API] Send Request',
  props<{ request: GetMemberAuditTrailsSearchUserRequest }>()
);
export const getMemberAuditTrailsSearchUserSuccessAction = createAction(
  '[GetMemberAuditTrailsSearchUser/API] Success',
  props<{ response: GetMemberAuditTrailsSearchUserResponse }>()
);
export const getMemberAuditTrailsSearchUserFailureAction = createAction(
  '[GetMemberAuditTrailsSearchUser/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetMemberAuditTrailsSearchUserStateAction = createAction(
  '[GetMemberAuditTrailsSearchUser] Clear'
);
