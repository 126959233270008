import { createReducer, on } from '@ngrx/store';
import { STATE } from '@ptg-shared/constance';

import * as MunicipalityPaymentAction from '../actions/municipality-payment.action';
import { DisabilityPayment, EditMetRequirementResponse, MunicipalityList } from '../../types/models';
import { BaseActionState } from '@ptg-shared/types/models';

export const MunicipalityPaymentFeatureKey = 'municipalityPayment';
export interface State {
  isLoading: boolean;
  payments: MunicipalityList;
  createState?: string;
  updateState?: string;
  editMetRequirementState?: string;
  editMetRequirementResponse?: EditMetRequirementResponse
  addDisabilityPaymentState?: string
  disabilityPayments?: BaseActionState<DisabilityPayment[]>
}

const initialState: State = {
  isLoading: true,
  payments: {
    total: 0,
    payments: [],
    totalService: '',
  },
  createState: '',
  updateState: '',
  editMetRequirementState: '',
  disabilityPayments: undefined
};

export const reducer = createReducer(
  initialState,
  on(MunicipalityPaymentAction.getMunicipalityPaymentList, (state, {}) => ({
    ...state,
    isLoading: true,
    payments: {
      total: 0,
      payments: [],
      totalService: '',
    },
  })),
  on(
    MunicipalityPaymentAction.municipalityPaymentListSuccess,
    (state, { payments }) => ({
      ...state,
      isLoading: false,
      payments,
    })
  ),
  on(MunicipalityPaymentAction.municipalityPaymentListFailure, (state) => ({
    ...state,
    isLoading: false,
    payments: {
      total: 0,
      payments: [],
      totalService: '',
    },
  })),

  on(MunicipalityPaymentAction.clearMunicipalityPaymentState, (state) => ({
    ...state,
    createState: '',
    updateState: '',
    editMetRequirementState: '',
    editMetRequirementResponse: undefined,
    addDisabilityPaymentState: '',
    disabilityPayments: undefined
  })),

  on(MunicipalityPaymentAction.editMunicipalityPaymentMetRequirement, (state) => ({
    ...state,
    editMetRequirementState: ''
  })),
  on(MunicipalityPaymentAction.editMunicipalityPaymentMetRequirementSuccess, (state, { editMetRequirementResponse }) => ({
    ...state,
    editMetRequirementState: STATE.SUCCESS,
    editMetRequirementResponse,
  })),
  on(MunicipalityPaymentAction.editMunicipalityPaymentMetRequirementFailure, (state) => ({
    ...state,
    editMetRequirementState: STATE.FAIL
  })),

  on(MunicipalityPaymentAction.addDisabilityPaymentAction, (state, { }) => ({
    ...state,
    isLoading: true,
    addDisabilityPaymentState: '',
  })),

  on(
    MunicipalityPaymentAction.addDisabilityPaymentActionSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      addDisabilityPaymentState: STATE.SUCCESS
    })
  ),
  on(MunicipalityPaymentAction.addDisabilityPaymentActionFailure, (state) => ({
    ...state,
    isLoading: false,
    addDisabilityPaymentState: STATE.FAIL
  })),

  on(MunicipalityPaymentAction.getDisabilityPaymentAction, (state, {}) => ({
    ...state,
    disabilityPayments: {
      isLoading: true,
    },
  })),
  on(
    MunicipalityPaymentAction.getDisabilityPaymentActionSuccess,
    (state, { response }) => ({
      ...state,
      disabilityPayments: {
        isLoading: false,
        success: true,
        payload: response.disabilityPayments ?? [],
        total: response.total ?? 0
      },
    })
  ),
  on(MunicipalityPaymentAction.getDisabilityPaymentActionFailure, (state, error) => ({
    ...state,
    disabilityPayments: {
      isLoading: false,
      error: error
    },
  })),
);
