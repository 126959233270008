<div class="edit-lookup-table-container" id="edit-lookup-table-dialog">
  <ptg-title-bar
    [name]="lookupTableDetail?.id ? 'Edit' : 'Add'"
    viewName="Lookup Table"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <ptg-checkbox
        [controlField]="editForm.get('active')"
        label="Active"
      ></ptg-checkbox>
      <ptg-textbox
        placeholder="Table Name"
        errorAsync="Table Name already exists."
        [controlField]="editForm.get('name')"
        [hasLabel]="true"
        [maxLength]="150"
      ></ptg-textbox>
    </div>
  </form>
</div>
