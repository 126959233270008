<div class="disability-detail" #memberDetail>
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <ptg-overview-header-entity></ptg-overview-header-entity>

  <div class="pt-6 px-6">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>
  </div>

  <div class="disability-benefit-detail-grid flex flex-col gap-6 p-6">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <ptg-button
      *ngIf="canRecalculate"
      buttonName="Initiate Disability Calculation"
      classInput="primary"
      (clickButton)="onClickRecalculate()"
    >
    </ptg-button>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header flex justify-between px-4 py-3">
          <span class="title font-bold">{{ title }}</span>
          <ptg-button
            buttonName="Edit"
            classInput="upload-button"
            class="upload-file"
            (clickButton)="onClickEditBenefit()"
          >
            <mat-icon aria-hidden="false" aria-label="edit-icon">edit</mat-icon>
          </ptg-button>
        </div>
        <div class="disability-benefit__content flex flex-wrap">
          <div class="disability-benefit__content--field">
            <div class="label">Begin Date</div>
            <div>{{ (beginDate | date: 'MM/dd/yyyy') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field">
            <div class="label">End Date</div>
            <div>{{ (endDate | date: 'MM/dd/yyyy') || '-' }}</div>
          </div>
          <div class="disability-benefit__content--field" *ngIf="isShowTotal">
            <div class="label">Total Days used</div>
            <div>{{ totalDaysUsed }}</div>
          </div>
        </div>

        <ptg-grid
          [data]="disabilityBenefitData"
          [columns]="columnsDisability"
          [totalRecords]="totalDisability"
          [isLoading]="isDisabilityLoading"
          [pageSize]="pageDisabilitySize"
          [pageNumber]="pageDisabilityIndex"
          [hideScrollbar]="false"
          notFoundMessage="No Data to Display"
          (sortChange)="onChangeSort($event)"
          (pageChange)="onChangePage($event)"
          (rowClick)="openBenefitCalculationDetail($event)"
        >
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              *ngIf="row.isTurnOnDeletion"
              stopPropagation
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onClickDeleteItem(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header px-4 py-3">
          <span class="title font-bold">Accident Information</span>
        </div>

        <ptg-grid
          [data]="accidentData"
          [columns]="columnsAccident"
          [isLoading]="isDisabilityLoading"
          [paginable]="false"
          [hideScrollbar]="false"
          (rowClick)="openAccidentDetail($event)"
          notFoundMessage="No Data to Display"
        >
        </ptg-grid>
      </div>
    </div>

    <div class="disability-detail-container">
      <div class="disability-benefit-grid flex-grow overflow-hidden">
        <div class="header px-4 py-3 document-header flex justify-between">
          <span class="title font-bold">Disability Documents</span>
          <ptg-button
            buttonName="Upload Document"
            classInput="upload-button"
            class="upload-file"
            (clickButton)="onUploadDocuments()"
          >
            <mat-icon aria-hidden="false" aria-label="upload-icon">upload_file</mat-icon>
          </ptg-button>
        </div>

        <ptg-grid
          [data]="disabilityBenefitDocuments"
          [columns]="columnsBenefitDocuments"
          [totalRecords]="totalBenefitDocuments"
          [isLoading]="isDocumentLoading"
          [pageSize]="pageDocumentSize"
          [pageNumber]="pageDocumentIndex"
          notFoundMessage="No Disability Benefit Documents to Display"
          (sortChange)="onChangeSortDocuments($event)"
          (pageChange)="onChangeDocumentsPage($event)"
        >
          <ng-template cellContent columnName="fileName" let-row>
            <div class="flex file-document-container">
              <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank"
                ><span class="truncate">{{ row?.fileName }}</span></span
              >
            </div>
          </ng-template>
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="onEditDocument(row)">
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              stopPropagation
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onRemoveDocument(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </div>
</div>
