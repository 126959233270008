import {
  Component, ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  DetailValueContentDirective
} from '@ptg-shared/controls/detail-display/directives/detail-value-content.directive';
import { CellContent } from '@ptg-shared/controls/grid';
import { DataType } from '../../constance/data-type.const';
import { DetailDisplay } from '../../types/models/detail-display.model';

@Component({
  selector: 'ptg-detail-display-box',
  templateUrl: './detail-display.component.html',
  styleUrls: ['./detail-display.component.scss']
})
export class DetailDisplayComponent implements OnInit {
  DATA_TYPE = DataType;
  @Input() title: string = '';
  @Input() selectedItem!: any;
  @Input() nonEditItem?: any;
  @Input() propertyDisplayed!: DetailDisplay[];
  @Input() isDisableEdit: boolean = false;
  @Output() editButtonEvent = new EventEmitter();
  @Output() removeButtonEvent = new EventEmitter();

  @Input() hasSecondButton: boolean = false;
  @Input() secondButtonIcon: string = '';
  @Input() secondButtonName: string = '';
  @Output() secondButtonEvent = new EventEmitter();


  @ContentChildren(DetailValueContentDirective) detailValueContents?: QueryList<DetailValueContentDirective>;

  constructor() { }

  ngOnInit(): void {
  }

  onEdit() {
    this.editButtonEvent.emit();
  }

  onClickSecondButton() {
    this.secondButtonEvent.emit();
  }

  getContentTemplate(templateName: string): TemplateRef<any> | undefined {
    let cellContentTemplate: TemplateRef<any> | undefined;
    if (this.detailValueContents && this.detailValueContents.length > 0) {
      const cellContent = this.detailValueContents.find(
        (cell) => cell.templateName === templateName
      );
      if (cellContent) {
        cellContentTemplate = cellContent.templateRef;
      }
    }
    return cellContentTemplate;
  }

  onRemoveClick(){
    this.removeButtonEvent.emit();
  }
}
