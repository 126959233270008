import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const payeePaymentSelector = createSelector(selectPayeeDetailModuleState, (state) => state?.payeePayment);

export const getPayeePaymentSelector = createSelector(payeePaymentSelector, (state) => state?.getPayeePayment);

export const setPayeePaymentSelector = createSelector(payeePaymentSelector, (state) => state?.setPayeePayment);

export const getHeaderConfigSelector = createSelector(payeePaymentSelector, (state) => state?.getHeaderConfig);
