<div class="edit-status-container" id="edit-status-dialog">
  <ptg-title-bar
    [name]="statusDetail?.id ? 'Edit' : 'Add'"
    viewName="Status"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <ptg-checkbox
        [controlField]="editForm.get('active')"
        label="Active"
      ></ptg-checkbox>
      <ptg-textbox
        placeholder="Status Name"
        errorAsync="Status Name already exists."
        [ngClass]="{'has-error': editForm.get('name')?.errors && editForm.get('name')?.touched}"
        [controlField]="editForm.get('name')"
        [hasLabel]="true"
        [maxLength]="150"
      ></ptg-textbox>
      <div class="icon-color-container">
        <ptg-input-color
          [controlField]="editForm.get('color')"
          placeholder="Status Color"
          errorInvalid="Invalid input."
        ></ptg-input-color>
        <div class="icon-selection-container">
          <span [ngClass]="[REQUIRED_INDICATOR]">Status Icon</span>
          <div class="icon-list-container">
            <div class="icon-container" *ngFor="let iconName of iconNames" (click)="changeIcon(iconName)">
              <mat-icon class="icon-status"
                        [ngClass]="{'selected': editForm.get('iconName')?.value === iconName}"
                        [ngStyle]="{'color': editForm.get('iconName')?.value === iconName ? editForm.get('color')?.value : '#EBEBEB'}">
                {{ iconName }}
              </mat-icon>
            </div>
          </div>
          <mat-error id="mat-error-iconName"
                     *ngIf="editForm.get('iconName')?.touched && editForm.get('iconName')?.errors?.required"
                     class="icon-name-error">
            Icon is required.
          </mat-error>

        </div>
      </div>
    </div>
  </form>
</div>
