import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import { SortType } from '@ptg-shared/constance';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CalculationRefundRecordResponse,
  CalculationRefundYearResponse,
  CreateInitiateRefundCalculationInformationRequest,
  CreateInitiateRefundCalculationInformationResponse,
  CreateReSelectRefundYearsRequest,
  GetInitiateBenefitValidationsRequest,
  GetInitiateBenefitValidationsResponse,
  GetRefundCalculationRecordsRequest,
  GetRefundCalculationRecordsResponse,
  GetRefundCalculationRequest,
  GetRefundPayeeRequest,
  GetRefundPayeeResponse,
  GetRetirementBenefitRequest,
  PayeeListRequest,
  PayeeListResponse,
  UpdatePayeeInfoRequest,
  ValidateBeforeRemoveCalculationBenefit,
} from './models';

@Injectable()
export class BenefitRefundService {
  constructor(private httpClient: HttpClient) {}

  // GetInitiateBenefitValidations
  getInitiateBenefitValidations(
    request: GetInitiateBenefitValidationsRequest,
  ): Observable<GetInitiateBenefitValidationsResponse> {
    const { memberId, calculationType } = request;
    const context = new HttpContext().set(SHOW_LOADING, true);

    return this.httpClient.get<GetInitiateBenefitValidationsResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${calculationType}/validation`,
      { context },
    );
  }

  getRefundPayee(request: GetRefundPayeeRequest): Observable<GetRefundPayeeResponse> {
    const { memberId, recordId } = request;
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<GetRefundPayeeResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${recordId}/payee-information`,
      { context }
    );
  }

  // Update Payee
  updatePayeeInformation = (request: UpdatePayeeInfoRequest) => {
    const body = {
      id: request.id,
      memberId: request.memberId,
      entityComponentId: request.entityComponentId,
      rowId: request.rowId,
    };
    return this.httpClient.post(
      `${environment.apiUrl}/calculations/benefit-processing/calculation-benefit/${request.recordId}/payee-information`,
      body,
    );
  };

  // GetPayeeList
  getPayeeList(query: PayeeListRequest): Observable<PayeeListResponse> {
    let params = new HttpParams();
    params = params.append('isSummary', query?.isSummary);
    if (query?.pageSize) {
      params = params.append('PageSize', query?.pageSize);
    }
    if (query?.pageNumber) {
      params = params.append('PageIndex', query.pageNumber.toString());
    }

    if (query && query.sortNames) {
      if (query.sortNames.split('_').length > 1) {
        params = params.append('SortNames', query.sortNames.split('_')[0]);
        params = params.append('EntityReferencePropertyId', query.sortNames.split('_')[1]);
      } else {
        params = params.append('SortNames', query.sortNames ?? '');
      }
      params = params.append('SortType', query.sortType ?? 0);
    }
    if (query.screenId) {
      params = params.append('screenId', encodeURIComponent(query.screenId));
    }
    const { entityMemberId, cardId, memberId } = query;

    return this.httpClient.get<PayeeListResponse>(
      `${environment.apiUrl}/entity-new/${entityMemberId}/cards/${cardId}/list-data/${memberId}`,
      { params },
    );
  }

  // CreateInitiateRefundCalculationInformation
  createInitiateRefundCalculationInformation(
    request: CreateInitiateRefundCalculationInformationRequest,
  ): Observable<CreateInitiateRefundCalculationInformationResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { memberId } = request;
    return this.httpClient.post<CreateInitiateRefundCalculationInformationResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-refund-benefit/initialization`,
      request,
      { context },
    );
  }

  // GetRefundCalculationRecords
  getRefundCalculationRecords(
    request: GetRefundCalculationRecordsRequest,
  ): Observable<GetRefundCalculationRecordsResponse> {
    const { memberId, calculationBenefitId } = request;

    return this.httpClient.get<GetRefundCalculationRecordsResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${calculationBenefitId}/refund-calculation-records`,
    );
  }

  // GetRefundCalculationRecordByIds
  getRefundCalculationRecordByIds(request: GetRefundCalculationRequest): Observable<CalculationRefundRecordResponse> {
    const { memberId, calculationRecordId } = request;
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<CalculationRefundRecordResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/refund-calculation-records/${calculationRecordId}`,
      { context },
    );
  }

  // GetCalculationRefundYears
  getCalculationRefundYears(
    request: GetRefundCalculationRequest,
    getRefundYearRequest: GetRetirementBenefitRequest,
  ): Observable<CalculationRefundYearResponse> {
    const { memberId, calculationRecordId } = request;

    let body: any = {};
    if (getRefundYearRequest?.sortNames?.length) {
      body = {
        ...body,
        sortNames: [getRefundYearRequest?.sortNames],
        sortType: getRefundYearRequest.sortType || SortType.ASC,
      };
    }
    if (getRefundYearRequest?.pageSize && getRefundYearRequest?.pageNumber) {
      body = {
        ...body,
        PageSize: getRefundYearRequest.pageSize,
        PageIndex: getRefundYearRequest.pageNumber,
      };
    }
    let params = new HttpParams({ fromObject: body as any });

    return this.httpClient.get<CalculationRefundYearResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/refund-calculation-records/${calculationRecordId}/refund-years`,
      { params },
    );
  }

  // ReSelectRefundYears
  createReSelectRefundYears(request: CreateReSelectRefundYearsRequest): Observable<boolean> {
    const { param, reSelectRefundYearsRequest } = request;
    return this.httpClient.post<boolean>(
      `${environment.apiUrl}/calculations/${param.memberId}/benefit-processing/calculation-benefit/refund-calculation-records/${param.calculationRecordId}/refund-years`,
      reSelectRefundYearsRequest,
    );
  }

  getValidateBeforeRemoveCalculationBenefit(
    request: GetRefundCalculationRecordsRequest,
  ): Observable<ValidateBeforeRemoveCalculationBenefit> {
    const { memberId, calculationBenefitId } = request;
    const context = new HttpContext().set(SHOW_LOADING, true);

    return this.httpClient.get<ValidateBeforeRemoveCalculationBenefit>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${calculationBenefitId}/remove-validation`,
      { context },
    );
  }
}
