import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { GetTypesOfDeductionsResponse } from '../../services/models';
import {
  clearGetTypesOfDeductionsStateAction,
  clearSetDeductionsStateAction,
  getTypesOfDeductionsAction,
  getTypesOfDeductionsFailureAction,
  getTypesOfDeductionsSuccessAction,
  setDeductionsAction,
  setDeductionsFailureAction,
  setDeductionsSuccessAction,
} from '../actions';

export interface EditDeductionsState {
  getTypesOfDeductions?: BaseActionState<GetTypesOfDeductionsResponse>;
  setDeductions?: BaseActionState<{ isChangeAmountOfPreTax: boolean }>;
}

const initialState: EditDeductionsState = {};

export const editDeductionsReducer = createReducer(
  initialState,
  on(getTypesOfDeductionsAction, (state) => ({
    ...state,
    getTypesOfDeductions: {
      isLoading: true,
    },
  })),
  on(getTypesOfDeductionsSuccessAction, (state, { response }) => ({
    ...state,
    getTypesOfDeductions: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getTypesOfDeductionsFailureAction, (state, { error }) => ({
    ...state,
    getTypesOfDeductions: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetTypesOfDeductionsStateAction, (state) => ({
    ...state,
    getTypesOfDeductions: undefined,
  })),

  on(setDeductionsAction, (state) => ({
    ...state,
    setDeductions: {
      isLoading: true,
    },
  })),
  on(setDeductionsSuccessAction, (state, { isChangeAmountOfPreTax }) => ({
    ...state,
    setDeductions: {
      isLoading: false,
      success: true,
      payload: {
        isChangeAmountOfPreTax
      }
    },
  })),
  on(setDeductionsFailureAction, (state, { error }) => ({
    ...state,
    setDeductions: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetDeductionsStateAction, (state) => ({
    ...state,
    setDeductions: undefined,
  })),
);
