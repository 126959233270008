import { createReducer, on } from '@ngrx/store';
import { GetMemberAuditTrailsCardInfoResponse, GetMemberAuditTrailsListResponse, GetMemberAuditTrailsProfileInfoResponse, GetMemberAuditTrailsSearchNameResponse, GetMemberAuditTrailsSearchUserResponse, GetMemberAuditTrailsSectionListResponse } from '@ptg-member/types/models/member-audit-trails.model';
import { BaseActionState } from '@ptg-shared/types/models';
import { getMemberAuditTrailsListAction, getMemberAuditTrailsListSuccessAction, getMemberAuditTrailsListUpFailureAction, clearGetMemberAuditTrailsListStateAction, getMemberAuditTrailsSectionListAction, getMemberAuditTrailsSectionListSuccessAction, getMemberAuditTrailsSectionListFailureAction, clearGetMemberAuditTrailsSectionListStateAction, getMemberAuditTrailsSearchNameAction, getMemberAuditTrailsSearchNameSuccessAction, getMemberAuditTrailsSearchNameFailureAction, clearGetMemberAuditTrailsSearchNameStateAction, getMemberAuditTrailsSearchUserAction, getMemberAuditTrailsSearchUserSuccessAction, getMemberAuditTrailsSearchUserFailureAction, clearGetMemberAuditTrailsSearchUserStateAction, clearGetMemberAuditTrailsCardInfoStateAction, getMemberAuditTrailsCardInfoAction, getMemberAuditTrailsCardInfoFailureAction, getMemberAuditTrailsCardInfoSuccessAction, clearGetMemberAuditTrailsProfileInfoStateAction, getMemberAuditTrailsProfileInfoAction, getMemberAuditTrailsProfileInfoFailureAction, getMemberAuditTrailsProfileInfoSuccessAction } from '../actions/member-audit-trails.action';

export interface MemberAuditTrailsState {
  getMemberAuditTrailsListState?: BaseActionState<GetMemberAuditTrailsListResponse>;
  getMemberAuditTrailsSectionListState?: BaseActionState<GetMemberAuditTrailsSectionListResponse>;
  getMemberAuditTrailsSearchNameState?: BaseActionState<GetMemberAuditTrailsSearchNameResponse>;
  getMemberAuditTrailsSearchUserState?: BaseActionState<GetMemberAuditTrailsSearchUserResponse>;
  getMemberAuditTrailsCardInfoState?: BaseActionState<GetMemberAuditTrailsCardInfoResponse>;
  getMemberAuditTrailsProfileInfoState?: BaseActionState<GetMemberAuditTrailsProfileInfoResponse>;
}

const initialState: MemberAuditTrailsState = {};

export const memberAuditTrailsKey = 'memberAuditTrails';

export const memberAuditTrailsReducer = createReducer(
  initialState,
  
  // Get Member Audit Trails List
  on(getMemberAuditTrailsListAction, (state) => ({
    ...state,
    getMemberAuditTrailsListState: {
      isLoading: true,
    },
  })),
  on(getMemberAuditTrailsListSuccessAction, (state, { response }) => ({
    ...state,
    getMemberAuditTrailsListState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberAuditTrailsListUpFailureAction, (state, { error }) => ({
    ...state,
    getMemberAuditTrailsListState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberAuditTrailsListStateAction, (state) => ({
    ...state,
    getMemberAuditTrailsListState: undefined,
  })),

  // Get Member Audit Trails Section List
  on(getMemberAuditTrailsSectionListAction, (state) => ({
    ...state,
    getMemberAuditTrailsSectionListState: {
      isLoading: true,
    },
  })),
  on(getMemberAuditTrailsSectionListSuccessAction, (state, { response }) => ({
    ...state,
    getMemberAuditTrailsSectionListState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberAuditTrailsSectionListFailureAction, (state, { error }) => ({
    ...state,
    getMemberAuditTrailsSectionListState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberAuditTrailsSectionListStateAction, (state) => ({
    ...state,
    getMemberAuditTrailsSectionListState: undefined,
  })),

  // Get Member Audit Trails Card Info
  on(getMemberAuditTrailsCardInfoAction, (state) => ({
    ...state,
    getMemberAuditTrailsCardInfoState: {
      isLoading: true,
    },
  })),
  on(getMemberAuditTrailsCardInfoSuccessAction, (state, { response }) => ({
    ...state,
    getMemberAuditTrailsCardInfoState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberAuditTrailsCardInfoFailureAction, (state, { error }) => ({
    ...state,
    getMemberAuditTrailsCardInfoState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberAuditTrailsCardInfoStateAction, (state) => ({
    ...state,
    getMemberAuditTrailsCardInfoState: undefined,
  })),

  // Get Member Audit Trails Profile Info
  on(getMemberAuditTrailsProfileInfoAction, (state) => ({
    ...state,
    getMemberAuditTrailsProfileInfoState: {
      isLoading: true,
    },
  })),
  on(getMemberAuditTrailsProfileInfoSuccessAction, (state, { response }) => ({
    ...state,
    getMemberAuditTrailsProfileInfoState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberAuditTrailsProfileInfoFailureAction, (state, { error }) => ({
    ...state,
    getMemberAuditTrailsProfileInfoState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberAuditTrailsProfileInfoStateAction, (state) => ({
    ...state,
    getMemberAuditTrailsProfileInfoState: undefined,
  })),

  // Get Member Audit Trails Search Name
  on(getMemberAuditTrailsSearchNameAction, (state) => ({
    ...state,
    getMemberAuditTrailsSearchNameState: {
      isLoading: true,
    },
  })),
  on(getMemberAuditTrailsSearchNameSuccessAction, (state, { response }) => ({
    ...state,
    getMemberAuditTrailsSearchNameState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberAuditTrailsSearchNameFailureAction, (state, { error }) => ({
    ...state,
    getMemberAuditTrailsSearchNameState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberAuditTrailsSearchNameStateAction, (state) => ({
    ...state,
    getMemberAuditTrailsSearchNameState: undefined,
  })),

  // Get Member Audit Trails Search User
  on(getMemberAuditTrailsSearchUserAction, (state) => ({
    ...state,
    getMemberAuditTrailsSearchUserState: {
      isLoading: true,
    },
  })),
  on(getMemberAuditTrailsSearchUserSuccessAction, (state, { response }) => ({
    ...state,
    getMemberAuditTrailsSearchUserState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberAuditTrailsSearchUserFailureAction, (state, { error }) => ({
    ...state,
    getMemberAuditTrailsSearchUserState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberAuditTrailsSearchUserStateAction, (state) => ({
    ...state,
    getMemberAuditTrailsSearchUserState: undefined,
  })),

);
