import { Injectable, OnDestroy } from '@angular/core';

import { DateTime } from 'luxon';
import { Store } from '@ngrx/store';

import { isEmpty } from '@ptg-shared/utils/string.util';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DEFAULT_PAGE_SIZE } from '@ptg-shared/constance';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import {
  GetPaymentInfoTabsRequest,
  LastPayrollCycle,
  PaymentTab,
} from '@ptg-member/features/payee-detail/services/models';

import {
  PayeeDetailState,
  clearAdjustmentDetailItemPositionState,
  getAdjustmentDetailItemPositionAction,
  getAdjustmentDetailItemPositionSelector,
  getPaymentDeductionsDetailState,
  getPaymentEarningsDetailState,
  getPaymentInfoTabsAction,
} from '../../store';
import { HeaderBenefit } from '../../types/models';
import {
  EditPaymentStatusActionName,
  PayStatus,
  PaymentInstructionType,
  PositionPaymentInfoTabDetailedQueryType,
} from '../../types/enums';
import { AdjustmentDetailItemPositionRequest } from '../../services/models/view-adjustment-details.model';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Params } from '@angular/router';

@Injectable()
export class PaymentInstructionDetailComponentService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private _paymentInfoTabRequestData?: GetPaymentInfoTabsRequest | null;
  private _paymentInstructionId: string = '';
  private _paymentInstructionHistoryId: string = '';
  private showOptButton$ = new BehaviorSubject(false);

  get paymentInfoTabRequestData() {
    return this._paymentInfoTabRequestData;
  }
  set paymentInfoTabRequestData(request: GetPaymentInfoTabsRequest | undefined | null) {
    this._paymentInfoTabRequestData = deepClone(request);
  }

  get selectedPaymentInstructionId() {
    return this._paymentInstructionId;
  }
  set selectedPaymentInstructionId(id: string) {
    this._paymentInstructionId = id;
  }

  get selectedPaymentInstructionHistoryId() {
    return this._paymentInstructionHistoryId;
  }
  set selectedPaymentInstructionHistoryId(id: string) {
    this._paymentInstructionHistoryId = id;
  }

  get getShowOptButton(): Observable<boolean> {
    return this.showOptButton$;
  }

  constructor(private readonly payeeDetailStore: Store<PayeeDetailState>) {
    this.selectAdjustmentDetailItemPositionState();
    this.handleShowOptButton();
  }

  ngOnDestroy(): void {
    this.paymentInfoTabRequestData = null;
    this.selectedPaymentInstructionId = '';
    this.selectedPaymentInstructionHistoryId = '';
  }

  getSettings(queryParams: Params, menuId = '', viewId = '', memberId = ''): Breadcrumb[] {
    const ids = `${menuId}/${viewId}/${memberId}`;
    const queries = this.getQueryParams(queryParams);

    return [
      {
        name: 'Initial Payment Configuration',
        url: `/member/system-view/initial-payment-configuration/${ids}${queries}`,
      },
      {
        name: 'Payment Header Configuration',
        url: `/member/system-view/payment-header-configuration/${ids}${queries}`,
      },
      {
        name: 'Final Payment Configuration',
        url: `/member/system-view/final-payment-configuration/${ids}${queries}`,
      },
    ];
  }

  getQueryParams(queryParams: Params): string {
    return !Object.keys(queryParams).length
      ? ''
      : Object.keys(queryParams).reduce((acc, key, index) => {
          acc += `${index ? '&' : ''}${key}=${queryParams[key]}`;
          return acc;
        }, '?');
  }

  getNotificationBannerMessage(
    headerConfig?: HeaderBenefit,
    selectedPayment?: PaymentTab,
    isSelectedTerminated?: boolean,
    isUncheckStatus?: boolean,
    lastPayrollCycle?: LastPayrollCycle,
  ): {
    bannerType: BannerType;
    message: string;
  } {
    const { benefitName = '' } = headerConfig ?? {};
    const {
      payeeName = '',
      payStatus,
      prevPayStatus,
      paymentType,
      endPeriodDate,
      cutOffDate,
      nextPeriod,
    } = selectedPayment ?? {};
    // If the cutoff date of the current benefit period < current date AND [Payment Instruction Status] is changed from "Suspended/Terminated" to "Pending", system displays the Unsuspended/Unterminated banners corresponding.
    if (isEmpty(payStatus) || isEmpty(prevPayStatus) || !cutOffDate) {
      return { bannerType: BannerType.Hidden, message: '' };
    }
    const paymentCutOffDate = DateTime.fromISO(cutOffDate);

    let actionName = '';
    if (prevPayStatus === PayStatus.Suspended) {
      actionName = EditPaymentStatusActionName.UnSuspended;
    } else if (prevPayStatus === PayStatus.Terminated || lastPayrollCycle != null) {
      actionName = EditPaymentStatusActionName.UnTerminated;
    }
    const nextPeriodStartDate = nextPeriod?.startDate
      ? DateTime.fromISO(nextPeriod.startDate).toFormat('MM/dd/yyyy')
      : '';
    const nextPeriodEndDate = nextPeriod?.endDate ? DateTime.fromISO(nextPeriod.endDate).toFormat('MM/dd/yyyy') : '';
    const displayNextPeriod = nextPeriod ? `(${nextPeriodStartDate} - ${nextPeriodEndDate})` : '';
    if (
      payStatus === PayStatus.Pending &&
      isUncheckStatus &&
      paymentCutOffDate.toMillis() <= DateTime.now().toMillis()
    ) {
      return {
        bannerType: BannerType.Warning,
        // System show the banner content in the following format: "<Payee Name>'s <Benefit Entity Name> benefit is unsuspended after cut-off date so it will be included in the next Benefit Period (<next benefit period Start Date> - <next benefit period End Date>)."
        message: `${payeeName}'s ${benefitName} benefit is ${actionName} after cut-off date so it will be included in the next Benefit Period ${displayNextPeriod}.`,
      };
    }
    if (
      isEmpty(payStatus) ||
      isEmpty(paymentType) ||
      !benefitName ||
      !endPeriodDate ||
      !lastPayrollCycle?.startDate ||
      !lastPayrollCycle?.endDate
    ) {
      return { bannerType: BannerType.Hidden, message: '' };
    }
    const lastPayrollCycleStartDate = DateTime.fromISO(lastPayrollCycle.startDate);
    const lastPayrollCycleEndDate = DateTime.fromISO(lastPayrollCycle.endDate);
    const currentBenefitEndDate = DateTime.fromISO(endPeriodDate);
    // If a Recurring Payment Instruction has "Terminate" check box checked with the [Last Payroll Cycle] > the current Payroll cycle, system will show components Termination Notification & Reverse on the Payment Instruction from Payment Tab.
    // or If a Recurring Payment Instruction has "Terminate" check box checked with the [Last Payroll Cycle] < the current Payroll cycle, this banner will be displayed in the last record of finalized recurring payment.
    if (
      paymentType === PaymentInstructionType.Recurring &&
      isSelectedTerminated &&
      lastPayrollCycleEndDate.toMillis() > currentBenefitEndDate.toMillis()
    ) {
      return {
        bannerType: BannerType.Warning,
        // System shows the label in the following format: "<Payee Name>'s <Benefit Entity Name> benefit is terminated with the last payroll cycle (<saved Last Payroll Cycle Start Date> - <saved Last Payroll Cycle End Date>)."
        message: `${payeeName}'s ${benefitName} benefit is terminated with the last payroll cycle (${lastPayrollCycleStartDate.toFormat(
          'MM/dd/yyyy',
        )} - ${lastPayrollCycleEndDate.toFormat('MM/dd/yyyy')}).`,
      };
    }
    return { bannerType: BannerType.Hidden, message: '' };
  }

  getAdjustmentDetailItemPosition(
    paramId: {
      adjustmentId?: string;
      paymentInstructionId?: string;
      paymentInstructionHistoryId?: string;
    },
    queryType: PositionPaymentInfoTabDetailedQueryType = PositionPaymentInfoTabDetailedQueryType.Adjustment,
  ): void {
    const {
      benefitSubtypeId = '',
      payeeRecordId = '',
      benefitCode = '',
      pageSize = DEFAULT_PAGE_SIZE,
    } = this.paymentInfoTabRequestData ?? {};

    const request: AdjustmentDetailItemPositionRequest = {
      benefitSubtypeId,
      payeeRecordId,

      // Query params
      benefitCode,
      paymentInstructionHistoryId: paramId?.paymentInstructionHistoryId,
      adjustmentId: paramId?.adjustmentId,
      paymentInstructionId: paramId?.paymentInstructionId,
      pageSize,

      queryType,
    };
    this.payeeDetailStore.dispatch(getAdjustmentDetailItemPositionAction({ request }));
  }

  private selectAdjustmentDetailItemPositionState(): void {
    this.payeeDetailStore
      .select(getAdjustmentDetailItemPositionSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(clearAdjustmentDetailItemPositionState());

        if (!response?.payload) return;

        const {
          pageNumber = 1,
          queryType,
          adjustmentId = '',
          paymentInstructionId = '',
          paymentInstructionHistoryId = '',
        } = response.payload;
        if (!pageNumber) return;

        this.selectedPaymentInstructionId =
          queryType === PositionPaymentInfoTabDetailedQueryType.Adjustment ? adjustmentId : paymentInstructionId;
        this.selectedPaymentInstructionHistoryId = paymentInstructionHistoryId;

        const paymentInfoTabRequest: GetPaymentInfoTabsRequest = {
          ...this.paymentInfoTabRequestData,
          pageNumber,
        };
        this.payeeDetailStore.dispatch(getPaymentInfoTabsAction({ request: paymentInfoTabRequest }));
      });
  }

  private handleShowOptButton() {
    combineLatest([
      this.payeeDetailStore.select(getPaymentEarningsDetailState),
      this.payeeDetailStore.select(getPaymentDeductionsDetailState),
    ])
      .pipe(
        map((res: any[]) => res.every((items: any) => items?.isLoading === false)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => this.showOptButton$.next(res));
  }
}
