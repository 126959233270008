import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { getDateString, stringToBoolean } from '@ptg-shared/utils/string.util';

import { DetailViewColumnType, EntityPropertyType, PropertyDisplay } from '@ptg-entity-management/types/enums';
import { Router } from '@angular/router';
import { getEntityValue } from '@ptg-member/helper';
import { CardComponent, ListPropertyForCard } from '@ptg-entity-management/services/models';
import { CREATED_AT, CREATED_BY, GUID_EMPTY, UPDATED_AT, UPDATED_BY } from '@ptg-shared/constance';
import { NOTES_PROPERTY } from '@ptg-entity-management/constants/entity-mapping.constant';

@Component({
  selector: 'ptg-member-navigation-standalone-property',
  templateUrl: './member-navigation-standalone-property.component.html',
  styleUrls: ['./member-navigation-standalone-property.component.scss'],
})
export class MemberNavigationStandalonePropertyComponent
  implements OnInit, OnChanges
{
  @Input() currentRow!: any;
  @Input() isEditable: boolean = true;
  @Input() metadataSection?: CardComponent[];
  @Input() menuItemName!: string;
  @Input() isList?: boolean;
  @Input() columns!: any[];
  @Input() memberId!: string;
  @Input() currentSectionLayout?: PropertyDisplay;
  @Input() isLoading: boolean = true;
  @Input() existNotePropertyAsStandAlonePropertyInListCard: boolean = false;

  readonly PropertyDisplay = PropertyDisplay;
  readonly EntityPropertyType = EntityPropertyType;
  visibilityOff: boolean = true;
  statusEventItem: any;

  memberNavigationItem: any;
  value: string = '';
  column1Data: any[] = [];
  column2Data: any[] = [];
  column3Data: any[] = [];
  column4Data: any[] = [];
  allColumnData: any[] = [];

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.column1Data = [];
    this.column2Data = [];
    this.column3Data = [];
    this.column4Data = [];
    this.allColumnData = [];

    if (changes.currentRow && changes.currentRow.currentValue) {
      this.metadataSection?.forEach((p) => {
        this.getProperties(p);
      });

      this.allColumnData = this.zipColumnData();
    }
  }

  getProperties(cardComponent: CardComponent): any {
    switch (cardComponent?.detailViewColumnType) {
      case DetailViewColumnType.DetailViewColumn1:
        cardComponent?.cardProperties?.forEach((element) => {
          if (this.existNotePropertyAsStandAlonePropertyInListCard && element?.entityPropertyId?.toUpperCase() === NOTES_PROPERTY.Id.toUpperCase()) {
            return;
          }
          this.column1Data.push(this.setDataProperties(element));
        });
       
        this.column1Data.sort(
          (a, b) => Number(a.orderColumn) - Number(b.orderColumn)
        );
        break;
      case DetailViewColumnType.DetailViewColumn2:
        cardComponent?.cardProperties?.forEach((element) => {
          if (this.existNotePropertyAsStandAlonePropertyInListCard && element?.entityPropertyId?.toUpperCase() === NOTES_PROPERTY.Id.toUpperCase()) {
            return;
          }
          this.column2Data.push(this.setDataProperties(element));
        });

        this.column2Data.sort(
          (a, b) => Number(a.orderColumn) - Number(b.orderColumn)
        );
        break;
      case DetailViewColumnType.DetailViewColumn3:
        cardComponent?.cardProperties?.forEach((element) => {
          if (this.existNotePropertyAsStandAlonePropertyInListCard && element?.entityPropertyId?.toUpperCase() === NOTES_PROPERTY.Id.toUpperCase()) {
            return;
          }
          this.column3Data.push(this.setDataProperties(element));
        });

        this.column3Data.sort(
          (a, b) => Number(a.orderColumn) - Number(b.orderColumn)
        );
        break;
      case DetailViewColumnType.DetailViewColumn4:
        cardComponent?.cardProperties?.forEach((element) => {
          if (this.existNotePropertyAsStandAlonePropertyInListCard && element?.entityPropertyId?.toUpperCase() === NOTES_PROPERTY.Id.toUpperCase()) {
            return;
          }
          this.column4Data.push(this.setDataProperties(element));
        });

        this.column4Data.sort(
          (a, b) => Number(a.orderColumn) - Number(b.orderColumn)
        );
        break;
      default:
        break;
    }
  }

  private zipColumnData() {
    const columnData = [];
    const maxLength = Math.max(
      this.column1Data.length,
      this.column2Data.length,
      this.column3Data.length,
      this.column4Data.length
    );
    for (let i = 0; i < maxLength; i++) {
      columnData.push([
        this.column1Data[i],
        this.column2Data[i],
        this.column3Data[i],
        this.column4Data[i],
      ]);
    }

    return columnData;
  }

  setDataProperties(cardComponent: any): any {
    let currentRowData: any;
    if (cardComponent?.entityReferencePropertyId) {
      currentRowData = this.currentRow?.find((item: any) => 
      (item.entityPropertyId === cardComponent?.entityReferencePropertyId &&
        item.entityReferencePropertyId === cardComponent?.propertyId))
    } else if (cardComponent?.propertyId) {
      currentRowData = this.currentRow?.find((item: any) => item.entityPropertyId === cardComponent?.propertyId && (item.option === cardComponent.option || item.fixedPropertyKey === cardComponent?.option));
    } else {
      currentRowData = this.currentRow?.find((item: any) => item.fixedPropertyKey === cardComponent?.option);
    }
   
    const optColDateList = [CREATED_AT, UPDATED_AT];
    const isAggregationProperty = cardComponent?.type === EntityPropertyType.Aggregation;

    const entityData: ListPropertyForCard = {
      value: optColDateList.includes(cardComponent.option) ? getDateString(currentRowData?.value) : currentRowData?.value,
      options: currentRowData?.options,
      option: currentRowData?.option ?? currentRowData?.type,
      optionValue: currentRowData?.optionValue
    }
    const opiton = {
      type: isAggregationProperty
        ? (currentRowData?.type ?? cardComponent?.type)
        : (cardComponent?.type ?? currentRowData?.type),
      config: cardComponent?.config
    }

    let data = getEntityValue(entityData, opiton, true, true);
    return {
      key: this.getColumnName(cardComponent),
      value: data,
      title: cardComponent?.propertyLabel,
      type: optColDateList.includes(cardComponent.option) ? EntityPropertyType['Date Time'] : cardComponent.type,
      configs: cardComponent?.config,
      options: entityData?.options,
      masked: stringToBoolean(
        cardComponent?.config?.masked
      ),
      valueUnMasked: this.mapDataUnMasked(entityData),
      visibilityOff: stringToBoolean(
        cardComponent?.config?.masked
      ),
      entityReferenceId: cardComponent.entityReferenceId,
      isBlankSpace: cardComponent.componentId && cardComponent.type === null,
    };
  }

  getColumnName(column: any): string {
    let columnName = '';
    const optColNameList = [UPDATED_BY, CREATED_AT, CREATED_BY, UPDATED_AT];
    if(optColNameList.includes(column.option)){
      columnName = GUID_EMPTY;
    } else {
      columnName = column.propertyId;
    }
    if (column.propertyId) {
      columnName = columnName + "_" + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + "_" + column.option;
    }

    return columnName;
  }

  mapDataUnMasked(entityData: any) {
    return entityData?.value?.originalValue;
  }

  ngOnInit(): void {}

  onClickIcon(row: any) {
    row.visibilityOff = !row.visibilityOff;
  }

  redirectToProfile(data: any) {
      this.router.navigateByUrl(`member/profiles/${data.entityReferenceId}`);
  }
}
