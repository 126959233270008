import { PERMISSION_KEY, SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Menu, MenuGroup } from '@ptg-shared/layout/models/layout.model';


export const MEMBER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [{ name: 'Overview', routerLink: '/member/detail/' }]
  }
];

export const IAM_MENU_LIST: Menu[] = [{
  title: 'Access Management',
  menuItems: [
    { name: 'Role Management', routerLink: '/access-management/roles' }
  ]
}];

export const ACCESS_MANAGEMENT_MENU_LIST: MenuGroup = {
  moduleKey: SUBMODULE_KEY.ACCESS_MANAGEMENT,
  name: 'access_management',
  menu: IAM_MENU_LIST,
  icon: 'vpn_key',
  permissionKey: PERMISSION_KEY.ACCESS_CONTROL
};

export const MEMBER_MANAGEMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'member_list',
  icon: 'list',
  permissionKey: PERMISSION_KEY.PARTICIPANTS,
  routerLink: '/member'
};

export const MEMBER_PROFILE_OVERVIEW_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'demographics',
  icon: 'account_circle',
  menu: MEMBER_OVERVIEW_MENU_LIST,
  permissionKey: PERMISSION_KEY.PARTICIPANTS
};

export const EMPLOYERS_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'apartment',
  routerLink: '/employer/employer-management',
  permissionKey: PERMISSION_KEY.EMPLOYERS
};

export const PARTICIPANTS_MENU_GROUPS = [MEMBER_MANAGEMENT_MENU_GROUP];

export const PARTICIPANT_OVERVIEW_MENU_GROUPS = [MEMBER_PROFILE_OVERVIEW_MENU_GROUP];

export const ADMIN_MENU_GROUPS = [ACCESS_MANAGEMENT_MENU_LIST];

export const EMPLOYERS_MENU_GROUPS = [EMPLOYERS_MENU_GROUP];

export const DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS: Partial<Record<DisplayedTabName, MenuGroup[]>> = {
  employers: EMPLOYERS_MENU_GROUPS,
  participants: PARTICIPANTS_MENU_GROUPS,
  admin: ADMIN_MENU_GROUPS,
  individualParticipant: PARTICIPANT_OVERVIEW_MENU_GROUPS,
  searchedParticipant: PARTICIPANT_OVERVIEW_MENU_GROUPS,
};
