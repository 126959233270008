import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import * as PayeeDetailsAction from '../actions/payee-detail.actions';
import { PaymentEarningsDetailResponse } from '../../services/models';
import { PaymentDeductionsDetailResponse } from '../../services/models/payment-deductions.model';
import { PaymentAnnualTotalsResponse } from '../../services/models/payment-annual-totals.model';

export const PaymentEarningsDetailFeatureKey = 'paymentEarningsDetail';

export interface State {
  isLoading: boolean;
  paymentEarningsDetail?: BaseActionState<PaymentEarningsDetailResponse>;
  payeeDeductionsDetail?: BaseActionState<PaymentDeductionsDetailResponse>;
  paymentAnnualTotals?: BaseActionState<PaymentAnnualTotalsResponse>;
}

const initialState: State = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(PayeeDetailsAction.getPaymentEarningDetailAction, (state) => ({
    ...state,
    paymentEarningsDetail: {
      isLoading: true,
    },
  })),
  on(PayeeDetailsAction.getPaymentEarningDetailSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    paymentEarningsDetail: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(PayeeDetailsAction.getPaymentEarningDetailFailure, (state, { error }) => ({
    ...state,
    paymentEarningsDetail: {
      isLoading: false,
      success: false,
      error: error,
      payload: undefined,
    },
  })),
  on(PayeeDetailsAction.clearGetPaymentEarningDetail, (state) => ({
    ...state,
    paymentEarningsDetail: {
      isLoading: false,
      success: false,
      error: false,
      payload: undefined,
    },
  })),
  on(PayeeDetailsAction.getPaymentAnnualTotalsAction, (state) => ({
    ...state,
    paymentAnnualTotals: {
      isLoading: true,
    },
  })),
  on(PayeeDetailsAction.getPaymentAnnualTotalsSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    paymentAnnualTotals: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(PayeeDetailsAction.getPaymentAnnualTotalsFailure, (state, { error }) => ({
    ...state,
    paymentAnnualTotals: {
      isLoading: false,
      success: false,
      error: error,
      payload: undefined,
    },
  })),
  on(PayeeDetailsAction.clearGetPaymentAnnualTotals, (state) => ({
    ...state,
    paymentAnnualTotals: {
      isLoading: false,
      success: false,
      error: false,
      payload: undefined,
    },
  })),

  on(PayeeDetailsAction.getPayeeDeductionsDetailAction, (state, {}) => ({
    ...state,
    payeeDeductionsDetail: {
      isLoading: true,
    },
  })),
  on(PayeeDetailsAction.getPayeeDeductionsDetailSuccess, (state, { payeeDeductionsDetail }) => ({
    ...state,
    payeeDeductionsDetail: {
      isLoading: false,
      success: true,
      error: false,
      payload: payeeDeductionsDetail,
    },
  })),
  on(PayeeDetailsAction.getPayeeDeductionsDetailFailure, (state) => ({
    ...state,
    payeeDeductionsDetail: {
      isLoading: false,
      success: false,
      error: true,
      payload: undefined,
    },
  })),
  on(PayeeDetailsAction.clearGetPayeeDeductionsDetail, (state) => ({
    ...state,
    payeeDeductionsDetail: {
      isLoading: false,
      success: false,
      error: false,
      payload: undefined,
    },
  })),
);
