import { createReducer, on } from '@ngrx/store';
import { getLateFeeReminderLetterAction, getLateFeeReminderLetterSuccessAction, getLateFeeReminderLetterFailureAction, clearGetLateFeeReminderLetterStateAction, generateLateFeeReminderLetterAction, generateLateFeeReminderLetterFailureAction, clearGenerateLateFeeReminderLetterStateAction, getLateFeeReminderLetterDownloadAction, getLateFeeReminderLetterDownloadSuccessAction, clearGetLateFeeReminderLetterDownloadStateAction, generateLateFeeReminderLetterSuccessAction, validateBeforeGenerateAction, validateBeforeGenerateSuccessAction, validateBeforeGenerateFailureAction, clearValidateBeforeGenerateStateAction, getLateFeeReminderLetterDownloadFailureAction } from '@ptg-employer/actions/late-fee-reminder-letter.action';
import { LateFeeReminderLetter, ValidateBeforeGenerateResponse } from '@ptg-employer/models/late-fee-reminder.model';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import { PreviewFileResponse } from 'src/app/admin/features/file/services/models/documents.model';

export const lateFeeReminderLetterFeatureKey = 'lateFeeReminderLetter';

export interface LateFeeReminderLetterState {
  getLateFeeReminderLetterState?: BaseActionState<LateFeeReminderLetter[]>;
  generateLateFeeReminderLetterState?: {
    state: ActionState,
    errorMsg?: string,
  };
  validateBeforeGenerateState?: BaseActionState<ValidateBeforeGenerateResponse>;
  getLateFeeReminderLetterDownloadState?: BaseActionState<PreviewFileResponse>;
}

const initialState: LateFeeReminderLetterState = {};

export const lateFeeReminderLetterReducer = createReducer(
  initialState,

  // Get Late Fee Reminder Letter
  on(getLateFeeReminderLetterAction, (state) => ({
    ...state,
    getLateFeeReminderLetterState: {
      isLoading: true,
    },
  })),
  on(getLateFeeReminderLetterSuccessAction, (state, { response }) => ({
    ...state,
    getLateFeeReminderLetterState: {
      isLoading: false,
      success: true,
      payload: response.letters,
      total: response.total,
    },
  })),
  on(getLateFeeReminderLetterFailureAction, (state, { error }) => ({
    ...state,
    getLateFeeReminderLetterState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetLateFeeReminderLetterStateAction, (state) => ({
    ...state,
    getLateFeeReminderLetterState: undefined,
  })),

  // Generate Late Fee Reminder Letter
  on(generateLateFeeReminderLetterAction, (state) => ({
    ...state,
    isLoading: true,
    generateLateFeeReminderLetterState: undefined,
  })),
  on(generateLateFeeReminderLetterSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    generateLateFeeReminderLetterState: {
      state: {
        action: ACTION.GENERATE,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(generateLateFeeReminderLetterFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    generateLateFeeReminderLetterState: {
      state: {
        action: ACTION.GENERATE,
        state: STATE.FAIL,
      },
      errorMsg: error,
    },
  })),
  on(clearGenerateLateFeeReminderLetterStateAction, (state) => ({
    ...state,
    isLoading: false,
    generateAnnualFeeLetterState: undefined,
  })),

  // Validate Before Generate
  on(validateBeforeGenerateAction, (state) => ({
    ...state,
    validateBeforeGenerateState: {
      isLoading: true,
    },
  })),
  on(validateBeforeGenerateSuccessAction, (state, { response }) => ({
    ...state,
    validateBeforeGenerateState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateBeforeGenerateFailureAction, (state, { error }) => ({
    ...state,
    validateBeforeGenerateState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateBeforeGenerateStateAction, (state) => ({
    ...state,
    validateBeforeGenerateState: undefined,
  })),

  // Download Late Fee Reminder Letter
  on(getLateFeeReminderLetterDownloadAction, (state) => ({
    ...state,
    getLateFeeReminderLetterDownloadState: {
      isLoading: true,
    },
  })),
  on(getLateFeeReminderLetterDownloadSuccessAction, (state, { response }) => ({
    ...state,
    getLateFeeReminderLetterDownloadState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getLateFeeReminderLetterDownloadFailureAction, (state, { error }) => ({
    ...state,
    getLateFeeReminderLetterDownloadState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetLateFeeReminderLetterDownloadStateAction, (state) => ({
    ...state,
    getLateFeeReminderLetterDownloadState: undefined,
  })),
);
