import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import * as TierConfigurationAction from '../actions/tier-configuration.actions';
import {
  BinaryValuesResponse,
  ConditionLookupResponse,
  ConditionOperatorsResponse,
  ConditionPropertiesResponse,
  GetTierConfigurationsResponse,
  PlanResponse,
  Tier,
} from '../../types/models';
import { BaseActionState } from '@ptg-shared/types/models';

export const tierConfigurationFeatureKey = 'tierConfiguration';

export interface State {
  isLoading?: boolean;
  tierConfiguration?: GetTierConfigurationsResponse;
  updateState?: string;
  createState?: string;
  plans?: PlanResponse;
  metadata?: ConditionPropertiesResponse;
  operator?: ConditionOperatorsResponse;
  result?: ConditionLookupResponse;
  resultBinary?: BinaryValuesResponse;
  getTierConfigurations?: BaseActionState<Tier[]>;
  editTierConfiguration?: BaseActionState;
  setTierConfiguration?: BaseActionState;
}

const initialState: State = {
  isLoading: true,
  tierConfiguration: {
    tiers: [],
    totalCount: 0,
  },
  updateState: '',
  createState: '',
  plans: {} as PlanResponse,
  metadata: {} as ConditionPropertiesResponse,
  operator: {} as ConditionOperatorsResponse,
  result: {} as ConditionLookupResponse,
  resultBinary: {} as BinaryValuesResponse,
  getTierConfigurations: undefined,
  editTierConfiguration: undefined,
  setTierConfiguration: undefined,
};
// const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(TierConfigurationAction.clearTierConfigurationState, (state) => ({
    ...state,
    operator: {} as ConditionOperatorsResponse,
    metadata: {} as ConditionPropertiesResponse,
    result: {} as ConditionLookupResponse,
    resultBinary: {} as BinaryValuesResponse,
    plans: {} as PlanResponse,
    updateState: '',
    createState: '',
  })),
  on(TierConfigurationAction.getTierConfiguration, (state) => ({
    ...state,
    getTierConfigurations: {
      isLoading: true,
    },
  })),
  on(TierConfigurationAction.getTierConfigurationSuccess, (state, { response }) => ({
    ...state,
    getTierConfigurations: {
      isLoading: false,
      success: true,
      payload: response.tiers,
      total: response.totalCount,
    },
  })),
  on(TierConfigurationAction.getTierConfigurationFailure, (state, { error }) => ({
    ...state,
    getTierConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(TierConfigurationAction.clearGetTierConfigurationsState, (state) => ({
    ...state,
    getTierConfigurations: undefined,
  })),

  on(TierConfigurationAction.setTierConfiguration, (state) => ({
    ...state,
    setTierConfiguration: {
      isLoading: true,
    },
  })),
  on(TierConfigurationAction.setTierConfigurationsSuccess, (state) => ({
    ...state,
    setTierConfiguration: {
      isLoading: false,
      success: true,
    },
  })),
  on(TierConfigurationAction.setTierConfigurationsFailure, (state, { error }) => ({
    ...state,
    setTierConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(TierConfigurationAction.clearSetTierConfigurations, (state) => ({
    ...state,
    setTierConfiguration: undefined,
  })),

  on(TierConfigurationAction.editTierConfiguration, (state) => ({
    ...state,
    editTierConfiguration: {
      isLoading: true,
    },
  })),
  on(TierConfigurationAction.editTierConfigurationsSuccess, (state) => ({
    ...state,
    editTierConfiguration: {
      isLoading: false,
      success: true,
    },
  })),
  on(TierConfigurationAction.editTierConfigurationsFailure, (state, { error }) => ({
    ...state,
    editTierConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(TierConfigurationAction.clearEditTierConfiguration, (state) => ({
    ...state,
    editTierConfiguration: undefined,
  })),
  on(
    TierConfigurationAction.getTierConfigurationPlanSuccess,
    (state, { plans }) => ({
      ...state,
      isLoading: false,
      plans: plans,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationPlanFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationMetadataSuccess,
    (state, { properties }) => ({
      ...state,
      isLoading: false,
      metadata: properties,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationMetadataFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationOperatorSuccess,
    (state, { operator }) => ({
      ...state,
      isLoading: false,
      operator: operator,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationOperatorFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationConditionLookupSuccess,
    (state, { result }) => ({
      ...state,
      isLoading: false,
      result: result,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationConditionLookupFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationBinaryValuesSuccess,
    (state, { resultBinary }) => ({
      ...state,
      isLoading: false,
      resultBinary: resultBinary,
    })
  ),
  on(
    TierConfigurationAction.getTierConfigurationBinaryValuesFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  )
);
