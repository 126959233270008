<div class="dialog-container" id="edit-event-dialog">
  <div class="header-title">
    {{ memberEvent?.id ? 'Edit Event' : 'Add Event' }}
  </div>

  <form (ngSubmit)="formSubmit$.next()" [formGroup]="editForm" class="edit-form">
    <div class="input-container">
      <ptg-checkbox
        [controlField]="editForm.get('active')"
        label="Active"
      ></ptg-checkbox>
      <ptg-textbox
        [controlField]="editForm.get('name')"
        [hasLabel]="true"
        [maxLength]="150"
        class="input-field"
        [errorAsync]="editForm.get('name')?.errors?.inValidAsync ? editForm.get('name')?.errors?.message : ''"
        placeholder="Event Name"
      ></ptg-textbox>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
