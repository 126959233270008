import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import { takeUntil, take, filter } from 'rxjs/operators';

import { DateTime } from 'luxon';

import {
  CalculationState,
  initiateDisabilityBenefitAction,
  initiateDisabilityBenefitSelector,
  clearInitiateDisabilityBenefitStateAction,
  editDisabilityBenefitAction,
  validateDisabilityBenefitAction,
  validateDisabilityBenefitSelector,
  checkExceptionConfigurationAction,
  checkExceptionSelector,
  clearCheckExceptionConfigurationStateAction,
  clearAddDisabilityStateAction,
  getTotalDaysUsedAction,
  getTotalDaysUsedSelector,
  editCalculationDetailAction,
  editCalculationDetailSelector,
} from '../../store';
import { Store, select } from '@ngrx/store';
import { deepClone, showBanner, toUtcDate } from '@ptg-shared/utils/common.util';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Column, ColumnType, RADIO_COLUMN } from '@ptg-shared/controls/grid';
import {
  RelatedAccident,
  RelatedAccidentGridView,
  InitiateDisabilityBenefitRequest,
} from '@ptg-member/features/calculation/services/models/disability-benefit.model';
import { DISABILITY_TERM_OPTION_LIST } from '@ptg-member/features/calculation/constants';
import {
  CalculationBenefitHistoryStatus,
  CalculationType,
  DisabilityType,
} from '@ptg-member/features/calculation/types/enums';
import {
  getAccidentHistoriesAction,
  clearGetAccidentHistoriesStateAction,
} from '@ptg-member/features/accident-claims/store/actions';
import { getAccidentHistoriesSelector } from '@ptg-member/features/accident-claims/store/selectors';
import { AccidentHistory, AccidentStatus } from '@ptg-member/features/accident-claims/services/models';
import { capitalizeFirstLetter, getDateFormatISO, getDateString, isNullOrUndefinedOrEmptyString } from '@ptg-shared/utils/string.util';
import {
  ACTION,
  BUTTON_LABEL_CLOSE,
  DEFAULT_PAGE_SIZE,
  MAX_VALUE_NUMBER,
  STATE,
  SortType,
} from '@ptg-shared/constance';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { CheckExceptionRequest } from '../../services/models';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { AddDisabilityComponentService } from './add-disability.component.service';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';

@Component({
  selector: 'ptg-add-disability',
  templateUrl: './add-disability.component.html',
  styleUrls: ['./add-disability.component.scss'],
  providers: [AddDisabilityComponentService],
})
export class AddDisabilityComponent extends BaseListComponent {
  form!: FormGroup;
  canSubmit = false;
  isShortTerm = false;
  isShowEndDate = false;
  customValidators: ValidatorFn[] = [];
  memberId = '';
  minBeginDate = MY_DATE.minDate;
  maxBeginDate = MY_DATE.maxDate;
  minEndDate = MY_DATE.minDate;
  maxEndDate = MY_DATE.maxDate;

  isEndDateRequire: boolean = false;

  title = this.dialogTitle;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  relatedAccidentList: AccidentHistory[] = [];
  relatedAccidentGridData: RelatedAccidentGridView[] = [];

  isHasChangeAccident = false;
  isDisabledBeginDate = false;
  isDisabilityTypeDisable = false;
  isLoadingRelatedAccident = true;
  isDisabledSelectAccident = false;

  notFoundMessageRelatedAccident = 'No data to display';
  totalRecordRelatedAccident = 0;
  pageNumberRelatedAccident = FIRST_PAGE;
  pageSizeRelatedAccident = DEFAULT_PAGE_SIZE;
  columnRelatedAccident: Column[] = this.initColumnRelatedAccident;
  calculationType: CalculationType = CalculationType.DisabilityShortTerm;
  sortInfoHistory: Sort = { active: '', direction: 'asc' };
  accidentSelectedId = undefined;

  totalDays = 0;
  formData: any;
  isShowReason: boolean = false;
  readonly RADIO_COLUMN = RADIO_COLUMN;
  readonly MAX_VALUE_NUMBER = MAX_VALUE_NUMBER;
  readonly DISABILITY_TERM_OPTION_LIST = DISABILITY_TERM_OPTION_LIST;

  private calculationBenefitId = '';
  private benefitEntityId = '';
  private calculationRecordId = '';

  totalDaysUsed: string = this.data?.totalDaysUsed ?? '';
  isInitiate: boolean = this.data?.isInitiate;
  isRecalculate: boolean = this.data?.isRecalculateBenefit;
  isEditBenefit: boolean = this.data?.isEditBenefit;
  isEditBenefitCalculation: boolean = this.data?.isEditBenefitCalculation;
  isShowRecalculateCalcEndDate: boolean = false;
  CalculationType = CalculationType;

  beginDatePlaceholder: string = 'Begin Date';
  endDatePlaceholder: string = 'End Date';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public readonly route: ActivatedRoute,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly store: Store<AccidentParticipantsState>,
    private readonly calculationStore: Store<CalculationState>,
    private readonly dialogRef: MatDialogRef<AddDisabilityComponent>,
    private readonly switchConfirmPopupService: SwitchConfirmPopupService,
    private readonly addDisabilityComponentService: AddDisabilityComponentService,
    public layoutService: LayoutService,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.memberId = this.data.memberId;
    this.calculationType = this.data.calculationType;
    this.calculationBenefitId = this.data.calculationBenefitId;

    this.prepareFormPlaceholders();
    this.prepareFormMinMaxDates();
    this.prepareEndDateRequireCondition();

    // Not use this anymore for new refactor codes to adapt US #102901
    // this.maxEndDate = this.getMaxEndDate;

    this.getDefaultData();

    this.listenFormGroupTouched();

    this.getAccidentHistoryList();
    this.listenGetRelatedAccidentState();
    this.handleEditInformation();
    this.getTotalDaysUsed();
    this.selectInitiateDisabilityBenefitState();
    this.selectCheckExceptionState();
    this.selectTotalDaysUsed();
  }

  prepareFormPlaceholders() {
    if (this.isRecalculate || this.isEditBenefitCalculation) {
      this.beginDatePlaceholder = 'Calc. as of Date';
      this.endDatePlaceholder = 'Calc. End Date';
    }
    if (this.isEditBenefit && this.data.calculationType === CalculationType.DisabilityShortTerm) {
      this.beginDatePlaceholder = 'Benefit Begin Date';
      this.endDatePlaceholder = 'Benefit End Date';
    }
  }

  prepareFormMinMaxDates() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Case Initiate
    if (this.isInitiate) {
      this.maxBeginDate = currentDate;
    }

    // Case Edit Benefit
    if (this.isEditBenefit) {

    }

    // Case Edit Benefit Calculation
    if (this.isEditBenefitCalculation) {
      if (this.calculationType === CalculationType.DisabilityShortTerm) {
        this.maxEndDate = currentDate;
      }
    }

    // Case Recalculate
    if (this.isRecalculate) {
      if (this.calculationType === CalculationType.DisabilityShortTerm) {
        this.maxEndDate = currentDate;
      }
    }
  }

  prepareEndDateRequireCondition() {
    // Case Initiate
    if (this.isInitiate) {
      this.isEndDateRequire = true;
    }

    // Case Edit Benefit
    if (this.isEditBenefit) {
      this.isEndDateRequire = false;
    }

    // Case Edit Benefit Calculation
    if (this.isEditBenefitCalculation) {
      if (this.calculationType === CalculationType.DisabilityShortTerm) {
        this.isEndDateRequire = true;
      }
      if (this.calculationType === CalculationType.DisabilityLongTerm) {
        this.isEndDateRequire = false;
      }
    }

    // Case Recalculate
    if (this.isRecalculate) {
      if (this.calculationType === CalculationType.DisabilityShortTerm) {
        this.isEndDateRequire = true;
      }
      if (this.calculationType === CalculationType.DisabilityLongTerm) {
        this.isEndDateRequire = false;
      }
    }
  }

  private getDefaultData() {
    if (!this.data.isRecalculate) {
      this.initFormGroup(this.data);
      return;
    }
    this.isShowReason =
      this.data.isRecalculate &&
      (!this.data.isEdit ||
        (this.data.isEdit &&
          this.data.detailData?.numberOfCalcRecords &&
          this.data.detailData?.numberOfCalcRecords > 1));
    const disabilityType =
      this.data.calculationType === CalculationType.DisabilityLongTerm
        ? DisabilityType['Long-term']
        : DisabilityType['Short-term'];
    const formData = {
      disabilityType,
    };
    this.initFormGroup(formData);
    this.onChangeDisabilityTypeOptionValue();
    this.disabilityTypeCtrl.disable();
    this.relatedAccidentRecordCtrl.disable();
    this.beginDateCtrl.setValue(this.data.calcAsOfDate);
    this.form.setControl('recalReason', this.fb.control('', [Validators.required]));
  }

  handleEditInformation() {
    if (!this.data.isEdit) {
      return;
    }
    this.isDisabilityTypeDisable = true;
    const detailItem = this.data.detailData.editItem;
    this.totalDays = this.data.detailData.totalDays - detailItem?.days;
    if (
      this.data.calculationType === CalculationType.DisabilityLongTerm &&
      this.data.detailData.firstCalculationItem?.status !== CalculationBenefitHistoryStatus.Initiated &&
      !this.data.isRecalculate
    ) {
      this.isDisabledBeginDate = true;
    }
    const disabilityType =
      this.data.calculationType === CalculationType.DisabilityLongTerm
        ? DisabilityType['Long-term']
        : DisabilityType['Short-term'];

    const propertiesRecord = this.data.detailData.propertiesRecord;
    this.benefitEntityId = this.data.detailData.propertiesRecord.benefitEntityId;
    this.calculationBenefitId = this.data.calculationBenefitId;
    this.calculationRecordId = this.data.calculationRecordId;
    this.formData = {
      disabilityType,
      beginDate: propertiesRecord?.beginDate ? DateTime.fromISO(propertiesRecord?.beginDate) : null,
      endDate: propertiesRecord?.endDate ? DateTime.fromISO(propertiesRecord?.endDate) : null,
      days: detailItem?.days ?? null,
      amount: detailItem?.memberAmount ?? null,
      relatedAccidentRecord: this.data.detailData.accident?.accidentId ?? '',
      recalReason: detailItem?.recalReason ?? '',
    };
    this.initFormGroup(this.formData);
    this.listenFormGroupTouched();
    this.onChangeDisabilityTypeOptionValue();
    const rowSelected = this.relatedAccidentGridData?.find(
      (item) => item.accidentId.toLowerCase() === this.data.detailData?.accident?.accidentId?.toLowerCase(),
    );
    this.accidentSelectedId = this.data.detailData?.accident?.accidentId.toLowerCase();
    this.onChangeRadioButton(rowSelected);
    this.isDisabledSelectAccident =
      this.data.calculationType === CalculationType.DisabilityLongTerm &&
      this.data.detailData.firstCalculationItem?.status === CalculationBenefitHistoryStatus.Approved;
  }

  onSave(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.relatedAccidentRecordCtrl.invalid) {
        showBanner.call(this, STATE.FAIL, this.title, ACTION.SAVE, {
          customMessage: 'An approved accident must be selected.',
        });
      }
      this.canSubmit = false;
      return;
    }
    this.bannerType = BannerType.Hidden;
    this.canSubmit = true;
    const formValue = this.form.value;
    if (this.data.isRecalculate) {
      this.handleSaveData();
      return;
    }
    if (formValue.relatedAccidentRecord?.accidentStatus !== AccidentStatus.Approved) {
      showBanner.call(this, STATE.FAIL, this.title, ACTION.SAVE, {
        customMessage: 'An approved accident must be selected.',
      });
      return;
    }

    this.handleSaveData();
  }

  handleSaveData() {
    const formValue = this.form.getRawValue();
    const beginValue = (formValue.beginDate as DateTime).toISO();
    const payload: InitiateDisabilityBenefitRequest = {
      beginDate: getDateFormatISO(beginValue).toString(),
      endDate: formValue.endDate ? getDateFormatISO(formValue.endDate).toString() : undefined,
      accidentId: formValue.relatedAccidentRecord?.accidentId,
      calculationType: this.calculationType,
      days: formValue.days ? parseInt(formValue.days) : undefined,
      memberAmount: formValue.disabilityType === DisabilityType['Short-term'] ? formValue.amount : undefined,
    };
    const calcTotalDays = (payload?.days ?? 0) + this.totalDays;

    // @-> Only use this popup for Initiate Short Term case (US #102901)
    if (this.isShortTerm && this.isInitiate && payload?.days && calcTotalDays > 180) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: `Total Days used of this short-term benefit is ${calcTotalDays}, and it is greater than 180 days. Are you sure you want to proceed?`,
          type: ConfirmType.Cancel,
          title: 'Warning',
          cancelButtonTitle: 'Cancel',
        },
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.handleContinueSaveData(payload, formValue);
          this.canSubmit = false;
        }
        if (!result) {
          return;
        }
      });
      return;
    }

    this.handleContinueSaveData(payload, formValue);
  }

  handleContinueSaveData(payload: any, formValue: any) {
    if (this.data.isRecalculate) {
      const requestInfo = {
        request: {
          beginDate: this.data.benefitBeginDate,
          endDate: payload.endDate,
          calculationType: payload.calculationType,
          days: payload.days,
          memberAmount: payload.memberAmount,
          id: this.data.calculationBenefitId,
          calculateAsOfDate: toUtcDate(DateTime.fromJSDate(new Date(this.beginDateCtrl.value)).startOf('day'))
            .toJSDate()
            .toISOString(),
          recalReason: this.recalReasonControl.value,
        },
        memberId: this.data.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.data?.calculationBenefitId,
        calculationRecordId: this.data?.calculationRecordId,
      };
      //case edit calculation detail
      if (this.data.isEdit) {
        this.layoutService.showLoading = true;
        this.calculationStore.dispatch(editCalculationDetailAction(requestInfo));
        this.selectEditCalculationDetailState();
        return;
      }
      //case initiate calculation
      this.calculationStore.dispatch(initiateDisabilityBenefitAction(requestInfo));
      return;
    }

    this.handleInitiateData(payload);
  }

  handleExceptionWhenEditCalculation() {
    this.getCheckExceptionData();

    this.calculationStore
      .pipe(
        select(checkExceptionSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.layoutService.showLoading = !!response?.isLoading;
        // No exception
        if (response?.success && response?.payload?.exceptionsOccur === false) {
          this.handleClosePopup();
        }
        // Exceptions occur
        else if (response?.success && response?.payload?.exceptionsOccur) {
          if(this.data.isDisabilityCalDetail){
            //now just apply for disability-calculation-detail
            //need to fix common for all component
            this.handleClosePopup();
          } else {
            const confirmResult = this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                title: BannerType.Warning,
                text: 'Please resolve exception to proceed.',
                type: ConfirmType.Warning,
                cancelButtonTitle: BUTTON_LABEL_CLOSE,
                hideConfirmButton: true,
              },
            });
            confirmResult
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => {
                this.handleClosePopup();
              });
          }

        }
      });
  }

  selectEditCalculationDetailState() {
    this.store.pipe(select(editCalculationDetailSelector), takeUntil(this.unsubscribe$)).subscribe((response) => {
      this.layoutService.showLoading = !!response?.isLoading;
      if (response?.success) {
        showBanner.call(this, STATE.SUCCESS, this.title, ACTION.EDIT);
        this.handleExceptionWhenEditCalculation();
      }
      if (!response?.success) {
        if (
          response?.error?.error?.errorType === 'ArgumentException' ||
          response?.error?.error?.errorType === 'CommandException'
        ) {
          showBanner.call(this, STATE.FAIL, this.title, ACTION.SAVE, {
            customMessage: response?.error?.error?.errorMessage?.[1] ?? '',
          });
        }
      }
    });
  }

  private handleClosePopup() {
    this.dialogRef.close();
    this.clearStates();
  }

  private handleInitiateData(payload: InitiateDisabilityBenefitRequest) {
    if (!this.data.isEdit) {
      this.calculationStore.dispatch(
        initiateDisabilityBenefitAction({
          request: payload,
          memberId: this.data.memberId,
        }),
      );
    }

    if (this.data.isEdit) {
      const requestInfo = {
        request: {
          memberId: this.memberId,
          calculationBenefitId: this.data.calculationBenefitId,
          calculationType: this.calculationType,
          processToStatus: CalculationBenefitHistoryStatus.Initiated,
          accidentId: payload.accidentId,
          beginDate: payload.beginDate,
          endDate: payload.endDate,
        },
      };
      this.calculationStore.dispatch(validateDisabilityBenefitAction(requestInfo));
      this.selectEditStatus(payload);
    }
  }

  private selectInitiateDisabilityBenefitState(): void {
    this.store.pipe(select(initiateDisabilityBenefitSelector), takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response?.success) {
        if (!this.data.isEdit) {
          this.benefitEntityId = response?.payload?.benefitEntityId ?? '';
        }
        this.calculationBenefitId = !this.data.isEdit
          ? response?.payload?.calculationBenefitId
          : this.data.calculationBenefitId;
        this.calculationRecordId = !this.data.isEdit
          ? response?.payload?.calculationRecordId
          : this.data.calculationRecordId;
        this.getCheckExceptionData();
      }
      if (!response?.success) {
        if (
          response?.error?.error?.errorType === 'ArgumentException' ||
          response?.error?.error?.errorType === 'CommandException'
        ) {
          showBanner.call(this, STATE.FAIL, this.title, ACTION.SAVE, {
            customMessage: response?.error?.error?.errorMessage[1] ?? '',
          });
        }
      }
    });
  }

  private getCheckExceptionData(): void {
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());

    const request: CheckExceptionRequest = {
      memberId: this.memberId,
      benefitEntityId: this.benefitEntityId ?? '',
      targetId: this.calculationRecordId ?? '',
      targetType: this.calculationType,
    };
    this.layoutService.showLoading = true;
    this.calculationStore.dispatch(checkExceptionConfigurationAction({ request }));
  }

  private selectCheckExceptionState(): void {
    this.calculationStore
      .pipe(
        select(checkExceptionSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.layoutService.showLoading = !!response?.isLoading;
        if (response?.success === true) {
          this.calculationStore.dispatch(clearInitiateDisabilityBenefitStateAction());
          this.clearStates();
          if (this.data.isEdit && this.data.isRecalculate) {
            return;
          }
          this.dialogRef.close(true);

          this.router.navigateByUrl(
            `member/disability-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`,
          );
        }
        if (response?.success === false) {
          this.clearStates();
          this.dialogRef.close(true);
          return;
        }
      });
  }

  selectEditStatus(payload: InitiateDisabilityBenefitRequest) {
    this.calculationStore
      .select(validateDisabilityBenefitSelector)
      .pipe(
        filter((rsp) => !!rsp?.payload),
        take(1),
      )
      .subscribe((res) => {
        this.layoutService.showLoading = !!res?.isLoading;
        if (res?.success && res?.payload?.isValid) {
          this.calculationStore.dispatch(
            editDisabilityBenefitAction({
              request: payload,
              calculationType: this.calculationType,
              calculationBenefitId: this.data.calculationBenefitId,
            }),
          );
        }
        if (!res?.payload?.isValid) {
          const customMessage = this.addDisabilityComponentService.getInvalidMessageOnEdit(
            res?.payload?.errorType,
            res?.payload?.confirmMessage,
          );
          showBanner.call(this, STATE.FAIL, this.title, ACTION.SAVE, { customMessage });
        }
      });
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
    this.clearStates();
  }

  onChangeDisabilityTypeOptionValue(): void {
    this.isShortTerm = this.disabilityTypeCtrl.value === DisabilityType['Short-term'];
    this.isShowEndDate =
      this.isShortTerm ||
      (this.disabilityTypeCtrl.value === DisabilityType['Long-term'] &&
        (this.data.detailData?.firstCalculationItem?.status === CalculationBenefitHistoryStatus.Approved ||
          (this.data.isRecalculate && this.data.isEdit)));

    this.calculationType = this.isShortTerm ? CalculationType.DisabilityShortTerm : CalculationType.DisabilityLongTerm;

    // Reset Max Begin Date back to Current Date in case Initiate and switch to Long Term
    if (this.disabilityTypeCtrl.value === DisabilityType['Long-term'] && this.isInitiate) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      this.maxBeginDate = currentDate;
    }
  }

  onChangeBeginDate(): void {
    const beginDate = this.beginDateCtrl.value;

    // Begin Date should always be equal or lesser than End Date, in all cases
    if (beginDate && this.beginDateCtrl.valid) {
      this.minEndDate = beginDate;
    }

    if (this.beginDateCtrl.value && this.endDateCtrl.value) {
      this.beginDateCtrl.updateValueAndValidity();
      this.beginDateCtrl.markAsTouched();
      this.endDateCtrl.updateValueAndValidity();
      this.endDateCtrl.markAsTouched();
    }
  }

  onChangeEndDate(): void {
    const endDate = this.endDateCtrl.value;

    // Begin Date should always be equal or lesser than End Date, in all cases
    if (endDate && this.endDateCtrl.valid) {
      this.maxBeginDate = endDate;
    }

    if (this.beginDateCtrl.value && this.endDateCtrl.value) {
      this.beginDateCtrl.updateValueAndValidity();
      this.beginDateCtrl.markAsTouched();
      this.endDateCtrl.updateValueAndValidity();
      this.endDateCtrl.markAsTouched();
    }
  }

  onChangeHistoryPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageSize = event.pageSize;
    this.pageNumberRelatedAccident = event.pageNumber;
    this.getAccidentHistoryList();
  }

  onSortChangeHistory(sort: Sort) {
    if (sort.active === 'injury') {
      sort.active = 'injuryCode';
    }
    if (sort.active === 'municipality') {
      sort.active = 'municipalityCode';
    }
    this.sortInfoHistory = sort;
    this.getAccidentHistoryList();
  }

  onChangeRadioButton(row: RelatedAccidentGridView | undefined): void {
    if (!row) {
      return;
    }

    this.relatedAccidentGridData.forEach((item) => {
      item.checked = false;
    });
    row.checked = true;
    this.relatedAccidentRecordCtrl.setValue(row);
    this.isHasChangeAccident = true;
    this.form?.get('beginDate')?.updateValueAndValidity();
  }

  get disabilityTypeCtrl(): FormControl {
    return this.form?.get('disabilityType') as FormControl;
  }

  get beginDateCtrl(): FormControl {
    return this.form.get('beginDate') as FormControl;
  }

  get endDateCtrl(): FormControl {
    return this.form.get('endDate') as FormControl;
  }

  get daysCtrl(): FormControl {
    return this.form.get('days') as FormControl;
  }

  get amountCtrl(): FormControl {
    return this.form.get('amount') as FormControl;
  }

  get relatedAccidentRecordCtrl(): FormControl {
    return this.form.get('relatedAccidentRecord') as FormControl;
  }

  get recalReasonControl(): FormControl {
    return this.form.get('recalReason') as FormControl;
  }

  get totalDaysUsedControl(): FormControl {
    return this.form.get('totalDaysUsed') as FormControl;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearStates();
  }

  customBeginDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Prepare data for validation
      const beginDateValue = control?.parent?.get('beginDate')?.value;
      const beginDate = new Date(DateTime.fromISO(beginDateValue).toFormat('yyyy-MM-dd'));
      beginDate.setHours(0, 0, 0, 0);
      const beginDateTimestamp = beginDate.getTime();


      const endDateValue = control.parent?.get('endDate')?.value;
      const endDate = new Date(DateTime.fromISO(endDateValue).toFormat('yyyy-MM-dd'));
      endDate.setHours(0, 0, 0, 0);
      const endDateTimestamp = endDate.getTime();

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const currentDateTimestamp = currentDate.getTime();

      const relatedAccidentRecordValue = control?.parent?.get('relatedAccidentRecord')?.value;
      const accidentDate = new Date(DateTime.fromISO(relatedAccidentRecordValue?.accidentDate ?? '').toFormat('yyyy-MM-dd'));
      accidentDate.setHours(0, 0, 0, 0);
      const accidentDateTimestamp = accidentDate.getTime();

      // Require validation will be handle by [isRequired]
      if (!beginDateValue) {
        return null;
      }

      // Validate for Begin Date and Accident
      if (beginDateTimestamp && accidentDateTimestamp && beginDateTimestamp < accidentDateTimestamp) {
        return {
          errorMessageBeginDate: "Begin date can't be sooner than Accident date.",
        };
      }

      // Case Initiate
      if (this.isInitiate) {
        // Short Term
        if (this.disabilityTypeCtrl.value === DisabilityType['Short-term']) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "End date can't be less than Begin date.",
            }
          }

          if (beginDateTimestamp && beginDateTimestamp > currentDateTimestamp) {
            return {
              errorMessageBeginDate: "Begin Date cannot be future date.",
            }
          }
        }
        // Long Term
        if (this.disabilityTypeCtrl.value === DisabilityType['Long-term']) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "End date can't be less than Begin date.",
            }
          }
          if (beginDateTimestamp && beginDateTimestamp > currentDateTimestamp) {
            return {
              errorMessageBeginDate: "Begin Date cannot be future date.",
            }
          }
        }
      }


      // Case Edit Benefit
      if (this.isEditBenefit) {
        // Short Term
        if (this.calculationType === CalculationType.DisabilityShortTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "Benefit End date can't be less than Benefit Begin date.",
            }
          }
        }
        // Long Term
        if (this.calculationType === CalculationType.DisabilityLongTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "Benefit End date can't be less than Benefit Begin date.",
            }
          }
        }
      }

      // Case Edit Benefit Calculation
      if (this.isEditBenefitCalculation) {
        // Short Term
        if (this.calculationType === CalculationType.DisabilityShortTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }
        }
        // Long Term
        if (this.calculationType === CalculationType.DisabilityLongTerm) {
          if (beginDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }
        }
      }

      // Case Recalculate
      if (this.isRecalculate) {
        // Short Term
        if (this.calculationType === CalculationType.DisabilityShortTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }
        }
        // Long Term
        if (this.calculationType === CalculationType.DisabilityLongTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageBeginDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }
        }
      }

      // Validate for Days follow Begin Date and End Date change
      this.form?.get('days')?.updateValueAndValidity();

      // Default no error
      return null;
    };
  }

  customEndDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Prepare data for validation
      const beginDateValue = control?.parent?.get('beginDate')?.value;
      const beginDate = new Date(DateTime.fromISO(beginDateValue).toFormat('yyyy-MM-dd'));
      beginDate.setHours(0, 0, 0, 0);
      const beginDateTimestamp = beginDate.getTime();

      const endDateValue = control.parent?.get('endDate')?.value;
      const endDate = new Date(DateTime.fromISO(endDateValue).toFormat('yyyy-MM-dd'));
      endDate.setHours(0, 0, 0, 0);
      const endDateTimestamp = endDate.getTime();

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const currentDateTimestamp = currentDate.getTime();

      // Require validation will be handle by [isRequired]
      if (!endDateValue) {
        return null;
      }

      // Case Initiate
      if (this.isInitiate) {
        // Short Term
        if (this.disabilityTypeCtrl.value === DisabilityType['Short-term']) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "End date can't be less than Begin date.",
            }
          }
        }
        // Long Term
        if (this.disabilityTypeCtrl.value === DisabilityType['Long-term']) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "End date can't be less than Begin date.",
            }
          }
        }
      }


      // Case Edit Benefit
      if (this.isEditBenefit) {
        // Short Term
        if (this.calculationType === CalculationType.DisabilityShortTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "Benefit End date can't be less than Benefit Begin date.",
            }
          }
        }
        // Long Term
        if (this.calculationType === CalculationType.DisabilityLongTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "Benefit End date can't be less than Benefit Begin date.",
            }
          }
        }
      }

      // Case Edit Benefit Calculation
      if (this.isEditBenefitCalculation) {
        // Short Term
        if (this.calculationType === CalculationType.DisabilityShortTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }

          if (endDateTimestamp && endDateTimestamp > currentDateTimestamp) {
            return {
              errorMessageEndDate: "Calculation End Date for short-term benefit cannot be future date.",
            }
          }
        }
        // Long Term
        if (this.calculationType === CalculationType.DisabilityLongTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }
        }
      }

      // Case Recalculate
      if (this.isRecalculate) {
        // Short Term
        if (this.calculationType === CalculationType.DisabilityShortTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }

          if (endDateTimestamp && endDateTimestamp > currentDateTimestamp) {
            return {
              errorMessageEndDate: "Calculation End Date for short-term benefit cannot be future date.",
            }
          }
        }
        // Long Term
        if (this.calculationType === CalculationType.DisabilityLongTerm) {
          if (beginDateTimestamp && endDateTimestamp && beginDateTimestamp > endDateTimestamp) {
            return {
              errorMessageEndDate: "Calculation As of Date cannot be later than Calculation End Date.",
            }
          }
        }
      }

      // Validate for Days follow Begin Date and End Date change
      this.form?.get('days')?.updateValueAndValidity();

      // Default no error
      return null;
    };
  }

  customDaysValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.disabilityTypeCtrl?.value === DisabilityType['Long-term']) {
        return null;
      }
      if (!control?.parent?.get('days')?.value) {
        return null;
      }

      const dayNumber: string = control.parent.get('days')?.value;
      if (!!dayNumber && +dayNumber <= 0) {
        return { errorMessageDays: 'Days must be greater than 0.' };
      }
      const endDate = control.parent.get('endDate')?.value;
      const beginDate =
        this.data.isEdit && this.isDisabledBeginDate
          ? this.data.detailData.editItem.createdDate
          : control.parent.get('beginDate')?.value;
      const rangeDays = this.calculateTotalDays(endDate, beginDate);
      if (!!dayNumber && +dayNumber > rangeDays) {
        return {
          errorMessageDays: 'Days cannot be greater than the duration between Begin Date and End Date.',
        };
      }
      return null;
    };
  }

  customAmountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.disabilityTypeCtrl?.value === DisabilityType['Long-term']) {
        return null;
      }
      if (control?.parent?.get('amount')?.value === null || control?.parent?.get('amount')?.value === undefined) {
        return null;
      }

      const amount: number = control.parent.get('amount')?.value;
      if (amount <= 0) {
        return {
          errorMessageAmount: 'Amount must be greater than 0.',
        };
      }
      return null;
    };
  }

  getAccidentHistoryList() {
    let sortType = SortType.DESC;
    let sortNames = '';
    if (this.sortInfoHistory?.active && this.sortInfoHistory?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfoHistory.active);
      sortType = this.sortInfoHistory.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.store.dispatch(
      getAccidentHistoriesAction({
        request: {
          pageNumber: this.pageNumberRelatedAccident,
          pageSize: this.pageSizeRelatedAccident,
          sortNames,
          sortType,
          forInitiateDisability: true,
        },
        memberId: this.data.memberId,
      }),
    );
  }

  calculateTotalDays(endDate: string, beginDate: string): number {
    const date1 = DateTime.fromISO(endDate);
    const date2 = DateTime.fromISO(beginDate);

    const diff = date1.diff(date2, ['days']).toObject();

    return isNullOrUndefinedOrEmptyString(diff.days) ? 0 : diff.days as number + 1;
  }

  private listenGetRelatedAccidentState(): void {
    this.store.pipe(select(getAccidentHistoriesSelector), takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response?.success) {
        const { raw, relatedAccidentGridData } = this.manipulatedRelatedAccidentGridData(response.payload ?? []);
        this.relatedAccidentList = raw;
        this.totalRecordRelatedAccident = response.total ?? 0;
        this.relatedAccidentGridData = relatedAccidentGridData;
        this.isLoadingRelatedAccident = false;
        const item = this.accidentSelectedId
          ? relatedAccidentGridData.find((item) => item.accidentId.toLowerCase() === this.accidentSelectedId)
          : null;
        this.onChangeRadioButton(item);
      }
    });
  }

  private manipulatedRelatedAccidentGridData(relatedAccidentList: AccidentHistory[]): {
    raw: AccidentHistory[];
    relatedAccidentGridData: any[];
  } {
    const listAllowAccident = relatedAccidentList.filter(
      (item: any) => item.accidentStatus === AccidentStatus.Approved,
    );
    this.relatedAccidentList = deepClone(listAllowAccident);
    const relatedAccidentGridData = this.relatedAccidentList.map((accidentItem: any) => {
      const statusWithDate =
        accidentItem?.accidentStatus === AccidentStatus.Pending
          ? 'Pending Review'
          : `${AccidentStatus[accidentItem?.accidentStatus]} (${this.datePipe.transform(
              getDateString(accidentItem.lastStatusDate),
              'MM/dd/yyyy',
            )})`;

      return {
        ...accidentItem,
        status: accidentItem.lastStatusDate ? statusWithDate : AccidentStatus[accidentItem.accidentStatus],
        injury: `${accidentItem.injuryCode ?? ''} - ${accidentItem.injuryCodeDescription ?? ''}`,
        municipality: `${accidentItem.municipalityCode ?? ''} ${'(' + accidentItem.municipalityName + ')' ?? ''}`,
      };
    });

    return {
      raw: relatedAccidentList,
      relatedAccidentGridData,
    };
  }

  private listenFormGroupTouched(): void {
    this.form.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.canSubmit = this.form.touched || this.form.dirty || this.isHasChangeAccident;
    });
  }

  initFormGroup(formData: any | null) {
    const isEditBenefitShortTerm = this.isEditBenefit && this.data.calculationType === CalculationType.DisabilityShortTerm;
    this.isShowRecalculateCalcEndDate = this.isRecalculate && this.data.calculationType === CalculationType.DisabilityLongTerm;
    this.form = this.fb.group({
      disabilityType: this.fb.control(formData?.disabilityType),
      beginDate: this.fb.control(formData?.beginDate, [this.customBeginDateValidator()]),
      endDate: this.fb.control(formData?.endDate, [this.customEndDateValidator()]),
      days: this.fb.control(formData?.days, isEditBenefitShortTerm ? [] : [this.customDaysValidator()]),
      amount: this.fb.control(formData?.amount ?? null, isEditBenefitShortTerm ? [] : [this.customAmountValidator()]),
      relatedAccidentRecord: this.fb.control(formData?.accidentId),
      recalReason: this.fb.control(formData?.recalReason, this.isShowReason ? [Validators.required] : []),
      ...isEditBenefitShortTerm && { totalDaysUsed: this.fb.control(this.totalDaysUsed) },
    });

    if (!this.data.isEdit) {
      this.relatedAccidentRecordCtrl.setValidators(Validators.required);
    }
  }

  getTotalDaysUsed() {
    this.calculationStore.dispatch(
      getTotalDaysUsedAction({
        memberId: this.memberId,
        calculationType: this.calculationType || CalculationType.DisabilityShortTerm,
        calculationBenefitId: this.data.isEdit || this.data.isRecalculate ? this.calculationBenefitId : undefined,
      }),
    );
  }

  private get getMaxEndDate(): Date {
    if (this.isEditBenefitCalculation && this.calculationType === CalculationType.DisabilityShortTerm) {
      return new Date();
    }
    if (
      !this.data.isRecalculate ||
      (this.data.isRecalculate && this.data.isEdit && this.calculationType === CalculationType.DisabilityLongTerm)
    ) {
      return MY_DATE.maxDate;
    }

    const dates = [];
    const currentDate = new Date();
    const benefitEndDate = this.data.benefitEndDate
      ? DateTime.fromISO(getDateString(this.data.benefitEndDate), { zone: 'utc' }).toJSDate()
      : new Date();
    dates.push(currentDate);
    dates.push(benefitEndDate);
    const sorted = dates.slice().sort((a, b) => {
      return a.getTime() - b.getTime();
    });

    return sorted[0];
  }

  private selectTotalDaysUsed(): void {
    this.calculationStore
      .pipe(
        select(getTotalDaysUsedSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success) {
          this.totalDays = this.data?.isEdit
            ? response.payload?.totalDays
              ? response.payload?.totalDays - this.data.detailData.editItem?.days
              : this.data.detailData.editItem?.days
            : response.payload?.totalDays ?? 0;
        }
      });
  }

  private clearStates(): void {
    this.calculationStore.dispatch(clearGetAccidentHistoriesStateAction());
    this.calculationStore.dispatch(clearAddDisabilityStateAction());
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
  }

  private get initColumnRelatedAccident(): Column[] {
    return [
      {
        name: 'radio',
      },
      {
        name: 'accidentNumber',
        header: {
          title: 'Accident ID',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'accidentDate',
        header: {
          title: 'Accident Date',
        },
        type: ColumnType.DateTime,
        templateArgs: {
          format: 'MM/dd/yyyy',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'receiveDate',
        header: {
          title: 'Receive Date',
        },
        type: ColumnType.DateTime,
        templateArgs: {
          format: 'MM/dd/yyyy',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'injury',
        header: {
          title: 'Injury',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'municipality',
        header: {
          title: 'Municipality',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'status',
        header: {
          title: 'Status',
        },
        truncate: true,
        sortable: true,
      },
    ];
  }

  get dialogTitle() {
    if (this.data.isEdit && this.data.isRecalculate) {
      return 'Edit Disability Calculation';
    }
    if (this.data.isEdit) {
      return 'Edit Disability';
    }
    if (this.data.isRecalculate) {
      return 'Recalculate Disability Calculation';
    }
    return 'Add New Disability';
  }
}
