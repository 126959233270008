import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BenefitPeriodPayload, GetPaymentNoteMemoRequest, GetPaymentNoteMemoResponse, PayableDatePayload, UpdatePaymentNoteMemoRequest } from '../types/models';


@Injectable({
  providedIn: 'root'
})
export class NoteMemoService {
  constructor(private httpClient: HttpClient) { }

  getNotes(query: GetPaymentNoteMemoRequest, paymentInstructionId: string): Observable<GetPaymentNoteMemoResponse> {
    let params = new HttpParams();
    if ( query?.totalPerPage) {
      params = params.append('PageSize', query?.totalPerPage);
    }
    if ( query?.pageIndex) {
      params = params.append('PageIndex', query?.pageIndex);
    }
    if (query?.paymentInstructionHistoryId) {
      params = params.append('paymentInstructionHistoryId', query?.paymentInstructionHistoryId);
    }
    if ( query?.payrollRunId) {
      params = params.append('PayrollRunId', query?.payrollRunId);
    }
    if (!query.isAdjustment) {
      return this.httpClient.get<GetPaymentNoteMemoResponse>(`${environment.apiUrl}/new-payroll/note-and-memo/payment-instruction-id/${paymentInstructionId}`,{ params });
    }
    return this.httpClient.get<GetPaymentNoteMemoResponse>(`${environment.apiUrl}/new-payroll/note-and-memo/adjustment/${paymentInstructionId}`);
    
  }

  addNote(body: UpdatePaymentNoteMemoRequest): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/new-payroll/note-and-memo`, body );
  }

  checkConditionEditPayableDate(paymentInstructionId: string, benefitTypeOptionId: string): Observable<{isValid: boolean}> {
    return this.httpClient.get<{isValid: boolean}>(`${environment.apiUrl}/new-payroll/one-time-payment/validate-display-payable-date/${paymentInstructionId}?benefitTypeOptionId=${benefitTypeOptionId}`)
  }

  checkConditionEditBenefitPeriod(paymentInstructionId: string, benefitTypeOptionId: string): Observable<{isValid: boolean}> {
    return this.httpClient.get<{isValid: boolean}>(`${environment.apiUrl}/new-payroll/one-time-payment/validate-display-edit-benefit-period/${paymentInstructionId}?benefitTypeOptionId=${benefitTypeOptionId}`)
  }

  editPayableDate(body: PayableDatePayload): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/new-payroll/payments/one-time-payment/payment-instruction/update-payable-date`, body );
  }

  editBenefitPeriod(body: BenefitPeriodPayload): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/new-payroll/payments/one-time-payment/payment-instruction/update-benefit-period`, body );
  }
}
