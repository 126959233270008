<div class="initial-payment-configuration">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <div class="initial-payment-content">
      <div class="flex justify-start gap-3 mb-5 wrap-btn">
        <ptg-button (click)="onSave()" classInput="primary" buttonName="Save"></ptg-button>
        <ptg-button (click)="onCancel()" classInput="tertiary" buttonName="Cancel"></ptg-button>
      </div>

      <ptg-toggle-button
        [controlField]="configValueControl"
        label="Combine initial payment in the first recurring payment"
      ></ptg-toggle-button>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </ng-template>
</div>
