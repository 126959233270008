import { createAction, props } from '@ngrx/store';
import { UpsertEntityDataRequest } from '@ptg-entity-management/services/models';

export const createMemberAction = createAction(
  '[CreateMember/API] Send Request',
  props<{ request: UpsertEntityDataRequest }>()
);

export const createMemberSuccessAction = createAction(
  '[CreateMember/API] Success',
  props<{ memberId: string }>()
);

export const createMemberFailureAction = createAction(
  '[CreateMember/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateMemberStateAction = createAction(
  '[CreateMember] Clear'
);

export const updateMemberCardAction = createAction(
  '[UpdateMemberCard/API] Send Request',
  props<{ memberId: string, cardName: string, request: UpsertEntityDataRequest, cardId?: string, auditTrailReason?: string }>()
);

export const updateMemberCardSuccessAction = createAction(
  '[UpdateMemberCard/API] Success',
  props<{ cardName: string }>()
);

export const updateMemberCardFailureAction = createAction(
  '[UpdateMemberCard/API] Failure',
  props<{cardName: string, error?: any }>()
);

export const clearUpdateMemberCardStateAction = createAction(
  '[UpdateMemberCard] Clear'
);

export const setMemberEventAction = createAction(
  '[SetMemberStatusEvent/API] Send Request',
  props<{ memberId: string, cardName: string, request: any }>()
);

export const setMemberEventSuccessAction = createAction(
  '[SetMemberStatusEvent/API] Success'
);

export const setMemberEventFailureAction = createAction(
  '[SetMemberStatusEvent/API] Failure',
  props<{error?: any }>()
);

export const clearSetMemberEventStateAction = createAction(
  '[SetMemberStatusEvent] Clear'
);

export const setNonMemberToMemberAction = createAction(
  '[SetNonMemberToMember/API] Send Request',
  props<{ recordId: string, request: UpsertEntityDataRequest }>()
);

export const setNonMemberToMemberSuccessAction = createAction(
  '[SetNonMemberToMember/API] Success',
  props<{ memberId: string }>()
);

export const setNonMemberToMemberFailureAction = createAction(
  '[SetNonMemberToMember/API] Failure',
  props<{ error?: any }>()
);

export const clearSetNonMemberToMemberStateAction = createAction(
  '[SetNonMemberToMember] Clear'
);

