import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MemberNavigationResponse, MemberNavigationItem } from '@ptg-member/types/models';
import { clearProfileNavigationItemState } from '@ptg-shared/layout/actions/layout.actions';
import { ViewFixType } from '@ptg-shared/layout/models/layout.model';
import { selectProfileNavigationState } from '@ptg-shared/layout/reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function navigateToPaymentInformation(
  context: {
    router: Router;
    store: Store;
    unsubscribe$: Subject<void>;
    getPaymentInformationParams?: () => { [key: string]: string };
    beforeNavigate?: () => void;
    clickedRow: undefined | { payeeRecordId: string; payeeEntityId: string; id: string; benefitId: string };
  },
  instructionIdKey: string,
) {
  context.store
    .select(selectProfileNavigationState)
    .pipe(takeUntil(context.unsubscribe$))
    .subscribe((menuItem) => {
      const memberNavigationMenus = menuItem?.memberNavigationList.memberNavigationMenu || [];
      if (!context.clickedRow || !memberNavigationMenus.length) {
        return;
      }
      context.store.dispatch(clearProfileNavigationItemState());
      const clickedRow = context.clickedRow;
      context.clickedRow = undefined;
      const paymentInformationMenuItem = findPaymentInformationMenuItem(memberNavigationMenus);
      if (paymentInformationMenuItem) {
        const menuId = paymentInformationMenuItem.id;
        const menuEntityViewId = paymentInformationMenuItem.entityViewId;
        const payeeRecordId = clickedRow.payeeRecordId;
        const entityReferenceLinkedId = clickedRow.payeeEntityId;
        const instructionId = clickedRow[instructionIdKey as keyof typeof clickedRow];
        const benefitId = clickedRow.benefitId;
        const params = new HttpParams({
          fromObject: {
            benefitId,
            instructionId,
            entityReferenceLinkedId,
            ...context.getPaymentInformationParams?.(),
          },
        });
        context.beforeNavigate?.();
        const url = `/member/system-view/payment-information/${menuId}/${menuEntityViewId}/${payeeRecordId}?${params.toString()}`;
        context.router.navigateByUrl(url);
      }
    });
}
function findPaymentInformationMenuItem(
  memberNavigationMenus: MemberNavigationResponse[],
): MemberNavigationItem | undefined {
  let menu: any;
  for (const memberNavigationMenu of memberNavigationMenus) {
    for (const menuItem of memberNavigationMenu.menuItems) {
      if (menuItem.viewFixType === ViewFixType.PaymentInformation) {
        menu = menuItem;
      }
    }
  }
  return menu;
}
