import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Option } from '@ptg-shared/controls/select/select.component';

import { FLAT_TYPE_LABEL, FEDERAL_TABLE_FORM_TYPE_LABEL, FILLING_STATUS_TYPE_LABEL } from '../../types/constants';

import {
  FlatType,
  FillingStatusType,
  FederalTableFormType,
  PayeeSourceType,
} from '@ptg-processing/features/taxes/types/enums';

import {
  Withholding,
  ParsedWithholding,
  ParsedEditPayrollSettings,
  SaveEditPayrollSettingsRequest,
  PayrollSetting,
} from '../../services/models/edit-payroll-settings.model';

import { deepClone } from '@ptg-shared/utils/common.util';
import { isEmpty, localeCompare } from '@ptg-shared/utils/string.util';

import { AddressPipe } from '@ptg-shared/pipes/address.pipe';
import { AddressData, PayeeElementDetail, PayeeResponse } from '../../services/models';

@Injectable()
export class EditPayrollSettingsComponentService {
  readonly federalTableFormTypeOptionList: Option[] = [
    { value: FederalTableFormType.None, displayValue: FEDERAL_TABLE_FORM_TYPE_LABEL[FederalTableFormType.None] },
    { value: FederalTableFormType.W4, displayValue: FEDERAL_TABLE_FORM_TYPE_LABEL[FederalTableFormType.W4] },
    { value: FederalTableFormType.W4P, displayValue: FEDERAL_TABLE_FORM_TYPE_LABEL[FederalTableFormType.W4P] },
  ];
  readonly withholdingNoneOption: Option = {
    value: {
      taxTableFormType: FederalTableFormType.None,
      fillingStatus: FillingStatusType.None,
    },
    displayValue: FILLING_STATUS_TYPE_LABEL[FillingStatusType.None],
  };
  readonly additionalWithholdingOptionList: Option[] = [
    {
      value: FlatType.None,
      displayValue: FLAT_TYPE_LABEL[FlatType.None],
    },
    {
      value: FlatType.Flat,
      displayValue: FLAT_TYPE_LABEL[FlatType.Flat],
    },
    {
      value: FlatType.Percent,
      displayValue: FLAT_TYPE_LABEL[FlatType.Percent],
    },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly addressPipe: AddressPipe,
  ) {}

  getInitForm(showFederalColumn: boolean, showStateColumn: boolean): FormGroup {
    const getCommonFormControls = () =>
      this.fb.group({
        form: null,
        // Withholding and its dependent components
        withholding: null,
        exemption: null,
        incomeFromJobPensionAnnuities: null,
        claimDependents: null,
        additionalIncome: null,
        additionalDeductions: null,
        // Additional withholding and its dependent components
        additionalWithholding: [null, Validators.required],
        flatAmount: null,
        percent: null,
      });

    return this.fb.group({
      // Top section
      hasRepresentativePayee: false,
      representativesPayee: null,
      namePrintedOnCheck: null,
      paymentAddress: null,
      // Bottom section
      federal: showFederalColumn ? getCommonFormControls() : null,
      state: showStateColumn ? getCommonFormControls() : null,
      issueToEstate: false,
      payee: null,
    });
  }

  getInitData(data: PayrollSetting, data2: PayeeResponse): ParsedEditPayrollSettings {
    const { isRepresentative = false, savedPayrollSetting } = deepClone(data);
    const { payeeName, relatedDataList, isAbleReissueToEstate, estateDataList } = deepClone(data2);

    // System Setting Data
    const currentPayeeName: string = payeeName ?? '';
    const withholdingOptionList: Option[] = this.getWithholdingOptionList;
    const representativePayeeOptionList: Option[] = this.getRepresentativePayeeOptionList(relatedDataList);
    const estatePayeeOptionList: Option[] = this.getEstatePayeeOptionList(estateDataList);

    // Saved Setting Data
    const savedPaymentAddressCode: string = savedPayrollSetting.addressCode ?? '';
    const savedRepresentativePayeeId: string = savedPayrollSetting.representativePayee?.representativePayeeId ?? '';
    const savedNamePrintedOnCheck: string = savedPayrollSetting.representativePayee?.printedName ?? '';
    const savedWithholding: ParsedWithholding | null = this.getSavedWithholding(savedPayrollSetting.withholding);

    return {
      systemSettings: {
        currentPayeeName,
        representativePayeeOptionList,
        withholdingOptionList,
        estatePayeeOptionList,
      },
      savedSettings: {
        isRepresentative,
        savedRepresentativePayeeId,
        savedNamePrintedOnCheck,
        savedPaymentAddressCode,
        savedWithholding,
        isAbleReissueToEstate,
      },
    };
  }

  private get getWithholdingOptionList(): Option[] {
    return Object.values(FillingStatusType)
      .filter((v) => typeof v !== 'string')
      .map((v: any) => {
        let taxTableFormType = FederalTableFormType.None;
        if (
          Object.values([FillingStatusType.Single, FillingStatusType.Married])
            .filter((v) => typeof v !== 'string')
            .includes(v)
        ) {
          taxTableFormType = FederalTableFormType.W4;
        } else if (
          Object.values([
            FillingStatusType.SingleOrMarried,
            FillingStatusType.MarriedOrQualifySpouse,
            FillingStatusType.HeadOfHousehold,
          ])
            .filter((v) => typeof v !== 'string')
            .includes(v)
        ) {
          taxTableFormType = FederalTableFormType.W4P;
        }
        return {
          value: {
            taxTableFormType,
            fillingStatus: v as FillingStatusType,
          },
          displayValue: FILLING_STATUS_TYPE_LABEL[v as FillingStatusType],
        };
      });
  }

  private getRepresentativePayeeOptionList(representativePayee: PayeeElementDetail[]): Option[] {
    const representativePayeeOptionList = (representativePayee ?? []).map((item) => ({
      value: item,
      displayValue: item?.name ?? '',
      valueDescription: item?.relationship ?? '',
    }));

    const firstNameSortFilter = (a: Option, b: Option) =>
      localeCompare(a.displayValue?.toLowerCase(), b.displayValue?.toLowerCase());

    const agentList = representativePayeeOptionList
      .filter((item) => item.value?.relationship?.toLowerCase() === 'agent')
      .sort(firstNameSortFilter);

    const contactList = representativePayeeOptionList
      .filter((item) => item.value?.relationship?.toLowerCase() === 'contact')
      .sort(firstNameSortFilter);

    const relationshipList = representativePayeeOptionList
      .filter((item) => ![...agentList, ...contactList].includes(item))
      .sort((a: Option, b: Option) => {
        const sortRelationshipResult = localeCompare(
          (a.value as PayeeElementDetail)?.relationship?.toLowerCase(),
          (b.value as PayeeElementDetail)?.relationship?.toLowerCase(),
        );
        return !sortRelationshipResult
          ? localeCompare(a.displayValue?.toLowerCase(), b.displayValue?.toLowerCase())
          : sortRelationshipResult;
      });

    return [...agentList, ...relationshipList, ...contactList];
  }

  private getEstatePayeeOptionList(estatePayeeList: PayeeElementDetail[]): Option[] {
    return (estatePayeeList ?? []).map((item) => ({
      displayValue: item.name,
      value: item.id,
      valueDescription: item.relationship,
      extraData: item.isPerson,
    }));
  }

  getAddressOptionList(addressList?: AddressData[], payeeEntityId?: string): Option[] {
    return deepClone(addressList ?? [])
      .sort((a: AddressData, b: AddressData) => {
        if (a.propertyName > b.propertyName) {
          return 1;
        } else if (a.propertyName === b.propertyName) {
          return a.address.street1 > b.address.street1 ? 1 : -1;
        }
        return -1;
      })
      .map((item) => ({
        displayValue: `${item.list ? item.list + '/' : ''}${item.propertyName}`,
        value: {
          addressCode: item?.address?.code,
          payeeEntityId,
        },
        valueDescription: this.addressPipe.transform(item.address),
        displayValueStyle: 'color: #1d7d51; font-weight: 700',
        hasSeparator: true,
        additionalDataConfig: { value: this.addressPipe.transform(item.address), style: 'color: #303030;' },
      }));
  }

  private getSavedWithholding(withholding?: Withholding): ParsedWithholding | null {
    if (!withholding) {
      return null;
    }

    const {
      form,
      federalTableId,
      fillingStatus,
      exemption,
      income,
      claimDependents,
      additionalIncome,
      additionalDeductions,
      value,
      type,
    } = withholding;

    const flatAmount = type === FlatType.Flat ? value : null;
    const percent = type === FlatType.Percent ? value : null;

    return {
      federal: {
        form,
        // Withholding and its dependent components
        federalTableId,
        fillingStatus,
        exemption,
        incomeFromJobPensionAnnuities: income,
        claimDependents,
        additionalIncome,
        additionalDeductions,
        // Additional withholding and its dependent components
        additionalWithholding: type,
        flatAmount,
        percent,
      },
    };
  }

  getSaveRequest(
    formData: any,
    currentPayee: { id: string; isPerson: boolean; name: string },
  ): SaveEditPayrollSettingsRequest {
    const { hasRepresentativePayee, representativesPayee, namePrintedOnCheck, paymentAddress, federal } = formData;
    const payee = hasRepresentativePayee ? representativesPayee : currentPayee;

    let additionalWithholdingValue: number | undefined;
    if (federal.additionalWithholding === FlatType.Flat) {
      additionalWithholdingValue = federal.flatAmount;
    } else if (federal.additionalWithholding === FlatType.Percent) {
      additionalWithholdingValue = federal.percent;
    }

    const withholdingInfos = {
      taxableId: null,
      formId: !isEmpty(federal.form) ? federal.form : null,
      exemption: typeof federal.exemption === 'string' && federal.exemption.length ? +federal.exemption : null,
      fillingStatus:
        federal.withholding && !isEmpty(federal.withholding?.fillingStatus)
          ? federal.withholding.fillingStatus
          : FillingStatusType.None,
      income: federal.incomeFromJobPensionAnnuities,
      claimDependents: federal.claimDependents,
      additionalIncome: federal.additionalIncome,
      additionalDeductions: federal.additionalDeductions,
      flatType: federal.additionalWithholding ?? FlatType.None,
      value: additionalWithholdingValue ?? null,
    };

    const payeeInfos = {
      isRepresentativePayee: hasRepresentativePayee,
      representativeId: hasRepresentativePayee ? representativesPayee?.id : payee?.id,
      printedName: hasRepresentativePayee ? namePrintedOnCheck : null,
      payeeSourceType: payee?.isPerson ? PayeeSourceType.Person : PayeeSourceType.Organization,
      addressCode: paymentAddress?.addressCode ?? null,
      estateId: formData.payee,
    };

    return { ...withholdingInfos, ...payeeInfos };
  }
}
