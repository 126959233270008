<div class="add-member-filter-dialog" id="add-member-filter-dialog">
  <div class="title">{{ data?.currentFilters ? 'Edit' : 'New' }} Filter</div>
  <div class="filter-select-container">
    <div class="filter-select-item">
      <div *ngIf="filterFormGroup.value?.filterBy?.type === 102" class="status-label">Status</div>
      <div class="filter-item">
        <ptg-select
          (changeOptionValue)="onCardSelection($event)"
          [controlField]="filterFormGroup.get('cardId')"
          [hasNoneValue]="true"
          [isMultipleLineOption]="true"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [listData]="cardSeclectionOptions"
          panelClass="property-name-dropdown"
          placeholder="Card Selection"
        >
        </ptg-select>
        <ptg-select
          (changeOptionValue)="onFilterBySelect()"
          [controlField]="filterFormGroup.get('filterBy')"
          [isMultipleLineOption]="true"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [listData]="filterByOptions"
          panelClass="property-name-dropdown"
          placeholder="Filter By"
        >
        </ptg-select>
        <ptg-select
          (changeOptionValue)="onFilterOperatorSelect()"
          [controlField]="filterFormGroup.get('operator')"
          [isCheckChange]="true"
          [isOptionObj]="true"
          [listData]="filterOperators"
          placeholder="Operator"
        ></ptg-select>
        <ng-container [ngSwitch]="inputType">
          <ng-container *ngSwitchCase="TEXT_BOX_INPUT_TYPE.TYPE_TEXT">
            <ptg-textbox
              class="value-input-field"
              [controlField]="filterFormGroup.get('valueFrom')"
              [placeholder]="placeholder.from"
              [isRequired]="!!placeholder.from"
              [inputType]="inputType"
              [hasLabel]="true"
            >
            </ptg-textbox>
            <ptg-textbox
              *ngIf="filterFormGroup.value?.operator === 8"
              class="value-input-field"
              [controlField]="filterFormGroup.get('valueTo')"
              [placeholder]="placeholder.to"
              [isRequired]="!!placeholder.to"
              [inputType]="inputType"
              [hasLabel]="true"
            >
            </ptg-textbox>
          </ng-container>
          <ng-container *ngSwitchCase="TEXT_BOX_INPUT_TYPE.TYPE_NUMBER">
            <ptg-textbox
              class="value-input-field"
              [controlField]="filterFormGroup.get('valueFrom')"
              [placeholder]="placeholder.from"
              [isRequired]="!!placeholder.from"
              [inputType]="inputType"
              [isPositive]="inputConfig.isPositive"
              [isDecimal]="inputConfig.isDecimal"
              [allowZero]="inputConfig.allowZero"
              [suffix]="inputConfig.suffix"
              [checkMinMaxValue]="false"
              [hasLabel]="true"
              (onBlur)="onValueFromChange($event)"
              [isNumberOnly]="true"
            >
            </ptg-textbox>
            <ptg-textbox
              *ngIf="filterFormGroup.value?.operator === 8"
              class="value-input-field"
              [controlField]="filterFormGroup.get('valueTo')"
              [placeholder]="placeholder.to"
              [isRequired]="!!placeholder.to"
              [inputType]="inputType"
              [isPositive]="inputConfig.isPositive"
              [isDecimal]="inputConfig.isDecimal"
              [allowZero]="inputConfig.allowZero"
              [suffix]="inputConfig.suffix"
              [checkMinMaxValue]="false"
              [customError]="'fromToError'"
              [hasLabel]="true"
              [isNumberOnly]="true"
            >
            </ptg-textbox>
          </ng-container>
          <ng-container *ngSwitchCase="'Date'">
            <ptg-datepicker
              [controlField]="filterFormGroup.get('valueFrom')"
              class="value-input-field"
              [isRequired]="!!placeholder.from"
              [placeholder]="placeholder.from"
              (changeValue)="onChangeFromDate()"
            >
            </ptg-datepicker>
            <ptg-datepicker
              *ngIf="filterFormGroup.value?.operator === 8"
              class="value-input-field"
              [controlField]="filterFormGroup.get('valueTo')"
              [isRequired]="!!placeholder.to"
              [placeholder]="placeholder.to"
              [customError]="'fromToError'"
            >
            </ptg-datepicker>
          </ng-container>
          <ng-container *ngSwitchCase="'DateTime'">
            <div
              class="date-time-input-container"
              [ngClass]="{ 'multiple-input': filterFormGroup.value?.operator === 8 }"
            >
              <ptg-datetime-picker
                [controlField]="filterFormGroup.get('valueFrom')"
                class="value-input-field"
                [isDisabled]="filterFormGroup.value?.operator === 0 || filterFormGroup.value?.operator === 1"
                [isRequired]="!!placeholder.from"
                [datePlaceholder]="filterFormGroup.value?.operator == 8 ? 'Date From' : 'Date'"
                [timePlaceholder]="filterFormGroup.value?.operator == 8 ? 'Time From' : 'Time'"
                [filterDateTime]="filterFormGroup.value?.operator == 8 ? true : false"
                (changeValue)="onChangeFromDate()"
              >
              </ptg-datetime-picker>
              <ptg-datetime-picker
                *ngIf="filterFormGroup.value?.operator === 8"
                class="value-input-field"
                [controlField]="filterFormGroup.get('valueTo')"
                [isDisabled]="filterFormGroup.value?.operator === 0 || filterFormGroup.value?.operator === 1"
                [isRequired]="!!placeholder.to"
                [customError]="'fromToError'"
                datePlaceholder="Date To"
                timePlaceholder="Time To"
                [filterDateTime]="true"
                [filterTimeTo]="true"
                (changeValue)="onChangeFromDate()"
              >
              </ptg-datetime-picker>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ptg-select
              *ngIf="filterFormGroup.value?.operator !== 9"
              class="value-input-field"
              [controlField]="filterFormGroup.get('valueFrom')"
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="valueOptions"
              [isRequired]="!!filterFormGroup.value?.operator"
              [isMultipleLineOption]="isMultipleLineOption"
              panelClass="property-name-dropdown"
              placeholder="Value"
              (changeOptionValue)="changeStatus()"
            >
            </ptg-select>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="filterFormGroup.value?.filterBy?.type === 102">
      <div class="filter-select-item">
        <div class="status-label">Event</div>
        <div class="filter-item status-item">
          <ptg-select
            (changeOptionValue)="onEventOperatorSelect()"
            [controlField]="filterFormGroup.get('event')?.get('operator')"
            [isCheckChange]="true"
            [isOptionObj]="true"
            [listData]="eventOperatorOptions"
            [isRequired]="!!filterFormGroup.value?.event?.valueFrom"
            placeholder="Operator"
          ></ptg-select>
          <ptg-select
            *ngIf="filterFormGroup.value?.event?.operator !== 9"
            class="value-input-field"
            (changeOptionValue)="onEventValueSelect()"
            [controlField]="filterFormGroup.get('event')?.get('valueFrom')"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [listData]="eventOptions"
            [isRequired]="!!filterFormGroup.value?.event?.operator"
            panelClass="property-name-dropdown"
            placeholder="Value"
          >
          </ptg-select>
        </div>
      </div>

      <div class="filter-select-item">
        <div class="status-label">Event Date</div>
        <div class="filter-item status-item">
          <ptg-select
            (changeOptionValue)="changeDateOperator()"
            [controlField]="filterFormGroup.get('date')?.get('operator')"
            [isCheckChange]="true"
            [isOptionObj]="true"
            [listData]="dateOperatorOptions"
            [isRequired]="
              filterFormGroup.value.date?.operator !== 12 &&
              (filterFormGroup.value.date?.valueFrom || filterFormGroup.value.date?.valueTo)
            "
            placeholder="Operator"
          ></ptg-select>
          <ptg-datepicker
            *ngIf="![11, 12].includes(filterFormGroup.value.date?.operator)"
            (changeValue)="changeDateValue()"
            class="value-input-field"
            [controlField]="filterFormGroup.get('date')?.get('valueFrom')"
            [isRequired]="!['', 12].includes(filterFormGroup.value.date?.operator)"
            [placeholder]="filterFormGroup.value.date?.operator === 8 ? 'Value From' : placeholder.from"
          >
          </ptg-datepicker>
          <ptg-datepicker
            *ngIf="filterFormGroup.value.date?.operator === 8"
            class="value-input-field"
            [controlField]="filterFormGroup.get('date')?.get('valueTo')"
            [isRequired]="!!placeholder.to"
            [placeholder]="placeholder.to"
            [customError]="'fromToError'"
          >
          </ptg-datepicker>
          <ptg-textbox
            *ngIf="filterFormGroup.value.date?.operator === 11"
            class="value-input-field"
            [allowZero]="inputConfig.allowZero"
            [checkMinMaxValue]="false"
            [controlField]="filterFormGroup.get('date')?.get('valueFrom')"
            [hasLabel]="true"
            [inputType]="'Number'"
            [isDecimal]="false"
            [isPositive]="true"
            [isRequired]="true"
            [placeholder]="placeholder.from"
          >
          </ptg-textbox>
        </div>
      </div>
    </ng-container>
  </div>

  <button mat-raised-button type="button" class="add-filter-button" (click)="addFilter()">
    <mat-icon>add</mat-icon>
    <span>Add Filter</span>
  </button>
  <span class="current-filters-title">Filters to apply</span>
  <div
    class="current-filter-container"
    [ngClass]="{ expanded: filterFormGroup.value?.operator === 8 || filterFormGroup.value.date?.operator === 8 }"
  >
    <ptg-view-current-filters
      [currentFilters]="currentFilters"
      [canRemove]="true"
      (removed)="removeFilterCondition($event)"
    >
    </ptg-view-current-filters>
  </div>
  <div class="dialog-footer-container">
    <button
      mat-raised-button
      type="button"
      class="submit-button"
      (click)="applyFilter()"
      [disabled]="!currentFilters?.length"
    >
      Apply Filter
    </button>
    <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
  </div>
</div>
