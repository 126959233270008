import { createAction, props } from '@ngrx/store';

import { GetFinalPaymentConfigurationsResponse, SetFinalPaymentConfigurationsRequest } from '../../services/models';

export const getFinalPaymentConfigurationsAction = createAction('[GetFinalPaymentConfigurations/API] Send Request');

export const getFinalPaymentConfigurationsSuccessAction = createAction(
  '[GetFinalPaymentConfigurations/API] Success',
  props<{ response: GetFinalPaymentConfigurationsResponse }>(),
);

export const getFinalPaymentConfigurationsFailureAction = createAction(
  '[GetFinalPaymentConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetFinalPaymentConfigurationsStateAction = createAction('[GetFinalPaymentConfigurations] Clear');

export const setFinalPaymentConfigurationsAction = createAction(
  '[SetFinalPaymentConfigurations/API] Send Request',
  props<{ body: SetFinalPaymentConfigurationsRequest }>(),
);

export const setFinalPaymentConfigurationsSuccessAction = createAction(
  '[SetFinalPaymentConfigurations/API] Success',
);

export const setFinalPaymentConfigurationsFailureAction = createAction(
  '[SetFinalPaymentConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearSetFinalPaymentConfigurationsStateAction = createAction('[SetFinalPaymentConfigurations] Clear');
