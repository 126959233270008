import { Align, ColumnType } from '@ptg-shared/controls/grid';

export const GRID_COLUMN_SPLIT_PAYMENT_BASE = [
  {
    name: 'name',
    header: {
      title: 'Name',
    },
    width: '110px',
    truncate: true,
  },
  {
    name: 'paymentMethod',
    header: {
      title: 'Payment Method',
    },
    width: '161px',
    truncate: true,
  },
  {
    name: 'amount',
    header: {
      title: 'Amount',
    },
    width: '166px',
    truncate: true,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
    },
    align: Align.Right,
  },
  {
    name: 'accountNumber',
    header: {
      title: 'Account#',
    },
    width: 'auto',
    truncate: true,
  },
];

export const GRID_COLUMN_SPLIT_PAYMENT_EXTENSION = [
  {
    name: 'name',
    header: {
      title: 'Name',
    },
    width: '110px',
    truncate: true,
  },
  {
    name: 'transactionDate',
    header: {
      title: 'Transaction Date',
    },
    width: '154px',
    truncate: true,
  },
  {
    name: 'transactionId',
    header: {
      title: 'Transaction ID',
    },
    width: '139px',
    truncate: true,
  },
  {
    name: 'postedTime',
    header: {
      title: 'Posted Time',
    },
    width: '226px',
    truncate: true,
  },
  {
    name: 'paymentMethod',
    header: {
      title: 'Payment Method',
    },
    width: '161px',
    truncate: true,
  },
  {
    name: 'amount',
    header: {
      title: 'Amount',
    },
    width: '166px',
    truncate: true,
    type: ColumnType.Decimal,
    templateArgs: {
      decimal: 2,
      unit: '$',
      unitPosition: 'left',
    },
    align: Align.Right,
  },
  {
    name: 'accountNumber',
    header: {
      title: 'Account#',
    },
    width: 'auto',
    truncate: true,
  },
];
