import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Column, Row } from '@ptg-shared/controls/grid';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Option } from '@ptg-shared/controls/select/select.component';
import { DEFAULT_PAGE_SIZE, ENTITY_MEMBER_GUID, ENTITY_ORGANIZATION_GUID, ENTITY_PARTICIPANT_GUID, ENTITY_PERSON_GUID, GUID_EMPTY, SortType } from '@ptg-shared/constance';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { MemberAuditTrail, MemberAuditTrailSearchName, MemberAuditTrailSearchUser, MemberAuditTrailSection } from '@ptg-member/types/models/member-audit-trails.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { personSearch } from '@ptg-shared/constance/listIcons.const';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { DateTime } from 'luxon';
import { clearGetMemberAuditTrailsListStateAction, clearGetMemberAuditTrailsSectionListStateAction, clearGetMemberAuditTrailsSearchNameStateAction, getMemberAuditTrailsListAction, getMemberAuditTrailsSearchNameAction, clearGetMemberAuditTrailsSearchUserStateAction, getMemberAuditTrailsSearchUserAction, getMemberAuditTrailsSectionListAction, clearGetMemberAuditTrailsCardInfoStateAction, getMemberAuditTrailsCardInfoAction, clearGetMemberAuditTrailsProfileInfoStateAction, getMemberAuditTrailsProfileInfoAction } from '@ptg-member/store/actions/member-audit-trails.action';
import { getMemberAuditTrailsCardInfoSelector, getMemberAuditTrailsListSelector, getMemberAuditTrailsProfileInfoSelector, getMemberAuditTrailsSearchNameSelector, getMemberAuditTrailsSearchUserSelector, getMemberAuditTrailsSectionListSelector } from '@ptg-member/store/selectors/member-audit-trails.selector';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DATETIME_FORMAT_MDYhMA, DATE_FORMAT_YMD } from 'src/app/app.const';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete/autocomplete-trigger';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as MemberNavigationActions from '@ptg-member/store/actions/member-navigation';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { PropertyType } from "@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum";

@Component({
  selector: 'ptg-member-audit-trails',
  templateUrl: './member-audit-trails.component.html',
  styleUrls: ['./member-audit-trails.component.scss'],
})
export class MemberAuditTrailsComponent extends BaseListComponent {
  // COMMON PROPERTIES
  @ViewChild('searchNameInput') searchNameInput!: ElementRef;
  currentFund: any = {};
  memberId: string = '';
  entityReferenceLinkedId: string = '';
  viewId: string = '';
  cardId: string = '';
  isFromOverview: boolean = false;
  isFromDetailView: boolean = false;
  isFromUnconfiguredViewScreen: boolean = false;
  currentSelectedRow: MemberAuditTrail | null = null;
  currentRowIdxFromUrl: string = '';
  
  // TABLE PROPERTIES
  isShowTableAfterFirstFilter: boolean = false; // Not show table when init in case come from Particiant List
  tableColumns: Column[] = [];
  tableData: (MemberAuditTrail & Row)[] = [];
  sortInfo: Sort = {
    active: '',
    direction: '',
  };
  lengthPg: number | any;
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber: number = FIRST_PAGE;
  isTableLoading: boolean = false;

  // BREADCRUMB PROPERTIES
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Audit Trails',
      url: '',
    },
  ];

  // BANNER PROPERTIES
  bannerType: BannerType = BannerType.Info;
  message: string = 'Please select a Filter Criteria to view Audit Trails.';

  // FORM PROPERTIES
  // Form common properties
  formGroup!: FormGroup;
  sectionOptionList: Option[] = [];

  // Search Name properties & methods
  isSearchNameLoading: boolean = false;
  isSearchNameError: boolean = false;
  valueNameSearch: string = '';
  searchName$ = new BehaviorSubject('');
  hideClearSearchNameContent: boolean = false;
  @ViewChild('searchNameTrigger') searchNameTrigger!: MatAutocompleteTrigger;
  searchNameResultColumns = [
    'Name', 'EIN/SSN'
  ];
  searchNameResultList: MemberAuditTrailSearchName[] = [];
  selectedName: MemberAuditTrailSearchName | null = null;

  clearSearchNameContent(): void {
    this.valueNameSearch = '';
    this.hideClearSearchNameContent = false;
  }
  searchName(value: string): void {
    this.searchName$.next(value);
    this.selectedName = null;
  }
  selectName(option: MemberAuditTrailSearchName) {
    this.searchNameTrigger.closePanel();
    this.selectedName = option;
    this.valueNameSearch = option?.name ?? '';
  }

  // Search User properties & methods
  isSearchUserLoading: boolean = false;
  isSearchUserError: boolean = false;
  valueUserSearch: string = '';
  searchUser$ = new BehaviorSubject('');
  hideClearSearchUserContent: boolean = false;
  searchUserResultList: MemberAuditTrailSearchUser[] = [];
  selectedUser: MemberAuditTrailSearchUser | null = null;

  clearSearchUserContent(): void {
    this.valueUserSearch = '';
    this.hideClearSearchNameContent = false;
  }
  searchUser(value: string): void {
    this.searchUser$.next(value);
    this.selectedUser = null;
  }
  selectUser(option: MemberAuditTrailSearchUser) {
    this.selectedUser = option;
    this.valueUserSearch = option.displayName;
  }

  // Date From / To properties
  minDateFrom = MY_DATE.minDate;
  maxDateFrom = MY_DATE.maxDate;
  minDateTo = MY_DATE.minDate;
  maxDateTo = MY_DATE.maxDate;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<fromReducer.State>,
    public authService: Auth0Service,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    private readonly fb: FormBuilder,
    public layoutService: LayoutService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    super(layoutService);
    iconRegistry.addSvgIconLiteral(
      'personSearch',
      sanitizer.bypassSecurityTrustHtml(personSearch)
    );
  }

  ngOnInit(): void {
    this.getCurrentFundData();

    this.initFormGroup();
    this.prepareMinMaxDate();

    this.registerGetSectionSelector();

    this.getCurrentUrlData();

    this.registerGetMemberAuditTrailsSelector();

    this.registerGetSearchNameSelector();
    this.registerGetSearchUserSelector();

    this.registerProfileNavigationSelector();
  }

  getCurrentFundData() {
    combineLatest([
      this.store.select(fromReducer.selectCurrentFundState),
    ])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(([currentFund]) => {
      this.currentFund = currentFund;
      this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
      const pageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + '-ptg-member-audit-trails-pageSize'));
      this.pageSize = pageSizeInSession === 0 ? this.defaultPageSize : pageSizeInSession;
    });
  }

  getCurrentUrlData() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params) {
        this.memberId = params?.memberId ?? '';
        this.viewId = params?.viewId ?? '';

        if (this.memberId) {
          this.isShowTableAfterFirstFilter = true;
          this.getTableColumns(true);
          this.prepareDataBeforeGetAuditTrailsForOneMember();
        } else {
          this.getTableColumns();
          this.getSection();
        }
      }
    });

    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((queryParams) => {
      if (queryParams) {
        if (queryParams?.cardId) {
          this.cardId = queryParams.cardId;
        }
        if (queryParams?.entityReferenceLinkedId) {
          this.entityReferenceLinkedId = queryParams.entityReferenceLinkedId;
        }
        if (queryParams?.currentRowIndex) {
          this.currentRowIdxFromUrl = queryParams.currentRowIndex;
        }
        if (queryParams?.isFromOverviewScreen === 'true') {
          this.isFromOverview = true;
          this.handleBreadcrumbsWhenComeFromOveriew();
        }
        if (queryParams?.isFromDetailViewScreen === 'true') {
          this.isFromDetailView = true;
          this.handleBreadcrumbsWhenComeFromDetailView();
        }
        if (queryParams?.isFromUnconfiguredViewScreen === 'true') {
          this.isFromUnconfiguredViewScreen = true;
          this.handleBreadcrumbsWhenComeFromUnconfiguredView();
        }
      }
    });
  }

  getOverviewUrl(currentUrl: string) {
    currentUrl = currentUrl.replace('member/audit-trails/', 'member/');
    if (currentUrl.includes('&isFromOverviewScreen=true')) {
      currentUrl = currentUrl.replace('&isFromOverviewScreen=true', '');
    }
    if (currentUrl.includes('?isFromOverviewScreen=true')) {
      currentUrl = currentUrl.replace('?isFromOverviewScreen=true', '');
    }
    if (this.cardId) {
      currentUrl = currentUrl.replace(`&cardId=${this.cardId}`, '');
    }
    return currentUrl;
  }

  getDetailViewUrl(currentUrl: string) {
    currentUrl = currentUrl.replace('member/audit-trails/', 'member/');
    if (currentUrl.includes('&isFromDetailViewScreen=true')) {
      currentUrl = currentUrl.replace('&isFromDetailViewScreen=true', '');
    }
    if (currentUrl.includes('?isFromDetailViewScreen=true')) {
      currentUrl = currentUrl.replace('?isFromDetailViewScreen=true', '');
    }
    if (currentUrl.includes('&isFromUnconfiguredViewScreen=true')) {
      currentUrl = currentUrl.replace('&isFromUnconfiguredViewScreen=true', '');
    }
    if (currentUrl.includes('?isFromUnconfiguredViewScreen=true')) {
      currentUrl = currentUrl.replace('?isFromUnconfiguredViewScreen=true', '');
    }
    if (this.cardId && !this.currentRowIdxFromUrl) {
      currentUrl = currentUrl.replace(`&cardId=${this.cardId}`, '');
    }
    return currentUrl;
  }

  handleBreadcrumbsWhenComeFromOveriew() {
    this.registerGetMemberAuditTrailsProfileInfoSelector();
    this.getMemberAuditTrailsProfileInfo();
  }

  handleBreadcrumbsWhenComeFromDetailView() {
    this.registerGetMemberAuditTrailsCardInfoSelector();
    this.getMemberAuditTrailsCardInfo();
  }

  handleBreadcrumbsWhenComeFromUnconfiguredView() {
    const cardName = this.sectionOptionList?.find(
      (item: Option) => item?.value === this.cardId
    )?.displayValue ?? '';
    this.listBreadcrumbs = [
      {
        name: cardName ?? 'Card',
        url: this.getDetailViewUrl(deepClone(this.router.url)),
      },
      {
        name: 'Audit Trails',
        url: '',
      },
    ];
  }

  getTableColumns(isNotHaveNameCol: boolean = false) {
    const columnSet1: Column[] = [
      {
        name: 'dateCreated',
        header: {
          title: 'Date Time',
        },
        truncate: true,
        sortable: false,
      },
      {
        name: 'section',
        header: {
          title: 'Section',
        },
        truncate: true,
        sortable: false,
      },
      {
        name: 'action',
        header: {
          title: 'Action',
        },
        truncate: true,
        sortable: false,
      },
    ]

    const columnSet2: Column[] = [
      {
        name: 'user',
        header: {
          title: 'User',
        },
        truncate: true,
        sortable: false,
      },
      {
        name: 'fieldName',
        header: {
          title: 'Field Name',
        },
        truncate: true,
        sortable: false,
      },
      {
        name: 'before',
        header: {
          title: 'Before',
        },
        truncate: true,
        sortable: false,
      },
      {
        name: 'after',
        header: {
          title: 'After',
        },
        truncate: true,
        sortable: false,
      },
      {
        name: 'reason',
        header: {
          title: 'Reason',
        },
        truncate: true,
        sortable: false,
      },
    ];

    const nameCol: Column = {
      name: 'targetName',
      header: {
        title: 'Name',
      },
      truncate: true,
      sortable: false,
    };

    if (isNotHaveNameCol) {
      this.tableColumns = [...columnSet1, ...columnSet2];
    } else {
      this.tableColumns = [...columnSet1, nameCol, ...columnSet2];
    }
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      section: this.fb.control(null),
      name: this.fb.control(null),
      user: this.fb.control(null),
      dateFrom: this.fb.control(null, [this.customDateFromValidator()]),
      dateTo: this.fb.control(null, [this.customDateToValidator()]),
    });
  }

  get sectionDropdownControl(): FormControl {
    return this.formGroup?.get('section') as FormControl;
  }

  get dateFromDatePickerControl(): FormControl {
    return this.formGroup?.get('dateFrom') as FormControl;
  }

  get dateToDatePickerControl(): FormControl {  
    return this.formGroup?.get('dateTo') as FormControl;
  }

  getSection() {
    this.store.dispatch(getMemberAuditTrailsSectionListAction({ request: {}}));
  }

  registerGetSectionSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsSectionListSelector))
      .subscribe((state) => {
        if (state) {
          this.layoutService.showLoading = !!state?.isLoading;
          if (state?.success === true) {
            this.sectionOptionList = state?.payload?.cards
              ?.filter((item: MemberAuditTrailSection) => 
                item?.isConfigInView === true
                && [
                  ENTITY_MEMBER_GUID,
                  ENTITY_PARTICIPANT_GUID,
                  ENTITY_PERSON_GUID,
                  ENTITY_ORGANIZATION_GUID,
                ].includes(item?.entityId)
              )
              ?.map((item: MemberAuditTrailSection) => ({
                value: item?.id,
                displayValue: item?.cardName,
              }))
              ?.sort((item1, item2) => 
                item1?.displayValue?.localeCompare(item2?.displayValue)
              ) ?? [];

            // For 1 Member, make sure Section Dropdown have value 1st before get Audit Trails
            if (this.cardId) {
              if (this.isFromUnconfiguredViewScreen) {
                this.handleBreadcrumbsWhenComeFromUnconfiguredView();
              }

              this.sectionDropdownControl.setValue(this.cardId);
              this.getMemberAuditTrails();
            }
          }
          this.store.dispatch(clearGetMemberAuditTrailsListStateAction());
        }
      });
  }

  getMemberAuditTrailsCardInfo() {
    if (this.viewId) {
      this.store.dispatch(getMemberAuditTrailsCardInfoAction({ request: {
        viewId: this.viewId,
      }}));
    }
  }

  registerGetMemberAuditTrailsCardInfoSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsCardInfoSelector))
      .subscribe((state) => {
        if (state) {
          this.layoutService.showLoading = !!state?.isLoading;
          if (state?.success === true) {
            this.listBreadcrumbs = [
              {
                name: state?.payload?.viewName ?? 'View',
                url: this.getDetailViewUrl(deepClone(this.router.url)),
              },
              {
                name: 'Audit Trails',
                url: '',
              },
            ];
          }
          this.store.dispatch(clearGetMemberAuditTrailsCardInfoStateAction());
        }
      });
  }

  getMemberAuditTrailsProfileInfo() {
    if (this.memberId) {
      this.store.dispatch(getMemberAuditTrailsProfileInfoAction({ request: {
        memberId: this.memberId,
        entityId: this.entityReferenceLinkedId ?? ENTITY_MEMBER_GUID,
      }}));
    }
  }

  registerGetMemberAuditTrailsProfileInfoSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsProfileInfoSelector))
      .subscribe((state) => {
        if (state) {
          this.layoutService.showLoading = !!state?.isLoading;
          if (state?.success === true) {
            const memberNavigationMenu: any[] = deepClone(state?.payload?.memberNavigationMenu ?? []);
            const menuItems = memberNavigationMenu
              ?.map((item) => {
                return item.menuItems;
              })
              ?.reduce((a, b) => {
                return a.concat(b);
              }, []);

            const menuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
            this.listBreadcrumbs = [
              {
                name: menuName ?? state?.payload?.overviewLabel ?? 'Overview',
                url: this.getOverviewUrl(deepClone(this.router.url)),
              },
              {
                name: 'Audit Trails',
                url: '',
              },
            ];
          }
          this.store.dispatch(clearGetMemberAuditTrailsProfileInfoStateAction());
        }
      });
  }

  registerGetSearchNameSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsSearchNameSelector))
      .subscribe((state) => {
        if (state) {
          this.isSearchNameLoading = !!state?.isLoading;
          if (state?.success === true) {
            let searchData = deepClone(state?.payload?.entityDatas ?? []);
            this.searchNameResultList = searchData.map((name, index) => {
              return {
                ...name,
                'Name': name?.name,
                'EIN/SSN': name?.ssn,
              };
            });
            this.isSearchNameError = false;
          }
          if (state?.success === false) {
            this.isSearchNameError = true;
            this.searchNameResultList = [];
          }
          this.store.dispatch(clearGetMemberAuditTrailsSearchNameStateAction());
        }
      });
    
    this.searchName$.pipe().subscribe(el => {
      // Need to reset all searching variables to default everytime trigger a new search action
      this.store.dispatch(clearGetMemberAuditTrailsSearchNameStateAction());
      if (el && el.length >= 2) {
        this.isSearchNameError = false;
        this.isSearchNameLoading = true;
        this.searchNameResultList = [];
      }
    });

    this.searchName$.pipe(debounceTime(500)).subscribe(el => {
      this.store.dispatch(clearGetMemberAuditTrailsSearchNameStateAction());
      if (el && el.length >= 2) {
        this.store.dispatch(
          getMemberAuditTrailsSearchNameAction({
            request: { searchNameContent: el }
          })
        );
      }
    });
  }

  registerGetSearchUserSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsSearchUserSelector))
      .subscribe((state) => {
        if (state) {
          this.isSearchUserLoading = !!state?.isLoading;
          if (state?.success === true) {
            let searchData = deepClone(state?.payload?.users ?? []);
            this.searchUserResultList = searchData.map((user, index) => {
              return {
                ...user,
                displayName: `${user?.firstName ?? ''} ${user?.lastName}`
              };
            });
            this.isSearchUserError = false;
          }
          if (state?.success === false) {
            this.isSearchUserError = true;
            this.searchUserResultList = [];
          }
          this.store.dispatch(clearGetMemberAuditTrailsSearchUserStateAction());
        }
      });
    
    this.searchUser$.pipe().subscribe(el => {
      // Need to reset all searching variables to default everytime trigger a new search action
      this.store.dispatch(clearGetMemberAuditTrailsSearchUserStateAction());
      if (el && el.length >= 2) {
        this.isSearchUserError = false;
        this.isSearchUserLoading = true;
        this.searchUserResultList = [];
      }
    });

    this.searchUser$.pipe(debounceTime(500)).subscribe(el => {
      this.store.dispatch(clearGetMemberAuditTrailsSearchUserStateAction());
      if (el && el.length >= 2) {
        this.store.dispatch(
          getMemberAuditTrailsSearchUserAction({
            request: { searchUserContent: el }
          })
        );
      }
    });
  }

  prepareDataBeforeGetAuditTrailsForOneMember() {
    this.selectedName = {
      id: this.memberId,
      entityId: '',
      firstName: '',
      lastName: '',
      name: '',
      ssn: '',
      last4ssn: '',
    }

    // For 1 Member, make sure Section Dropdown have value 1st before get Audit Trails
    this.getSection();
  }

  getMemberAuditTrails(isTriggerByClearFilter: boolean = false) {
    // Not trigger get data if form have error, but still keep getting data if trigger by click Clear button
    if (!isTriggerByClearFilter) {
      this.formGroup.markAllAsTouched();
      if (!this.formGroup.valid) return;
    }
    
    let sortType = SortType.DESC;
    let sortNames = '';
    if (this.sortInfo?.active && this.sortInfo.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }

    const sectionId = this.sectionDropdownControl.value;
    const nameId = this.selectedName?.id;
    const userId = this.selectedUser?.id;
    const dateFrom = this.dateFromDatePickerControl.value 
      ? DateTime.fromISO(this.dateFromDatePickerControl.value).toFormat(DATE_FORMAT_YMD)
      : null;
    const dateTo = this.dateToDatePickerControl.value
      ? DateTime.fromISO(this.dateToDatePickerControl.value).toFormat(DATE_FORMAT_YMD)
      : null;

    this.store.dispatch(
      getMemberAuditTrailsListAction({
        request: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortNames,
          sortType,
          ...sectionId && {
            sectionId,
          },
          ...nameId && {
            nameId,
          },
          ...userId && {
            userId,
          },
          ...dateFrom && {
            dateFrom,
          },
          ...dateTo && {
            dateTo,
          }
        },
      })
    );
  }

  registerGetMemberAuditTrailsSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsListSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isTableLoading = !!data?.isLoading;
          if (data?.success === true) {
            this.lengthPg = data?.payload?.total ?? 0;

            // Return to 1st Page if no data found (case apply other filter conditions when in a page > 1)
            if (this.lengthPg > 0 && data?.payload?.results?.length === 0) {
              this.pageNumber = FIRST_PAGE;
              sessionStorage.setItem(this.currentFund.key + '-ptg-member-audit-trails-pageSize', this.pageSize.toString());
              this.getMemberAuditTrails();
              return;
            }

            this.tableData = data?.payload?.results?.map(
              (item: MemberAuditTrail) => this.handleAuditTrailsRecord(item)
            ) ?? [];
          }
        }
      });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getMemberAuditTrails();
  }

  pageChange(event: PageEvent) {
    this.pageNumber = event.pageNumber;
    this.pageSize = event.pageSize;
    sessionStorage.setItem(this.currentFund.key + '-ptg-member-audit-trails-pageSize', this.pageSize.toString());
    this.getMemberAuditTrails();
  }

  onApplyFilter() {
    if (!this.memberId && this.isShowTableAfterFirstFilter === false) {
      this.isShowTableAfterFirstFilter = true;
    }
    this.getMemberAuditTrails();
  }

  onClearFilter(shouldTriggerGetAuditTrails: boolean = true) {
    this.sectionDropdownControl.reset(null);
    this.dateToDatePickerControl.reset(null);
    this.dateFromDatePickerControl.reset(null);
    if (!this.memberId) {
      this.selectedName = null;
      this.valueNameSearch = '';
      this.searchNameResultList = [];
    }
    this.valueUserSearch = '';
    this.searchUserResultList = [];
    this.selectedUser = null;

    if (shouldTriggerGetAuditTrails) {
      if (!this.memberId && this.isShowTableAfterFirstFilter === false) {
        this.isShowTableAfterFirstFilter = true;
      }
      this.getMemberAuditTrails(true);
    }
  }

  prepareMinMaxDate() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    this.maxDateTo = currentDate;
    this.maxDateFrom = currentDate;
  }

  onChangeDateFrom() {
    const dateFrom = this.dateFromDatePickerControl.value;

    // Date From should always be equal or lesser than Date To
    if (dateFrom && this.dateFromDatePickerControl.valid) {
      this.minDateTo = dateFrom;
    }

    if (this.dateFromDatePickerControl.value && this.dateToDatePickerControl.value) {
      this.dateFromDatePickerControl.updateValueAndValidity();
      this.dateFromDatePickerControl.markAsTouched();
      this.dateToDatePickerControl.updateValueAndValidity();
      this.dateToDatePickerControl.markAsTouched();
    }
  }

  onChangeDateTo() {
    const dateTo = this.dateToDatePickerControl.value;

    // Date To should always be equal or greater than End Date
    if (dateTo && this.dateToDatePickerControl.valid) {
      this.maxDateFrom = dateTo;
    }

    this.dateFromDatePickerControl.updateValueAndValidity();
    this.dateFromDatePickerControl.markAsTouched();
    this.dateToDatePickerControl.updateValueAndValidity();
    this.dateToDatePickerControl.markAsTouched();
  }

  customDateFromValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Prepare data for validation
      const dateFromValue = control?.parent?.get('dateFrom')?.value;
      const dateFrom = new Date(DateTime.fromISO(dateFromValue).toFormat('yyyy-MM-dd'));
      dateFrom.setHours(0, 0, 0, 0);
      const dateFromTimestamp = dateFrom.getTime();

      const dateToValue = control.parent?.get('dateTo')?.value;
      const dateTo = new Date(DateTime.fromISO(dateToValue).toFormat('yyyy-MM-dd'));
      dateTo.setHours(0, 0, 0, 0);
      const dateToTimestamp = dateTo.getTime();

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const currentDateTimestamp = currentDate.getTime();

      // Require Date From if Date To have value
      if (!dateFromValue && dateToValue) {
        return {
          errorMessageDateFrom: "Date From is required.",
        };
      }

      // Date From must be equal or lesser Date To
      if (dateFromTimestamp && dateToTimestamp && dateFromTimestamp > dateToTimestamp) {
        return {
          errorMessageDateFrom: "Date From cannot be later than Date To.",
        }
      }

      // Date From must be equal or lesser Current Date
      if (dateFromTimestamp && dateFromTimestamp > currentDateTimestamp) {
        return {
          errorMessageDateFrom: "Date From cannot be future date.",
        }
      }

      // Default no error
      return null;
    };
  }

  customDateToValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Prepare data for validation
      const dateFromValue = control?.parent?.get('dateFrom')?.value;
      const dateFrom = new Date(DateTime.fromISO(dateFromValue).toFormat('yyyy-MM-dd'));
      dateFrom.setHours(0, 0, 0, 0);
      const dateFromTimestamp = dateFrom.getTime();

      const dateToValue = control.parent?.get('dateTo')?.value;
      const dateTo = new Date(DateTime.fromISO(dateToValue).toFormat('yyyy-MM-dd'));
      dateTo.setHours(0, 0, 0, 0);
      const dateToTimestamp = dateTo.getTime();

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const currentDateTimestamp = currentDate.getTime();

      // Date To must be equal or greater Date From
      if (dateFromTimestamp && dateToTimestamp && dateFromTimestamp > dateToTimestamp) {
        return {
          errorMessageDateTo: "Date From cannot be later than Date To.",
        }
      }

      // Date To must be equal or lesser Current Date
      if (dateToTimestamp && dateToTimestamp > currentDateTimestamp) {
        return {
          errorMessageDateTo: "Date To cannot be future date.",
        }
      }

      // Default no error
      return null;
    };
  }

  onClickHyperlink(row: MemberAuditTrail) {
    if (!row?.isProfileConfigured) return;
    this.currentSelectedRow = deepClone(row);
    this.currentSelectedRow.currentSeekingProfilePriority = ENTITY_MEMBER_GUID;
    this.store.dispatch(MemberNavigationActions.clearMemberNavigationState());
    this.store.dispatch(LayoutActions.profileNavigationItemRequest({ 
      memberId: this.currentSelectedRow?.targetId, 
      entityReferenceLinkedId: ENTITY_MEMBER_GUID,
    }));
  }

  registerProfileNavigationSelector() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.layoutService.showLoading = !!state?.isLoading;
        if (state?.success === true) {
          // Try to get profile config as MEMBER 1st, if found, do navigate, if not, keep going below
          if (state?.menu && state?.menu.length > 0 && this.currentSelectedRow) {
            const overviewView = state.memberNavigationList as any;
            const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
            let url = `${
              isOverviewDetailView
                ? '/member/detail-view/true'
                : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${state.memberId}?entityReferenceLinkedId=${this.currentSelectedRow?.currentSeekingProfilePriority}`;
            this.currentSelectedRow = null;

            this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
            this.store.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
            return;
          }

          // If no MEMBER profile config found, keep trying with PARTICIPANT profile config
          if (this.currentSelectedRow?.currentSeekingProfilePriority === ENTITY_MEMBER_GUID) {
            this.currentSelectedRow.currentSeekingProfilePriority = ENTITY_PARTICIPANT_GUID;
            this.store.dispatch(LayoutActions.profileNavigationItemRequest({ 
              memberId: this.currentSelectedRow?.targetId, 
              entityReferenceLinkedId: ENTITY_PARTICIPANT_GUID,
            }));
            return;
          }

          // If no PARTICIPANT profile config found, keep trying with PERSON profile config
          if (this.currentSelectedRow?.currentSeekingProfilePriority === ENTITY_PARTICIPANT_GUID) {
            this.currentSelectedRow.currentSeekingProfilePriority = ENTITY_PERSON_GUID;
            this.store.dispatch(LayoutActions.profileNavigationItemRequest({ 
              memberId: this.currentSelectedRow?.targetId, 
              entityReferenceLinkedId: ENTITY_PERSON_GUID,
            }));
            return;
          }

          // If found no available profile config, do nothing
        } 
      });
  }

  handleAuditTrailsRecord(rec: MemberAuditTrail): MemberAuditTrail {
    const item = deepClone(rec);
    switch (item?.propertyType) {
      case PropertyType.DateTime:
        if (item?.before) {
          item.before = this.datePipe.transform(item?.before, DATETIME_FORMAT_MDYhMA) ?? '';
        }
        if (item?.after) {
          item.after = this.datePipe.transform(item?.after, DATETIME_FORMAT_MDYhMA) ?? '';
        }
    }
    if (item?.dateCreated) {  
      item.dateCreated = this.datePipe.transform(getDateString(item?.dateCreated as string), DATETIME_FORMAT_MDYhMA) ?? '';
    }
    return item;
  }

  resetCommonVariables() {
    this.memberId = '';
    this.entityReferenceLinkedId = '';
    this.cardId = '';
    this.viewId = '';
    this.currentSelectedRow = null;
    this.isShowTableAfterFirstFilter = false;
  }

  clearStates(): void {
    this.store.dispatch(clearGetMemberAuditTrailsListStateAction());
    this.store.dispatch(clearGetMemberAuditTrailsSectionListStateAction());
    this.store.dispatch(clearGetMemberAuditTrailsCardInfoStateAction());
    this.store.dispatch(clearGetMemberAuditTrailsProfileInfoStateAction());
    this.store.dispatch(clearGetMemberAuditTrailsSearchNameStateAction());
    this.store.dispatch(clearGetMemberAuditTrailsSearchUserStateAction());
    this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
  }

  ngOnDestroy(): void {
    this.resetCommonVariables();
    this.onClearFilter(false);
    super.ngOnDestroy();
    this.clearStates();
  }
}
