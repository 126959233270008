import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT_NOT_FOUND_MSG } from './constants';
import { CellContentSearch } from './directives';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent<T> implements OnInit {
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;
  search$ = new BehaviorSubject('');

  @ContentChild(CellContentSearch) cellContentSearch?: CellContentSearch;
  @ViewChild('searchTrigger') searchTrigger?: MatAutocompleteTrigger;

  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() svgIcon: string = '';
  @Input() searchContent: string = '';
  @Input() isShowClearButton: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() isError: boolean = false;
  @Input() data: T[] = [];
  @Input() columns: string[] = [];
  @Input() noDataMsg: string = DEFAULT_NOT_FOUND_MSG;
  @Input() lengthToSearch: number = 2;
  @Input() typingTime: number = 500;
  @Input() isSelected: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() searchClass: string = '';
  @Input() dialogClass: string = '';
  @Input() defaultSearchResult: boolean = false;
  @Input() allowEmitEventClearIconClickedOutside: boolean = false;
  @Input() shouldNotShowDefaultSearchResult: boolean = false;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  @Output() search = new EventEmitter<string>();
  @Output() selectItem = new EventEmitter<T>();
  @Output() clickClear = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.dialogClass) {
      document.querySelector(`.${this.dialogClass}`)?.addEventListener('scroll', this.scrollEvent);
    }

    this.search$.pipe(debounceTime(this.typingTime)).subscribe((value) => {
      if (value && value.length >= this.lengthToSearch) {
        this.search.emit(value);
      }
    });
  }

  ngOndestroy(): void {
    if (this.dialogClass) {
      document.querySelector(`.${this.dialogClass}`)?.removeEventListener('scroll', this.scrollEvent);
    }
  }

  scrollEvent = (): void => {
    if(this.searchTrigger?.panelOpen) {
      this.searchTrigger.updatePosition();
    }
  };

  onChange(value: string): void {
    this.searchContent = value;
    this.valueChange.emit(value);
  }

  onSearch(): void {
    this.data = [];
    this.isLoading = this.searchContent.length >= this.lengthToSearch;
    this.search$.next(this.searchContent);
  }

  onFocusValue(value: FocusEvent): void {
    this.onFocus.emit((value.target as HTMLInputElement).value);
  }

  onClickSelect(row: T) {
    this.isSelected = true;
    this.searchTrigger?.closePanel();
    this.selectItem.emit(row);
  }

  onClickClearSearch(): void {
    if (this.allowEmitEventClearIconClickedOutside) {
      this.clickClear.emit(false);
      return;
    }
    if (!this.isDisabled) {
      const oldIsSelected = !!this.isSelected;
      this.clearSearch();
      this.clickClear.emit(oldIsSelected);
    }
  }

  clearSearch(): void {
    this.isSelected = false;
    this.searchContent = '';
  }
}
