import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { catchError, delay, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import * as fromReducer from '@ptg-reducers';
import { select, Store } from '@ngrx/store';
import {
  CheckExistsDocumentNameResponse,
  DocumentBodyRequest,
  DocumentList,
  RemoveDocumentItem,
  RemoveOrganizationItem,
} from '@ptg-member/types/models/member-document.model';
import { DocumentState } from '@ptg-employer/reducers/employer-document.reducer';
import {
  selectCheckMemberGeneratingDocumentState,
  selectEditMemberDocumentState,
  selectMemberDocumentListState,
  selectOrganizationDocumentListState,
  selectRemoveMemberDocumentState,
  selectRemoveOrganizationDocumentState,
  selectUploadMemberDocumentState,
  selectUploadOrganizationDocumentState,
} from '@ptg-member/store/reducers';
import { DatePipe } from '@angular/common';
import {
  checkMemberGeneratingDocumentAction,
  clearCheckMemberGeneratingDocumentAction,
  clearEditDocumentsStateAction,
  clearNavigateToDocumentListsAction,
  clearRemoveDocumentsStateAction,
  clearRemoveOrganizationDocumentsStateAction,
  clearUploadDocumentsStateAction,
  clearUploadOrganizationDocumentsStateAction,
  editDocumentsAction,
  getDocumentListsAction,
  getOrganizationDocumentListsAction,
  removeDocumentsAction,
  removeOrganizationDocumentsAction,
  uploadDocumentsAction,
  uploadOrganizationDocumentsAction,
} from '@ptg-member/store/actions/member-document.action';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION, ENTITY_ORGANIZATION_GUID, SortType, STATE } from '@ptg-shared/constance';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { Sort } from '@angular/material/sort';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  generateDocumentSelector,
  selectcheckHaveZipFileState,
  selectDowloadMemberDocumentState,
  selectDownloadMultipleDocumentState,
  selectPrepareDownloadMultipleDocumentState,
} from 'src/app/admin/features/file/store/selectors';
import {
  checkHaveZipFileAction,
  clearCheckHaveZipFileStateAction,
  clearDownloadMultipleDocumentsStateAction,
  clearGenerateParticipantDocumentsStateAction,
  clearGetDocumentDownloadStateAction,
  clearPrepareDownloadMultipleDocumentsStateAction,
  downloadMultipleDocumentsAction,
  getDocumentDownloadAction,
  prepareDownloadMultipleDocumentsAction,
} from 'src/app/admin/features/file/store/actions';
import { DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { MemberDocumentService } from '@ptg-member/services/member-document.service';
import { EMPTY, Observable, combineLatest, of, timer } from 'rxjs';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { GenerateDocumentComponent } from 'src/app/admin/features/file/components/generate-document/generate-document.component';
import { GenerateDocument } from '@ptg-member/types/models/generate-document.models';
import { DocumentPreviewComponent } from 'src/app/admin/features/file/components/document-preview/document-preview.component';
import { MemberDetailActions } from '@ptg-member/store/actions';
import * as fromMember from '../../store/reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { IndividualDocumentListComponent } from 'src/app/admin/features/file/pages/individual-document-list/individual-document-list.component';
import { GenerateDocumentStatus } from '@ptg-member/constants';
import { AccidentDocGenerate } from '@ptg-member/features/accident-claims/constants';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { GenerateDocumentComponentService } from 'src/app/admin/features/file/components/generate-document/generate-document.component.service';
import { SERVICE_HISTORY_LIST } from '@ptg-entity-management/constants/entity-mapping.constant';
import { REMOVE_DOCUMENT_POPUP_CONFIG } from 'src/app/admin/features/file/constants';

const PAGE_SIZE_CONST = '-ptg-member-individual-document-pageSize';

@Component({
  selector: 'ptg-member-individual-document',
  templateUrl: './member-individual-document.component.html',
  styleUrls: ['./member-individual-document.component.scss'],
})
export class MemberIndividualDocumentComponent extends BaseComponent {
  @ViewChild('gridDocumentData') gridDocumentData!: IndividualDocumentListComponent;
  readonly EntityType = EntityType;
  dataTable: DocumentList[] = [];
  pageSize!: number;
  pageNumber: number = FIRST_PAGE;
  lengthPg: number = 0;
  sortInfo: {} | any = {};
  isLoading: boolean = true;
  currentFund: any = {};
  downloadFileName: string = 'sample.pdf';
  zipId: string = '';
  participantInfo: any;
  isFromOverview: boolean = false;
  memberId?: string;
  entityId?: string;
  message: string = '';
  customMessage = 'Error occurred while downloading Document(s). Please try again.';
  bannerType: BannerType = BannerType.Hidden;
  isLoadingInfoBanner!: boolean;
  isHideCloseButton!: boolean;
  downloaded: boolean = false;
  documentFilter!: any;
  isShowDocumentPreview: boolean = false;
  generateDocumentInfo: any;
  generateDocumentData!: GenerateDocument;
  generateDocumentId!: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store<DocumentState>,
    private datePipe: DatePipe,
    private documentStore: Store<DocumentsState>,
    private memberDocumentService: MemberDocumentService,
    public layoutService: LayoutService,
    private generateDocumentService: GenerateDocumentComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getRouterData();
    this.getOverviewParticipantInfo();
    this.getCurrentFundState();
    this.registerGenerateDocumentSelector();
    this.registerGetDocumentListSelector();
    this.registerGetOrganizationDocumentListSelector();
    this.checkGeneratingDocumentSelector();
    this.registerGetNavigateDocumentListSelector();

    // Get Response after clicking download
    this.documentStore
      .select(selectPrepareDownloadMultipleDocumentState)
      .pipe(
        delay(2000),
        filter((x) => !!x),
        takeUntil(this.unsubscribe$),
        switchMap((state: any) => {
          this.zipId = state?.payload?.zipId;
          if (this.zipId) {
            this.documentStore.dispatch(checkHaveZipFileAction({ zipId: this.zipId }));
            return this.documentStore.select(selectcheckHaveZipFileState).pipe(takeUntil(this.unsubscribe$));
          } else if (this.zipId === null || state.success === false) {
            showBanner.call(this, STATE.FAIL, '', ACTION.DOWNLOAD, {
              customMessage: this.customMessage,
            });
            this.documentStore.dispatch(clearCheckHaveZipFileStateAction());
            this.documentStore.dispatch(clearDownloadMultipleDocumentsStateAction());
            this.documentStore.dispatch(clearPrepareDownloadMultipleDocumentsStateAction());
          }
          return EMPTY;
        }),
        switchMap((state: any) => {
          if (state?.payload?.status === 'Available') {
            return of(state?.payload?.status);
          } else {
            return of(state?.payload?.status).pipe(delay(3000));
          }
        }),
      )
      .subscribe((state) => {
        if (state === 'Zipping') {
          this.documentStore.dispatch(checkHaveZipFileAction({ zipId: this.zipId }));
        } else if (state === 'Available') {
          if (this.zipId) {
            this.documentStore.dispatch(
              downloadMultipleDocumentsAction({
                zipId: this.zipId,
              }),
            );
          }
        } else if (state === 'Unavailable') {
          showBanner.call(this, STATE.FAIL, '', ACTION.DOWNLOAD, {
            customMessage: this.customMessage,
          });
          this.documentStore.dispatch(clearCheckHaveZipFileStateAction());
          this.documentStore.dispatch(clearDownloadMultipleDocumentsStateAction());
          this.documentStore.dispatch(clearPrepareDownloadMultipleDocumentsStateAction());
        }
      });

    this.store.pipe(select(selectRemoveMemberDocumentState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        showBanner.call(this, state?.state, 'Document', ACTION.REMOVE);
        this.store.dispatch(clearRemoveDocumentsStateAction());
        if (state.state === STATE.SUCCESS) {
          if (this.pageNumber !== 1 && this.dataTable.length <= 1) {
            this.pageNumber = this.pageNumber - 1;
          }
          this.getData();
          this.gridDocumentData.gridIndividualDocumentList.selection.clear();
          if (this.gridDocumentData.functionButtons?.length > 0) {
            this.gridDocumentData.functionButtons.forEach((btn) => {
              if (btn.buttonName === 'Remove' || btn.buttonName === 'Download') {
                btn.isDisabled = true;
              }
            });
          }
        }
      }
    });

    this.store.pipe(select(selectUploadMemberDocumentState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.isLoadingInfoBanner = false;
        this.isHideCloseButton = false;
        showBanner.call(this, state?.state, 'Document', ACTION.UPLOAD);
        this.store.dispatch(clearUploadDocumentsStateAction());
        this.getData();
      }
    });

    this.store.pipe(select(selectRemoveOrganizationDocumentState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        showBanner.call(this, state?.state, 'Document', ACTION.REMOVE);
        this.store.dispatch(clearRemoveOrganizationDocumentsStateAction());
        if (state.state === STATE.SUCCESS) {
          if (this.pageNumber !== 1 && this.dataTable.length <= 1) {
            this.pageNumber = this.pageNumber - 1;
          }
          this.getData();
          this.gridDocumentData.gridIndividualDocumentList.selection.clear();
          if (this.gridDocumentData.functionButtons?.length > 0) {
            this.gridDocumentData.functionButtons.forEach((btn) => {
              if (btn.buttonName === 'Remove' || btn.buttonName === 'Download') {
                btn.isDisabled = true;
              }
            });
          }
        }
      }
    });

    this.store.pipe(select(selectUploadOrganizationDocumentState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.isLoadingInfoBanner = false;
        this.isHideCloseButton = false;
        showBanner.call(this, state?.state, 'Document', ACTION.UPLOAD);
        this.store.dispatch(clearUploadOrganizationDocumentsStateAction());
        this.getData();
      }
    });

    this.store.pipe(select(selectEditMemberDocumentState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        showBanner.call(this, state?.state, 'Document', ACTION.EDIT);
        this.store.dispatch(clearEditDocumentsStateAction());
        this.getData();
      }
    });

    this.documentStore
      .pipe(select(selectDownloadMultipleDocumentState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.success) {
          this.downloaded = true;
          showBanner.call(this, STATE.SUCCESS, 'Document(s)', ACTION.DOWNLOAD);
          this.documentStore.dispatch(clearCheckHaveZipFileStateAction());
          this.documentStore.dispatch(clearDownloadMultipleDocumentsStateAction());
          this.documentStore.dispatch(clearPrepareDownloadMultipleDocumentsStateAction());
        }
      });

    this.documentStore
      .pipe(select(selectDowloadMemberDocumentState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.success === true) {
          showBanner.call(this, STATE.SUCCESS, 'Document', ACTION.DOWNLOAD);
          this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
        } else if (state?.success === false) {
          showBanner.call(this, STATE.FAIL, 'Document', ACTION.DOWNLOAD);
          this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
        }
      });
  }

  getRouterData() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, queryParams]) => {
        this.memberId = params.id || params.memberId;
        if (queryParams.overview === 'true'){
          this.isFromOverview = true;
        }
      });
  }

  getOverviewParticipantInfo() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu && state?.menu.length > 0) {
          this.participantInfo = state.memberNavigationList as any;
          this.entityId = this.participantInfo.entityId;
          this.getData();
          if (this.isFromOverview) {
            const isOverviewDetailView = this.participantInfo.isOverviewDetailView;
            const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${this.participantInfo.id}/${this.participantInfo.overviewViewId}/${state.memberId}${this.participantInfo.entityId ? `?entityReferenceLinkedId=${this.participantInfo.entityId}` : ''}`;

            this.gridDocumentData.listBreadcrumbs = [
              {
                name: 'Overview',
                url: url
              },
              {
                name: 'Document List'
              },
            ]
          }
        }
      });
  }

  registerGenerateDocumentSelector() {
    this.store
      .pipe(
        select(generateDocumentSelector),
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((generateDocumentState) => {
        this.layoutService.showLoading = false;
        this.dialog.closeAll();

        if (generateDocumentState?.state === STATE.SUCCESS) {
          if (!generateDocumentState.isValid && generateDocumentState.message) {
            // validation failure
            this.showErrorPopup(generateDocumentState.message);
            this.bannerType = BannerType.Hidden;
            this.getData();
            this.store.dispatch(clearGenerateParticipantDocumentsStateAction());
          } else if (
            generateDocumentState.isValid &&
            this.generateDocumentService.selectedGenerateDocumentInfoFromService.isShowPreview
          ) {
            //handle generate document for Preview
            const currentEntity = {
              entityType: EntityType.Participant,
              entityId: this.memberId,
            };
            this.generateDocumentService.selectedGenerateDocumentDataFromService = {
              ...this.generateDocumentService.selectedGenerateDocumentDataFromService,
              isPreview: false,
            };
            this.generateDocumentService.selectedGenerateDocumentInfoFromService = {
              ...this.generateDocumentService.selectedGenerateDocumentInfoFromService,
              isShowPreview: false,
            };
            const previewDocumentDialog = this.dialog.open(DocumentPreviewComponent, {
              panelClass: 'dialog-full-screen',
              disableClose: true,
              data: {
                currentEntity,
                document: this.generateDocumentService.selectedGenerateDocumentInfoFromService,
                documentForm: this.generateDocumentService.selectedGenerateDocumentDataFromService,
              },
            });
            previewDocumentDialog.afterClosed().subscribe((previewDocumentResult: any) => {
              this.store.dispatch(clearGenerateParticipantDocumentsStateAction());
            });
          } else if (generateDocumentState.isSaved) {
            // generated document success
            if (generateDocumentState.generateInformationId) {
              this.generateDocumentId = generateDocumentState.generateInformationId;
              this.checkGeneratingDocument(generateDocumentState.generateInformationId);
              this.getData();
              this.handleShowBannerGenerating();
            }
          }
        }

        if (generateDocumentState?.state === STATE.FAIL) {
          this.isLoading = false;
          this.showBannerGeneratedDocument(generateDocumentState?.state);
          this.getData();
          this.store.dispatch(clearGenerateParticipantDocumentsStateAction());
        }
      });
  }

  getCurrentFundState() {
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.currentFund = el;
      this.pageSize = el.defaultPageSize ?? 50;
      const defaultPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      this.pageSize = defaultPageSize === 0 ? this.pageSize : defaultPageSize;
    });
  }

  getData() {
    let sortType = SortType.DESC;
    let sortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    if (this.entityId === ENTITY_ORGANIZATION_GUID) {
      this.store.dispatch(
        getOrganizationDocumentListsAction({
          request: {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortNames,
            sortType,
            memberId: this.memberId,
            filters: this.documentFilter,
          },
        }),
      );
    } else {
      this.store.dispatch(
        getDocumentListsAction({
          request: {
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            sortNames,
            sortType,
            memberId: this.memberId,
            filters: this.documentFilter,
          },
        }),
      );
    }
  }

  registerGetDocumentListSelector() {
    this.store.pipe(select(selectMemberDocumentListState), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoading = data?.isLoading;
      }
      this.lengthPg = data?.total ?? 0;
      this.dataTable =
        data?.payload?.map((item: any) => {
          return {
            ...item,
            showOnOverview: item.showOnOverview === true ? 'Yes' : item.showOnOverview === false ? 'No' : '',
            uploadedDate: this.datePipe.transform(getDateString(item.uploadedDate), 'MM/dd/yyyy'),
          };
        }) ?? [];
    });
  }

  registerGetOrganizationDocumentListSelector() {
    this.store.pipe(select(selectOrganizationDocumentListState), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoading = data?.isLoading;
      }
      this.lengthPg = data?.total ?? 0;
      this.dataTable =
        data?.payload?.map((item: any) => {
          return {
            ...item,
            showOnOverview: item.showOnOverview === true ? 'Yes' : item.showOnOverview === false ? 'No' : '',
            uploadedDate: this.datePipe.transform(getDateString(item.uploadedDate), 'MM/dd/yyyy'),
          };
        }) ?? [];

    });
  }

  registerGetNavigateDocumentListSelector() {
    this.store
      .select(fromMember.selectNavigateToDocumentListSelector)
      .pipe(
        filter((x: any) => x),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((generateDocumentId) => {
        if (generateDocumentId) {
          this.handleShowBannerGenerating();
          this.generateDocumentId = generateDocumentId;
          this.checkGeneratingDocument(generateDocumentId);
          this.store.dispatch(clearNavigateToDocumentListsAction());
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkGeneratingDocument(generateId: string) {
    this.store.dispatch(
      checkMemberGeneratingDocumentAction({
        request: {
          generateId,
        },
      }),
    );
  }

  checkGeneratingDocumentSelector() {
    this.store
      .select(selectCheckMemberGeneratingDocumentState)
      .pipe(
        filter((response) => !!response?.success && !response.isLoading && !!response.payload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const generatedDocument = response?.payload?.generatedReports?.find(
          (x) => x.generateId === this.generateDocumentId,
        );
        if (
          generatedDocument?.status === GenerateDocumentStatus.Failed ||
          generatedDocument?.status === GenerateDocumentStatus.Completed
        ) {
          this.isLoading = false;
          this.showBannerGeneratedDocument(generatedDocument?.status);
          this.getData();
          this.store.dispatch(clearGenerateParticipantDocumentsStateAction());
          this.store.dispatch(clearCheckMemberGeneratingDocumentAction());
        } else if (this.generateDocumentId) {
          setTimeout(() => {
            this.checkGeneratingDocument(this.generateDocumentId);
          }, 2000);
        }
      });
  }

  changePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  sortChange(sort: Sort) {
    this.sortInfo = sort;
    this.getData();
  }

  downloadDocument(row: any) {
    this.bannerType = BannerType.Hidden;
    if (!row) {
      return;
    }
    this.downloadFileName = row?.fileName;
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  // Remove single document
  onclickRemoveDocument(row: any) {
    this.bannerType = BannerType.Hidden;
    const mesError = 'Cannot remove Postcard Acknowledgement Letter.';
    const listRemoveDocumentItem: RemoveDocumentItem[] = [
      {
        memberId: row?.memberId,
        documentId: row?.fileId,
        documentLocation: row?.documentLocation,
        calculationBenefitId: row?.calculationBenefitId,
        calculationRecordId: row?.calculationRecordId,
        calculationType: row?.calculationType,
        calculationBenefitDocumentId: row?.calculationBenefitDocumentId,
        calculationBenefitDocumentDetailId: row?.calculationBenefitDocumentDetailId,
        accidentId: row?.accidentId,
        accidentDocumentId: row?.accidentDocumentId,
      },
    ];
    const organizationDocument: RemoveOrganizationItem = {documentIds: [row?.fileId]};
    if (this.entityId === ENTITY_ORGANIZATION_GUID) {
      this.removeOrganizationDocuments({ body: organizationDocument });
    } else {
      if(row?.accidentDocumentClassification === AccidentDocGenerate.AccidentPostcard){
        showBanner.call(this, STATE.FAIL, '', ACTION.REMOVE, {
          customMessage: mesError,
        });
      } else {
        if (row?.calculationBenefitDocumentId) {
          this.memberDocumentService.validateBeforeRemoveDocument({ values: listRemoveDocumentItem }).subscribe((res) => {
            if (!res?.isValid && res?.message) {
              this.showErrorPopup(res?.message);
            } else {
              this.removeDocuments({ values: listRemoveDocumentItem });
            }
          });
        } else {
          this.store.dispatch(
            MemberDetailActions.checkRemoveExitAttachmentAction({
              body: [row?.fileId],
            }),
          );
          const subscription = this.store
            .pipe(select(fromMember.checkRemoveExitAttachmentSelector), takeUntil(this.unsubscribe$))
            .subscribe((state: any) => {
              if (state?.success) {
                const response = state?.response;
                if (!response.isValid && response.message) {
                  this.showErrorPopup(response.message);
                } else {
                  this.removeDocuments({ values: listRemoveDocumentItem });
                }
                this.store.dispatch(MemberDetailActions.clearRemoveExitAttachmentFailure());
                subscription.unsubscribe();
              }
            });
        }
      }
    }
  }

  // Remove multiple document
  onclickRemoveMultipleDocument(listSelectedRow: any) {
    this.bannerType = BannerType.Hidden;
    listSelectedRow = listSelectedRow.filter((row: any) => this.dataTable.some((doc) => row.id === doc.id));
    const listRemoveDocumentItem: RemoveDocumentItem[] = listSelectedRow.map((doc: any) => {
      return {
        memberId: doc?.memberId,
        documentId: doc?.fileId,
        documentLocation: doc?.documentLocation,
        calculationBenefitId: doc?.calculationBenefitId,
        calculationRecordId: doc?.calculationRecordId,
        calculationType: doc?.calculationType,
        calculationBenefitDocumentId: doc?.calculationBenefitDocumentId,
        calculationBenefitDocumentDetailId: doc?.calculationBenefitDocumentDetailId,
        accidentId: doc?.accidentId,
        accidentDocumentId: doc?.accidentDocumentId,
      };
    });
    const organizationListDocumentId: string[] = listSelectedRow.map((doc: any) => {
      return doc?.fileId;
    });
    if (this.entityId === ENTITY_ORGANIZATION_GUID) {
      this.removeOrganizationDocuments({body: {documentIds: organizationListDocumentId}});
    } else {
      const listCalculationDocument = listRemoveDocumentItem.filter((doc: any) => !!doc?.calculationBenefitDocumentId);
      if (listCalculationDocument?.length) {
        this.memberDocumentService.validateBeforeRemoveDocument({ values: listCalculationDocument }).subscribe((res) => {
          if (!res?.isValid && res?.message) {
            this.showErrorPopup(res?.message);
          } else {
            this.removeDocuments({ values: listRemoveDocumentItem });
          }
        });
      } else {
        const listCordOrderRemove: string[] = listSelectedRow.map((doc: any) => doc?.fileId);
        this.store.dispatch(
          MemberDetailActions.checkRemoveExitAttachmentAction({
            body: listCordOrderRemove,
          }),
        );
        const subscription = this.store
          .pipe(select(fromMember.checkRemoveExitAttachmentSelector), takeUntil(this.unsubscribe$))
          .subscribe((state: any) => {
            if (state?.success) {
              const response = state?.response;
              if (!response.isValid && response.message) {
                this.showErrorPopup(response.message);
              } else {
                this.removeDocuments({ values: listRemoveDocumentItem });
              }
              this.store.dispatch(MemberDetailActions.clearRemoveExitAttachmentFailure());
              subscription.unsubscribe();
            }
          });
        }
    }
  }

  removeDocuments(body: DocumentBodyRequest) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: REMOVE_DOCUMENT_POPUP_CONFIG,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(removeDocumentsAction({ body }));
      }
    });
  }

  // Dowload multiple document
  downloadMultipleDocument(listSelectedRow: any) {
    this.downloaded = false;
    this.bannerType = BannerType.Hidden;
    const listSelectedId = listSelectedRow.map((item: any) => item.fileId);
    if (listSelectedRow?.length === 1) {
      this.downloadDocument(listSelectedRow[0]);
    } else if (listSelectedRow?.length > 1) {
      this.store.dispatch(
        prepareDownloadMultipleDocumentsAction({
          fileIds: listSelectedId,
        }),
      );
    }
  }

  validateExistDocumentNameExist(documentId: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.memberDocumentService
              .checkExistDocumentName({
                memberId: this.memberId || '',
                documentName: control.value.toString(),
                documentId: documentId ?? null,
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  uploadDocument() {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
      overviewEntityId: this.entityId,
    };
    const infoForm = {
      isUploadMultipleFile: true,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true,
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const files = objectUpload.files;
        const body: any = {
          participantId: this.memberId,
          fileName: files.length <= 1 ? files[0].name : '', // check if upload multiple or not
          description: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: files.length <= 1 ? objectUpload.documentName : '', // check if upload multiple or not
          documentLocationTitle: DOCUMENT_LOCATION.DOCUMENTLIST,
          documentLocationRouter: `/member/individual-document/${this.memberId}`,
          participantDocumentType: objectUpload?.participantDocumentType,
        };
        this.memberDocumentService.documentFile = files;
        if (this.entityId === ENTITY_ORGANIZATION_GUID) {
          this.store.dispatch(uploadOrganizationDocumentsAction({ body }));
        } else {
          this.store.dispatch(uploadDocumentsAction({ body }));
        }
        this.isLoadingInfoBanner = true;
        this.isHideCloseButton = true;
        this.bannerType = BannerType.Info;
        this.message = 'Documents are being uploaded, this might take a few minutes for everything fully uploaded.';
      }
    });
  }

  editDocument(row: any) {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(row.fileId),
    };

    // Use for Document uploaded from Service History List Card, and must belong to a List Record
    const isDocumentBelongServiceHistoryListCard = row?.entityComponentId?.toUpperCase() === SERVICE_HISTORY_LIST.Id.toUpperCase();
    const isDocumentBelongOneListRecord = !!row?.targetId;
    const isUploadEditDocumentForServiceHistoryListRecord = isDocumentBelongServiceHistoryListCard && isDocumentBelongOneListRecord;
    // End of Use for Document uploaded from Service History List Card, and must belong to a List Record

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: row,
        infoForm,
        isUploadEditDocumentForServiceHistoryListRecord,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const body: any = {
          documentDescription: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: objectUpload.documentName,
          participantDocumentType: objectUpload?.participantDocumentType,
        };

        // call api update document
        this.store.dispatch(
          editDocumentsAction({
            memberId: this.memberId ?? '',
            fileId: row.fileId,
            body,
          }),
        );
      }
    });
  }

  applyFilterEvent(documentFilter: any) {
    this.documentFilter = documentFilter;
    this.getData();
  }

  generateDocument() {
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };

    const generateDocumentDialog = this.dialog.open(GenerateDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        currentEntity,
        memberId: this.memberId,
        documentLocationTitle: DOCUMENT_LOCATION.DOCUMENTLIST,
        documentLocationRouter: `/member/individual-document/${this.memberId}`,
      },
    });
  }

  private handleShowBannerGenerating() {
    this.isLoadingInfoBanner = true;
    this.bannerType = BannerType.Info;
    this.isHideCloseButton = true;
    this.message = 'Document is being generated, this might take a few minutes for everything fully generated.';
  }

  private showBannerGeneratedDocument(status: string) {
    this.isLoadingInfoBanner = false;
    this.isHideCloseButton = false;
    showBanner.call(
      this,
      status === GenerateDocumentStatus.Completed ? STATE.SUCCESS : STATE.FAIL,
      'Document',
      ACTION.GENERATE,
    );
  }

  private showErrorPopup(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Error',
        type: ConfirmType.Warning,
        text: message,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  removeOrganizationDocuments(deleteRequestBody: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: REMOVE_DOCUMENT_POPUP_CONFIG,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(removeOrganizationDocumentsAction(deleteRequestBody));
      }
    });
  }
}
