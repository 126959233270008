import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { takeUntil } from 'rxjs/operators';

import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BaseComponent } from '@ptg-shared/components';

import * as fromReducer from '../../../../../reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ALL_EMPLOYER_DOCUMENT_LIST_BREADSCRUMB_ITEM, ALL_EMPLOYER_DOCUMENT_LIST_URL_PATTERN, ALL_MEMBER_DOCUMENT_LIST_BREADSCRUMB_ITEM, ALL_MEMBER_DOCUMENT_LIST_URL_PATTERN, DOCUMENT_LIST_TITLE, DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM, SINGLE_EMPLOYER_DOCUMENT_LIST_URL_PATTERN, SINGLE_MEMBER_DOCUMENT_LIST_URL_PATTERN, DOCUMENT_LIST_CONFIGURATION_TITLE, DOCUMENT_LIST_CONFIGURATION_PROPERTY_KEY_AND_NAME_MAP } from '../../constants';
import { getDocumentListConfigurationDetailSelector, getDocumentListConfigurationPropertiesSelector, updateDocumentListConfigurationSelector } from '../../store/selectors/document-list-configuration.selector';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { ACTION, CANCEL_CONFIRM_MESSAGE, SORT_TYPE, STATE } from '@ptg-shared/constance/value.const';
import { DocumentListConfigurationState } from '../../store/reducers/document-list-configuration.reducer';
import { clearGetDocumentListConfigurationDetailStateAction, clearGetDocumentListConfigurationPropertiesStateAction, clearUpdateDocumentListConfigurationStateAction, getDocumentListConfigurationDetailAction, getDocumentListConfigurationPropertiesAction, updateDocumentListConfigurationAction } from '../../store/actions/document-list-configuration.action';
import { combineLatest } from 'rxjs';
import { Option } from '@ptg-shared/controls/select/select.component';
import { DocumentListConfigurationAvailableProperty, DocumentListConfigurationDetailProperty, DocumentListConfigurationPropertyKey } from '../../services/models/document-list-configuration.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ACTION_COLUMN, Align, Column, GridComponent, Row, getValidatorsFromColumns } from '@ptg-shared/controls/grid';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupTitle, ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

@Component({
  selector: 'ptg-document-list-configuration',
  templateUrl: './document-list-configuration.component.html',
  styleUrls: ['./document-list-configuration.component.scss'],
})
export class DocumentListConfigurationComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  COLUMN_NAME_MAX_LENGTH = 250;

  @ViewChild('gridOrderColumns')
  gridOrderColumns!: GridComponent<DocumentListConfigurationDetailProperty>;
  @ViewChild('gridSortRow')
  gridSortRow!: GridComponent<DocumentListConfigurationDetailProperty>;
  
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: DOCUMENT_LIST_CONFIGURATION_TITLE,
      url: '',
    },
  ];

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  url: string = '';
  isFromOverview: boolean = false;
  memberId: string = '';
  employerId: string = '';

  isDataLoading: boolean = true;

  isDataChanged: boolean = false;

  currentAvailablePropertyList: (DocumentListConfigurationAvailableProperty & Option)[] = [];
  selectedProperty: (DocumentListConfigurationAvailableProperty & Option) | null = null;

  currentOrderColumnsTableData: (DocumentListConfigurationDetailProperty & Row)[] = [];

  currentSortRowTableData: (DocumentListConfigurationDetailProperty & Row)[] = [];

  orderColumnsTableColumns: Column[] = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.COLUMN_NAME_MAX_LENGTH),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkColumnNameExist(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
      controlArgs: {
        placeholder: 'Column Name'
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  checkColumnNameExist(obj: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const orderColumns = [...this.currentOrderColumnsTableData];
      const existed = orderColumns.some(
        (item) =>
          !(item?.propertyKey === obj?.propertyKey)
          && item.form?.value?.columnName?.toLowerCase()?.trim() === control?.value?.toLowerCase()?.trim()
      );
      return existed ? { existed: true } : null;
    };
  }

  sortRowTableColumns: Column[] = [
    {
      name: 'columnName',
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };

  addColumnSectionformGroup: FormGroup = this.fb.group({
    propertyName: this.fb.control('', [Validators.required]),
    columnName: this.fb.control('', [Validators.required, this.checkColumnNameDuplicated()]),
  });

  checkColumnNameDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.currentOrderColumnsTableData?.length) {
        return null;
      }
      if (
        this.currentOrderColumnsTableData?.find(
          (item: { columnName: string }) =>
            item.columnName?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: 'Column Name already exists.',
        };
      }
      return null;
    };
  }

  get propertyNameDropdownControl(): FormControl {
    return this.addColumnSectionformGroup?.get('propertyName') as FormControl;
  }

  get columnNameTextboxControl(): FormControl {
    return this.addColumnSectionformGroup?.get('columnName') as FormControl;
  }

  constructor(
    private store: Store<fromReducer.State>,
    private documentListConfigurationStore: Store<DocumentListConfigurationState>,
    public route: ActivatedRoute,
    public router: Router,
    public layoutService: LayoutService,
    private readonly fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store.dispatch(LayoutActions.hiddenSideMenu());

    this.handleCurrentRoute();
    this.handleBreadcrumb();

    this.getDocumentConfigurationPropertiesAndDetailData();
    this.registerGetDocumentConfigurationPropertiesAndDetailData();
    this.registerUpdateDocumentListConfigurationSelector();
  }

  handleCurrentRoute() {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params?.memberId) {
          this.memberId = params?.memberId;
        }
        if (params?.employerId) {
          this.employerId = params?.employerId;
        }
      }
    );

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((queryParams) => {
        if (queryParams?.overview) {
          this.isFromOverview = queryParams?.overview === 'true';
        }
      }
    );
  }

  getDocumentConfigurationPropertiesAndDetailData() {
    this.documentListConfigurationStore.dispatch(
      getDocumentListConfigurationPropertiesAction({ request: {}})
    );
    this.documentListConfigurationStore.dispatch(
      getDocumentListConfigurationDetailAction({ request: {}})
    );
  }

  registerGetDocumentConfigurationPropertiesAndDetailData() {
    combineLatest([
      this.documentListConfigurationStore.select(getDocumentListConfigurationPropertiesSelector),
      this.documentListConfigurationStore.select(getDocumentListConfigurationDetailSelector),
    ]).pipe(takeUntil(this.unsubscribe$))
    .subscribe(([availablePropertiesState, detailPropertiesState]) => {
      this.isDataLoading = !!availablePropertiesState?.isLoading || !!detailPropertiesState?.isLoading;

      if (availablePropertiesState?.isLoading === false && detailPropertiesState?.isLoading === false) {
        if (availablePropertiesState?.success && detailPropertiesState?.success) {
          this.isDataChanged = false;
          this.prepareTablesData(detailPropertiesState?.payload?.properties ?? []);
          this.preparePropertyNameDropdownData(availablePropertiesState?.payload?.properties ?? []);
        }

        this.clearGetDocumentConfigurationPropertiesAndDetailDataStates();
      }
    })
  }

  onSubmit() {
    if (this.gridOrderColumns.formStatus !== AbstractControlStatus.VALID) {
      return;
    }

    const result = this.getSubmitData();

    this.store.dispatch(
      updateDocumentListConfigurationAction({
        request: { properties: result }
      })
    );

    this.resetAddPropertySectionForm();
  }

  getSubmitData() {
    let order = 1;
    const result: DocumentListConfigurationDetailProperty[] =
      this.currentOrderColumnsTableData.map(
        (record: DocumentListConfigurationDetailProperty & Row) => {
          const obj = {
            id: record?.id,
            propertyKey: record?.propertyKey,
            columnName: record?.form?.value?.columnName ?? record?.columnName,
            orderColumn: record.deleted ? -1 : order,
            orderRow: null,
            sortType: null,
          };
          order += record.deleted ? 0 : 1;
          return obj;
        }
      );
    
    order = 1;
    this.currentSortRowTableData.forEach((record) => {
      const index = result.findIndex(
        (item) => item.propertyKey === record.propertyKey
      );
      if (index < 0) {
        return;
      }
      result[index].orderRow = record.deleted ? -1 : order;
      order += 1;
      result[index].sortType = record.sortType;
    });

    return result;
  }

  registerUpdateDocumentListConfigurationSelector() {
    this.documentListConfigurationStore
      .pipe(select(updateDocumentListConfigurationSelector), takeUntil(this.unsubscribe$))
      .subscribe((updateDocumentListConfigurationState) => {
        this.layoutService.showLoading = !!updateDocumentListConfigurationState?.isLoading;

        if (updateDocumentListConfigurationState && updateDocumentListConfigurationState?.isLoading === false) {
          this.documentListConfigurationStore.dispatch(clearGetDocumentListConfigurationPropertiesStateAction());

          showBanner.call(this, updateDocumentListConfigurationState?.state?.state as string, DOCUMENT_LIST_CONFIGURATION_TITLE, ACTION.EDIT);
          
          if (updateDocumentListConfigurationState?.state?.state === STATE.SUCCESS) {
            this.getDocumentConfigurationPropertiesAndDetailData();
          }
        }
      }
    );
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: ConfirmPopupTitle.CancelAction,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.resetToInitialState();
      }
    });
  }

  prepareTablesData(detailProperties: DocumentListConfigurationDetailProperty[]) {
    // Prepare data for Order Columns table
    this.currentOrderColumnsTableData = detailProperties
      .map((prop) => {
        const obj = {
          ...prop,
          columnNameDescription: DOCUMENT_LIST_CONFIGURATION_PROPERTY_KEY_AND_NAME_MAP.get(prop.propertyKey),
          isUsedInSortRowTable: prop.orderRow !== null,
        };
        return this.createInlineEditFormControls(obj);
      })
      .sort((a, b) => Number(a.orderColumn) - Number(b.orderColumn));
    
    // Prepare data for Sort Row table
    this.currentSortRowTableData = detailProperties
      .reduce((result, prop) => {
        if (prop.orderRow) {
          result.push({
            ...prop,
            columnNameDescription: DOCUMENT_LIST_CONFIGURATION_PROPERTY_KEY_AND_NAME_MAP.get(prop.propertyKey),
          });
        }
        return result;
      }, [] as DocumentListConfigurationDetailProperty[])
      .sort((a, b) => Number(a.orderRow) - Number(b.orderRow));
  }

  preparePropertyNameDropdownData(availableProperties: DocumentListConfigurationAvailableProperty[]) {
    const list: (DocumentListConfigurationAvailableProperty & Option)[] = availableProperties?.map((item: DocumentListConfigurationAvailableProperty) => {
      const isUsedInOrderColumnsTable = !!this.currentOrderColumnsTableData?.some((orderColumn: any) => orderColumn?.propertyKey === item?.key);

      return {
        ...item,
        value: item?.key,
        displayValue: item?.name,
        isHide: isUsedInOrderColumnsTable,
      }
    });

    this.currentAvailablePropertyList = deepClone(list);
  }

  createInlineEditFormControls(
    obj: DocumentListConfigurationDetailProperty
  ): DocumentListConfigurationDetailProperty {
    var result = {
      ...obj,
      form: this.fb.group({
        columnName: new FormControl(
          obj.columnName,
          getValidatorsFromColumns(
            this.orderColumnsTableColumns[0].name,
            this.orderColumnsTableColumns,
            obj
          )
        ),
      }),
    };

    return result;
  }

  changePropertyNameDropdown() {
    const value = this.propertyNameDropdownControl?.value;

    if (!value) return;

    this.selectedProperty = this.currentAvailablePropertyList?.find((item) => item?.value === value) ?? null;
    if (this.selectedProperty) {
      this.selectedProperty!.isHide = true;
      this.columnNameTextboxControl.setValue(this.selectedProperty?.displayValue);
    }
  }

  addColumn() {
    this.addColumnSectionformGroup.markAllAsTouched();
    if (!this.addColumnSectionformGroup.valid) {
      return;
    }

    if (this.selectedProperty) {
      const newRecord: DocumentListConfigurationDetailProperty = {
        propertyKey: this.selectedProperty.key as DocumentListConfigurationPropertyKey,
        columnName: this.columnNameTextboxControl?.value ?? '',
        columnNameDescription: DOCUMENT_LIST_CONFIGURATION_PROPERTY_KEY_AND_NAME_MAP.get(this.selectedProperty.key),
        orderColumn: this.currentOrderColumnsTableData?.length + 1,
        orderRow: null,
        sortType: null,
        isUsedInSortRowTable: false,
      };
  
      this.currentOrderColumnsTableData = [
        ...this.currentOrderColumnsTableData,
        this.createInlineEditFormControls(newRecord),
      ];
      this.resetAddPropertySectionForm();
      this.isDataChanged = true;
    }
  }

  onDropEventOrderColumnsTable($event: any) {
    this.currentOrderColumnsTableData = this.gridOrderColumns.dataSource;
    this.isDataChanged = true;
  }

  onDropEventSortRowTable($event: any) {
    this.currentSortRowTableData = this.gridSortRow.dataSource;
    this.isDataChanged = true;
  }

  onRowActions(
    event: { row: DocumentListConfigurationDetailProperty & Row; type: string }
  ) {
    switch (event.type) {
      case this.ACTION.ADD_SORT_ROW: {
        event.row.isUsedInSortRowTable = true;
        this.addOrderColumnPropertyToSortRowTable(event.row);
        const index = this.currentOrderColumnsTableData.findIndex(
          (item) => item.propertyKey === event.row.propertyKey
        );
        if (index > -1) {
          this.currentOrderColumnsTableData[index].isUsedInSortRowTable = true;
          this.currentOrderColumnsTableData = [...this.currentOrderColumnsTableData];
        }
        break;
      }

      case this.ACTION.SORT_CHANGE: {
        this.changeSortTypeForSortRowTableRecord(event.row);
        break;
      }

      case this.ACTION.REMOVE: {
        event.row.deleted = true;
        const index = this.currentSortRowTableData.findIndex(
          (item) => item.propertyKey === event.row.propertyKey
        );
        if (index > -1) {
          this.currentSortRowTableData[index].deleted = true;
          this.currentSortRowTableData = [...this.currentSortRowTableData];
        }
        break;
      }
    }
    this.isDataChanged = true;
  }

  addOrderColumnPropertyToSortRowTable(row: DocumentListConfigurationDetailProperty) {
    if (
      this.currentSortRowTableData.find(
        (item) => item.propertyKey === row.propertyKey
      )
    ) {
      return;
    }

    this.currentSortRowTableData = [
      ...this.currentSortRowTableData,
      {
        ...row,
        orderRow: this.currentSortRowTableData?.length + 1,
        sortType: SORT_TYPE.ASC,
      },
    ];
  }

  changeSortTypeForSortRowTableRecord(row: DocumentListConfigurationDetailProperty) {
    const index = this.currentSortRowTableData.findIndex(
      (item) => item.propertyKey === row.propertyKey
    );
    if (index < 0) {
      return;
    }
    this.currentSortRowTableData[index].sortType =
      this.currentSortRowTableData[index].sortType === SORT_TYPE.ASC
        ? SORT_TYPE.DESC
        : SORT_TYPE.ASC;
    this.currentSortRowTableData = [...this.currentSortRowTableData];
  }

  onSoftDeleteOrderColumnsTableRecord(row: DocumentListConfigurationDetailProperty & Row) {
    row.deleted = true;
    const deletedItem = this.currentOrderColumnsTableData.find(
      (item) => item.propertyKey === row.propertyKey
    );
    if (deletedItem) {
      deletedItem.deleted = true;
      this.isDataChanged = true;
    }

    const index = this.currentSortRowTableData.findIndex(
      (item) => item.propertyKey === row.propertyKey
    );
    if (index > -1) {
      this.currentSortRowTableData[index].deleted = true;
      this.currentSortRowTableData = [...this.currentSortRowTableData];
    }
  }

  onChangeOrderColumnsTable() {
    this.isDataChanged = true;
  }

  resetAddPropertySectionForm() {
    this.addColumnSectionformGroup.reset();
  }

  resetToInitialState() {
    this.resetAddPropertySectionForm();
    this.getDocumentConfigurationPropertiesAndDetailData();
  }

  handleBreadcrumb() {
    const url = deepClone(this.router.url);

    switch (true) {
      // Come from Left menu All Member Document List
      case url.includes(ALL_MEMBER_DOCUMENT_LIST_URL_PATTERN):
        this.listBreadcrumbs = [
          ALL_MEMBER_DOCUMENT_LIST_BREADSCRUMB_ITEM,
          DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM,
        ];
        return;

      // Come from Left menu All Employer Document List
      case url.includes(ALL_EMPLOYER_DOCUMENT_LIST_URL_PATTERN):
        this.listBreadcrumbs = [
          ALL_EMPLOYER_DOCUMENT_LIST_BREADSCRUMB_ITEM,
          DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM,
        ];
        return;

      // Come from Single Member Overview -> Left menu Document List
      case url.includes(SINGLE_MEMBER_DOCUMENT_LIST_URL_PATTERN) && !this.isFromOverview:
        this.listBreadcrumbs = [
          {
            name: DOCUMENT_LIST_TITLE,
            url: `member/individual-document/${this.memberId}`,
          },
          DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM,
        ];
        return;

      // Come from Single Employer Overview -> Left menu Document List
      case url.includes(SINGLE_EMPLOYER_DOCUMENT_LIST_URL_PATTERN) && !this.isFromOverview:
        this.listBreadcrumbs = [
          {
            name: DOCUMENT_LIST_TITLE,
            url: `employer/individual-document/${this.memberId}`,
          },
          DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM,
        ];
        return;

      // Come from Single Member Overview -> Document Card -> Manage Document List
      case url.includes(SINGLE_MEMBER_DOCUMENT_LIST_URL_PATTERN) && this.isFromOverview:
        this.handleBreadcrumbForSingleMemberOverviewDocumentConfigurationFlow();
        return;

      // Come from Single Employer Overview -> Document Card -> Manage Document List
      case url.includes(SINGLE_EMPLOYER_DOCUMENT_LIST_URL_PATTERN) && this.isFromOverview:
        this.listBreadcrumbs = [
          {
            name: 'Overview',
            url: `employer/detail/${this.employerId}`,
          },
          {
            name: DOCUMENT_LIST_TITLE,
            url: `employer/individual-document/${this.employerId}?overview=true`,
          },
          DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM,
        ];
        return;
      default:
        return;
    }
  }

  handleBreadcrumbForSingleMemberOverviewDocumentConfigurationFlow() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu && state?.menu.length > 0) {
          const participantInfo = state?.memberNavigationList as any;

          const isOverviewDetailView = participantInfo.isOverviewDetailView;
          const url = `${isOverviewDetailView 
            ? '/member/detail-view/true' 
            : '/member/summary-view/true'}/${participantInfo.id}/${participantInfo.overviewViewId}/${this.memberId}${participantInfo.entityId 
              ? `?entityReferenceLinkedId=${participantInfo.entityId}` 
              : ''}`;

          this.listBreadcrumbs = [
            {
              name: 'Overview',
              url: url,
            },
            {
              name: DOCUMENT_LIST_TITLE,
              url: `member/individual-document/${this.memberId}?overview=true`,
            },
            DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM,
          ];
        }
      }
    );
  }

  clearGetDocumentConfigurationPropertiesAndDetailDataStates() {
    this.documentListConfigurationStore.dispatch(clearGetDocumentListConfigurationPropertiesStateAction());
    this.documentListConfigurationStore.dispatch(clearGetDocumentListConfigurationDetailStateAction());
  }

  clearStates() {
    this.clearGetDocumentConfigurationPropertiesAndDetailDataStates();
    this.documentListConfigurationStore.dispatch(clearUpdateDocumentListConfigurationStateAction());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearStates();
  }
}
