import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FinalPaymentConfigurationService } from '../../services';
import { GetFinalPaymentConfigurationsResponse } from '../../services/models';
import {
  getFinalPaymentConfigurationsAction,
  getFinalPaymentConfigurationsFailureAction,
  getFinalPaymentConfigurationsSuccessAction,

  setFinalPaymentConfigurationsAction,
  setFinalPaymentConfigurationsFailureAction,
  setFinalPaymentConfigurationsSuccessAction,

} from '../actions';

@Injectable()
export class FinalPaymentConfigurationEffects {
  constructor(
    private actions$: Actions,
    private finalPaymentConfigurationService: FinalPaymentConfigurationService
  ) {}

  getFinalPaymentConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFinalPaymentConfigurationsAction),
      switchMap(() =>
        this.finalPaymentConfigurationService.getFinalPaymentConfigurations().pipe(
          map((response: GetFinalPaymentConfigurationsResponse) => getFinalPaymentConfigurationsSuccessAction({ response })),
          catchError((error) => of(getFinalPaymentConfigurationsFailureAction({ error })))
        )
      )
    )
  );

  setFinalPaymentConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFinalPaymentConfigurationsAction),
      switchMap(({ body }) =>
        this.finalPaymentConfigurationService.setFinalPaymentConfigurations(body).pipe(
          map(() => setFinalPaymentConfigurationsSuccessAction()),
          catchError((error) => of(setFinalPaymentConfigurationsFailureAction({ error })))
        )
      )
    )
  );

}
