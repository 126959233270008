import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskCharacter',
})
export class MaskCharacterPipe implements PipeTransform {
  transform(value: unknown, character = 'X', length = 4): unknown | string {
    if (typeof value !== 'string') {
      return value;
    }
    if (value.length <= length) {
      return value;
    }

    const maskedPart = character.repeat(value.length - length);
    const lastFourChars = value.slice(-length);

    return maskedPart + lastFourChars;
  }
}
