import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ptg-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonName: string | never | any;
  @Input() classInput: string | never | any;
  @Input() isDisabled?: boolean = false;
  @Input() buttonId: string = '';
  @Input() buttonDirective: any = '';
  @Input() labelClass: string = '';
  @Input() iconName: string = '';
  @Input() iconClass: string = 'material-icons-round';
  @Input() iconAlignRight: boolean = false;

  @Output() clickButton = new EventEmitter();
  @Output() mouseDownButton = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  buttonExecuteFn(): void {
    this.clickButton.emit('');
  }

  clickMouseDown(): void {
    this.mouseDownButton.emit('');
  }
}
