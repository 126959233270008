<mat-accordion>
  <mat-expansion-panel togglePosition="before">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon svgIcon="summary"></mat-icon>
        Summary
      </mat-panel-title>
      <mat-panel-description style="color: #2d6c71">
        {{ summary.length }} statements on what was found in the upload
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p *ngFor="let sum of summary">{{ sum }}</p>
  </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
  <mat-expansion-panel togglePosition="before" *ngIf="warnings.length">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon svgIcon="warning"></mat-icon>
        <!-- <mat-icon style="color: #fda703">warning</mat-icon> -->
        Warning
      </mat-panel-title>
      <mat-panel-description style="color: #fbc94a">
        {{ warnings.length }} statements to be aware of before proceeding
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p *ngFor="let warn of warnings">{{ warn }}</p>
  </mat-expansion-panel>
</mat-accordion>
<mat-accordion>
  <mat-expansion-panel togglePosition="before" *ngIf="errors.length">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon svgIcon="error"></mat-icon>
        Error
      </mat-panel-title>
      <mat-panel-description style="color: #7e0e0e">
        {{ errors.length }} statements
        {{ status === 'Invalid' ? 'preventing this import' : 'caused during the import' }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p *ngFor="let err of errors">{{ err }}</p>
  </mat-expansion-panel>
</mat-accordion>
