import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const selectCalculationChildSurvivor = createSelector(selectCalculationModuleState, (state) => state?.calculationChildSurvivor);

// Get Child Survivor Label Name
export const getChildSurvivorLabelNameSelector = createSelector(
  selectCalculationChildSurvivor, 
  (state) => state?.getChildSurvivorLabelName
);

// Get Child Selection
export const getChildSelectionSelector = createSelector(
  selectCalculationChildSurvivor, 
  (state) => state?.getChildSelectionState
);

// Get Child Survivor Validation Before Initiate
export const getChildSurvivorValidationBeforeInitializationSelector = createSelector(
  selectCalculationChildSurvivor,
  (state) => state?.getChildSurvivorValidationBeforeInitialization,
);
