import { createAction, props } from '@ngrx/store';

import { GetInitialPaymentConfigurationsResponse, SetInitialPaymentConfigurationsRequest } from '../../services/models';

export const getInitialPaymentConfigurationsAction = createAction('[GetInitialPaymentConfigurations/API] Send Request');

export const getInitialPaymentConfigurationsSuccessAction = createAction(
  '[GetInitialPaymentConfigurations/API] Success',
  props<{ response: GetInitialPaymentConfigurationsResponse }>(),
);

export const getInitialPaymentConfigurationsFailureAction = createAction(
  '[GetInitialPaymentConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetInitialPaymentConfigurationsStateAction = createAction('[GetInitialPaymentConfigurations] Clear');

export const setInitialPaymentConfigurationsAction = createAction(
  '[SetInitialPaymentConfigurations/API] Send Request',
  props<{ request: SetInitialPaymentConfigurationsRequest }>(),
);

export const setInitialPaymentConfigurationsSuccessAction = createAction(
  '[SetInitialPaymentConfigurations/API] Success',
);

export const setInitialPaymentConfigurationsFailureAction = createAction(
  '[SetInitialPaymentConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearSetInitialPaymentConfigurationsStateAction = createAction('[SetInitialPaymentConfigurations] Clear');
