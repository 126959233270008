import { createAction, props } from '@ngrx/store';

import {
  CreateAdjustmentDetailedQueryParams,
  CreateAdjustmentDetailedRequest,
  GetValidateAdjustmentDetailIdRequest,
  GetValidateAdjustmentDetailIdResponse,
} from '../../services/models';

export const getCreateAdjustmentDetailedAction = createAction(
  '[GetCreateAdjustmentDetailed/API] Send Request',
  props<{ request: CreateAdjustmentDetailedRequest; queries: CreateAdjustmentDetailedQueryParams }>(),
);

export const getCreateAdjustmentDetailedSuccessAction = createAction(
  '[GetCreateAdjustmentDetailed/API] Success',
  props<{ response?: void }>(),
);

export const getCreateAdjustmentDetailedFailureAction = createAction(
  '[GetCreateAdjustmentDetailed/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetCreateAdjustmentDetailedStateAction = createAction('[GetCreateAdjustmentDetailed] Clear');

export const getValidateAdjustmentDetailIdAction = createAction(
  '[GetValidateAdjustmentDetailId/API] Send Request',
  props<{ request: GetValidateAdjustmentDetailIdRequest }>(),
);

export const getValidateAdjustmentDetailIdSuccessAction = createAction(
  '[GetValidateAdjustmentDetailId/API] Success',
  props<{ response: GetValidateAdjustmentDetailIdResponse }>(),
);

export const getValidateAdjustmentDetailIdFailureAction = createAction(
  '[GetValidateAdjustmentDetailId/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetValidateAdjustmentDetailIdStateAction = createAction('[GetValidateAdjustmentDetailId] Clear');
