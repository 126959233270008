import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import { GetInitialPaymentConfigurationsResponse, SetInitialPaymentConfigurationsRequest } from './models';

@Injectable()
export class InitialPaymentConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getInitialPaymentConfigurations(): Observable<GetInitialPaymentConfigurationsResponse> {
    return this.httpClient.get<GetInitialPaymentConfigurationsResponse>(
      `${environment.apiUrl}/payroll-settings/initial-payment-configuration`,
    );
  }

  setInitialPaymentConfigurations(
    request: SetInitialPaymentConfigurationsRequest
  ): Observable<void> {
    let params = new HttpParams();
    params = params.append('configValue', request.configValue ?? false);

    return this.httpClient.put<void>(
      `${environment.apiUrl}/payroll-settings/initial-payment-configuration`,
      {},
      { params }
    );
  }

}
