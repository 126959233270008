import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CheckVersionFlagDirective } from '@ptg-shared/directive/check-version-flag.directive';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { InputZipCodeDirective } from './layout/directive/input-zip-code.directive';
import { SearchBoldDirective } from './layout/directive/search-bold.directive';
import { TruncateTextDirective } from './layout/directive/truncate-text/truncate-text.directive';
import { MaterialUIModule } from './material-ui.module';
import { NgxUIModule } from './ngx-ui.module';
import { PipeModule } from './pipes/pipe.module';
import { CustomTooltipDirective } from './directive/custom-tooltip.directive';
import { SearchRemittanceSubmissionBoldDirective } from '@ptg-shared/layout/directive/search-remittance-submission-bold.directive';

const MODULES = [
  CommonModule,
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
  FormsModule,
  ReactiveFormsModule,
  MaterialUIModule,
  NgxUIModule,
  PipeModule,
];
const COMPONENTS: any = [];
const DIRECTIVES = [
  SearchBoldDirective,
  TruncateTextDirective,
  CheckVersionFlagDirective,
  InputZipCodeDirective,
  CustomTooltipDirective,
  SearchRemittanceSubmissionBoldDirective,
];

@NgModule({
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [...MODULES],
})
export class SharedModule {}
