import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const paymentHeaderConfigurationSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.paymentHeaderConfiguration,
);

export const selectPaymentHeaderConfigurationList = createSelector(
  paymentHeaderConfigurationSelector,
  (state) => state?.getHeaders,
);

export const selectPaymentEntities = createSelector(paymentHeaderConfigurationSelector, (state) => state?.getEntities);

export const createPaymentHeader = createSelector(paymentHeaderConfigurationSelector, (state) => state.createHeader);

export const selectPaymentHeaderConfigurationDetail = createSelector(
  paymentHeaderConfigurationSelector,
  (state) => state?.getHeadersDetail,
);

export const updatePaymentHeader = createSelector(paymentHeaderConfigurationSelector, (state) => state?.updateHeader);

export const selectHeaderConfigApply = createSelector(
  paymentHeaderConfigurationSelector,
  (state) => state?.getHeadersConfigApply,
);

export const selectHeaderDataApply = createSelector(
  paymentHeaderConfigurationSelector,
  (state) => state?.getHeadersDataApply,
);

export const paymentHeaderConfigurationInDialogSelector = createSelector(
  paymentHeaderConfigurationSelector,
  (state) => state?.paymentHeaderConfigurationInDialog,
);
