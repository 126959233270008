import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MODULE_KEY } from '../../types/constants';
import { PayeeDetailState } from '../reducers';

export const selectPayeeDetailModuleState = createFeatureSelector<PayeeDetailState>(MODULE_KEY);

export const getPaymentEarningsDetailState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.paymentEarningsDetail.paymentEarningsDetail,
);

export const getPaymentDeductionsDetailState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.paymentEarningsDetail.payeeDeductionsDetail,
);

export const getPaymentAnnualTotalsState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.paymentEarningsDetail.paymentAnnualTotals,
);

export const editPayrollSettingsState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.editPayrollSettings,
);

export const editPaymentStatusState = createSelector(selectPayeeDetailModuleState, (state) => state.editPaymentStatus);

export const createGeneralAdjustmentState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.createGeneralAdjustment,
);

export const viewAdjustmentDetailsState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.viewAdjustmentDetails,
);

export const createReversalAdjustmentState = createSelector(
  selectPayeeDetailModuleState,
  (state) => state.createReversalAdjustment,
);
