<div
  class="header-metadata"
  [ngClass]="{ 'page-header': !isOverview, 'sub-header': isSubHeader, 'header-loading': isLoading}"
>
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <div class="left-group" *ngIf="isShowChangeMemberDetailBtn && !targetIdFromDialog">
      <div class="option">
        <button
          aria-label="expand icon"
          mat-button
          (click)="changeMemberDetail()"
          class="option-icon"
          [disabled]="navigationDisabled.previous"
        >
          <mat-icon style="color: #828282">expand_less</mat-icon>
        </button>
        <button
          aria-label="expand more icon"
          mat-button
          (click)="changeMemberDetail(true)"
          class="option-icon"
          [disabled]="navigationDisabled.next"
        >
          <mat-icon style="color: #828282">expand_more</mat-icon>
        </button>
      </div>
    </div>
    <div class="main-group" [ngClass]="{'main-group-dialog': !!targetIdFromDialog}">
      <div class="demographics-data no-config" *ngIf="isRetriveError; else retriveSuccessTemp">
        <span>Error trying to retrieve your data, please retry later.</span>
      </div>

      <ng-template #retriveSuccessTemp>
        <div class="left-main-group">
          <ng-container *ngIf="headerItems?.length && headerItems?.length !== 0; else noProfileConfig">
            <div class="demographics-data" *ngFor="let item of headerItems">
              <span class="label">{{ item.propertyName }}</span>
              <ng-container [ngSwitch]="item?.type">
                <span class="value" *ngSwitchCase="EntityPropertyType.Currency">{{
                  (item.value | numberLocalDecimal : "$") || "-"
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Percentage"
                  >{{ (item.value | numberLocalDecimal : "") || "-"
                  }}{{ (item.value | numberLocalDecimal : "") ? "%" : "" }}</span
                >
                <span class="value" *ngSwitchCase="EntityPropertyType.Date">{{
                  !item.value ? "-" : (item.value | date : "MM/dd/yyyy")
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType['Person Name']">{{
                  !item.value ? "-" : (item.value | personName : item.options)
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Address">{{
                  checkNull4Address(item.value) ? "-" : (item.value | address : item.options)
                }}&nbsp;</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Decimal">{{
                  !item.value && item.value !== 0
                    ? "-"
                    : (item.value
                      | numberDecimal
                        : {
                            decimal: getFractionalLengthDecimal(item?.configs)
                          })
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType['Whole Number']">{{
                  (item.value | numberDecimal) || "-"
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Binary">{{
                  item?.value === null || item?.value === undefined
                    ? "-"
                    : item?.value
                    ? item.configs.affirmative
                    : item.configs.negative
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Employer">{{
                  item.value || "-"
                }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Tier">{{ (item?.value | propertyList : item.options) || "-"  }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Lookup">{{ (item?.value | propertyList : item.options) || "-" }}</span>
                <span class="value" *ngSwitchCase="EntityPropertyType.Status">
                  <div class="icon-status-container flex">
                    <mat-icon
                      [matTooltip]="item.value?.tooltip ?? ''"
                      matTooltipClass="custom-tooltip with-arrow"
                      *ngIf="item.value?.icon"
                      class="icon-status"
                      [ngStyle]="{ color: item.value?.color }"
                      >{{ item.value?.icon }}</mat-icon
                    >
                    <div class="status-name">
                      {{ !item.value?.name ? "-" : item.value.name }}
                    </div>
                    <ptg-button
                      *ngIf="item.option === 'Event' && isOverview"
                      iconName="edit"
                      classInput="edit-button"
                      (clickButton)="onEditMemberStatusEvent()"
                    ></ptg-button>
                  </div>
                </span>
                <span class="value" *ngSwitchCase="EntityPropertyType.SSN">{{
                  item.value ? (item.value | ssnNumber) : "-"
                }}</span>
                <ng-container *ngSwitchCase="EntityPropertyType.RichText">
                  <ptg-view-rich-text
                    [title]="item?.propertyName"
                    [content]="item.value"
                    [defaultContent]="'-'"
                  ></ptg-view-rich-text>
                </ng-container>
                <span class="value" *ngSwitchCase="EntityPropertyType['Date Time']">{{
                  !item.value ? "-" : (item?.value | date : "MM/dd/yyyy hh:mm a")
                }}</span>
                <span class="value" *ngSwitchDefault>{{ item.value || "-" }}</span>
              </ng-container>
            </div>
          </ng-container>

          <ng-template #noProfileConfig>
            <div class="demographics-data no-config" *ngIf="(!headerItems?.length || headerItems?.length === 0) && !isLoadingHeaderItems">
              <span>No Property Configured.</span>
            </div>
          </ng-template>
        </div>
        <div *ngIf="isOverview && (actionItems?.length || identityKey || isDeactivate)" class="right-main-group">
          <div class="header-button">
            <ng-container>
              <button mat-button [matMenuTriggerFor]="menu" class="menu-button">
                <mat-icon class="actions-icon">more_vert</mat-icon>
                <span class="menu-text">Actions</span>
                <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="member-action-menu">
                <ng-container *ngIf="isLocked; else unlock">
                  <button
                    mat-menu-item
                    (click)="lockAccount(false)"
                    *ngIf="identityKey"
                  >
                    <mat-icon style="color: #629dba">lock_open</mat-icon>
                    Unlock Account
                  </button>
                </ng-container>
                <ng-template #unlock>
                  <button
                    mat-menu-item
                    (click)="lockAccount(true)"
                    *ngIf="identityKey"
                  >
                    <mat-icon style="color: #629dba">lock</mat-icon>
                    Lock Account
                  </button>
                </ng-template>

                <button
                  *ngFor="let item of actionItems"
                  mat-menu-item
                  (click)="onClickActionItem(item.id)"
                >
                  <mat-icon class="actions-icon">{{ item.icon }}</mat-icon>
                  {{ item.title }}
                </button>

                <button
                  mat-menu-item
                  (click)="deactivateMember()"
                  *ngIf="isDeactivate"
                >
                <mat-icon
                  svgIcon="remove-account"
                  aria-hidden="false"
                  class="actions-icon"
                ></mat-icon>
                  Deactivate Member
                </button>
              </mat-menu>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="right-group" *ngIf="!isSubHeader && !targetIdFromDialog && !isLoading">
      <button (click)="close()" mat-button class="btn-close" [disabled]="shouldDisableCloseButtonInAllCases" aria-label="close button" >
        <mat-icon class="icon-close">close</mat-icon>
        <span *ngIf="isOverview" class="label">Close</span>
      </button>
    </div>
  </ng-container>

  <ng-template #loadingTemplate>
      <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </ng-template>
</div>
