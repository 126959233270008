<div class="add-disability p-2">
  <div class="add-disability__header font-bold">{{ title }}</div>
  <div class="add-disability__body flex flex-col gap-4 py-12">
    <div class="flex flex-col pb-0 gap-6" *ngIf="bannerType">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>
    <div class="add-disability__body--dropdown flex gap-4"
      [ngClass]="
        {
          'basis-25-percent':
            isEditBenefit
            && isShortTerm
            && !(isRecalculate && calculationType === CalculationType.DisabilityLongTerm)
        }
      ">
      <ptg-select
        placeholder="Disability Type"
        [controlField]="disabilityTypeCtrl"
        [listData]="DISABILITY_TERM_OPTION_LIST"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [isRequired]="true"
        [isDisabledSelect]="isDisabilityTypeDisable"
        (changeOptionValue)="onChangeDisabilityTypeOptionValue()"
      ></ptg-select>
      <ptg-datepicker
        [placeholder]="beginDatePlaceholder"
        [controlField]="beginDateCtrl"
        [isRequired]="true"
        (changeValue)="onChangeBeginDate()"
        [isDisabled]="isDisabledBeginDate"
        customError="errorMessageBeginDate"
        [minDate]="minBeginDate"
        [maxDate]="maxBeginDate"
        [notUseErrorMaxDate]="true"
        [notUseErrorMinDate]="true"
      ></ptg-datepicker>
      <ng-container *ngIf="isShowEndDate || isShowRecalculateCalcEndDate">
        <ptg-datepicker
          [placeholder]="endDatePlaceholder"
          [controlField]="endDateCtrl"
          customError="errorMessageEndDate"
          (changeValue)="onChangeEndDate()"
          [isRequired]="isEndDateRequire"
          [minDate]="minEndDate"
          [maxDate]="maxEndDate"
          [notUseErrorMaxDate]="true"
          [notUseErrorMinDate]="true"
        ></ptg-datepicker>
      </ng-container>
      <ng-container *ngIf="isShortTerm">
        <ptg-textbox
          [controlField]="totalDaysUsedControl"
          placeholder="Total Days Used"
          [maxLength]="250"
          [hasLabel]="true"
          [isDisabled]="true"
          *ngIf="isEditBenefit"
        ></ptg-textbox>

        <ptg-textbox
          [controlField]="daysCtrl"
          placeholder="Days"
          [maxLength]="250"
          inputType="Number"
          [checkMinMaxValue]="false"
          [isPositive]="true"
          [isDecimal]="false"
          [hasLabel]="true"
          [isRequired]="true"
          [customValidator]="customDaysValidator()"
          customError="errorMessageDays"
          *ngIf="!isEditBenefit"
        ></ptg-textbox>

        <ptg-textbox
          [controlField]="amountCtrl"
          [hasLabel]="true"
          placeholder="Amount"
          [isDecimal]="true"
          [isPositive]="true"
          [allowZero]="true"
          inputType="Currency"
          [min]="0.0"
          [max]="MAX_VALUE_NUMBER"
          [maxLength]="16"
          errorMinMaxValue="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
          [allowNegativeNumbers]="false"
          [isRequired]="true"
          [customValidator]="customAmountValidator()"
          customError="errorMessageAmount"
          *ngIf="!isEditBenefit"
        ></ptg-textbox>
      </ng-container>
    </div>
    <div *ngIf="data.isRecalculate && isShowReason">
      <ptg-input-area
        [class]="'w-full'"
        [controlField]="recalReasonControl"
        [hasLabel]="true"
        placeholder="Recalculation reason"
        [maxLength]="4000"
        errorRequire="Recalculation reason is required."
        errorMaxLength="Exceed the 4000 character limit."
      ></ptg-input-area>
    </div>

    <div class="related-accident" *ngIf="!data.isRecalculate">
      <div class="related-accident__title font-bold">Select Related Accident</div>
      <div class="related-accident__body">
        <ptg-grid
          [data]="relatedAccidentGridData"
          [columns]="columnRelatedAccident"
          [isLoading]="isLoadingRelatedAccident"
          [totalRecords]="totalRecordRelatedAccident"
          [pageNumber]="pageNumberRelatedAccident"
          [pageSize]="pageSize"
          [notFoundMessage]="notFoundMessageRelatedAccident"
          [fitToParent]="true"
          [hideScrollbar]="false"
          (sortChange)="onSortChangeHistory($event)"
          (pageChange)="onChangeHistoryPage($event)"
        >
          <ng-template cellContent columnName="radio" let-row>
            <mat-radio-button
              [checked]="row.checked"
              [disabled]="isDisabledSelectAccident"
              (change)="onChangeRadioButton(row)"
            ></mat-radio-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </div>
  <div class="add-disability__footer flex justify-end gap-2">
    <ptg-button buttonName="Save" classInput="primary" (clickButton)="onSave()" [isDisabled]="!canSubmit"></ptg-button>
    <ptg-button buttonName="Cancel" classInput="tertiary" (clickButton)="onCancel()"></ptg-button>
  </div>
</div>