import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as CreateGeneralAdjustmentActions from '../actions/create-general-adjustment.actions';
import { CreateGeneralAdjustmentService } from '../../services/create-general-adjustment.service';

@Injectable()
export class CreateGeneralAdjustmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly createGeneralAdjustmentService: CreateGeneralAdjustmentService,
  ) {}

  validateGeneralAdjustment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateGeneralAdjustmentActions.getValidateGeneralAdjustmentAction),
      switchMap(({ params, body }) => {
        return this.createGeneralAdjustmentService.validateGeneralAdjustment(params, body).pipe(
          map((response) => CreateGeneralAdjustmentActions.getValidateGeneralAdjustmentSuccess({ response })),
          catchError((err) => of(CreateGeneralAdjustmentActions.getValidateGeneralAdjustmentFailure(err))),
        );
      }),
    ),
  );

  getDeductionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateGeneralAdjustmentActions.getGetDeductionTypesAction),
      switchMap(({ request }) => {
        return this.createGeneralAdjustmentService.getDeductionTypes(request).pipe(
          map((response) => CreateGeneralAdjustmentActions.getGetDeductionTypesSuccess({ response })),
          catchError((err) => of(CreateGeneralAdjustmentActions.getGetDeductionTypesFailure(err))),
        );
      }),
    ),
  );

  addGeneralAdjustment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateGeneralAdjustmentActions.getAddGeneralAdjustmentAction),
      switchMap(({ params, body }) => {
        return this.createGeneralAdjustmentService.setCreateGeneralAdjustment(params, body).pipe(
          map((response) => CreateGeneralAdjustmentActions.getAddGeneralAdjustmentSuccess({ response })),
          catchError((err) => of(CreateGeneralAdjustmentActions.getAddGeneralAdjustmentFailure(err))),
        );
      }),
    ),
  );
}
