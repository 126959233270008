import { CardComponentType, DetailViewColumnType, EntityProfileComponentType, EntityProfileFixType, EntityPropertyType, EntityViewFixType, EntityViewType, StandalonePropertiesDisplayRule } from '../../types/enums';
import { HeaderPropertyConfig } from '@ptg-member/types/models/header-configuration.model';

export interface EntityView {
  id?: string;
  entityId?: string;
  viewName?: string;
  entityName?: string;
  type?: EntityViewType;
  viewFixType?: EntityViewFixType;
  cardCount?: number;
  listEntityNavigationItem?: any[];
}

export interface GetEntityViewsResponse {
  entityViews: EntityView[];
}

export interface GetCardsByViewRequest {
  viewId?: string;
}

export interface GetCardsByViewResponse {
  entityViewItems: EntityViewItem[];
  isSummaryView: boolean;
  viewName: string;
}

export interface EntityViewItem {
  cardName: string;
  isRight: boolean;
  order: number;
  attachmentType: any;
  isDragDrop: boolean;
  cardId: string;
  entityComponentId?: string;
  cardDisplayType: number;
  cardComponents: CardComponent[];
  datalessConfig: DatalessConfig;
  displayRule: StandalonePropertiesDisplayRule;
  entityId: string;
  listLabel: string;
  isHideCard: boolean;
  isShowButtonAdd: boolean;
  isConfigured: boolean;
  profileFixType?: EntityProfileFixType;
  viewFixType?: number;
  isExistDetailConfig?: boolean;
  viewId?: string;
  viewName?: string;
  tooltipContent?: string;
  isBenefitCard: boolean;
  enableNote?: boolean;
  isInactiveActionAddRelationships?: boolean;
  currentEntityReferenceLinkedId?: string;
}

export interface DatalessConfig {
  isHideEmptyCard: boolean;
  listDisplayPreferenceType: any;
  nonRecordListDisplayType: any;
  nonValuePropertyDisplayType: any;
  propertyDisplayPreferenceType: any;
}

export interface CardComponent {
  propertyDisplay: number;
  detailViewLabel: string;
  componentId: string;
  componentType: EntityProfileComponentType;
  detailViewColumnType?: DetailViewColumnType;
  cardProperties: CardProperty[];
}

export interface CardProperty {
  componentId: string;
  entityPropertyId?: string;
  propertyId: string;
  propertyLabel: string;
  type: EntityPropertyType;
  config: any;
  orderRow: number;
  orderColumn: number;
  value: any;
  valueMask: {
    originalValue: string;
    maskedValue: string;
  };
  options: any;
  option: string;
  isMasked: boolean;
  entityReferencePropertyId: string;
  displayFieldType: any;
}

export interface EntityViewItems {
  entityViewItems: EntityViewItem[];
  isSummaryView: boolean;
  viewName: string;
}

export interface EntityViewItem {
  cardName: string;
  isRight: boolean;
  order: number;
  attachmentType: any;
  isDragDrop: boolean;
  cardId: string;
  cardDisplayType: number;
  cardComponents: CardComponent[];
  datalessConfig: DatalessConfig;
  isHideCard: boolean;
  isShowButtonAdd: boolean;
  isConfigured: boolean;
  profileFixType?: EntityProfileFixType;
  isBenefitCard: boolean;
  cardComponentType: CardComponentType;
  viewFixType?: number;
  isExistDetailConfig?: boolean;
  existOnlyNotePropertyAndEnableNote?: boolean;
}

export interface DatalessConfig {
  isHideEmptyCard: boolean;
  listDisplayPreferenceType: any;
  nonRecordListDisplayType: any;
  nonValuePropertyDisplayType: any;
  propertyDisplayPreferenceType: any;
}

export interface CardProperty {
  componentId: string;
  propertyId: string;
  propertyLabel: string;
  type: EntityPropertyType;
  config: any;
  orderRow: number;
  orderColumn: number;
  value: any;
  valueMask: {
    originalValue: string,
    maskedValue: string
  };
  options: any;
  option: string;
  isMasked: boolean;
  entityReferencePropertyId: string;
  masked: boolean;
  fixedPropertyKey?: string;
  entityReferenceId: string;
}

export interface CardMemberData {
  cardNoData: boolean;
  results: HeaderPropertyConfig;
}

export interface CardValue {
  recordId?: string;
  entityId?: string;
  entityComponentId?: string;
  entityPropertyId?: string;
  entityReferenceId?: string;
  entityReferenceComponentId?: string;
  entityReferencePropertyId?: string;
  value?: any;
}

export interface ListPropertyForCard {
  id?: string;
  values?: any;
  noOfAttachments?: number;
  value?: any;
  options?: any;
  option?: string;
  optionValue?: string;
  displayFieldType?: EntityPropertyType;
  isCourtOrder?: boolean;
  entityPropertyId?: string;
  isRelationshipCard?: boolean;
  isRelatedPerson?: boolean;
}

export interface GetListPropertyForCardsRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  targetId?: string;
  cardId?: string;
  entityId?: string;
  isSummary?: boolean;
  fixedCardName?: string,
  screenId?: string
}

export interface GetListPropertyForCardsResponse {
  rowData: ListPropertyForCard[];
  total: number;
  isCourtOrder?: boolean;
  isRelationshipCard?: boolean;
}

export interface StandAlonePropertyOnlyCardData {
  recordId?: string;
  entityId?: string;
  entityComponentId?: string;
  entityPropertyId?: string;
  entityReferenceId?: string;
  entityReferenceComponentId?: string;
  entityReferencePropertyId?: string;
  configs?: any;
  propertyName?: string;
  type: EntityPropertyType;
  value?: any;
  options: any;
  option: any;
  fixedPropertyKey?: string;
  optionValue?: string;
  displayFieldType?: string;
}

export interface GetStandAlonePropertyOnlyCardDataRequest {
  cardId: string;
  targetId: string;
  entityId: string;
  screenId?: string;
}

export interface GetStandAlonePropertyOnlyCardDataResponse {
  results: StandAlonePropertyOnlyCardData[];
}

export interface MemberEntityAttachments {
  id: string;
  targetId: string;
  documentName: string;
  description: string;
  fileSnapshot: any;
  attachment: string;
  participantDocumentType: any;
}

export interface GetMemberEntityAttachmentsRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  memberId: string;
  targetId?: string; // cardid or rowId
  entityComponentId?: string;
}

export interface GetMemberEntityAttachmentsResponse {
  items: MemberEntityAttachments[];
  total: number;
}

export interface SearchEntityReferenceExceptRecord {
  entityPropertyId: string;
  recordId: string;
}

