import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  BenefitCalculationConfigurationsByType,
  CheckComputeDataResponse,
  CheckStepConfigurationExistsResponse,
  ComputationDataResponse,
  ParameterMappings,
} from '../../services/models';
import {
  getBenefitCalculationParameterMappingAction,
  getBenefitCalculationParameterMappingSuccessAction,
  getBenefitCalculationParameterMappingFailureAction,
  clearGetBenefitCalculationParameterMappingStateAction,
  getCalculationDisplayConfigurationByTypeAction,
  getCalculationDisplayConfigurationByTypeSuccessAction,
  getCalculationDisplayConfigurationByTypeFailureAction,
  clearGetCalculationDisplayConfigurationByTypeStateAction,
  setModifyCalculationDisplayConfigurationAction,
  setModifyCalculationDisplayConfigurationSuccessAction,
  setModifyCalculationDisplayConfigurationFailureAction,
  clearSetModifyCalculationDisplayConfigurationStateAction,
  completeCalculationBenefitAction,
  completeCalculationBenefitSuccessAction,
  completeCalculationBenefitFailureAction,
  approveCalculationBenefitAction,
  approveCalculationBenefitSuccessAction,
  approveCalculationBenefitFailureAction,
  reopenCalculationBenefitAction,
  reopenCalculationBenefitSuccessAction,
  reopenCalculationBenefitFailureAction,
  validateCalculationBenefitAction,
  validateCalculationBenefitSuccessAction,
  validateCalculationBenefitFailureAction,
  computeCalculationBenefitAction,
  computeCalculationBenefitSuccessAction,
  computeCalculationBenefitFailureAction,
  clearComputeCalculationBenefitStateAction,
  clearApproveCalculationBenefitStateAction,
  clearCompleteCalculationBenefitStateAction,
  clearReopenCalculationBenefitStateAction,
  clearValidateCalculationBenefitAction,
  clearValidateBeforeCalculationBenefitStateAction,
  validateBeforeCalculationBenefitAction,
  validateBeforeCalculationBenefitFailureAction,
  validateBeforeCalculationBenefitSuccessAction,
} from '../actions';

export interface BenefitCalculationConfigurationState {
  getBenefitCalculationParameterMapping?: BaseActionState<ParameterMappings>;
  getCalculationDisplayConfigurationByType?: BaseActionState<BenefitCalculationConfigurationsByType>;
  setModifyCalculationDisplayConfiguration?: BaseActionState;
  completeCalculationBenefit?: BaseActionState;
  approveCalculationBenefit?: BaseActionState;
  reopenCalculationBenefit?: BaseActionState;
  validateCalculationBenefit?: BaseActionState<CheckComputeDataResponse>;
  computeCalculationBenefit?: BaseActionState<ComputationDataResponse>;
  validateBeforeComputeJoinSurvivor?: BaseActionState<any>;
}

const initialState: BenefitCalculationConfigurationState = {};

export const benefitCalculationConfigurationReducer = createReducer(
  initialState,
  // GetCalculationParameterMapping
  on(getBenefitCalculationParameterMappingAction, (state) => ({
    ...state,
    getBenefitCalculationParameterMapping: {
      isLoading: true,
    },
  })),
  on(getBenefitCalculationParameterMappingSuccessAction, (state, { response }) => ({
    ...state,
    getBenefitCalculationParameterMapping: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getBenefitCalculationParameterMappingFailureAction, (state, { error }) => ({
    ...state,
    getBenefitCalculationParameterMapping: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetBenefitCalculationParameterMappingStateAction, (state) => ({
    ...state,
    getBenefitCalculationParameterMapping: undefined,
  })),

  // GetCalculationDisplayConfigurationByType
  on(getCalculationDisplayConfigurationByTypeAction, (state) => ({
    ...state,
    getCalculationDisplayConfigurationByType: {
      isLoading: true,
    },
  })),
  on(getCalculationDisplayConfigurationByTypeSuccessAction, (state, { response }) => ({
    ...state,
    getCalculationDisplayConfigurationByType: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCalculationDisplayConfigurationByTypeFailureAction, (state, { error }) => ({
    ...state,
    getCalculationDisplayConfigurationByType: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCalculationDisplayConfigurationByTypeStateAction, (state) => ({
    ...state,
    getCalculationDisplayConfigurationByType: undefined,
  })),

  // ModifyCalculationDisplayConfiguration
  on(setModifyCalculationDisplayConfigurationAction, (state) => ({
    ...state,
    setModifyCalculationDisplayConfiguration: {
      isLoading: true,
    },
  })),
  on(setModifyCalculationDisplayConfigurationSuccessAction, (state) => ({
    ...state,
    setModifyCalculationDisplayConfiguration: {
      isLoading: false,
      success: true,
    },
  })),
  on(setModifyCalculationDisplayConfigurationFailureAction, (state, { error }) => ({
    ...state,
    setModifyCalculationDisplayConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetModifyCalculationDisplayConfigurationStateAction, (state) => ({
    ...state,
    setModifyCalculationDisplayConfiguration: undefined,
  })),
  // CompleteCalculationBenefit
  on(completeCalculationBenefitAction, (state) => ({
    ...state,
    completeCalculationBenefit: {
      isLoading: true,
    },
  })),
  on(completeCalculationBenefitSuccessAction, (state) => ({
    ...state,
    completeCalculationBenefit: {
      isLoading: false,
      success: true,
    },
  })),
  on(completeCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    completeCalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCompleteCalculationBenefitStateAction, (state) => ({
    ...state,
    completeCalculationBenefit: undefined,
  })),

  // ApproveCalculationBenefit
  on(approveCalculationBenefitAction, (state) => ({
    ...state,
    approveCalculationBenefit: {
      isLoading: true,
    },
  })),
  on(approveCalculationBenefitSuccessAction, (state) => ({
    ...state,
    approveCalculationBenefit: {
      isLoading: false,
      success: true,
    },
  })),
  on(approveCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    approveCalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearApproveCalculationBenefitStateAction, (state) => ({
    ...state,
    approveCalculationBenefit: undefined,
  })),

  // ReopenCalculationBenefit
  on(reopenCalculationBenefitAction, (state) => ({
    ...state,
  })),
  on(reopenCalculationBenefitSuccessAction, (state) => ({
    ...state,
    reopenCalculationBenefit: {
      isLoading: false,
      success: true,
    },
  })),
  on(reopenCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    reopenCalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearReopenCalculationBenefitStateAction, (state) => ({
    ...state,
    reopenCalculationBenefit: undefined,
  })),

  // validate CalculationBenefit
  on(validateCalculationBenefitAction, (state) => ({
    ...state,
    validateCalculationBenefit: {
      isLoading: true,
    },
  })),
  on(validateCalculationBenefitSuccessAction, (state, { response }) => ({
    ...state,
    validateCalculationBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    validateCalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateCalculationBenefitAction, (state) => ({
    ...state,
    validateCalculationBenefit: undefined,
  })),

  // compute CalculationBenefit
  on(computeCalculationBenefitAction, (state) => ({
    ...state,
    computeCalculationBenefit: {
      isLoading: true,
    },
  })),
  on(computeCalculationBenefitSuccessAction, (state, { response }) => ({
    ...state,
    computeCalculationBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(computeCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    computeCalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearComputeCalculationBenefitStateAction, (state) => ({
    ...state,
    computeCalculationBenefit: undefined,
  })),

  // validate before CalculationBenefit with JointSurvivor
  on(validateBeforeCalculationBenefitAction, (state) => ({
    ...state,
    validateBeforeComputeJoinSurvivor: {
      isLoading: true,
    },
  })),
  on(validateBeforeCalculationBenefitSuccessAction, (state, { response, sectionType }) => ({
    ...state,
    validateBeforeComputeJoinSurvivor: {
      isLoading: false,
      success: true,
      payload: { ...response, sectionType },
    },
  })),
  on(validateBeforeCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    validateBeforeComputeJoinSurvivor: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateBeforeCalculationBenefitStateAction, (state) => ({
    ...state,
    validateBeforeComputeJoinSurvivor: undefined,
  })),
);
