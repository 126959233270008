import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ParametersQuery } from '@ptg-employer/models';
import { GetGeneratedReportResponse, GetManageReportResponse, GetReportCategoryNameResponse, GetReportCategorySidebarResponse } from '@ptg-member/types/models/participant-report.model';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../reducers/index';
import { IEditReportConfig } from '@ptg-member/components/participant-report/report-config/report-config.component';
import { GUID_EMPTY } from '@ptg-shared/constance';

@Injectable({
  providedIn: 'root',
})
export class ParticipantReportService {
  controller: string = 'report';
  activeFund: any;
  reportTemplateFile: any;
  currentRowId: string = '';

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
  }

  getManageReport(query: ParametersQuery): Observable<GetManageReportResponse> {
    let body: any = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    };
    if (query.sortNames) {
      body = {
        ...body,
        sortNames: [query?.sortNames],
        sortType: query.sortType || 0,
      };
    }
    let params = new HttpParams({ fromObject: body as any });

    return this.httpClient.get<GetManageReportResponse>(
      `${environment.apiUrl}/${this.controller}`,
      { params });
  }

  getReportCategorySideBar(): Observable<GetReportCategorySidebarResponse> {
    if (this.activeFund?.id) {
      return this.httpClient.get<GetReportCategorySidebarResponse>(`${environment.apiUrl}/${this.controller}/category-sidebar`);
    }
    return of();
  }

  deleteReport(id: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/${this.controller}/${id}`
    );
  }

  createReportConfig(body: IEditReportConfig) {
    const formData = new FormData();
    formData.append('CategoryName', body.CategoryName);
    formData.append('ReportName', body.ReportName);
    if(body.ReportCategoryId)
      formData.append('ReportCategoryId', body.ReportCategoryId);
    formData.append('CreateType', body.CreateType.toString());
    formData.append('Description', body.Description);
    formData.append('Template', this.reportTemplateFile);
    return this.httpClient.post(`${environment.apiUrl}/${this.controller}/report-config`, formData);
  }

  getReportCategoryName(): Observable<GetReportCategoryNameResponse> {
    return this.httpClient.get<GetReportCategoryNameResponse>(`${environment.apiUrl}/${this.controller}/category-name`);
  }

  editReportConfig(reportId: string, body: IEditReportConfig) {
    const formData = new FormData();
    formData.append('ReportId', reportId);
    formData.append('CategoryName', body.CategoryName);
    formData.append('ReportName', body.ReportName);
    if(body.ReportCategoryId)
      formData.append('ReportCategoryId', body.ReportCategoryId);
    formData.append('CreateType', body.CreateType.toString());
    formData.append('Description', body.Description);
    if(this.reportTemplateFile !== undefined)
      formData.append('Template', this.reportTemplateFile);
    this.reportTemplateFile = undefined;
    return this.httpClient.put(`${environment.apiUrl}/${this.controller}/${reportId}`, formData);
  }

  checkReportCategoryNameExist = (reportCategoryName: any) => {
    let params = new HttpParams({
      fromObject: {
        'name': reportCategoryName?.category ?? ''
      }  
    });
    return this.httpClient.get(`${environment.apiUrl}/${this.controller}/category/exist`, {params});
  }

  checkReportNameExist = (reportName: any) => {
    var fromObject: any = {
      'name': reportName?.reportName ?? '',
    }  
    if(this.currentRowId !== '') {
      fromObject = {
        ...fromObject,
        'id' : this.currentRowId
      }
    }
    let params = new HttpParams({
      fromObject: fromObject
    });
    
    return this.httpClient.get(`${environment.apiUrl}/${this.controller}/exist`, {params});
  }

  getGeneratedReport(query: ParametersQuery): Observable<GetGeneratedReportResponse> {
    let body: any = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    };
    if (query.sortNames) {
      body = {
        ...body,
        sortNames: [query?.sortNames],
        sortType: query.sortType || 0,
      };
    }
    let params = new HttpParams({ fromObject: body as any });

    return this.httpClient.get<GetGeneratedReportResponse>(
      `${environment.apiUrl}/${this.controller}/generated`,
      { params });
  }

  deleteGeneratedReport(reportId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/${this.controller}/generated/${reportId}`
    );
  }
}
