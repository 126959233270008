<ptg-dialog
  class="edit-entity-reference-dialog"
  [title]="'Edit Property'"
  [footerPosition]="'bottom'"
  [footerAlign]="'right'"
  [isFullScreen]="false"
>
  <ng-template dialogBody>
    <form class="edit-form" [formGroup]="editForm">
      <div class="input-container">
        <ptg-textbox
          [controlField]="propertyNameCtrl"
          placeholder="Property Name"
          [maxLength]="250"
          [hasLabel]="true"
          [isRequired]="true"
          errorAsync="Property Name or Import Label already exists."
        ></ptg-textbox>
        <div class="break-line">
          <span class="title">Property Type</span>
          <span class="value"> Entity Reference</span>
        </div>
      </div>
      <div class="input-container">
        <div class="config-section">
          <div>
            <ptg-toggle-button
              [controlField]="requiredConditionCtrl"
              label="Required"
            ></ptg-toggle-button>
          </div>
          <div>
            <ptg-toggle-button
              [controlField]="includeInBulkUpdateCtrl"
              (valueChange)="cleanImportLabel($event)"
              label="Enable Bulk Update"
            ></ptg-toggle-button>
            <ng-container *ngIf="includeInBulkUpdateCtrl?.value">
              <div
                [ngClass]="{
                  'error-margin-bottom':
                    editForm.get('importLabel')?.touched &&
                    editForm.get('importLabel')?.errors
                }"
                class="input-import-label"
              >
                <ptg-textbox
                  [controlField]="importLabelCtrl"
                  [maxLength]="250"
                  [hasLabel]="true"
                  [isRequired]="true"
                  placeholder="Import Label"
                  errorAsync="Property Name or Import Label already exists."
                ></ptg-textbox>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="divider"></div>
        <span class="linked-entity"> Linked Entity </span>
        <div class= "entity">
          <div *ngFor="let item of listEntity">
            <div class="break-line-entity">
              <span class="title">Entity</span>
              <span class="value"> {{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template dialogFooter>
    <button
      mat-raised-button
      type="button"
      class="submit"
      (click)="formSubmit$.next()"
    >
      Save
    </button>
    <button
      mat-raised-button
      type="button"
      class="cancel"
      (click)="onClickCancel()"
    >
      Cancel
    </button>
  </ng-template>
</ptg-dialog>
