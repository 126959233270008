import { createSelector } from '@ngrx/store';

import { payrollSelector } from './selector';

export const selectPayrollRunState = createSelector(payrollSelector, (state) => state?.payrollRun);

export const getPayrollRunSummarySelector = createSelector(
  selectPayrollRunState,
  (state) => state?.getPayrollRunSummary,
);

export const getBankAccountsDetailSelector = createSelector(
  selectPayrollRunState,
  (state) => state?.getBankAccountsDetail,
);
export const getPayrollRunListPaymentSelector = createSelector(
  selectPayrollRunState,
  (state) => state?.getListPaymentInstruction,
);

export const setIssuePaymentSelector = createSelector(selectPayrollRunState, (state) => state?.setIssuePayment);

export const updateTransactionsSelector = createSelector(selectPayrollRunState, (state) => state?.updateTransactions);
export const submitToBankSelector = createSelector(selectPayrollRunState, (state) => state?.submitToBank);
export const getPaymentLevelErrorListSelector = createSelector(
  selectPayrollRunState,
  (state) => state?.getPaymentLevelErrorList,
);
export const editCheckNumberSelector = createSelector(selectPayrollRunState, (state) => state?.editCheckNumber);

export const editCheckMemoSelector = createSelector(selectPayrollRunState, (state) => state?.editCheckMemo);

export const resetPayrollSelector = createSelector(selectPayrollRunState, (state) => state?.resetPayroll);

export const cancelPayrollSelector = createSelector(selectPayrollRunState, (state) => state?.cancelPayroll);

export const nextYearTaxSelector = createSelector(selectPayrollRunState, (state) => state?.getNextYearTax);

export const getSearchPayeesSelector = createSelector(selectPayrollRunState, (state) => state?.getSearchPayees);

export const getACHFileSelector = createSelector(selectPayrollRunState, (state) => state?.getACHFile);

export const clearAuditTrailSelector = createSelector(selectPayrollRunState, (state) => state?.clearAuditTrail);

export const editStartCheckNumberReviewSelector = createSelector(selectPayrollRunState, (state) => state?.editStartCheckNumberReview);

export const editStartCheckNumberDeductionSelector = createSelector(selectPayrollRunState, (state) => state?.editStartCheckNumberDeduction);
