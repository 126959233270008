import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { filter, takeUntil, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { SortType } from '@ptg-shared/constance';
import { Column, Row } from '@ptg-shared/controls/grid';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';

import {
  PayeeDetailState,
  getAdjustmentDetailsAction,
  clearAdjustmentDetailsState,
  getAdjustmentDetailsSelector,
} from '../../store';
import { AdjustmentDetailedType, PaymentInfoAdjustmentType } from '../../types/enums';
import { PaymentInfoTabLoadingStateHandler, PaymentTab } from '../../services/models';
import { handlePaymentInfoTabLoadingState } from '../../types/constants/payment-info-tab.constant';
import { GRID_COLUMN_VIEW_ADJUSTMENT_DETAILS } from '../../types/constants/view-adjustment-details.constants';
import { AdjustmentDetailed, ViewAdjustmentDetailsRequest } from '../../services/models/view-adjustment-details.model';
import { PaymentInstructionDetailComponentService } from '../../pages/payment-instruction-detail/payment-instruction-detail.component.service';

@Component({
  selector: 'ptg-view-adjustment-details',
  templateUrl: './view-adjustment-details.component.html',
  styleUrls: ['./view-adjustment-details.component.scss'],
})
export class ViewAdjustmentDetailsComponent
  extends BaseListComponent
  implements OnChanges, PaymentInfoTabLoadingStateHandler
{
  readonly PAGE_KEY = 'view-adjustment-details';
  @Input() selectedRow?: PaymentTab;
  @Output() onDataListChange = new EventEmitter<{ adjustmentDetails: AdjustmentDetailed[]; onReloading?: boolean }>();

  // Loading Indicator
  isLoading = true;

  // Grid variables
  private sortInfo: Sort = { active: '', direction: '' };
  totalRecords = 0;
  pageIndex = FIRST_PAGE;
  adjustmentDetailList: AdjustmentDetailed[] = [];
  readonly adjustmentDetailColumnList: Column[] = GRID_COLUMN_VIEW_ADJUSTMENT_DETAILS;

  constructor(
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    private readonly payeeDetailStore: Store<PayeeDetailState>,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selectViewAdjustmentDetailsState();
    handlePaymentInfoTabLoadingState(this, this.payeeDetailStore);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow.currentValue !== changes.selectedRow.previousValue) {
      this.onDataListChange.emit({ adjustmentDetails: [], onReloading: true });
      this.getAdjustmentDetails();
    }
  }

  onSortChange(event: Sort): void {
    this.sortInfo = event;
    if (event.active === 'itemTypeLabel') this.sortInfo.active = 'itemType';
    this.getAdjustmentDetails();
  }

  onPageChange(event: PageEvent): void {
    super.onChangePage(event);
    this.selectedPageSize = event.pageSize;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageNumber;
    this.getAdjustmentDetails();
  }

  onClickRow(row: Row & AdjustmentDetailed): void {
    this.paymentInstructionDetailComponentService.getAdjustmentDetailItemPosition({ adjustmentId: row?.adjustmentId });
  }

  private getAdjustmentDetails(): void {
    if (
      !this.selectedRow?.paymentInstructionHistoryId ||
      this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
    ) {
      this.adjustmentDetailList = [];
      this.onDataListChange.emit({ adjustmentDetails: this.adjustmentDetailList });
      return;
    }

    // Sort by names & direction (if any)
    let sortNames: string[] = [];
    let sortType: SortType = SortType.ASC;
    const { active: sortName = '', direction = '' } = this.sortInfo;
    if (sortName && direction) {
      sortNames = [sortName];
      sortType = direction === 'asc' ? SortType.ASC : SortType.DESC;
    }

    const request: ViewAdjustmentDetailsRequest = {
      paymentInstructionHistoryId: this.selectedRow.paymentInstructionHistoryId,
      memberId: this.route.snapshot.params.id ?? '',
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortNames,
      sortType,
    };
    this.payeeDetailStore.dispatch(getAdjustmentDetailsAction({ request }));
  }

  private selectViewAdjustmentDetailsState(): void {
    this.payeeDetailStore
      .select(getAdjustmentDetailsSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(clearAdjustmentDetailsState());

        const { total = 0, adjustmentDetailed = [] } = response?.payload ?? {};

        this.totalRecords = total;
        this.adjustmentDetailList = (adjustmentDetailed ?? []).map((item) => ({
          ...item,
          itemTypeLabel: typeof item.itemType === 'number' ? AdjustmentDetailedType[item.itemType] : '',
        }));
        this.onDataListChange.emit({ adjustmentDetails: this.adjustmentDetailList });
      });
  }
}
