import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityListConfig, EntityPropertyConfigs, EntityPropertyDisplayResponse } from '../../services/models';
import { EntityListViewConfigurationService } from '../../services';
import {
  getEntityCardSelectionRequest,
  getEntityCardSelectionRequestFailure,
  getEntityCardSelectionRequestSuccess,
  getEntityPropertyByCardSelectionFailure,
  getEntityPropertyByCardSelectionRequest,
  getEntityPropertyByCardSelectionSuccess,
  getEntityPropertyList,
  getEntityPropertyListFailure,
  getEntityPropertyListSuccess,
  getEntityPropertyOfParticipantsRequest,
  getEntityPropertyOfParticipantsRequestFailure,
  getEntityPropertyOfParticipantsRequestSuccess,
  getParticipantListConfiguration,
  getParticipantListConfigurationFailure,
  getParticipantListConfigurationSuccess,
  setParticipantListConfiguration,
  setParticipantListConfigurationFailure,
  setParticipantListConfigurationSuccess,
} from '../actions';
import { GetCardSelectionResponse, PropertyDisplayResponse } from '@ptg-member/types/models';

@Injectable()
export class EntityListViewConfigurationEffect {
  constructor(
    private actions$: Actions,
    private entityListViewConfigurationService: EntityListViewConfigurationService,
  ) {}

  getEntityPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertyList),
      switchMap(({ entityId, screenId, query }) => {
        return this.entityListViewConfigurationService.getEntityPropertyList(entityId, screenId, query).pipe(
          map((res: EntityPropertyConfigs) => {
            return getEntityPropertyListSuccess({
              propertyConfigs: res.properties,
              fixPersonProperties: res.fixPersonProperties,
            });
          }),
          catchError((err) => {
            return of(
              getEntityPropertyListFailure({
                errorMsg: err.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  getParticipantListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getParticipantListConfiguration),
      switchMap(({ entityId }) => {
        return this.entityListViewConfigurationService.getParticipantListConfiguration(entityId).pipe(
          map((res: EntityListConfig) => {
            return getParticipantListConfigurationSuccess({ propertyDisplayConfigurations: res.configurations });
          }),
          catchError((err) => {
            return of(getParticipantListConfigurationFailure({ errorMsg: err.message }));
          }),
        );
      }),
    ),
  );

  setParticipantListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParticipantListConfiguration),
      switchMap(({ entityId, body }) => {
        return this.entityListViewConfigurationService.setParticipantListConfiguration(entityId, body).pipe(
          map(() => {
            return setParticipantListConfigurationSuccess();
          }),
          catchError((err) => {
            return of(setParticipantListConfigurationFailure({ errorMsg: err.message }));
          }),
        );
      }),
    ),
  );

  getEntityCardSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityCardSelectionRequest),
      switchMap(({query}) => {
        return this.entityListViewConfigurationService
          .getEntityCardSelection(query)
          .pipe(
            map((response: GetCardSelectionResponse) => {
              return getEntityCardSelectionRequestSuccess({ response });
            }),
            catchError((error) => {
              return of(getEntityCardSelectionRequestFailure({ error }));
            })
          );
      })
    )
  );

  getEntityPropertyByCardSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertyByCardSelectionRequest),
      switchMap(({ cardId }) => {
        return this.entityListViewConfigurationService
          .getEntityPropertyByCardSelection(cardId)
          .pipe(
            map((response: any) => {
              return getEntityPropertyByCardSelectionSuccess({ response });
            }),
            catchError((error) => {
              return of(getEntityPropertyByCardSelectionFailure({ error }));
            })
          );
      })
    )
  );

  getEntityPropertyOfParticipants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertyOfParticipantsRequest),
      switchMap(({ entityId, query }) => {
        return this.entityListViewConfigurationService
          .getEntityPropertyOfParticipants(entityId, query)
          .pipe(
            map((propertyOfParticipants: EntityPropertyDisplayResponse) => {
              return getEntityPropertyOfParticipantsRequestSuccess({ propertyOfParticipants });
            }),
            catchError((error) => {
              return of(getEntityPropertyOfParticipantsRequestFailure({ error }));
            })
          );
      })
    )
  );
}
