<mat-form-field class="text-area  {{ class }}" appearance="fill" floatLabel="always">
  <mat-label 
    *ngIf="hasLabel"
    [ngClass]="[
      (controlField?.hasValidator(Validators.required) || shouldShowRequiredIndicator)
        ? REQUIRED_INDICATOR
        : '',
    ]"
  >{{ placeholder }}</mat-label>
  <textarea
    matInput
    [formControl]="controlField"
    [placeholder]="hasLabel ? '' : placeholder"
    (blur)="trimValue()"
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="minRows"
    [cdkAutosizeMaxRows]="maxRows ? maxRows : 10"
    [maxlength]="maxLength ? maxLength : 'none'"
    (input)="onValueChange($event)"
  ></textarea>
  <mat-error *ngIf="controlField?.errors?.required">{{ errorRequire }}</mat-error>
  <mat-error *ngIf="controlField?.errors?.maxlength">{{ errorMaxLength }}</mat-error>
  <mat-error *ngIf="controlField?.errors?.inValidAsync">{{
    errorAsync ? errorAsync : controlField.getError('inValidAsync')
  }}</mat-error>
  <mat-error *ngIf="controlField?.errors?.pattern && !controlField?.errors?.maxlength">{{ errorPattern }}</mat-error>
</mat-form-field>
