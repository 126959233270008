import { AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ptg-custom-truncate-tooltip',
  templateUrl: './custom-truncate-tooltip.component.html',
  styleUrls: ['./custom-truncate-tooltip.component.scss'],
})
export class CustomTruncateTooltipComponent implements OnInit, AfterViewInit {
  @Input() tooltipTemplate: TemplateRef<any> | null;
  @Input() tooltipText: string = '';

  @ViewChild('parentElem') parentElem!: ElementRef;
  @ViewChild('childElem') childElem!: ElementRef;
  @ViewChild('tooltipElem') tooltipElem!: ElementRef;

  isShowTruncate: boolean = false;

  constructor() {
    this.tooltipTemplate = null;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const parentElem = this.parentElem.nativeElement;
    const childElem = this.childElem.nativeElement;
    const tooltipElem = this.tooltipElem.nativeElement;
    const parentElemWidth = parentElem.offsetWidth;
    const childElementWidth = childElem.offsetWidth;

    if (childElementWidth > parentElemWidth) {
      parentElem.classList.add('truncate');
      childElem.remove();
    } else {
      parentElem.classList.remove('truncate');
      tooltipElem.remove();
    }
  }
}
