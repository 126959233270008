import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

import { CreateReversalAdjustmentParams, CreateReversalAdjustmentResponse } from './models';

@Injectable()
export class CreateReversalAdjustmentService {
  constructor(private readonly httpClient: HttpClient) {}

  createReversalAdjustment(params: CreateReversalAdjustmentParams): Observable<CreateReversalAdjustmentResponse> {
    const { adjustmentId = '' } = params;
    const context = new HttpContext().set(SHOW_LOADING, true);

    return this.httpClient
      .post<string>(`${environment.apiUrl}/new-payroll/adjustment-transaction/${adjustmentId}/reversal-adjustment`, {
        context,
      })
      .pipe(map((response) => ({ reversalAdjustmentId: response ?? '' })));
  }
}
