import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
    public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'EEE', locale ?? 'en-US'); // use short week days
    }

    public monthViewDayNumber({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'd', locale ?? 'en-US');
    }

    public monthViewDayNumberInMonth({ date, locale }: DateFormatterParams): string {
        if (date.getDate() === 1) {
            return formatDate(date, 'd MMM', locale ?? 'en-US');
        }
        return formatDate(date, 'd', locale ?? 'en-US');
    }
}