import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromReducer from '@ptg-reducers';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import {
  arrowDropDown,
  pensionViewWeek,
  removeAccountIcon,
  switchAccountIcon,
} from '@ptg-shared/constance/listIcons.const';
import { BaseComponent } from '@ptg-shared/components';
import { Align, Column, GridComponent, Row } from '@ptg-shared/controls/grid';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { FundType } from '@ptg-shared/types/enums';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { getInitiationPropertiesSelector, getModuleConnectionsSelector } from '@ptg-entity-management/store/selectors';
import {
  clearGetInitiationPropertiesStateAction,
  clearModuleConnectionsStateAction,
  getInitiationPropertiesAction,
  getModuleConnectionsAction,
} from '@ptg-entity-management/store/actions';

import { ColumnSetListComponent } from '../../components/column-set-list/column-set-list.component';
import { AddColumnSetComponent } from '../../components/add-column-set/add-column-set.component';
import { PropertyTypeEntity } from '../../constance/metadataPropertyType.const';
import { ManageMemberFilterComponent } from '../../components/manage-member-filter/manage-member-filter.component';
import {
  FIXED_PROPERTIES,
  FilterPropertyType,
  PARTICIPANT_ENTITY_CONNECTION_NAME,
  Operator,
  FIXED_KEY_ENTITY,
} from '../../constance/member-list.const';
import {
  ColumnConfig,
  ColumnDetail,
  ExportFileRequest,
  FilterByPropertyConfigEntity,
  FilterInfo,
  MemberFilter,
  MemberList,
  MemberListQuery,
} from '../../types/models';
import { MemberListActions } from '../../store/actions';
import * as MemberNavigationActions from '../../store/actions/member-navigation';
import { SaveMemberFilterComponent } from '../../components/save-member-filter/save-member-filter.component';
import * as fromMember from '../../store/reducers';
import { State } from '../../store/reducers/member-list.reducer';
import { MemberDetailService } from '../../services/member-detail.service';
import { MemberListService } from '../../services/member-list.service';
import { getColumConfigEntity } from '../../helper';
import { SCREEN_ID } from '../../constance/lookupTable.const';
import { MemberState } from '../../store/reducers';
import { AddMemberFiltersComponent } from '@ptg-member/components/add-member-filters/add-member-filters.component';
import { UpsertMemberComponent } from '@ptg-member/components/upsert-member/upsert-member.component';
import { VERTICAL_LINE_SEPARATOR } from '@ptg-shared/constance/common.const';
import { CalculationState } from '@ptg-member/features/calculation/store';

@Component({
  selector: 'ptg-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
})
export class MembersListComponent extends BaseComponent {
  @ViewChild('gridMemberList') gridMemberList!: GridComponent<any>;
  cardId: string = '';
  columns: Column[] = [];
  errorMsg: string = '';
  membersData: (any & Row)[] = [];
  groupButtonFn = {
    lockMember: ((member: any) => {
      this.memberKey = member.Fixed_memberid;
      if (member._isLocked) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: 'Are you sure you want to unlock this account?',
            title: 'Unlock Participant',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.memberStore.dispatch(MemberListActions.unlockMember({ id: member._memberId }));
          }
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: 'Are you sure you want to lock this account?',
            title: 'Lock Participant',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.memberStore.dispatch(MemberListActions.lockMember({ id: member._memberId }));
          }
        });
      }
    }).bind(this),
    removeMember: ((member: any) => {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'THIS IS A DESTRUCTIVE ACTION',
          type: ConfirmType.Destruct,
          text: `Removing this Participant will remove all traces of them, including all data and files, from the system.<br> Are you sure you want to proceed? This action is not reversible.`,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.memberKey = member.Fixed_memberid;
          this.memberStore.dispatch(
            MemberListActions.removeMember({
              id: member._memberId,
              memberKey: this.memberKey,
            }),
          );
        }
      });
    }).bind(this),
  };
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  isLoading: boolean = true;
  isConnectionLoading: boolean = true;
  memberListStore$: Observable<State>;
  currentFund: any = {};
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '',
    },
  ];

  entityId: string = '';
  entityReferenceLinkedId: string = '';
  settings: Breadcrumb[] = [
    {
      name: 'Status Events',
      url: '/member/status',
    },
    {
      name: 'Lookup Tables',
      url: '/member/lookup-table',
    },
    {
      name: 'Participant List',
      url: '/member/member-list-configuration',
    },
    {
      name: 'Participant Search',
      url: '/member/participant-search-configuration',
    },
    {
      name: 'Tiers',
      url: '/member/member-list-tier',
    },
    {
      name: 'Cards',
      url: '/member/card-list',
      classButton: 'divider-top',
    },
    {
      name: 'Views',
      url: '/member/view',
    },
    {
      name: 'Headers',
      url: '/member/header',
    },
    {
      name: 'Navigation',
      url: '/member/participant-navigation-configuration',
    },
    {
      name: 'Profiles',
      url: '/member/profile-configuration',
    },
  ];
  memberKey: string = '';

  memberData: {
    demographicMetadata: any;
    memberList: MemberList | undefined;
  } = {
    demographicMetadata: null,
    memberList: undefined,
  };
  query!: MemberListQuery;
  FILTER_ACTION = {
    ADD: 'add',
    LOAD: 'load',
  };
  filterOptions: { iconName: string; label: string; value: string }[] = [
    {
      iconName: 'add_circle',
      label: 'New Filter',
      value: this.FILTER_ACTION.ADD,
    },
    {
      iconName: 'upload',
      label: 'Load Filter',
      value: this.FILTER_ACTION.LOAD,
    },
  ];
  selectedFilter: string = this.filterOptions[0].value;
  currentFilters: MemberFilter[] = [];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: '',
      icon: 'add',
      classInput: 'add-button',
    },
    {
      buttonName: 'Manage Column Set',
      icon: 'list_alt',
      classInput: 'add-button',
    },
    {
      buttonName: 'Export',
      icon: 'file_download',
      classInput: 'add-button',
      isDisabled: false,
    },
  ];
  metadata: any[] = [];
  isCheckingConditionAddNew = false;
  isError = false;
  routeState: any;
  isGetPageSize = true;
  isApplyMunicipalityFilter = false;
  listColumnConfig: ColumnDetail[] = [];
  selectColumn: ColumnDetail = {} as ColumnDetail;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isSelectedMember = false;

  constructor(
    private memberStore: Store<MemberState>,
    private router: Router,
    public dialog: MatDialog,
    private store: Store<fromReducer.State>,
    private calculationStore: Store<CalculationState>,
    private memberDetailService: MemberDetailService,
    private memberListService: MemberListService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public authService: Auth0Service,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
  ) {
    super();
    this.memberListStore$ = memberStore.pipe(select(fromMember.selectMemberListState), takeUntil(this.unsubscribe$));

    iconRegistry.addSvgIconLiteral('remove-account', sanitizer.bypassSecurityTrustHtml(removeAccountIcon));
    iconRegistry.addSvgIconLiteral('group-account', sanitizer.bypassSecurityTrustHtml(switchAccountIcon));
    iconRegistry.addSvgIconLiteral('arrowDropDown', sanitizer.bypassSecurityTrustHtml(arrowDropDown));
    iconRegistry.addSvgIconLiteral('view-week', sanitizer.bypassSecurityTrustHtml(pensionViewWeek));
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.routeState = this.router.getCurrentNavigation()?.extras.state;
      this.pageNumber = this.routeState?.beforeState?.pageIndex ?? FIRST_PAGE;
      this.pageSize = this.routeState?.beforeState?.totalPerPage ?? this.pageSize;
      this.isGetPageSize = false;
      this.store.dispatch(LayoutActions.setHistory({ history: { urlBeforeSearch: '' } }));
      this.memberStore.dispatch(MemberNavigationActions.clearMemberNavigationState());
    }
  }

  checkPermissionMenuSettings() {
    this.settings = this.settings.filter((setting) => setting.name !== 'Tiers');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.getReportCategorySidebar());
    this.memberStore.dispatch(getModuleConnectionsAction());

    //TODO remove after implement employer portal
    if (!this.authService.isAdminPortal$.value) {
      return;
    }

    combineLatest([
      this.route.queryParams,
      this.store.pipe(select(fromReducer.selectCurrentFundState)),
      this.memberStore.pipe(select(getModuleConnectionsSelector)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund, entities]) => {
        if (this.currentFund.id !== currentFund.id) {
          setTimeout(() => {
            if (this.isGetPageSize) {
              this.pageSize = currentFund?.defaultPageSize ?? 50;
              this.pageSize =
                Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageSize')) === 0
                  ? this.pageSize
                  : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageSize'));
            }
            this.setMunicipalityParticipantFilter(params?.municipalityId, params?.status);
            // Note: Switch between BVFF/ChicagoParks fund and other funds
          }, 100);
        }
        if (currentFund.fundType === FundType.BVFF) {
          this.checkPermissionMenuSettings();
        }
        this.currentFund = currentFund;

        if (entities?.payload) {
          const connection = (entities.payload.items || []).find(
            (x) => x.moduleKey === PARTICIPANT_ENTITY_CONNECTION_NAME,
          );
          if (connection) {
            this.entityId = connection?.entityId || '';
            this.functionButtons[0] = {
              buttonName: `New ${connection.entityLabel}`,
              icon: 'add',
              classInput: 'add-button',
            };
            if (!entities.isLoading && entities.success) {
              this.getData(true);
            }
          }
          if (connection && !this.settings.some((item) => item.url?.includes(connection.entityId))) {
            this.settings.forEach((item) => {
              if (item.name === 'Participant List') {
                item.url = `/entity-management/entity-list-view-configuration/${connection.entityId}`;
              }
              if (item.name === 'Participant Search') {
                item.url = `/member/participant-search-configuration/${connection.entityId}`;
              }
            });
          }

          this.isConnectionLoading = entities.isLoading;
        }

        this.memberStore.dispatch(clearModuleConnectionsStateAction());
      });

    this.memberListStore$.subscribe((el) => {
      this.isLoading = el.isLoading || el.isExporting || this.isConnectionLoading;
      if (el.isError) {
        this.isError = true;
      }

      this.memberData.memberList = deepClone(el.memberList);
      this.functionButtons[2].isDisabled = el.memberList?.message == 'No Columns Configured' || el.isExporting;

      this.lengthPg = el.memberList.total;
      this.mapMemberMetadata();

      if (el.removeState === STATE.SUCCESS) {
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
        showBanner.call(this, BannerType.Success, 'Participant', ACTION.REMOVE);
        this.closeIndividualTab();
        setTimeout(() => {
          if (el.memberList.members.length === 1 && this.pageNumber !== 0) {
            this.gridMemberList.paginator?.jumpToFirst();
          } else {
            this.changePagging({
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
          }
        });
      }

      if (el.removeState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'participant', ACTION.REMOVE);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.unLockMemberState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'participant', ACTION.UNLOCK);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.unLockMemberState === STATE.SUCCESS) {
        showBanner.call(this, BannerType.Success, 'Participant', ACTION.UNLOCK);
        if (this.currentFilters.filter((item) => item.propertyKey === FIXED_KEY_ENTITY.Locked).length > 0) {
          this.gridMemberList.paginator?.jumpToFirst();
        } else {
          this.getData(true);
        }
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.lockMemberState === STATE.SUCCESS) {
        if (this.currentFilters.filter((item) => item.propertyKey === FIXED_KEY_ENTITY.Locked).length > 0) {
          this.gridMemberList.paginator?.jumpToFirst();
        } else {
          this.getData(true);
        }
        showBanner.call(this, BannerType.Success, 'Participant', ACTION.LOCK);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.lockMemberState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'participant', ACTION.LOCK);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.createMemberFilterState === STATE.SUCCESS) {
        showBanner.call(this, BannerType.Success, 'Filter', ACTION.SAVE);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.createMemberFilterState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'Filter', ACTION.SAVE);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.updateFilterState === STATE.SUCCESS) {
        showBanner.call(this, BannerType.Success, 'Filter', ACTION.EDIT);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.updateFilterState === STATE.FAIL) {
        showBanner.call(this, BannerType.Fail, 'Filter', ACTION.EDIT);
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
      }

      if (el.updateColumnSetState) {
        if (el.idColumnSet) {
          this.selectColumn.columnSetName = this.selectColumn?.isDragDrop
            ? `${this.selectColumn.columnSetName} (Draft)`
            : this.selectColumn.columnSetName;
          sessionStorage.setItem(this.currentFund.key, JSON.stringify(el.idColumnSet));
        }
        if (el.selectedColumnSetState) {
          sessionStorage.setItem(this.currentFund.key, JSON.stringify({ id: el.selectedColumnSetState }));
        }
        showBanner.call(
          this,
          el.updateColumnSetState || '',
          `Column Set ${this.selectColumn.columnSetName}`,
          el?.actionColumnSetState ? ACTION.EDIT : this.selectColumn?.isDragDrop ? ACTION.APPLY : ACTION.ADD,
        );
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
        this.getListColumnSetConfiguration();
        this.getData();
      }
      if (el.reorderColumnSetState) {
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
        this.getListColumnSetConfiguration();
      }
      if (el?.removeColumnSetStatusState && el?.removeColumnSetState) {
        const findColumnSet = this.listColumnConfig.find((item: ColumnDetail) => item.id === el.removeColumnSetState);
        showBanner.call(
          this,
          el.removeColumnSetStatusState || '',
          `${findColumnSet?.columnSetName} is `,
          ACTION.REMOVE,
        );
        this.memberStore.dispatch(MemberListActions.removeMemberClear());
        if (findColumnSet && findColumnSet.columnSetName === this.selectColumn.columnSetName) {
          const checkColumnDefault = this.listColumnConfig.find((item) => !item.auditTrail);
          if (checkColumnDefault) {
            sessionStorage.setItem(this.currentFund.key, JSON.stringify({ id: checkColumnDefault.id }));
            this.selectColumn = checkColumnDefault;
            this.getData();
          }
        }
        this.listColumnConfig = this.listColumnConfig.filter(
          (item: ColumnDetail) => item.id !== el.removeColumnSetState,
        );
      }
    });

    this.memberStore.pipe(select(fromMember.selectFiltersState), takeUntil(this.unsubscribe$)).subscribe((filters) => {
      if (filters) {
        this.currentFilters = filters;
        this.clearMunicipalityParticipantFilter();
        if (this.currentFilters.length > 0) {
          this.getData();
        }
      }
    });

    this.getListsColumnConfig();

    // Get the list of initiation properties
    this.memberStore
      .pipe(
        select(getInitiationPropertiesSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload && state?.payload.includeEntityReference) {
          if (!state.payload.properties?.length) {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: 'Screen not yet configured. Please contact your administrator for support.',
                title: 'Warning',
                cancelButtonTitle: 'Close',
                hideConfirmButton: true,
              },
            });
          } else {
            const dialogRef = this.dialog.open(UpsertMemberComponent, {
              panelClass: 'dialog-full-screen',
              disableClose: true,
              autoFocus: false,
              data: state.payload,
            });

            dialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                this.getData();
              }
            });
          }
        }

        this.memberStore.dispatch(clearGetInitiationPropertiesStateAction());
      });

    this.getOverviewURL();
  }

  getOverviewURL() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu && state?.menu.length > 0 && this.isSelectedMember) {
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
          const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${state.memberId}${this.entityReferenceLinkedId ? `?entityReferenceLinkedId=${this.entityReferenceLinkedId}` : ''}`;

          this.isSelectedMember = false;
          this.entityReferenceLinkedId = '';
          this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
          this.store.dispatch(
            LayoutActions.selectTab({
              tab: DisplayedTabName.IndividualParticipant,
              url,
            }),
          );
        }
      });
  }

  mapMemberMetadata() {
    const fixed_locked_key = FIXED_KEY_ENTITY.Locked;
    if (this.isLoading) {
      return;
    }
    const checkLocked: any = this.memberData.memberList?.columns?.find(
      (x) => x.propertyName === fixed_locked_key && !x.isColumnRef,
    );
    const dataColumn = this.memberData.memberList?.columns;
    this.columns = this.getConfigColumns(dataColumn || []);
    if (this.memberData.memberList?.members) {
      const data = JSON.parse(JSON.stringify(this.memberData.memberList.members)) || [];
      this.membersData = data.map((member: any) => {
        member.disabledRow = member.NoProfile;
        member.Locked = !!(checkLocked && member[checkLocked.columnKey]);
        for (const property in member) {
          const checkLockedRef = this.memberData.memberList?.columns?.find(
            (x) => x.propertyName === fixed_locked_key && x.isColumnRef && x.columnKey === property,
          );
          if (checkLockedRef) {
            member[property] = member[property] ? 'lock' : '';
            member[`${property}_Locked_Color`] = '#cecece';
          }
          const checkRegister = this.memberData.memberList?.columns?.find(
            (x) => x.propertyName === PropertyTypeEntity.TYPE_REGISTER && x.columnKey === property,
          );
          if (checkRegister) {
            member[property] = member[property] ? 'account_circle' : '';
            member[`${property}_Register_Color`] = '#828282';
          }
        }
        return member;
      });
      return;
    }
    this.membersData = [];
  }

  getConfigColumns(columns: ColumnConfig[]) {
    this.columns = [];
    let fixed_locked_key = FIXED_KEY_ENTITY.Locked;
    let isAddLockColumn = false;
    if (!columns.length) {
      return [];
    }
    columns.forEach((column: any) => {
      if (column.type === PropertyTypeEntity.TYPE_SYSTEM) {
        if (column.propertyName === PropertyTypeEntity.TYPE_REGISTER) {
          column.type = PropertyTypeEntity.TYPE_REGISTER;
        }
        if ([PropertyTypeEntity.TYPE_STATUS_NAME, PropertyTypeEntity.TYPE_STATUS_EVENT].includes(column.options)) {
          column.type = PropertyTypeEntity.TYPE_STATUS;
        }
        if (column.options === PropertyTypeEntity.TYPE_STATUS_DATE) {
          column.type = PropertyTypeEntity.TYPE_DATE;
        }
        if (column.propertyName === FIXED_KEY_ENTITY.Locked && column.isColumnRef) {
          column.type = PropertyTypeEntity.TYPE_LOCKED_REFERENCE;
        }
      }
      if (column.type === PropertyTypeEntity.TYPE_BOOLEAN) {
        column.type = PropertyTypeEntity.TYPE_TEXT;
      }
      const columConfig = getColumConfigEntity(
        column.type === PropertyTypeEntity.TYPE_ADDRESS || column.type === PropertyTypeEntity.TYPE_PERSON_NAME
          ? PropertyTypeEntity.TYPE_LIST
          : column.type,
        column.configValue,
        column.columnKey,
      );
      if (column.propertyName == fixed_locked_key && !column.isColumnRef) {
        isAddLockColumn = true;
        this.columns.unshift({
          name: '_isLocked',
          width: '62px',
        });
      } else {
        this.columns.push({
          name: column.columnKey,
          header: {
            title: column.columnName,
          },
          truncate: true,
          sortable:
            column.type === PropertyTypeEntity.TYPE_REGISTER || column.configValue?.isNotSortable ? false : true,
          align: column.type === PropertyTypeEntity.TYPE_REGISTER ? Align.Center : undefined,
          type: columConfig.type,
          templateArgs: columConfig.templateArgs,
        });
      }
    });
    if (!isAddLockColumn) {
      this.columns.unshift({
        name: '_mock_padding',
        width: '62px',
      });
    }
    return this.columns;
  }

  getData(isInit: boolean = false) {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      (sortField = this.columns.find((column) => column.name === this.sortInfo.active)?.header?.title || ''),
        (sortType = this.sortInfo.direction === 'desc' ? 1 : 0);
    }
    this.query = {
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
      listFilterInfo: this.getMemberListFilterInfo(),
      screenId: SCREEN_ID.ParticipantList,
    };
    const columnSetDefault = JSON.parse(sessionStorage.getItem(this.currentFund?.key)!);
    if (columnSetDefault?.id) {
      this.query.ColumnSetId = columnSetDefault?.id;
    }

    if (this.entityId) {
      this.memberStore.dispatch(
        MemberListActions.getMemberListConfiguration({ query: this.query, entityId: this.entityId }),
      );
      this.getListColumnSetConfiguration();
    }

    if (isInit) {
      this.memberStore.dispatch(MemberNavigationActions.setQuery({ query: this.query }));
    }
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getData();
    this.closeIndividualTab();
  }

  onClickReworkOverviewEntity(row: any & Row): void {
    // TODO Refactor Entity OVerview
    const url = `/entity-management/entities/${row.entityId}/entity-data/${row.Id}`;
    this.router.navigateByUrl(url);
    this.memberStore.dispatch(MemberNavigationActions.clearMemberNavigationState());
    this.memberStore.dispatch(MemberNavigationActions.setQuery({ query: this.query }));
    this.memberStore.dispatch(
      MemberNavigationActions.setMembers({
        memberList: this.memberData.memberList as MemberList,
        pageSize: this.query.totalPerPage,
      }),
    );
  }

  onRowClick(row: any & Row) {
    // TODO cuongnh20 Remove later
    this.memberStore.dispatch(MemberNavigationActions.clearMemberNavigationState());
    this.memberStore.dispatch(MemberNavigationActions.setQuery({ query: this.query }));
    this.memberStore.dispatch(
      MemberNavigationActions.setMembers({
        memberList: this.memberData.memberList as MemberList,
        pageSize: this.query.totalPerPage,
      }),
    );
    this.isSelectedMember = true;
    this.entityReferenceLinkedId = row.entityId;
    this.memberDetailService.memberId = row.Id;
    this.store.dispatch(
      LayoutActions.profileNavigationItemRequest({ memberId: row.Id, entityReferenceLinkedId: row.entityId }),
    );
  }

  changePagging(event: PageEvent) {
    this.pageNumber = event.pageNumber;
    this.pageSize = event.pageSize;
    sessionStorage.setItem(this.currentFund.key + '-ptg-members-list-pageSize', this.pageSize.toString());
    this.getData();
  }

  addSection(section: any, addToTop: boolean = true, isAddNew: boolean = true): void {
    // open add section screen
  }

  closeIndividualTab() {
    this.memberStore.pipe(select(fromMember.selectMemberNavigationState), take(1)).subscribe((navigationState) => {
      if (navigationState.query?.pageIndex) {
        this.store.dispatch(
          LayoutActions.closeTab({
            tabName: DisplayedTabName.IndividualParticipant,
          }),
        );
      }
    });
  }

  onFilterSelect(value: string) {
    if (value === this.FILTER_ACTION.ADD) {
      this.openFilterDialog();
      this.selectedFilter = this.filterOptions[0].value;
      return;
    }
    this.openLoadFilterDialog();
    this.selectedFilter = this.filterOptions[1].value;
  }

  openFilterDialog(isEdit: boolean = false) {
    const dialogRef = this.dialog.open(AddMemberFiltersComponent, {
      panelClass: 'edit-popup',
      height: 'auto',
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      maxHeight: '90vh',
      maxWidth: '1177px',
      data: isEdit ? { currentFilters: this.currentFilters, entityId: this.entityId } : { entityId: this.entityId },
    });

    dialogRef.afterClosed().subscribe((filters) => {
      if (filters) {
        if (!isEdit) {
          this.memberListService.currentFilter = undefined;
        }
        this.currentFilters = filters;
        this.pageNumber = 1;
        this.memberStore.dispatch(MemberListActions.setMemberFilter({ filters }));
        this.closeIndividualTab();
      }
    });
  }

  getListFilterInfo(): FilterInfo[] {
    return deepClone(this.currentFilters).reduce((result: FilterInfo[], filter: MemberFilter) => {
      if (filter.propertyRefId || filter?.entityReferencePropertyId) {
        filter.id = filter?.id?.split(VERTICAL_LINE_SEPARATOR)[0];
      }
      if (filter.type === FilterPropertyType.Status) {
        result.push({
          id: filter.id,
          propertyName: filter.propertyName || '',
          propertyKey: filter.propertyKey ?? '',
          itemKey: filter.sectionKey || filter.itemKey || '',
          sectionName: filter.sectionKey,
          options: filter.options || '',
          type: filter.type,
          values: filter.valueName ? filter.values : filter?.statusNameId || [],
          operator: filter.operator,
          entityReferencePropertyId: filter?.entityReferencePropertyId
            ? filter.entityReferencePropertyId
            : filter.propertyRefId,
          entityId: filter.entityId,
          valueName: filter.valueName ? filter.valueName : filter.values,
          cardId: filter?.cardId ?? null,
        });
        if (filter.event?.operator) {
          result.push({
            id: filter.id,
            propertyName: filter.propertyName || '',
            propertyKey: filter.propertyKey ?? '',
            itemKey: filter.sectionKey || filter.itemKey || '',
            sectionName: filter.sectionKey,
            options: filter.event.options || '',
            type: filter.type,
            values: filter.event.valueName ? filter.event.values : filter.event.statusEventId || [],
            operator: filter.event.operator,
            entityReferencePropertyId: filter?.entityReferencePropertyId
              ? filter.entityReferencePropertyId
              : filter.propertyRefId,
            entityId: filter.entityId,
            valueName: filter.event.valueName ? filter.event.valueName : filter.event.values,
            cardId: filter?.cardId ?? null,
          });
        }
        if (filter.date?.operator) {
          result.push({
            id: filter.id,
            propertyName: filter.propertyName || '',
            propertyKey: filter.propertyKey ?? '',
            itemKey: filter.sectionKey || filter.itemKey || '',
            sectionName: filter.sectionKey,
            options: filter.date.options || '',
            type: filter.type,
            values: filter.date.values || [],
            operator: filter.date.operator,
            entityReferencePropertyId: filter?.entityReferencePropertyId
              ? filter.entityReferencePropertyId
              : filter.propertyRefId,
            entityId: filter.entityId,
            cardId: filter?.cardId ?? null,
          });
        }
      } else {
        result.push({
          id: filter.id,
          propertyName: filter.propertyName || '',
          propertyKey: filter.propertyKey ?? '',
          itemKey: filter.sectionKey || filter.itemKey || '',
          sectionName: filter.sectionKey,
          options: filter.options || '',
          type: filter.type,
          values: filter.values || [],
          operator: filter.operator,
          entityReferencePropertyId: filter?.entityReferencePropertyId
            ? filter.entityReferencePropertyId
            : filter.propertyRefId,
          entityId: filter.entityId,
          cardId: filter?.cardId ?? null,
        });
      }

      return result;
    }, []);
  }

  getMemberListFilterInfo(): FilterInfo[] {
    return deepClone(this.currentFilters).reduce((result: FilterInfo[], filter: MemberFilter) => {
      if (filter.propertyRefId || filter?.entityReferencePropertyId) {
        filter.id = filter?.id?.split(VERTICAL_LINE_SEPARATOR)[0];
      }
      if (filter.type === FilterPropertyType.Status) {
        result.push({
          id: filter.id,
          propertyName: filter.propertyName || '',
          propertyKey: filter.propertyKey ?? '',
          itemKey: filter.sectionKey || filter.itemKey || '',
          sectionName: filter.sectionKey,
          options: filter.options || '',
          type: filter.type,
          values: filter.valueName ? filter.values : filter?.statusNameId || [],
          operator: filter.operator,
          entityReferencePropertyId: filter?.entityReferencePropertyId
            ? filter.entityReferencePropertyId
            : filter.propertyRefId,
          entityId: filter.entityId,
          valueName: filter.valueName ? filter.valueName : filter.values,
        });
        if (filter.event?.operator) {
          result.push({
            id: filter.id,
            propertyName: filter.propertyName || '',
            propertyKey: filter.propertyKey ?? '',
            itemKey: filter.sectionKey || filter.itemKey || '',
            sectionName: filter.sectionKey,
            options: filter.event.options || '',
            type: filter.type,
            values: filter.event.valueName ? filter.event.values : filter.event.statusEventId || [],
            operator: filter.event.operator,
            entityReferencePropertyId: filter?.entityReferencePropertyId
              ? filter.entityReferencePropertyId
              : filter.propertyRefId,
            entityId: filter.entityId,
            valueName: filter.event.valueName ? filter.event.valueName : filter.event.values,
          });
        }
        if (filter.date?.operator) {
          result.push({
            id: filter.id,
            propertyName: filter.propertyName || '',
            propertyKey: filter.propertyKey ?? '',
            itemKey: filter.sectionKey || filter.itemKey || '',
            sectionName: filter.sectionKey,
            options: filter.date.options || '',
            type: filter.type,
            values: filter.date.values || [],
            operator: filter.date.operator,
            entityReferencePropertyId: filter?.entityReferencePropertyId
              ? filter.entityReferencePropertyId
              : filter.propertyRefId,
            entityId: filter.entityId,
          });
        }
      } else {
        result.push({
          id: filter.id,
          propertyName: filter.propertyName || '',
          propertyKey: filter.propertyKey ?? '',
          itemKey: filter.sectionKey || filter.itemKey || '',
          sectionName: filter.sectionKey,
          options: filter.options || '',
          type: filter.type,
          values: filter.values || [],
          operator: filter.operator,
          entityReferencePropertyId: filter?.entityReferencePropertyId
            ? filter.entityReferencePropertyId
            : filter.propertyRefId,
          entityId: filter.entityId,
        });
      }

      return result;
    }, []);
  }

  clearFilter() {
    this.currentFilters = [];
    this.memberStore.dispatch(MemberListActions.setMemberFilter({ filters: [] }));
    this.closeIndividualTab();
    this.getData(true);
    this.clearMunicipalityParticipantFilter();
  }

  saveFilter() {
    if (this.memberListService.currentFilter?.id) {
      let dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        data: {
          text: `Would you like to save your changes to <b>${this.memberListService.currentFilter.name}</b> filter or save as a new filter?<br> If you select to save, the changes will impact In Review and Scheduled notifications which use <b>${this.memberListService.currentFilter.name}</b> filter.`,
          type: ConfirmType.ConfirmSave,
          cancelButtonTitle: 'Cancel',
          title: 'Confirmation',
          hideConfirmButton: true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.isSaveAs) {
            this.saveNewFilter();
          } else {
            this.memberStore.dispatch(
              MemberListActions.updateFilterNameRequest({
                memberFilterName: {
                  id: this.memberListService.currentFilter?.id,
                  name: this.memberListService.currentFilter?.name as string,
                  listFilterInfo: this.getListFilterInfo(),
                },
              }),
            );
          }
        }
      });
    } else {
      this.saveNewFilter();
    }
  }

  saveNewFilter() {
    this.dialog.open(SaveMemberFilterComponent, {
      panelClass: 'save-filter-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: this.getListFilterInfo(),
    });
  }

  openLoadFilterDialog() {
    this.removeAria();
    const dialogRef = this.dialog.open(ManageMemberFilterComponent, {
      panelClass: 'edit-popup',
      height: 'auto',
      width: 'auto',
      minWidth: '950px',
      disableClose: true,
      autoFocus: false,
      minHeight: '185px',
      data: this.getListFilterInfo(),
    });

    dialogRef.afterClosed().subscribe((filters) => {
      if (filters) {
        this.memberListService.currentFilter = filters;
        this.currentFilters = this.getCurrentFilter(filters.listFilterInfo);
        this.memberStore.dispatch(MemberListActions.setMemberFilter({ filters: this.currentFilters }));
        this.closeIndividualTab();
        this.gridMemberList.paginator?.jumpToFirst();
      }
    });
  }
  removeAria() {
    setTimeout(() => {
      let inputWithAria = document.querySelectorAll('[aria-expanded="false"]');
      let inputRoleTag = document.querySelectorAll('[role="combobox"]');
      if (inputRoleTag.length > 0) {
        (function () {
          for (let i = 0; i < inputRoleTag.length; i++) {
            inputRoleTag[i].removeAttribute('role');
            // inputRoleTag[i].setAttribute('style', 'border: 2px solid blue;');
          }
        })();
      }
      if (inputWithAria.length > 0) {
        (function () {
          for (let i = 0; i < inputWithAria.length; i++) {
            inputWithAria[i].removeAttribute('aria-autocomplete');
            inputWithAria[i].removeAttribute('aria-expanded');
            inputWithAria[i].removeAttribute('aria-required');
            inputWithAria[i].removeAttribute('aria-labelledby');
          }
        })();
      }
    }, 10);
  }

  getCurrentFilter(filters: FilterInfo[]): MemberFilter[] {
    const statusIndex = filters.findIndex((filter) => filter.type === FilterPropertyType.Status);
    return (deepClone(filters) as FilterInfo[]).reduce(
      (result: MemberFilter[], filter, currentIndex: number, array) => {
        if (filter.type !== FilterPropertyType.Status) {
          result.push({
            ...filter,
            sectionKey: filter.itemKey,
          });
        } else {
          if (filter.options === 'Name') {
            result.push({
              ...filter,
              sectionKey: filter.itemKey,
              options: 'Name',
            });
          }
          if (filter.options === 'Event') {
            result[statusIndex].event = {
              options: 'Event',
              operator: filter.operator,
              values: filter.values,
              valueName: filter.valueName,
            };
          }
          if (filter.options === 'Date') {
            result[statusIndex].date = {
              options: 'Date',
              operator: filter.operator,
              values: filter.values,
            };
          }
        }
        return result;
      },
      [],
    );
  }

  emitFunction(event: FunctionButtonData): void {
    if (event.buttonName === this.functionButtons[0].buttonName) {
      this.memberStore.dispatch(
        getInitiationPropertiesAction({
          entityId: this.entityId,
          includeEntityReference: true,
          screenId: SCREEN_ID.ParticipantList,
        }),
      );
    } else if (event.buttonName === this.functionButtons[1].buttonName) {
      this.openColumnSetDialog();
    } else if (event.buttonName === this.functionButtons[2].buttonName) {
      this.onClickExport();
    }
  }

  filterSectionAndProperties(list: any[]) {
    let listSectionStatic = JSON.parse(JSON.stringify(list));
    // Filter Properties
    listSectionStatic.forEach((el: any) => {
      el.properties = el.properties.filter(
        (property: any) => stringToBoolean(property.config.required) && stringToBoolean(property.config.initiated),
      );
    });
    // Filter to remove Section don't have any property required & initiated
    listSectionStatic = listSectionStatic?.filter((el: any) => el.properties.length > 0);
    return listSectionStatic;
  }

  temporarilyDisabledExport() {
    this.functionButtons = [
      this.functionButtons[0],
      {
        ...this.functionButtons[2],
        isDisabled: true,
      },
    ];
    setTimeout(() => {
      this.functionButtons = [
        this.functionButtons[0],
        {
          ...this.functionButtons[2],
          isDisabled: false,
        },
      ];
    }, 1000);
  }

  setStatePageNumber() {
    sessionStorage.setItem(this.currentFund.key + '-ptg-members-list-pageNumber', this.pageNumber.toString());
  }

  getStatePageNumber() {
    this.pageNumber =
      Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageNumber')) === 0
        ? 1
        : Number(sessionStorage.getItem(this.currentFund.key + '-ptg-members-list-pageNumber'));
  }

  onClickExport() {
    let sortType = 0;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      (sortField = this.columns.find((column) => column.name === this.sortInfo.active)?.header?.title || ''),
        (sortType = this.sortInfo.direction === 'desc' ? 1 : 0);
    }
    const datePipe = new DatePipe('en-US');
    const request: ExportFileRequest = {
      fileName: `ParticipantList_${datePipe.transform(new Date(), 'MMddyy_HHmmss')}`,
      entityId: this.entityId,
      listFilter: this.getListFilterInfo(),
      pageIndex: this.pageNumber,
      totalPerPage: this.pageSize,
      sortField,
      sortType,
    };
    const columnSetDefault = JSON.parse(sessionStorage.getItem(this.currentFund?.key)!);

    if (columnSetDefault?.id) {
      request.ColumnSetId = columnSetDefault?.id;
    }
    this.store.dispatch(MemberListActions.exportMembersRequest({ request: request }));
    // this.memberListService.exportMemberList(request);
  }

  setMunicipalityParticipantFilter(municipalityId: string, status: string) {
    if (!municipalityId) {
      return;
    }
    this.getDepartmentEmployerDetail(municipalityId);
    this.isApplyMunicipalityFilter = true;
    this.memberStore
      .select(fromMember.selectEmployerDetailState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((employerDetail) => {
        const value = `${municipalityId}${status ? '|' + status : ''}`;
        this.currentFilters = [
          {
            operator: Operator.Contains,
            sectionKey: FIXED_PROPERTIES.MunicipalityId.SectionKey,
            propertyKey: FIXED_PROPERTIES.MunicipalityId.PropertyKey,
            propertyName: FIXED_PROPERTIES.MunicipalityId.PropertyName,
            selectedPropertyItem: FIXED_PROPERTIES.MunicipalityId.PropertyName,
            optionValues: [{ id: value, text: employerDetail?.name || '' }],
            type: FIXED_PROPERTIES.MunicipalityId.Type,
            values: [value],
          },
        ];
      });
  }

  clearMunicipalityParticipantFilter() {
    if (this.isApplyMunicipalityFilter) {
      this.isApplyMunicipalityFilter = false;
      this.router.navigateByUrl('/member');
    }
  }

  getDepartmentEmployerDetail(municipalityId: string) {
    this.memberStore.dispatch(
      MemberListActions.getEmployerDetailRequest({
        employerId: municipalityId,
        clientId: this.currentFund.id,
      }),
    );
  }

  getListColumnSetConfiguration() {
    const columnSetDefault = JSON.parse(sessionStorage.getItem(this.currentFund?.key)!);
    if (this.entityId) {
      this.memberStore.dispatch(
        MemberListActions.getListColumnSetConfigurationEntityRequest({
          IsDragDrop: columnSetDefault ? true : false,
          entityId: this.entityId,
        }),
      );
    }
  }

  openColumnSetDialog() {
    const coverColumnConfig = this.listColumnConfig.filter((item: ColumnDetail) => !item?.isDragDrop);

    this.getListColumnSetConfiguration();
    const dialogRef = this.dialog.open(ColumnSetListComponent, {
      panelClass: 'column-list-popup',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      maxHeight: '548px',
      maxWidth: '848px',
      data: {
        columnConfig: coverColumnConfig,
        entityId: this.entityId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.openAddColumnDialog(result);
      }
    });
  }

  openAddColumnDialog(data: any) {
    const cloneListColumnConfig = this.listColumnConfig
      .filter((item: ColumnDetail) => !item?.isDragDrop)
      .map((item: ColumnDetail) => {
        delete item.iconConfig;
        return item;
      });
    const dialogRef = this.dialog.open(AddColumnSetComponent, {
      panelClass: 'add-column-popup',
      width: '100%',
      disableClose: true,
      autoFocus: false,
      maxWidth: '1702px',
      data: {
        columSelected: data.columSelected,
        listColumnConfig: cloneListColumnConfig,
        entityId: this.entityId,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.selectColumn = result;
      }
    });
  }

  onSelectColumnConfig(event: string) {
    const checkColumn = this.listColumnConfig.find((item: ColumnDetail) => item.value === event);
    if (checkColumn) {
      sessionStorage.setItem(this.currentFund.key, JSON.stringify({ id: checkColumn.id }));
      this.selectColumn = checkColumn;
      this.selectColumn.listMetadataMemberListConfig = checkColumn.listConfigs;
    }
    this.getData();
  }

  ngAfterViewInit(): void {
    this.removeAria();
  }

  getListsColumnConfig() {
    this.memberStore
      .select(fromMember.selectListColumnConfigState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state?.payload?.listColumnConfig?.length) {
          return;
        }
        this.listColumnConfig = state?.payload?.listColumnConfig?.map((item: ColumnDetail) => {
          return {
            ...item,
            value: item.columnSetName,
            valueDescription: item.auditTrail
              ? `${item.auditTrail} ${this.datePipe.transform(
                  new Date(item.dateUpdated + ' UTC'),
                  'MM/dd/yyyy hh:mm a',
                )}`
              : '',
            displayValue: item.columnSetName,
            iconConfig: {
              icon: 'view-week',
              isSvg: true,
              iconFirst: true,
            },
          };
        });
        const columnSetDefault = JSON.parse(sessionStorage.getItem(this.currentFund?.key)!);
        if (columnSetDefault) {
          this.selectColumn = this.listColumnConfig.find((item: ColumnDetail) => item.id === columnSetDefault?.id)!;
        } else {
          const checkColumnDefault = this.listColumnConfig.find((item) => !item.auditTrail);
          if (checkColumnDefault) {
            this.selectColumn = checkColumnDefault;
            sessionStorage.setItem(this.currentFund.key, JSON.stringify({ id: checkColumnDefault?.id }));
          }
        }
      });
  }
}
