<div class="edit-exception">
  <div class="flex flex-col">
    <div class="wrap-btn mb-6">
      <button
        mat-flat-button
        type="submit"
        class="submit-button"
        [disabled]="!isFormDirty && !descriptionControl.dirty"
        (click)="onSubmit()"
      >
        Save
      </button>
      <button mat-stroked-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>

    <!-- [BenefitEffectiveBeforeServiceTerminated, BenefitEffectiveMoreThan6MonthsFuture, MemberDeceasedBeforeBenefitEffective, DateValueValidation] -->
    <div class="mb-6" *ngIf="isShowEditingValidationToggler">
      <ptg-toggle-button label="Editing Validation" [controlField]="isEditingValidationControl"></ptg-toggle-button>
    </div>

    <div class="input-area__wrapper">
      <ptg-input-area
        placeholder="Description"
        [controlField]="descriptionControl"
        [maxLength]="255"
        errorMaxLength="Exceed the 255 character limit."
      >
      </ptg-input-area>
    </div>

    <div class="flex flex-col gap-6">
      <!-- [SpouseNotFound, MultipleSpousesFound, SpouseMissingDOB, EffectiveJointSurvivorFactorNotFound, SpouseIsDeceased, DifferentSpouseInRetirement, DependentChildMissingDOB, SurvivorNotFound] -->
      <div *ngIf="isShowEntityListDropdown" class="selection__wrapper flex flex-col gap-4 mb-6">
        <div class="k-font-weight-bold">Configure Property in Exception</div>
        <div class="flex flex-wrap gap-4">
          <ptg-select
            placeholder="List"
            [listData]="entityList"
            [controlField]="entityControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isShowEntityListDropdown"
            customError="entityIsUneditable"
            (changeOptionValue)="onChangeList()"
          ></ptg-select>
          <ptg-select
            placeholder="Property"
            [listData]="propertyList"
            [controlField]="propertyControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isShowEntityListDropdown"
            customError="entityIsUneditable"
            (changeOptionValue)="onChangeProperty()"
          ></ptg-select>
          <ptg-select
            [placeholder]="isSurvivorNotFound ? 'Value for Spouse' : 'Value'"
            [listData]="entityValueList"
            [controlField]="additionalEntityValueControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isShowEntityListDropdown"
            customError="entityIsUneditable"
            (changeOptionValue)="onChangeEntityValue()"
          ></ptg-select>
          <ptg-select
            *ngIf="isSurvivorNotFound"
            placeholder="Value for Child"
            [listData]="entityValueList"
            [controlField]="additionalEntityValueForChildControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isSurvivorNotFound"
            (changeOptionValue)="onChangeEntityValue()"
          ></ptg-select>
        </div>
      </div>

      <div *ngIf="benefitOptionList.length" class="selection__wrapper flex flex-col gap-4">
        <div class="k-font-weight-bold">Choose Benefit Option to apply exception</div>
        <div class="flex flex-wrap gap-4">
          <ptg-select
            placeholder="Benefit Option"
            [listData]="benefitOptionList"
            [controlField]="benefitOptionControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            (changeControlValue)="onChangeBenefitOption()"
          >
          </ptg-select>

          <!-- [MemberMinAgeNotMet, MemberMinServiceNotMet, MemberMinPensionPaymentsNotMet] -->
          <ptg-textbox
            *ngIf="isShowAdditionalBenefitValueTextBox"
            [placeholder]="additionalBenefitValueObj?.placeholder ?? ''"
            [controlField]="additionalBenefitValueControl"
            [hasLabel]="true"
            [isRequired]="isShowAdditionalBenefitValueTextBox"
            [min]="rangeValue.min"
            [max]="rangeValue.max"
            inputType="Number"
            [maxLength]="additionalBenefitValueTextboxMaxLength"
            [isPositive]="true"
            [isDecimal]="false"
            [minErrorMessage]="additionalBenefitValueTextboxMinErrorMsg"
          >
          </ptg-textbox>

          <ptg-select
            *ngIf="isShowBenefitBeginDateDropdown"
            placeholder="Benefit Begin Date"
            [listData]="outputMappingList"
            [controlField]="outputMappingControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isShowBenefitBeginDateDropdown"
          >
          </ptg-select>

          <!-- [DateValueValidation] -->
          <ng-container *ngIf="isDateValueValidation">
            <ptg-select
              placeholder="Validation Date"
              [listData]="outputMappingList"
              [controlField]="validationDateControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [isRequired]="isDateValueValidation"
              [isMultipleLineOption]="true"
            >
            </ptg-select>
            <ptg-select
              placeholder="Validation Interval"
              [listData]="validationIntervalList"
              [controlField]="validationIntervalControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [isRequired]="isDateValueValidation"
            >
            </ptg-select>
            <ptg-select
              placeholder="Comparison Date"
              [listData]="comparisonDateList"
              [controlField]="comparisonDateControl"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              [isRequired]="isDateValueValidation"
              [isMultipleLineOption]="true"
            >
            </ptg-select>
          </ng-container>

          <button
            mat-icon-button
            (click)="onAddNewChip()"
            [disabled]="
              !benefitOptionControl.value ||
              additionalBenefitValueControl.invalid ||
              outputMappingControl.invalid ||
              validationDateControl.invalid ||
              validationIntervalControl.invalid ||
              comparisonDateControl.invalid
            "
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>

      <mat-chip-list *ngIf="benefitOptionChipListControl?.length">
        <mat-chip *ngFor="let item of benefitOptionChipListControl.controls; index as i" (removed)="onRemoveChip(i)">
          <span class="truncate">{{ item.value?.benefitName }}</span>
          <span class="additional-info ml-2 pl-2" *ngIf="item.value?.additionalInfos?.length">
            <ng-container *ngIf="isDateValueValidation; else defaultTemplate">
              <span class="chip__txt--primary">{{ item.value?.additionalInfos[0]?.displayValue }}</span>
              <span class="pl-2">{{ item.value?.additionalInfos[1]?.name }}</span>
              <span class="chip__txt--primary pl-2">{{ item.value?.additionalInfos[2]?.name }}</span>
              <span class="pl-2">{{ '(' + item.value?.additionalInfos[2]?.displaySubValue + ')' }}</span>
            </ng-container>

            <ng-template #defaultTemplate>
              <span>{{ item.value?.additionalInfos[0]?.name }}:&nbsp;</span>
              <span class="chip__txt--primary">
                <span class="mr-1">{{
                  !!additionalBenefitValueObj
                    ? item.value?.additionalInfos[0]?.value
                    : item.value?.additionalInfos[0]?.displayValue || item.value?.additionalInfos[0]?.value
                }}</span>
                <span *ngIf="item.value?.additionalInfos[0]?.unit">{{ item.value?.additionalInfos[0]?.unit }}(s)</span>
              </span>
            </ng-template>
          </span>

          <button matChipRemove>
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
