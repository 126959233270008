import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING, SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { SortType } from '@ptg-shared/constance';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CalculationBenefitHistoryStatus, CalculationType } from '../types/enums';

import {
  ApproveCalculationBenefitRequest,
  GetDisabilityBenefitDetailRequest,
  GetDisabilityBenefitDetailResponse,
  GetDisabilityCalculationDetailResponse,
  InitiateDisabilityBenefitRequest,
  ValidateDisabilityBenefitRequest,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class DisabilityBenefitService {
  constructor(private httpClient: HttpClient) {}

  getDisabilityBenefitDetail(
    request: GetDisabilityBenefitDetailRequest,
  ): Observable<GetDisabilityBenefitDetailResponse> {
    const calculationType = request.calculationType;
    const calculationBenefitId = request.calculationBenefitId;
    const memberId = request.memberId;
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetDisabilityBenefitDetailResponse>(
      `${environment.apiUrl}/calculations/disability-benefit-details/${calculationType}/${calculationBenefitId}/${memberId}`,
      { params },
    );
  }

  computeDisabilityBenefit(memberId: string, calculationBenefitId: string, calculationType: CalculationType) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    context.set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/calculation/${calculationType}/${calculationBenefitId}/computation`,
      { context },
    );
  }

  completeDisabilityBenefit(memberId: string, disabilityBenefitId: string, calculationType: CalculationType) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${disabilityBenefitId}/complete`,
      {},
      { context },
    );
  }

  approveDisabilityBenefit(
    memberId: string,
    disabilityBenefitId: string,
    calculationType: CalculationType,
    body?: ApproveCalculationBenefitRequest,
  ) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${disabilityBenefitId}/approve`,
      { ...body },
      { context },
    );
  }

  validateDisabilityBenefit(request: ValidateDisabilityBenefitRequest) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    let params = new HttpParams();
    if (request?.accidentId) {
      params = params.append('accidentId', request.accidentId);
    }
    if (request?.beginDate) {
      params = params.append('beginDate', request.beginDate);
    }
    if (request?.endDate) {
      params = params.append('endDate', request.endDate);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${request.memberId}/benefit-processing/calculation-benefit-histories/${request.calculationType}/${request.calculationBenefitId}/check-condition-to-process/${request.processToStatus}`,
      { params, context },
    );
  }

  initiateDisabilityBenefit(request: InitiateDisabilityBenefitRequest, memberId: string) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-disability-benefit/initialization`,
      request,
      { context },
    );
  }

  editDisabilityBenefit(
    request: InitiateDisabilityBenefitRequest,
    calculationType: CalculationType,
    disabilityBenefitId: string,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/disability-benefit-details/${calculationType}/${disabilityBenefitId}`,
      request,
    );
  }

  validateDataCompute(memberId: string, calculationBenefitId: string, calculationType: CalculationType) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${calculationType}/${calculationBenefitId}/validation-before-computing`,
      { context },
    );
  }

  getTotalDaysUsed(memberId: string, calculationType: CalculationType, calculationBenefitId?: string) {
    let params = new HttpParams();
    if (calculationBenefitId) {
      params = params.append('calculationBenefitId', calculationBenefitId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-disability-benefit/${calculationType}/days`,
      { params },
    );
  }

  validateRemoveCalculation(memberId: string, calculationRecordId: string) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${calculationRecordId}/validation-before-remove`,
      { context },
    );
  }

  removeDisabilityCalculation(memberId: string, calculationRecordId: string): Observable<boolean> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.delete<boolean>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/calculation-record/${calculationRecordId}`,
      { context },
    );
  }

  getDisabilityCalculationDetail(
    calculationBenefitId: string,
    memberId: string,
    calculationType: CalculationType,
    calculationRecordId: string,
  ): Observable<GetDisabilityCalculationDetailResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<GetDisabilityCalculationDetailResponse>(
      `${environment.apiUrl}/calculations/${memberId}/${calculationType}/calculation-detail/${calculationBenefitId}/${calculationRecordId}`,
      { context },
    );
  }

  //edit calculation detail
  editCalculationDetail(
    request: InitiateDisabilityBenefitRequest,
    calculationType: CalculationType,
    calculationBenefitId: string,
    calculationRecordId: string,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/disability-benefit-details/${calculationType}/${calculationBenefitId}/${calculationRecordId}`,
      request,
    );
  }
}
