import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { ACTION, CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { ACTION_COLUMN, Align, Column, GridComponent, Row, getValidatorsFromColumns } from '@ptg-shared/controls/grid';

import { HeaderService } from '@ptg-member/services/header.service';
import { VERTICAL_LINE_SEPARATOR } from '@ptg-shared/constance/common.const';
import { PayeeDetailState,
         clearPaymentHeaderConfigurationState,
         getHeaderDetailAction,
         selectPaymentHeaderConfigurationDetail,
         updatePaymentHeader,
         updatePaymentHeaderAction
} from '../../store';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { AllProperties, PaymentHeader, PaymentHeaderProperties, Properties, UpdateHeaderDetailRequest } from '../../types/models';
import { PaymentInstructionDetailComponentService } from '../payment-instruction-detail/payment-instruction-detail.component.service';

@Component({
  selector: 'ptg-payment-header-configuration-detail',
  templateUrl: './payment-header-configuration-detail.component.html',
  styleUrls: ['./payment-header-configuration-detail.component.scss'],
  providers:[PaymentInstructionDetailComponentService]
})
export class PaymentHeaderConfigurationDetailComponent extends BaseComponent {

  readonly ACTION_COLUMN = ACTION_COLUMN;

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Payment Header Configuration',
      url: '/member',
    },
    {
      name: '',
    },
  ];

  configuredPropertyColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `Label is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(250),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} characters limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `Label already exists.`,
        },
      },
      controlArgs: {
        placeholder: 'Label',
        maxLength: 250,
      }
    },
    {
      name: ACTION_COLUMN,
      align: Align.Left,
      width: '50px',
    },
  ];

  headerDetailData!: PaymentHeader;

  propertyOptions: RadioOption[] = [];
  availablePropertyConfigs!: Option[];

  propertyConfigs!: AllProperties[];
  leftCard: (PaymentHeader & Row)[] = [];

  message: string = '';
  headerId!: string;
  bannerType: BannerType = BannerType.Hidden;

  isChanged: boolean = false;
  propertyNameFixed = 'Fixed';
  isLoading: boolean = true;


  addPropertyForm: FormGroup = this.fb.group({
    option: '',
    propertyName: this.fb.control('', [Validators.required]),
    propertyLabel: this.fb.control('', [Validators.required, this.checkDuplicated()]),
  });


  @ViewChild('gridLeftCard')
  gridLeftCard?: GridComponent<PaymentHeader>;

  constructor(
    private store: Store<fromReducer.State>,
    private dialog: MatDialog,
    private payeeDetailStore: Store<PayeeDetailState>,
    public headerService: HeaderService,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, queryParams]) => {
        const { headerId = '', menuId = '', viewId = '', id = '' } = params;
        this.headerId = headerId;
        const extendsUrl = this.paymentInstructionDetailComponentService.getQueryParams(queryParams);
        this.listBreadcrumbs[0].url = `/member/system-view/payment-header-configuration/${menuId}/${viewId}/${id}${extendsUrl}`;
      });

    // Get view detail
    this.loadDataHeaderDetail();

    this.loadDataHeaderDetailAction();

    // Listen update status
    this.payeeDetailStore.pipe(select(updatePaymentHeader), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success && !data?.isLoading) {
        this.loadDataHeaderDetailAction();
        showBanner.call(this, BannerType.Success, 'Header', ACTION.EDIT);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'Header', ACTION.EDIT);
      }

      this.propertyOptions = [];
      this.addPropertyForm.reset();
    })


    this.store.dispatch(LayoutActions.hiddenSideMenu());
  }

  ngOnDestroy(): void {
    this.addPropertyForm.reset();
    this.payeeDetailStore.dispatch(clearPaymentHeaderConfigurationState());
  }

  changeProperty() {
    const currentProperty = this.addPropertyForm.get('propertyName')?.value;
    currentProperty?.name && this.addPropertyForm.get('propertyLabel')?.setValue(currentProperty?.name);

    let options: RadioOption[] = [];
    if (currentProperty?.options) {
      options = Object.keys(currentProperty?.options)?.map((key) => {
        return {
          label: key,
          value: currentProperty.options[key]
        }
      });
    }

    this.propertyOptions = (options || []).filter(option => {
      const isExisted = this.gridLeftCard?.dataSource.find(
        item => (item?.ppName === currentProperty?.name && option.label === item?.option)
      );
      return !isExisted;
    });

    if (this.propertyOptions?.length) {
      this.addPropertyForm.patchValue({
        option: this.propertyOptions[0].value
      })
    }
  }



  private checkExits(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isDuplicatePropertyDraft = this.gridLeftCard?.dataSource
        .filter((item: any) => item.propertyId !== obj?.propertyId )
        .find((item: any) => (item?.label ?? item?.name)?.toLowerCase()?.trim() === control.value?.toLowerCase()?.trim());
        return isDuplicatePropertyDraft ? { existed: "Label already exists." } : null;
    };
  }

  onChangeData(): void {
    this.isChanged = true;
  }

  onCancelChangeData(): void {
    if (this.gridLeftCard?.tableDataSource?.filteredData) {
      this.isChanged = this.gridLeftCard?.tableDataSource?.filteredData?.some(
        (item: any) =>
          (!!item?.oldValues?.name && item?.name !== item?.oldValues?.name) ||
          item?.editing ||
          item?.deleted ||
          item?.added,
      );
    }
  }

  onSoftDeleteSectionConfig(): void {
    // Soft delete corresponding item at the dataless table
    this.isChanged = true;
  }

  loadDataHeaderDetailAction() {
    this.payeeDetailStore.dispatch(getHeaderDetailAction({ id: this.headerId }));
  }

  loadDataHeaderDetail() {
    this.payeeDetailStore
      .pipe(
        select(selectPaymentHeaderConfigurationDetail),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success && state?.payload) {
          this.headerDetailData = state?.payload?.paymentHeader;
          this.listBreadcrumbs[1].name = this.headerDetailData?.benefitName;
          this.leftCard = (this.headerDetailData?.properties ?? [])
            .filter((config: Properties) => config)
            .sort((a: Properties, b: Properties) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            .map(
              (config: any) => {
                const cloneConfig = deepClone(config);
                let crrPropertyType = this.getType(cloneConfig?.entityReferencePropertyNames ? 54 : cloneConfig.type);
                if (cloneConfig.isFixed) {
                  crrPropertyType = this.propertyNameFixed;
                }
                cloneConfig.propertyId = cloneConfig.entityReferencePropertyNames ? `${cloneConfig.entityPropertyId}|${cloneConfig.entityPropertyLinkedId}` : cloneConfig.entityPropertyId;
                return ({
                  ...cloneConfig,
                  name: cloneConfig.label,
                  propertyType: cloneConfig.type,
                  ppName: cloneConfig.propertyName,
                  ...(cloneConfig?.entityReference ? { propertyType: crrPropertyType } : {}),
                  propertyName: cloneConfig.isFixed ? crrPropertyType : `${crrPropertyType} ${cloneConfig.entityReferencePropertyNames ? `/ ${cloneConfig?.entityReferencePropertyNames[0].name}` : ''} / ${cloneConfig.propertyName}${config.option ? ` / ${cloneConfig.option}` : ''}`,
                  form: this.createInlineEditFormControls(cloneConfig),
                } as PaymentHeader & Row)
              }
            );
          this.propertyConfigs = state.payload?.allProperties.map((item: AllProperties) => {
            const cloneData = deepClone(item);
            if (item?.entityReferencePropertyNames?.length) {
              cloneData.id = `${item.id}|${item.entityReferencePropertyNames[0].id}`;
            }
            return cloneData;
          });
          this.getAvailablePropertyConfigs();
          this.isLoading = state.isLoading;
        }
      });
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.addPropertyForm.reset();
        this.loadDataHeaderDetailAction();
        this.isChanged = false;
        this.propertyOptions = [];
      }
    });
  }

  onSubmit(): void {
    if (this.gridLeftCard?.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    const leftSections = (this.getProfileOverviewConfigs(this.gridLeftCard!?.dataSource) ?? []).filter(item => !(!item?.id && item.isRemoved));
    const metadataProfileOverview: UpdateHeaderDetailRequest = {
      paymentHeaderId: this.headerDetailData.paymentHeaderId,
      paymentHeaderProperties: leftSections
    };
    this.payeeDetailStore.dispatch(updatePaymentHeaderAction({
      body: metadataProfileOverview
    }));
    this.isChanged = false;
  }


  private createInlineEditFormControls(
    config: PaymentHeader & Row
  ): FormGroup {
    return this.fb.group({
      name: new FormControl(
        config?.name ?? config.label,
        getValidatorsFromColumns('name', this.configuredPropertyColumns, config)
      ),
    });
  }


  getAvailablePropertyConfigs() {
    const allPropertyAdded = this.leftCard.map(item => item?.propertyId);
    this.availablePropertyConfigs = this.propertyConfigs.reduce((result: Option[], propertyConfig: AllProperties) => {
      const existedOption = Object.keys(propertyConfig?.options ?? {}).every(
        key => this.leftCard.find(item => (item.ppName === propertyConfig?.name && key === item.option))
      )
      const entityReferencePropertyName = propertyConfig?.entityReferencePropertyNames?.[0]?.name;

      if (allPropertyAdded?.includes(propertyConfig.id) && existedOption) {
        return result;
      } else {
        result.push({
          value: propertyConfig,
          displayValue: propertyConfig?.name,
          valueDescription: propertyConfig?.isFixed ? this.propertyNameFixed : entityReferencePropertyName ? `${this.getType(54)} / ${entityReferencePropertyName}` : this.getType(propertyConfig.type),
        });
        return result;
      }
    }, [] as Option[]);
  }

  setAvailablePropertyConfigs(addedIndex: number) {
    const selectedPropertyOptions = this.propertyOptions
      .filter((item: any) => item.checked)
      .map((item: any) => item.value.propertyName);
    if (
      this.propertyOptions?.length > 1 &&
      !!this.propertyOptions?.length &&
      selectedPropertyOptions?.length < this.propertyOptions?.length
    ) {
      return;
    }
    if (
      !this.propertyOptions ||
      this.propertyOptions.length <= 1
    ) {
      this.availablePropertyConfigs.splice(addedIndex, 1);
    }
  }


  private getProfileOverviewConfigs(
    data: (PaymentHeader & Row)[]
  ): (PaymentHeaderProperties & Row)[] {
    let order: number = 1;
    return data?.map((config: (PaymentHeader & Row)) => {
      const { id, name, propertyId, propertyType, option, entityReferencePropertyNames } = config;
      // Always exists
      const newConfig: PaymentHeaderProperties & Row = {
        id: id,
        entityPropertyId: propertyId?.split(VERTICAL_LINE_SEPARATOR)[0],
        name: name,
        order: config.deleted ? -1 : order,
        type: propertyType,
        isRemoved: config.deleted,
        ...(option ? { option: option } : {}),
        ...(entityReferencePropertyNames ? { entityPropertyLinkedId: entityReferencePropertyNames[0]?.id } : {}),
        ...(entityReferencePropertyNames ? { entityReferenceLinkedId: entityReferencePropertyNames[0]?.entityLinkedId } : {}),
      };

      if (!config.deleted) order++;
      return newConfig;
    });
  }

  addProperty() {
    this.addPropertyForm.markAllAsTouched();
    if (!this.addPropertyForm.valid) {
      return;
    }

    const formValue = this.addPropertyForm.value;
    const optionValue = this.addPropertyForm.get('option')?.value;
    const { id, name, options, entityReferencePropertyNames } = formValue.propertyName;
    let propertyTypeName = this.getType(entityReferencePropertyNames ? 54 : formValue.propertyName.type);
    const addedIndex = this.availablePropertyConfigs.findIndex(
      (item) =>
        item.value.id === id
    );
    if (formValue.propertyName.isFixed) {
      propertyTypeName = this.propertyNameFixed;
    }
    const newViewCard: any & Row = {
      isRemoved: false,
      name: formValue.propertyLabel,
      propertyId: id,
      ppName: name,
      propertyType: formValue.propertyName.type,
      entityReferencePropertyNames: entityReferencePropertyNames,
      propertyName: formValue.propertyName.isFixed ? this.propertyNameFixed : `${propertyTypeName} ${entityReferencePropertyNames ? `/ ${entityReferencePropertyNames[0]?.name}` : ''} / ${name}${Object.keys(options)?.length ? ` / ${optionValue}` : ''}`,
      order: this.gridLeftCard!.dataSource.length,
      added: true,
      ...(options ? { option: optionValue } : {})
    }
    newViewCard.form = this.createInlineEditFormControls(newViewCard);

    // Insert new row into the left card table
    this.leftCard = [...this.gridLeftCard!.dataSource, newViewCard];
    this.setAvailablePropertyConfigs(addedIndex);
    options && this.addPropertyForm.patchValue({
      option: null
    });
    this.addPropertyForm.reset();
    this.propertyOptions = [];
    this.isChanged = true;
  };

  getType(entityPropertyType: EntityPropertyType): string {
    if (
      entityPropertyType === EntityPropertyType.Calculation ||
      entityPropertyType === EntityPropertyType.System ||
      entityPropertyType === EntityPropertyType.Aggregation ||
      entityPropertyType === EntityPropertyType['Entity Reference'] ||
      entityPropertyType === EntityPropertyType.Identifier
    ) {
      return EntityPropertyType[entityPropertyType];
    } else {
      return 'Data';
    }
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.configuredPropertyColumns?.length) {
        return null;
      }
      if (
        this.leftCard?.find(
          (item) =>
            item.name?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: 'Label already exists.',
        };
      }
      return null;
    };
  }
}
