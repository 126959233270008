import { InitialPaymentConfigurationEffects } from './initial-payment-configuration.effect';
import { FinalPaymentConfigurationEffects } from './final-payment-configuration.effect';
import { PaymentHeaderConfigurationEffect } from './payment-header-configuration.effects';
import { PayeeDetailInfoEffects } from './payee-detail.effects';
import { PaymentInfoTabEffect } from './payment-info-tab.effect';
import { PayrollSettingsEffect } from './payroll-settings.effect';
import { PayeePaymentEffects } from './payee-payment.effects';
import { EditFundingSourceEffects } from './edit-funding-source.effect';
import { EditPayrollSettingsEffects } from './edit-payroll-settings.effects';
import { AddOneTimeEffect } from './add-one-time.effects';
import { EditDeductionsEffects } from '@ptg-member/features/payee-detail/store/effects/edit-deductions.effect';
import { NoteMemoEffects } from './note-memo-detail.effects';
import { EditPaymentStatusEffects } from './edit-payment-status.effects';
import { CreateAdjustmentDetailedEffects } from './new-detailed-adjustment.effect';
import { CreateGeneralAdjustmentEffects } from './create-general-adjustment.effects';
import { ViewAdjustmentDetailsEffects } from './view-adjustment-details.effects';
import { CreateReversalAdjustmentEffects } from './create-reversal-adjustment.effects';
import { CreateOffsetDeductionEffect } from './create-offset-deduction.effects';

export const PayeeDetailEffects = [
  InitialPaymentConfigurationEffects,
  FinalPaymentConfigurationEffects,
  PaymentHeaderConfigurationEffect,
  PayeeDetailInfoEffects,
  PaymentInfoTabEffect,
  PayrollSettingsEffect,
  PayeePaymentEffects,
  EditFundingSourceEffects,
  EditPayrollSettingsEffects,
  AddOneTimeEffect,
  EditDeductionsEffects,
  NoteMemoEffects,
  EditPaymentStatusEffects,
  CreateAdjustmentDetailedEffects,
  CreateGeneralAdjustmentEffects,
  ViewAdjustmentDetailsEffects,
  CreateReversalAdjustmentEffects,
  CreateOffsetDeductionEffect
];

export * from './initial-payment-configuration.effect';
export * from './payment-header-configuration.effects';
export * from './payment-info-tab.effect';
export * from './payroll-settings.effect';
export * from './payee-payment.effects';
export * from './edit-funding-source.effect';
export * from './edit-payroll-settings.effects';
export * from './add-one-time.effects';
export * from './edit-deductions.effect';
export * from './edit-payment-status.effects';
export * from './new-detailed-adjustment.effect';
export * from './create-general-adjustment.effects';
export * from './view-adjustment-details.effects';
export * from './create-reversal-adjustment.effects';
export * from './create-offset-deduction.effects';
