<div *ngIf="dataTable.length > 0 || !configCard?.datalessConfig?.isHideEmptyCard" class="deposit-account-container">
  <div class="flex-grow overflow-hidden">
    <ptg-list-display-box
      [title]="configCard.cardName"
      pageName="Data"
      [isAccountDeposit]="true"
      [isLoading]="isLoading"
      [dataTable]="dataTable"
      [columns]="dataTable.length > 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide ? columns : []"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [paginable]="dataTable.length > 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide"
      [isHideNotFoundMessage]="configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.Hide || 
        configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.DisplayColumnHeaders"
      (changePage)="changePage($event)"
      (selectRow)="selectRow($event)"
    >
      <ng-container>
        <p>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Icon-button with a menu"
            class="group-button ignore-row"
          >
            <mat-icon class="ignore-row">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onAddNewAccount()">
              <mat-icon class="member-section-action-icon">add</mat-icon>
              <span class="label-icon">New Account</span>
            </button>
          </mat-menu>
        </p>
      </ng-container>
    </ptg-list-display-box>
  </div>
</div>
