<ptg-dialog class="edit-deductions-dialog-container" [title]="'Edit Deductions'" [isFullScreen]="true">
  <ng-template dialogContentHeader>
    <ptg-payment-header [targetIdFromDialog]="data.memberId" [isShowInteractionComponents]="false"></ptg-payment-header>

    <div *ngIf="bannerType" class="pt-6 px-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>

    <div class="flex gap-2 pt-6 px-6">
      <ptg-button
        (clickButton)="formSubmit$.next()"
        [buttonName]="'Save'"
        [isDisabled]="isLoading || isDisabledSaveBtn"
        classInput="primary"
      >
      </ptg-button>
      <ptg-button (clickButton)="onCancel()" [buttonName]="'Cancel'" classInput="tertiary"> </ptg-button>
    </div>
  </ng-template>

  <ng-template dialogBody>
    <div class="form-container" [ngClass]="data.isRecurringRecord ? 'w-[1385px]' : 'w-[900px]'">
      <div class="form-content flex flex-col gap-4">
        <ng-container *ngFor="let deduction of typesOfDeductions">
          <ng-container *ngIf="deduction.isVisiblePart">
            <ng-container [ngSwitch]="deduction.deductionType">
              <!-- TAXES -->
              <ng-container *ngSwitchCase="DeductionType.Tax">
                <div class="text-xl font-bold emphasis-high">Taxes</div>
                <div class="deduction-box">
                  <ng-container *ngIf="data.isRecurringRecord; else oneTimeTax">
                    <div
                      *ngFor="
                        let deductionForm of $any(editForm.controls[deduction.deductionType])?.controls?.[
                          'deductionSubTypes'
                        ]?.controls
                      "
                      class="flex gap-6"
                    >
                      <div class="min-w-[320px]">
                        <div class="text-sm leading-6 emphasis-medium">Deduction</div>
                        <div class="text-base emphasis-high">
                          {{ deductionForm.value.deductionCode + ' - ' + deductionForm.value?.deductionSubTypeName }}
                        </div>
                      </div>
                      <div class="min-w-[320px]">
                        <div class="text-sm leading-6 emphasis-medium">Amount</div>
                        <div class="text-base emphasis-high">
                          {{ deductionForm.value.amount | currency: 'USD' : 'symbol' : '1.2-2' }}
                        </div>
                      </div>
                    </div>
                    <ptg-button
                      buttonName="New Taxes"
                      iconName="add"
                      classInput="secondary"
                      (clickButton)="onClickAddNewDeductionItem($any(deduction.deductionType))"
                      *ngIf="editForm.controls[deduction.deductionType].value.canAddNew"
                    ></ptg-button>
                  </ng-container>
                  <ng-template #oneTimeTax>
                    <div class="flex gap-4">
                      <ng-container
                        *ngFor="
                          let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                            'deductionSubTypes'
                          ]?.controls
                        "
                      >
                        <ptg-textbox
                          *ngIf="childFormGroup?.value?.deductionSubTypeId !== 2"
                          [controlField]="childFormGroup.get('amount')"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_CURRENCY"
                          [max]="maxValueForCurrency"
                          [min]="minValueForCurrency"
                          class="w-full"
                          [placeholder]="childFormGroup?.value?.deductionSubTypeId === 1 ? 'Federal Tax' : 'State Tax'"
                          (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                        ></ptg-textbox>
                      </ng-container>
                    </div>
                  </ng-template>
                </div>
              </ng-container>

              <!-- INSURANCES -->
              <ng-container *ngSwitchCase="DeductionType.Insurance">
                <div class="text-xl font-bold emphasis-high">Insurances</div>
                <div
                  *ngFor="
                    let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                      'deductionSubTypes'
                    ]?.controls;
                    let i = index
                  "
                  class="flex gap-5"
                >
                  <ptg-select
                    (changeOptionValue)="changeDeductionSubType(DeductionType.Insurance, $event, i)"
                    [controlField]="childFormGroup.get('payrollDeductionId')"
                    [isOptionObj]="true"
                    [isSetMaxWidthSelect]="true"
                    [listData]="childFormGroup.get('listData').value"
                    [placeholder]="'Insurances ' + (i + 1)"
                    [ngClass]="{
                      'w-full': true,
                      'pointer-events-none opacity-70': childFormGroup.get('isDisabledItemName')?.value
                    }"
                  ></ptg-select>
                  <ptg-textbox
                    [controlField]="childFormGroup.get('amount')"
                    [hasLabel]="true"
                    [inputType]="InputType.TYPE_CURRENCY"
                    [max]="maxValueForCurrency"
                    [min]="minValueForCurrency"
                    class="w-full"
                    placeholder="Amount"
                    (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                  ></ptg-textbox>
                  <ng-container *ngIf="data.isRecurringRecord">
                    <ptg-datepicker
                      [controlField]="childFormGroup.get('startDate')"
                      [customError]="'customError'"
                      [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                      [minDate]="childFormGroup.value.masterEffectiveStartDate"
                      [errorMinDate]="
                        childFormGroup.value.masterEffectiveStartDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      [errorMaxDate]="
                        childFormGroup.value.masterEffectiveEndDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      class="w-full"
                      placeholder="Start Date"
                      (changeValue)="onChangeStartDate($any(deduction.deductionType), i)"
                      (onOutFocus)="onChangeStartDate($any(deduction.deductionType), i)"
                    ></ptg-datepicker>
                    <ptg-datepicker
                      [controlField]="childFormGroup.get('endDate')"
                      [customError]="'customError'"
                      [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                      [minDate]="childFormGroup.value.masterEffectiveStartDate"
                      [errorMinDate]="
                        childFormGroup.value.masterEffectiveStartDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      [errorMaxDate]="
                        childFormGroup.value.masterEffectiveEndDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      class="w-full"
                      placeholder="End Date"
                      (changeValue)="onChangeEndDate($any(deduction.deductionType), i)"
                      (onOutFocus)="onChangeEndDate($any(deduction.deductionType), i)"
                    ></ptg-datepicker>
                  </ng-container>
                  <ptg-button
                    (clickButton)="removeDeductionItem($any(deduction.deductionType), i)"
                    classInput="delete-icon text-only"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </ptg-button>
                </div>
                <ptg-button
                  buttonName="New Insurance"
                  iconName="add"
                  classInput="secondary"
                  *ngIf="editForm.controls[deduction.deductionType].value.canAddNew"
                  (clickButton)="onClickAddNewDeductionItem($any(deduction.deductionType))"
                ></ptg-button>
                <hr />
              </ng-container>

              <!-- ADJUSTMENTS -->
              <ng-container *ngSwitchCase="DeductionType.Adjustment">
                <ng-container *ngIf="isShownAdjustmentSection">
                  <div class="text-xl font-bold emphasis-high">Adjustment Deductions</div>
                  <div
                    *ngFor="
                      let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                        'deductionSubTypes'
                      ]?.controls;
                      let i = index
                    "
                    class="flex flex-col gap-5"
                  >
                    <div class="flex gap-5">
                      <ptg-select
                        *ngIf="childFormGroup.get('selectedFromExistingList').value"
                        placeholder="Deduction Name"
                        [isOptionObj]="true"
                        [isSetMaxWidthSelect]="true"
                        [listData]="childFormGroup.get('listData').value"
                        [controlField]="childFormGroup.get('payrollPayeeAdjustmentDeductionId')"
                        [ngClass]="{
                          'w-full': true,
                          'pointer-events-none opacity-70': childFormGroup.get('isDisabledItemName')?.value
                        }"
                        (changeOptionValue)="changeDeductionSubType(DeductionType.Adjustment, $event, i)"
                      ></ptg-select>
                      <ptg-textbox
                        *ngIf="!childFormGroup.get('selectedFromExistingList').value"
                        placeholder="Deduction Name"
                        class="w-full"
                        [hasLabel]="true"
                        [maxLength]="250"
                        customError="customErrorDeductionName"
                        [controlField]="childFormGroup.get('deductionName')"
                        (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                      ></ptg-textbox>
                      <ptg-textbox
                        placeholder="Amount"
                        class="w-full"
                        [hasLabel]="true"
                        [inputType]="InputType.TYPE_CURRENCY"
                        [max]="maxValueForCurrency"
                        [min]="minValueForCurrencyAdjustment"
                        [controlField]="childFormGroup.get('amount')"
                        (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                        (valueChange)="calculateRemainingBalance($any(deduction.deductionType), i)"
                      ></ptg-textbox>
                      <ptg-textbox
                        placeholder="Total Limit"
                        class="w-full"
                        [hasLabel]="true"
                        [inputType]="InputType.TYPE_CURRENCY"
                        [max]="maxValueForCurrency"
                        [min]="minValueForCurrencyAdjustment"
                        [controlField]="childFormGroup.get('totalLimit')"
                        (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                        (valueChange)="calculateRemainingBalance($any(deduction.deductionType), i)"
                      ></ptg-textbox>
                      <ptg-datepicker
                        placeholder="Start Date"
                        class="w-full"
                        [minDate]="minAdjustmentDeductionStartDate"
                        errorMinDate="Cannot enter date earlier than the current benefit period's start date."
                        [isDisabled]="childFormGroup.get('isLinkedToFinalizedPayment').value || data.isOTPRecord"
                        [controlField]="childFormGroup.get('startDate')"
                        (changeValue)="onChangeStartDate($any(deduction.deductionType), i)"
                        (onOutFocus)="onChangeStartDate($any(deduction.deductionType), i)"
                      ></ptg-datepicker>
                      <div class="w-full">
                        <div class="text-sm leading-6 font-bold emphasis-medium">Remaining Balance</div>
                        <div class="text-lg leading-6 font-bold emphasis-high">
                          {{
                            childFormGroup.get('remainingBalance').value
                              | numberLocalDecimal: '$' : { accountingFormat: true }
                          }}
                        </div>
                        <mat-error *ngIf="childFormGroup.get('remainingBalance').hasError('min')"
                          >The remaining amount for deduction is insufficient.</mat-error
                        >
                      </div>
                      <ptg-button
                        (clickButton)="removeDeductionItem($any(deduction.deductionType), i)"
                        classInput="delete-icon text-only"
                      >
                        <mat-icon>delete_forever</mat-icon>
                      </ptg-button>
                    </div>
                    <div class="flex gap-5">
                      <ptg-textbox
                        placeholder="Deduction Description"
                        [ngClass]="{ 'w-[252px]': data.isRecurringRecord }"
                        [ngStyle]="data.isRecurringRecord ? null : { width: '30%' }"
                        [hasLabel]="true"
                        [maxLength]="255"
                        [controlField]="childFormGroup.get('deductionDescription')"
                        (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                      ></ptg-textbox>
                      <ptg-radio-button
                        *ngIf="data.isRecurringRecord"
                        [isObject]="true"
                        [classInput]="'flex items-center justify-center mt-3'"
                        [listOption]="postOrPretaxOptions"
                        [controlField]="childFormGroup.get('postOrPretax')"
                      ></ptg-radio-button>
                    </div>
                    <div
                      *ngFor="
                        let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                          'deductionSubTypes'
                        ]?.controls;
                        let i = index
                      "
                      class="flex flex-col gap-5"
                    >
                      <div class="flex gap-5">
                        <ptg-select
                          *ngIf="childFormGroup.get('selectedFromExistingList').value"
                          placeholder="Deduction Name"
                          [isOptionObj]="true"
                          [isSetMaxWidthSelect]="true"
                          [listData]="childFormGroup.get('listData').value"
                          [controlField]="childFormGroup.get('payrollPayeeAdjustmentDeductionId')"
                          class="w-full {{childFormGroup.get('isDisabledItemName')?.value ? 'pointer-events-none opacity-70' : ''}}"
                          (changeOptionValue)="changeDeductionSubType(DeductionType.Adjustment, $event, i)"
                        ></ptg-select>
                        <ptg-textbox
                          *ngIf="!childFormGroup.get('selectedFromExistingList').value"
                          placeholder="Deduction Name"
                          class="w-full"
                          [hasLabel]="true"
                          [maxLength]="250"
                          customError="customErrorDeductionName"
                          [controlField]="childFormGroup.get('deductionName')"
                          (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                        ></ptg-textbox>
                        <ptg-textbox
                          placeholder="Amount"
                          class="w-full"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_CURRENCY"
                          [max]="maxValueForCurrency"
                          [min]="minValueForCurrencyAdjustment"
                          [controlField]="childFormGroup.get('amount')"
                          (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                          (valueChange)="calculateRemainingBalance($any(deduction.deductionType), i)"
                        ></ptg-textbox>
                        <ptg-textbox
                          placeholder="Total Limit"
                          class="w-full"
                          [hasLabel]="true"
                          [inputType]="InputType.TYPE_CURRENCY"
                          [max]="maxValueForCurrency"
                          [min]="minValueForCurrencyAdjustment"
                          [controlField]="childFormGroup.get('totalLimit')"
                          (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                          (valueChange)="calculateRemainingBalance($any(deduction.deductionType), i)"
                        ></ptg-textbox>
                        <ptg-datepicker
                          placeholder="Start Date"
                          class="w-full"
                          [minDate]="minAdjustmentDeductionStartDate"
                          errorMinDate="Cannot enter date earlier than the current benefit period's start date."
                          [isDisabled]="childFormGroup.get('isLinkedToFinalizedPayment').value || data.isOTPRecord"
                          [controlField]="childFormGroup.get('startDate')"
                          (changeValue)="onChangeStartDate($any(deduction.deductionType), i)"
                          (onOutFocus)="onChangeStartDate($any(deduction.deductionType), i)"
                        ></ptg-datepicker>
                        <div class="w-full">
                          <div class="text-sm leading-6 font-bold emphasis-medium">Remaining Balance</div>
                          <div class="text-lg leading-6 font-bold emphasis-high">
                            {{
                              childFormGroup.get('remainingBalance').value
                                | numberLocalDecimal: '$' : { accountingFormat: true }
                            }}
                          </div>
                          <mat-error *ngIf="childFormGroup.get('remainingBalance').hasError('min')"
                            >The remaining amount for deduction is insufficient.</mat-error
                          >
                        </div>
                        <ptg-button
                          (clickButton)="removeDeductionItem($any(deduction.deductionType), i)"
                          classInput="delete-icon text-only"
                        >
                          <mat-icon>delete_forever</mat-icon>
                        </ptg-button>
                      </div>
                      <div class="flex gap-5">
                        <ptg-textbox
                          placeholder="Deduction Description"
                          class="w-[252px]"
                          [hasLabel]="true"
                          [maxLength]="255"
                          [controlField]="childFormGroup.get('deductionDescription')"
                          (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                        ></ptg-textbox>
                        <ptg-radio-button
                          [isObject]="true"
                          [classInput]="'flex items-center justify-center'"
                          [listOption]="postOrPretaxOptions"
                          [controlField]="childFormGroup.get('postOrPretax')"
                        ></ptg-radio-button>
                      </div>
                      <div
                        *ngIf="
                          !childFormGroup.get('isShownWhenOpening').value &&
                          childFormGroup.get('listData').value?.length
                        "
                        class="mt-[-0.5rem] mb-4"
                      >
                        <ptg-toggle-button
                          label="Select From Existing List"
                          [controlField]="childFormGroup.get('selectedFromExistingList')"
                          [disabled]="data.isOTPRecord && !childFormGroup.get('isLumpSum').value"
                          (valueChange)="getSelectedFromExistingListChanges($any(deduction.deductionType), i, $event)"
                        ></ptg-toggle-button>
                      </div>
                    </div>
                  </div>
                  <ptg-button
                    buttonName="New Adjustment Deductions"
                    iconName="add"
                    classInput="secondary"
                    *ngIf="editForm.controls[deduction.deductionType].value.canAddNew"
                    (clickButton)="onClickAddNewDeductionItem($any(deduction.deductionType))"
                  ></ptg-button>
                  <hr />
                </ng-container>
              </ng-container>

              <!-- OTHERS -->
              <ng-container *ngSwitchCase="DeductionType.Others">
                <div class="text-xl font-bold emphasis-high">Others</div>
                <div
                  *ngFor="
                    let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                      'deductionSubTypes'
                    ]?.controls;
                    let i = index
                  "
                  class="flex gap-5"
                >
                  <ptg-select
                    (changeOptionValue)="changeDeductionSubType($any(deduction.deductionType), $event, i)"
                    [controlField]="childFormGroup.get('payrollDeductionId')"
                    [isSetMaxWidthSelect]="true"
                    [isOptionObj]="true"
                    [listData]="childFormGroup.get('listData').value"
                    [placeholder]="'Others ' + (i + 1)"
                    [ngClass]="{
                      'w-full': true,
                      'pointer-events-none opacity-70': childFormGroup.get('isDisabledItemName')?.value
                    }"
                  ></ptg-select>
                  <ptg-textbox
                    placeholder="Amount"
                    class="w-full"
                    [controlField]="childFormGroup.get('amount')"
                    [hasLabel]="true"
                    [inputType]="InputType.TYPE_CURRENCY"
                    [min]="minValueForCurrency"
                    [max]="maxValueForCurrency"
                    (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                  ></ptg-textbox>
                  <ng-container *ngIf="data.isRecurringRecord">
                    <ptg-datepicker
                      [customError]="'customError'"
                      [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                      [minDate]="childFormGroup.value.masterEffectiveStartDate"
                      [errorMinDate]="
                        childFormGroup.value.masterEffectiveStartDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      [errorMaxDate]="
                        childFormGroup.value.masterEffectiveEndDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      class="w-full"
                      placeholder="Start Date"
                      (changeValue)="onChangeStartDate($any(deduction.deductionType), i)"
                      (onOutFocus)="onChangeStartDate($any(deduction.deductionType), i)"
                      [controlField]="childFormGroup.get('startDate')"
                    ></ptg-datepicker>
                    <ptg-datepicker
                      [customError]="'customError'"
                      [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                      [minDate]="childFormGroup.value.masterEffectiveStartDate"
                      [errorMinDate]="
                        childFormGroup.value.masterEffectiveStartDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      [errorMaxDate]="
                        childFormGroup.value.masterEffectiveEndDate
                          ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                          : 'Invalid Date format.'
                      "
                      class="w-full"
                      placeholder="End Date"
                      (changeValue)="onChangeEndDate($any(deduction.deductionType), i)"
                      (onOutFocus)="onChangeEndDate($any(deduction.deductionType), i)"
                      [controlField]="childFormGroup.get('endDate')"
                    ></ptg-datepicker>
                  </ng-container>
                  <ptg-button
                    (clickButton)="removeDeductionItem($any(deduction.deductionType), i)"
                    classInput="delete-icon text-only"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </ptg-button>
                </div>
                <ptg-button
                  buttonName="New Others"
                  iconName="add"
                  classInput="secondary"
                  (clickButton)="onClickAddNewDeductionItem($any(deduction.deductionType))"
                  *ngIf="editForm.controls[deduction.deductionType].value.canAddNew"
                ></ptg-button>
                <hr />
              </ng-container>

              <!-- GARNISHMENTS -->
              <ng-container *ngSwitchCase="DeductionType.Garnishment">
                <div class="text-xl font-bold emphasis-high">Garnishments</div>
                <div
                  *ngFor="
                    let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                      'deductionSubTypes'
                    ]?.controls;
                    let i = index
                  "
                  class="flex flex-col gap-5"
                >
                  <div class="flex gap-5">
                    <ptg-select
                      (changeOptionValue)="changeDeductionSubType($any(deduction.deductionType), $event, i)"
                      [controlField]="childFormGroup.get('caseNumber')"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [listData]="childFormGroup.get('listData').value"
                      [placeholder]="'Court Order'"
                      [customError]="'customError'"
                      [ngClass]="{
                        'w-full': true,
                        'pointer-events-none opacity-70': childFormGroup.get('isDisabledItemName')?.value
                      }"
                    ></ptg-select>
                    <div class="w-full">
                      <div class="text-sm leading-6 emphasis-medium">Deduction Payee</div>
                      <div class="text-base emphasis-high">
                        {{ childFormGroup.value.payee }}
                      </div>
                    </div>
                    <ptg-textbox
                      [controlField]="childFormGroup.get('amount')"
                      [hasLabel]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [max]="maxValueForCurrency"
                      [min]="minValueForCurrency"
                      (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                      class="w-full"
                      placeholder="Amount"
                    ></ptg-textbox>
                    <ng-container *ngIf="data.isRecurringRecord">
                      <ptg-datepicker
                        [controlField]="childFormGroup.get('startDate')"
                        [customError]="'customError'"
                        [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                        [minDate]="childFormGroup.value.masterEffectiveStartDate"
                        [errorMinDate]="
                          childFormGroup.value.masterEffectiveStartDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        [errorMaxDate]="
                          childFormGroup.value.masterEffectiveEndDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        class="w-full"
                        placeholder="Start Date"
                        (changeValue)="onChangeStartDate($any(deduction.deductionType), i)"
                        (onOutFocus)="onChangeStartDate($any(deduction.deductionType), i)"
                      ></ptg-datepicker>
                      <ptg-datepicker
                        [controlField]="childFormGroup.get('endDate')"
                        [customError]="'customError'"
                        [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                        [minDate]="childFormGroup.value.masterEffectiveStartDate"
                        [errorMinDate]="
                          childFormGroup.value.masterEffectiveStartDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        [errorMaxDate]="
                          childFormGroup.value.masterEffectiveEndDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        class="w-full"
                        placeholder="End Date"
                        (changeValue)="onChangeEndDate($any(deduction.deductionType), i)"
                        (onOutFocus)="onChangeEndDate($any(deduction.deductionType), i)"
                      ></ptg-datepicker>
                    </ng-container>
                    <ptg-button
                      (clickButton)="removeDeductionItem($any(deduction.deductionType), i)"
                      classInput="delete-icon text-only"
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                  <div class="flex gap-5">
                    <ptg-textbox
                      [controlField]="childFormGroup.get('deductionDescription')"
                      [hasLabel]="true"
                      [maxLength]="255"
                      [ngClass]="{ 'w-[252px]': data.isRecurringRecord }"
                      [ngStyle]="data.isRecurringRecord ? null : { width: '30%' }"
                      placeholder="Deduction Description"
                      (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                    ></ptg-textbox>
                    <ptg-radio-button
                      *ngIf="data.isRecurringRecord"
                      [classInput]="'flex items-center justify-center mt-3'"
                      [controlField]="childFormGroup.get('postOrPretax')"
                      [isObject]="true"
                      [listOption]="postOrPretaxOptions"
                    ></ptg-radio-button>
                  </div>
                </div>
                <ptg-button
                  buttonName="New Garnishment"
                  iconName="add"
                  classInput="secondary"
                  (clickButton)="onClickAddNewDeductionItem($any(deduction.deductionType))"
                  *ngIf="editForm.controls[deduction.deductionType].value.canAddNew"
                ></ptg-button>
              </ng-container>

              <!-- QDROs -->
              <ng-container *ngSwitchCase="DeductionType.Qdro">
                <div class="text-xl font-bold emphasis-high">
                  {{ editForm.controls[deduction.deductionType]?.value?.labelName }}s
                </div>
                <div
                  class="flex flex-col gap-4"
                  *ngFor="
                    let childFormGroup of $any(editForm.controls[deduction.deductionType])?.controls?.[
                      'deductionSubTypes'
                    ]?.controls;
                    let i = index
                  "
                >
                  <div class="flex gap-5">
                    <ptg-select
                      (changeOptionValue)="changeDeductionSubType($any(deduction.deductionType), $event, i)"
                      [controlField]="childFormGroup.get('caseNumber')"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [listData]="childFormGroup.get('listData').value"
                      [placeholder]="'Court Order'"
                      [customError]="'customError'"
                      [ngClass]="{
                        'w-full': true,
                        'pointer-events-none opacity-70': childFormGroup.get('isDisabledItemName')?.value
                      }"
                    ></ptg-select>
                    <div class="w-full">
                      <div class="text-sm leading-6 emphasis-medium">Alternate Payee</div>
                      <div class="text-base emphasis-high">
                        {{ childFormGroup.value.payee }}
                      </div>
                    </div>
                    <ptg-textbox
                      [controlField]="childFormGroup.get('amount')"
                      [hasLabel]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [max]="maxValueForCurrency"
                      [min]="minValueForCurrency"
                      (changeControlValue)="checkCanAddNewItems($any(deduction.deductionType))"
                      class="w-full"
                      placeholder="Amount"
                    ></ptg-textbox>
                    <ng-container *ngIf="data.isRecurringRecord">
                      <ptg-datepicker
                        [controlField]="childFormGroup.get('startDate')"
                        [customError]="'customError'"
                        [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                        [minDate]="childFormGroup.value.masterEffectiveStartDate"
                        [errorMinDate]="
                          childFormGroup.value.masterEffectiveStartDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        [errorMaxDate]="
                          childFormGroup.value.masterEffectiveEndDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        class="w-full"
                        placeholder="Start Date"
                        (changeValue)="onChangeStartDate($any(deduction.deductionType), i)"
                        (onOutFocus)="onChangeStartDate($any(deduction.deductionType), i)"
                      ></ptg-datepicker>
                      <ptg-datepicker
                        [controlField]="childFormGroup.get('endDate')"
                        [customError]="'customError'"
                        [maxDate]="childFormGroup.value.masterEffectiveEndDate"
                        [minDate]="childFormGroup.value.masterEffectiveStartDate"
                        [errorMinDate]="
                          childFormGroup.value.masterEffectiveStartDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        [errorMaxDate]="
                          childFormGroup.value.masterEffectiveEndDate
                            ? 'Start Date and End Date must be in defined date range in Deduction List (master data).'
                            : 'Invalid Date format.'
                        "
                        class="w-full"
                        placeholder="End Date"
                        (changeValue)="onChangeEndDate($any(deduction.deductionType), i)"
                        (onOutFocus)="onChangeEndDate($any(deduction.deductionType), i)"
                      ></ptg-datepicker>
                    </ng-container>
                    <ptg-button
                      *ngIf="!$any(editForm.controls[deduction.deductionType])?.controls?.['hideRemove']?.value"
                      (clickButton)="removeDeductionItem($any(deduction.deductionType), i)"
                      classInput="delete-icon text-only"
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                  <div class="flex">
                    <ptg-radio-button
                      *ngIf="data.isRecurringRecord"
                      [classInput]="'flex items-center justify-center mt-3'"
                      [controlField]="childFormGroup.get('postOrPretax')"
                      [isObject]="true"
                      [listOption]="postOrPretaxOptions"
                    ></ptg-radio-button>
                  </div>
                </div>
                <ptg-button
                  buttonName="New {{ editForm.controls[deduction.deductionType]?.value?.labelName ?? 'QDRO' }}"
                  iconName="add"
                  classInput="secondary"
                  (clickButton)="onClickAddNewDeductionItem($any(deduction.deductionType))"
                  *ngIf="editForm.controls[deduction.deductionType].value.canAddNew"
                ></ptg-button>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
