import { createAction, props } from '@ngrx/store';

import {
  AddDisabilityPaymentRequestBody,
  EditMetRequirementBody,
  EditMetRequirementResponse,
  GetDisabilityPaymentsResponse,
  GetListStatusHistoryQuery,
  MunicipalityList,
} from '../../types/models';

export const MunicipalityPaymentListRequest =
  '[MunicipalityPaymentList/API] Send Request';
export const MunicipalityPaymentListSuccess =
  '[MunicipalityPaymentList/API] Success';
export const MunicipalityPaymentListFailure =
  '[MunicipalityPaymentList/API] Failure';

export const CreateMemberMunicipalityPaymentRequest =
  '[CreateMemberMunicipalityPayment/API] Send Request';
export const CreateMemberMunicipalityPaymentSuccess =
  '[CreateMemberMunicipalityPayment/API] Success';
export const CreateMemberMunicipalityPaymentFailure =
  '[CreateMemberMunicipalityPayment/API] Failure';
export const EditMemberMunicipalityPaymentRequest =
  '[EditMemberMunicipalityPayment/API] Send Request';
export const EditMemberMunicipalityPaymentSuccess =
  '[EditMemberMunicipalityPayment/API] Success';
export const EditMemberMunicipalityPaymentFailure =
  '[EditMemberMunicipalityPayment/API] Failure';
export const ClearMunicipalityPaymentState = '[ClearMunicipalityPaymentState]';

export const EditMunicipalityPaymentMetRequirementRequest =  '[EditMunicipalityPaymentMetRequirement/API] Send Request';
export const EditMunicipalityPaymentMetRequirementSuccess =  '[EditMunicipalityPaymentMetRequirement/API] Success';
export const EditMunicipalityPaymentMetRequirementFailure =  '[EditMunicipalityPaymentMetRequirement/API] Failure';

export const AddDisabilityPaymentSendRequest = '[AddDisabilityPayment/API] Send Request';
export const AddDisabilityPaymentSuccess = '[AddDisabilityPayment/API] Success';
export const AddDisabilityPaymentFailure = '[AddDisabilityPayment/API] Failure';

export const GetDisabilityPaymentsSendRequest = '[GetDisabilityPayments/API] Send Request';
export const GetDisabilityPaymentsSuccess = '[GetDisabilityPayments/API] Success';
export const GetDisabilityPaymentsFailure = '[GetDisabilityPayments/API] Failure';

export const getMunicipalityPaymentList = createAction(
  MunicipalityPaymentListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const municipalityPaymentListSuccess = createAction(
  MunicipalityPaymentListSuccess,
  props<{ payments: MunicipalityList }>()
);
export const municipalityPaymentListFailure = createAction(
  MunicipalityPaymentListFailure
);
export const clearMunicipalityPaymentState = createAction(
  ClearMunicipalityPaymentState
);

export const editMunicipalityPaymentMetRequirement = createAction(
  EditMunicipalityPaymentMetRequirementRequest,
  props<{ body: EditMetRequirementBody }>()
);
export const editMunicipalityPaymentMetRequirementSuccess = createAction(
  EditMunicipalityPaymentMetRequirementSuccess,
  props<{ editMetRequirementResponse: EditMetRequirementResponse }>()
);
export const editMunicipalityPaymentMetRequirementFailure = createAction(
  EditMunicipalityPaymentMetRequirementFailure,
  props<{ error?: any }>()
);

export const addDisabilityPaymentAction = createAction(
  AddDisabilityPaymentSendRequest,
  props<{ body: AddDisabilityPaymentRequestBody }>()
);
export const addDisabilityPaymentActionSuccess = createAction(
  AddDisabilityPaymentSuccess
);
export const addDisabilityPaymentActionFailure = createAction(
  AddDisabilityPaymentFailure,
  props<{ error?: any }>()
);

export const getDisabilityPaymentAction = createAction(
  GetDisabilityPaymentsSendRequest,
  props<{ memberId: string }>()
);
export const getDisabilityPaymentActionSuccess = createAction(
  GetDisabilityPaymentsSuccess,
  props<{ response: GetDisabilityPaymentsResponse }>()
);
export const getDisabilityPaymentActionFailure = createAction(
  GetDisabilityPaymentsFailure,
  props<{ error?: any }>()
);
