import { createAction, props } from '@ngrx/store';

import {
  ParameterMappings,
  ModifiedDisplayOutputsConfiguration,
  BenefitCalculationConfigurationsByType,
  CheckComputeDataResponse,
  ComputationDataResponse,
  CheckStepConfigurationExistsResponse,
  ApproveCalculationBenefitRequest,
} from '@ptg-member/features/calculation/services/models';
import {
  CalculationBenefitHistoryStatus,
  CalculationType,
  GetParameterMappingType,
} from '@ptg-member/features/calculation/types/enums';
import { CalculationBenefitDetailType } from '../../services/models/retirement-benefit-detail.model';

// GetCalculationParameterMapping
export const getBenefitCalculationParameterMappingAction = createAction(
  '[GetBenefitCalculationParameterMapping/API] Send Request',
  props<{ parameterType?: GetParameterMappingType }>(),
);
export const getBenefitCalculationParameterMappingSuccessAction = createAction(
  '[GetBenefitCalculationParameterMapping/API] Success',
  props<{ response: ParameterMappings }>(),
);
export const getBenefitCalculationParameterMappingFailureAction = createAction(
  '[GetBenefitCalculationParameterMapping/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetBenefitCalculationParameterMappingStateAction = createAction(
  '[GetBenefitCalculationParameterMapping] Clear',
);

// GetCalculationDisplayConfigurationByType
export const getCalculationDisplayConfigurationByTypeAction = createAction(
  '[GetCalculationDisplayConfigurationByType/API] Send Request',
  props<{ calculationType: CalculationType }>(),
);
export const getCalculationDisplayConfigurationByTypeSuccessAction = createAction(
  '[GetCalculationDisplayConfigurationByType/API] Success',
  props<{ response: BenefitCalculationConfigurationsByType }>(),
);
export const getCalculationDisplayConfigurationByTypeFailureAction = createAction(
  '[GetCalculationDisplayConfigurationByType/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetCalculationDisplayConfigurationByTypeStateAction = createAction(
  '[GetCalculationDisplayConfigurationByType] Clear',
);

// ModifyCalculationDisplayConfiguration
export const setModifyCalculationDisplayConfigurationAction = createAction(
  '[GetModifyCalculationDisplayConfiguration/API] Send Request',
  props<{ id: string; request: ModifiedDisplayOutputsConfiguration }>(),
);
export const setModifyCalculationDisplayConfigurationSuccessAction = createAction(
  '[GetModifyCalculationDisplayConfiguration/API] Success',
);
export const setModifyCalculationDisplayConfigurationFailureAction = createAction(
  '[GetModifyCalculationDisplayConfiguration/API] Failure',
  props<{ error?: any }>(),
);
export const clearSetModifyCalculationDisplayConfigurationStateAction = createAction(
  '[SetModifyCalculationDisplayConfiguration] Clear',
);

//CompleteCalculationBenefit
export const completeCalculationBenefitAction = createAction(
  '[completeCalculationBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
  }>(),
);
export const completeCalculationBenefitSuccessAction = createAction('[completeCalculationBenefit/API] Success');
export const completeCalculationBenefitFailureAction = createAction(
  '[completeCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearCompleteCalculationBenefitStateAction = createAction('[completeCalculationBenefit] Clear');

//ApproveCalculationBenefit
export const approveCalculationBenefitAction = createAction(
  '[ApproveCalculationBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
    body?: ApproveCalculationBenefitRequest;
  }>(),
);
export const approveCalculationBenefitSuccessAction = createAction('[ApproveCalculationBenefit/API] Success');
export const approveCalculationBenefitFailureAction = createAction(
  '[ApproveCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearApproveCalculationBenefitStateAction = createAction('[ApproveCalculationBenefit] Clear');

//ReopenCalculationBenefit
export const reopenCalculationBenefitAction = createAction(
  '[ReopenCalculationBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
  }>(),
);
export const reopenCalculationBenefitSuccessAction = createAction('[ReopenCalculationBenefit/API] Success');
export const reopenCalculationBenefitFailureAction = createAction(
  '[ReopenCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearReopenCalculationBenefitStateAction = createAction('[ReopenCalculationBenefit] Clear');

//validate Calculation Benefit
export const validateCalculationBenefitAction = createAction(
  '[ValidateCalculationBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
    processToStatus: CalculationBenefitHistoryStatus;
    calculationRecordId?: string;
  }>(),
);
export const validateCalculationBenefitSuccessAction = createAction(
  '[ValidateCalculationBenefit/API] Success',
  props<{ response: CheckComputeDataResponse }>(),
);
export const validateCalculationBenefitFailureAction = createAction(
  '[ValidateCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearValidateCalculationBenefitAction = createAction('[ValidateCalculationBenefit] Clear');

//validate Calculation Benefit
export const computeCalculationBenefitAction = createAction(
  '[ComputeCalculationBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
  }>(),
);
export const computeCalculationBenefitSuccessAction = createAction(
  '[ComputeCalculationBenefit/API] Success',
  props<{ response: ComputationDataResponse }>(),
);
export const computeCalculationBenefitFailureAction = createAction(
  '[ComputeCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearComputeCalculationBenefitStateAction = createAction('[ComputeCalculationBenefit] Clear');

//validate before Calculation Benefit with JointSurvivor
export const validateBeforeCalculationBenefitAction = createAction(
  '[ValidateBeforeCalculationBenefit/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    calculationType: CalculationType;
    sectionType?: CalculationBenefitDetailType;
  }>(),
);
export const validateBeforeCalculationBenefitSuccessAction = createAction(
  '[ValidateBeforeCalculationBenefit/API] Success',
  props<{ response: CheckStepConfigurationExistsResponse; sectionType?: CalculationBenefitDetailType }>(),
);
export const validateBeforeCalculationBenefitFailureAction = createAction(
  '[ValidateBeforeCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearValidateBeforeCalculationBenefitStateAction = createAction(
  '[ValidateBeforeCalculationBenefit] Clear',
);
