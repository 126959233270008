import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Column, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Menu, MenuItem, ViewFixType } from '@ptg-shared/layout/models/layout.model';
import { DEFAULT_PAGE_SIZE } from '@ptg-shared/constance';

import { EntityViewItem } from '@ptg-entity-management/services/models';
import * as fromReducer from '@ptg-reducers';
import { DepositAccount, GetDepositAccountsRequest } from '@ptg-member/types/models/deposit-account.model';
import { getDepositAccountsAction } from '@ptg-member/store/actions/deposit-account.action';
import { createDepositAccountsSelector, getDepositAccountsState } from '@ptg-member/store/selectors';
import { DepositAccountRouterUrl } from '@ptg-member/constants';
import { UpsertDepositAccountComponent } from '@ptg-member/components/upsert-deposit-account/upsert-deposit-account.component';
import { LIST_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';

const PAGE_SIZE_CONST = '-ptg-entity-deposit-account-pageSize';

@Component({
  selector: 'ptg-entity-deposit-account',
  templateUrl: './entity-deposit-account.component.html',
  styleUrls: ['./entity-deposit-account.component.scss'],
})
export class EntityDepositAccountComponent extends BaseComponent {
  readonly LIST_DISPLAY_PREFERENCE_TYPE = LIST_DISPLAY_PREFERENCE_TYPE;
  
  @Input() memberId!: string;
  @Input() screenId!: string;
  @Input() configCard!: EntityViewItem;
  @Input() viewId!: string;
  @Input() viewName!: string;
  @Input() viewUrl!: string;

  columns: Column[] = [
    {
      name: 'bankName',
      header: {
        title: 'Bank Name',
      },
      truncate: true,
    },
    {
      name: 'accountNumber',
      header: {
        title: 'Account Number',
      },
      truncate: true,
    },
    {
      name: 'isUse',
      header: {
        title: 'In Use',
      },
    },
    {
      name: 'isActive',
      header: {
        title: 'Active',
      },
    },
  ];

  memberNavigationMenu!: Menu[];
  title: string = '';
  isLoading: boolean = true;
  dataTable: (DepositAccount & Row)[] = [];
  lengthPg: number | any;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  currentFund: any = {};

  constructor(
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFund();

    this.getData();

    this.getDepositAccountSelector();
    this.getCreateDepositAccountSelector();
  }

  getCurrentFund() {
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? DEFAULT_PAGE_SIZE;
      this.currentFund = el;
      this.pageSize =
        Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0
          ? this.pageSize
          : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    });
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  getData(jumpToFirst?: boolean) {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }
    let request: GetDepositAccountsRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      targetId: this.memberId,
    };

    this.store.dispatch(getDepositAccountsAction({ request }));
  }

  getDepositAccountSelector() {
    this.store.pipe(select(getDepositAccountsState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success && !state?.isLoading) {
        this.dataTable = (state?.payload?.depositAccounts ?? [])?.map((el) => {
          return {
            ...el,
            accountNumber: el?.accountNumber && el?.accountNumber?.length > 4 ?
              (this.getMaskedFormat((el?.accountNumber?.length ?? 0) - 4) ?? '') +
              el?.accountNumber?.slice(el.accountNumber?.length - 4, el.accountNumber?.length) : el?.accountNumber,
          };
        });
        this.isLoading = state?.isLoading;
        this.lengthPg = state?.payload?.total;
      }
    });
  }

  getCreateDepositAccountSelector() {
    this.store
    .pipe(
      select(createDepositAccountsSelector),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state?.success) {
        this.getData();
      } 
    });
  }

  getMaskedFormat(length?: number) {
    let format = '';
    if (length! < 0) {
      return;
    }
    for (let index = 0; index < length!; index++) {
      format = format + 'X';
    }
    return format;
  }

  selectRow(event: any) {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.isReloading && state.memberId) {
          const allMenuItems = state?.menu?.reduce((acc: MenuItem[], menu: Menu) => {
            return [...acc, ...(menu?.menuItems ?? [])];
          }, []);
          const menuSameCard = allMenuItems.find((menuitem) => menuitem.viewFixType === ViewFixType.DepositAccountList);
          const currentRowIndex = this.dataTable.findIndex((item) => item.id === event?.id);
          if (menuSameCard) {
            this.router.navigateByUrl(
              `${menuSameCard.routerLink}${this.memberId}?currentRowIndex=${currentRowIndex}&pageSize=${
                this.pageSize
              }&pageNumber=${this.pageNumber}&prevName=${encodeURIComponent(this.viewName)}`,
            );
          } else {
            this.router.navigateByUrl(
              DepositAccountRouterUrl +
                `${this.viewId}/${this.memberId}?currentRowIndex=${currentRowIndex}&pageSize=${
                  this.pageSize
                }&pageNumber=${this.pageNumber}&prevName=${encodeURIComponent(
                  this.viewName,
                )}&prevUrl=${encodeURIComponent(this.viewUrl)}`,
            );
          }
        }
      });
  }

  onAddNewAccount() {
    this.dialog.open(UpsertDepositAccountComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
      },
    });
  }
}
