import { createAction, props } from '@ngrx/store';

import { generateActionName } from '@ptg-shared/utils/common.util';
import {
  CreateGeneralAdjustmentParams,
  CreateGeneralAdjustmentRequest,
  CreateGeneralAdjustmentResponse,
  GetDeductionTypeRequest,
  GetDeductionTypeResponse,
  ValidateGeneralAdjustmentParams,
  ValidateGeneralAdjustmentRequest,
  ValidateGeneralAdjustmentResponse,
} from '../../services/models/create-general-adjustment.model';

// Actions for Getting deduction type data for Create General Adjustment
export const getGetDeductionTypesAction = createAction(
  generateActionName('CreateGeneralAdjustment GetDeductionTypes/API').Send,
  props<{ request: GetDeductionTypeRequest }>(),
);
export const getGetDeductionTypesSuccess = createAction(
  generateActionName('CreateGeneralAdjustment GetDeductionTypes/API').Success,
  props<{ response: GetDeductionTypeResponse }>(),
);
export const getGetDeductionTypesFailure = createAction(
  generateActionName('CreateGeneralAdjustment GetDeductionTypes/API').Failure,
  props<{ error: any }>(),
);
export const clearGetDeductionTypesState = createAction(
  generateActionName('CreateGeneralAdjustment GetDeductionTypes').Clear,
);

// Actions for Validating a new General Adjustment
export const getValidateGeneralAdjustmentAction = createAction(
  generateActionName('CreateGeneralAdjustment ValidateGeneralAdjustment/API').Send,
  props<{ params: ValidateGeneralAdjustmentParams, body: ValidateGeneralAdjustmentRequest }>(),
);
export const getValidateGeneralAdjustmentSuccess = createAction(
  generateActionName('CreateGeneralAdjustment ValidateGeneralAdjustment/API').Success,
  props<{ response: ValidateGeneralAdjustmentResponse }>(),
);
export const getValidateGeneralAdjustmentFailure = createAction(
  generateActionName('CreateGeneralAdjustment ValidateGeneralAdjustment/API').Failure,
  props<{ error: any }>(),
);
export const clearValidateGeneralAdjustmentState = createAction(
  generateActionName('CreateGeneralAdjustment ValidateGeneralAdjustment').Clear,
);

// Actions for Adding a new General Adjustment
export const getAddGeneralAdjustmentAction = createAction(
  generateActionName('CreateGeneralAdjustment AddGeneralAdjustment/API').Send,
  props<{ params: CreateGeneralAdjustmentParams; body: CreateGeneralAdjustmentRequest }>(),
);
export const getAddGeneralAdjustmentSuccess = createAction(
  generateActionName('CreateGeneralAdjustment AddGeneralAdjustment/API').Success,
  props<{ response: CreateGeneralAdjustmentResponse }>(),
);
export const getAddGeneralAdjustmentFailure = createAction(
  generateActionName('CreateGeneralAdjustment AddGeneralAdjustment/API').Failure,
  props<{ error: any }>(),
);
export const clearAddGeneralAdjustmentState = createAction(
  generateActionName('CreateGeneralAdjustment AddGeneralAdjustment').Clear,
);
