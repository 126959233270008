import { createSelector } from '@ngrx/store';
import { selectFileModuleState } from './selector';

export const documentListConfigurationSelector = createSelector(
  selectFileModuleState,
  (state) => state?.documentListConfiguration,
);

// Get Document List Configuration Properties
export const getDocumentListConfigurationPropertiesSelector = createSelector(
  documentListConfigurationSelector,
  (state) => state?.getDocumentListConfigurationPropertiesState
);

// Get Document List Configuration Detail
export const getDocumentListConfigurationDetailSelector = createSelector(
  documentListConfigurationSelector,
  (state) => state?.getDocumentListConfigurationDetailState
);

// Update Document List Configuration
export const updateDocumentListConfigurationSelector = createSelector(
  documentListConfigurationSelector,
  (state) => state?.updateDocumentListConfigurationState
);