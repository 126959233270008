import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const paymentNoteMemoDetailSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.noteMemoState
);

export const getNotesState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.payeeNotesDetail
);

export const createNotesState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.createPayeeNotes
);

export const updateNotesState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.updatePayeeNotes
);

export const checkEditPDState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.checkEditButtonPD
);

export const checkEditBPState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.checkEditButtonBP
);

export const editPayableDateState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.editPayableDate
);

export const editBenefitPeriodState = createSelector(
  paymentNoteMemoDetailSelector,
  (state) => state.editBenefitPeriod
);
