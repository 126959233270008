import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerService } from '@ptg-shared/services/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: LoggerService) {}

  handleError(error: any): void {
    console.error(error);
    this.logger.error(error?.toString());

    // Note: Fix bug 99439 (Redeploy causes this bug)
    if (error?.rejection?.name === 'ChunkLoadError') {
      window.location.reload();
      return;
    }
  }
}
