import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NON_VALUE_PROPERTY_DISPLAY_TYPE, PROPERTY_DISPLAY_PREFERENCE_TYPE } from '../../types/enums';

import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { CardProperty } from '@ptg-entity-management/services/models';

@Component({
  selector: 'ptg-member-summary-card-column',
  templateUrl: './member-summary-card-column.component.html',
  styleUrls: ['./member-summary-card-column.component.scss'],
})
export class MemberSummaryCardColumnComponent implements OnInit, OnChanges {
  readonly PROPERTY_DISPLAY_PREFERENCE_TYPE = PROPERTY_DISPLAY_PREFERENCE_TYPE;
  readonly NON_VALUE_PROPERTY_DISPLAY_TYPE = NON_VALUE_PROPERTY_DISPLAY_TYPE;
  readonly EntityPropertyType = EntityPropertyType;

  @Input() propertiesConfig: CardProperty[] = [];
  @Input() cardData: any;
  @Input() configDataless: any;
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.propertiesConfig.forEach(property => {
      if (property?.type === EntityPropertyType.Decimal || property?.type === EntityPropertyType.Currency || property?.type === EntityPropertyType.Percentage) {
        let fractionalLength = property?.config?.fractionalLengthInput;
        if (!fractionalLength && property?.config?.calcucation) {
          let configCalculator = JSON.parse(property?.config?.calcucation);
          fractionalLength = configCalculator?.fractionalLength;
          property.config.fractionalLengthInput = !fractionalLength && fractionalLength !== 0 ? 2 : fractionalLength;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  checkDisplayAddressField(property: CardProperty) {
    // 131859: Also fix 117059
    // If no data, use Property Display Preference config
    if (this.cardData?.cardNoData) {
      return this.configDataless.propertyDisplayPreferenceType !== PROPERTY_DISPLAY_PREFERENCE_TYPE.Hide;
    }
    // If has data and Non-Value Property Display is Label Only, always show property
    if (this.configDataless.nonValuePropertyDisplayType === NON_VALUE_PROPERTY_DISPLAY_TYPE.DisplayLabelOnly) {
      return true;
    }
    // If has data and Non-Value Property Display is Hide
    if (this.configDataless.nonValuePropertyDisplayType === NON_VALUE_PROPERTY_DISPLAY_TYPE.Hide) {
      // If is address, check if address is empty
      if (property.type === EntityPropertyType.Address) {
        return property.value?.city || property.value?.country || property.value?.state || property.value?.street1 || property.value?.street2 || property.value?.zipCode;
      }
      // If property is not address and has no value, hide
      return false;
    }
    // Default
    return true
  }

  onClickIcon(property: CardProperty) {
    if (property.isMasked) {
      property.value = property.valueMask.originalValue;
      property.isMasked = false;
    } else {
      property.value = property.valueMask.maskedValue;
      property.isMasked = true;
    }
  }

  showNull4Address(value: any) {
    return value && ['city', 'country', 'state', 'street1', 'street2', 'zipCode'].every(i => value[i] === null) ? '-' : '';
  }

  noDataAddress(propertyDisplay: number, type: any){
    return propertyDisplay === NON_VALUE_PROPERTY_DISPLAY_TYPE.Hide && type === EntityPropertyType.Address
  }
}
