import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';


export interface RadioOption {
  label: string;
  value: string | number | boolean;
}

@Component({
  selector: 'ptg-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit, OnChanges {
  @Input() controlField!: AbstractControl | any;
  @Input() listOption: RadioOption[] | string[] = [];
  @Input() isDisabled?: boolean;
  @Input() isObject?: boolean;
  @Input() isCustomChangeValue: boolean = false;
  @Input() classInput: string = '';
  @Output() changeValue = new EventEmitter();
  @Output() changeValueOption = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isDisabled && changes.isDisabled.currentValue) {
      this.controlField?.disable();
    } else if (changes && changes.isDisabled && !changes.isDisabled.currentValue) {
      this.controlField?.enable();
    }
  }

  onChangeValue(event: any) {
    this.changeValue.emit(event.value);
  }

  onChangeValueOption(event: RadioOption) {
    if (event.value || this.isDisabled) return;
    this.changeValueOption.emit();
  }
}
