import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentInfoAdjustmentType, PaymentInstructionType } from '@ptg-member/features/payee-detail/types/enums';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GetPaymentEarningDetailParams, GetPaymentEarningDetailRequest, PaymentEarningsDetailResponse } from './models';
import { PaymentDeductionsDetailResponse } from './models/payment-deductions.model';
import {
  PaymentAnnualTotalsParams,
  PaymentAnnualTotalsRequest,
  PaymentAnnualTotalsResponse,
} from './models/payment-annual-totals.model';

@Injectable()
export class PaymentDetailsService {
  constructor(private httpClient: HttpClient) {}

  getPaymentEarningDetailInfo(
    request: GetPaymentEarningDetailRequest,
    parameters?: GetPaymentEarningDetailParams,
  ): Observable<PaymentEarningsDetailResponse> {
    let params = new HttpParams();
    if (parameters?.paymentInstructionHistoryId) {
      params = params.append('paymentInstructionHistoryId', parameters.paymentInstructionHistoryId);
    }

    if (parameters?.instructionStatusHistoryId) {
      params = params.append('instructionStatusHistoryId', parameters.instructionStatusHistoryId);
    }

    return this.httpClient.get<PaymentEarningsDetailResponse>(
      `${environment.apiUrl}/new-payroll/earning-item/${request.paymentId}/benefit-option/${request.benefitTypeOptionId}/section/${request.paymentInfoAdjustmentType}`,
      { params },
    );
  }

  getPaymentDeductionsDetailInfo(
    paymentInstructionId: string,
    memberId: string,
    paymentInstructionHistoryId: string,
    instructionStatusHistoryId: string,
    paymentInfoAdjustmentType?: PaymentInfoAdjustmentType | PaymentInstructionType,
  ): Observable<PaymentDeductionsDetailResponse> {
    let params = new HttpParams();
    if (paymentInstructionHistoryId) {
      params = params.append('paymentInstructionHistoryId', paymentInstructionHistoryId);
    }
    if (instructionStatusHistoryId) {
      params = params.append('instructionStatusHistoryId', instructionStatusHistoryId);
    }
    return this.httpClient.get<PaymentDeductionsDetailResponse>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${paymentInstructionId}/member/${memberId}/view-deductions/${paymentInfoAdjustmentType}`,
      { params },
    );
  }

  getPaymentAnnualTotals(
    request: PaymentAnnualTotalsRequest,
    parameters?: PaymentAnnualTotalsParams,
  ): Observable<PaymentAnnualTotalsResponse> {
    let params = new HttpParams();
    if (parameters?.paymentInstructionHistoryId) {
      params = params.append('paymentInstructionHistoryId', parameters.paymentInstructionHistoryId);
    }
    if (parameters?.payrollBenefitAdjustmentId) {
      params = params.append('payrollBenefitAdjustmentId', parameters.payrollBenefitAdjustmentId);
    }
    if (parameters?.memberId) {
      params = params.append('memberId', parameters.memberId);
    }
    return this.httpClient.get<PaymentAnnualTotalsResponse>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${request.paymentInstructionId}/annual-total-section`,
      { params },
    );
  }
}
