<div
  class="p-4"
  [ngClass]="{
    'payment-annual-totals-container': !isLoading,
    'payment-annual-totals-container-closed move-up': !isShowPaymentDetail && !isLoading,
    'move-down': isShowPaymentDetail && !isLoading
  }"
>
  <ng-container *ngIf="!isLoading; else loadingBlock">
    <div class="flex justify-content-space">
      <div class="flex gap-8 items-center">
        <div class="title">Annual Totals</div>
      </div>
      <div class="flex gap-8 items-center">
        <div class="toggle-payment-annual-totals">
          <button (click)="controlToggle()">
            <ng-container *ngIf="isShowPaymentDetail; else arrowDown">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </ng-container>
            <ng-template #arrowDown>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-template>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isShowPaymentDetail">
      <ng-container *ngIf="hasAmounts; else noDataTemplate">
        <div class="annual-totals-content">
          <div class="earnings-section">
            <ng-template [ngTemplateOutlet]="earningsTemplate"></ng-template>
            <div class="item-container flex justify-content-space align-items-center">
              <div class="item-text">Gross</div>
              <div>{{ annualTotals?.gross || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            </div>
          </div>
          <div class="deductions-section">
            <ng-template [ngTemplateOutlet]="deductionsTemplate"></ng-template>
            <div class="item-container flex justify-content-space align-items-center">
              <div class="item-text">Total Deductions</div>
              <div>{{ annualTotals?.totalDeduction || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #noDataTemplate>
  <div class="annual-totals-content">
    <div class="item-container">No data to display</div>
  </div>
</ng-template>

<ng-template #loadingBlock>
  <div class="spinner-wrapper" [class.active]="isLoading">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>

<!-- EARNINGS -->
<ng-template #earningsTemplate>
  <div class="detail-title">Earnings</div>
  <ng-container *ngFor="let item of annualTotals?.earningItems">
    <div class="item-container">
      <div class="item-earning flex justify-content-space align-items-center">
        <div class="item-text">{{ item.name }}</div>
        <div>{{ item.amount || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
      </div>
      <ng-container *ngIf="(item?.fundingSources ?? []).length > 1">
        <ng-container *ngFor="let fund of item?.fundingSources">
          <div class="item-fund flex justify-content-space align-items-center">
            <div class="item-text">{{ fund.fundingSourceName }}</div>
            <div>{{ fund.amount || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- DEDUCTIONS -->
<ng-template #deductionsTemplate>
  <div class="detail-title">Deductions</div>
  <ng-container *ngFor="let item of annualTotals?.deductionItems">
    <div *ngIf="!!item?.amount" class="item-container flex justify-content-space align-items-center">
      <div class="item-text">{{ item.name }}</div>
      <div>{{ item?.amount | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
    </div>
  </ng-container>
</ng-template>
