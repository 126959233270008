<mat-form-field
  *ngIf="controlField"
  class="input-form-field {{ class }}"
  [ngClass]="{ 'invalid-range': showError && errorMinMaxValue && errorMinMax && (controlField?.errors?.min || controlField?.errors?.max) }"
  appearance="fill"
  [style.width]="width"
  [ngSwitch]="inputType"
  floatLabel="always"
>
  <mat-label 
    *ngIf="hasLabel" 
    [ngClass]="[
      (controlField?.hasValidator(Validators.required) || shouldShowRequiredIndicator)
        ? REQUIRED_INDICATOR
        : '',
    ]"
    >{{ placeholder }}
  </mat-label>
  <span
    class="w-full whitespace-nowrap overflow-hidden text-ellipsis cursor-text"
    [ngClass]="{
      'inline-block visible':
        inputType === InputType.TYPE_CURRENCY && isAccounting && previewAccountingValueOnBlur && controlField?.value < 0
    }"
  >
    {{ previewAccountingValueOnBlur }}
  </span>
  <input
    #inputField
    [id]="inputId"
    *ngSwitchCase="InputType.TYPE_CURRENCY"
    matInput
    currencyMask
    [formControl]="controlField"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
    autocomplete="off"
    [options]="{
      align: 'left',
      inputMode: currencyMaskInputMode.NATURAL,
      allowNegative: allowNegativeNumbers,
      nullable: true,
      suffix,
      prefix,
      precision
    }"
    [class.hidden]="
      inputType === InputType.TYPE_CURRENCY && isAccounting && previewAccountingValueOnBlur && controlField?.value < 0
    "
    (blur)="onBlurInput($event)"
    (focus)="onFocusField()"
    (ngModelChange)="onValueChange($event)"
    (focusout)="onFocusOut($event)"
  />
  <input
    #inputField
    [id]="inputId"
    *ngSwitchCase="InputType.TYPE_PERCENTAGE"
    matInput
    (focusout)="onValueChange($event)"
    currencyMask
    [formControl]="controlField"

    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
    autocomplete="off"
    [options]="{
      align: 'left',
      inputMode: currencyMaskInputMode.NATURAL,
      allowNegative: allowNegativeNumbers,
      nullable: true,
      prefix,
      precision
    }"
    (blur)="onBlurInput()"
    (focus)="onFocusField()"
  />
  <ng-container *ngSwitchCase="InputType.TYPE_NUMBER">
    <input
      #inputField
      [id]="inputId"
      *ngIf="!isNumberOnly"
      matInput
      type="text"
      ptgNumberOnly
      [formControl]="controlField"
      [placeholder]="hasLabel ? '' : placeholder"
      [maxlength]="maxLength ? maxLength : 'none'"
      [isPositive]="isPositive"
      [isDecimal]="isDecimal"
      [allowZero]="allowZero"
      [isStrict]="isStrict"
      (focus)="onFocusField()"
      (focusout)="onFocusOut($event)"
      (input)="onValueChange($event)"
      (blur)="onBlurInput()"
      (onPaste)="onPasteValueField($event)"
      (keydown)="onKeydownEnter($event)"
    />
    <input
      #inputField
      [id]="inputId"
      *ngIf="isNumberOnly"
      matInput
      type="text"
      ptgNumbersOnlySpec
      [formControl]="controlField"
      [placeholder]="hasLabel ? '' : placeholder"
      [maxlength]="maxLength ? maxLength : 'none'"
      (focus)="onFocusField()"
      (focusout)="onFocusOut($event)"
      (input)="onValueChange($event)"
      (blur)="onBlurInput()"
      (onPaste)="onPasteValueField($event)"
      (keydown)="onKeydownEnter($event)"
    />
  </ng-container>

  <input
    #inputField
    [id]="inputId"
    *ngSwitchCase="InputType.TYPE_DECIMAL"
    matInput
    type="text"
    [mask]="mask"
    [thousandSeparator]="thousandSeparator"
    [allowNegativeNumbers]="allowNegativeNumbers"
    [formControl]="controlField"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
    (focus)="onFocusField()"
    (focusout)="onFocusOut($event)"
    (input)="onValueChange($event)"
    [validation]="isValidation"
  />
  <input
    #inputField
    *ngSwitchCase="InputType.TYPE_EMAIL"
    matInput
    [mask]="maskExpression"
    [patterns]="customPatterns"
    [hiddenInput]="true"
    [dropSpecialCharacters]="false"
    [specialCharacters]="['•']"
    [validation]="false"
    [id]="inputId"
    type="text"
    [formControl]="controlField"
    (blur)="trimValue()"
    (input)="onValueChange($event)"
    (focus)="onFocusField()"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
  />
  <input
    #inputField
    *ngSwitchCase="
      inputType === InputType.TYPE_PHONE || inputType === InputType.TYPE_SSN
        ? inputType
        : 'none'
    "
    matInput
    [mask]="maskExpression"
    [hiddenInput]="this.isShowIconMasked && this.isMasked"
    [dropSpecialCharacters]="false"
    [validation]="false"
    [id]="inputId"
    type="text"
    [formControl]="controlField"
    (blur)="trimValue()"
    (input)="onValueChange($event)"
    (focus)="onFocusField()"
    (focusout)="onFocusOut($event)"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
  />
  <input
    #inputField
    *ngSwitchCase="InputType.TYPE_EIN"
    matInput
    [id]="inputId"
    type="text"
    [formControl]="controlField"
    (blur)="trimValue()"
    (input)="onValueChange($event)"
    (focus)="onFocusField()"
    (focusout)="onFocusOut($event)"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
  />
  <input
    #inputField
    *ngSwitchCase="InputType.TYPE_PASS_WORD"
    matInput
    [mask]="maskExpression"
    [patterns]="customPatterns"
    [hiddenInput]="true"
    [dropSpecialCharacters]="false"
    [specialCharacters]="['•']"
    [validation]="false"
    [id]="inputId"
    type="text"
    [formControl]="controlField"
    (blur)="trimValue()"
    (input)="onValueChange($event)"
    (focus)="onFocusField()"
    (focusout)="onFocusOut($event)"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
  />
  <input
    #inputField
    [id]="inputId"
    *ngSwitchCase="'CustomNumber'"
    matInput type="text"
    ptgNumberOnly
    [formControl]="controlField"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
    [isPositive]="isPositive"
    [isDecimal]="isDecimal"
    [allowZero]="allowZero"
    [isStrict]="isStrict"
    (focus)="onFocusField()"
    oninput="this.value = !!this.value && this.value >= (min ? min : 0) ? this.value : null"
    (focusout)="onFocusOut($event)"
    (input)="onValueChange($event)"
    (blur)="onBlurInput()"
  />
  <input
    #inputField
  *ngSwitchCase="InputType.TYPE_ZIP_CODE"
    matInput
    type="text"
    ptgInputZipCode
    [formControl]="controlField"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
    (focus)="onFocusField()"
    (focusout)="onFocusOut($event)"
    (input)="onValueChange($event)"
    (onPaste)="onPasteValueField($event)"
  />
  <input
    #inputField
    *ngSwitchDefault
    #defaultInputField
    matInput
    [id]="inputId"
    type="text"
    [formControl]="controlField"
    (blur)="trimValue()"
    (input)="onValueChange($event)"
    (focus)="onFocusField()"
    (focusout)="onFocusOut($event)"
    [placeholder]="hasLabel ? '' : placeholder"
    [maxlength]="maxLength ? maxLength : 'none'"
  />
  <span *ngIf="inputType !== InputType.TYPE_CURRENCY && suffix" matSuffix>
    {{ suffix }}
  </span>
  <span *ngIf="customPrefix" matPrefix>
    {{ customPrefix }}
    </span>
  <mat-hint
    *ngIf="isShowHintText && !controlField?.errors && focused"
    class="helpText"
  >
    {{hintText}}
  </mat-hint>
  <mat-icon
    *ngIf="icon"
    matSuffix
    (click)="onClickIcon()"
    [ngClass]="{ clickable: this.isShowIconMasked }"
  >
    {{ icon }}
  </mat-icon>
  <mat-error
    [id]="matErrorId"
    *ngIf="showError && controlField?.errors?.required"
  >{{ errorRequire || placeholder  +  ' is required.' }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="
      showError &&
      controlField?.errors?.pattern &&
      !controlField?.errors?.maxlength
    "
  >{{ errorPattern }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="showError && controlField?.errors?.inValidAsync"
  >
    {{ errorAsync ? errorAsync : controlField.getError("inValidAsync") }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="showError && controlField?.errors?.maxlength && !errorMinMaxValue && !maxErrorMessage"
  >{{ errorMaxLength }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="showError && controlField?.errors?.[customError] && !controlField?.errors?.required"
  >{{ controlField.getError([customError]) }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="
      showError &&
      controlField?.errors?.errorArray &&
      !controlField?.errors?.required &&
      !controlField?.errors?.min &&
      !controlField?.errors?.max
    "
  >{{ errorArray }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="
      showError &&
      !errorMinMaxValue &&
      !controlField?.errors?.pattern &&
      errorMinMax &&
      controlField?.errors?.min "
      >{{minErrorMessage}}
  </mat-error>
  <mat-error
      [id]="matErrorId"
      *ngIf="
        showError &&
        !errorMinMaxValue &&
        !controlField?.errors?.pattern &&
        errorMinMax &&
        controlField?.errors?.max"
      >{{maxErrorMessage}}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="
      showError &&
      errorMinMaxValue &&
      errorMinMax &&
      (controlField?.errors?.min || controlField?.errors?.max)
    "
  >{{ errorMinMaxValue }}
  </mat-error>
  <mat-error
    [id]="matErrorId"
    *ngIf="showError && controlField?.errors?.duplicated"
  >{{ errorDuplicated }}
  </mat-error>
</mat-form-field>
