import { createAction, props } from "@ngrx/store";
import { generateActionName } from "@ptg-shared/utils/common.util";
import { OffsetDeductionPayload } from "../../services/models/create-offset-deduction.model";

export const createOffsetDeductionAction = createAction(
  generateActionName('CreateOffsetDeduction/API').Send,
  props<{ body: OffsetDeductionPayload }>()
);
export const createOffsetDeductionSuccessAction = createAction(
  generateActionName('CreateOffsetDeduction/API').Success,
);
export const createOffsetDeductionFailureAction = createAction(
  generateActionName('CreateOffsetDeduction/API').Failure,
  props<{ error?: any }>()
);

export const clearCreateOffsetDeductionStateAction = createAction(
  generateActionName('CreateOffsetDeduction/API').Clear,
);
