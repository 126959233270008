<div id="loading-check">
  <div class="wrap-item" *ngIf="!checkPermissionService.isNoPermission">
    <mat-progress-spinner diameter="50" mode="indeterminate">
    </mat-progress-spinner>
    <p>Loading...</p>
  </div>
  <div *ngIf="checkPermissionService.isNoPermission" class="message">
    You do not have any permission.
  </div>
</div>
