<div class="header-metadata page-header" [ngClass]="{ 'header-loading': isLoading }">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <div class="left-group" *ngIf="!targetIdFromDialog && isShowInteractionComponents">
      <div class="option">
        <button
          mat-button
          class="option-icon"
          (click)="changeBenefitOptionByNavigator()"
          [disabled]="navigationDisabled.previous"
        >
          <mat-icon style="color: #828282">expand_less</mat-icon>
        </button>
        <button
          mat-button
          class="option-icon"
          [disabled]="navigationDisabled.next"
          (click)="changeBenefitOptionByNavigator(true)"
        >
          <mat-icon style="color: #828282">expand_more</mat-icon>
        </button>
      </div>
    </div>
    <div class="main-group" [ngClass]="{ 'main-group-dialog': !!targetIdFromDialog }">
      <div class="left-main-group">
        <ng-container *ngIf="headerItems.length">
          <div class="demographics-data" *ngFor="let item of headerItems">
            <span class="label">{{ item.propertyName }}</span>
            <ng-container [ngSwitch]="item?.type">
              <span class="value" *ngSwitchCase="EntityPropertyType.Currency">{{
                (item.value | numberLocalDecimal: '$') || '-'
              }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Percentage"
                >{{ (item.value | numberLocalDecimal: '') || '-'
                }}{{ (item.value | numberLocalDecimal: '') ? '%' : '' }}</span
              >
              <span class="value" *ngSwitchCase="EntityPropertyType.Date">{{
                !item.value ? '-' : (item.value | date: 'MM/dd/yyyy')
              }}</span>
              <span
                *ngSwitchCase="EntityPropertyType['Person Name']"
                class="value"
                [class.link]="!targetIdFromDialog && item?.recordLinkedId"
                (click)="!targetIdFromDialog && gotoPaymentInfo(item)"
              >
                {{ !item.value ? '-' : (item.value | personName: item.options) }}
              </span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Address"
                >{{ checkNull4Address(item.value) ? '-' : (item.value | address: item.options) }}&nbsp;</span
              >
              <span class="value" *ngSwitchCase="EntityPropertyType.Decimal">{{
                !item.value && item.value !== 0
                  ? '-'
                  : (item.value
                    | numberDecimal
                      : {
                          decimal: getFractionalLengthDecimal(item?.configs)
                        })
              }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType['Whole Number']">{{
                (item.value | numberDecimal) || '-'
              }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Binary">{{
                item?.value === null || item?.value === undefined
                  ? '-'
                  : item?.value
                    ? item.configs.affirmative
                    : item.configs.negative
              }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Employer">{{ item.value || '-' }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Tier">{{
                (item?.value | propertyList: item.options) || '-'
              }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Lookup">{{
                (item?.value | propertyList: item.options) || '-'
              }}</span>
              <span class="value" *ngSwitchCase="EntityPropertyType.Status">
                <div class="icon-status-container flex">
                  <mat-icon
                    [matTooltip]="item.value?.tooltip ?? ''"
                    matTooltipClass="custom-tooltip with-arrow"
                    *ngIf="item.value?.icon"
                    class="icon-status"
                    [ngStyle]="{ color: item.value?.color }"
                    >{{ item.value?.icon }}</mat-icon
                  >
                  <div class="status-name">
                    {{ !item.value?.name ? '-' : item.value.name }}
                  </div>
                </div>
              </span>
              <span class="value" *ngSwitchCase="EntityPropertyType.SSN">{{
                item.value ? (item.value | ssnNumber) : '-'
              }}</span>
              <ng-container *ngSwitchCase="EntityPropertyType.RichText">
                <ptg-view-rich-text
                  [title]="item?.propertyName"
                  [content]="item.value"
                  [defaultContent]="'-'"
                ></ptg-view-rich-text>
              </ng-container>
              <span class="value" *ngSwitchCase="EntityPropertyType['Date Time']">{{
                !item.value ? '-' : (item?.value | date: 'MM/dd/yyyy hh:mm a')
              }}</span>
              <span class="value" *ngSwitchDefault>{{ item.value || '-' }}</span>
            </ng-container>
          </div>
        </ng-container>
        <ng-container>
          <div class="demographics-data" *ngIf="headerBenefits?.length && headerBenefits?.length === 1">
            <span class="label">Benefit</span>
            <span class="value">{{ headerBenefits[0].displayValue || '-' }}</span>
          </div>
          <div
            class="demographics-data"
            *ngIf="(headerBenefits?.length || 0) > 1 && targetIdFromDialog && !isShowInteractionComponents"
          >
            <span class="label">Benefit</span>
            <span class="value">{{ selectedBenefitName }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="customData">
          <div *ngFor="let item of customData" class="demographics-data">
            <span class="label">{{ item.label }}</span>
            <span class="value">{{ item.displayValue || '-' }}</span>
          </div>
        </ng-container>
      </div>
      <div
        class="right-main-group"
        *ngIf="headerBenefits?.length && headerBenefits?.length !== 1 && isShowInteractionComponents"
      >
        <div class="header-button">
          <ptg-select
            [controlField]="headerIdFormControl"
            [listData]="headerBenefits"
            [isOptionObj]="true"
            placeholder="Benefit"
            (changeOptionValue)="getBenefitOptionChange()"
            [isSetMaxWidthSelect]="true"
          ></ptg-select>
        </div>
      </div>
    </div>
    <div class="right-group"></div>
  </ng-container>

  <ng-template #loadingTemplate>
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </ng-template>
</div>
