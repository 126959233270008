<div *ngIf="!isLoading; else notLoading" class="payroll-settings-container">
  <div class="payroll-settings-header">
    <div class="left-title">
      <span class="name">Payroll Settings</span>
    </div>
    <div
      *ngIf="
        selectedRow?.payStatus !== PayStatus.Finalized &&
        payrollSettings?.isEditable &&
        selectedTabPayment === TabPaymentInfo.Payment
      "
      class="edit-btn"
      (click)="onClickEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
    <!-- Out scope in release 1 -->
    <!-- <div *ngIf="selectedTabPayment === TabPaymentInfo.InstructionHistory" class="edit-btn">
      <mat-icon>history</mat-icon>
      <span class="title-edit">Audit</span>
    </div> -->
  </div>
  <div class="bottom-content">
    <p *ngIf="selectedRow?.estateId">
      <span class="label">Payee </span>
      <span class="value">{{ payrollSettings?.payeeName || (payrollSettings?.payee | personName) || '-' }}</span>
    </p>
    <p>
      <span class="label">Payment Address </span>
      <ptg-view-address
        [value]="payrollSettings?.paymentAddress?.isActive ? payrollSettings?.paymentAddress : undefined"
        customClass="text-end"
      ></ptg-view-address>
    </p>
    <p>
      <span class="label">Representative Payee </span>
      <span class="value">{{ (payrollSettings?.representativePayee | personName) || '-' }}</span>
    </p>
  </div>
  <div class="payroll-settings-content">
    <div *ngIf="payrollSettings?.isFederalOn" class="column-value federal" style="margin-bottom: 16px">
      <div class="title">Federal</div>
      <p>
        <span class="label">Federal W/H </span>
        <span class="value">{{
          payrollSettings?.federalWithholding ? payrollSettings?.federalWithholdingLabel : '-'
        }}</span>
      </p>
      <p>
        <span class="label">Federal Add. W/H </span>
        <span *ngIf="payrollSettings?.federalAddWithholdingType === FlatType.Flat" class="value">
          {{
            !payrollSettings?.federalAddWithholdingValue
              ? '-'
              : (payrollSettings?.federalAddWithholdingValue | numberLocalDecimal: '$' : { decimal: 2 })
          }}
        </span>
        <span *ngIf="payrollSettings?.federalAddWithholdingType === FlatType.Percent" class="value">
          {{ (payrollSettings?.federalAddWithholdingValue | numberLocalDecimal: '') + '%' }}
        </span>
        <span *ngIf="payrollSettings?.federalAddWithholdingType === FlatType.None" class="value">-</span>
      </p>
      <ng-container *ngIf="payrollSettings?.federalForm === FederalTableFormType.W4">
        <p>
          <span class="label">Federal Exemption </span>
          <span class="value">
            {{
              !payrollSettings?.federalExemption
                ? '-'
                : (payrollSettings?.federalExemption | numberLocalDecimal: '' : { decimal: 0 })
            }}
          </span>
        </p>
      </ng-container>
      <ng-container *ngIf="payrollSettings?.federalForm === FederalTableFormType.W4P">
        <p>
          <span class="label">Federal Income </span>
          <span class="value">
            {{
              !payrollSettings?.federalIncome
                ? '-'
                : (payrollSettings?.federalIncome | numberLocalDecimal: '$' : { decimal: 2 })
            }}
          </span>
        </p>
        <p>
          <span class="label">Federal Claim Dep. </span>
          <span class="value">
            {{
              !payrollSettings?.federalClaimDep
                ? '-'
                : (payrollSettings?.federalClaimDep | numberLocalDecimal: '$' : { decimal: 2 })
            }}
          </span>
        </p>
        <p>
          <span class="label">Federal Add. Income </span>
          <span class="value">
            {{
              !payrollSettings?.federalAddIncome
                ? '-'
                : (payrollSettings?.federalAddIncome | numberLocalDecimal: '$' : { decimal: 2 })
            }}
          </span>
        </p>
        <p>
          <span class="label">Federal Add. Deductions </span>
          <span class="value">
            {{
              !payrollSettings?.federalAddDeductions
                ? '-'
                : (payrollSettings?.federalAddDeductions | numberLocalDecimal: '$' : { decimal: 2 })
            }}
          </span>
        </p>
      </ng-container>
    </div>
    <div *ngIf="payrollSettings?.isStateOn" class="column-value state">
      <div class="title">State</div>
      <p>
        <span class="label">State W/H </span>
        <span class="value">
          {{ payrollSettings?.stateWithholding ? payrollSettings?.stateWithholdingLabel : '-' }}
        </span>
      </p>
      <p>
        <span class="label">State Add. W/H </span>
        <span *ngIf="payrollSettings?.stateAddWithholdingType === FlatType.Flat" class="value">
          {{
            !payrollSettings?.stateAddWithholdingValue
              ? '-'
              : (payrollSettings?.stateAddWithholdingValue | numberLocalDecimal: '$' : { decimal: 2 })
          }}
        </span>
        <span *ngIf="payrollSettings?.stateAddWithholdingType === FlatType.Percent" class="value">
          {{ (payrollSettings?.stateAddWithholdingValue | numberLocalDecimal: '') + '%' }}
        </span>
        <span *ngIf="payrollSettings?.stateAddWithholdingType === FlatType.None" class="value">-</span>
      </p>
      <ng-container *ngIf="payrollSettings?.stateForm === FederalTableFormType.W4">
        <p>
          <span class="label">State Exemption </span>
          <span class="value">
            {{
              !payrollSettings?.stateExemption
                ? '-'
                : (payrollSettings?.stateExemption | numberLocalDecimal: '' : { decimal: 2 })
            }}
          </span>
        </p>
      </ng-container>
      <ng-container *ngIf="payrollSettings?.stateForm === FederalTableFormType.W4P">
        <p>
          <span class="label">State Income </span>
          <span class="value">
            {{
              !payrollSettings?.stateIncome
                ? '-'
                : (payrollSettings?.stateIncome | numberLocalDecimal: '$' : { decimal: 2 })
            }}</span
          >
        </p>
        <p>
          <span class="label">State Claim Dep. </span>
          <span class="value">
            {{
              !payrollSettings?.stateClaimDep
                ? '-'
                : (payrollSettings?.stateClaimDep | numberLocalDecimal: '$' : { decimal: 2 })
            }}</span
          >
        </p>
        <p>
          <span class="label">State Add. Income </span>
          <span class="value">
            {{
              !payrollSettings?.stateAddIncome
                ? '-'
                : (payrollSettings?.stateAddIncome | numberLocalDecimal: '$' : { decimal: 2 })
            }}</span
          >
        </p>
        <p>
          <span class="label">State Add. Deductions </span>
          <span class="value">
            {{
              !payrollSettings?.stateAddDeductions
                ? '-'
                : (payrollSettings?.stateAddDeductions | numberLocalDecimal: '$' : { decimal: 2 })
            }}</span
          >
        </p>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #notLoading>
  <ptg-spinner classInput="p-6" [isLoading]="isLoading"></ptg-spinner>
</ng-template>
