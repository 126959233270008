import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { EMPLOYER_FIXED_PROPERTIES } from '@ptg-employer/constance/employer.const';
import { getDateFormatISO } from '@ptg-shared/utils/string.util';
import { EmployerCheckDateOfBirthRequest, EmployerCheckDisableRequest } from '@ptg-employer/models/employer.model';
import {
  ALLOW_SHOW_DIVORCE_DATE_WITH_RELATIONSHIP_VALUES,
  ALLOW_SHOW_MARRIAGE_DATE_WITH_RELATIONSHIP_VALUES,
  ENTITY_MAPPING,
  FixedPropertyKey,
  LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID,
  LIMIT_PHONE_NUMBER,
  LIST_INPUT_ADDRESS,
  LIST_INPUT_PERSON_NAME,
  LIST_INPUT_STATUS,
  PERSON_BASE_PROPERTIES,
  RELATIONSHIP_LIST_PROPERTY_FIXED_ID,
  JOB_HISTORY,
  StatusCourtOrder,
  UnitedStates,
  RELATIONSHIP_LIST,
  SERVICE_HISTORY_LIST,
  SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID,
} from '@ptg-entity-management/constants';

import { COURT_ORDER_TYPE_CODE, FixedPropertyId } from '@ptg-entity-management/constants/index';

import { EntityDataService, EntityPropertyService } from '@ptg-entity-management/services';
import {
  CheckSumOfPercentageValueRequest,
  CourtOrderQdroSelectedPayee,
  CourtOrderQdroSelectedPayeeOriginalPersonName,
  DetailOptionStatus,
  EntityInitiationPropertyValue,
  EntityReferenceData,
  InitiationProperty,
  LegalRelationshipTypeOption,
  PayeeOption,
  PayeeRequest,
  SearchEntityReferenceExceptRecord,
  ValidateMaritalRequest,
  ValidateMaritalResponse,
  ValidateReportedDeceasedResponse,
} from '@ptg-entity-management/services/models';
import {
  clearGetCourtOrderPayeeInfoStateAction,
  clearPayeePropertiesStateAction,
  getCourtOrderPayeeInfoAction,
  getPayeePropertiesAction,
} from '@ptg-entity-management/store/actions/entity-property.action';
import { getCourtOrderPayeeInfoSelector, getPayeePropertiesSelector } from '@ptg-entity-management/store/selectors/entity-property.selector';
import {
  CourtOrderType,
  EntityPropertyType,
  RelationshipOptions,
  StaticPropertyType,
} from '@ptg-entity-management/types/enums';
import { isEINProperty } from '@ptg-member/helper';
import { AddressHistoryAction, clearSetMemberEventStateAction } from '@ptg-member/store/actions';
import { BaseComponent } from '@ptg-shared/components';
import {
  BUTTON_LABEL_CLOSE,
  ENTITY_BENEFICIARIES_LIST_GUID,
  ENTITY_DEPENDENTS_LIST_GUID,
  ENTITY_ID_GUID,
  ENTITY_MEMBER_GUID,
  ENTITY_ORGANIZATION_GUID,
  ENTITY_PARTICIPANT_GUID,
  ENTITY_PERSON_GUID,
} from '@ptg-shared/constance';

import { ConfirmPopupCancelButtonTitle, ConfirmPopupSaveButtonTitle, ConfirmPopupTitle, ConfirmType, CustomPopupConfig, DefaultCustomPopupConfig } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType, LookupTableType, ValidationOperator, ValidationType } from '@ptg-shared/types/enums';
import {
  DateValidationValue,
  FieldData,
  FormControlDetail,
  OptionValue,
  PropValidation,
} from '@ptg-shared/types/models';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone, sortByCriteria, toCamelCase } from '@ptg-shared/utils/common.util';
import {
  getDateString,
  getValueWithoutFormat,
  isEmpty,
  isNullOrUndefinedOrEmptyString,
  isString,
  setFractionalLength,
  stringToBoolean,
} from '@ptg-shared/utils/string.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { checkUnique } from '@ptg-shared/validators/checkUnique.validator';
import { BehaviorSubject, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as fromMember from '../../../member/store/reducers';
import { CONFIRM_MSG_PREFIX, CONFIRM_MSG_SUFFIX, CourtOrderComponentId, CourtOrderPropertiesGUIDs, MARITAL_INFORMATION_VALIDATE_MSG, NEED_VALIDATE_CAN_NOT_AFTER_TODAY_FIELDS_FIXED_ID, NEED_WARNING_SHOULD_NOT_AFTER_TODAY_FIELDS_FIXED_ID, NEED_WARNING_SHOULD_NOT_BLANK_FIELDS_FIXED_ID, ValidateFor } from './upsert-entity-data-for-court-order.const';
import { DateTime } from 'luxon';
import { DATE_FORMAT_YMD, PROPERTY_PERSON_NAME_FIRST_KEY, PROPERTY_PERSON_NAME_LAST_KEY, PROPERTY_PERSON_NAME_MIDDLE_KEY, PROPERTY_PERSON_NAME_PREFIX_KEY, PROPERTY_PERSON_NAME_SUFFIX_KEY, REQUIRED_INDICATOR } from 'src/app/app.const';
import { EditPropertyEntityReferenceComponent } from '../edit-property-entity-reference/edit-property-entity-reference.component';

@Component({
  selector: 'ptg-upsert-entity-data',
  templateUrl: './upsert-entity-data.component.html',
  styleUrls: ['./upsert-entity-data.component.scss'],
})
export class UpsertEntityDataComponent extends BaseComponent implements AfterViewInit, OnChanges {
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;
  readonly EntityPropertyType = EntityPropertyType;
  readonly StaticPropertyType = StaticPropertyType;
  readonly RELATIONSHIP_LIST_PROPERTY_FIXED_ID = RELATIONSHIP_LIST_PROPERTY_FIXED_ID;
  readonly MY_DATE = MY_DATE;
  readonly FixedPropertyKey = FixedPropertyKey;
  readonly UnitedStates = UnitedStates;
  readonly CourtOrderType = CourtOrderType;
  readonly CourtOrderPropertiesGUIDs = CourtOrderPropertiesGUIDs;
  readonly CourtOrderStatus = deepClone(StatusCourtOrder);

  @ViewChildren('entityReferenceListOfRelationshipCard') entityReferenceListOfRelationshipCard!: EditPropertyEntityReferenceComponent[];
  @ViewChildren('entityReferencePropertyComponentList') entityReferencePropertyComponentList!: EditPropertyEntityReferenceComponent[];

  // Used for Court Order only
  valuePayeeSearch: string = '';
  searchPayee$ = new BehaviorSubject('');
  showClearSearchPayeeContent: boolean = false;
  searchPayeeResultList: CourtOrderQdroSelectedPayee[] = [];
  searchPayeeDisplayList: CourtOrderQdroSelectedPayee[] = [];
  selectedPayee: CourtOrderQdroSelectedPayee | null = null;
  showPayeeRequired: boolean = false;

  clearSearchPayeeContent(): void {
    this.valuePayeeSearch = '';
    this.showClearSearchPayeeContent = false;
    if (!this.isEdit) {
      this.selectedPayee = null;
      this.payeeControl.setValue(null);
    }
    this.clearPayeeInformationFormData();
    this.showPayeeRequired = false;
  }
  searchPayee(value: string): void {
    this.searchPayee$.next(value);
    this.showClearSearchPayeeContent = false;
    this.selectedPayee = null;
    this.payeeControl.setValue(null);
    this.clearPayeeInformationFormData();
    this.showPayeeRequired = false;

    if (value && value?.length > 1) {
      this.searchPayeeDisplayList = this.searchPayeeResultList?.filter((item: any) =>
        item?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
    }
  }
  selectPayee(option: any) {
    this.showClearSearchPayeeContent = true;
    this.selectedPayee = option;
    this.valuePayeeSearch = option?.name ?? '';
    this.showPayeeRequired = false;

    this.payeeControl.setValue(option?.id ?? null);
    this.handleWhenSelectPayee(option);

    this.memberStore.dispatch(
      getCourtOrderPayeeInfoAction({
        request: {
          entityId: ENTITY_PERSON_GUID,
          id: option?.id ?? '',
        }
      }),
    );
  }

  qdroValueId: string = '';
  garnishmentValueId: string = '';
  currentEditingCourtOrderQdroPayeeId: string = '';
  currentPayeeInformationNameObj: CourtOrderQdroSelectedPayeeOriginalPersonName | null = null;
  currentEditingCourtOrderStatus: string = deepClone(StatusCourtOrder.Pending);
  // End of used for Court Order only

  // Used for Relationship List Card only
  currentLegalRelationshipTypeChipList: LegalRelationshipTypeOption[] = [];
  currentLegalRelationshipTypeOptionList: LegalRelationshipTypeOption[] = [];
  relationshipLabel: string = '';
  // End of Used for Relationship List Card only
  isSearchAllEntityRef: boolean = false;

  @Input() properties!: InitiationProperty[];
  @Input() searchExceptRecords!: SearchEntityReferenceExceptRecord[];
  @Input() entityId!: string;
  @Input() data: any;
  @Input() cardInfor!: {
    id: string;
    isSummaryView: boolean;
    isList: boolean;
  };
  @Input() isSubmitting: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() isEditCard?: boolean = false;
  @Input() isEditListRecord?: boolean = false;
  @Input() targetId: string = '';
  @Input() screenId?: string;
  @Input() isEffectiveData: boolean = false;
  @Input() entityComponentId!: string;
  @Input() isCourtOrder: boolean = false;
  @Input() isRelationshipCard: boolean = false;
  @Input() isPayeeUsedInCourtOrder: boolean = false;
  @Input() initialRelationshipValueSelected: string = '';
  @Input() legalRelationshipTypeOptionList: LegalRelationshipTypeOption[] = [];
  @Input() legalRelationshipTypeChipList: LegalRelationshipTypeOption[] = [];
  @Input() isAtLeastOneRecordIsSpouse: boolean = false;
  @Input() isList!: boolean;
  @Output() editSectionEvent = new EventEmitter();
  @Output() addNewEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  formGroup: FormGroup = new FormGroup({});
  erFormGroup: any = {};
  erFormRadioButton: any = {};
  controls: FormControlDetail[] = [];
  erControls: any = {};
  erProperties: any = {};
  propertyReferences!: InitiationProperty[];
  listAllStatus: any[] = [];
  listStatusEvent: Option[] = [];
  selectedRecordId: any = {};

  isBVFFFund = this.layoutService.fundType === FundType.BVFF;

  currentMemberDetailUnmask: any;
  datePropValidation: PropValidation[] = [];
  dateValidationValue: DateValidationValue[] = [];
  currentInactivationDate = '';
  validatorPrevious: any = {};
  formSubmit$ = new Subject<boolean>();
  payeeId: string = '';
  spousePerson?: any;
  entityReal: string = '';
  currentRelationshipValue?: number;
  isSelectedSpouse!: boolean;
  relationshipLookup: any;
  isAddNew: boolean = false;
  isJobHistory: boolean = false;
  isServiceHistory: boolean = false;
  listMemberParticipants = [ENTITY_MEMBER_GUID, ENTITY_PERSON_GUID, ENTITY_PARTICIPANT_GUID];
  entityReferenceDatas: EntityReferenceData[] = [];

  constructor(
    private dialog: MatDialog,
    private entityPropertyService: EntityPropertyService,
    private entityDataService: EntityDataService,
    private memberStore: Store<fromMember.MemberState>,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currentLegalRelationshipTypeOptionList = deepClone(this.legalRelationshipTypeOptionList);
    this.currentLegalRelationshipTypeChipList = deepClone(this.legalRelationshipTypeChipList);
    this._loadPropertiesToForm(this.properties, this.data);

    // User for Job History -> Search all ER
    if (this.entityComponentId?.toUpperCase() == JOB_HISTORY.EntityComponentId.toUpperCase()) {
      this.isSearchAllEntityRef = true;
      this.isJobHistory = true;
    }
    // End of use for Job History only

    //ServiceHistory
    if (this.entityComponentId?.toUpperCase() == SERVICE_HISTORY_LIST.Id.toUpperCase()) {
      this.isSearchAllEntityRef = true;
      this.isServiceHistory = true;
    }
    //End ServiceHistory
    
    // Use for Court Order only
    if (this.isCourtOrder) {
      const courtOrderTypeCtrl = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.CourtOrderType);
      this.qdroValueId = courtOrderTypeCtrl?.data?.options?.find((item: any) => item?.code === COURT_ORDER_TYPE_CODE.QDRO)?.id ?? '';
      this.garnishmentValueId = courtOrderTypeCtrl?.data?.options?.find((item: any) => item?.code === COURT_ORDER_TYPE_CODE.Garnishment)?.id ?? '';
    }
    // End of use for Court Order only

    if (this.dateOfDeathControl && this.lineOfDutyDeathControl) {
      this._onChangeDateOfDeathValue();
    }
    if (this.newHireExamControl && this.newHireExamDateControl) {
      this._onChangeNewHireExamValue();
    }
    if (this.inactivationDateControl) {
      this._onChangeInactivationDateValue();
    }
    this.formatSSNAndDOBMember();
    this.onSubmit();
    this.getPayeeProperties();
    this.registerGetPayeeInfoSelector();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSubmitting) {
      this.isSubmitting = changes.isSubmitting?.currentValue;
    }
  }

  ngAfterViewInit() {
    if (this.data) {
      this._setValue(this.data);
      this.initFormCourtOrder();
    }
    
    // Case Relationship Card, Legal Relationship Type Dropdown should always blank when open Add/Edit screen in all cases
    if (this.isRelationshipCard && this.isList && this.legalRelationshipTypeControl) {
      this.legalRelationshipTypeControl.setValue(null);
    }
  }

  formatSSNAndDOBMember(){
    const isNotPerson = ENTITY_PERSON_GUID !== this.entityId;
    const idx = this.properties.findIndex(el => el.type === EntityPropertyType['Entity Reference']);
    if(idx === -1){
      this.properties = this.properties.map(elm => {
        const SSNAndDOBType = [PERSON_BASE_PROPERTIES.SSN, PERSON_BASE_PROPERTIES.DateOfBirth].includes(elm.entityPropertyId.toUpperCase());
          return {
            ...elm,
            configs: {
              ...elm.configs,
              required: SSNAndDOBType && isNotPerson && !this.isCourtOrder ? 'true' : elm.configs.required,
            }
          }
        });
      };
      this.controls = this.controls.map(el => {
        const SSNAndDOBType = [PERSON_BASE_PROPERTIES.SSN, PERSON_BASE_PROPERTIES.DateOfBirth].includes(el.name.toUpperCase());
        return {
          ...el,
          isRequired: SSNAndDOBType && isNotPerson && !this.isCourtOrder ? true : el.isRequired,
        }
      });
  }

  initFormCourtOrder() {
    const checkCourtOrderType = this.controls?.find((item: FormControlDetail) => item?.configs?.fixedKey === FixedPropertyKey.CourtOrderType);
    if (checkCourtOrderType) {
      const selectedCourtOrder = checkCourtOrderType?.lstOption?.find((item: any) => item.value === checkCourtOrderType?.formControl.value);
      this.payeeId = this.payeeControl.value;
      const body = {
        memberId: this.targetId,
        courtOrderType:
          selectedCourtOrder.code === COURT_ORDER_TYPE_CODE.QDRO ? CourtOrderType.QDRO : CourtOrderType.Garnishment,
        screenId: this.screenId,
        courtOrderComponentId: this.entityComponentId,
        payeeId: this.payeeId,
      };
      this.memberStore.dispatch(getPayeePropertiesAction({
        body
      }));
      const checkRelationship = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip);
      if (checkRelationship) {
        checkRelationship.isHidden = selectedCourtOrder.code !== COURT_ORDER_TYPE_CODE.QDRO;
        checkRelationship.isRequired = selectedCourtOrder.code === COURT_ORDER_TYPE_CODE.QDRO;
        checkRelationship?.formControl.setValue('');
      }
    }

    const checkStatusCourtOrder = this.controls.find((item: FormControlDetail)  => item?.configs?.fixedKey === FixedPropertyKey.StatusCourtOrder);
    if (checkStatusCourtOrder && checkStatusCourtOrder.formControl.value === StatusCourtOrder.Approved) {
      this.currentEditingCourtOrderStatus = deepClone(StatusCourtOrder.Approved);
      checkStatusCourtOrder.lstOption = checkStatusCourtOrder.lstOption.filter(
        (item) => item.value !== StatusCourtOrder.Pending,
      );
      this.controls.forEach((item) => {
        if (
          item.configs?.fixedKey === FixedPropertyKey.StatusCourtOrder ||
          item.configs?.fixedKey === FixedPropertyKey.RejectReason ||
          item.data?.isPayeeInformationProperty === true
        ) {
          item.disableRow = false;
        } else {
          item.disableRow = true;

          // US #150597, field display with only Label - Value format should not have required indicator
          if (item?.formControl?.hasValidator(Validators.required)) {
            item!.formControl!.removeValidators(Validators.required);
            item!.formControl!.updateValueAndValidity();
          }

          if (item?.data?.type === EntityPropertyType.Binary) {
            item!.formControl!.disable();
          }
        }
      });
    }

    if (this.isCourtOrder && this.isEdit && this.courtOrderTypeControl?.value === this.qdroValueId) {
      this.currentEditingCourtOrderQdroPayeeId = this.payeeControl.value;
      
      // Store orginal Person Name of Payee Information used to trigger Name Change Reason logics for EDIT case
      const payeePersonNameCtrl = this.controls
        ?.find((ctrl: FormControlDetail) => ctrl?.data?.entityPropertyId?.toUpperCase() === CourtOrderPropertiesGUIDs.PersonName.toUpperCase());
      if (payeePersonNameCtrl) {
        const prefix = payeePersonNameCtrl?.lstChildFormControl
          ?.find((childCtrl: any) => childCtrl?.key === PROPERTY_PERSON_NAME_PREFIX_KEY)?.formControl?.value ?? null;
        const first = payeePersonNameCtrl?.lstChildFormControl
          ?.find((childCtrl: any) => childCtrl?.key === PROPERTY_PERSON_NAME_FIRST_KEY)?.formControl?.value ?? null;
        const middle = payeePersonNameCtrl?.lstChildFormControl
          ?.find((childCtrl: any) => childCtrl?.key === PROPERTY_PERSON_NAME_MIDDLE_KEY)?.formControl?.value ?? null;
        const last = payeePersonNameCtrl?.lstChildFormControl
          ?.find((childCtrl: any) => childCtrl?.key === PROPERTY_PERSON_NAME_LAST_KEY)?.formControl?.value ?? null;
        const suffix = payeePersonNameCtrl?.lstChildFormControl
          ?.find((childCtrl: any) => childCtrl?.key === PROPERTY_PERSON_NAME_SUFFIX_KEY)?.formControl?.value ?? null;

        this.currentPayeeInformationNameObj = {
          prefix, first, middle, last, suffix,
        }
      }
    }
  }

  private _setValue(data: any) {
    let value: any;
    this.controls.forEach((element) => {
      if (element.type === EntityPropertyType.Aggregation || element.type === EntityPropertyType.Calculation) {
        element.isHidden = true;
      }
      if (element?.fixedPropertyKey) {
        value = data?.find((item: any) => item.fixedPropertyKey === element.fixedPropertyKey)?.value;
        element.formControl?.setValue(value);
      } else {
        value = data?.find((item: any) => item.entityPropertyId === element.name)?.value;
        if (value?.maskedValue || value?.maskedValue === null) {
          value = value.originalValue;
        }
        if (isEmpty(value) && element.type !== EntityPropertyType.Binary) {
          return;
        }
        if (
          element.type == EntityPropertyType.Address ||
          element.type == EntityPropertyType['Person Name'] ||
          element.type == EntityPropertyType.Status
        ) {
          element.lstChildFormControl?.forEach((item: any) => {
            if (item?.key === 'country') {
              if (value[item.key]) {
                item.formControl?.setValue(value[item.key]);
              }
            } else {
              item.formControl?.setValue(
                item?.key === 'effectFrom' && value?.isClearEffectFrom ? null : value[item.key] || null,
              );
            }
          });
          if (element.type == EntityPropertyType.Address) {
            element.isActive = value?.id ? true : false;
            element.code = value?.code;
          }
        } else if (element.type == EntityPropertyType.Phone) {
          element.formControl?.setValue(this.formatPhoneNumberInput(value));
        } else if (element.type == EntityPropertyType.Binary) {
          element.formControl?.setValue(value === 'true' || value ? true : false);
        } else if (element.type === EntityPropertyType['Date Time']) {
          element.formControl?.setValue(getDateString(value));
        } else {
          if (element?.fractionalLength) {
            const fractionalLengthValue = setFractionalLength(value, element?.fractionalLength);
            element.formControl?.setValue((+value)?.toFixed(fractionalLengthValue));
          } else {
            element.formControl?.setValue(value);
          }
        }
      }
    });
    this.currentInactivationDate = this._findPropertyByFixedKey(data, EMPLOYER_FIXED_PROPERTIES.InactivationDate)
      ?.value;
  }

  formatPhoneNumberInput(formControlValue: string) {
    const cleaned = ('' + formControlValue).trim().replace(/[^0-9]/g, '');
    let match = cleaned.match(LIMIT_PHONE_NUMBER);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return formControlValue;
  }

  private _loadPropertiesToForm(properties: InitiationProperty[] = [], cardData?: any): void {
    this.controls = [...properties]
      .filter(
        (prop) =>
          // Auto Generated Identifier Property should not be show in Add new Member screen,
          // but still show in Edit Member screen as a label with value
          !(
            prop.type === EntityPropertyType.Identifier &&
            stringToBoolean(prop?.configs?.autogenerated) === true &&
            this.isEditCard === false
          ),
      )
      .map((prop) => {
        const isIdentifierAutoGeneratedPropertyType =
          prop.type === EntityPropertyType.Identifier && stringToBoolean(prop?.configs?.autogenerated) === true;
        let control: any;
        if (
          prop.configs.fixedKey &&
          [FixedPropertyKey.Payee, FixedPropertyKey.StatusCourtOrder].includes(prop.configs.fixedKey)
        ) {
          prop.type = EntityPropertyType.Lookup;
        }
        if (isEINProperty(prop.entityPropertyId)) {
          (prop.type as any) = 'EIN';
        }
        if (cardData) {
          control = cardData?.find((item: any) => item.entityPropertyId === prop.entityPropertyId);
        }
        if (prop.type === EntityPropertyType.Address || prop.type === EntityPropertyType['Person Name']) {
          // Address type | Person name type
          let listInputAddressDefault = deepClone(LIST_INPUT_ADDRESS);
          if (!this.isEffectiveData || this.cardInfor?.isList || !this.listMemberParticipants.includes(this.entityId)) {
            listInputAddressDefault = deepClone(LIST_INPUT_ADDRESS).filter((item: any) => {
              return item?.key !== 'effectTo' && item?.key !== 'effectFrom';
            });
          }
          let defaultCountry = '';
          const childFields =
            prop.type === EntityPropertyType.Address
              ? deepClone(listInputAddressDefault)
              : deepClone(LIST_INPUT_PERSON_NAME);
          childFields.forEach((field: FieldData) => {
            field.formControl = new FormControl(null);
            field.lstOption = [];

            if (prop.options?.length) {
              if (field.type === 'select' && prop.configs?.prefixList === 'true') {
                field.lstOption = prop.options.map((x: any) => ({
                  displayValue: x.text,
                  value: x.id,
                }));
              } else if (field.key === 'state') {
                field.lstOption = prop.options
                  .filter((option: OptionValue) => option.type === LookupTableType.State)
                  .map((option: OptionValue) => ({
                    displayValue: option.text,
                    value: option.id,
                  }));
              } else if (field.key === 'country') {
                field.lstOption = prop.options
                  .filter((option: OptionValue) => option.type === LookupTableType.Country)
                  .map((option: OptionValue) => ({
                    displayValue: option.text,
                    value: option.id,
                    code: option.description,
                  }));
                defaultCountry = field.lstOption?.find(item => item.code === this.UnitedStates)?.value;
                field.formControl.setValue(defaultCountry ? defaultCountry : null);
              }
            }

            if (stringToBoolean(prop.configs?.readOnly)) {
              field.formControl.disable();
            }
          });
          if (prop.type === EntityPropertyType.Address && defaultCountry && prop.configs?.required === 'false') {
            childFields?.forEach((item: FieldData) => {
              if (!['effectTo', 'street2'].includes(item.key)) {
                item.isSetRequired = true;
                item?.formControl?.addValidators(Validators.required);
                item?.formControl?.updateValueAndValidity();
              }
            });
          }
          const fromElement = childFields?.find((el) => el.key === 'effectFrom');
          const toElement = childFields?.find((el) => el.key === 'effectTo');
          fromElement?.formControl?.addValidators(this._effectiveDate(fromElement, toElement));

          return {
            name: prop.entityPropertyId,
            recordId: control?.recordId,
            label: prop.label,
            type: prop.type,
            lstChildFormControl: childFields,
            isRequired: prop.configs?.required === 'true',
            data: prop,
          } as FormControlDetail;
        } else if (prop.type === EntityPropertyType.Status) {
          // Status type | Person name type
          const childFields = deepClone(LIST_INPUT_STATUS);
          childFields.forEach((field: FieldData) => {
            field.formControl = new FormControl(null);
            field.lstOption = [];

            if (prop.options?.length && field.key === 'status') {
              field.lstOption = prop.options
                .filter((item) => this.isEdit || item.active)
                ?.map((item) => {
                  return {
                    value: item.id,
                    displayValue: item.name,
                  };
                });
              this.listAllStatus = prop.options;
            }
          });

          return {
            name: prop.entityPropertyId,
            recordId: control?.recordId,
            label: prop.label,
            type: prop.type,
            lstChildFormControl: childFields,
            data: prop,
          } as FormControlDetail;
        } else {
          // One input type
          const formControl = new FormControl(null);
          const controlDetail: FormControlDetail = {
            name: prop.entityPropertyId,
            entityPropertyId: prop.entityPropertyId,
            recordId: control?.recordId,
            label: prop.label,
            type: prop.type,
            lstOption: [],
            formControl: formControl,
            isRequired: prop.configs?.required === 'true',
            maxLength: 'none',
            ...(isIdentifierAutoGeneratedPropertyType && {
              isIdentifierAutoGeneratedPropertyType: true,
            }),
            fixedPropertyKey: prop.fixedPropertyKey,
            staticPropertyType: prop.staticPropertyType,
            belongPropertiesOrder: prop?.belongPropertiesOrder,
          };
          // validate by property config
          const isExistingMinVal =
            stringToBoolean(prop.configs?.inclusiveInRange) &&
            isString(prop.configs?.minInput) &&
            !isNaN(Number(prop.configs?.minInput));
          const isExistingMaxVal =
            stringToBoolean(prop.configs?.inclusiveInRange) &&
            isString(prop.configs?.maxInput) &&
            !isNaN(Number(prop.configs?.maxInput));
          const isExistingMaxLengthVal =
            stringToBoolean(prop.configs?.maximumLength) &&
            isString(prop.configs?.maxLengthInput) &&
            !isNaN(Number(prop.configs?.maxLengthInput));
          const isExistingFracLengthVal =
            stringToBoolean(prop.configs?.fractionalLength) &&
            isString(prop.configs?.fractionalLengthInput) &&
            !isNaN(Number(prop.configs?.fractionalLengthInput));

          if (isExistingMinVal) {
            controlDetail.min = prop.configs?.minInput.trim() ? prop.configs?.minInput.trim() : undefined;
          }
          if (isExistingMaxVal) {
            controlDetail.max = prop.configs?.maxInput.trim() ? prop.configs?.maxInput.trim() : undefined;
          }
          if (isExistingMaxLengthVal && !isExistingMinVal && !isExistingMaxVal) {
            controlDetail.maxLength = prop.configs?.maxLengthInput;
          }
          let propTransformedType = prop.type;
          if (isExistingFracLengthVal) {
            const fractionalLength = Number(prop.configs?.fractionalLengthInput);
            if (fractionalLength === 0) {
              propTransformedType = EntityPropertyType['Whole Number'];
            } else {
              const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${fractionalLength}})?$`);
              formControl.addValidators(Validators.pattern(rgx));
              controlDetail.fractionalLength = fractionalLength.toString();
            }
          }

          switch (prop?.type) {
            case EntityPropertyType.Currency:
              formControl.setValue(null);
              break;
            case EntityPropertyType.Decimal:
              if (!isExistingFracLengthVal) {
                propTransformedType = EntityPropertyType['Whole Number'];
              }
              break;
            case EntityPropertyType.Percentage:
              formControl.addValidators(Validators.min(0));
              controlDetail.min = 0;

              formControl.addValidators(Validators.max(100));
              controlDetail.max = 100;

              const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${2}})?$`);
              formControl.addValidators(Validators.pattern(rgx));
              controlDetail.fractionalLength = 2;
              break;
            case EntityPropertyType.Date:
              let minDate: Date | undefined = undefined;
              let maxDate: Date | undefined = undefined;

              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);

              if (prop.configs?.excludeFutureDates?.toLowerCase() == 'true') {
                currentDate.setHours(24);
                maxDate = currentDate;
              }

              if (prop.configs?.excludePastDates?.toLowerCase() == 'true') {
                currentDate.setHours(-24);
                minDate = currentDate;
              }

              if (prop.configs?.dateValidation?.toLowerCase() == 'true') {
                controlDetail.isValidation = true;
                const rules = JSON.parse(prop.configs.dateValidationExpressions);
                if (rules?.length > 0) {
                  rules.forEach((rule: any) => {
                    switch (rule.validationType) {
                      case ValidationType.Year:
                        const yearValidation = +rule.value;
                        if (yearValidation) {
                          const vaidationDate = new Date();
                          vaidationDate.setFullYear(vaidationDate.getFullYear() - yearValidation);
                          if (rule.validation === ValidationOperator.LessThan) {
                            let dateVal = new Date(vaidationDate);
                            dateVal.setHours(0);
                            if (minDate == undefined || dateVal > minDate) {
                              minDate = dateVal;
                            }
                          }

                          if (rule.validation === ValidationOperator.GreaterThan) {
                            let dateVal = new Date(vaidationDate);
                            dateVal.setHours(0);
                            if (maxDate == undefined || dateVal < maxDate) {
                              maxDate = dateVal;
                            }
                          }
                        }
                        break;
                      case ValidationType.SpecificDate:
                        const dateValidation = rule.value;
                        if (dateValidation) {
                          if (rule.validation === ValidationOperator.LessThan) {
                            const dateVal = new Date(dateValidation);
                            dateVal.setHours(0);
                            if (maxDate == undefined || dateVal < maxDate) {
                              maxDate = dateVal;
                            }
                          }

                          if (rule.validation === ValidationOperator.GreaterThan) {
                            const dateVal = new Date(dateValidation);
                            dateVal.setHours(0);
                            if (minDate == undefined || dateVal > minDate) {
                              minDate = dateVal;
                            }
                          }
                        }
                        break;
                      case ValidationType.Property:
                        this.datePropValidation.push({
                          property: prop.entityPropertyId,
                          validationProp: rule.value,
                          operator: rule.validation,
                        });
                    }
                  });
                }
              }

              this.dateValidationValue.push({
                property: prop.entityPropertyId,
                max: maxDate,
                min: minDate,
              });

              if (minDate) {
                this.setMinDate(controlDetail, minDate);
              }

              if (maxDate) {
                this.setMaxDate(controlDetail, maxDate);
              }

              // check control is inactivation date
              if (prop?.configs?.fixedKey && prop?.configs?.fixedKey === EMPLOYER_FIXED_PROPERTIES.InactivationDate) {
                maxDate = currentDate;
                controlDetail.max = new Date();
                controlDetail.maxMessage = 'Inactivation Date should not be greater than today.';
                controlDetail.formControl.addAsyncValidators(this._validateInactivationDate());
              }
              if (!prop?.configs?.fixedKey || prop?.configs?.fixedKey !== EMPLOYER_FIXED_PROPERTIES.InactivationDate) {
                this._setDateValidationMessage(controlDetail);
              }
              // Validate Jobhistory Begin & EndDate
              if (prop?.entityPropertyId?.toLocaleLowerCase() === JOB_HISTORY.BeginDate.toLocaleLowerCase()) {
                controlDetail.formControl.addAsyncValidators(this.validateJobHistoryOnBlur(true, false));
              }
              if (prop?.entityPropertyId?.toLocaleLowerCase() === JOB_HISTORY.EndDate.toLocaleLowerCase()) {
                controlDetail.formControl.addAsyncValidators(this.validateJobHistoryOnBlur(false, true));
              }
              // Validate ServiceHistory Begin & EndDate
              if (prop?.entityPropertyId?.toLocaleLowerCase() ===  SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.StartDate.toLocaleLowerCase()) {
                controlDetail.formControl.addAsyncValidators(this.validateServiceHistoryOnBlur(true, false));
              }
              if (prop?.entityPropertyId?.toLocaleLowerCase() === SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.EndDate.toLocaleLowerCase()) {
                controlDetail.formControl.addAsyncValidators(this.validateServiceHistoryOnBlur(false, true));
              }              

              // Validate DateOfBirth only
              if (this.isBVFFFund && prop?.entityPropertyId?.toLocaleLowerCase() === PERSON_BASE_PROPERTIES.DateOfBirth.toLocaleLowerCase() && this.entityId === ENTITY_MAPPING.Member) {
                controlDetail.formControl.addAsyncValidators(this._validateDateOfBirth());
              }
              break;
            case EntityPropertyType.Binary:
              if (prop?.entityPropertyId?.toLocaleLowerCase() === PERSON_BASE_PROPERTIES.ReportedDeceased.toLocaleLowerCase()) {
                controlDetail.formControl.addAsyncValidators(this._validateReportedDeceased());
                controlDetail.customError = 'customError';
              }
              break;
            default:
              break;
          }

          // set list option for List type
          let lstOption: any[] = [];
          if (
            (prop.type === EntityPropertyType.Lookup || prop.type === EntityPropertyType.Tier) &&
            prop.options?.length
          ) {
            let isRelationship = false;
            lstOption = prop.options.map((x) => {
              const result: Option = {
                displayValue: x.text,
                value: x.id,
                extraData: {
                  ...x,
                },
              };
              if (prop.configs.fixedKey && prop.configs.fixedKey === FixedPropertyKey.CourtOrderType) {
                result.code = x.code;
              }
              if (prop.configs.fixedKey && prop.configs.fixedKey === FixedPropertyKey.StatusCourtOrder) {
                result.value = x.code;
              }
              if (x.relationshipType) {
                isRelationship = true;
              }
              return result;
            });
            if (isRelationship && (properties || [])) {
              let spouseId =  prop.options?.find((x :any) => x.relationshipType === RelationshipOptions.Spouse).id;
              if (control?.value) {
                this.currentRelationshipValue = prop.options?.find((x :any) => x.id === control.value).relationshipType;
              }
              if (control?.value !== spouseId || !control?.value) {
                this.entityPropertyService.checkExistSpouse({targetId: this.targetId, lookupTableId: prop.configs.lookupTable, value: spouseId})?.subscribe((result: any ) => {
                  if(result) {
                    this.spousePerson = deepClone(result);
                  }
                });
              }
            }
          } else if (
            (prop.type === EntityPropertyType.Employer || prop.type === EntityPropertyType.Department) &&
            prop.options?.length
          ) {
            lstOption = prop.options
              .filter((x) => x.active)
              .map((x) => ({
                displayValue: x.text,
                value: x.id,
                valueDescription: x.description,
              }));
          }

          if (stringToBoolean(prop.configs?.readOnly)) {
            formControl.disable();
          }

          // Check exists entity property value
          if (prop.configs?.unique === 'true'
            && prop.configs.autogenerated !== 'true'
            && prop.entityPropertyId.toLowerCase() !== PERSON_BASE_PROPERTIES.SSN.toLowerCase()
          ) {
            formControl.addAsyncValidators(
              checkApiValidator(
                this.entityPropertyService.checkExistPropertyValue,
                'value',
                undefined,
                {
                  params: {
                    entityId: prop.entityId,
                    componentId: prop.entityComponentId,
                    propertyId: prop.entityPropertyId,
                    id: control?.recordId,
                    propertyType: prop.type,
                  },
                },
                () => {
                  this.formGroup.updateValueAndValidity();
                },
              ),
            );
          }

          if (prop.staticPropertyType === StaticPropertyType.Percentage 
            || (
              this.isRelationshipCard
              && prop?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Percentage.toUpperCase()
            )) {
            formControl.addAsyncValidators(
              checkApiValidator(
                this.entityPropertyService.checkSumOfPercentageValue,
                'value',
                undefined,
                {
                  params: {
                    targetId: this.targetId,
                    recordId: control?.recordId,
                    isCheckSumLessThan100: false,
                  },
                },
                () => {
                  this.formGroup.updateValueAndValidity();
                },
              ),
            );
          }
          if (prop?.staticPropertyType === StaticPropertyType.IsPrimary) {
            this.entityPropertyService.isPrimary = control?.value;
          }

          if (this.isRelationshipCard
            && prop?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.IsPrimary.toUpperCase()
          ) {
            formControl.addAsyncValidators(
              checkApiValidator(
                this.entityPropertyService.checkIsPrimarySavedValue,
                'value',
                undefined,
                {
                  params: {
                    targetId: this.targetId,
                    recordId: control?.recordId,
                  },
                },
                () => {
                  this.formGroup.updateValueAndValidity();
                },
                'Only one Beneficiary can be primary.'
              ),
            );
          }

          if (prop.configs.fixedKey === FixedPropertyKey.LineOfDutyDeath) {
            formControl.addValidators(this._lineOfDutyDeathValidator());
            controlDetail.customError = 'invalidLineOfDutyDeath';
          }
          if (!this.isEdit) {
            if (prop.configs.fixedKey === FixedPropertyKey.RelationShip) {
              controlDetail.isHidden = true;
            }
            if (prop.configs.fixedKey === FixedPropertyKey.Payee) {
              formControl.disable();
            }
            if (prop.configs.fixedKey === FixedPropertyKey.StatusCourtOrder) {
              formControl.setValue(StatusCourtOrder.Pending);
            }
          }
          if (prop.configs.fixedKey === FixedPropertyKey.RejectReason) {
            controlDetail.isHidden = true;
          }
          controlDetail.type = propTransformedType;
          controlDetail.lstOption = lstOption;
          controlDetail.configs = prop.configs;
          controlDetail.data = prop;
          return controlDetail;
        }
      }
    );
    
    // Hide property Status
    if (this.isEdit) {
      this.controls = this.controls.filter((prop) => prop.type !== EntityPropertyType.Status);
    }

    this.controls.forEach((f) => {
      if (f.formControl !== undefined) {
        this.formGroup.setControl(f.name, f.formControl);
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            this.formGroup.setControl(f.name + x.name, x.formControl);
          }
        });
      }

      // Used for Relationship List Card only
      if (this.isRelationshipCard) {
        f.isHidden = f?.data?.isHidden;
      }
      // End of Used for Relationship List Card only
    });

    // Handle for Entity References properties
    this.propertyReferences = [...properties]
      .filter((prop) => prop.type === EntityPropertyType['Entity Reference'])
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        return {
          ...item,
          referenceDatas: item.referenceDatas ?? [],
          initiationValue: item.initiationValue,
        };
      });
    this.propertyReferences.forEach((element: any) => {
      this.erProperties[element.entityPropertyId] = element;
      this.erControls[element.entityPropertyId] = [];
      this.erFormGroup[element.entityPropertyId] = new FormGroup({});
      this.erFormRadioButton[element.entityPropertyId] = new FormControl();
      this.selectedRecordId[element.entityPropertyId] = '';
    });

    if (this.isEdit && this.propertyReferences.length > 0) {
      this.propertyReferences.forEach((prop: any) => {
        prop.initiationValue = true;
        prop.referenceDatas = (
          this.data.filter(
            (item: any) =>
              item.entityId === prop.entityId &&
              item.entityComponentId === prop.entityComponentId &&
              item.entityPropertyId === prop.entityPropertyId,
          ) || []
        ).map((item: any) => {
          const entitySelectedId = item.entityReferenceLinkedId;
          return {
            ...item,
            value: this.getPropertyValue(item.propertyType, item.value),
            recordId: item.recordId,
            recordLinkedId: item.recordLinkedId,
            valueObj:
            item.propertyType === EntityPropertyType.Address || EntityPropertyType['Person Name']
            ? this.getPropertyValue(item.propertyType, item.value)
            : null,
            entitySelectedId: entitySelectedId,
            entityReferenceLinkedId: item.entityReferenceLinkedId,
            entityReferencePropertyId: item.entityReferencePropertyId,
            entityReferenceId: item.entityReferenceId,
            entityReferenceComponentId: item.entityReferenceComponentId,
          };
        });
      });
    }
    const isPrimaryControl = this.controls?.find(control => control?.staticPropertyType === StaticPropertyType.IsPrimary);
    if (!isPrimaryControl) {
      const percentageControl = this.controls?.find(
        (control) => control?.staticPropertyType === StaticPropertyType.Percentage,
      );
      percentageControl?.formControl?.clearAsyncValidators();
    }
  }

  isPrimaryChange(value: boolean) {
    const percentageField = this.controls?.find(control => control.staticPropertyType === StaticPropertyType.Percentage);
    this.entityPropertyService.isPrimary = value;

    if (percentageField?.formControl.value <= 100 && percentageField?.formControl.value >= 0) {
      if (value && percentageField?.formControl.valid) {
        percentageField?.formControl.updateValueAndValidity();
      } else {
        percentageField?.formControl.setErrors(null);
      }
    }
  }

  getPropertyValue(type: EntityPropertyType, value: any) {
    if (type === EntityPropertyType.Address || type === EntityPropertyType['Person Name']) {
      if (value && typeof value === 'object' && Object.values(value).every((item) => item === null))
        return (value = null);
      return (value = value);
    }
    return value;
  }

  setStatus(childForm: FieldData) {
    if (childForm.key === 'status') {
      this.listStatusEvent = this.listAllStatus
        ?.find((item) => item.id === childForm.formControl?.value)
        .events.filter((item: any) => this.isEdit || item.active)
        ?.map((item: any) => {
          return {
            value: item.id,
            displayValue: item.name,
          };
        });
    }
  }

  private _effectiveDate(fromElement: any, toElement: any) {
    return (c: AbstractControl) => {
      let fromElementCompare = fromElement?.formControl?.value ? ((typeof fromElement?.formControl?.value === 'string') ? new Date(fromElement?.formControl?.value) : fromElement?.formControl?.value) : '';
      let toElementCompare = toElement?.formControl?.value ? ((typeof toElement?.formControl?.value === 'string') ? new Date(toElement?.formControl?.value) : toElement?.formControl?.value) : '';
      return (fromElementCompare
        && toElementCompare
        && fromElementCompare >= toElementCompare) ? {errorMsg: 'System does not allow Effective From is equal OR greater than Effective To.'} : null;
    };
  }

  dateEffectiveChange(event: any, lstChildFormControl?: FieldData[], data?: FormControlDetail) {
    lstChildFormControl?.find((el) => el.key === 'effectFrom')?.formControl?.updateValueAndValidity();
    lstChildFormControl?.find((el) => el.key === 'effectTo')?.formControl?.updateValueAndValidity();
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  dateValueChange(event: any, form: any): void {
    if (this.datePropValidation) {
      //validate property rules
      const validateRules = this.datePropValidation.filter((o) => o.property === form.name);
      if (validateRules) {
        for (const element of validateRules) {
          const rule = element;
          const compareProperty = rule.validationProp;

          const compareForm = this.controls?.find((o) => o.name === compareProperty);
          if (compareForm) {
            const compareControl = compareForm.formControl;
            if (compareControl) {
              const valueCompare = compareControl.value;
              if (valueCompare) {
                if (rule.operator === ValidationOperator.LessThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMinDateValidation(form.name, dateValidation);
                  this.setMinDate(form.formControl, dateValidation);
                }

                if (rule.operator === ValidationOperator.GreaterThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMaxDateValidation(form.name, dateValidation);
                  this.setMaxDate(form.formControl, dateValidation);
                }
                this._setDateValidationMessage(form.formControl);
              }
            }
          }
        }
      }

      //validate property
      const validateProps = this.datePropValidation.filter((o) => o.validationProp == form.name);

      validateProps.forEach((prop) => {
        const sourceForm = this.controls?.find((o) => o.name === prop.property);
        if (sourceForm) {
          if (prop.operator === ValidationOperator.LessThan && event) {
            let valDate = new Date(event);
            valDate = this.compareMaxDateValidation(prop.property, valDate);
            this.setMaxDate(sourceForm, valDate);
          }

          if (prop.operator === ValidationOperator.GreaterThan && event) {
            let valDate = new Date(event);
            valDate = this.compareMinDateValidation(prop.property, valDate);
            this.setMinDate(sourceForm, valDate);
          }

          this._setDateValidationMessage(sourceForm);
        }
      });
    }
  }

  private compareMaxDateValidation(property: string, dateVal: Date): Date {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue?.find((o) => o.property === property);
      if (val && val.max) {
        dateVal.setHours(0);
        if (val.max < dateVal) {
          dateVal = val.max;
        }
      }
    }
    return dateVal;
  }

  private compareMinDateValidation(property: string, dateVal: Date): Date {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue?.find((o) => o.property === property);
      if (val && val.min) {
        dateVal.setHours(0);
        if (val.min > dateVal) {
          dateVal = val.min;
        }
      }
    }
    return dateVal;
  }

  private setMaxDate(formControlDetail: FormControlDetail, vaidationDate: Date): void {
    if (vaidationDate) {
      vaidationDate.setHours(-24);

      formControlDetail.max = vaidationDate;
    }
  }

  private setMinDate(formControlDetail: FormControlDetail, vaidationDate: Date): void {
    if (vaidationDate) {
      vaidationDate.setHours(24);
      formControlDetail.min = vaidationDate;
    }
  }
  private _clearTriangleValidationErrorMessage(): void {
    if (this.isJobHistory) {
      this.beginDateControl.setErrors(null);
      this.endDateControl.setErrors(null);
    }
    else
      if (this.isServiceHistory) {
        this.beginDateServiceHistoryControl.setErrors(null);
        this.endDateServiceHistoryControl.setErrors(null);
      }
  }
  private _setDateValidationMessage(formControlDetail: FormControlDetail): void {
    const min = formControlDetail.min as Date;
    const max = formControlDetail.max as Date;
    const current = new Date();

    min?.setHours(0, 0, 0, 0);
    max?.setHours(0, 0, 0, 0);
    current.setHours(0, 0, 0, 0);

    formControlDetail.minMessage = formControlDetail.maxMessage = 'The input does not meet the validation.';

    if (min?.getTime() === current.getTime()) {
      formControlDetail.minMessage = 'The input must be greater than today';
    }

    if (max?.getTime() === current.getTime()) {
      formControlDetail.maxMessage = 'The input must be less than today';
    }

    if (max && min && min.getTime() >= max.getTime()) {
      formControlDetail.maxMessage = formControlDetail.minMessage = 'No date satifies the validation.';
    }
  }

  checkFieldsUnique(result: EntityInitiationPropertyValue[], entityPropertyId: string) {
    // Add validation about check duplicate for unique fields
    result?.forEach((item) => {
      if (item.configs?.unique === 'true') {
        let control = this.controls?.find((control) => control.name === item.entityPropertyId);
        if (control) {
          if (!this.validatorPrevious[entityPropertyId]) {
            this.validatorPrevious[entityPropertyId] = {};
          }
          if (
            this.validatorPrevious[entityPropertyId] &&
            this.validatorPrevious[entityPropertyId][item.entityPropertyId]
          ) {
            control?.formControl.removeValidators(this.validatorPrevious[entityPropertyId][item.entityPropertyId]);
          }
          let type = typeof item.value;
          if (item.value && type === 'object' && item.value.originalValue) {
            this.validatorPrevious[entityPropertyId][item.entityPropertyId] = checkUnique(
              item.value.originalValue,
              item?.label + ' is already exists.',
            );
            control?.formControl.addValidators(this.validatorPrevious[entityPropertyId][item.entityPropertyId]);
          } else if (item.value && type !== 'object') {
            this.validatorPrevious[entityPropertyId][item.entityPropertyId] = checkUnique(
              item.value,
              item?.label + ' is already exists.',
            );
            control?.formControl.addValidators(this.validatorPrevious[entityPropertyId][item.entityPropertyId]);
          }
          control?.formControl.updateValueAndValidity();
        }
      }
    });
  }

  createUniqueOtherReference(property: InitiationProperty) {
    // Create uniqueOtherReference for check duplicate all fields unique from Other Reference
    let uniqueOtherReference: { parent: string; name: string; value: any }[] = [];
    this.propertyReferences
      .filter((el: InitiationProperty) => el?.entityPropertyId !== property?.entityPropertyId)
      .forEach((el: InitiationProperty) => {
        el?.referenceDatas
          ?.filter((item) => item.configs?.unique === 'true')
          ?.forEach((item) => {
            let type = typeof item.value;
            uniqueOtherReference.push({
              parent: el?.entityPropertyId,
              name: item?.entityPropertyId,
              value: type === 'object' && item?.value != null ? item?.value?.originalValue : item?.value,
            });
          });
      });
    return uniqueOtherReference;
  }

  onSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formGroup.markAllAsTouched();
          const arrForm = Object.keys(this.erFormGroup);
          for (const element of arrForm) {
            const form = this.erFormGroup[element];
            form.markAllAsTouched();
          }
        }),
        debounceTime(500),
        switchMap(() => {
          const arrayForm = Object.keys(this.erFormGroup);
          return forkJoin([
            this.formGroup.statusChanges.pipe(
              startWith(this.formGroup.status),
              filter((status) => status !== AbstractControlStatus.PENDING),
              take(1),
            ),
            ...arrayForm.map((i) => {
              return this.erFormGroup[i].statusChanges.pipe(
                startWith(this.erFormGroup[i].status),
                filter((status) => status !== AbstractControlStatus.PENDING),
                take(1),
              );
            }),
          ]);
        }),
        filter(
          (status) =>
            status.filter((i) => i === AbstractControlStatus.VALID || i === AbstractControlStatus.DISABLED).length ===
              status.length && !this.isSubmitting,
        ),
      )
      .subscribe(() => {
        // Use for checking exist any ER Properties have configured REQUIRE ON but not filled enough values
        if (this.entityReferencePropertyComponentList?.length) {
          // 0. Declare violated flag
          let isExistErPropertyWithToggleAddNewOffButNotSelectedAnyRecord = false;

          // 1. Filter any ER Properties have configured REQUIRED ON
          const erPropsHaveConfiguredRequiredOn = this.entityReferencePropertyComponentList?.filter(item => item?.isErConfiguredRequiredOn) ?? [];

          // 2. Check each ER Propertiy exist any belong fields required but dont have value
          if (erPropsHaveConfiguredRequiredOn?.length) {

            // 2.1 Loop through all found ER property with configured REQUIRED ON
            erPropsHaveConfiguredRequiredOn?.forEach((item, idx) => {

              // 2.1.1 Just need to check for case Add New Toggle is OFF, or not show in UI and Not selected any record yet
              if (!erPropsHaveConfiguredRequiredOn?.[idx]?.recordId && !erPropsHaveConfiguredRequiredOn?.[idx]?.addToggleCtrl?.value) {
                isExistErPropertyWithToggleAddNewOffButNotSelectedAnyRecord = true;

                // 2.1.1.1 Loop through all belonged fields
                for (const key in erPropsHaveConfiguredRequiredOn?.[idx]?.formGroup?.controls) {
                  const currentField = erPropsHaveConfiguredRequiredOn?.[idx]?.formGroup?.controls?.[key];
                  const currentFieldHaveConfigRequireOn = currentField?.hasValidator(Validators.required);

                  // Trigger error below current field
                  if (currentFieldHaveConfigRequireOn) {
                    currentField.enable();
                    currentField.setValue(null);
                    currentField.markAsTouched();
                  }
                }
              }
            });

            // 2.2 Break Saving flow
            if (isExistErPropertyWithToggleAddNewOffButNotSelectedAnyRecord) {
              return;
            }
          }
        }
        // End of checking exist any ER Properties have configured REQUIRE ON but not filled enoguh values

        // Use for Job History validation
        if (this.isJobHistory && this.isList) {
          const beginDate = this.beginDateControl?.value;
          const endDate = this.endDateControl?.value;
          const employerId  = this.selectedRecordId[JOB_HISTORY.Employer_Employer_Id.toLocaleLowerCase()];
          const beginDateParam = beginDate ? getDateFormatISO(beginDate?.toString()) : '';
          const endDateParam = endDate ? getDateFormatISO(endDate?.toString()) : '';
          const recordId = this.data?.filter((item: any) => 
            item.entityComponentId.toLocaleLowerCase() === JOB_HISTORY.EntityComponentId.toLocaleLowerCase()
          )?.[0]?.['recordId'];
          this.layoutService.showLoading = true;
          this.entityDataService.validateJobHistoryDateRange(beginDateParam, endDateParam, this.targetId, employerId, recordId)
            .subscribe(res => {
              this.layoutService.showLoading = false;
              if (res?.isError) {
                if (res?.errorCode == 'existed_services') {
                  const beginDateLabel = this.getLabelByEntityPropertyId(this.properties, JOB_HISTORY.BeginDate, 'Begin Date');
                  const endDateLabel = this.getLabelByEntityPropertyId(this.properties, JOB_HISTORY.EndDate, 'End Date');
                  const employerIdLabel = this.getLabelByEntityPropertyId(this.properties, JOB_HISTORY.Employer_Employer_Id, 'Employer');

                  const msg = `You cannot edit ${beginDateLabel}, ${endDateLabel}, and ${employerIdLabel} because there is already a granted service in your previously selected ${beginDateLabel} and ${endDateLabel}.`;
                  this.dialog.open(ConfirmPopupComponent, {
                    panelClass: 'confirm-popup',
                    data: {
                      text: msg,
                      type: ConfirmType.Warning,
                      title: ConfirmPopupTitle.Warning,
                      cancelButtonTitle: ConfirmPopupCancelButtonTitle.Close,
                      hideConfirmButton: true,
                    },
                  });
                }
                else {
                  const err = this.getJobHistoryErrorByKey(res?.errorCode);
                  if (err.begin) this.beginDateControl.setErrors({ errorMsg: err.begin });
                  if (err.end) this.endDateControl.setErrors({ errorMsg: err.end });
                }
                return;
              }
              else {
                this.saveValue();
              }
            },
              (err) => {
                this.layoutService.showLoading = false;
              });
              return;
            }
        // End of Use for Job History validation

        // Use for Service History validation
        if (this.isServiceHistory && this.isList) {
          const beginDate = this.beginDateServiceHistoryControl?.value;
          const endDate = this.endDateServiceHistoryControl?.value;
          const employerId = this.selectedRecordId[SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.Employer.toLocaleLowerCase()];
          const beginDateParam = beginDate ? getDateFormatISO(beginDate?.toString()) : '';
          const endDateParam = endDate ? getDateFormatISO(endDate?.toString()) : '';
          const recordId = this.data?.filter((item: any) =>
            item.entityComponentId.toLocaleLowerCase() === SERVICE_HISTORY_LIST.Id.toLocaleLowerCase()
          )?.[0]?.['recordId'];
          this.layoutService.showLoading = true;
          this.entityDataService.validateServiceHistoryDateRange(beginDateParam, endDateParam, this.targetId, employerId, recordId)
            .subscribe(res => {
              this.layoutService.showLoading = false;
              if (res?.isError) {
                const err = this.getServiceHistoryErrorByKey(res?.errorCode);
                if (err.begin) this.beginDateServiceHistoryControl.setErrors({ errorMsg: err.begin });
                if (err.end) this.endDateServiceHistoryControl.setErrors({ errorMsg: err.end });
                return;
              }
              else {
                this.saveValue();
              }
            },
              (err) => {
                this.layoutService.showLoading = false;
              });
          return;
        }
        // End of Use for Service History validation

        // Use for Court Order - QDRO case only
        if (this.isCourtOrder && this.courtOrderTypeControl?.value === this.qdroValueId && this.isList) {
          const warningMsgs = this.checkingCourtOrderQDROWarningMsg();
          if (warningMsgs?.length > 0) {
            // Keep validate with Martial Status
            this.validateMaritalInfo(
              ValidateFor.CourtOrder,
              warningMsgs,
            );
            return;
          } else {
            // Keep validate with Martial Status
            this.validateMaritalInfo(
              ValidateFor.CourtOrder,
            );
            return;
          }
        }
        // End of Use for Court Order - QDRO case only

        // Use for Relationship List Card
        // Validate Relationship Beneficiary Percentage
        const beneficiaryPercentageControl = this.controls?.find((item: FormControlDetail) => item?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Percentage.toUpperCase());
        if (this.isRelationshipCard 
          && this.isList
          && this.currentLegalRelationshipTypeChipList?.find(item => item?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary)
          && (this.beneficiaryPercentageControl || beneficiaryPercentageControl)) {
          const request: CheckSumOfPercentageValueRequest = {
            recordId: beneficiaryPercentageControl?.recordId,
            targetId: this.targetId,
            value: this.beneficiaryPercentageControl?.value ?? 0,
            isCheckSumLessThan100: true,
          };
          this.layoutService.showLoading = true;
          this.entityPropertyService.checkSumOfPercentageValue(request).subscribe(
            res => {
              this.layoutService.showLoading = false;
              if (res?.isValid) {
                // Validate Relationship Beneficiary Percentage PASSED, keep going with Validate Marital Status
                const msg = 'Total Percentage of all added Beneficiaries has not reached 100% yet. Are you sure your entered information is correct?';
                const configs = {
                  text: msg,
                }
                this.customPopupBeforeSave(
                  configs,
                  () => this.validateMaritalInfo(ValidateFor.Relationship)
                );
                return;
              } else {
                // Validate Relationship Beneficiary Percentage FAILED, keep going with Validate Marital Status
                this.validateMaritalInfo(ValidateFor.Relationship)
              }
            },
            err => {
              this.layoutService.showLoading = false;
            }
          );
          return;
        }

        // Validate Marital Information alone (Case Relationship record dont have Beneficiary)
        if (this.isRelationshipCard && this.isList) {
          this.validateMaritalInfo(ValidateFor.Relationship);
          return;
        }
        // End of Use for Relationship List Card

        this.saveValue();
      });
  }

  private saveEntityValue(entityPropertyId: string) {
    const controls = this.erControls[entityPropertyId];
    const entityPropertyValues: EntityInitiationPropertyValue[] = [];
    const datepipe = new DatePipe('en-US');
    if (this.erFormGroup[entityPropertyId].status === AbstractControlStatus.DISABLED) {
      return entityPropertyValues;
    }
    for (const f of controls) {
      let value = f.formControl?.value;
      let valueObj: any;
      let valueMask = {
        maskedValue: '',
        originalValue: '',
      };
      switch (f.type) {
        case EntityPropertyType.Status:
        case EntityPropertyType.Address:
        case EntityPropertyType['Person Name']:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            if (childForm.type === 'date') {
              objVal[childForm.name] = childForm.formControl?.value
                ? datepipe.transform(childForm.formControl?.value, DATE_FORMAT_YMD)
                : f.type === EntityPropertyType.Address
                  ? null
                  : '';
            } else {
              objVal[childForm.name] = childForm.formControl?.value;
            }
          }
          value = isHaveData ? JSON.stringify(objVal) : '';
          valueObj = value ? JSON.parse(value, (key, value) => toCamelCase(key, value)) : '';
          break;
        case EntityPropertyType.Date:
          value = value ? datepipe.transform(value, DATE_FORMAT_YMD) : '';
          break;
        case EntityPropertyType.Binary:
          value = value ? true : false;
          break;
        case EntityPropertyType.Percentage:
          value = value?.toString() || '';
          value = (value?.endsWith('.') ? value?.substring(0, value.length - 1) : value) || '';
          break;
        case EntityPropertyType.Email:
          value = {
            originalValue: value,
          };
          break;
        case EntityPropertyType.SSN:
          value = {
            originalValue: value,
          };
          break;
        case EntityPropertyType.Phone:
          value = {
            originalValue: value,
          };
          break;
        default:
          value = isEmpty(value) ? '' : value?.toString();
      }

      entityPropertyValues.push({
        configs: f.data.configs,
        type: f.data.type,
        label: f.data.label,
        options: f.data.options,
        entitySelectedId: this.erFormRadioButton[entityPropertyId].value,
        entityId: f.data.entityId,
        entityComponentId: f.data.entityComponentId,
        entityPropertyId: f.data.entityPropertyId,
        value: value,
        valueObj: valueObj,
        valueMask: valueMask,
        recordId: f.recordId,
        selectedRecordId: this.selectedRecordId[entityPropertyId],
        isInitiationProperty: f.data.isInitiationProperty,
        isComputedProperty: f.data.isComputedProperty,
      } as EntityInitiationPropertyValue);
    }

    return entityPropertyValues;
  }
  private saveValue(): void {

    const array = Object.keys(this.erControls);
    for (const element of array) {
      const entityPropertyId = element;
      const result: any = this.saveEntityValue(entityPropertyId);
      let propRef = this.propertyReferences?.find((item: any) => item.entityPropertyId === entityPropertyId);
      if (propRef) {
        propRef.initiationValue = false;
        propRef.referenceDatas = result;
      }
      this.checkFieldsUnique(result, entityPropertyId);
    }
    const entityPropertyValues: EntityInitiationPropertyValue[] = [];
    const datepipe = new DatePipe('en-US');
    for (const f of this.controls) {
      let value = f.formControl?.value;
      let rawValue = f.formControl?.value;
      switch (f.type) {
        case EntityPropertyType.Status:
        case EntityPropertyType.Address:
        case EntityPropertyType['Person Name']:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            if (childForm.type === 'date') {
              objVal[childForm.name] = childForm.formControl?.value
                ? datepipe.transform(childForm.formControl?.value, DATE_FORMAT_YMD)
                : f.type === EntityPropertyType.Address
                  ? null
                  : '';
            } else {
              objVal[childForm.name] = childForm.formControl?.value;
            }
          }
          value = isHaveData
            ? JSON.stringify({ ...objVal, code: f.code ?? null })
            : JSON.stringify({ code: f.code ?? null });
          break;
        case EntityPropertyType.Date:
          value = value ? datepipe.transform(value, DATE_FORMAT_YMD) : null;
          break;
        case EntityPropertyType.Binary:
          value = value ? true : false;
          break;
        case EntityPropertyType.Percentage:
          value = value?.toString() || null;
          value = (value?.endsWith('.') ? value?.substring(0, value.length - 1) : value) || null;
          break;
        case EntityPropertyType.SSN:
        case EntityPropertyType.Phone:
          value = getValueWithoutFormat(value);
          break;
        case EntityPropertyType.System:
          // Only used for Relationship List Card Upsert Record flow
          if (this.isRelationshipCard && this.isList && f?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.LegalRelationshipType.toUpperCase()) {
            value = this.prepareLegalRelationshipTypeRequestValue();
            rawValue = this.prepareLegalRelationshipTypeRequestValue();
          }
          // End of Only used for Relationship List Card Upsert Record flow
          break;
        case EntityPropertyType['Entity Reference']:
          // Only used for Relationship List Card Upsert Record flow
          // @-> Workaround here to force Entity Reference property in case click X (no ER inputted) will be send `null` to BE
          // @-> If ER inputted, they will be overwrite later by ER handler flow below
          if (this.isRelationshipCard && this.isList) {
            value = null;
            rawValue = null;
          }
          // End of Only used for Relationship List Card Upsert Record flow
          break;
        default:
          value = isNullOrUndefinedOrEmptyString(value) ? null : value?.toString();
      }
      // Not add Identifier Auto Generated to body request when save (act as label not form control)
      if (
        !f.isIdentifierAutoGeneratedPropertyType &&
        ((!this.isEdit && value !== null && value !== undefined && value !== '') || this.isEdit)
      ) {
        entityPropertyValues.push({
          recordId: f.recordId,
          entityId: f.data.entityId,
          entityComponentId: f.data.entityComponentId,
          entityPropertyId: f.data.entityPropertyId,
          value: value,
          type: f.type,
          isComputedProperty: f.data.isComputedProperty,
          rawValue,
        } as EntityInitiationPropertyValue);
      }
    }
    for (const p of this.propertyReferences) {
      if (p.referenceDatas && p.referenceDatas.length > 0) {
        const properties = p.referenceDatas;
        const entityPropertyReferencesValues: EntityInitiationPropertyValue[] = [];
        const entitySelectedId = properties?.find(
          (item: any) => item.entitySelectedId !== null && item.entitySelectedId !== undefined,
        )?.entitySelectedId;
        const selectedRecordId =
          properties?.find((item: any) => item.selectedRecordId !== null && item.selectedRecordId !== undefined)
            ?.selectedRecordId || properties[0]?.recordLinkedId;
        for (const f of properties) {
          let value =
            f.value?.originalValue || f.value?.originalValue === null || f.value?.originalValue === ''
              ? f.value?.originalValue
              : f.value;
          if (f.type === EntityPropertyType.SSN || f.type === EntityPropertyType.Phone) {
            value = value ? getValueWithoutFormat(value) : value;
          }
          if ((!this.isEdit && value !== null && value !== undefined && value !== '') || this.isEdit) {
            entityPropertyReferencesValues.push({
              entityId: f.entityId,
              recordId: f.recordId,
              entityComponentId: f.entityComponentId,
              entityPropertyId: f.entityPropertyId,
              value: value,
              isComputedProperty: f.isComputedProperty,
            } as EntityInitiationPropertyValue);
          }
        }

        if (entityPropertyReferencesValues.length > 0) {
          entityPropertyValues.push({
            entityId: p.entityId,
            entityComponentId: p.entityComponentId,
            entityPropertyId: p.entityPropertyId,
            entityPropertyReferenceValue: {
              recordId: selectedRecordId,
              entityId: entitySelectedId,
              entityPropertyValues: entityPropertyReferencesValues,
            },
          } as any);
        }
      }
    }
    if (this.isEffectiveData && !this.cardInfor?.isList && this.listMemberParticipants.includes(this.entityId)) {
      entityPropertyValues.forEach(item => {
        if (item.type === EntityPropertyType.Address) {
          const getAddressId = this.data?.find((value: any) => value.entityPropertyId === item.entityPropertyId);
          if (getAddressId?.value?.id) {
            const cloneValue = item.value ? JSON.parse(item.value) : null;
            if (cloneValue) {
              cloneValue.id = getAddressId.value.id;
              cloneValue.code = getAddressId.value.code;
              item.value = JSON.stringify(cloneValue);
            }
          }
        }
      });
    }
    const request = {
      entityId: this.entityId,
      entityPropertyValues: [...entityPropertyValues],
    };
    if (this.dateOfDeathControl?.value) {
      (request as any)[PERSON_BASE_PROPERTIES.ReportedDeceased.toLowerCase()] = true;
    }
    if (this.isEffectiveData && !this.cardInfor?.isList && this.listMemberParticipants.includes(this.entityId)) {
      const cloneTypeAddress = entityPropertyValues.filter((item) => item.type === EntityPropertyType.Address);
      if (!cloneTypeAddress.length) {
        this.updateEvent(request);
        return;
      }
      let body: any = [];
      cloneTypeAddress.forEach((item) => {
        const value = item.value ? JSON.parse(item.value) : null;
        if (value && Object.keys(value).length > 1) {
          const data = {
            reCordId: item.recordId ? item.recordId : null,
            entityId: item.entityId,
            key: item.entityPropertyId,
            entityPropertyId: item.entityPropertyId,
            effectFrom: value.EffectFrom,
            effectTo: value.EffectTo || null,
            id: value?.id ? value.id : null,
          };
          body.push(data);
        }
      });
      this.memberStore.dispatch(AddressHistoryAction.checkExitAddress({ body }));
      let subscription = this.memberStore
      .pipe(
        select(fromMember.selectAddressHistoryExitState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.messages) {
          if (state?.messages.length) {
            state?.messages?.forEach((item: any) => {
              const checkAddressError = this.controls.find(value => value.data.entityPropertyId === item.key);
              if (checkAddressError) {
                const form = checkAddressError?.lstChildFormControl?.find(f => f.key === 'effectFrom')!;
                form.messageError = item.message;
                form?.formControl?.setErrors({inValidAsync:true});
              }
            });
          } else {
            this.updateEvent(request);
          }
          this.memberStore.dispatch(clearSetMemberEventStateAction());
          subscription.unsubscribe();
        }
      });
    } else {
      this.updateEvent(request);
    }
  }

  updateEvent(data: any) {
    // Only use for Court Order Qdro logics
    if (this.isCourtOrder && this.courtOrderTypeControl?.value === this.qdroValueId) {
      data['courtOrderQdroOldPayeePersonName'] = this.currentPayeeInformationNameObj ?? null;
    }
    data = {
      ...data,
      entityReferenceDatas: this.entityReferenceDatas
    };

    // End of only use for Court Order Qdro logics
    if (this.isEdit) {
      const editEmitter = (request: any) => this.editSectionEvent.emit(request);
      const validatePaymentInProgress = (request: any) => this._validatePaymentInProgress(request, editEmitter);
      const validateCourtOrder = (request: any) => this._validateCourtOrderStatus(request, validatePaymentInProgress);
      this._showConfirmPopupRetireRehire(data, validateCourtOrder);
      if (this._checkIsCallApiConfirmRetireRehire()) {
        this._showConfirmPopupRetireRehire(data, this.retireRehireControl.value);
        return;
      }
      if (this._findPropertyByFixedKey(this.properties, FixedPropertyKey.StatusCourtOrder)) {
        this._validateCourtOrderStatus(data, editEmitter);
        return;
      }
      if (this.dateOfDeathControl || this.reportedDeceasedControl) {
        this._validatePaymentInProgress(data, editEmitter);
        return;
      }
      this.editSectionEvent.emit(data);
    } else {
      this.addNewEvent.emit(data);
    }
  }

  onClickIcon(property: any) {
    property.isMasked = property.isMasked === undefined ? false : !property.isMasked;
  }

  onCancel(): void {
    this.cancelEvent.emit();
  }

  //Job History controls
  get beginDateControl(): FormControl {
    return this._getControlByFixedPropertyId(JOB_HISTORY.BeginDate.toLowerCase());
  }

  get endDateControl(): FormControl {
    return this._getControlByFixedPropertyId(JOB_HISTORY.EndDate.toLowerCase());
  }
  // End of Job History controls

  //Service History controls
  get beginDateServiceHistoryControl(): FormControl {
    return this._getControlByFixedPropertyId(SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.StartDate.toLowerCase());
  }

  get endDateServiceHistoryControl(): FormControl {
    return this._getControlByFixedPropertyId(SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.EndDate.toLowerCase());
  }
  // End of Job History controls
  get dateOfDeathControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.DateOfDeath);
  }

  get reportedDeceasedControl(): FormControl {
    return this.formGroup.get(PERSON_BASE_PROPERTIES.ReportedDeceased.toLowerCase()) as FormControl;
  }

  get lineOfDutyDeathControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.LineOfDutyDeath);
  }

  get newHireExamControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.NewHireExam);
  }

  get newHireExamDateControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.NewHireExamDate);
  }

  get retireRehireControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.RetireRehire);
  }

  get courtOrderTypeControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.CourtOrderType);
  }

  get statusCourtOrderControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.StatusCourtOrder);
  }

  get payeeControl(): FormControl {
    return this._getControlByFixedKey(FixedPropertyKey.Payee);
  }

  get inactivationDateControl(): FormControl {
    return this._getControlByFixedKey(EMPLOYER_FIXED_PROPERTIES.InactivationDate);
  }

  get inactiveControl(): FormControl {
    return this._getControlByFixedKey(EMPLOYER_FIXED_PROPERTIES.Inactive);
  }

  get eligibleForColaControl(): FormControl {
    return this._getControlByFixedPropertyId(CourtOrderPropertiesGUIDs.EligibleForCOLA);
  }

  get colaPercentageControl(): FormControl {
    return this._getControlByFixedPropertyId(CourtOrderPropertiesGUIDs.COLAPercentage);
  }

  get relationshipControl(): FormControl {
    return this._getControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship);
  }

  get legalRelationshipTypeControl(): FormControl {
    return this._getControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.LegalRelationshipType);
  }

  get beneficiaryPercentageControl(): FormControl {
    return this._getControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Percentage);
  }

  get marriageDateControl(): FormControl {
    return this._getControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.MarriageDate);
  }

  get divorceDateControl(): FormControl {
    return this._getControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.DivorceDate);
  }

  private _getControlByFixedPropertyId(propertyId: string): FormControl {
    const fieldName = this.controls?.find(
      (ctrl: FormControlDetail) => ctrl?.data?.entityPropertyId?.toUpperCase() === propertyId?.toUpperCase()
    )?.name ?? '';
    return this.formGroup.get(fieldName) as FormControl;
  }

  private _getControlByFixedKey(propertyKey: string): FormControl {
    const entityPropety = this._findPropertyByFixedKey(this.properties, propertyKey);
    return this.formGroup.get(entityPropety?.entityPropertyId ?? '') as FormControl;
  }

  private _findPropertyByFixedKey(listProperty: any[], propertyKey: string) {
    return listProperty?.find((x) => x.configs?.fixedKey === propertyKey);
  }

  private _findControlByFixedPropertyId(propertyId: string) {
    return this.controls?.find(
      (ctrl: FormControlDetail) => ctrl?.data?.entityPropertyId?.toUpperCase() === propertyId?.toUpperCase()
    ) ?? null;
  }

  private _findRelationshipEntityReferenceComponentByFixedPropertyId(propertyId: string) {
    return this.entityReferenceListOfRelationshipCard?.find(
      (item: EditPropertyEntityReferenceComponent) => 
        item?.property?.entityPropertyId?.toUpperCase() === propertyId.toUpperCase()
    ) ?? null;
  }

  private _lineOfDutyDeathValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) return null;
      if (control.value && !this.dateOfDeathControl?.value) {
        return {
          invalidLineOfDutyDeath: 'Invalid Line of Duty Death.',
        };
      }

      return null;
    };
  }

  private getLabelByEntityPropertyId(array: InitiationProperty[], entityPropertyId: string, defaultValue: string) {
    const filteredArray = array.filter(item => item.entityPropertyId?.toLowerCase() === entityPropertyId?.toLowerCase());
    return filteredArray.length > 0 ? filteredArray[0].label : defaultValue;
  }

  private getJobHistoryErrorByKey(errorKey: string): any {
    const beginDateLabel = this.getLabelByEntityPropertyId(this.properties, JOB_HISTORY.BeginDate, 'Begin Date');
    const endDateLabel = this.getLabelByEntityPropertyId(this.properties, JOB_HISTORY.EndDate, 'End Date');

    const errorObj: { [key: string]: { begin: string | null, end: string | null } } = {
      begin_greater_end: {
        begin: `${beginDateLabel} must be earlier or equal to ${endDateLabel}.`,
        end: null
      },
      begin_less_date_of_birth: {
        begin: `${beginDateLabel} must be after Date of Birth.`,
        end: null
      },
      end_greater_date_of_death: {
        begin: "",
        end: `${endDateLabel} must be prior to Date of Death.`
      },
      end_blank_when_death: {
        begin: null,
        end: `${endDateLabel} cannot be blank and must be prior or equal to Date of Death.`
      },
      overlap: {
        begin: null,
        end: `${endDateLabel} has an overlapping employment record.`
      },
      begin_empty: {
        begin: `${beginDateLabel} must not be empty.`,
        end: null
      },
      existed_services: {
        begin: `You cannot edit this record because there is already a granted service from your previously selected ${beginDateLabel} and ${endDateLabel}.`,
        end: null
      },
      no_err: {
        begin: null,
        end: null
      }
    };
    return errorObj[errorKey];
  }

  private getServiceHistoryErrorByKey(errorKey: string): any {
    const beginDateLabel = this.getLabelByEntityPropertyId(this.properties, SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.StartDate, 'Begin Date');
    const endDateLabel = this.getLabelByEntityPropertyId(this.properties, SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.EndDate, 'End Date');

    const errorObj: { [key: string]: { begin: string | null, end: string | null } } = {
      begin_greater_end: {
        begin: `${beginDateLabel} must be earlier or equal to ${endDateLabel}.`,
        end: null
      },
      begin_less_date_of_birth: {
        begin: `${beginDateLabel} must be after Date of Birth.`,
        end: null
      },
      end_greater_date_of_death: {
        begin: "",
        end: `${endDateLabel} must be prior to Date of Death.`
      },
      end_blank_when_death: {
        begin: null,
        end: `${endDateLabel} cannot be blank and must be prior or equal to Date of Death.`
      },
      overlap: {
        begin: null,
        end: `${endDateLabel} has an overlapping service record.`
      },
      begin_empty: {
        begin: `${beginDateLabel} must not be empty.`,
        end: null
      },
      none_overlap_job_history: {
        begin: null,
        end: 'No job history found in the selected employer during this period.'
      },      
      no_err: {
        begin: null,
        end: null
      }
    };

    return errorObj[errorKey];
  }

  private validateJobHistoryOnBlur(isBeginDateChanged: boolean = false, isEndDateChanged: boolean = false): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {return of(null);}
      const beginDate = this.beginDateControl?.value;
      const endDate = this.endDateControl?.value;
      const employerId = this.selectedRecordId[JOB_HISTORY.Employer_Employer_Id.toLocaleLowerCase()];
      const beginDateParam = beginDate ? getDateFormatISO(beginDate?.toString()) : '';
      const endDateParam = endDate ? getDateFormatISO(endDate?.toString()) : '';  

  
      const recordId = this.data?.filter((item: any) =>
        item.entityComponentId.toLocaleLowerCase() === JOB_HISTORY.EntityComponentId.toLocaleLowerCase()
      )[0]?.['recordId'];
      //Clear err on control
      this.beginDateControl.setErrors(null);
      this.endDateControl.setErrors(null);
      
      this.beginDateControl.markAsTouched();
      this.endDateControl.markAsTouched();

      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.validateJobHistoryDateRange(beginDateParam, endDateParam, this.targetId, employerId, recordId).pipe(
              map((res: any) => {
                const err = this.getJobHistoryErrorByKey(res?.errorCode);
                if (res?.isError && res?.errorCode != 'existed_services') {
                  //Jobhistory special validator, if we change one of theme, err value may apply for another control
                  if (err.begin) this.beginDateControl.setErrors({ errorMsg: err.begin });
                  if (err.end) this.endDateControl.setErrors({ errorMsg: err.end });
                  return (err.begin && isBeginDateChanged || isEndDateChanged && err.end)
                    ? { errorMsg: isBeginDateChanged ? err.begin : isEndDateChanged ? err.end : 'err' } : {};
                }
                return {};
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }

  private validateServiceHistoryOnBlur(isBeginDateChanged: boolean = false, isEndDateChanged: boolean = false): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {return of(null);}
      const beginDate = this.beginDateServiceHistoryControl?.value;
      const endDate = this.endDateServiceHistoryControl?.value;

      const employerId = this.selectedRecordId[SERVICE_HISTORY_LIST_PROPERTY_FIXED_ID.Employer.toLocaleLowerCase()];

      const beginDateParam = beginDate ? getDateFormatISO(beginDate?.toString()) : '';
      const endDateParam = endDate ? getDateFormatISO(endDate?.toString()) : '';  
  
      const recordId = this.data?.filter((item: any) =>
        item.entityComponentId.toLocaleLowerCase() === SERVICE_HISTORY_LIST.Id.toLocaleLowerCase()
      )[0]?.['recordId'];
      //Clear err on control
      this.beginDateServiceHistoryControl.setErrors(null);
      this.endDateServiceHistoryControl.setErrors(null);
      
      this.beginDateServiceHistoryControl.markAsTouched();
      this.endDateServiceHistoryControl.markAsTouched();

      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.validateServiceHistoryDateRange(beginDateParam, endDateParam, this.targetId, employerId, recordId).pipe(
              map((res: any) => {
                const err = this.getServiceHistoryErrorByKey(res?.errorCode);
                if (res?.isError) {
                  //Jobhistory special validator, if we change one of theme, err value may apply for another control
                  if (err.begin) this.beginDateServiceHistoryControl.setErrors({ errorMsg: err.begin });
                  if (err.end) this.endDateServiceHistoryControl.setErrors({ errorMsg: err.end });
                  return (err.begin && isBeginDateChanged || isEndDateChanged && err.end)
                    ? { errorMsg: isBeginDateChanged ? err.begin : isEndDateChanged ? err.end : 'err' } : {};
                }
                return {};
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }

  private _onChangeDateOfDeathValue() {
    this.dateOfDeathControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.lineOfDutyDeathControl?.updateValueAndValidity();
      if (value) {
        this.reportedDeceasedControl?.setValue(true);
      }
      this.reportedDeceasedControl?.updateValueAndValidity();
    });
  }

  private _onChangeNewHireExamValue() {
    this.newHireExamControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isNewHireExam) => {
      if (isNewHireExam) {
        this.newHireExamDateControl.enable();
      } else {
        this.newHireExamDateControl.disable();
        this.newHireExamDateControl.reset();
      }
      const propetyNewHireExamDate = this._findPropertyByFixedKey(this.properties, FixedPropertyKey.NewHireExamDate);
      const newHireExamDate = this.controls?.find((x) => x.name === propetyNewHireExamDate?.entityPropertyId);
      newHireExamDate!.isHidden = !isNewHireExam;
      newHireExamDate!.isRequired = isNewHireExam;
    });
  }

  private _checkIsCallApiConfirmRetireRehire(): boolean {
    const retireRehireProperty = this._findPropertyByFixedKey(this.data, FixedPropertyKey.RetireRehire);
    return !!(this.isEdit &&
      this.targetId &&
      retireRehireProperty &&
      this.retireRehireControl &&
      !this.retireRehireControl.value &&
      this.retireRehireControl.value !== retireRehireProperty.value);
  }

  private _validateCourtOrderStatus(data: any, callback: (request: any) => void): void {
    if (!this._findPropertyByFixedKey(this.properties, FixedPropertyKey.StatusCourtOrder)) {
      callback(data);
      return;
    }
    if (![StatusCourtOrder.Terminated, StatusCourtOrder.Rejected].includes(this.statusCourtOrderControl.value)) {
      callback(data);
      return;
    }
    const courtOrderTypeProperty = this._findPropertyByFixedKey(this.properties, FixedPropertyKey.CourtOrderType);
    const caseNumberControl = this.formGroup.get(FixedPropertyId.CaseNumber.toLowerCase()) as FormControl;
    if (
      this.isEdit &&
      this.targetId &&
      courtOrderTypeProperty &&
      caseNumberControl &&
      this.courtOrderTypeControl?.value !== courtOrderTypeProperty.value
    ) {
      this.entityDataService
        .validateCourtOrder({
          memberId: this.targetId,
          caseNumber: caseNumberControl.value,
          courtOrderType:
            this.courtOrderTypeControl?.value === this.qdroValueId
              ? CourtOrderType.QDRO
              : CourtOrderType.Garnishment,
        })
        .subscribe((response) => {
          if (response.isChangedStatus) {
            callback(data);
            return;
          }
          const text =
            response.courtOrderType === CourtOrderType.Garnishment
              ? 'The garnishment has been setup as a deduction. Please remove the deduction from the benefit before terminating this court order.'
              : 'The QDRO is still being collected. Please remove the deduction and terminate the QDRO benefit before terminating this court order.';
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              title: 'Warning',
              text,
              type: ConfirmType.Warning,
              hideConfirmButton: true,
              cancelButtonTitle: ConfirmPopupCancelButtonTitle.Close,
            },
          });
        });
      return;
    }
    callback(data);
  }

  private _showConfirmPopupRetireRehire(request: any, callback: (request: any) => void) {
    if (!this._checkIsCallApiConfirmRetireRehire()) {
      callback(request);
      return;
    }
    const newRetireRehire = this.retireRehireControl?.value;
    this.entityDataService.confirmRetireRehire(this.targetId, newRetireRehire).subscribe((res) => {
      if (res?.isShowConfirmPopup) {
        const message = newRetireRehire
          ? 'This participant was included in Annual Certification(s). Do you want to remove it from Annual Certification(s)?'
          : 'This participant was not included in unposted Annual Certification(s). This participant can be added back to Annual Certification(s) where eligible and Annual Certification Status will be reset to "1 - Not Complete". Do you want to include?';
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: 'Confirmation',
            text: message,
            type: ConfirmType.ConfirmSave,
            saveButtonTitle: ConfirmPopupSaveButtonTitle.Yes,
            cancelButtonTitle: ConfirmPopupCancelButtonTitle.No,
            hideSaveAsButton: true,
            hideConfirmButton: true,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          request.isConfirmRetireRehire = !!result;
          callback(request);
        });
      } else {
        callback(request);
      }
    });
  }

  private _validateInactivationDate(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {
        return of(null);
      }
      const inactivationDateValue = new DatePipe('en-US').transform(control.value, DATE_FORMAT_YMD);

      if (inactivationDateValue === null) {
        return of(null);
      }

      const body: EmployerCheckDisableRequest = {
        employerId: this.targetId ?? '',
        inactivationDate: inactivationDateValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.getMunicipalityCheckDisable(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { dateValidationError: res?.message };
                }
                if (this.inactiveControl?.value === true) {
                  return null;
                }
                if (this.currentInactivationDate) {
                  this.inactiveControl?.setValue(true);
                  return null;
                }
                const dialogRef = this.dialog.open(ConfirmPopupComponent, {
                  panelClass: 'confirm-popup',
                  data: {
                    title: 'Confirmation',
                    text: res.message,
                    type: ConfirmType.ConfirmSave,
                    saveButtonTitle: ConfirmPopupSaveButtonTitle.Yes,
                    cancelButtonTitle: ConfirmPopupCancelButtonTitle.Cancel,
                    hideSaveAsButton: true,
                    hideConfirmButton: true,
                  },
                });
                dialogRef.afterClosed().subscribe((result: any) => {
                  if (!result) {
                    control.setValue(this.currentInactivationDate);
                    return;
                  }
                  this.inactiveControl?.setValue(true);
                });
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }

  private _validateDateOfBirth(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.pristine !== false) {
        return of(null);
      }
      const dateofBirthValue = new DatePipe('en-US').transform(control.value, 'yyyy-MM-dd');

      if (dateofBirthValue === null) {
        return of(null);
      }

      const body: EmployerCheckDateOfBirthRequest = {
        memberId: this.targetId ?? '',
        dateOfBirth: dateofBirthValue ?? '',
      };
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.getMunicipalityCheckDateOfBirth(body).pipe(
              map((res: any) => {
                if (res?.isError) {
                  return { errorMsg: res?.message };
                }
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }

  private _onChangeInactivationDateValue() {
    this.inactivationDateControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((inactivationDate) => {
      if (!this.currentInactivationDate && !inactivationDate) {
        this.inactiveControl?.setValue(false);
        return;
      }
      if (this.currentInactivationDate && this.inactivationDateControl.valid && !inactivationDate) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: 'Confirmation',
            text: 'This Municipality is inactive. Are you sure you want to reactivate this Municipality?',
            type: ConfirmType.ConfirmSave,
            saveButtonTitle: ConfirmPopupSaveButtonTitle.Yes,
            cancelButtonTitle: ConfirmPopupCancelButtonTitle.Cancel,
            hideSaveAsButton: true,
            hideConfirmButton: true,
          },
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (!result) {
            this.inactivationDateControl?.setValue(this.currentInactivationDate);
            return;
          }
          this.inactiveControl?.setValue(false);
        });
      }
    });
  }

  checkTextbox(data?: FieldData[]) {
    const checkCountry = data?.find((item: FieldData) => item.key === 'country');
    const checkCode = checkCountry?.lstOption?.find((item: any) => item.value === checkCountry?.formControl?.value)!;
    return checkCode && checkCode?.code === 'USA' ? true : false;
  }

  changeValueAddress(field: FieldData, fieldDatas?: FieldData[], data?: FormControlDetail) {
    if (field.key === 'country') {
      this.checkTextbox(fieldDatas);
    }
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  changeValueInputAddress(data: FormControlDetail) {
    this.validateEffectForm(data);
    this.validateAddressField(data);
  }

  validateEffectForm(data?: FormControlDetail) {
    if (!this.isEffectiveData || data?.isRequired) return;
    const checkValueForm = data?.lstChildFormControl?.find((item: FieldData) => item?.formControl?.value);
    const effectFrom = data?.lstChildFormControl?.find((item: FieldData) => item?.key === 'effectFrom');
    if (effectFrom) {
      effectFrom.isSetRequired = checkValueForm ? true : false;
    }
  }

  validateAddressField(data?: FormControlDetail) {
    if (data?.isRequired) return;
    const checkValueForm = data?.lstChildFormControl?.find((item: FieldData) => item?.formControl?.value);
    data?.lstChildFormControl?.forEach((item: FieldData) => {
      if (!['effectTo', 'street2'].includes(item.key)) {
        item.isSetRequired = checkValueForm ? true : false;
        if (checkValueForm) {
          item?.formControl?.addValidators(Validators.required);
        } else {
          item?.formControl?.removeValidators(Validators.required);
        }
        item?.formControl?.updateValueAndValidity();
      }
    });
  }

  changeLookup(data: FormControlDetail, value: any) {
    // Used for Relationship List Card only
    if (data?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship.toUpperCase() && this.isRelationshipCard) {
      const marriageDateControl = this.controls.find(
        (ctrl: FormControlDetail) => ctrl?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.MarriageDate.toUpperCase()
      );
      if (marriageDateControl) {
        marriageDateControl.isHidden = !ALLOW_SHOW_MARRIAGE_DATE_WITH_RELATIONSHIP_VALUES.includes(value?.extraData?.relationshipType);
        if (marriageDateControl.isHidden) {
          marriageDateControl?.formControl?.setValue(null);
          if (marriageDateControl?.formControl && marriageDateControl?.formControl.hasValidator(Validators.required)) {
            marriageDateControl?.formControl.removeValidators(Validators.required);
            marriageDateControl?.formControl.updateValueAndValidity({ onlySelf: true });
          } 
        } else {
          if (marriageDateControl?.formControl && marriageDateControl.isRequired) {
            marriageDateControl.formControl.addValidators(Validators.required);
            marriageDateControl.formControl.updateValueAndValidity({ onlySelf: true });
          }
        }
      }

      const divorceDateControl = this.controls.find(
        (ctrl: FormControlDetail) => ctrl?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.DivorceDate.toUpperCase()
      );
      if (divorceDateControl) {
        divorceDateControl.isHidden = !ALLOW_SHOW_DIVORCE_DATE_WITH_RELATIONSHIP_VALUES.includes(value?.extraData?.relationshipType);
        if (divorceDateControl.isHidden) {
          divorceDateControl?.formControl?.setValue(null);
          if (divorceDateControl?.formControl && divorceDateControl?.formControl.hasValidator(Validators.required)) {
            divorceDateControl?.formControl.removeValidators(Validators.required);
            divorceDateControl?.formControl.updateValueAndValidity({ onlySelf: true });
          } 
        } else {
          if (divorceDateControl?.formControl && divorceDateControl.isRequired) {
            divorceDateControl.formControl.addValidators(Validators.required);
            divorceDateControl.formControl.updateValueAndValidity({ onlySelf: true });
          }
        }
      };
    }
    // End of Used for Relationship List Card only

    if (data?.configs?.fixedKey === FixedPropertyKey.StatusCourtOrder) {
      const selectedStatusCourtOrder = data?.lstOption?.find((item: any) => item.value === data?.formControl.value);
      const checkRejectReason = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.RejectReason);
      if (checkRejectReason) {
        const listRejectReason = [StatusCourtOrder.Terminated, StatusCourtOrder.Rejected];
        checkRejectReason.isHidden = listRejectReason.includes(selectedStatusCourtOrder.value) ? false : true;
        checkRejectReason.isRequired = listRejectReason.includes(selectedStatusCourtOrder.value) ? true : false;
        checkRejectReason?.formControl.setValue('');
        if (listRejectReason.includes(selectedStatusCourtOrder.value)) {
          checkRejectReason?.formControl.addValidators(Validators.required);
        } else {
          checkRejectReason?.formControl.removeValidators(Validators.required);
        }
        checkRejectReason?.formControl.updateValueAndValidity();
      }
    }

    if (data?.configs?.fixedKey === FixedPropertyKey.CourtOrderType) {
      const selectedCourtOrder = data?.lstOption?.find((item: any) => item.value === data?.formControl.value);
      this.payeeControl?.enable();
      const body: PayeeRequest = {
        memberId: this.targetId,
        courtOrderType:
          selectedCourtOrder.code === COURT_ORDER_TYPE_CODE.QDRO ? CourtOrderType.QDRO : CourtOrderType.Garnishment,
        screenId: this.screenId,
        courtOrderComponentId: this.entityComponentId,
      };
      if (this.isEdit) {
        body.payeeId = this.payeeId;
      }
      const checkPayee = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.Payee);
      if (checkPayee) {
        checkPayee.lstOption = [];
        checkPayee?.formControl.setValue('');
      }
      this.memberStore.dispatch(getPayeePropertiesAction({
        body
      }));
      const checkRelationship = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip);
      this.relationshipLookup = checkRelationship;
      if (checkRelationship) {
        checkRelationship.isHidden = selectedCourtOrder.code !== COURT_ORDER_TYPE_CODE.QDRO;
        checkRelationship.isRequired = selectedCourtOrder.code === COURT_ORDER_TYPE_CODE.QDRO;
        checkRelationship?.formControl.setValue('');
        if (selectedCourtOrder.code === COURT_ORDER_TYPE_CODE.QDRO) {
          checkRelationship?.formControl.addValidators(Validators.required);
        } else {
          checkRelationship?.formControl.removeValidators(Validators.required);
        }
        checkRelationship?.formControl.updateValueAndValidity();
      }

      // Reset all Payee Information fields when Court Order Type change to not QDRO
      // Add / Remove validations for specified fields also
      if (data?.formControl.value !== this.qdroValueId) {
        this.clearValidationCanNotAfterTodayForSpecifiedFields();
        this.clearSearchPayeeContent();
      } else {
        this.addValidationCanNotAfterTodayForSpecifiedFields();
      }
    }

    if (data?.configs?.fixedKey === FixedPropertyKey.Payee) {
      const selectedPayee = data?.lstOption?.find((item: any) => item.value === data?.formControl.value);
      const checkRelationship = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip)!;
      if (selectedPayee) {
        checkRelationship.lstOption.push({
          displayValue: selectedPayee.relationship,
          value: selectedPayee.relationshipLookupId,
        });
        checkRelationship?.formControl.setValue(selectedPayee.relationshipLookupId);
      }
    }
    
    if (this.isList && (this.isCourtOrder || this.isRelationshipCard)) {
      this.currentRelationshipValue = value?.extraData?.relationshipType;
      if (this.currentRelationshipValue === RelationshipOptions.Spouse) {
        this.isSelectedSpouse = true;
      }
      const customError = this.validateRelationship(value?.extraData?.relationshipType);
      if (customError) {
        data.formControl.setErrors({ customError });
      }
      else {
        if (data.formControl?.hasValidator(Validators.required)) {
          data.formControl.clearValidators();
          
          // Keep validator required if have
          data.formControl.addValidators(Validators.required);
        } else {
          data.formControl.clearValidators();
        }
        data.formControl!.updateValueAndValidity();
      }
    }
  }

  validateRelationship(relationshipType: number | null) {
    const relationshipCtrl = this.controls
      ?.find(
        (ctrl: FormControlDetail) => 
          ctrl?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship.toUpperCase()
      );
    
    if (this.relationshipControl?.value) {
      let isPerson =  this.entityReal === ENTITY_PERSON_GUID;
      let isTrust = this.entityReal?.toUpperCase() === ENTITY_MAPPING.Trust.toUpperCase();
      let isEstate = this.entityReal?.toUpperCase() === ENTITY_MAPPING.Estate.toUpperCase();

      if (this.isEditListRecord 
        && this.isRelationshipCard 
        && this.isPayeeUsedInCourtOrder
        && this.relationshipControl) {
        if (this.relationshipControl?.value !== this.initialRelationshipValueSelected) {
          let relationshipLabel = relationshipCtrl?.label ?? '';
          return `The ${relationshipLabel} cannot be changed because it's used in a court order.`;
        } else {
          // Hide error inside Releted Entity if Relationship selected correct
          this.hideRelatedEntityUsedInCourtOrderError();
        }
      }

      // @-> This validate will be remove until we got latest informations
      // if (relationshipType === RelationshipOptions.ExSpouse
      //   && this.currentLegalRelationshipTypeChipList?.some((item: LegalRelationshipTypeOption) => item?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary)
      // ) {
      //   return 'Beneficiary cannot be an Ex-Spouse';
      // }
      
      if (
        relationshipType
        && [
          RelationshipOptions.Spouse,
          RelationshipOptions.Child,
          RelationshipOptions.ExSpouse,
          RelationshipOptions.Parent,
          RelationshipOptions.Sibling,
          RelationshipOptions.DomesticPartner,
        ].includes(relationshipType)
        && (isTrust || isEstate)
      ) {
        const currentRelationshipDisplayValue = relationshipCtrl?.lstOption?.find((item: any) => item?.value === relationshipCtrl?.formControl?.value)?.displayValue ?? '';
        return `${currentRelationshipDisplayValue} must be a Person.`;
      } else if (RelationshipOptions.Organization === relationshipType && isPerson) {
        return `This relationship must be a Trust or Estate.`;
      }
      
      if (RelationshipOptions.Spouse === relationshipType
        && this.isAtLeastOneRecordIsSpouse
        && this.relationshipControl?.value !== this.initialRelationshipValueSelected) {
        return 'Only one spouse is allowed. Please change the previous spouse to ex-spouse first.';
      }
    }
    return null;
  }

  getPayeeProperties() {
    const checkPayee = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.Payee);
    if (checkPayee) {
      checkPayee.lstOption = [];
      checkPayee?.formControl.setValue('');
    }
    this.memberStore
      .pipe(
        select(getPayeePropertiesSelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state: any) => {
        this.layoutService.showLoading = !!state?.isLoading;
        this.memberStore.dispatch(clearPayeePropertiesStateAction());
        if (state?.success === true && state?.payload) {
          // Keep old Garnishment flow
          if (checkPayee) {
            checkPayee.lstOption = state?.payload?.payeeOptions.map((item: PayeeOption) => {
              return {
                ...item,
                displayValue: item.name,
                value: item.id,
              };
            });
            const checkRelationship = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip);
            const selectedPayee = checkPayee?.lstOption?.find((item: any) => item.value === checkPayee?.formControl.value);
            if (selectedPayee && checkRelationship) {
              checkRelationship.lstOption = [];
              checkRelationship?.formControl.setValue('');
              if (!state?.payload.courtOrderType) {
                checkRelationship.lstOption.push({
                  displayValue: selectedPayee.relationship,
                  value: selectedPayee.relationshipLookupId,
                });
                checkRelationship?.formControl.setValue(selectedPayee.relationshipLookupId);
              }
            }
          }

          // New QDRO flow
          if (checkPayee && state?.payload.courtOrderType === CourtOrderType.QDRO) {
            this.searchPayeeResultList = deepClone(state?.payload?.payeeOptions);

            if (this.isEdit && this.isCourtOrder && this.courtOrderTypeControl?.value === this.qdroValueId) {
              this.selectedPayee = this.searchPayeeResultList?.find((payee) => payee?.id === this.currentEditingCourtOrderQdroPayeeId) ?? null;

              // Prepare initial form data for Search Payee in case Edit Court Order QDRO with Status = Pending
              this.showClearSearchPayeeContent = false;
              this.valuePayeeSearch = this.selectedPayee?.name ?? '';
            }
          }
        }
      });
  }

  getListUnique() {
    return this.controls
      .filter((el) => el.configs?.unique === 'true')
      .map((item) => {
        return { name: item.name, value: item.formControl.value };
      });
  }

  getUniqueOtherReference(property: InitiationProperty) {
    return this.createUniqueOtherReference(property);
  }

  onRecordChange({ id, value }: { id: string; value: string }) {
    this.selectedRecordId[id] = value;
    this.isAddNew = false;
    this.updateValidation();

    this.erControls?.[id]?.forEach((control: any) => {
      control.recordId = value;
    });

    // If in services history or job history, remove triangle validation when change ER.
    this._clearTriangleValidationErrorMessage();
  }

  getEntityReal(entityReal: string) {
    this.entityReal = entityReal;
    this.updateValidation();
  }

  onAddNewEntityReference(addNew: boolean) {
    this.isAddNew = addNew;
    this.updateValidation();
  }

  updateValidation() {
    if (this.currentRelationshipValue != null) {
      const customError = this.validateRelationship(this.currentRelationshipValue);
      const checkRelationship = this.controls.find(item => item.data?.options[0]?.relationshipType !== null);
      checkRelationship?.formControl.markAsTouched();
      if (customError)
        checkRelationship?.formControl.setErrors({ customError });
      else {
        checkRelationship?.formControl.clearValidators();
        checkRelationship?.formControl.updateValueAndValidity();
      }
    }
  }

  private _validateReportedDeceased(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!this.listMemberParticipants.includes(this.entityId) || control.value) {
        return of(null);
      }
      const formValue =this.formGroup.getRawValue();
      const isIncludeDateOfDeath = Object.keys(formValue).includes(PERSON_BASE_PROPERTIES.DateOfDeath.toLowerCase());
      const customError = {customError: 'Date of Death must be blank before Reported Deceased can be unchecked.'};
      if (isIncludeDateOfDeath) {
        if (!!formValue[PERSON_BASE_PROPERTIES.DateOfDeath.toLowerCase()]) {
          return of(customError);
        }
        return of(null);
      }
      return timer(100).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.entityDataService.validateDateOfDeath([this.targetId]).pipe(
              map((res: ValidateReportedDeceasedResponse) => {
                if (res.infos.find(item => item.id === this.targetId)?.dateOfDeath) {
                  return customError;
                }
                return null;
              }),
              finalize(() => {
                this.formGroup.updateValueAndValidity();
              }),
            ),
        ),
      );
    };
  }

  private _validatePaymentInProgress(data: any, callback: (request: any) => void): void {
    if (this.dateOfDeathControl && this.reportedDeceasedControl && !this.dateOfDeathControl.value && !this.reportedDeceasedControl.value) {
      callback(data);
      return;
    }
    this.entityDataService
      .checkPaymentInProgress([this.targetId])
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((isWarning) => {
        if (!isWarning) {
          callback(data);
          return;
        }
        const dialog = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: 'Warning',
            text: 'A payment is in progress for this payee. Please go to Processing module to make changes if needed.',
            type: ConfirmType.Warning,
            hideConfirmButton: true,
            cancelButtonTitle: BUTTON_LABEL_CLOSE,
          },
        });
        dialog.afterClosed().subscribe(() => {
          callback(data);
        })
      });
  }
  
  onGetDataReference(data: EntityReferenceData) {
    if (!this.entityReferenceDatas
      .some(x => x.entityId === data.entityId && x.entityPropertyId === data.entityPropertyId && x.recordId === data.recordId)
    ) {
      this.entityReferenceDatas.push(data);
    }
  }

  handleWhenSelectPayee(payee: CourtOrderQdroSelectedPayee) {
    const payeeDropdown = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.Payee)
    const checkRelationship = this.controls?.find(item => item.configs?.fixedKey === FixedPropertyKey.RelationShip)!;
    if (payee) {
      checkRelationship.lstOption.push({
        displayValue: payee?.relationship,
        value: payee?.relationshipLookupId,
      });
      checkRelationship?.formControl.setValue(payee.relationshipLookupId);
    }

    this.currentRelationshipValue = undefined;
    if (payeeDropdown) {
      payeeDropdown?.formControl?.clearValidators();
    }
  }

  registerGetPayeeInfoSelector() {
    this.memberStore.pipe(select(getCourtOrderPayeeInfoSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.layoutService.showLoading = !!state?.isLoading;
        if (state?.success === true) {
          // Store orginal Person Name of Payee Information used to trigger Name Change Reason logics for ADD case
          this.currentPayeeInformationNameObj = state?.payload?.record?.[CourtOrderPropertiesGUIDs.PersonName.toLowerCase()] ?? null;

          // Mapping Payee Info data into Form controls
          this.mappingPayeeInfoToForm(deepClone(state?.payload?.record));
        } else {
          this.currentPayeeInformationNameObj = null;
        }
        this.memberStore.dispatch(clearGetCourtOrderPayeeInfoStateAction())
      }
    });
  }

  mappingPayeeInfoToForm(data: any) {
    if (!data) return;

    // Filter Payee Information controls
    this.controls
      ?.filter(
        (ctrl: FormControlDetail) => ctrl?.data?.isPayeeInformationProperty
      )
      ?.forEach(
        (element: FormControlDetail, idx: number) => {
          // Prepare Property value
          let propValue = data?.[element?.name] 

          element.recordId = data?.[ENTITY_ID_GUID] || propValue?.recordId;
          // Check exists entity property value
          if (element?.configs?.unique === 'true' && element?.recordId) {
            element.formControl.clearAsyncValidators();
            element.formControl.addAsyncValidators(
              checkApiValidator(
                this.entityPropertyService.checkExistPropertyValue,
                'value',
                undefined,
                {
                  params: {
                    entityId: element?.data?.entityId,
                    componentId: element?.data?.entityComponentId,
                    propertyId: element?.data?.entityPropertyId,
                    id: this.selectedPayee?.id ?? null,
                    propertyType: element.type,
                  },
                },
                () => {
                  this.formGroup.updateValueAndValidity();
                },
              ),
            );
            element.formControl.updateValueAndValidity();
          }

          let value = propValue?.valueObj || propValue?.value || propValue || null;

          if (value?.maskedValue || value?.maskedValue === null || value?.maskedValue === '') {
            value = value.originalValue;
          }

          if (isEmpty(value) && element.type !== EntityPropertyType.Binary) {
            return;
          }
          
          if (element.type == EntityPropertyType.Address || element.type == EntityPropertyType['Person Name']) {
            element.lstChildFormControl?.forEach((item: any) => {
              if (item?.key === 'country') {
                if (value[item.key]) {
                  item.formControl?.setValue(value[item.key]);
                }
              } else {
                item.formControl?.setValue(
                item?.key === 'effectFrom' && value?.isClearEffectFrom ? null : value[item.key] || null,
              );
              }
            });
            if (element.type == EntityPropertyType.Address) {
              element.isActive = value?.id ? true : false;
            }
          } else if (element.type == EntityPropertyType.Phone) {
            element.formControl?.setValue(this.formatPhoneNumberInput(value));
          } else if (element.type == EntityPropertyType.Binary) {
            element.formControl?.setValue(value === 'true' || value ? true : false);
          } else if (element.type == EntityPropertyType.Status) {
            element.lstChildFormControl?.forEach((el: any) => {
              el.formControl?.setValue(value[el.key]);
              if (el.key === 'event' && propValue?.options) {
                this.listStatusEvent = propValue?.options
                  ?.find((x: DetailOptionStatus) => x.id === value['status'])
                  ?.events?.map((item: any) => {
                    return {
                      value: item.id,
                      displayValue: item.name,
                    };
                  });
              } else if (!propValue?.options) {
                this.setStatus(el);
              }
            });
          } else if (element.type == EntityPropertyType.SSN) {
            element.formControl?.setValue(value);
          } else if (element.type === EntityPropertyType['Date Time']) {
            element.formControl?.setValue(getDateString(value));
          } else {
            if (element?.fractionalLength) {
              const fractionalLengthValue = setFractionalLength(value, element?.fractionalLength);
              element.formControl?.setValue((+value)?.toFixed(fractionalLengthValue));
            } else {
              element.formControl?.setValue(value);
            }
          }
        }
      );

    // Enable/Disable fields after set values
    this.controls
      ?.filter(
        (ctrl: FormControlDetail) => ctrl?.data?.isPayeeInformationProperty
      )
      ?.forEach(
        (f: FormControlDetail, idx: number) => {
          if (f.formControl !== undefined) {
            if (!stringToBoolean(f.configs?.readOnly)) {
              f.formControl.enable();
            }
          } else {
            f.lstChildFormControl?.forEach((x: any) => {
              if (x.formControl !== undefined && !stringToBoolean(f.configs?.readOnly)) {
                x.formControl.enable();
              }
            });
          }
    });
  }

  clearPayeeInformationFormData() {
    this.controls
      ?.filter(
        (ctrl: FormControlDetail) => ctrl?.data?.isPayeeInformationProperty
      )
      ?.forEach(
        (element: FormControlDetail, idx: number) => {
          if (element.type == EntityPropertyType.Address || element.type == EntityPropertyType['Person Name']) {
            element.lstChildFormControl?.forEach((item: any) => {
              item.formControl?.setValue(null);
            });
          } else if (element.type == EntityPropertyType.Binary) {
            element.formControl?.setValue(false);
          } else if (element.type == EntityPropertyType.Status) {
            element.lstChildFormControl?.forEach((el: any) => {
              el.formControl?.setValue(null);
            });
          } else {
            element.formControl?.setValue(null);
          }
        }
      );
  }

  addValidationCanNotAfterTodayForSpecifiedFields() {
    NEED_VALIDATE_CAN_NOT_AFTER_TODAY_FIELDS_FIXED_ID.forEach((id: string) => {
      const field = this.controls.find((ctrl) => ctrl?.name?.toUpperCase() === id?.toUpperCase());

      if (field) {
        field.formControl.addValidators(this.canNotAfterTodayValidator(id, field?.label));
        field.formControl.updateValueAndValidity();
      }
    });
  }
  
  clearValidationCanNotAfterTodayForSpecifiedFields() {
    NEED_VALIDATE_CAN_NOT_AFTER_TODAY_FIELDS_FIXED_ID.forEach((id: string) => {
      const field = this.controls.find((ctrl) => ctrl?.name?.toUpperCase() === id?.toUpperCase());

      if (field) {
        field.formControl.removeValidators(this.canNotAfterTodayValidator(id, field?.label));
        field.formControl.updateValueAndValidity();
      }
    });
  }

  canNotAfterTodayValidator(fieldName: string, label: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Prepare data for validation
      const dateValue = control?.value;
      const date = new Date(DateTime.fromISO(dateValue).toFormat(DATE_FORMAT_YMD));
      date.setHours(0, 0, 0, 0);
      const dateTimestamp = date.getTime();

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const currentDateTimestamp = currentDate.getTime();

      // Date must be equal or lesser Current Date
      if (dateTimestamp && dateTimestamp > currentDateTimestamp) {
        return {
          errorMsg: `${label} cannot be in the future.`,
        }
      }

      // Default no error
      return null;
    };
  }

  checkingCourtOrderQDROWarningMsg() {
    const warningFieldsMessages: string[] = [];

    NEED_WARNING_SHOULD_NOT_AFTER_TODAY_FIELDS_FIXED_ID.forEach((id: string) => {
      const field = this.controls.find((ctrl) => ctrl?.name?.toUpperCase() === id?.toUpperCase());

      if (field) {
        const dateValue = field?.formControl?.value;
        const date = new Date(DateTime.fromISO(dateValue).toFormat(DATE_FORMAT_YMD));
        date.setHours(0, 0, 0, 0);
        const dateTimestamp = date.getTime();

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const currentDateTimestamp = currentDate.getTime();

        if (dateTimestamp && dateTimestamp > currentDateTimestamp) {
          warningFieldsMessages.push(`${field?.label} is in the future`);
        }
      }
    });

    NEED_WARNING_SHOULD_NOT_BLANK_FIELDS_FIXED_ID.forEach((id: string) => {
      const field = this.controls.find((ctrl) => ctrl?.name?.toUpperCase() === id?.toUpperCase());

      if (field) {
        const value = field?.formControl?.value;

        if (!value) {
          warningFieldsMessages.push(`${field?.label} is missing`);
        }
      }
    });

    return warningFieldsMessages;
  }

  formatWarningMessage(warningMsg: string[]) {
    let content: string = '';
    
    warningMsg.forEach((msg: string, idx: number) => {
      content += msg + `${idx === warningMsg?.length - 1 ? '. ' : ', '}`;
    });

    return CONFIRM_MSG_PREFIX + content + CONFIRM_MSG_SUFFIX;
  }

  onBinaryPropertyChange(chkVal: boolean) {
    if (this.isCourtOrder && this.courtOrderTypeControl?.value === this.qdroValueId) {
      if (!chkVal && this.colaPercentageControl) {
        this.colaPercentageControl.setValue('');
      }
    }
  }

  onSaveClicked() {
    if (this.isCourtOrder && this.courtOrderTypeControl?.value === this.qdroValueId && (!this.selectedPayee)) {
      this.showPayeeRequired = true;
    }
  }
 
  clearStates() {
    this.memberStore.dispatch(clearGetCourtOrderPayeeInfoStateAction());
    this.memberStore.dispatch(clearSetMemberEventStateAction());
    this.memberStore.dispatch(clearPayeePropertiesStateAction());
  }

  ngOnDestroy(): void {
    this.clearStates();
    super.ngOnDestroy();
  }

  addLegalRelationshipTypeChip() {
    const currentOpt = this.currentLegalRelationshipTypeOptionList?.find(
      (option) => option?.value === this.legalRelationshipTypeControl?.value
    );
    if (currentOpt) {
      // Reset control value to null
      this.legalRelationshipTypeControl.setValue(null);

      // Hide option from dropdown list
      currentOpt.isHide = true;

      // Add option to chip list
      this.currentLegalRelationshipTypeChipList.push(currentOpt);
      this.currentLegalRelationshipTypeChipList = sortByCriteria(
        this.currentLegalRelationshipTypeChipList, 'text'
      );

      // Show / Hide corresponding section
      this.showOrHideRelationshipListSection(currentOpt.value, false);

      // Trigger Relationship Validation
      this.updateValidation();
    }
  }

  removeLegalRelationshipTypeChip(value: string, idx: number) {
    const currentOpt = this.currentLegalRelationshipTypeOptionList?.find(
      (option) => option?.value === value
    );
    if (currentOpt) {
      // Hide option from dropdown list
      currentOpt.isHide = false;

      // Remove option to chip list
      this.currentLegalRelationshipTypeChipList.splice(idx, 1);

      // Show / Hide corresponding section
      this.showOrHideRelationshipListSection(currentOpt.value, true);

      // Trigger Relationship Validation
      this.updateValidation();
    }
  }

  prepareLegalRelationshipTypeRequestValue() {
    let val = '';
    if (!this.currentLegalRelationshipTypeChipList?.length) return val;
    this.currentLegalRelationshipTypeChipList?.forEach(
      (item: LegalRelationshipTypeOption, idx: number) => {
        val += item?.value + `${idx === this.currentLegalRelationshipTypeChipList?.length - 1 ? '' : ','}`;
      }
    );
    return val;
  }

  showOrHideRelationshipListSection(id: string, isHidden: boolean) {
    this.controls
      ?.filter(
        (ctrl: FormControlDetail) => 
          ctrl?.data?.belongLegalRelatioshipTypeId?.toUpperCase() === id?.toUpperCase()
      )
      ?.forEach(
        (element: FormControlDetail) => {
          // Hide/Show controls
          element!.isHidden = isHidden;

          // Case Hide -> Set value to `null` and Remove Require validator
          if (isHidden) {
            if (element.type == EntityPropertyType.Address || element.type == EntityPropertyType['Person Name']) {
              element.lstChildFormControl?.forEach((item: FieldData) => {
                item?.formControl?.setValue(null);
                if (item?.formControl && item?.formControl.hasValidator(Validators.required)) {
                  item?.formControl.removeValidators(Validators.required);
                  item?.formControl.updateValueAndValidity({ onlySelf: true });
                }
              });
            } else if (element.type == EntityPropertyType.Status) {
              element.lstChildFormControl?.forEach((el: FieldData) => {
                el.formControl?.setValue(null);
                if (el.formControl && el.formControl.hasValidator(Validators.required)) {
                  el.formControl.removeValidators(Validators.required);
                  el.formControl.updateValueAndValidity({ onlySelf: true });
                }
              });
            } else if (element.type == EntityPropertyType['Entity Reference']) {
              const erComponent = this.entityReferenceListOfRelationshipCard?.find(
                (item: EditPropertyEntityReferenceComponent) => 
                  item?.property?.entityPropertyId?.toUpperCase() === element?.data?.entityPropertyId.toUpperCase()
              );
              if (erComponent) {
                erComponent.onClickClearSearchResult(true);
              }
            } else {
              element.formControl?.setValue(null);
              if (element.formControl.hasValidator(Validators.required)) {
                element.formControl.removeValidators(Validators.required);
                element.formControl.updateValueAndValidity({ onlySelf: true });
              }
            }
          }

          // Case Show -> Only Restore Require validator with Control has config tobe Require by user or Require by Business logic
          if (!isHidden) {
            if (element.type == EntityPropertyType.Address || element.type == EntityPropertyType['Person Name']) {
              element.lstChildFormControl?.forEach((item: FieldData) => {
                if (item.formControl && (item.isSetRequired || item.required)) {
                  item.formControl.addValidators(Validators.required);
                  item.formControl.updateValueAndValidity({ onlySelf: true });
                }
              });
            } else if (element.type == EntityPropertyType.Status) {
              element.lstChildFormControl?.forEach((el: FieldData) => {
                if (el.formControl && (el.isSetRequired || el.required)) {
                  el.formControl.addValidators(Validators.required);
                  el.formControl.updateValueAndValidity({ onlySelf: true });
                }
              });
            } else {
              if (element.formControl && element.isRequired) {
                element.formControl.addValidators(Validators.required);
                element.formControl.updateValueAndValidity({ onlySelf: true });
              }
            }
          }
        }
      );
  }

  hideRelatedEntityUsedInCourtOrderError() {
    const existRelatedEntityErControl = !!this.controls?.find(
      (ctrl: FormControlDetail) => ctrl?.data?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.RelatedEntity.toUpperCase()
    );
    if (existRelatedEntityErControl) {
      const relatedEntityComponent = this.entityReferenceListOfRelationshipCard?.find(
        (item: EditPropertyEntityReferenceComponent) => 
          item?.property?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.RelatedEntity.toUpperCase()
      );
      if (relatedEntityComponent) {
        relatedEntityComponent.hidePayeeUsedInCourtOrderErrorMsg();
      }
    }
  }

  customPopupBeforeSave(configs: CustomPopupConfig, callback?: () => any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: configs?.panelClass ?? DefaultCustomPopupConfig.panelClass,
      data: {
        title: configs?.title ?? DefaultCustomPopupConfig.title,
        text: configs?.text ?? DefaultCustomPopupConfig.text,
        type: configs?.type ?? DefaultCustomPopupConfig.type,
        saveButtonTitle: configs?.saveButtonTitle ?? DefaultCustomPopupConfig.saveButtonTitle,
        cancelButtonTitle: configs?.cancelButtonTitle ?? DefaultCustomPopupConfig.cancelButtonTitle,
        hideSaveAsButton: configs?.hideSaveAsButton ?? DefaultCustomPopupConfig.hideSaveAsButton,
        hideConfirmButton: configs?.hideConfirmButton ?? DefaultCustomPopupConfig.hideConfirmButton,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }
      if (callback) {
        callback();
      }
    });
  }

  validateMaritalInfo(
    validateFor: ValidateFor,
    warningMsgs: string[] = [], // Warning messages of Court Order, default empty array when validate in Relationship or No warning messages found
  ) {
    const datepipe = new DatePipe('en-US');
    let componentId = '';
    let selectedId = '';
    let marriageDateValue = null;
    let divorceDateValue = null;

    if (validateFor === ValidateFor.CourtOrder) {
      componentId = CourtOrderComponentId;
      selectedId = this.payeeControl?.value;
      marriageDateValue = this._findControlByFixedPropertyId(CourtOrderPropertiesGUIDs.MarriageDate)?.formControl?.value ?? null;
      divorceDateValue = this._findControlByFixedPropertyId(CourtOrderPropertiesGUIDs.DivorceDate)?.formControl?.value ?? null;
    }

    if (validateFor === ValidateFor.Relationship) {
      componentId = RELATIONSHIP_LIST.Id;
      selectedId = this._findRelationshipEntityReferenceComponentByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.RelatedEntity)?.recordId ?? '';
      marriageDateValue = this._findControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.MarriageDate)?.formControl?.value ?? null;
      divorceDateValue = this._findControlByFixedPropertyId(RELATIONSHIP_LIST_PROPERTY_FIXED_ID.DivorceDate)?.formControl?.value ?? null;
    }

    if (marriageDateValue) {
      marriageDateValue = datepipe.transform(marriageDateValue, DATE_FORMAT_YMD);
    }
    if (divorceDateValue) {
      divorceDateValue = datepipe.transform(divorceDateValue, DATE_FORMAT_YMD);
    }

    if (selectedId && this.targetId && (marriageDateValue || divorceDateValue)) {
      let relationRequest: ValidateMaritalRequest = {
        componentId: componentId,
        basePersonId: this.targetId,
        selectedPersonId: selectedId,
        divorceDate: divorceDateValue,
        marriageDate: marriageDateValue,
      };

      this.layoutService.showLoading = true;
      this.entityDataService.validateMaritalInformation(relationRequest).subscribe(
        (res: ValidateMaritalResponse) => {
          this.layoutService.showLoading = false;
          if (res.isValid === false) {
            // Validate for Marital Status FAILED, push message to show in Warning Popup with other Court Order warnings if have
            warningMsgs.push(MARITAL_INFORMATION_VALIDATE_MSG);
            const finalMsg = this.formatWarningMessage(warningMsgs);
            const configs = {
              text: finalMsg,
            }
            this.customPopupBeforeSave(configs, () => this.saveValue());
          } else {
            if (warningMsgs?.length) {
              // Validate for Marital Status PASSED, but still have Court Order warnings
              const finalMsg = this.formatWarningMessage(warningMsgs);
              const configs = {
                text: finalMsg,
              }
              this.customPopupBeforeSave(configs, () => this.saveValue());
            } else {
              // Validate for Marital Status PASSED, no Court Order warnings, keep Saving data as usual
              this.saveValue();
            }
          }
        },
        (err: unknown) => {
          this.layoutService.showLoading = false;
        }
      );
    } else {
      if (warningMsgs?.length) {
        // No validate for Marital Status, but still have Court Order warnings
        const finalMsg = this.formatWarningMessage(warningMsgs);
        const configs = {
          text: finalMsg,
        }
        this.customPopupBeforeSave(configs, () => this.saveValue());
      } else {
        // No validate for Marital Status, no Court Order warnings, keep Saving data as usual
        this.saveValue();
      }
    }
  }
}
