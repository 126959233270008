import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  EntityListConfig,
  EntityListViewConfigurations,
  EntityPropertyConfigs,
  EntityPropertyDisplayResponse,
  QueryEntityProperty,
} from './models/entity-list-view-configuration.model';
import { GetCardSelectionResponse, PropertyDisplayResponse, QueryCardSelection, QueryProperty } from '@ptg-member/types/models';

@Injectable()
export class EntityListViewConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getEntityPropertyList(entityId: string, screenId?: string, query?: QueryEntityProperty): Observable<EntityPropertyConfigs> {
    let params =  new HttpParams();
    if(screenId){
      params = params.append('screenId', screenId);
    }
    if (query?.includeConfig) {
      params = params.append('includeConfig', query.includeConfig)
    }
    return this.httpClient.get<EntityPropertyConfigs>(
      `${environment.apiUrl}/entity/entities/${entityId}/properties/list-view-configuration`,
      {params}
    );
  }

  getParticipantListConfiguration(entityId: string): Observable<EntityListConfig> {
    return this.httpClient.get<EntityListConfig>(
      `${environment.apiUrl}/entity/entities/${entityId}/list-view-configuration`
    );
  }

  setParticipantListConfiguration(entityId: string, body: EntityListViewConfigurations) {
    return this.httpClient.post<EntityListViewConfigurations>(
      `${environment.apiUrl}/entity/entities/${entityId}/list-view-configuration`,
      body
    );
  }

  getEntityPropertyOfParticipants( entityId: string, query?: QueryEntityProperty ): Observable<EntityPropertyDisplayResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<EntityPropertyDisplayResponse>(
      `${environment.apiUrl}/entities/members/${entityId}/PropertyOfParticipants`, { params }
    );
  }

  getEntityCardSelection( query?: QueryCardSelection ): Observable<GetCardSelectionResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<GetCardSelectionResponse>(
      `${environment.apiUrl}/entity/cards`, { params }
    );
  }

  getEntityPropertyByCardSelection( cardId: string ): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity/cards/${cardId}`
    );
  }
}
