import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';

import { MemberGraphConfigDataItem, ParticipantRelationship } from '../../types/models';
import { ParticipantRelationshipService } from '../../services/participant-relationship.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';

@Component({
  selector: 'ptg-participant-detail-dialog',
  templateUrl: './participant-detail-dialog.component.html',
  styleUrls: ['./participant-detail-dialog.component.scss'],
})
export class ParticipantDetailDialogComponent extends BaseComponent{
  isLoading!: boolean;
  entityId!: string;
  @Input() targetId!: string;
  detailData: Record<string, any>[] = [];
  @Input() participantRelationshipDetail!: ParticipantRelationship;
  @Input() screenId!: string;

  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor(
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private participantRelationshipService: ParticipantRelationshipService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
    .pipe(
      select(fromLayoutReducer.selectProfileNavigationState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      let url = '';
      const memberId = this.participantRelationshipDetail?.memberId;
      if (state?.menu && state?.menu.length > 0 && memberId && this.entityId) {
        const overviewView = (state.memberNavigationList as any);
        if ((state.memberNavigationList as any)?.isOverviewDetailView) {
          url = `/member/detail-view/true/${overviewView.id}/${overviewView.overviewViewId}/${memberId}?entityReferenceLinkedId=${overviewView.entityId}`;
        } else {
          url = `/member/summary-view/true/${overviewView.id}/${overviewView.overviewViewId}/${memberId}?entityReferenceLinkedId=${overviewView.entityId}`;
        }
        this.store.dispatch(
          LayoutActions.clearProfileNavigationItemState()
        );
        this.store.dispatch(
          LayoutActions.selectTab({
            tab: DisplayedTabName.IndividualParticipant,
            url,
          })
        );
      }
    })
  }

  closeDialog() {
    this.close.emit();
  }

  viewProfile() {
    this.store.dispatch(
      LayoutActions.profileNavigationItemRequest({
        memberId: this.participantRelationshipDetail?.memberId,
        entityReferenceLinkedId: this.entityId
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.participantRelationshipDetail) {
      const { memberId, entityRelationshipType } = this.participantRelationshipDetail;
      
      this.isLoading = true;
      this.participantRelationshipService.getCardRelationshipDetail(
        this.targetId,
        memberId,
        entityRelationshipType,
        this.screenId
      ).pipe(finalize(() => {
        this.isLoading = false;
      })
      ).subscribe((res) => {
        this.entityId = res.entityId;
        if (res.memberGraphConfigDataItems) {
          const crrMemberGraphConfigDataItems = res.memberGraphConfigDataItems.sort((a, b) => a.order < b.order ? -1 : 1).map(item => {
            if (item?.isBenefitProperty) {
              return {
                ...item,
                value: this.participantRelationshipDetail.benefitLabels?.join(' , ') ?? ''
              }
            }
            return item;
          })
          this.mapDetailData(crrMemberGraphConfigDataItems);
        }
      }, (error) => {
        if (error) {
          this.detailData = [];
        }
      });
    }
  }

  mapDetailData(memberGraphConfigDataItems: MemberGraphConfigDataItem[]) {
    this.detailData = [
      ...memberGraphConfigDataItems.map(
        (item) => {
          return {
            label: item.propertyLabel,
            value: item.value,
            type: item.type,
            typedValue: item.typedValue,
          };
        }
      ),
    ];
  }
}
