import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { MiddleComponent } from './shared/layout/components/middle/middle.component';
import { PermissionComponent } from './shared/layout/components/permission/permission.component';
import { CheckRoleService } from './shared/services/check-role.service';

const routes: Routes = [
  {
    path: 'member',
    canActivate: [AuthGuard],
    loadChildren: () => import('./member/member.module').then(value => value.MemberModule)
  },
  {
    path: 'estimator',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pension-estiomator/pension-estimator.module').then(value => value.PensionEstimatorModule)
  },
  {
    path: 'fund-list',
    canActivate: [AuthGuard, CheckRoleService],
    loadChildren: () => import('./fund-list/fund-list.module').then(value => value.FundListModule)
  },
  {
    path: 'fund-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./fund-management/fund-management.module').then(value => value.FundManagementModule)
  },
  {
    path: 'page',
    canActivate: [AuthGuard],
    loadChildren: () => import('./page/page.module').then(value => value.PageModule)
  },
  {
    path: 'access-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./access-management/access-management.module').then(value => value.AccessManagementModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(value => value.AdminModule)
  },
  {
    path: 'middle',
    component: MiddleComponent
  },
  {
    path: 'permission',
    canActivate: [AuthGuard],
    component: PermissionComponent
  },
  {
    path: 'processing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./processing/processing.module').then(value => value.ProcessingModule)
  },
  {
    path: 'organization',
    canActivate: [AuthGuard],
    loadChildren: () => import('./organization-management/organization-management.module').then(value => value.OrganizationManagementModule)
  },
  {
    path: 'employer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./employer/employer.module').then(value => value.EmployerModule)
  },
  {
    path: 'entity-management',
    canActivate: [AuthGuard],
    loadChildren: () => import('./entity-management/entity-management.module').then(value => value.EntityManagementModule)
  },
  {
    path: '**',
    redirectTo: '/permission',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
