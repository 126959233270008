import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';
import * as fromAddOneTimeReducer from '../reducers/add-one-time.reducer';

export const addOneTimeSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state[fromAddOneTimeReducer.AddOneTimeKey]
);

export const addOneTimePaymentTypeSelector = createSelector(
  addOneTimeSelector,
  (state) => state.paymentInstructionTypeDtos
);

export const addOneTimeCorrectionTypeSelector = createSelector(
  addOneTimeSelector,
  (state) => state.correctionType
);

export const addFundingSourceSelector = createSelector(
  addOneTimeSelector,
  (state) => state.fundingSources
);

export const addPayeeListSelector = createSelector(
  addOneTimeSelector,
  (state) => state.payeeResponse
);

export const benefitTimeInfoSelector = createSelector(
  addOneTimeSelector,
  (state) => state.benefitTimeInfo
);

export const paymentAddressSelector = createSelector(
  addOneTimeSelector,
  (state) => state.addressData
);

export const createOneTimePaymentSelector = createSelector(
  addOneTimeSelector,
  (state) => state.createOneTimePayment
);

export const displayWithHoldingTaxSelector = createSelector(
  addOneTimeSelector,
  (state) => state.displayWithHoldingTax
);

export const deductionDataSelector = createSelector(
  addOneTimeSelector,
  (state) => state.deductionData
);
