import { createAction, props } from '@ngrx/store';

import {
  AccidentClaim,
  AccidentPostCardRequest,
  GetAccidentClaimsRequest,
  GetAccidentClaimsResponse,
  GetListMunicipalityResponse,
  GetAccidentHistoriesRequest,
  GetAccidentHistoriesResponse,
  ViewAccidentDetailsResponse,
  AccidentReportRequest,
  AccidentNote,
  GetAccidentNotesResponse,
  AccidentDocumentDownloadRequest,
  EditParticipantAccidentStatusRequest,
  UploadAccidentReportRequest,
  AccidentLetterRequest,
  AccidentLetterResponse,
  EditAccidentRequest,
  AccidentClaimsReport,
} from '../../services/models';
import { GetDisbursementReportsRequest, GetDisbursementReportsResponse, GetVendorsRequest, GetVendorsResponse } from 'src/app/admin/features/external-payment/services/models';

export const getAccidentClaimsAction = createAction(
  '[GetAccidentClaims/API] Send Request',
  props<{ request: GetAccidentClaimsRequest, memberId?: string }>()
);

export const getAccidentClaimsSuccessAction = createAction(
  '[GetAccidentClaims/API] Success',
  props<{ response: GetAccidentClaimsResponse }>()
);

export const getAccidentClaimsFailureAction = createAction(
  '[GetAccidentClaims/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentClaimsStateAction = createAction(
  '[GetAccidentClaims] Clear'
);

export const getAccidentHistoriesAction = createAction(
  '[GetAccidentHistories/API] Send Request',
  props<{ request: GetAccidentHistoriesRequest, memberId?: string }>()
);

export const getAccidentHistoriesSuccessAction = createAction(
  '[GetAccidentHistories/API] Success',
  props<{ response: GetAccidentHistoriesResponse }>()
);

export const getAccidentHistoriesFailureAction = createAction(
  '[GetAccidentHistories/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentHistoriesStateAction = createAction(
  '[GetAccidentHistories] Clear'
);

export const removeAccidentHistoryAction = createAction(
  '[RemoveAccidentHistory/API] Send Request',
  props<{ memberId: string, accidentId: string }>()
);
export const removeAccidentHistorySuccessAction = createAction(
  '[RemoveAccidentHistory/API] Success'
);
export const removeAccidentHistoryFailureAction = createAction(
  '[RemoveAccidentHistory/API] Failure',
  props<{ error?: any }>()
);

export const clearAccidentHistoryAction = createAction(
  '[clearAccidentHistoryAction/API] Clear'
);

export const createAccidentClaimsAction = createAction(
  '[CreateAccidentClaims/API] Send Request',
  props<{ request: AccidentClaim, memberId: string, accidentId: string }>()
);

export const createAccidentClaimsSuccessAction = createAction(
  '[CreateAccidentClaims/API] Success'
);

export const createAccidentClaimsFailureAction = createAction(
  '[CreateAccidentClaims/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateAccidentClaimsStateAction = createAction(
  '[CreateAccidentClaims] Clear'
);

export const editAccidentClaimsAction = createAction(
  '[EditAccidentClaims/API] Send Request',
  props<{ request: AccidentClaim, memberId: string, accidentId: string, accidentClaimId: string }>()
);

export const editAccidentClaimsSuccessAction = createAction(
  '[EditAccidentClaims/API] Success'
);

export const editAccidentClaimsFailureAction = createAction(
  '[EditAccidentClaims/API] Failure',
  props<{ error?: any }>()
);

export const clearEditAccidentClaimsStateAction = createAction(
  '[EditAccidentClaims] Clear'
);


export const getListMunicipalityAction = createAction(
  '[GetListMunicipalityAction/API] Send Request',
  props<{ memberId: string, accidentDate: string }>()
);

export const getListMunicipalitySuccessAction = createAction(
  '[GetListMunicipality/API] Success',
  props<{ response: GetListMunicipalityResponse }>()
);

export const getListMunicipalityFailureAction = createAction(
  '[GetListMunicipality/API] Failure',
  props<{ error?: any }>()
);

export const clearGetListMunicipalityStateAction = createAction(
  '[GetListMunicipality] Clear'
);

export const addAccidentPostCardAction = createAction(
  '[AddAccidentPostCard/API] Send Request',
  props<{ memberId: string; accidentPostCardRequest: AccidentPostCardRequest }>()
);
export const addAccidentPostCardSuccessAction = createAction(
  '[AddAccidentPostCard/API] Success',
  props<{ accidentId: string }>()
);
export const addAccidentPostCardFailureAction = createAction(
  '[AddAccidentPostCard/API] Failure',
  props<{ error: any }>()
);

export const editAccidentPostCardAction = createAction(
  '[EditAccidentPostCard/API] Send Request',
  props<{ memberId: string; accidentPostCardRequest: AccidentPostCardRequest }>()
);
export const editAccidentPostCardSuccessAction = createAction(
  '[EditAccidentPostCard/API] Success'
);
export const editAccidentPostCardFailureAction = createAction(
  '[EditAccidentPostCard/API] Failure',
  props<{ error: any }>()
);
export const clearAccidentPostCardAction = createAction(
  '[clearAccidentPostCardAction/API] Clear'
);


// Get Vendor List
export const getVendorsMasterDataAction = createAction(
  '[GetVendorsMasterDataAccident/API] Send Request',
  props<{ request: GetVendorsRequest, clientId: string }>()
);

export const getVendorsMasterDataSuccessAction = createAction(
  '[GetVendorsMasterDataAccident/API] Success',
  props<{ response: GetVendorsResponse }>()
);

export const getVendorsMasterDataFailureAction = createAction(
  '[GetVendorsMasterDataAccident/API] Failure',
  props<{ error?: any }>()
);

export const clearGetVendorsMasterDataStateAction = createAction(
  '[GetVendorsMasterDataAccident] Clear'
);

// Get Disbursement List
export const getDisbursementReportsAction = createAction(
  '[GetDisbursementReportsAccident/API] Send Request',
  props<{ request: GetDisbursementReportsRequest, clientId: string }>()
);

export const getDisbursementReportsSuccessAction = createAction(
  '[GetDisbursementReportsAccident/API] Success',
  props<{ response: GetDisbursementReportsResponse }>()
);

export const getDisbursementReportsFailureAction = createAction(
  '[GetDisbursementReportsAccident/API] Failure',
  props<{ error?: any }>()
);

export const clearGetDisbursementReportsStateAction = createAction(
  '[GetDisbursementReportsAccident] Clear'
);
export const getAccidentClaimDetailAction = createAction(
  '[GetAccidentClaimDetail/API] Send Request',
  props<{ request: GetAccidentClaimsRequest, memberId?: string, accidentId?: string}>()
);

export const getAccidentClaimDetailSuccessAction = createAction(
  '[GetAccidentClaimDetail/API] Success',
  props<{ response: GetAccidentClaimsResponse }>()
);

export const getAccidentClaimDetailFailureAction = createAction(
  '[GetAccidentClaimDetail/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentClaimDetailStateAction = createAction(
  '[GetAccidentClaimDetail/API] Clear'
);

export const removeAccidentClaimAction = createAction(
  '[RemoveAccidentClaim/API] Send Request',
  props<{ memberId: string, accidentId: string, accidentClaimId: string }>()
);
export const removeAccidentClaimSuccessAction = createAction(
  '[RemoveAccidentClaim/API] Success'
);
export const removeAccidentClaimFailureAction = createAction(
  '[RemoveAccidentClaim/API] Failure',
  props<{ error?: any }>()
);

export const clearAccidentClaimAction = createAction(
  '[ClearAccidentClaimAction/API] Clear'
);
export const viewAccidentDetailsAction = createAction(
  '[ViewAccidentDetailsAction/API] Send Request',
  props<{ memberId: string, accidentId: string }>()
);

export const viewAccidentDetailsSuccessAction = createAction(
  '[ViewAccidentDetails/API] Success',
  props<{ response: ViewAccidentDetailsResponse }>()
);

export const viewAccidentDetailsFailureAction = createAction(
  '[ViewAccidentDetails/API] Failure',
  props<{ error?: any }>()
);

export const clearViewAccidentDetailsStateAction = createAction(
  '[ViewAccidentDetails] Clear'
);

export const addAccidentReportAction = createAction(
  '[AddAccidentReport/API] Send Request',
  props<{ memberId: string; accidentId: string; accidentReportRequest: AccidentReportRequest }>()
);
export const addAccidentReportSuccessAction = createAction(
  '[AddAccidentReport/API] Success'
);
export const addAccidentReportFailureAction = createAction(
  '[AddAccidentReport/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearAccidentReportAction = createAction(
  '[clearAccidentReportAction/API] Clear'
);

export const getAccidentDocumentsAction = createAction(
  '[GetAccidentDocuments/API] Send Request',
  props<{ request: any, memberId: string, accidentId: string }>()
);

export const getAccidentDocumentsSuccessAction = createAction(
  '[GetAccidentDocuments/API] Success',
  props<{ response: any }>()
);

export const getAccidentDocumentsFailureAction = createAction(
  '[GetAccidentDocuments/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentDocumentsStateAction = createAction(
  '[GetAccidentDocuments] Clear'
);

export const uploadAccidentDocumentsAction  = createAction(
  '[UploadAccidentDocuments/API] Send Request',
  props<{ request: UploadAccidentReportRequest, memberId: string, accidentId: string }>(),
);

export const uploadAccidentDocumentsSuccessAction  = createAction(
  '[UploadAccidentDocuments/API] Success',
);

export const uploadAccidentDocumentsFailureAction  = createAction(
  '[UploadAccidentDocuments/API] Failure',
  props<{ error?: any }>()
);

export const clearUploadAccidentDocumentsStateAction = createAction(
  '[UploadAccidentDocuments] Clear'
);

export const getAccidentDocumentDownloadAction = createAction(
  '[GetAccidentDocumentDownload/API] Send Request',
  props<{ request: AccidentDocumentDownloadRequest }>()
);

export const getAccidentDocumentDownloadSuccessAction = createAction(
  '[GetAccidentDocumentDownload/API] Success',
  props<{ response: Blob[] }>()
);

export const getAccidentDocumentDownloadFailureAction = createAction(
  '[GetAccidentDocumentDownload/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentDocumentDownloadStateAction = createAction(
  '[GetAccidentDocumentDownload] Clear'
);

export const getAccidentPostcardLetterAction = createAction(
  '[GetAccidentPostcardLetter/API] Send Request',
  props<{ request: AccidentLetterRequest }>()
);

export const getAccidentPostcardLetterSuccessAction = createAction(
  '[GetAccidentPostcardLetter/API] Success',
  props<{ response: AccidentLetterResponse }>()
);

export const getAccidentPostcardLetterFailureAction = createAction(
  '[GetAccidentPostcardLetter/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentPostcardLetterStateAction = createAction(
  '[GetAccidentPostcardLetter] Clear'
);

export const getAccidentReportLetterAction = createAction(
  '[GetAccidentReportLetter/API] Send Request',
  props<{ request: AccidentLetterRequest }>()
);

export const getAccidentReportLetterSuccessAction = createAction(
  '[GetAccidentReportLetter/API] Success',
  props<{ response: AccidentLetterResponse }>()
);

export const getAccidentReportLetterFailureAction = createAction(
  '[GetAccidentReportLetter/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentReportLetterStateAction = createAction(
  '[GetAccidentReportLetter] Clear'
);

export const getAccidentClaimsReportAction = createAction(
  '[GetAccidentClaimsReport/API] Send Request',
  props<{ request: AccidentClaimsReport, fileName?: string }>()
);

export const getAccidentClaimsReportSuccessAction = createAction(
  '[GetAccidentClaimsReport/API] Success'
);

export const getAccidentClaimsReportFailureAction = createAction(
  '[GetAccidentClaimsReport/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentClaimsReportStateAction = createAction(
  '[GetAccidentClaimsReport] Clear'
);

export const createAccidentNoteAction = createAction(
  '[CreateAccidentNote/API] Send Request',
  props<{ note: string, memberId: string, accidentId: string }>()
);

export const createAccidentNoteSuccessAction = createAction(
  '[CreateAccidentNote/API] Success'
);

export const createAccidentNoteFailureAction = createAction(
  '[CreateAccidentNote/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateAccidentNoteStateAction = createAction(
  '[CreateAccidentNote] Clear'
);

export const getAccidentNotesAction = createAction(
  '[GetAccidentNotes/API] Send Request',
  props<{ query: any, memberId: string, accidentId: string }>()
);

export const getAccidentNotesSuccessAction = createAction(
  '[GetAccidentNotes/API] Success',
  props<{ response: GetAccidentNotesResponse }>()
);

export const getAccidentNotesFailureAction = createAction(
  '[GetAccidentNotes/API] Failure',
  props<{ error?: any }>()
);

export const clearGetAccidentNotesStateAction = createAction(
  '[GetAccidentNotes] Clear'
);

export const editAccidentReportAction = createAction(
  '[EditAccidentReport/API] Send Request',
  props<{ memberId: string; accidentId: string; accidentReportRequest: AccidentReportRequest }>()
);
export const editAccidentReportSuccessAction = createAction(
  '[EditAccidentReport/API] Success'
);
export const editAccidentReportFailureAction = createAction(
  '[EditAccidentReport/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearEditAccidentReportAction = createAction(
  '[ClearEditAccidentReportAction/API] Clear'
);

export const editParticipantAccidentStatusAction = createAction(
  '[EditParticipantAccidentStatus/API] Send Request',
  props<{ memberId: string; accidentId: string; editParticipantAccidentStatusRequest: EditParticipantAccidentStatusRequest }>()
);
export const editParticipantAccidentStatusSuccessAction = createAction(
  '[EditParticipantAccidentStatus/API] Success'
);
export const editParticipantAccidentStatusFailureAction = createAction(
  '[EditParticipantAccidentStatus/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearEditParticipantAccidentStatusAction = createAction(
  '[ClearEditParticipantAccidentStatus/API] Clear'
);

export const removeAccidentDocumentAction = createAction(
  '[RemoveAccidentDocument/API] Send Request',
  props<{ memberId: string, accidentId: string, accidentDocumentId: string }>()
);
export const removeAccidentDocumentSuccessAction = createAction(
  '[RemoveAccidentDocument/API] Success'
);
export const removeAccidentDocumentFailureAction = createAction(
  '[RemoveAccidentDocument/API] Failure',
  props<{ error?: any }>()
);
export const clearRemoveAccidentDocumentAction = createAction(
  '[ClearRemoveAccidentDocument] Clear'
);

export const editAccidentDocumentAction = createAction(
  '[EditAccidentDocument/API] Send Request',
  props<{ memberId: string; accidentId: string; fileId: string; request: EditAccidentRequest }>()
);
export const editAccidentDocumentSuccessAction = createAction(
  '[EditAccidentDocument/API] Success'
);
export const editAccidentDocumentFailureAction = createAction(
  '[EditAccidentDocument/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearEditAccidentDocumentStateAction = createAction(
  '[EditAccidentDocument/API] Clear'
);
