import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetValidateAdjustmentDetailIdResponse } from '../../services/models';

import { NewAdjustmentDetailedService } from '../../services';
import {
  getCreateAdjustmentDetailedAction,
  getCreateAdjustmentDetailedFailureAction,
  getCreateAdjustmentDetailedSuccessAction,
  getValidateAdjustmentDetailIdAction,
  getValidateAdjustmentDetailIdFailureAction,
  getValidateAdjustmentDetailIdSuccessAction,
} from '../actions';

@Injectable()
export class CreateAdjustmentDetailedEffects {
  constructor(
    private actions$: Actions,
    private newAdjustmentDetailedService: NewAdjustmentDetailedService,
  ) {}

  createAdjustmentDetailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCreateAdjustmentDetailedAction),
      switchMap(({ request, queries }) =>
        this.newAdjustmentDetailedService.createAdjustmentDetailed(request, queries).pipe(
          map((response: void) => {
            return getCreateAdjustmentDetailedSuccessAction({ response });
          }),
          catchError((error) => of(getCreateAdjustmentDetailedFailureAction({ error }))),
        ),
      ),
    ),
  );

  getValidateAdjustmentDetailId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getValidateAdjustmentDetailIdAction),
      switchMap(({ request }) =>
        this.newAdjustmentDetailedService.getValidateAdjustmentDetailId(request).pipe(
          map((response: GetValidateAdjustmentDetailIdResponse) =>
            getValidateAdjustmentDetailIdSuccessAction({ response }),
          ),
          catchError((error) => of(getValidateAdjustmentDetailIdFailureAction({ error }))),
        ),
      ),
    ),
  );
}
