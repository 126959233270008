import { createReducer, on } from '@ngrx/store';
import { GetDepositAccountsResponse } from '@ptg-member/types/models/deposit-account.model';

import { BaseActionState } from '@ptg-shared/types/models/base-action-state';
import {
  clearGetDepositAccountsStateAction,
  clearCreateDepositAccountsStateAction,
  getDepositAccountsAction,
  getDepositAccountsFailureAction,
  getDepositAccountsSuccessAction,
  createDepositAccountsAction,
  createDepositAccountsFailureAction,
  createDepositAccountsSuccessAction,
  setDepositAccountAction,
  setDepositAccountSuccessAction,
  setDepositAccountFailureAction,
  clearSetDepositAccountStateAction
} from '../actions/deposit-account.action';

export interface DepositAccountState {
  getDepositAccounts?: BaseActionState<GetDepositAccountsResponse>;
  createDepositAccounts?: BaseActionState;
  setDepositAccount?: BaseActionState;
}

const initialState: DepositAccountState = {};

export const depositAccountReducer = createReducer(
  initialState,
  on(getDepositAccountsAction, (state) => ({
    ...state,
    getDepositAccounts: {
      isLoading: true,
    },
  })),
  on(getDepositAccountsSuccessAction, (state, { response }) => ({
    ...state,
    getDepositAccounts: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getDepositAccountsFailureAction, (state, { error }) => ({
    ...state,
    getDepositAccounts: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDepositAccountsStateAction, (state) => ({
    ...state,
    getDepositAccounts: undefined,
  })),
  on(createDepositAccountsAction, (state) => ({
    ...state,
    createDepositAccounts: {
      isLoading: true,
    },
  })),
  on(createDepositAccountsSuccessAction, (state) => ({
    ...state,
    createDepositAccounts: {
      isLoading: false,
      success: true,
    },
  })),
  on(createDepositAccountsFailureAction, (state, { error }) => ({
    ...state,
    createDepositAccounts: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateDepositAccountsStateAction, (state) => ({
    ...state,
    createDepositAccounts: undefined,
  })),
  on(setDepositAccountAction, (state) => ({
    ...state,
    setDepositAccount: {
      isLoading: true,
    },
  })),
  on(setDepositAccountSuccessAction, (state) => ({
    ...state,
    setDepositAccount: {
      isLoading: false,
      success: true,
    },
  })),
  on(setDepositAccountFailureAction, (state, { error }) => ({
    ...state,
    setDepositAccount: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetDepositAccountStateAction, (state) => ({
    ...state,
    setDepositAccount: undefined,
  })),
);
