import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityDataService } from '../../services';
import {
  createEntityDataAction,
  createEntityDataFailureAction,
  createEntityDataSuccessAction,
  getDetailsEntityDatasAction,
  getDetailsEntityDatasFailureAction,
  getDetailsEntityDatasSuccessAction,
  searchEntityDatasAction,
  searchEntityDatasFailureAction,
  searchEntityDatasSuccessAction,
  upsertComponentListDataAction,
  upsertComponentListDataSuccessAction,
  upsertComponentListDataFailureAction,
  getEntityMetadataSuccessAction,
  getEntityMetadataFailureAction,
  getEntityMetadataAction,
} from '../actions';
import { DetailsEntityData, SearchResult, GetEntityMetaDataResponse } from '@ptg-entity-management/services/models';

@Injectable()
export class EntityDataEffect {
  constructor(
    private actions$: Actions,
    private entityDataService: EntityDataService
  ) {}

  createEntityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEntityDataAction),
      switchMap(({ recordId, cardName, request, cardId, auditTrailReason }) =>
        this.entityDataService.createEntityData(recordId, request, cardId, auditTrailReason)
        .pipe(
          map((recordId) => {
            return createEntityDataSuccessAction({ recordId, cardName });
          }),
          catchError((error) => {
            return of(createEntityDataFailureAction({ error }));
          })
        )
      )
    )
  );

  getSearchEntityDatas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchEntityDatasAction),
      switchMap(({ searchValue, entityId, propertyId, exceptRecordIds }) => {
        return this.entityDataService
          .searchEntityDatas(searchValue, entityId, exceptRecordIds)
          .pipe(
            map((searchResult: SearchResult | any) => {
              return searchEntityDatasSuccessAction({ propertyId, searchResult });
            }),
            catchError((error) => {
              return of(searchEntityDatasFailureAction({ error }));
            })
          );
      })
    )
  );

  getDetailsEntityDatas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDetailsEntityDatasAction),
      switchMap(({ entityId, entityPropertyId, id }) => {
        return this.entityDataService
          .getDetailsEntityDatas(entityId, id)
          .pipe(
            map((detailsEntityData: DetailsEntityData) => {
              return getDetailsEntityDatasSuccessAction({ detailsEntityData: { ...detailsEntityData, propertyId: entityPropertyId } });
            }),
            catchError((error) => {
              return of(getDetailsEntityDatasFailureAction({ error }));
            })
          );
      })
    )
  );

  createEntityListData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertComponentListDataAction),
      switchMap(({ request }) =>
        this.entityDataService.createEntityListData(request)
        .pipe(
          map((memberId) => {
            return upsertComponentListDataSuccessAction({ memberId });
          }),
          catchError((error) => {
            return of(upsertComponentListDataFailureAction({ error }));
          })
        )
      )
    )
  );

  getEntityMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityMetadataAction),
      switchMap(() => {
        return this.entityDataService
          .getEntityMetadataAction()
          .pipe(
            map((entityMetadata: GetEntityMetaDataResponse) => {
              return getEntityMetadataSuccessAction({ entityMetadata });
            }),
            catchError((error) => {
              return of(getEntityMetadataFailureAction({ error }));
            })
          );
      })
    )
  );
}
