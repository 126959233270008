import { createSelector } from '@ngrx/store';

import { editPaymentStatusState } from './selector';

export const getSuspensionReasonsSelector = createSelector(editPaymentStatusState, (state) => state?.getReasons);

export const getLastPayrollCyclesSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.getLastPayrollCycles,
);

export const getEditPaymentStatusInitRulesSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.getEditPaymentStatusInitRules,
);

export const getValidationQDROLinkedSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.getValidationQDROLinked,
);

export const getValidationOTPPeriodOverlappedSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.getValidationOTPPeriodOverlapped,
);

export const getValidationOTPRunSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.getValidationOTPRun,
);

export const saveEditPaymentStatusSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.saveEditPaymentStatus,
);
//adjustment
export const saveAdjustmentStatusSelector = createSelector(
  editPaymentStatusState,
  (state) => state?.saveAdjustmentStatus,
);
