import { createAction, props } from '@ngrx/store';
import { GetChildSelectionResponse, GetChildSurvivorLabelNameResponse, GetChildSurvivorValidationBeforeInitializationRequest, GetChildSurvivorValidationBeforeInitializationResponse } from '../../services/models/calculation-child-survivor.model';

// Get Child Survivor Label Name
export const getChildSurvivorLabelNameAction = createAction(
  '[GetChildSurvivorLabelName/API] Send Request',
  props<{ memberId: string }>(),
);
export const getChildSurvivorLabelNameSuccessAction = createAction(
  '[GetChildSurvivorLabelName/API] Success',
  props<{ response: GetChildSurvivorLabelNameResponse }>(),
);
export const getChildSurvivorLabelNameFailureAction = createAction(
  '[GetChildSurvivorLabelName/API] Failure', 
  props<{ error?: any }>()
);
export const clearGetChildSurvivorLabelNameStateAction = createAction(
  '[GetChildSurvivorLabelName] Clear'
);

// Get Child Selection
export const getChildSelectionAction = createAction(
  '[GetChildSurvivorChildSelection/API] Send Request',
  props<{ memberId: string }>(),
);
export const getChildSelectionSuccessAction = createAction(
  '[GetChildSurvivorChildSelection/API] Success',
  props<{ response: GetChildSelectionResponse }>(),
);
export const getChildSelectionFailureAction = createAction(
  '[GetChildSurvivorChildSelection/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetChildSelectionStateAction = createAction(
  '[GetChildSurvivorChildSelection] Clear',
);

// Get Child Survivor Validation Before Initiate
export const getChildSurvivorValidationBeforeInitializationAction = createAction(
  '[GetChildSurvivorValidationBeforeInitialization/API] Send Request',
  props<{ request: GetChildSurvivorValidationBeforeInitializationRequest }>(),
);
export const getChildSurvivorValidationBeforeInitializationSuccessAction = createAction(
  '[GetChildSurvivorValidationBeforeInitialization/API] Success',
  props<{ response: GetChildSurvivorValidationBeforeInitializationResponse }>(),
);
export const getChildSurvivorValidationBeforeInitializationFailureAction = createAction(
  '[GetChildSurvivorValidationBeforeInitialization/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetChildSurvivorValidationBeforeInitializationStateAction = createAction(
  '[GetChildSurvivorValidationBeforeInitialization] Clear',
);
