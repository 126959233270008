import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CalculationChildSurvivorService } from '../../services/calculation-child-survivor.service';
import { GetChildSelectionResponse, GetChildSurvivorLabelNameResponse, GetChildSurvivorValidationBeforeInitializationResponse } from '../../services/models/calculation-child-survivor.model';
import { getChildSelectionAction, getChildSelectionSuccessAction, getChildSelectionFailureAction, getChildSurvivorValidationBeforeInitializationAction, getChildSurvivorValidationBeforeInitializationFailureAction, getChildSurvivorValidationBeforeInitializationSuccessAction, getChildSurvivorLabelNameAction, getChildSurvivorLabelNameFailureAction, getChildSurvivorLabelNameSuccessAction } from '../actions/calculation-child-survivor.action';

@Injectable()
export class CalculationChildSurvivorEffect {
  constructor(
    private actions$: Actions,
    private calculationChildSurvivorService: CalculationChildSurvivorService,
  ) {}

  // Get Child Survivor Label Name
  getChildSurvivorLabelName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChildSurvivorLabelNameAction),
      switchMap(({ memberId }) =>
        this.calculationChildSurvivorService.getCalculationChildSurvivorLabelName(memberId).pipe(
          map((response: GetChildSurvivorLabelNameResponse) => getChildSurvivorLabelNameSuccessAction({ response })),
          catchError((error) => of(getChildSurvivorLabelNameFailureAction({ error })))
        )
      )
    )
  );

  // Get Child Selection
  getChildSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChildSelectionAction),
      switchMap(({ memberId }) =>
        this.calculationChildSurvivorService.getChildSelection(memberId).pipe(
          map((response: GetChildSelectionResponse) => getChildSelectionSuccessAction({ response })),
          catchError((error) => of(getChildSelectionFailureAction({ error }))),
        ),
      ),
    ),
  );

  // Get Child Survivor Validation Before Initiate
  getChildSurvivorValidationBeforeInitialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getChildSurvivorValidationBeforeInitializationAction),
      switchMap(({ request }) =>
        this.calculationChildSurvivorService.getChildSurvivorValidationBeforeInitialization(request).pipe(
          map((response: GetChildSurvivorValidationBeforeInitializationResponse) =>
            getChildSurvivorValidationBeforeInitializationSuccessAction({ response }),
          ),
          catchError((error) => of(getChildSurvivorValidationBeforeInitializationFailureAction({ error }))),
        ),
      ),
    ),
  );
}
