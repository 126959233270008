import { AfterViewInit, Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

// This class is open to common implementation
// Please discuss with your team before implementing
@Injectable()
export abstract class BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  screenSource?: string;

  unsubscribe$ = new Subject<void>();

  constructor(public route?: ActivatedRoute) {}

  ngOnInit(): void {
    this.route?.queryParams.subscribe((queryParams) => {
      this.screenSource = queryParams.screenSource;
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  alignComponentsWithErrorMessage(
    error: AbstractControl | null,
    styleAttribute: string,
    nonErrorValue: string,
    errorValue: string,
  ): object {
    if (error && error.touched && error.errors) {
      return { [styleAttribute]: errorValue };
    } else {
      return { [styleAttribute]: nonErrorValue };
    }
  }
}
