import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { InitialPaymentConfigurationService } from '../../services';
import { GetInitialPaymentConfigurationsResponse } from '../../services/models';
import {
  getInitialPaymentConfigurationsAction,
  getInitialPaymentConfigurationsFailureAction,
  getInitialPaymentConfigurationsSuccessAction,

  setInitialPaymentConfigurationsAction,
  setInitialPaymentConfigurationsFailureAction,
  setInitialPaymentConfigurationsSuccessAction,

} from '../actions';

@Injectable()
export class InitialPaymentConfigurationEffects {
  constructor(
    private actions$: Actions,
    private initialPaymentConfigurationService: InitialPaymentConfigurationService
  ) {}

  getInitialPaymentConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInitialPaymentConfigurationsAction),
      switchMap(() =>
        this.initialPaymentConfigurationService.getInitialPaymentConfigurations().pipe(
          map((response: GetInitialPaymentConfigurationsResponse) => getInitialPaymentConfigurationsSuccessAction({ response })),
          catchError((error) => of(getInitialPaymentConfigurationsFailureAction({ error })))
        )
      )
    )
  );

  setInitialPaymentConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setInitialPaymentConfigurationsAction),
      switchMap(({ request }) =>
        this.initialPaymentConfigurationService.setInitialPaymentConfigurations(request).pipe(
          map(() => setInitialPaymentConfigurationsSuccessAction()),
          catchError((error) => of(setInitialPaymentConfigurationsFailureAction({ error })))
        )
      )
    )
  );

}
