export interface DepositAccount {
  id?: string;
  bankId?: string;
  bankName?: string;
  accountNumber?: string;
  accountNumberMasked?: string;
  accountType?: string | number;
  isUse: boolean;
  isActive: boolean;
  visibilityOff?: boolean;
  routingNumber?: string;
}

export interface GetDepositAccountsRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  targetId?: string;
  paymentInstructionId?: string;
}

export interface GetDepositAccountsResponse {
  depositAccounts: DepositAccount[];
  total: number;
}

export interface UpsertDepositAccountRequest {
  id?: string;
  targetId?: string;
  bankId?: string;
  accountNumber?: string;
  isActive?: boolean;
  accountType: BankAccountType;
}

export enum BankAccountType {
  Checking,
  Savings,
}

export interface CheckExistsDepositAccountNumberRequest {
  id?: string;
  targetId?: string;
  bankId: string;
  accountNumber: string;
}
