import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  FillingStatusLabel,
  PayStatus,
  PaymentInfoAdjustmentType,
  PaymentInstructionType,
  TabPaymentInfo,
} from '../../types/enums';
import { Store } from '@ngrx/store';
import { PayeeDetailState } from '../../store';
import { LayoutService } from '@ptg-shared/services/layout.service';
import * as PayrollSettingsAction from '../../store/actions';
import {
  GetPayrollSettingsRequest,
  GetPayrollSettingsResponse,
  PaymentInfoTabLoadingStateHandler,
  PaymentTab,
} from '../../services/models';
import { getPayrollSettingsSelector } from '../../store/selectors';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { FederalTableFormType, FlatType } from '@ptg-processing/features/taxes/types/enums/federal-taxes.enum';
import { MatDialog } from '@angular/material/dialog';
import { EditPayrollSettingsComponent } from '../edit-payroll-settings/edit-payroll-settings.component';
import { ACTION } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { GetEditPayrollSettingsInput } from '../../services/models/edit-payroll-settings.model';
import { handlePaymentInfoTabLoadingState, isOneTimePayment } from '../../types/constants/payment-info-tab.constant';
import { Subject } from 'rxjs';
import { HeaderBenefit } from '../../types/models/payment-header-configuration.model';

@Component({
  selector: 'ptg-payroll-settings-detail',
  templateUrl: './payroll-settings-detail.component.html',
  styleUrls: ['./payroll-settings-detail.component.scss'],
})
export class PayrollSettingsComponent implements OnInit, OnDestroy, OnChanges, PaymentInfoTabLoadingStateHandler {
  readonly PayStatus = PayStatus;
  readonly FlatType = FlatType;
  readonly FillingStatusLabel = FillingStatusLabel;
  readonly FederalTableFormType = FederalTableFormType;
  readonly TabPaymentInfo = TabPaymentInfo;

  unsubscribe$ = new Subject<void>();
  isLoading: boolean = false;

  @Input() selectedRow?: PaymentTab;
  @Input() selectedTabPayment!: TabPaymentInfo;
  @Input() memberId: string = '';
  @Output() changeBannerEmitter = new EventEmitter();
  @Output() reloadDataEmitter = new EventEmitter<string>();
  @Input() selectedHeaderBenefit?: HeaderBenefit;

  payrollSettings?: GetPayrollSettingsResponse;

  constructor(
    private store: Store<PayeeDetailState>,
    public layoutService: LayoutService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.selectedRow?.id) {
      this.getData();
    }
    this.selectGetPayrollSettingsState();
    handlePaymentInfoTabLoadingState(this, this.store);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow) {
      this.store.dispatch(
        PayrollSettingsAction.getPayrollSettingsAction({
          request: {
            paymentInstructionId: this.selectedRow?.id,
            paymentInstructionHistoryId: this.selectedRow?.paymentInstructionHistoryId ?? '',
            instructionStatusHistoryId: this.selectedRow?.instructionStatusHistoryId ?? '',
          },
        }),
      );
    }
  }

  getData() {
    const request: GetPayrollSettingsRequest = {
      paymentInstructionId: this.selectedRow?.id,
      paymentInstructionHistoryId: this.selectedRow?.paymentInstructionHistoryId ?? '',
      instructionStatusHistoryId: this.selectedRow?.instructionStatusHistoryId ?? '',
    };
    this.store.dispatch(PayrollSettingsAction.getPayrollSettingsAction({ request }));
  }

  selectGetPayrollSettingsState() {
    this.store
      .select(getPayrollSettingsSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success) {
          this.payrollSettings = {
            ...state.payload,
            federalWithholdingLabel: state.payload?.federalWithholding
              ? FillingStatusLabel[state.payload?.federalWithholding]
              : '',
            stateWithholdingLabel: state.payload?.stateWithholding
              ? FillingStatusLabel[state.payload?.stateWithholding]
              : '',
          };
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit(): void {
    const dialog = this.dialog.open<EditPayrollSettingsComponent, GetEditPayrollSettingsInput>(
      EditPayrollSettingsComponent,
      {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {
          paymentInstructionId: this.selectedRow?.id ?? '',
          paymentStatus: this.selectedRow?.payStatus as PayStatus | undefined,
          memberId: this.memberId,
          paymentInstructionHistoryId: this.selectedRow?.paymentInstructionHistoryId ?? '',
          isOneTimePayment: isOneTimePayment(this.selectedRow),
          paymentInfoAdjustmentType:
            this.selectedRow?.paymentInfoAdjustmentType ?? PaymentInfoAdjustmentType.PaymentInstruction,
          isReissue: [PaymentInstructionType.Reissue, PaymentInstructionType.FinalPayment].includes(
            this.selectedRow?.paymentType as PaymentInstructionType,
          ),
          benefitCode: this.selectedHeaderBenefit?.benefitId ?? '',
          estateId: this.selectedRow?.estateId ?? '',
        },
      },
    );

    dialog
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.changeBannerEmitter.emit({
            state: BannerType.Success,
            propertyName: 'Payroll Settings',
            action: ACTION.EDIT,
          });
          this.reloadDataEmitter.emit('payment-info-tab');
        }
      });
  }
}
