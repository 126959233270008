<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div>
    <span class="cal-day-number"
      >{{ day.inMonth ? (day.date | calendarDate:'monthViewDayNumberInMonth': locale) : (day.date | calendarDate:'monthViewDayNumber': locale) }}</span
    >
  </div>
  <div class="grid grid-cols-1 gap-2 pb-2">
    <span
      *ngFor="let event of day.events"
      class="event-badge py-1"
      [style.background-color]="event.color.primary"
      [style.color]="event.color.tertiary"
    >
      {{ event.title }}
    </span>
  </div>
</ng-template>

<ng-template
  #customHeaderTemplate
  let-days="days"
  let-locale="locale"
  let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
>
  <div class="cal-cell-row cal-header">
    <div
      class="cal-cell"
      *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
      [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday"
      [class.cal-future]="day.isFuture"
      [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass"
      tabindex="0"
    >
      {{ day.date | calendarDate : 'monthViewColumnHeader' : locale }}
    </div>
  </div>
</ng-template>

<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="'month'"
    [viewDate]="viewDate"
    [events]="events"
    [cellTemplate]="customCellTemplate"
    [headerTemplate]="customHeaderTemplate"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-year-view
    *ngSwitchDefault
    [events]="events"
    [viewDate]="viewDate"
  >    
  </mwl-calendar-year-view>
</div>
