import { Component, Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { FileService } from '@ptg-shared/services/file-service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { iconUploadAttachment } from '@ptg-shared/constance/listIcons.const';
import { ACTION, GUID_EMPTY, SortType } from '@ptg-shared/constance/value.const';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { BaseComponent } from '@ptg-shared/components';
import { getDateString } from '@ptg-shared/utils/string.util';

import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import { MemberDetailActions } from '../../store/actions';
import { clearCreateUploadMemberEntityAttachmentsStateAction, getMemberEntityAttachmentsAction } from '@ptg-member/store/actions/member-detail.actions';
import { MemberNavigationAttachmentList, MetadataSection } from '@ptg-member/types/models';
import { GetMemberEntityAttachmentsRequest } from '@ptg-entity-management/services/models';
import { IncludeAttachmentType } from '@ptg-entity-management/types/enums';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { clearGetDocumentDownloadStateAction, getDocumentDownloadAction } from 'src/app/admin/features/file/store/actions';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { MemberDocumentService } from '@ptg-member/services/member-document.service';
import { AUDIT_TRAIL_PROPERTIES, SERVICE_HISTORY_LIST } from '@ptg-entity-management/constants';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { ActivatedRoute, Router } from '@angular/router';
import { ParticipantDocumentType } from 'src/app/admin/features/file/constants';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { clearGetMemberAuditTrailsListStateAction, getMemberAuditTrailsSectionListAction } from '@ptg-member/store/actions/member-audit-trails.action';
import { getMemberAuditTrailsSectionListSelector } from '@ptg-member/store/selectors/member-audit-trails.selector';
import { MemberAuditTrailSection } from '@ptg-member/types/models/member-audit-trails.model';
const PAGE_SIZE_CONST = '-ptg-member-manage-attachment-list-pageSize'


export interface AttachmentData {
  memberId: string;
  itemKey: string;
  configSection: MetadataSection;
  breadcrumbs: Breadcrumb[];
  isNavigationPage: boolean;
  rowId: string;
  menuName: string;
  recordId: string;
  isOverview: boolean;
  viewId: string;
  cardName: string;
  menuId: string;
  entityComponentId?: string;
  specificDetailViewUrl?: string;
  entityId?: string;
  isExistFiledDeathCertificateProperty?: boolean;
  cardId?: string;
  currentRowIndex?: any;
  isServiceHistoryCard?: boolean;
  currentEntityComponentId?: string;
  currentEntityReferenceLinkedId?: string;
  currentCardDetailViewUrl?: string;
}

@Component({
  selector: 'ptg-member-manage-attachment-list',
  templateUrl: './member-manage-attachment-list.component.html',
  styleUrls: ['./member-manage-attachment-list.component.scss']
})
export class MemberManageAttachmentListComponent extends BaseComponent {

  unsubscribe$ = new Subject<void>();
  isLoading?: boolean = true;
  dataTable: (MemberNavigationAttachmentList & Row)[] = [];
  sortInfo: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  isEditAttachment: boolean = false;
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  memberId!: string;
  itemKey!: string;
  sectionData!: MetadataSection;
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Overview' }, { name: '' }];
  documentName: string = '';
  keyCreatedAt = 'dateCreated';
  columns: Column[] = [
    {
      name: this.keyCreatedAt,
      header: {
        title: 'Upload Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      sortable: true,
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'action',
      header: {
        title: 'Action',
      },
    },
  ];
  currentFund: any = {};
  currentUrlQueryParams: any = null;
  cardId: string = this.data?.cardId ?? '';
  menuId: string = this.data?.menuId ?? '';
  viewId: string = this.data?.viewId ?? '';
  currentRowIndex: any = this.data?.currentRowIndex === 0 ? '0' : this.data?.currentRowIndex ?? '';
  isServiceHistoryCard: boolean = this.data?.isServiceHistoryCard ?? false;
  entityComponentId: string | null = this.data?.entityComponentId ?? null;
  isOverview: boolean = this.data?.isOverview;
  currentEntityReferenceLinkedId: string = this.data?.currentEntityReferenceLinkedId ?? '';
  currentCardDetailViewUrl: string = this.data?.currentCardDetailViewUrl ?? '';
  cardName: string = '';

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private documentStore: Store<DocumentsState>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentData,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<MemberManageAttachmentListComponent>,
    private memberDocumentService: MemberDocumentService,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private layoutService: LayoutService,
  ) {
    super();
    iconRegistry.addSvgIconLiteral(
      'upload-attachment-icon',
      sanitizer.bypassSecurityTrustHtml(iconUploadAttachment)
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((queryParams) => {
        this.currentUrlQueryParams = queryParams;
      });
    
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      });
    if (this.data) {
      this.memberId = this.data.memberId;
      this.itemKey = this.data.configSection?.key;
      this.sectionData = this.data.configSection;
    }
    this.getBreadcrumb();
    this.getEntityAttachmentAction();
    this.getAttachmentSelector();
    this.createUploadMemberEntityAttachmentsSelector();
    this.removeMemberEntityAttachmentsSelector();

    this.getCardList();
    this.registerGetCardListSelector();
  }

  getEntityAttachmentAction(jumpToFirst?: boolean) {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }

    let request: GetMemberEntityAttachmentsRequest = {
      targetId: this.data.recordId,
      memberId: this.memberId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      entityComponentId: this.data.entityComponentId
    };

    if (this.sortInfo) {
      const field =
        this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType:
          this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.store.dispatch(getMemberEntityAttachmentsAction({ request }));
  }

  getAttachmentSelector() {
    this.store
      .pipe(
        select(fromMember.getMemberEntityAttachmentsSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((ele) => {
        this.isLoading = ele?.isLoading;
        if (ele && !ele.isLoading && ele.success) {
          this.dataTable = (ele.payload || []).map((att: any) => {
          return {
            ...att,
            attachment: att?.fileSnapshot?.fileName,
            [this.keyCreatedAt]: getDateString(att[this.keyCreatedAt])
          };
          });
          this.lengthPg = ele.total || 0;
        }

        this.store.dispatch(
          MemberDetailActions.clearCreateUploadMemberEntityAttachmentsStateAction()
        );
        this.store.dispatch(
          MemberDetailActions.clearRemoveMemberEntityAttachmentsStateAction()
        );
      });
  }

  createUploadMemberEntityAttachmentsSelector() {
    this.store
      .pipe(
        select(fromMember.createUploadMemberEntityAttachmentsSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state.isLoading) {
          if (state.success) {
            this.bannerType = BannerType.Success;
            this.getEntityAttachmentAction(true);
          }

          if (state.error) {
            this.bannerType = BannerType.Fail;
          }

          let message = '';

          if (this.isEditAttachment) {
            message = this.documentName;
          } else {
            message = `${this.data?.breadcrumbs[0]?.name || this.data?.cardName} document`
          }
          showBanner.call(
            this,
            this.bannerType,
            message,
            !this.isEditAttachment ? ACTION.UPLOAD : ACTION.EDIT
          );
          this.documentName = '';
          this.store.dispatch(clearCreateUploadMemberEntityAttachmentsStateAction());
        }
      });
  }

  removeMemberEntityAttachmentsSelector() {
    this.store
      .pipe(
        select(fromMember.removeMemberEntityAttachmentsSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state.isLoading) {
          if (state.success) {
            this.bannerType = BannerType.Success;
            this.getEntityAttachmentAction(true);
          }

          if (state.error) {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(
            this,
            this.bannerType,
            this.documentName,
            ACTION.REMOVE
          );
        }
      });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.getEntityAttachmentAction();
  }

  pageChange(event: any) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getEntityAttachmentAction();
  }

  onClickBreadcrumb() {
    this.clearStates();
    this.dialogRef.close();
  }

  addAttachment(attachment: any) {
    this.isEditAttachment = false;
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    }
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: checkApiValidator(
        this.memberDocumentService.checkExistDocumentName,
        'documentName',
        undefined,
        {
          params: {
            memberId: this.memberId
          }
        }
      ),
      defaultShowOnOverview: true
    }

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        masterEntityId: this.data.entityId,
        targetId: this.memberId,
        isNeedCheckExistedDeathCertificate: !!this.data.isExistFiledDeathCertificateProperty,
        isUploadEditDocumentForServiceHistoryListRecord: this.isServiceHistoryCard,
        specificMenuData: {
          shouldUseCommonBreadcrumbs: true,
          usedForMenu: USED_FOR_MENU.ATTACHMENTS,
          documentTypeOptionList: null,
          acceptFile: '.pdf',
          recordId: this.data.recordId,
          memberId: this.memberId,
          checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
        }
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const body = {
          fileName: objectUpload?.attachmentFile.name,
          description: objectUpload.description,
          documentDescription: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: objectUpload.documentName,
          // @-> `data.cardName` for Detail View flow, `cardName` for Summary View flow
          documentLocationTitle: this.data.cardName || this.cardName,
          // @-> `getListRecordDocumentLocationRouter()` for Detail View flow, `currentCardDetailViewUrl` for Summary View flow
          documentLocationRouter: this.currentCardDetailViewUrl || this.getListRecordDocumentLocationRouter(), 
          attachmentType: IncludeAttachmentType.ListRecord,
          file: objectUpload?.attachmentFile,
          targetId: this.data.recordId,
          memberId: this.memberId,
          participantDocumentType: this.isServiceHistoryCard
            ? ParticipantDocumentType.Other
            : objectUpload?.participantDocumentType,

          // Audit trails
          entityId: this.data?.entityId,
          cardId: this.data.recordId,
          auditTrailReason: objectUpload?.[AUDIT_TRAIL_PROPERTIES.Reason.Key],

          // Store EntityComponentId to know current uploaded document belong to which Card List
          entityComponentId: this.entityComponentId ?? null,
        }

        this.store.dispatch(
          MemberDetailActions.createUploadMemberEntityAttachmentsAction({
            body: [body],
          })
        );
      }
    });
  }

  editAttachment(attachment: any) {
    this.isEditAttachment = true;
    this.documentName = attachment?.documentName;
    // Open form upload
    let rowData = deepClone(attachment);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = rowData?.documentLocationTitle // @-> Only need define Document Location Title in Add Case
    rowData['documentLocationRouter'] = rowData?.documentLocationRouter // @-> Only need define Document Location Router in Add Case
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    }
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: checkApiValidator(
        this.memberDocumentService.checkExistDocumentName,
        'documentName',
        rowData?.documentName,
        {
          ignoreCase: true,
          params: {
            documentId: rowData?.id,
            memberId: this.memberId
          }
        }
      )
    }
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        masterEntityId: this.data.entityId,
        targetId: this.memberId,
        isNeedCheckExistedDeathCertificate: !!this.data.isExistFiledDeathCertificateProperty,
        isUploadEditDocumentForServiceHistoryListRecord: this.isServiceHistoryCard,
      }
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const body = {
          // Un-editable
          id: rowData.id,
          fileName: rowData.fileName,
          documentLocationTitle: rowData?.documentLocationTitle, // @-> Only need define Document Location Title in Add Case
          documentLocationRouter: rowData?.documentLocationRouter, // @-> Only need define Document Location Router in Add Case
          attachmentType: rowData?.attachmentType || IncludeAttachmentType.ListRecord,
          targetId: rowData?.targetId,
          memberId: this.memberId,

          // Editable
          description: objectUpload.description,
          documentDescription: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: objectUpload.documentName,
          participantDocumentType: objectUpload?.participantDocumentType,

          // Audit trails
          entityId: this.data?.entityId,
          cardId: this.data.recordId,
          auditTrailReason: objectUpload?.[AUDIT_TRAIL_PROPERTIES.Reason.Key],
        }

        this.store.dispatch(
          MemberDetailActions.createUploadMemberEntityAttachmentsAction({
            body: [body],
          })
        );
      }
    });
  }

  removeAttachment(attachment: any) {
    this.store.dispatch(
      MemberDetailActions.checkRemoveExitAttachmentAction({
        body: [attachment?.id],
      })
    );
    const subscription = this.store
    .pipe(
      select(fromMember.checkRemoveExitAttachmentSelector),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state: any) => {
      if (state) {
        this.layoutService.showLoading = !!state?.isLoading;
        if (state?.success) {
          const response = state?.response;
          if (!response.isValid && response.message) {
            this.showErrorPopup(response.message);
          } else {
            this.showDialogConfirm(attachment);
          }
          this.store.dispatch(
            MemberDetailActions.clearRemoveExitAttachmentFailure()
          );
          subscription.unsubscribe();
        }
      }
    });  
  }

  showErrorPopup(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Error',
        type: ConfirmType.Warning,
        text: message,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  showDialogConfirm(attachment: any) {
    this.documentName = attachment?.documentName;
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove this ${this.listBreadcrumbs[0]?.name}: ${attachment.documentName}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          MemberDetailActions.removeMemberEntityAttachmentsAction({
            memberId: this.memberId,
            attachmentId: attachment?.id,
          })
        );
      }
    });
  }

  openPDF(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.id as string, fileName: row.fileName as string }),
    );
  }

  getBreadcrumb() {
    if (this.data.specificDetailViewUrl) {
      this.listBreadcrumbs = [
        { name: this.data.cardName, url: this.data.specificDetailViewUrl },
        { name: 'Documents'}
      ];
      return;
    }
    if (this.data?.isNavigationPage) {
      this.listBreadcrumbs[0].url = `/member/summary-view/${this.data?.isOverview}/${this.data?.menuId}/${this.data?.viewId}/${this.memberId}`
        + (this.currentEntityReferenceLinkedId 
          ? `?entityReferenceLinkedId=${this.currentEntityReferenceLinkedId}`
          : '');
      this.listBreadcrumbs[0].name = this.data?.isOverview ? 'Overview' : this.data?.menuName;
      this.listBreadcrumbs[1].name = this.data?.cardName + ' - Documents';
    } else {
      this.listBreadcrumbs[0].url = `/member/detail-view/${this.memberId}`
        + (this.currentEntityReferenceLinkedId 
          ? `?entityReferenceLinkedId=${this.currentEntityReferenceLinkedId}`
          : '');
      this.listBreadcrumbs[1].name = this.sectionData?.name + ' - Documents';
      if (this.data.breadcrumbs) {
        this.listBreadcrumbs = deepClone(this.data.breadcrumbs);
      }
    }
  }

  getListRecordDocumentLocationRouter() {
    return `/member/detail-view/`
      + `${this.isOverview}/`
      + `${this.menuId || GUID_EMPTY}/`
      + `${this.viewId || GUID_EMPTY}/`
      + `${this.memberId}`
      + (this.currentEntityReferenceLinkedId
        ? `?entityReferenceLinkedId=${this.currentEntityReferenceLinkedId}`
        : '');
  }

  openAuditTrailsScreen() {
    const cardId = this.data?.cardId ?? '';
    
    let url = deepClone(this.router.url);

    // From Detail or From Overview can not happen both cases, only 1 of 2 (or 1 of many if in future we have new other view types)
    let fromViewTypeStringValue = '';
    if (url?.includes('member/detail-view/')) {
      fromViewTypeStringValue = 'isFromDetailViewScreen';

      // Edge case: Come from Detail View screen but View has not yet configured
      if (this.viewId === GUID_EMPTY) {
        fromViewTypeStringValue = 'isFromUnconfiguredViewScreen';
      }
    }
    if (url?.includes('member/summary-view/')) {
      fromViewTypeStringValue = 'isFromOverviewScreen';
    }
    
    const memberUrlFragmentIdx = url?.indexOf('member/');
    if (memberUrlFragmentIdx === -1) return;

    const firstPartUrlEndIdx = memberUrlFragmentIdx + 6;
    const firstPartUrl = url.slice(0, firstPartUrlEndIdx + 1);
    const secondPartUrl = url.slice(firstPartUrlEndIdx);

    url = firstPartUrl + 'audit-trails' + secondPartUrl;

    const existQueryParams = Object.keys(this.currentUrlQueryParams)?.length >= 1;
    url = url + `${existQueryParams ? '&' : '?'}${fromViewTypeStringValue}=true${cardId && !this.currentRowIndex ? '&cardId=' + cardId : ''}`;
    
    this.clearStates();
    this.dialogRef.close();
    this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
    this.store.dispatch(
      LayoutActions.selectTab({
        tab: DisplayedTabName.IndividualParticipant,
        url,
      }),
    );
  }

  getCardList() {
    this.store.dispatch(getMemberAuditTrailsSectionListAction({ request: {}}));
  }

  registerGetCardListSelector() {
    this.store
      .pipe(select(getMemberAuditTrailsSectionListSelector))
      .subscribe((state) => {
        if (state) {
          this.layoutService.showLoading = !!state?.isLoading;
          if (state?.success === true) {
            this.cardName = state?.payload?.cards?.find(
              (item: MemberAuditTrailSection) => item?.id?.toUpperCase() === this.cardId?.toUpperCase()
            )?.cardName ?? '';
          }
          this.store.dispatch(clearGetMemberAuditTrailsListStateAction());
        }
      });
  }

  clearStates() {
    this.store.dispatch(clearCreateUploadMemberEntityAttachmentsStateAction());
    this.store.dispatch(clearGetMemberAuditTrailsListStateAction());
    this.store.dispatch(MemberDetailActions.clearRemoveMemberEntityAttachmentsStateAction());
  }

  ngOnDestroy(): void {
    this.clearStates();
    super.ngOnDestroy();
  }
}
