import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';

import * as StatusHistoryActions from '@ptg-member/store/actions/status-history.action';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '@ptg-member/store/reducers';
import { GetListStatusHistoryQuery } from '@ptg-member/types/models';
import { EditMemberStatusHistoryComponent } from '@ptg-member/components/edit-member-status-history/edit-member-status-history.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION } from '@ptg-shared/constance';


const PAGE_SIZE_CONST = '-ptg-manage-status-history-pageSize';

@Component({
  selector: 'ptg-manage-status-history',
  templateUrl: './manage-status-history.component.html',
  styleUrls: ['./manage-status-history.component.scss']
})
export class ManageStatusHistoryComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Overview' }, { name: '' }];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  isLoading: boolean = true;
  dataTable: (any)[] = [];
  columns: Column[] = [
    {
      name: 'statusDate',
      header: {
        title: 'Event Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      truncate: true,
      sortable: true
    },
    {
      name: 'statusName',
      header: {
        title: 'Status',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'statusEvent',
      header: {
        title: 'Event',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'action',
      header: {
        title: '',
      },
      width: '10%'
    },
  ];

  sortInfo?: Sort = {
    active: 'Name',
    direction: 'asc',
  };

  lengthPg!: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  currentFund: any = {};
  statusHistorysData: any[] = [];
  memberId: string = '';
  isOverview: boolean = false;
  menuId: string = '';
  viewId: string = '';

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());

    this.getParamValue();
    this.getBreadcrumb();
    this.getCurrentFund();
    this.getData();

    this.getStatusHistorySelector();
  }

  getParamValue() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params) {
        this.memberId = params?.memberId;
        this.isOverview = params?.isOverview;
        this.menuId = params?.menuId;
        this.viewId = params?.viewId;
      }
    });
  }

  getBreadcrumb() {
    this.listBreadcrumbs[0].url = `member/summary-view/${this.isOverview}/${this.menuId}/${this.viewId}/${this.memberId}`;
    this.listBreadcrumbs[0].name = 'Overview';
    this.listBreadcrumbs[1].name = 'Manage Status History';
  }

  getCurrentFund() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize =
          Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          ) === 0
            ? this.pageSize
            : Number(
                sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
              );
      });
  }

  getData() {
    let sortType = 1;
    let sortNames = ['StatusDate'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      StatusHistoryActions.getStatusHistoryList({ query })
    );
  }

  onChangeSort(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-status-history-pageNumber',
      this.pageNumber.toString()
    );
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-status-history-pageSize',
      this.pageSize.toString()
    );
    this.getData();
  }

  onClickAddStatus() {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: "Status History",
        statusHistory: undefined,
      },
    });
  }

  onClickEditProperty(statusHistory: any) {
    this.dialog.open(EditMemberStatusHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberId: this.memberId,
        viewName: 'Member Status',
        statusHistory,
      },
    });
  }

  getStatusHistorySelector() {
    this.memberStore
    .pipe(
      select(fromMember.selectStatusHistoryState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      this.lengthPg = state?.statusHistoryList?.total;
      this.isLoading = state?.isLoading;
      this.statusHistorysData = state?.statusHistoryList?.memberStatusHistoryDto.map((item, index) => (
        {
          ...item,
          order: index,
          statusDate: item?.statusDate.includes('T00:00:00') ? item.statusDate : getDateString(item.statusDate as string),
        }
      ));
      if (state.createState || state.updateState) {
        this.memberStore.dispatch(
          StatusHistoryActions.clearStatusHistoryState()
        );
        this.getData();
      }

      if (state.createState) {
        showBanner.call(
          this,
          state.createState || '',
          'Status History',
          ACTION.ADD
        );
      }

      if (state.updateState) {
        showBanner.call(
          this,
          state.updateState || '',
          'Status History',
          ACTION.EDIT
        );
      }
    });
  }
}
