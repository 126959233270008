export enum OffCyclePaymentStatusType {
  NotIssued,
  Issued,
}

export enum PaymentInfoType {
  Recurring,
  Reissue,
  'Off-Cycle',
  Reversal,
}

export enum TaxCode {
  Tax01 = 'TAX01',
  Tax02 = 'TAX02',
}

export enum TaxType {
  FederalTax = 0,
  StateTax = 1,
}
