import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EditPaymentStatusService } from '../../services';

import {
  EditPaymentStatusInitRulesResponse,
  GetLastPayrollCyclesResponse,
  ReasonsResponse,
  SaveEditPaymentStatusResponse,
  ValidationOTPPeriodOverlappedResponse,
  ValidationOTPRunResponse,
  ValidationQDROLinkedResponse,
} from '../../services/models/edit-payment-status.model';
import * as EditPaymentStatusActions from '../actions/edit-payment-status.actions';

@Injectable()
export class EditPaymentStatusEffects {
  constructor(
    private actions$: Actions,
    private editPaymentStatusService: EditPaymentStatusService,
  ) {}

  getReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.getReasonsAction),
      switchMap(({ suspensionStatus, terminationStatus }) => {
        return combineLatest([
          this.editPaymentStatusService.getReasons(suspensionStatus),
          this.editPaymentStatusService.getReasons(terminationStatus),
        ]).pipe(
          map(([suspensionReasonsResponse, terminationReasonsResponse]: [ReasonsResponse, ReasonsResponse]) =>
            EditPaymentStatusActions.getReasonsSuccess({ suspensionReasonsResponse, terminationReasonsResponse }),
          ),
          catchError((err) => of(EditPaymentStatusActions.getReasonsFailure(err))),
        );
      }),
    ),
  );

  getLastPayrollCycles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.getLastPayrollCyclesAction),
      switchMap(({ paymentInstructionId, benefitEndDate }) => {
        return this.editPaymentStatusService.getLastPayrollCycles(paymentInstructionId, benefitEndDate).pipe(
          map((response: GetLastPayrollCyclesResponse) =>
            EditPaymentStatusActions.getLastPayrollCyclesSuccess({ response }),
          ),
          catchError((err) => of(EditPaymentStatusActions.getLastPayrollCyclesFailure(err))),
        );
      }),
    ),
  );

  getValidationQDROLinked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.getValidationQDROLinkedAction),
      switchMap(({ paymentInstructionId }) => {
        return this.editPaymentStatusService.getValidationQDROLinked(paymentInstructionId).pipe(
          map((response: ValidationQDROLinkedResponse) =>
            EditPaymentStatusActions.getValidationQDROLinkedSuccess({ response }),
          ),
          catchError((err) => of(EditPaymentStatusActions.getValidationQDROLinkedFailure(err))),
        );
      }),
    ),
  );

  getValidationOTPPeriodOverlapped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.getValidationOTPPeriodOverlappedAction),
      switchMap(({ paymentInstructionId }) => {
        return this.editPaymentStatusService.getValidationOTPPeriodOverlapped(paymentInstructionId).pipe(
          map((response: ValidationOTPPeriodOverlappedResponse) =>
            EditPaymentStatusActions.getValidationOTPPeriodOverlappedSuccess({ response }),
          ),
          catchError((err) => of(EditPaymentStatusActions.getValidationOTPPeriodOverlappedFailure(err))),
        );
      }),
    ),
  );

  getValidationOTPRun$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.getValidationOTPRunAction),
      switchMap(({ paymentInstructionId, benefitTypeOptionId, isSuspend }) => {
        return this.editPaymentStatusService
          .getValidationOTPRun(paymentInstructionId, benefitTypeOptionId, isSuspend)
          .pipe(
            map((response: ValidationOTPRunResponse) =>
              EditPaymentStatusActions.getValidationOTPRunSuccess({ response }),
            ),
            catchError((err) => of(EditPaymentStatusActions.getValidationOTPRunFailure(err))),
          );
      }),
    ),
  );

  getEditPaymentStatusInitRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.getEditPaymentStatusInitRulesAction),
      switchMap(({ paymentInstructionId }) => {
        return this.editPaymentStatusService.getEditPaymentStatusInitRules(paymentInstructionId).pipe(
          map((response: EditPaymentStatusInitRulesResponse) =>
            EditPaymentStatusActions.getEditPaymentStatusInitRulesSuccess({ response }),
          ),
          catchError((err) => of(EditPaymentStatusActions.getEditPaymentStatusInitRulesFailure(err))),
        );
      }),
    ),
  );

  saveEditPaymentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.saveEditPaymentStatusAction),
      switchMap(({ paymentInstructionId, body, isOTP }) => {
        return this.editPaymentStatusService.saveEditPaymentStatus(paymentInstructionId, body, isOTP).pipe(
          map((response: SaveEditPaymentStatusResponse) =>
            EditPaymentStatusActions.saveEditPaymentStatusSuccess({ response }),
          ),
          catchError((err) => of(EditPaymentStatusActions.saveEditPaymentStatusFailure(err))),
        );
      }),
    ),
  );

  //Adjustment
  saveAdjustmenttStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPaymentStatusActions.saveAdjustmentStatusAction),
      switchMap(({ body }) => {
        return this.editPaymentStatusService.saveAdjustmentStatus(body).pipe(
          map((response: SaveEditPaymentStatusResponse) =>
            EditPaymentStatusActions.saveAdjustmentStatusSuccess({ response }),
          ),
          catchError((err) => of(EditPaymentStatusActions.saveAdjustmentStatusFailure(err))),
        );
      }),
    ),
  );
}
