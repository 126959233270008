import { createSelector } from '@ngrx/store';

import { entityManagementModuleSelector } from './selector';

export const entityPropertySelector = createSelector(
  entityManagementModuleSelector,
  (state) => state?.entityProperty
);

export const getInitiationPropertiesSelector = createSelector(
  entityPropertySelector,
  (state) => state?.getInitiationProperties
);

export const getModuleConnectionsSelector = createSelector(
  entityPropertySelector,
  (state) => state?.moduleConnectionState
);

export const getPayeePropertiesSelector = createSelector(
  entityPropertySelector,
  (state) => state?.getPayeeProperties
);

export const getCourtOrderPayeeInfoSelector = createSelector(
  entityPropertySelector,
  (state) => state?.getCourtOrderPayeeInfo
);