export enum EntityType {
  BaseEntity,
  FixedEntity,
  None,
}

export enum EntityComponentType {
  None,
  PropertyList,
  List,
}

export enum IncludeAttachmentType {
  NoAttachment,
  CardLevel,
  ListRecord,
}

export enum DetailViewColumnType {
  SummaryView,
  DetailViewColumn1,
  DetailViewColumn2,
  DetailViewColumn3,
  DetailViewColumn4,
}

export enum PropertyDisplay {
  Column,
  Table,
}

export enum EntityProfileComponentType {
  Properties,
  ['List - Summary View'],
  ['List - Detail View'],
  ['Version History'],
}

export enum ViewType {
  ListOnly,
  PropertyOnly,
  ListAndProperty,
  NoneConfig
}

export enum EntityProfileFixType
{
  RelatedPerson,
  StatusHistory,
  ServiceHistory,
  Totals,
  Documents,
  AddressHistory,
  DepositAccountList
}

export enum CardComponentType {
  None = 0,
  Beneficiaries,
  Dependents,
  Relationships,
}

export enum StandalonePropertiesDisplayRule
{
  None,
  HideEditButton,
  HideEditButtonAndHighlight
}

export enum RelationshipOptions {
  Spouse,
  Child,
  DomesticPartner,
  Parent,
  ExSpouse,
  Sibling,
  Organization,
}

export enum CourtOrderType {
  QDRO,
  Garnishment,
}
