import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Option } from '@ptg-shared/controls/select/select.component';

import { MetadataLookupTableOption } from '../../types/models/lookup-table.model';
import * as fromMember from '../../store/reducers';
import { LookupTableService } from '../../services/lookup-table.service';

@Component({
  selector: 'ptg-replace-lookup-table-value',
  templateUrl: './replace-lookup-table-value.component.html',
  styleUrls: ['./replace-lookup-table-value.component.scss'],
})
export class ReplaceLookupTableValueComponent implements OnInit, OnDestroy {
  lookupTableOptions: Option[] = [];
  editForm!: FormGroup;
  unsubscribe$ = new Subject<void>();
  isReplace: boolean = false;
  isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ReplaceLookupTableValueComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lookupTableValues: MetadataLookupTableOption[];
      lookUpValueId: string;
      lookupValueDescription: string;
      lookupTableId: string;
    },
    public fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    private lookupTableService: LookupTableService
  ) {}

  ngOnInit(): void {
    this.lookupTableService
      .checkLookUpTableValueBeingUsed(this.data.lookupTableId, this.data.lookUpValueId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          if (res.exists) {
            this.isReplace = true;
            this.initForm();
          } else {
            this.isReplace = false;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    this.lookupTableOptions = this.data.lookupTableValues.reduce(
      (result, option) => {
        if (option.id !== this.data.lookUpValueId) {
          result.push({
            value: option.id,
            displayValue: option.description,
          });
        }
        return result;
      },
      [] as Option[]
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm() {
    this.editForm = this.fb.group({
      lookUpValueId: this.fb.control(this.data.lookUpValueId),
      replacementValue: this.fb.control(''),
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(
      this.editForm?.value || { lookUpValueId: this.data.lookUpValueId }
    );
  }
}
