import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  GetHeaderConfigRequest,
  GetHeaderConfigResponse,
  GetPayeePaymentRequest,
  GetPayeePaymentResponse,
  SetPayeePaymentRequest,
} from '../types/models';

@Injectable()
export class PayeePaymentService {
  constructor(private httpClient: HttpClient) {}

  getPayeePayment(request: GetPayeePaymentRequest): Observable<GetPayeePaymentResponse> {
    let params = new HttpParams();
    if (request?.paymentInstructionId) {
      params = params.append('PaymentInstructionId', request.paymentInstructionId);
    }
    if (request?.paymentInstructionHistoryId) {
      params = params.append('paymentInstructionHistoryId', request.paymentInstructionHistoryId);
    }

    if (request?.instructionStatusHistoryId) {
      params = params.append('instructionStatusHistoryId', request.instructionStatusHistoryId);
    }

    return this.httpClient.get<GetPayeePaymentResponse>(
      `${environment.apiUrl}/new-payroll/payments/benefitSubtypes/${request?.benefitSubtypeId}`,
      { params },
    );
  }

  setPayeePayment(request: SetPayeePaymentRequest): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/new-payroll/payments`, request);
  }

  getHeaderConfig(request: GetHeaderConfigRequest): Observable<GetHeaderConfigResponse> {
    return this.httpClient.get<GetHeaderConfigResponse>(
      `${environment.apiUrl}/new-payroll/header-config/${request?.recordId}/${request?.entityId}`,
    );
  }

  checkIsActiveAccount = (body: any) => {
    return this.httpClient.get(`${environment.apiUrl}/Members/deposit-account-list/${body?.id}/is-active`);
  };
}
