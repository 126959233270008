import { IconConfig } from '@ptg-shared/controls/select/select.component';
import { OptionValue } from '@ptg-shared/types/models';

import {
  FilterPropertyType,
  Operator,
  OPERATOR_BY_TYPE,
} from '../../constance/member-list.const';
import { PropertyDisplayConfiguration } from './property-display-configuration.model';

export interface MemberListQuery {
  pageIndex: number;
  totalPerPage: number;
  sortField?: string;
  sortType?: number;
  listFilterInfo?: FilterInfo[];
  ColumnSetId?: string;
  screenId?: string;
}

export interface MemberList {
  total: number;
  members: Record<string, unknown>[];
  columns: ColumnConfig[];
  message: string;
  success?: boolean;
  error?: any;
}

export interface ColumnConfig {
  columnName: string;
  columnKey: string;
  type: string;
  configValue: any;
  isColumnRef?: boolean;
  propertyName?: string;
}

export interface MemberFilter {
  id?: string;
  sectionKey: string;
  itemKey?: string;
  propertyKey: string;
  operator: Operator;
  type: number;
  values: string[] | number[];
  propertyName?: string;
  options?: string;
  selectedPropertyItem?: string;
  propertyRefId?: string,
  entityId?: string,
  event?: {
    operator: Operator;
    options: string;
    values: string[] | number[];
    statusEventId?:  string[] | number[];
    valueName?: string[] | number[];
  };
  date?: {
    operator: Operator;
    options: string;
    values: string[] | number[];
  };
  statusNameId?: string[];
  optionValues?: OptionValue[];
  valueName?: string[] | number[];
  entityReferencePropertyId?: string;
  cardId?: any
}

export interface ListSectionProperty {
  listSectionProperty: FilterByPropertyConfig[];
}

export interface FilterByPropertyConfig {
  propertyName: string;
  propertyKey: string;
  sectionKey: string;
  sectionName: string;
  options: { key: string; value: string }[];
  propertyType: FilterPropertyType;
  propertyTypeLabel?: string;
  headerType: number;
  aggregationId?: string;
  aggregationName?: string;
  aggregationType?: number;
  isRequired?: boolean;
  calculationId?: string;
  calculationName?: string;
  type: number;
}

export interface FilterInfo {
  id?: string;
  propertyName: string;
  propertyKey: string;
  itemKey: string;
  sectionName: string;
  options: string;
  type: FilterPropertyType;
  values: string[] | number[];
  operator: Operator;
  propertyRefId?: string,
  entityId?: string,
  entityReferencePropertyId?: string,
  valueName?: string[] | number[];
  cardId?: string
}

export interface ExportFileRequest {
  entityId: string;
  fileName: string;
  listFilter: FilterInfo[];
  pageIndex: number;
  totalPerPage: number;
  sortField?: string;
  sortType?: number;
  ColumnSetId?: string;
  columnSetName?: string;
}

export type PropertyItem = keyof typeof OPERATOR_BY_TYPE;

export type FilterInputType =
  | 'Text'
  | 'Number'
  | 'Date'
  | 'Boolean'
  | 'DateTime';

export interface MemberFilterModal {
  clientId?: string;
  editMode?: boolean;
  id: string;
  listFilterInfo?: MemberFilter[];
  name: string;
}

export interface Employer {
  id?: string;
  clientId?: string;
  code: string;
  name: string;
  disabled: boolean;
}

export interface ColumnSetConfig {
  listColumnConfig: ColumnDetail[];
}

export interface ColumnDetail{
  id: string;
  auditTrail: string;
  columnSetName: string;
  listConfigs: PropertyDisplayConfiguration[];
  orderRow?: number;
  value?: string,
  valueDescription?: string,
  displayValue?: string,
  isDraft?: boolean,
  listMetadataMemberListConfig: PropertyDisplayConfiguration[];
  iconConfig?: IconConfig,
  dateUpdated: string;
  isDragDrop?: boolean;
}

export interface ColumnSetConfigRequest{
  columnSetName: string;
  listMetadataMemberListConfig: PropertyDisplayConfiguration[];
  isDraft?: boolean;
  auditTrail?: string;
  isDragDrop?: boolean;
}

export interface CheckExitsRequest{
  columnSetName: string;
  id?: string;
  entityId?: string;
}

export interface CheckExitsResponse{
  exists: boolean;
  message: string;
}

export interface ColumnSetPropertyDisplayConfig {
  propertyName: string;
  id: string;
  propertyNameEntityRef: string;
  type: number;
  importKey: string | null,
  inheritance: boolean,
  entityPropertyId?: string;
  optionArr?: { key: string; value: string }[];
  options: { key: string; value: string }[];
  propertyRefId?: string;
}

export interface ColumnSetPropertyDisplayConfiguration {
  id?: string;
  propertyName?: string;
  entityPropertyId?: string;
  type?: number;
  columnName: string;
  orderColumn?: number;
  option: string;
  orderRow?: number;
  sortType?: number;
  columnNameDescription?: string;
  order?: number;
  optionName?: string;
  deleted?: boolean;
  isUsed?: boolean;
  isEnable?: boolean;
  columnNameMaxLength?: number;
  entityNameRef?: string;
  entityColumnSetId?: string;
  propertyNameRef?: string;
  entityReferencePropertyId?: string;
}

export interface PropertyDisplayResponse {
  propertyOfParticipants: ColumnSetPropertyDisplayConfig[]
}

export interface QueryProperty {
  isIncludePropertyEntityReference: boolean,
  isIncludePropertyOfList: boolean,
  isUseFilter: boolean,
  screenId?: string
}

export interface QueryPropertyByCard {
  isUseFilter?: boolean,
  screenId?: string
}

export interface QueryCardSelection {
  sortField?: string;
  sortType?: number;
  filterByEntityIds?: string;
  includesList?: boolean;
  includeSystemCard?: boolean;
}

export interface GetCardSelectionResponse {
  cards: CardSelection[];
}
export interface CardSelection {
  id: string;
  cardName: string;
  entityName: string;
  includesList: boolean;
  totalProperties: number;
  entityComponentId: string;
}

export interface FilterByPropertyConfigEntity {
  propertyKey: string;
  sectionKey: string;
  sectionName: string;
  options: { key: string; value: string }[];
  optionArr: { key: string; value: string }[];
  propertyType: FilterPropertyType;
  propertyTypeLabel?: string;
  aggregationId?: string;
  aggregationName?: string;
  aggregationType?: number;
  isRequired?: boolean;
  calculationId?: string;
  calculationName?: string;
  type: number;
  entityReferencePropertyName: string;
  propertyName: string;
  headerType: number;
  entityId: string;
  entityRefName: string;
  inheritance: boolean;
  id: string;
  componentName?: string;
  propertyRefId?: string;
  componentNameRef?: string;
  configs?: any
  displayDataType: number;
  entityPropertyId?: string;
  entityReferencePropertyId?: string
}

export interface MemberFilterEntity {
  id?: string;
  sectionKey?: string;
  itemKey?: string;
  propertyKey?: string;
  operator: Operator;
  type: number;
  values: string[] | number[];
  propertyName?: string;
  options?: string;
  selectedPropertyItem?: string;
  propertyRefId?: string,
  entityId?: string,
  statusNameId?: string[] | number[];
  event?: {
    operator: Operator;
    options: string;
    values: string[] | number[];
    valueName?: string[] | number[];
  };
  date?: {
    operator: Operator;
    options: string;
    values: string[] | number[];
  };
  optionValues?: OptionValue[];
  dropdownId?: string;
  entityReferencePropertyId?: string;
  valueName?: string[] | number[];
  cardId?: string
}

export interface CheckMemberExistsBySsnRequest {
  ssn: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string | null;
}
export interface CheckMemberExistsBySsnResponse {
  isMatched: boolean;
  isFullyMatched: boolean;
  message: string;
  existedMemberId: string;
  existedRecordIsMember: boolean;
}
