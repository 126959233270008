import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberLocalDecimal',
})
export class NumberLocalDecimalPipe implements PipeTransform {
  transform(
    value: number | string | undefined,
    unit?: string,
    options?: {
      decimal?: number;
      locales?: string; // Set default locale to 'en' (English)
      accountingFormat?: boolean;
    },
  ): string {
    if (value === null || value === undefined || value === '') return '';

    const parsedValue = Number(value);
    if (isNaN(parsedValue)) return value.toString();

    const { decimal = 2, locales = 'en', accountingFormat = false } = options ?? {};

    const unitValue = unit ? unit + ' ' : '';
    const isNegative = parsedValue < 0;
    const wholeNumberPart = BigInt(Math.abs(parsedValue).toString().split('.')[0]).toLocaleString(locales);
    const fractionalPart = decimal ? '.' + parsedValue.toFixed(decimal).split('.')[1] : '';

    const formattedValue = `${wholeNumberPart}${fractionalPart}`;

    if (!isNegative) return `${unitValue}${formattedValue}`;

    if (accountingFormat) return `(${unitValue}${formattedValue})`;

    return `${unitValue}-${formattedValue}`;
  }
}
