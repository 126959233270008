import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SortType } from '@ptg-shared/constance';

import { environment } from 'src/environments/environment';

import {
  GetExceptionConfigurationRequest,
  GetExceptionConfigurationResponse,
  ExceptionConfigurationDetailResponse,
  SaveExceptionConfigurationDetailRequest,
  GetValidationExceptionRequest,
  GetValidationExceptionResponse,
} from './models/exception-configuration.model';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable({
  providedIn: 'root',
})
export class ExceptionConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}

  getExceptionConfigurations(request: GetExceptionConfigurationRequest): Observable<GetExceptionConfigurationResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get<GetExceptionConfigurationResponse>(
      `${environment.apiUrl}/calculations/calculation-options/exception-configurations`,
      { params },
    );
  }

  getExceptionConfigurationDetail(exceptionId: string): Observable<ExceptionConfigurationDetailResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<ExceptionConfigurationDetailResponse>(
      `${environment.apiUrl}/calculations/calculation-options/exception-configurations/details/${exceptionId}`,
      { context },
    );
  }

  setExceptionConfigurationsDetail(
    exceptionId: string,
    payload: SaveExceptionConfigurationDetailRequest,
  ): Observable<any> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<any>(
      `${environment.apiUrl}/calculations/calculation-options/exception-configurations/details/${exceptionId}`,
      payload,
      { context },
    );
  }

  getValidationExceptionConfigurations(
    request: GetValidationExceptionRequest,
  ): Observable<GetValidationExceptionResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { memberId, exceptionType } = request;
    let params = new HttpParams();
    if (request?.calculationBenefitId) {
      params = params.append('calculationBenefitId', request.calculationBenefitId);
    }
    return this.httpClient.get<GetValidationExceptionResponse>(
      `${environment.apiUrl}/calculations/${memberId}/calculation-options/exception-configurations/details`,
      { params, context },
    );
  }
}
