import { createSelector } from '@ngrx/store';
import { selectMemberModuleState } from '../reducers';

export const selectDepositAccountState = createSelector(selectMemberModuleState, (state) => state.depositAccount);

export const getDepositAccountsState = createSelector(selectDepositAccountState, (state) => state.getDepositAccounts);

export const createDepositAccountsSelector = createSelector(
  selectDepositAccountState,
  (state) => state?.createDepositAccounts,
);

export const setDepositAccountSelector = createSelector(selectDepositAccountState, (state) => state?.setDepositAccount);
