import { createSelector } from '@ngrx/store';

import { createGeneralAdjustmentState } from './selector';

export const getDeductionTypeSelector = createSelector(
  createGeneralAdjustmentState,
  (state) => state?.getDeductionType,
);

export const validateGeneralAdjustmentSelector = createSelector(
  createGeneralAdjustmentState,
  (state) => state?.validateGeneralAdjustment,
);

export const addGeneralAdjustmentSelector = createSelector(
  createGeneralAdjustmentState,
  (state) => state?.addGeneralAdjustment,
);
