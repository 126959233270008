import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PayrollRegisterListResponse, PayrollRegisterSubRecordResponse } from './models/payroll-register.model';
import { SORT_TYPE } from '@ptg-shared/constance';

@Injectable()
export class PayrollRegisterService {
  constructor(private httpClient: HttpClient) {}

  getPayrollRegisterList(query: any): Observable<PayrollRegisterListResponse> {
    let params = new HttpParams();
    params = params.append('PageSize', query.pageSize);
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append('SortNames', query.sortField[0].toUpperCase() + query.sortField.substring(1));
      params = params.append('SortType', query.sortType ? query.sortType : SORT_TYPE.ASC);
    }

    if (query.isCancelledRun) {
      params = params.append('IsCancelledRun', query.isCancelledRun);
    }

    if (query.isOneTimeRun) {
      params = params.append('IsOneTimeRun', query.isOneTimeRun);
    }
    
    return this.httpClient.get<PayrollRegisterListResponse>(`${environment.apiUrl}/new-payroll/payroll-registers`, {
      params,
    });
  }

  getSubPayrollRegister(payrollRunId: string): Observable<PayrollRegisterSubRecordResponse> {
    return this.httpClient.get<PayrollRegisterSubRecordResponse>(
      `${environment.apiUrl}/new-payroll/payroll-registers/${payrollRunId}`,
    );
  }
}
