<div #parentElem>
  <span #childElem>
    <ng-container [ngTemplateOutlet]="tooltipTemplate"></ng-container>
  </span>
  <span
    [matTooltip]="tooltipText"
    matTooltipClass="cs-tooltip custom-tooltip"
    #tooltipElem
  >
    <ng-container [ngTemplateOutlet]="tooltipTemplate"></ng-container>
  </span>
</div>

