import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SortType } from '@ptg-shared/constance';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetListSectionRecordValuesType } from '../types/enums';
import {
  CheckExceptionRequest,
  GetCalculationAuditTrailRequest,
  GetCalculationAuditTrailResponse,
  GetExceptionRequest,
  GetExceptionResponse,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class RetirementBenefitService {
  constructor(private httpClient: HttpClient) {}

  getListSectionRecordValues(
    memberId: string,
    sectionKey: string,
    query: { index: number; type: GetListSectionRecordValuesType },
  ): Observable<any> {
    let params = new HttpParams({ fromObject: query });

    return this.httpClient.get<any>(
      `${environment.apiUrl}/members/${memberId}/${sectionKey}/GetListSectionRecordValues`,
      { params },
    );
  }

  getExceptionList(request: GetExceptionRequest): Observable<GetExceptionResponse> {
    let params = new HttpParams();

    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    const { memberId, calculationType: targetType, benefitTypeId: benefitEntityId, calculationId: targetId } = request;

    return this.httpClient.get<GetExceptionResponse>(
      `${environment.apiUrl}/calculations/${memberId}/calculation-options/exception-configurations/calculation/${targetType}/${benefitEntityId}/${targetId}`,
      { params },
    );
  }

  getCalculationAuditTrailList(request: GetCalculationAuditTrailRequest): Observable<GetCalculationAuditTrailResponse> {
    let params = new HttpParams();

    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    const { memberId, calculationType, calculationId } = request;

    return this.httpClient.get<GetCalculationAuditTrailResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationId}/audit-trails`,
      { params },
    );
  }

  checkExceptionConfiguration(request: CheckExceptionRequest): Observable<boolean> {
    const { memberId, ...params } = request;
    return this.httpClient.post<boolean>(
      `${environment.apiUrl}/calculations/${memberId}/calculation-options/exception-configurations/calculation`,
      { ...params }
    );
  }
}
