<div *ngIf="docData.length > 0 || !configCard?.datalessConfig?.isHideEmptyCard" class="participant-documents-container">
  <div class="flex-grow overflow-hidden">
    <ptg-list-display-box
      title="{{cardNameDoc}}"
      pageName="Data"
      [isLoading]="isLoading"
      [dataTable]="docData"
      [columns]="docData.length > 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide ? columns : []"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [paginable]="docData.length> 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide"
      [isHideNotFoundMessage]="configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.Hide || 
        configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.DisplayColumnHeaders"
      [isDocument]="isDocument"
      (changePage)="changePage($event)"
      (removeClick)="onclickRemoveDocument($event)"
      (downloadDocumentEvent)="downloadDocument($event)"
      (manageDocumentList)="onManageDocumentList($event)"
      (uploadDocumentEvent)="uploadDocument()"
    ></ptg-list-display-box>
  </div>
</div>
