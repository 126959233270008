<ptg-dialog class="edit-calculation-parameter-container flex flex-col" [title]="dialogTitle">
  <ng-template dialogBody>
    <div class="edit-form-container flex flex-col">
      <ptg-overview-header-entity
        [isShowChangeMemberDetailBtn]="false"
        [injectedMemberId]="memberId"
      ></ptg-overview-header-entity>
      <div class="p-6 gap-6">
        <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>
        <div class="flex justify-start gap-3 pt-3">
          <ptg-button (click)="onSave()" classInput="mat-flat-button primary" buttonName="Save"></ptg-button>
          <ptg-button (click)="onCancel()" classInput="mat-flat-button tertiary" buttonName="Cancel"></ptg-button>
        </div>

        <div class="content-container mt-3">
          <div class="box-container flex flex-row w-1/2">
            <div class="add-property-section" *ngIf="isShowHeader">
              <div>
                <p class="sub-title">System Calc values</p>
              </div>
              <div class="flex flex-col other-component-container mt-3" *ngIf="parameterSystemArray?.length">
                <div
                  *ngFor="let property of parameterSystemArray.controls; let index = index"
                  class="value-container flex mb-4"
                >
                  <ptg-dynamic-input
                    [controlField]="$any(property.get('value'))"
                    [formControlDetail]="parametersSysConfigs[index]"
                  ></ptg-dynamic-input>
                </div>
              </div>
            </div>
            <div
              class="add-property-section"
              [ngClass]="{ 'ml-5': isShowHeader }"
              *ngIf="parameterOverriddenArray?.length"
            >
              <div *ngIf="isShowHeader">
                <p class="sub-title">User Overridden</p>
              </div>
              <div class="flex flex-col other-component-container mt-3">
                <div
                  *ngFor="let property of parameterOverriddenArray.controls; let index = index"
                  class="value-container flex mb-4"
                >
                  <ptg-dynamic-input
                    [controlField]="$any(property.get('value'))"
                    [formControlDetail]="parametersOverriddenConfigs[index]"
                    [isAllowAction]="isChicagoParks"
                    (valueChange)="changeValue($event, property)"
                    (outFocus)="handleOutFocus($event, property)"
                  ></ptg-dynamic-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
