<div class="refunds-overview-container" id="refunds-overview-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [stepperState]="stepperState"
    [completedStep]="completedStep"
  ></ptg-breadcrumb>

  <ptg-overview-header-entity #overViewHeader></ptg-overview-header-entity>

  <div class="refunds-overview-wrapper">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>

    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="flex justify-between">
      <div class="flex flex-wrap gap-4">
        <ptg-button
          buttonName="Compute"
          classInput="secondary"
          (clickButton)="onClickCompute()"
          *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated"
        >
        </ptg-button>

        <ptg-button
          buttonName="Complete Refund"
          classInput="primary"
          (clickButton)="completeRefund()"
          *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated"
        ></ptg-button>

        <ptg-button
          buttonName="Re-open"
          classInput="secondary"
          (clickButton)="reOpenRefund()"
          *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus['Pending Approval']"
        ></ptg-button>

        <ptg-button
          buttonName="Approve"
          classInput="primary"
          (clickButton)="approveRefund()"
          *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus['Pending Approval']"
        ></ptg-button>

        <div class="calculation-content">
          <div class="value-container ml-4">
            <p class="label text-sm">Net Refund</p>
            <p class="value text-lg font-bold leading-none">$ {{ netRefund | numberDecimal: { decimal: 2 } }}</p>
          </div>
        </div>
      </div>
      <ptg-municipality-type
        #municipalityType
        *ngIf="
          [CalculationBenefitHistoryStatus['Pending Approval'], CalculationBenefitHistoryStatus.Approved].includes(
            $any(refundCalculationRecordById?.status)
          )
        "
        [currentStatus]="refundCalculationRecordById?.status"
        [request]="getEmployerTypesRequest"
      ></ptg-municipality-type>
    </div>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header">
        <span class="title font-bold">Exceptions</span>
      </div>
      <ptg-grid
        [data]="exceptionList"
        [columns]="columnException"
        [totalRecords]="totalExceptionRecords"
        [isLoading]="isExceptionLoading"
        [pageSize]="pageExceptionSize"
        [pageSizeOptions]="pageExceptionSizeOptions"
        [pageNumber]="pageExceptionIndex"
        notFoundMessage="No Exceptions to Display"
        (sortChange)="onChangeSortException($event)"
        (pageChange)="onChangeExceptionPage($event)"
      >
        <ng-template cellContent columnName="exceptionType" let-row>
          <mat-icon class="icon-table" style="color: red" matTooltip="Exception" *ngIf="row.exceptionType === 0">
            error_outline
          </mat-icon>
          <mat-icon class="icon-table" style="color: yellow" matTooltip="Warning" *ngIf="row.exceptionType === 1">
            warning
          </mat-icon>
        </ng-template>
      </ptg-grid>
    </div>

    <div class="benefit-overview-grid flex-grow overflow-hidden" *ngIf="showPayeeInformation">
      <div class="header">
        <span class="title font-bold">Payee Information</span>
        <ptg-button
          *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated && cardId"
          buttonName="Edit"
          classInput="edit-button"
          class="float-right"
          (clickButton)="onEditPayeeInfo()"
        >
          <mat-icon>edit</mat-icon>
        </ptg-button>
      </div>

      <div class="p-6" *ngIf="isPayeeLoading; else hasPayee">
        <div class="loading-backdrop"></div>
        <ptg-spinner [isLoading]="isPayeeLoading"></ptg-spinner>
      </div>
      <ng-template #hasPayee>
        <div class="calculation-content payee-info-content">
          <div class="calculation-content flex justify-start p-4">
            <p *ngIf="payeeInformation.length === 0; else hasData" class="text-2xl text-center w-full py-1">
              No Data to Display
            </p>
            <ng-template #hasData>
              <div class="value-container pr-6" *ngFor="let item of payeeInformation">
                <p class="label text-sm whitespace-nowrap">{{ item.label }}</p>
                <p class="value text-lg">{{ item.value ?? '-' }}</p>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>

    <div
      class="benefit-overview-grid refund-years-grid flex-grow overflow-hidden"
      [ngClass]="{
        'refund-years-grid--initiate': refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated
      }"
    >
      <div class="header">
        <span class="title font-bold">Refund Years</span>
      </div>
      <ptg-grid
        #refundYearsGrid
        [data]="refundYears"
        [columns]="columnsRefundYears"
        [totalRecords]="totalRefundYear"
        [isLoading]="isRefundYearLoading"
        [pageSize]="pageRefundSize"
        [pageNumber]="pageRefundIndex"
        notFoundMessage="No Refund Years to Display"
        [paginable]="false"
        [allowSelection]="true"
        (sortChange)="onChangeSortRefundYear($event)"
        (pageChange)="onRefundYearsPage($event)"
        (selectionChange)="onChangeSelection()"
      >
        <ng-template cellContent columnName="disabilityFees" let-row>
          <span
            ><span>$ </span> <span>{{ row.disabilityFees | numberDecimal: { decimal: 2 } }}</span></span
          >
        </ng-template>
        <ng-template cellContent columnName="muniPaidPension" let-row>
          <span
            ><span>$ </span> <span>{{ row.muniPaidPension | numberDecimal: { decimal: 2 } }}</span></span
          >
        </ng-template>
        <ng-template cellContent columnName="memberPaidPension" let-row>
          <span
            ><span>$ </span> <span>{{ row.memberPaidPension | numberDecimal: { decimal: 2 } }}</span></span
          >
        </ng-template>
        <ng-template cellContent columnName="sumOfPensionPayments" let-row>
          <span
            ><span>$ </span> <span>{{ row.sumOfPensionPayments | numberDecimal: { decimal: 2 } }}</span></span
          >
        </ng-template>
      </ptg-grid>

      <div class="refund-years-grid-loading" *ngIf="isReselectRefundLoading">
        <div class="loading-backdrop"></div>
        <ptg-spinner [isLoading]="isReselectRefundLoading"></ptg-spinner>
      </div>
    </div>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header document-header flex justify-between">
        <span class="title font-bold">Refund Documents</span>
        <ptg-button
          buttonName="Upload Document"
          classInput="upload-button"
          class="upload-file"
          (clickButton)="onUploadDocuments()"
          *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated"
        >
          <mat-icon aria-hidden="false" aria-label="upload-icon">upload_file</mat-icon>
        </ptg-button>
      </div>
      <ptg-grid
        [data]="refundDocuments"
        [columns]="columnsBenefitDocuments"
        [totalRecords]="totalBenefitDocuments"
        [isLoading]="isDocumentLoading"
        [pageSize]="pageDocumentSize"
        [pageNumber]="pageDocumentIndex"
        notFoundMessage="No Refund Documents to Display"
        (sortChange)="onChangeSortDocuments($event)"
        (pageChange)="onChangeDocumentsPage($event)"
      >
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank">
              <span class="truncate">{{ row?.fileName }}</span>
            </span>
          </div>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditDocument(row)"
            *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveDocument(row)"
            *ngIf="refundCalculationRecordById?.status === CalculationBenefitHistoryStatus.Initiated"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header">
        <span class="title font-bold">Audit Trails</span>
      </div>
      <ptg-grid
        [data]="calculationAuditTrails"
        [columns]="columnCalculationAuditTrail"
        [totalRecords]="totalCalculationAuditTrailRecords"
        [isLoading]="isCalculationAuditTrailLoading"
        [pageSize]="pageCalculationAuditTrailSize"
        [pageNumber]="pageCalculationAuditTrailIndex"
        notFoundMessage="No Audit Trails to Display"
        (sortChange)="onChangeSortAuditTrail($event)"
        (pageChange)="onChangeAuditTrailPage($event)"
      >
        <ng-template cellContent columnName="createdDate" let-row>
          {{ row.createdDate | date: 'MM/dd/yyyy hh:mm a' }}
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
