import { ACTION_COLUMN, Column, ColumnType } from '@ptg-shared/controls/grid';

export const GRID_COLUMN_INIT_BENEFIT_DOCUMENT: Column[] = [
  {
    name: 'uploadDate',
    header: {
      title: 'Upload Date',
      style: {
        width: '14.28%',
      },
    },
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'documentName',
    header: {
      title: 'Document Name',
      style: {
        width: '25.22%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'fileName',
    header: {
      title: 'File Name',
      style: {
        width: '27.22%',
      },
    },
    truncate: true,
    sortable: false,
  },
  {
    name: 'documentTypeName',
    header: {
      title: 'Document Type',
      style: {
        width: '17.63%',
      },
    },
    truncate: true,
    sortable: true,
  },
  {
    name: ACTION_COLUMN,
    header: {
      title: 'Action',
    },
    width: '200px',
  },
];
