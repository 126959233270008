import { Option } from '@ptg-shared/controls/select/select.component';
import { Sort } from '@angular/material/sort';

export const MODULE_KEY = 'memberModule';

export const PARTICIPANT_MODULE_KEY = 'participants';

export const REGISTED_APP = 'registed';

export const LIST_TYPE: Option[] = [
  {
    value: 1,
    displayValue: 'Summary',
  },
  {
    value: 2,
    displayValue: 'Detail',
  },
];

export const propertyType = [
  {
    value: 204,
    displayValue: 'Data',
  },
  {
    value: 203,
    displayValue: 'System',
  },
  {
    value: 54,
    displayValue: 'Entity Reference',
  },
  {
    value: 103,
    displayValue: '​Aggregation',
  },
  {
    value: 104,
    displayValue: '​Calculation',
  },
  {
    value: 205,
    displayValue: 'Identifier',
  },
];

// TODO cuongnh20: pls move to entity
export enum ENTITY_TYPE {
  System,
  Summary,
  Detail,
}

export enum PROPERTY_DISPLAY {
  Column,
  Table,
}

export enum ExceptionOption {
  All = 'all',
}

export enum ActionCode{
  UserAddNewParticipant = 0,
  UserAddNewServiceRecord = 1,
  UserHasFirstPayment = 2,
  UserUpdateRetireRehireStatus = 3,
  Deactive = 4
}

export const RequireDateOfDeathErrorMessage = 'Date of Death and Line of Duty Death need to be configured together in Detail View and Summary View. Please edit to save card configuration.';
export const RequireNewHireExamErrorMessage = 'New Hire Exam and New Hire Exam Date need to be configured together in Detail View and Summary View. Please edit to save card configuration.';

export enum MenuItemTitle {
  RetirementBenefit = 'Retirement Benefit',
  Survivor = 'Survivor',
  Disability = 'Disability',
  Accident = 'Accident',
  BuybackMakeup = 'Buyback / Makeup',
  Refunds = 'Refunds',
  QDRO = "QDRO"
}

export enum MenuItemSubTitle {
  BenefitsProcessing = 'Benefits Processing',
  ReturnsProcessing = 'Returns Processing',
  PurchaseServices = 'Purchase Services',
}

export enum SubHeaderType {
  Gender = 'Gender',
  YearsOfService = 'Years of Service',
  NumbersOfPayments = 'Number of Payments',
  MemberDateOfBirth = 'Member Date of Birth',
  MartialStatus = 'Martial Status',
  MemberCurrentAge = 'Member Current Age',
  EligibleYears = 'Eligible Years',
  MemberDateOfDeath = 'Member Date of Death',
  SpouseAge = 'Spouse Age',
}

export enum GenerateDocumentStatus {
  Completed = 'Completed',
  Failed = 'Failed',
}

export const DepositAccountRouterUrl = `member/system-view/deposit-account/`;

export const DefaultSortInfoForListCard: Sort = {
  active: 'Name',
  direction: 'asc',
}
