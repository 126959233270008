import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ViewAdjustmentDetailsActions from '../actions/view-adjustment-details.actions';
import { ViewAdjustmentDetailsService } from '../../services/view-adjustment-details.service';

@Injectable()
export class ViewAdjustmentDetailsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly viewAdjustmentDetailsService: ViewAdjustmentDetailsService,
  ) {}

  getAdjustmentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewAdjustmentDetailsActions.getAdjustmentDetailsAction),
      switchMap(({ request }) => {
        return this.viewAdjustmentDetailsService.getAdjustmentDetails(request).pipe(
          map((response) => ViewAdjustmentDetailsActions.getAdjustmentDetailsSuccess({ response })),
          catchError((err) => of(ViewAdjustmentDetailsActions.getAdjustmentDetailsFailure(err))),
        );
      }),
    ),
  );

  getAdjustmentDetailItemPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewAdjustmentDetailsActions.getAdjustmentDetailItemPositionAction),
      switchMap(({ request }) => {
        return this.viewAdjustmentDetailsService.getAdjustmentDetailItemPosition(request).pipe(
          map((response) =>
            ViewAdjustmentDetailsActions.getAdjustmentDetailItemPositionSuccess({
              response: {
                ...response,
                queryType: request.queryType,
                adjustmentId: request.adjustmentId,
                paymentInstructionId: request.paymentInstructionId,
                paymentInstructionHistoryId: request.paymentInstructionHistoryId,
              },
            }),
          ),
          catchError((err) => of(ViewAdjustmentDetailsActions.getAdjustmentDetailItemPositionFailure(err))),
        );
      }),
    ),
  );
}
