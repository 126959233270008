import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { FORMAT_PHONE_NUMBER, LIMIT_PHONE_NUMBER, PropertyType } from 'src/app/member/constance/metadataPropertyType.const';
import { EMAIL, PHONE_NUMBER } from '../../constance/regex.const';
import { checkApiValidator } from '../../validators/checkApi.validator';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit, OnChanges {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() maxLength?: number;
  @Input() hasLabel?: boolean;
  @Input() pattern?: string;

  @Input() min?: number;
  @Input() max?: number;

  @Input() isRequired?: boolean;
  @Input() isDisabled?: boolean;
  @Input() class?: string;
  @Input() type?: string;
  @Input() customError: string = '';
  @Input() errorAsync?: string;
  @Input() asyncFn?: any;
  @Input() fieldCheck?: string;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  icon?: string;
  @Input() errorRequire?: string = '';
  errorPattern = '';
  @Input() errorMaxLength?: string = '';

  errorMin = '';
  errorMax = '';
  errorBetween = '';
  isMatInput = true;
  @Input() id: string = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  currentVal = '';
  constructor() {
  }

  ngOnInit(): void {
    if (this.isRequired) {
      this.controlField.addValidators(Validators.required);
      this.errorRequire = `${this.placeholder} is required.`;
    }

    if (this.maxLength) {
      this.controlField.addValidators(Validators.maxLength(Number(this.maxLength)));
      this.errorMaxLength = `Exceed the ${this.maxLength} character limit.`;
    }

    if (this.pattern) {
      this.controlField.addValidators(Validators.pattern(this.pattern));
      this.errorPattern = `Invalid format.`;
    }

    if (this.isDisabled) {
      this.controlField.disable();
    }

    if (this.asyncFn && this.fieldCheck) {
      this.controlField.addAsyncValidators(checkApiValidator(this.asyncFn, this.fieldCheck));
    }

    this.errorBetween = `${this.placeholder} must be within the range of ${this.min} - ${this.max}.`

    if (this.max !== undefined && isFinite(this.max)) {
      this.controlField.addValidators(Validators.max, this.max);
      this.errorMax = `${this.placeholder} must be lower than ${this.max}.`
    }

    if (this.min !== undefined && isFinite(this.min)) {
      this.controlField.addValidators(Validators.min, this.min);
      this.errorMin = `${this.placeholder} must be higher than ${this.min}.`
    }

    // validate by type
    switch (this.type) {
      case PropertyType.TYPE_EMAIL:
        this.controlField.addValidators(Validators.pattern(EMAIL));
        this.errorPattern = `Invalid Email format.`;
        this.icon = 'email';
        break
      case PropertyType.TYPE_PHONE:
        this.formatPhoneNumberInput(this.controlField);
        this.maxLength = 30;
        this.controlField.addValidators(Validators.pattern(PHONE_NUMBER));
        this.errorPattern = `Invalid Phone Number format.`;
        this.icon = 'phone';
        break
      case PropertyType.TYPE_CURRENCY:
        break;
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isDisabled && changes.isDisabled.currentValue) {
      this.controlField?.disable();
    } else if (changes && changes.isDisabled && !changes.isDisabled.currentValue) {
      this.controlField?.enable();
    }
  }

  trimValue() {
    if (this.controlField.value) {
      this.controlField.setValue(this.controlField.value.trim());
    }
  }

  formatPhoneNumberInput(formControl: FormControl) {
    formControl?.valueChanges.subscribe(value => {
      let valueCleaned = ('' + value).replace(/[^0-9\(\)\.\-\ ]/g, '');
      if (valueCleaned !== value) {
        formControl?.setValue(valueCleaned);
      }

      if (!FORMAT_PHONE_NUMBER.test(formControl.value)) {
        let cleaned = ('' + value).trim().replace(/[^0-9]/g, '');
        if ((/^([0-9]{10})$/).test(cleaned)) {
          let match = cleaned.match(LIMIT_PHONE_NUMBER);
          if (match) {
            formControl?.setValue('(' + match[1] + ') ' + match[2] + '-' + match[3], { emitEvent: false });
          }
        }
      }
    });
  }

  onValueChange(event: Event) {
    this.valueChange.emit(this.controlField.value);
  }
}
