import { ConfirmPopupTitle, ConfirmType } from "@ptg-shared/constance/confirm-type.const";
import { DocumentListConfigurationPropertyKey, DocumentListConfigurationPropertyName } from "./services/models/document-list-configuration.model";

export const API_ERROR_TYPE  = 'CommandException'
export const MODULE_KEY  = 'file-module'
export enum FileType {
    doc = 'doc',
    docx = 'docx',
    csv = 'csv',
    xls = 'xls',
    xlsx = 'xlsx',
    ppt = 'ppt',
    pptx = 'pptx',
    pdf = 'pdf',
    png = 'png',
    jpg = 'jpg',
    jpeg = 'jpeg'
}

export const ListFileType = [
    {
        value: FileType.doc,
        displayValue: 'doc'
    },
    {
        value: FileType.docx,
        displayValue: 'docx'
    },
    {
        value: FileType.csv,
        displayValue: 'csv'
    },
    {
        value: FileType.xls,
        displayValue: 'xls'
    },
    {
        value: FileType.xlsx,
        displayValue: 'xlsx'
    },
    {
        value: FileType.ppt,
        displayValue: 'ppt'
    },
    {
        value: FileType.pptx,
        displayValue: 'pptx'
    },
    {
        value: FileType.pdf,
        displayValue: 'pdf'
    },
    {
        value: FileType.png,
        displayValue: 'png'
    },
    {
        value: FileType.jpg,
        displayValue: 'jpg'
    },
    {
        value: FileType.jpeg,
        displayValue: 'jpeg'
    },
];

export const GENERATE_DOCUMENT_STATUS = {
  COMPLETED: 'Completed'
}

export enum ParticipantDocumentType {
  DeathCertificate = 0,
  MarriedCertificate = 1,
  InvoiceVoucher= 2,
  Other= 3,
  AnnualPensionCertification = 4,
  BirthCertificate = 5,
  DocumentaryEvidence = 6,
  HistoricalScannedRemittance = 7,
  MarriageCertificate = 8,
  MedicalRecords = 9,
  QdroForms = 10,
  QdroDocuments = 11,
  Retire_RehireForm= 12,
  ScannedRetirementForms = 13,
}

export enum ParticipantGenerateDocumentType {
  NoticeofRetirement  = 1000,
  Age65Letter = 1001,
  PensionCertificationReport= 1002,
  CurrentAFRSDisbursementReport= 1003,
  PensionConfirmationLetter = 1004,
}

export enum EmployerDocumentType {
  Other = 10000,
  CertificationForm= 10001,
  Retire_RehireForm = 10002,
  InvoiceVoucher = 10003,
}

export const PARTICIPANT_DOCUMENT_TYPE_OPTIONS = [
    {
        value: ParticipantDocumentType.DeathCertificate,
        displayValue: 'Death Certificate'
    },
    {
        value: ParticipantDocumentType.MarriedCertificate,
        displayValue: 'Married Certificate'
    },
    {
        value: ParticipantDocumentType.InvoiceVoucher,
        displayValue: 'Invoice Voucher'
    },
    {
        value: ParticipantDocumentType.Other,
        displayValue: 'Other'
    },
];

export const PARTICIPANT_DOCUMENT_TYPE_OPTIONS_FOR_BVFF = [
  {
    value: ParticipantDocumentType.AnnualPensionCertification,
    displayValue: 'Annual Pension Certification'
  },
  {
    value: ParticipantDocumentType.BirthCertificate,
    displayValue: 'Birth Certificate'
  },
  {
    value: ParticipantDocumentType.DeathCertificate,
    displayValue: 'Death Certificate'
  },
  {
    value: ParticipantDocumentType.DocumentaryEvidence,
    displayValue: 'Documentary Evidence'
  },
  {
    value: ParticipantDocumentType.HistoricalScannedRemittance,
    displayValue: 'Historical Scanned Remittance'
  },
  {
    value: ParticipantDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher'
  },
  {
    value: ParticipantDocumentType.MarriageCertificate,
    displayValue: 'Marriage Certificate'
  },
  {
    value: ParticipantDocumentType.MedicalRecords,
    displayValue: 'Medical Records'
  },
  {
    value: ParticipantDocumentType.QdroForms,
    displayValue: 'QDRO Forms'
  },
  {
    value: ParticipantDocumentType.Retire_RehireForm,
    displayValue: 'Retire/Rehire Form'
  },
  {
    value: ParticipantDocumentType.ScannedRetirementForms,
    displayValue: 'Scanned Retirement Forms'
  },
  {
    value: ParticipantDocumentType.Other,
    displayValue: 'Other'
  },
];

export const EMPLOYER_DOCUMENT_TYPE_OPTIONS = [
  {
    value: EmployerDocumentType.CertificationForm,
    displayValue: 'Certification Form'
  },
  {
    value: EmployerDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher'
  },
  {
    value: EmployerDocumentType.Retire_RehireForm,
    displayValue: 'Retire/Rehire Form'
  },
  {
    value: EmployerDocumentType.Other,
    displayValue: 'Other'
  },
]

export const DEATH_CERTIFICATE_DOCUMENT_TYPE_OPTIONS = [
    {
        value: ParticipantDocumentType.DeathCertificate,
        displayValue: 'Death Certificate'
    },
    {
        value: ParticipantDocumentType.Other,
        displayValue: 'Other'
    },
];

export const REMOVE_DOCUMENT_CONFIRM_MSG = 'Document(s) will be removed from the system and cannot be undone. Are you sure you want to proceed?';

export const REMOVE_DOCUMENT_POPUP_CONFIG = {
  text: REMOVE_DOCUMENT_CONFIRM_MSG,
  type: ConfirmType.Delete,
  title: ConfirmPopupTitle.Remove,
}

export const ALL_MEMBER_DOCUMENT_LIST_URL_PATTERN = 'member/document-list';
export const ALL_EMPLOYER_DOCUMENT_LIST_URL_PATTERN = 'employer/document-list';
export const SINGLE_MEMBER_DOCUMENT_LIST_URL_PATTERN = 'member/individual-document/';
export const SINGLE_EMPLOYER_DOCUMENT_LIST_URL_PATTERN = 'employer/individual-document/';

export const DOCUMENT_LIST_TITLE = 'Document List';
export const DOCUMENT_LIST_CONFIGURATION_TITLE = 'Document List Configuration';

export const DOCUMENT_LIST_GEAR_ICON_CLASS = 'gear-icon-for-document-list';
export const DOCUMENT_LIST_GEAR_MENU_PANEL_SETTINGS_CLASS = 'menu-panel-for-document-list';

export const ALL_MEMBER_DOCUMENT_LIST_BREADSCRUMB_ITEM = {
  name: DOCUMENT_LIST_TITLE,
  url: 'member/document-list',
};
export const ALL_EMPLOYER_DOCUMENT_LIST_BREADSCRUMB_ITEM = {
  name: DOCUMENT_LIST_TITLE,
  url: 'employer/document-list',
};
export const DOCUMENT_LIST_CONFIGURATION_BREADSCRUMB_ITEM = {
  name: DOCUMENT_LIST_CONFIGURATION_TITLE,
  url: '',
};

export const DOCUMENT_LIST_CONFIGURATION_PROPERTY_KEY_AND_NAME_MAP = new Map<DocumentListConfigurationPropertyKey, DocumentListConfigurationPropertyName>([
  [DocumentListConfigurationPropertyKey.DocumentName, DocumentListConfigurationPropertyName.DocumentName],
  [DocumentListConfigurationPropertyKey.DocumentType, DocumentListConfigurationPropertyName.DocumentType],
  [DocumentListConfigurationPropertyKey.UploadedAt, DocumentListConfigurationPropertyName.UploadedAt],
  [DocumentListConfigurationPropertyKey.UploadedBy, DocumentListConfigurationPropertyName.UploadedBy],
  [DocumentListConfigurationPropertyKey.FileName, DocumentListConfigurationPropertyName.FileName],
  [DocumentListConfigurationPropertyKey.DocumentTags, DocumentListConfigurationPropertyName.DocumentTags],
  [DocumentListConfigurationPropertyKey.LastModifiedAt, DocumentListConfigurationPropertyName.LastModifiedAt],
  [DocumentListConfigurationPropertyKey.LastModifiedBy, DocumentListConfigurationPropertyName.LastModifiedBy],
  [DocumentListConfigurationPropertyKey.LinkTo, DocumentListConfigurationPropertyName.LinkTo],
]);
