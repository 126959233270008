import { Component, Inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { SortType } from '@ptg-shared/constance/value.const';
import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { DisbursementReportService, VendorService } from 'src/app/admin/features/external-payment/services';
import { AccidentClaim } from '../../services/models';
import { AccidentState } from '../../store/reducers';
import { createAccidentClaimsAction, editAccidentClaimsAction, getDisbursementReportsAction, getVendorsMasterDataAction } from '../../store/actions';
import { getDisbursementReportsSelector, getVendorsSelector } from '../../store/selectors';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DisbursementType } from '../../constants';
import { NUMBER_TEXT_SPECIAL } from '@ptg-shared/constance/regex.const';

@Component({
  selector: 'ptg-add-accident-claim',
  templateUrl: './add-accident-claim.component.html',
  styleUrls: ['./add-accident-claim.component.scss']
})
export class AddAccidentClaimComponent extends BaseComponent {
  NUMBER_TEXT_SPECIAL = NUMBER_TEXT_SPECIAL;
  readonly DisbursementType = DisbursementType
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isEdit = false;

  optionsChooseVendor: RadioOption[] = [
    {
      label: 'Choose Vendor',
      value: false,
    },
    {
      label: 'New Vendor',
      value: true,
    },
  ];

  disbursementTypeDefault = 0;
  listVendor: Option[] = [];
  listWarrantRegisterNumber: Option[] = [];
  listDisbursementType = [
    { displayValue: 'DEATH BEN - Death Benefit (Accident Related)', value: DisbursementType.DeathBen, valueShow: 'DEATH BEN' },
    { displayValue: 'DIS COMP - Disability Compensation (Accident Related)', value: DisbursementType.DisComp, valueShow: 'DIS COMP' },
    { displayValue: 'DUP PMT - Duplicate Payment (Accident Related)', value: DisbursementType.DupPMT, valueShow: 'DUP PMT' },
    { displayValue: 'MED SRVS - Medical Services (Accident Related)', value: DisbursementType.MedSRSV, valueShow: 'MED SRVS' },
    { displayValue: 'MILEAGE - Mileage, Travel Reimbursement (Accident Related)', value: DisbursementType.Mileage, valueShow: 'MILEAGE' },
    { displayValue: 'PPD - Perm/Part time Disability Settlement (Accident Related)', value: DisbursementType.Ppd, valueShow: 'PPD' },
    { displayValue: 'REIMB - Reimbursement for Medical Expense (Accident Related)', value: DisbursementType.Reimb, valueShow: 'REIMB' },
    { displayValue: 'RX - RX, Prescription Drugs (Accident Related)', value: DisbursementType.Rx, valueShow: 'RX' },
    { displayValue: 'CANCEL - Cancelled Warrant (State Statute, SOL)', value: DisbursementType.Cancel, valueShow: 'CANCEL' },
    { displayValue: 'REPL - Replacement Warrant (Lost, Stale, Destroyed)', value: DisbursementType.Repl, valueShow: 'REPL' },
  ];
  vendorNumber?: string;
  paymentDate?: string;
  warrantBatchNumber?: string;
  disbursementReportId?: string;
  isNewVendor: boolean = false;
  listVendorSearch!: Observable<Option[]>;
  listWarrantRegisterNumberSearch!: Observable<Option[]>;
  isLoading: boolean = true;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddAccidentClaimComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accidentClaim: AccidentClaim | null,
      clientId: string,
      memberId: string,
      accidentId: string,
    },
    private fb: FormBuilder,
    private disbursementReportService: DisbursementReportService,
    private vendorService: VendorService,
    private store: Store<AccidentState>,
    private switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.getVendorDataList();
    this.getWarrantRegisterNumberList();
    this.store
      .pipe(select(getVendorsSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (!data?.isLoading) {
          this.isLoading = false;
          this.listVendor = data?.payload?.map((item: any) => {
            return { displayValue: `${item.vendorName} (${item.vendorNumber})`, value: item.vendorNumber, id: item.id, valueShow: item.vendorName } as Option;
          }) ?? [];

          this.listVendorSearch = this.vendorIdControl?.valueChanges?.pipe(
            startWith(''),
            map((item: Option) => {
              const vendorNameOrNumber =
              typeof item === 'string' ? item : item?.valueShow;
            return vendorNameOrNumber
              ? this._filter(vendorNameOrNumber as string, this.listVendor)
              : this.listVendor?.slice(0, 2000);
            })
          );

          if (this.data?.accidentClaim) {
            this.isEdit = true;
            this.vendorNumber = this.data?.accidentClaim.vendorNumber;
            this.vendorIdControl?.setValue({
              id: this.data?.accidentClaim.vendorId,
              value: this.data?.accidentClaim.vendorNumber,
              valueShow: this.data?.accidentClaim.vendorName
            });
          }
        }
      })

    this.store
      .pipe(select(getDisbursementReportsSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        this.listWarrantRegisterNumber = data?.payload?.map((item: any) => {
          return {
            displayValue: item.warrantRegisterNumber,
            value: item.warrantRegisterNumber,
            valueShow: item.warrantRegisterNumber,
            extraData: {
              disbursementReportId: item.disbursementReportId,
              warrantBatchNumber: item.warrantBatchNumber,
              paymentDate: item.paymentDate
            }
          }
        }).sort((a, b) => a.displayValue.localeCompare(b.displayValue)) ?? [] as Option[];

        this.listWarrantRegisterNumberSearch = this.warrantBatchNumberControl?.valueChanges?.pipe(
          startWith(''),
          map((item: Option) => {
            const warrantBatchNumber =
            typeof item === 'string' ? item : item?.valueShow;
          return warrantBatchNumber
            ? this._filter(warrantBatchNumber as string, this.listWarrantRegisterNumber)
            : this.listWarrantRegisterNumber?.slice(0, 2000);
          })
        );

        if (this.data?.accidentClaim) {
          this.isEdit = true;
          this.warrantBatchNumber = this.data?.accidentClaim.warrantBatchNumber;
          this.warrantRegisterNumberControl?.setValue({
            value: this.data?.accidentClaim.warrantRegisterNumber,
            displayValue: this.data?.accidentClaim.warrantRegisterNumber,
            valueShow: this.data?.accidentClaim.warrantRegisterNumber
          });
        }
      });

    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  validateVendor() {
    if (this.vendorIdControl.value) {
      if (
        this.vendorIdControl.enabled &&
        typeof this.vendorIdControl.value === 'string'
      ) {
        this.vendorNumber = undefined;
        this.vendorIdControl.setErrors({ inValidAsync: true });
        return;
      }
      if (this.vendorIdControl.value.value === undefined) {
        this.vendorNumber = undefined;
        this.vendorIdControl.setErrors({ required: true });
      }
    }
    if (!this.vendorIdControl.value) {
      this.vendorNumber = undefined;
      this.vendorIdControl.setErrors({ required: true });
    }
  }

  displayFn(value: any): string {
    return value && value.valueShow ? value.valueShow : '';
  }

  private _filter(name: string, listResult: Option[]): Option[] {
    const filterValue = name.toLowerCase();

    return listResult.filter((option: Option) =>
      option.displayValue.toLowerCase().includes(filterValue)
    ).slice(0, 2000);
  }

  getVendorDataList() {
    let sortType = SortType.ASC;
    let sortNames = 'VendorName';
    this.store.dispatch(
      getVendorsMasterDataAction({
        request: {
          sortNames,
          sortType,
        },
        clientId: this.data.clientId
      })
    );
  }

  getWarrantRegisterNumberList() {
    let sortType = SortType.DESC;
    let sortNames = 'PaymentDate'
    this.store.dispatch(
      getDisbursementReportsAction({
        request: {
          sortNames,
          sortType,
        },
        clientId: this.data.clientId
      })
    )
  }

  validatePaymentNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (this.data.accidentClaim && control.value.toString() === this.data?.accidentClaim.paymentNumber) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.disbursementReportService.checkkUniquePaymentNumber(control.value.toString(), this.data.accidentClaim?.accidentClaimId || '', this.data.clientId).pipe(
            map((res: any) => {
              if (res?.exists) {
                return { errorMessagePaymentNumber: 'Payment Number already exists.' };
              }
              return null;
            }),
            catchError(({ error }) => {
              return of({ errorMessagePaymentNumber: error?.errorMessage });
            }),
          )
        )
      );
    };
  }

  validateVendorNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.vendorService.checkkUniqueVendorNumber(control.value.toString(), '', this.data.clientId).pipe(
            map((res: any) => {
              if (res?.exists) {
                return { errorMessageVendorNumber: 'Vendor Number already exists.' };
              }
              return null;
            }),
            catchError(({ error }) => {
              return of({ errorMessageVendorNumber: error?.errorMessage });
            }),
          )
        )
      );
    };
  }

  initFormGroup() {
    const accidentClaim = this.data?.accidentClaim;
    if (accidentClaim) {
      this.isEdit = true;
      this.vendorNumber = accidentClaim.vendorNumber;
      this.warrantBatchNumber = accidentClaim.warrantBatchNumber;
      this.paymentDate = accidentClaim.paymentDate;

    }
    this.editForm = this.fb.group({
      disbursementReportId: accidentClaim ? '' : this.disbursementReportId,
      disbursementReportPaymentId: accidentClaim?.disbursementReportPaymentId,
      vendorId: this.fb.control(accidentClaim?.vendorNumber ?? '', [Validators.required]),
      vendorName: this.fb.control('', [Validators.required]),
      vendorNumber: this.fb.control('', {
        asyncValidators: this.validateVendorNumber()
      }),
      warrantRegisterNumber: this.fb.control(accidentClaim?.warrantRegisterNumber ?? '', [Validators.required]),
      warrantBatchNumber: this.fb.control(accidentClaim?.warrantBatchNumber ? accidentClaim?.warrantBatchNumber : this.warrantBatchNumber),
      paymentDate: this.fb.control(accidentClaim?.paymentDate ? accidentClaim?.paymentDate : this.paymentDate),
      paymentNumber: this.fb.control(accidentClaim?.paymentNumber, {
        validators: [Validators.required],
        asyncValidators: this.validatePaymentNumber()
      }),

      disbursementType: this.fb.control(accidentClaim?.disbursementTypeCode),
      amount: this.fb.control(accidentClaim?.amount, {
        validators: [Validators.required],
      }),
      chooseVendor: this.fb.control(false),
    });
    this.changeChooseVendor(false);
  }

  get vendorNameControl(): FormControl {
    return this.editForm?.get('vendorName') as FormControl;
  }

  get vendorNumberControl(): FormControl {
    return this.editForm?.get('vendorNumber') as FormControl;
  }

  get vendorIdControl(): FormControl {
    return this.editForm?.get('vendorId') as FormControl;
  }
  get warrantRegisterNumberControl(): FormControl {
    return this.editForm.get('warrantRegisterNumber') as FormControl;
  }
  get warrantBatchNumberControl(): FormControl {
    return this.editForm.get('warrantBatchNumber') as FormControl;
  }
  get disbursementTypeControl(): FormControl {
    return this.editForm?.get('disbursementType') as FormControl;
  }

  changeChooseVendor(event: any) {
    this.isNewVendor = event;
    if (event) {
      this.vendorIdControl.disable();
      this.vendorNameControl.enable();
      this.vendorNumberControl.enable();
    } else {
      this.vendorIdControl.enable();
      this.vendorNameControl.disable();
      this.vendorNumberControl.disable();
    }
  }

  onVendorChangedValue() {
    this.vendorNumber = this.vendorIdControl.value.value;
  }

  onWarrantRegisterNumberChangedValue() {
    this.warrantBatchNumber = this.warrantRegisterNumberControl.value.value;
    this.paymentDate = this.warrantRegisterNumberControl.value?.extraData?.paymentDate;
    this.disbursementReportId = this.warrantRegisterNumberControl.value?.extraData?.disbursementReportId;
  }

  onSubmit() {
    const accidentClaim = deepClone(this.editForm.value as AccidentClaim);
    if (!this.isNewVendor) {
      const vendor = this.vendorIdControl.value;
      accidentClaim.vendorName = vendor.valueShow;
      accidentClaim.vendorNumber = vendor.value;
      accidentClaim.vendorId = vendor.id;
    } else {
      accidentClaim.vendorId = undefined;
    }

    const accidentClaimDataSubmit = {
      ...accidentClaim,
      disbursementReportId: this.disbursementReportId,
      paymentDate: this.paymentDate,
      warrantBatchNumber: this.warrantBatchNumber
    }


    if (this.data?.accidentClaim?.accidentClaimId) {
      this.store.dispatch(
        editAccidentClaimsAction({
          accidentId: this.data.accidentId,
          memberId: this.data.memberId,
          request: accidentClaimDataSubmit,
          accidentClaimId: this.data.accidentClaim?.accidentClaimId ?? ''
        })
      );
    } else {
      this.store.dispatch(
        createAccidentClaimsAction({
          accidentId: this.data.accidentId,
          memberId: this.data.memberId,
          request: accidentClaimDataSubmit,
        })
      );
    }
    this.dialogRef.close();
  }
}
