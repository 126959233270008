<div class="granulation-container" [ngClass]="classGranulation">
  <ng-container [ngSwitch]="formControlType">
    <!-- is config form -->
    <ng-container *ngSwitchCase="1">
      <ptg-radio-button
        [controlField]="form.get('granulationOptions')"
        [listOption]="optionsGranulation"
        [isObject]="true"
        [isDisabled]="formControlDetail?.isDisabled"
        (changeValue)="changeGranulation($event)"
      ></ptg-radio-button>
    </ng-container>
    <!-- is input data -->
    <ng-container *ngSwitchCase="2">
      <ptg-textbox
        [controlField]="form.get('fieldText')"
        [placeholder]="formControlDetail?.label"
        [hasLabel]="true"
        class="w-full"
        inputType="Text"
        [isRequired]="formControlDetail?.isRequired"
        [isDisabled]="formControlDetail?.isDisabled"
        *ngIf="isNone"
      >
      </ptg-textbox>
      <ptg-textbox
        [controlField]="form.get('fieldYears')"
        placeholder="Year(s)"
        [hasLabel]="true"
        class="w-full"
        inputType="Number"
        *ngIf="isHaveYears"
        [isNumberOnly]="true"
        [isStrict]="false"
        [isDisabled]="formControlDetail?.isDisabled"
        (valueChange)="changeData($event)"
      >
      </ptg-textbox>
      <ptg-textbox
        [controlField]="form.get('fieldMonths')"
        [maxLength]="2"
        placeholder="Month(s)"
        [hasLabel]="true"
        [min]="0"
        [max]="12"
        class="w-full"
        inputType="Number"
        *ngIf="isHaveMonths"
        [isStrict]="false"
        [isDisabled]="formControlDetail?.isDisabled"
        (valueChange)="changeData($event)"
      >
      </ptg-textbox>
      <ptg-textbox
        [controlField]="form.get('fieldDays')"
        [maxLength]="2"
        placeholder="Day(s)"
        [hasLabel]="true"
        [min]="0"
        [max]="31"
        class="w-full"
        inputType="Number"
        *ngIf="isHaveDays"
        [isStrict]="false"
        [isDisabled]="formControlDetail?.isDisabled"
        (valueChange)="changeData($event)"
      >
      </ptg-textbox>
    </ng-container>
    <!-- is display value -->
    <ng-container *ngSwitchCase="3"></ng-container>
  </ng-container>
</div>
