import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CreateReversalAdjustmentService } from '../../services';
import * as CreateReversalAdjustmentActions from '../actions/create-reversal-adjustment.actions';

@Injectable()
export class CreateReversalAdjustmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly createReversalAdjustmentService: CreateReversalAdjustmentService,
  ) {}

  createReversalAdjustment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateReversalAdjustmentActions.createReversalAdjustmentAction),
      switchMap(({ params }) => {
        return this.createReversalAdjustmentService.createReversalAdjustment(params).pipe(
          map((response) => CreateReversalAdjustmentActions.createReversalAdjustmentSuccess({ response })),
          catchError((err) => of(CreateReversalAdjustmentActions.createReversalAdjustmentFailure(err))),
        );
      }),
    ),
  );
}
