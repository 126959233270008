import { SortType } from "@ptg-shared/constance";

export interface GetDocumentListConfigurationPropertiesRequest {

}

export interface GetDocumentListConfigurationPropertiesResponse {
  properties: DocumentListConfigurationAvailableProperty[],
}

export interface GetDocumentListConfigurationDetailRequest {

}

export interface GetDocumentListConfigurationDetailResponse {
  properties: DocumentListConfigurationDetailProperty[],
}

export interface UpdateDocumentListConfigurationRequest {
  properties: DocumentListConfigurationDetailProperty[]
}

// Available Property -> Stand for current Available Properties can use for config
export interface DocumentListConfigurationAvailableProperty {
  name: string,
  key: DocumentListConfigurationPropertyKey,
}

// Detail Property -> Stand for current Saved Properties for current config
export interface DocumentListConfigurationDetailProperty {
  id?: string,
  propertyKey: DocumentListConfigurationPropertyKey,
  columnName: string,
  orderColumn: number,
  orderRow: number | null,
  sortType: SortType | null,

  // Use in Config flow
  columnNameDescription?: string,
  isUsedInSortRowTable?: boolean,
}

export enum DocumentListConfigurationPropertyKey {
  DocumentName = "documentName",
  DocumentType = "documentType",
  UploadedAt = "uploadedAt",
  UploadedBy = "uploadedBy",
  FileName = "fileName",
  DocumentTags = "documentTags",
  LastModifiedAt = "lastModifiedAt",
  LastModifiedBy = "lastModifiedBy",
  LinkTo = "linkTo",
}

export enum DocumentListConfigurationPropertyName {
  DocumentName = "Document Name",
  DocumentType = "Document Type",
  UploadedAt = "Uploaded at",
  UploadedBy = "Uploaded by",
  FileName = "File Name",
  DocumentTags = "Document Tags",
  LastModifiedAt = "Last Modified at",
  LastModifiedBy = "Last Modified by",
  LinkTo = "Link to",
}
