import { Store } from '@ngrx/store';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { PaymentInfoAdjustmentType, PaymentInstructionType } from '../enums/payment-info-tab.enum';
import { PaymentInfoTabLoadingStateHandler, PaymentTab } from '../../services/models';
import { PayeeDetailState } from '../../store/reducers/index';
import { getPaymentInfoTabsSelector } from '../../store/selectors/payment-info-tab.selector';

export const GRID_COLUMN_PAYMENT_INFO_TAB: Column[] = [
  {
    name: 'payStatusName',
    header: {
      title: 'Status',
    },
    truncate: true,
  },
  {
    name: 'benefitPeriod',
    header: {
      title: 'Benefit Period',
    },
    truncate: true,
  },
  {
    name: 'payableDate',
    header: {
      title: 'Payable Date',
    },
    truncate: true,
  },
  {
    name: 'payeeName',
    header: {
      title: 'Payee',
    },
    truncate: true,
  },
  {
    name: 'paymentTypeName',
    header: {
      title: 'Type',
    },
    truncate: true,
  },
  {
    name: 'gross',
    header: {
      title: 'Gross',
    },
    truncate: true,
    align: Align.Right,
  },
  {
    name: 'totalDeduction',
    header: {
      title: 'Deds (Total)',
    },
    truncate: true,
    align: Align.Right,
  },
  {
    name: 'netPayment',
    header: {
      title: 'Net Payment',
    },
    truncate: true,
    align: Align.Right,
  },
  {
    name: 'note',
    header: {
      title: 'Note',
    },
    truncate: true,
    align: Align.Center,
  },
  {
    name: 'calculation',
    header: {
      title: 'Calculation',
    },
    truncate: true,
    align: Align.Center,
  },
];

export const GRID_COLUMN_INSTRUCTION_HISTORY_TAB: Column[] = [
  {
    name: 'paymentInstructionTypeName',
    header: {
      title: 'Instruction Type',
    },
    truncate: true,
  },
  {
    name: 'statusName',
    header: {
      title: 'Status',
    },
    truncate: true,
  },
  {
    name: 'payeeName',
    header: {
      title: 'Payee',
    },
    truncate: true,
  },
  {
    name: 'benefitPeriod',
    header: {
      title: 'Benefit Period',
    },
    truncate: true,
  },
  {
    name: 'updatedAt',
    header: {
      title: 'Updated At',
    },
    truncate: true,
    type: ColumnType.DateTime,
    templateArgs: { 
      format: 'MM/dd/yyyy hh:mm a'
    },
  },
  {
    name: 'updatedBy',
    header: {
      title: 'Updated By',
    },
    truncate: true,
  },
  {
    name: 'calculation',
    header: {
      title: 'Calculation',
    },
    truncate: true,
    align: Align.Center,
  },
];

export const ONE_TIME_PAYMENT_INSTRUCTION_TYPE: PaymentInstructionType[] = [
  PaymentInstructionType.InitialPayment, // Manual-OneTime
  PaymentInstructionType.Correction, // Manual-OneTime
  PaymentInstructionType.Reissue, // Manual-OneTime
  PaymentInstructionType.FinalPayment, //Manual-OneTime
  PaymentInstructionType.PeriodicLumpsumPayment, // Manual-OneTime
  PaymentInstructionType.AutoOneTime, //Auto-OneTime
  PaymentInstructionType.CorrectionPayingSuspendedPeriod, // Manual-OneSubTime
  PaymentInstructionType.CorrectionUnderpaymentIncorrectBenefitAmount, // Manual-OneSubTime
  PaymentInstructionType.CorrectionUnderpaymentIncorrectDeductionAmount, // Manual-OneSubTime
  PaymentInstructionType.CorrectionOthers, //Manual-OneSubTime

  PaymentInstructionType.AllOneTime, // Technical Reserved
];

export const isOneTimePayment = (paymentTab?: PaymentTab) => {
  return (
    (paymentTab?.paymentType && ONE_TIME_PAYMENT_INSTRUCTION_TYPE.includes(paymentTab.paymentType)) ||
    !!paymentTab?.lumpSumPaymentTypeName ||
    paymentTab?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
  );
};

export const shouldUseLumpsumpName = (paymentType?: PaymentInstructionType) => {
  return paymentType === PaymentInstructionType.AutoOneTime;
};

export const shouldHideBenefitPeriod = (paymentType?: PaymentInstructionType) => {
  return (
    paymentType === PaymentInstructionType.PeriodicLumpsumPayment || paymentType === PaymentInstructionType.AutoOneTime
  );
};

export const handlePaymentInfoTabLoadingState = (
  context: PaymentInfoTabLoadingStateHandler,
  payeeDetailStore: Store<PayeeDetailState>,
) => {
  payeeDetailStore
    .select(getPaymentInfoTabsSelector)
    .pipe(
      filter((res) => !!res),
      tap((res) => (context.isLoading = !!res?.isLoading)),
      takeUntil(context.unsubscribe$),
    )
    .subscribe();
};

export enum PaymentType {
  Check,
  'Direct Deposit',
  'Wire Transfer',
  'Internal Payment',
}
