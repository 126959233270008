import { FormControl } from '@angular/forms';

import { FieldData } from './field-data';

export interface FormControlDetail {
  name?: any;
  label?: any;
  type?: any;
  maxLength?: any;
  min?: any;
  max?: any;
  fractionalLength?: any;
  option?: any;
  options?: any[];
  lstOption: any[];
  formControl: FormControl;
  lstChildFormControl?: FieldData[];
  isRequired?: any;
  configs?: {
    encrypted: string;
    includeInBulkUpdate: string;
    initiated: string;
    masked: string;
    readOnly: string;
    required: string;
    unique: string;
    autogenerated?: string;
    pattern?: string;
    patternPrefix?: string;
    patternSuffix?: string;
    startingValue?: string;
    fixedKey?: string;
  };
  config?: any;
  customError?: any;
  isHidden?: boolean;
  isValidation?: boolean;
  minMessage?: string;
  maxMessage?: string;
  data?: any;
  isDisabled?: boolean;
  recordId?: string;
  entityPropertyId: string;
  isIdentifierAutoGeneratedPropertyType?: boolean;
  fixedPropertyKey?: string;
  staticPropertyType?: number;
  isMultiple?: boolean;
  messageError?: string;
  isActive?: boolean;
  disableRow?: boolean;
  code?: string;
  value?: any;
  isShowCustomMessage?: boolean;
  allowNegativeNumbers?: boolean;
  belongPropertiesOrder?: any[];
}
