import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import {
  clearParticipantListConfigurationState,
  getEntityCardSelectionRequest,
  getEntityCardSelectionRequestFailure,
  getEntityCardSelectionRequestSuccess,
  getEntityPropertyByCardSelectionFailure,
  getEntityPropertyByCardSelectionRequest,
  getEntityPropertyByCardSelectionSuccess,
  getEntityPropertyListFailure,
  getEntityPropertyListSuccess,
  getEntityPropertyOfParticipantsRequest,
  getEntityPropertyOfParticipantsRequestFailure,
  getEntityPropertyOfParticipantsRequestSuccess,
  getParticipantListConfigurationFailure,
  getParticipantListConfigurationSuccess,
  setParticipantListConfigurationFailure,
  setParticipantListConfigurationSuccess
} from '../actions';
import {
  EntityPropertyDisplayConfig,
  EntityPropertyDisplayConfiguration,
  EntityPropertyDisplayResponse
} from '../../services/models';
import { BaseActionState } from '@ptg-shared/types/models';
import { GetCardSelectionResponse, PropertyDisplayResponse } from '@ptg-member/types/models';
import { AnyPtrRecord } from 'dns';

export const entityListViewConfigurationFeatureKey = 'entityListViewConfiguration';

export interface EntityListViewConfigurationState {
  isLoading: boolean;
  propertyConfigs: EntityPropertyDisplayConfig[];
  fixPersonProperties: EntityPropertyDisplayConfig[];
  propertyDisplayConfigurations: EntityPropertyDisplayConfiguration[];
  updateState: string;
  cards?: BaseActionState<GetCardSelectionResponse>;
  propertyByCardSelection?: BaseActionState<any>;
  propertyConfiguration?: BaseActionState<EntityPropertyDisplayResponse>;
}

const initialState: EntityListViewConfigurationState = {
  isLoading: true,
  propertyConfigs: [],
  fixPersonProperties: [],
  propertyDisplayConfigurations: [],
  updateState: '',
};

export const entityListViewConfigurationReducer = createReducer(
  initialState,
  on(clearParticipantListConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
    })
  ),
  on(getEntityPropertyListSuccess,
    (state, { propertyConfigs, fixPersonProperties }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
      fixPersonProperties
    })
  ),
  on(getEntityPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(getParticipantListConfigurationSuccess,
    (state, { propertyDisplayConfigurations }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations,
    })
  ),
  on(getParticipantListConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
    })
  ),
  on(setParticipantListConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
    })
  ),
  on(setParticipantListConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
      updateState: STATE.FAIL,
    })
  ),

  on(getEntityCardSelectionRequest, (state) => ({
    ...state,
    cards: {
      isLoading: true,
    },
  })),
  on(getEntityCardSelectionRequestSuccess, (state, { response }) => ({
    ...state,
    cards: {
      isLoading: false,
      success: true,
      cards: response.cards
    },
  })),
  on(getEntityCardSelectionRequestFailure, (state) => ({
    ...state,
    cards: {
      isLoading: false,
    },
  })),

  on(getEntityPropertyByCardSelectionRequest, (state) => ({
    ...state,
    propertyByCardSelection: {
      isLoading: true,
    },
  })),
  on(getEntityPropertyByCardSelectionSuccess, (state, { response }) => ({
    ...state,
    propertyByCardSelection: {
      isLoading: false,
      success: true,
      propertyByCardSelection: response
    }
  })),

  on(getEntityPropertyByCardSelectionFailure, (state) => ({
    ...state,
    propertyByCardSelection: {
      isLoading: false,
    },
  })),

  on(getEntityPropertyOfParticipantsRequest, (state) => ({
    ...state,
    propertyConfiguration: {
      isLoading: true,
    },
  })),
  on(getEntityPropertyOfParticipantsRequestSuccess, (state, { propertyOfParticipants }) => ({
    ...state,
    propertyConfiguration: {
      isLoading: false,
      success: true,
      payload: propertyOfParticipants
    },
  })),

  on(getEntityPropertyOfParticipantsRequestFailure, (state) => ({
    ...state,
    propertyConfiguration: {
      isLoading: false,
    },
  })),
);
