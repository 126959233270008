<ptg-dialog class="edit-funding-sources flex flex-col" [title]="dialogTitle" [isFullScreen]="true">
  <!-- DIALOG CONTENT HEADER -->
  <ng-template dialogContentHeader>
    <ptg-payment-header
      [targetIdFromDialog]="data?.earningInfo?.memberId ?? ''"
      [isShowInteractionComponents]="false"
    ></ptg-payment-header>
  </ng-template>
  <!-- DIALOG CONTENT BODY -->
  <ng-template dialogBody>
    <div class="edit-funding-sources__body" *ngIf="!isLoading; else loadingBlock">
      <div class="flex flex-col gap-6">
        <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

        <div class="action-buttons flex gap-2">
          <ptg-button
            classInput="primary"
            buttonName="Save"
            [isDisabled]="isDisabledSaveBtn"
            (clickButton)="onSave()"
          ></ptg-button>
          <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
        </div>

        <div class="flex flex-col gap-[14px]">
          <div class="calculated-amounts flex gap-6">
            <div class="min-w-[140px]">
              <div class="text-sm leading-6 emphasis-medium">Gross Payment</div>
              <div class="text-base emphasis-high">{{ earningInfo?.grossPayment ?? 0 | numberLocalDecimal: '$' }}</div>
            </div>
            <div class="min-w-[140px]">
              <div class="text-sm leading-6 emphasis-medium">Total Deductions</div>
              <div class="text-base emphasis-high">
                {{ earningInfo?.totalDeductions ?? 0 | numberLocalDecimal: '$' }}
              </div>
            </div>
            <div class="min-w-[140px]">
              <div class="text-sm leading-6 emphasis-medium">Net Payment</div>
              <div class="text-base emphasis-high">{{ earningInfo?.netPayment ?? 0 | numberLocalDecimal: '$' }}</div>
            </div>
          </div>

          <div class="text-xl font-bold emphasis-high">{{ fundingSourceTitle }}</div>
          <mat-error *ngIf="isShowErrorFundingSource" class="text-sm mt-[-14px]">
            At least one Funding Source must be added.
          </mat-error>
          <div
            *ngIf="fundingSourcesFormArray && fundingSourcesFormArray.controls.length"
            class="funding-sources flex flex-col gap-3 w-[700px]"
          >
            <div
              class="flex gap-3"
              *ngFor="let child of fundingSourcesFormArray.controls; let i = index; trackBy: identify"
            >
              <ptg-select
                [placeholder]="fundingSourceTitle"
                class="w-full"
                [controlField]="child.get('id')"
                [isSetMaxWidthSelect]="true"
                [isOptionObj]="true"
                [listData]="child?.get('fundingSources')?.value ?? []"
                (changeOptionValue)="onChangeFundingSource()"
                [isRequired]="true"
              ></ptg-select>
              <ptg-textbox
                placeholder="Amount"
                class="w-full"
                [controlField]="child.get('amount')"
                [hasLabel]="true"
                [inputType]="InputType.TYPE_CURRENCY"
                [max]="maxValueForCurrency"
                customError="errorAmountMessage"
                [maxErrorMessage]="'Amount must be less than or equal to ' + maxValueForCurrency"
                (onOutFocus)="onChangeFundingAmount()"
              ></ptg-textbox>
              <ptg-button classInput="delete-icon text-only" (clickButton)="removeFundingSourceItem(i)">
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </div>
          </div>

          <ptg-button
            *ngIf="isShowFundingSourceButton"
            buttonName="New Funding Source"
            iconName="add"
            classInput="secondary add-button"
            (clickButton)="addFundingSourceItem()"
          ></ptg-button>

          <ng-container *ngIf="earningInfo?.isRecurringRecord">
            <hr />

            <div class="text-xl font-bold emphasis-high">{{ adjustmentFundingTitle }}</div>

            <div
              *ngIf="adjustmentFundingFormArray && adjustmentFundingFormArray.controls.length"
              class="adjustment-funding flex flex-col gap-3 w-[1385px]"
            >
              <div
                class="flex gap-3"
                *ngFor="let child of adjustmentFundingFormArray.controls; let i = index; trackBy: identify"
              >
                <ptg-select
                  [placeholder]="adjustmentFundingTitle"
                  class="w-full"
                  [controlField]="child.get('id')"
                  [isSetMaxWidthSelect]="true"
                  [isOptionObj]="true"
                  [listData]="adjustmentFundingOptionList"
                  [isDisabled]="!child.get('isEditable')?.value"
                ></ptg-select>
                <ptg-textbox
                  placeholder="Amount"
                  class="w-full"
                  [controlField]="child.get('amount')"
                  [hasLabel]="true"
                  [inputType]="InputType.TYPE_CURRENCY"
                  [max]="maxValueForCurrency"
                  [maxErrorMessage]="'Amount must be less than or equal to ' + maxValueForCurrency"
                  customError="errorAmountMessage"
                  (onOutFocus)="onChangeFundingAmount()"
                  [isDisabled]="!child.get('isEditable')?.value"
                ></ptg-textbox>
                <ptg-datepicker
                  placeholder="Start Date"
                  class="w-full"
                  [controlField]="child.get('startDate')"
                  (changeValue)="onChangeStartDate($event, i)"
                  customError="errorDateMessage"
                  [isRequired]="true"
                  [minDate]="$any(earningInfo.benefitPeriodStartDate)"
                  errorMinDate="Start Date is invalid."
                  [isDisabled]="!child.get('isEditable')?.value"
                ></ptg-datepicker>
                <ptg-datepicker
                  placeholder="End Date"
                  class="w-full"
                  [controlField]="child.get('endDate')"
                  (changeValue)="onChangeEndDate($event, i)"
                  customError="errorDateMessage"
                  [isRequired]="true"
                  [minDate]="$any(earningInfo.benefitPeriodStartDate)"
                ></ptg-datepicker>
                <ptg-button classInput="delete-icon text-only" (clickButton)="removeAdjustmentFundingItem(i)">
                  <mat-icon>delete_forever</mat-icon>
                </ptg-button>
              </div>
            </div>

            <div class="emphasis-medium">
              Start Date and End Date must be Start Date and End Date of Payroll cycles.
            </div>

            <ptg-button
              buttonName="New Adjustment Funding"
              iconName="add"
              classInput="secondary add-button"
              (clickButton)="addAdjustmentFundingItem()"
            ></ptg-button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</ptg-dialog>

<ng-template #loadingBlock>
  <div class="spinner-wrapper" [class.active]="isLoading">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>
