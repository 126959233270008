import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

import { DepositAccountService } from '@ptg-member/services/deposit-account.service';
import {
  BankAccountType,
  CheckExistsDepositAccountNumberRequest,
  UpsertDepositAccountRequest,
} from '@ptg-member/types/models/deposit-account.model';
import * as fromReducer from '@ptg-reducers';
import { createDepositAccountsAction, setDepositAccountAction } from '@ptg-member/store/actions/deposit-account.action';

@Component({
  selector: 'ptg-upsert-deposit-account',
  templateUrl: './upsert-deposit-account.component.html',
  styleUrls: ['./upsert-deposit-account.component.scss'],
})
export class UpsertDepositAccountComponent extends BaseComponent {
  readonly BankAccountType = BankAccountType;
  listAccountType = ['Checking', 'Savings'];
  editForm!: FormGroup;

  formSubmit$ = new Subject<boolean>();
  isLoading: boolean = true;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UpsertDepositAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { memberId: string; isEdit?: boolean; depositAccountDetail: any },
    public depositAccountService: DepositAccountService,
    private store: Store<fromReducer.State>,
  ) {
    super();
    this.editForm = new FormGroup({
      active: new FormControl(true),
      routingNumber: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(9)],
        asyncValidators: this.depositAccountService.checkApiValidator(
          this.data?.depositAccountDetail ? this.data?.depositAccountDetail?.routingNumber : '',
        ),
      }),
      accountNumber: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(30)],
        asyncValidators: [this.checkBoardBankExistValidator()],
      }),
      accountType: new FormControl('Checking'),
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onInitForm();
  }

  onInitForm(): void {
    if (this.data?.isEdit) {
      this.editForm?.setValue({
        active: this.data?.depositAccountDetail?.isActive,
        routingNumber: this.data?.depositAccountDetail?.routingNumber,
        accountNumber: this.data?.depositAccountDetail?.accountNumber,
        accountType: this.data?.depositAccountDetail?.accountType,
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }

    this.editForm.get('routingNumber')?.valueChanges.subscribe((value) => {
      if (value) {
        this.editForm.get('accountNumber')?.updateValueAndValidity();
      }
    });
  }

  checkBoardBankExistValidator(editValue?: string): AsyncValidatorFn {
    // Need obs to call api for check
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const routingNumber = control.parent?.get('routingNumber')?.value;
      const accountNumber = control.parent?.get('accountNumber')?.value;
      const bankId = control.parent?.get('routingNumber')?.value ? this.depositAccountService.bankInfo?.id : null;
      if (
        (routingNumber &&
          accountNumber &&
          this.data.depositAccountDetail?.routingNumber === routingNumber &&
          this.data.depositAccountDetail?.accountNumber === accountNumber) ||
        !bankId ||
        !accountNumber
      ) {
        return of(null);
      }

      const request: CheckExistsDepositAccountNumberRequest = {
        id: this.data?.depositAccountDetail?.id ?? null,
        targetId: this.data?.memberId,
        bankId: bankId,
        accountNumber: accountNumber,
      };

      return this.depositAccountService.checkExitsDepositAccountNumber(request).pipe(
        map((response: any) => {
          if (response?.isExists) {
            const bankName = this.depositAccountService.bankInfo?.name;
            return { invalidValue: `This account of ${bankName} already exist.` };
          }
          return null;
        }),
        catchError((err) => {
          return of(null);
        }),
      );
    };
  }

  onSubmit() {
    if (this.editForm.pending) {
      let sub = this.editForm.statusChanges.subscribe(() => {
        if (this.editForm.valid) {
          this.onSave();
        }
        sub.unsubscribe();
      });
    } else if (this.editForm.valid) {
      this.onSave();
    }
  }

  onSave() {
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }

    let request: UpsertDepositAccountRequest = {
      targetId: this.data?.memberId,
      bankId: this.depositAccountService.bankInfo?.id,
      accountNumber: this.editForm.get('accountNumber')?.value,
      isActive: this.editForm.get('active')?.value,
      accountType:
        this.editForm.get('accountType')?.value === 'Checking' ? BankAccountType.Checking : BankAccountType.Savings,
    };

    if (!this.data?.isEdit) {
      this.store.dispatch(createDepositAccountsAction({ request }));
    } else {
      request.id = this.data?.depositAccountDetail?.id;
      this.store.dispatch(setDepositAccountAction({ request }));
    }

    this.dialogRef.close();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
