import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { ACTION, STATE } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { deepClone, showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CalculationListService } from '../../services';
import {
  CalculationState,
  clearRetirementBenefitCalculationState,
  getBenefitTypesAction,
  getBenefitTypesSelector,
  selectUploadCalculationFileState,
  uploadCalculationFileRequest,
} from '../../store';
import { Calculations, GetBenefitTypeRequest } from '../../services/models';
import { BenefitRequestType } from '../../types/enums/calculation.enum';

@Component({
  selector: 'ptg-upload-calculation-dialog',
  templateUrl: './upload-calculation-dialog.component.html',
  styleUrls: ['./upload-calculation-dialog.component.scss'],
})
export class UploadCalculationDialogComponent extends BaseComponent {
  file?: File;
  uploadForm!: FormGroup;
  supportedFileTypes: string = 'excel file format.';
  checkPattern: RegExp = new RegExp(/^[\x00-\x7F]+\.(xls|xlsx)$/, 'i');
  acceptFile = '.xls,.xlsx';
  bannerType: BannerType = BannerType.Hidden;
  listBenefitType: Option[] = [];
  listSubType: Option[] = [];
  message: string = '';
  @ViewChild('fileDocument') private fileDocument!: UploadComponent;
  fileSelected!: File;
  formSubmit$: Subject<void> = new Subject();
  get fileControl() {
    return this.uploadForm.get('fileControl') as FormControl;
  }

  get benefitEntityControl(): FormControl {
    return this.uploadForm?.get('benefitEntityId') as FormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<UploadCalculationDialogComponent>,
    public dialog: MatDialog,
    public switchConfirmPopupService: SwitchConfirmPopupService,
    @Inject(MAT_DIALOG_DATA) public data: Calculations,
    private fb: FormBuilder,
    private calculationStore: Store<CalculationState>,
    private calculationListService: CalculationListService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setDefaultData();
    this.checkUploadCalculationState();
    this.checkFormSubmit();
    this.getListBenefitType();
  }

  setDefaultData() {
    this.uploadForm = this.initUploadForm();
    if (this.data.fileDocument?.fileSnapshot?.fileName) {
      this.fileSelected = new File([''], this.data.fileDocument?.fileSnapshot?.fileName ?? '');
    }
  }

  initUploadForm() {
    return this.fb.group({
      id: this.fb.control(this.data?.id ?? ''),
      name: this.fb.control(this.data?.name ?? '', {
        validators: [Validators.required, Validators.maxLength(100)],
        asyncValidators: checkApiValidator(this.calculationListService.checkFileNameExisted, 'name', this.data?.name),
      }),
      description: this.fb.control(this.data?.description ?? ''),
      benefitEntityId: this.fb.control({ value: this.data?.benefitEntityId, disabled: this.data?.id }),
      fileControl: this.fb.control('', {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.calculationListService.checkFileNameExisted,
          'name',
          this.data.fileDocument?.fileSnapshot?.fileName ?? '',
          { params: { isCheckFileName: true } },
        ),
      }),
    });
  }

  getListBenefitType() {
    const request: GetBenefitTypeRequest = {
      type: BenefitRequestType.UploadCalculationFile,
      calculationId: this.data?.id,
    };
    this.calculationStore.dispatch(getBenefitTypesAction({ request }));
    this.calculationStore.pipe(select(getBenefitTypesSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (!state?.isLoading && state?.success) {
        this.listBenefitType = deepClone(state?.payload ?? [])?.map((item) => {
          return {
            value: item.id,
            displayValue: item.name,
          };
        });
        this.benefitEntityControl.setValue(this.data?.benefitEntityId);
      }
    });
  }

  onSubmit() {
    this.calculationListService.calculationFile = this.file;
    if (this.data.id) {
      this.uploadForm.value.benefitEntityId = this.data.benefitEntityId;
    }
    const { id, name, description, benefitEntityId } = this.uploadForm.value;
    this.calculationStore.dispatch(
      uploadCalculationFileRequest({
        uploadFormData: { id, benefitEntityId, name, description },
        action: this.data.id ? ACTION.EDIT : ACTION.ADD,
      }),
    );
  }

  uploadFile(event: any) {
    if (this.data.id) {
      this.openConfirmDialog(event);
      return;
    }
    this.setSelectedFile(event);
  }

  setSelectedFile(event: any) {
    this.fileDocument.firstTouch = false;
    if (event) {
      this.file = event;
    }
    this.fileControl?.setValue(event.name);
    this.fileControl?.updateValueAndValidity();
  }

  openConfirmDialog(event: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Confirmation',
        text: 'Uploading new file may result in some current Input and Output becoming invalid. Are you sure you want to update this calculation file?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.setSelectedFile(event);
      } else {
        this.fileSelected = new File([''], this.data.fileDocument?.fileSnapshot?.fileName ?? '');
        this.file = undefined;
      }
    });
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  clearSelectedFile($event: any) {
    this.file = undefined;
    this.fileDocument.firstTouch = false;
    this.fileControl?.setValue('');
    this.fileControl?.updateValueAndValidity();
  }

  checkUploadCalculationState() {
    this.calculationStore
      .pipe(select(selectUploadCalculationFileState), takeUntil(this.unsubscribe$))
      .subscribe((uploadCalculationFileState) => {
        if (uploadCalculationFileState.state === STATE.SUCCESS) {
          this.dialogRef.close();
          return;
        }
        if (uploadCalculationFileState.state === STATE.FAIL) {
          showBanner.call(this, uploadCalculationFileState.state, '', '', {
            customMessage: 'Error occurred uploading calculation file. Please try again.',
          });
          this.calculationStore.dispatch(clearRetirementBenefitCalculationState());
        }
      });
  }

  checkFormSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.uploadForm.markAllAsTouched();
          this.fileDocument.firstTouch = false;
        }),
        switchMap(() =>
          this.uploadForm.statusChanges.pipe(
            startWith(this.uploadForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID && !this.fileDocument.hasError),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }
}
