import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { GetInitiationPropertiesResponse, GetPayeePropertiesResponse, ModuleConnections } from '../../services/models';
import {
  clearGetCourtOrderPayeeInfoStateAction,
  clearGetInitiationPropertiesStateAction,
  clearModuleConnectionsStateAction,
  clearPayeePropertiesStateAction,
  getCourtOrderPayeeInfoAction,
  getCourtOrderPayeeInfoFailureAction,
  getCourtOrderPayeeInfoSuccessAction,
  getInitiationPropertiesAction,
  getInitiationPropertiesFailureAction,
  getInitiationPropertiesSuccessAction,
  getModuleConnectionsAction,
  getModuleConnectionsFailureAction,
  getModuleConnectionsSuccessAction,
  getPayeePropertiesAction,
  getPayeePropertiesFailureAction,
  getPayeePropertiesSuccessAction,
} from '../actions';

export interface EntityPropertyState {
  getInitiationProperties?: BaseActionState<GetInitiationPropertiesResponse>;
  moduleConnectionState?: BaseActionState<ModuleConnections>;
  getPayeeProperties?: BaseActionState<GetPayeePropertiesResponse>;
  getCourtOrderPayeeInfo?: BaseActionState<any>;
}

const initialState: EntityPropertyState = {};

export const entityPropertyReducer = createReducer(
  initialState,
  on(getInitiationPropertiesAction, (state) => ({
    ...state,
    getInitiationProperties: {
      isLoading: true,
    },
  })),
  on(getInitiationPropertiesSuccessAction, (state, { response }) => ({
    ...state,
    getInitiationProperties: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getInitiationPropertiesFailureAction, (state, { error }) => ({
    ...state,
    getInitiationProperties: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetInitiationPropertiesStateAction, (state) => ({
    ...state,
    getInitiationProperties: undefined,
  })),
  
  on(getModuleConnectionsAction, (state) => ({
    ...state,
    moduleConnectionState: {
      isLoading: true,
    },
  })),
  on(getModuleConnectionsSuccessAction, (state, { moduleConnections }) => ({
    ...state,
    moduleConnectionState: {
      isLoading: false,
      success: true,
      payload: moduleConnections,
    },
  })),
  on(getModuleConnectionsFailureAction, (state) => ({
    ...state,
    moduleConnectionState: {
      isLoading: false,
      success: false,
    },
  })),
  on(clearModuleConnectionsStateAction, (state) => ({
    ...state,
    moduleConnectionState: undefined,
  })),

  on(getPayeePropertiesAction, (state) => ({
    ...state,
    getPayeeProperties: {
      isLoading: true,
    },
  })),
  on(getPayeePropertiesSuccessAction, (state, { response }) => ({
    ...state,
    getPayeeProperties: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPayeePropertiesFailureAction, (state) => ({
    ...state,
    getPayeeProperties: {
      isLoading: false,
      success: false,
    },
  })),
  on(clearPayeePropertiesStateAction, (state) => ({
    ...state,
    getPayeeProperties: undefined,
  })),

  on(getCourtOrderPayeeInfoAction, (state) => ({
    ...state,
    getCourtOrderPayeeInfo: {
      isLoading: true,
    },
  })),
  on(getCourtOrderPayeeInfoSuccessAction, (state, { response }) => ({
    ...state,
    getCourtOrderPayeeInfo: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCourtOrderPayeeInfoFailureAction, (state) => ({
    ...state,
    getCourtOrderPayeeInfo: {
      isLoading: false,
      success: false,
    },
  })),
  on(clearGetCourtOrderPayeeInfoStateAction, (state) => ({
    ...state,
    getCourtOrderPayeeInfo: undefined,
  })),
);
