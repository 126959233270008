import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MemberAuditTrailsService } from '@ptg-member/services/member-audit-trails.service';
import { GetMemberAuditTrailsSectionListResponse, GetMemberAuditTrailsSearchNameResponse, GetMemberAuditTrailsSearchUserResponse, GetMemberAuditTrailsCardInfoResponse, MemberAuditTrail, GetMemberAuditTrailsListResponse, GetMemberAuditTrailsProfileInfoResponse } from '@ptg-member/types/models/member-audit-trails.model';
import { getMemberAuditTrailsListAction, getMemberAuditTrailsListSuccessAction, getMemberAuditTrailsListUpFailureAction, getMemberAuditTrailsSectionListAction, getMemberAuditTrailsSectionListSuccessAction, getMemberAuditTrailsSectionListFailureAction, getMemberAuditTrailsSearchNameAction, getMemberAuditTrailsSearchNameSuccessAction, getMemberAuditTrailsSearchNameFailureAction, getMemberAuditTrailsSearchUserAction, getMemberAuditTrailsSearchUserSuccessAction, getMemberAuditTrailsSearchUserFailureAction, getMemberAuditTrailsCardInfoAction, getMemberAuditTrailsCardInfoFailureAction, getMemberAuditTrailsCardInfoSuccessAction, getMemberAuditTrailsProfileInfoAction, getMemberAuditTrailsProfileInfoFailureAction, getMemberAuditTrailsProfileInfoSuccessAction } from '../actions/member-audit-trails.action';

@Injectable()
export class MemberAuditTrailsEffects {
  constructor(
    private actions$: Actions,
    private memberAuditTrailsService: MemberAuditTrailsService
  ) { }

  getMemberAuditTrailsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberAuditTrailsListAction),
      switchMap(({ request }) =>
        this.memberAuditTrailsService.getMemberAuditTrailsList(request).pipe(
          map((response: GetMemberAuditTrailsListResponse) => getMemberAuditTrailsListSuccessAction({ response })),
          catchError((error) => of(getMemberAuditTrailsListUpFailureAction({ error })))
        )
      )
    )
  );

  getMemberAuditTrailsSectionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberAuditTrailsSectionListAction),
      switchMap(({ request }) =>
        this.memberAuditTrailsService.getMemberAuditTrailsSectionList(request).pipe(
          map((response: GetMemberAuditTrailsSectionListResponse) => getMemberAuditTrailsSectionListSuccessAction({ response })),
          catchError((error) => of(getMemberAuditTrailsSectionListFailureAction({ error })))
        )
      )
    )
  );

  getMemberAuditTrailsCardInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberAuditTrailsCardInfoAction),
      switchMap(({ request }) =>
        this.memberAuditTrailsService.getMemberAuditTrailsCardInfo(request).pipe(
          map((response: GetMemberAuditTrailsCardInfoResponse) => getMemberAuditTrailsCardInfoSuccessAction({ response })),
          catchError((error) => of(getMemberAuditTrailsCardInfoFailureAction({ error })))
        )
      )
    )
  );

  getMemberAuditTrailsProfileInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberAuditTrailsProfileInfoAction),
      switchMap(({ request }) =>
        this.memberAuditTrailsService.getMemberAuditTrailsProfileInfo(request).pipe(
          map((response: GetMemberAuditTrailsProfileInfoResponse) => getMemberAuditTrailsProfileInfoSuccessAction({ response })),
          catchError((error) => of(getMemberAuditTrailsProfileInfoFailureAction({ error })))
        )
      )
    )
  );

  getMemberAuditTrailsSearchName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberAuditTrailsSearchNameAction),
      switchMap(({ request }) =>
        this.memberAuditTrailsService.getMemberAuditTrailsSearchName(request).pipe(
          map((response: GetMemberAuditTrailsSearchNameResponse) => getMemberAuditTrailsSearchNameSuccessAction({ response })),
          catchError((error) => of(getMemberAuditTrailsSearchNameFailureAction({ error })))
        )
      )
    )
  );

  getMemberAuditTrailsSearchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberAuditTrailsSearchUserAction),
      switchMap(({ request }) =>
        this.memberAuditTrailsService.getMemberAuditTrailsSearchUser(request).pipe(
          map((response: GetMemberAuditTrailsSearchUserResponse) => getMemberAuditTrailsSearchUserSuccessAction({ response })),
          catchError((error) => of(getMemberAuditTrailsSearchUserFailureAction({ error })))
        )
      )
    )
  );

}
