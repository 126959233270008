import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Option } from '@ptg-shared/controls/select/select.component';
import { isEmpty } from '@ptg-shared/utils/string.util';

import { EarningItems, FundingSources, PaymentEarningsDetailResponse, PaymentTab } from '../../services/models';
import {
  EditPaymentStatusInputData,
  LastPayrollCycleDetail,
  ParsedEditPaymentStatusInputData,
} from '../../services/models/edit-payment-status.model';
import { EditPaymentStatusActionName, PaymentInstructionType, PayStatus } from '../../types/enums';

@Injectable()
export class EditPaymentStatusComponentService {
  constructor(private readonly fb: FormBuilder) {}

  getDialogTitle(paymentType?: PaymentInstructionType): string {
    return 'Edit Payment Status';
  }

  getConfirmationMessage(actionName: EditPaymentStatusActionName): string {
    return `If you ${actionName} the benefit, system will consequently ${actionName} associated QDRO benefit(s). Are you sure you want to ${actionName} the benefit?`;
  }

  getBannerCustomMessage(isSuccess: boolean, isReversing: boolean): string {
    if (isSuccess && !isReversing) {
      return 'Payment Status successfully updated.';
    }
    if (!isSuccess && !isReversing) {
      return 'Error occurred updating Payment Status. Please try again.';
    }
    if (isSuccess && isReversing) {
      return 'Benefit termination successfully reversed. Please review the payment history to create a retroactive payment if necessary.';
    }
    if (!isSuccess && isReversing) {
      return 'Error occurred reversing benefit termination. Please try again.';
    }
    return '';
  }

  get getInitFormGroup(): FormGroup {
    return this.fb.group({
      // Checkbox
      isSuspended: [false],
      isTerminated: [false],

      // Toggle
      isCreatePayment: [false],

      //Date
      benefitEndDate: [undefined],

      // Dropdown list
      suspensionReason: [null],
      lastPayrollCycle: [null],
      terminationReason: [null],

      // [_Private] Chip list
      suspensionReasonChipList: this.fb.array([]),

      //Adjustment
      isApproved: [false],
      isRejected: [false],
      adjReason: [''],
    });
  }

  get getInitFormGroupForAdjustment(): FormGroup {
    return this.fb.group({
      //Adjustment
      isApproved: [false],
      isRejected: [false],
      adjReason: [''],
    });
  }

  getParsedSavedData(data: EditPaymentStatusInputData): ParsedEditPaymentStatusInputData | null {
    if (!data) {
      return null;
    }
    const { paymentInstruction } = data;
    const lastPayrollCycle = paymentInstruction?.lastPayrollCycle && {
      dateDetailId: paymentInstruction?.lastPayrollCycle?.id ?? '',
      startDate: paymentInstruction?.lastPayrollCycle?.startDate ?? '',
      endDate: paymentInstruction?.lastPayrollCycle?.endDate ?? '',
    } as LastPayrollCycleDetail;

    return {
      isSuspended: paymentInstruction?.payStatus === PayStatus.Suspended,
      isTerminated: data.isOneTimePayment
        ? false
        : paymentInstruction?.payStatus === PayStatus.Terminated ||
          (paymentInstruction?.payStatus !== PayStatus.Suspended &&
            paymentInstruction?.lastPayrollCycle !== null &&
            paymentInstruction?.statusReason !== null &&
            !!paymentInstruction?.statusReason?.reasonOptionIds &&
            paymentInstruction?.statusReason?.reasonOptionIds?.length > 0),
      isPending: paymentInstruction?.payStatus === PayStatus.Pending,
      savedReasonIdList: paymentInstruction?.statusReason?.reasonOptionIds ?? [],
      lastPayrollCycle,
    };
  }

  getEarningDetailData(paymentType?: PaymentInstructionType, earningsInfo?: PaymentEarningsDetailResponse): any | null {
    if (isEmpty(paymentType) && !earningsInfo) {
      return null;
    }

    let grossCurrent = 0;
    let adjustmentData: FundingSources[] = [];

    (earningsInfo?.earningItems ?? []).forEach((item: EarningItems) => {
      grossCurrent += item?.amounts?.amountCurrent ?? 0;
      adjustmentData = [...adjustmentData, ...(item?.adjustmentFundings ?? [])];
    });

    if (paymentType === PaymentInstructionType.Recurring) {
      adjustmentData?.forEach((item) => {
        grossCurrent += item.isInCurrentBenefitPeriod ? item?.amount ?? 0 : 0;
      });
    }

    if (earningsInfo?.deductionAsFundingSourceList?.length) {
      grossCurrent =
        earningsInfo?.deductionAsFundingSourceList.reduce((res, el) => {
          res += el.deductionSubTypes
            ? el.deductionSubTypes?.reduce((result, item) => {
                result += item.amount;
                return result;
              }, 0)
            : 0;
          res += el.courtOrderDeductions
            ? el.courtOrderDeductions?.reduce((result, item) => {
                result += item.amount;
                return result;
              }, 0)
            : 0;
          return res;
        }, 0) || 0;
    }

    return {
      grossCurrent,
      totalDeductions: earningsInfo?.totalDeductions,
    };
  }

  getDesiredPaymentStatus(
    isSuspendedBoxChecked: boolean,
    isTerminateBoxChecked: boolean,
    lastPayrollCycle: LastPayrollCycleDetail,
    currentPaymentInstruction?: PaymentTab,
  ): PayStatus | null {
    const isRecurring = currentPaymentInstruction?.paymentType === PaymentInstructionType.Recurring;

    //* (1) For Recurring Payment Instruction
    // (a) Suspend Payment Instruction: If Suspend = checked, then update [Payment Instruction Status] = "Suspended".
    if (isRecurring && isSuspendedBoxChecked) {
      return PayStatus.Suspended;
    }

    // (b) Unsuspend Payment Instruction: If Suspend = unchecked and Terminate = unchecked, then update [Payment Instruction Status] = "Pending".
    if (isRecurring && !isSuspendedBoxChecked && !isTerminateBoxChecked) {
      return PayStatus.Pending;
    }

    // (c) Terminate Payment Instruction: If Terminate = checked & If <input Last Payroll Cycle> =< current Benefit period, then update [Payment Instruction Status] = "Terminated".
    if (isRecurring && isTerminateBoxChecked) {
      return PayStatus.Terminated;
    }

    // (d) Unterminate Payment Instruction: If Suspend = checked and Terminate = unchecked, then update [Payment Instruction Status] from "Terminated" to "Suspended"
    if (isRecurring && isSuspendedBoxChecked && !isTerminateBoxChecked) {
      return PayStatus.Suspended;
    }
    //! (d) Unterminate Payment Instruction: If Suspend = unchecked and Terminate = unchecked, then update [Payment Instruction Status] from "Terminated" to "Pending" - SAME AS (1)(b) ABOVE - NO NEED TO IMPLEMENT AGAIN

    return null;
  }

  getReasonIdList(
    isSuspended: boolean,
    isTerminated: boolean,
    terminationReasonId: string,
    suspensionReasonChipList: Option[] = [],
  ): string[] | null {
    if (!isSuspended && !isTerminated) {
      return null;
    }
    if (isSuspended && !isTerminated) {
      return suspensionReasonChipList.map((item) => item.value);
    }
    if (!isSuspended && isTerminated) {
      return [terminationReasonId];
    }
    return null;
  }
}
