import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;

  @Input() controlField!: AbstractControl | null;
  @Input() class!: string;
  get inputControl() {
    return this.controlField as FormControl;
  }
  @Input() placeholder!: string;
  @Input() errorRequire?: string;
  @Input() errorPattern?: string;
  @Input() errorAsync?: string;
  @Input() errorMaxLength?: string;
  @Input() maxLength?: number;
  @Input() hasLabel?: boolean;
  @Input() isDisabled?: boolean;
  @Input() errorMin?: string;
  @Input() errorMax?: string;
  @Input() isPositive: boolean = false;
  @Input() isDecimal: boolean = true;
  @Input() allowZero: boolean = true;
  @Input() isStrict: boolean = true;
  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Input() customError: string = 'invalidFormat';

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  setNumberValue() {
    this.onBlur.emit();
  }
}
