import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';

import { 
  GetNextPayrollRunListQueryResult
} from './models';

@Injectable()
export class NextPayrollListService {

  constructor(private httpClient: HttpClient) {}

  getPayrollRun(): Observable<GetNextPayrollRunListQueryResult> {
    return this.httpClient.get<GetNextPayrollRunListQueryResult>(`${environment.apiUrl}/new-payroll/payroll-runs`);
  }

  startPayrollRun(calendarDateDetailId: string) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.get(`${environment.apiUrl}/new-payroll/calendars/start-payroll-run/${calendarDateDetailId}`, { context });
  }
}
