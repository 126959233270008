import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const newAdjustmentDetailedSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.createAdjustmentDetailed
);

export const createAdjustmentDetailedSelector = createSelector(
  newAdjustmentDetailedSelector,
  (state) => state?.createAdjustmentDetailed
);

export const getValidateAdjustmentDetailIdSelector = createSelector(
  newAdjustmentDetailedSelector,
  (state) => state?.getValidateAdjustmentDetailId
);

