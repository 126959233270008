import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { GetPaymentInfoService } from '../../services';
import {
  EarningInfos,
  EarningItems,
  FundingSourceItems,
  InstructionHistory,
  PaymentEarningsDetailResponse,
  PaymentInfoTabLoadingStateHandler,
  PaymentTab,
} from '../../services/models';
import { getPaymentEarningDetailAction, getPaymentEarningsDetailState } from '../../store';
import * as fromPayeeDetail from '../../store/reducers/index';
import { handlePaymentInfoTabLoadingState, isOneTimePayment } from '../../types/constants/payment-info-tab.constant';
import { PaymentInfoAdjustmentType, PaymentInstructionType, PayStatus, TabPaymentInfo } from '../../types/enums';
import { EarningDetailComponent } from '../earning-detail/earning-detail.component';
import { EditFundingSourcesComponent } from '../edit-funding-sources/edit-funding-sources.component';
import { EditDeductionFundingSourceComponent } from '../edit-deduction-funding-source/edit-deduction-funding-source.component';
import { HeaderBenefit } from '../../types/models';

@Component({
  selector: 'ptg-payment-earning-section',
  templateUrl: './payment-earnings-section.component.html',
  styleUrls: ['./payment-earnings-section.component.scss'],
})
export class PaymentEarningSectionComponent implements OnInit, OnDestroy, OnChanges, PaymentInfoTabLoadingStateHandler {
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly PaymentInfoAdjustmentType = PaymentInfoAdjustmentType;
  readonly TabPaymentInfo = TabPaymentInfo;

  earningsInfo?: PaymentEarningsDetailResponse;
  earnings: EarningItems[] = [];
  adjustmentData: EarningItems[] = [];
  deductionAsFundingSourceList: FundingSourceItems[] = [];
  hasManualAdjusted: boolean = false;

  unsubscribe$ = new Subject<void>();
  showEarning: boolean = true;
  isLoading: boolean = true;
  isShowMoreBtn: boolean = false;
  isShowEditBtn: boolean = false;
  isShowViewDetailBtn: boolean = false;
  isHistory: boolean = false;

  @Output() changeBannerEmitter = new EventEmitter<{
    customMessage: string;
    resultStatus: BannerType;
  }>();
  @Output() editButtonEvent = new EventEmitter();

  @Input() breadcrumbs: Breadcrumb[] | undefined;
  @Input() selectedRow?: PaymentTab & InstructionHistory;
  @Input() selectedTabPayment!: TabPaymentInfo;
  @Input() benefitTypeOptionId: string = '';
  @Input() isEntityView: boolean = false;
  @Input() isEstablishBenefit: boolean = true;
  @Input() targetId?: string;
  @Input() benefitId?: string;
  @Input() memberId: string = '';
  @Input() selectedHeaderBenefit?: HeaderBenefit;
  @Output() reloadDataEmitter = new EventEmitter<string>();

  constructor(
    private dialog: MatDialog,
    private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
    private getPaymentInfoService: GetPaymentInfoService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow) {
      this.isHistory = this.selectedRow?.payStatus === PayStatus.Finalized;
      const isHideEditBtn =
        this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment ||
        this.isHistory ||
        this.selectedRow?.paymentType === PaymentInstructionType.Reissue;
      this.isShowEditBtn = !isHideEditBtn;

      this.getPaymentEarningDetail();
    }
  }

  ngOnInit(): void {
    this.selectPaymentEarningsDetail();
    handlePaymentInfoTabLoadingState(this, this.payeeDetailStore);
  }

  getPaymentEarningDetail() {
    if (!this.selectedRow) {
      return;
    }
    const request = {
      paymentId: this.selectedRow.id ?? '',
      benefitTypeOptionId: this.benefitTypeOptionId,
      paymentInfoAdjustmentType: this.selectedRow.paymentInfoAdjustmentType,
    };
    const params = {
      paymentInstructionHistoryId: this.selectedRow.paymentInstructionHistoryId,
      instructionStatusHistoryId: this.selectedRow.instructionStatusHistoryId,
    };
    this.payeeDetailStore.dispatch(getPaymentEarningDetailAction({ request, params }));
  }

  selectPaymentEarningsDetail(): void {
    this.payeeDetailStore
      .pipe(
        select(getPaymentEarningsDetailState),
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        if (res?.success) {
          this.earningsInfo = deepClone(res?.payload);
          this.earnings = deepClone(this.earningsInfo?.earningItems ?? []).filter((item) => !item.isAdjustmentEarning);
          this.adjustmentData = deepClone(this.earningsInfo?.earningItems ?? []).filter(
            (item) => item.isAdjustmentEarning,
          );
          this.deductionAsFundingSourceList = this.earningsInfo?.deductionAsFundingSourceList || [];

          this.handleData();
          if (this.deductionAsFundingSourceList?.length) {
            this.handleDataFundingSource();
          }
        }
      });
  }

  handleDataFundingSource() {
    let grossCurrent = this.deductionAsFundingSourceList.reduce((res, el) => {
      res += el.deductionSubTypes
        ? el.deductionSubTypes?.reduce((result, item) => {
            result += item.amount;
            return result;
          }, 0)
        : 0;
      res += el.courtOrderDeductions
        ? el.courtOrderDeductions?.reduce((result, item) => {
            result += item.amount;
            return result;
          }, 0)
        : 0;
      return res;
    }, 0);
    let amountNetCurrent = this.deductionAsFundingSourceList.reduce((res, el) => {
      res += el.deductionSubTypes
        ? el.deductionSubTypes?.reduce((result, item) => {
            result += item.netAmount ?? 0;
            return result;
          }, 0)
        : 0;
      res += el.courtOrderDeductions
        ? el.courtOrderDeductions?.reduce((result, item) => {
            result += item.netAmount ?? 0;
            return result;
          }, 0)
        : 0;
      return res;
    }, 0);
    if (this.earningsInfo && this.earningsInfo.grossPayment && this.earningsInfo.netPayment) {
      this.earningsInfo.grossPayment.amountCurrent = grossCurrent;
      this.earningsInfo.grossPayment.amountNet = amountNetCurrent;
      this.earningsInfo.netPayment.amountCurrent = grossCurrent - (this.earningsInfo.totalDeductions || 0);
      this.earningsInfo.netPayment.amountNet = amountNetCurrent - (this.earningsInfo?.totalNetAmountDeductions ?? 0);
    }
  }

  handleData() {
    let grossCurrent = 0;
    let grossYTD = 0;
    this.isShowViewDetailBtn = false;
    this.earnings?.forEach((item) => {
      grossCurrent += item?.amounts?.amountCurrent ?? 0;
      grossYTD += item?.amounts?.amountYTD ?? 0;
      if (this.shouldShowViewDetailButton(item)) {
        this.isShowViewDetailBtn = true;
      }
    });
    this.adjustmentData?.forEach((item) => {
      grossCurrent += item?.amounts?.amountCurrent ?? 0;
      grossYTD += item?.amounts?.amountYTD ?? 0;
    });
    if (this.deductionAsFundingSourceList?.length) {
      this.isShowViewDetailBtn = true;
    }
    this.getPaymentInfoService.setEarningInfo([...this.earnings, ...this.adjustmentData]);
    this.getPaymentInfoService.setDeductionAsFundingSource(this.deductionAsFundingSourceList ?? []);

    this.isShowMoreBtn = this.isShowEditBtn && this.isShowViewDetailBtn;

    const netPaymentCurrent = grossCurrent - (this.earningsInfo?.totalDeductions ?? 0);
    const netPaymentYTD = grossYTD - (this.earningsInfo?.yearToDateDeduction ?? 0);
    const grossPaymentAmountNet = this.getTotalAmount(this.earnings, 'amountNet');

    this.earningsInfo = {
      ...this.earningsInfo!,
      earningItems: [...(this.earningsInfo?.earningItems ?? [])],
      grossPayment: { amountCurrent: grossCurrent, amountNet: grossPaymentAmountNet, amountYTD: grossYTD },
      netPayment: {
        amountCurrent: netPaymentCurrent,
        amountNet: grossPaymentAmountNet - (this.earningsInfo?.totalNetAmountDeductions ?? 0),
        amountYTD: netPaymentYTD,
      },
    };
  }

  getTotalAmount(source: Array<any>, property: string): number {
    return source.reduce((result, current) => {
      result += current?.amounts?.[property] ?? 0;
      return result;
    }, 0);
  }

  shouldShowViewDetailButton(earningItem: EarningItems): boolean {
    const paymentType = this.selectedRow?.paymentType;
    if (paymentType === PaymentInstructionType.Reissue) {
      return false;
    }
    if (
      paymentType === PaymentInstructionType.Recurring ||
      this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
    ) {
      if (earningItem.fundingSources?.length === 1) {
        return this.adjustmentData.length > 0;
      }
      return (earningItem.fundingSources?.length || 0) > 1;
    }
    return isOneTimePayment(this.selectedRow) && (earningItem.fundingSources?.length || 0) > 1;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit(): void {
    if (!this.selectedRow) {
      return;
    }
    this.editButtonEvent.emit();
    const earningInfo: EarningInfos = {
      earningItems: this.earningsInfo?.earningItems,
      deductionAsFundingSourceList: this.deductionAsFundingSourceList,
      grossPayment: this.earningsInfo?.grossPayment?.amountCurrent,
      totalDeductions: this.earningsInfo?.totalDeductions,
      netPayment: this.earningsInfo?.netPayment?.amountCurrent,
      benefitTypeOptionId: this.benefitTypeOptionId,
      paymentInstructionId: this.selectedRow.id,
      memberId: this.memberId,
      benefitPeriodStartDate: this.earningsInfo?.benefitPeriodStartDate,
      benefitPeriodEndDate: this.earningsInfo?.benefitPeriodEndDate,
      isRecurringRecord:
        this.selectedRow?.paymentType === PaymentInstructionType.Recurring ||
        this.selectedRow?.paymentType === PaymentInstructionType.AllRecurring,
      paymentInstructionHistoryId: this.selectedRow.paymentInstructionHistoryId,
      paymentInfoAdjustmentType: this.selectedRow?.paymentInfoAdjustmentType,
      paymentId: this.selectedRow.id,
      selectedHeaderBenefit: this.selectedHeaderBenefit,
      isTitleFundingSource: this.earningsInfo?.isTitleFundingSource
    };
    const component: any = this.deductionAsFundingSourceList.length
      ? EditDeductionFundingSourceComponent
      : EditFundingSourcesComponent;
    const dialogRef = this.dialog.open(component, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: { earningInfo },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const customMessage = result.resultStatus
          ? this.selectedRow?.paymentType === PaymentInstructionType.InitialPayment && result.isChangeAmountOfTaxable ? 'Earnings successfully updated. Please be aware of federal withholding which might need to be recalculated.' : 'Earnings successfully updated.'
          : 'Error occurred updating Earnings. Please try again.';
        const resultStatus = result.resultStatus ? BannerType.Success : BannerType.Fail;
        this.changeBannerEmitter.emit({ customMessage, resultStatus });
        if (result.resultStatus) {
          this.reloadDataEmitter.emit('payment-info-tab');
        }
      }
    });
  }

  onClickManage() {
    this.dialog.open(EarningDetailComponent, {
      panelClass: 'total-deduction-popup',
      autoFocus: false,
      disableClose: true,
      minWidth: 700,
      data: {
        grossAmount: this.earningsInfo?.grossPayment,
        earningItems: this.earnings,
        adjustmentItems: this.adjustmentData,
        deductionItem: this.deductionAsFundingSourceList,
        isLinkToAdjustment: this.earningsInfo?.isLinkToAdjustment,
        isAdjustmentType: this.selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment,
        isTitleFundingSource: this.earningsInfo?.isTitleFundingSource,
        isAdjustmentIncorrectDeduction: this.earningsInfo?.isAdjustmentIncorrectDeduction,
      },
    });
  }
}
