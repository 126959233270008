<div class="status-history-container" id="status-history-container">
  <ptg-title-bar
    [name]="data.serviceHistory ? 'Edit' : 'Add New'"
    [viewName]="data.viewName"
    [onlyTitle]="true"
  ></ptg-title-bar>
  
  <ptg-overview-header-entity
    [shouldDisableNavigationButtonsInAllCases]="false"
    [shouldDisableCloseButtonInAllCases]="false"
    [injectedMemberId]="memberId">
  </ptg-overview-header-entity>

  <div *ngIf="!isLoading && !isFormLoading">
    <form [formGroup]="editForm">
      <div class="flex flex-wrap gap-3">
        <ptg-button buttonName="Save" classInput="primary" (clickButton)="onSubmit()"></ptg-button>
        <ptg-button buttonName="Cancel" classInput="tertiary" (clickButton)="onCancel()"></ptg-button>
      </div>
      <div class="form-control-container">
        <div class="form-control-date">
          <ptg-datepicker
            placeholder="Begin Date"
            class="input-field"
            [controlField]="beginDate"
            customError="beginDateErrorMessage"
          ></ptg-datepicker>
          <ptg-datepicker
            placeholder="End Date"
            class="input-field"
            [controlField]="endDate"
            customError="endDateErrorMessage"
            errorAsync="End Date had an overlap service record."
          ></ptg-datepicker>
        </div>
        <div class="form-control-select">
          <mat-form-field class="auto-complete" appearance="fill">
            <mat-label>Municipality</mat-label>
            <input type="text" matInput [formControl]="municipality" [matAutocomplete]="auto" (blur)="validateMuni()" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option.valueDescription }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="!municipality?.errors?.required && municipality?.errors?.inValidAsync">
              Value must be selected from result list.
            </mat-error>
            <mat-error *ngIf="municipality?.errors?.required"> Municipality is required. </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div id="loading-table" *ngIf="isLoading || isFormLoading" >
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
