import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { AmountInfo, EarningItems, FundingSourceItems, FundingSources } from '../../services/models';
import { DeductionType, DeductionTypeRevert } from '../../types/enums';
import { deepClone } from '@ptg-shared/utils/common.util';
import * as fromReducer from '@ptg-reducers/index';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { FundType } from '@ptg-shared/types/enums';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';

@Component({
  selector: 'ptg-earning-detail',
  templateUrl: './earning-detail.component.html',
  styleUrls: ['./earning-detail.component.scss'],
})
export class EarningDetailComponent extends BaseComponent {
  readonly DeductionTypeRevert = DeductionTypeRevert;
  grossAmount: AmountInfo = { amountCurrent: 0, amountNet: 0, amountYTD: 0 };
  title: string = '';
  titleFunding: string = '';
  titleAdjustment: string = '';
  fundingSources: FundingSources[] = [];
  adjustmentFundings: FundingSources[] = [];
  singleLineText = '';
  deductionAsFundingSource: FundingSourceItems[] = [];
  activeFund?: FundModel;

  constructor(
    private store: Store<fromReducer.State>,
    public readonly dialog: MatDialog,
    public readonly dialogRef: MatDialogRef<EarningDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      earningItems: EarningItems[];
      adjustmentItems: EarningItems[];
      deductionItem: FundingSourceItems[];
      isLinkToAdjustment?: boolean;
      isAdjustmentType?: boolean;
      isTitleFundingSource?: boolean;
      grossAmount?: AmountInfo;
      isAdjustmentIncorrectDeduction?: boolean;
    },
  ) {
    super();
    if (this.data.deductionItem?.length) {
      this.setListDeductionAsFundingSources();
    }
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.store
    .pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((el) => (this.activeFund = el));
    this.getData();
  }

  setListDeductionAsFundingSources() {
    this.deductionAsFundingSource = this.data.deductionItem.sort((a, b) => {
      return a.deductionType - b.deductionType;
    });
    const indexOther = this.deductionAsFundingSource.findIndex((el) => el.deductionType === DeductionType.Others);
    const otherData = deepClone(this.deductionAsFundingSource[indexOther]);
    const indexQdro = this.deductionAsFundingSource.findIndex((el) => el.deductionType === DeductionType.Qdro);
    if (indexQdro > -1 && indexOther > -1) {
      this.deductionAsFundingSource[indexOther] = this.deductionAsFundingSource[indexQdro];
      this.deductionAsFundingSource[indexQdro] = otherData;
    }
    this.deductionAsFundingSource = this.deductionAsFundingSource.filter((el) => {
      let temp = 0;
      el.deductionSubTypes?.forEach((item) => {
        if (item.payrollBenefitDeductionId || item.payrollDeductionId) {
          temp += 1;
        }
      });
      el.courtOrderDeductions?.forEach((item) => {
        if (item.payrollBenefitDeductionId || item.payrollDeductionId) {
          temp += 1;
        }
      });
      return !!temp;
    });
  }

  getData() {
    this.data.earningItems.forEach((curr) => {
      if (curr.fundingSources) {
        const fundingSources = curr.fundingSources.filter((item) => item?.showInViewDetail);
        this.fundingSources = [...this.fundingSources, ...fundingSources];
      }
    });
    this.data.adjustmentItems.forEach((item) => {
      const adjustmentFundings = (item.adjustmentFundings ?? []).filter((item) => item?.showInViewDetail);
      this.adjustmentFundings = [...this.adjustmentFundings, ...adjustmentFundings];
    });

    this.titleAdjustment = this.fundingSources?.length > 1 ? 'Adjustment Funding' : 'Adjustment Earnings';
    const totalFundingSource = this.fundingSources?.reduce((accfs, currfs) => accfs + (currfs.amount ?? 0), 0) ?? 0;
    const totalAdjustment =
      this.adjustmentFundings?.reduce(
        (accaf, curraf) => (curraf.showInViewDetail ? accaf + (curraf.amount ?? 0) : accaf),
        0,
      ) ?? 0;
    const totalGrossAmountNet =
      this.getTotalAmount(this.fundingSources, 'netAmount') + this.getTotalAmount(this.adjustmentFundings, 'netAmount');
    const totalGrossAmountYTD =
      this.getTotalAmount(this.fundingSources, 'ytd') + this.getTotalAmount(this.adjustmentFundings, 'ytd');
    this.grossAmount = {
      ...this.grossAmount,
      amountCurrent: totalFundingSource + totalAdjustment,
      amountNet: totalGrossAmountNet,
      amountYTD: totalGrossAmountYTD,
    };
    if (this.deductionAsFundingSource.length > 0) {
      if (this.data.grossAmount) {
        this.grossAmount = this.data.grossAmount;
      }
      if (this.data.isTitleFundingSource || this.activeFund?.fundType === FundType.ChicagoParks) {
        this.title = 'View Funding Sources';
        this.singleLineText =
          'Funding Sources for the selected One-Time Payment are from recovery of the following Deductions';
        if (this.deductionAsFundingSource.length && this.data.isAdjustmentIncorrectDeduction) {
          this.singleLineText = 'Deductions as Funding Sources for the selected Adjustment';
        }
      } else {
        this.title = 'View Earnings';
        this.singleLineText =
          'Earnings for the selected One-Time Payment are from recovery of the following Deductions';
        if (this.deductionAsFundingSource.length && this.data.isAdjustmentIncorrectDeduction) {
          this.singleLineText = 'Deductions as Earnings for the selected Adjustment';
        }
      }
    } else {
      this.title = this.fundingSources?.length > 1 ? 'View Funding Sources' : 'View Earnings';
      this.titleFunding = this.fundingSources?.length > 1 ? 'Funding Source' : 'Earnings';
    }
  }

  getTotalAmount(source: Array<any>, property: string): number {
    return source.reduce((result, current) => {
      result += current?.[property] ?? 0;
      return result;
    }, 0);
  }

  onClose() {
    this.dialogRef.close();
  }

  onClickEdit() {}
}
