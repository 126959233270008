import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GetBenefitPeriodsResponse } from './models';

@Injectable()
export class BenefitService {
  constructor(private httpClient: HttpClient) {}

  getBenefitPeriods(): Observable<GetBenefitPeriodsResponse> {
    return this.httpClient.get<GetBenefitPeriodsResponse>(`${environment.apiUrl}/new-payroll/benefit-periods`);
  }
}
