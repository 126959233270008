<ng-container [ngSwitch]="formControlDetail.type">
  <ng-container *ngSwitchCase="InputType.Currency">
    <ptg-textbox
      [controlField]="controlField"
      [maxLength]="formControlDetail?.maxLength"
      [placeholder]="formControlDetail.label"
      [hasLabel]="true"
      class="w-full"
      [min]="formControlDetail.min"
      [max]="formControlDetail.max"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      inputType="Currency"
      [errorAsync]="formControlDetail.label + ' already exists.'"
      [errorMinMaxValue]="formControlDetail.minMessage"
    >
    </ptg-textbox>
    <span class="info-label" *ngIf="!!formControlDetail?.customError && formControlDetail?.isShowCustomMessage">{{
      formControlDetail.customError
    }}</span>
  </ng-container>

  <ng-container
    *ngSwitchCase="
      [InputType.Email, InputType.Phone, InputType.Ssn].includes($any(formControlDetail.type))
        ? formControlDetail.type
        : ''
    "
  >
    <ptg-textbox
      [controlField]="controlField"
      [maxLength]="formControlDetail.maxLength"
      [placeholder]="formControlDetail.label"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [hasLabel]="true"
      [isShowIconMasked]="formControlDetail?.configs?.masked === 'true'"
      class="w-full"
      [inputType]="formControlDetail.type"
      [errorAsync]="formControlDetail.label + ' already exists.'"
    >
    </ptg-textbox>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.Text">
    <ptg-textbox
      [controlField]="controlField"
      [maxLength]="formControlDetail.maxLength"
      [placeholder]="formControlDetail.label"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [hasLabel]="true"
      [isShowIconMasked]="formControlDetail?.configs?.masked === 'true'"
      class="w-full"
      [inputType]="formControlDetail.type"
      [errorAsync]="formControlDetail.label + ' already exists.'"
      *ngIf="!isShowGranulation"
    >
    </ptg-textbox>
    <ptg-granulation-options
      *ngIf="isShowGranulation"
      [formControlDetail]="formControlDetail"
      [formControlType]="2"
      [controlField]="controlField"
      [isDisabled]="formControlDetail.isDisabled"
      (valueChange)="changeValue($event)"
    ></ptg-granulation-options>
  </ng-container>

  <ng-container
    *ngSwitchCase="
      [InputType.Whole_Number, InputType.WholeNumber, InputType['Whole Number'], InputType.Decimal].includes(
        $any(formControlDetail.type)
      )
        ? formControlDetail.type
        : ''
    "
  >
    <ptg-textbox
      [controlField]="controlField"
      [placeholder]="formControlDetail.label"
      [hasLabel]="true"
      [mask]="
        formControlDetail.type === InputType.Whole_Number
          ? 'separator.0'
          : formControlDetail.fractionalLength
            ? 'separator.' + formControlDetail.fractionalLength
            : 'separator'
      "
      [thousandSeparator]="','"
      [min]="formControlDetail.min"
      [max]="formControlDetail.max"
      [maxLength]="formControlDetail?.maxLength"
      class="w-full"
      inputType="Decimal"
      customError="customError"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [errorAsync]="formControlDetail.label + ' already exists.'"
      [errorMinMaxValue]="formControlDetail.minMessage"
      (onOutFocus)="handleOutFocus($event)"
    >
    </ptg-textbox>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.Date">
    <ptg-datepicker
      [controlField]="controlField"
      [placeholder]="formControlDetail.label"
      class="w-full"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [minDate]="$any(formControlDetail?.min)"
      [maxDate]="$any(formControlDetail?.max)"
      [isValidation]="formControlDetail?.isValidation"
      (changeValue)="dateValueChange($event)"
      customError="customError"
      [errorMinDate]="formControlDetail?.minMessage"
      [errorMaxDate]="formControlDetail?.maxMessage"
      [errorAsync]="formControlDetail.label + ' already exists.'"
    >
    </ptg-datepicker>
  </ng-container>

  <ng-container
    *ngSwitchCase="
      [InputType.Address, InputType.Person_Name, InputType.PersonName, InputType['Person Name']].includes(
        formControlDetail.type
      )
        ? formControlDetail.type
        : ''
    "
  >
    <ng-container *ngFor="let childForm of formControlDetail.lstChildFormControl; let i = index">
      <ng-container *ngIf="childForm.type === 'select'">
        <ptg-select
          [controlField]="$any(controlField).controls[childForm.key]"
          [placeholder]="childForm.label"
          [listData]="childForm.lstOption || []"
          [isOptionObj]="true"
          [class]="childForm.className"
          [hasNoneValue]="!formControlDetail.isRequired || !childForm.required"
          [isDisabled]="formControlDetail.isDisabled"
          [isRequired]="formControlDetail.isRequired && childForm.required"
        >
        </ptg-select>
      </ng-container>
      <ng-container *ngIf="childForm.type === 'text'">
        <ptg-textbox
          [controlField]="controlField.get(childForm.key)"
          [maxLength]="childForm.maxLength"
          [placeholder]="childForm.label"
          [isRequired]="formControlDetail.isRequired && childForm.required"
          [isDisabled]="formControlDetail.isDisabled"
          [hasLabel]="true"
          [class]="childForm.className"
        >
        </ptg-textbox>
      </ng-container>
      <ng-container *ngIf="childForm.type === 'number'">
        <ptg-textbox
          [controlField]="controlField.get(childForm.key)"
          [maxLength]="childForm.maxLength"
          [placeholder]="childForm.label"
          [hasLabel]="true"
          [mask]="'0'.repeat(childForm.maxLength)"
          [isAutoLeadingZeros]="true"
          [isValidation]="false"
          [class]="childForm.className"
          [isRequired]="formControlDetail.isRequired && childForm.required"
          [isDisabled]="formControlDetail.isDisabled"
          inputType="Decimal"
        >
        </ptg-textbox>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container
    *ngSwitchCase="
      [InputType.List, InputType.Lookup, InputType.Employer, InputType.Department].includes(formControlDetail.type)
        ? formControlDetail.type
        : ''
    "
  >
    <ptg-select
      [controlField]="controlField"
      [placeholder]="formControlDetail.label"
      [listData]="formControlDetail.lstOption"
      [isOptionObj]="true"
      class="w-full"
      [isSetMaxWidthSelect]="true"
      [hasNoneValue]="!formControlDetail.isRequired"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [isMultipleLineOption]="[InputType.Employer, InputType.Department].includes(formControlDetail.type)"
    >
    </ptg-select>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.Binary">
    <ptg-checkbox
      [controlField]="controlField"
      [label]="formControlDetail.label"
      class="w776"
      [customError]="formControlDetail?.customError ?? ''"
      [isDisabled]="formControlDetail.isDisabled"
      (changeValue)="changeOptionData($event)"
    ></ptg-checkbox>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.Percentage">
    <ptg-textbox
      [controlField]="controlField"
      [placeholder]="formControlDetail.label"
      [hasLabel]="true"
      [mask]="
        formControlDetail.type === InputType.Whole_Number
          ? 'separator.0'
          : formControlDetail.fractionalLength
            ? 'separator.' + formControlDetail.fractionalLength
            : 'separator'
      "
      [thousandSeparator]="','"
      [min]="formControlDetail.min"
      [max]="formControlDetail.max"
      [isPercentage]="true"
      inputType="Decimal"
      class="w-full"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [errorAsync]="formControlDetail.label + ' already exists.'"
    >
    </ptg-textbox>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.RichText">
    <ptg-editor
      class="w-full"
      [controlField]="controlField"
      [placeholder]="formControlDetail.label"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled ?? false"
      [maxLength]="formControlDetail.maxLength"
      [hasFloatLabel]="true"
    >
    </ptg-editor>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.DateTime">
    <ptg-datetime-picker
      class="w-full"
      [controlField]="controlField"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
    >
    </ptg-datetime-picker>
  </ng-container>

  <ng-container *ngSwitchCase="InputType.DateTime">
    <ptg-select
      [controlField]="controlField"
      [placeholder]="formControlDetail.label"
      [listData]="formControlDetail.lstOption"
      [isOptionObj]="true"
      class="w-full"
      [isSetMaxWidthSelect]="true"
      [hasNoneValue]="!formControlDetail.isRequired"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [isMultipleLineOption]="[InputType.Employer, InputType.Department].includes(formControlDetail.type)"
      [isMultiple]="!!formControlDetail?.isMultiple"
    >
    </ptg-select>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ptg-textbox
      [controlField]="controlField"
      [maxLength]="formControlDetail.maxLength"
      [placeholder]="formControlDetail.label"
      [hasLabel]="true"
      class="w-full"
      [isRequired]="formControlDetail.isRequired"
      [isDisabled]="formControlDetail.isDisabled"
      [inputType]="formControlDetail.type"
      [errorAsync]="formControlDetail.label + ' already exists.'"
    >
    </ptg-textbox>
  </ng-container>
</ng-container>
