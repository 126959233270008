import { map, filter } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ACTION_COLUMN, Align, CellContent, Column, ColumnType, DEFAULT_CURRENT_ROW_INDEX, EXPAND_COLLAPSE_COLUMN, Row, STOP_PROPAGATION_CLASS } from '../grid';
import { MatSort, Sort } from '@angular/material/sort';
import { FIRST_PAGE, PageEvent, PaginationComponent } from '../pagination';

export interface ConfigColums {
  styleColums: string[];
  displayedColumns: string[];
  headerColumns: string[];
  configFormatColumns?: any[];
  sortable?: any[];
}
@Component({
  selector: 'ptg-grid-expandable-rows',
  templateUrl: './grid-expandable-rows.component.html',
  styleUrls: ['./grid-expandable-rows.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GridExpandableRowsComponent<T extends Row> implements OnInit {
  readonly ColumnType = ColumnType;
  readonly AlignEnum = Align;
  readonly ACTION_COLUMN = ACTION_COLUMN;

  displayedColumns!: string[];
  columnNames: any[] = [];
  columnNameDetails: any[] = [];

  @ContentChildren(CellContent) cellContents?: QueryList<CellContent>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator?: PaginationComponent;

  @Input() dataTable!: {} | any;
  @Input() columnsToDisplay!: any;
  @Input() columnsExpandToDisplay?: any;
  @Input() fitToParent?: boolean;
  @Input() hideScrollbar: boolean = true;
  @Input() notFoundMessage: string = '';
  @Input() errorMessage?: string;
  @Input() isLoading?: boolean;
  @Input() allowSelection?: boolean;
  @Input() hideHeader?: boolean;
  @Input() fixedHeader: boolean = true;
  @Input() softDeletable?: boolean;
  @Input() inlineEditable?: boolean;
  @Input() allowSaveInline?: boolean;
  @Input() allowExpandMultiple: boolean = false;

  @Input() isExpandCollapse: boolean = true;

  @Output() sortChange = new EventEmitter<Sort>();
  @Output() change = new EventEmitter();
  @Output() softDelete = new EventEmitter<any>();
  @Output() iconChange = new EventEmitter();
  @Output() rowClick = new EventEmitter<T>();

  // Pagination options
  @Input() paginable: boolean = true;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 50;
  @Input() pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 200];
  @Input() hiddenPageSizeOptions: boolean = false;
  @Input() maxPages: number = 5;
  @Output() pageChange = new EventEmitter<PageEvent>();

  // Two-way binding for the current row index
  @Input() currentRowIndex: number = DEFAULT_CURRENT_ROW_INDEX;
  @Output() currentRowIndexChange = new EventEmitter<number>();

  expandedElement!: any;

  constructor() { }

  ngOnInit(): void {
  // init custom table columns
    for (const column of this.columnsToDisplay) {
      this.columnNames.push(column.name);
    }
    if(this.columnsExpandToDisplay){
      for (const column of this.columnsExpandToDisplay) {
        this.columnNameDetails.push(column.name);
      }
    }
    else{
      this.columnNameDetails = this.columnNames;
    }
  }
  onClickSoftDetele(row: any): void {
    row.deleted = true;
    this.softDelete.emit(row)

    // Mark as changed data
    this.change.emit();
  }

  changeIcon(row: any, col: Column, event: MouseEvent){
    if (col.name === EXPAND_COLLAPSE_COLUMN) {
      this.dataTable = this.dataTable.map((item: any) => {
        if (row.isDisabledExpandRow) {
          return item;
        }
        if (this.allowExpandMultiple) {
          item.statusIconName = item.id === row.id ? item.statusIconName === "keyboard_arrow_up" ? "keyboard_arrow_down" : "keyboard_arrow_up" : item.statusIconName;
        } else if (item.id === row.id) {
          item.statusIconName = item.statusIconName == "keyboard_arrow_up" ? "keyboard_arrow_down": "keyboard_arrow_up";
        }
        return item;
      });
      if (!row.isDisabledExpandRow) {
        event.stopPropagation();
      }

      this.iconChange.emit(row);
    }
  }

  onClickRow(row: T, index: number, event: MouseEvent): void {
    const elem = event.target as Element;

    // Stop propagation the row click event if click on the inside elements
    if (elem && !elem.className.includes(STOP_PROPAGATION_CLASS)) {
      this.currentRowIndex = index;
      this.currentRowIndexChange.emit(index);
      this.rowClick.emit(row);
    }

    if (this.isExpandCollapse) {
      this.setExpandElement(row);
    }
  }

  onClickInlineEdit(row: any): void {
    row.editing = true;

    // Capture old values of the row
    const editableColumns = this.columnsToDisplay.filter((col:any) => col.editable);
    if (editableColumns.length > 0) {
      row.oldValues = {};
      editableColumns.forEach((col:any) => {
        row.oldValues[col.name] = row[col.name];
      });
    }

    // Mark as changed data
    this.change.emit();
  }
  onChangeSort(sortState: Sort): void {
    this.sortChange.emit(sortState);
    this.paginator?.jumpToFirst();
  }

  onChangePage(event: PageEvent): void {
    this.pageChange.emit(event);
  }
  getCellContentTemplate(columnName: string): TemplateRef<any> | undefined {
    let cellContentTemplate: TemplateRef<any> | undefined;
    if (this.cellContents && this.cellContents.length > 0) {
      const cellContent = this.cellContents.find(
        (cell) => cell.columnName === columnName
      );
      if (cellContent) {
        cellContentTemplate = cellContent.templateRef;
      }
    }

    return cellContentTemplate;
  }
  getCellStyle(column: Column): any {
    const styleObj: any = column.style || {};

    if (column.width) {
      styleObj['width'] = column.width;
    }

    return styleObj;
  }
  getColumnClasses(column: Column): string {
    let className = '';

    let alignClass = '';
    if (column.type === ColumnType.Decimal) {
      alignClass = 'align-right';
    }
    if (column.align === Align.Left) {
      alignClass = 'align-left';
    }
    if (column.align === Align.Right) {
      alignClass = 'align-right';
    }
    if (column.align === Align.Center) {
      alignClass = 'align-center';
    }
    className += alignClass;

    // if (column.truncate) {
    //   className += ' truncate';
    // }

    if (column.sortable) {
      className += ' sortable';
    }

    return className;
  }

  getRowClasses(row: T): string {
    let classNames = '';

    if (this.rowClick.observers.length > 0) {
      classNames += ' active-row';
    }

    if (row.deleted) {
      classNames += ' deleted-row';
    }

    if (row.italic) {
      classNames += ' italic';
    }

    if (row.errorRow) {
      classNames += ' error-row';
    }

    if (row.disabledRow) {
      classNames += ' disabled-row';
    }

    return classNames;
  }

  getHeaderStyle(column: Column): any {
    const styleObj: any = column.header?.style || {};

    if (column.width) {
      styleObj['width'] = column.width;
    }

    return styleObj;
  }

  setExpandElement(element: any) {
    if (element.isDisabledExpandRow) {
      return;
    }
    this.expandedElement = this.expandedElement === element ? null : element;
  }
}
