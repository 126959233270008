import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MMMM y',
  },
  display: {
    dateInput: 'MMMM y',
    monthYearLabel: 'MMMM y',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'MMMM y'
  },
  minDate: new Date(1799, 11, 31),
  maxDate:new Date(9999, 11, 31)
};

export const MY_DATE = {
  minDate: new Date(1799, 11, 31),
  maxDate:new Date(9999, 11, 31)
};

@Component({
  selector: 'ptg-datepicker-month',
  templateUrl: './datepicker-month.component.html',
  styleUrls: ['./datepicker-month.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ]
})
export class DatepickerMonthComponent implements OnInit {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() isRequired?: boolean;
  @Input() class?: string;
  @Input() minDate = MY_DATE.minDate;
  @Input() maxDate = MY_DATE.maxDate;
  @Input() errorAsync?: string;

  @Input() minTime!: any;
  @Input() errorRequire?: string;
  @Input() errorRange?: string;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  @Output() changeValue = new EventEmitter();

  errorInvalid? = "Invalid Date format.";
  errorMinDate = 'Chosen month must be after the current month.';
  errorMaxDate = 'Invalid Date format.';
  constructor() { }

  ngOnInit(): void {
    if(this.isRequired){
      this.controlField.addValidators(Validators.required);
      this.errorRequire = this.errorRequire ? this.errorRequire : `${this.placeholder} is required.`;
    }
  }

  onChangeValue(event: any) {
    this.changeValue.emit(event.value);
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: any) {
    this.controlField.setValue(normalizedMonth);
    datepicker.close();
  }
}
