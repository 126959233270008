import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { ACTION } from '@ptg-shared/constance';
import * as fromReducer from '@ptg-reducers';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ParametersQuery } from '@ptg-employer/models';

import * as TierConfigurationActions from '../../store/actions/tier-configuration.actions';
import * as fromMember from '../../store/reducers';
import { TierConditionOperatorType, TierFilePropertyType } from '../../constance/tier-configuration.const';
import { EditTierConfigurationComponent } from '../../components/edit-tier-configuration/edit-tier-configuration.component';
import {
  Tier,
  TierCondition,
} from '../../types/models/tier-configuration.model';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '@ptg-shared/components';

export enum Operator {
  Is,
  And,
  Or,
}

@Component({
  selector: 'ptg-tier-configuration',
  templateUrl: './tier-configuration.component.html',
  styleUrls: ['./tier-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TierConfigurationComponent extends BaseComponent {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Tiers',
      url: '',
    },
  ];
  currentPageName: string = 'Tier';
  selectedTier: Tier | undefined;

  currentRowIndex: number = 0;
  totalRecord: number = 0;
  sortInfo: {} | any = {};
  isLoading: boolean = false;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentFund: any;
	pageName = 'ptg-tier-configuration';
  dataTable: Tier[] = [];
  columns: Column[] = [
    {
      name: 'tierCode',
      header: {
        title: 'Tier Code',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'tierName',
      header: {
        title: 'Tier Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'modifiedDate',
      header: {
        title: 'Modified At',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy hh:mm a' },
      sortable: true,
    },
    {
      name: 'ACTION_COLUMN',
      header: {
        title: 'Action',
      },
    },
  ];
  tierName: string = '';
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    // private route: ActivatedRoute,
    public datepipe: DatePipe,
    public dialog: MatDialog
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize')) === 0?  this.pageSize: Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize'));
    this.getData();
    this.selectorGetData();
  }

  private selectorGetData() {
    this.memberStore
      .select(fromMember.selectTierConfiguration)
      .pipe(
        tap((state) => (this.isLoading = !!state?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.memberStore.dispatch(
          TierConfigurationActions.clearGetTierConfigurationsState()
        );
        if (!state?.payload?.length) return;

        this.dataTable = state?.payload.map(el => {
          return {
            ...el,
            modifiedByName: el.modifiedByName ? `${el.modifiedByName} ${this.datepipe.transform(new Date(el.modifiedDate + ' UTC'), 'MM/dd/yyyy hh:mm a')}` : '',
            modifiedDate: new Date(el.modifiedDate + ' UTC').toISOString(),
            conditions: el.conditions.map(con => {
              return {
                ...con,
                firstValueDisplay: this.formatDateFirstValueDisplay(con),
                secondValueDisplay: this.formatDateSecondValueDisplay(con),
              }
            })
          }
        });

        this.totalRecord = state.total ?? 0;

        this.currentRowIndex = 0;
        this.selectedTier = this.dataTable[this.currentRowIndex];
      });
  }

  getData() {
    let sortType = 0;
    let sortNames = ['TierName'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: ParametersQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      TierConfigurationActions.getTierConfiguration({ query: query })
    );
  }
  onChangeSort(event: any) {
    this.sortInfo = event;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-' + this.pageName + '-pageSize', this.pageSize.toString());
    sessionStorage.setItem(this.currentFund.key+ '-' + this.pageName + '-pageNumber', this.pageNumber.toString()); 
    this.getData();
  }

  selectRow(event: any) {
    this.selectedTier = event.row;
    this.currentRowIndex = this.dataTable.findIndex(
      (item) => item.id === event?.row?.id
    );
  }

  formatDateFirstValueDisplay(input: TierCondition) : string{
    if(input.propertyType === TierFilePropertyType.Date && input.operatorType !== TierConditionOperatorType.IsIn && input.operatorType !== TierConditionOperatorType.IsNotIn){
      let ouput = this.datepipe.transform(new Date(input.firstValueDisplay), 'MM/dd/yyyy')
      if(ouput)
        return ouput;
    }
    return input.firstValueDisplay;
  }
  
  formatDateSecondValueDisplay(input: TierCondition) : string{
    if(input.propertyType === TierFilePropertyType.Date && input.operatorType !== TierConditionOperatorType.IsIn && input.operatorType !== TierConditionOperatorType.IsNotIn){
      let ouput = this.datepipe.transform(new Date(input.secondValueDisplay), 'MM/dd/yyyy')
      if(ouput)
        return ouput;
    }
    return input.secondValueDisplay;
  }

  displayOperator(condition: TierCondition): string {
    if (!condition.firstValueDisplay && !condition.secondValueDisplay) {
      return '';
    }
    let displayValue = `<i>${condition.operatorName}</i>`;
    switch (condition.operatorType) {
      case TierConditionOperatorType.Equal:
      case TierConditionOperatorType.NotEqual:
      case TierConditionOperatorType.Contains:
      case TierConditionOperatorType.NotContains:
      case TierConditionOperatorType.GreaterThan:
      case TierConditionOperatorType.LessThan:
      case TierConditionOperatorType.IsOrGreaterThan:
      case TierConditionOperatorType.IsOrLessThan: {
        displayValue += this.displayValue(
          Operator.Is,
          condition.firstValueDisplay
        );
        break;
      }

      case TierConditionOperatorType.IsBetween: {
        displayValue += this.displayValue(
          Operator.And,
          condition.firstValueDisplay,
          condition.secondValueDisplay
        );
        break;
      }

      case TierConditionOperatorType.IsNotIn:
      case TierConditionOperatorType.IsIn: {
        displayValue += this.displayValue(
          Operator.Or,
          condition.firstValueDisplay
        );
        break;
      }
    }
    return displayValue;
  }

  displayValue(
    operator: Operator,
    firstValue: string,
    secondValue?: string
  ): string {
    switch (operator) {
      case Operator.Is:
        return this.formatIsOperator(firstValue);
      case Operator.And:
        return this.formatBetweenOperator(firstValue, secondValue);
      case Operator.Or:
        return this.formatInAndNotInOperator(firstValue);
    }
  }

  formatInAndNotInOperator(data: string): string {
    let displayValue = '';
    let values = data.split(',');
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      if (index === 0) {
        displayValue += `<span class="colored"> ${element}</span>`;
      } else {
        displayValue += ` <i>or</i> <span class="colored">${element}</span>`;
      }
    }
    return displayValue;
  }

  formatIsOperator(firstValue: string): string {
    return `<span class="colored"> ${firstValue}</span>`;
  }

  formatBetweenOperator(firstValue: string, secondValue?: string): string {
    let secondValueDisplay = secondValue
      ? ` <i>and</i> <span class="colored">${secondValue}</span>`
      : '';
    return `<span class="colored"> ${firstValue}</span>${secondValueDisplay}`;
  }

  // Create/Edit Tier
  onUpdateTier(tier?: Tier) {
    const dialogRef = this.dialog.open(EditTierConfigurationComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        ...tier,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const { isSuccess, tierName, action } = result;
        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, `Tier ${tierName}`, action);
        if (isSuccess) this.getData();
      }
    });
  }
  //End Create/Edit Tier

  // Remove Tier
  onClickRemoveItem(row: any): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Warning,
        title: 'Confirmation',
        cancelButtonTitle: 'No',
        text: `Are you sure you want to remove this Tier Item ?`, //TODO
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //TODO
      }
    });
  }
  // End Remove Tier

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
