import { createAction, props } from '@ngrx/store';

import { PayrollRegisterListResponse } from '../../services/models/payroll-register.model';

export const GetPayrollRegisterListRequest = '[GetPayrollRegisterList/API] Send Request';
export const GetPayrollRegisterListSuccess = '[GetPayrollRegisterList/API] Success';
export const GetPayrollRegisterListFailure = '[GetPayrollRegisterList/API] Failure';

export const getPayrollRegisterList = createAction(
  GetPayrollRegisterListRequest,
  props<{ query: any }>()
);
export const getPayrollRegisterListSuccess = createAction(
  GetPayrollRegisterListSuccess,
  props<{ payrollRegisterList: PayrollRegisterListResponse }>()
);
export const getPayrollRegisterListFailure = createAction(
  GetPayrollRegisterListFailure,
  props<{ error?: any }>()
);

