<ptg-select
  *ngIf="isEmployerMultipleBank && CalculationBenefitHistoryStatus['Pending Approval'] === currentStatus;"
  [placeholder]="employerTypeLabel"
  [errorRequire]="employerTypeLabel + ' is required.'"
  class="w-full"
  [controlField]="employerTypeControl"
  [isSetMaxWidthSelect]="true"
  [isOptionObj]="true"
  [listData]="employerTypeOptions"
></ptg-select>
<ng-container *ngIf="CalculationBenefitHistoryStatus.Approved === currentStatus">
  <div class="flex flex-col" *ngIf="employerTypeControl?.value?.employerName">
    <div class="text-sm leading-6 emphasis-medium">{{employerTypeLabel}}</div>
    <div class="text-base emphasis-high">
      {{employerTypeControl?.value?.employerName}}
    </div>
  </div>
</ng-container>
