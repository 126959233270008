import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { 
  clearCreateMemberStateAction, 
  clearSetMemberEventStateAction, 
  clearSetNonMemberToMemberStateAction, 
  clearUpdateMemberCardStateAction, 
  createMemberAction, 
  createMemberFailureAction, 
  createMemberSuccessAction, 
  setMemberEventFailureAction, 
  setMemberEventSuccessAction, 
  setNonMemberToMemberAction, 
  setNonMemberToMemberFailureAction, 
  setNonMemberToMemberSuccessAction, 
  updateMemberCardAction, 
  updateMemberCardFailureAction, 
  updateMemberCardSuccessAction 
} from '../actions/member.action';

export interface MemberDataState {
  createMember?: BaseActionState<{ memberId: string }>;
  updateMemberCard?: BaseActionState<{ cardName: string }>;
  setMemberEvent?: BaseActionState;
  setNonMemberToMember?: BaseActionState<{ memberId: string }>;
}

const initialState: MemberDataState = {};

export const memberDataReducer = createReducer(
  initialState,
  on(createMemberAction, (state) => ({
    ...state,
    createMember: {
      isLoading: true,
    },
  })),
  on(createMemberSuccessAction, (state, { memberId }) => ({
    ...state,
    createMember: {
      isLoading: false,
      success: true,
      payload: { memberId : memberId }
    },
  })),
  on(createMemberFailureAction, (state, { error }) => ({
    ...state,
    createMember: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateMemberStateAction, (state) => ({
    ...state,
    createMember: undefined,
  })),
  on(updateMemberCardAction, (state) => ({
    ...state,
    updateMemberCard: {
      isLoading: true,
    },
  })),
  on(updateMemberCardSuccessAction, (state, { cardName }) => ({
    ...state,
    updateMemberCard: {
      isLoading: false,
      success: true,
      payload: { cardName : cardName }
    },
  })),
  on(updateMemberCardFailureAction, (state, { cardName, error }) => ({
    ...state,
    updateMemberCard: {
      isLoading: false,
      success: false,
      error: error,
      payload: { cardName : cardName }
    },
  })),
  on(clearUpdateMemberCardStateAction, (state) => ({
    ...state,
    updateMemberCard: undefined,
  })),
  on(setMemberEventSuccessAction, (state) => ({
    ...state,
    setMemberEvent: {
      isLoading: false,
      success: true
    },
  })),
  on(setMemberEventFailureAction, (state, { error }) => ({
    ...state,
    setMemberEvent: {
      isLoading: false,
      success: false,
      error: error
    },
  })),
  on(clearSetMemberEventStateAction, (state) => ({
    ...state,
    setMemberEvent: undefined,
  })),

  on(setNonMemberToMemberAction, (state) => ({
    ...state,
    setNonMemberToMember: {
      isLoading: true,
    },
  })),
  on(setNonMemberToMemberSuccessAction, (state, { memberId }) => ({
    ...state,
    setNonMemberToMember: {
      isLoading: false,
      success: true,
      payload: {
        memberId
      }
    },
  })),
  on(setNonMemberToMemberFailureAction, (state, { error }) => ({
    ...state,
    setNonMemberToMember: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetNonMemberToMemberStateAction, (state) => ({
    ...state,
    setNonMemberToMember: undefined,
  }))

);
