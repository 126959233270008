<ng-container *ngFor="let item of data; let idx = index">
  <div #contentEl class="card-description__content" [ngStyle]="{ width: columnNumber }">
    <div class="card-description__content--wrapper">
      <div class="card-description__content--label">
        <div class="label truncate">
          {{ item?.name }}
        </div>
      </div>
      <div class="card-description__content--value flex" [ngClass]="{ 'justify-between': !item?.valueMask }">
        <ng-container [ngSwitch]="item?.type">
          <div class="value truncate" *ngSwitchCase="'Currency'">
            {{ (item?.value | numberLocalDecimal: '$') || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Date'">{{ (item?.value | date: 'MM/dd/yyyy') || '-' }}</div>
          <div class="value truncate" *ngSwitchCase="'Person_Name'">
            <span *ngIf="!item?.option; else partialNameTemplate">
              {{ (item.value | personName: item.options) || '-' }}
            </span>
            <ng-template #partialNameTemplate>
              <span *ngIf="item?.option == 'Prefix'">
                {{ ({ prefix: item?.value } | prefixName: item?.options) || '-' }}
              </span>
              <span *ngIf="item?.option != 'Prefix'">
                {{ item?.value || '-' }}
              </span>
            </ng-template>
          </div>
          <div class="value truncate" *ngSwitchCase="'Person Name'">
            <span *ngIf="!item?.option; else partialNameTemplate">
              {{ (item.value | personName: item.options) || '-' }}
            </span>
            <ng-template #partialNameTemplate>
              <span *ngIf="item?.option == 'Prefix'">
                {{ ({ prefix: item?.value } | prefixName: item?.options) || '-' }}
              </span>
              <span *ngIf="item?.option != 'Prefix'">
                {{ item?.value || '-' }}
              </span>
            </ng-template>
          </div>
          <div class="value truncate" *ngSwitchCase="'PersonName'">
            <span *ngIf="!item?.option; else partialNameTemplate">
              {{ (item.value | personName: item.options) || '-' }}
            </span>
            <ng-template #partialNameTemplate>
              <span *ngIf="item?.option == 'Prefix'">
                {{ ({ prefix: item?.value } | prefixName: item?.options) || '-' }}
              </span>
              <span *ngIf="item?.option != 'Prefix'">
                {{ item?.value || '-' }}
              </span>
            </ng-template>
          </div>
          <div class="value w-100" *ngSwitchCase="'Address'">
            <div *ngIf="!item.option; else addressOptionTemplate">
              <ptg-view-address [value]="item?.value" [options]="item.options"></ptg-view-address>
            </div>
            <ng-template #addressOptionTemplate>
              <div *ngIf="item?.option == 'State' || item?.option == 'Country'">
                <ptg-view-address
                  [value]="{ state: item?.value, country: item?.value, street1: '', city: '', zipCode: '' }"
                  [options]="item.options"
                ></ptg-view-address>
              </div>
              <div *ngIf="item?.option != 'State' && item?.option != 'Country'">
                {{ item?.value || '-' }}
              </div>
            </ng-template>
          </div>
          <div class="value truncate" *ngSwitchCase="'Decimal'">
            {{
              (item?.value
                | numberDecimal
                  : {
                      decimal:
                        item?.config?.fractionalLength === 'false'
                          ? 2
                          : item?.config?.fractionalLengthInput ?? item?.config?.fractionalLength ?? 2
                    }) || '-'
            }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Whole_Number'">
            {{ (item?.value | numberDecimal) || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'WholeNumber'">
            {{ (item?.value | numberDecimal) || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Whole Number'">
            {{ (item?.value | numberDecimal) || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Lookup'">
            {{ (item?.value | propertyList: item.options) || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Binary'">
            {{ item?.value === null ? '-' : !!item?.value ? item.config.affirmative : item.config.negative }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Percentage'">
            {{ (item?.value | numberDecimal) ? (item?.value | numberDecimal) + '%' : '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Employer'">
            {{ (item?.value | propertyList: item.options) || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Department'">
            {{ (item?.value | propertyList: item.options) || '-' }}
          </div>
          <ng-container *ngSwitchCase="'RichText'">
            <ptg-view-rich-text [title]="item?.name" [content]="item?.value"></ptg-view-rich-text>
          </ng-container>
          <div class="value truncate" *ngSwitchCase="'DateTime'">
            {{ (item?.value | date: 'MM/dd/yyyy hh:mm a') || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Date Time'">
            {{ (item?.value | date: 'MM/dd/yyyy hh:mm a') || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Date_Time'">
            {{ (item?.value | date: 'MM/dd/yyyy hh:mm a') || '-' }}
          </div>
          <div class="value truncate break-word" *ngSwitchCase="'Email'">{{ item?.value || '-' }}</div>
          <div class="value truncate" *ngSwitchCase="'Tier'">
            {{ (item?.value | propertyList: item.options) || '-' }}
          </div>
          <div class="value truncate" *ngSwitchCase="'Entity Reference'">
            <span class="link" target="_blank" (click)="onClickCaseNumber(item)">
              {{ item?.value || '-' }}
            </span>
          </div>
          <div class="value truncate" *ngSwitchCase="'Text'">
            <span class="{{ item.path ? 'link' : '' }}" target="_blank" (click)="onClickCaseNumber(item)">
              {{ (item?.value | granulationOptions: item.option) || '-' }}
            </span>
          </div>
          <div class="value truncate" *ngSwitchDefault>{{ item?.value || '-' }}</div>
        </ng-container>
        <div *ngIf="item?.valueMask" class="masked-icon">
          <mat-icon class="ml-2" matSuffix (click)="onClickIcon(item)">{{
            item?.isMasked ? 'visibility' : 'visibility_off'
          }}</mat-icon>
        </div>
        <!--        <ptg-masked-eye-->
        <!--          *ngIf="item?.value && item?.config?.masked == 'true' && item?.propertyKey"-->
        <!--          page="retirement-benefit-detail-section"-->
        <!--          [propertyValue]="item"-->
        <!--          [propertyKey]="item.propertyKey ?? ''"-->
        <!--          idx="0"-->
        <!--        ></ptg-masked-eye>-->
      </div>
    </div>
  </div>
</ng-container>
