import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, combineLatest } from 'rxjs';

import { BaseComponent } from '@ptg-shared/components';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';

import {
  BooleanPropertyConfigType,
  EntityPropertyDetail,
  EntityReference,
  GetEntityPropertyDetailRequest,
  GetEntityReferencesRequest,
  PropertyConfig,
  PropertyConfigOption,
  SetEntityPropertyRequest,
} from '../../services/models';
import { stringToBoolean } from '@ptg-shared/utils/string.util';

import { Store, select } from '@ngrx/store';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { EntityService } from '../../services';
import { EntityState } from '../../store/reducers';
import { getEntityPropertyDetailAction, getEntityReferencesAction, setEntityPropertyAction } from '../../store/actions';
import { getEntityPropertyDetailSelector, getEntityReferencesSelector } from '../../store/selectors';
import { LayoutService } from '@ptg-shared/services/layout.service';

@Component({
  selector: 'ptg-edit-entity-reference',
  templateUrl: './edit-entity-reference.component.html',
  styleUrls: ['./edit-entity-reference.component.scss'],
})
export class EditEntityReferenceComponent extends BaseComponent {
  formSubmit$: Subject<void> = new Subject();
  editForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.entityService.checkPropertyNameExist,
        'name',
        undefined,
        {
          params: {
            entityId: this.data?.entityId,
            entityPropertyId: this.data?.entityPropertyId,
            entityComponentId: this.data?.entityComponentId,
          },
        }
      ),
    }),
    includeInBulkUpdate: this.fb.control(''),
    importLabel: this.fb.control(''),
    entityName: this.fb.control(''),
  });

  get propertyNameCtrl() {
    return this.editForm?.get('name') as FormControl;
  }

  get requiredConditionCtrl() {
    return this.editForm?.get('requiredCondition') as FormControl;
  }

  get includeInBulkUpdateCtrl() {
    return this.editForm?.get('includeInBulkUpdate') as FormControl;
  }

  get importLabelCtrl() {
    return this.editForm?.get('importLabel') as FormControl;
  }

  get entityNameCtrl() {
    return this.editForm?.get('entityName') as FormControl;
  }

  listEntity?: any;
  propertyDetail?: EntityPropertyDetail;
  entityReference: EntityReference[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityId: string;
      entityComponentId: string;
      entityPropertyId: string;
      importKey?: string;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditEntityReferenceComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private entityService: EntityService,
    public entityStore: Store<EntityState>,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formSubmit();
    this.getEntityPropertyDetailAction();
    this.getEntityReferenceAction();
    this.getEntityPropertyDetailSelector();
  }

  initFormGroup(disabledToggles: any): void {
    this.editForm = this.fb.group({
      name: this.fb.control(this.propertyDetail?.propertyName, {
        validators: [Validators.required, Validators.maxLength(250)],
        asyncValidators: checkApiValidator(
          this.entityService.checkPropertyNameExist,
          'name',
          undefined,
          {
            params: {
              entityId: this.data?.entityId,
              entityPropertyId: this.data?.entityPropertyId,
              entityComponentId: this.data?.entityComponentId,
            },
          }
        ),
      }),
      requiredCondition: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.required),
        disabled: disabledToggles?.includes(BooleanPropertyConfigType.Required),
      }),
      includeInBulkUpdate: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.readOnly)
          ? false
          : !!this.propertyDetail?.importKey,
        disabled: stringToBoolean(this.propertyDetail?.configs?.readOnly),
      }),
      importLabel: this.fb.control(this.data?.importKey || this.propertyDetail?.importKey, {
        validators: [Validators.required, Validators.maxLength(250)],
        asyncValidators: checkApiValidator(
          this.entityService.checkPropertyNameExist,
          'importKey',
          undefined,
          {
            params: {
              entityId: this.data?.entityId,
              entityPropertyId: this.data?.entityPropertyId,
              entityComponentId: this.data?.entityComponentId,
            },
          }
        ),
      }),
      entityName: this.fb.control({
        value: stringToBoolean(this.propertyDetail?.configs?.entityName),
        disabled: false,
      }),
    });
  }

  getEntityPropertyDetailAction(): void {
    let request: GetEntityPropertyDetailRequest = {
      entityPropertyId: this.data?.entityPropertyId,
    };

    this.entityStore.dispatch(getEntityPropertyDetailAction({ request }));
  }

  getEntityPropertyDetailSelector(): void {
    combineLatest([
      this.entityStore.select(getEntityPropertyDetailSelector),
      this.entityStore.select(getEntityReferencesSelector)
    ])
    .pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([propertyDetail, entityRefs]) => {
      this.layoutService.showLoading = !!propertyDetail?.isLoading || !!entityRefs?.isLoading;
      if (!propertyDetail?.isLoading && !entityRefs?.isLoading) {
        this.entityReference = entityRefs?.payload ?? [];
        this.propertyDetail = propertyDetail?.payload;
        const disabledToggles = this.propertyDetail?.configs?.disabledToggles?.replace(' ', '')?.split(',');
        this.initFormGroup(disabledToggles);
        if (propertyDetail?.payload?.configs?.entityReference?.entityReferences?.length > 0) {
          this.listEntity = propertyDetail?.payload?.configs?.entityReference?.entityReferences?.map(
            (item: any) => {
              const entityName = this.entityReference?.find(
                (ele) => ele.id === item.entityId
              )?.name;
              return entityName;
            }
          );
        }  
      }
    });
  }

  getEntityReferenceAction(): void {
    const request: GetEntityReferencesRequest = {};

    this.entityStore.dispatch(
      getEntityReferencesAction({
        request,
      })
    );
  }

  formSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
          this.editForm.get('importLabel')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    const configKey = Object.values(PropertyConfigOption);
    const booleanKey = Object.values(BooleanPropertyConfigType);
    const formValue = this.editForm.getRawValue();
    let config: PropertyConfig = {} as PropertyConfig;
    Object.keys(formValue).forEach((key) => {
      if (configKey.includes(key as any)) {
        (config as any)[key] = Array.isArray(formValue[key])
          ? JSON.stringify(formValue[key])
          : formValue[key].toString() || '';
      }
      if (booleanKey.includes(key as any)) {
        (config as any)[key] = (!!formValue[key]).toString();
      }
    });

    config.required = this.editForm.get('requiredCondition')?.value.toString();

    const request: SetEntityPropertyRequest = {
      entityComponentId: this.data?.entityComponentId,
      entityPropertyId: this.data?.entityPropertyId,
      name: this.propertyNameCtrl.value.trim(),
      importKey: this.editForm.get(
        BooleanPropertyConfigType.IncludeInBulkUpdate
      )?.value
        ? this.importLabelCtrl.value?.trim()
        : '',
      configs: config as PropertyConfig,
    };

    this.entityStore.dispatch(setEntityPropertyAction({ request }));
    this.dialogRef.close({ propertyName: this.propertyNameCtrl?.value });
  }

  onClickCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  cleanImportLabel(value: any): void {
    if (!value)
      this.importLabelCtrl.setValidators(null);
    else
      this.importLabelCtrl.setValidators([Validators.required, Validators.maxLength(250)]);
    this.importLabelCtrl.setValue('');
  }
}
