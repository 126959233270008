import {Component, Inject, OnInit} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InputType} from '@ptg-member/constance/metadataPropertyType.const';
import {AnnualCertificationConfirmationData} from 'src/app/employer/models/annual-certification-participants.model';
import {Observable, of, Subject, timer} from "rxjs";
import {catchError, debounceTime, filter, map, startWith, switchMap, take, tap} from "rxjs/operators";
import {AnnualCertificationService} from "../../../services/annual-certification.service";
import {AbstractControlStatus} from "@ptg-shared/types/models/common.model";

@Component({
  selector: 'ptg-confirm-annual-certification-dialog',
  templateUrl: './confirm-annual-certification-dialog.component.html',
  styleUrls: ['./confirm-annual-certification-dialog.component.scss'],
})
export class ConfirmAnnualCertificationDialogComponent implements OnInit {
  readonly InputType = InputType;
  cashJournalControl: FormControl = this.fb.control(0);
  formSubmit$ = new Subject();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmAnnualCertificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      annualCertificationConfirmationData?: AnnualCertificationConfirmationData,
      municipalityName: string,
      creditAmount: number,
      participantName: string,
      greatestCashJournal: string,
    },
    private annualCertificationService: AnnualCertificationService
  ) {
  }

  ngOnInit(): void {
    this.initCashJournalControl();
    this.checkSubmitForm();
  }

  initCashJournalControl() {
    this.cashJournalControl = this.fb.control(
      this.data.greatestCashJournal ? this.data.greatestCashJournal : 
      (this.data.annualCertificationConfirmationData?.cashJournalEntry || 0),
      [Validators.required, Validators.maxLength(4)],
      this.validateCashJournal()
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  validateCashJournal(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.annualCertificationService.verifyCashJournal(control.value.toString()).pipe(
            map(() => {
              return null;
            }),
            catchError(({error}) => {
              return of({ invalidCashJournal: error?.errorMessage?.[1] });
            }),
          )
        )
      );
    };
  }

  checkSubmitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.cashJournalControl.markAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.cashJournalControl.statusChanges.pipe(
            startWith(this.cashJournalControl.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter(status => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.dialogRef.close({cashJournalEntry: Number(this.cashJournalControl.value).toString()});
      });
  }

  get noOfParticipantsNoActivity(): number | undefined {
    return this.data.annualCertificationConfirmationData?.noOfParticipantsNoActivity;
  }

  get noOfParticipantsNotMeetRequirement(): number | undefined {
    return this.data.annualCertificationConfirmationData?.noOfParticipantsNotMeetRequirement;
  }

  get amountCredit(): number | undefined {
    return this.data.annualCertificationConfirmationData?.amountCredit;
  }
}
