import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';
import { GetMemberAuditTrailsCardInfoRequest, GetMemberAuditTrailsCardInfoResponse, GetMemberAuditTrailsListRequest, GetMemberAuditTrailsListResponse, GetMemberAuditTrailsProfileInfoRequest, GetMemberAuditTrailsProfileInfoResponse, GetMemberAuditTrailsSearchNameRequest, GetMemberAuditTrailsSearchNameResponse, GetMemberAuditTrailsSearchUserRequest, GetMemberAuditTrailsSearchUserResponse, GetMemberAuditTrailsSectionListRequest, GetMemberAuditTrailsSectionListResponse } from '@ptg-member/types/models/member-audit-trails.model';

@Injectable({
  providedIn: 'root',
})
export class MemberAuditTrailsService {
  constructor(private httpClient: HttpClient) {}

  getMemberAuditTrailsList(
    request: GetMemberAuditTrailsListRequest,
  ): Observable<GetMemberAuditTrailsListResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.sectionId) {
      params = params.append('CardId', request.sectionId);
    }
    if (request?.nameId) {
      params = params.append('TargetId', request.nameId);
    }
    if (request?.userId) {
      params = params.append('CreatedBy', request.userId);
    }
    if (request?.dateFrom) {
      params = params.append('DateCreatedFrom', request.dateFrom);
    }
    if (request?.dateTo) {
      params = params.append('DateCreatedTo', request.dateTo);
    }

    return this.httpClient.get<GetMemberAuditTrailsListResponse>(
      `${environment.apiUrl}/AuditLogEvent`,
      { params },
    );
  }

  getMemberAuditTrailsSectionList(
    request: GetMemberAuditTrailsSectionListRequest,
  ): Observable<GetMemberAuditTrailsSectionListResponse> {
    return this.httpClient.get<GetMemberAuditTrailsSectionListResponse>(
      `${environment.apiUrl}/entity/cards`,
    );
  }

  getMemberAuditTrailsCardInfo(
    request: GetMemberAuditTrailsCardInfoRequest,
  ): Observable<GetMemberAuditTrailsCardInfoResponse> {
    const viewId = request?.viewId ?? '';

    return this.httpClient.get<GetMemberAuditTrailsCardInfoResponse>(
      `${environment.apiUrl}/entity/views/${viewId}/cards`,
    );
  }

  getMemberAuditTrailsProfileInfo(
    request: GetMemberAuditTrailsProfileInfoRequest,
  ): Observable<GetMemberAuditTrailsProfileInfoResponse> {
    const memberId = request?.memberId ?? '';
    let params = new HttpParams();

    if (request?.entityId) {
      params = params.append('entityId', request?.entityId);
    }

    return this.httpClient.get<GetMemberAuditTrailsProfileInfoResponse>(
      `${environment.apiUrl}/entity/entities/member/${memberId}/profile`,
      { params },
    );
  }

  getMemberAuditTrailsSearchName(
    request: GetMemberAuditTrailsSearchNameRequest,
  ): Observable<GetMemberAuditTrailsSearchNameResponse> {
    let params = new HttpParams();

    if (request?.searchNameContent) {
      params = params.append('searchValue', request?.searchNameContent);
    }

    return this.httpClient.get<GetMemberAuditTrailsSearchNameResponse>(
      `${environment.apiUrl}/entities/members/audit-trails/entity-data/search`,
      { params }
    );
  }

  getMemberAuditTrailsSearchUser(
    request: GetMemberAuditTrailsSearchUserRequest,
  ): Observable<GetMemberAuditTrailsSearchUserResponse> {
    let params = new HttpParams();

    if (request?.searchUserContent) {
      params = params.append('searchValue', request?.searchUserContent);
    }

    return this.httpClient.get<GetMemberAuditTrailsSearchUserResponse>(
      `${environment.apiUrl}/users/search`,
      { params },
    );
  }
}
