import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { EarningItems, FundingSourceItems } from './models';
import { DeductionsItems } from './models/payment-deductions.model';

@Injectable()
export class GetPaymentInfoService {

  private earningInfo: BehaviorSubject<EarningItems[]> = new BehaviorSubject<EarningItems[]>([]);
  private deductionInfo: BehaviorSubject<DeductionsItems[]> = new BehaviorSubject<DeductionsItems[]>([]);
  private deductionAsFundingSource: BehaviorSubject<FundingSourceItems[]> = new BehaviorSubject<FundingSourceItems[]>([]);

  constructor() { }

  setEarningInfo(value: EarningItems[]) {
    this.earningInfo.next(value);
  }

  getEarningInfo(): Observable<EarningItems[]> {
    return this.earningInfo.asObservable();
  }

  setDeductionInfo(value: DeductionsItems[]) {
    this.deductionInfo.next(value);
  }

  getDeductionInfo(): Observable<DeductionsItems[]> {
    return this.deductionInfo.asObservable();
  }

  setDeductionAsFundingSource(value: FundingSourceItems[]) {
    this.deductionAsFundingSource.next(value);
  }

  getDeductionAsFundingSource(): Observable<FundingSourceItems[]> {
    return this.deductionAsFundingSource.asObservable();
  }

}
