import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  getHeaderConfigAction,
  getHeaderConfigFailureAction,
  getHeaderConfigSuccessAction,
  getPayeePaymentAction,
  getPayeePaymentFailureAction,
  getPayeePaymentSuccessAction,
  setPayeePaymentAction,
  setPayeePaymentFailureAction,
  setPayeePaymentSuccessAction,
} from '../actions';
import { GetHeaderConfigResponse, GetPayeePaymentResponse } from '../../types/models';
import { PayeePaymentService } from '../../services';

@Injectable()
export class PayeePaymentEffects {
  constructor(
    private actions$: Actions,
    private payeePaymentService: PayeePaymentService,
  ) {}

  getPayeePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayeePaymentAction),
      switchMap(({ request }) =>
        this.payeePaymentService.getPayeePayment(request).pipe(
          map((response: GetPayeePaymentResponse) => getPayeePaymentSuccessAction({ response })),
          catchError((error) => of(getPayeePaymentFailureAction({ error }))),
        ),
      ),
    ),
  );

  setPayeePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPayeePaymentAction),
      switchMap(({ request }) =>
        this.payeePaymentService.setPayeePayment(request).pipe(
          map(() => setPayeePaymentSuccessAction()),
          catchError((error) => of(setPayeePaymentFailureAction({ error }))),
        ),
      ),
    ),
  );

  getHeaderConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHeaderConfigAction),
      switchMap(({ request }) =>
        this.payeePaymentService.getHeaderConfig(request).pipe(
          map((response: GetHeaderConfigResponse) => getHeaderConfigSuccessAction({ response })),
          catchError((error) => of(getHeaderConfigFailureAction({ error }))),
        ),
      ),
    ),
  );
}
