import { createAction, props } from '@ngrx/store';
import { PaymentInfoAdjustmentType, PaymentInstructionType } from '@ptg-member/features/payee-detail/types/enums';

import { generateActionName } from '@ptg-shared/utils/common.util';
import {
  GetPaymentEarningDetailParams,
  GetPaymentEarningDetailRequest,
  PaymentEarningsDetailResponse,
} from '../../services/models';
import { PaymentDeductionsDetailResponse } from '../../services/models/payment-deductions.model';
import {
  PaymentAnnualTotalsParams,
  PaymentAnnualTotalsRequest,
  PaymentAnnualTotalsResponse,
} from '../../services/models/payment-annual-totals.model';

export const getPaymentEarningDetailAction = createAction(
  generateActionName('PaymentEarningDetail/API').Send,
  props<{ request: GetPaymentEarningDetailRequest; params?: GetPaymentEarningDetailParams }>(),
);
export const getPaymentEarningDetailSuccess = createAction(
  generateActionName('PaymentEarningDetail/API').Success,
  props<{ response: PaymentEarningsDetailResponse }>(),
);
export const getPaymentEarningDetailFailure = createAction(
  generateActionName('PaymentEarningDetail/API').Failure,
  props<{ error: any }>(),
);

export const getPaymentAnnualTotalsAction = createAction(
  generateActionName('PaymentAnnualTotals/API').Send,
  props<{ request: PaymentAnnualTotalsRequest; params?: PaymentAnnualTotalsParams }>(),
);

export const getPaymentAnnualTotalsSuccess = createAction(
  generateActionName('PaymentAnnualTotals/API').Success,
  props<{ response: PaymentAnnualTotalsResponse }>(),
);

export const getPaymentAnnualTotalsFailure = createAction(
  generateActionName('PaymentAnnualTotals/API').Failure,
  props<{ error: any }>(),
);

export const clearGetPaymentAnnualTotals = createAction(generateActionName('PaymentAnnualTotals/API').Clear);

export const clearGetPaymentEarningDetail = createAction(generateActionName('PaymentEarningDetail/API').Clear);

export const getPayeeDeductionsDetailAction = createAction(
  generateActionName('PayeeDeductionsDetail/API').Send,
  props<{
    paymentInstructionId: string;
    memberId: string;
    paymentInstructionHistoryId: string;
    instructionStatusHistoryId: string;
    paymentInfoAdjustmentType?: PaymentInfoAdjustmentType | PaymentInstructionType;
  }>(),
);
export const getPayeeDeductionsDetailSuccess = createAction(
  generateActionName('PayeeDeductionsDetail/API').Success,
  props<{ payeeDeductionsDetail: PaymentDeductionsDetailResponse }>(),
);
export const getPayeeDeductionsDetailFailure = createAction(
  generateActionName('PayeeDeductionsDetail/API').Failure,
  props<{ error: any }>(),
);

export const clearGetPayeeDeductionsDetail = createAction(generateActionName('PayeeDeductionsDetail/API').Clear);
