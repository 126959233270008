import { Align, Column, ColumnType } from '@ptg-shared/controls/grid';

export const GRID_COLUMN_VIEW_ADJUSTMENT_DETAILS: Column[] = [
  {
    name: 'createdDate',
    header: {
      title: 'Created Date',
    },
    truncate: true,
    sortable: true,
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
      keepLocalTime: true,
    },
  },
  {
    name: 'createdBy',
    header: {
      title: 'Created By',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'postedDate',
    header: {
      title: 'Posted Date',
    },
    truncate: true,
    sortable: true,
    type: ColumnType.DateTime,
    templateArgs: {
      format: 'MM/dd/yyyy',
    },
  },
  {
    name: 'adjustmentItem',
    header: {
      title: 'Adjusted Item',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'itemTypeLabel',
    header: {
      title: 'Item Type',
    },
    truncate: true,
    sortable: true,
  },
  {
    name: 'amount',
    header: {
      title: 'Amount',
    },
    truncate: true,
    sortable: true,
    align: Align.Right,
  },
];
