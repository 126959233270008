<div class="deposit-account-detail-container" id="deposit-account-detail-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" [isDisabledGear]="true"> </ptg-breadcrumb>
  
  <ptg-overview-header-entity #overViewHeaderEntity></ptg-overview-header-entity>

  <div class="deposit-account-content flex flex-col p-6 gap-6">
    <ptg-banner *ngIf="message" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div *ngIf="depositAccountTable != null && depositAccountTable[currentRowIndex] != null" class="box">
      <div class="title">
        <span class="title-name">Selected Account</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditDepositAccount(depositAccountTable[currentRowIndex])"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail">
          <span class="label">Bank Name</span>
          <span class="value truncate">
            <ng-container>
              <ng-container>
                <ng-container>
                  {{ depositAccountTable[currentRowIndex]?.bankName }}
                </ng-container>
              </ng-container>
            </ng-container>
          </span>
        </div>

        <div class="detail detail-account">
          <span class="label">Account</span>
          <span class="value truncate">
            <ng-container>
              <div class="account-number">
                <span>{{
                  depositAccountTable[currentRowIndex].visibilityOff
                    ? depositAccountTable[currentRowIndex].accountNumberMasked
                    : depositAccountTable[currentRowIndex].accountNumber || '-'
                }}</span>

                <span>
                  &nbsp;
                  <mat-icon class="masked-icon" matSuffix (click)="onClickIcon(depositAccountTable[currentRowIndex])">{{
                    depositAccountTable[currentRowIndex].visibilityOff ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
                </span>
              </div>
            </ng-container>
          </span>
        </div>

        <div class="detail">
          <span class="label truncate">Account Type</span>
          <span class="value">
            <ng-container>
              {{ depositAccountTable[currentRowIndex].accountType }}
            </ng-container>
          </span>
        </div>

        <div class="detail detail-active">
          <span class="label">Active</span>
          <span class="value">
            {{ depositAccountTable[currentRowIndex].isActive ? 'Yes' : 'No' }}
          </span>
        </div>
        <div class="detail">
          <span class="label"></span>
          <span class="value">
            <ng-container> </ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="flex-grow overflow-hidden">
      <ptg-list-display-box
        title="Deposit Account List"
        pageName="Data"
        btnAddTitle="New Account"
        [isLoading]="isLoading"
        [dataTable]="depositAccountTable"
        [isAccountDeposit]="true"
        [columns]="columns"
        [sortInfo]="sortInfo"
        [lengthPg]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [currentRowIndex]="currentRowIndex"
        (changePage)="changePage($event)"
        (sortChange)="sortChange($event)"
        (selectRow)="selectRow($event)"
        (addItem)="onAddDepositAccount(undefined)"
      ></ptg-list-display-box>
    </div>
  </div>
</div>
