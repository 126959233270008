import { createAction, props } from '@ngrx/store';

import { GetBenefitPeriodsResponse } from '../../services/models';

export const getBenefitPeriodsAction = createAction('[GetBenefitPeriods/API]');

export const getBenefitPeriodsSuccessAction = createAction(
  '[GetBenefitPeriods/API] Success',
  props<{ response: GetBenefitPeriodsResponse }>(),
);

export const getBenefitPeriodsFailureAction = createAction('[GetBenefitPeriods/API] Failure', props<{ error?: any }>());

export const clearGetBenefitPeriodsStateAction = createAction('[GetBenefitPeriods] Clear');
