import { DateTime } from "luxon";
import { environment } from "src/environments/environment";
import { PreviewFileResponse } from 'src/app/admin/features/file/services/models/documents.model';

export function isString(value: any): boolean {
  return typeof value === 'string'
}

export function stringToBoolean(value: string | boolean | undefined): boolean {
  return `${value}`.toLowerCase() === 'true' || value === true;
}

export function getConcatString(values: string[], separator: string = ', ') {
  return values.filter(value => value).join(separator);
}

export function getDateString(value: string) {
  return (!value || value.includes('+') || value.includes('Z')) ? value : value + 'Z';
}

export function getValueWithoutFormat(value: string) {
  if (!value) {
    return '';
  }
  return value.replace(/[\D]/gi, '');
}

export function isEmpty(value: any) {
  return value === null || value === undefined;
}

export function isNullOrUndefinedOrEmptyString(value: any) {
  return value === null || typeof value === 'undefined' || value === '';
}

export function allItemsAreNullOrUndefinedOrEmptyString(...values: any[]) {
  return [...values].every((item) => isNullOrUndefinedOrEmptyString(item));
}

export function toFixedTrunc(num: number, fixed: number): string {
  const decimalRegex = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || 0) + '})?');
  return Number(Number(num).toString().match(decimalRegex)?.[0] || '0').toLocaleString('en', { minimumFractionDigits: fixed });
}

export function titleCase(value: string): string {
  if (!value) {
    return '';
  }
  return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

export function capitalizeFirstLetterOfEachWord(value: string): string {
  const wordList = value?.split(' ');

  return wordList
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
}

export function getElementId(elementType: string, elementName: string) {
  return `${ elementType }-${ elementName?.replace(/\s+/g, '-').toLowerCase() }`;
}

export function capitalizeFirstLetter(value: string) {
  if (!value) {
    return '';
  }
  return value[0].toUpperCase() + value.slice(1);
}

export function setFractionalLength(value: string | number, fractionalLength: number): number {
  const currentFractionalLength = String(value).split('.')[1] ? String(value).split('.')[1].length : 0;
  return Math.min(fractionalLength, currentFractionalLength);
}

export function getTimeZone() {
  let timezone_offset_min = new Date().getTimezoneOffset();
	let offset_hrs: any = Math.floor(Math.abs(timezone_offset_min/60));
	let offset_min: any = Math.abs(timezone_offset_min%60);
	let timezone_standard;

  if (offset_hrs < 10)
    offset_hrs = '0' + offset_hrs;

  if (offset_min < 10)
    offset_min = '0' + offset_min;

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezone_offset_min < 0)
    timezone_standard = '+' + offset_hrs + ':' + offset_min;
  else if (timezone_offset_min > 0)
    timezone_standard = '-' + offset_hrs + ':' + offset_min;
  else if (timezone_offset_min == 0)
    timezone_standard = 'Z';

  // Timezone difference in hours and minutes
  // String such as +5:30 or -6:00 or Z
  return timezone_standard;
}

export function getDateFormatISO(value: string) {
  let dt = new Date(value),
	current_date: any = dt.getDate(),
	current_month: any = dt.getMonth() + 1,
	current_year: any = dt.getFullYear(),
	current_day;

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  current_date = current_date < 10 ? '0' + current_date : current_date;
  current_month = current_month < 10 ? '0' + current_month : current_month;

  // Current datetime
  // String such as 2016-07-16T19:20:30
  current_day = '' + current_year + '-' + current_month + '-' + current_date;
  return current_day;
}

/**
 * Convert time data to string value use to binding into time picker component
 * @param hour hour value
 * @param time minute value
 * @returns value after formatted. For eg: 0 & 5 -> "12:5 AM", 13 & 30 -> "1:30 PM"
 */
export function getTimePickerString(hour?: number, minute?: number): string {
  if (hour === null || typeof hour === 'undefined') return '';
  if (minute === null || typeof minute === 'undefined') return '';
  return `${hour > 12 ? hour - 12 : hour}:${minute} ${hour >= 12 ? 'PM' : 'AM'}`;
}

export function localeCompare(a: string | undefined, b: string | undefined) {
  return (a ?? '').localeCompare((b ?? ''), 'en');
}

/**
 * Used for split original pattern into Prefix, Pattern, Suffix
 * @param str Identifier Pattern
 * @returns Prefix, Pattern, Suffix of Identifier pattern
 */
export function analyzeIdentifierPattern(str: string, checkingVal: string = '#') {
  const firstCheckingVal = str.indexOf(checkingVal);
    let prefix = '', pattern = '', suffix = '';

    // No `checkingVal` found OR falsy value, set all variables to default values
    if (firstCheckingVal === -1 || !str) {
      return { prefix, pattern, suffix, }
    }

    // 'checkingVal' at 0 index, meaning no Prefix setup
    if (firstCheckingVal === 0) {
      prefix = '';
      const firstNoCheckingValExisted = str.split('').findIndex(item => item !== checkingVal);
        if (firstNoCheckingValExisted !== -1) {
          pattern = str.substring(0, firstNoCheckingValExisted);
          suffix = str.substring(firstNoCheckingValExisted);
        } else {
          pattern = str;
        }

      return { prefix, pattern, suffix, }
    }

    // 'checkingVal' at > 0 index
    if (firstCheckingVal > 0) {
      prefix = str.substring(0, firstCheckingVal);
      const remain = str.substring(firstCheckingVal);
      const firstNoSharpExisted = remain.split('').findIndex(item => item !== checkingVal);
      if (firstNoSharpExisted !== -1) {
        pattern = remain.substring(0, firstNoSharpExisted);
        suffix = remain.substring(firstNoSharpExisted);
      } else {
        pattern = remain;
      }

      return { prefix, pattern, suffix, }
    }

    // No case matched, return default value
    return { prefix, pattern, suffix, }
}

/**
 * Generate Filename with current UTC time
 * @param isUtc use Utc Time or Local Time
 * @param format expected format
 * @param prefix prefix if need
 * @param suffix suffix if need
 * @returns filename
 */
export function getFileNameBaseOnTime(isUtc = true, format = 'MMddyy_HHmmss', prefix = '', suffix = '') {
  const timeString = isUtc ? DateTime.utc().toFormat(format) : DateTime.now().toFormat(format);
  return prefix + timeString + suffix;
}

export function createPreviewUrl(payload?: PreviewFileResponse): string {
  if (payload) {
    const filename = encodeURIComponent(payload?.filename);
    const token = encodeURIComponent(payload?.token);
    return `${environment.apiUrl}/PreviewDocument/view/${filename}?token=${token}`;
  }
  return '';
}

export function isEqual(str1: string | undefined, str2: string | undefined): boolean {
  return str1?.toLowerCase() === str2?.toLowerCase();
}
