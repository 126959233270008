<div class="input-file">
  <div class="input-container">
    <input #inputFile type="file" [multiple]="isMultiple" (change)="getFile($event)" style="display: none" [accept]="accept" />

    <ptg-button
      *ngIf="!controlField?.value && !fileLabel"
      [buttonName]="
        imageFile
          ? pageHeader
            ? 'Upload ' + pageHeader
            : 'Upload Image'
          : uploadButtonName
          ? uploadButtonName
          : 'Upload File'
      "
      classInput="placeholder-file"
      (clickButton)="fnButtonRemove()"
      [isDisabled]="true"
    >
      <ng-container *ngIf="!uploadIcon; else customIconTemplate"
        ><mat-icon svgIcon="upload-placeholder" aria-hidden="false" aria-label="upload-icon"></mat-icon
      ></ng-container>
      <ng-template #customIconTemplate>
        <mat-icon class="material-icons-round custom-upload-icon">{{ uploadIcon }}</mat-icon>
      </ng-template>
    </ptg-button>

    <input
      *ngIf="!controlField?.value && fileLabel; else inputFileControl"
      class="file-name {{ class }}"
      [value]="fileLabel"
      [title]="fileLabel"
      disabled
      role="presentation"
      aria-disabled="true"
    />
    <ng-template #inputFileControl>
      <input readonly class="file-name {{ class }}" [formControl]="controlField" [title]="controlField.value"
       aria-readonly="true" aria-label="uploaded files" /> 
    </ng-template>

    <!-- <ptg-button
      *ngIf="controlField?.value || fileLabel"
      buttonName="Remove"
      classInput="remove-file"
      (clickButton)="fnButtonRemove()"
    >
      <mat-icon class="ignore-row">delete_forever</mat-icon>
    </ptg-button> -->
  </div>

  <button
    mat-raised-button
    type="button"
    (click)="selectFile()"
    [disabled]="isDisabled"
    [ngStyle]="{ backgroundColor: '#2d6c71', color: '#fff' }"
  >
    {{ buttonLabel ? buttonLabel : 'Browse' }}
  </button>
  <button
    *ngIf="!noUpload"
    mat-raised-button
    type="button"
    (click)="uploadFile()"
    [ngStyle]="{ backgroundColor: '#2d6c71', color: '#fff', width: '89px' }"
  >
    Upload
  </button>
</div>

<div *ngFor="let file of filesSelected;" class="document-selected-container">
  <div class="document-selected-item">
    <div class="label">
      <mat-icon>upload_file</mat-icon>
      {{file.name}}
    </div>
    <div class="button-container">
      <button aria-label="remove" type="button" (click)="removeFile(file)" mat-button class="btn-close">
        <mat-icon class="icon-close">close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="file.isExist" class="errorMessage">
    {{file.name}} already exists.
  </div>
</div>
<mat-error *ngIf="hasError">
  {{ errMsg }}
</mat-error>
<mat-error *ngIf="!hasError && errDuplicated && isDuplicated">
  {{ errDuplicated }}
</mat-error>
  <!-- <p class="type-upload">
    {{ typeFile }}
  </p>
  <p class="error-msg" *ngIf="isRequired && !fileSelected && !controlField.value && !firstTouch">
    {{ errRequired }}
  </p>
  <p class="error-msg" *ngIf="!hasError && errLength && controlField.value && controlField.value.length > 50">
    {{ errLength }}
  </p>
  <p class="error-msg" *ngIf="!hasError && errDuplicated && controlField.value && isDuplicated">
    {{ errDuplicated }}
  </p>
  <p class="error-msg" *ngIf="controlField.getError('inValidAsync')">
    {{ errorAsync || controlField.getError('inValidAsync') }}
  </p> -->
