<div class="header-detail-container">
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    <div class="flex flex-col p-6 gap-6" *ngIf="!isLoading">
        <ptg-banner
          *ngIf="bannerType"
          [(bannerType)]="bannerType"
          [message]="message"
        ></ptg-banner>

        <div class="flex justify-start gap-3 wrap-btn">
          <ptg-button
            type="submit"
            classInput="primary"
            [isDisabled]="!isChanged"
            (click)="onSubmit()"
          >
            Save
          </ptg-button>
          <ptg-button
            type="button"
            classInput="tertiary"
            (click)="onCancel()"
          >
            Cancel
          </ptg-button>
        </div>

        <div class="form-control-container">
          <div class="flex gap-5">
            <div class="left-controls flex">
              <div class="detail mt-3">
                <span class="label">Benefit Type</span>
                <span class="value">
                  {{headerDetailData?.benefitName}}
                </span>
              </div>
              <div class="detail mt-3">
                <span class="label">Entity</span>
                <span class="value">
                  {{headerDetailData?.entityName}}
                </span>
              </div>
          </div>
        </div>

        <div class="flex gap-5 mt-3">
          <div class="flex-1">
            <div class="box-info">
              <div class="box-info-header">Add Property</div>
              <div class="box-info-content">
                <div class="form-container">
                  <ptg-select
                    [isOptionObj]="true"
                    [isSetMaxWidthSelect]="true"
                    [listData]="availablePropertyConfigs"
                    [controlField]="addPropertyForm.get('propertyName')"
                    placeholder="Property Name"
                    [isMultipleLineOption]="true"
                    panelClass="property-name-dropdown"
                    (changeOptionValue)="changeProperty()"
                  ></ptg-select>
                  <ptg-radio-button *ngIf="propertyOptions?.length"
                    [controlField]="addPropertyForm.get('option')"
                    [listOption]="$any(propertyOptions)"
                    [isObject]="true"
                  ></ptg-radio-button>
                  <ptg-textbox
                    [controlField]="addPropertyForm.get('propertyLabel')"
                    errorAsync="Label already exists."
                    [hasLabel]="true"
                    customError="duplicatedValue"
                    placeholder="Label"
                    [maxLength]="250"
                  ></ptg-textbox>
                  <ptg-button
                    buttonName="Add Property"
                    classInput="add-button"
                    type="button"
                    (clickButton)="addProperty()"
                  >
                    <mat-icon>add</mat-icon>
                  </ptg-button>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-1">
            <div class="box-info">
              <div class="box-info-header">Order Properties</div>
              <div class="box-info-content">
                <ptg-grid
                  #gridLeftCard
                  [data]="leftCard"
                  [columns]="configuredPropertyColumns"
                  [paginable]="false"
                  [notFoundMessage]="''"
                  [allowDragDrop]="true"
                  [hideHeader]="true"
                  [inlineEditable]="true"
                  [softDeletable]="true"
                  (softDelete)="onSoftDeleteSectionConfig()"
                  (change)="onChangeData()"
                  (cancelInlineEdit)="onCancelChangeData()"
                >
                  <ng-template cellContent columnName="name" let-row>
                    <div class="multiple-line-text">
                      <span class="caption truncate">{{ row.name }}</span>
                      <span class="description">{{ row.propertyName }}</span>
                    </div>
                  </ng-template>
                </ptg-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="loading-table" *ngIf="isLoading">
      <div class="wrap-item">
          <mat-progress-spinner diameter="50" mode="indeterminate">
          </mat-progress-spinner>
          <p>Loading...</p>
      </div>
    </div>
  </div>
