import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { InstructionHistory, PaymentTab } from '../../services/models';
import { PaymentInfoAdjustmentType, TabPaymentInfo } from '../../types/enums';
import { Subject } from 'rxjs';
import { handlePaymentInfoTabLoadingState } from '../../types/constants/payment-info-tab.constant';
import { Store, select } from '@ngrx/store';
import * as fromPayeeDetail from '../../store/reducers/index';
import { clearGetPaymentAnnualTotals, getPaymentAnnualTotalsAction } from '../../store/actions/payee-detail.actions';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { getPaymentAnnualTotalsState } from '../../store/selectors/selector';
import {
  PaymentAnnualTotalsParams,
  PaymentAnnualTotalsRequest,
  PaymentAnnualTotalsResponse,
} from '../../services/models/payment-annual-totals.model';
import { deepClone } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-payment-annual-totals',
  templateUrl: './payment-annual-totals.component.html',
  styleUrls: ['./payment-annual-totals.component.scss'],
})
export class PaymentAnnualTotalsComponent implements OnInit, OnChanges, OnDestroy {
  readonly TabPaymentInfo = TabPaymentInfo;
  unsubscribe$ = new Subject<void>();
  isLoading = true;
  isShowPaymentDetail: boolean = false;
  annualTotals: PaymentAnnualTotalsResponse | undefined;
  hasAmounts = false;
  @Input() selectedRow!: PaymentTab & InstructionHistory;
  @Input() memberId: string = '';
  constructor(private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow) {
      this.getAnnualTotals();
    }
  }

  ngOnInit(): void {
    this.selectPaymentAnnualTotal();
    handlePaymentInfoTabLoadingState(this, this.payeeDetailStore);
  }

  selectPaymentAnnualTotal(): void {
    this.payeeDetailStore
      .pipe(
        select(getPaymentAnnualTotalsState),
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        if (res?.success) {
          this.payeeDetailStore.dispatch(clearGetPaymentAnnualTotals());
          this.annualTotals = deepClone(res?.payload);
          this.hasAmounts = this.checkAmountsExist(deepClone(res?.payload ?? ({} as PaymentAnnualTotalsResponse)));
        }
      });
  }

  getAnnualTotals(): void {
    if (!this.selectedRow) {
      return;
    }

    const request: PaymentAnnualTotalsRequest = {
      paymentInstructionId: this.selectedRow.id,
    };

    const params: PaymentAnnualTotalsParams = {
      paymentInstructionHistoryId: this.selectedRow.paymentInstructionHistoryId,
      memberId: this.memberId,
      ...(this.selectedRow.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment && {
        payrollBenefitAdjustmentId: this.selectedRow.id,
      }),
    };
    this.payeeDetailStore.dispatch(getPaymentAnnualTotalsAction({ request, params }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  controlToggle() {
    this.isShowPaymentDetail = !this.isShowPaymentDetail;
  }

  checkAmountsExist(item: PaymentAnnualTotalsResponse): boolean {
    if (!item.deductionItems && !item.earningItems) {
      return false;
    }

    const hasAmountOfDeduction = (item.deductionItems ?? []).some((deduction) => !!deduction.amount);
    const hasAmountOfEarning = (item.earningItems ?? []).some((earning) => !!earning.amount);

    return hasAmountOfDeduction || hasAmountOfEarning;
  }
}
