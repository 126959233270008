import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { GetPayrollSettingsResponse } from '../../services/models';
import { PayrollSettingsService } from '../../services';
import { getPayrollSettingsAction, getPayrollSettingsSuccessAction, getPayrollSettingsFailureAction } from '../actions';

@Injectable()
export class PayrollSettingsEffect {
  constructor(
    private actions$: Actions,
    private payrollSettingsService: PayrollSettingsService,
  ) {}

  getPayrollSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayrollSettingsAction),
      switchMap(({ request }) =>
        this.payrollSettingsService.getPayrollSettings(request).pipe(
          map((response: GetPayrollSettingsResponse) => getPayrollSettingsSuccessAction({ response })),
          catchError((error) => of(getPayrollSettingsFailureAction({ error }))),
        ),
      ),
    ),
  );
}
