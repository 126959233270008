import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';

import * as LookupTableActions from '../../store/actions/lookup-table.actions';
import { LookupTableDetail } from '../../types/models';
import * as fromMember from '../../store/reducers';
import { LookupTableService } from '../../services/lookup-table.service';
import { isEmpty } from '@ptg-shared/utils/string.util';

@Component({
  selector: 'ptg-edit-lookup-table',
  templateUrl: './edit-lookup-table.component.html',
  styleUrls: ['./edit-lookup-table.component.scss'],
})
export class EditLookupTableComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditLookupTableComponent>,
    @Inject(MAT_DIALOG_DATA) public lookupTableDetail: LookupTableDetail | null,
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public lookupTableService: LookupTableService
  ) {}

  ngOnInit(): void {
    this.initFormGroup(this.lookupTableDetail || ({} as LookupTableDetail));
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup(formData: LookupTableDetail) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData.id),
      name: this.fb.control(
        formData.name,
        [Validators.required, Validators.maxLength(150)],
        checkApiValidator(
          this.lookupTableService.checkExistLookupTable,
          'name',
          this.lookupTableDetail?.name,
          {
            ignoreCase: true,
            params: { id: formData.id },
          }
        )
      ),
      active: this.fb.control(
        isEmpty(formData.active) ? true : formData.active
      ),
    });
  }

  onSubmit() {
    const lookupTableDetail = JSON.parse(
      JSON.stringify(this.editForm.value as LookupTableDetail)
    );
    if (lookupTableDetail.id) {
      this.memberStore.dispatch(
        LookupTableActions.updateLookupTable({ lookupTableDetail })
      );
    } else {
      this.memberStore.dispatch(
        LookupTableActions.createLookupTable({ lookupTableDetail })
      );
    }
    this.dialogRef.close();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
