<div class="dialog-container">
  <div class="header-title">Edit Benefits</div>
  <form
    *ngIf="editForm"
    class="edit-form"
    [formGroup]="editForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="input-container">
      <ptg-textbox
        class="text-box"
        [controlField]="amountCtrl"
        placeholder="Minimum Benefit Amount"
        [hasLabel]="true"
        [allowZero]="true"
        inputType="Currency"
        [min]="0.0"
        [max]="9999999999999.99"
        [maxLength]="16"
        errorMinMaxValue="Minimum Benefit Amount must be within the range of 0.00 - 9,999,999,999,999.99."
      ></ptg-textbox>
      <ptg-select
        class="text-box"
        placeholder="Relationship Type Lookup Table"
        [controlField]="lookupTableIdCtrl"
        [listData]="lookupTables"
        [isOptionObj]="true"
        [isDisabled] = "data.benefit?.isLinkedLookupTable"
      ></ptg-select>
      <div class="line-break"></div>
      <ptg-toggle-button
        [controlField]="isActiveQDROCtrl"
        [label]="qdroLabelName"
        (valueChange)="onChangeToggle($event)"
      ></ptg-toggle-button>
    </div>
    <div class="input-qdro" *ngIf="isActiveQDROCtrl?.value">
      <ptg-select
        placeholder="{{ qdroLabelName }} Benefit"
        [controlField]="qdroBenefitTypeIdCtrl"
        [listData]="qdroBenefitTypes"
        [isOptionObj]="true"
        errorRequire="{{ qdroLabelName }} Benefit is required."
      ></ptg-select>
      <ptg-select
        placeholder="{{ qdroLabelName }} Metadata Section"
        [controlField]="qdroSectionKeyCtrl"
        [listData]="qdroMetadatas"
        [isOptionObj]="true"
        errorRequire="{{ qdroLabelName }} Metadata Section is required."
      ></ptg-select>
    </div>

    <div class="row-button">
      <button mat-raised-button type="submit" class="submit-button">
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="onClickCancel()"
        class="cancel-button"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
