import { Option } from 'src/app/shared/controls/select/select.component';
import { CalculationDocumentType, DisabilityType } from './types/enums';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
export const MODULE_KEY = 'calculationModule';

export const CALCULATION_OPTION = {
  ByPass: 'byPass',
  Validation: 'validation',
  StepNameKey: 'stepName',
  StepLabelKey: 'stepLabel',
  SectionKey: 'section',
  CardKey: 'card',
  CalculationStepProperties: 'calculationStepProperties',
};

export const BOOLEAN_VALUE = {
  TrueValue: 'true',
  FalseValue: 'false',
};

export enum ParameterType {
  Input,
  Output,
}

export enum LinkedPropertyType {
  Property,
  Aggregation,
  EntityReference,
}

export enum BenefitType {
  BenefitHistory,
  BenefitDocument,
  BenefitDocumentHistory,
  BenefitAuditTrail,
  BenefitException,
}

export enum DisabilityTableType {
  DisabilityProperties,
  DisabilityDocuments,
  DisabilityException,
  DisabilityCalculationDocuments,
  DisabilityAuditTrails
}

export enum RefundProcessing {
  AuditTrail,
}

export const SURVIVOR_BANNER_MESSAGE = 'Survivor Benefit can only be initiated upon death status of participant';

export const POPUP_MESSAGE = {
  RemoveCalculationBenefitTitle: 'This is a destructive action',
  RemoveCalculationBenefitAlert:
    'Removing this benefit record will remove all data of it and a new benefit record should be initiated. Are you sure you want to proceed?',
  RemoveCalculationBenefitNotice: 'Only benefit with a status of "Initiated" can be removed.',
  RemoveDisabilityBenefitNotice:
    'Removing this disability benefit record will remove all data of it and a new benefit record should be initiated. Are you sure you want to proceed?',

  RemoveRefundBenefitNotice: 'Only benefit with a status of "Initiated" can be removed',
  RemoveRefundBenefitAlert: 'Are you sure to remove this Refund record?',
  RemoveCalculationQDROBenefitLink:
    'This Retirement Calculation cannot be removed since it had been linked to a {qdroLabelName} Benefit.',
};

export const CALCULATION_DOCUMENT_TYPE_OPTIONS: Option[] = [
  {
    value: CalculationDocumentType.CertificateOfEligibility,
    displayValue: 'Certificate of Eligibility',
  },
  {
    value: CalculationDocumentType.NoticeOfRetirement,
    displayValue: 'Notice of Retirement',
  },
  {
    value: CalculationDocumentType.MarriedCertificate,
    displayValue: 'Married Certificate',
  },
  {
    value: CalculationDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher',
  },
  {
    value: CalculationDocumentType.CalculationWorksheet,
    displayValue: 'Calculation worksheet',
  },
];

export const DISABILITY_DOCUMENT_TYPE_OPTIONS: Option[] = [
  {
    value: CalculationDocumentType.ActivityPrescription,
    displayValue: 'Activity Prescription',
  },
  {
    value: CalculationDocumentType.EmployerNote,
    displayValue: 'Employer Note',
  },
  {
    value: CalculationDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher',
  },
  {
    value: CalculationDocumentType.PayStub,
    displayValue: 'Pay Stub',
  },
  {
    value: CalculationDocumentType.MedicalExam,
    displayValue: 'Medical Exam',
  },
  {
    value: CalculationDocumentType.PhysicianAnnualLetter,
    displayValue: 'Physician Annual Letter',
  },
  {
    value: CalculationDocumentType.TaxStatement,
    displayValue: 'Tax Statement',
  },
];

export const DISABILITY_TERM_OPTION_LIST: Option[] = [
  {
    displayValue: DisabilityType[1],
    value: 1,
  },
  {
    displayValue: DisabilityType[0],
    value: 0,
  },
];

export const REFUND_DOCUMENT_TYPE_OPTIONS = [
  {
    value: CalculationDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher',
  },
];

export const BENEFIT_OPTION_FIELD = {
  CalculationAsOfDateField: 'Calculation As of Date',
  RecalculationReasonField: 'Recalculation Reason',
};

export const UPLOAD_DOCUMENT_RADIO_LIST: RadioOption[] = [
  { value: false, label: 'Existing Document' },
  { value: true, label: 'New Document' },
];
