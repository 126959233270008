import { CdkDragDrop, CdkDragStart } from "@angular/cdk/drag-drop";

export const DEFAULT_KEY_COLUMN = 'id';
export const SELECTION_COLUMN = 'selection';
export const RADIO_COLUMN = 'radio';
export const DRAG_DROP_COLUMN = 'dragdrop';
export const ACTION_COLUMN = 'action';
export const GHOST_COLUMN = 'ghost';
export const DEFAULT_CURRENT_ROW_INDEX = -1;
export const DEFAULT_NOT_FOUND_MSG = 'Data Not Found';
export const STOP_PROPAGATION_CLASS = 'stop-propagation';
export const EXPAND_COLLAPSE_COLUMN = 'expand-collapse-action';
export const DEFAULT_LOADING_MESSAGE = 'Loading...';

export const MULTI_DRAG = {
  // Adjusts clicked items that have ".grp-row-selected" to organize together
  dragList: [] as any[],
  dragListCopy: [] as any[],
  dragErase: Symbol('DragErase') as any,

  dragStarted(e: CdkDragStart) {
    if (e.source.element.nativeElement.classList.contains("grp-row-selected")) {
      let listData = e.source.dropContainer.data;
      this.dragList = [];
      this.dragListCopy = [...listData];

      // dragged element
      const id = e.source.element.nativeElement.dataset.id;
      let DOMdragEl = document.querySelectorAll("[data-id='" + id + "'].cdk-drag-placeholder")[0];

      let DOMcontainer = Array.from(DOMdragEl.parentElement!.children);
      let DOMdragElIndex = DOMcontainer.indexOf(DOMdragEl);
      let allSelected = document.querySelectorAll(".grp-row-selected");

      // Goes through all ".grp-row-selected"
      allSelected.forEach((eli) => {
        if (eli.parentNode !== document.body) {
          // get index of current element
          let CurrDOMelIndexi = DOMcontainer.indexOf(eli);

          // Add listData of current ".grp-row-selected" to dragList
          this.dragList.push(listData[CurrDOMelIndexi]);

          // Replaces current position in dragListCopy with "DragErase" (to erase exact position later)
          this.dragListCopy[CurrDOMelIndexi] = this.dragErase;

          // Put opacity effect (by CSS class ".hide") on elements (after starting Drag)
          if (DOMdragElIndex !== CurrDOMelIndexi) {
            eli.classList.add("hide");
          }
        }
      });
    }
  },

  dropListDropped(e: CdkDragDrop<any[]>, emitOutsideRowDropCallback: (() => void) | undefined) {
    if (!this.dragList.length) {
      return;
    }
    if (e.previousContainer === e.container) { 
      // If in the same container
      let posAdjust = e.previousIndex < e.currentIndex ? 1 : 0;
      this.dragListCopy.splice(e.currentIndex + posAdjust, 0, ...this.dragList);
      this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase));

      // Pass item by item to final list
      for (let i = 0; i < e.container.data.length; i++) {
        e.container.data[i] = this.dragListCopy[i];
      }
    }
    else
    {
      // If in different containers
      // remove the "DragErase" from the list
      this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase));

      // Pass item by item to initial list
      for (let i = 0; i < e.previousContainer.data.length; i++) {
        e.previousContainer.data[i] = this.dragListCopy[i];
      }
      for (let i = 0; i < this.dragList.length; i++) {
        e.previousContainer.data.pop();
      }

      let currentIndex = e.currentIndex;

      // Merge if existed current parent in container
      const parentId = this.dragList?.[0]?.parentId ?? this.dragList?.[0]?.id;
      const lastIndexOfSubRow = e.container.data.map(x => x.parentId).lastIndexOf(parentId);
      if (lastIndexOfSubRow > -1) {
        this.dragList = this.dragList.filter(x => !x.isParentRow);
        currentIndex = lastIndexOfSubRow + 1;
      }

      let otherListCopy = [...e.container.data];
      otherListCopy.splice(currentIndex, 0, ...this.dragList);

      // Pass item by item to final list
      for (let i = 0; i < otherListCopy.length; i++) {
        const item = otherListCopy[i];
        if (this.dragList.some((el) => el.id === item.id)) {
          item.parentDivId = '#' + e.container.id;
        }
        item.isHideOnDragParent = false;
        e.container.data[i] = item;
      }

      if (emitOutsideRowDropCallback) {
        emitOutsideRowDropCallback();
      }
    }

    // Remove ".hide"
    let allHidden = document.querySelectorAll(".hide");
    allHidden.forEach((el) => {
      el.classList.remove("hide");
    });
    // Remove ".grp-row-selected"
    setTimeout(() => {
      let allSelected = document.querySelectorAll(".grp-row-selected");
      allSelected.forEach((el) => {
        el.classList.remove("grp-row-selected", "last");
      });
    }, 300);

    this.dragListCopy = [];
    this.dragList = [];
  },
}
