import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import {
  PayeeDetailState,
  clearGetInitialPaymentConfigurationsStateAction,
  getInitialPaymentConfigurationsAction,
  getInitialPaymentConfigurationsSelector,
  setInitialPaymentConfigurationsAction,
} from '../../store';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberNavigationResponse } from '@ptg-member/types/models';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { PaymentInstructionDetailComponentService } from '../payment-instruction-detail/payment-instruction-detail.component.service';

@Component({
  selector: 'ptg-initial-payment-configuration',
  templateUrl: './initial-payment-configuration.component.html',
  styleUrls: ['./initial-payment-configuration.component.scss'],
  providers:[PaymentInstructionDetailComponentService]
})
export class InitialPaymentConfiguration extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [];
  configurationForm!: FormGroup;

  menuId: string = '';
  viewId: string = '';
  id: string = '';
  extendsUrl?: string;
  isLoading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private payeeDetailStore: Store<PayeeDetailState>,
    public dialog: MatDialog,
    private router: Router,
    public route: ActivatedRoute,
    private readonly paymentInstructionDetailComponentService: PaymentInstructionDetailComponentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.onInitForm();
    this.getRouteData();

    this.getInitPaymentConfig();

    this.selectInitPaymentConfig();
  }

  getRouteData() {
    const params = this.route.snapshot.params;
    const queryParams = this.route.snapshot.queryParams;
    this.menuId = params.menuId;
    this.id = params.id;
    this.viewId = params.viewId;
    this.extendsUrl = this.paymentInstructionDetailComponentService.getQueryParams(queryParams);
    this.getViewName();
  }

  getViewName() {
    this.payeeDetailStore
      .select(fromLayoutReducer.selectMemberNavigationListState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((menu) => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] = menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });
          const menuName = menuItems?.find((item: any) => item?.id === this.menuId)?.name;

          this.listBreadcrumbs[0] = {
            name: menuName ?? '',
          };

          this.listBreadcrumbs = [
            {
              name: menuName ?? '',
              url: `/member/system-view/payment-information/${this.menuId}/${this.viewId}/${this.id}${this.extendsUrl}`,
            },
            {
              name: 'Initial Payment Configuration',
            },
          ];
        }
      });
  }

  onInitForm() {
    this.configurationForm = this.fb.group({
      configValue: this.fb.control(false),
    });
  }

  get configValueControl(): FormControl {
    return this.configurationForm?.get('configValue') as FormControl;
  }

  getInitPaymentConfig() {
    this.payeeDetailStore.dispatch(getInitialPaymentConfigurationsAction());
  }

  selectInitPaymentConfig() {
    this.payeeDetailStore
      .select(getInitialPaymentConfigurationsSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        const combineInitialPayment = res?.payload?.combineInitialPayment ?? false;
        this.configValueControl.setValue(combineInitialPayment);
        this.payeeDetailStore.dispatch(clearGetInitialPaymentConfigurationsStateAction());
      });
  }

  setInitPaymentConfig() {
    const configValue = this.configValueControl.value;
    this.payeeDetailStore.dispatch(setInitialPaymentConfigurationsAction({ request: { configValue } }));

    this.navigateToPaymentInfo();
  }

  navigateToPaymentInfo() {
    this.router.navigateByUrl(`/member/system-view/payment-information/${this.menuId}/${this.viewId}/${this.id}${this.extendsUrl}`);
  }

  onSave() {
    this.setInitPaymentConfig();
  }

  onCancel() {
    const ALERT_MESSAGE = 'Are you sure you want to cancel any/all changes?';
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.ConfirmWithDontShowAgain,
        title: 'Cancel Action',
        cancelButtonTitle: 'No',
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.navigateToPaymentInfo();
      }
    });
  }
}
