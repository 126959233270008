import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-input-color',
  templateUrl: './input-color.component.html',
  styleUrls: ['./input-color.component.scss']
})
export class InputColorComponent implements OnInit {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;
  
  colorCode = '';
  inputColor$ = new BehaviorSubject('#');

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() errorInvalid?: string;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  @ViewChild('color') color!: ElementRef;
  @ViewChild('inputText') inputText!: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.colorCode = this.controlField.value;
    this.inputColor$.pipe(debounceTime(500)).subscribe(el => {
      if (!el.includes('#')) {
        this.controlField.setValue('#' + this.controlField.value);
      }
      this.colorCode = this.controlField.value;
      setTimeout(() => {
        let valueDom = this.rgbToHex(this.color.nativeElement.style.backgroundColor).toUpperCase();
        if (valueDom !== this.controlField.value.toUpperCase()) {
          this.controlField.setErrors({wrongFormat: true});
          this.colorCode = '';
        } else {
          (this.controlField as FormControl).setErrors(null);
        }
      }, 100)
    });
  }

  colorPickerChange(event: any) {
    if (this.controlField.value.length < 7) {
      return;
    }
    this.controlField.setValue(event.toUpperCase());
  }

  focusInput() {
    this.inputText.nativeElement.focus();
  }

  inputManual() {
    this.inputColor$.next(this.controlField.value);
  }

  cpSliderDragEnd(event: any) {
    this.controlField.setValue(event.color.toUpperCase());
  }

  private componentToHex(c: number) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  
  private rgbToHex(rgb: string) {
    let temp = rgb.substring(4, rgb.length - 1);
    let [r, g, b] = temp.split(', ');
    return "#" + this.componentToHex(Number(r)) + this.componentToHex(Number(g)) + this.componentToHex(Number(b));
  }
}
