import { createAction, props } from '@ngrx/store';
import { EntityPropertyDisplayConfig, EntityPropertyDisplayConfiguration, EntityListViewConfigurations, EntityPropertyDisplayResponse, QueryEntityProperty } from '../../services/models';
import { GetCardSelectionResponse, PropertyDisplayResponse, QueryCardSelection, QueryProperty } from '@ptg-member/types/models';

export const ClearParticipantListConfiguration = '[ParticipantListConfiguration] Clear';
export const GetEntityPropertyListRequest = '[GetEntityPropertyList/API] Send Request';
export const GetEntityPropertyListSuccess = '[GetEntityPropertyList/API] Success';
export const GetEntityPropertyListFailure = '[GetEntityPropertyList/API] Failure';
export const GetParticipantListConfigurationRequest =
  '[GetParticipantListConfiguration/API] Send Request';
export const GetParticipantListConfigurationSuccess =
  '[GetParticipantListConfiguration/API] Success';
export const GetParticipantListConfigurationFailure =
  '[GetParticipantListConfiguration/API] Failure';
export const SetParticipantListConfigurationRequest =
  '[SetParticipantListConfiguration/API] Send Request';
export const SetParticipantListConfigurationSuccess =
  '[SetParticipantListConfiguration/API] Success';
export const SetParticipantListConfigurationFailure =
  '[SetParticipantListConfiguration/API] Failure';

export const GetEntityCardSelectionRequest = '[GetEntityCardSelection/API] Send Request';
export const GetEntityCardSelectionSuccess = '[GetEntityCardSelection/API] Success';
export const GetEntityCardSelectionFailure = '[GetEntityCardSelection/API] Failure';

export const GetEntityPropertyByCardSelectionRequest = '[GetEntityPropertyByCardSelection/API] Send Request';
export const GetEntityPropertyByCardSelectionSuccess = '[GetEntityPropertyByCardSelection/API] Success';
export const GetEntityPropertyByCardSelectionFailure = '[GetEntityPropertyByCardSelection/API] Failure';

export const GetEntityPropertyOfParticipantsRequest = '[GetEntityPropertyOfParticipants/API] Send Request';
export const GetEntityPropertyOfParticipantsSuccess = '[GetEntityPropertyOfParticipants/API] Success';
export const GetEntityPropertyOfParticipantsFailure = '[GetEntityPropertyOfParticipants/API] Failure';


export const clearParticipantListConfigurationState = createAction(
  ClearParticipantListConfiguration
);
export const getEntityPropertyList = createAction(
  GetEntityPropertyListRequest,
  props<{ entityId: string, screenId?: string, query?: QueryEntityProperty }>()
);
export const getEntityPropertyListSuccess = createAction(
  GetEntityPropertyListSuccess,
  props<{ propertyConfigs: EntityPropertyDisplayConfig[], fixPersonProperties: EntityPropertyDisplayConfig[] }>()
);
export const getEntityPropertyListFailure = createAction(
  GetEntityPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const getParticipantListConfiguration = createAction(
  GetParticipantListConfigurationRequest,
  props<{ entityId: string}>()
);
export const getParticipantListConfigurationSuccess = createAction(
  GetParticipantListConfigurationSuccess,
  props<{ propertyDisplayConfigurations: EntityPropertyDisplayConfiguration[] }>()
);
export const getParticipantListConfigurationFailure = createAction(
  GetParticipantListConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const setParticipantListConfiguration = createAction(
  SetParticipantListConfigurationRequest,
  props<{ entityId: string, body: EntityListViewConfigurations }>()
);
export const setParticipantListConfigurationSuccess = createAction(
  SetParticipantListConfigurationSuccess
);
export const setParticipantListConfigurationFailure = createAction(
  SetParticipantListConfigurationFailure,
  props<{ errorMsg: string }>()
);

export const getEntityPropertyOfParticipantsRequest = createAction(
  GetEntityPropertyOfParticipantsRequest,
  props<{ entityId: string, query: QueryEntityProperty }>()
);
export const getEntityPropertyOfParticipantsRequestSuccess = createAction(
  GetEntityPropertyOfParticipantsSuccess,
  props<{ propertyOfParticipants: EntityPropertyDisplayResponse }>()
);
export const getEntityPropertyOfParticipantsRequestFailure = createAction(
  GetEntityPropertyOfParticipantsFailure,
  props<{ error?: any }>()
);

export const getEntityCardSelectionRequest = createAction(
  GetEntityCardSelectionRequest,
  props<{ query: QueryCardSelection }>()
);
export const getEntityCardSelectionRequestSuccess = createAction(
  GetEntityCardSelectionSuccess,
  props<{ response: GetCardSelectionResponse }>()
);
export const getEntityCardSelectionRequestFailure = createAction(
  GetEntityCardSelectionFailure,
  props<{ error?: any }>()
);

export const getEntityPropertyByCardSelectionRequest = createAction(
  GetEntityPropertyByCardSelectionRequest,
  props<{ cardId: string}>()
);
export const getEntityPropertyByCardSelectionSuccess = createAction(
  GetEntityPropertyByCardSelectionSuccess,
  props<{ response: any }>()
);
export const getEntityPropertyByCardSelectionFailure = createAction(
  GetEntityPropertyByCardSelectionFailure,
  props<{ error?: any }>()
);

