import { createReducer, on } from '@ngrx/store';
import { RemittanceSubmissionActions } from '../actions';
import {
  BuybackMakeupRemittanceCalculatonResultResponse,
  GetCashJournalEntriesResponse,
  RemittanceFeesPayment,
  RemittanceSubmission,
  RemittanceUpdateResponse,
  ValidateRemittanceResponse,
  ValidateRemittanceSubmissionResponse,
} from '../models/remittance-submission.model';
import { STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  getBuybackMakeupRemittanceCalculationResultAction,
  getBuybackMakeupRemittanceCalculationResultSuccessAction,
  getBuybackMakeupRemittanceCalculationResultFailureAction,
  clearGetBuybackMakeupRemittanceCalculationResultStateAction,
  clearGetAnnualSupplementalRemittanceCalculationResultStateAction,
  getAnnualSupplementalRemittanceCalculationResultAction,
  getAnnualSupplementalRemittanceCalculationResultFailureAction,
  getAnnualSupplementalRemittanceCalculationResultSuccessAction,
  getRemittanceStatusAction,
  getRemittanceStatusFailureAction,
  getRemittanceStatusSuccessAction,
  clearGetRemittanceStatusStateAction,
  getCashJournalEntriesAction,
  getCashJournalEntriesSuccessAction,
  getCashJournalEntriesFailureAction,
  clearGetCashJournalEntriesStateAction,
} from '@ptg-employer/actions/remittance-submission.actions';

export const remittanceSubmissionFeatureKey = 'remittanceSubmission';

export interface State {
  remittanceSubmission?: BaseActionState<RemittanceSubmission>;
  editRemittance: {
    editRemittanceState: string;
    editType?: number;
    remittanceUpdateResponse?: RemittanceUpdateResponse;
    errorMsg?: string;
  };
  editRemittanceYear: string;
  editRemittanceParticipantState?: {
    state: string;
    remittanceParticipantId: string;
  };
  validateRemittanceResponse?: ValidateRemittanceResponse;
  removeRemittanceState: string;
  remittanceFeesPayment?: BaseActionState<RemittanceFeesPayment>;
  recordPaymentState: string;
  searchRemittanceParticipantResultState?: {
    isLoading: boolean;
    isError: boolean;
    searchRemittanceParticipantResult: any;
  };
  refundCreditState: string;
  recordPaymentForMunicipalityState: string;
  validateRemittanceSubmission?: BaseActionState<ValidateRemittanceSubmissionResponse>;
  getBuybackMakeupRemittanceCalculationResultState?: BaseActionState<BuybackMakeupRemittanceCalculatonResultResponse>;
  getAnnualSupplementalRemittanceCalculationResultState?: BaseActionState<RemittanceFeesPayment>;
  getRemittanceStatus?: BaseActionState<RemittanceSubmission>;
  getCashJournalEntries?: BaseActionState<GetCashJournalEntriesResponse>;
}

const initialState: State = {
  remittanceSubmission: undefined,
  editRemittance: {
    editRemittanceState: '',
    remittanceUpdateResponse: {} as RemittanceUpdateResponse,
    errorMsg: '',
  },
  editRemittanceYear: '',
  removeRemittanceState: '',
  recordPaymentState: '',
  refundCreditState: '',
  recordPaymentForMunicipalityState: '',
  validateRemittanceSubmission: undefined,
  getBuybackMakeupRemittanceCalculationResultState: undefined,
  getAnnualSupplementalRemittanceCalculationResultState: undefined,
};

export const reducer = createReducer(
  initialState,
  on(RemittanceSubmissionActions.getRemittanceSubmissionRequest, (state, {}) => ({
    ...state,
    remittanceSubmission: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceSubmissionSuccess, (state, { remittanceSubmission, remittanceId }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      payload: {
        ...remittanceSubmission,
        id: remittanceId,
      },
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceSubmissionFailure, (state, { error }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      error,
      payload: undefined,
    },
  })),

  on(RemittanceSubmissionActions.clearGetRemittanceSubmissionState, (state) => ({
    ...state,
    remittanceSubmission: undefined,
  })),

  on(RemittanceSubmissionActions.editRemittanceSuccess, (state, { editType, remittanceUpdateResponse }) => ({
    ...state,
    editRemittance: {
      editRemittanceState: STATE.SUCCESS,
      remittanceUpdateResponse,
      editType,
    },
  })),

  on(RemittanceSubmissionActions.editRemittanceFailure, (state, { error, editType }) => {
    let errorMsg = '';
    if (error) {
      errorMsg = error;
    }
    return {
      ...state,
      editRemittance: {
        editRemittanceState: STATE.FAIL,
        errorMsg,
        editType,
      },
    };
  }),

  on(RemittanceSubmissionActions.clearRemittanceSubmissionState, (state) => ({
    ...state,
    editRemittance: {
      editRemittanceState: '',
      remittanceUpdateResponse: {} as RemittanceUpdateResponse,
    },
    removeRemittanceState: '',
  })),

  on(RemittanceSubmissionActions.editRemittanceParticipantSuccess, (state, { remittanceParticipantId }) => ({
    ...state,
    editRemittanceParticipantState: {
      state: STATE.SUCCESS,
      remittanceParticipantId,
    },
  })),
  on(RemittanceSubmissionActions.editRemittanceParticipantFailure, (state, { remittanceParticipantId }) => ({
    ...state,
    editRemittanceParticipantState: {
      state: STATE.FAIL,
      remittanceParticipantId,
    },
  })),
  on(RemittanceSubmissionActions.clearRemittanceParticipantState, (state) => ({
    ...state,
    editRemittanceParticipantState: undefined,
  })),

  on(RemittanceSubmissionActions.validateRemittanceParticipant, (state, {}) => ({
    ...state,
    validateRemittanceResponse: undefined,
  })),
  on(RemittanceSubmissionActions.validateRemittanceParticipantSuccess, (state, { validateRemittanceResponse }) => ({
    ...state,
    validateRemittanceResponse,
  })),
  on(RemittanceSubmissionActions.validateRemittanceParticipantFailure, (state, { errorMsg }) => ({
    ...state,
    validateRemittanceResponse: undefined,
  })),

  on(RemittanceSubmissionActions.clearValidateRemittanceParticipant, (state) => ({
    ...state,
    validateRemittanceResponse: undefined,
  })),

  on(RemittanceSubmissionActions.removeRemittanceSuccess, (state, {}) => ({
    ...state,
    removeRemittanceState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.removeRemittanceFailure, (state, { errorMsg }) => ({
    ...state,
    removeRemittanceState: STATE.FAIL,
  })),

  on(RemittanceSubmissionActions.clearRemittanceFeesPaymentState, (state) => ({
    ...state,
    remittanceFeesPayment: undefined,
  })),
  on(RemittanceSubmissionActions.getRemittanceFeesPaymentRequest, (state) => ({
    ...state,
    remittanceFeesPayment: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceFeesPaymentSuccess, (state, { remittanceFeesPayment }) => ({
    ...state,
    remittanceFeesPayment: {
      isLoading: false,
      success: true,
      payload: remittanceFeesPayment,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceFeesPaymentFailure, (state) => ({
    ...state,
    remittanceFeesPayment: {
      isLoading: false,
      success: false,
    },
  })),
  on(RemittanceSubmissionActions.recordPaymentSuccess, (state) => ({
    ...state,
    recordPaymentState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.recordPaymentFailure, (state) => ({
    ...state,
    recordPaymentState: STATE.FAIL,
  })),
  on(RemittanceSubmissionActions.clearRecordPaymentState, (state) => ({
    ...state,
    recordPaymentState: '',
  })),
  on(RemittanceSubmissionActions.searchRemittanceParticipantRequest, (state, { }) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: true,
      isError: false,
      searchRemittanceParticipantResult: undefined,
    },
  })),
  on(RemittanceSubmissionActions.searchRemittanceParticipantSuccess, (state, { searchRemittanceParticipantResult }) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: false,
      isError: false,
      searchRemittanceParticipantResult
    }
  })),
  on(RemittanceSubmissionActions.searchRemittanceParticipantFailure, (state) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: false,
      isError: true,
      searchRemittanceParticipantResult: undefined,
    },
  })),
  on(RemittanceSubmissionActions.clearSearchRemittanceParticipantState, (state) => ({
    ...state,
    searchRemittanceParticipantResultState: {
      isLoading: false,
      isError: false,
      searchRemittanceParticipantResult: undefined,
    },
  })),

  on(RemittanceSubmissionActions.getRemittanceParticipantRequest, (state, { }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: true
    },
  })),

  on(RemittanceSubmissionActions.getRemittanceParticipantSuccess, (state, { remittanceSubmissionParticipant }) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      payload: {
        ...state.remittanceSubmission?.payload,
        remittance: {
          ...state.remittanceSubmission?.payload?.remittance,
          remittanceParticipants: [remittanceSubmissionParticipant],
        } as any,
      } as any,
    },
  })),
  on(RemittanceSubmissionActions.getRemittanceParticipantFailure, (state) => ({
    ...state,
    remittanceSubmission: {
      ...state.remittanceSubmission,
      isLoading: false,
      payload: {
        ...state.remittanceSubmission?.payload,
        remittance: {
          ...state.remittanceSubmission?.payload?.remittance,
          remittanceParticipants: [],
        } as any,
      } as any,
    },
  })),
  on(RemittanceSubmissionActions.refundCreditSuccess, (state) => ({
    ...state,
    refundCreditState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.refundCreditFailure, (state) => ({
    ...state,
    refundCreditState: STATE.FAIL,
  })),
  on(RemittanceSubmissionActions.clearRefundCreditState, (state) => ({
    ...state,
    refundCreditState: '',
  })),
  on(RemittanceSubmissionActions.recordPaymentForMunicipalitySuccess, (state) => ({
    ...state,
    recordPaymentForMunicipalityState: STATE.SUCCESS,
  })),
  on(RemittanceSubmissionActions.recordPaymentForMunicipalityFailure, (state) => ({
    ...state,
    recordPaymentForMunicipalityState: STATE.FAIL,
  })),
  on(RemittanceSubmissionActions.clearRecordPaymentForMunicipalityState, (state) => ({
    ...state,
    recordPaymentForMunicipalityState: '',
  })),
  on(RemittanceSubmissionActions.validateRemittanceSubmission, (state) => ({
    ...state,
    validateRemittanceSubmission: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionActions.validateRemittanceSubmissionSuccess, (state: State, { response }) => ({
    ...state,
    validateRemittanceSubmission: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(RemittanceSubmissionActions.validateRemittanceSubmissionFailure, (state: State, { error }) => ({
    ...state,
    validateRemittanceSubmission: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(RemittanceSubmissionActions.clearValidateRemittanceSubmissionState, (state: State) => ({
    ...state,
    validateRemittanceSubmission: undefined,
  })),

  // Get Buyback Makeup Remittance Calculation
  on(getBuybackMakeupRemittanceCalculationResultAction, (state) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: {
      isLoading: true,
    },
  })),
  on(getBuybackMakeupRemittanceCalculationResultSuccessAction, (state, { response }) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getBuybackMakeupRemittanceCalculationResultFailureAction, (state, { error }) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetBuybackMakeupRemittanceCalculationResultStateAction, (state) => ({
    ...state,
    getBuybackMakeupRemittanceCalculationResultState: undefined,
  })),

  // Get Annual Supplemental Remittance Calculation
  on(getAnnualSupplementalRemittanceCalculationResultAction, (state) => ({
    ...state,
    getAnnualSupplementalRemittanceCalculationResultState: {
      isLoading: true,
    },
  })),
  on(getAnnualSupplementalRemittanceCalculationResultSuccessAction, (state, { response }) => ({
    ...state,
    getAnnualSupplementalRemittanceCalculationResultState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getAnnualSupplementalRemittanceCalculationResultFailureAction, (state, { error }) => ({
    ...state,
    getAnnualSupplementalRemittanceCalculationResultState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAnnualSupplementalRemittanceCalculationResultStateAction, (state) => ({
    ...state,
    getAnnualSupplementalRemittanceCalculationResultState: undefined,
  })),

  on(getRemittanceStatusAction, (state) => ({
    ...state,
    getRemittanceStatus: {
      isLoading: true,
    },
  })),
  on(getRemittanceStatusSuccessAction, (state, { response }) => ({
    ...state,
    getRemittanceStatus: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRemittanceStatusFailureAction, (state, { error }) => ({
    ...state,
    getRemittanceStatus: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRemittanceStatusStateAction, (state) => ({
    ...state,
    getRemittanceStatus: undefined,
  })),

  on(getCashJournalEntriesAction, (state) => ({
    ...state,
    getCashJournalEntries: {
      isLoading: true,
    },
  })),
  on(getCashJournalEntriesSuccessAction, (state, { response }) => ({
    ...state,
    getCashJournalEntries: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCashJournalEntriesFailureAction, (state, { error }) => ({
    ...state,
    getCashJournalEntries: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCashJournalEntriesStateAction, (state) => ({
    ...state,
    getCashJournalEntries: undefined,
  })),
);
