import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const finalPaymentConfigurationSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.finalPaymentConfiguration
);

export const getFinalPaymentConfigurationsSelector = createSelector(
  finalPaymentConfigurationSelector,
  (state) => state?.getFinalPaymentConfigurations
);

export const setFinalPaymentConfigurationsSelector = createSelector(
  finalPaymentConfigurationSelector,
  (state) => state?.setFinalPaymentConfigurations
);

