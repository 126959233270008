import { createAction, props } from '@ngrx/store';

import { GetPaymentRunsResponse, GetPaymentRunsRequest } from '../../services/models';

export const getPaymentRunsAction = createAction(
  '[GetPaymentRuns/API] Send Request',
  props<{ request?: GetPaymentRunsRequest }>(),
);

export const getPaymentRunsSuccessAction = createAction(
  '[GetPaymentRuns/API] Success',
  props<{ response: GetPaymentRunsResponse }>(),
);

export const getPaymentRunsFailureAction = createAction('[GetPaymentRuns/API] Failure', props<{ error?: any }>());

export const clearGetPaymentRunsStateAction = createAction('[GetPaymentRuns] Clear');
