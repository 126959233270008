import { NgModule, ModuleWithProviders } from '@angular/core';
import { CalendarYearViewComponent } from './year-view/calendar-year-view.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    CalendarYearViewComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [
    CalendarYearViewComponent
  ]
})
export class CalendarViewModule { 
  public static forRoot(): ModuleWithProviders<CalendarViewModule> {
    return {
      ngModule: CalendarViewModule
    }
  }
}