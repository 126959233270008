import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import {
  PaymentInstructionDetailComponent
} from '@ptg-member/features/payee-detail/pages/payment-instruction-detail/payment-instruction-detail.component';
import { CheckFundService } from '@ptg-shared/services/check-fund.service';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: PaymentInstructionDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayeeDetailRoutingModule { }
