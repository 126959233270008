<div class="dialog-container" id="edit-value-dialog">
  <div class="header-title">
    {{ lookupTableDetail?.lookupTableValue?.id ? 'Edit Value' : 'Add Value' }}
  </div>

  <form (ngSubmit)="formSubmit$.next()" [formGroup]="editForm" class="edit-form">
    <div class="input-container">
      <ptg-checkbox
        stopPropagation
        [controlField]="editForm.get('active')"
        label="Active"
      ></ptg-checkbox>
      <ptg-textbox
        [controlField]="editForm.get('code')"
        [hasLabel]="true"
        [maxLength]="10"
        placeholder="ID"
        [ngClass]="{'has-error': editForm.get('code')?.touched && editForm.get('code')?.errors}"
      ></ptg-textbox>
      <ptg-textbox
        [controlField]="editForm.get('name')"
        [hasLabel]="true"
        [maxLength]="255"
        placeholder="Short Description"
        errorAsync="Short Description already exists."
      ></ptg-textbox>
      <ptg-input-area
        [controlField]="editForm.get('longName')"
        [hasLabel]="true"
        [maxLength]="500"
        [minRows]="3"
        [maxRows]="3"
        errorRequire="Long Description is required."
        errorMaxLength="Exceed the 500 characters limit."
        placeholder="Long Description"
      ></ptg-input-area>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
