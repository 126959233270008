<div class="add-payment-container">
  <ptg-title-bar
    name="Disability Payment"
    [onlyTitle]="true"
  ></ptg-title-bar>
  <ptg-overview-header-entity
    #overViewHeaderEntity
    [targetIdFromDialog]="memberId"
  ></ptg-overview-header-entity>

  <div class="data-container">
    <div class="button-group">
      <button mat-raised-button type="button" class="button button-add" (click)="onAddPayment()" [disabled]="!this.listRecordSelected.length">Add Payment</button>
      <button mat-raised-button type="button" class="button button-cancel" (click)="onCancel()">Cancel</button>
    </div>
    <ptg-grid
      #disabilityPaymentsList
      [data]="dataTable"
      [columns]="columns"
      [isLoading]="isLoading"
      [paginable]="false"
      [allowSelection]="true"
      [fitToParent]="true"
      [hideScrollbar]="false"
      (selectionChange)="onChangeSelection($event)"
      notFoundMessage="No Disability Payment to Display"
    >
      <ng-template cellContent columnName="disabilityFee" let-row>
        <ptg-number-decimal-column
          *ngIf="row.disabilityFee !== null && row.disabilityFee !== undefined; else noDisabilityFee"
          [value]="row.disabilityFee"
          [decimal]="2"
          unit="$"
          unitPosition="left"
        ></ptg-number-decimal-column>
        <ng-template #noDisabilityFee>
          {{ '-' }}
        </ng-template>
      </ng-template>
    </ptg-grid>
  </div>
</div>
