import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import {
  CreateEditEarningItemDetailsRequest,
  GetEarningFundingSourcesRequest,
  GetEarningFundingSourcesResponse,
  CreateValidateAdjustmentFundingSourcePeriodsRequest,
  CreateValidateAdjustmentFundingSourcePeriodsResponse,
  GetEarningFundingSourcesQueryParams,
  GetDeductionFundingSourcesResponse,
} from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class EditFundingSourceService {
  constructor(private httpClient: HttpClient) {}

  getEarningFundingSources(
    request: GetEarningFundingSourcesRequest,
    queryParams?: GetEarningFundingSourcesQueryParams,
  ): Observable<GetEarningFundingSourcesResponse> {
    let params = new HttpParams();
    if (queryParams) {
      const { isGetMainFundingSource, orderByType } = queryParams;
      params = params.append('IsGetMainFundingSource', isGetMainFundingSource);
      params = params.append('OrderByType', orderByType);
    }

    return this.httpClient.get<GetEarningFundingSourcesResponse>(
      `${environment.apiUrl}/new-payroll/earning-funding-source/${request.benefitTypeOptionId}`,
      { params },
    );
  }

  createEditEarningItemDetails(request: CreateEditEarningItemDetailsRequest): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { paymentId, benefitTypeOptionId, payrollEarningItemListDetail } = request;
    return this.httpClient.post<void>(
      `${environment.apiUrl}/new-payroll/earning-item/${paymentId}/benefit-option/${benefitTypeOptionId}/section`,
      {
        fundingSources: payrollEarningItemListDetail.fundingSources,
        adjustmentFundings: payrollEarningItemListDetail.adjustmentFundings,
      },
      { context },
    );
  }

  createValidateAdjustmentFundingSourcePeriods(
    request: CreateValidateAdjustmentFundingSourcePeriodsRequest,
  ): Observable<CreateValidateAdjustmentFundingSourcePeriodsResponse> {
    let params = new HttpParams();
    const { paymentId, benefitTypeOptionId } = request;
    params = params.append('startDate', request.startDate);
    params = params.append('endDate', request.endDate);
    return this.httpClient.get<CreateValidateAdjustmentFundingSourcePeriodsResponse>(
      `${environment.apiUrl}/new-payroll/earning-item/${paymentId}/benefit-option/${benefitTypeOptionId}/validate-adjustment-funding`,
      { params },
    );
  }
}
