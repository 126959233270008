import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CheckStepConfigurationExistsRequest,
  CreateStepConfigurationsRequest,
  GetBenefitTypeRequest,
  GetBenefitOptionsResponse,
  GetStepConfigurationPropertiesResponse,
  GetStepConfigurationResponse,
  GetStepConfigurationsRequest,
  GetStepConfigurationsResponse,
  SetStepConfigurationRequest,
  StepConfigurationValuesRequest,
  GetMemberEntitiesResponse,
  GetCardsResponse,
  GetCardsRequest, CheckStepConfigurationExistsResponse,
} from './models/step-configuration.model';
import { GetPropertyType } from '@ptg-member/constance/member-list.const';
import { SortType } from '@ptg-shared/constance';
import { isEmpty } from '@ptg-shared/utils/string.util';
import {AbstractControl, ValidationErrors} from "@angular/forms";
import {map, switchMap} from "rxjs/operators";
import {Option} from "@ptg-shared/controls/select/select.component";
import { BenefitCalculationErrorType } from '../types/enums';
@Injectable({
  providedIn: 'root',
})
export class StepConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getStepConfigurations(request: GetStepConfigurationsRequest): Observable<GetStepConfigurationsResponse> {
    const params = new HttpParams({ fromObject: request as any });
    return this.httpClient.get<GetStepConfigurationsResponse>(
      `${environment.apiUrl}/calculations/calculation-options/step-configurations`,
      { params },
    );
  }

  getBenefitTypes(request?: GetBenefitTypeRequest): Observable<GetBenefitOptionsResponse> {
    let params = new HttpParams();
    if (!isEmpty(request?.type)) {
      params = params.append('Type', request?.type ?? 0);
    }
    if (!isEmpty(request?.calculationId)) {
      params = params.append('CalculationId', request?.calculationId ?? '');
    }
    return this.httpClient.get<GetBenefitOptionsResponse>(`${environment.apiUrl}/calculations/benefit-options`, {
      params,
    });
  }

  checkStepConfigurationExists(request: CheckStepConfigurationExistsRequest): Observable<CheckStepConfigurationExistsResponse> {
    return this.httpClient.post<CheckStepConfigurationExistsResponse>(
      `${ environment.apiUrl }/calculations/calculation-options/step-configurations/existing`,
      request,
    );
  }

  createStepConfigurations(request: CreateStepConfigurationsRequest) {
    return this.httpClient.post(`${environment.apiUrl}/calculations/calculation-options/step-configurations`, request);
  }

  getStepConfiguration(stepConfigurationId: string, memberId: string): Observable<GetStepConfigurationResponse> {
    return this.httpClient.get<GetStepConfigurationResponse>(
      `${environment.apiUrl}/calculations/${memberId}/calculation-options/step-configurations/${stepConfigurationId}`,
    );
  }

  setStepConfiguration(request: SetStepConfigurationRequest) {
    return this.httpClient.put(`${environment.apiUrl}/calculations/calculation-options/step-configurations`, request);
  }

  getStepConfigurationByType(request: StepConfigurationValuesRequest): Observable<GetStepConfigurationResponse> {
    return this.httpClient.get<GetStepConfigurationResponse>(
      `${environment.apiUrl}/calculations/${request.memberId}/calculation-options/step-configurations/${request.benefitEntityId}`,
    );
  }

  removeStepConfiguration(stepConfigurationId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/calculations/calculation-options/step-configurations/${stepConfigurationId}`,
    );
  }

  checkAllowRemoveStepConfiguration(stepConfigurationId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/calculation-options/step-configurations/${stepConfigurationId}`,
    );
  }

  validateStepConfigExists = (benefitTypes: Option[], stepConfigurationId?: string, isInitiateScreen?: boolean) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }
      const selectedBenefitOptionName = benefitTypes.filter((item) => item.value === control.value)[0]?.displayValue;

      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.checkStepConfigurationExists({
              benefitEntityId: control.value,
              stepConfigurationId,
              isInitiateScreen
            }).pipe(
              map((response: any) => {
                if (!response?.isValid && response?.errorType === BenefitCalculationErrorType.ExistedBenefitOption) {
                  return { existsBenefitOption: `Step Configuration for ${selectedBenefitOptionName} already exists.` };
                }
                return null;
              }),
            ),
        ),
      );
    };
  };

  getMemberEntities(): Observable<GetMemberEntitiesResponse> {
    return this.httpClient.get<GetMemberEntitiesResponse>(`${environment.apiUrl}/entities/members/participant`);
  }

  getCards(request: GetCardsRequest): Observable<GetCardsResponse> {
    let params = new HttpParams({
      fromObject: {
        IncludeSystemCard: request.includeSystemCard,
        FilterByEntityIds: request.filterByEntityIds,
        SortNames: request.sortNames,
        SortType: request?.sortType ?? SortType.ASC,
      }
    });

    return this.httpClient.get<GetCardsResponse>(`${environment.apiUrl}/entity/cards`, { params });
  }
}
