import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EditDeductionsState } from '@ptg-member/features/payee-detail/store/reducers/edit-deductions.reducer';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EditDeductionsService } from '../../services';
import { GetTypesOfDeductionsResponse } from '../../services/models';
import {
  getTypesOfDeductionsAction,
  getTypesOfDeductionsFailureAction,
  getTypesOfDeductionsSuccessAction, setDeductionsAction, setDeductionsFailureAction, setDeductionsSuccessAction,
} from '../actions';

@Injectable()
export class EditDeductionsEffects {
  constructor(
    private actions$: Actions,
    private editDeductionsService: EditDeductionsService
  ) {}

  getTypesOfDeductions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTypesOfDeductionsAction),
      switchMap(({ request }) =>
        this.editDeductionsService.getTypesOfDeductions(request).pipe(
          map((response: GetTypesOfDeductionsResponse) => getTypesOfDeductionsSuccessAction({ response })),
          catchError((error) => of(getTypesOfDeductionsFailureAction({ error })))
        )
      )
    )
  );

  setDeductions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDeductionsAction),
      switchMap(({ paymentInstructionId, request, isChangeAmountOfPreTax }) =>
        this.editDeductionsService.setDeductions(paymentInstructionId, request).pipe(
          map(() => setDeductionsSuccessAction({ isChangeAmountOfPreTax })),
          catchError((error) => of(setDeductionsFailureAction({ error })))
        )
      )
    )
  );

}
