import { Action, combineReducers } from '@ngrx/store';

import { RegisterState, registerReducer } from './register.reducer';

export interface TransactionRegisterState {
  register: RegisterState;
}

export const transactionRegisterReducers = (state: TransactionRegisterState | undefined, action: Action) => {
  return combineReducers({ register: registerReducer })(state, action);
};

export * from './register.reducer';
