import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE, ENTITY_MEMBER_GUID, ENTITY_PARTICIPANT_GUID, ENTITY_PERSON_GUID, GUID_EMPTY, SortType } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BaseComponent } from '@ptg-shared/components';
import { deepClone, equalObjectByFields, lowercaseFirstCharacterKeys, sortByCriteria } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

import { clearCreateEntityDataStateAction, clearUpsertComponentListDataStateAction, createEntityDataAction, upsertComponentListDataAction } from '@ptg-entity-management/store/actions';
import { MemberState } from '@ptg-member/store/reducers';
import { EntityPropertyType, RelationshipOptions } from '@ptg-entity-management/types/enums';
import { CourtOrderQdroSelectedPayeeOriginalPersonName, EntityInitiationPropertyValue, EntityReferenceData, GetInitiationPropertiesResponse, InitiationProperty, LegalRelationshipTypeOption, UpsertEntityDataRequest, ValidateMaritalRequest, ValidateMaritalResponse } from '@ptg-entity-management/services/models';
import { EntityManagementState } from '@ptg-entity-management/store/reducers';
import { createEntityDataSelector, upsertComponentListDataSelector } from '@ptg-entity-management/store/selectors';
import { getInitiationPropertiesSelector } from '@ptg-entity-management/store/selectors';
import {
  clearGetInitiationPropertiesStateAction,
  getInitiationPropertiesAction,
} from '@ptg-entity-management/store/actions';
import { clearUpdateMemberCardStateAction, updateMemberCardAction } from '@ptg-member/store/actions';
import { updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';
import { GetListRelatedPersonQuery } from '@ptg-member/types/models';
import * as RelatedPersonActions from '@ptg-member/store/actions/related-person.action';
import { ALLOW_SHOW_DIVORCE_DATE_WITH_RELATIONSHIP_VALUES, ALLOW_SHOW_MARRIAGE_DATE_WITH_RELATIONSHIP_VALUES, ENTITY_MAPPING, LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID, RELATIONSHIP_LIST, RELATIONSHIP_MARITAL_INFORMATION_PROPERTIES, SHOULD_NOT_SHOW_NOTE_PROPERTIES_WHEN_EDIT_LIST_CARD_STAND_ALONE_PROPERTIES } from '@ptg-entity-management/constants/entity-mapping.constant';
import { CalculationState, getCalculationQDROSelector } from '@ptg-member/features/calculation/store';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';
import { AUDIT_TRAIL_PROPERTIES, LIST_INPUT_PERSON_NAME, PERSON_PROPERTY_MAPPING } from '@ptg-entity-management/constants';
import { ConfirmPopupWithDataComponent } from '@ptg-shared/controls/confirm-popup-with-data/confirm-popup-with-data.component';
import { EntityDataService } from '@ptg-entity-management/services';
import { DEFAULT_LOOKUPTABLE } from '@ptg-shared/constance/client-setting.const';
import { CourtOrderPropertiesGUIDs } from '../upsert-entity-data/upsert-entity-data-for-court-order.const';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { allItemsAreNullOrUndefinedOrEmptyString, isNullOrUndefinedOrEmptyString } from '@ptg-shared/utils/string.util';
import { NOTES_PROPERTY, RELATIONSHIP_BENEFICIARY_PROPERTIES, RELATIONSHIP_DEPENDENT_PROPERTIES, RELATIONSHIP_LIST_PROPERTY_FIXED_ID, RELATIONSHIP_POWER_OF_ATTOURNEY_PROPERTIES, SHOULD_NOT_SHOW_NOTE_PROPERTIES_IN_UPSERT_ENTITY_FLOW } from '@ptg-entity-management/constants/entity-mapping.constant';
import { SortByCompareType } from '@ptg-shared/utils/common.util.model';

@Component({
  selector: 'ptg-create-entity-data',
  templateUrl: './create-entity-data.component.html',
  styleUrls: ['./create-entity-data.component.scss'],
})
export class CreateEntityDataComponent extends BaseComponent {
  readonly EntityPropertyType = EntityPropertyType;
  entityProperties!: GetInitiationPropertiesResponse;
  cardData: any;
  cardInfor = {
    id: '',
    isSummaryView: false,
    isList: false,
  };
  title: string = '';
  entityId: string = '';

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isEditCard: boolean = false;
  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  qdroLabelName: string = '';
  entityIsEditPersonName: string = '';
  isSubmitting: boolean = false;
  isCourtOrder: boolean = this.data?.section?.isCourtOrder ?? false;

  // Use for Relationship List Card only
  isRelationshipCard: boolean = false;
  legalRelationshipTypeOptionList: LegalRelationshipTypeOption[] = [];
  legalRelationshipTypeChipList: LegalRelationshipTypeOption[] = [];
  initialRelationshipValueSelected: string = '';
  relationshipTypeSelected: RelationshipOptions | null = null;
  isLegalRelationshipTypeBeneficiarySelected: boolean = false;
  isLegalRelationshipTypeDependentSelected: boolean = false;
  isLegalRelationshipTypePowerOfAttorneySelected: boolean = false;
  isPayeeUsedInCourtOrder: boolean = this.data?.section?.isPayeeUsedInCourtOrder ?? false;
  isAtLeastOneRecordIsSpouse: boolean = this.data?.section?.isAtLeastOneRecordIsSpouse ?? false;
  // End of Use for Relationship List Card only

  isEnableNote: boolean = this.data?.section?.isEnableNote ?? this.data?.section?.enableNote ?? false;
  existNotePropertyAsStandAlonePropertyInListCard: boolean = this.data?.section?.existNotePropertyAsStandAlonePropertyInListCard ?? false;
  isEditListRecord: boolean = false;
  isEditStandAlonePropertiesFromSummaryView: boolean = !!this.data?.isEditStandAlonePropertiesFromSummaryView ?? false;
  isEditStandAlonePropertiesFromDetailView: boolean = !!this.data?.isEditStandAlonePropertiesFromDetailView ?? false;

  standAlonePropertyOrderInEditFormList: any[] = this.data?.standAlonePropertyOrderInEditFormList ?? [];

  constructor(
    private memberStore: Store<MemberState>,
    private entityManagementStore: Store<EntityManagementState>,
    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    private calculationStore: Store<CalculationState>,
    public dialogRef: MatDialogRef<CreateEntityDataComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private entityDataService: EntityDataService,
    private layoutService: LayoutService,
  ) {
    super();
  }

// FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  private getBenefitEntityId() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
        }
      });
  }

  private _getInitiationProperties() {
    const {
      section: { cardId, entityId, entityComponentId },
      isSummaryView,
      cardData,
      isAddRecord,
      isList,
      screenId,
    } = this.data;
    let cardName = this.data.detailViewLabel ? this.data.detailViewLabel : this.data?.section?.cardName;
    this.isEditCard = !isAddRecord;
    this.isEditListRecord = this.data?.isList && !isAddRecord;
    this.cardInfor = {
      id: cardId,
      isSummaryView: isSummaryView,
      isList: isList ? true : false,
    };
    if (cardData) {
      this.cardData = deepClone(cardData);
    }
    if (this.data.propertiesConfig) {
      this.entityProperties = {
        entityId,
        entityLabel: '',
        entityComponentId,
        properties: deepClone(this.data.propertiesConfig),
      };
      this.title = `${isAddRecord ? 'Add ' + cardName + ' Record' : 'Edit ' + cardName}`;
      this.entityId = entityId;
    } else {
      this.memberStore.dispatch(
        getInitiationPropertiesAction({
          entityId,
          cardId,
          isList: isList ? true : false,
          isSummaryView,
          screenId,
          includeEntitySystem: this.data?.section?.isCourtOrder ? true : false,
        }),
      );
      // Get the list of initiation properties
      this.memberStore
        .pipe(
          select(getInitiationPropertiesSelector),
          filter((state) => !!state),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((state) => {
          this.layoutService.showLoading = !!state?.isLoading;
          if (!state?.isLoading && state?.success && state?.payload && !this.entityProperties) {
            this.isRelationshipCard = state?.payload?.entityComponentId?.toUpperCase() === RELATIONSHIP_LIST.Id.toUpperCase();
            
            // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
            let _entityProperties: GetInitiationPropertiesResponse = deepClone(state.payload);
            _entityProperties.properties = _entityProperties.properties?.map((property) => ({
              ...property,
              options: property.options.map((option) => ({
                ...option,
                text: option.text?.includes(CalculationType[CalculationType.QDRO])
                  ? option.text?.replaceAll(CalculationType[CalculationType.QDRO], this.qdroLabelName)
                  : option.text
              })),
            }));
            this.entityProperties = _entityProperties;
            // this.entityProperties = deepClone(state.payload);

            // Case Edit Stand Alone Properties without any special logics,
            // Properties will be re-arrange follow latest logics of US 151165
            if (!this.data?.isList && (this.isEditStandAlonePropertiesFromSummaryView || this.isEditStandAlonePropertiesFromDetailView)) {
              this.reArrangeStandAlonePropertiesOrder();
            }

            // Case Add/Edit a List Record, without any special logics,
            // Properties will be re-arrange follow latest logics of US 151165
            if (this.data?.isList && this.isNotSpecialListRecordOrderLogics()) {
              this.reArrangeListRecordPropertiesOrder();
            }

            // Remove properties to serve Note logics
            this.handlePropertiesForServeNoteLogics();

            // Re-order properties to serve Relationship logics
            if (this.isRelationshipCard && this.data?.isList) {
              this.handlePropertiesForRelationshipCard();
            }

            // Re-order properties to serve Court Order logics
            if (this.isCourtOrder && this.data?.isList) {
              this.handlePropertiesForCourtOrder();
            }

            this.title = `${isAddRecord ? 'Add ' + cardName + ' Record' : 'Edit ' + cardName}`;
            this.entityId = entityId;
          }
          this.memberStore.dispatch(clearGetInitiationPropertiesStateAction());
        });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    this.getBenefitEntityId();
    this.getRelatedPersonList();
    this._upsertComponentListDataSelector();
    this._updateMemberCardSelector();
    this._updateEntityDataSelector();
    this._getInitiationProperties();
  }

  private _upsertComponentListDataSelector(): void {
    this.entityManagementStore
      .pipe(
        select(upsertComponentListDataSelector),
        takeUntil(this.unsubscribe$),
        finalize(() => { this.isSubmitting = false })
      )
      .subscribe((el) => {
        if (el) {
          this.layoutService.showLoading = !!el?.isLoading;
          if (el?.success) {
            this.dialogRef.close('submit');
          } else if (el.error) {
            this.dialogRef.close('submitFail');
          }

          this.memberStore.dispatch(clearUpsertComponentListDataStateAction());
        }
      });
  }

  private _updateMemberCardSelector(): void {
    this.memberStore.pipe(
      select(updateMemberCardSelector),
      takeUntil(this.unsubscribe$),
      finalize(() =>  { this.isSubmitting = false })
    ).subscribe((el) => {
      this._handleAfterUpdateEntityData(el, true);
    });
  }

  private _updateEntityDataSelector(): void {
    this.memberStore.pipe(
      select(createEntityDataSelector),
      takeUntil(this.unsubscribe$),
      finalize(() =>  { this.isSubmitting = false })
    ).subscribe((el) => {
      this._handleAfterUpdateEntityData(el, false);
    });
  }

  private _handleAfterUpdateEntityData(state: any, isMember: boolean) {
    if (state) {
      this.layoutService.showLoading = !!state?.isLoading;
      if (state?.success) {
        this.isSubmitting = false;
        this.dialogRef.close('submit');
      } else if (state.success === false) {
        if(state?.error && state?.error.error.errorMessage){
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: state?.error.error.errorMessage[1],
              type: ConfirmType.Warning,
              title: 'Error',
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            }
          });
        } else {
          this.bannerType = BannerType.Fail;
          this.message = `Error occurred updating ${state.payload?.cardName}. Please try again.`;
        }
        this.isSubmitting = false;
      }

      if (isMember) {
        this.memberStore.dispatch(clearUpdateMemberCardStateAction());
      } else {
        this.memberStore.dispatch(clearCreateEntityDataStateAction());
      }
    }
  }

  private getRelatedPersonList() {
    const query: GetListRelatedPersonQuery = {
      screenId: this.data.screenId,
      memberId: this.data.memberId,
      pageIndex: 1,
      pageSize: 100,
      sortNames: ['Name'],
      sortType: SortType.ASC,
    };
    this.memberStore.dispatch(
      RelatedPersonActions.getRelatedPersonList({ query })
    );
  }

  private _remapPayloadReference(newRequest: { entityId: string; entityPropertyValues: EntityInitiationPropertyValue[]}) {
    newRequest.entityPropertyValues?.forEach(el => {
      if (el.entityPropertyReferenceValue?.entityPropertyValues.length > 0 && typeof el.entityPropertyReferenceValue?.entityPropertyValues === 'object') {
        el.entityPropertyReferenceValue?.entityPropertyValues.forEach((item: any) => {
          item.recordId = el.entityPropertyReferenceValue?.recordId;
        });
      }
    });
  }

  onSubmit(newRequest: { 
    entityId: string;
    entityPropertyValues: EntityInitiationPropertyValue[],
    entityReferenceDatas?: EntityReferenceData[],
    courtOrderQdroOldPayeePersonName?: CourtOrderQdroSelectedPayeeOriginalPersonName,
  }): void {
    // Only use for Court Order Qdro logics
    const courtOrderQdroOldPayeePersonName = deepClone(newRequest?.courtOrderQdroOldPayeePersonName ?? null);
    if (courtOrderQdroOldPayeePersonName) delete newRequest.courtOrderQdroOldPayeePersonName;
    // End of only use for Court Order Qdro logics

    this.isSubmitting = true;
    newRequest.entityPropertyValues = newRequest.entityPropertyValues.filter(
      (x) => x.recordId !== null || x.entityComponentId !== GUID_EMPTY || x.entityPropertyId !== GUID_EMPTY,
    );
    const {
      section: { entityId, cardId, cardName },
      isAddRecord,
      recordId: id,
      memberId,
      addToTop,
      isList,
    } = this.data;
    if (isList) {
      const request = {
        ...newRequest,
        recordId: id,
        entityId,
        entityComponentId: this.entityProperties.entityComponentId,
        targetId: memberId,
        addToTop,
        cardId: this.data.section.cardId,
      };

      if (!isAddRecord || (this.isCourtOrder && courtOrderQdroOldPayeePersonName)) {
        request.entityPropertyValues = request.entityPropertyValues.reduce(
          (acc: EntityInitiationPropertyValue[], entityPropertyValue: EntityInitiationPropertyValue) => {
            let newEntityPropertyValue = entityPropertyValue;

            if (entityPropertyValue?.entityPropertyReferenceValue) {
              const currentEntityPropertyValue =
                entityPropertyValue?.entityPropertyReferenceValue.entityPropertyValues?.filter(
                  (item: any) => item.value || item.value === false || item.value === 0 || item.value === null || item.value === '',
                );

              if (currentEntityPropertyValue?.length) {
                newEntityPropertyValue.entityPropertyReferenceValue.entityPropertyValues = currentEntityPropertyValue;
              } else {
                return acc;
              }
            }
            return [...acc, newEntityPropertyValue];
          },
          [],
        );

        this._remapPayloadReference(request);
        
        // Only use for Court Order Qdro logics
        if (courtOrderQdroOldPayeePersonName && this.isPayeePersonNameChanged(request.entityPropertyValues, courtOrderQdroOldPayeePersonName)) {
          this.entityIsEditPersonName = ENTITY_PERSON_GUID;
          this.saveListWithAuditTrailData(request);
          return;
        }
        // End of only use for Court Order Qdro logics
      }

      if (this.checkIsEditStaticPropertyPersonName(request.entityPropertyValues, newRequest.entityReferenceDatas)) {
        this.saveListWithAuditTrailData(request);
      } else {
        this.memberStore.dispatch(upsertComponentListDataAction({ request }));
      }
    } else {
      const request = {
        ...newRequest,
        entityId,
      };
      if (!isAddRecord && this.checkIsEditStaticPropertyPersonName(newRequest.entityPropertyValues, newRequest.entityReferenceDatas)) {
        this.saveNonListWithAuditTrailData(request);
      } else {
        this.dispatchActionSaveData(memberId, cardName, this.data.section.cardId, request);
      }
    }
  }

  dispatchActionSaveData(
    memberId: string,
    cardName: string,
    cardId: string,
    request: UpsertEntityDataRequest,
    auditTrailReason?: string
  ) {
    if (this.entityId === ENTITY_MEMBER_GUID) {
      this.memberStore.dispatch(
        updateMemberCardAction({
          memberId,
          cardName,
          request,
          cardId,
          auditTrailReason
        })
      );
    } else {
      this.memberStore.dispatch(
        createEntityDataAction({
          recordId: memberId,
          cardName,
          request,
          cardId,
          auditTrailReason
        })
      );
    }
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  checkIsEditStaticPropertyPersonName(newPropertiesValue: any[], entityReferenceDatas: EntityReferenceData[] = []): boolean {
    const staticPropertyPersonNameId = PERSON_PROPERTY_MAPPING.Name.toLowerCase();
    for(const newPropertyValue of newPropertiesValue) {
      this.entityIsEditPersonName = '';
      const propertyMetadata = this.entityProperties.properties.find(p => p.entityPropertyId === newPropertyValue.entityPropertyId);
      if (!propertyMetadata) continue;

      let objOldPersonNameValue = null;
      let objNewPersonNameValue = null;
      if (propertyMetadata.entityPropertyId.toLowerCase() === staticPropertyPersonNameId) {
        // check is edit 'non-reference' person name property
        objOldPersonNameValue = this.data.cardData
          ?.find((p: any) => p.entityPropertyId.toLowerCase() === staticPropertyPersonNameId)?.value;
        objNewPersonNameValue = JSON.parse(newPropertyValue.value);
        this.entityIsEditPersonName = this.data.section.entityId;
      } else if (
        propertyMetadata.type === EntityPropertyType['Entity Reference'] 
        && newPropertyValue?.entityPropertyReferenceValue?.recordId
        && propertyMetadata.options
          ?.find(o => o.id === newPropertyValue?.entityPropertyReferenceValue?.entityId)?.properties
          ?.some((p: any) => p.entityPropertyId.toLowerCase() === staticPropertyPersonNameId)
      ) {
        // check is edit any 'reference' person name property
        const entityReferenceValue = entityReferenceDatas
          .find(p => p.entityPropertyId === propertyMetadata.entityPropertyId && p.recordId === newPropertyValue?.entityPropertyReferenceValue?.recordId);
        objOldPersonNameValue = entityReferenceValue?.referenceValues?.[staticPropertyPersonNameId];
        objNewPersonNameValue = newPropertyValue?.entityPropertyReferenceValue?.entityPropertyValues
          ?.find((p: any) => p.entityPropertyId.toLowerCase() === staticPropertyPersonNameId)?.value;
        objNewPersonNameValue = JSON.parse(objNewPersonNameValue);
        this.entityIsEditPersonName = newPropertyValue?.entityPropertyReferenceValue?.entityId;
      }
      
      if (
        objOldPersonNameValue 
        && objNewPersonNameValue 
        && !equalObjectByFields(objOldPersonNameValue, objNewPersonNameValue, LIST_INPUT_PERSON_NAME.map(x => x.key), true, true)
      ) {
        return true;
      }
    }
    return false;
  }

  saveNonListWithAuditTrailData(request: any) {
    this.layoutService.showLoading = true;
    this.entityDataService.getLookupTableByFixedKey(DEFAULT_LOOKUPTABLE.NAME_CHANGE_REASON).subscribe(res => {
      this.layoutService.showLoading = false;
      const dialogRef = this.showPopupConfirmAuditTrailPersonName(res?.metadataLookupTableOptions);
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          this.isSubmitting = false;
          return;
        }

        const auditTrailReason = result?.find((x: any) => x.key === AUDIT_TRAIL_PROPERTIES.Reason.Key)?.value;
        this.dispatchActionSaveData(
          this.data.memberId,
          this.data.section.cardName,
          this.data.section.cardId,
          request,
          auditTrailReason
        );
      });
    },
    (err) => {
      this.layoutService.showLoading = false;
    });
  }

  saveListWithAuditTrailData(request: any) {
    this.layoutService.showLoading = true;
    this.entityDataService.getLookupTableByFixedKey(DEFAULT_LOOKUPTABLE.NAME_CHANGE_REASON).subscribe(res => {
      this.layoutService.showLoading = false;
      const dialogRef = this.showPopupConfirmAuditTrailPersonName(res?.metadataLookupTableOptions);
      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          this.isSubmitting = false;
          return;
        }

        const auditTrailReason = result?.find((x: any) => x.key === AUDIT_TRAIL_PROPERTIES.Reason.Key)?.value;
        request.auditTrailReason = auditTrailReason;
        request.cardId = this.data.section.cardId;
        this.memberStore.dispatch(upsertComponentListDataAction({ request }));
      });
    },
    (err) => {
      this.layoutService.showLoading = false;
    });
  }

  showPopupConfirmAuditTrailPersonName(lookupTableOptions: any[]): MatDialogRef<ConfirmPopupWithDataComponent, any> {
    return this.dialog.open(ConfirmPopupWithDataComponent, {
      panelClass: 'confirm-popup',
      disableClose: true,
      autoFocus: false,
      data: {
        title: 'Warning',
        icon: 'error',
        message: `Name of the current ${this.getEntityTitleOnAuditTrailPopup()} has been modified. Please select the Reason for the Name change before saving the updates.`,
        properties: [
          {
            key: AUDIT_TRAIL_PROPERTIES.Reason.Key,
            label: AUDIT_TRAIL_PROPERTIES.Reason.Label,
            type: EntityPropertyType.Lookup,
            isRequired: true,
            options: lookupTableOptions?.map((x: any) => {
              return {
                displayValue: x.description,
                value: x.description,
              }
            }),
          }
        ]
      },
    });
  }

  getEntityTitleOnAuditTrailPopup() {
    switch (this.entityIsEditPersonName) {
      case ENTITY_PERSON_GUID:
        return 'Person';
      case ENTITY_PARTICIPANT_GUID:
        return 'Participant';
      case ENTITY_MEMBER_GUID:
        return 'Member';
      default:
        return '';
    }
  }

  handlePropertiesForServeNoteLogics() {
    /*
      Show Note Property only when Enable Note Toggle in these cases:
        + Edit Non-List card Stand Alone Properties
        + Add/Edit a List card List Record
      -> Hide in all remaining cases
    */
    let originalProperties = deepClone(this.entityProperties?.properties ?? []);
    originalProperties = originalProperties?.filter(
      (item: InitiationProperty) => 
        this.existNotePropertyAsStandAlonePropertyInListCard && !this.data?.isList
          ? !SHOULD_NOT_SHOW_NOTE_PROPERTIES_WHEN_EDIT_LIST_CARD_STAND_ALONE_PROPERTIES?.includes(item?.entityPropertyId?.toLowerCase())
          : !SHOULD_NOT_SHOW_NOTE_PROPERTIES_IN_UPSERT_ENTITY_FLOW?.includes(item?.entityPropertyId?.toLowerCase())
    );
    this.entityProperties.properties = originalProperties;
  }

  handlePropertiesForRelationshipCard() {
    let originalProperties = deepClone(this.entityProperties?.properties ?? []);

    originalProperties = originalProperties.map((item: InitiationProperty) => ({
      ...item,
      isLegalRelationshipType: false,

      isFirstBeneficiaryProperty: false,
      isLastBeneficiaryProperty: false,
      
      isFirstDependentProperty: false,
      isLastDependentProperty: false,

      isFirstPowerOfAttorneyProperty: false,
      isLastPowerOfAttorneyProperty: false,

      belongLegalRelatioshipTypeId: '',
      isHidden: false,
    }));

    let remainingProperties = [];
    let relationshipProp = null;
    let legalRelationshipTypeProp = null;
    let beneficiaryProps: InitiationProperty[] = [];
    let dependentProps: InitiationProperty[] = [];
    let powerOfAttorneyProps: InitiationProperty[] = [];
    let shouldNotIncludedToAnySectionProps = [];
    let relatedEntityReferenceProp = null;
    let guardianEntityReferenceProp = null;

    // Find Relationship Prop
    relationshipProp = deepClone(originalProperties?.find(
      (item) => item?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship
    ) ?? null);
    if (relationshipProp) {
      this.handleRelationshipData(relationshipProp);
    }

    // Find Legal Relationship Type Prop
    legalRelationshipTypeProp = deepClone(originalProperties?.find(
      (item) => item?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.LegalRelationshipType
    ) ?? null);
    if (legalRelationshipTypeProp) {
      this.handleLegalRelationshipTypeData(legalRelationshipTypeProp);
    }

    // 1. Loop through Properties list
    for (let i = 0; i <= originalProperties.length - 1; i++) {
      // Continue if item is Relationship Prop attribute
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship) {
        continue;
      }

      // Continue if item is Legal Relationship Type Prop attribute
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.LegalRelationshipType) {
        continue;
      }

      // Find Related Entity Reference Prop
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.RelatedEntity) {
        relatedEntityReferenceProp = deepClone(originalProperties?.[i]);
        continue;
      }

      // Find Guardian Entity Reference Prop
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Guardian) {
        originalProperties[i].isHidden = !this.isLegalRelationshipTypeDependentSelected;
        originalProperties[i].belongLegalRelatioshipTypeId = LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Dependent;
        guardianEntityReferenceProp = deepClone(originalProperties?.[i]);
        continue;
      }

      // Find Note Property
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === NOTES_PROPERTY.Id?.toUpperCase() && this.isEnableNote) {
        shouldNotIncludedToAnySectionProps.push(deepClone(originalProperties?.[i]));
        continue;
      }

      // Hide / Show all Marital Information Props for initial phase, hide all in Add case
      if (RELATIONSHIP_MARITAL_INFORMATION_PROPERTIES?.includes(originalProperties?.[i]?.entityPropertyId?.toUpperCase() as RELATIONSHIP_LIST_PROPERTY_FIXED_ID)) {
        originalProperties[i].isHidden = true;
        if (this.isEditListRecord && this.relationshipTypeSelected !== null) {
          if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.MarriageDate.toUpperCase()) {
            originalProperties[i].isHidden = !ALLOW_SHOW_MARRIAGE_DATE_WITH_RELATIONSHIP_VALUES.includes(this.relationshipTypeSelected);
          }
          if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.DivorceDate.toUpperCase()) {
            originalProperties[i].isHidden = !ALLOW_SHOW_DIVORCE_DATE_WITH_RELATIONSHIP_VALUES.includes(this.relationshipTypeSelected);
          }
        }
        remainingProperties.push(deepClone(originalProperties?.[i]));
        continue;
      }

      // Find all Beneficiary Information Props, hide all in Add case
      if (RELATIONSHIP_BENEFICIARY_PROPERTIES?.includes(originalProperties?.[i]?.entityPropertyId?.toUpperCase() as RELATIONSHIP_LIST_PROPERTY_FIXED_ID)) {
        originalProperties[i].belongLegalRelatioshipTypeId = LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary;
        originalProperties[i].isHidden = !this.isLegalRelationshipTypeBeneficiarySelected;
        beneficiaryProps.push(deepClone(originalProperties?.[i]));
        continue;
      }

      // Find all Dependent Information Props, hide all in Add case
      if (RELATIONSHIP_DEPENDENT_PROPERTIES?.includes(originalProperties?.[i]?.entityPropertyId?.toUpperCase() as RELATIONSHIP_LIST_PROPERTY_FIXED_ID)) {
        originalProperties[i].belongLegalRelatioshipTypeId = LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Dependent;
        originalProperties[i].isHidden = !this.isLegalRelationshipTypeDependentSelected;
        dependentProps.push(deepClone(originalProperties?.[i]));
        continue;
      }

      // Find all PoA Information Props, hide all in Add case
      if (RELATIONSHIP_POWER_OF_ATTOURNEY_PROPERTIES?.includes(originalProperties?.[i]?.entityPropertyId?.toUpperCase() as RELATIONSHIP_LIST_PROPERTY_FIXED_ID)) {
        originalProperties[i].belongLegalRelatioshipTypeId = LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.PowerOfAttorney;
        originalProperties[i].isHidden = !this.isLegalRelationshipTypePowerOfAttorneySelected;
        powerOfAttorneyProps.push(deepClone(originalProperties?.[i]));
        continue;
      }

      // If not belong above cases, add to remaining list
      if (originalProperties?.[i]) {
        remainingProperties.push(deepClone(originalProperties[i]));
      }
    }

    // 2. Add `isFirst` flag for 3 section types
    if (beneficiaryProps?.length) {
      beneficiaryProps[0].isFirstBeneficiaryProperty = true;
      beneficiaryProps[0].relationshipListSectionTitle = this.getRelationshipListCardSectionTitle(LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary);
    }
    if (dependentProps?.length) {
      dependentProps[0].isFirstDependentProperty = true;
      dependentProps[0].relationshipListSectionTitle = this.getRelationshipListCardSectionTitle(LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Dependent);
    }
    if (powerOfAttorneyProps?.length) {
      powerOfAttorneyProps[0].isFirstPowerOfAttorneyProperty = true;
      powerOfAttorneyProps[0].relationshipListSectionTitle = this.getRelationshipListCardSectionTitle(LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.PowerOfAttorney);
    }

    // 3. Prepare Re-Ordered List
    let reOrderedList: InitiationProperty[] = [];
    if (legalRelationshipTypeProp) {
      reOrderedList.push(legalRelationshipTypeProp);
    }
    if (relationshipProp) {
      reOrderedList.push(relationshipProp);
    }
    if (remainingProperties?.length) {
      reOrderedList.push(...remainingProperties);
    }
    if (shouldNotIncludedToAnySectionProps?.length) {
      reOrderedList.push(...shouldNotIncludedToAnySectionProps);
    }
    if (relatedEntityReferenceProp) {
      reOrderedList.push(relatedEntityReferenceProp);
    }
    if (guardianEntityReferenceProp) {
      dependentProps.push(guardianEntityReferenceProp);
    }

    // 4. Display 3 sections Bene, Depe, PoA follow A-Z rule
    let reOrderedSectionList: InitiationProperty[] = [];
    if (this.legalRelationshipTypeOptionList.length >= 2) {
      this.legalRelationshipTypeOptionList.forEach((opt) => {
        if (opt?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary.toUpperCase()) {
          reOrderedSectionList.push(...beneficiaryProps);
        }
        else if (opt?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Dependent.toUpperCase()) {
          reOrderedSectionList.push(...dependentProps);
        }
        else if (opt?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.PowerOfAttorney.toUpperCase()) {
          reOrderedSectionList.push(...powerOfAttorneyProps);
        }
      });
    } else {
      reOrderedSectionList = [
        ...beneficiaryProps,
        ...dependentProps,
        ...powerOfAttorneyProps,
      ];
    }

    // 5. Combine lists
    reOrderedList = [
      ...reOrderedList,
      ...reOrderedSectionList
    ];

    // 6. Overwrite Properties list
    this.entityProperties.properties = reOrderedList;
  }

  handlePropertiesForCourtOrder() {
    let originalProperties = deepClone(this.entityProperties?.properties ?? []);
    originalProperties = originalProperties.map((item: InitiationProperty) => ({
      ...item,
      isCourtOrderTypeProperty: false,
      isPayeeProperty: false,
      isPayeeInformationProperty: false,
      isFirstPayeeInformationProperty: false,
      isLastPayeeInformationProperty: false,
    }));
    let remainingProperties = [];
    let courtOrderTypeProp = null;
    let payeeProp = null;
    let payeeInformationProps = [];
    let shouldNotIncludedToPayeeInformationSectionProps = [];
              
    // 1. Loop through Properties list
    for (let i = 0; i <= originalProperties.length - 1; i++) {
      // 1.1 Find Court Order Type Prop
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === CourtOrderPropertiesGUIDs.CourtOrderType) {
        originalProperties[i].isCourtOrderTypeProperty = true;
        courtOrderTypeProp = deepClone(originalProperties?.[i]);
        continue;
      }

      // 1.2 Find Payee Prop
      if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === CourtOrderPropertiesGUIDs.Payee) {
        originalProperties[i].isPayeeProperty = true;
        payeeProp = deepClone(originalProperties?.[i]);
        continue;
      }

      // 1.3 Find all Payee Information Props (configured by all Person properties in Court Order list entity)
      if (originalProperties?.[i]?.entityId?.toLowerCase() === ENTITY_PERSON_GUID) {
        if (originalProperties?.[i]?.entityPropertyId?.toUpperCase() === CourtOrderPropertiesGUIDs.Note
          && this.isEnableNote) {
          shouldNotIncludedToPayeeInformationSectionProps.push(deepClone(originalProperties?.[i]));
          continue;
        }
        originalProperties[i].isPayeeInformationProperty = true;
        payeeInformationProps.push(deepClone(originalProperties?.[i]));
        continue;
      }

      // 1.4 If not belong above cases, add to remaining list
      if (originalProperties?.[i]) {
        remainingProperties.push(deepClone(originalProperties[i]));
      }
    }

    // 2. Add `isLastPayeeInformationProperty` and `isFirstPayeeInformationProperty` flags
    if (payeeInformationProps?.length) {
      const lastIdx = payeeInformationProps.length - 1;
      payeeInformationProps[0].isFirstPayeeInformationProperty = true;
      payeeInformationProps[lastIdx].isLastPayeeInformationProperty = true;
    }

    // 3. Prepare Re-Ordered List
    let reOrderedList: InitiationProperty[] = [];
    if (courtOrderTypeProp) {
      reOrderedList.push(courtOrderTypeProp);
    }
    if (payeeProp) {
      reOrderedList.push(payeeProp);
    }
    reOrderedList = [
      ...reOrderedList,
      ...payeeInformationProps,
      ...remainingProperties,
      ...shouldNotIncludedToPayeeInformationSectionProps,
    ];

    // 4. Overwrite Properties list
    this.entityProperties.properties = reOrderedList;
  }

  isPayeePersonNameChanged(newPropertiesValue: any[], courtOrderQdroOldPayeePersonName: CourtOrderQdroSelectedPayeeOriginalPersonName): boolean {
    const newNameJsonObj = newPropertiesValue?.find(item => item?.entityPropertyId?.toLowerCase() === CourtOrderPropertiesGUIDs.PersonName.toLowerCase())?.value;
    const oldNameObj = deepClone(courtOrderQdroOldPayeePersonName);

    if (newNameJsonObj && oldNameObj) {
      const newNameObj = JSON.parse(newNameJsonObj);

      const isPrefixChanged = 
        newNameObj.Prefix !== courtOrderQdroOldPayeePersonName.prefix 
          && !allItemsAreNullOrUndefinedOrEmptyString(newNameObj?.Prefix, oldNameObj?.prefix);
      const isFirstChanged = 
        newNameObj.First !== courtOrderQdroOldPayeePersonName.first 
          && !allItemsAreNullOrUndefinedOrEmptyString(newNameObj?.First, oldNameObj?.first);
      const isMiddleChanged = 
        newNameObj.Middle !== courtOrderQdroOldPayeePersonName.middle 
          && !allItemsAreNullOrUndefinedOrEmptyString(newNameObj?.Middle, oldNameObj?.middle);
      const isLastChanged = 
        newNameObj.Last !== courtOrderQdroOldPayeePersonName.last 
          && !allItemsAreNullOrUndefinedOrEmptyString(newNameObj?.Last, oldNameObj?.last);
      const isSuffixChanged = 
        newNameObj.Suffix !== courtOrderQdroOldPayeePersonName.suffix 
          && !allItemsAreNullOrUndefinedOrEmptyString(newNameObj?.Suffix, oldNameObj?.suffix);

      return isPrefixChanged
        || isFirstChanged
        || isMiddleChanged
        || isLastChanged
        || isSuffixChanged;
    }

    return false;
  }

  handleRelationshipData(relationshipProp: InitiationProperty) {
    // Prepare initial value for Legal Relationship Type in Edit case
    if (this.isEditListRecord) {
      const relationshipField = this.cardData?.find(
        (item: any) => item?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.Relationship.toUpperCase()
      );

      if (relationshipField?.value) {
        this.initialRelationshipValueSelected = relationshipField?.value;
        this.relationshipTypeSelected = 
          relationshipField?.options?.find((item: any) => item?.id === relationshipField?.value)?.relationshipType as RelationshipOptions
          ?? null;
      }
    }
  }

  handleLegalRelationshipTypeData(legalRelationshipTypeProp: InitiationProperty) {
    // Prepare option list for Legal Relationship Type
    this.legalRelationshipTypeOptionList = legalRelationshipTypeProp?.options
      ?.map(item => ({
        ...item,
        value: item?.id,
        displayValue: item?.text ?? item?.description ?? '',
      }))
      ?.sort((item1, item2) => 
        item1?.displayValue?.localeCompare(item2?.displayValue)
      )
    ?? [];

    // Prepare initial value for Legal Relationship Type in Edit case
    if (this.isEditListRecord) {
      const initialValue = this.cardData?.find(
        (item: any) => item?.entityPropertyId?.toUpperCase() === RELATIONSHIP_LIST_PROPERTY_FIXED_ID.LegalRelationshipType.toUpperCase()
      )?.value;

      if (initialValue) {
        const valueList = initialValue?.split(',');
        valueList?.forEach((val: string) => {
          const opt = this.legalRelationshipTypeOptionList?.find(
            (el: LegalRelationshipTypeOption) => el?.value?.toUpperCase() === val?.toUpperCase()
          );
          if (opt) {
            opt.isHide = true;
            if (opt?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Beneficiary.toUpperCase()) {
              this.isLegalRelationshipTypeBeneficiarySelected = true;
            }
            if (opt?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.Dependent.toUpperCase()) {
              this.isLegalRelationshipTypeDependentSelected = true;
            }
            if (opt?.value?.toUpperCase() === LEGAL_RELATIONSHIP_TYPE_LOOKUP_FIXED_ID.PowerOfAttorney.toUpperCase()) {
              this.isLegalRelationshipTypePowerOfAttorneySelected = true;
            }
            this.legalRelationshipTypeChipList.push(deepClone(opt));
            this.legalRelationshipTypeChipList = sortByCriteria(
              this.legalRelationshipTypeChipList, 'text'
            );
          }
        });
      }
    }
  }

  getRelationshipListCardSectionTitle(id: string) {
    return this.legalRelationshipTypeOptionList.find(
      (item) => item?.id?.toUpperCase() === id?.toUpperCase()
    )?.displayValue ?? '';
  }

  reArrangeStandAlonePropertiesOrder() {
    // Re-Arrange for Summary View
    if (this.isEditStandAlonePropertiesFromSummaryView) {
      let originalProperties = deepClone(this.entityProperties?.properties ?? []);
      originalProperties = sortByCriteria(originalProperties, 'order', true, SortByCompareType.Number);
      this.entityProperties.properties = originalProperties;
      return;
    }

    // Re-Arrange for Detail View
    if (this.isEditStandAlonePropertiesFromDetailView) {
      let originalProperties = deepClone(this.entityProperties?.properties ?? []);

      if (this.standAlonePropertyOrderInEditFormList?.length === 0) {
        return;
      }

      const newPropertyList: any = [];
      this.standAlonePropertyOrderInEditFormList.forEach((item) => {
        if (item?.entityReferencePropertyId) {
          const erProperty = originalProperties?.find((prop: any) => prop?.entityPropertyId?.toUpperCase() === item?.entityReferencePropertyId?.toUpperCase());
          if (erProperty) {
            newPropertyList.push({
              ...erProperty,
              belongPropertiesOrder: item?.belongPropertiesOrder,
            });
          }
        } else {
          const property = originalProperties?.find((prop: any) => prop?.entityPropertyId?.toUpperCase() === item?.propertyId?.toUpperCase());
          if (property) {
            newPropertyList.push(property);
          }
        }
      });

      this.entityProperties.properties = newPropertyList;
      return;
    }
  }

  reArrangeListRecordPropertiesOrder() {
    let originalProperties = deepClone(this.entityProperties?.properties ?? []);
    originalProperties = sortByCriteria(originalProperties, 'order', true, SortByCompareType.Number);
    this.entityProperties.properties = originalProperties;
    return;
  }

  isNotSpecialListRecordOrderLogics() {
    const isNotSpecialListRecordOrderLogics = [
      this.isCourtOrder,
      this.isRelationshipCard,
    ];
    return isNotSpecialListRecordOrderLogics.every(item => item !== true);
  }
}
