import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GetChildSurvivorValidationBeforeInitializationResponse,
  GetChildSelectionResponse,
  GetChildSurvivorValidationBeforeInitializationRequest,
  GetChildSurvivorLabelNameResponse,
} from './models/calculation-child-survivor.model';
import { BenefitCalculationErrorType } from '../types/enums';

@Injectable()
export class CalculationChildSurvivorService {
  constructor(private httpClient: HttpClient) {}

  // Get Child Survivor Label Name
  getCalculationChildSurvivorLabelName(memberId: string): Observable<GetChildSurvivorLabelNameResponse> {
    return this.httpClient.get<GetChildSurvivorLabelNameResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/name-of-child-survivor`,
    );
  }

  // Get Child Selection
  getChildSelection(memberId: string): Observable<GetChildSelectionResponse> {
    return this.httpClient.get<GetChildSelectionResponse>(`${environment.apiUrl}/calculations/${memberId}/child`);
  }

  // Get Child Survivor Validation Before Initiate
  getChildSurvivorValidationBeforeInitialization(
    request: GetChildSurvivorValidationBeforeInitializationRequest,
  ): Observable<GetChildSurvivorValidationBeforeInitializationResponse> {
    const { memberId, calculationType, benefitEntityId } = request;
    return this.httpClient.get<GetChildSurvivorValidationBeforeInitializationResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/${calculationType}/entity/${benefitEntityId}`,
    );
  }
}

export const errorMessageValidationChildSurvivor = (errorType: BenefitCalculationErrorType) => {
  switch (errorType) {
    case BenefitCalculationErrorType.CalculationFileIsMissing:
      return {
        msg: 'Please set up Child Survivor Benefit before initiating.',
        title: 'Setup Requiring',
      };
    case BenefitCalculationErrorType.StepConfigurationIsMissing:
      return {
        msg: 'Step Configuration needs to be set up before initiating Benefit Calculation.',
        title: 'Setup Requiring',
      };
    case BenefitCalculationErrorType.BenefitEntityMissing:
      return {
        msg: 'Benefit Option must be associated with a Calculation File before initiating Benefit.',
        title: 'Setup Requiring',
      };
    case BenefitCalculationErrorType.NoChildAvailableForInitiate:
      return {
        msg: 'No Child available to initiate benefit calculation.',
        title: 'Warning',
      };
    default:
      return {
        msg: '',
        title: '',
      };
  }
};
