import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import * as EditPayrollSettingsActions from '../actions/edit-payroll-settings.actions';

import { GetEditPayrollSettingsResponse } from '../../services/models/edit-payroll-settings.model';

export interface EditPayrollSettingsState {
  getEditPayrollSettings?: BaseActionState<GetEditPayrollSettingsResponse>;
  saveEditPayrollSettings?: BaseActionState<void>;
}

const initialState: EditPayrollSettingsState = {};

export const editPayrollSettingsReducer = createReducer(
  initialState,
  on(EditPayrollSettingsActions.getEditPayrollSettingsAction, (state) => ({
    ...state,
    getEditPayrollSettings: {
      isLoading: true,
    },
  })),
  on(EditPayrollSettingsActions.getEditPayrollSettingsSuccess, (state, { response }) => ({
    ...state,
    getEditPayrollSettings: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPayrollSettingsActions.getEditPayrollSettingsFailure, (state, { error }) => ({
    ...state,
    getEditPayrollSettings: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPayrollSettingsActions.clearGetEditPayrollSettingsState, (state) => ({
    ...state,
    getEditPayrollSettings: undefined,
  })),

  on(EditPayrollSettingsActions.saveEditPayrollSettingsAction, (state) => ({
    ...state,
    saveEditPayrollSettings: {
      isLoading: true,
    },
  })),
  on(EditPayrollSettingsActions.saveEditPayrollSettingsSuccess, (state) => ({
    ...state,
    saveEditPayrollSettings: {
      isLoading: false,
      success: true,
      error: null,
    },
  })),
  on(EditPayrollSettingsActions.saveEditPayrollSettingsFailure, (state, { error }) => ({
    ...state,
    saveEditPayrollSettings: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPayrollSettingsActions.clearSaveEditPayrollSettingsState, (state) => ({
    ...state,
    saveEditPayrollSettings: undefined,
  })),
);
