import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const benefitCalculationConfigurationSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.benefitCalculationConfiguration,
);

export const getBenefitCalculationParameterMappingSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.getBenefitCalculationParameterMapping,
);

export const getCalculationDisplayConfigurationByTypeSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.getCalculationDisplayConfigurationByType,
);

export const setModifyCalculationDisplayConfigurationSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.setModifyCalculationDisplayConfiguration,
);

export const completeCalculationBenefitSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.completeCalculationBenefit,
);

export const approveCalculationBenefitSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.approveCalculationBenefit,
);

export const reopenCalculationBenefitSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.reopenCalculationBenefit,
);

export const validateCalculationBenefitSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.validateCalculationBenefit,
);

export const computeCalculationBenefitSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.computeCalculationBenefit,
);

export const validateBeforeComputeJoinSurvivorSelector = createSelector(
  benefitCalculationConfigurationSelector,
  (state) => state?.validateBeforeComputeJoinSurvivor,
);
