export enum AdjustmentDetailedType {
  Earnings = 1,
  'Funding Sources' = 2,
  Deduction = 3,
  'Deduction as Funding Source' = 4,
}

export enum PositionPaymentInfoTabDetailedQueryType {
  Adjustment,
  PaymentInstruction,
  PaymentInstructionHistory,
}
