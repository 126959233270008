import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { GetRetirementBenefitDownloadDocumentRequest } from '@ptg-member/features/calculation/services/models/retirement-benefit-history.model';
import { GenerateDocument } from '@ptg-member/types/models/generate-document.models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  GetGeneratedReportDetailRequest,
  GetGeneratedReportsInformationRequest, GetGeneratedReportsInformationResponse,
  ValidateGenerateAge65LetterRequest,
  ValidateGenerateAge65LetterResponse,
  MunicipalityGenerateDocument
} from 'src/app/admin/features/file/services/models/documents.model';
import { SortType } from '@ptg-shared/constance';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private httpClient: HttpClient) {}

  // Download Inline Document
  downloadDocument(fileId: string) {
    let params = new HttpParams();
    let context = new HttpContext().set(SKIP_ERRORS, '404');
    if (fileId) {
      params = params.append('FileId', fileId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/documents/download`,
      { context, responseType: 'blob', params }
    );
  }

  // NEED TO UPDATE
  prepareDownloadMultipleDocuments(documentIds: any): Observable<any> {
    let context = new HttpContext().set(SKIP_ERRORS, '404');
    let params = new HttpParams();
    documentIds.forEach((fileId: any) => {
      if (params) {
        params = params.append('fileIds', fileId);
      }
    });
    return this.httpClient.get<any>(
      `${environment.apiUrl}/documents/download/prepare`, { context, params }
    );
  }

  downloadSelectedDocument(zipId: string) {
    let context = new HttpContext().set(SKIP_ERRORS, '404');
    let params = new HttpParams();
    if(zipId) {
      params = params.append('ZipId', zipId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/documents/download`,
      { context, responseType: 'blob', params }
    );
  }

  checkHaveZipFile(zipId?: string) {
    let params = new HttpParams();
    if (zipId) {
      params = params.append('ZipId', zipId);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/documents/download/check`,
      { params }
    );
  }

  generateDocument(body: GenerateDocument | MunicipalityGenerateDocument) {
    return this.httpClient.post(
      `${environment.apiUrl}/Template/generate-file`,
      body
    );
  }

  getRetirementBenefitDownloadDocument(request: GetRetirementBenefitDownloadDocumentRequest) {
    const { memberId, calculationType, calculationDocumentId } = request;
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/${calculationType}/${calculationDocumentId}`,
      { responseType: 'blob' }
    );
  }

  // validateGenerateAge65Letter
  validateGenerateAge65Letter(request: ValidateGenerateAge65LetterRequest): Observable<ValidateGenerateAge65LetterResponse> {
    let params = new HttpParams();
    if (request.thruDate){
      params = params.append('thruDate', request.thruDate);
    }
    return this.httpClient.get<ValidateGenerateAge65LetterResponse>(
      `${environment.apiUrl}/Template/age-65/validate`,
      { params }
    );
  }

  getGeneratedReportsInformation(request: GetGeneratedReportsInformationRequest): Observable<GetGeneratedReportsInformationResponse> {
    let params = new HttpParams();
    if (request?.templateName) {
      params = params.append('templateName', request.templateName);
    }
    if (request?.generateId) {
      params = params.append('generateId', request.generateId);
    }
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get<GetGeneratedReportsInformationResponse>(
      `${environment.apiUrl}/Template/generate-information`,
      { params }
    );
  }

  getGeneratedReportDetail(request: GetGeneratedReportDetailRequest): Observable<any> {
    let params = new HttpParams();
    params = params.append('generateId', request.generateId);
    if (request?.sortNames?.length) {
      (request?.sortNames as string[]).forEach((sortNames) => {
        params = params.append('SortNames', sortNames);
      });
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/Template/generate-files`,
      { params }
    );
  }

  getTemplateTagsList(fileId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/Members/document/${fileId}`,
    );
  }

  approveRefundMuniCreditReq(requestId: string){
    return this.httpClient.post(
      `${environment.apiUrl}/PaymentInstructions/municipality-refunds/${requestId}/approve`, {}
    );
  }

  rejectRefundMuniCreditReq(requestId: string){
    return this.httpClient.post(
      `${environment.apiUrl}/PaymentInstructions/municipality-refunds/${requestId}/reject`, {}
    );
  }

  previewFile(fileId: string) {
    let params = new HttpParams();
    params = params.append('fileId', fileId);

    return this.httpClient.get(
      `${environment.apiUrl}/PreviewDocument/GenerateToken`,
      {
        params,
      }
    );
  }
}
