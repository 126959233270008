import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { REQUIRED_INDICATOR } from 'src/app/app.const';

@Component({
  selector: 'ptg-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  readonly Validators = Validators;
  readonly REQUIRED_INDICATOR = REQUIRED_INDICATOR;

  quillConfiguration = {toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
  ]};

  matErrorId: string = '';
  inputId: string = '';
  errorMaxLength = '';
  customValidators: ValidatorFn[] = [];

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() errorRequire?: string = '';
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() maxLength?: number;
  @Input() hasFloatLabel: boolean = false;

  // @-> Use this `shouldShowRequiredIndicator` is case dont have form control (`controlField`) injected
  // or just want to always show required the indicator.
  // Without this, required indicator will show follow control field exist Validator.Require or not
  @Input() shouldShowRequiredIndicator: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.addValidation();
  }
  
  addValidation() {
    if (this.isRequired || this.errorRequire) {
      this.controlField.addValidators(Validators.required);
    }

    if (this.controlField.hasValidator(Validators.required)) {
      this.errorRequire = this.errorRequire || `${ this.placeholder } is required.`;
    }

    if (this.maxLength) {
      this.controlField.addValidators(this.maxLengthValidator());
      this.errorMaxLength = `Exceed the ${ this.maxLength } character limit.`; 
    }

    if (this.isDisabled) {
      this.controlField.disable();
    }
  }

  textChanged($event: any) {
    if(Number(this.maxLength) && this.getTextContent(this.controlField?.value).length > Number(this.maxLength)) {
      $event.editor.deleteText(this.maxLength, $event.editor.getLength());
      this.controlField.setValue(this.getTextContent(this.controlField.value || '').substring(0, this.maxLength));
      $event.editor.setSelection($event.editor.getLength(), 0);
    }
  }

  getTextContent(value: any) {
    const span = document.createElement('span');
    span.innerHTML = value;
    return span.textContent || span.innerText || '';
  }

  maxLengthValidator(): ValidatorFn {
   return (control : AbstractControl) : ValidationErrors | null => {
    if (Number(this.maxLength) && this.getTextContent(control.value)?.length > Number(this.maxLength)) {
      return { maxLength: true };
    }
    return null;
   };
  }
}
