import { CalculationListEffects } from './calculation-list.effect';
import { InputOutputEffects } from './input-output.effect';
import { StepConfigurationEffects } from './step-configuration.effect';
import { CalculationParameterConfigurationEffects } from './calculation-parameter-configuration.effect';
import { RetirementBenefitEffects } from './retirement-benefit-history.effect';
import { BenefitCalculationConfigurationEffects } from './benefit-calculation-configuration.effect';
import { RetirementBenefitDialogEffect } from './retirement-benefit-dialog.effect';
import { RetirementBenefitUploadDocumentEffects } from './retirement-benefit-upload-document.effect';
import { ExceptionConfigurationEffects } from './exception-configuraion.effect';
import { RetirementBenefitDetailDocumentEffect } from './retirement-benefit-detail-document.effect';
import { RetirementBenefitDetailUploadDocumentEffects } from './retirement-benefit-detail-upload-document.effect';
import { RemoveCalculationDocumentDetailEffects } from './remove-calculation-document-detail.effect';
import { CheckRetirementDocumentCanRemoveEffects } from './check-retirement-document-can-remove.effect';
import { RemoveOverviewDocumentEffects } from './remove-overview-document.effect';
import { GetCalculationBenefitDetailsEffects } from './calculation-benefit-details.effect';
import { DisabilityBenefitEffects } from './disability-benefit.effects';
import { MemberEntityListComponentsEffects } from '@ptg-member/features/calculation/store/effects/member-entity.effect';
import { GetEntityComponentListDataByComponentIdEffect } from './get-entity-component-list-data-by-component-id.effect';
import { InitiateBenefitEffect } from './benefit-refund.effects';
import { SubHeaderConfigurationEffect } from './subheader-configuration.effect';
import { CalculationLoddBenefitEffects } from './calculation-lodd-benefit.effect';
import { CalculationQDROEffect } from './calculation-qdro.effect';
import { CalculationChildSurvivorEffect } from './calculation-child-survivor.effect';

export const CalculationEffects = [
  StepConfigurationEffects,
  CalculationListEffects,
  InputOutputEffects,
  CalculationParameterConfigurationEffects,
  RetirementBenefitEffects,
  BenefitCalculationConfigurationEffects,
  RetirementBenefitDialogEffect,
  RetirementBenefitUploadDocumentEffects,
  ExceptionConfigurationEffects,
  RetirementBenefitDetailDocumentEffect,
  RetirementBenefitDetailUploadDocumentEffects,
  RemoveCalculationDocumentDetailEffects,
  CheckRetirementDocumentCanRemoveEffects,
  RemoveOverviewDocumentEffects,
  GetCalculationBenefitDetailsEffects,
  DisabilityBenefitEffects,
  MemberEntityListComponentsEffects,
  GetEntityComponentListDataByComponentIdEffect,
  InitiateBenefitEffect,
  SubHeaderConfigurationEffect,
  CalculationLoddBenefitEffects,
  CalculationQDROEffect,
  CalculationChildSurvivorEffect,
];

export * from './step-configuration.effect';
export * from './step-configuration.effect';
export * from './input-output.effect';
export * from './calculation-parameter-configuration.effect';
export * from './benefit-calculation-configuration.effect';
export * from './retirement-benefit-upload-document.effect';
export * from './exception-configuraion.effect';
export * from './retirement-benefit-detail-document.effect';
export * from './disability-benefit.effects';
export * from './member-entity.effect';
export * from './get-entity-component-list-data-by-component-id.effect';
export * from './subheader-configuration.effect';
