import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { checkDisplayWithHoldingTaxAction, checkDisplayWithHoldingTaxFailureAction, checkDisplayWithHoldingTaxSuccessAction, clearCreateOneTimePaymentStateAction, clearDeductionDataStateAction, clearPayeeListState, clearPaymentAddressState, createOneTimePaymentFailureAction, createOneTimePaymentSuccessAction, getBenefitTimeInfoAction, getBenefitTimeInfoFailureAction, getBenefitTimeInfoSuccessAction, getDeductionDataAction, getDeductionDataFailureAction, getDeductionDataSuccessAction, getFundingSourceAction, getFundingSourceFailureAction, getFundingSourceSuccessAction, getOneTimeCorrectionTypeAction, getOneTimeCorrectionTypeFailureAction, getOneTimeCorrectionTypeSuccessAction, getOneTimePaymentTypeAction, getOneTimePaymentTypeFailureAction, getOneTimePaymentTypeSuccessAction, getPayeeListAction, getPayeeListFailureAction, getPayeeListSuccessAction, getPaymentAddressAction, getPaymentAddressFailureAction, getPaymentAddressSuccessAction } from '../actions';
import { AddressData, BenefitTimeInfo, FundingSourceElement, GetDeductionFundingSourcesResponse, PayeeResponse, PaymentType } from '../../services/models';

export interface State {
  paymentInstructionTypeDtos: BaseActionState<PaymentType[]>;
  correctionType: BaseActionState<PaymentType[]>;
  fundingSources: BaseActionState<FundingSourceElement[]>;
  createOneTimePayment?: BaseActionState<{ id: string }>;
  payeeResponse: BaseActionState<PayeeResponse>;
  benefitTimeInfo: BaseActionState<BenefitTimeInfo>;
  addressData: BaseActionState<AddressData[]>;
  displayWithHoldingTax: BaseActionState<boolean>;
  deductionData: BaseActionState<GetDeductionFundingSourcesResponse>;
}

export const AddOneTimeKey = 'addOneTime';

const initialState: State = {
  paymentInstructionTypeDtos: {
    isLoading: true
  },
  correctionType: {
    isLoading: true
  },
  fundingSources: {
    isLoading: true
  },
  payeeResponse: {
    isLoading: true
  },
  benefitTimeInfo: {
    isLoading: true
  },
  addressData: {
    isLoading: true
  },
  displayWithHoldingTax: {
    isLoading: true
  },
  deductionData: {
    isLoading: true
  },
};

export const reducer = createReducer(
  initialState,
  on(getOneTimePaymentTypeAction, (state: State) => ({
    ...state,
    paymentInstructionTypeDtos: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getOneTimePaymentTypeSuccessAction, (state: State, {paymentInstructionTypeDtos}) => ({
    ...state,
    paymentInstructionTypeDtos: {
      isLoading: false,
      success: true,
      payload: paymentInstructionTypeDtos
    }
  })),
  on(getOneTimePaymentTypeFailureAction, (state: State) => ({
    ...state,
    paymentInstructionTypeDtos: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),
  on(getOneTimeCorrectionTypeAction, (state: State) => ({
    ...state,
    correctionType: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getOneTimeCorrectionTypeSuccessAction, (state: State, {paymentInstructionTypeDtos}) => ({
    ...state,
    correctionType: {
      isLoading: false,
      success: true,
      payload: paymentInstructionTypeDtos
    }
  })),
  on(getOneTimeCorrectionTypeFailureAction, (state: State) => ({
    ...state,
    correctionType: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(getFundingSourceAction, (state: State) => ({
    ...state,
    fundingSource: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getFundingSourceSuccessAction, (state: State, {fundingSources}) => ({
    ...state,
    fundingSources: {
      isLoading: false,
      success: true,
      payload: fundingSources
    }
  })),
  on(getFundingSourceFailureAction, (state: State) => ({
    ...state,
    fundingSources: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(getBenefitTimeInfoAction, (state: State) => ({
    ...state,
    benefitTimeInfo: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getBenefitTimeInfoSuccessAction, (state: State, {benefitTimeInfo}) => ({
    ...state,
    benefitTimeInfo: {
      isLoading: false,
      success: true,
      payload: benefitTimeInfo
    }
  })),
  on(getBenefitTimeInfoFailureAction, (state: State) => ({
    ...state,
    benefitTimeInfo: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(getPayeeListAction, (state: State) => ({
    ...state,
    payeeResponse: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getPayeeListSuccessAction, (state: State, {response}) => ({
    ...state,
    payeeResponse: {
      isLoading: false,
      success: true,
      payload: response
    }
  })),
  on(getPayeeListFailureAction, (state: State) => ({
    ...state,
    payeeResponse: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),
  on(clearPayeeListState, (state: State) => ({
    ...state,
    payeeResponse: {
      isLoading: true,
      payload: undefined,
    },
  })),

  on(getPaymentAddressAction, (state: State) => ({
    ...state,
    addressData: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getPaymentAddressSuccessAction, (state: State, {addressData}) => ({
    ...state,
    addressData: {
      isLoading: false,
      success: true,
      payload: addressData
    }
  })),
  on(getPaymentAddressFailureAction, (state: State) => ({
    ...state,
    addressData: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),
  on(clearPaymentAddressState, (state: State) => ({
    ...state,
    addressData: {
      isLoading: true,
      payload: undefined
    }
  })),

  on(clearCreateOneTimePaymentStateAction, (state: State) => ({
    ...state,
    createOneTimePayment: undefined
  })),
  on(createOneTimePaymentSuccessAction, (state: State, { response }) => ({
    ...state,
    createOneTimePayment: {
      isLoading: false,
      success: true,
      payload: response
    }
  })),
  on(createOneTimePaymentFailureAction, (state: State) => ({
    ...state,
    createOneTimePayment: {
      isLoading: false,
      success: false,
    }
  })),

  on(checkDisplayWithHoldingTaxAction, (state: State) => ({
    ...state,
    displayWithHoldingTax: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(checkDisplayWithHoldingTaxSuccessAction, (state: State, {isValid}) => ({
    ...state,
    displayWithHoldingTax: {
      isLoading: false,
      success: true,
      payload: isValid
    }
  })),
  on(checkDisplayWithHoldingTaxFailureAction, (state: State) => ({
    ...state,
    displayWithHoldingTax: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(getDeductionDataAction, (state: State) => ({
    ...state,
    deductionData: {
      isLoading: true,
      payload: undefined
    }
  })),
  on(getDeductionDataSuccessAction, (state: State, {response}) => ({
    ...state,
    deductionData: {
      isLoading: false,
      success: true,
      payload: response
    }
  })),
  on(getDeductionDataFailureAction, (state: State) => ({
    ...state,
    deductionData: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),
  on(clearDeductionDataStateAction, (state: State) => ({
    ...state,
    deductionData: {
      isLoading: true,
      payload: undefined
    }
  })),
);
