import { Injectable } from '@angular/core';
import {
  ValidatorFn,
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

import { DateTime } from 'luxon';

import { FundType } from '@ptg-shared/types/enums';
import { deepClone } from '@ptg-shared/utils/common.util';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { Option } from '@ptg-shared/controls/select/select.component';
import { DeductionType } from '@ptg-processing/features/payroll-calendar-container/types/enums';
import { CreateGeneralAdjustmentFormArrayKey } from '@ptg-member/types/enums/create-general-adjustment.enum';

import { EarningFundingSource } from '../../services/models';
import {
  SubTypeDeduction,
  TaxDeductionItem,
  CourtOrderDeduction,
  FundingSourceAdjustment,
  GetDeductionTypeResponse,
  BenefitDeductionAdjustment,
  FundingSourceFormArrayItem,
  ValidateGeneralAdjustmentRequest,
  ParsedDeductionSubTypeAndBenefitPeriodData,
} from '../../services/models/create-general-adjustment.model';

@Injectable()
export class CreateGeneralAdjustmentComponentService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly layoutService: LayoutService,
  ) {}

  // Fund type
  private get isChicagoParks(): boolean {
    return this.layoutService.fundType === FundType.ChicagoParks;
  }

  get getInitFormGroup(): FormGroup {
    return this.fb.group({
      benefitPeriodStartDate: null,
      benefitPeriodEndDate: null,
      [CreateGeneralAdjustmentFormArrayKey.FundingSources]: this.fb.array([]),
      deductionType: null,
      deductionTypeChips: this.fb.array([]),
      taxDeduction: this.fb.group({
        amount: [null, this.customAmountFieldValidator()],
        isNegativeAdjust: true,
        // Variables for requesting
        deductionId: null,
        deductionType: null,
        deductionSubType: null,
      }),
      [CreateGeneralAdjustmentFormArrayKey.Insurances]: this.fb.array([]),
      [CreateGeneralAdjustmentFormArrayKey.OtherDeductions]: this.fb.array([]),
      [CreateGeneralAdjustmentFormArrayKey.Garnishments]: this.fb.array([]),
      [CreateGeneralAdjustmentFormArrayKey.QDRODeductions]: this.fb.array([]),
      accountingPostDate: null,
      transactionDate: null,
      reason: null,
    });
  }

  changeUniqueRowOption(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArray: FormArray,
    targetFormGroup: AbstractControl,
  ): boolean {
    const addedFormGroupList = formArray.controls.filter((formGroup) => formGroup.get(formGroupKey)?.value);
    const notTargetFormGroupList = formArray.controls.filter((formGroup) => formGroup !== targetFormGroup);

    notTargetFormGroupList.forEach((formGroup) => {
      const optionList: Option[] = formGroup.value.displayOptionList;
      optionList.forEach((option) => {
        if (option.value === formGroup.get(formGroupKey)?.value) return;
        option.isHide = addedFormGroupList.some((addedFormGroup) =>
          this.isSameObjectValue(formArrayKey, formGroupKey, option, addedFormGroup.value),
        );
      });
    });

    const isGarnishmentOrQDRODeduction = [
      CreateGeneralAdjustmentFormArrayKey.Garnishments,
      CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
    ].includes(formArrayKey);

    if (isGarnishmentOrQDRODeduction) {
      const { payeeId = '', payeeName = '' } = targetFormGroup.value?.[formGroupKey] ?? {};
      targetFormGroup.get('payee')?.patchValue({
        payeeId,
        payeeName,
      });
    }

    // Show/Hide Add New Button on dropdown list changes
    const isHiddenAddNewButton = addedFormGroupList.every(
      (formGroup) => formGroup.value.displayOptionList.filter((option: Option) => !option.isHide).length === 1,
    );
    if (isHiddenAddNewButton) {
      formArray.controls = formArray.controls.filter((formGroup) => formGroup.get(formGroupKey)?.value);
    }
    return !isHiddenAddNewButton;
  }

  changeOnRemoveUniqueRow(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArray: FormArray,
    index: number,
  ): void {
    const removedFormGroup: AbstractControl = formArray.at(index);
    formArray.removeAt(index);

    if (!removedFormGroup.get(formGroupKey)?.value) return;
    formArray.controls.forEach((formGroup) => {
      const optionList: Option[] = formGroup.value.displayOptionList;
      const hiddenOption: Option | undefined = optionList.find((option) =>
        this.isSameObjectValue(formArrayKey, formGroupKey, option, removedFormGroup.value),
      );
      if (hiddenOption) hiddenOption.isHide = false;
    });
  }

  getNewUniqueRow(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArrayValue: any[],
    optionList: Option[],
  ): FormGroup {
    const childFormGroup = this.fb.group({
      [formGroupKey]: null,
      displayOptionList: this.getDisplayOptionListOnAddNewRow(formArrayKey, formGroupKey, formArrayValue, optionList),
      amount: [null, this.customAmountFieldValidator()],
      isNegativeAdjust: true,
    });

    const isGarnishmentOrQDRODeduction = [
      CreateGeneralAdjustmentFormArrayKey.Garnishments,
      CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
    ].includes(formArrayKey);

    if (isGarnishmentOrQDRODeduction) {
      childFormGroup.addControl('payee', this.fb.group({ payeeId: null, payeeName: null }));
    }
    return childFormGroup;
  }

  parsedFundingSourceData(data: EarningFundingSource[] | undefined): {
    fundingSourceOptionList: Option[];
    fundingSourceTitle: string;
  } {
    if (!data?.length) return { fundingSourceOptionList: [], fundingSourceTitle: '' };
    const earningItemIdList = data.map(({ earningItemId }) => earningItemId);

    return {
      fundingSourceOptionList: deepClone(data).map(({ fundingSourceId = '', earningItemId = '', label = '' }) => ({
        value: { fundingSourceId, earningItemId, fundingSourceName: label },
        displayValue: label,
      })),
      fundingSourceTitle: earningItemIdList.filter(
        (earningItemId, index) => earningItemIdList.indexOf(earningItemId) < index,
      ).length
        ? 'Funding Sources'
        : 'Earnings',
    };
  }

  parsedDeductionTypesAndBenefitPeriodValidationData(
    data: GetDeductionTypeResponse | undefined,
  ): ParsedDeductionSubTypeAndBenefitPeriodData | null {
    if (!data) return null;

    let deductionTypeList: number[] = [];
    let deductionTypeOptionList: Option[] = [];
    let taxDeductionItemList: TaxDeductionItem[] = [];
    let insuranceOptionList: Option[] = [];
    let otherDeductionOptionList: Option[] = [];
    let garnishmentOptionList: Option[] = [];
    let qdroDeductionOptionList: Option[] = [];
    let qdroDeductionTitle = '';

    const getDeductionTypeOptionListMapper = (item: SubTypeDeduction, deductionType: DeductionType) => ({
      displayValue: `${item.deductionCode} - ${item.subTypeName}`,
      value: {
        deductionType,
        deductionSubType: item.subTypeId,
        deductionId: item.payrollDeductionId,
        deductionCode: item.deductionCode,
        subTypeName: item.subTypeName,
      },
    });
    const getCourtOrderOptionListMapper = (item: CourtOrderDeduction, deductionType: DeductionType) => ({
      displayValue: `${item.caseNumber}`,
      value: {
        deductionType,
        deductionSubType: item.subTypeId,
        caseNumber: item.caseNumber,
        payeeId: item.payeeId,
        payeeName: item.payee,
      },
    });

    const {
      hasLumpsumpPension,
      isLumpsumpBenefit,
      listTypeOfDeductions,

      payrollBenefitStartDate,
      payrollBenefitEndDate,
      currentBenefitPeriodStartDate,
      doNotHaveFinalizedRecurringPayment,
      benefitPeriodEndDateOfLatestFinalizedRecurringPayment,
    } = data;

    deepClone(listTypeOfDeductions).forEach(
      ({ deductionType, courtOrderDeductions = [], deductionSubTypes = [], labelName = '' }) => {
        deductionTypeList.push(deductionType);

        if (deductionType === DeductionType.QDRO) qdroDeductionTitle = labelName;

        switch (deductionType) {
          case DeductionType.Tax:
            taxDeductionItemList = deductionSubTypes.map((item) => ({
              deductionId: item.payrollDeductionId,
              deductionSubType: item.subTypeId,
              deductionType: DeductionType.Tax,
            }));
            break;
          case DeductionType.Insurance:
            insuranceOptionList = deductionSubTypes.map((item) =>
              getDeductionTypeOptionListMapper(item, deductionType),
            );
            break;
          case DeductionType.Others:
            otherDeductionOptionList = deductionSubTypes.map((item) =>
              getDeductionTypeOptionListMapper(item, deductionType),
            );
            break;
          case DeductionType.Garnishment:
            garnishmentOptionList = courtOrderDeductions.map((item) =>
              getCourtOrderOptionListMapper(item, deductionType),
            );
            break;
          case DeductionType.QDRO:
            qdroDeductionOptionList = courtOrderDeductions.map((item) =>
              getCourtOrderOptionListMapper(item, deductionType),
            );
            break;
          default:
            break;
        }
      },
    );

    // To remove all duplicated items
    deductionTypeList = [...new Set(deductionTypeList)];

    // Create [Deduction Type] dropdown list data
    if (deductionTypeList.includes(DeductionType.Tax)) {
      deductionTypeOptionList.push({ value: DeductionType.Tax, displayValue: 'Tax' });
    }
    if (deductionTypeList.includes(DeductionType.Insurance)) {
      deductionTypeOptionList.push({ value: DeductionType.Insurance, displayValue: 'Insurance' });
    }
    if (deductionTypeList.includes(DeductionType.Others)) {
      deductionTypeOptionList.push({ value: DeductionType.Others, displayValue: 'Others' });
    }
    if (deductionTypeList.includes(DeductionType.Garnishment)) {
      deductionTypeOptionList.push({ value: DeductionType.Garnishment, displayValue: 'Garnishment' });
    }
    if (deductionTypeList.includes(DeductionType.QDRO)) {
      deductionTypeOptionList.push({
        value: DeductionType.QDRO,
        displayValue: this.isChicagoParks ? qdroDeductionTitle : 'QDRO',
      });
    }

    return {
      deductionTypeOptionList,
      taxDeductionItemList,
      insuranceOptionList,
      otherDeductionOptionList,
      garnishmentOptionList,
      qdroDeductionOptionList,
      qdroDeductionTitle,
      hasLumpsumpPension,
      isLumpsumpBenefit,

      payrollBenefitStartDate,
      payrollBenefitEndDate,
      currentBenefitPeriodStartDate,
      doNotHaveFinalizedRecurringPayment,
      benefitPeriodEndDateOfLatestFinalizedRecurringPayment,
    };
  }

  getValidateGeneralAdjustmentBodyRequest(
    benefitPeriodStartDate: DateTime | null,
    benefitPeriodEndDate: DateTime | null,
    deductionItems: {
      isShowTaxDeduction: boolean;
      isShowInsurance: boolean;
      isShowOtherDeduction: boolean;
      isShowGarnishment: boolean;
      isShowQdroDeduction: boolean;
      taxDeductionFormGroupValue?: any;
      insuranceFormArrayValue?: any[];
      otherDeductionFormArrayValue?: any[];
      garnishmentFormArrayValue?: any[];
      qdroDeductionFormArrayValue?: any[];
    },
    fundingSourceList: FundingSourceFormArrayItem[] = [],
  ): ValidateGeneralAdjustmentRequest {
    const startDate = benefitPeriodStartDate ? benefitPeriodStartDate.toFormat('yyyy-MM-dd') : undefined;
    const endDate = benefitPeriodEndDate ? benefitPeriodEndDate.toFormat('yyyy-MM-dd') : undefined;

    // Funding Sources request data
    const fundingSources: FundingSourceAdjustment[] = fundingSourceList.map(
      ({ fundingSource, amount }: FundingSourceFormArrayItem) => ({
        amount,
        fundingSourceId: fundingSource?.fundingSourceId,
        earningItemId: fundingSource?.earningItemId,
      }),
    );

    // Deduction Items request data
    const benefitDeductions: BenefitDeductionAdjustment[] = [];
    const totalDeductions = {
      ...(deductionItems.isShowTaxDeduction && { taxDeduction: deductionItems.taxDeductionFormGroupValue }),
      ...(deductionItems.isShowInsurance && { insuranceDeductions: deductionItems.insuranceFormArrayValue }),
      ...(deductionItems.isShowOtherDeduction && { otherDeductions: deductionItems.otherDeductionFormArrayValue }),
      ...(deductionItems.isShowGarnishment && { garnishmentDeductions: deductionItems.garnishmentFormArrayValue }),
      ...(deductionItems.isShowQdroDeduction && { qdroDeductions: deductionItems.qdroDeductionFormArrayValue }),
    };
    const { taxDeduction, insuranceDeductions, otherDeductions, garnishmentDeductions, qdroDeductions } =
      totalDeductions;

    // Tax Deductions
    if (taxDeduction) {
      const { isNegativeAdjust, ...rest } = taxDeduction;
      const taxes: BenefitDeductionAdjustment = rest;
      benefitDeductions.push(taxes);
    }
    // Insurance Deductions
    if (insuranceDeductions?.length) {
      const insurances: BenefitDeductionAdjustment[] = insuranceDeductions.map(({ amount, insurance }: any) => ({
        amount,
        ...insurance,
      }));
      benefitDeductions.push(...insurances);
    }
    // Other Deductions
    if (otherDeductions?.length) {
      const others: BenefitDeductionAdjustment[] = otherDeductions.map(({ amount, otherDeduction }: any) => ({
        amount,
        ...otherDeduction,
      }));
      benefitDeductions.push(...others);
    }
    // Garnishment Deductions
    if (garnishmentDeductions?.length) {
      const garnishments: BenefitDeductionAdjustment[] = garnishmentDeductions.map(({ amount, garnishment }: any) => ({
        amount,
        ...garnishment,
      }));
      benefitDeductions.push(...garnishments);
    }
    // QDRO Deductions
    if (qdroDeductions?.length) {
      const qdros: BenefitDeductionAdjustment[] = qdroDeductions.map(({ amount, qdroDeduction }: any) => ({
        amount,
        ...qdroDeduction,
      }));
      benefitDeductions.push(...qdros);
    }

    return {
      startDate,
      endDate,
      fundingSources,
      benefitDeductions,
    };
  }

  private getDisplayOptionListOnAddNewRow(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArrayValue: any[],
    optionList: Option[],
  ): FormControl {
    return this.fb.control(
      deepClone(optionList).map((option) => {
        option.isHide = formArrayValue.some((formGroupValue) =>
          this.isSameObjectValue(formArrayKey, formGroupKey, option, formGroupValue),
        );
        return option;
      }),
    );
  }

  private isSameObjectValue(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    option: Option,
    formGroupValue: any,
  ): boolean {
    let compareKey = '';
    switch (formArrayKey) {
      case CreateGeneralAdjustmentFormArrayKey.FundingSources:
        compareKey = 'fundingSourceId';
        break;
      case CreateGeneralAdjustmentFormArrayKey.Insurances:
      case CreateGeneralAdjustmentFormArrayKey.OtherDeductions:
      case CreateGeneralAdjustmentFormArrayKey.Garnishments:
      case CreateGeneralAdjustmentFormArrayKey.QDRODeductions:
        compareKey = 'deductionId';
        break;
      default:
        return false;
    }

    return option.value?.[compareKey] === formGroupValue[formGroupKey]?.[compareKey];
  }

  private customAmountFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control?.parent?.get('amount') || !control?.parent?.get('isNegativeAdjust')) {
        return null;
      }

      const amount = control.parent.get('amount')?.value;
      const isNegativeAdjust = control.parent.get('isNegativeAdjust')?.value;

      if (amount > 0 && isNegativeAdjust === true) {
        return { invalidAmount: 'Cannot enter positive amount.' };
      }
      if (amount < 0 && isNegativeAdjust === false) {
        return { invalidAmount: 'Cannot enter negative amount.' };
      }
      return null;
    };
  }
}
