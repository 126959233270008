<div class="estimator-config-container" id="estimator-config-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div [ngClass]="{ banner: bannerType }">
    <ptg-banner
      *ngIf="bannerType && !isLoading"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>
  </div>
  <ptg-dialog *ngIf="!isLoading"
    class="edit-entity-dialog"
    [footerPosition]="'top'"
    [footerAlign]="'left'"
    [isFullScreen]="true"
  >
    <ng-template dialogBody>
      <div class="flex flex-col gap-6">
        <ptg-panel [title]="entity?.entityName + ' Configuration'" [noPaddingBody]="true" >
          <ng-template panelExtraHeader>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="onClickEditEntity()"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
          </ng-template>
          <ng-template panelBody>
            <div class="detail-container">
              <div class="detail">
                <span class="label">Entity Name</span>
                <span class="value truncate">{{ entity?.entityName || "-" }}</span>
              </div>
              <div class="detail">
                <span class="label">Entity Code</span>
                <span class="value truncate">{{ entity?.code || "-" }}</span>
              </div>
              <div class="detail">
                <span class="label">Parent Entity</span>
                <span class="value truncate">{{ entity?.parentName || "-" }}</span>
              </div>
              <div class="detail">
                <span class="label">Enable Versioning</span>
                <span class="value truncate">{{ entity?.isEnableVersioning ? "Yes" : "No" || "-" }}</span>
              </div>
              <div class="detail">
                <span class="label">Entity Key</span>
                <span class="value truncate">{{ entity?.entityKey || "-" }}</span>
              </div>
              <div class="detail">
                <span class="label">Display Field</span>
                <span class="value truncate">{{ entity?.displayField || "-" }}</span>
              </div>
            </div>
            <div class="detail-container description">
              <div class="detail" style="max-width: 100%">
                <span class="label">Description</span>
                <span class="value truncate">{{ entity?.description || "-" }}</span>
              </div>
            </div>
          </ng-template>
        </ptg-panel>

        <ptg-panel [title]="'Property'" [noPaddingBody]="true">
          <ng-template panelExtraHeader>
            <ptg-button
              buttonName="New Property"
              classInput="edit-button"
              (clickButton)="onClickAddNewProperty()"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </ng-template>

          <ng-template panelBody>
            <ptg-grid
              [data]="propertiesData"
              [columns]="propertiesColumns"
              [fitToParent]="true"
              [isLoading]="isLoadingProperty"
              [totalRecords]="totalPropertyRecords"
              [pageSize]="pageSizeProperty"
              [pageNumber]="pageNumberProperty"
              [paginable]="true"
              (sortChange)="onChangeSortProperty($event)"
              (pageChange)="onChangePageProperty($event)"
              notFoundMessage="No Property to Display"
            >
              <ng-template cellContent columnName="action" let-row>
                <ptg-button
                  *ngIf="!row.inheritance"
                  buttonName="Edit"
                  classInput="edit-button"
                  (clickButton)="onClickEditProperty(row)"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
                <ptg-button
                  *ngIf="!row.isStatic && !row.inheritance"
                  buttonName="Remove"
                  classInput="remove-button"
                  (clickButton)="onClickRemovePropertyOrList(row, false)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </ptg-button>
                <span *ngIf="row.inheritance" class="inheritance">
                  Inherited
                </span>
              </ng-template>
            </ptg-grid>
          </ng-template>
        </ptg-panel>

        <ptg-panel [title]="'List'" [noPaddingBody]="true">
          <ng-template panelExtraHeader>
            <ptg-button
              buttonName="New List"
              classInput="edit-button"
              (clickButton)="onClickAddNewList()"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </ng-template>

          <ng-template panelBody>
            <ptg-grid
              [isLoading]="isLoadingSection"
              [data]="sectionsData"
              [columns]="sectionsColumns"
              [fitToParent]="true"
              [paginable]="true"
              [totalRecords]="totalRecordsSection"
              [pageSize]="pageSizeSection"
              [pageNumber]="pageNumberSection"
              (pageChange)="onChangePageSection($event)"
              (sortChange)="onChangeSortSection($event)"
              notFoundMessage="No Section to Display"
            >
              <ng-template cellContent columnName="action" let-row>
                <ptg-button
                  *ngIf="!row.inheritance"
                  buttonName="Edit"
                  classInput="edit-button"
                  (clickButton)="onClickEditList(row)"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
                <ptg-button
                  *ngIf="!row.inheritance"
                  buttonName="Remove"
                  classInput="remove-button"
                  (clickButton)="onClickRemovePropertyOrList(row)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </ptg-button>
                <span *ngIf="row.inheritance" class="inheritance">
                  Inherited
                </span>
              </ng-template>
            </ptg-grid>
          </ng-template>
        </ptg-panel>
      </div>
    </ng-template>
  </ptg-dialog>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>
