import { PayrollType } from '@ptg-processing/features/one-time-payments/services/models';
import { TransactionType } from '@ptg-processing/features/report/types/enums';

export interface GetPaymentRunsRequest {
  transactionType: TransactionType;
  timeZoneOffset: number;

  // Recurring
  benefitStartDate?: string;
  benefitEndDate?: string;

  // One-time
  payableStartDate?: string;
  payableEndDate?: string;
  excludeOnetime?: boolean;
  reportType?: string;
}

export interface GetPaymentRunsResponse {
  paymentRuns: PaymentRun[];
}

export interface PaymentRun {
  id: string;
  type: PayrollType;
  createdDate?: string;
  startDate?: string;
  endDate?: string;
  finalizedDate?: string
}
