export interface PaymentCurrent {
  payrollId: string;
  payrollDate: string;
  numberOfPayees: number;
  gross: number;
}

export interface ListPayment {
  total: number;
  paymentDetailHistory: PaymentCurrent[];
}

export interface PayrollRun {
  id?: string;
  runInformationId?: string;
  calendarName?: string;
  payableDate?: string;
  startDate?: string;
  endDate?: string;
  configuredFundInformation?: boolean;
  configuredPayeeInformation?: boolean;
}

export interface GetPayrollRunRequest {
}

export interface GetPayrollRunResponse {
  payrollRun: PayrollRun;
  configuredPayrollCalendar?: boolean;
}

