import { createAction, props } from '@ngrx/store';
import { GenerateLateFeeReminderLetterRequest, GetLateFeeReminderLetterRequest, GetLateFeeReminderLetterResponse, ValidateBeforeGenerateRequest, ValidateBeforeGenerateResponse } from '@ptg-employer/models/late-fee-reminder.model';
import { PreviewFileResponse } from 'src/app/admin/features/file/services/models/documents.model';

// Get Late Fee Reminder Letter
export const getLateFeeReminderLetterAction = createAction(
  '[GetLateFeeReminderLetterAction/API] Send Request',
  props<{ request: GetLateFeeReminderLetterRequest }>()
);
export const getLateFeeReminderLetterSuccessAction = createAction(
  '[GetLateFeeReminderLetterAction/API] Success',
  props<{ response: GetLateFeeReminderLetterResponse }>()
);
export const getLateFeeReminderLetterFailureAction = createAction(
  '[GetLateFeeReminderLetterAction/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetLateFeeReminderLetterStateAction = createAction(
  '[GetLateFeeReminderLetterAction] Clear'
);

// Generate Late Fee Reminder Letter
export const generateLateFeeReminderLetterAction = createAction(
  '[GenerateLateFeeReminderLetter/API] Send Request',
  props<{ request: GenerateLateFeeReminderLetterRequest }>()
);
export const generateLateFeeReminderLetterSuccessAction = createAction(
  '[GenerateLateFeeReminderLetter/API] Success',
);
export const generateLateFeeReminderLetterFailureAction = createAction(
  '[GenerateLateFeeReminderLetter/API] Failure',
  props<{ error?: any }>()
);
export const clearGenerateLateFeeReminderLetterStateAction = createAction(
  '[GenerateLateFeeReminderLetter] Clear'
);

// Validate Before Generate
export const validateBeforeGenerateAction = createAction(
  '[ValidateBeforeGenerateLateFeeReminder/API] Send Request',
  props<{ request: ValidateBeforeGenerateRequest }>()
);
export const validateBeforeGenerateSuccessAction = createAction(
  '[ValidateBeforeGenerateLateFeeReminder/API] Success',
  props<{ response: ValidateBeforeGenerateResponse }>()
);
export const validateBeforeGenerateFailureAction = createAction(
  '[ValidateBeforeGenerateLateFeeReminder/API] Failure',
  props<{ error?: unknown }>()
);
export const clearValidateBeforeGenerateStateAction = createAction(
  '[ValidateBeforeGenerateLateFeeReminder] Clear'
);

// Download Late Fee Reminder Letter
export const getLateFeeReminderLetterDownloadAction = createAction(
  '[GetLateFeeReminderLetterDownload/API] Send Request',
  props<{ fileId: string }>()
);
export const getLateFeeReminderLetterDownloadSuccessAction = createAction(
  '[GetLateFeeReminderLetterDownload/API] Success',
  props<{ response: PreviewFileResponse }>()
);
export const getLateFeeReminderLetterDownloadFailureAction = createAction(
  '[GetLateFeeReminderLetterDownload/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetLateFeeReminderLetterDownloadStateAction = createAction(
  '[GetLateFeeReminderLetterDownload] Clear'
);
