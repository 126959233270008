import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';
import { RemittanceSubmissionService } from '@ptg-employer/services/remittance-submission.service';

@Directive({
  selector: '[ptgSearchRemittanceSubmissionBold]'
})
export class SearchRemittanceSubmissionBoldDirective implements AfterViewChecked {
  @Input() searchClassName: string = 'value';

  constructor(private elementRef: ElementRef, private remittanceSubmissionService: RemittanceSubmissionService) { }

  ngAfterViewChecked(): void {
    if (this.remittanceSubmissionService.searchValue && this.elementRef.nativeElement) {
      this.boldText();
    }
  }

  boldText() {
    let searchValues = this.remittanceSubmissionService.searchValue.split(' ').filter(value => value?.trim()).join('|');
    searchValues = searchValues.replace(new RegExp('>', 'gi'), (match) => `&gt;`);
    searchValues = searchValues.replace(new RegExp('<', 'gi'), (match) => `&lt;`);
    const regex = new RegExp(searchValues, 'gi');
    const element = this.elementRef.nativeElement.getElementsByClassName(this.searchClassName)[0] as HTMLElement;
    if (element) {
      let innerText = element.innerText;
      innerText = innerText.replace(new RegExp('>', 'gi'), (match) => `&gt;`);
      innerText = innerText.replace(new RegExp('<', 'gi'), (match) => `&lt;`);
      innerText = innerText.replace(regex, (match) => `<b style="pointer-events: none;">${ match }</b>`); // [cuongnh20] Add pointer-events = none to fix bug #103944
      element.innerHTML = innerText;
    }
  }

}
