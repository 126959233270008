<mat-form-field class="rich-text-editor-container" appearance="fill">
  <mat-label 
    *ngIf="hasFloatLabel"
    [ngClass]="[
      (controlField?.hasValidator(Validators.required) || shouldShowRequiredIndicator)
        ? REQUIRED_INDICATOR
        : '',
    ]"
  >{{placeholder}}</mat-label>
  <quill-editor #quillEditor
                *ngIf="controlField"
                ptgCustomRichTextEditor
                [hasFloatLabel]="hasFloatLabel"
                class="content-editor"
                [ngClass]="{'float-label': hasFloatLabel}"
                [placeholder]="placeholder"
                [formControl]="controlField"
                [modules]="quillConfiguration"
                (onContentChanged)="textChanged($event)"
  ></quill-editor>
  <mat-error *ngIf="errorRequire || controlField?.touched && controlField?.errors?.required">{{errorRequire}}</mat-error>
  <mat-error *ngIf="controlField?.errors?.maxLength">{{errorMaxLength}}</mat-error>
</mat-form-field>
