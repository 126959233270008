<div class="drop-plan-container" [class.show-banner]="bannerType">
    <ptg-breadcrumb
        [listBreadcrumbs]="listBreadcrumbs"
        [settings]="settings"
    ></ptg-breadcrumb>

    <div class="drop-banner" *ngIf="bannerType">
        <ptg-banner
        [(bannerType)]="bannerType"
        [message]="message"
        ></ptg-banner>
    </div>

    <ptg-grid
        #gridGeneratedReport
        [data]="generatedReport"
        [columns]="columns"
        [notFoundMessage]="notFoundMessage"
        keyColumn="id"
        [errorMessage]="errorMsg"
        [isLoading]="isLoading"
        [fitToParent]="true"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="onChangePage($event)"
        (sortChange)="onChangeSort($event)">
        <ng-template cellContent [columnName]='"fileName"' let-row>
            <a *ngIf="row.fileName" class="flex items-center gap-1 link"  href="javascript:void(0)" (click)="onClickHyperlink(row)">
                <span class="file-name truncate">{{row?.fileName}}</span>
            </a>
        </ng-template>
        <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
            <ptg-button buttonName="Remove" classInput="delete-button"  (clickButton)="onRemoveClick(row)">
                <mat-icon>delete_forever</mat-icon>
            </ptg-button>
        </ng-template>
    </ptg-grid>
</div>
