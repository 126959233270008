import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const calculationBenefitDetailsSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.getCalculationBenefitDetails,
);

export const getCalculationBenefitDetailsSelector = createSelector(
  calculationBenefitDetailsSelector,
  (state) => {
    return state?.getGetCalculationBenefitDetails
  }
);
export const getSetBenefitDetailSelector = createSelector(
  calculationBenefitDetailsSelector,
  (state) => state.setBenefitDetail,
);

export const createGenerateCalculationWorksheetSelector = createSelector(
  calculationBenefitDetailsSelector,
  (state) => state?.createGenerateCalculationWorksheet,
);

export const getListEmployerTypesSelector = createSelector(
  calculationBenefitDetailsSelector,
  (state) => state?.getListEmployerTypes,
);

export const getBankConfigurationSelector = createSelector(
  calculationBenefitDetailsSelector,
  (state) => state?.getBankConfiguration
);

