import { ParticipantPropertyType } from '@ptg-shared/constance/value.const';

import { SectionType } from '@ptg-employer/models/employer.model';
import { PropertyType } from '../../constance/metadata.const';
import { DisplaySectionWODataType, SideType } from '../enums';
import { Status } from './profile-navigation-configuration.model';

export interface PropertyDisplayConfiguration {
  id?: string;
  clientKey?: string;
  propertyName?: string;
  propertyKey?: string;
  headerItemKey?: string;
  headerItemType?: string;
  sectionName?: string;
  sectionKey: string;
  columnName: string;
  orderColumn?: number;
  options: string;
  orderRow?: number;
  sortType?: number;
  columnNameDescription?: string;
  order?: number;
  optionName?: string;
  deleted?: boolean;
  isUsed?: boolean;
  isEnable?: boolean;
  columnNameMaxLength?: number;
  aggregationId?: string;
  aggregationName?: string;
  headerType?: number;
  calculationId?: string;
  calculationName?: string;
  propertyType?: number;
  cardId?: string
}

export interface PropertyDisplayConfig {
  propertyName: string;
  propertyKey: string;
  sectionKey: string;
  sectionName: string;
  options: { key: string; value: string }[];
  aggregationId?: string;
  aggregationName?: string;
  calculationId?: string;
  calculationName?: string;
  headerType?: ParticipantPropertyType;
}

export interface ProfileHeaderConfigurations {
  metadataProfileHeaderConfigurations: PropertyDisplayConfiguration[];
}

export interface MemberListConfigurations {
  listMetadataMemberListConfig: PropertyDisplayConfiguration[];
  memberNavigationItemId?: string;
}

export interface ListConfig {
  listConfig: PropertyDisplayConfiguration[];
}

export interface PropertyConfigs {
  listSectionProperty: PropertyDisplayConfig[];
}

export interface SectionConfig {
  title: string;
  columnName?: string;
  propertyName?: string;
}

export interface ProfileOverviewConfigurations {
  listConfig: PropertyDisplayConfiguration[];
}

export interface SectionMetadataList {
  sectionMetadata: SectionMetadata[];
}

export interface SectionMetadata {
  clientKey?: string;
  sectionKey: string;
  sectionName: string;
  options?: SectionMetadataOptions[];
  isStatic?: boolean;
  type?: SectionType;
}

export interface SectionMetadataOptions {
  propertyName: string;
  propertyKey: string;
}

export interface SetProfileOverviewRequest {
  metadataProfileOverview?: {
    id?: string;
    name?: string;
    statuses: Status[];
    displayNonListSectionWOData: boolean;
    displayListSectionWOData: boolean;
    metadataProfileOverviewConfigurations: ProfileOverviewConfiguration[];
  };
}

export interface MetadataProfileHeaderConfiguration {
  id?: string;
  name: string;
  statuses: Status[];
  isStatic: boolean;
  totalProfileHeaderConfig?: number;
  metadataProfileHeaderConfigurations: PropertyDisplayConfiguration[];
  allStatuses?: Status[];
}

export interface ProfileOverviewConfiguration {
  id?: string;
  clientKey?: string;
  sectionKey: string;
  sectionName: string;
  name: string;
  order: number;
  options: string;
  onSide: SideType;
  showSectionDataless: boolean;
  showPropertyDataless: boolean;
  isRemoved: boolean;
  isStatic?: boolean;
  sectionType?: SectionType;
}

export interface CheckboxOption {
  value: { propertyKey: string; propertyName: string };
  checked: boolean;
  disabled?: boolean;
}

export interface ProfileHeader {
  columnName?: string;
  value: any;
  typedValue: any;
  type: PropertyType;
}

export interface ProfileHeaderData {
  profileHeaders: ProfileHeader[];
  memberStatusId: string;
}

export interface ProfileOverviewList {
  metadataProfileOverviews: ProfileOverview[];
  statuses: Status[];
  total: number;
}

export interface ProfileOverview {
  id: string;
  clientKey: string;
  name: string;
  statuses: Status[];
  isStatic: boolean;
  totalProfileConfig: number;
  displayNonListSectionWOData: DisplaySectionWODataType;
  displayListSectionWOData: DisplaySectionWODataType;
  metadataProfileOverviewConfigurations: ProfileOverviewConfiguration[];
}

export interface ProfileOverviewDetail {
  metadataProfileOverview: ProfileOverview;
  statuses: Status[];
}

export interface StatusOption {
  key?: string;
  id?: string;
  value: string;
  color: string;
  iconName: string;
  order: number;
}

export interface ProfileHeaderList {
  metadataProfileHeaders: MetadataProfileHeaderConfiguration[];
  statuses: Status[];
  total: number;
}

export interface ParticipantSearchConfigurations {
  listMemberSearchConfig: PropertyDisplayConfiguration[];
}
