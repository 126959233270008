import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { GetCardsByViewResponse, GetEntityViewsResponse } from './models';

@Injectable()
export class EntityViewService {
  constructor(private httpClient: HttpClient) {}

  getEntityViewsByEntity(entityId: string): Observable<GetEntityViewsResponse> {
    return this.httpClient.get<GetEntityViewsResponse>(
      `${environment.apiUrl}/entity/entities/${entityId}/entity-views`,
    );
  }

  getEntityViewsByCard(cardId: string): Observable<GetEntityViewsResponse> {
    return this.httpClient.get<GetEntityViewsResponse>(`${environment.apiUrl}/entity/cards/${cardId}/entity-views`);
  }

  getCardsByView(viewId: string): Observable<GetCardsByViewResponse> {
    return this.httpClient.get<GetCardsByViewResponse>(`${environment.apiUrl}/entity/views/${viewId}/cards`);
  }
}
