import { createSelector } from '@ngrx/store';

import { selectPayeeDetailModuleState } from './selector';

export const earningFundingSourceSelector = createSelector(
  selectPayeeDetailModuleState,
  (state) => state?.editFundingSource,
);

export const getEarningFundingSourcesSelector = createSelector(
  earningFundingSourceSelector,
  (state) => state?.getEarningFundingSources,
);

export const createEditEarningItemDetailsSelector = createSelector(
  earningFundingSourceSelector,
  (state) => state?.createEditEarningItemDetails,
);

export const editDeductionFundingSourcesSelector = createSelector(
  earningFundingSourceSelector,
  (state) => state?.editDeductionFundingSources,
);
