import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import {
  GetTypesOfDeductionsRequest,
  GetTypesOfDeductionsResponse,
  SetDeductionsRequest,
} from '@ptg-member/features/payee-detail/services/models/edit-deductions.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class EditDeductionsService {
  constructor(private httpClient: HttpClient) {}

  getTypesOfDeductions(request: GetTypesOfDeductionsRequest): Observable<GetTypesOfDeductionsResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<GetTypesOfDeductionsResponse>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${request.paymentInstructionId}/member/${request.memberId}/edit-deductions/types-of-deduction`,
      { context },
    );
  }

  setDeductions(paymentInstructionId: string, body: SetDeductionsRequest): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put<void>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${paymentInstructionId}/edit-deductions`,
      body,
      { context },
    );
  }
}
