import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SortType } from '@ptg-shared/constance';
import { CheckUniqueTagRequest, GetTagListRequest, Tag } from './models/tag.models';

@Injectable()
export class TagService {
  constructor(private httpClient: HttpClient) {
  }

  addTag(tagRequest: Tag): Observable<any> {
    return this.httpClient.post<Tag>(
      `${environment.apiUrl}/Files/document-tag`,
      tagRequest,
    );
  }

  editTag(tagRequest: Tag): Observable<any> {
    return this.httpClient.put<Tag>(
      `${environment.apiUrl}/Files/document-tag/${tagRequest.id}`,
      tagRequest,
    );
  }

  getTagList(request: GetTagListRequest): Observable<any> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.search) {
      params = params.append('search', request.search);
    }

    return this.httpClient.get(
      `${environment.apiUrl}/Files/document-tag`,
      { params },
    );
  }

  removeTag(tagId: string): Observable<any> {
    return this.httpClient.delete(
      `${environment.apiUrl}/Files/document-tag/${tagId}`,
    );
  }

  checkExistsTag(request: CheckUniqueTagRequest) {
    let params = new HttpParams();

    if (request.id) {
      params = params.append('id', request.id);
    }

    params = params.append('search', request.name);

    return this.httpClient.get(
      `${environment.apiUrl}/Files/document-tag/existing`,
      { params },
    );
  }
}
