import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import { GetStepConfigurationResponse } from '@ptg-member/features/calculation/services/models';
import { CalculationType, GetListSectionRecordValuesType } from '@ptg-member/features/calculation/types/enums';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  CreateRetirementBenefitRequest,
  GetDetectRecalculationBenefitRequest,
  GetDetectRecalculationBenefitResponse,
  GetValidateBeforeRecalculationBenefitResponse,
} from './models/retirement-benefit-dialog.model';
import { isNullOrUndefinedOrEmptyString } from '@ptg-shared/utils/string.util';

@Injectable()
export class RetirementBenefitDialogService {
  constructor(private httpClient: HttpClient) {}

  getListSectionRecordValues(
    memberId: string,
    sectionKey: string,
    query: { index: number; type: GetListSectionRecordValuesType },
  ): Observable<any> {
    let params = new HttpParams({ fromObject: query });

    return this.httpClient.get<any>(
      `${environment.apiUrl}/members/${memberId}/${sectionKey}/GetListSectionRecordValues`,
      { params },
    );
  }

  checkExits = (bodyCheck: any) => {
    let params = new HttpParams();
    params = params.append('documentName', bodyCheck.name);
    if (bodyCheck?.fileId) {
      params = params.append('fileId', bodyCheck.fileId);
    }

    if (!isNullOrUndefinedOrEmptyString(bodyCheck?.calculationType)) {
      params = params.append('calculationType', bodyCheck.calculationType);
    }

    const memberId = bodyCheck.memberId;
    return this.httpClient.get<any>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/exists`,
      { params },
    );
  };

  getStepConfigurationValues(memberId: string, benefitTypeId: string, benefitSubTypeId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('benefitTypeId', benefitTypeId);
    params = params.append('benefitSubTypeId', benefitSubTypeId);
    return this.httpClient.get<any>(`${environment.apiUrl}/members/${memberId}/StepConfigurationValues`, { params });
  }

  createRetirementBenefit(request: CreateRetirementBenefitRequest): Observable<string> {
    const formData = new FormData();
    formData.append('benefitEntityId', request.benefitEntityId);
    formData.append('calculationType', request.calculationType.toString());
    request.files?.forEach((item, index) => {
      formData.append(`files[${index}].id`, item.id || '');
      formData.append(`files[${index}].uploadDate`, new Date(item.uploadDate).toISOString() ?? '');
      formData.append(`files[${index}].documentName`, item.documentName || '');
      formData.append(`files[${index}].type`, item.type?.toString() || '');
      formData.append(`files[${index}].documentTypeName`, item.documentTypeName || '');
      formData.append(`files[${index}].file`, item.file || '');

      if (item?.tags?.length) {
        item?.tags?.forEach((tag, idx) => {
          formData.append(`files[${index}].tags[${idx}]`, tag);
        });
      }

      formData.append(`files[${index}].calculationBenefitId`, item?.calculationBenefitId || '');
      formData.append(`files[${index}].fileName`, item.fileName || '');
      formData.append(`files[${index}].documentLocationTitle`, item.documentLocationTitle || '');
      formData.append(`files[${index}].documentLocationRouter`, item.documentLocationRouter || '');
      formData.append(`files[${index}].documentDescription`, item.documentDescription || '');
      formData.append(`files[${index}].showOnOverview`, (!!item?.showOnOverview as boolean).toString());
    });
    request.propertyValueFromPriorSteps?.forEach((item, index) => {
      formData.append(`propertyValueFromPriorSteps[${index}].calculationStepId`, item.calculationStepId || '');
      formData.append(
        `propertyValueFromPriorSteps[${index}].calculationParamMappingId`,
        item.calculationParamMappingId || '',
      );
      formData.append(`propertyValueFromPriorSteps[${index}].id`, item.id || '');
      formData.append(`propertyValueFromPriorSteps[${index}].order`, item.order?.toString() ?? '0');
      formData.append(`propertyValueFromPriorSteps[${index}].orderColumn`, item.orderColumn?.toString() ?? '0');
      formData.append(`propertyValueFromPriorSteps[${index}].value`, item.value || '');
      Object.entries(item.inputConfigs).forEach(([key, value]) => {
        formData.append(`propertyValueFromPriorSteps[${index}].configs.${key}`, value);
      });
    });
    if (!request.propertyValueFromPriorSteps?.length) {
      formData.append('propertyValueFromPriorSteps', '[]');
    }
    if (request.recalculateModel) {
      Object.entries(request.recalculateModel).forEach(([key, value]) => {
        formData.append(`recalculateModel.${key}`, value);
      });
    }
    if (request.alternatePayeeId) {
      formData.append('alternatePayeeId', `${request.alternatePayeeId}`);
    }
    if (request?.childId) {
      formData.append('childId', `${request.childId}`);
    }
    if (request?.rowId) {
      formData.append('rowId', `${request.rowId}`);
    }
    formData.append('fundType', `${request.fundType}`);
    const memberId = request.memberId;
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post<string>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/initiate`,
      formData,
      { context }
    );
  }

  getStepConfigurationDetailByBenefitOption(
    memberId: string,
    benefitEntityId: string,
    calculationType?: number,
  ): Observable<GetStepConfigurationResponse> {
    let params = new HttpParams();
    if (typeof calculationType === 'number') params = params.append('calculationType', calculationType);
    return this.httpClient.get<GetStepConfigurationResponse>(
      `${environment.apiUrl}/calculations/${memberId}/calculation-options/step-configurations/benefit-options/${benefitEntityId}`,
      { params },
    );
  }

  getDetectRecalculationBenefit(
    request: GetDetectRecalculationBenefitRequest,
  ): Observable<GetDetectRecalculationBenefitResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    let params = new HttpParams();
    if (request?.courtOrderId) {
      params = params.append('courtOrderId', request.courtOrderId);
    }
    if (request?.benefitEntityId) {
      params = params.append('benefitEntityId', request.benefitEntityId);
    }
    if (request?.childId) {
      params = params.append('childId', request.childId);
    }
    return this.httpClient.get<GetDetectRecalculationBenefitResponse>(
      `${environment.apiUrl}/calculations/${request.memberId}/benefit-processing/calculation-benefit-histories/${request.calculationType}/detect-recalculate-benefit`,
      { params, context },
    );
  }

  getValidateBeforeRecalculationBenefit(
    memberId: string,
    calcAsOfDate: string,
  ): Observable<GetValidateBeforeRecalculationBenefitResponse> {
    const params = new HttpParams({ fromObject: { calcAsOfDate } });
    return this.httpClient.get<GetValidateBeforeRecalculationBenefitResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/validate-before-recalculate-benefit`,
      { params },
    );
  }
}
