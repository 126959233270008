import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BenefitCalculationConfigurationService } from '../../services';
import {
  getBenefitCalculationParameterMappingAction,
  getBenefitCalculationParameterMappingSuccessAction,
  getBenefitCalculationParameterMappingFailureAction,
  getCalculationDisplayConfigurationByTypeAction,
  getCalculationDisplayConfigurationByTypeSuccessAction,
  getCalculationDisplayConfigurationByTypeFailureAction,
  setModifyCalculationDisplayConfigurationAction,
  setModifyCalculationDisplayConfigurationSuccessAction,
  setModifyCalculationDisplayConfigurationFailureAction,
  completeCalculationBenefitSuccessAction,
  completeCalculationBenefitFailureAction,
  completeCalculationBenefitAction,
  approveCalculationBenefitAction,
  approveCalculationBenefitFailureAction,
  approveCalculationBenefitSuccessAction,
  reopenCalculationBenefitAction,
  reopenCalculationBenefitFailureAction,
  reopenCalculationBenefitSuccessAction,
  validateCalculationBenefitAction,
  validateCalculationBenefitSuccessAction,
  validateCalculationBenefitFailureAction,
  computeCalculationBenefitAction,
  computeCalculationBenefitSuccessAction,
  computeCalculationBenefitFailureAction,
  validateBeforeCalculationBenefitAction,
  validateBeforeCalculationBenefitFailureAction,
  validateBeforeCalculationBenefitSuccessAction,
} from '../actions';
import {
  ParameterMappings,
  BenefitCalculationConfigurationsByType,
  CheckComputeDataResponse,
  ComputationDataResponse,
  CheckStepConfigurationExistsResponse,
} from '@ptg-member/features/calculation/services/models';

@Injectable()
export class BenefitCalculationConfigurationEffects {
  constructor(
    private actions$: Actions,
    private benefitCalculationConfigurationService: BenefitCalculationConfigurationService,
  ) { }

  // GetCalculationParameterMapping
  getCalculationParameterMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBenefitCalculationParameterMappingAction),
      switchMap(({ parameterType }) =>
        this.benefitCalculationConfigurationService.getCalculationParameterMapping(parameterType).pipe(
          map((response: ParameterMappings) => getBenefitCalculationParameterMappingSuccessAction({ response })),
          catchError((error) => of(getBenefitCalculationParameterMappingFailureAction({ error }))),
        ),
      ),
    ),
  );

  // GetCalculationDisplayConfigurationByType
  getCalculationDisplayConfigurationByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationDisplayConfigurationByTypeAction),
      switchMap(({ calculationType }) =>
        this.benefitCalculationConfigurationService.getCalculationDisplayConfigurationByType(calculationType).pipe(
          map((response: BenefitCalculationConfigurationsByType) =>
            getCalculationDisplayConfigurationByTypeSuccessAction({
              response,
            }),
          ),
          catchError((error) => of(getCalculationDisplayConfigurationByTypeFailureAction({ error }))),
        ),
      ),
    ),
  );

  // ModifyCalculationDisplayConfiguration
  setModifyCalculationDisplayConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setModifyCalculationDisplayConfigurationAction),
      switchMap(({ id, request }) =>
        this.benefitCalculationConfigurationService.setModifyCalculationDisplayConfiguration(id, request).pipe(
          map(() => setModifyCalculationDisplayConfigurationSuccessAction()),
          catchError((error) => of(setModifyCalculationDisplayConfigurationFailureAction({ error }))),
        ),
      ),
    ),
  );

  // CompleteCalculationBenefit
  completeCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeCalculationBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.benefitCalculationConfigurationService
          .completeCalculationBenefit(memberId, calculationBenefitId, calculationType)
          .pipe(
            map(() => completeCalculationBenefitSuccessAction()),
            catchError((error) => of(completeCalculationBenefitFailureAction({ error }))),
          ),
      ),
    ),
  );

  // ApproveCalculationBenefit
  approveCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveCalculationBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType, body }) =>
        this.benefitCalculationConfigurationService
          .approveCalculationBenefit(memberId, calculationBenefitId, calculationType, body)
          .pipe(
            map(() => approveCalculationBenefitSuccessAction()),
            catchError((error) => of(approveCalculationBenefitFailureAction({ error }))),
          ),
      ),
    ),
  );

  // ReopenCalculationBenefit
  reopenCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reopenCalculationBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.benefitCalculationConfigurationService
          .reopenCalculationBenefit(memberId, calculationBenefitId, calculationType)
          .pipe(
            map(() => reopenCalculationBenefitSuccessAction()),
            catchError((error) => of(reopenCalculationBenefitFailureAction({ error }))),
          ),
      ),
    ),
  );

  // validate CalculationBenefit
  validateCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateCalculationBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType, processToStatus, calculationRecordId }) =>
        this.benefitCalculationConfigurationService
          .validateCalculationBenefit(memberId, calculationBenefitId, calculationType, processToStatus, calculationRecordId)
          .pipe(
            map((response: CheckComputeDataResponse) => validateCalculationBenefitSuccessAction({ response })),
            catchError((error) => of(validateCalculationBenefitFailureAction({ error }))),
          ),
      ),
    ),
  );

  // compute data Benefit
  computeCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(computeCalculationBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType }) =>
        this.benefitCalculationConfigurationService
          .computeCalculationBenefit(memberId, calculationBenefitId, calculationType)
          .pipe(
            map((response: ComputationDataResponse) => computeCalculationBenefitSuccessAction({ response })),
            catchError((error) => of(computeCalculationBenefitFailureAction({ error }))),
          ),
      ),
    ),
  );

  // validate before compute Benefit with JointSurvivor
  validateBeforeCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateBeforeCalculationBenefitAction),
      switchMap(({ memberId, calculationBenefitId, calculationType, sectionType }) =>
        this.benefitCalculationConfigurationService
          .validateBeforeComputeBenefitWithJointSurvivor(memberId, calculationType, calculationBenefitId)
          .pipe(
            map((response: CheckStepConfigurationExistsResponse) =>
              validateBeforeCalculationBenefitSuccessAction({ response, sectionType }),
            ),
            catchError((error) => of(validateBeforeCalculationBenefitFailureAction({ error }))),
          ),
      ),
    ),
  );
}
