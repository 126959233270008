<ptg-individual-document-list
  #gridDocumentData
  [entityType]="EntityType.Participant"
  [dataTable]="dataTable"
  [isLoading]="isLoading"
  [pageSize]="pageSize"
  [pageNumber]="pageNumber"
  [totalRecords]="lengthPg"
  [message]="message"
  [bannerType]="bannerType"
  [isLoadingInfoBanner]="isLoadingInfoBanner"
  [isHideCloseButton]="isHideCloseButton"
  (sortChangeEvent)="sortChange($event)"
  (changePaggingEvent)="changePage($event)"
  (downloadDocumentEvent)="downloadDocument($event)"
  (removeDocumentEvent)="onclickRemoveDocument($event)"
  (removeMultipleDocumentEvent)="onclickRemoveMultipleDocument($event)"
  (downloadMultipleDocumentEvent)="downloadMultipleDocument($event)"
  (uploadDocumentEvent)="uploadDocument()"
  (editDocumentEvent)="editDocument($event)"
  (applyFilterEvent)="applyFilterEvent($event)"
  (generateDocumentEvent)="generateDocument()"
></ptg-individual-document-list>
