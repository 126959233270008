import { SelectionModel } from '@angular/cdk/collections';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FIRST_PAGE, PageEvent, PaginationComponent } from '@ptg-shared/controls/pagination';
import { isEmpty } from '@ptg-shared/utils/string.util';

import {
  ACTION_COLUMN,
  DEFAULT_CURRENT_ROW_INDEX,
  DEFAULT_KEY_COLUMN, DEFAULT_LOADING_MESSAGE,
  DEFAULT_NOT_FOUND_MSG,
  DRAG_DROP_COLUMN,
  GHOST_COLUMN,
  SELECTION_COLUMN,
  STOP_PROPAGATION_CLASS,
  MULTI_DRAG,
} from './constants';
import { CellContent, CellEdit } from './directives';
import { Align, ControlType, ColumnType } from './types/enums';
import { Column, OutsideReorderInfo, ReorderInfo, Row } from './types/models';
import { PersonName } from '@ptg-shared/types/models/common.model';

@Component({
  selector: 'ptg-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent<T extends Row> implements OnChanges, AfterViewChecked {
  readonly ColumnType = ColumnType;
  readonly AlignEnum = Align;
  readonly SELECTION_COLUMN = SELECTION_COLUMN;
  readonly DRAG_DROP_COLUMN = DRAG_DROP_COLUMN;
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly GHOST_COLUMN = GHOST_COLUMN;
  readonly ControlType = ControlType;
  readonly multiDrag = MULTI_DRAG;

  tableDataSource: MatTableDataSource<T> = new MatTableDataSource();
  displayedColumns!: string[];
  isDragDisabled: boolean = true;
  selection = new SelectionModel<T>(true, []);
  groupDisplayedColumns!: string[];

  private _rootForm: FormArray = this.fb.array([]);

  get formStatus(): string {
    return this._rootForm.status;
  }

  get dataSource(): T[] {
    return this.tableDataSource.data;
  }

  @ContentChildren(CellContent) cellContents?: QueryList<CellContent>;
  @ContentChildren(CellEdit) cellEdits?: QueryList<CellEdit>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('paginator') paginator?: PaginationComponent; // Export to the outside to access page information

  @Input() id: string = '';
  @Input() data!: T[];
  @Input() columns!: Column[];
  @Input() keyColumn: string = DEFAULT_KEY_COLUMN;
  @Input() notFoundMessage: string = DEFAULT_NOT_FOUND_MSG;
  @Input() errorMessage?: string;
  @Input() isLoading?: boolean;
  @Input() allowSelection?: boolean;
  @Input() disableSelection?: boolean;
  @Input() fitToParent?: boolean;
  @Input() hideScrollbar: boolean = true;
  @Input() hideHeader?: boolean;
  @Input() fixedHeader: boolean = true;
  @Input() softDeletable?: boolean;
  @Input() inlineEditable?: boolean;
  @Input() allowSaveInline?: boolean;
  @Input() connectedTo: string[] = [];
  @Input() hideHeaderSelection?: boolean;
  @Input() ignoreHiddenCheckboxRow?: boolean = true;
  @Input() groupColumns?: Column[];
  @Input() isGroup?: (index: number, row: T) => boolean;
  @Input() getGroupColspan?: (groupColumn: Column, columns: Column[]) => number;
  @Input() groupRowClasses?: string;
  @Input() groupKey?: string;
  @Input() disableAllCheckbox?: boolean;
  @Input() loadingMessage? = DEFAULT_LOADING_MESSAGE;
  @Input() fullErrorMessage?: string;
  @Input() useTooltipForDefaultCellData?: boolean = false;

  @Output() change = new EventEmitter();
  @Output() rowClick = new EventEmitter<T>();
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() selectionChange = new EventEmitter();
  @Output() saveInlineValue = new EventEmitter();
  @Output() softDelete = new EventEmitter<T>();

  // Two-way binding for the current row index
  @Input() currentRowIndex: number = DEFAULT_CURRENT_ROW_INDEX;
  @Output() currentRowIndexChange = new EventEmitter<number>();

  // Drag and drop the rows
  @Input() allowDragDrop?: boolean;
  @Input() swapKeyValue?: boolean;
  @Output() rowDrop = new EventEmitter<ReorderInfo>();
  @Output() outsideRowDrop = new EventEmitter<OutsideReorderInfo<T>>();

  // Pagination options
  @Input() paginable: boolean = true;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 50;
  @Input() pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 200];
  @Input() hiddenPageSizeOptions: boolean = false;
  @Input() maxPages: number = 5;
  @Input() matSortActive?: string;
  @Input() matSortDirection?: SortDirection;
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() cancelInlineEdit = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngAfterViewChecked() : void {
    //setTimeout(() => {
      let tdTags = document.querySelectorAll("thead, tbody, tr, th, td");
      // let thTags = document.getElementsByTagName("th");
      let tdItemLength = tdTags.length;
      if(tdItemLength > 0){
        (function () {
          for(let i = 0; i < tdItemLength; i++){
            tdTags[i].removeAttribute("role");
          }
        }) ();
      }
    //}, 10);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.groupColumns) {
      const groupColumns: Column[] = changes.groupColumns.currentValue || [];
      this.groupDisplayedColumns = groupColumns.map(({ name }) => name);
      if (this.allowSelection) {
        this.groupDisplayedColumns.unshift(SELECTION_COLUMN);
      }
    }
    if (changes.columns) {
      const columns: Column[] = changes.columns.currentValue;

      // Append inline edit column
      if ((this.softDeletable || this.inlineEditable) && !columns.some((col) => col.name === ACTION_COLUMN)) {
        columns.push({
          name: ACTION_COLUMN,
          width: '140px',
        });
      }

      const newDisplayedColumns = columns.map((col) => col.name);
      if (this.displayedColumns?.toString() !== newDisplayedColumns.toString()) {
        this.displayedColumns = newDisplayedColumns;
      } else {
        // Note: Add a ghost column to re-render the table in case "columns" change but "displayColumns" does not change
        this.displayedColumns = [...columns.map((col) => col.name), GHOST_COLUMN];
      }

      // Insert the selection column to the head
      if (this.allowSelection) {
        this.displayedColumns.unshift(SELECTION_COLUMN);
      }

      // Insert the drag drop column to the head
      if (this.allowDragDrop) {
        this.displayedColumns.unshift(DRAG_DROP_COLUMN);
      }
    }

    // Clear grid's data if loading
    if (changes.isLoading?.currentValue) {
      this.data = [];
    }

    // Reset the form root
    if (this.inlineEditable) {
      this._rootForm = this.fb.array([]);
    }

    this.data?.filter((row: T) => row?.checked)?.forEach((row: T) => this.check(row, false));

    this.tableDataSource.data =
      this.data?.map((item) => {
        // Append the row's form to the root form
        if (this.inlineEditable && item.form) {
          this._rootForm.push(item.form);
        }

        // Initialize row properties if not already present
        return {
          hideCheckBox: false,
          checked: false,
          disableDragDrop: false,
          backgroundColor: null,
          deleted: false,
          editing: false,
          form: null,
          oldValues: null,
          ...item,
        };
      }) || [];

    this.setDeleteStatusOfParentRow();

    if (changes.loadingMessage && isEmpty(this.loadingMessage)) {
      this.loadingMessage = DEFAULT_LOADING_MESSAGE;
    }
  }

  //#region Render grid UI
  getHeaderStyle(column: Column): any {
    const styleObj: any = column.header?.style || {};

    if (column.width) {
      styleObj['width'] = column.width;
    }

    return styleObj;
  }

  getCellStyle(column: Column): any {
    const styleObj: any = column.style || {};

    if (column.width) {
      styleObj['width'] = column.width;
    }

    return styleObj;
  }

  entityReferenceNameIsString(colName: string | PersonName): boolean {
    return typeof colName === 'string';
  }

  getColumnClasses(column: Column): string {
    let className = '';

    let alignClass = '';
    if (column.type === ColumnType.Decimal) {
      alignClass = 'align-right';
    }
    if (column.align === Align.Left) {
      alignClass = 'align-left';
    }
    if (column.align === Align.Right) {
      alignClass = 'align-right';
    }
    if (column.align === Align.Center) {
      alignClass = 'align-center';
    }
    className += alignClass;

    if (column.truncate) {
      className += ' truncate';
    }

    if (column.sortable) {
      className += ' sortable';
    }

    return className;
  }

  getRowClasses(row: T): string {
    if (!row) {
      return '';
    }
    let classNames = '';

    if (this.rowClick.observers.length > 0) {
      classNames += ' active-row';
    }

    if (row.deleted) {
      classNames += ' deleted-row';
    }

    if (row.italic) {
      classNames += ' italic';
    }

    if (row.errorRow) {
      classNames += ' error-row';
    }

    if (row.disabledRow) {
      classNames += ' disabled-row';
    }

    if (row.isSubRow) {
      classNames += ' sub-row';
    }    
    
    if (row.isParentRow) {
      classNames += ' parent-row';
    }

    if (row.isDragDisabled) {
      classNames += ' drag-disabled';
    }
    
    if (row.isDragParentRow) {
      classNames += ' grp-row-selected';
    }

    if (row.isHideOnDragParent) {
      classNames += ' hide';
    }

    return classNames;
  }

  getCellContentTemplate(columnName: string): TemplateRef<any> | undefined {
    let cellContentTemplate: TemplateRef<any> | undefined;
    if (this.cellContents && this.cellContents.length > 0) {
      const cellContent = this.cellContents.find((cell) => cell.columnName === columnName);
      if (cellContent) {
        cellContentTemplate = cellContent.templateRef;
      }
    }

    return cellContentTemplate;
  }

  getCellEditTemplate(columnName: string): TemplateRef<any> | undefined {
    let cellEditTemplate: TemplateRef<any> | undefined;
    if (this.cellEdits && this.cellEdits.length > 0) {
      const cellEdit = this.cellEdits.find((cell) => cell.columnName === columnName);
      if (cellEdit) {
        cellEditTemplate = cellEdit.templateRef;
      }
    }

    return cellEditTemplate;
  }
  //#endregion

  onClickRow(row: T, index: number, event: MouseEvent): void {
    const elem = event.target as Element;

    // Stop propagation the row click event if click on the inside elements
    if (elem && !elem.className.includes(STOP_PROPAGATION_CLASS)) {
      this.currentRowIndex = index;
      this.currentRowIndexChange.emit(index);
      this.rowClick.emit(row);
    }
  }

  //#region Soft delete and Inline form editing
  onClickSoftDetele(row: T): void {
    row.deleted = true;
    this.softDelete.emit(row);

    // Mark as changed data
    this.change.emit();

    this.softDeleteParentRowOnDeleteSubRow(row);
  }

  onClickInlineEdit(row: T): void {
    row.editing = true;

    // Capture old values of the row
    const editableColumns = this.columns.filter((col) => col.editable);
    if (editableColumns.length > 0) {
      row.oldValues = {};
      editableColumns.forEach((col) => {
        row.oldValues[col.name] = row[col.name];
      });
    }

    // Mark as changed data
    this.change.emit();
  }

  onClickCancelInlineEdit(row: T): void {
    row.editing = false;

    // Rollback to old values of the row
    const editableColumns = this.columns.filter((col) => col.editable);
    if (editableColumns.length > 0 && row.oldValues) {
      editableColumns.forEach((col) => {
        (row as any)[col.name] = row.oldValues[col.name];
        row.form?.controls[col.name]?.setValue(row.oldValues[col.name]);
      });
      this.cancelInlineEdit.emit(row);
    }
  }

  onClickSaveInlineEdit(row: T): void {
    const editableColumns = this.columns.filter((col) => col.editable);
    if (editableColumns.length > 0) {
      if (row.form?.invalid) {
        return;
      }
      row.editing = false;
      this.saveInlineValue.emit(row);
    }
  }

  getValidationMsg(row: T, column: Column): string | null {
    const control = row.form?.controls[column.name];
    if (!control) return null;

    const errors = Object.entries(control.errors || {});
    if (errors.length === 0) return null;

    const [key, value] = errors[0];
    if (!column.validators || !column.validators[key]) return null;

    return column.validators[key].message(
      control.getError(key),
      column.header?.title || column.controlArgs?.placeholder || row[`${column.name}Placeholder`] || column.name,
    );
  }
  //#endregion

  //#region Handle for sorting
  onChangeSort(sortState: Sort): void {
    this.sortChange.emit(sortState);
    this.paginator?.jumpToFirst();
  }

  clearSort() {
    this.sort.sort({ id: '', start: 'asc', disableClear: false });
  }
  //#endregion

  //#region Handle for selection column
  isChecked(row: T, index?: number): boolean {
    if (this.isGroup?.(index!!, row)) {
      return !!row.isCheckedGroup;
    }
    const found = this.selection.selected.find((item) => item[this.keyColumn] === row[this.keyColumn]);
    return !!found?.checked;
  }

  isAllChecked(): boolean {
    return [...this.tableDataSource.data]
      .filter((element) => this.ignoreHiddenCheckboxRow && !element.hideCheckBox)
      .every((item) => this.selection.selected.some((item1) => item1[this.keyColumn] === item[this.keyColumn]));
  }

  isIndeterminate(): boolean {
    const current = [...this.tableDataSource.data].filter(
      (element) => this.ignoreHiddenCheckboxRow && !element.hideCheckBox,
    );
    return (
      current.some((item) => this.selection.selected.some((item1) => item1[this.keyColumn] === item[this.keyColumn])) &&
      current.some((item) => !this.selection.selected.find((item1) => item1[this.keyColumn] === item[this.keyColumn]))
    );
  }

  check(row: T, emit: boolean = true, index?: number): void {
    if (this.isGroup?.(index!!, row)) {
      const groupKey = this.groupKey!;
      this.tableDataSource.data.forEach((item) => {
        if (!item.hideCheckBox && item[groupKey] === row[groupKey]) {
          const found = this.selection.selected.find((el) => el[this.keyColumn] === item[this.keyColumn]);
          if (!found) {
            this.selection.select(item);
            item.checked = true;
          }
        }
      });
      row.checked = true;
      this.selectionChange.emit(row);
      return;
    }
    const found = this.selection.selected.find((item) => item[this.keyColumn] === row[this.keyColumn]);
    if (!found) {
      this.selection.select(row);
      row.checked = true;

      if (emit) {
        this.selectionChange.emit(row);
      }
    }
  }

  uncheck(row: T, emit: boolean = true, index?: number): void {
    if (this.isGroup?.(index!!, row)) {
      const groupKey = this.groupKey!;
      this.selection.selected.forEach(item => {
        if (item[groupKey] === row[groupKey]) {
          this.selection.deselect(item);
        }
      });
      row.checked = false;
      this.selectionChange.emit(row);
      return;
    }
    const found = this.selection.selected.find((item) => item[this.keyColumn] === row[this.keyColumn]);
    if (found) {
      this.selection.deselect(found);
      row.checked = false;

      if (emit) {
        this.selectionChange.emit(row);
      }
    }
  }

  masterToggle(): void {
    if (this.isAllChecked()) {
      this.tableDataSource.data.forEach((row: T) => this.uncheck(row, false));
      this.selectionChange.emit();
    } else {
      this.tableDataSource.data.forEach((row: T) => this.check(row, false));
      this.selectionChange.emit();
    }
  }
  //#endregion

  //#region Handle for drag and drop
  customPredicate(index: number, item: CdkDrag<T>): boolean {
    return !item.data.disableDragDrop;
  }

  onDragRow(rowDrag: Row | any): void {
    this.isDragDisabled = false;

    if (rowDrag.isSubRow || rowDrag.isParentRow) {
      this.tableDataSource.data.forEach((row: any) => {
        row.isDragDisabled = rowDrag.isSubRow && row.parentId !== rowDrag.parentId;
  
        row.isDragParentRow = rowDrag.isParentRow 
          && (row.parentId === rowDrag.id || row.id === rowDrag.id);
  
        row.isHideOnDragParent = rowDrag.isParentRow && row.parentId === rowDrag.id;
      });
    }
  }

  onDropRow(event: CdkDragDrop<T[]>) {
    this.isDragDisabled = true;

    if (this._isNestedGroupDragDrop 
        || (event.previousContainer !== event.container && this.isNestedGroupDragDrop(event.previousContainer.data))) {
      this.enableDragAllRow();

      const tagertRow = event.item.data;
      const sourceRow = [...event.container.data][event.currentIndex];
      if (event.previousContainer === event.container) {
        const lastIndexOfSourceSubRow = [...event.container.data].map(x => x.parentId).lastIndexOf(sourceRow?.parentId);
        // drop inside container:
        // cond 1: if drop a sub item to outside group
        // cond 2 & 3: if drop a item to inside other group
        if (
          (tagertRow.isSubRow && sourceRow?.parentId !== tagertRow.parentId)
          || (!tagertRow.isSubRow && sourceRow?.isSubRow && (event.currentIndex !== lastIndexOfSourceSubRow || event.distance.y < 0))
          || (!tagertRow.isSubRow && sourceRow?.isParentRow && event.distance.y > 0)
        ) {
          return;
        }
      } else if (sourceRow?.isSubRow) {
        // drop outside container: 
        // return if drop a item to inside other group
        event.previousContainer.data.forEach((row: any) => {
          row.isHideOnDragParent = false;
        });
        return;
      }

      if(event.item.data.isParentRow) {
        this.multiDrag.dropListDropped(event, () => this.emitOutsideRowDrop(event));
        this.tableDataSource.data = [...event.container.data];
        return;
      }
    }

    if (event.previousContainer === event.container) {
      // Swap items in the same table
      // Update grid's data source
      let data = [...event.container.data];
      // Swap position of the two items
      moveItemInArray(data, event.previousIndex, event.currentIndex);

      let reorderInfo: ReorderInfo = {
        reorderItemKey: data[event.currentIndex][this.keyColumn],
        upperAdjacentKey: data[event.currentIndex - 1]?.[this.keyColumn],
        currentIndex: event.currentIndex,
        previousIndex: event.previousIndex,
      };

      // Swap key values if the grid is dynamic
      if (this.swapKeyValue) {
        const keyValues = this.dataSource.map((row: T) => row[this.keyColumn]);
        data = data.map((row: T, index: number) => ({
          ...row,
          [this.keyColumn]: keyValues[index],
        }));

        // Recalculate reorder information if swap key values
        reorderInfo = {
          upperAdjacentKey:
            data[event.currentIndex > event.previousIndex ? event.currentIndex : event.currentIndex - 1]?.[
              this.keyColumn
            ],
          reorderItemKey: data[event.previousIndex][this.keyColumn],
          currentIndex: event.currentIndex,
          previousIndex: event.previousIndex,
        };
      }
      this.tableDataSource.data = data;

      // Emitting reorder information
      this.rowDrop.emit(reorderInfo);
    } else {
      // Swap items between tables
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.tableDataSource.data = [...event.container.data];

      // Emitting outside reorder information
      this.emitOutsideRowDrop(event);

      // Note: Haven't handled the case of saving changes to the DB when dragging & dropping between tables.
      // If there is a requirement to save to the DB, please improve the logic here.
    }

    // Mark as changed data
    this.change.emit();
  }
  //#endregion

  //#region Handle for pagination
  onChangePage(event: PageEvent): void {
    this.pageChange.emit(event);
  }
  //#endregion

  isUnCheckedForAllYear() {
    const listYearSelected = this.tableDataSource.data.filter((elm) => elm.isYearSelected !== null);
    return listYearSelected.every((item) => !item.checked);
  }

  get _isNestedGroupDragDrop() {
    return this.isNestedGroupDragDrop(this.tableDataSource.data);
  }

  isNestedGroupDragDrop(dataSource: T[]) {
    return dataSource.some((row: T) => row.isParentRow || row.isSubRow);
  }

  onMouseUpDragBtn() {
    if (this._isNestedGroupDragDrop) {
      this.enableDragAllRow();
    }
  }

  enableDragAllRow() {
    this.tableDataSource.data.forEach((row: any) => {
      row.isDragDisabled = false;
      row.isDragParentRow = false;
      row.isHideOnDragParent = false;
    });
  }

  emitOutsideRowDrop(event: CdkDragDrop<T[]>) {
    this.outsideRowDrop.emit({
      prevTableId: event.previousContainer.id,
      prevTableData: [...event.previousContainer.data],
      currentTableId: event.container.id,
      currentTableData: [...event.container.data],
    });
  }

  softDeleteParentRowOnDeleteSubRow(deletedRow: T) {
    if (!deletedRow.isSubRow)
      return;

    const isExistsSubRow = this.tableDataSource.data.find(row => row.parentId === deletedRow.parentId && !row.deleted);
    const parentRow = this.tableDataSource.data.find(row => row.id === deletedRow.parentId);
    if (!isExistsSubRow && parentRow) {
      parentRow.deleted = true;
    }
  }

  setDeleteStatusOfParentRow() {
    const listParentDeleted = this.tableDataSource.data.filter(row => row.isParentRow && row.deleted) ?? [];
    listParentDeleted.forEach(parentRow => {
      const isUndelete = this.tableDataSource.data.find(row => row.parentId === parentRow.id && !row.deleted);
      if (isUndelete) {
        parentRow.deleted = false;
      }
    });
  }

}
