import { createReducer, on } from '@ngrx/store';

import { ACTION, ActionState, STATE } from '@ptg-shared/constance/value.const';

import * as MunicipalityServiceHistoryAction from '../actions/municipality-service-history.action';
import {
  CreateValidateServiceHistoryRecordBeginDateTransactionResponse,
  Municipality,
  MunicipalityServiceRecordList,
  MunicipalityServiceRecordListEntity,
  ValidateRemoveServiceHistoryResponse,
  ValidateServiceHistoryRecordTerminatedMemberResponse,
  ValidateTerminatedMemberResponse
} from '../../types/models';
import { BaseActionState } from '@ptg-shared/types/models';

export const MunicipalityServiceHistoryFeatureKey = 'municipalityServiceHistory';

export interface RemoveServiceHistoryResponse extends ActionState {
  error?: any;
  isLoading: boolean;
}
export interface State {
  isLoading: boolean;
  isFormEditLoading: boolean;
  serviceRecords: MunicipalityServiceRecordList;
  serviceRecordsEntity: MunicipalityServiceRecordListEntity;
  listMunicipality?: Municipality[];
  createState?: string;
  updateState?: string;
  validateTerminatedMember?: ValidateTerminatedMemberResponse;
  createTransactionState?: string;
  errorMessage?: string;
  errMsg?: string;
  validateServiceHistoryRecordTerminatedMemberState?: BaseActionState<ValidateServiceHistoryRecordTerminatedMemberResponse>;
  createValidateServiceHistoryRecordBeginDateTransactionState?: BaseActionState<CreateValidateServiceHistoryRecordBeginDateTransactionResponse>;
  validateRemoveServiceHistoryState?: BaseActionState<ValidateRemoveServiceHistoryResponse>;
  removeServiceHistoryState: RemoveServiceHistoryResponse
}

const initialState: State = {
  isLoading: true,
  isFormEditLoading: true,
  serviceRecords: {
    total: 0,
    beginDate: '',
    endDate: '',
    dateOfDeath: '',
    serviceRecords: [],
    totalService: '',
  },
  serviceRecordsEntity: {
    total: 0,
    beginDate: '',
    endDate: '',
    table: [],
    totalService: '',
  },
  listMunicipality: [],
  createState: '',
  updateState: '',
  validateServiceHistoryRecordTerminatedMemberState: undefined,
  createValidateServiceHistoryRecordBeginDateTransactionState: undefined,
  errorMessage: '',
  validateRemoveServiceHistoryState: undefined,
  removeServiceHistoryState: {
    isLoading: false,
    action: ACTION.REMOVE,
    state: '',
  }
  
};

export const reducer = createReducer(
  initialState,
  on(
    MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryList,
    (state, {}) => ({
      ...state,
      isLoading: true,
      serviceRecords: {
        total: 0,
        beginDate: '',
        endDate: '',
        dateOfDeath: '',
        serviceRecords: [],
        totalService: '',
      },
    })
  ),
  on(
    MunicipalityServiceHistoryAction.municipalityServiceHistoryListSuccess,
    (state, { serviceRecords }) => ({
      ...state,
      isLoading: false,
      serviceRecords,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.municipalityServiceHistoryListFailure,
    (state) => ({
      ...state,
      isLoading: false,
      serviceRecords: {
        total: 0,
        beginDate: '',
        endDate: '',
        dateOfDeath: '',
        serviceRecords: [],
        totalService: '',
      },
    })
  ),

  on(
    MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryEntityList,
    (state, {}) => ({
      ...state,
      isLoading: true,
      serviceRecordsEntity: {
        total: 0,
        beginDate: '',
        endDate: '',
        table: [],
        totalService: '',
      },
    })
  ),
  on(
    MunicipalityServiceHistoryAction.municipalityServiceHistoryEntityListSuccess,
    (state, { serviceRecordsEntity }) => ({
      ...state,
      isLoading: false,
      serviceRecordsEntity,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.municipalityServiceHistoryEntityListFailure,
    (state) => ({
      ...state,
      isLoading: false,
      serviceRecordsEntity: {
        total: 0,
        beginDate: '',
        endDate: '',
        table: [],
        totalService: '',
      },
    })
  ),

  on(MunicipalityServiceHistoryAction.getMunicipalityList, (state, {}) => ({
    ...state,
    isFormEditLoading: true,
  })),
  on(
    MunicipalityServiceHistoryAction.getMunicipalityListSuccess,
    (state, { listMunicipality }) => ({
      ...state,
      isFormEditLoading: false,
      listMunicipality,
    })
  ),
  on(MunicipalityServiceHistoryAction.getMunicipalityListFailure, (state) => ({
    ...state,
    isFormEditLoading: false,
    listMunicipality: [],
  })),
  on(MunicipalityServiceHistoryAction.clearMunicipalityListState, (state) => ({
    ...state,
    listMunicipality: undefined,
  })),

  on(
    MunicipalityServiceHistoryAction.clearMunicipalityServiceHistoryState,
    (state) => ({
      ...state,
      createState: '',
      updateState: '',
      createTransactionState: '',
      validateTerminatedMember: undefined,
      errorMessage: ''
    })
  ),
  on(
    MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistorySuccess,
    (state) => ({
      ...state,
      createState: STATE.SUCCESS,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.createMemberMunicipalityServiceHistoryFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      createState: STATE.FAIL,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistorySuccess,
    (state) => ({
      ...state,
      updateState: STATE.SUCCESS,
    })
  ),
  on(
    MunicipalityServiceHistoryAction.editMemberMunicipalityServiceHistoryFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      updateState: STATE.FAIL,
    })
  ),
  on(MunicipalityServiceHistoryAction.validateTerminatedMemberSuccess, (state, { validateTerminatedMember }) => ({
      ...state,
    validateTerminatedMember,
    }),
  ),
  on(MunicipalityServiceHistoryAction.validateTerminatedMemberFailure, (state) => ({
      ...state,
    validateTerminatedMember: {} as any,
    }),
  ),
  on(MunicipalityServiceHistoryAction.createTransactionSuccess, (state) => ({
      ...state,
    createTransactionState: STATE.SUCCESS,
    }),
  ),
  on(MunicipalityServiceHistoryAction.createTransactionFailure, (state) => ({
      ...state,
    createTransactionState: STATE.FAIL,
    }),
  ),

  on(MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberAction, (state) => ({
    ...state,
    validateServiceHistoryRecordTerminatedMemberState: undefined,
  })),
  on(MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberSuccessAction, (state, { response }) => ({
    ...state,
    validateServiceHistoryRecordTerminatedMemberState: {
      payload: response,
      success: true,
      isLoading: false,
    },
  })),
  on(MunicipalityServiceHistoryAction.validateServiceHistoryRecordTerminatedMemberFailureAction, (state, { errorMsg }) => ({
    ...state,
    validateServiceHistoryRecordTerminatedMemberState: {
      errorMsg: errorMsg,
      success: false,
      isLoading: false,
    },
  })),
  on(MunicipalityServiceHistoryAction.clearValidateServiceHistoryRecordTerminatedMemberStateAction, (state) => ({
    ...state,
    validateServiceHistoryRecordTerminatedMemberState: undefined,
  })),

  on(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionAction, (state) => ({
    ...state,
    createValidateServiceHistoryRecordBeginDateTransactionState: undefined,
  })),
  on(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionSuccessAction, (state, { response }) => ({
    ...state,
    createValidateServiceHistoryRecordBeginDateTransactionState: {
      payload: response,
      success: true,
      isLoading: false,
    },
  })),
  on(MunicipalityServiceHistoryAction.createValidateServiceHistoryRecordBeginDateTransactionFailureAction, (state, { errorMsg }) => ({
    ...state,
    createValidateServiceHistoryRecordBeginDateTransactionState: {
      errorMsg: errorMsg,
      success: false,
      isLoading: false,
    },
  })),
  on(MunicipalityServiceHistoryAction.clearCreateValidateServiceHistoryRecordBeginDateTransactionStateAction, (state) => ({
    ...state,
    createValidateServiceHistoryRecordBeginDateTransactionState: undefined,
  })),

  //validate remove service history
  on(MunicipalityServiceHistoryAction.validateRemoveServiceHistoryRequest, (state) => ({
    ...state,
    validateRemoveServiceHistoryState: undefined,
  })),
  on(MunicipalityServiceHistoryAction.validateRemoveServiceHistorySuccess, (state, { response }) => ({
    ...state,
    validateRemoveServiceHistoryState: {
      payload: response,
      success: true,
      isLoading: false,
    },
  })),
  on(MunicipalityServiceHistoryAction.validateRemoveServiceHistoryFailure, (state) => ({
    ...state,
    validateRemoveServiceHistoryState: {
      success: false,
      isLoading: true,
    },
  })),
  on(MunicipalityServiceHistoryAction.clearValidateRemoveServiceHistoryFailure, (state) => ({
    ...state,
    validateRemoveServiceHistoryState: undefined,
  })),

  //remove service history
  on(MunicipalityServiceHistoryAction.removeServiceHistoryAction, (state) => ({
    ...state,
    removeServiceHistoryState: {
      isLoading: true,
      action: ACTION.REMOVE,
      state: '',
    },
  })),
  on(MunicipalityServiceHistoryAction.removeServiceHistorySuccess, (state, {}) => ({
    ...state,
    removeServiceHistoryState: {
      isLoading: false,
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
    },
  })),
  on(MunicipalityServiceHistoryAction.removeServiceHistoryFailure, (state, {error}) => ({
    ...state,
    removeServiceHistoryState: {
      isLoading: false,
      action: ACTION.REMOVE,
      state: STATE.FAIL,
      error,
    },
  })),
  on(MunicipalityServiceHistoryAction.clearRemoveServiceHistoryFailure, (state) => ({
    ...state,
    removeServiceHistoryState: {
      isLoading: true,
      action: ACTION.REMOVE,
      state: '',
    },
  })),
);
