import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@ptg-shared/components';
import { DEFAULT_TAB } from '@ptg-shared/layout/components/captionbar/types/caption-bar.const';
import { Tab } from '@ptg-shared/layout/components/captionbar/types/caption-bar.model';
import { DisplayedTabName, HiddenTabName, SidebarOpenedStatus } from '@ptg-shared/layout/constance/layout.const';
import { deepEqual, deepFlattenArray } from '@ptg-shared/utils/common.util';
import { Auth0Service } from 'src/app/shared/auth/services/auth0.service';
import { ADMIN_FUND, ADMIN_SYSTEM } from 'src/app/shared/constance/value.const';
import { CheckPermissionService } from '../../../services/check-permission.service';
import { Store } from '@ngrx/store';
import { MemberState } from '@ptg-member/store/reducers';
import { MemberListActions } from '@ptg-member/store/actions';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { employerPinIcon } from '@ptg-shared/constance/listIcons.const';

@Component({
  selector: 'ptg-captionbar',
  templateUrl: './captionbar.component.html',
  styleUrls: ['./captionbar.component.scss']
})
export class CaptionbarComponent extends BaseComponent implements OnChanges {
  readonly SidebarOpenedStatusEnum = SidebarOpenedStatus;
  @Output() logoutAction = new EventEmitter();
  ADMIN_FUND = ADMIN_FUND;
  ADMIN_SYSTEM = ADMIN_SYSTEM;

  @Input() sidebarOpenedStatus: SidebarOpenedStatus = SidebarOpenedStatus.Opened;
  @Output() sidebarOpenedStatusChange: EventEmitter<SidebarOpenedStatus> = new EventEmitter<SidebarOpenedStatus>();

  @Output() changeSelectedTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeSideMenu: EventEmitter<void> = new EventEmitter<void>();

  @Input() tabs: Tab[] = DEFAULT_TAB;
  isHiddenTab: boolean = false;

  constructor(
    public router: Router,
    public authService: Auth0Service,
    public checkPermissionService: CheckPermissionService,
    private memberStore: Store<MemberState>,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    super();
    iconRegistry.addSvgIconLiteral('employer_pin', sanitizer.bypassSecurityTrustHtml(employerPinIcon));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabs && !deepEqual(changes.tabs.previousValue, changes.tabs.currentValue)) {
      this.checkSelectedTab();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  logout() {
    this.logoutAction.emit();
  }

  onCloseSideMenu() {
    this.closeSideMenu.emit();
  }

  selectTab(tab: any) {
    this.changeSelectedTab.emit(tab);
  }

  ngOnDestroy(): void {
  }

  checkSelectedTab() {
    this.isHiddenTab = !(deepFlattenArray(this.tabs) as Tab[]).find(item => item.isSelected)?.tabName;
  }

  navigateFund(): void {
    this.router.navigateByUrl('/fund-list');
  }
}
