import { createAction, props } from '@ngrx/store';

import { generateActionName } from '@ptg-shared/utils/common.util';

import {
  AdjustmentDetailItemPositionRequest,
  AdjustmentDetailItemPositionResponse,
  ViewAdjustmentDetailsRequest,
  ViewAdjustmentDetailsResponse,
} from '../../services/models/view-adjustment-details.model';

export const getAdjustmentDetailsAction = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetails/API').Send,
  props<{ request: ViewAdjustmentDetailsRequest }>(),
);
export const getAdjustmentDetailsSuccess = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetails/API').Success,
  props<{ response: ViewAdjustmentDetailsResponse }>(),
);
export const getAdjustmentDetailsFailure = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetails/API').Failure,
  props<{ error: any }>(),
);
export const clearAdjustmentDetailsState = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetails').Clear,
);

export const getAdjustmentDetailItemPositionAction = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetailItemPosition/API').Send,
  props<{ request: AdjustmentDetailItemPositionRequest }>(),
);
export const getAdjustmentDetailItemPositionSuccess = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetailItemPosition/API').Success,
  props<{ response: AdjustmentDetailItemPositionResponse }>(),
);
export const getAdjustmentDetailItemPositionFailure = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetailItemPosition/API').Failure,
  props<{ error: any }>(),
);
export const clearAdjustmentDetailItemPositionState = createAction(
  generateActionName('ViewAdjustmentDetails AdjustmentDetailItemPosition').Clear,
);
