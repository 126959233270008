import { createAction, props } from '@ngrx/store';

import { GetTypesOfDeductionsRequest, GetTypesOfDeductionsResponse, SetDeductionsRequest } from '../../services/models';

export const getTypesOfDeductionsAction = createAction(
  '[GetTypesOfDeductions/API] Send Request',
  props<{ request: GetTypesOfDeductionsRequest }>(),
);
export const getTypesOfDeductionsSuccessAction = createAction(
  '[GetTypesOfDeductions/API] Success',
  props<{ response: GetTypesOfDeductionsResponse }>(),
);
export const getTypesOfDeductionsFailureAction = createAction(
  '[GetTypesOfDeductions/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetTypesOfDeductionsStateAction = createAction('[GetTypesOfDeductions] Clear');

export const setDeductionsAction = createAction(
  '[SetDeductions/API] Send Request',
  props<{ paymentInstructionId: string, request: SetDeductionsRequest, isChangeAmountOfPreTax: boolean }>()
);
export const setDeductionsSuccessAction = createAction(
  '[SetDeductions/API] Success', 
  props<{ isChangeAmountOfPreTax: boolean }>()
);
export const setDeductionsFailureAction = createAction(
  '[SetDeductions/API] Failure',
  props<{ error?: any }>()
);
export const clearSetDeductionsStateAction = createAction(
  '[SetDeductions] Clear'
);
