import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { GetListEmployerTypesRequest } from '@ptg-member/features/calculation/services/models';
import {
  CalculationState,
  clearGetBankConfigurationStateAction,
  clearGetListEmployerTypesStateAction,
  getBankConfigurationAction,
  getBankConfigurationSelector,
  getListEmployerTypesAction,
  getListEmployerTypesSelector,
} from '@ptg-member/features/calculation/store';
import { CalculationBenefitHistoryStatus, MultipleBankType } from '@ptg-member/features/calculation/types/enums';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ptg-municipality-type',
  templateUrl: './municipality-type.component.html',
  styleUrls: ['./municipality-type.component.scss'],
})
export class MunicipalityTypeComponent extends BaseComponent implements OnInit, OnChanges {
  readonly CalculationBenefitHistoryStatus = CalculationBenefitHistoryStatus;
  @Input() request?: GetListEmployerTypesRequest;
  @Input() currentStatus?: CalculationBenefitHistoryStatus;
  @Input() employerTypeControl: FormControl = new FormControl(null, [Validators.required]);

  employerTypeOptions: Option[] = [];
  employerTypeLabel = 'Employer Type';
  isEmployerMultipleBank = false;



  constructor(
    private readonly calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.request || changes.currentStatus) && this.request && !isEmpty(this.currentStatus)) {
      this.getListEmployerTypes();
      this.getBankConfiguration();
    }
  }

  ngOnInit(): void {
    this.selectGetListEmployerTypesState();
    this.selectGetBankConfigurationSelector();
  }

  private selectGetListEmployerTypesState() {
    this.calculationStore
      .select(getListEmployerTypesSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearGetListEmployerTypesStateAction());

        if (!res?.payload) {
          return;
        }
        const { label, employerTypes } = res.payload;
        this.employerTypeLabel = label || 'Municipality Type';
        this.employerTypeOptions = deepClone(employerTypes).map((item) => {
          return {
            value: item,
            displayValue: item.employerName,
          };
        });
        if (this.employerTypeOptions.length === 1) {
          this.employerTypeControl.setValue(this.employerTypeOptions[0].value);
        }
      });
  }

  getListEmployerTypes() {
    if (!this.request) {
      return;
    }
    this.calculationStore.dispatch(
      getListEmployerTypesAction({
        request: this.request,
      }),
    );
  }

  getBankConfiguration() {
    this.calculationStore.dispatch(getBankConfigurationAction());
  }

  private selectGetBankConfigurationSelector() {
    this.calculationStore
      .select(getBankConfigurationSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearGetBankConfigurationStateAction());
        if (!res?.payload) {
          return;
        }
        this.isEmployerMultipleBank = res.payload.bankType === MultipleBankType.EmployerType;
      });

  }
}
