import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { DateTime } from 'luxon';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers';

import { GetPropertyType } from '../constance/member-list.const';
import {
  CheckExitsRequest,
  CheckExitsResponse,
  ColumnSetConfig,
  ColumnSetConfigRequest,
  Employer,
  ExportFileRequest,
  FilterInformationList,
  GetCardSelectionResponse,
  ListSectionProperty,
  MemberFilterConfig,
  MemberFilterModal,
  MemberFilterName,
  MemberList,
  MemberListQuery,
  PropertyDisplayResponse,
  QueryCardSelection,
  QueryProperty,
} from '../types/models';
import { FundType } from '@ptg-shared/types/enums';

@Injectable({
  providedIn: 'root',
})
export class MemberListService {
  activeFund: any;
  currentFilter: MemberFilterModal | undefined;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>,
  ) {
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe((el) => (this.activeFund = el));
  }

  memberList(query: MemberListQuery, idClient: string): Observable<MemberList> {
    let body: any = {
      pageSize: query.totalPerPage,
      pageIndex: query.pageIndex,
      listFilterInfo: query?.listFilterInfo,
      screenId: query.screenId,
    };
    if (query.sortField) {
      body = {
        ...body,
        sortNames: [query?.sortField],
        sortType: query.sortType || 0,
      };
    }
    if (query?.ColumnSetId) {
      body = {
        ...body,
        ColumnSetId: query?.ColumnSetId,
      };
    }
    if (query?.screenId) {
      body.screenId = query?.screenId;
    }
    return this.httpClient.post<MemberList>(`${environment.apiUrl}/Members`, body);
  }

  removeMember(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Members/${id}`);
  }

  lockMember(id: string): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Members/${id}/Lock`, {});
  }

  unlockMember(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Members/${id}/Lock`);
  }

  checkExits = (bodyCheck: any) => {
    const body = {
      ...bodyCheck,
      clientId: this.activeFund?.id,
    };
    return this.httpClient.post(`${environment.apiUrl}/Members/Exists`, body);
  };

  createNewMember(dataMember: any[], screenId?: string) {
    let formData = new FormData();
    dataMember.forEach((el: any, i) => {
      formData.append(`Sections[${i}].sectionKey`, el.sectionKey);
      el?.propertyValues?.forEach((item: any, y: number) => {
        formData.append(`Sections[${i}].propertyValues[${y}].key`, item?.key);
        formData.append(`Sections[${i}].propertyValues[${y}].type`, item?.type);
        if (item.type === 'MemberKey' || item.type === 'StatusId' || item.type === 'EventId') {
          formData.append(item.type, item.value);
        } else {
          formData.append(`Sections[${i}].propertyValues[${y}].value`, item?.value);
        }
      });
    });
    let params = new HttpParams();
    if (screenId) {
      params = params.append('screenId', screenId);
    }
    return this.httpClient.post(`${environment.apiUrl}/Members/AddSectionData`, formData, { params });
  }

  getFilterByPropertyList(): Observable<ListSectionProperty> {
    // return this.httpClient.get<ListSectionProperty>(
    //   `${environment.apiUrl}/Metadata/SectionProperties/${GetPropertyType.MemberListFilter}`
    // );

    // TODO remove when complete module entity
    return of();
  }

  checkMemberFilterExist = (body: MemberFilterName) => {
    return this.httpClient.post(`${environment.apiUrl}/entities/members/filters-members/exists`, body);
  };

  createMemberFilter(body: MemberFilterConfig): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/entities/members/filters-members`, body);
  }

  getMemberFilter(): Observable<FilterInformationList> {
    return this.httpClient.get<FilterInformationList>(`${environment.apiUrl}/entities/members/filters-members`);
  }

  updateFilterName(memberFilterName: MemberFilterName) {
    return this.httpClient.put(`${environment.apiUrl}/entities/members/filters-members`, memberFilterName);
  }

  removeMemberFilter(id: string): Observable<void> {
    if (id === this.currentFilter?.id) {
      this.currentFilter = undefined;
    }
    return this.httpClient.delete<void>(`${environment.apiUrl}/entities/members/filters-members/${id}`);
  }

  exportMemberList(request: ExportFileRequest) {
    let body: any = {
      entityId: request.entityId,
      pageSize: request.totalPerPage,
      pageIndex: request.pageIndex,
      fileName: request.fileName,
      listFilter: request.listFilter,
      timeZone: DateTime.now().toFormat('ZZ'),
    };
    if (request.sortField) {
      body = {
        ...body,
        sortNames: [request?.sortField],
        sortType: request?.sortType || 0,
      };
    }
    if (request?.ColumnSetId) {
      body = {
        ...body,
        ColumnSetId: request?.ColumnSetId,
      };
    }
    return this.httpClient.post(`${environment.apiUrl}/entities/members/generate-excel-file`, body);
  }

  downloadExportFile(fileName: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/ExportMembers/Download`,
      { fileName },
      { responseType: 'blob' },
    );
  }

  getEmployerDetail(employerId: string, clientId: string): Observable<Employer> {
    return this.httpClient.get<Employer>(`${environment.apiUrl}/Organization/${employerId}/Employer/${clientId}`);
  }

  getColumnSetConfiguration(IsDragDrop: boolean): Observable<ColumnSetConfig> {
    // return this.httpClient.get<ColumnSetConfig>(
    //   `${environment.apiUrl}/MetadataPortal/ManageColumnSet/GetListColumnSetConfiguration?IsDragDrop=${IsDragDrop}`,
    // );

    // TODO remove when complete module entity
    return of();
  }

  createColumnSetConfiguration(body: ColumnSetConfigRequest, entityId: string): Observable<any> {
    let params = new HttpParams();
    if (entityId) {
      params = params.append('entityId', entityId);
    }
    return this.httpClient.post<any>(`${environment.apiUrl}/entities/members/manage-column-set`, body, { params });
  }

  reorderColumnSetConfiguration(body: any): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entities/members/manage-column-set/reorder-column-set`,
      body,
    );
  }

  checkExitsColumnSet(body: CheckExitsRequest): Observable<CheckExitsResponse> {
    return this.httpClient.put<CheckExitsResponse>(
      `${environment.apiUrl}/entities/members/manage-column-set/check-exist-column-set-name`,
      body,
    );
  }

  removeColumnSet(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/entities/members/manage-column-set/${id}`);
  }

  editColumnSetConfiguration(id: string, body: ColumnSetConfigRequest): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/entities/members/manage-column-set/${id}`, body);
  }

  getMemberListEntity(query: MemberListQuery, entityId: string): Observable<MemberList> {
    let body: any = {
      pageSize: query.totalPerPage,
      pageIndex: query.pageIndex,
      listFilterInfo: query?.listFilterInfo,
      screenId: query.screenId,
    };
    if (query.sortField) {
      body = {
        ...body,
        sortNames: [query?.sortField],
        sortType: query.sortType || 0,
      };
    }
    if (query?.ColumnSetId) {
      body = {
        ...body,
        ColumnSetId: query?.ColumnSetId,
      };
    }
    if (query?.screenId) {
      body.screenId = query?.screenId;
    }
    return this.httpClient.post<MemberList>(`${environment.apiUrl}/entities/members/${entityId}`, body);
  }

  getColumnSetConfigurationEntity(IsDragDrop: boolean, entityId: string): Observable<ColumnSetConfig> {
    let params = new HttpParams();
    if (IsDragDrop) {
      params = params.append('IsDragDrop', IsDragDrop);
    }
    if (entityId) {
      params = params.append('entityId', entityId);
    }

    return this.httpClient.get<ColumnSetConfig>(`${environment.apiUrl}/entities/members/manage-column-sets`, {
      params,
    });
  }

  getPropertyOfParticipants(entityId: string, query?: QueryProperty): Observable<PropertyDisplayResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<PropertyDisplayResponse>(
      `${environment.apiUrl}/entities/members/${entityId}/PropertyOfParticipants`,
      { params },
    );
  }

  getCardSelection(query?: QueryCardSelection): Observable<GetCardSelectionResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<GetCardSelectionResponse>(`${environment.apiUrl}/entity/cards`, { params });
  }

  getPropertyByCardSelection(cardId: string, screenId: string): Observable<any> {
    let params = new HttpParams();
    if (screenId) params = params.append('screenId', screenId);

    return this.httpClient.get<any>(`${environment.apiUrl}/entity/cards/${cardId}`, { params });
  }
}
