<div class="final-payment-configuration">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <!-- NOTIFICATION BANNER -->
    <ptg-banner class="mx-6 mt-8 block" *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <form class="final-payment-content py-8 px-6" [formGroup]="configurationForm">
      <div class="flex justify-start gap-3 mb-5 wrap-btn">
        <ptg-button type="submit" (click)="onSubmit()" classInput="primary" buttonName="Save" [isDisabled]="!isEnableSaveBtn"></ptg-button>
        <ptg-button type="button" (click)="onCancel()" classInput="tertiary" buttonName="Cancel"></ptg-button>
      </div>

      <div class="final-payment-wrapper">
        <div class="whitespace-nowrap mb-6 font-bold text-xl">
          <p class="inline-block col-label mr-8">Benefit Subtype</p>
          <div class="inline-block col-detail whitespace-nowrap">
            <p class="col-1 inline-block whitespace-normal mr-8">Pay Partial Period Amount</p>
            <p class="col-2 inline-block whitespace-normal mr-8">Do not Pay</p>
            <p class="col-3 inline-block whitespace-normal">Pay Full Period</p>
          </div>
        </div>
        <ng-container *ngFor="let item of benefitSubTypesList">
          <div class="whitespace-nowrap mb-6">
            <div class="inline-block col-label whitespace-normal text-lg mr-8">
              {{ item.name }}
            </div>
            <mat-radio-group class="inline-block col-detail" [formControl]="configurationForm.get(item.id)">
              <mat-radio-button [value]="FinalPaymentConfigurationEnum.PayPartialPeriodAmount" class="col-1 mr-8"></mat-radio-button>
              <mat-radio-button [value]="FinalPaymentConfigurationEnum.DoNotPay" class="col-2 mr-8"></mat-radio-button>
              <mat-radio-button [value]="FinalPaymentConfigurationEnum.PayFullPeriod" class="col-3"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>
    </form>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="py-8">
      <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
    </div>
  </ng-template>
</div>
