import { AfterViewChecked, Component, OnDestroy, OnInit, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Auth0Service } from './shared/auth/services/auth0.service';
import { CheckPermissionService } from './shared/services/check-permission.service';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { CheckVersionFlagService } from '@ptg-shared/services/check-version-flag.service';

@Component({
  selector: 'ptg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, DoCheck {
  title = 'pension-admin';
  isLogin = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: Auth0Service,
    public router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public checkPermissionService: CheckPermissionService,
    public layoutService: LayoutService,
    public checkVersionFlagService: CheckVersionFlagService,
  ) {}

  ngAfterViewChecked() : void {
    // setTimeout(() => {
    //   let inputWithAria = document.querySelectorAll('[aria-expanded="false"]');
    //   let inputRoleTag = document.querySelectorAll('[role="combobox"]');
    //   if(inputRoleTag.length > 0){
    //     (function () {
    //       for(let i = 0; i < inputRoleTag.length; i++){
    //         inputRoleTag[i].removeAttribute("role");
    //         // inputRoleTag[i].setAttribute('style', 'border: 2px solid blue;');
    //       }
    //     }) ();
    //   }
    //   if(inputWithAria.length > 0){
    //     (function () {
    //       for(let i = 0; i < inputWithAria.length; i++){
    //         inputWithAria[i].removeAttribute("aria-autocomplete");
    //         inputWithAria[i].removeAttribute("aria-expanded");
    //         inputWithAria[i].removeAttribute("aria-required");
    //         inputWithAria[i].removeAttribute("aria-labelledby");
    //       }
    //     }) ();
    //   }
    // }, 10);
  }
  ngDoCheck(){
    let ptgRoot = document.getElementsByTagName("ptg-root");
    if(ptgRoot.length > 0){
      (function () {
        for(let i = 0; i < ptgRoot.length; i++){
          ptgRoot[i].removeAttribute("aria-hidden");
        }
      }) ();
    }
  }
  
  ngOnInit(): void {
    this.checkAuthenticationState();
  }

  checkAuthenticationState() {
    this.authService.initializeAuth();
    this.authService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      this.isLogin = isAuthenticated;
      if (!this.isLogin) {
        localStorage.clear();
        location.reload();
        return;
      }
      this.checkVersionFlagService.getAppSettings();
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
