import { createReducer, on } from '@ngrx/store';
import { validateRemittance2024ReceiptNumberAction, validateRemittance2024ReceiptNumberSuccessAction, validateRemittance2024ReceiptNumberFailureAction, clearValidateRemittance2024ReceiptNumberStateAction } from '@ptg-employer/actions/remittance-2024-confirmation.action';
import { ValidateRemittance2024ReceiptNumberResponse } from '@ptg-employer/models/remittance-2024-confirmation.model';
import { BaseActionState } from '@ptg-shared/types/models';

export const remittance2024ConfirmationFeatureKey = 'remittance2024Confirmation';

export interface Remittance2024ConfirmationState {
  validateRemittance2024ReceiptNumberState?: BaseActionState<ValidateRemittance2024ReceiptNumberResponse>;
}

const initialState: Remittance2024ConfirmationState = {};

export const remittance2024ConfirmationReducer = createReducer(
  initialState,

  // Validate Remittance 2024 Receipt Number
  on(validateRemittance2024ReceiptNumberAction, (state) => ({
    ...state,
    validateRemittance2024ReceiptNumberState: {
      isLoading: true,
    },
  })),
  on(validateRemittance2024ReceiptNumberSuccessAction, (state, { response }) => ({
    ...state,
    validateRemittance2024ReceiptNumberState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateRemittance2024ReceiptNumberFailureAction, (state, { error }) => ({
    ...state,
    validateRemittance2024ReceiptNumberState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateRemittance2024ReceiptNumberStateAction, (state) => ({
    ...state,
    validateRemittance2024ReceiptNumberState: undefined,
  })),
);
