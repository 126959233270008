import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { InterceptorActions } from '@ptg-shared/layout/actions';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import * as fromReducer from '@ptg-reducers';
import { Router } from '@angular/router';
import { LoggerService } from '@ptg-shared/services/logger.service';
import { LayoutService } from '@ptg-shared/services/layout.service';

export const SKIP_ERRORS = new HttpContextToken<string | string[]>(() => '');
export const SKIP_LOG = new HttpContextToken<string | string[]>(() => '');
export const SHOW_LOADING = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(
    private authService: Auth0Service,
    private router: Router,
    private store: Store<fromReducer.State>,
    private logger: LoggerService,
    private layoutService: LayoutService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const prevUrl = this.router.routerState.snapshot.url;
    this.checkLoadingIndicator(request, true);
    request = request.clone({
      headers: request.headers.set('Cache-control', 'no-cache'),
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    request = request.clone({ headers: request.headers.set('ClientKey', '') });
    let lastResponse: HttpEvent<any>;
    let errorResponse: HttpErrorResponse;

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        lastResponse = event;
        this.checkLoadingIndicator(request, false);
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        errorResponse = error;
        this.checkLoadingIndicator(request, false);

        // Log error
        const skipLog = request.context.get(SKIP_LOG);
        if (!skipLog) {
          const message = `HttpResponse {Url: "${request?.urlWithParams}", Method: "${request?.method}", Status: ${error?.status}, Body: ${JSON.stringify(request?.body)}, Error: ${error?.message}}`;
          if (error.status === 400) {
            this.logger.warning(message);
          } else {
            this.logger.error(message);
          }
        }

        // Retry the call to refresh token when response status code is 401
        if (error && error.status === 401) {
          this.authService.refreshToken()?.subscribe({
            next: (token) => {
              console.log('Access token: ', token);
            },
            error: (refreshTokenError) => {
              this.logger.error(`Failed to get access token after retries ${refreshTokenError}`);
              this.authService.logout();
            },
          });
        }

        // Dispatch error action
        const skipErrors = request.context.get(SKIP_ERRORS);
        const errorObject = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;
        const isSkipError = skipErrors.includes(error.status.toString()) || skipErrors.includes(errorObject?.errorType);
        const currentUrl = this.router.routerState.snapshot.url;
        if (request.method === 'GET' && !isSkipError && prevUrl === currentUrl) {
          const errorStatus = deepClone(error) as any;
          delete errorStatus.headers;
          this.store.dispatch(InterceptorActions.setError({ error: errorStatus }));
        }

        // Cast Error object to json to pass in the redux action
        error = JSON.parse(JSON.stringify(error));
        return throwError(error);
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !errorResponse) {
          // last response type was 0, and we haven't received an error
          this.checkLoadingIndicator(request, false);
        }
      }),
    );
  }

  private checkLoadingIndicator(request: HttpRequest<unknown>, isRequest: boolean = false) {
    const showLoading = request.context.get(SHOW_LOADING);
    if (!showLoading) {
      return;
    }
    if (isRequest) {
      this.totalRequests++;
      this.layoutService.showLoading = true;
      return;
    }
    this.totalRequests--;
    if (!this.totalRequests) {
      this.layoutService.showLoading = false;
    }
  }
}
