import { createAction, props } from '@ngrx/store';
import {
  GetDocumentListConfigurationDetailRequest,
  GetDocumentListConfigurationDetailResponse,
  GetDocumentListConfigurationPropertiesRequest,
  GetDocumentListConfigurationPropertiesResponse,
  UpdateDocumentListConfigurationRequest,
} from '../../services/models/document-list-configuration.model';

// Get Document List Configuration Properties
export const getDocumentListConfigurationPropertiesAction = createAction(
  '[GetDocumentListConfigurationProperties/API] Send Request',
  props<{ request: GetDocumentListConfigurationPropertiesRequest }>()
);
export const getDocumentListConfigurationPropertiesSuccessAction = createAction(
  '[GetDocumentListConfigurationProperties/API] Success',
  props<{ response: GetDocumentListConfigurationPropertiesResponse }>()
);
export const getDocumentListConfigurationPropertiesFailureAction = createAction(
  '[GetDocumentListConfigurationProperties/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetDocumentListConfigurationPropertiesStateAction = createAction(
  '[GetDocumentListConfigurationProperties] Clear'
);

// Get Document List Configuration Detail
export const getDocumentListConfigurationDetailAction = createAction(
  '[GetDocumentListConfigurationDetail/API] Send Request',
  props<{ request: GetDocumentListConfigurationDetailRequest }>()
);
export const getDocumentListConfigurationDetailSuccessAction = createAction(
  '[GetDocumentListConfigurationDetail/API] Success',
  props<{ response: GetDocumentListConfigurationDetailResponse }>()
);
export const getDocumentListConfigurationDetailFailureAction = createAction(
  '[GetDocumentListConfigurationDetail/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetDocumentListConfigurationDetailStateAction = createAction(
  '[GetDocumentListConfigurationDetail] Clear'
);

// Update Document List Configuration
export const updateDocumentListConfigurationAction = createAction(
  '[UpdateDocumentListConfiguration/API] Send Request',
  props<{ request: UpdateDocumentListConfigurationRequest }>()
);
export const updateDocumentListConfigurationSuccessAction = createAction(
  '[UpdateDocumentListConfiguration/API] Success',
);
export const updateDocumentListConfigurationFailureAction = createAction(
  '[UpdateDocumentListConfiguration/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearUpdateDocumentListConfigurationStateAction = createAction(
  '[UpdateDocumentListConfiguration] Clear'
);


