import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as HeaderActions from '../actions/payment-header-configuration.actions';
import { PaymentHeaderConfigurationService } from '../../services';
import { GetPaymentHeaderDetailResponse, PaymentEntitiesResponse, PaymentHeaderConfigResponsive, PaymentHeaderConfigurationsResponsive, PaymentHeaderDataResponsive } from '../../types/models';


@Injectable()
export class PaymentHeaderConfigurationEffect {
  constructor(
    private actions$: Actions,
    private paymentHeaderConfigurationService: PaymentHeaderConfigurationService,
  ) {}

  getPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderList),
      switchMap(({ query }) => {
        return this.paymentHeaderConfigurationService.getHeaderList(query).pipe(
          map((headerList: PaymentHeaderConfigurationsResponsive) => {
            return HeaderActions.getHeaderListSuccess({ headerList });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderListFailure({ error }));
          })
        );
      })
    )
  );
  
  createHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.createHeader),
      switchMap(({ body }) => {
        return this.paymentHeaderConfigurationService.createHeader(body).pipe(
          map(( header ) => {
            return HeaderActions.createHeaderSuccess({ body: header });
          }),
          catchError((err) => {
            return of(
              HeaderActions.createHeaderFailure({ error: err.message })
            );
          })
        );
      })
    )
  );

  getPaymentEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getPaymentEntitiesAction),
      switchMap(() =>
        this.paymentHeaderConfigurationService.getPaymentEntities().pipe(
          map((response: PaymentEntitiesResponse) =>
          HeaderActions.getPaymentEntitiesSuccessAction({ response })
          ),
          catchError((error) => of(HeaderActions.getPaymentEntitiesFailureAction({ error })))
        )
      )
    )
  );

  getHeaderDetailData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderDetailAction),
      switchMap(({ id }) => {
        return this.paymentHeaderConfigurationService.getHeaderDetail(id).pipe(
          map((headerDetail: GetPaymentHeaderDetailResponse) => {
            return HeaderActions.getHeaderDetailSuccess({ headerDetail });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderDetailFailure({ error }));
          })
        );
      })
    )
  );
  
  updatePaymentHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.updatePaymentHeaderAction),
      switchMap(({ body }) => {
        return this.paymentHeaderConfigurationService.updatePaymentHeader(body).pipe(
          map(() => {
            return HeaderActions.updatePaymentHeaderSuccess();
          }),
          catchError((err) => {
            return of(
              HeaderActions.updatePaymentHeaderFailure({ error: err.message })
            );
          })
        );
      })
    )
  );

  getHeaderConfigApply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderConfigApplyAction),
      switchMap(({ recordId, entityId, headerId }) => {
        return this.paymentHeaderConfigurationService.getPaymentHeaderConfigApply(recordId, entityId, headerId).pipe(
          map((headerConfigApply: PaymentHeaderConfigResponsive) => {
            return HeaderActions.getHeaderConfigApplySuccess({ headerConfigApply });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderConfigApplyFailure({ error }));
          })
        );
      })
    )
  );

  getHeaderDataApply$ = createEffect(() =>
  this.actions$.pipe(
    ofType(HeaderActions.getHeaderDataApplyAction),
    switchMap(({ query }) => {
      return this.paymentHeaderConfigurationService.getPaymentHeaderDataApply(query).pipe(
        map((headerDataApply: PaymentHeaderDataResponsive) => {
          return HeaderActions.getHeaderDataApplySuccess({ headerDataApply });
        }),
        catchError((error) => {
          return of(HeaderActions.getHeaderDataApplyFailure({ error }));
        })
      );
    })
  )
);
}
