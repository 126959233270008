<div class="title-bar flex">
  <div class="title-name flex" [ngClass]="{'theme-view': isTheme}">
    <ng-container *ngIf="!isProfile; else back">
      {{name}} {{viewName}}
    </ng-container>
    
    <ng-template #back>
      <div class="back-button" (click)="backView()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        {{name}} {{viewName}}
      </div>
    </ng-template>
  </div>
  <div class="title-action flex" [ngClass]="{'theme-view': isTheme}"  *ngIf="!isProfile && !onlyTitle">
    <div class="upload-button flex">
      <div *ngIf="onlyUpload" style="border-left: 1px solid #EBEBEB; margin-right: 24px; height: 24px;"></div>
      <div class="upload-btn" (click)="uploadFile()" *ngIf="!isTheme; else domain">
        <ng-container *ngIf="hasImportKey">
          <mat-icon svgIcon="upload-icon" aria-hidden="false" aria-label="upload-icon"></mat-icon>
          <span>{{uploadName}}</span>
        </ng-container>
      </div>
      <div class="upload-btn" (click)="uploadHistory()" *ngIf="!isTheme && !onlyUpload">
        <mat-icon>history</mat-icon>
        <span>Upload History</span>
      </div>
      <ng-template #domain>
        <a [href]="domainURL" target="_blank">{{domainURL}}</a>
      </ng-template>

      <div class="upload-btn" (click)="download()" *ngIf="haveDownLoad">
        <mat-icon>save_alt</mat-icon>
        <span class="label-download">Bulk Download</span>
        <ng-container *ngIf="isZipping">
          <ptg-three-dots name="Zipping"></ptg-three-dots>
        </ng-container>
      </div>
    </div>
    <ptg-toggle-button *ngIf="!onlyUpload" [controlField]="active" (valueChange)="setStatusView($event)" [single]="true"></ptg-toggle-button>
    <div *ngIf="!onlyUpload && authService.Role === ADMIN_SYSTEM">
      <button mat-icon-button [matMenuTriggerFor]="menu" class="gear-header">
        <mat-icon style="color: #124462">settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="routeAppContent()">
          <span>App Content</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
