import { createAction, props } from '@ngrx/store';

import { GetPayrollRunRequest, GetPayrollRunResponse, ListPayment, PaymentCurrent } from '../../services/models';

export const PaymentCurrentRequest = '[PaymentCurrent/API] Send Request';
export const PaymentCurrentSuccess = '[PaymentCurrent/API] Success';
export const PaymentCurrentFailure = '[PaymentCurrent/API] Failure';

export const GetListPaymentRequest = '[GetListPayment/API] Send Request';
export const GetListPaymentSuccess = '[GetListPayment/API] Success';
export const GetListPaymentFailure = '[GetListPayment/API] Failure';

export const getPaymentCurrent = createAction(PaymentCurrentRequest);
export const getPaymentCurrentSuccess = createAction(
  PaymentCurrentSuccess,
  props<{ paymentCurrent: PaymentCurrent }>()
);
export const getPaymentCurrentFailure = createAction(
  PaymentCurrentFailure,
  props<{ errorMsg: string }>()
);

export const getListPayment = createAction(
  GetListPaymentRequest,
  props<{ query: any }>()
);
export const getListPaymentSuccess = createAction(
  GetListPaymentSuccess,
  props<{ listPayment: ListPayment }>()
);
export const getListPaymentFailure = createAction(
  GetListPaymentFailure,
  props<{ error?: any }>()
);
export const getPayrollRunAction = createAction(
  '[GetPayrollRun/API] Send Request',
  props<{ request: GetPayrollRunRequest }>()
);

export const getPayrollRunSuccessAction = createAction(
  '[GetPayrollRun/API] Success',
  props<{ response: GetPayrollRunResponse }>()
);

export const getPayrollRunFailureAction = createAction(
  '[GetPayrollRun/API] Failure',
  props<{ error?: any }>()
);

export const clearGetPayrollRunStateAction = createAction(
  '[GetPayrollRun] Clear'
);

