<div class="edit-subheader-configuration" *ngIf="!isLoading">
  <div class="edit-subheader-configuration__header" >
    <ng-container *ngIf="listBreadcrumbs.length">
      <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    </ng-container>
  </div>
  <div *ngIf="bannerType" class="px-6 py-4">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <div class="edit-subheader-configuration__body px-6 py-4">
    <div class="flex flex-col gap-4">
      <ptg-edit-subheader
        [description]="description"
        [menuItemsList]="menuItemsList"
        [calculationDetailOption]="calculationDetailOption"
        [currentSubHeaderId]="subHeaderId"
        [currentSelectedEntityId]="currentSelectedEntityId"
        [currentSelectedPropertyId]="currentSelectedPropertyId"
        [currentSelectedValueId]="currentSelectedValueId"
        [chipList]="chipList"
        [isSpouseAge]="isSpouseAge"
        (handleClickSaveBtn)="handleClickSaveBtn($event)"
        (handleClickCancelBtn)="handleClickCancelBtn()"
      ></ptg-edit-subheader>
    </div>
  </div>
</div>
<div id="loading-check" class="is-loading-section" *ngIf="isLoading">
  <div class="wrap-item">
    <mat-progress-spinner diameter="50" mode="indeterminate">
    </mat-progress-spinner>
    <p>Loading...</p>
  </div>
</div>
