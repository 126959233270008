<div class="header-detail-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div class="flex flex-col p-6 gap-6" *ngIf="!isLoading">
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>

      <div class="wrap-btn">
        <button
          mat-raised-button
          type="submit"
          class="submit-button"
          [disabled]="!isChanged"
          (click)="formSubmit$.next()"
        >
          Save
        </button>
        <button
          mat-raised-button
          type="button"
          class="cancel-button"
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>

      <div class="form-control-container">
        <div class="flex gap-5">
          <div class="left-controls flex flex-col w-full">
            <form
              *ngIf="editForm"
              [formGroup]="editForm"
              class="row-controls flex flex-col"
            >
              <ptg-textbox
                placeholder="Header Name"
                errorAsync="Header Name already exists."
                [ngClass]="{
                  'has-error': nameCtrl.errors && nameCtrl.touched
                }"
                width="33%"
                [controlField]="nameCtrl"
                [hasLabel]="true"
                class="mt-1"
                [maxLength]="250"
                (valueChange)="onChangeData()"
              ></ptg-textbox>
              <div class="detail mt-3">
                <span class="label" id="label-Status Name">Entity</span>
                <span class="value" id="value-Status Name">
                  <ng-container>{{headerDetailData?.linkedEntity ?? "Participant"}}</ng-container>
                </span>
              </div>
            </form>
        </div>
      </div>

      <div class="flex gap-5 mt-3">
        <div class="flex-1">
          <div class="box-info">
            <div class="box-info-header">Add Property</div>
            <div class="box-info-content">
              <div class="form-container">
                <ptg-select
                  [isOptionObj]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="availableTypeConfigs"
                  [controlField]="addPropertyForm.get('propertyType')"
                  placeholder="Type"
                  [isMultipleLineOption]="true"
                  panelClass="property-name-dropdown"
                  (changeOptionValue)="changePropertyType()"
                ></ptg-select>
                <ptg-select
                  [isOptionObj]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="availablePropertyConfigs"
                  [controlField]="addPropertyForm.get('propertyName')"
                  placeholder="Property Name"
                  [isMultipleLineOption]="true"
                  panelClass="property-name-dropdown"
                  (changeOptionValue)="changeProperty()"
                ></ptg-select>
                <ptg-radio-button *ngIf="propertyOptions?.length"
                  [controlField]="addPropertyForm.get('option')"
                  [listOption]="$any(propertyOptions)"
                  [isObject]="true"
                ></ptg-radio-button>
                <ptg-textbox
                  [controlField]="addPropertyForm.get('propertyLabel')"
                  errorAsync="Label already exists."
                  [hasLabel]="true"
                  customError="duplicatedValue"
                  placeholder="Label"
                  [maxLength]="250"
                ></ptg-textbox>
                <ptg-button
                  buttonName="Add Property"
                  classInput="add-button"
                  type="button"
                  [isDisabled]="isLoadingAddProperty"
                  (clickButton)="addProperty()"
                >
                  <mat-icon>add</mat-icon>
                </ptg-button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-1">
          <div class="box-info">
            <div class="box-info-header">Order Properties</div>
            <div class="box-info-content">
              <ptg-grid
                #gridLeftCard
                [data]="leftCard"
                [columns]="configuredPropertyColumns"
                [paginable]="false"
                [notFoundMessage]="''"
                [allowDragDrop]="true"
                [hideHeader]="true"
                [inlineEditable]="true"
                [softDeletable]="true"
                (softDelete)="onSoftDeleteSectionConfig($event)"
                (change)="onChangeData()"
              >
                <ng-template cellContent columnName="name" let-row>
                  <div class="multiple-line-text">
                    <span class="caption">{{ row.name }}</span>
                    <span class="description">{{ row.propertyName }}</span>
                  </div>
                </ng-template>
              </ptg-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
    </div>
  </div>
</div>
