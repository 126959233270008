import { FillingStatusType } from '@ptg-processing/features/taxes/types/enums';

export enum ValidateAdjustmentFundingSourcePeriodErrorType {
  StartDateMustBeGreaterOrEqualToBeginningOn = 0,
  EndDateMustBeBeLessOrEqualToEndingOn = 1,
  StartDateMustBeStartDateOfPayrollCycles = 2,
  EndDateMustBeEndDateOfPayrollCycles = 3,
}

export enum AdjustmentFundingDate {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum DeductionType {
  Tax = 1,
  Insurance = 2,
  Garnishment = 3,
  Adjustment = 4,
  Qdro = 5,
  Others = 255,
}

export const DeductionTypeRevert = {
  '1': 'Tax',
  '2': 'Insurance',
  '3': 'Garnishment',
  '4': 'Adjustment',
  '5': 'Qdro',
  '255': 'Others',
}

export enum DeductionSubType {
  FederalTax = 1,
  StateTax = 2,
  Medical = 3,
  Dental = 4,
  Life = 5,
  Garnishment = 21,
  Adjustment = 22,
  Other = 23,
  Qdro = 24,
}

export const FillingStatusLabel = {
  [FillingStatusType.None]: 'None',
  [FillingStatusType.Single]: 'Single',
  [FillingStatusType.Married]: 'Married',
  [FillingStatusType.SingleOrMarried]: 'Single or Married filling separately',
  [FillingStatusType.MarriedOrQualifySpouse]: 'Married filling jointly or Qualifying surviving spouse',
  [FillingStatusType.HeadOfHousehold]: 'Head of household',
};
