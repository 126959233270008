import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { CustomDateFormatter } from './date-formatter/calendar-date-formatter.provider';

@Component({
  selector: 'ptg-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],  
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter
  }]
})
export class CalendarComponent {
  @Input()
  view: CalendarView = CalendarView.Month;

  @Input()
  viewDate: Date = new Date();

  @Input()
  events: CalendarEvent[] = [];

  ngOnInit() {
  }
}
