import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DocumentsService } from "../../services/documents.service";
import {
  approveRefundMuniCreditReq,
  approveRefundMuniCreditReqFailureAction,
  approveRefundMuniCreditReqSuccessAction,
  checkHaveZipFileAction,
  checkHaveZipFileFailureAction,
  checkHaveZipFileSuccessAction,
  downloadMultipleDocumentsAction,
  downloadMultipleDocumentsFailureAction,
  downloadMultipleDocumentsSuccessAction,
  generateMunicipalityDocumentsAction, generateMunicipalityDocumentsFailureAction,
  generateMunicipalityDocumentsSuccessAction,
  generateParticipantDocumentsAction,
  generateParticipantDocumentsFailureAction,
  generateParticipantDocumentsSuccessAction,
  getDocumentDownloadAction,
  getDocumentDownloadFailureAction,
  getDocumentDownloadSuccessAction,
  getGeneratedReportDetailAction,
  getGeneratedReportDetailFailureAction,
  getGeneratedReportDetailSuccessAction,
  getGeneratedReportsInformationAction,
  getGeneratedReportsInformationFailureAction,
  getGeneratedReportsInformationSuccessAction,
  getListTemplateAction,
  getListTemplateFailureAction,
  getListTemplateSuccessAction,
  getListTemplateTagsAction,
  getListTemplateTagsFailureAction,
  getListTemplateTagsSuccessAction,
  getRetirementBenefitDownloadDocumentAction,
  getRetirementBenefitDownloadDocumentFailureAction,
  getRetirementBenefitDownloadDocumentSuccessAction,
  prepareDownloadMultipleDocumentsAction,
  prepareDownloadMultipleDocumentsFailureAction,
  prepareDownloadMultipleDocumentsSuccessAction,
  rejectRefundMuniCreditReq,
  rejectRefundMuniCreditReqFailureAction,
  rejectRefundMuniCreditReqSuccessAction,
  validateGenerateAge65LetterAction,
  validateGenerateAge65LetterFailureAction,
  validateGenerateAge65LetterSuccessAction,
} from '../actions';
import { convertBase64ToBlob, downloadFile } from '@ptg-shared/utils/common.util';
import {
  GetGeneratedReportsInformationResponse,
  PrepareDocumentResponse,
  ValidateGenerateAge65LetterResponse,
} from '../../services/models/documents.model';
import { DatePipe } from "@angular/common";
import { GetListDocumentTemplateResponse } from 'src/app/admin/features/template/services/models';
import { DocumentTemplateService } from 'src/app/admin/features/template/services';

@Injectable()
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private documentsService: DocumentsService,
    private documentTemplateService: DocumentTemplateService
  ) {
  }

  getDocumentDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDocumentDownloadAction),
      switchMap(({ fileId, fileName }) =>
        this.documentsService.downloadDocument(fileId).pipe(
          map((response) => {
            if (response) {
              downloadFile.call(this, response, fileName ?? '');
              return getDocumentDownloadSuccessAction();
            }
            return getDocumentDownloadSuccessAction();
          }),
          catchError((err) => {
            return of(
              getDocumentDownloadFailureAction({
                error: "File not found",
              })
            );
          })
        )
      )
    )
  );

  downloadMultipleDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadMultipleDocumentsAction),
      switchMap(({ zipId }) =>
        this.documentsService.downloadSelectedDocument(zipId).pipe(
          map((response) => {
            if (response) {
              const datePipe = new DatePipe('en-US');
              downloadFile.call(this, response, `MultipleDownload_${datePipe.transform(new Date(), 'MMddyy_HHmmss')}.zip` );
              return downloadMultipleDocumentsSuccessAction();
            }
            return downloadMultipleDocumentsSuccessAction();
          }),
          catchError((err) => {
            return of(
              downloadMultipleDocumentsFailureAction({
                error: err.message,
              })
            );
          })
        )
      )
    )
  );

  prepareDownloadMultipleDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareDownloadMultipleDocumentsAction),
      switchMap(({ fileIds: documentIds }) =>
        this.documentsService.prepareDownloadMultipleDocuments(documentIds).pipe(
          map((response: PrepareDocumentResponse) => prepareDownloadMultipleDocumentsSuccessAction({response})),
          catchError((error) => of(prepareDownloadMultipleDocumentsFailureAction({ error })))
        )
      )
    )
  );

  checkHaveZipFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkHaveZipFileAction),
      switchMap(({zipId} ) =>
        this.documentsService.checkHaveZipFile(zipId).pipe(
          map((response) => checkHaveZipFileSuccessAction({response})),
          catchError((error) => of(checkHaveZipFileFailureAction({ error })))
        )
      )
    )
  );

  getListDocumentTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListTemplateAction),
      switchMap(({ request }) =>
        this.documentTemplateService.getListDocumentTemplate(request).pipe(
          map((response: GetListDocumentTemplateResponse) => getListTemplateSuccessAction({ response })),
          catchError((error) => of(getListTemplateFailureAction({ error })))
        )
      )
    )
  );

  generateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateParticipantDocumentsAction),
      switchMap(({body}) => {
        return this.documentsService.generateDocument(body).pipe(
          map((response: any) => {
            const { isSaved, isValid, message, fileContent, generateInformationId } = response;
            let filePreview;
            if (fileContent) {
              const file = convertBase64ToBlob(fileContent, 'application/pdf');
              filePreview = window.URL.createObjectURL(file);
            }
            return generateParticipantDocumentsSuccessAction({ response: { isSaved, isValid, message, filePreview, generateInformationId } });
          }),
          catchError((err) => {
            return of(generateParticipantDocumentsFailureAction({ err }));
          })
        );
      })
    )
  );

  generateMunicipalityDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateMunicipalityDocumentsAction),
      switchMap(({body}) => {
        return this.documentsService.generateDocument(body).pipe(
          map((response: any) => {
            const { isSaved, isValid, message, fileContent } = response;
            let filePreview;
            if (fileContent) {
              const file = convertBase64ToBlob(fileContent, 'application/pdf');
              filePreview = window.URL.createObjectURL(file);
            }
            return generateMunicipalityDocumentsSuccessAction({ response: { isSaved, isValid, message, filePreview } });
          }),
          catchError((error) => {
            return of(generateMunicipalityDocumentsFailureAction({ error }));
          })
        );
      })
    )
  );

  getRetirementBenefitDownloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRetirementBenefitDownloadDocumentAction),
      switchMap(({ request }) =>
        this.documentsService.getRetirementBenefitDownloadDocument(request).pipe(
          map((response: Blob) => {
            return getRetirementBenefitDownloadDocumentSuccessAction({ response: [...[response]] });
          }),
          catchError((error) => of(getRetirementBenefitDownloadDocumentFailureAction({ error }))),
        ),
      ),
    ),
  );

  validateGenerateAge65Letter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateGenerateAge65LetterAction),
      switchMap(({ request }) =>
        this.documentsService.validateGenerateAge65Letter(request).pipe(
          map((response: ValidateGenerateAge65LetterResponse) => validateGenerateAge65LetterSuccessAction({ response })),
          catchError((error) => of(validateGenerateAge65LetterFailureAction({ error }))),
        ),
      ),
    ),
  );

  getGeneratedReportsInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGeneratedReportsInformationAction),
      switchMap(({ request }) =>
        this.documentsService.getGeneratedReportsInformation(request).pipe(
          map((response: GetGeneratedReportsInformationResponse) => getGeneratedReportsInformationSuccessAction({ response })),
          catchError((error) => of(getGeneratedReportsInformationFailureAction({ error }))),
        ),
      ),
    ),
  );

  getGeneratedReportDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGeneratedReportDetailAction),
      switchMap(({ request }) =>
        this.documentsService.getGeneratedReportDetail(request).pipe(
          map((response: any) => getGeneratedReportDetailSuccessAction({ response })),
          catchError((error) => of(getGeneratedReportDetailFailureAction({ error }))),
        ),
      ),
    ),
  );

  getDocumentTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListTemplateTagsAction),
      switchMap(({ fileId }) =>
        this.documentsService.getTemplateTagsList(fileId).pipe(
          map((response: any) => getListTemplateTagsSuccessAction({ response })),
          catchError((error) => of(getListTemplateTagsFailureAction({ error })))
        )
      )
    )
  );

  approveRefundMuniCreditReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveRefundMuniCreditReq),
      switchMap(({ requestId }) =>
        this.documentsService.approveRefundMuniCreditReq(requestId).pipe(
          map((response: any) => approveRefundMuniCreditReqSuccessAction()),
          catchError((error) => of(approveRefundMuniCreditReqFailureAction({ error })))
        )
      )
    )
  );

  rejectRefundMuniCreditReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectRefundMuniCreditReq),
      switchMap(({ requestId }) =>
        this.documentsService.rejectRefundMuniCreditReq(requestId).pipe(
          map((response: any) => rejectRefundMuniCreditReqSuccessAction()),
          catchError((error) => of(rejectRefundMuniCreditReqFailureAction({ error })))
        )
      )
    )
  );
}
