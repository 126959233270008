import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityPropertyService } from '../../services';
import { GetInitiationPropertiesResponse } from '../../services/models';
import {
  getCourtOrderPayeeInfoAction,
  getCourtOrderPayeeInfoFailureAction,
  getCourtOrderPayeeInfoSuccessAction,
  getInitiationPropertiesAction,
  getInitiationPropertiesFailureAction,
  getInitiationPropertiesSuccessAction,
  getModuleConnectionsAction,
  getModuleConnectionsFailureAction,
  getModuleConnectionsSuccessAction,
  getPayeePropertiesAction,
  getPayeePropertiesFailureAction,
  getPayeePropertiesSuccessAction,
} from '../actions';

@Injectable()
export class EntityPropertyEffect {
  constructor(
    private actions$: Actions,
    private entityPropertyService: EntityPropertyService
  ) { }

  getInitiationProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInitiationPropertiesAction),
      switchMap(({ 
        entityId, 
        includeEntityReference,
        cardId,
        isList, 
        isSummaryView, 
        includeInitiationProperties, 
        entityReferencePropertyId, 
        screenId,
        includeEntitySystem  }) =>
        this.entityPropertyService.getInitiationProperties({
          entityId,
          includeEntityReference,
          cardId,
          isList,
          isSummaryView,
          includeInitiationProperties,
          entityReferencePropertyId,
          screenId,
          includeEntitySystem
        }).pipe(
          map((response: GetInitiationPropertiesResponse) => {
            return getInitiationPropertiesSuccessAction({
              response: {
                ...response,
                includeEntityReference
              }
            })
          }
          ),
          catchError((error) =>
            of(getInitiationPropertiesFailureAction({ error }))
          )
        )
      )
    )
  );

  getModuleConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getModuleConnectionsAction),
      switchMap(() => {
        return this.entityPropertyService
          .getModuleConnections()
          .pipe(
            map((moduleConnections) => {
              return getModuleConnectionsSuccessAction({ moduleConnections });
            }),
            catchError((error) => {
              return of(getModuleConnectionsFailureAction({ error }));
            })
          );
      })
    )
  );

  getPayeeProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayeePropertiesAction),
      switchMap(({ body }) => {
        return this.entityPropertyService.getPayee(body).pipe(
          map((response) => {
            return getPayeePropertiesSuccessAction({ response });
          }),
          catchError((error) => {
            return of(getPayeePropertiesFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  getCourtOrderPayeeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCourtOrderPayeeInfoAction),
      switchMap(({ request }) => {
        return this.entityPropertyService.getCourtOrderPayeeInfo(request).pipe(
          map((response) => {
            return getCourtOrderPayeeInfoSuccessAction({ response });
          }),
          catchError((error) => {
            return of(getCourtOrderPayeeInfoFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
