import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'ptg-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
  @Input() controlField?: AbstractControl | any;
  @Input() label: string = '';
  @Input() single: boolean = false;
  @Input() isIncludeChild: boolean = false;
  @Input() disabled: boolean = false;
  @Input() displayLabel: boolean = false;
  @Input() customError: string = '';

  @Input() value: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();

  onToggle(event: MatSlideToggleChange): void {
    this.value = event.checked;
    this.valueChange.emit(event.checked);
  }
}
