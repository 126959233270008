import { DatePipe } from '@angular/common';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Observable } from 'rxjs';
import {
  AnnualCertificationInformation,
  CertificationHistoryList,
  CertificationHistoryQuery,
} from 'src/app/employer/models/annual-certification.model';
import { environment } from 'src/environments/environment';
import {
  AnnualCertificationConfirmationData,
  AnnualCertificationParticipant,
  AnnualCertificationParticipantList,
  AnnualCertificationParticipantsQuery,
  AnnualMetRequirementsSaveRequest,
  CompleteAnnualCertificationBody,
  ConfirmSignaturesRequestBody,
} from '../models/annual-certification-participants.model';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';


@Injectable({
  providedIn: 'root',
})
export class AnnualCertificationService {
  annualCertificationFile?: File;
  datePipe = new DatePipe('en-US');
  constructor(
    private httpClient: HttpClient,
  ) {}

  getAnnualCertificationInformation(municipalityId: string, annualCertId?: string): Observable<AnnualCertificationInformation> {
    let params = new HttpParams({ fromObject: { municipalityId } });
    if (annualCertId) {
      params = params.append('annualCertId', annualCertId);
    }
    return this.httpClient.get<AnnualCertificationInformation>(`${ environment.apiUrl }/municipality/annual-certification`, { params });
  }

  getCertificationHistoryList(query: CertificationHistoryQuery) {
    let params = new HttpParams();
    if (query.municipalityId) {
      params.append('Municipality', query.municipalityId);
    }

    if (query.totalPerPage && query.pageIndex) {
      params = params.append('PageSize', query.totalPerPage);
      params = params.append('PageIndex', query.pageIndex);
    }

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }

    return this.httpClient.get<CertificationHistoryList>(
      `${environment.apiUrl}/municipality/${query.municipalityId}/AnnualCertificationHistory`,
      { params }
    );
  }

  getAnnualCertificationParticipants(
    annualCertId: string,
    query: AnnualCertificationParticipantsQuery,
  ): Observable<AnnualCertificationParticipantList> {
    const newQuery = deepClone(query) as any;
    if (newQuery.sortNames === null) {
      delete newQuery['sortNames'];
    }
    const params = new HttpParams({ fromObject: newQuery as any });
    return this.httpClient.get<AnnualCertificationParticipantList>(
      `${ environment.apiUrl }/municipality/${ annualCertId }/annual-certification-participants`,
      { params },
    );
  }

  updateEndOfService(
    annualCertificationParticipant: AnnualCertificationParticipant
  ): Observable<void> {
    const endOfServiceParam = {
      endOfService: this.datePipe.transform(annualCertificationParticipant.endOfService, 'yyyy-MM-dd'),
      clientDate: this.datePipe.transform(new Date(), 'YYYY-MM-dd'),
    };
    return this.httpClient.post<void>(
      `${environment.apiUrl}/municipality/${annualCertificationParticipant.annualCertificationId}/${annualCertificationParticipant.id}/end-of-service`,
      endOfServiceParam
    );
  }

  updateMetRequirement(
    annualCertificationParticipant: AnnualCertificationParticipant
  ): Observable<void> {
    const metRequirementParam = {
      isMet: annualCertificationParticipant.metRequirement,
    };
    return this.httpClient.post<void>(
      `${environment.apiUrl}/municipality/${annualCertificationParticipant.annualCertificationId}/${annualCertificationParticipant.id}/met-requirement`,
      metRequirementParam
    );
  }

  checkAllMetRequirement(annualCertId: string) {
    return this.httpClient.get(`${ environment.apiUrl }/municipality/annual-certifications/${annualCertId}/check-all-met-requirement`)
  }

  uploadFile(annualCertId: string) {
    const formData = new FormData();
    formData.append('File', this.annualCertificationFile || null as any);
    return this.httpClient.post<void>(`${environment.apiUrl}/Municipality/${ annualCertId }/upload-annual-certification`, formData);
  }

  saveMetRequirements(annualCertId: string, annualMetSaveRequest: AnnualMetRequirementsSaveRequest) {
    return this.httpClient.post(`${ environment.apiUrl }/municipality/${ annualCertId }/save-met-requirements`, annualMetSaveRequest);
  }

  completeAnnualCertification(annualCertId: string, completeAnnualCertificationBody?: CompleteAnnualCertificationBody) {
    return this.httpClient.post(`${ environment.apiUrl }/municipality/${annualCertId}/complete-annual-certification`, completeAnnualCertificationBody ?? {});
  }

  verifyCashJournal(cashJournal: string, IsTransaction: boolean = false) {
    return this.httpClient.post(`${ environment.apiUrl }/municipality/verify-cash-journal`, { cashJournal, IsTransaction });
  }

  confirmationBeforePostingAnnualCertification(annualCertId: string) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.get<AnnualCertificationConfirmationData>(`${ environment.apiUrl }/municipality/${annualCertId}/confirmation-before-posting-annual-certification`, { context });
  }

  setConfirmSignatures(confirmSignaturesRequestBody: ConfirmSignaturesRequestBody) {
    return this.httpClient.post<AnnualCertificationConfirmationData>(`${ environment.apiUrl }/municipality/annual-certification-action-confirm-signatures`, confirmSignaturesRequestBody);
  }

  updateMode(annualCertId: string): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/municipality/${annualCertId}/annual-certification-action-change-mode-flow`, {});
  }
}
