import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FileSignature } from "../types/models/file-signature";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private httpClient: HttpClient) { }

    public signFile(fileKey: string): Observable<FileSignature> {
        return this.httpClient.get<FileSignature>(`${environment.apiUrl}/Files/Sign?key=${fileKey}`);
    }    

    generateValidationReport(sessionId: string) {
        return this.httpClient.post(`${ environment.apiUrl }/Imports/${sessionId}/GenerateValidationReport`, {});
    }

    downloadValidateFile(sessionId: string) {
        return this.httpClient.post(`${ environment.apiUrl }/Imports/${sessionId}/DownloadValidationReport`, {}, { responseType: 'blob' });
    }

    downloadRawFile(sessionId: string) {
        return this.httpClient.post(`${ environment.apiUrl }/Imports/${sessionId}/DownloadRawFile`, {}, { responseType: 'blob' });
    }
}