import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployerCheckDateOfBirthRequest, EmployerCheckDisableRequest } from '@ptg-employer/models/employer.model';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  UpsertEntityDataRequest,
  CreateEntityListDataRequest,
  CheckDenpendentRecordInUseResponse,
  GetValidateCourtOrderRequest,
  GetValidateCourtOrderResponse,
  ValidateReportedDeceasedResponse,
  ValidateMaritalRequest,
  ValidateMaritalResponse
} from './models';
import { SearchResult } from '@ptg-shared/layout/models/search.models';
import { transformEntityListData, transformEntityToObject } from '@ptg-entity-management/helpers';
import { ENTITY_ID_GUID } from '@ptg-shared/constance';

@Injectable()
export class EntityDataService {
  constructor(private httpClient: HttpClient) {}

  createEntityData(recordId: string, request: UpsertEntityDataRequest, cardId?: string, auditTrailReason?: string): Observable<any> {
    const transformedData = transformEntityToObject(request);
    const body = {
      data: {
        ...transformedData.entityData,
        [ENTITY_ID_GUID]: recordId
      },
      description: auditTrailReason,
      cardId,
    }

    return this.httpClient.post<any>(
      `${environment.apiUrl}/entity-new/entities/${request.entityId}/entity-data`,
      body
    );
  }

  searchEntityDatas(
    searchValue: string,
    entityId: string,
    exceptRecordIds?: string[],
  ) {
    const payload = {
      searchValue,
      timeZoneOffset: new Date().getTimezoneOffset(),
      entityId,
      IsSearchAllEntity: true,
      exceptRecordIds
    };
    return this.httpClient.post<SearchResult | any>(`${environment.apiUrl}/entities/members/search`, payload);
  }

  getDetailsEntityDatas(
    entityId: string,
    id: string
  ) {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/entities/${entityId}/entity-data/${id}`,
    );
  }

  createEntityListData(request: CreateEntityListDataRequest): Observable<any> {
    const body = {
      ...transformEntityListData(request),
      cardId: request?.cardId,
      description: request?.auditTrailReason,
    };
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entity-new/components/${request.entityComponentId}/entity-data/${request.targetId}/component-data`,
      body
    );
  }

  checkDenpendentRecordInUse(targetId: string, recordId: string): Observable<CheckDenpendentRecordInUseResponse> {
    return this.httpClient.get<CheckDenpendentRecordInUseResponse>(
      `${environment.apiUrl}/entity-new/components/dependent/${targetId}/${recordId}/in-use`
    );
  }

  confirmRetireRehire(
    memberId: string,
    isRetireRehire: boolean
  ): Observable<{ isShowConfirmPopup: boolean }> {
    return this.httpClient.get<{ isShowConfirmPopup: boolean }>(
      `${environment.apiUrl}/entities/municipalities/members/${memberId}/retire-rehire/${isRetireRehire}/confirmation`
    );
  }

  getMunicipalityCheckDisable(body: EmployerCheckDisableRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.get<EmployerCheckDisableRequest>(`${ environment.apiUrl }/municipalities/${body.employerId}/check-disabled/${body.inactivationDate}`, { context });
  }

  getMunicipalityCheckDateOfBirth(body: EmployerCheckDateOfBirthRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    let params = new HttpParams();
    if (body?.dateOfBirth) {
      params = params.append('DateOfBirth', body.dateOfBirth);
    }
    if (body?.memberId) {
      params = params.append('MemberId', body.memberId);
    }
    return this.httpClient.get<EmployerCheckDisableRequest>(`${ environment.apiUrl }/members/validate-member-sixteen`, { params, context });
  }

  getEntityMetadataAction() {
    return this.httpClient.get<any>(
      `${environment.apiUrl}/entity-new/entities-metadata`,
    );
  }

  validateCourtOrder(request: GetValidateCourtOrderRequest): Observable<GetValidateCourtOrderResponse> {
    return this.httpClient.get<GetValidateCourtOrderResponse>(
      `${environment.apiUrl}/calculations/${request.memberId}/${request.courtOrderType}/case-number/${request.caseNumber}/validate`,
    );
  }

  validateDateOfDeath(ids: string[]): Observable<ValidateReportedDeceasedResponse> {
    let params = new HttpParams();
    ids.forEach(id => {
      params = params.append('ids', id);
    });
    return this.httpClient.get<ValidateReportedDeceasedResponse>(
      `${environment.apiUrl}/members/person-info`, {params},
    );
  }

  checkPaymentInProgress(payeeRecordIds: string[]): Observable<boolean> {
    let params = new HttpParams();
    if (payeeRecordIds.length) {
      payeeRecordIds.forEach(payeeRecordId => {
        params = params.append('payeeRecordIds', payeeRecordId);
      })
    }
    return this.httpClient.get<boolean>(`${ environment.apiUrl }/new-payroll/reported-deceased-warning`, { params });
  }
  
  getLookupTableByFixedKey(fixedKey: string): Observable<any>{
    let params = new HttpParams();
    params = params.append('clientFixedKey', fixedKey);
    return this.httpClient.get<any>(`${environment.apiUrl}/lookup-table`, { params });
  }

  validateJobHistoryDateRange(beginDate: string, endDate: string, memberId: string, employerId: string, recordId: string): Observable<any> {
    let params = new HttpParams();
    
    if (beginDate) { params = params.append('beginDate', beginDate); }
    if (endDate) { params = params.append('endDate', endDate); }
    params = params.append('memberId', memberId);
    if (employerId) { params = params.append('employerId', employerId) };
    if (recordId) { params = params.append('recordId', recordId) };    
    return this.httpClient.get<any>(`${environment.apiUrl}/members/job-history/validate-date-range`, { params });
  }

  validateJobHistorySubsetServicesHistory(beginDate: string, endDate: string, memberId: string, employerId: string, recordId: string): Observable<any> {
    let params = new HttpParams();
    
    if (beginDate) { params = params.append('beginDate', beginDate); }
    if (endDate) { params = params.append('endDate', endDate); }
    params = params.append('memberId', memberId);
    if (employerId) { params = params.append('employerId', employerId) };
    if (recordId) { params = params.append('recordId', recordId) };    
    return this.httpClient.get<any>(`${environment.apiUrl}/members/job-history/validate-subset-services`, { params });
  }
  
  validateServiceHistoryDateRange(beginDate: string, endDate: string, memberId: string, employerId: string, recordId: string): Observable<any> {
    let params = new HttpParams();    
    if (beginDate) { params = params.append('beginDate', beginDate); }
    if (endDate) { params = params.append('endDate', endDate); }
    params = params.append('memberId', memberId);
    if (employerId) { params = params.append('employerId', employerId) };
    if (recordId) { params = params.append('recordId', recordId) };    
    return this.httpClient.get<any>(`${environment.apiUrl}/members/service-history/validate-date-range`, { params });
  }

  validateMaritalInformation(request: ValidateMaritalRequest): Observable<ValidateMaritalResponse> {
    let params = new HttpParams();
    if(request.basePersonId)
      params = params.append('basePersonId',request.basePersonId);

    if(request.selectedPersonId)
      params = params.append('selectedPersonId',request.selectedPersonId);

    if(request.marriageDate)
      params = params.append('marriageDate',request.marriageDate);
    
    if(request.divorceDate)
      params = params.append('divorceDate',request.divorceDate);
    

    return this.httpClient.get<ValidateMaritalResponse>(`${environment.apiUrl}/entity-new/entities/${request.componentId}/validate-marital-information`, {params});
  }
}

