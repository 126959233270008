<div class="status-history-container" id="status-history-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="status-history-content flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <!-- benefit -->
    <ptg-detail-display-box
      [selectedItem]="benefit || { isActiveQDRO: false }"
      [propertyDisplayed]="
        benefit?.isActiveQDRO ? propertyDisplayed : propertyDisplayedNotShowQDRO
      "
      (editButtonEvent)="onClickConfigureBenefit()"
      title="Benefits"
    ></ptg-detail-display-box>

    <!-- benefit type list -->
    <div class="flex-grow overflow-hidden">
      <div class="list-display">
        <div class="list-header">
          <span class="title-name">Benefit Type List</span>
          <div class="button-container">
            <div class="separator"></div>
            <div class="button-group">
              <ptg-button
                [buttonName]="'New Benefit Type'"
                classInput="edit-button"
                (clickButton)="onClickNewBenefitType()"
              >
                <mat-icon>add</mat-icon>
              </ptg-button>
            </div>
          </div>
        </div>

        <div class="grid-wrapper">
          <ptg-grid
            [data]="benefitTypesData"
            [columns]="columns"
            [isLoading]="isLoading"
            [totalRecords]="totalRecords"
            [pageSize]="pageSize"
            [pageNumber]="pageNumber"
            [fitToParent]="true"
            [errorMessage]="errorMsg"
            notFoundMessage="No Benefit Type to display"
            (pageChange)="onChangePage($event)"
            (sortChange)="onChangeSort($event)"
          >
            <ng-template cellContent columnName="statusName" let-row>
              <div class="flex">
                <mat-icon
                  class="mt-auto mb-auto"
                  style="font-size: 18px; width: 18px; height: 18px"
                  [style.color]="row.statusColor"
                >
                  {{ row.statusIconName }}
                </mat-icon>
                <span class="mt-auto mb-auto ml-2">
                  {{ row.statusName }}
                </span>
              </div>
            </ng-template>

            <ng-template cellContent columnName="firstParentTypeCode" let-row>
              {{ row.parentTypeCodes }}
            </ng-template>

            <ng-template cellContent columnName="action" let-row>
              <ptg-button
                buttonName="Edit"
                classInput="edit-button"
                (clickButton)="onClickEditBenefitType(row)"
              >
                <mat-icon>edit</mat-icon>
              </ptg-button>
              <ptg-button
                *ngIf="row?.totalParticipant === 0 && row?.allowRemove"
                buttonName="Remove"
                classInput="remove-button"
                (clickButton)="onClickRemoveBenefitType(row)"
              >
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-template>
          </ptg-grid>
        </div>
      </div>
    </div>
  </div>
</div>
