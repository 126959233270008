import { createAction, props } from '@ngrx/store';
import { GetNextPayrollRunListQueryResult } from '../../services/models';

export const getPayrollRunsAction = createAction(
  '[GetPayrollRuns/API]'
);

export const getPayrollRunsSuccessAction = createAction(
  '[GetPayrollRuns/API] Success',
  props<{ response: GetNextPayrollRunListQueryResult }>()
);

export const getPayrollRunsFailureAction = createAction(
  '[GetPayrollRuns/API] Failure',
  props<{ error?: any }>()
);

export const clearGetPayrollRunsStateAction = createAction(
  '[GetPayrollRuns/API] Clear'
)

export const startPayrollRunAction = createAction(
  '[StartPayrollRun/API]',
  props<{ calendarDateDetailId: string }>()
);

export const startPayrollRunSuccessAction = createAction(
  '[StartPayrollRun/API] Success'
);

export const startPayrollRunFailureAction = createAction(
  '[StartPayrollRun/API] Failure',
  props<{ error?: any }>()
);

export const clearStartPayrollRunStateAction = createAction(
  '[StartPayrollRun] Clear'
)

