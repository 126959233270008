import { BaseActionState } from "@ptg-shared/types/models";
import { FeeAmounts } from "../models/employer-setting.model";
import { createReducer, on } from "@ngrx/store";
import { EmployerSettingActions } from '../actions';
import { MunicipalityType } from "../constance/employer-setting.const";
import { ACTION, STATE } from "@ptg-shared/constance/value.const";
import { deepClone } from "@ptg-shared/utils/common.util";

export const employerSettingFeatureKey = 'employerSetting';

export interface State {
  settingFeeAmounts: {
    listFireDepartmentFees: BaseActionState<FeeAmounts[]>;
    listReserveOfficerFees: BaseActionState<FeeAmounts[]>;
    listEMSDFees: BaseActionState<FeeAmounts[]>;
    dataState?: {
      type?: MunicipalityType,
      action?: string,
      state?: string
    }
  }
}

const initialState: State = {
  settingFeeAmounts: {
    listFireDepartmentFees: {
      type: MunicipalityType.FireDepartmentFees,
      isLoading: true
    },
    listReserveOfficerFees: {
      type: MunicipalityType.ReserveOfficerFees,
      isLoading: true
    },
    listEMSDFees: {
      type: MunicipalityType.EMSDFees,
      isLoading: true
    },
  }
};

export const reducer = createReducer(
  initialState,
  on(EmployerSettingActions.getFireDepartmentFeesRequest, (state, { query }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listFireDepartmentFees: {
        ...state.settingFeeAmounts.listFireDepartmentFees,
        isLoading: true,
        total: 0,
        payload: []
      }
    }
  })),
  on(EmployerSettingActions.getFireDepartmentFeesSuccess, (state, { response }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listFireDepartmentFees: {
        ...state.settingFeeAmounts.listFireDepartmentFees,
        isLoading: false,
        total: response?.total,
        payload: response?.feeAmountDtos,
        success: true
      }
    }
  })),
  on(EmployerSettingActions.getFireDepartmentFeesFailure, (state) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listFireDepartmentFees: {
        ...state.settingFeeAmounts.listFireDepartmentFees,
        isLoading: false,
        total: 0,
        payload: [],
        success: false
      }
    }
  })),

  on(EmployerSettingActions.getReserveOfficerFeesRequest, (state, { query }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listReserveOfficerFees: {
        ...state.settingFeeAmounts.listReserveOfficerFees,
        isLoading: true,
        total: 0,
        payload: []
      }
    }
  })),
  on(EmployerSettingActions.getReserveOfficerFeesSuccess, (state, { response }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listReserveOfficerFees: {
        ...state.settingFeeAmounts.listReserveOfficerFees,
        isLoading: false,
        total: response?.total,
        payload: response?.feeAmountDtos,
        success: true
      }
    }
  })),
  on(EmployerSettingActions.getReserveOfficerFeesFailure, (state) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listReserveOfficerFees: {
        ...state.settingFeeAmounts.listReserveOfficerFees,
        isLoading: false,
        total: 0,
        payload: [],
        success: false
      }
    }
  })),

  on(EmployerSettingActions.getEMSDFeesRequest, (state, { query }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listEMSDFees: {
        ...state.settingFeeAmounts.listEMSDFees,
        isLoading: true,
        total: 0,
        payload: []
      }
    }
  })),
  on(EmployerSettingActions.getEMSDFeesSuccess, (state, { response }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listEMSDFees: {
        ...state.settingFeeAmounts.listEMSDFees,
        isLoading: false,
        total: response?.total,
        payload: response?.feeAmountDtos,
        success: true
      }
    }
  })),
  on(EmployerSettingActions.getEMSDFeesFailure, (state) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      listEMSDFees: {
        ...state.settingFeeAmounts.listEMSDFees,
        isLoading: false,
        total: 0,
        payload: [],
        success: false
      }
    }
  })),

  on(EmployerSettingActions.clearFeeAmountsState, (state) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: undefined,
        action: '',
        state: ''
      }
    }
  })),

  on(EmployerSettingActions.addFeeAmountsRequest, (state, { body }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: body.type,
        action: ACTION.ADD
      }
    }
  })),
  on(EmployerSettingActions.addFeeAmountsSuccess, (state, { municipalityType }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: municipalityType,
        action: ACTION.ADD,
        state: STATE.SUCCESS
      }
    }
  })),
  on(EmployerSettingActions.addFeeAmountsFailure, (state, { municipalityType }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: municipalityType,
        action: ACTION.ADD,
        state: STATE.FAIL
      }
    }
  })),

  on(EmployerSettingActions.editFeeAmountsRequest, (state, { body }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: body.type,
        action: ACTION.EDIT
      }
    }
  })),
  on(EmployerSettingActions.editFeeAmountsSuccess, (state, { municipalityType }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: municipalityType,
        action: ACTION.EDIT,
        state: STATE.SUCCESS
      }
    }
  })),
  on(EmployerSettingActions.editFeeAmountsFailure, (state, { municipalityType }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: municipalityType,
        action: ACTION.EDIT,
        state: STATE.FAIL
      }
    }
  })),

  on(EmployerSettingActions.deleteFeeAmountsRequest, (state, { body }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: body.type,
        action: ACTION.REMOVE
      }
    }
  })),
  on(EmployerSettingActions.deleteFeeAmountsSuccess, (state, { municipalityType }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: municipalityType,
        action: ACTION.REMOVE,
        state: STATE.SUCCESS
      }
    }
  })),
  on(EmployerSettingActions.deleteFeeAmountsFailure, (state, { municipalityType }) => ({
    ...state,
    settingFeeAmounts: {
      ...state.settingFeeAmounts,
      dataState: {
        type: municipalityType,
        action: ACTION.REMOVE,
        state: STATE.FAIL
      }
    }
  })),
);
