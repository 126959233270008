export const DOCUMENT_LOCATION = {
  RETIREMENT: 'Retirement',
  DISABILITY: 'Disability',
  REFUND: 'Refund',
  ACCIDENT: 'Accident',
  OVERVIEW: 'Overview',
  DOCUMENTLIST: 'Document List',
  AGE65_LETTER: 'Age 65 Letter',
  LODD_DEATH: 'LODD Death',
  LODD_SURVIVOR: ' LODD Survivor',
  ANNUAL_CERTIFICATION_HISTORY: 'Annual Certification History',
  PENDING_PAYMENTS: 'Pending Payments',
};

export const CALCULATION_DOCUMENT_LOCATION = [
  DOCUMENT_LOCATION.RETIREMENT,
  DOCUMENT_LOCATION.DISABILITY,
  DOCUMENT_LOCATION.REFUND,
  DOCUMENT_LOCATION.ACCIDENT,
  DOCUMENT_LOCATION.DOCUMENTLIST,
];

export enum USED_FOR_MENU {
  EDIT_COMMON_DOCUMENT = 'Edit_Common_Document', // @-> Use for case Edit Document of Accident, Retirement Benefit, Refund, Disability, LODD
  RETIREMENT_BENEFIT_OVERVIEW = 'Retirement_Benefit_Overview',
  RETIREMENT_BENEFIT_DETAIL = 'Retirement_Benefit_Detail',
  DISABILITY_DETAIL = 'Disability_Detail',
  DISABILITY_CALCULATION_DETAIL = 'Disability_Calculation_Detail',
  REFUND_DETAIL = 'Refund_Detail',
  REFUND_CALCULATION_DETAIL = 'Refund_Calculation_Detail',
  ACCIDENT = 'Accident',
  ATTACHMENTS = 'Attachments',
  // @-> Maybe change this property LODD later for LODD implement
  LODD_CALCULATION_DETAIL = 'LODD_Calculation_Detail',
}
