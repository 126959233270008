import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { deepClone } from '@ptg-shared/utils/common.util';

import { RelationshipType } from '../../constance/participant-relationship.const';
import { ParticipantRelationship } from '../../types/models/organization.model';
import { FilterPropertyType } from '@ptg-member/constance/member-list.const';

@Component({
  selector: 'ptg-organization-chart',
  templateUrl: './organization-chart.component.html',
  styleUrls: ['./organization-chart.component.scss'],
})
export class OrganizationChartComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  @Input() participantRelationships!: ParticipantRelationship[];
  @Input() screenId!: string;
  @Input() memberId!: string;
  readonly RelationshipType = RelationshipType;
  readonly FilterPropertyType = FilterPropertyType;
  chartData: {
    parents: ParticipantRelationship[];
    siblings: ParticipantRelationship[];
    children: ParticipantRelationship[];
  } = {
    parents: [],
    siblings: [],
    children: [],
  };

  selectedItem: ParticipantRelationship | undefined;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.participantRelationships) {
      this.updateChart();
    }
  }

  updateChart() {
    if (!this.participantRelationships?.length) {
      return;
    }
    const parents = (
      deepClone(this.participantRelationships) as ParticipantRelationship[]
    ).filter((item) => item.graphRelationshipType === RelationshipType.Parent);
    const children = (
      deepClone(this.participantRelationships) as ParticipantRelationship[]
    ).filter((item) => item.graphRelationshipType === RelationshipType.Child);
    const current = (
      deepClone(this.participantRelationships) as ParticipantRelationship[]
    ).filter((item) => item.graphRelationshipType === RelationshipType.Self);
    const siblings = [...current];
    this.chartData = {
      parents,
      siblings,
      children,
    };
  }

  selectRelationshipItem(item: ParticipantRelationship) {
    this.setSelectedRelationshipItem(item);
    this.selectedItem = item;
  }

  setSelectedRelationshipItem(item?: ParticipantRelationship) {
    Object.keys(this.chartData).forEach((key: string) => {
      ((this.chartData as any)[key] as ParticipantRelationship[]).forEach(
        (element) => (element.selected = element === item)
      );
    });
  }

  closeDetailPanel() {
    this.selectedItem = undefined;
    this.setSelectedRelationshipItem();
  }
}
