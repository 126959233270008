import { Injectable } from '@angular/core';
import { CalculationType } from '../../types/enums';
import { MenuItemTitle } from '@ptg-member/constants';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';

export interface BreadcrumbAndMenuTitle {
  calculationTypeForBreadcrumb: CalculationType;
  menuItemTitle: MenuItemTitle;
}

@Injectable()
export class RetirementBenefitDetailComponentService {
  getBreadcrumbAndMenuTitleByCalculationType(calculationType: CalculationType): BreadcrumbAndMenuTitle {
    if (calculationType === CalculationType.RetirementBenefit) {
      return {
        calculationTypeForBreadcrumb: CalculationType.RetirementBenefit,
        menuItemTitle: MenuItemTitle.RetirementBenefit,
      };
    }
    if (calculationType === CalculationType.QDRO) {
      return {
        calculationTypeForBreadcrumb: CalculationType.QDRO,
        menuItemTitle: MenuItemTitle.QDRO,
      };
    }
    return {
      calculationTypeForBreadcrumb: CalculationType.Survivor,
      menuItemTitle: MenuItemTitle.Survivor,
    };
  }

  getTitleByCalculationType(calculationType: CalculationType): string {
    switch (calculationType) {
      case CalculationType.RetirementBenefit:
        return 'Retirement Benefit Detail';

      case CalculationType.Survivor:
        return 'Survivor Benefit Detail';

      case CalculationType.JointSurvivor:
        return 'Joint Survivor Benefit Detail';

      case CalculationType.LODDDeath:
        return 'Death Benefit';

      case CalculationType.LODDSurvivor:
        return 'Survivor (LODD)';

      case CalculationType.QDRO:
        return '{qdroLabelName} Benefit Detail';

      case CalculationType.ChildSurvivor:
        return 'Child Survivor Benefit Detail';

      default:
        return '';
    }
  }

  getDocumentListDisplayStatus(calculationType: CalculationType): boolean {
    return [
      CalculationType.RetirementBenefit,
      CalculationType.LODDDeath,
      CalculationType.LODDSurvivor,
      CalculationType.LODDBenefit,
    ].some((x) => x === calculationType);
  }

  getGearMenuSettings(memberId = '', calculationType: CalculationType): Breadcrumb[] {
    return calculationType !== CalculationType.RetirementBenefit
      ? []
      : [
          {
            name: 'Input/Output',
            url: `/member/calculation/retirement-benefit-input-outputs/${memberId}`,
          },
          {
            name: 'Calculation List',
            url: `/member/calculation/retirement-benefit-calculation-list/${memberId}`,
          },
          {
            name: 'Sub - Header',
            url: '',
          },
          {
            name: 'Step Configuration',
            url: `/member/calculation/step-configuration/${memberId}`,
          },
          {
            name: 'Calculation Parameter',
            url: `/member/calculation/calculation-parameter-configuration/${memberId}`,
          },
          {
            name: 'Benefit Calculation',
            url: `/member/calculation/benefit-calculation-configuration/${memberId}`,
          },
          {
            name: 'Exception Calculation',
            url: `/member/calculation/exception-configuration/${memberId}`,
          },
        ];
  }
}
