
<mat-form-field class="input-color" appearance="fill">
  <mat-label
    [ngClass]="[
      (controlField?.hasValidator(Validators.required) || shouldShowRequiredIndicator)
        ? REQUIRED_INDICATOR
        : '',
    ]"
  >{{placeholder}}</mat-label>
  <input 
    #inputText
    matInput [formControl]="controlField"
    autocomplete="off"
    [(colorPicker)]="colorCode"
    [cpPosition]="'bottom'"
    [cpDisableInput]="true"
    [cpAlphaChannel]="'disabled'"
    [cpOutputFormat]="'hex'"
    (colorPickerChange)="colorPickerChange($event)"
    (cpSliderDragEnd)="cpSliderDragEnd($event)"
    (input)="inputManual()"
  />
  <div class="show-color" #color [style.backgroundColor]="colorCode" (focus)="focusInput()"></div>
  <mat-error *ngIf="controlField?.errors?.wrongFormat">{{errorInvalid}}</mat-error>
</mat-form-field>
