import { createSelector } from '@ngrx/store';

import { viewAdjustmentDetailsState } from './selector';

export const getAdjustmentDetailsSelector = createSelector(
  viewAdjustmentDetailsState,
  (state) => state?.viewAdjustmentDetails,
);

export const getAdjustmentDetailItemPositionSelector = createSelector(
  viewAdjustmentDetailsState,
  (state) => state?.adjustmentDetailItemPosition,
);
