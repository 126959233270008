import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { AbstractControlStatus, InputProperty } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { debounceTime, filter, startWith, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'ptg-confirm-popup-with-data',
  templateUrl: './confirm-popup-with-data.component.html',
  styleUrls: ['./confirm-popup-with-data.component.scss']
})
export class ConfirmPopupWithDataComponent implements OnInit {
  readonly EntityPropertyType = EntityPropertyType;
  formGroup: FormGroup = new FormGroup({});
  formSubmit$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupWithDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      icon: string,
      properties: InputProperty[],
    }
  ) { }

  ngOnInit(): void {
    this._setFormGroup();
    this._submitForm();
  }

  private _setFormGroup() {
    if (!this.data?.properties?.length) {
      return;
    }
    this.data.properties.forEach(p => {
      const formControl = new FormControl({
        value: p.value ?? null,
        disabled: p.isDisabled,
      });
      if (p.customValidators) {
        formControl.addValidators(p.customValidators);
      }
      this.formGroup.setControl(p.key, formControl);
    });
  }

  private _submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formGroup.markAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter(status => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this._onClosePopup();
      });
  }

  private _onClosePopup() {
    const propertiesValue = this.data.properties.map(p => {
      return {
        key: p.key,
        value: this.formGroup.get(p.key)?.value
      }
    });
    this.dialogRef.close(propertiesValue);
  }

  cancel() {
    this.dialogRef.close();
  }
}
