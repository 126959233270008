<div class="view-dataless-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div class="flex flex-col py-6 px-12 gap-6" *ngIf="!isLoadingView">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        (click)="submit()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-control-container">
      <div class="flex flex-col mb-4 view-name">
        <span class="label text-sm">View Name</span>
        <span class="value text-lg">
          <ng-container>{{this.viewDatalessData?.name}}</ng-container>
        </span>
      </div>

      <form [formGroup]="viewForm" (ngSubmit)="submit()">
        <div
          class="card mb-6"
          *ngFor="let child of getControls; let i=index"
        >
          <div class="header p-4">
            <div class="card-name">
              <span class="title">{{ child.get('label')?.value }}</span>
              <span class="sub-title">{{ child.get('name')?.value }}</span>
            </div>
            <div class="divider-vertical"></div>
            <div class="card-chip">
              <span class="chip" *ngFor="let chip of child.get('chips')?.value.split(',');">{{ chip }}</span>
            </div>
          </div>
          <div class="px-4 py-6 grid grid-cols-3 gap-x-8 gap-y-4">
            <div>
              <div class="text-lg mb-4">When Card contains no data</div>
              <div class="my-4">
                <ptg-toggle-button
                  [controlField]="child.get('isHideEmptyCard')"
                  label="Hide empty Card"
                  width="100%"
                  (valueChange)="changeToggleHideEmptyCard(child.get('isHideEmptyCard')?.value, i)"
                ></ptg-toggle-button>
              </div>
              <div class="pl-10">
                <ptg-select
                  [listData]="propertyDisplayPreferenceData"
                  [controlField]="child.get('propertyDisplayPreferenceType')"
                  placeholder="Property Display Preference"
                  width="100%"
                  [isOptionObj]="true"
                  *ngIf="!child.get('isHideEmptyCard')?.value && child.get('chips')?.value.split(',').includes('Properties')"
                ></ptg-select>
                <ptg-select
                  [listData]="listDisplayPreferenceData"
                  [controlField]="child.get('listDisplayPreferenceType')"
                  placeholder="List Display Preference"
                  width="100%"
                  [isOptionObj]="true"
                  *ngIf="!child.get('isHideEmptyCard')?.value && child.get('chips')?.value.split(',').includes('List')"
                ></ptg-select>
              </div>
            </div>
            <div *ngIf="child.get('chips')?.value.split(',').includes('Properties')">
              <div class="text-lg mb-4">
                <span>When Card contains data</span>
                &nbsp;
              </div>
              <ptg-select
                [listData]="nonValuePropertyDisplayData"
                [controlField]="child.get('nonValuePropertyDisplayType')"
                placeholder="Non-value Property Display"
                width="100%"
                [isOptionObj]="true"
              ></ptg-select>
              <ptg-select
                [listData]="listDisplayPreferenceData"
                [controlField]="child.get('nonRecordListDisplayType')"
                placeholder="Non-record List Display"
                width="100%"
                [isOptionObj]="true"
                *ngIf="child.get('chips')?.value.split(',').includes('List')"
              ></ptg-select>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoadingView">
    <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
    </div>
  </div>
</div>
