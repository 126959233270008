<div class="dialog-container" class="edit-buyback-makeup-interest-fees-dialog">
  <div class="header-title">Buyback/Make-up Interest Fees</div>
  <form (ngSubmit)="formSubmit$.next()" [formGroup]="editForm" class="edit-form" [ngClass]="{ 'hide-section': isCalculating }">
    <div class="form-container">
      <div class="left-content flex">
        <div class="remittance-info">
          <div class="items">
            <div class="item">
              <span class="label truncate">Created Date</span>
              <span class="value truncate">{{ currentBuybackMakeupYearRecord?.createdDate || '' }}</span>
            </div>
            <div class="item">
              <span class="label truncate">Remittance Year</span>
              <span class="value truncate">{{ currentBuybackMakeupYearRecord?.year }}</span>
            </div>
            <div class="item">
              <span class="label truncate">Due Date</span>
              <span class="value truncate">{{ currentBuybackMakeupYearRecord?.dueDate || '' | date: 'MM/dd/yyyy' }}</span>
            </div>
            <div class="item"></div>
            <div class="item">
              <span class="label truncate">Interest Begin Date</span>
              <span class="value truncate">
                <ptg-datepicker
                  placeholder="Interest Begin Date"
                  [controlField]="editForm.get('interestBeginDate')"
                  [isRequired]="true"
                  class="no-placeholder-input begin-date"
                  [minDate]="interestBeginMinDate"
                  [errorMinDate]="errorBeginDateMinDate"
                  (changeValue)="onDateChange()"
                  errorRange="Interest Begin Date must be earlier than Interest Thru Date."
                  [isDisabled]="isCalculating"
                ></ptg-datepicker>
              </span>
            </div>
            <div class="item"></div>
            <div class="item">
              <span class="label truncate">Interest Thru Date</span>
              <span class="value truncate">
                <ptg-datepicker
                  placeholder="Interest Thru Date"
                  [controlField]="editForm.get('interestThruDate')"
                  [isRequired]="true"
                  class="no-placeholder-input thru-date"
                  (changeValue)="onDateChange()"
                  errorRange="Interest Begin Date must be earlier than Interest Thru Date."
                  [isDisabled]="isCalculating"
                ></ptg-datepicker>
              </span>
            </div>
          </div>
        </div>
        <div class="remittance-info">
          <div class="title title-text">
            Pension
          </div>
          <div class="items">
            <div class="item">
              <span class="label truncate">Amount Due</span>
              <span class="value truncate">{{ (currentBuybackMakeupYearRecord?.pensionAmount | numberLocalDecimal:'$') || '-' }}</span>
            </div>
            <div class="item"></div>
          </div>
        </div>
      </div>

      <div class="right-content">
        <div class="item balance-due">
          <span class="label truncate title-text">Balance Due</span>
          <span class="value truncate">{{ (currentBuybackMakeupYearRecord?.pensionAmount | numberLocalDecimal:'$') || '-' }}</span>
        </div>
        <div class="item total-interest">
          <span class="label truncate p-t-6">
            <span class="title-text">Total Interest</span>
            <span class="value"><b>{{ ' (' + (currentBuybackMakeupYearRecord?.interestPercent || 0) + '%)'}}</b></span>
          </span>
          <span class="value truncate">
            <ptg-textbox
              [controlField]="editForm.get('totalInterestAmount')"
              [hasLabel]="true"
              class="no-placeholder-input text-right"
              [inputType]="InputType.TYPE_CURRENCY"
              placeholder="Total Interest"
              (onOutFocus)="onFocusOutTotalInterestAmountInput()"
              [min]="0.0"
              [max]="9999999999999.99"
              [maxLength]="20"
              errorMinMaxValue="Total Interest must be within the range of 0.00 - 9,999,999,999,999.99."
              [isDisabled]="isCalculating"
            ></ptg-textbox>
          </span>
        </div>
        <div class="item cost">
          <span class="label truncate title-text">Cost</span>
          <span class="value truncate"><b>{{ (currentBuybackMakeupYearRecord?.cost | numberLocalDecimal:'$') || '-' }}</b></span>
        </div>
        <p 
          *ngIf="
            (currentBuybackMakeupYearRecord?.cost !== currentBuybackMakeupYearRecord?.pensionAmount) &&
            currentBuybackMakeupYearRecord?.type === 0 &&
            (currentBuybackMakeupYearRecord?.interestPercent === 0 || currentBuybackMakeupYearRecord?.interestPercent === null)"
          class="cost-error-message"
        >Cost must be equal to Pension (Amount Due) when using Buyback Table</p>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit" [disabled]="isCalculating">Update</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button" [disabled]="isCalculating">Cancel</button>
    </div>
  </form>
  <div id="loading-check" class="is-loading-section" *ngIf="isCalculating">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>

