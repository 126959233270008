import { createAction, props } from '@ngrx/store';
import { GetAnnualFeeLetterRequest, GetAnnualFeeLetterResponse, GenerateAnnualFeeLetterRequest, ValidateBeforeGenerateRequest, ValidateBeforeGenerateResponse, EmployerDetail } from '@ptg-employer/models/annual-fee-letter.model';
import { PreviewFileResponse } from 'src/app/admin/features/file/services/models/documents.model';

// Get Annual Fee Letter
export const getAnnualFeeLetterAction = createAction(
  '[GetAnnualFeeLetterAction/API] Send Request',
  props<{ request: GetAnnualFeeLetterRequest }>()
);
export const getAnnualFeeLetterSuccessAction = createAction(
  '[GetAnnualFeeLetterAction/API] Success',
  props<{ response: GetAnnualFeeLetterResponse }>()
);
export const getAnnualFeeLetterFailureAction = createAction(
  '[GetAnnualFeeLetterAction/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetAnnualFeeLetterStateAction = createAction(
  '[GetAnnualFeeLetterAction] Clear'
);

// Generate Annual Fee Letter
export const generateAnnualFeeLetterAction = createAction(
  '[GenerateAnnualFeeLetter/API] Send Request',
  props<{ request: GenerateAnnualFeeLetterRequest }>()
);
export const generateAnnualFeeLetterSuccessAction = createAction(
  '[GenerateAnnualFeeLetter/API] Success',
);
export const generateAnnualFeeLetterFailureAction = createAction(
  '[GenerateAnnualFeeLetter/API] Failure',
  props<{ error?: any }>()
);
export const clearGenerateAnnualFeeLetterStateAction = createAction(
  '[GenerateAnnualFeeLetter] Clear'
);

// Validate Before Generate
export const validateBeforeGenerateAction = createAction(
  '[ValidateBeforeGenerateAnnualFeeLetter/API] Send Request',
  props<{ request: ValidateBeforeGenerateRequest }>()
);
export const validateBeforeGenerateSuccessAction = createAction(
  '[ValidateBeforeGenerateAnnualFeeLetter/API] Success',
  props<{ response: ValidateBeforeGenerateResponse }>()
);
export const validateBeforeGenerateFailureAction = createAction(
  '[ValidateBeforeGenerateAnnualFeeLetter/API] Failure',
  props<{ error?: unknown }>()
);
export const clearValidateBeforeGenerateStateAction = createAction(
  '[ValidateBeforeGenerateAnnualFeeLetter] Clear'
);

// Download Annual Fee Letter
export const getAnnualFeeLetterDownloadAction = createAction(
  '[GetAnnualFeeLetterDownload/API] Send Request',
  props<{ fileId: string }>()
);
export const getAnnualFeeLetterDownloadSuccessAction = createAction(
  '[GetAnnualFeeLetterDownload/API] Success',
  props<{ response: PreviewFileResponse }>()
);
export const getAnnualFeeLetterDownloadFailureAction = createAction(
  '[GetAnnualFeeLetterDownload/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetAnnualFeeLetterDownloadStateAction = createAction(
  '[GetAnnualFeeLetterDownload] Clear'
);

// Get Current Employer Detail
export const getCurrentEmployerDetailAction = createAction(
  '[GetCurrentEmployerDetail/API] Send Request',
  props<{ employerId: string, clientId: string }>()
);
export const getCurrentEmployerDetailSuccessAction = createAction(
  '[GetCurrentEmployerDetail/API] Success',
  props<{ response: EmployerDetail }>()
);
export const getCurrentEmployerDetailFailureAction = createAction(
  '[GetCurrentEmployerDetail/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetCurrentEmployerDetailStateAction = createAction(
  '[GetCurrentEmployerDetail] Clear'
);
