import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CreateAdjustmentDetailedQueryParams,
  CreateAdjustmentDetailedRequest,
  GetValidateAdjustmentDetailIdRequest,
  GetValidateAdjustmentDetailIdResponse,
} from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class NewAdjustmentDetailedService {
  constructor(private httpClient: HttpClient) {}

  createAdjustmentDetailed(
    request: CreateAdjustmentDetailedRequest,
    queries: CreateAdjustmentDetailedQueryParams,
  ): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { benefitEntityId = '', benefitSubType = '' } = queries;
    let params = new HttpParams();
    params = params.append('BenefitSubType', benefitSubType);
    params = params.append('BenefitEntityId', benefitEntityId);

    return this.httpClient.post<void>(
      `${environment.apiUrl}/new-payroll/adjustment-transaction/${request.paymentInstructionHistoryId}/payee/${request.payeeEntityRecordId}/detailed-adjustment`,
      request,
      { context, params },
    );
  }

  getValidateAdjustmentDetailId(
    request: GetValidateAdjustmentDetailIdRequest,
  ): Observable<GetValidateAdjustmentDetailIdResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    let params = new HttpParams();
    params = params.append('benefitStartDate', request.benefitStartDate);
    params = params.append('benefitEndDate', request.benefitEndDate);
    return this.httpClient.get<GetValidateAdjustmentDetailIdResponse>(
      `${environment.apiUrl}/new-payroll/adjustment-transaction/${request.paymentInstructionHistoryId}/detailed-adjustment/${request.calculationType}/validate-qdro`,
      { params, context },
    );
  }
}
