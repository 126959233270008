import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetPaymentNoteMemoResponse } from '../../types/models';
import { NoteMemoService } from '../../services/note-memo-detail.service';
import { addNoteAction, addNoteFailure, addNoteSuccess, checkEditButtonPDFailure, checkEditButtonPDAction, checkEditButtonPDSuccess, editPayableDateAction, editPayableDateFailure, editPayableDateSuccess, getNotesAction, getNotesFailure, getNotesSuccess, updateNoteAction, updateNoteFailure, updateNoteSuccess, checkEditButtonBPAction, checkEditButtonBPFailure, checkEditButtonBPSuccess, editBenefitPeriodAction, editBenefitPeriodFailure, editBenefitPeriodSuccess } from '../actions';


@Injectable()
export class NoteMemoEffects {
  constructor(
    private actions$: Actions,
    private noteMemoService: NoteMemoService,
  ) {}

  getNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotesAction),
      switchMap(({ query, paymentInstructionId }) => {
        return this.noteMemoService.getNotes(query, paymentInstructionId)
          .pipe(
            map((paymentInfoNotes: GetPaymentNoteMemoResponse) => {
              return getNotesSuccess({ paymentInfoNotes });
            }),
            catchError((err) => {
              return of(
                getNotesFailure({ errorMsg: err.message })
              );
            })
          );
      })
    )
  );

  addNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNoteAction),
      switchMap(({ body }) => {
        return this.noteMemoService.addNote(body).pipe(
          map(() => {
            return addNoteSuccess();
          }),
          catchError((err) => {
            return of(
              addNoteFailure({ error: err.message })
            );
          })
        );
      })
    )
  );
  
  updateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNoteAction),
      switchMap(({ body }) => {
        return this.noteMemoService.addNote(body).pipe(
          map(() => {
            return updateNoteSuccess();
          }),
          catchError((err) => {
            return of(updateNoteFailure({ error: err.message }));
          })
        );
      })
    )
  );

  checkEditButtonPD$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkEditButtonPDAction),
      switchMap(({ paymentInstructionId, benefitTypeOptionId }) => {
        return this.noteMemoService.checkConditionEditPayableDate(paymentInstructionId, benefitTypeOptionId).pipe(
          map((response) => {
            return checkEditButtonPDSuccess({isValid: response.isValid});
          }),
          catchError((err) => {
            return of(checkEditButtonPDFailure({ error: err.message }));
          })
        );
      })
    )
  );

  checkEditButtonBP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkEditButtonBPAction),
      switchMap(({ paymentInstructionId, benefitTypeOptionId }) => {
        return this.noteMemoService.checkConditionEditBenefitPeriod(paymentInstructionId, benefitTypeOptionId).pipe(
          map((response) => {
            return checkEditButtonBPSuccess({isValid: response.isValid});
          }),
          catchError((err) => {
            return of(checkEditButtonBPFailure({ error: err.message }));
          })
        );
      })
    )
  );

  editPayableDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editPayableDateAction),
      switchMap(({ body }) => {
        return this.noteMemoService.editPayableDate(body).pipe(
          map(() => {
            return editPayableDateSuccess();
          }),
          catchError((err) => {
            return of(
              editPayableDateFailure({ error: err.message })
            );
          })
        );
      })
    )
  );

  editBenefitPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editBenefitPeriodAction),
      switchMap(({ body }) => {
        return this.noteMemoService.editBenefitPeriod(body).pipe(
          map(() => {
            return editBenefitPeriodSuccess();
          }),
          catchError((err) => {
            return of(
              editBenefitPeriodFailure({ error: err.message })
            );
          })
        );
      })
    )
  );
}
