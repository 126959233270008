export enum DeductionType {
  Tax = 1,
  Insurance = 2,
  Garnishment = 3,
  Adjustment = 4,
  QDRO = 5,
  Others = 255,
}

export enum TaxSettingType {
  PostTax,
  PreTax,
}

export enum TaxType {
  FederalTax = 'Federal Tax',
  StateTax = 'State Tax',
}

export enum DeductionSubType {
  FederalTax = 1,
  StateTax = 2,
  Medical = 3,
  Dental = 4,
  Life = 5,
  Garnishment = 21,
  Adjustment = 22,
  Other = 23,
  Qdro = 24,
}
