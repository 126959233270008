<div class="municipality-payment-container" id="municipality-payment-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>
  <div *ngIf="!isEntityView; else entityView">
    <ptg-overview-header
      #overViewHeader
      (changeMemberDetailEvent)="onChangeMemberDetail()"
    ></ptg-overview-header>
  </div>
  <ng-template #entityView>
    <ptg-overview-header-entity></ptg-overview-header-entity>
  </ng-template>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <ptg-municipality-group-aggregation [aggregationDetails]="listAggregations">
    </ptg-municipality-group-aggregation>

    <ptg-detail-display-box
      *ngIf="dataTable && dataTable.length && !isLoading"
      title="Selected Service"
      [selectedItem]="dataTable[currentRowIndex]"
      [propertyDisplayed]="propertyDisplayed"
      (editButtonEvent)="onEditServiceHistory(dataTable[currentRowIndex])"
      (removeButtonEvent)="removeButtonEvent(dataTable[currentRowIndex])"
    ></ptg-detail-display-box>

    <ptg-list-display-box
      title="Service History"
      pageName="{{ currentPageName || menuName }}"
      btnAddTitle="Add"
      [isLoading]="isLoading"
      [dataTable]="dataTable"
      [columns]="columns"
      [sortInfo]="sortInfo"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [currentRowIndex]="currentRowIndex"
      (changePage)="changePage($event)"
      (sortChange)="sortChange($event)"
      (selectRow)="selectRow($event)"
      (addItem)="onEditServiceHistory(undefined)"
    ></ptg-list-display-box>
  </div>
</div>
