import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EditPayrollSettingsService } from '../../services';

import * as EditPayrollSettingsActions from '../actions/edit-payroll-settings.actions';

import { GetEditPayrollSettingsResponse } from '../../services/models/edit-payroll-settings.model';

@Injectable()
export class EditPayrollSettingsEffects {
  constructor(
    private actions$: Actions,
    private editPayrollSettingsService: EditPayrollSettingsService,
  ) {}

  getEditPayrollSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPayrollSettingsActions.getEditPayrollSettingsAction),
      switchMap(({ request }) => {
        return this.editPayrollSettingsService.getEditPayrollSettings(request).pipe(
          map((response: GetEditPayrollSettingsResponse) =>
            EditPayrollSettingsActions.getEditPayrollSettingsSuccess({ response }),
          ),
          catchError((err) => of(EditPayrollSettingsActions.getEditPayrollSettingsFailure(err))),
        );
      }),
    ),
  );

  saveEditPayrollSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPayrollSettingsActions.saveEditPayrollSettingsAction),
      switchMap(({ body, paymentInstructionId }) => {
        return this.editPayrollSettingsService.savePayrollSettings(body, paymentInstructionId).pipe(
          map(() => EditPayrollSettingsActions.saveEditPayrollSettingsSuccess()),
          catchError((err) => of(EditPayrollSettingsActions.saveEditPayrollSettingsFailure(err))),
        );
      }),
    ),
  );
}
