import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearGetCreateAdjustmentDetailedStateAction,
  getCreateAdjustmentDetailedAction,
  getCreateAdjustmentDetailedFailureAction,
  getCreateAdjustmentDetailedSuccessAction,
  clearGetValidateAdjustmentDetailIdStateAction,
  getValidateAdjustmentDetailIdAction,
  getValidateAdjustmentDetailIdFailureAction,
  getValidateAdjustmentDetailIdSuccessAction,

} from '../actions';
import { GetValidateAdjustmentDetailIdResponse } from '../../services/models';

export interface CreateAdjustmentDetailedState {
  createAdjustmentDetailed?: BaseActionState<void>;
  getValidateAdjustmentDetailId?: BaseActionState<GetValidateAdjustmentDetailIdResponse>;
}

const initialState: CreateAdjustmentDetailedState = {};

export const createAdjustmentDetailedReducer = createReducer(
  initialState,
  on(getCreateAdjustmentDetailedAction, (state) => ({
    ...state,
    createAdjustmentDetailed: {
      isLoading: true,
    },
  })),
  on(getCreateAdjustmentDetailedSuccessAction, (state, { response }) => ({
    ...state,
    createAdjustmentDetailed: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(getCreateAdjustmentDetailedFailureAction, (state, { error }) => ({
    ...state,
    createAdjustmentDetailed: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCreateAdjustmentDetailedStateAction, (state) => ({
    ...state,
    createAdjustmentDetailed: undefined,
  })),

  on(getValidateAdjustmentDetailIdAction, (state) => ({
    ...state,
    getValidateAdjustmentDetailId: {
      isLoading: true,
    },
  })),
  on(getValidateAdjustmentDetailIdSuccessAction, (state, { response }) => ({
    ...state,
    getValidateAdjustmentDetailId: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getValidateAdjustmentDetailIdFailureAction, (state, { error }) => ({
    ...state,
    getValidateAdjustmentDetailId: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetValidateAdjustmentDetailIdStateAction, (state) => ({
    ...state,
    getValidateAdjustmentDetailId: undefined,
  }))

);
