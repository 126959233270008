<div class="profile-detail-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div class="flex flex-col p-6 gap-6"  *ngIf="!isLoadingViewCard">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [disabled]="!isChanged"
        (click)="formSubmit$.next()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>

    <div class="form-control-container">
      <div class="flex gap-5">
        <div class="left-controls flex flex-col w-full">
          <form
            *ngIf="editForm"
            [formGroup]="editForm"
            class="row-controls flex flex-col"
          >
            <ptg-textbox
              placeholder="Profile Name"
              errorAsync="Profile Name already exists."
              [ngClass]="{
                'has-error': nameCtrl.errors && nameCtrl.touched
              }"
              width="33%"
              [controlField]="nameCtrl"
              [hasLabel]="true"
              class="mt-1"
              [maxLength]="100"
              (valueChange)="onChangeData()"
            ></ptg-textbox>
            <div class="detail mt-2">
              <span class="label" id="label-Status Name">Entity</span>
              <span class="value" id="value-Status Name">
                <ng-container>{{profileDetailData?.entityName ?? ""}}</ng-container> 
              </span>
            </div>
            <div class="status-container" *ngIf="profileDetailData?.isMember">
              <div class="status-select">
                <mat-form-field
                  appearance="fill"
                  class="select-custom mb-2 status-field"
                  [ngClass]="{ 'mat-form-field-invalid': false }"
                >
                  <mat-label>Status</mat-label>
                  <mat-select [formControl]="statusSelected" (valueChange)="onChangeData()" >
                    <mat-select-trigger>
                      <div class="option-class">
                        <mat-icon
                          *ngIf="statusSelected?.value?.iconName"
                          [style.color]="statusSelected?.value?.color"
                          >{{ statusSelected?.value?.iconName }}</mat-icon
                        >
                        <span>{{ statusSelected?.value?.statusEventName }}</span>
                      </div>
                    </mat-select-trigger>
                    <mat-option
                      class="select-status-option"
                      *ngFor="let option of listStatusOption"
                      [value]="option"
                    >
                      <span *ngIf="option?.isShowAllOption">
                        <span class="inline-flex flex-col leading-5 text-base ml-0.5">
                          <span>{{ option.statusName }} - All</span>
                        </span>
                      </span>
                      <span *ngIf="!option?.isShowAllOption">
                          <span class="inline-flex flex-col leading-5 ml-0.5">
                            <span class="font-bold text-base">{{ option.statusEventName }}</span>
                            <span class="text-sm">{{ option.statusName }}</span>
                          </span>
                      </span>
                    </mat-option>
                  </mat-select>
    
                  <mat-error
                    id="mat-error-status"
                    *ngIf="statusSelected.touched && statusSelected?.errors"
                    class="icon-name-status"
                  >
                    Status is required.
                  </mat-error>
                </mat-form-field>
                <div
                  class="add-btn"
                  [ngClass]="{
                    'add-btn-disable': !statusSelected?.value?.statusName
                  }"
                >
                  <mat-icon (click)="addStatus()">add_circle</mat-icon>
                </div>
                <div class="chips-style">
                  <mat-chip-list>
                    <mat-chip *ngFor="let option of editForm.value.statuses">
                      <mat-icon
                        *ngIf="option?.iconName"
                        [style.color]="option?.color"
                        >{{ option?.iconName }}</mat-icon
                      >
                      <span>{{ option?.statusEventName }}</span>
                      <div class="remove-btn">
                        <mat-icon (click)="removeStatus(option)">clear</mat-icon>
                      </div>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
            <div class="flex gap-3 mt-5 overview-wrap">
              <div class="flex-1">
                <ptg-textbox
                  placeholder="Overview - Label"
                  errorAsync="Overview - Label already exists."
                  [controlField]="labelCtrl"
                  [hasLabel]="true"
                  width="100%"
                  [maxLength]="100"
                  (valueChange)="onChangeData()"
                ></ptg-textbox>
              </div>
              <div class="flex-1">
                <ptg-select 
                  [controlField]="overviewViewCtrl" 
                  [listData]="listOverviewView"
                  [isOptionObj]="true"
                  width="100%"
                  (changeOptionValue)="onChangeData()"
                  placeholder="Overview - View"
                ></ptg-select>
              </div>
              <div class="flex-1">
                <ptg-select 
                  [controlField]="overviewHeaderCtrl" 
                  [listData]="listOverviewHeader" 
                  [isOptionObj]="true"
                  width="100%"
                  (changeOptionValue)="onChangeData()"
                  placeholder="Overview - Header"
                ></ptg-select>
              </div>
              <div class="flex-1">
                <ptg-select 
                  [controlField]="subPageHeaderCtrl" 
                  [listData]="listSubPageHeader" 
                  [isOptionObj]="true"
                  width="100%"
                  (changeOptionValue)="onChangeData()"
                  placeholder="Sub-page Header"
                ></ptg-select>
              </div>
            </div>
          </form>
      </div>
    </div>

    <div class="flex gap-5 mt-3">
      <div class="add-menu-card">
        <div class="box-info">
          <div class="box-info-header">Add Menu</div>
          <div class="box-info-content">
            <div class="form-container">
              <ptg-select
                [isOptionObj]="true"
                [isSetMaxWidthSelect]="true"
                [listData]="availableMenuConfigs"
                [controlField]="menuNameCtrl"
                placeholder="Menu Name"
                [isMultipleLineOption]="true"
                panelClass="property-name-dropdown"
                (changeOptionValue)="changeProperty()"
              ></ptg-select> 
              <ptg-textbox
                [controlField]="menuLabelCtrl"
                errorAsync="Label already exists."
                [hasLabel]="true"
                customError="duplicatedValue"
                placeholder="Menu Label"
                [maxLength]="100"
              ></ptg-textbox>
              <ptg-button
                buttonName="Add to Profile"
                classInput="add-button"
                type="button"
                [isDisabled]="isLoadingAddMenuItem"
                (clickButton)="addProperty()"
              >
                <mat-icon>add</mat-icon>
              </ptg-button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-1">
        <div class="box-info">
          <div class="box-info-header">Menu Order</div>
          <div class="box-info-content">
            <ptg-grid
              #gridLeftCard
              [data]="leftCard"
              [columns]="configuredPropertyColumns"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (change)="onChangeData()"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row?.menuName }}</span>
                </div>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div id="loading-table" *ngIf="isLoadingViewCard">
    <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
    </div>
  </div>
</div>
